import { ProjectId, TeamId, WorkspaceId } from "../../core";
import {
  GlobalRoleName,
  ProjectRoleName,
  Role,
  TeamRoleName,
  WorkspaceRoleName,
} from "../types.generated";
import { unsafeRole } from "./internal";

export function globalRole(name: GlobalRoleName): Role {
  return unsafeRole(`G:${name}`);
}

export function workspaceRole(
  name: WorkspaceRoleName,
  workspaceId: WorkspaceId
): Role {
  return unsafeRole(`W:${name}:${workspaceId}`);
}

export function projectRole(name: ProjectRoleName, projectId: ProjectId): Role {
  return unsafeRole(`P:${name}:${projectId}`);
}

export function teamRole(name: TeamRoleName, teamId: TeamId): Role {
  return unsafeRole(`T:${name}:${teamId}`);
}

export const superuserRole: Role = globalRole("Superuser");
export const globalAdminRole: Role = globalRole("Admin");
export const mapViewerRole: Role = globalRole("MapViewer");

export function workspaceDisabledRole(workspaceId: WorkspaceId): Role {
  return workspaceRole("Disabled", workspaceId);
}

export function workspaceActiveRole(workspaceId: WorkspaceId): Role {
  return workspaceRole("Active", workspaceId);
}

export function workspaceAdminRole(workspaceId: WorkspaceId): Role {
  return workspaceRole("Admin", workspaceId);
}

export function workspaceOwnerRole(workspaceId: WorkspaceId): Role {
  return workspaceRole("Owner", workspaceId);
}

export function projectMemberRole(projectId: ProjectId): Role {
  return projectRole("Member", projectId);
}

export function projectSurveyorRole(projectId: ProjectId): Role {
  return projectRole("Surveyor", projectId);
}

export function projectApproverRole(projectId: ProjectId): Role {
  return projectRole("Approver", projectId);
}

export function projectCoordinatorRole(projectId: ProjectId): Role {
  return projectRole("Coordinator", projectId);
}

export function teamMemberRole(teamId: TeamId): Role {
  return teamRole("Member", teamId);
}

export function teamSurveyorRole(teamId: TeamId): Role {
  return teamRole("Surveyor", teamId);
}

export function teamApproverRole(teamId: TeamId): Role {
  return teamRole("Approver", teamId);
}

export function teamCoordinatorRole(teamId: TeamId): Role {
  return teamRole("Coordinator", teamId);
}
