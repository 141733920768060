// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityWaterLevel = "High" | "Normal" | "Low";

const High: ArrtWaterQualityWaterLevel = "High";
const Normal: ArrtWaterQualityWaterLevel = "Normal";
const Low: ArrtWaterQualityWaterLevel = "Low";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityWaterLevelEnum = {
  ...createEnum("ArrtWaterQualityWaterLevel", [
    [High, "High"],
    [Normal, "Normal"],
    [Low, "Low"],
  ]),
  High,
  Normal,
  Low,
};

export default ArrtWaterQualityWaterLevelEnum;
