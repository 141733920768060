import {
  surveyModuleInventory,
  SurveyModuleInventory,
} from "./SurveyModuleInventory";
import TestSurvey1Module, {
  testSurvey1,
  TestSurvey1,
  TestSurvey1Data,
} from "./TestSurvey1";
import TestSurvey2Module, {
  testSurvey2,
  TestSurvey2,
  TestSurvey2Data,
} from "./TestSurvey2";
import TestSurvey3Module, {
  testSurvey3,
  TestSurvey3,
  TestSurvey3Data,
} from "./TestSurvey3";

export {
  TestSurvey1Module,
  TestSurvey2Module,
  TestSurvey3Module,
  testSurvey1,
  testSurvey2,
  testSurvey3,
};

export type {
  TestSurvey1,
  TestSurvey2,
  TestSurvey3,
  TestSurvey1Data,
  TestSurvey2Data,
  TestSurvey3Data,
};

export function testSurveyModules(): SurveyModuleInventory {
  return surveyModuleInventory([
    TestSurvey1Module,
    TestSurvey2Module,
    TestSurvey3Module,
  ]);
}
