// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPondCountWhySizeChanged = "E" | "L" | "F" | "D" | "M" | "O";

const Incorrect: FhtPondCountWhySizeChanged = "E";
const Enlarged: FhtPondCountWhySizeChanged = "L";
const Filled: FhtPondCountWhySizeChanged = "F";
const Divided: FhtPondCountWhySizeChanged = "D";
const Merged: FhtPondCountWhySizeChanged = "M";
const Other: FhtPondCountWhySizeChanged = "O";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPondCountWhySizeChangedEnum = {
  ...createEnum("FhtPondCountWhySizeChanged", [
    [Incorrect, "Pond was in the wrong size category in the first place"],
    [Enlarged, "Pond has been enlarged"],
    [Filled, "Pond has been infilled"],
    [Divided, "Pond has been divided into two ponds"],
    [Merged, "Two ponds have been merged into one"],
    [Other, "Other"],
  ]),
  Incorrect,
  Enlarged,
  Filled,
  Divided,
  Merged,
  Other,
};

export default FhtPondCountWhySizeChangedEnum;
