// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRiverWoodRetainedByArtificialFeature =
  | "HP"
  | "BR"
  | "WE"
  | "JE"
  | "Other";

const IncorporatesHumanPosts: MrsRiverWoodRetainedByArtificialFeature = "HP";
const RetainedByBridge: MrsRiverWoodRetainedByArtificialFeature = "BR";
const RetainedByWeir: MrsRiverWoodRetainedByArtificialFeature = "WE";
const RetainedByJetty: MrsRiverWoodRetainedByArtificialFeature = "JE";
const Other: MrsRiverWoodRetainedByArtificialFeature = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRiverWoodRetainedByArtificialFeatureEnum = {
  ...createEnum("MrsRiverWoodRetainedByArtificialFeature", [
    [IncorporatesHumanPosts, "Jam incorporates human-constructed posts/frame"],
    [RetainedByBridge, "Retained by bridge structure"],
    [RetainedByWeir, "Retained by weird"],
    [RetainedByJetty, "Retained by jetty"],
    [Other, "Retained by other artificial structure"],
  ]),
  IncorporatesHumanPosts,
  RetainedByBridge,
  RetainedByWeir,
  RetainedByJetty,
  Other,
};

export default MrsRiverWoodRetainedByArtificialFeatureEnum;
