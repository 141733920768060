import { isOption, Option, Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  AnonymisedUser,
  ApiParams,
  checkedToResult,
  Email,
  isAnonymisedUser,
  isChecked,
  isSearchResults,
  isUser,
  Message,
  SearchResults,
  UserId,
  UserUpdate,
  User,
  WorkspaceRef,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs, notFoundToOption, optionalContentAs } from "../../response";
import { formatUrl, UrlParts } from "../../url";
import { PartialParams } from "../params";
import { UserSearchOptionsV2 } from "./common";

const basePath = "/user/v2";

export function search(
  apiParams: ApiParams,
  options: PartialParams<UserSearchOptionsV2> = {}
): IO<SearchResults<User>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(contentAs("SearchResults<User>", isSearchResults(isUser)));
}

export function searchUrl(
  options: PartialParams<UserSearchOptionsV2> = {}
): UrlParts {
  return {
    path: basePath,
    query: { ...options },
  };
}

export function searchAnonymised(
  apiParams: ApiParams,
  options: PartialParams<UserSearchOptionsV2> = {}
): IO<SearchResults<AnonymisedUser>> {
  const url: UrlParts = {
    path: `${basePath}/anonymised`,
    query: { ...options },
  };

  return fetch
    .get({ apiParams, url })
    .flatMap(
      contentAs(
        "SearchResults<AnonymisedUser>",
        isSearchResults(isAnonymisedUser)
      )
    );
}

export function searchAnonymisedUrl(
  apiParams: ApiParams,
  options: PartialParams<UserSearchOptionsV2> = {}
): string {
  return formatUrl(apiParams.apiConfig.baseUrl, {
    path: `${basePath}/anonymised`,
    query: { ...options },
  });
}

export function readOrFail(
  apiParams: ApiParams,
  ref: UserId | Email
): IO<User> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(contentAs("User", isUser));
}

export function readOption(
  apiParams: ApiParams,
  ref: UserId | Email
): IO<Option<User>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(optionalContentAs("User", isUser));
}

export function readAnonymised(
  apiParams: ApiParams,
  user: UserId | Email
): IO<Option<AnonymisedUser>> {
  const url: UrlParts = {
    path: `${basePath}/anonymised/${user}`,
  };

  return fetch
    .get({ apiParams, url })
    .map(notFoundToOption)
    .flatMap(contentAs("Option<AnonymisedUser>", isOption(isAnonymisedUser)));
}

export function readAnonymisedOrFail(
  apiParams: ApiParams,
  user: UserId | Email
): IO<AnonymisedUser> {
  const url: UrlParts = {
    path: `${basePath}/anonymised/${user}`,
  };

  return fetch
    .get({ apiParams, url })
    .flatMap(contentAs("AnonymisedUser", isAnonymisedUser));
}

export function save(
  apiParams: ApiParams,
  userId: UserId,
  userUpdate: UserUpdate,
  workspace?: WorkspaceRef,
  welcome?: boolean
): IO<Result<Message[], User>> {
  const url: UrlParts = {
    path: `${basePath}/${userId}`,
    query: { workspace, welcome },
  };

  return fetch
    .put({ apiParams, url, body: userUpdate })
    .flatMap(contentAs("Checked<User>", isChecked(isUser)))
    .map(checkedToResult);
}

export function remove(apiParams: ApiParams, userId: UserId): IO<void> {
  const url: UrlParts = {
    path: `${basePath}/${userId}`,
  };

  return fetch.remove({ apiParams, url }).void();
}
