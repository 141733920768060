// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessPhysicalAssessmentForm =
  | "Visual"
  | "Rhs"
  | "Morph"
  | "Other";

const Visual: NeRiverNaturalnessPhysicalAssessmentForm = "Visual";
const Rhs: NeRiverNaturalnessPhysicalAssessmentForm = "Rhs";
const Morph: NeRiverNaturalnessPhysicalAssessmentForm = "Morph";
const Other: NeRiverNaturalnessPhysicalAssessmentForm = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessPhysicalAssessmentFormEnum = {
  ...createEnum("NeRiverNaturalnessPhysicalAssessmentForm", [
    [Visual, "Simple visual inspection"],
    [Rhs, "River Habitat Survey (RHS)"],
    [Morph, "Modular River Survey (MoRPh)"],
    [Other, "Other (please specify)"],
  ]),
  Visual,
  Rhs,
  Morph,
  Other,
};

export default NeRiverNaturalnessPhysicalAssessmentFormEnum;
