import {
  attachmentAttr,
  attributeGroup,
  enumAttr,
  numberAttr,
  pointLayer,
  standardAttribution,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { StdActivityTypeEnum } from "@cartographerio/inventory-enums";
import { SurveyStatusEnum, unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const StdActivityDiary = mapSchemaWithDefaults({
  mapId: unsafeMapId("StdActivityDiary"),
  title: "Activity Diary",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("StdActivityDiary"),
      title: "Activity Diary",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("StdActivityDiary"),
        attribution: [
          standardAttribution({
            what: "Activity Diary Dataset",
            who: "Cartographer Studios Ltd",
            url: "https://cartographer.io",
            from: "2012",
          }),
        ],
      }),
      defaultAttribute: "activityType",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          attributes: [
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "activityType",
              label: "Activity Type",
              enum: StdActivityTypeEnum,
            }),
            numberAttr({
              attributeId: "duration",
              label: "Duration",
              buckets: "auto",
              showTimeline: false,
            }),
            surveyAttr("surveyId", "Survey"),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("StdActivityDiary"),
            }),
            enumAttr({
              attributeId: "status",
              label: "Survey Status",
              enum: SurveyStatusEnum,
            }),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
      ],
    }),
  ],
});
