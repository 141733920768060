import { unsafeMapId } from "@cartographerio/topo-map";
import { unsafeProjectAlias } from "@cartographerio/types";
import { mapSchemaWithDefaults } from "../../core";
import { riverflyLayer } from "./helpers";

export const Riverfly = mapSchemaWithDefaults({
  mapId: unsafeMapId("riverfly"),
  title: "Riverfly",
  layers: [
    riverflyLayer(
      [
        unsafeProjectAlias("riverfly"),
        unsafeProjectAlias("urbanriverfly"),
        unsafeProjectAlias("extendedriverfly"),
      ],
      "Riverfly"
    ),
  ],
});
