import { isArray } from "@cartographerio/guard";

export type UrlQueryValue = string | number | boolean;

export interface UrlQuery {
  [name: string]: UrlQueryValue | UrlQueryValue[] | null | undefined;
}

export interface UrlParts {
  path?: string;
  query?: UrlQuery | null;
  fragment?: string | null;
  encodePath?: boolean;
}

function formatQueryValue(value: UrlQueryValue): string {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "number") {
    return value.toString(10);
  } else {
    return value ? "true" : "false";
  }
}

export function formatUrl(baseUrl: string, parts: UrlParts): string {
  const { path, query, fragment, encodePath = true } = parts;

  let ans: string = baseUrl;
  let sep: string = "?";

  if (path != null) {
    ans += encodePath ? encodeURI(path) : path;
  }

  function kvp(key: string, value: UrlQueryValue): void {
    ans += sep;
    ans += encodeURIComponent(key);
    ans += "=";
    ans += encodeURIComponent(formatQueryValue(value));
    sep = "&";
  }

  if (typeof query === "object") {
    for (const key in query) {
      const value = query[key];

      if (isArray(value)) {
        for (const item of value) {
          kvp(key, item);
        }
      } else if (value != null) {
        kvp(key, value);
      }
    }
  }

  if (fragment != null) {
    ans += "#" + encodeURIComponent(fragment);
  }

  return ans;
}
