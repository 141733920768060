// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type StdInvasiveSpecies =
  | "AmericanSkunkCabbage"
  | "CherryLaurel"
  | "CurlyWaterweed"
  | "FloatingPennywort"
  | "GiantHogweed"
  | "GunneraSpecies"
  | "HimalayanBalsam"
  | "HottentotFig"
  | "JapaneseKnotweed"
  | "MonkeyFlower"
  | "NewZealandPigmyweed"
  | "OrangeBalsam"
  | "PampasGrass"
  | "ParrotsFeather"
  | "PirriPirriBur"
  | "Rhododendron"
  | "WaterFern"
  | "WaterPrimrose";

const AmericanSkunkCabbage: StdInvasiveSpecies = "AmericanSkunkCabbage";
const CherryLaurel: StdInvasiveSpecies = "CherryLaurel";
const CurlyWaterweed: StdInvasiveSpecies = "CurlyWaterweed";
const FloatingPennywort: StdInvasiveSpecies = "FloatingPennywort";
const GiantHogweed: StdInvasiveSpecies = "GiantHogweed";
const GunneraSpecies: StdInvasiveSpecies = "GunneraSpecies";
const HimalayanBalsam: StdInvasiveSpecies = "HimalayanBalsam";
const HottentotFig: StdInvasiveSpecies = "HottentotFig";
const JapaneseKnotweed: StdInvasiveSpecies = "JapaneseKnotweed";
const MonkeyFlower: StdInvasiveSpecies = "MonkeyFlower";
const NewZealandPigmyweed: StdInvasiveSpecies = "NewZealandPigmyweed";
const OrangeBalsam: StdInvasiveSpecies = "OrangeBalsam";
const PampasGrass: StdInvasiveSpecies = "PampasGrass";
const ParrotsFeather: StdInvasiveSpecies = "ParrotsFeather";
const PirriPirriBur: StdInvasiveSpecies = "PirriPirriBur";
const Rhododendron: StdInvasiveSpecies = "Rhododendron";
const WaterFern: StdInvasiveSpecies = "WaterFern";
const WaterPrimrose: StdInvasiveSpecies = "WaterPrimrose";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StdInvasiveSpeciesEnum = {
  ...createEnum("StdInvasiveSpecies", [
    [AmericanSkunkCabbage, "American Skunk-Cabbage"],
    [CherryLaurel, "Cherry Laurel"],
    [CurlyWaterweed, "Curly Waterweed"],
    [FloatingPennywort, "Floating Pennywort"],
    [GiantHogweed, "Giant Hogweed"],
    [GunneraSpecies, "Gunnera Species"],
    [HimalayanBalsam, "Himalayan Balsam"],
    [HottentotFig, "Hottentot Fig"],
    [JapaneseKnotweed, "Japanese Knotweed"],
    [MonkeyFlower, "Monkey Flower"],
    [NewZealandPigmyweed, "New Zealand Pigmyweed"],
    [OrangeBalsam, "Orange Balsam"],
    [PampasGrass, "Pampas Grass"],
    [ParrotsFeather, "Parrot's Feather"],
    [PirriPirriBur, "Pirri-Pirri Bur"],
    [Rhododendron, "Rhododendron"],
    [WaterFern, "Water Fern"],
    [WaterPrimrose, "Water Primrose"],
  ]),
  AmericanSkunkCabbage,
  CherryLaurel,
  CurlyWaterweed,
  FloatingPennywort,
  GiantHogweed,
  GunneraSpecies,
  HimalayanBalsam,
  HottentotFig,
  JapaneseKnotweed,
  MonkeyFlower,
  NewZealandPigmyweed,
  OrangeBalsam,
  PampasGrass,
  ParrotsFeather,
  PirriPirriBur,
  Rhododendron,
  WaterFern,
  WaterPrimrose,
};

export default StdInvasiveSpeciesEnum;
