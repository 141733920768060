// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRiverWoodJamType = "T" | "W";

const Tree: MrsRiverWoodJamType = "T";
const Wood: MrsRiverWoodJamType = "W";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRiverWoodJamTypeEnum = {
  ...createEnum("MrsRiverWoodJamType", [
    [Tree, "Individual isolated tree"],
    [Wood, "Wood accumulation"],
  ]),
  Tree,
  Wood,
};

export default MrsRiverWoodJamTypeEnum;
