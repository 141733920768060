// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopWaterColor =
  | "Clear"
  | "Brown"
  | "Milky"
  | "Green"
  | "Other";

const Clear: UclPitStopWaterColor = "Clear";
const Brown: UclPitStopWaterColor = "Brown";
const Milky: UclPitStopWaterColor = "Milky";
const Green: UclPitStopWaterColor = "Green";
const Other: UclPitStopWaterColor = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopWaterColorEnum = {
  ...createEnum("UclPitStopWaterColor", [
    [Clear, "Gin-clear (colourless)"],
    [Brown, "Tea without milk (brown / see-through)"],
    [Milky, "Tea with milk (creamy brown / opaque)"],
    [Green, "Peppermint tea (green)"],
    [Other, "Other"],
  ]),
  Clear,
  Brown,
  Milky,
  Green,
  Other,
};

export default UclPitStopWaterColorEnum;
