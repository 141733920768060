import {
  AnonymisedUser,
  Project,
  Team,
  Workspace,
} from "@cartographerio/types";
import { WorkspaceGraph } from "./graph";
import { UserWorkspaceGraph } from "./userGraph";

export function workspaceGraph(
  workspaces: Workspace | Workspace[],
  projects: Project[],
  teams: Team[]
): WorkspaceGraph {
  return new WorkspaceGraph({
    workspaces,
    projects,
    teams,
  });
}

export function userWorkspaceGraph<U extends AnonymisedUser = AnonymisedUser>(
  workspaces: Workspace | Workspace[],
  projects: Project[],
  teams: Team[],
  users: U[]
): UserWorkspaceGraph<U> {
  return new UserWorkspaceGraph({
    workspaces,
    projects,
    teams,
    users,
  });
}
