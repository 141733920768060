// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessHerpetofaunaSpecies = "CommonFrog" | "GrassSnake";

const CommonFrog: NeRiverNaturalnessHerpetofaunaSpecies = "CommonFrog";
const GrassSnake: NeRiverNaturalnessHerpetofaunaSpecies = "GrassSnake";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessHerpetofaunaSpeciesEnum = {
  ...createEnum("NeRiverNaturalnessHerpetofaunaSpecies", [
    [CommonFrog, "Common frog (Rana temporaria)"],
    [GrassSnake, "Grass snake (Natrix natrix)"],
  ]),
  CommonFrog,
  GrassSnake,
};

export default NeRiverNaturalnessHerpetofaunaSpeciesEnum;
