// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiWildlife =
  | "none"
  | "otter"
  | "mink"
  | "waterVole"
  | "kingfisher"
  | "greyWagtail"
  | "heron"
  | "dragonfliesOrDamselflies"
  | "fish"
  | "dipper"
  | "other";

const None: WrtWestcountryCsiWildlife = "none";
const Otter: WrtWestcountryCsiWildlife = "otter";
const Mink: WrtWestcountryCsiWildlife = "mink";
const WaterVole: WrtWestcountryCsiWildlife = "waterVole";
const Kingfisher: WrtWestcountryCsiWildlife = "kingfisher";
const GreyWagtail: WrtWestcountryCsiWildlife = "greyWagtail";
const Heron: WrtWestcountryCsiWildlife = "heron";
const DragonfliesOrDamselflies: WrtWestcountryCsiWildlife =
  "dragonfliesOrDamselflies";
const Fish: WrtWestcountryCsiWildlife = "fish";
const Dipper: WrtWestcountryCsiWildlife = "dipper";
const Other: WrtWestcountryCsiWildlife = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiWildlifeEnum = {
  ...createEnum("WrtWestcountryCsiWildlife", [
    [None, "None"],
    [Otter, "Otter"],
    [Mink, "Mink"],
    [WaterVole, "Water vole"],
    [Kingfisher, "Kingfisher"],
    [GreyWagtail, "Grey wagtail"],
    [Heron, "Heron"],
    [DragonfliesOrDamselflies, "Dragonflies/Damselflies"],
    [Fish, "Fish"],
    [Dipper, "Dipper"],
    [Other, "Other"],
  ]),
  None,
  Otter,
  Mink,
  WaterVole,
  Kingfisher,
  GreyWagtail,
  Heron,
  DragonfliesOrDamselflies,
  Fish,
  Dipper,
  Other,
};

export default WrtWestcountryCsiWildlifeEnum;
