import {
  Timestamp,
  ddmmyyyy,
  defaultTimestampFormat,
  formatTimestamp,
  nowTimestamp,
} from "@cartographerio/types";
import { RuleLevel } from "./type";

/* TODO: How can we restructure this file
 * to ensure we maintain 100% coverage
 * as we add new types of field and rule?
 */

export function requiredRuleDefaultMessage(level: RuleLevel): string {
  return level === "error"
    ? "You must specify a value."
    : "You should specify a value.";
}

function defaultBoundsMessage<T>(
  level: RuleLevel,
  minValue: T | null | undefined,
  maxValue: T | null | undefined,
  valueDescription: string = "The value",
  format: (value: T) => string,
  messages: "number" | "timestamp"
): string {
  const greater = messages === "timestamp" ? "later" : "greater";
  const less = messages === "timestamp" ? "earlier" : "less";

  if (minValue != null && maxValue != null) {
    if (minValue === maxValue) {
      return level === "error"
        ? `${valueDescription} must be ${format(minValue)}.`
        : `${valueDescription} should be ${format(minValue)}.`;
    } else {
      return level === "error"
        ? `${valueDescription} must be between ${format(minValue)} and ${format(maxValue)}.`
        : `${valueDescription} should be between ${format(minValue)} and ${format(maxValue)}.`;
    }
  } else if (minValue != null) {
    return level === "error"
      ? `${valueDescription} must be ${format(minValue)} or ${greater}.`
      : `${valueDescription} should be ${format(minValue)} or ${greater}.`;
  } else if (maxValue != null) {
    return level === "error"
      ? `${valueDescription} must be ${format(maxValue)} or ${less}.`
      : `${valueDescription} should be ${format(maxValue)} or ${less}.`;
  } else {
    return `${valueDescription} can be any value.`;
  }
}

export function boundsRuleDefaultMessage(
  level: RuleLevel,
  minValue?: number,
  maxValue?: number,
  valueDescription?: string
): string {
  return defaultBoundsMessage(
    level,
    minValue,
    maxValue,
    valueDescription,
    String,
    "number"
  );
}

export function timestampBoundsRuleDefaultMessage(
  level: RuleLevel,
  minValue: Timestamp | "now" | null,
  maxValue: Timestamp | "now" | null,
  validateTime: boolean,
  valueDescription: string,
  now: Timestamp = nowTimestamp()
): string {
  return defaultBoundsMessage(
    level,
    minValue,
    maxValue,
    valueDescription,
    ts =>
      formatTimestamp(ts === "now" ? now : ts, {
        format: validateTime ? defaultTimestampFormat : ddmmyyyy,
      }),
    "timestamp"
  );
}
