import {
  columns2,
  form,
  page,
  pointField,
  required,
  section,
  select,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { SasWaterQualitySiteTypeEnum } from "@cartographerio/inventory-enums";

export default form({
  title: "Coliforms Site",
  surveyorLabel: "Creator",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            columns2(
              vstack(
                textField({
                  label: "River Name",
                  path: ["data", "river"],
                  required: required("info", "You should enter a river name"),
                }),
                textField({
                  label: "Site Name",
                  path: ["data", "site"],
                  required: required("info", "You should enter a site name"),
                }),
                timestampField({
                  label: "Date and Time Established",
                  path: ["data", "established"],
                  help: "Date and time the site was established.",
                  required: required("info"),
                  defaultValue: "now",
                })
              ),
              pointField({
                label: "Location",
                path: ["data", "location"],
                required: required("info"),
                help: "Location of the monitoring site.",
              })
            ),
            columns2(
              vstack(
                select({
                  label: "Type of site",
                  path: ["data", "siteType"],
                  options: SasWaterQualitySiteTypeEnum.entries,
                  fullWidth: true,
                })
              )
            ),
          ],
        }),
      ],
    }),
  ],
});
