// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessFernSpecies = "LadyFern" | "OakFern";

const LadyFern: NeRiverNaturalnessFernSpecies = "LadyFern";
const OakFern: NeRiverNaturalnessFernSpecies = "OakFern";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessFernSpeciesEnum = {
  ...createEnum("NeRiverNaturalnessFernSpecies", [
    [LadyFern, "Lady fern (Athyrium filix-femina)"],
    [OakFern, "Oak fern (Gymnocarpium dryopteris)"],
  ]),
  LadyFern,
  OakFern,
};

export default NeRiverNaturalnessFernSpeciesEnum;
