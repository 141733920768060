import {
  AnonymisedUser,
  ProjectRoleNameEnum,
  Project,
  TeamRoleNameEnum,
  WorkspaceRoleNameEnum,
} from "@cartographerio/types";
import { UserWorkspaceGraph } from "@cartographerio/workspace-graph";
import { chain } from "lodash";

interface CalcPossibleSurveyorsProps {
  project: Project;
  workspaceGraph: UserWorkspaceGraph;
}

/**
 * Builds a list of all AnonymisedUsers who could
 * possibly be selected as a surveyor in `project`.
 */
export function calcPossibleSurveyorUsers(
  props: CalcPossibleSurveyorsProps
): AnonymisedUser[] {
  const { project, workspaceGraph } = props;

  return chain([
    // Workspace admins:
    ...workspaceGraph.findUsersByWorkspaceId(
      project.workspaceId,
      WorkspaceRoleNameEnum.Admin
    ),
    // Project surveyors:
    ...workspaceGraph.findUsersByProjectId(
      project.id,
      ProjectRoleNameEnum.Surveyor
    ),
    // Related team surveyors:
    ...project.teamIds.flatMap(id =>
      workspaceGraph.findUsersByTeamId(id, TeamRoleNameEnum.Surveyor)
    ),
  ])
    .uniqBy(({ id }) => id)
    .sortBy(({ screenName }) => screenName)
    .value();
}
