import lodash from "lodash";
import { rangeBucket, RangeBucket, standardBuckets } from "../bucket";
import { marker, MarkerStyle } from "../marker";
import { numberFormatter } from "./helpers";
import { NumberAttribute, PropertyFormatter } from "./type";

// "Threshold attributes" aren't actually a thing.
// They're just a set of constructors for building range attributes.
// This file contains the code to translate from one format to the other.

export interface ThresholdBucket {
  label?: string;
  value: number | null;
  marker: MarkerStyle;
}

export interface ThresholdProps {
  label: string;
  attributeId: string;
  propertyName?: string;
  decimalPlaces?: number;
  format?: PropertyFormatter;
  unit?: string;
  buckets: Array<ThresholdBucket>;
  showDistribution?: boolean;
  showTimeline?: boolean;
}

export function thresholdBucket(
  value: number | null,
  marker: MarkerStyle,
  label?: string
): ThresholdBucket {
  return { value, marker, label };
}

export function thresholdAttr(props: ThresholdProps): NumberAttribute {
  const {
    attributeId,
    label,
    propertyName = attributeId,
    decimalPlaces,
    format = numberFormatter(decimalPlaces),
    unit,
    buckets,
    showDistribution = true,
    showTimeline = true,
  } = props;

  function convertBuckets(
    thresholds: ThresholdBucket[]
  ): RangeBucket<number | null>[] {
    if (thresholds.length === 0) {
      return [
        rangeBucket<number | null>(
          null,
          null,
          marker.standard,
          undefined,
          false
        ),
      ];
    } else {
      const pairs = lodash.zip(
        thresholds,
        lodash.tail(thresholds as Array<ThresholdBucket | null>).concat([null])
      );

      return pairs.map(([prev, next]) =>
        rangeBucket(
          prev?.value ?? null,
          next?.value ?? null,
          prev?.marker ?? marker.standard,
          prev?.label,
          false
        )
      );
    }
  }

  return {
    type: "NumberAttribute",
    attributeId,
    label,
    propertyName,
    format,
    unit,
    buckets: standardBuckets(convertBuckets(buckets)),
    showDistribution,
    showTimeline,
  };
}
