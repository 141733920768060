// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankArea =
  | "ZeroToOne"
  | "OneToFive"
  | "FiveToTen"
  | "OverTen";

const ZeroToOne: NwcSeedbankArea = "ZeroToOne";
const OneToFive: NwcSeedbankArea = "OneToFive";
const FiveToTen: NwcSeedbankArea = "FiveToTen";
const OverTen: NwcSeedbankArea = "OverTen";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankAreaEnum = {
  ...createEnum("NwcSeedbankArea", [
    [ZeroToOne, "0-1 m2"],
    [OneToFive, "1-5 m2"],
    [FiveToTen, "5-10 m2"],
    [OverTen, ">10 m2"],
  ]),
  ZeroToOne,
  OneToFive,
  FiveToTen,
  OverTen,
};

export default NwcSeedbankAreaEnum;
