import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  SearchResults,
  Subscription,
  SubscriptionUpdate,
  WorkspaceRef,
  isSearchResults,
  isSubscription,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";

const basePath = "/billing/subscription/v1";

export function search(apiParams: ApiParams): IO<SearchResults<Subscription>> {
  const url = { path: basePath };
  return fetch
    .get({ apiParams, url })
    .flatMap(
      contentAs("SearchResults<Subscription>", isSearchResults(isSubscription))
    );
}

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<Subscription> {
  const url = { path: `${basePath}/${workspaceRef}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(contentAs("Subscription", isSubscription));
}

export function readOption(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<Option<Subscription>> {
  const url = { path: `${basePath}/${workspaceRef}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(optionalContentAs("Subscription", isSubscription));
}

export function update(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  update: SubscriptionUpdate
): IO<void> {
  const url = { path: `${basePath}/${workspaceRef}` };
  return fetch.put({ apiParams, url, body: update }).void();
}
