// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessMammalSpecies = "Otter" | "WaterVole";

const Otter: NeRiverNaturalnessMammalSpecies = "Otter";
const WaterVole: NeRiverNaturalnessMammalSpecies = "WaterVole";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessMammalSpeciesEnum = {
  ...createEnum("NeRiverNaturalnessMammalSpecies", [
    [Otter, "Otter (Lutra lutra)"],
    [WaterVole, "Water vole (Arvicola terrestris)"],
  ]),
  Otter,
  WaterVole,
};

export default NeRiverNaturalnessMammalSpeciesEnum;
