import { checkExhausted } from "@cartographerio/util";
import {
  GlobalRoleName,
  ProjectRoleName,
  Role,
  TeamRoleName,
  WorkspaceRoleName,
} from "../types.generated";
import { parseRole } from "./parse";

type RoleComparator = (x: Role, y: Role) => boolean | undefined;

type LevelComparator = (x: number, y: number) => boolean;

/** Compare roles that operate on the same resource.
 * Return `undefined` if they have different types/associated resources.
 * */
function compareRoles(func: LevelComparator): RoleComparator {
  return (x, y) => {
    const [type1, name1, param1] = parseRole(x);
    const [type2, name2, param2] = parseRole(y);

    switch (type1) {
      case "G":
        return type2 === "G"
          ? func(globalRoleLevel(name1), globalRoleLevel(name2))
          : undefined;

      case "W":
        return type2 === "W" && param1 === param2
          ? func(workspaceRoleLevel(name1), workspaceRoleLevel(name2))
          : undefined;

      case "P":
        return type2 === "P" && param1 === param2
          ? func(projectRoleLevel(name1), projectRoleLevel(name2))
          : undefined;

      case "T":
        return type2 === "T" && param1 === param2
          ? func(teamRoleLevel(name1), teamRoleLevel(name2))
          : undefined;

      default:
        return checkExhausted(type1);
    }
  };
}

export const rolesAlike = compareRoles(() => true);
export const roleGt = compareRoles((x, y) => x > y);
export const roleGte = compareRoles((x, y) => x >= y);
export const roleLte = compareRoles((x, y) => x <= y);
export const roleLt = compareRoles((x, y) => x < y);

type OnAddRoleConflict = "replace" | "keepBest";

/** Add `role` to `roles`. */
export function addRole(
  roles: Role[],
  role: Role,
  onConflict: OnAddRoleConflict
): Role[] {
  switch (onConflict) {
    case "replace":
      return removeRole(roles, role).concat([role]);
    case "keepBest":
      return uniqRoles([...roles, role]);
    default:
      return checkExhausted(onConflict);
  }
}

/** Remove any roles like `role` from `roles`. */
export function removeRole(
  roles: Role[],
  role: Role,
  alike: RoleComparator = rolesAlike
): Role[] {
  return roles.filter(r => alike(r, role) !== true);
}

/** Remove any duplicate roles (as defined by `rolesAlike`). */
export function uniqRoles(roles: Role[]): Role[] {
  const ans: Role[] = [];

  for (let i = 0; i < roles.length; i++) {
    const ri = roles[i];
    let foundBetter = false;

    for (let j = 0; j < roles.length; j++) {
      const rj = roles[j];

      if (i !== j && roleGt(rj, ri)) {
        foundBetter = true;
        break;
      }
    }

    if (!foundBetter) {
      ans.push(ri);
    }
  }

  return ans;
}

export function globalRoleLevel(name: GlobalRoleName): number {
  switch (name) {
    case "MapViewer":
      return 0;
    case "Admin":
      return 1;
    case "Superuser":
      return 2;
    default:
      return checkExhausted(name);
  }
}

export function workspaceRoleLevel(name: WorkspaceRoleName): number {
  switch (name) {
    case "Disabled":
      return 0;
    case "Active":
      return 1;
    case "Admin":
      return 2;
    case "Owner":
      return 3;
    default:
      return checkExhausted(name);
  }
}

export function projectRoleLevel(name: ProjectRoleName): number {
  switch (name) {
    case "Member":
      return 0;
    case "Surveyor":
      return 1;
    case "Approver":
      return 2;
    case "Coordinator":
      return 3;
    default:
      return checkExhausted(name);
  }
}

export function teamRoleLevel(name: TeamRoleName): number {
  switch (name) {
    case "Member":
      return 0;
    case "Surveyor":
      return 1;
    case "Approver":
      return 2;
    case "Coordinator":
      return 3;
    default:
      return checkExhausted(name);
  }
}
