// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { ProjectVisibility } from "../types.generated";

const Private: ProjectVisibility = "Private";
const Workspace: ProjectVisibility = "Workspace";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProjectVisibilityEnum = {
  ...createEnum("ProjectVisibility", [
    [Private, "Private"],
    [Workspace, "Workspace"],
  ]),
  Private,
  Workspace,
};

export default ProjectVisibilityEnum;
