// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPondCountPresence = "E" | "N" | "A" | "C";

const Existing: FhtPondCountPresence = "E";
const New: FhtPondCountPresence = "N";
const Absent: FhtPondCountPresence = "A";
const CouldNotAccess: FhtPondCountPresence = "C";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPondCountPresenceEnum = {
  ...createEnum("FhtPondCountPresence", [
    [Existing, "Existing pond"],
    [New, "New pond"],
    [Absent, "Absent pond"],
    [CouldNotAccess, "Could not access"],
  ]),
  Existing,
  New,
  Absent,
  CouldNotAccess,
};

export default FhtPondCountPresenceEnum;
