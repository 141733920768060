import { Env } from "@cartographerio/topo-core";
import {
  topoExpr,
  TopoExpr,
  attachmentField,
  checkbox,
  columns2,
  dynamicValue,
  enumSelectOptions,
  form,
  multiSelect,
  numberField,
  otherSpecify,
  page,
  pointField,
  required,
  requiredIfV2,
  section,
  select,
  SelectOption,
  selectOption,
  subsection,
  subsubsection,
  text,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { Option } from "@cartographerio/fp";
import { isArray, isBoolean } from "@cartographerio/guard";
import {
  MrsHorizontalOrientationEnum,
  MrsRiverWoodArtificialElementEnum,
  MrsRiverWoodHydraulicTypeEnum,
  MrsRiverWoodJamType,
  MrsRiverWoodJamTypeEnum,
  MrsRiverWoodRetainedByArtificialFeatureEnum,
  MrsRiverWoodRetainedByNaturalFeatureEnum,
  MrsUadEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
import { narrowOtherSpecify } from "../ArrtWaterQuality/form";

function flowChartOptions(
  yesLabel: string,
  noLabel: string
): SelectOption<boolean>[] {
  return [
    selectOption(true, `Yes - ${yesLabel}`),
    selectOption(false, `No - ${noLabel}`),
  ];
}

const flowChartYN = (env: Env, question: string) =>
  env
    .getAbsoluteAs(["data", "jamDetails", question], isBoolean)
    .map(b => (b ? "y" : "n"))
    .getOrNull();

const jamType: TopoExpr<MrsRiverWoodJamType | null> = topoExpr(env =>
  env
    .getAbsoluteAs(
      ["data", "jamDetails", "jamType"],
      MrsRiverWoodJamTypeEnum.isValue
    )
    .getOrNull()
);

const isolatedTreeVisible: TopoExpr<boolean> = topoExpr(
  (_env, run) => run(jamType) === MrsRiverWoodJamTypeEnum.Tree
);

const q1Visible: TopoExpr<boolean> = topoExpr(
  (_env, run) => run(jamType) === MrsRiverWoodJamTypeEnum.Tree
);

const q2Visible: TopoExpr<boolean> = topoExpr(
  (env, run) =>
    run(q1Visible) && flowChartYN(env, "isolatedTreeLeaningTree") === "y"
);

const q3Visible: TopoExpr<boolean> = topoExpr(
  (env, run) =>
    run(q2Visible) && flowChartYN(env, "isolatedTreeFallenTree") === "y"
);

const woodAccumulationVisible: TopoExpr<boolean> = topoExpr(
  (_env, run) => run(jamType) === MrsRiverWoodJamTypeEnum.Wood || run(q5Visible)
);

const q4Visible: TopoExpr<boolean> = topoExpr(
  (_env, run) => run(jamType) === MrsRiverWoodJamTypeEnum.Wood
);

const q5Visible: TopoExpr<boolean> = topoExpr(
  (env, run) =>
    (run(q3Visible) && flowChartYN(env, "isolatedTreeHighJam") === "n") ||
    (run(q4Visible) && flowChartYN(env, "woodAccumulationHighJam") === "n")
);

const q6Visible: TopoExpr<boolean> = topoExpr(
  (env, run) =>
    run(q5Visible) && flowChartYN(env, "woodAccumulationPartialJam") === "y"
);

const calculateHydraulicType: TopoExpr<string | null> = topoExpr((env, run) => {
  function test<K extends string | number | symbol, R>(
    test: K | null,
    branches: { [S in K]: () => R | null }
  ): R | null {
    return test == null ? null : branches[test]();
  }

  const calcQ5Type = () =>
    test(flowChartYN(env, "woodAccumulationPartialJam"), {
      n: () => MrsRiverWoodHydraulicTypeEnum.PartialJam,
      y: () =>
        test(flowChartYN(env, "woodAccumulationCompleteJam"), {
          n: () => MrsRiverWoodHydraulicTypeEnum.CompleteJam,
          y: () => MrsRiverWoodHydraulicTypeEnum.ActiveJam,
        }),
    });

  const hydraulicType = test(run(jamType), {
    [MrsRiverWoodJamTypeEnum.Tree]: () =>
      test(flowChartYN(env, "isolatedTreeLeaningTree"), {
        n: () => MrsRiverWoodHydraulicTypeEnum.LeaningTree,
        y: () =>
          test(flowChartYN(env, "isolatedTreeFallenTree"), {
            n: () => MrsRiverWoodHydraulicTypeEnum.FallenTree,
            y: () =>
              test(flowChartYN(env, "isolatedTreeHighJam"), {
                y: () => MrsRiverWoodHydraulicTypeEnum.HighJam,
                n: calcQ5Type,
              }),
          }),
      }),
    [MrsRiverWoodJamTypeEnum.Wood]: () =>
      test(flowChartYN(env, "woodAccumulationHighJam"), {
        y: () => MrsRiverWoodHydraulicTypeEnum.HighJam,
        n: calcQ5Type,
      }),
  });

  return Option.wrap(hydraulicType)
    .map(MrsRiverWoodHydraulicTypeEnum.labelOf)
    .getOrNull();
});

export default form({
  title: "RiverWood",
  pages: [
    page({
      title: "Page 1",
      path: ["data"],
      blocks: [
        section({
          title: "General Information",
          path: ["generalInformation"],
          blocks: [
            columns2(
              vstack(
                timestampField({
                  label: "Survey Date/Time",
                  path: ["recorded"],
                  required: required(),
                  defaultValue: "now",
                  help: outdent`
                If you are unsure of the exact date,
                choose the first day of the relevant month e.g. 01/05/2023.
                If you are unsure of the time, you can change this to 12:00.
              `,
                }),
                checkbox({
                  label: "Bed Visible?",
                  checkboxLabel: "Is the bed fully or partially visible?",
                  path: ["bedVisible"],
                  defaultValue: false,
                }),
                checkbox({
                  label: "Adverse Conditions?",
                  checkboxLabel:
                    "Are there adverse conditions at the time of the survey?",
                  path: ["adverseConditions"],
                  defaultValue: false,
                  help: outdent`
                  Examples of adverse conditions include: limited access, high river flows,
                  impenetrable riparian vegetation, and poor light conditions.
                  `,
                }),
                otherSpecify({
                  label: "Adverse Conditions Notes",
                  thatPath: ["adverseConditions"],
                  thisPath: ["adverseConditionsNotes"],
                  rows: 3,
                  level: "error",
                  test: other => other === true,
                  visible: "auto",
                  help: "Describe the adverse conditions.",
                }),
                textField({
                  label: "Project Name",
                  path: ["projectName"],
                  help: "Name of the restoration project being monitored (if applicable).",
                }),
                textField({
                  label: "River Name",
                  path: ["riverName"],
                  help: outdent`
                    Name of the river or stream (e.g. as shown on 1:50,000 scale maps).

                    Unnamed tributaries should be named with reference to a main watercourse
                    (e.g. "Tributary of River Brent").
                  `,
                }),
                textField({
                  label: "Reach Name",
                  path: ["reachName"],
                  help: "Name of the reach in which the jam is located.",
                }),
                textField({
                  label: "Wood Jam Number/ID",
                  path: ["jamNumber"],
                  help: "Reference code/number for the jam.",
                })
              )
            ),
          ],
        }),
        section({
          title: "River Wood Location, Naturalness, and Type",
          path: ["jamDetails"],
          blocks: [
            columns2(
              vstack(
                select({
                  label: "Type of Wood Jam",
                  help: "e.g. leaning tree, fallen tree, partial jam, complete jam, active jam, high jam",
                  path: ["jamType"],
                  options: enumSelectOptions(MrsRiverWoodJamTypeEnum),
                  required: required(),
                })
              )
            ),
            pointField({
              label: "Wood Jam Location",
              path: ["location"],
              help: "Location of the centre of the jam.",
            }),
            attachmentField({
              label: "Photographs",
              path: ["photographs"],
              maxFiles: 3,
              help: outdent`
                    Upload 3 photos as follows:

                    - View across the channel at the jam site
                    - The jam viewed from downstream
                    - The jam viewed from upstream
                  `,
            }),
            columns2(
              vstack(
                checkbox({
                  label: "Cut By Beavers?",
                  checkboxLabel: "Was the jam cut by beavers?",
                  help: "i.e. the ends of wood pieces should show beaver tooth marks",
                  path: ["cutByBeavers"],
                  defaultValue: false,
                }),
                checkbox({
                  label: "Other Evidence of Beavers",
                  checkboxLabel:
                    "Is there any other evidence of the presence of beavers?",
                  path: ["presenceOfBeavers"],
                  defaultValue: false,
                }),
                multiSelect({
                  label: "Artificial Elements",
                  help: "Are any supporting the jam structure?",
                  path: ["artificialElements", "selected"],
                  options: enumSelectOptions(MrsRiverWoodArtificialElementEnum),
                  appearance: "checkboxgroup",
                }),
                otherSpecify({
                  label: "Other Artificial Elements",
                  basePath: ["artificialElements"],
                  rows: 3,
                  level: "error",
                  test: value =>
                    isArray(value) &&
                    value.includes(MrsRiverWoodArtificialElementEnum.Other),
                  visible: "auto",
                  help: "If you have selected 'other', please describe.",
                })
              )
            ),
            subsubsection({
              title: "Individual Isolated Tree",
              help: "> 2m tall and > 10cm diameter, leaning over part of the bankfull channel",
              path: [],
              visible: isolatedTreeVisible,
              blocks: [
                select({
                  label:
                    "Q1: Is the isolated tree leaning at <30° above the horizontal?",
                  path: ["isolatedTreeLeaningTree"],
                  options: flowChartOptions(
                    MrsRiverWoodHydraulicTypeEnum.labelOf("LT"),
                    "Go to Q2"
                  ),
                  visible: q1Visible,
                  customRules: [
                    requiredIfV2({
                      message: "You must specify a value.",
                      otherTest: q1Visible,
                    }),
                  ],
                }),
                select({
                  label:
                    "Q2: Is any part of the trunk of the tree located within the bankfull channel?",
                  path: ["isolatedTreeFallenTree"],
                  options: flowChartOptions(
                    MrsRiverWoodHydraulicTypeEnum.labelOf("FT"),
                    "Go to Q3"
                  ),
                  visible: q2Visible,
                  customRules: [
                    requiredIfV2({
                      message: "You must specify a value.",
                      otherTest: q2Visible,
                    }),
                  ],
                }),
                select({
                  label:
                    "Q3: Is the tree trunk suspended above the stream bed so that water can flow freely beneath it during low stream flows?",
                  path: ["isolatedTreeHighJam"],
                  options: flowChartOptions(
                    "Go to Q5",
                    MrsRiverWoodHydraulicTypeEnum.labelOf("HJ")
                  ),
                  visible: q3Visible,
                  customRules: [
                    requiredIfV2({
                      message: "You must specify a value.",
                      otherTest: q3Visible,
                    }),
                  ],
                }),
              ],
            }),
            subsubsection({
              title: "Wood Accumulation",
              help: "At least two pieces of wood > 1m long and 10cm diameter (including complete trees) located at least partly within the bankfull channel",
              path: [],
              visible: woodAccumulationVisible,
              blocks: [
                select({
                  label:
                    "Q4: Is the wood suspended above the stream bed so that water can flow freely beneath it during low stream flows?",
                  path: ["woodAccumulationHighJam"],
                  options: flowChartOptions(
                    "Go to Q5",
                    MrsRiverWoodHydraulicTypeEnum.labelOf("HJ")
                  ),
                  visible: q4Visible,
                  customRules: [
                    requiredIfV2({
                      message: "You must specify a value.",
                      otherTest: q4Visible,
                    }),
                  ],
                }),
                select({
                  label:
                    "Q5: Is the wood in contact with the channel bed across the entire width of the stream bed?",
                  path: ["woodAccumulationPartialJam"],
                  options: flowChartOptions(
                    MrsRiverWoodHydraulicTypeEnum.labelOf("PJ"),
                    "Go to Q6"
                  ),
                  visible: q5Visible,
                  customRules: [
                    requiredIfV2({
                      message: "You must specify a value.",
                      otherTest: q5Visible,
                    }),
                  ],
                }),
                select({
                  label:
                    "Q6: Is there a clear drop in the water surface level from upstream to downstream of the wood accumulation?",
                  path: ["woodAccumulationCompleteJam"],
                  options: flowChartOptions(
                    MrsRiverWoodHydraulicTypeEnum.labelOf("CJ"),
                    MrsRiverWoodHydraulicTypeEnum.labelOf("AJ")
                  ),
                  visible: q6Visible,
                  customRules: [
                    requiredIfV2({
                      message: "You must specify a value.",
                      otherTest: q6Visible,
                    }),
                  ],
                }),
              ],
            }),
            columns2(
              vstack(
                dynamicValue({
                  label: "Calculated Hydraulic Type",
                  value: calculateHydraulicType,
                  valueType: "string",
                }),
                select({
                  label: "Hydraulic Type Override",
                  path: ["hydraulicTypeOverride"],
                  options: enumSelectOptions(MrsRiverWoodHydraulicTypeEnum),
                  placeholder: "No override",
                })
              )
            ),
          ],
        }),
        section({
          title: "River Channel Dimensions",
          path: ["riverChannelDimensions"],
          blocks: [
            pointField({
              label: "River Location",
              path: ["riverLocation"],
            }),
            columns2(
              vstack(
                numberField({
                  label: "MoRPh River Width",
                  help: "Nearest 0.1m",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["morphRiverWidth"],
                }),
                numberField({
                  label: "Left Bank Height",
                  help: "Nearest 0.1m",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["leftBankHeight"],
                }),
                numberField({
                  label: "Right Bank Height",
                  help: "Nearest 0.1m",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["rightBankHeight"],
                }),
                numberField({
                  label: "Bankfull Width",
                  help: "Nearest 0.1m",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["bankfullWidth"],
                }),
                numberField({
                  label: "Water Width",
                  help: "Nearest 0.1m",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["waterWidth"],
                }),
                numberField({
                  label: "Water Depth",
                  help: "Nearest 0.1m",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["waterDepth"],
                })
              ),
              text({
                markdown: outdent`
                  ![Channel Dimensions](https://media.cartographer.io/static/images/mrs-morph/channel-dimensions.png)

                  The figure above illustrates the dimensions as seen looking downstream
                `,
              })
            ),
          ],
        }),
        section({
          title: "Jam Anchorage and Adjacent Physical Features",
          path: ["jamAnchorage"],
          blocks: [
            subsection({
              title: "Anchorage",
              help: "Record the main feature retaining the wood",
              path: [],
              blocks: [
                multiSelect({
                  label: "Wood Retained by an Artificial Feature?",
                  help: "Record as many as appropriate",
                  path: ["artificialFeatures", "selected"],
                  options: enumSelectOptions(
                    MrsRiverWoodRetainedByArtificialFeatureEnum
                  ),
                  appearance: "checkboxgroup",
                }),
                narrowOtherSpecify({
                  label: "Other Artificial Features",
                  basePath: ["artificialFeatures"],
                  level: "error",
                  test: value =>
                    isArray(value) &&
                    value.includes(MrsRiverWoodArtificialElementEnum.Other),
                  visible: "auto",
                  help: "If you have selected 'other', please describe.",
                }),
                multiSelect({
                  label: "Wood Retained by a Natural Feature?",
                  help: "Record as many as appropriate",
                  path: ["naturalFeatures"],
                  options: enumSelectOptions(
                    MrsRiverWoodRetainedByNaturalFeatureEnum
                  ),
                  appearance: "checkboxgroup",
                }),
              ],
            }),
            subsection({
              title: "Adjacent Physical Features",
              help: "Within 1 MoRPH river width upstream/downstream of the WOOD jam",
              path: [],
              blocks: [
                columns2(
                  vstack(
                    select({
                      label: "Pool",
                      path: ["adjacentPool"],
                      options: enumSelectOptions(MrsUadEnum),
                    }),
                    select({
                      label: "Riffle",
                      path: ["adjacentRiffle"],
                      options: enumSelectOptions(MrsUadEnum),
                    }),
                    select({
                      label: "Unvegetated Bar",
                      path: ["adjacentUnvegetatedBar"],
                      options: enumSelectOptions(MrsUadEnum),
                    }),
                    select({
                      label: "Vegetated Bar",
                      path: ["adjacentVegetatedBar"],
                      options: enumSelectOptions(MrsUadEnum),
                    }),
                    select({
                      label: "Berm / Bench",
                      path: ["adjacentBench"],
                      options: enumSelectOptions(MrsUadEnum),
                    }),
                    select({
                      label: "Island",
                      path: ["adjacentIsland"],
                      options: enumSelectOptions(MrsUadEnum),
                    }),
                    select({
                      label: "Eroding Bank Face",
                      path: ["adjacentErodingBankFace"],
                      options: enumSelectOptions(MrsUadEnum),
                    }),
                    select({
                      label: "Undercut Bank Face",
                      path: ["adjacentUndercutBankFace"],
                      options: enumSelectOptions(MrsUadEnum),
                    }),
                    select({
                      label: "Side Channel",
                      path: ["adjacentSideChannel"],
                      options: enumSelectOptions(MrsUadEnum),
                    })
                  )
                ),
              ],
            }),
          ],
        }),
        section({
          title: "Jam Dimensions",
          help: "Measurements to the nearest 0.1m",
          path: ["jamDimensions"],
          blocks: [
            columns2(
              vstack(
                numberField({
                  label: "MoRPH river width",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["morphRiverWidth"],
                }),
                numberField({
                  label: "Width of Jam Across Channel",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["acrossChannelWidth"],
                }),
                numberField({
                  label: "Length of Jam Upstream-Downstream",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["jamLength"],
                }),
                numberField({
                  label: "Maximum Height of Jam",
                  help: "Maximum height above channel bed",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["jamMaxHeightAboveChannelBed"],
                }),
                numberField({
                  label: "Height of Bottom of Jam",
                  help: "Maximum height above channel bed",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["bottomOfJamHeightAboveChannelBed"],
                }),
                select({
                  label: "Horizontal Orientation of Jam",
                  path: ["horizontalOrientationOfJam"],
                  help: "With respect to channel banks",
                  options: enumSelectOptions(MrsHorizontalOrientationEnum),
                }),
                numberField({
                  label: "Length of Largest Wood Piece",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["largestWoodPieceLength"],
                }),
                numberField({
                  label: "Diameter of Largest Wood Piece",
                  units: "m",
                  decimalPlaces: 1,
                  path: ["largestWoodPieceDiameter"],
                })
              )
            ),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["notes"],
              rows: 5,
            }),
          ],
        }),
      ],
    }),
  ],
});
