// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusRecreationalFacility =
  | "Se"
  | "Cp"
  | "Do"
  | "Pc"
  | "Sf"
  | "Te"
  | "Ru"
  | "Sp"
  | "Gy"
  | "Sw"
  | "Bo"
  | "Fi"
  | "Wi"
  | "Np"
  | "Na"
  | "Cf"
  | "Ot";

const PublicSeating: EcostatusRecreationalFacility = "Se";
const ChildrensPlayground: EcostatusRecreationalFacility = "Cp";
const DogExerciseArea: EcostatusRecreationalFacility = "Do";
const PicnicArea: EcostatusRecreationalFacility = "Pc";
const SportsFields: EcostatusRecreationalFacility = "Sf";
const TennisCourts: EcostatusRecreationalFacility = "Te";
const RunningOrJoggingTrack: EcostatusRecreationalFacility = "Ru";
const SkatePark: EcostatusRecreationalFacility = "Sp";
const OutdoorGym: EcostatusRecreationalFacility = "Gy";
const SwimmingOrPaddlingProvision: EcostatusRecreationalFacility = "Sw";
const BoatingProvision: EcostatusRecreationalFacility = "Bo";
const FishingProvision: EcostatusRecreationalFacility = "Fi";
const WildlifeAreas: EcostatusRecreationalFacility = "Wi";
const NaturalPlayAreas: EcostatusRecreationalFacility = "Np";
const NaturalArt: EcostatusRecreationalFacility = "Na";
const CafeOrRestaurant: EcostatusRecreationalFacility = "Cf";
const Other: EcostatusRecreationalFacility = "Ot";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusRecreationalFacilityEnum = {
  ...createEnum("EcostatusRecreationalFacility", [
    [PublicSeating, "Public seating"],
    [ChildrensPlayground, "Childrens' playground"],
    [DogExerciseArea, "Dog exercise area"],
    [PicnicArea, "Picnic area"],
    [SportsFields, "Sports fields"],
    [TennisCourts, "Tennis courts"],
    [RunningOrJoggingTrack, "Running / jogging / cycle track"],
    [SkatePark, "Skate park"],
    [OutdoorGym, "Outdoor gym"],
    [SwimmingOrPaddlingProvision, "Swimming / paddling provision"],
    [BoatingProvision, "Boating provision"],
    [FishingProvision, "Fishing provision"],
    [WildlifeAreas, "Wildlife areas"],
    [NaturalPlayAreas, "Natural play areas"],
    [NaturalArt, "Natural art"],
    [CafeOrRestaurant, "Cafe / restaurant"],
    [Other, "Other"],
  ]),
  PublicSeating,
  ChildrensPlayground,
  DogExerciseArea,
  PicnicArea,
  SportsFields,
  TennisCourts,
  RunningOrJoggingTrack,
  SkatePark,
  OutdoorGym,
  SwimmingOrPaddlingProvision,
  BoatingProvision,
  FishingProvision,
  WildlifeAreas,
  NaturalPlayAreas,
  NaturalArt,
  CafeOrRestaurant,
  Other,
};

export default EcostatusRecreationalFacilityEnum;
