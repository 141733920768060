import {
  Icon,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ReactElement, RefObject } from "react";
import { BsInfoCircle } from "react-icons/bs";

import Markdown from "./Markdown";

export interface HelpPopoverProps extends Partial<IconButtonProps> {
  size?: "sm" | "md";
  text: string;
  portalContainerRef?: RefObject<HTMLElement>;
}

export default function HelpPopover(props: HelpPopoverProps): ReactElement {
  const {
    size = "md",
    text,
    portalContainerRef: containerRef,
    ...rest
  } = props;

  return (
    <Popover placement="bottom" autoFocus={false} trigger="hover">
      <PopoverTrigger>
        <IconButton
          variant="helpPopover"
          aria-label="info"
          lineHeight="0"
          icon={<Icon as={BsInfoCircle} />}
          color="blackAlpha.600"
          size={size}
          alignSelf="center"
          w="auto"
          h="auto"
          minW="auto"
          minH="auto"
          p="0"
          ml="2"
          tabIndex={-1}
          {...rest}
        />
      </PopoverTrigger>
      <Portal containerRef={containerRef}>
        <PopoverContent zIndex="popover">
          <PopoverArrow />
          <PopoverBody pr="8" fontSize="xs" color="gray.500" zIndex="inherit">
            <Markdown text={text} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
