// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { DataLicense } from "../types.generated";

const Default: DataLicense = "Default";
const CcBy4: DataLicense = "CcBy4";
const CcByNd4: DataLicense = "CcByNd4";
const CcBySa4: DataLicense = "CcBySa4";
const CcByNc4: DataLicense = "CcByNc4";
const CcByNcNd4: DataLicense = "CcByNcNd4";
const CcByNcSa4: DataLicense = "CcByNcSa4";
const Ogl3: DataLicense = "Ogl3";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DataLicenseEnum = {
  ...createEnum("DataLicense", [
    [Default, "Default"],
    [CcBy4, "CC BY 4.0"],
    [CcByNd4, "CC BY-ND 4.0"],
    [CcBySa4, "CC BY-SA 4.0"],
    [CcByNc4, "CC BY-NC 4.0"],
    [CcByNcNd4, "CC BY-NC-ND 4.0"],
    [CcByNcSa4, "CC BY-NC-SA 4.0"],
    [Ogl3, "OGL 3.0"],
  ]),
  Default,
  CcBy4,
  CcByNd4,
  CcBySa4,
  CcByNc4,
  CcByNcNd4,
  CcByNcSa4,
  Ogl3,
};

export default DataLicenseEnum;
