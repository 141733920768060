import {
  Box,
  Popover as ChakraPopover,
  PlacementWithLogical,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SystemProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";

interface PopoverProps {
  enabled?: boolean;
  portal?: boolean;
  placement?: PlacementWithLogical;
  zIndex?: SystemProps["zIndex"];
  children: ReactNode;
  body: ReactNode;
}

export default function Popover(props: PopoverProps): ReactNode {
  const { enabled, portal, placement, zIndex, children, body } = props;

  return enabled ? (
    <ChakraPopover autoFocus={false} trigger="hover" placement={placement}>
      <PopoverTrigger>
        <Box>{children}</Box>
      </PopoverTrigger>
      {portal ? (
        <Portal>
          <PopoverContent zIndex={zIndex}>
            <PopoverArrow />
            <PopoverBody>{body}</PopoverBody>
          </PopoverContent>
        </Portal>
      ) : (
        <PopoverContent zIndex={zIndex}>
          <PopoverArrow />
          <PopoverBody>{body}</PopoverBody>
        </PopoverContent>
      )}
    </ChakraPopover>
  ) : (
    children
  );
}
