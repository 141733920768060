export function filterAndMap<I, O>(
  arr: I[],
  mapper: (val: I) => O | null | undefined
): O[] {
  const output: O[] = [];

  for (const val of arr) {
    const item = mapper(val);

    if (item != null) {
      output.push(item);
    }
  }
  return output;
}
