// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesCondition = "G" | "M" | "P";

const Good: CesCondition = "G";
const Moderate: CesCondition = "M";
const Poor: CesCondition = "P";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesConditionEnum = {
  ...createEnum("CesCondition", [
    [Good, "Good"],
    [Moderate, "Moderate"],
    [Poor, "Poor"],
  ]),
  Good,
  Moderate,
  Poor,
};

export default CesConditionEnum;
