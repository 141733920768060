// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsChannelSubstrate =
  | "NV"
  | "AR"
  | "BE"
  | "BO"
  | "CO"
  | "GP"
  | "SA"
  | "SI"
  | "CL"
  | "PE";

const NotVisible: UrsChannelSubstrate = "NV";
const Artificial: UrsChannelSubstrate = "AR";
const Bedrock: UrsChannelSubstrate = "BE";
const Boulder: UrsChannelSubstrate = "BO";
const Cobble: UrsChannelSubstrate = "CO";
const GravelPebble: UrsChannelSubstrate = "GP";
const Sand: UrsChannelSubstrate = "SA";
const Silt: UrsChannelSubstrate = "SI";
const Clay: UrsChannelSubstrate = "CL";
const Peat: UrsChannelSubstrate = "PE";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsChannelSubstrateEnum = {
  ...createEnum("UrsChannelSubstrate", [
    [NotVisible, "Not visible"],
    [Artificial, "Artificial"],
    [Bedrock, "Bedrock"],
    [Boulder, "Boulder"],
    [Cobble, "Cobble"],
    [GravelPebble, "Gravel / pebble"],
    [Sand, "Sand"],
    [Silt, "Silt"],
    [Clay, "Clay"],
    [Peat, "Peat"],
  ]),
  NotVisible,
  Artificial,
  Bedrock,
  Boulder,
  Cobble,
  GravelPebble,
  Sand,
  Silt,
  Clay,
  Peat,
};

export default UrsChannelSubstrateEnum;
