// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopHabitatAvailability =
  | "Good"
  | "Moderate"
  | "Poor"
  | "None";

const Good: UclPitStopHabitatAvailability = "Good";
const Moderate: UclPitStopHabitatAvailability = "Moderate";
const Poor: UclPitStopHabitatAvailability = "Poor";
const None: UclPitStopHabitatAvailability = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopHabitatAvailabilityEnum = {
  ...createEnum("UclPitStopHabitatAvailability", [
    [
      Good,
      "Good - Natural / seminatural habitat that offers good opportunities for wildlife to forage and shelter covering 75% of surrounding area.",
    ],
    [
      Moderate,
      "Moderate - Habitat offers opportunities for foraging and shelter but may not be extensive (25-75%).",
    ],
    [
      Poor,
      "Poor - Habitat with poor structure (e.g. amenity grassland, improved pasture and arable) offering limited opportunities.",
    ],
    [
      None,
      "None -  No suitable habitat around pond (e.g. centre of arable field or large expanse of bare habitat).",
    ],
  ]),
  Good,
  Moderate,
  Poor,
  None,
};

export default UclPitStopHabitatAvailabilityEnum;
