// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesWoodySpeciesCount = "1" | "2-4" | "5+";

const One: CesWoodySpeciesCount = "1";
const TwoToFour: CesWoodySpeciesCount = "2-4";
const FivePlus: CesWoodySpeciesCount = "5+";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesWoodySpeciesCountEnum = {
  ...createEnum("CesWoodySpeciesCount", [
    [One, "1"],
    [TwoToFour, "2-4"],
    [FivePlus, "5+"],
  ]),
  One,
  TwoToFour,
  FivePlus,
};

export default CesWoodySpeciesCountEnum;
