// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeChalkRiversPh = "Ph_0_7" | "Ph_7_75" | "Ph_75_8" | "Ph_8_14";

const Ph_0_7: NeChalkRiversPh = "Ph_0_7";
const Ph_7_75: NeChalkRiversPh = "Ph_7_75";
const Ph_75_8: NeChalkRiversPh = "Ph_75_8";
const Ph_8_14: NeChalkRiversPh = "Ph_8_14";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeChalkRiversPhEnum = {
  ...createEnum("NeChalkRiversPh", [
    [Ph_0_7, "<7"],
    [Ph_7_75, "7-7.5"],
    [Ph_75_8, "7.5-8"],
    [Ph_8_14, ">8"],
  ]),
  Ph_0_7,
  Ph_7_75,
  Ph_75_8,
  Ph_8_14,
};

export default NeChalkRiversPhEnum;
