// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessHydrologicalAssessmentForm =
  | "Visual"
  | "Ea"
  | "Wfd"
  | "Other";

const Visual: NeRiverNaturalnessHydrologicalAssessmentForm = "Visual";
const Ea: NeRiverNaturalnessHydrologicalAssessmentForm = "Ea";
const Wfd: NeRiverNaturalnessHydrologicalAssessmentForm = "Wfd";
const Other: NeRiverNaturalnessHydrologicalAssessmentForm = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessHydrologicalAssessmentFormEnum = {
  ...createEnum("NeRiverNaturalnessHydrologicalAssessmentForm", [
    [Visual, "Simple visual inspection"],
    [Ea, "EA abstraction information"],
    [Wfd, "Water Framework Directive data"],
    [Other, "Other (please specify)"],
  ]),
  Visual,
  Ea,
  Wfd,
  Other,
};

export default NeRiverNaturalnessHydrologicalAssessmentFormEnum;
