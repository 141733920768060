import { hasKey, isObject, isString } from "@cartographerio/guard";
import { isPath, prefixPath } from "./path";
import { Message, MessageData, MessageLevel, Path, PathLike } from "./type";

export interface ErrorMessage extends Message {
  level: "error";
}

export interface InfoMessage extends Message {
  level: "info";
}

export function isMessage(m: unknown): m is Message {
  return (
    isObject(m) &&
    hasKey(
      m,
      "level",
      (s: unknown): s is MessageLevel => s === "info" || s === "error"
    ) &&
    hasKey(m, "path", isPath) &&
    hasKey(m, "text", isString)
  );
}

export function isErrorMessage(m: unknown): m is ErrorMessage {
  return isMessage(m) && m.level === "error";
}

export function isInfoMessage(m: unknown): m is InfoMessage {
  return isMessage(m) && m.level === "info";
}

export function createMessage(
  level: MessageLevel,
  text: string,
  path: Path = [],
  data?: MessageData
): Message {
  return data === undefined
    ? { level, path, text }
    : { level, path, text, data };
}

export function errorMessage(
  text: string,
  path: Path = [],
  data?: MessageData
): Message {
  return createMessage("error", text, path, data);
}

export function infoMessage(
  text: string,
  path: Path = [],
  data?: MessageData
): Message {
  return createMessage("info", text, path, data);
}

export function prefixMessage(prefix: PathLike, message: Message): Message {
  return { ...message, path: prefixPath(prefix, message.path) };
}

export function prefixMessages(
  prefix: PathLike,
  messages: Message[]
): Message[] {
  return messages.map(message => prefixMessage(prefix, message));
}
