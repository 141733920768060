// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeNaturalnessInvasivePlantSpecies =
  | "HimalayanBalsam"
  | "GiantHogweed"
  | "JapaneseKnotweed"
  | "Other";

const HimalayanBalsam: NeNaturalnessInvasivePlantSpecies = "HimalayanBalsam";
const GiantHogweed: NeNaturalnessInvasivePlantSpecies = "GiantHogweed";
const JapaneseKnotweed: NeNaturalnessInvasivePlantSpecies = "JapaneseKnotweed";
const Other: NeNaturalnessInvasivePlantSpecies = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeNaturalnessInvasivePlantSpeciesEnum = {
  ...createEnum("NeNaturalnessInvasivePlantSpecies", [
    [HimalayanBalsam, "Himalayan Balsam"],
    [GiantHogweed, "Giant Hogweed"],
    [JapaneseKnotweed, "Japanese Knotweed"],
    [Other, "Other"],
  ]),
  HimalayanBalsam,
  GiantHogweed,
  JapaneseKnotweed,
  Other,
};

export default NeNaturalnessInvasivePlantSpeciesEnum;
