// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { KnownUserFeature } from "../types.generated";

const ChangeIdentity: KnownUserFeature = "ChangeIdentity";
const IntegrationTest: KnownUserFeature = "IntegrationTest";
const NonBillable: KnownUserFeature = "NonBillable";
const Mobile2Preview: KnownUserFeature = "Mobile2Preview";
const Mobile2Default: KnownUserFeature = "Mobile2Default";
const EnableMobileRiverfly: KnownUserFeature = "EnableMobileRiverfly";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const KnownUserFeatureEnum = {
  ...createEnum("KnownUserFeature", [
    [ChangeIdentity, "Can change identity"],
    [IntegrationTest, "Integration testing"],
    [NonBillable, "Non-billable"],
    [Mobile2Preview, "Preview mobile app v2"],
    [Mobile2Default, "Default to mobile app v2"],
    [EnableMobileRiverfly, "Enable Riverfly on mobile"],
  ]),
  ChangeIdentity,
  IntegrationTest,
  NonBillable,
  Mobile2Preview,
  Mobile2Default,
  EnableMobileRiverfly,
};

export default KnownUserFeatureEnum;
