// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type StormPhosphatesLevelTrend = "R" | "S" | "F";

const Rising: StormPhosphatesLevelTrend = "R";
const Steady: StormPhosphatesLevelTrend = "S";
const Falling: StormPhosphatesLevelTrend = "F";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StormPhosphatesLevelTrendEnum = {
  ...createEnum("StormPhosphatesLevelTrend", [
    [Rising, "Rising"],
    [Steady, "Steady"],
    [Falling, "Falling"],
  ]),
  Rising,
  Steady,
  Falling,
};

export default StormPhosphatesLevelTrendEnum;
