/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  Email,
  isEmail,
  ScreenName,
  isScreenName,
  Timestamp,
  isTimestamp,
  UserFeature,
  isUserFeature,
  UserId,
  isUserId,
} from "../core/types.generated";
import {
  QualificationRole,
  isQualificationRole,
  Role,
  isRole,
} from "../permission/types.generated";

export interface UserRef {
  userId?: UserId | null;
  screenName?: ScreenName | null;
}

export interface User {
  screenName: ScreenName;
  firstName: string;
  lastName: string;
  email: Email;
  roles: Role[];
  qualificationRoles: QualificationRole[];
  features: UserFeature[];
  created: Timestamp;
  updated: Timestamp;
  temporary?: boolean | null;
  id: UserId;
}

export interface UserUpdate {
  screenName?: ScreenName | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: Email | null;
  roles?: Role[] | null;
  qualificationRoles?: QualificationRole[] | null;
  features?: UserFeature[] | null;
}

export interface AnonymisedUser {
  id: UserId;
  screenName: ScreenName;
  roles: Role[];
  qualificationRoles: QualificationRole[];
}

export const isUserRef = (v: any): v is UserRef => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("userId" in v) || isUserId(v.userId) || v.userId === null) &&
    (!("screenName" in v) ||
      isScreenName(v.screenName) ||
      v.screenName === null)
  );
};

export const isUser = (v: any): v is User => {
  return (
    typeof v === "object" &&
    v != null &&
    "screenName" in v &&
    isScreenName(v.screenName) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "email" in v &&
    isEmail(v.email) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i)) &&
    "features" in v &&
    Array.isArray(v.features) &&
    v.features.every((i: any) => isUserFeature(i)) &&
    "created" in v &&
    isTimestamp(v.created) &&
    "updated" in v &&
    isTimestamp(v.updated) &&
    (!("temporary" in v) ||
      typeof v.temporary === "boolean" ||
      v.temporary === null) &&
    "id" in v &&
    isUserId(v.id)
  );
};

export const isUserUpdate = (v: any): v is UserUpdate => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("screenName" in v) ||
      isScreenName(v.screenName) ||
      v.screenName === null) &&
    (!("firstName" in v) ||
      typeof v.firstName === "string" ||
      v.firstName === null) &&
    (!("lastName" in v) ||
      typeof v.lastName === "string" ||
      v.lastName === null) &&
    (!("email" in v) || isEmail(v.email) || v.email === null) &&
    (!("roles" in v) ||
      (Array.isArray(v.roles) && v.roles.every((i: any) => isRole(i))) ||
      v.roles === null) &&
    (!("qualificationRoles" in v) ||
      (Array.isArray(v.qualificationRoles) &&
        v.qualificationRoles.every((i: any) => isQualificationRole(i))) ||
      v.qualificationRoles === null) &&
    (!("features" in v) ||
      (Array.isArray(v.features) &&
        v.features.every((i: any) => isUserFeature(i))) ||
      v.features === null)
  );
};

export const isAnonymisedUser = (v: any): v is AnonymisedUser => {
  return (
    typeof v === "object" &&
    v != null &&
    "id" in v &&
    isUserId(v.id) &&
    "screenName" in v &&
    isScreenName(v.screenName) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i))
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
