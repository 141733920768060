import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isMapSchemaSummary,
  isSearchResults,
  MapId,
  MapSchemaSummary,
  ProjectId,
  SearchResults,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";

const basePath = "/map/schema/v1";

export function search(
  apiParams: ApiParams,
  project?: ProjectId
): IO<SearchResults<MapSchemaSummary>> {
  const url: UrlParts = { path: basePath, query: { project } };

  return fetch
    .get({ apiParams, url })
    .chain(
      contentAs(
        "SearchResults<MapSchemaSummary>",
        isSearchResults(isMapSchemaSummary)
      )
    );
}

export function readOrFail(
  apiParams: ApiParams,
  mapId: MapId
): IO<MapSchemaSummary> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${mapId}` } })
    .flatMap(contentAs("MapSchemaSummary", isMapSchemaSummary));
}

export function readOption(
  apiParams: ApiParams,
  mapId: MapId
): IO<Option<MapSchemaSummary>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${mapId}` } })
    .flatMap(optionalContentAs("MapSchemaSummary", isMapSchemaSummary));
}
