// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21SlowFlowFeatureType = "Dam" | "Swale" | "Bund";

const Dam: Thames21SlowFlowFeatureType = "Dam";
const Swale: Thames21SlowFlowFeatureType = "Swale";
const Bund: Thames21SlowFlowFeatureType = "Bund";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21SlowFlowFeatureTypeEnum = {
  ...createEnum("Thames21SlowFlowFeatureType", [
    [Dam, "Leaky Large Wood Dam"],
    [Swale, "Landscaped Swale"],
    [Bund, "Earth Bund"],
  ]),
  Dam,
  Swale,
  Bund,
};

export default Thames21SlowFlowFeatureTypeEnum;
