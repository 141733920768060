/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  MapLayerId,
  isMapLayerId,
  Message,
  isMessage,
  Timestamp,
  isTimestamp,
  UserId,
  isUserId,
} from "../core/types.generated";
import {
  PermissionCheck,
  isPermissionCheck,
  QualificationRole,
  isQualificationRole,
  Role,
  isRole,
} from "../permission/types.generated";

export interface ExpiredAuthorizationError {
  timestamp?: Timestamp | null;
}

export interface InvalidAuthorizationError {
  timestamp?: Timestamp | null;
}

export interface MissingAuthorizationError {
  timestamp?: Timestamp | null;
}

export interface ForbiddenError {
  userId?: UserId | null;
  roles?: Role[] | null;
  qualificationRoles?: QualificationRole[] | null;
  check: PermissionCheck;
  timestamp?: Timestamp | null;
}

export interface BadRequestError {
  message: string;
  extraData: unknown;
  timestamp?: Timestamp | null;
}

export interface NotFoundError {
  itemType: string;
  item: string;
  timestamp?: Timestamp | null;
}

export interface DuplicateError {
  itemType: string;
  item: string;
  timestamp?: Timestamp | null;
}

export interface MapQueryUnsupportedError {
  layerId: MapLayerId;
  message: string;
  timestamp?: Timestamp | null;
}

export interface InternalError {
  message: string;
  timestamp?: Timestamp | null;
}

export interface ValidationError {
  value: unknown;
  errors: Message[];
  message: string;
  status: number;
  timestamp?: Timestamp | null;
}

export interface NetworkError {
  extraData: unknown;
  message: string;
  timestamp?: Timestamp | null;
}

export type ApiError =
  | ({ type: "ExpiredAuthorizationError" } & ExpiredAuthorizationError)
  | ({ type: "InvalidAuthorizationError" } & InvalidAuthorizationError)
  | ({ type: "MissingAuthorizationError" } & MissingAuthorizationError)
  | ({ type: "ForbiddenError" } & ForbiddenError)
  | ({ type: "BadRequestError" } & BadRequestError)
  | ({ type: "NotFoundError" } & NotFoundError)
  | ({ type: "DuplicateError" } & DuplicateError)
  | ({ type: "MapQueryUnsupportedError" } & MapQueryUnsupportedError)
  | ({ type: "InternalError" } & InternalError)
  | ({ type: "ValidationError" } & ValidationError)
  | ({ type: "NetworkError" } & NetworkError);

export const isExpiredAuthorizationError = (
  v: any
): v is ExpiredAuthorizationError => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isInvalidAuthorizationError = (
  v: any
): v is InvalidAuthorizationError => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isMissingAuthorizationError = (
  v: any
): v is MissingAuthorizationError => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isForbiddenError = (v: any): v is ForbiddenError => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("userId" in v) || isUserId(v.userId) || v.userId === null) &&
    (!("roles" in v) ||
      (Array.isArray(v.roles) && v.roles.every((i: any) => isRole(i))) ||
      v.roles === null) &&
    (!("qualificationRoles" in v) ||
      (Array.isArray(v.qualificationRoles) &&
        v.qualificationRoles.every((i: any) => isQualificationRole(i))) ||
      v.qualificationRoles === null) &&
    "check" in v &&
    isPermissionCheck(v.check) &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isBadRequestError = (v: any): v is BadRequestError => {
  return (
    typeof v === "object" &&
    v != null &&
    "message" in v &&
    typeof v.message === "string" &&
    "extraData" in v &&
    true &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isNotFoundError = (v: any): v is NotFoundError => {
  return (
    typeof v === "object" &&
    v != null &&
    "itemType" in v &&
    typeof v.itemType === "string" &&
    "item" in v &&
    typeof v.item === "string" &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isDuplicateError = (v: any): v is DuplicateError => {
  return (
    typeof v === "object" &&
    v != null &&
    "itemType" in v &&
    typeof v.itemType === "string" &&
    "item" in v &&
    typeof v.item === "string" &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isMapQueryUnsupportedError = (
  v: any
): v is MapQueryUnsupportedError => {
  return (
    typeof v === "object" &&
    v != null &&
    "layerId" in v &&
    isMapLayerId(v.layerId) &&
    "message" in v &&
    typeof v.message === "string" &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isInternalError = (v: any): v is InternalError => {
  return (
    typeof v === "object" &&
    v != null &&
    "message" in v &&
    typeof v.message === "string" &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isValidationError = (v: any): v is ValidationError => {
  return (
    typeof v === "object" &&
    v != null &&
    "value" in v &&
    true &&
    "errors" in v &&
    Array.isArray(v.errors) &&
    v.errors.every((i: any) => isMessage(i)) &&
    "message" in v &&
    typeof v.message === "string" &&
    "status" in v &&
    typeof v.status === "number" &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isNetworkError = (v: any): v is NetworkError => {
  return (
    typeof v === "object" &&
    v != null &&
    "extraData" in v &&
    true &&
    "message" in v &&
    typeof v.message === "string" &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null)
  );
};

export const isApiError = (v: any): v is ApiError => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "ExpiredAuthorizationError" &&
      isExpiredAuthorizationError(v)) ||
      (v.type === "InvalidAuthorizationError" &&
        isInvalidAuthorizationError(v)) ||
      (v.type === "MissingAuthorizationError" &&
        isMissingAuthorizationError(v)) ||
      (v.type === "ForbiddenError" && isForbiddenError(v)) ||
      (v.type === "BadRequestError" && isBadRequestError(v)) ||
      (v.type === "NotFoundError" && isNotFoundError(v)) ||
      (v.type === "DuplicateError" && isDuplicateError(v)) ||
      (v.type === "MapQueryUnsupportedError" &&
        isMapQueryUnsupportedError(v)) ||
      (v.type === "InternalError" && isInternalError(v)) ||
      (v.type === "ValidationError" && isValidationError(v)) ||
      (v.type === "NetworkError" && isNetworkError(v)))
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
