// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopLitter = "High" | "Medium" | "Low" | "None";

const High: UclPitStopLitter = "High";
const Medium: UclPitStopLitter = "Medium";
const Low: UclPitStopLitter = "Low";
const None: UclPitStopLitter = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopLitterEnum = {
  ...createEnum("UclPitStopLitter", [
    [High, "High - Evidence of fly tipping in large volumes"],
    [Medium, "Medium - Some evidence of fly tipping"],
    [Low, "Low - Some evidence of litter present"],
    [None, "None - No evidence of litter"],
  ]),
  High,
  Medium,
  Low,
  None,
};

export default UclPitStopLitterEnum;
