// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopMapPoint = "Water" | "Vegetation" | "Other";

const Water: UclPitStopMapPoint = "Water";
const Vegetation: UclPitStopMapPoint = "Vegetation";
const Other: UclPitStopMapPoint = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopMapPointEnum = {
  ...createEnum("UclPitStopMapPoint", [
    [Water, "Water"],
    [Vegetation, "Vegetation"],
    [Other, "Other"],
  ]),
  Water,
  Vegetation,
  Other,
};

export default UclPitStopMapPointEnum;
