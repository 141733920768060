// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsBankStructure = "B" | "U" | "S" | "C";

const Bare: UrsBankStructure = "B";
const Uniform: UrsBankStructure = "U";
const Simple: UrsBankStructure = "S";
const Complex: UrsBankStructure = "C";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsBankStructureEnum = {
  ...createEnum("UrsBankStructure", [
    [Bare, "Bare earth/rock etc."],
    [Uniform, "Predominantly one type (no scrub or trees)"],
    [Simple, "Two or three vegetation types"],
    [Complex, "Four or more types"],
  ]),
  Bare,
  Uniform,
  Simple,
  Complex,
};

export default UrsBankStructureEnum;
