import {
  attributeGroup,
  pointLayer,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const FbaWindermereSite = mapSchemaWithDefaults({
  mapId: unsafeMapId("FbaWindermereSite"),
  title: "Windermere Sites",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("FbaWindermereSite"),
      title: "Windermere Sites",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("FbaWindermereSite"),
        attribution: [],
      }),
      defaultAttribute: "name",
      attributes: [
        attributeGroup({
          label: "Site",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("FbaWindermereSite"),
            }),
            surveyAttr("surveyId", "Site"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time Created",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Site",
          attributes: [
            stringAttr({ attributeId: "name", label: "Name", buckets: "auto" }),
            stringAttr({
              attributeId: "description",
              label: "Description",
              buckets: "auto",
            }),
          ],
        }),
      ],
    }),
  ],
});
