// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeRestorationPrioritiesPhysicalMeasure =
  | "RestoreRiparianZone"
  | "RestoreShorelines"
  | "RestoreLittoralMargins"
  | "RemoveOutflowStructures";

const RestoreRiparianZone: NeLakeRestorationPrioritiesPhysicalMeasure =
  "RestoreRiparianZone";
const RestoreShorelines: NeLakeRestorationPrioritiesPhysicalMeasure =
  "RestoreShorelines";
const RestoreLittoralMargins: NeLakeRestorationPrioritiesPhysicalMeasure =
  "RestoreLittoralMargins";
const RemoveOutflowStructures: NeLakeRestorationPrioritiesPhysicalMeasure =
  "RemoveOutflowStructures";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeRestorationPrioritiesPhysicalMeasureEnum = {
  ...createEnum("NeLakeRestorationPrioritiesPhysicalMeasure", [
    [
      RestoreRiparianZone,
      "Establish/restore riparian zone of semi-natural vegetation",
    ],
    [RestoreShorelines, "Restore natural lake shorelines"],
    [
      RestoreLittoralMargins,
      "Restore littoral margins including marginal emergent vegetation where lost",
    ],
    [
      RemoveOutflowStructures,
      "Remove outflow structures where these do not result in the loss of the water body",
    ],
  ]),
  RestoreRiparianZone,
  RestoreShorelines,
  RestoreLittoralMargins,
  RemoveOutflowStructures,
};

export default NeLakeRestorationPrioritiesPhysicalMeasureEnum;
