import { MapInventory } from "./MapInventory";
import { ArrtWaterQuality, OutfallSafari } from "./maps/arrt";
import { EsrtWaterQuality } from "./maps/esrt";
import { FbaWindermere, FbaWindermereSite } from "./maps/fba";
import { FhtPondCount } from "./maps/fht";
import { FhtPriorityPonds } from "./maps/fht/FhtPriorityPonds";
import {
  MrsMorph,
  MrsMorph10,
  MrsMorph10Pro,
  MrsMorph5,
  MrsMorph5Pro,
  MrsMorphEstuaries,
  MrsMorphEstuaries5,
  MrsMorphEstuaries5Pro,
  MrsMorphEstuariesPro,
  MrsMorphPro,
  MrsMorphRivers,
  MrsMudSpotter,
  MrsRcaPro,
  MrsRiverCondition,
  MrsRiverConditionPro,
  MrsRiverWood,
  MrsRta,
  MrsRtaPro,
  MrsWildInvaders,
} from "./maps/mrs";
import { NwcSeedbank } from "./maps/nwc";
import {
  PlacemarkerRiverStretch,
  PlacemarkerStudyArea,
} from "./maps/placemarker";
import {
  NeChalkRivers,
  NeLakeNaturalness,
  NeLakeRestorationPriorities,
  NeNaturalness,
  NeRestorationPriorities,
  NeRiverNaturalness,
  NeRiverRestorationPriorities,
  NeRiverTypes,
} from "./maps/priorityhabitats";
import {
  ExtendedRiverflySiltAndFlow,
  ExtendedRiverflyWaterQuality,
  Riverfly,
  RiverflySite,
  UrbanRiverfly,
} from "./maps/riverfly";
import { RoyalParksSpotlighting } from "./maps/royalparks";
import { StSmartRivers, StSmartRiversSite } from "./maps/salmontrout";
import { SasWaterQuality, SasWaterQualitySite } from "./maps/sas";
import { StdActivityDiary, StdInvasiveSpeciesSighting } from "./maps/standard";
import { StormPhosphates } from "./maps/storm";
import {
  Thames21BadgedGroupEvent,
  Thames21GreenWallModule,
  Thames21Litter,
  Thames21SlowFlow,
  Thames21ThamesWaterQuality,
  Thames21Vegetation,
  Thames21WaterQuality,
} from "./maps/thames21";
import { UclPitStop } from "./maps/ucl";
import { Urs } from "./maps/urs";
import { WrtFords, WrtUpstreamThinking, WrtWestcountryCsi } from "./maps/wrt";

export function productionMaps(): MapInventory {
  return new MapInventory([
    OutfallSafari,
    ArrtWaterQuality,
    EsrtWaterQuality,
    ExtendedRiverflyWaterQuality,
    ExtendedRiverflySiltAndFlow,
    FbaWindermere,
    FbaWindermereSite,
    FhtPondCount,
    FhtPriorityPonds,
    MrsMorph,
    MrsMorph10,
    MrsMorph10Pro,
    MrsMorph5,
    MrsMorph5Pro,
    MrsMorphEstuaries,
    MrsMorphEstuariesPro,
    MrsMorphEstuaries5,
    MrsMorphEstuaries5Pro,
    MrsMorphPro,
    MrsMorphRivers,
    MrsMudSpotter,
    MrsRiverCondition,
    MrsRiverConditionPro,
    MrsRiverWood,
    MrsRcaPro,
    MrsRta,
    MrsRtaPro,
    MrsWildInvaders,
    NeChalkRivers,
    NeLakeNaturalness,
    NeLakeRestorationPriorities,
    NeNaturalness,
    NeRestorationPriorities,
    NeRiverNaturalness,
    NeRiverRestorationPriorities,
    NeRiverTypes,
    NwcSeedbank,
    PlacemarkerRiverStretch,
    PlacemarkerStudyArea,
    Riverfly,
    RiverflySite,
    RoyalParksSpotlighting,
    SasWaterQuality,
    SasWaterQualitySite,
    StdActivityDiary,
    StdInvasiveSpeciesSighting,
    StormPhosphates,
    StSmartRivers,
    StSmartRiversSite,
    Thames21BadgedGroupEvent,
    Thames21GreenWallModule,
    Thames21Litter,
    Thames21SlowFlow,
    Thames21ThamesWaterQuality,
    Thames21Vegetation,
    Thames21WaterQuality,
    UclPitStop,
    UrbanRiverfly,
    Urs,
    WrtFords,
    WrtUpstreamThinking,
    WrtWestcountryCsi,
  ]);
}
