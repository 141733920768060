import { checkExhausted } from "@cartographerio/util";
import { Attribute, AttributeGroup } from "../attribute";
import { MapLayer } from "./type";

export function layerAttributeGroups(layer: MapLayer): AttributeGroup[] {
  return layer.attributes;
}

export function layerAttributes(layer: MapLayer): Attribute[] {
  return layer.attributes.flatMap(group => group.attributes);
}

export function findLayerAttribute(
  layer: MapLayer,
  attributeId: string
): Attribute | undefined {
  for (const group of layer.attributes) {
    for (const attr of group.attributes) {
      if (attr.attributeId === attributeId) {
        return attr;
      }
    }
  }

  return undefined;
}

export function defaultLayerAttribute(layer: MapLayer): Attribute {
  const attributes = layerAttributes(layer);
  if (layer.defaultAttribute == null) {
    return attributes[0];
  } else {
    const selected = attributes.find(attr => {
      switch (attr.type) {
        case "FeatureAttribute":
          return false;

        case "StringAttribute":
        case "NumberAttribute":
        case "BooleanAttribute":
        case "TimestampAttribute":
        case "SurveyAttribute":
        case "TeamAttribute":
          return layer.defaultAttribute === attr.propertyName;
        case "AttachmentAttribute":
          return layer.defaultAttribute === attr.folderPropertyName;
        default:
          return checkExhausted(attr);
      }
    });

    return selected ?? attributes[0];
  }
}
