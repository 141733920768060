import { checkExhausted } from "@cartographerio/util";
import { QualificationRole, QualificationRoleName } from "../types.generated";
import { parseQualificationRole } from "./parse";

type QualificationRoleComparator = (
  x: QualificationRole,
  y: QualificationRole
) => boolean | undefined;

type QualificationLevelComparator = (x: number, y: number) => boolean;

/** Compare roles that operate on the same resource.
 * Return `undefined` if they have different types/associated resources.
 * */
function compareQualificationRoles(
  func: QualificationLevelComparator
): QualificationRoleComparator {
  return (x, y) => {
    const [name1, param1] = parseQualificationRole(x);
    const [name2, param2] = parseQualificationRole(y);

    return param1 === param2
      ? func(qualificationRoleLevel(name1), qualificationRoleLevel(name2))
      : undefined;
  };
}

export const qualificationRolesAlike = compareQualificationRoles(() => true);
export const qualificationRoleGt = compareQualificationRoles((x, y) => x > y);
export const qualificationRoleGte = compareQualificationRoles((x, y) => x >= y);
export const qualificationRoleLte = compareQualificationRoles((x, y) => x <= y);
export const qualificationRoleLt = compareQualificationRoles((x, y) => x < y);

type OnAddRoleConflict = "replace" | "keepBest";

/** Add `role` to `roles`. */
export function addQualificationRole(
  roles: QualificationRole[],
  role: QualificationRole,
  onConflict: OnAddRoleConflict
): QualificationRole[] {
  switch (onConflict) {
    case "replace":
      return removeQualificationRole(roles, role).concat([role]);
    case "keepBest":
      return uniqQualificationRoles([...roles, role]);
    default:
      return checkExhausted(onConflict);
  }
}

/** Remove any roles like `role` from `roles`. */
export function removeQualificationRole(
  roles: QualificationRole[],
  role: QualificationRole,
  alike: QualificationRoleComparator = qualificationRolesAlike
): QualificationRole[] {
  return roles.filter(r => alike(r, role) !== true);
}

/** Remove any duplicate roles (as defined by `rolesAlike`). */
export function uniqQualificationRoles(
  roles: QualificationRole[]
): QualificationRole[] {
  const ans: QualificationRole[] = [];

  for (let i = 0; i < roles.length; i++) {
    const ri = roles[i];
    let foundBetter = false;

    for (let j = 0; j < roles.length; j++) {
      const rj = roles[j];

      if (i !== j && qualificationRoleGt(rj, ri)) {
        foundBetter = true;
        break;
      }
    }

    if (!foundBetter) {
      ans.push(ri);
    }
  }

  return ans;
}

export function qualificationRoleLevel(name: QualificationRoleName): number {
  switch (name) {
    case "Expired":
      return 0;
    case "Trainee":
      return 1;
    case "Qualified":
      return 2;
    case "Trainer":
      return 3;
    case "Coordinator":
      return 4;
    default:
      return checkExhausted(name);
  }
}
