// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type YesNo = "yes" | "no";

const Yes: YesNo = "yes";
const No: YesNo = "no";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const YesNoEnum = {
  ...createEnum("YesNo", [
    [Yes, "Yes"],
    [No, "No"],
  ]),
  Yes,
  No,
};

export default YesNoEnum;
