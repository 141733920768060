import { raise } from "./raise";

export interface EnumEntry<A = string> {
  value: A;
  label: string;
}

export interface Enum<A, E extends EnumEntry<A> = EnumEntry<A>> {
  name: string;

  values: A[];
  labels: string[];
  entries: E[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isValue(value: any): value is A;
  entryOf(value: A): E;
  labelOf(value: A): string;
}

function missing(name: string, value: unknown): never {
  throw new Error(`Enum value not found: ${value} was not a value of ${name}`);
}

export function createEnum<A>(name: string, arg: [A, string][]): Enum<A> {
  const values: A[] = arg.map(e => e[0]);

  const labels: string[] = arg.map(e => e[1]);

  const entries: EnumEntry<A>[] = arg.map(([value, label]) => ({
    value,
    label,
  }));

  function isValue(value: unknown): value is A {
    return (values as unknown[]).includes(value);
  }

  function entryOf(value: A): EnumEntry<A> {
    return entries.find(e => e.value === value) ?? raise(missing(name, value));
  }

  function labelOf(value: A): string {
    return (
      entries.find(e => e.value === value)?.label ?? raise(missing(name, value))
    );
  }

  return {
    name,

    values,
    labels,
    entries,

    isValue,
    entryOf,
    labelOf,
  };
}
