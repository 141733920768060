import {
  attachmentField,
  enumSelectOptions,
  form,
  integerField,
  minValue,
  page,
  pointField,
  required,
  requireRole,
  section,
  select,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import { StdActivityTypeEnum } from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

export default form({
  title: "Activity Diary",
  surveyorHelp: "Who was the active person?",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date and Time",
              path: ["data", "recorded"],
              required: required("info"),
              help: "When did the activity take place?",
              defaultValue: "now",
            }),
            textField({
              label: "Site Name",
              path: ["data", "siteName"],
              required: required("info"),
              help: "Roughly where did the activity take place?",
            }),
            pointField({
              label: "Location",
              path: ["data", "location"],
              required: required("info", "You should select a location."),
              help: "*Exactly* where did the activity take place?",
              fullWidth: true,
            }),
          ],
        }),
        section({
          title: "The Activity",
          path: [],
          blocks: [
            select({
              label: "Activity Type",
              path: ["data", "activityType"],
              options: enumSelectOptions(StdActivityTypeEnum),
              required: required("info"),
            }),
            integerField({
              label: "Duration",
              path: ["data", "duration"],
              units: "mins",
              required: required("info"),
              bounds: minValue(0, "error"),
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Comments",
          path: [],
          blocks: [
            requireRole({
              role: "Approver",
              block: textArea({
                label: null,
                path: ["data", "comments"],
                rows: 5,
                help: outdent`
                If you are approving this survey,
                please include any comments, including details of any additional surveyors.
                `,
              }),
            }),
          ],
        }),
      ],
    }),
  ],
});
