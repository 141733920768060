// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverRestorationPrioritiesPhysicalMeasure =
  | "EstablishRiparianZone"
  | "EstablishRiparianTrees"
  | "AllowAllowWoodyMaterial"
  | "RestoreTransitionZone"
  | "RestoreLateralMovement"
  | "RemoveInChannelStructures"
  | "RestoreChannelBedLevels"
  | "RemoveFloodEmbankments"
  | "RestoreAlluvialWoodland"
  | "RestoreWetlandMosaic";

const EstablishRiparianZone: NeRiverRestorationPrioritiesPhysicalMeasure =
  "EstablishRiparianZone";
const EstablishRiparianTrees: NeRiverRestorationPrioritiesPhysicalMeasure =
  "EstablishRiparianTrees";
const AllowAllowWoodyMaterial: NeRiverRestorationPrioritiesPhysicalMeasure =
  "AllowAllowWoodyMaterial";
const RestoreTransitionZone: NeRiverRestorationPrioritiesPhysicalMeasure =
  "RestoreTransitionZone";
const RestoreLateralMovement: NeRiverRestorationPrioritiesPhysicalMeasure =
  "RestoreLateralMovement";
const RemoveInChannelStructures: NeRiverRestorationPrioritiesPhysicalMeasure =
  "RemoveInChannelStructures";
const RestoreChannelBedLevels: NeRiverRestorationPrioritiesPhysicalMeasure =
  "RestoreChannelBedLevels";
const RemoveFloodEmbankments: NeRiverRestorationPrioritiesPhysicalMeasure =
  "RemoveFloodEmbankments";
const RestoreAlluvialWoodland: NeRiverRestorationPrioritiesPhysicalMeasure =
  "RestoreAlluvialWoodland";
const RestoreWetlandMosaic: NeRiverRestorationPrioritiesPhysicalMeasure =
  "RestoreWetlandMosaic";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverRestorationPrioritiesPhysicalMeasureEnum = {
  ...createEnum("NeRiverRestorationPrioritiesPhysicalMeasure", [
    [EstablishRiparianZone, "Establish riparian zone of natural vegetation"],
    [
      EstablishRiparianTrees,
      "Establish at least patchy cover of native riparian trees",
    ],
    [
      AllowAllowWoodyMaterial,
      "Allow natural delivery and retention of woody material",
    ],
    [RestoreTransitionZone, "Restore natural mire-stream transition zone"],
    [RestoreLateralMovement, "Restore natural lateral movement of the channel"],
    [RemoveInChannelStructures, "Remove in-channel structures"],
    [RestoreChannelBedLevels, "Restore natural channel bed levels"],
    [RemoveFloodEmbankments, "Remove flood embankments"],
    [RestoreAlluvialWoodland, "Re-establish alluvial woodland"],
    [RestoreWetlandMosaic, "Restore natural floodplain wetland mosaic"],
  ]),
  EstablishRiparianZone,
  EstablishRiparianTrees,
  AllowAllowWoodyMaterial,
  RestoreTransitionZone,
  RestoreLateralMovement,
  RemoveInChannelStructures,
  RestoreChannelBedLevels,
  RemoveFloodEmbankments,
  RestoreAlluvialWoodland,
  RestoreWetlandMosaic,
};

export default NeRiverRestorationPrioritiesPhysicalMeasureEnum;
