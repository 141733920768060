// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type SasWaterQualitySiteType = "Upstream" | "Midstream" | "Downstream";

const Upstream: SasWaterQualitySiteType = "Upstream";
const Midstream: SasWaterQualitySiteType = "Midstream";
const Downstream: SasWaterQualitySiteType = "Downstream";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SasWaterQualitySiteTypeEnum = {
  ...createEnum("SasWaterQualitySiteType", [
    [Upstream, "Upstream"],
    [Midstream, "Midstream"],
    [Downstream, "Downstream"],
  ]),
  Upstream,
  Midstream,
  Downstream,
};

export default SasWaterQualitySiteTypeEnum;
