import { Point } from "@cartographerio/geometry";
import {
  AttachmentFolder,
  randomAttachmentFolder,
  SurveyF,
  TestSurveyProps,
  testSurvey,
  Timestamp,
  unsafeSurveyModuleId,
} from "@cartographerio/types";

export interface TestSurvey2Data {
  location?: Point | null;
  timestamp?: Timestamp | null;
  a?: number | null;
  b?: number | null;
  c?: number | null;
  photographs: AttachmentFolder;
}

export type TestSurvey2 = SurveyF<TestSurvey2Data>;

export function testSurvey2(
  props: TestSurveyProps<TestSurvey2Data>
): TestSurvey2 {
  return testSurvey({
    ...props,
    moduleId: unsafeSurveyModuleId("TestSurvey2"),
    data: props.data ?? {
      location: null,
      timestamp: null,
      a: null,
      b: null,
      c: null,
      photographs: randomAttachmentFolder(),
    },
  });
}
