import {
  attachmentField,
  bounds,
  featureField,
  featureFieldCartographerMapStyle,
  form,
  grid,
  minValue,
  numberField,
  page,
  requireRole,
  required,
  section,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import { stringAttr } from "@cartographerio/topo-map";
import { unsafeMapLayerId, unsafeProjectAlias } from "@cartographerio/types";
import { outdent } from "outdent";

export default form({
  title: "Windermere",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date and Time of Survey",
              path: ["data", "observations", "recorded"],
              required: required("info"),
              help: "Date and time the survey data was collected in the field.",
            }),
            featureField({
              label: "Site",
              path: ["data", "site"],
              region: "uk",
              required: required(),
              mapOptions: {
                mapStyle: featureFieldCartographerMapStyle({
                  project: unsafeProjectAlias("windermeresites"),
                  layer: unsafeMapLayerId("FbaWindermereSite"),
                  geometryType: "Point",
                  primaryKey: "surveyId",
                }),
                selectMinZoom: 10,
                attributes: [
                  stringAttr({
                    attributeId: "name",
                    label: "Site Name",
                    buckets: "auto",
                  }),
                  stringAttr({
                    attributeId: "description",
                    label: "Description",
                    buckets: "auto",
                  }),
                ],
              },
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "observations", "photographs"],
              maxFiles: 2,
              help: outdent`
              Provide two photographs of the site:

              1. Feet facing lake/facing across to opposite channel (if river)
              2. Feet facing away from lake/channel (180° from photo 1)
              `,
            }),
          ],
        }),
        section({
          title: "Measured Parameters",
          path: ["data", "observations"],
          blocks: [
            numberField({
              label: "Temperature",
              path: ["temperature"],
              units: "°C",
              required: required("info"),
              bounds: minValue(0, "info"),
            }),
            numberField({
              label: "Electrical Conductivity",
              path: ["electricalConductivity"],
              units: "µS/cm",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "pH",
              path: ["ph"],
              required: required("info"),
              bounds: bounds(0, 14),
            }),
            numberField({
              label: "Alkalinity",
              path: ["alkalinity"],
              units: "mg/L as CaCO3",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "UV",
              path: ["uv"],
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Chlorophyll-A",
              path: ["chlorophyllA"],
              units: "µg/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Total Phosphorus",
              path: ["totalPhosphorus"],
              units: "mg P/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Total Dissolved Phosphorus",
              path: ["totalDissolvedPhosphorus"],
              units: "mg P/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Soluble Reactive Phosphorus",
              path: ["solubleReactivePhosphorus"],
              units: "mg P/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Total Ammonia",
              path: ["totalAmmonia"],
              units: "mg N/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Total Oxidised Nitrogen",
              path: ["totalOxidisedNitrogen"],
              units: "mg N/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Nitrate",
              path: ["nitrate"],
              units: "mg N/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Nitrite",
              path: ["nitrite"],
              units: "mg N/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Silica",
              path: ["silica"],
              units: "mg SiO₂/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Dissolved Organic Carbon",
              path: ["dissolvedOrganicCarbon"],
              units: "mg C/L",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Total Coliforms",
              path: ["totalColiforms"],
              units: "cfu/100 ml",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "E-Coli",
              path: ["eColi"],
              units: "cfu/100 ml",
              required: required("info"),
              bounds: minValue(0),
            }),
            numberField({
              label: "Intestinal Enterococci",
              path: ["intestinalEnterococci"],
              units: "cfu/100 ml",
              required: required("info"),
              bounds: minValue(0),
            }),
          ],
        }),
        section({
          title: "Additional Data",
          path: [],
          help: "This section is reserved for project coordinators.",
          blocks: [
            requireRole({
              role: "Approver",
              block: grid({
                columns: 1,
                blocks: [
                  textField({
                    label: "Bathing Water Quality",
                    path: ["data", "observations", "bathingWaterQuality"],
                  }),
                  textField({
                    label: "Site Type (lake or river)",
                    path: ["data", "observations", "lakeOrRiver"],
                  }),
                  textField({
                    label: "WFD Phosphorus Status",
                    path: ["data", "observations", "wfdPhosphorusStatus"],
                  }),
                ],
              }),
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "observations", "notes"],
              rows: 8,
            }),
          ],
        }),
      ],
    }),
  ],
});
