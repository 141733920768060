import {
  polygonLayer,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { mapSchemaWithDefaults } from "../../core";
import {
  lakeRestorationPrioritiesAttributeGroups,
  lakesSource,
  overallPriorityAttribute,
} from "./restorationPrioritiesHelpers";
import { MapBaseEnum } from "@cartographerio/types";

export const NeLakeRestorationPriorities = mapSchemaWithDefaults({
  mapId: unsafeMapId("neLakeRestorationPriorities"),
  title: "Lake Restoration Priorities",
  defaultBase: MapBaseEnum.Satellite,
  layers: [
    polygonLayer({
      layerId: unsafeMapLayerId("neLakeRestorationPriorities"),
      title: "Lake Restoration Priorities",
      source: lakesSource,
      defaultAttribute: overallPriorityAttribute.propertyName,
      attributes: lakeRestorationPrioritiesAttributeGroups,
    }),
  ],
});
