// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterOverlandFlowSourceType =
  | "Field"
  | "Unsurfaced"
  | "Surfaced"
  | "Other";

const Field: MrsMudSpotterOverlandFlowSourceType = "Field";
const Unsurfaced: MrsMudSpotterOverlandFlowSourceType = "Unsurfaced";
const Surfaced: MrsMudSpotterOverlandFlowSourceType = "Surfaced";
const Other: MrsMudSpotterOverlandFlowSourceType = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterOverlandFlowSourceTypeEnum = {
  ...createEnum("MrsMudSpotterOverlandFlowSourceType", [
    [Field, "Field"],
    [Unsurfaced, "Unsurfaced Track / Yard"],
    [Surfaced, "Surfaced Road / Car Park"],
    [Other, "Other"],
  ]),
  Field,
  Unsurfaced,
  Surfaced,
  Other,
};

export default MrsMudSpotterOverlandFlowSourceTypeEnum;
