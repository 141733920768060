// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessPerimeterTrees =
  | "None"
  | "Isolated"
  | "Occasional"
  | "SemiContinuous"
  | "Continuous";

const None: NeLakeNaturalnessPerimeterTrees = "None";
const Isolated: NeLakeNaturalnessPerimeterTrees = "Isolated";
const Occasional: NeLakeNaturalnessPerimeterTrees = "Occasional";
const SemiContinuous: NeLakeNaturalnessPerimeterTrees = "SemiContinuous";
const Continuous: NeLakeNaturalnessPerimeterTrees = "Continuous";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessPerimeterTreesEnum = {
  ...createEnum("NeLakeNaturalnessPerimeterTrees", [
    [None, "None"],
    [Isolated, "Isolated scattered"],
    [Occasional, "Occasional clumps"],
    [SemiContinuous, "Semi continuous"],
    [Continuous, "Continuous"],
  ]),
  None,
  Isolated,
  Occasional,
  SemiContinuous,
  Continuous,
};

export default NeLakeNaturalnessPerimeterTreesEnum;
