import {
  MapLayer,
  MapLayerId,
  mapSchema,
  MapSchema,
} from "@cartographerio/topo-map";
import { Option } from "@cartographerio/fp";
import { MapBaseEnum, MapId, unsafeMapId } from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import lodash from "lodash";
import { baseStyles } from "./core/baseStyles";

type MapDict = Record<string, MapSchema>;

/** A colletion of `MapSchemas`. One day we'll deprecate this in favour of loading schemas from the server. */
export class MapInventory {
  _maps: MapSchema[];
  _mapsById: MapDict;

  constructor(maps: MapSchema[]) {
    this._maps = maps;
    this._mapsById = lodash
      .chain(maps)
      .map(map => [map.mapId, map])
      .fromPairs()
      .value();
  }

  /** Returns a list of all survey maps. */
  maps = (): MapSchema[] => {
    return this._maps;
  };

  allLayers = (): MapLayer[] =>
    lodash
      .chain(Object.values(this.maps()))
      .flatMap(map => map.layers)
      .uniqBy(layer => layer.layerId)
      .value();

  unsafeMapFromLayerIds = (
    layerIds: MapLayerId[],
    mapId: MapId = unsafeMapId("custom")
  ): MapSchema => {
    const layers = this.allLayers();

    return mapSchema({
      mapId,
      title: "Combined",
      baseStyles,
      defaultBase: MapBaseEnum.Terrain,
      layers: filterAndMap(layerIds, layerId =>
        layers.find(layer => layer.layerId === layerId)
      ),
    });
  };

  unsafeKitchenSinkMap = (mapId: MapId = unsafeMapId("custom")): MapSchema => {
    return mapSchema({
      mapId,
      title: "Cartographer",
      baseStyles,
      defaultBase: MapBaseEnum.Terrain,
      layers: this.allLayers(),
    });
  };

  /** Returns a list of all survey map IDs. */
  mapIds = (): MapId[] => {
    return this._maps.map(map => map.mapId);
  };

  /** Returns a Map given an ID. */
  mapById = (id: MapId): Option<MapSchema> => {
    return Option.wrap(this._mapsById[id]);
  };
}

export function mapInventory(maps: MapSchema[]): MapInventory {
  return new MapInventory(maps);
}
