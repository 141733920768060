import { surveyModuleFromJson } from "../SurveyModule";
import formSchema from "./form";
import moduleJson from "./module.generated.json";
import {
  copyData,
  dataDescription,
  dataGeometry,
  dataTimestamp,
} from "./parts";

export default surveyModuleFromJson({
  moduleJson,
  formSchema,
  dataDescription,
  dataGeometry,
  dataTimestamp,
  copyData,
}).unsafeGet();
