import {
  topoExpr,
  attachmentField,
  checkbox,
  form,
  numberField,
  page,
  pointField,
  required,
  requiredIf,
  section,
  select,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import {
  Thames21SlowFlowFeatureTypeEnum,
  Thames21SlowFlowPondTypeEnum,
  Thames21SlowFlowServiceTypeEnum,
  Thames21SlowFlowSiteEnum,
  Thames21SlowFlowSuitabilityEnum,
  Thames21SlowFlowSurveyTypeEnum,
  Thames21SlowFlowVegetationTypeEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

const requiredIfNewFeature = requiredIf({
  level: "info",
  message: "You should select a value.",
  otherPath: ["data", "surveyType"],
  otherTest: value => value === Thames21SlowFlowSurveyTypeEnum.NewFeature,
});

const requiredIfExistingFeature = requiredIf({
  level: "info",
  message: "You should select a value.",
  otherPath: ["data", "surveyType"],
  otherTest: value => value === Thames21SlowFlowSurveyTypeEnum.ExistingFeature,
});

export default form({
  title: "Slow Flow",
  pages: [
    page({
      title: "General",
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date and Time",
              path: ["data", "recorded"],
              defaultValue: "now",
              required: required("info"),
            }),
            pointField({
              label: "Location",
              path: ["data", "location"],
              fullWidth: true,
              required: required("info"),
            }),
            select({
              label: "Site",
              path: ["data", "site"],
              options: Thames21SlowFlowSiteEnum.entries,
              required: required("info"),
            }),
            textField({
              label: "Other Site",
              path: ["data", "otherSite"],
            }),
          ],
        }),
        section({
          title: "New/Existing Feature",
          path: [],
          blocks: [
            select({
              label: null,
              path: ["data", "surveyType"],
              options: Thames21SlowFlowSurveyTypeEnum.entries,
              required: required("info"),
            }),
          ],
        }),
      ],
    }),
    page({
      title: "New Feature",
      path: ["data"],
      enabled: topoExpr(
        env =>
          env.getAbsolute(["data", "surveyType"]) ===
          Thames21SlowFlowSurveyTypeEnum.NewFeature
      ),
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            select({
              label: "What makes this location suitable for NFM?",
              path: ["newFeature", "suitability"],
              options: Thames21SlowFlowSuitabilityEnum.entries,
              customRules: [requiredIfNewFeature],
            }),
            select({
              label: "How would this structure provide NFM services?",
              path: ["newFeature", "serviceType"],
              options: Thames21SlowFlowServiceTypeEnum.entries,
              customRules: [requiredIfNewFeature],
            }),
            select({
              label: "What type of woodland or hedgerow?",
              path: ["newFeature", "vegetationType"],
              options: Thames21SlowFlowVegetationTypeEnum.entries,
              customRules: [requiredIfNewFeature],
            }),
            select({
              label: "What type of pond?",
              path: ["newFeature", "pondType"],
              options: Thames21SlowFlowPondTypeEnum.entries,
              customRules: [requiredIfNewFeature],
            }),
            attachmentField({
              label: "Photographs",
              path: ["photographs"],
              maxFiles: 4,
            }),
            textArea({
              label: "Any other comments",
              path: ["notes"],
              rows: 5,
              help: outdent`
              e.g. changes in vegetation, ground conditions, etc
              `,
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Existing Feature",
      path: ["data"],
      enabled: topoExpr(
        env =>
          env.getAbsolute(["data", "surveyType"]) ===
          Thames21SlowFlowSurveyTypeEnum.ExistingFeature
      ),
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            select({
              label: "What type of NFM structure or feature is here?",
              path: ["existingFeature", "featureType"],
              options: Thames21SlowFlowFeatureTypeEnum.entries,
              customRules: [requiredIfExistingFeature],
            }),
            textField({
              label: "ID or name of the feature",
              path: ["existingFeature", "featureId"],
            }),
            select({
              label: "How is this feature providing NFM services?",
              path: ["existingFeature", "serviceType"],
              options: Thames21SlowFlowServiceTypeEnum.entries,
              customRules: [requiredIfExistingFeature],
            }),
            numberField({
              label: "Area of retained water",
              path: ["existingFeature", "storageArea"],
              units: "m2",
              customRules: [requiredIfExistingFeature],
            }),
            checkbox({
              label: "State of repair",
              path: ["existingFeature", "needsRepair"],
              checkboxLabel: "Is the structure in need of repair?",
              customRules: [requiredIfExistingFeature],
            }),
            attachmentField({
              label: "Photographs",
              path: ["photographs"],
              maxFiles: 4,
              help: outdent`
              Where possible, attach at least one of each of the following. Remember to only take photographs from safe locations:

               - a photograph of the NFM structure of the feature;
               - a photograph of the water retained behind the feature;
               - if there is a visible level marker, a photograph to show the depth of water behind the feature.
              `,
            }),
            textArea({
              label: "Any other comments",
              path: ["notes"],
              rows: 5,
              help: outdent`
              e.g. changes in vegetation, ground conditions, etc
              `,
            }),
          ],
        }),
      ],
    }),
  ],
});
