// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterDisturbedBankFaceSourceType =
  | "EarthWorksOrDredging"
  | "VehicleDisturbance"
  | "Poaching"
  | "NaturalBankErosion";

const EarthWorksOrDredging: MrsMudSpotterDisturbedBankFaceSourceType =
  "EarthWorksOrDredging";
const VehicleDisturbance: MrsMudSpotterDisturbedBankFaceSourceType =
  "VehicleDisturbance";
const Poaching: MrsMudSpotterDisturbedBankFaceSourceType = "Poaching";
const NaturalBankErosion: MrsMudSpotterDisturbedBankFaceSourceType =
  "NaturalBankErosion";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterDisturbedBankFaceSourceTypeEnum = {
  ...createEnum("MrsMudSpotterDisturbedBankFaceSourceType", [
    [EarthWorksOrDredging, "Earth Works / Dredging"],
    [VehicleDisturbance, "Vehicle Disturbance"],
    [Poaching, "Poaching"],
    [NaturalBankErosion, "Natural Bank Erosion"],
  ]),
  EarthWorksOrDredging,
  VehicleDisturbance,
  Poaching,
  NaturalBankErosion,
};

export default MrsMudSpotterDisturbedBankFaceSourceTypeEnum;
