// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesAbundance = "N" | "R" | "O" | "F" | "A" | "D";

const None: CesAbundance = "N";
const Rare: CesAbundance = "R";
const Occasional: CesAbundance = "O";
const Frequent: CesAbundance = "F";
const Abundant: CesAbundance = "A";
const Dominant: CesAbundance = "D";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesAbundanceEnum = {
  ...createEnum("CesAbundance", [
    [None, "None"],
    [Rare, "Rare (<= 5%)"],
    [Occasional, "Occasional (>5-25%)"],
    [Frequent, "Frequent (>25-50%)"],
    [Abundant, "Abundant (>50-75%)"],
    [Dominant, "Dominant (>75-100%)"],
  ]),
  None,
  Rare,
  Occasional,
  Frequent,
  Abundant,
  Dominant,
};

export default CesAbundanceEnum;
