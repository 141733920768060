import { point, Point, BBox } from "./geometry";

interface CommonLocation {
  bounds: BBox;
  center: Point;
  zoom: number;
}

function commonLocation(
  bounds: BBox,
  center: Point,
  zoom: number
): CommonLocation {
  return { bounds, center, zoom };
}

export const commonLocations = {
  greatBritain: commonLocation(
    [-11.19056, 49.692546, 2.348194, 59.548095],
    point(-4.421183, 54.6203205),
    6
  ),
  london: commonLocation(
    [-0.563049, 51.246444, 0.307617, 51.726178],
    point(-0.12, 51.505),
    10
  ),
  brighton: commonLocation(
    [-0.246162, 50.805718, -0.073471, 50.905198],
    point(-0.1457802, 50.8237123),
    16
  ),
  singapore: commonLocation(
    [103.583221, 1.199482, 104.096832, 1.476811],
    point(103.855262, 1.283319),
    14
  ),
};
