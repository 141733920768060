// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsBankMaterial =
  | "NV"
  | "AR"
  | "BE"
  | "BO"
  | "CO"
  | "GS"
  | "EA"
  | "CL";

const NotVisible: UrsBankMaterial = "NV";
const Artificial: UrsBankMaterial = "AR";
const Bedrock: UrsBankMaterial = "BE";
const Boulder: UrsBankMaterial = "BO";
const Cobble: UrsBankMaterial = "CO";
const GravelOrSand: UrsBankMaterial = "GS";
const Earth: UrsBankMaterial = "EA";
const Clay: UrsBankMaterial = "CL";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsBankMaterialEnum = {
  ...createEnum("UrsBankMaterial", [
    [NotVisible, "Not visible"],
    [Artificial, "Artificial"],
    [Bedrock, "Bedrock"],
    [Boulder, "Boulder"],
    [Cobble, "Cobble"],
    [GravelOrSand, "Gravel/sand"],
    [Earth, "Earth"],
    [Clay, "Clay"],
  ]),
  NotVisible,
  Artificial,
  Bedrock,
  Boulder,
  Cobble,
  GravelOrSand,
  Earth,
  Clay,
};

export default UrsBankMaterialEnum;
