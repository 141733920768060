import { Result } from "@cartographerio/fp";
import { isPoint, Point } from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isBoolean,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  MrsRtaAverageBedMaterial,
  MrsRtaAverageBedMaterialEnum,
  MrsRtaCoarsestBedMaterial,
  MrsRtaCoarsestBedMaterialEnum,
  MrsRtaLevelOfConfinement,
  MrsRtaLevelOfConfinementEnum,
  MrsRtaRiverCategory,
  MrsRtaRiverCategoryEnum,
  MrsRtaRiverType,
  MrsRtaRiverTypeEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, nowTimestamp, Timestamp } from "@cartographerio/types";

interface SurveyDetails {
  projectName?: string | null;
  projectCode?: string | null;
  riverName?: string | null;
  reachName?: string | null;
  wfdWaterBodyId?: string | null;
  referenceDate?: Timestamp | null;
  upstreamLocation?: Point | null;
  downstreamLocation?: Point | null;
}

function isSurveyDetails(attrs: unknown): attrs is SurveyDetails {
  return (
    isObject(attrs) &&
    hasOptionalKey(attrs, "projectName", isNullable(isString)) &&
    hasOptionalKey(attrs, "projectCode", isNullable(isString)) &&
    hasOptionalKey(attrs, "riverName", isNullable(isString)) &&
    hasOptionalKey(attrs, "reachName", isNullable(isString)) &&
    hasOptionalKey(attrs, "wfdWaterBodyId", isNullable(isString)) &&
    hasOptionalKey(attrs, "referenceDate", isNullable(isTimestamp)) &&
    hasOptionalKey(attrs, "upstreamLocation", isNullable(isPoint)) &&
    hasOptionalKey(attrs, "downstreamLocation", isNullable(isPoint))
  );
}

interface ReachProperties {
  riverCategory?: MrsRtaRiverCategory | null;
  braidingIndex?: number | null;
  reachRiverLength?: number | null;
  reachValleyLength?: number | null;
  anabranchingIndex?: number | null;
  levelOfConfinement?: MrsRtaLevelOfConfinement | null;
  upstreamElevation?: number | null;
  downstreamElevation?: number | null;
  bedrockReach: boolean;
  coarsestBedMaterial?: MrsRtaCoarsestBedMaterial | null;
  averageBedMaterial?: MrsRtaAverageBedMaterial | null;
  riverTypeOverride?: MrsRtaRiverType | null;
}

function isReachProperties(
  reachProperties: unknown
): reachProperties is ReachProperties {
  return (
    isObject(reachProperties) &&
    hasOptionalKey(
      reachProperties,
      "riverCategory",
      isNullable(MrsRtaRiverCategoryEnum.isValue)
    ) &&
    hasOptionalKey(reachProperties, "braidingIndex", isNullable(isNumber)) &&
    hasOptionalKey(reachProperties, "reachRiverLength", isNullable(isNumber)) &&
    hasOptionalKey(
      reachProperties,
      "reachValleyLength",
      isNullable(isNumber)
    ) &&
    hasOptionalKey(
      reachProperties,
      "anabranchingIndex",
      isNullable(isNumber)
    ) &&
    hasOptionalKey(
      reachProperties,
      "levelOfConfinement",
      isNullable(MrsRtaLevelOfConfinementEnum.isValue)
    ) &&
    hasOptionalKey(
      reachProperties,
      "upstreamElevation",
      isNullable(isNumber)
    ) &&
    hasOptionalKey(
      reachProperties,
      "downstreamElevation",
      isNullable(isNumber)
    ) &&
    hasOptionalKey(reachProperties, "bedrockReach", isBoolean) &&
    hasOptionalKey(
      reachProperties,
      "coarsestBedMaterial",
      isNullable(MrsRtaCoarsestBedMaterialEnum.isValue)
    ) &&
    hasOptionalKey(
      reachProperties,
      "averageBedMaterial",
      isNullable(MrsRtaAverageBedMaterialEnum.isValue)
    ) &&
    hasOptionalKey(
      reachProperties,
      "riverTypeOverride",
      isNullable(MrsRtaRiverTypeEnum.isValue)
    )
  );
}

interface PartialData {
  surveyDetails: SurveyDetails;
  reachProperties: ReachProperties;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasKey(data, "surveyDetails", isSurveyDetails) &&
    hasOptionalKey(data, "reachProperties", isReachProperties)
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data.surveyDetails?.riverName ?? "Unknown River",
      data.surveyDetails?.reachName ?? "Unknown Reach",
      data.surveyDetails?.projectName,
      data.surveyDetails?.projectCode,
    ]
      .filter(x => x != null)
      .join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(
    (data: PartialData) => data.surveyDetails.referenceDate ?? null
  );
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map(
    (data: PartialData) =>
      data.surveyDetails.upstreamLocation ??
      data.surveyDetails.downstreamLocation ??
      null
  );
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
    surveyDetails: {
      ...data.surveyDetails,
      referenceDate: nowTimestamp(),
    },
  }));
}
