import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isProjectTransferResult,
  isSurveyTransferResult,
  isUserTransferResult,
  ProjectTransferRequest,
  ProjectTransferResult,
  SurveyTransferRequest,
  SurveyTransferResult,
  UserTransferRequest,
  UserTransferResult,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs } from "../../response";

const basePath = "/transfer/v2";

export function user(
  apiParams: ApiParams,
  body: UserTransferRequest
): IO<UserTransferResult> {
  return fetch
    .post({ apiParams, url: { path: `${basePath}/user` }, body })
    .flatMap(contentAs("UserTransferResult", isUserTransferResult));
}

export function survey(
  apiParams: ApiParams,
  body: SurveyTransferRequest
): IO<SurveyTransferResult> {
  return fetch
    .post({ apiParams, url: { path: `${basePath}/survey` }, body })
    .flatMap(contentAs("SurveyTransferResult", isSurveyTransferResult));
}

export function project(
  apiParams: ApiParams,
  body: ProjectTransferRequest
): IO<ProjectTransferResult> {
  return fetch
    .post({ apiParams, url: { path: `${basePath}/project` }, body })
    .flatMap(contentAs("ProjectTransferResult", isProjectTransferResult));
}
