// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type OutfallSafariActivity =
  | "Start"
  | "Outfall"
  | "End"
  | "Inaccessible";

const Start: OutfallSafariActivity = "Start";
const Outfall: OutfallSafariActivity = "Outfall";
const End: OutfallSafariActivity = "End";
const Inaccessible: OutfallSafariActivity = "Inaccessible";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OutfallSafariActivityEnum = {
  ...createEnum("OutfallSafariActivity", [
    [Start, "Mark start of survey"],
    [Outfall, "Outfall assessment"],
    [End, "Mark end of survey"],
    [Inaccessible, "Inaccessible area"],
  ]),
  Start,
  Outfall,
  End,
  Inaccessible,
};

export default OutfallSafariActivityEnum;
