// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { InvitationStatus } from "../types.generated";

const Accepted: InvitationStatus = "Accepted";
const Canceled: InvitationStatus = "Canceled";
const Expired: InvitationStatus = "Expired";
const Pending: InvitationStatus = "Pending";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const InvitationStatusEnum = {
  ...createEnum("InvitationStatus", [
    [Accepted, "Accepted"],
    [Canceled, "Canceled"],
    [Expired, "Expired"],
    [Pending, "Pending"],
  ]),
  Accepted,
  Canceled,
  Expired,
  Pending,
};

export default InvitationStatusEnum;
