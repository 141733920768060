// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { WorkspaceAccessDeniedReason } from "../types.generated";

const AccountDisabled: WorkspaceAccessDeniedReason = "AccountDisabled";
const WorkspaceDisabled: WorkspaceAccessDeniedReason = "WorkspaceDisabled";
const NoAccess: WorkspaceAccessDeniedReason = "NoAccess";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WorkspaceAccessDeniedReasonEnum = {
  ...createEnum("WorkspaceAccessDeniedReason", [
    [AccountDisabled, "Account-Disabled"],
    [WorkspaceDisabled, "Workspace-Disabled"],
    [NoAccess, "No-Access"],
  ]),
  AccountDisabled,
  WorkspaceDisabled,
  NoAccess,
};

export default WorkspaceAccessDeniedReasonEnum;
