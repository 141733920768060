import { Attribution, StandardAttribution, OsAttribution } from "./type";

function thisYear(): string {
  return `${new Date().getFullYear()}`;
}

function escape(string: string): string {
  return string
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

function years(from: string | undefined, to: string | undefined): string {
  if (from == null) {
    if (to == null) {
      return "";
    } else {
      return escape(to);
    }
  } else {
    if (to == null) {
      return `${escape(from)}-${thisYear()}`;
    } else {
      return `${escape(from)}-${escape(to)}`;
    }
  }
}

function link(url: string | undefined, label: string): string {
  return url == null ? label : `<a href="${url}" target="_blank">${label}</a>`;
}

export function attributionHtml(attr: Attribution): string {
  switch (attr.type) {
    case "StandardAttribution": {
      const { what, who, url, from, to } = attr;

      return link(
        url,
        `${escape(what)} Copyright ${years(from, to)} ${escape(who)}`
      );
    }

    case "OsAttribution": {
      const { what, url, from, to } = attr;

      return link(url, `${escape(what)} Crown Copyright ${years(from, to)}`);
    }
  }
}

export function standardAttribution(
  options: Omit<StandardAttribution, "type">
): StandardAttribution {
  return { type: "StandardAttribution", ...options };
}

export function osAttribution(
  options: Omit<OsAttribution, "type">
): OsAttribution {
  return { type: "OsAttribution", ...options };
}
