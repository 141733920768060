// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesTypicalHedgeWidth = "N" | "M" | "W";

const Narrow: CesTypicalHedgeWidth = "N";
const Medium: CesTypicalHedgeWidth = "M";
const Wide: CesTypicalHedgeWidth = "W";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesTypicalHedgeWidthEnum = {
  ...createEnum("CesTypicalHedgeWidth", [
    [Narrow, "<1.5m"],
    [Medium, "1.5-2.5m"],
    [Wide, ">2.5m"],
  ]),
  Narrow,
  Medium,
  Wide,
};

export default CesTypicalHedgeWidthEnum;
