// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type OutfallSafariInaccessibleAreaLength =
  | "Under10m"
  | "Under50m"
  | "Over50m";

const Under10m: OutfallSafariInaccessibleAreaLength = "Under10m";
const Under50m: OutfallSafariInaccessibleAreaLength = "Under50m";
const Over50m: OutfallSafariInaccessibleAreaLength = "Over50m";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OutfallSafariInaccessibleAreaLengthEnum = {
  ...createEnum("OutfallSafariInaccessibleAreaLength", [
    [Under10m, "Less than 10m"],
    [Under50m, "Between 10m and 50m"],
    [Over50m, "Greater than 50m"],
  ]),
  Under10m,
  Under50m,
  Over50m,
};

export default OutfallSafariInaccessibleAreaLengthEnum;
