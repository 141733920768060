// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { KnownProjectFeature } from "../types.generated";

const LockAlias: KnownProjectFeature = "LockAlias";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const KnownProjectFeatureEnum = {
  ...createEnum("KnownProjectFeature", [
    [LockAlias, "Lock project alias (prevent editing)"],
  ]),
  LockAlias,
};

export default KnownProjectFeatureEnum;
