import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isMapLayerSummary,
  isSearchResults,
  MapId,
  MapLayerId,
  MapLayerSummary,
  SearchResults,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";

const basePath = "/map/layer/v2";

export function search(
  apiParams: ApiParams,
  map?: MapId
): IO<SearchResults<MapLayerSummary>> {
  return fetch
    .get({ apiParams, url: { path: basePath, query: { map } } })
    .chain(
      contentAs(
        "SearchResults<MapLayerSummary>",
        isSearchResults(isMapLayerSummary)
      )
    );
}

export function readOrFail(
  apiParams: ApiParams,
  layerId: MapLayerId
): IO<MapLayerSummary> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${layerId}` } })
    .flatMap(contentAs("MapLayerSummary", isMapLayerSummary));
}

export function readOption(
  apiParams: ApiParams,
  layerId: MapLayerId
): IO<Option<MapLayerSummary>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${layerId}` } })
    .flatMap(optionalContentAs("MapLayerSummary", isMapLayerSummary));
}
