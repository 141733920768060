/* eslint-disable jest/no-export */

import { Path } from "@cartographerio/types";
import {
  topoExpr,
  TopoExpr,
  Field,
  requiredIff,
  RuleLevel,
  textArea,
  TextField,
  textField,
} from ".";
import {
  hasKey,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";

export interface OtherSpecify<A> {
  selected: A;
  other?: string | null;
}

export function isOtherSpecify<A>(isA: (a: unknown) => a is A) {
  return function (value: unknown): value is OtherSpecify<A> {
    return (
      isObject(value) &&
      hasKey(value, "selected", isA) &&
      hasOptionalKey(value, "other", isNullable(isString))
    );
  };
}

export interface OtherSpecifyOptions {
  // Either specify this level:
  level?: RuleLevel;
  // Or specify these levels:
  requiredLevel?: RuleLevel;
  forbiddenLevel?: RuleLevel;
  // ---
  label: string;
  // Either specify this path:
  basePath?: Path;
  // Or specify these paths:
  thisPath?: Path;
  thatPath?: Path;
  // ---
  test: (value: unknown) => boolean;
  visible?: "auto" | TopoExpr<boolean>;
  help?: string | null;
  rows?: number;
}

export function otherSpecify(options: OtherSpecifyOptions): Field {
  const {
    level = "info",
    requiredLevel = level,
    forbiddenLevel = level,
    label,
    basePath = [],
    thisPath = [...basePath, "other"],
    thatPath: otherPath = [...basePath, "selected"],
    test: otherTest,
    visible: _visible,
    help = "If you selected 'Other' above, please describe.",
    rows = 1,
  } = options;

  const createField = (props: Omit<TextField, "type">) =>
    rows === 1 ? textField(props) : textArea({ ...props, rows });

  const visible: TopoExpr<boolean> | undefined =
    _visible === "auto"
      ? topoExpr(env => otherTest(env.get(otherPath)))
      : _visible;

  return createField({
    label,
    path: thisPath,
    visible,
    help: help ?? undefined,
    customRules: requiredIff({
      requiredLevel,
      forbiddenLevel,
      requiredMessage: "Please describe your selection.",
      forbiddenMessage:
        "Only enter a description if you selected 'Other' above.",
      enabled: visible,
      otherPath,
      otherTest,
    }),
  });
}
