// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityLandUse =
  | "Woodland"
  | "Heathland"
  | "Urban"
  | "TallHerbsOrScrub"
  | "GrasslandOrPasture"
  | "Agriculture"
  | "TilledLand"
  | "Other";

const Woodland: EsrtWaterQualityLandUse = "Woodland";
const Heathland: EsrtWaterQualityLandUse = "Heathland";
const Urban: EsrtWaterQualityLandUse = "Urban";
const TallHerbsOrScrub: EsrtWaterQualityLandUse = "TallHerbsOrScrub";
const GrasslandOrPasture: EsrtWaterQualityLandUse = "GrasslandOrPasture";
const Agriculture: EsrtWaterQualityLandUse = "Agriculture";
const TilledLand: EsrtWaterQualityLandUse = "TilledLand";
const Other: EsrtWaterQualityLandUse = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityLandUseEnum = {
  ...createEnum("EsrtWaterQualityLandUse", [
    [Woodland, "Woodland"],
    [Heathland, "Heathland"],
    [Urban, "Urban/residential/commercial/industrial"],
    [TallHerbsOrScrub, "Tall herbs/scrub"],
    [GrasslandOrPasture, "Grassland/pasture"],
    [Agriculture, "Agriculture (crops)"],
    [TilledLand, "Tilled land"],
    [Other, "Other"],
  ]),
  Woodland,
  Heathland,
  Urban,
  TallHerbsOrScrub,
  GrasslandOrPasture,
  Agriculture,
  TilledLand,
  Other,
};

export default EsrtWaterQualityLandUseEnum;
