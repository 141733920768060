import { checks } from "@cartographerio/permission";
import { Workspace } from "@cartographerio/types";
import { ReactElement } from "react";

import queries from "../../queries";
import RequirePermissionLink from "../components/RequirePermissionLink";
import { useApiParams } from "../contexts/auth";
import { routes } from "../routes";
import { useSuspenseQueryData } from "./useSuspenseQueryData";

export default function useProjectLimitReason(
  workspace: Workspace
): ReactElement | null {
  const apiParams = useApiParams();

  const { hasRoom: isUnderLimit } = useSuspenseQueryData(
    queries.workspace.limits.v1.checkProjects(apiParams, workspace.id)
  );

  return isUnderLimit ? null : (
    <>
      Your workspace has reached the project limit set on{" "}
      <RequirePermissionLink.Internal
        check={checks.billing.workspace(workspace.id)}
        to={routes.workspace.billing.settings.url([workspace.alias])}
        display="inline"
      >
        your Billing Setting page
      </RequirePermissionLink.Internal>
      . Increase the limit to invite more projects.
    </>
  );
}
