// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21VegetationStructure = "uniform" | "simple" | "complex";

const Uniform: Thames21VegetationStructure = "uniform";
const Simple: Thames21VegetationStructure = "simple";
const Complex: Thames21VegetationStructure = "complex";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21VegetationStructureEnum = {
  ...createEnum("Thames21VegetationStructure", [
    [Uniform, "Uniform"],
    [Simple, "Simple"],
    [Complex, "Complex"],
  ]),
  Uniform,
  Simple,
  Complex,
};

export default Thames21VegetationStructureEnum;
