// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesTidalFloodBarrier = "A" | "U" | "M" | "D";

const Absent: MrsMorphEstuariesTidalFloodBarrier = "A";
const Upstream: MrsMorphEstuariesTidalFloodBarrier = "U";
const MidModule: MrsMorphEstuariesTidalFloodBarrier = "M";
const Downstream: MrsMorphEstuariesTidalFloodBarrier = "D";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesTidalFloodBarrierEnum = {
  ...createEnum("MrsMorphEstuariesTidalFloodBarrier", [
    [Absent, "Absent"],
    [Upstream, "Upstream"],
    [MidModule, "Mid-module"],
    [Downstream, "Downstream"],
  ]),
  Absent,
  Upstream,
  MidModule,
  Downstream,
};

export default MrsMorphEstuariesTidalFloodBarrierEnum;
