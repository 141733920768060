// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessChemicalAssessmentForm =
  | "WaterClarity"
  | "Algar"
  | "PlantDistribution"
  | "WaterChemistryTest"
  | "BiologicalSampling";

const WaterClarity: NeLakeNaturalnessChemicalAssessmentForm = "WaterClarity";
const Algar: NeLakeNaturalnessChemicalAssessmentForm = "Algar";
const PlantDistribution: NeLakeNaturalnessChemicalAssessmentForm =
  "PlantDistribution";
const WaterChemistryTest: NeLakeNaturalnessChemicalAssessmentForm =
  "WaterChemistryTest";
const BiologicalSampling: NeLakeNaturalnessChemicalAssessmentForm =
  "BiologicalSampling";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessChemicalAssessmentFormEnum = {
  ...createEnum("NeLakeNaturalnessChemicalAssessmentForm", [
    [WaterClarity, "Water clarity"],
    [Algar, "Algae"],
    [PlantDistribution, "Plant distribution"],
    [WaterChemistryTest, "Water chemistry test"],
    [BiologicalSampling, "Biological sampling"],
  ]),
  WaterClarity,
  Algar,
  PlantDistribution,
  WaterChemistryTest,
  BiologicalSampling,
};

export default NeLakeNaturalnessChemicalAssessmentFormEnum;
