// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankSpecies =
  | "Betony"
  | "BirdsfootTrefoil"
  | "Bluebell"
  | "CommonCatsEar"
  | "CommonKnapweed"
  | "CommonSorrel"
  | "CornChamomile"
  | "CornMarigold"
  | "CornPoppy"
  | "Corncockle"
  | "Cornflower"
  | "CowParsley"
  | "DevilsBitScabious"
  | "FieldScabious"
  | "Foxglove"
  | "GreaterBirdsfootTrefoil"
  | "HedgeBedstraw"
  | "LadysBedstraw"
  | "MeadowButtercup"
  | "Meadowsweet"
  | "MuskMallow"
  | "OxeyeDaisy"
  | "PurpleLoosestrife"
  | "RaggedRobin"
  | "RedCampion"
  | "RedClover"
  | "RibwortPlantain"
  | "SelfHeal"
  | "TuftedVetch"
  | "VipersBugloss"
  | "WildCarrot"
  | "Yarrow"
  | "YellowFlagIris"
  | "YellowRattle"
  | "Other";

const Betony: NwcSeedbankSpecies = "Betony";
const BirdsfootTrefoil: NwcSeedbankSpecies = "BirdsfootTrefoil";
const Bluebell: NwcSeedbankSpecies = "Bluebell";
const CommonCatsEar: NwcSeedbankSpecies = "CommonCatsEar";
const CommonKnapweed: NwcSeedbankSpecies = "CommonKnapweed";
const CommonSorrel: NwcSeedbankSpecies = "CommonSorrel";
const CornChamomile: NwcSeedbankSpecies = "CornChamomile";
const CornMarigold: NwcSeedbankSpecies = "CornMarigold";
const CornPoppy: NwcSeedbankSpecies = "CornPoppy";
const Corncockle: NwcSeedbankSpecies = "Corncockle";
const Cornflower: NwcSeedbankSpecies = "Cornflower";
const CowParsley: NwcSeedbankSpecies = "CowParsley";
const DevilsBitScabious: NwcSeedbankSpecies = "DevilsBitScabious";
const FieldScabious: NwcSeedbankSpecies = "FieldScabious";
const Foxglove: NwcSeedbankSpecies = "Foxglove";
const GreaterBirdsfootTrefoil: NwcSeedbankSpecies = "GreaterBirdsfootTrefoil";
const HedgeBedstraw: NwcSeedbankSpecies = "HedgeBedstraw";
const LadysBedstraw: NwcSeedbankSpecies = "LadysBedstraw";
const MeadowButtercup: NwcSeedbankSpecies = "MeadowButtercup";
const Meadowsweet: NwcSeedbankSpecies = "Meadowsweet";
const MuskMallow: NwcSeedbankSpecies = "MuskMallow";
const OxeyeDaisy: NwcSeedbankSpecies = "OxeyeDaisy";
const PurpleLoosestrife: NwcSeedbankSpecies = "PurpleLoosestrife";
const RaggedRobin: NwcSeedbankSpecies = "RaggedRobin";
const RedCampion: NwcSeedbankSpecies = "RedCampion";
const RedClover: NwcSeedbankSpecies = "RedClover";
const RibwortPlantain: NwcSeedbankSpecies = "RibwortPlantain";
const SelfHeal: NwcSeedbankSpecies = "SelfHeal";
const TuftedVetch: NwcSeedbankSpecies = "TuftedVetch";
const VipersBugloss: NwcSeedbankSpecies = "VipersBugloss";
const WildCarrot: NwcSeedbankSpecies = "WildCarrot";
const Yarrow: NwcSeedbankSpecies = "Yarrow";
const YellowFlagIris: NwcSeedbankSpecies = "YellowFlagIris";
const YellowRattle: NwcSeedbankSpecies = "YellowRattle";
const Other: NwcSeedbankSpecies = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankSpeciesEnum = {
  ...createEnum("NwcSeedbankSpecies", [
    [Betony, "Betony"],
    [BirdsfootTrefoil, "Birdsfoot Trefoil"],
    [Bluebell, "Bluebell"],
    [CommonCatsEar, "Common Cat's Ear"],
    [CommonKnapweed, "Common Knapweed"],
    [CommonSorrel, "Common Sorrel"],
    [CornChamomile, "Corn Chamomile"],
    [CornMarigold, "Corn Marigold"],
    [CornPoppy, "Corn Poppy"],
    [Corncockle, "Corncockle"],
    [Cornflower, "Cornflower"],
    [CowParsley, "Cow Parsley"],
    [DevilsBitScabious, "Devil's Bit Scabious"],
    [FieldScabious, "Field Scabious"],
    [Foxglove, "Foxglove"],
    [GreaterBirdsfootTrefoil, "Greater Birdsfoot Trefoil"],
    [HedgeBedstraw, "Hedge Bedstraw"],
    [LadysBedstraw, "Lady's Bedstraw"],
    [MeadowButtercup, "Meadow Buttercup"],
    [Meadowsweet, "Meadowsweet"],
    [MuskMallow, "Musk Mallow"],
    [OxeyeDaisy, "Oxeye Daisy"],
    [PurpleLoosestrife, "Purple Loosestrife"],
    [RaggedRobin, "Ragged Robin"],
    [RedCampion, "Red Campion"],
    [RedClover, "Red clover"],
    [RibwortPlantain, "Ribwort Plantain"],
    [SelfHeal, "Self-Heal"],
    [TuftedVetch, "Tufted Vetch"],
    [VipersBugloss, "Vipers Bugloss"],
    [WildCarrot, "Wild Carrot"],
    [Yarrow, "Yarrow"],
    [YellowFlagIris, "Yellow Flag Iris"],
    [YellowRattle, "Yellow Rattle"],
    [Other, "Other"],
  ]),
  Betony,
  BirdsfootTrefoil,
  Bluebell,
  CommonCatsEar,
  CommonKnapweed,
  CommonSorrel,
  CornChamomile,
  CornMarigold,
  CornPoppy,
  Corncockle,
  Cornflower,
  CowParsley,
  DevilsBitScabious,
  FieldScabious,
  Foxglove,
  GreaterBirdsfootTrefoil,
  HedgeBedstraw,
  LadysBedstraw,
  MeadowButtercup,
  Meadowsweet,
  MuskMallow,
  OxeyeDaisy,
  PurpleLoosestrife,
  RaggedRobin,
  RedCampion,
  RedClover,
  RibwortPlantain,
  SelfHeal,
  TuftedVetch,
  VipersBugloss,
  WildCarrot,
  Yarrow,
  YellowFlagIris,
  YellowRattle,
  Other,
};

export default NwcSeedbankSpeciesEnum;
