// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopTreeSpecies =
  | "Oak"
  | "Willow"
  | "Blackthorn"
  | "Hawthorn"
  | "Ash"
  | "Other"
  | "Unknown";

const Oak: UclPitStopTreeSpecies = "Oak";
const Willow: UclPitStopTreeSpecies = "Willow";
const Blackthorn: UclPitStopTreeSpecies = "Blackthorn";
const Hawthorn: UclPitStopTreeSpecies = "Hawthorn";
const Ash: UclPitStopTreeSpecies = "Ash";
const Other: UclPitStopTreeSpecies = "Other";
const Unknown: UclPitStopTreeSpecies = "Unknown";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopTreeSpeciesEnum = {
  ...createEnum("UclPitStopTreeSpecies", [
    [Oak, "Oak"],
    [Willow, "Willow"],
    [Blackthorn, "Blackthorn"],
    [Hawthorn, "Hawthorn"],
    [Ash, "Ash"],
    [Other, "Other"],
    [Unknown, "Don't know"],
  ]),
  Oak,
  Willow,
  Blackthorn,
  Hawthorn,
  Ash,
  Other,
  Unknown,
};

export default UclPitStopTreeSpeciesEnum;
