// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopAccess = "Footpath" | "Road" | "Track";

const Footpath: UclPitStopAccess = "Footpath";
const Road: UclPitStopAccess = "Road";
const Track: UclPitStopAccess = "Track";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopAccessEnum = {
  ...createEnum("UclPitStopAccess", [
    [Footpath, "Footpath"],
    [Road, "Road"],
    [Track, "Track"],
  ]),
  Footpath,
  Road,
  Track,
};

export default UclPitStopAccessEnum;
