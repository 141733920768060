// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsWildInvadersManagement =
  | "NoSign"
  | "RemovedManually"
  | "RemovedMechanically"
  | "DugOut"
  | "Sprayed"
  | "StemInjected"
  | "Other";

const NoSign: MrsWildInvadersManagement = "NoSign";
const RemovedManually: MrsWildInvadersManagement = "RemovedManually";
const RemovedMechanically: MrsWildInvadersManagement = "RemovedMechanically";
const DugOut: MrsWildInvadersManagement = "DugOut";
const Sprayed: MrsWildInvadersManagement = "Sprayed";
const StemInjected: MrsWildInvadersManagement = "StemInjected";
const Other: MrsWildInvadersManagement = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsWildInvadersManagementEnum = {
  ...createEnum("MrsWildInvadersManagement", [
    [NoSign, "No sign of treatment"],
    [RemovedManually, "Removed by hand"],
    [RemovedMechanically, "Removed mechanically (e.g. strimmed or cut)"],
    [DugOut, "Dug out"],
    [Sprayed, "Sprayed"],
    [StemInjected, "Stem injected"],
    [Other, "Other treatment"],
  ]),
  NoSign,
  RemovedManually,
  RemovedMechanically,
  DugOut,
  Sprayed,
  StemInjected,
  Other,
};

export default MrsWildInvadersManagementEnum;
