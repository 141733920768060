import { Point } from "@cartographerio/geometry";
import {
  PlainDate,
  ProjectRef,
  SurveyStatus,
  TeamRef,
  WorkspaceRef,
} from "@cartographerio/types";
import { SearchResultsFormat, SortOrder } from "../params";

export type SurveySearchKeyV3 =
  | "workspace"
  | "project"
  | "team"
  | "description"
  | "status"
  | "surveyor"
  | "timestamp"
  | "created"
  | "updated";

export interface SurveySearchOptionsV3 {
  workspace: WorkspaceRef;
  project: ProjectRef;
  team: TeamRef;
  q: string;
  from: PlainDate;
  to: PlainDate;
  sw: Point;
  ne: Point;
  status: SurveyStatus;
  order: SortOrder<SurveySearchKeyV3>;
  skip: number;
  limit: number;
  format: SearchResultsFormat;
}

export function formatPointParam(point: Point): string {
  return `${point.coordinates[1]},${point.coordinates[0]}`;
}
