// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityPollutionSource =
  | "InactiveOutfalls"
  | "ActiveOutfalls"
  | "OutfallOdour"
  | "OutfallDiscolouration"
  | "RiverBankCollapse"
  | "Trampling"
  | "GreyWater"
  | "RoadRunoff"
  | "SoilRunoff"
  | "FarmRunoff"
  | "Livestock"
  | "Other";

const InactiveOutfalls: EsrtWaterQualityPollutionSource = "InactiveOutfalls";
const ActiveOutfalls: EsrtWaterQualityPollutionSource = "ActiveOutfalls";
const OutfallOdour: EsrtWaterQualityPollutionSource = "OutfallOdour";
const OutfallDiscolouration: EsrtWaterQualityPollutionSource =
  "OutfallDiscolouration";
const RiverBankCollapse: EsrtWaterQualityPollutionSource = "RiverBankCollapse";
const Trampling: EsrtWaterQualityPollutionSource = "Trampling";
const GreyWater: EsrtWaterQualityPollutionSource = "GreyWater";
const RoadRunoff: EsrtWaterQualityPollutionSource = "RoadRunoff";
const SoilRunoff: EsrtWaterQualityPollutionSource = "SoilRunoff";
const FarmRunoff: EsrtWaterQualityPollutionSource = "FarmRunoff";
const Livestock: EsrtWaterQualityPollutionSource = "Livestock";
const Other: EsrtWaterQualityPollutionSource = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityPollutionSourceEnum = {
  ...createEnum("EsrtWaterQualityPollutionSource", [
    [InactiveOutfalls, "Outfall present not discharging"],
    [ActiveOutfalls, "Outfall present discharging"],
    [OutfallOdour, "Outfall causing odour"],
    [OutfallDiscolouration, "Outfall causing discolouration"],
    [RiverBankCollapse, "Collapsed riverbanks"],
    [Trampling, "Livestock trampling riverbanks"],
    [GreyWater, "Grey water presence (poss. misconnection)"],
    [RoadRunoff, "Road run-off"],
    [SoilRunoff, "Soil run-off"],
    [FarmRunoff, "Farm run-off"],
    [Livestock, "Livestock in watercourse"],
    [Other, "Other"],
  ]),
  InactiveOutfalls,
  ActiveOutfalls,
  OutfallOdour,
  OutfallDiscolouration,
  RiverBankCollapse,
  Trampling,
  GreyWater,
  RoadRunoff,
  SoilRunoff,
  FarmRunoff,
  Livestock,
  Other,
};

export default EsrtWaterQualityPollutionSourceEnum;
