import { hasKey, isNullable, isObject } from "@cartographerio/guard";
import { checkExhausted, raise } from "@cartographerio/util";
import { subDays, subMonths, subYears } from "date-fns";
import { omit } from "lodash";
import {
  PlainDate,
  dateToPlainDate,
  isValidPlainDate,
  nowPlainDate,
  parseAnyPlainDate,
  plainDateToDate,
} from "./plainDate";
import { internalError } from "../error";

export interface ClosedInterval {
  from: PlainDate;
  to: PlainDate;
}
export interface OpenInterval {
  from: PlainDate | null;
  to: PlainDate | null;
}

export type NamedInterval =
  | { type: "week" | "month" | "year" }
  | ({ type: "custom" } & OpenInterval);

export const isClosedInterval = (value: unknown): value is ClosedInterval =>
  isObject(value) &&
  hasKey(value, "from", isValidPlainDate) &&
  hasKey(value, "to", isValidPlainDate);

export const isOpenInterval = (value: unknown): value is OpenInterval =>
  isObject(value) &&
  hasKey(value, "from", isNullable(isValidPlainDate)) &&
  hasKey(value, "to", isNullable(isValidPlainDate));

export const emptyOpenInterval: OpenInterval = { from: null, to: null };

export function closedInterval(from: PlainDate, to: PlainDate): ClosedInterval {
  return { from, to };
}

export function openInterval(
  from: PlainDate | null,
  to: PlainDate | null
): OpenInterval {
  return { from, to };
}

export function namedInterval(type: "week" | "month" | "year"): NamedInterval;
export function namedInterval(
  type: "custom",
  from: PlainDate | null,
  to: PlainDate | null
): NamedInterval;
export function namedInterval(
  type: NamedInterval["type"],
  from?: PlainDate | null,
  to?: PlainDate | null
): NamedInterval {
  return type === "custom"
    ? { type, from: from ?? null, to: to ?? null }
    : { type };
}

export function parseNamedInterval(
  value: string,
  sep = "..."
): NamedInterval | undefined {
  if (value === "week" || value === "month" || value === "year") {
    return { type: value };
  } else {
    const [from, to] = value
      .split(sep)
      .map(str => parseAnyPlainDate(str) ?? null);
    return from != null || to != null
      ? { type: "custom", from, to }
      : undefined;
  }
}

export function formatNamedInterval(
  namedInterval: NamedInterval,
  sep = "..."
): string {
  switch (namedInterval.type) {
    case "week":
    case "month":
    case "year":
      return namedInterval.type;
    case "custom":
      return [namedInterval.from, namedInterval.to].join(sep);
    default:
      return checkExhausted(namedInterval);
  }
}

export function namedToOpenInterval(
  namedInterval: NamedInterval,
  now = nowPlainDate()
): OpenInterval {
  const nowDate =
    plainDateToDate(now) ??
    raise<Date>(internalError(`Cannot convert ${now} to a Date`));
  switch (namedInterval.type) {
    case "week":
      return { from: dateToPlainDate(subDays(nowDate, 7)), to: now };
    case "month":
      return { from: dateToPlainDate(subMonths(nowDate, 1)), to: now };
    case "year":
      return { from: dateToPlainDate(subYears(nowDate, 1)), to: now };
    case "custom":
      return omit(namedInterval, "type");
    default:
      return checkExhausted(namedInterval);
  }
}
