// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityFlowConditions =
  | "Surging"
  | "Steady"
  | "Slow"
  | "Still";

const Surging: ArrtWaterQualityFlowConditions = "Surging";
const Steady: ArrtWaterQualityFlowConditions = "Steady";
const Slow: ArrtWaterQualityFlowConditions = "Slow";
const Still: ArrtWaterQualityFlowConditions = "Still";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityFlowConditionsEnum = {
  ...createEnum("ArrtWaterQualityFlowConditions", [
    [Surging, "Surging"],
    [Steady, "Steady"],
    [Slow, "Slow"],
    [Still, "Still"],
  ]),
  Surging,
  Steady,
  Slow,
  Still,
};

export default ArrtWaterQualityFlowConditionsEnum;
