import { Uuid } from "./types.generated";

const uuidRegex =
  /([a-z0-9]{8})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{12})/i;

export function isValidUuid(value: unknown): value is Uuid {
  return (
    typeof value === "string" && value.length === 36 && uuidRegex.test(value)
  );
}
