import { Option } from "@cartographerio/fp";
import { isArrayOf, isRecordOf } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  CompleteQualificationHistoryEvent,
  Email,
  QualificationHistoryEvent,
  QualificationId,
  QualificationRef,
  SearchResults,
  UserId,
  isCompleteQualificationHistoryEvent,
  isQualificationHistoryEvent,
  isQualificationId,
  isSearchResults,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";
import { PartialParams } from "../../params";
import { QualificationHistorySearchOptions } from "./common";

const basePath = "/qualification/history/v1";

export function search(
  apiParams: ApiParams,
  options: PartialParams<QualificationHistorySearchOptions> = {}
): IO<SearchResults<CompleteQualificationHistoryEvent>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(
      contentAs(
        "SearchResults<CompleteQualificationHistoryEvent>",
        isSearchResults(isCompleteQualificationHistoryEvent)
      )
    );
}

export function searchUrl(
  options: PartialParams<QualificationHistorySearchOptions> = {}
): UrlParts {
  return {
    path: basePath,
    query: { ...options },
  };
}

export function readOrFail(
  apiParams: ApiParams,
  user: UserId | Email,
  qualification: QualificationRef
): IO<QualificationHistoryEvent[]> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${user}/${qualification}` } })
    .flatMap(
      contentAs(
        "QualificationHistoryEvent[]",
        isArrayOf(isQualificationHistoryEvent)
      )
    );
}

export function readOption(
  apiParams: ApiParams,
  user: UserId | Email,
  qualification: QualificationRef
): IO<Option<QualificationHistoryEvent[]>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${user}/${qualification}` } })
    .flatMap(
      optionalContentAs(
        "QualificationHistoryEvent[]",
        isArrayOf(isQualificationHistoryEvent)
      )
    );
}

export function readAll(
  apiParams: ApiParams,
  user: UserId | Email
): IO<Record<QualificationId, QualificationHistoryEvent[]>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${user}` } })
    .flatMap(
      contentAs(
        "Record<QualificationId, QualificationHistoryEvent[]>",
        isRecordOf(isQualificationId, isArrayOf(isQualificationHistoryEvent))
      )
    );
}

export function saveAll(
  apiParams: ApiParams,
  user: UserId | Email,
  history: Record<QualificationId, QualificationHistoryEvent[]>
): IO<void> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${user}` },
      body: history,
    })
    .void();
}
