// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessEmergentFringe =
  | "None"
  | "Occasional"
  | "Isolated"
  | "Extensive"
  | "Continuous";

const None: NeLakeNaturalnessEmergentFringe = "None";
const Occasional: NeLakeNaturalnessEmergentFringe = "Occasional";
const Isolated: NeLakeNaturalnessEmergentFringe = "Isolated";
const Extensive: NeLakeNaturalnessEmergentFringe = "Extensive";
const Continuous: NeLakeNaturalnessEmergentFringe = "Continuous";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessEmergentFringeEnum = {
  ...createEnum("NeLakeNaturalnessEmergentFringe", [
    [None, "No emergent fringe"],
    [Occasional, "Occasional clumps of emergent vegetation"],
    [
      Isolated,
      "Large but isolated beds of emergent vegetation up to 33% of the perimeter",
    ],
    [
      Extensive,
      "Extensive beds of emergent vegetation 33-66% of the perimeter",
    ],
    [
      Continuous,
      "Continuous emergent vegetation covering >66% of the perimeter",
    ],
  ]),
  None,
  Occasional,
  Isolated,
  Extensive,
  Continuous,
};

export default NeLakeNaturalnessEmergentFringeEnum;
