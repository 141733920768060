// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsSurveyType =
  | "monitoring"
  | "pre-project"
  | "post-project"
  | "post-recovery"
  | "training"
  | "scenario"
  | "not-applicable";

const Monitoring: UrsSurveyType = "monitoring";
const PreProject: UrsSurveyType = "pre-project";
const PostProject: UrsSurveyType = "post-project";
const PostRecovery: UrsSurveyType = "post-recovery";
const Training: UrsSurveyType = "training";
const Scenario: UrsSurveyType = "scenario";
const NotApplicable: UrsSurveyType = "not-applicable";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsSurveyTypeEnum = {
  ...createEnum("UrsSurveyType", [
    [Monitoring, "Monitoring"],
    [PreProject, "Pre-project"],
    [PostProject, "Post-project"],
    [PostRecovery, "Post-recovery"],
    [Training, "Training"],
    [Scenario, "Scenario"],
    [NotApplicable, "Not applicable"],
  ]),
  Monitoring,
  PreProject,
  PostProject,
  PostRecovery,
  Training,
  Scenario,
  NotApplicable,
};

export default UrsSurveyTypeEnum;
