import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  ProjectId,
  ProjectMapSettings,
  ProjectRef,
  WorkspaceRef,
  isProjectMapSettings,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";

const basePath = "/project/map-settings/v1";

function endpointUrl(project: ProjectRef, workspace?: WorkspaceRef): UrlParts {
  return { path: `${basePath}/${project}`, query: { workspace } };
}

export function readOption(
  apiParams: ApiParams,
  project: ProjectRef,
  workspace?: WorkspaceRef
): IO<Option<ProjectMapSettings>> {
  return fetch
    .get({ apiParams, url: endpointUrl(project, workspace) })
    .chain(optionalContentAs("ProjectMapSettings", isProjectMapSettings));
}

export function readOrNull(
  apiParams: ApiParams,
  project: ProjectRef,
  workspace?: WorkspaceRef
): IO<ProjectMapSettings | null> {
  return readOption(apiParams, project, workspace).map(opt => opt.getOrNull());
}

export function save(
  apiParams: ApiParams,
  project: ProjectId,
  settings: ProjectMapSettings
): IO<void> {
  return fetch
    .put({
      apiParams,
      url: endpointUrl(project),
      body: settings,
    })
    .void();
}

export function remove(apiParams: ApiParams, project: ProjectId): IO<void> {
  return fetch
    .remove({
      apiParams,
      url: endpointUrl(project),
    })
    .void();
}
