// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityPollutionSource =
  | "None"
  | "ActiveOutfalls"
  | "InactiveOutfalls"
  | "OutfallOdour"
  | "OutfallDiscolouration"
  | "RiverBankCollapse"
  | "DogAccess"
  | "LivestockAccess"
  | "GreyWater"
  | "RoadRunoff"
  | "SoilRunoff"
  | "FarmRunoff"
  | "Other";

const None: ArrtWaterQualityPollutionSource = "None";
const ActiveOutfalls: ArrtWaterQualityPollutionSource = "ActiveOutfalls";
const InactiveOutfalls: ArrtWaterQualityPollutionSource = "InactiveOutfalls";
const OutfallOdour: ArrtWaterQualityPollutionSource = "OutfallOdour";
const OutfallDiscolouration: ArrtWaterQualityPollutionSource =
  "OutfallDiscolouration";
const RiverBankCollapse: ArrtWaterQualityPollutionSource = "RiverBankCollapse";
const DogAccess: ArrtWaterQualityPollutionSource = "DogAccess";
const LivestockAccess: ArrtWaterQualityPollutionSource = "LivestockAccess";
const GreyWater: ArrtWaterQualityPollutionSource = "GreyWater";
const RoadRunoff: ArrtWaterQualityPollutionSource = "RoadRunoff";
const SoilRunoff: ArrtWaterQualityPollutionSource = "SoilRunoff";
const FarmRunoff: ArrtWaterQualityPollutionSource = "FarmRunoff";
const Other: ArrtWaterQualityPollutionSource = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityPollutionSourceEnum = {
  ...createEnum("ArrtWaterQualityPollutionSource", [
    [None, "None"],
    [ActiveOutfalls, "Outfall Discharging"],
    [InactiveOutfalls, "Outfall Not Discharging"],
    [OutfallOdour, "Outfall Causing Odour"],
    [OutfallDiscolouration, "Outfall Causing Discolouration"],
    [RiverBankCollapse, "Collapsed River Banks"],
    [DogAccess, "Dog Access to River"],
    [LivestockAccess, "Livestock/Cattle Access to River"],
    [GreyWater, "Grey Water (Misconnection)"],
    [RoadRunoff, "Road Run-Off"],
    [SoilRunoff, "Soil Run-Off"],
    [FarmRunoff, "Farm Run-Off (Slurry/Silage)"],
    [Other, "Other"],
  ]),
  None,
  ActiveOutfalls,
  InactiveOutfalls,
  OutfallOdour,
  OutfallDiscolouration,
  RiverBankCollapse,
  DogAccess,
  LivestockAccess,
  GreyWater,
  RoadRunoff,
  SoilRunoff,
  FarmRunoff,
  Other,
};

export default ArrtWaterQualityPollutionSourceEnum;
