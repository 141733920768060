// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { BillingType } from "../types.generated";

const Free: BillingType = "Free";
const Commercial: BillingType = "Commercial";
const NonCommercial: BillingType = "NonCommercial";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BillingTypeEnum = {
  ...createEnum("BillingType", [
    [Free, "Free"],
    [Commercial, "Commercial"],
    [NonCommercial, "Non-Commercial"],
  ]),
  Free,
  Commercial,
  NonCommercial,
};

export default BillingTypeEnum;
