import {
  BillingInterval,
  CurrencyAmount,
  InvoiceTotal,
  unsafeCurrencyAmount,
} from "@cartographerio/types";

import { formatCurrencyAmount } from "./format";

export function invoiceTotalAmount(invoiceTotal: InvoiceTotal): CurrencyAmount {
  return unsafeCurrencyAmount(
    invoiceTotal.excludingTax + invoiceTotal.totalTax
  );
}

export function formatAmount(
  amount: CurrencyAmount,
  billingInterval: BillingInterval
): string {
  return billingInterval === "Monthly"
    ? formatCurrencyAmount(amount)
    : formatCurrencyAmount(unsafeCurrencyAmount(amount * 10));
}

export function pluralize(num: number, unit: string) {
  return num === 1 ? unit : `${unit}s`;
}

export function calculateNewLimit(
  usage: number,
  pLimit: number | null | undefined
): number {
  return pLimit != null ? Math.min(usage, pLimit) : usage;
}
