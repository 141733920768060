import { isOtherSpecify, OtherSpecify } from "@cartographerio/topo-form";
import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isArrayOf,
  isBoolean,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  ArrtWaterQualityBankVegetation,
  ArrtWaterQualityBankVegetationEnum,
  ArrtWaterQualityChannelSubstrate,
  ArrtWaterQualityChannelSubstrateEnum,
  ArrtWaterqualityChannelVegetation,
  ArrtWaterQualityFlowConditions,
  ArrtWaterQualityFlowConditionsEnum,
  ArrtWaterQualityFlowObstacle,
  ArrtWaterQualityFlowObstacleEnum,
  ArrtWaterQualityInvasivePlant,
  ArrtWaterQualityInvasivePlantEnum,
  ArrtWaterQualityLandUse,
  ArrtWaterQualityLandUseEnum,
  ArrtWaterQualityNitrate,
  ArrtWaterQualityNitrateEnum,
  ArrtWaterQualityPollutionEvidence,
  ArrtWaterQualityPollutionEvidenceEnum,
  ArrtWaterQualityPollutionSource,
  ArrtWaterQualityPollutionSourceEnum,
  ArrtWaterQualityRainfall,
  ArrtWaterQualityRainfallEnum,
  ArrtWaterQualityWaterbodyTypeEnum,
  ArrtWaterQualityWaterLevel,
  ArrtWaterQualityWaterLevelEnum,
  ArrtWaterQualityWildlife,
  ArrtWaterQualityWildlifeEnum,
} from "@cartographerio/inventory-enums";
import {
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  Timestamp,
} from "@cartographerio/types";

export type PartialData = {
  recorded?: Timestamp | null;
  details: {
    location?: Point | null;
    river?: string | null;
    site?: string | null;
    recentRain?: ArrtWaterQualityRainfall | null;
    photographs: AttachmentFolder;
  };
  ecosystem: {
    landUse: OtherSpecify<ArrtWaterQualityLandUse[]>;
    bankVegetation: OtherSpecify<ArrtWaterQualityBankVegetation[]>;
    invasivePlants: OtherSpecify<ArrtWaterQualityInvasivePlant[]>;
    wildlife: OtherSpecify<ArrtWaterQualityWildlife[]>;
  };
  pollution: {
    pollutionSources: OtherSpecify<ArrtWaterQualityPollutionSource[]>;
    pollutionEvidence: OtherSpecify<ArrtWaterQualityPollutionEvidence[]>;
  };
  riverChannel: {
    estimatedWidth?: number | null;
    estimatedDepth?: number | null;
    dominantSubstrate: OtherSpecify<ArrtWaterQualityChannelSubstrate | null>;
    channelVegetation: OtherSpecify<ArrtWaterqualityChannelVegetation | null>;
    flowConditions?: ArrtWaterQualityFlowConditions | null;
    waterLevel?: ArrtWaterQualityWaterLevel | null;
    obstacles: ArrtWaterQualityFlowObstacle[];
    sampleTaken?: boolean | null;
    temperature?: number | null;
    dissolvedSolids?: number | null;
    phosphate?: number | null;
    turbidity?: number | null;
    nitrate?: ArrtWaterQualityNitrate | null;
  };
  notes?: string | null;
};

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasKey(
      data,
      "details",
      (details: unknown): details is PartialData["details"] =>
        isObject(details) &&
        hasOptionalKey(details, "river", isNullable(isString)) &&
        hasOptionalKey(details, "site", isNullable(isString)) &&
        hasOptionalKey(details, "location", isNullable(isPoint)) &&
        hasKey(
          details,
          "waterbodyType",
          isOtherSpecify(isNullable(ArrtWaterQualityWaterbodyTypeEnum.isValue))
        ) &&
        hasOptionalKey(
          details,
          "recentRain",
          isNullable(ArrtWaterQualityRainfallEnum.isValue)
        ) &&
        hasKey(details, "photographs", isAttachmentFolder)
    ) &&
    hasKey(
      data,
      "ecosystem",
      (ecosystem: unknown): ecosystem is PartialData["ecosystem"] =>
        isObject(ecosystem) &&
        hasKey(
          ecosystem,
          "landUse",
          isOtherSpecify(isArrayOf(ArrtWaterQualityLandUseEnum.isValue))
        ) &&
        hasKey(
          ecosystem,
          "bankVegetation",
          isOtherSpecify(isArrayOf(ArrtWaterQualityBankVegetationEnum.isValue))
        ) &&
        hasKey(
          ecosystem,
          "invasivePlants",
          isOtherSpecify(isArrayOf(ArrtWaterQualityInvasivePlantEnum.isValue))
        ) &&
        hasKey(
          ecosystem,
          "wildlife",
          isOtherSpecify(isArrayOf(ArrtWaterQualityWildlifeEnum.isValue))
        )
    ) &&
    hasKey(
      data,
      "pollution",
      (pollution: unknown): pollution is PartialData["pollution"] =>
        isObject(pollution) &&
        hasKey(
          pollution,
          "pollutionSources",
          isOtherSpecify(isArrayOf(ArrtWaterQualityPollutionSourceEnum.isValue))
        ) &&
        hasKey(
          pollution,
          "pollutionEvidence",
          isOtherSpecify(
            isArrayOf(ArrtWaterQualityPollutionEvidenceEnum.isValue)
          )
        )
    ) &&
    hasKey(
      data,
      "riverChannel",
      (riverChannel: unknown): riverChannel is PartialData["riverChannel"] =>
        isObject(riverChannel) &&
        hasOptionalKey(riverChannel, "estimatedWidth", isNullable(isNumber)) &&
        hasOptionalKey(riverChannel, "estimatedDepth", isNullable(isNumber)) &&
        hasKey(
          riverChannel,
          "dominantSubstrate",
          isOtherSpecify(
            isNullable(ArrtWaterQualityChannelSubstrateEnum.isValue)
          )
        ) &&
        hasOptionalKey(
          riverChannel,
          "flowConditions",
          isNullable(ArrtWaterQualityFlowConditionsEnum.isValue)
        ) &&
        hasOptionalKey(
          riverChannel,
          "waterLevel",
          isNullable(ArrtWaterQualityWaterLevelEnum.isValue)
        ) &&
        hasKey(
          riverChannel,
          "obstacles",
          isArrayOf(ArrtWaterQualityFlowObstacleEnum.isValue)
        ) &&
        hasOptionalKey(riverChannel, "sampleTaken", isNullable(isBoolean)) &&
        hasOptionalKey(riverChannel, "temperature", isNullable(isNumber)) &&
        hasOptionalKey(riverChannel, "dissolvedSolids", isNullable(isNumber)) &&
        hasOptionalKey(riverChannel, "phosphate", isNullable(isNumber)) &&
        hasOptionalKey(riverChannel, "turbidity", isNullable(isNumber)) &&
        hasOptionalKey(
          riverChannel,
          "nitrate",
          isNullable(ArrtWaterQualityNitrateEnum.isValue)
        )
    ) &&
    hasOptionalKey(data, "notes", isNullable(isString))
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialArrtWaterQualityData"
  )(data).map(data =>
    [
      data.details.river ?? "Unknown River",
      data.details.site ?? "Unknown Site",
      data.details.location
        ? safePointToNgr(data.details.location)
        : "Unknown NGR",
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialArrtWaterQualityData"
  )(data).map(data => data.details.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialArrtWaterQualityData"
  )(data).map(data => data.recorded ?? null);
}

const emptyArrayOtherSpecify = { selected: [], other: null };

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return Result.guard(
    isPartialData,
    "PartialArrtWaterQualityData"
  )(data).map(
    data =>
      ({
        recorded: null,
        details: {
          ...data.details,
          recentRain: null,
        },
        ecosystem: {
          ...data.ecosystem,
          wildlife: emptyArrayOtherSpecify,
          invasivePlants: emptyArrayOtherSpecify,
        },
        pollution: {
          ...data.pollution,
          pollutionSources: emptyArrayOtherSpecify,
          pollutionEvidence: emptyArrayOtherSpecify,
        },
        riverChannel: {
          ...data.riverChannel,
          flowConditions: null,
          waterLevel: null,
          obstacles: [],
          sampleTaken: null,
          temperature: null,
          dissolvedSolids: null,
          phosphate: null,
          turbidity: null,
          nitrate: null,
        },
        notes: null,
      }) satisfies PartialData
  );
}
