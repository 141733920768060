// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeRestorationPrioritiesBiologicalMeasure =
  | "ControlInvasiveSpecies"
  | "ReduceFishStocking"
  | "RestoreNaturalBiologicalAssemblages";

const ControlInvasiveSpecies: NeLakeRestorationPrioritiesBiologicalMeasure =
  "ControlInvasiveSpecies";
const ReduceFishStocking: NeLakeRestorationPrioritiesBiologicalMeasure =
  "ReduceFishStocking";
const RestoreNaturalBiologicalAssemblages: NeLakeRestorationPrioritiesBiologicalMeasure =
  "RestoreNaturalBiologicalAssemblages";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeRestorationPrioritiesBiologicalMeasureEnum = {
  ...createEnum("NeLakeRestorationPrioritiesBiologicalMeasure", [
    [ControlInvasiveSpecies, "Control non-native invasive species"],
    [ReduceFishStocking, "Reduce the intensity of or halt fish stocking"],
    [
      RestoreNaturalBiologicalAssemblages,
      "Restore natural biological assemblages e.g. through biomanipulation",
    ],
  ]),
  ControlInvasiveSpecies,
  ReduceFishStocking,
  RestoreNaturalBiologicalAssemblages,
};

export default NeLakeRestorationPrioritiesBiologicalMeasureEnum;
