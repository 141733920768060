// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityEstimatedDepth =
  | "D00_03"
  | "D03_06"
  | "D06_09"
  | "D09_12"
  | "D12Plus";

const D00_03: EsrtWaterQualityEstimatedDepth = "D00_03";
const D03_06: EsrtWaterQualityEstimatedDepth = "D03_06";
const D06_09: EsrtWaterQualityEstimatedDepth = "D06_09";
const D09_12: EsrtWaterQualityEstimatedDepth = "D09_12";
const D12Plus: EsrtWaterQualityEstimatedDepth = "D12Plus";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityEstimatedDepthEnum = {
  ...createEnum("EsrtWaterQualityEstimatedDepth", [
    [D00_03, "0.0 to 0.3m"],
    [D03_06, "0.3 to 0.6m"],
    [D06_09, "0.6 to 0.9m"],
    [D09_12, "0.9 to 1.2m"],
    [D12Plus, ">1.2m"],
  ]),
  D00_03,
  D03_06,
  D06_09,
  D09_12,
  D12Plus,
};

export default EsrtWaterQualityEstimatedDepthEnum;
