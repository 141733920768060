import { isPosition } from "../position";
import {
  Geometry,
  GeometryAtom,
  GeometryAtomType,
  GeometryCollection,
  GeometryType,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from "./type";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export function isPoint(v: any): v is Point {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "Point" &&
    "coordinates" in v &&
    isPosition(v.coordinates)
  );
}

export function isMultiPoint(v: any): v is MultiPoint {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "MultiPoint" &&
    "coordinates" in v &&
    Array.isArray(v.coordinates) &&
    v.coordinates.every(isPosition)
  );
}

export function isLineString(v: any): v is LineString {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "LineString" &&
    "coordinates" in v &&
    Array.isArray(v.coordinates) &&
    v.coordinates.every(isPosition)
  );
}

export function isMultiLineString(v: any): v is MultiLineString {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "MultiLineString" &&
    "coordinates" in v &&
    Array.isArray(v.coordinates) &&
    v.coordinates.every((i: any) => Array.isArray(i) && i.every(isPosition))
  );
}

export function isPolygon(v: any): v is Polygon {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "Polygon" &&
    "coordinates" in v &&
    Array.isArray(v.coordinates) &&
    v.coordinates.every((i: any) => Array.isArray(i) && i.every(isPosition))
  );
}

export function isMultiPolygon(v: any): v is MultiPolygon {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "MultiPolygon" &&
    "coordinates" in v &&
    Array.isArray(v.coordinates) &&
    v.coordinates.every(
      (i: any) =>
        Array.isArray(i) &&
        i.every((i: any) => Array.isArray(i) && i.every(isPosition))
    )
  );
}

export function isGeometryCollection(v: any): v is GeometryCollection {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "MultiPolygon" &&
    "geometries" in v &&
    Array.isArray(v.geometries) &&
    v.geometries.every(isGeometry)
  );
}

export function isGeometryAtom(v: any): v is GeometryAtom {
  return (
    isPosition(v) ||
    isPoint(v) ||
    isMultiPoint(v) ||
    isLineString(v) ||
    isMultiLineString(v) ||
    isPolygon(v) ||
    isMultiPolygon(v)
  );
}

export function isGeometry(v: any): v is Geometry {
  return isGeometryAtom(v) || isGeometryCollection(v);
}

export function isGeometryAtomType(v: any): v is GeometryAtomType {
  switch (v) {
    case "Point":
      return true;
    case "MultiPoint":
      return true;
    case "LineString":
      return true;
    case "MultiLineString":
      return true;
    case "Polygon":
      return true;
    case "MultiPolygon":
      return true;
    default:
      return false;
  }
}

export function isGeometryType(v: any): v is GeometryType {
  return isGeometryAtomType(v) || v === "GeometryCollection";
}

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-explicit-any */
