// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21LitterMetric =
  | "count"
  | "presence"
  | "polystyreneCover"
  | "smallPlasticCount";

const Count: Thames21LitterMetric = "count";
const Presence: Thames21LitterMetric = "presence";
const PolystyreneCover: Thames21LitterMetric = "polystyreneCover";
const SmallPlasticCount: Thames21LitterMetric = "smallPlasticCount";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21LitterMetricEnum = {
  ...createEnum("Thames21LitterMetric", [
    [Count, "Item count"],
    [Presence, "Presence/absence"],
    [PolystyreneCover, "Polystyrene cover"],
    [SmallPlasticCount, "Small plastic count"],
  ]),
  Count,
  Presence,
  PolystyreneCover,
  SmallPlasticCount,
};

export default Thames21LitterMetricEnum;
