import { unsafeMapId } from "@cartographerio/topo-map";
import { mapSchemaWithDefaults } from "../../core";
import {
  neLakeNaturalnessLayer,
  neRiverNaturalnessLayer,
  priorityLakeHabitatLayer,
  priorityRiverHabitatLayer,
} from "./naturalnessHelpers";
import { MapBaseEnum } from "@cartographerio/types";

export const NeNaturalness = mapSchemaWithDefaults({
  mapId: unsafeMapId("neNaturalness"),
  title: "River/Stream/Lake Naturalness",
  defaultBase: MapBaseEnum.Satellite,
  layers: [
    priorityRiverHabitatLayer,
    neRiverNaturalnessLayer,
    priorityLakeHabitatLayer,
    neLakeNaturalnessLayer,
  ],
});
