// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopHistoricalKnowledge =
  | "NeverDries"
  | "DriesRarely"
  | "DriesMostyears"
  | "DriesAnnually"
  | "None";

const NeverDries: UclPitStopHistoricalKnowledge = "NeverDries";
const DriesRarely: UclPitStopHistoricalKnowledge = "DriesRarely";
const DriesMostyears: UclPitStopHistoricalKnowledge = "DriesMostyears";
const DriesAnnually: UclPitStopHistoricalKnowledge = "DriesAnnually";
const None: UclPitStopHistoricalKnowledge = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopHistoricalKnowledgeEnum = {
  ...createEnum("UclPitStopHistoricalKnowledge", [
    [NeverDries, "Never dries"],
    [DriesRarely, "Dries no more than two years in ten or only in drought"],
    [DriesMostyears, "Dries between three years in ten to most years"],
    [DriesAnnually, "Dries annually"],
    [None, "No previous knowledge ascertained"],
  ]),
  NeverDries,
  DriesRarely,
  DriesMostyears,
  DriesAnnually,
  None,
};

export default UclPitStopHistoricalKnowledgeEnum;
