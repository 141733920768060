/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { Semver, isSemver } from "../core/types.generated";

export interface Unchecked {
  reason: string;
}

export interface Success {
  actual: Semver;
  soft: Semver;
  hard: Semver;
}

export interface SoftFailure {
  actual: Semver;
  soft: Semver;
  hard: Semver;
}

export interface HardFailure {
  actual: Semver;
  soft: Semver;
  hard: Semver;
}

export type ClientVersionCheckResult =
  | ({ type: "HardFailure" } & HardFailure)
  | ({ type: "SoftFailure" } & SoftFailure)
  | ({ type: "Success" } & Success)
  | ({ type: "Unchecked" } & Unchecked);

export const isUnchecked = (v: any): v is Unchecked => {
  return (
    typeof v === "object" &&
    v != null &&
    "reason" in v &&
    typeof v.reason === "string"
  );
};

export const isSuccess = (v: any): v is Success => {
  return (
    typeof v === "object" &&
    v != null &&
    "actual" in v &&
    isSemver(v.actual) &&
    "soft" in v &&
    isSemver(v.soft) &&
    "hard" in v &&
    isSemver(v.hard)
  );
};

export const isSoftFailure = (v: any): v is SoftFailure => {
  return (
    typeof v === "object" &&
    v != null &&
    "actual" in v &&
    isSemver(v.actual) &&
    "soft" in v &&
    isSemver(v.soft) &&
    "hard" in v &&
    isSemver(v.hard)
  );
};

export const isHardFailure = (v: any): v is HardFailure => {
  return (
    typeof v === "object" &&
    v != null &&
    "actual" in v &&
    isSemver(v.actual) &&
    "soft" in v &&
    isSemver(v.soft) &&
    "hard" in v &&
    isSemver(v.hard)
  );
};

export const isClientVersionCheckResult = (
  v: any
): v is ClientVersionCheckResult => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "HardFailure" && isHardFailure(v)) ||
      (v.type === "SoftFailure" && isSoftFailure(v)) ||
      (v.type === "Success" && isSuccess(v)) ||
      (v.type === "Unchecked" && isUnchecked(v)))
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
