import {
  NgrDigits,
  ngrToOsgb36,
  osgb36ToNgr,
  osgb36ToWgs84,
  wgs84ToOsgb36,
} from "../position";
import { createPoint, point } from "./create";
import { Point } from "./type";

export type GpsDigits = 0 | 1 | 2 | 3 | 4 | 5 | 6;
const defaultGpsDigits: GpsDigits = 6;

const defaultNgrDigits: NgrDigits = 10;

export function pointToPrecision(
  p: Point,
  digits: GpsDigits = defaultGpsDigits
): Point {
  const [lon, lat] = p.coordinates;
  return point(
    Number.parseFloat(lon.toFixed(digits)),
    Number.parseFloat(lat.toFixed(digits))
  );
}

export function formatLatLng(
  p: Point,
  digits: GpsDigits = defaultGpsDigits,
  separator: string = ", "
): string {
  const [lon, lat] = p.coordinates;
  return `${lat.toFixed(digits)}${separator}${lon.toFixed(digits)}`;
}

const ORDINAL_REGEX = /^-?\d+(\.\d+)?$/;

export function parseLatLng(
  value: string,
  separator: string = ","
): Point | null {
  const parts = value.split(separator).map(part => part.trim());
  if (parts.length !== 2) return null;

  try {
    const latitudeString = parts[0];
    if (!ORDINAL_REGEX.test(latitudeString)) return null;

    const longitudeString = parts[1];
    if (!ORDINAL_REGEX.test(longitudeString)) return null;

    const latitude = Number.parseFloat(latitudeString);
    if (latitude < -90 || latitude > 90) return null;

    const longitude = Number.parseFloat(longitudeString);
    if (longitude < -180 || longitude > 180) return null;

    return {
      type: "Point",
      coordinates: [longitude, latitude],
    };
  } catch (e) {
    return null;
  }
}

export function ngrToPoint(value: string): Point | undefined {
  try {
    const osgb = ngrToOsgb36(value);
    if (osgb == null) {
      return undefined;
    } else {
      const wgs = osgb36ToWgs84(osgb);
      return pointToPrecision(createPoint(wgs));
    }
  } catch (e) {
    return undefined;
  }
}

export function pointToNgr(
  point: Point,
  digits: NgrDigits = defaultNgrDigits,
  spaces: boolean = true
): string | undefined {
  try {
    return osgb36ToNgr(wgs84ToOsgb36(point.coordinates), digits, spaces);
  } catch (error) {
    return undefined;
  }
}

export function safePointToNgr(
  point: Point,
  digits: NgrDigits = defaultNgrDigits,
  spaces: boolean = true
): string {
  return pointToNgr(point, digits, spaces) ?? "Outside of the UK";
}

export function formatNgr(
  ngr: string,
  digits: NgrDigits = defaultNgrDigits,
  spaces: boolean = true
): string | undefined {
  try {
    const osgb = ngrToOsgb36(ngr);
    if (osgb == null) {
      return undefined;
    } else {
      return osgb36ToNgr(osgb, digits, spaces);
    }
  } catch (error) {
    return undefined;
  }
}

export function safeFormatNgr(
  ngr: string,
  digits: NgrDigits = 10,
  spaces: boolean = true
): string {
  return formatNgr(ngr, digits, spaces) ?? "Outside of the UK";
}
