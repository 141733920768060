import { BBox, Position } from "geojson";
import {
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from "./type";

export function point(lng: number, lat: number, ...extra: number[]): Point {
  return {
    type: "Point",
    coordinates: [lng, lat, ...extra],
  };
}

export function multiPoint(points: Point[]): MultiPoint {
  return {
    type: "MultiPoint",
    coordinates: points.map(point => point.coordinates),
  };
}

export function lineString(coordinates: Position[]): LineString {
  return {
    type: "LineString",
    coordinates,
  };
}

export function multiLineString(...lines: LineString[]): MultiLineString {
  return {
    type: "MultiLineString",
    coordinates: lines.map(line => line.coordinates),
  };
}

export function polygon(coordinates: Position[][]): Polygon {
  return {
    type: "Polygon",
    coordinates,
  };
}

export function multiPolygon(...polys: Polygon[]): MultiPolygon {
  return {
    type: "MultiPolygon",
    coordinates: polys.map(poly => poly.coordinates),
  };
}

export function createPoint(
  coordinates: Point["coordinates"],
  bbox?: BBox
): Point {
  return { type: "Point", coordinates, bbox };
}

export function createMultiPoint(
  coordinates: MultiPoint["coordinates"],
  bbox?: BBox
): MultiPoint {
  return { type: "MultiPoint", coordinates, bbox };
}

export function createLineString(
  coordinates: LineString["coordinates"],
  bbox?: BBox
): LineString {
  return { type: "LineString", coordinates, bbox };
}

export function createMultiLineString(
  coordinates: MultiLineString["coordinates"],
  bbox?: BBox
): MultiLineString {
  return { type: "MultiLineString", coordinates, bbox };
}

export function createPolygon(
  coordinates: Polygon["coordinates"],
  bbox?: BBox
): Polygon {
  return { type: "Polygon", coordinates, bbox };
}

export function createMultiPolygon(
  coordinates: MultiPolygon["coordinates"],
  bbox?: BBox
): MultiPolygon {
  return { type: "MultiPolygon", coordinates, bbox };
}
