// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopSubstrateDepth =
  | "Firm"
  | "D10"
  | "D10_50"
  | "D50_100"
  | "Gt100"
  | "NotCompleted";

const Firm: UclPitStopSubstrateDepth = "Firm";
const D10: UclPitStopSubstrateDepth = "D10";
const D10_50: UclPitStopSubstrateDepth = "D10_50";
const D50_100: UclPitStopSubstrateDepth = "D50_100";
const Gt100: UclPitStopSubstrateDepth = "Gt100";
const NotCompleted: UclPitStopSubstrateDepth = "NotCompleted";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopSubstrateDepthEnum = {
  ...createEnum("UclPitStopSubstrateDepth", [
    [Firm, "Firm"],
    [D10, "10cm"],
    [D10_50, "10-50cm"],
    [D50_100, "50cm-100cm"],
    [Gt100, "More than 100cm"],
    [NotCompleted, "Not completed"],
  ]),
  Firm,
  D10,
  D10_50,
  D50_100,
  Gt100,
  NotCompleted,
};

export default UclPitStopSubstrateDepthEnum;
