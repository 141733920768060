import { unsafePlanId } from "@cartographerio/types";
import { plan } from "./plan";
import { freePrice, perUserPrice, premiumPrice } from "./price";

export const StarterV1 = plan({
  id: unsafePlanId("StarterV1"),
  title: "Starter",
  price: freePrice,
  limits: {
    maxUsers: 2,
    maxProjects: 1,
    features: "Starter",
  },
  features: {
    ProjectsAndUsers: {
      Teams: false,
    },
    DataEntry: {
      UploadDataAndFiles: true,
      ExportAtAnyTime: true,
      WebSite: true,
      MobileApp: false,
      OfflineModeOnMobile: false,
    },
    LocationEntry: {
      ClickOnMap: true,
      ClickOnAerialPhoto: true,
      Geolocation: true,
      GpsOrNgr: true,
      What3words: false,
      PreDefinedSites: false,
    },
    Maps: {
      MapWidgets: true,
      OfflineMapsOnMobile: false,
      WhiteLabelMaps: false,
    },
    Integrations: {
      ArcgisOnline: false,
      GoogleDrive: false,
      Mailchimp: false,
      Custom: false,
    },
    BillingAndSupport: {
      SetupAssistance: true,
      PayByInvoice: false,
      StandardSupport: true,
      PrioritySupport: false,
      PremiumSupport: false,
    },
  },
});

export const StandardV1 = plan({
  id: unsafePlanId("StandardV1"),
  title: "Standard",
  price: perUserPrice(25),
  limits: {
    maxUsers: null,
    maxProjects: null,
    features: "Standard",
  },
  features: {
    ProjectsAndUsers: {
      Teams: false,
    },
    DataEntry: {
      UploadDataAndFiles: true,
      ExportAtAnyTime: true,
      WebSite: true,
      MobileApp: true,
      OfflineModeOnMobile: false,
    },
    LocationEntry: {
      ClickOnMap: true,
      ClickOnAerialPhoto: true,
      Geolocation: true,
      GpsOrNgr: true,
      What3words: false,
      PreDefinedSites: false,
    },
    Maps: {
      MapWidgets: true,
      OfflineMapsOnMobile: false,
      WhiteLabelMaps: false,
    },
    Integrations: {
      ArcgisOnline: false,
      GoogleDrive: false,
      Mailchimp: false,
      Custom: false,
    },
    BillingAndSupport: {
      SetupAssistance: true,
      PayByInvoice: false,
      StandardSupport: true,
      PrioritySupport: false,
      PremiumSupport: false,
    },
  },
});

export const PlusV1 = plan({
  id: unsafePlanId("PlusV1"),
  title: "Plus",
  price: perUserPrice(30),
  limits: {
    maxUsers: null,
    maxProjects: null,
    features: "Plus",
  },
  features: {
    ProjectsAndUsers: {
      Teams: false,
    },
    DataEntry: {
      UploadDataAndFiles: true,
      ExportAtAnyTime: true,
      WebSite: true,
      MobileApp: true,
      OfflineModeOnMobile: true,
    },
    LocationEntry: {
      ClickOnMap: true,
      ClickOnAerialPhoto: true,
      Geolocation: true,
      GpsOrNgr: true,
      What3words: true,
      PreDefinedSites: true,
    },
    Maps: {
      MapWidgets: true,
      OfflineMapsOnMobile: true,
      WhiteLabelMaps: false,
    },
    Integrations: {
      ArcgisOnline: true,
      GoogleDrive: true,
      Mailchimp: true,
      Custom: false,
    },
    BillingAndSupport: {
      SetupAssistance: true,
      PayByInvoice: false,
      StandardSupport: false,
      PrioritySupport: true,
      PremiumSupport: false,
    },
  },
});

export const PremiumV1 = plan({
  id: unsafePlanId("PremiumV1"),
  title: "Premium",
  price: premiumPrice,
  limits: {
    maxUsers: null,
    maxProjects: null,
    features: "Premium",
  },
  features: {
    ProjectsAndUsers: {
      Teams: true,
    },
    DataEntry: {
      UploadDataAndFiles: true,
      ExportAtAnyTime: true,
      WebSite: true,
      MobileApp: true,
      OfflineModeOnMobile: true,
    },
    LocationEntry: {
      ClickOnMap: true,
      ClickOnAerialPhoto: true,
      Geolocation: true,
      GpsOrNgr: true,
      What3words: true,
      PreDefinedSites: true,
    },
    Maps: {
      MapWidgets: true,
      OfflineMapsOnMobile: true,
      WhiteLabelMaps: true,
    },
    Integrations: {
      ArcgisOnline: true,
      GoogleDrive: true,
      Mailchimp: true,
      Custom: true,
    },
    BillingAndSupport: {
      SetupAssistance: true,
      PayByInvoice: true,
      StandardSupport: false,
      PrioritySupport: false,
      PremiumSupport: true,
    },
  },
});

export const allCommercialPlans = [StarterV1, StandardV1, PlusV1, PremiumV1];
