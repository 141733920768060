import { unsafePlanId } from "@cartographerio/types";
import { plan } from "./plan";
import { perProjectPrice, premiumPrice } from "./price";

export const NCStandardV1 = plan({
  id: unsafePlanId("NCStandardV1"),
  title: "Standard",
  price: perProjectPrice(100, 1, 50),
  limits: {
    maxUsers: null,
    maxProjects: null,
    features: "Standard",
  },
  features: {
    ProjectsAndUsers: {
      Teams: false,
    },
    DataEntry: {
      UploadDataAndFiles: true,
      ExportAtAnyTime: true,
      WebSite: true,
      MobileApp: true,
      OfflineModeOnMobile: false,
    },
    LocationEntry: {
      ClickOnMap: true,
      ClickOnAerialPhoto: true,
      Geolocation: true,
      GpsOrNgr: true,
      What3words: false,
      PreDefinedSites: false,
    },
    Maps: {
      MapWidgets: true,
      OfflineMapsOnMobile: false,
      WhiteLabelMaps: false,
    },
    Integrations: {
      ArcgisOnline: false,
      GoogleDrive: false,
      Mailchimp: false,
      Custom: false,
    },
    BillingAndSupport: {
      SetupAssistance: true,
      PayByInvoice: false,
      StandardSupport: true,
      PrioritySupport: false,
      PremiumSupport: false,
    },
  },
});

export const NCPlusV1 = plan({
  id: unsafePlanId("NCPlusV1"),
  title: "Plus",
  price: perProjectPrice(150, 2, 50),
  limits: {
    maxUsers: null,
    maxProjects: null,
    features: "Plus",
  },
  features: {
    ProjectsAndUsers: {
      Teams: false,
    },
    DataEntry: {
      UploadDataAndFiles: true,
      ExportAtAnyTime: true,
      WebSite: true,
      MobileApp: true,
      OfflineModeOnMobile: true,
    },
    LocationEntry: {
      ClickOnMap: true,
      ClickOnAerialPhoto: true,
      Geolocation: true,
      GpsOrNgr: true,
      What3words: true,
      PreDefinedSites: true,
    },
    Maps: {
      MapWidgets: true,
      OfflineMapsOnMobile: true,
      WhiteLabelMaps: false,
    },
    Integrations: {
      ArcgisOnline: true,
      GoogleDrive: true,
      Mailchimp: true,
      Custom: false,
    },
    BillingAndSupport: {
      SetupAssistance: true,
      PayByInvoice: false,
      StandardSupport: false,
      PrioritySupport: true,
      PremiumSupport: false,
    },
  },
});

export const NCPremiumV1 = plan({
  id: unsafePlanId("NCPremiumV1"),
  title: "Premium",
  price: premiumPrice,
  limits: {
    maxUsers: null,
    maxProjects: null,
    features: "Premium",
  },
  features: {
    ProjectsAndUsers: {
      Teams: true,
    },
    DataEntry: {
      UploadDataAndFiles: true,
      ExportAtAnyTime: true,
      WebSite: true,
      MobileApp: true,
      OfflineModeOnMobile: true,
    },
    LocationEntry: {
      ClickOnMap: true,
      ClickOnAerialPhoto: true,
      Geolocation: true,
      GpsOrNgr: true,
      What3words: true,
      PreDefinedSites: true,
    },
    Maps: {
      MapWidgets: true,
      OfflineMapsOnMobile: true,
      WhiteLabelMaps: true,
    },
    Integrations: {
      ArcgisOnline: true,
      GoogleDrive: true,
      Mailchimp: true,
      Custom: true,
    },
    BillingAndSupport: {
      SetupAssistance: true,
      PayByInvoice: true,
      StandardSupport: false,
      PrioritySupport: false,
      PremiumSupport: true,
    },
  },
});

export const allNonCommercialPlans = [NCStandardV1, NCPlusV1, NCPremiumV1];
