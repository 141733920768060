// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21LitterCategory =
  | "foodPlastic"
  | "foodMetal"
  | "foodTetraPak"
  | "foodPaperWoodCorkGlass"
  | "foodPolystyrene"
  | "foodOther"
  | "packagingPlastic"
  | "sewageMixed"
  | "smokingMixed"
  | "clothingMixed"
  | "miscMixed"
  | "fishing"
  | "unidentifiedPlastic"
  | "unidentifiedPolystyrene"
  | "unidentifiedCloth"
  | "unidentifiedFoam"
  | "glassOther"
  | "metalOther"
  | "largeMixed"
  | "buildingPlastic"
  | "domesticPlastic";

const FoodPlastic: Thames21LitterCategory = "foodPlastic";
const FoodMetal: Thames21LitterCategory = "foodMetal";
const FoodTetraPak: Thames21LitterCategory = "foodTetraPak";
const FoodPaperWoodCorkGlass: Thames21LitterCategory = "foodPaperWoodCorkGlass";
const FoodPolystyrene: Thames21LitterCategory = "foodPolystyrene";
const FoodOther: Thames21LitterCategory = "foodOther";
const PackagingPlastic: Thames21LitterCategory = "packagingPlastic";
const SewageMixed: Thames21LitterCategory = "sewageMixed";
const SmokingMixed: Thames21LitterCategory = "smokingMixed";
const ClothingMixed: Thames21LitterCategory = "clothingMixed";
const MiscMixed: Thames21LitterCategory = "miscMixed";
const Fishing: Thames21LitterCategory = "fishing";
const UnidentifiedPlastic: Thames21LitterCategory = "unidentifiedPlastic";
const UnidentifiedPolystyrene: Thames21LitterCategory =
  "unidentifiedPolystyrene";
const UnidentifiedCloth: Thames21LitterCategory = "unidentifiedCloth";
const UnidentifiedFoam: Thames21LitterCategory = "unidentifiedFoam";
const GlassOther: Thames21LitterCategory = "glassOther";
const MetalOther: Thames21LitterCategory = "metalOther";
const LargeMixed: Thames21LitterCategory = "largeMixed";
const BuildingPlastic: Thames21LitterCategory = "buildingPlastic";
const DomesticPlastic: Thames21LitterCategory = "domesticPlastic";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21LitterCategoryEnum = {
  ...createEnum("Thames21LitterCategory", [
    [FoodPlastic, "Food related: Plastic"],
    [FoodMetal, "Food related: Metal"],
    [FoodTetraPak, "Food related: Tetra pak"],
    [FoodPaperWoodCorkGlass, "Food related: Paper/wood/cork/glass"],
    [FoodPolystyrene, "Food related: Polystyrene"],
    [FoodOther, "Food related"],
    [PackagingPlastic, "Packaging (not food related): Plastic"],
    [SewageMixed, "Sewage related: Mixed"],
    [SmokingMixed, "Smoking related: Mixed"],
    [ClothingMixed, "Clothing: Mixed"],
    [MiscMixed, "Misc: Mixed"],
    [Fishing, "Fishing related"],
    [UnidentifiedPlastic, "Unidentified: Plastic"],
    [UnidentifiedPolystyrene, "Unidentified: Polystyrene"],
    [UnidentifiedCloth, "Unidentified: Cloth"],
    [UnidentifiedFoam, "Unidentified: Foam (synthetic)"],
    [GlassOther, "Glass"],
    [MetalOther, "Metal"],
    [LargeMixed, "Large items: Mixed"],
    [BuildingPlastic, "Building: Plastic"],
    [DomesticPlastic, "Domestic: Plastic"],
  ]),
  FoodPlastic,
  FoodMetal,
  FoodTetraPak,
  FoodPaperWoodCorkGlass,
  FoodPolystyrene,
  FoodOther,
  PackagingPlastic,
  SewageMixed,
  SmokingMixed,
  ClothingMixed,
  MiscMixed,
  Fishing,
  UnidentifiedPlastic,
  UnidentifiedPolystyrene,
  UnidentifiedCloth,
  UnidentifiedFoam,
  GlassOther,
  MetalOther,
  LargeMixed,
  BuildingPlastic,
  DomesticPlastic,
};

export default Thames21LitterCategoryEnum;
