// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { KnownWorkspaceFeature } from "../types.generated";

const EnableMobile: KnownWorkspaceFeature = "EnableMobile";
const OfflineMaps: KnownWorkspaceFeature = "OfflineMaps";
const EnableArcgisIntegration: KnownWorkspaceFeature =
  "EnableArcgisIntegration";
const EnableInvitationCodes: KnownWorkspaceFeature = "EnableInvitationCodes";
const EnableTeams: KnownWorkspaceFeature = "EnableTeams";
const PreferTeams: KnownWorkspaceFeature = "PreferTeams";
const LockAlias: KnownWorkspaceFeature = "LockAlias";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const KnownWorkspaceFeatureEnum = {
  ...createEnum("KnownWorkspaceFeature", [
    [EnableMobile, "Enable mobile app (standard feature)"],
    [OfflineMaps, "Enable offline maps on mobile (plus feature)"],
    [
      EnableArcgisIntegration,
      "Enable ArcGIS Online integration (plus feature)",
    ],
    [EnableInvitationCodes, "Enable invitation codes (plus feature)"],
    [EnableTeams, "Enable teams (premium feature)"],
    [PreferTeams, "Prefer teams in the UI (premium feature)"],
    [LockAlias, "Lock workspace alias (prevent editing)"],
  ]),
  EnableMobile,
  OfflineMaps,
  EnableArcgisIntegration,
  EnableInvitationCodes,
  EnableTeams,
  PreferTeams,
  LockAlias,
};

export default KnownWorkspaceFeatureEnum;
