// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityWaterLevel =
  | "Flood"
  | "High"
  | "Normal"
  | "Low"
  | "Dry";

const Flood: EsrtWaterQualityWaterLevel = "Flood";
const High: EsrtWaterQualityWaterLevel = "High";
const Normal: EsrtWaterQualityWaterLevel = "Normal";
const Low: EsrtWaterQualityWaterLevel = "Low";
const Dry: EsrtWaterQualityWaterLevel = "Dry";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityWaterLevelEnum = {
  ...createEnum("EsrtWaterQualityWaterLevel", [
    [Flood, "Flood out of bank"],
    [High, "High (still in channel)"],
    [Normal, "Normal"],
    [Low, "Low"],
    [Dry, "Dry"],
  ]),
  Flood,
  High,
  Normal,
  Low,
  Dry,
};

export default EsrtWaterQualityWaterLevelEnum;
