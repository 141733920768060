export type Tagged<A extends string> = string & { _tag_: A };

export function unsafeIsTagged<A extends string>(
  value: unknown
): value is Tagged<A> {
  return typeof value === "string";
}

export function untag<A extends string>(value: Tagged<A>): string {
  return value;
}

export function unsafeTag<A extends string>(value: string): Tagged<A> {
  return value as Tagged<A>;
}

/**
 * Workaround for defficiency in Bridges until we can fix it in the library itself.
 * Don't use this in hand-written Typescript code!
 *
 * Explanation:
 *
 * Bridges (the Scala library we use to generate Typescript code from Scala type definitions)
 * makes some assumptions about the shape of guard functions based on the shape of the types we're guarding.
 *
 * For a type `Tagged<A>`, the library expects a function of the following type:
 *
 *     `const isTaggedA = (isA: (a: any) => a is A) => (value: any) => value is Tagged<A>`
 *
 * Because the `A` in `Tagged<A>` is a phantom type, we don't actually need the `isA` parameter.
 * However, Bridges expects it to be there. We provide this hack that ignores `isA`.
 */
export function bridgesIsTaggedHack<A extends string>(
  _isA: (a: unknown) => a is A
) {
  return function (value: unknown): value is Tagged<A> {
    return typeof value === "string";
  };
}
