// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessFeature =
  | "BanksideFlushesOrSprings"
  | "RiparianTrees"
  | "WoodyMaterialInChannel"
  | "WaterfallOrCascade"
  | "NaturalMireStreamTransition"
  | "SinuousOrMultipleChannel"
  | "RiparianWetland"
  | "ExposedCobbleGravelOrSand"
  | "MossCoveredBoulders"
  | "FernFilledGhyllOrRavine"
  | "TufaDeposits";

const BanksideFlushesOrSprings: NeRiverNaturalnessFeature =
  "BanksideFlushesOrSprings";
const RiparianTrees: NeRiverNaturalnessFeature = "RiparianTrees";
const WoodyMaterialInChannel: NeRiverNaturalnessFeature =
  "WoodyMaterialInChannel";
const WaterfallOrCascade: NeRiverNaturalnessFeature = "WaterfallOrCascade";
const NaturalMireStreamTransition: NeRiverNaturalnessFeature =
  "NaturalMireStreamTransition";
const SinuousOrMultipleChannel: NeRiverNaturalnessFeature =
  "SinuousOrMultipleChannel";
const RiparianWetland: NeRiverNaturalnessFeature = "RiparianWetland";
const ExposedCobbleGravelOrSand: NeRiverNaturalnessFeature =
  "ExposedCobbleGravelOrSand";
const MossCoveredBoulders: NeRiverNaturalnessFeature = "MossCoveredBoulders";
const FernFilledGhyllOrRavine: NeRiverNaturalnessFeature =
  "FernFilledGhyllOrRavine";
const TufaDeposits: NeRiverNaturalnessFeature = "TufaDeposits";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessFeatureEnum = {
  ...createEnum("NeRiverNaturalnessFeature", [
    [BanksideFlushesOrSprings, "Bankside/riparian flushes or springs"],
    [RiparianTrees, "Riparian trees interacting with the channel"],
    [WoodyMaterialInChannel, "Woody material in-channel"],
    [WaterfallOrCascade, "Waterfall or cascade"],
    [NaturalMireStreamTransition, "Natural mire-stream transition"],
    [SinuousOrMultipleChannel, "Sinuous or multiple channel"],
    [RiparianWetland, "Riparian wetland"],
    [ExposedCobbleGravelOrSand, "Exposed cobble/gravel/sand"],
    [MossCoveredBoulders, "Moss-covered boulders"],
    [FernFilledGhyllOrRavine, "Fern-filled ghyll/ravine"],
    [
      TufaDeposits,
      "Tufa deposits (crumbly calcareous deposits associated with limestone and chalk springs)",
    ],
  ]),
  BanksideFlushesOrSprings,
  RiparianTrees,
  WoodyMaterialInChannel,
  WaterfallOrCascade,
  NaturalMireStreamTransition,
  SinuousOrMultipleChannel,
  RiparianWetland,
  ExposedCobbleGravelOrSand,
  MossCoveredBoulders,
  FernFilledGhyllOrRavine,
  TufaDeposits,
};

export default NeRiverNaturalnessFeatureEnum;
