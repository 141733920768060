import { Result } from "@cartographerio/fp";
import { Feature, isFeatureF, isPoint, Point } from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  Timestamp,
} from "@cartographerio/types";

interface SiteAttributes {
  name?: string | null;
  description?: string | null;
}

function isSiteAttributes(attrs: unknown): attrs is SiteAttributes {
  return (
    isObject(attrs) &&
    hasOptionalKey(attrs, "name", isNullable(isString)) &&
    hasOptionalKey(attrs, "description", isNullable(isString))
  );
}

interface Observations {
  recorded?: Timestamp | null;
  photographs: AttachmentFolder;
}

function isObservations(observations: unknown): observations is Observations {
  return (
    isObject(observations) &&
    hasOptionalKey(observations, "recorded", isNullable(isTimestamp)) &&
    hasKey(observations, "photographs", isAttachmentFolder)
  );
}

interface PartialData {
  site?: Feature<Point, SiteAttributes> | null;
  observations: Observations;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasKey(data, "observations", isObservations) &&
    hasOptionalKey(
      data,
      "site",
      isNullable(isFeatureF(isPoint, isSiteAttributes))
    )
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(
    (data: PartialData) =>
      `${data.site?.properties.name}, ${data.site?.properties.description}`
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.observations.recorded ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data.site?.geometry ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
    observations: {
      photographs: data.observations.photographs,
    },
  }));
}
