import {
  attributeGroup,
  booleanAttr,
  lookupBucket,
  marker,
  pointLayer,
  stringAttr,
  teamAttr,
  thresholdAttr,
  thresholdBucket,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const RiverflySite = mapSchemaWithDefaults({
  mapId: unsafeMapId("riverflySite"),
  title: "Riverfly Sites",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("riverflySite"),
      title: "Riverfly Sites",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("riverflySite"),
        attribution: [],
      }),
      defaultAttribute: "teamId",
      attributes: [
        attributeGroup({
          label: "Location",
          attributes: [
            stringAttr({
              attributeId: "catchment",
              label: "Catchment",
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "river",
              label: "River",
              buckets: "auto",
            }),
            stringAttr({ attributeId: "site", label: "Site", buckets: "auto" }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("riverflySite"),
            }),
          ],
        }),
        attributeGroup({
          label: "Trigger Levels",
          attributes: [
            booleanAttr({
              attributeId: "siteAccepted",
              label: "Site Accepted?",
              buckets: [
                lookupBucket(true, marker.fromColor("limegreen"), "Yes"),
                lookupBucket(false, marker.fromColor("red"), "No"),
              ],
            }),
            thresholdAttr({
              attributeId: "triggerLevel",
              label: "Trigger Level",
              decimalPlaces: 1,
              buckets: [
                thresholdBucket(0, marker.gradient(210, 50, 5 / 5)),
                thresholdBucket(4, marker.gradient(210, 50, 4 / 5)),
                thresholdBucket(8, marker.gradient(210, 50, 3 / 5)),
                thresholdBucket(12, marker.gradient(210, 50, 2 / 5)),
                thresholdBucket(16, marker.gradient(210, 50, 1 / 5)),
                thresholdBucket(null, marker.empty),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
