import { parseMarkdown } from "../core";
import { summaryHandlers } from "./handlers";
import { MarkdownSummary } from "./type";

export * from "./handlers";
export * from "./type";

export function markdownSummary(markdown: string): MarkdownSummary {
  return parseMarkdown(markdown, summaryHandlers());
}
