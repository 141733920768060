// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankTreeFeature =
  | "shade"
  | "trailingTreesOrShrubs"
  | "exposedRoots"
  | "fallenTrees"
  | "largeWood";

const Share: CesBankTreeFeature = "shade";
const BranchesTrailingInWater: CesBankTreeFeature = "trailingTreesOrShrubs";
const ExposedRoots: CesBankTreeFeature = "exposedRoots";
const FallenTrees: CesBankTreeFeature = "fallenTrees";
const LargeWood: CesBankTreeFeature = "largeWood";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankTreeFeatureEnum = {
  ...createEnum("CesBankTreeFeature", [
    [Share, "Shade"],
    [BranchesTrailingInWater, "Tree/shrub branches trailing into canal"],
    [ExposedRoots, "Exposed roots"],
    [FallenTrees, "Fallen trees"],
    [LargeWood, "Large wood (>1m long, >10cm diam)"],
  ]),
  Share,
  BranchesTrailingInWater,
  ExposedRoots,
  FallenTrees,
  LargeWood,
};

export default CesBankTreeFeatureEnum;
