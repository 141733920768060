// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeChalkRiversModificationType =
  | "Addition"
  | "Deletion"
  | "ChangeToHighCertainty";

const Addition: NeChalkRiversModificationType = "Addition";
const Deletion: NeChalkRiversModificationType = "Deletion";
const ChangeToHighCertainty: NeChalkRiversModificationType =
  "ChangeToHighCertainty";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeChalkRiversModificationTypeEnum = {
  ...createEnum("NeChalkRiversModificationType", [
    [Addition, "Addition"],
    [Deletion, "Deletion"],
    [ChangeToHighCertainty, "Change to high certainty"],
  ]),
  Addition,
  Deletion,
  ChangeToHighCertainty,
};

export default NeChalkRiversModificationTypeEnum;
