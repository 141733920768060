// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { BillingCalculationFilter } from "../types.generated";

const All: BillingCalculationFilter = "All";
const Selectable: BillingCalculationFilter = "Selectable";
const Suggested: BillingCalculationFilter = "Suggested";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BillingCalculationFilterEnum = {
  ...createEnum("BillingCalculationFilter", [
    [All, "All"],
    [Selectable, "Selectable"],
    [Suggested, "Suggested"],
  ]),
  All,
  Selectable,
  Suggested,
};

export default BillingCalculationFilterEnum;
