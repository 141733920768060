// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopWaterfowl = "High" | "Medium" | "Low" | "None";

const High: UclPitStopWaterfowl = "High";
const Medium: UclPitStopWaterfowl = "Medium";
const Low: UclPitStopWaterfowl = "Low";
const None: UclPitStopWaterfowl = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopWaterfowlEnum = {
  ...createEnum("UclPitStopWaterfowl", [
    [
      High,
      "High - Evidence of supplementary feeding in and around pond and/or numbers of ducks or geese present above 8",
    ],
    [Medium, "Medium - Evidence of 4 or more ducks or geese on the pond"],
    [Low, "Low - Presence of at least one duck or goose on the pond"],
    [None, "None - No evidence of geese or ducks"],
  ]),
  High,
  Medium,
  Low,
  None,
};

export default UclPitStopWaterfowlEnum;
