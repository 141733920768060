// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankVegetation =
  | "NoneBareEarth"
  | "NoneImpermeableSurface"
  | "Grass"
  | "Meadow"
  | "SandOrDune"
  | "TreesOrShrubs"
  | "Hedgerow"
  | "Unknown"
  | "Other";

const NoneBareEarth: NwcSeedbankVegetation = "NoneBareEarth";
const NoneImpermeableSurface: NwcSeedbankVegetation = "NoneImpermeableSurface";
const Grass: NwcSeedbankVegetation = "Grass";
const Meadow: NwcSeedbankVegetation = "Meadow";
const SandOrDune: NwcSeedbankVegetation = "SandOrDune";
const TreesOrShrubs: NwcSeedbankVegetation = "TreesOrShrubs";
const Hedgerow: NwcSeedbankVegetation = "Hedgerow";
const Unknown: NwcSeedbankVegetation = "Unknown";
const Other: NwcSeedbankVegetation = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankVegetationEnum = {
  ...createEnum("NwcSeedbankVegetation", [
    [NoneBareEarth, "None (Bare Earth)"],
    [NoneImpermeableSurface, "None (Impermeable Surface)"],
    [Grass, "Grass"],
    [Meadow, "Meadow"],
    [SandOrDune, "Sand / Dune"],
    [TreesOrShrubs, "Trees / Shrubs"],
    [Hedgerow, "Hedgerow"],
    [Unknown, "Unknown"],
    [Other, "Other"],
  ]),
  NoneBareEarth,
  NoneImpermeableSurface,
  Grass,
  Meadow,
  SandOrDune,
  TreesOrShrubs,
  Hedgerow,
  Unknown,
  Other,
};

export default NwcSeedbankVegetationEnum;
