import {
  attachmentAttr,
  attributeGroup,
  enumAttr,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  WrtWestcountryCsiFlowLevelEnum,
  WrtFordsLandUseEnum,
  WrtRainfallEnum,
} from "@cartographerio/inventory-enums";
import { unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const WrtFords = mapSchemaWithDefaults({
  mapId: unsafeMapId("wrtFords"),
  title: "FORDs",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("wrtFords"),
      title: "FORDs",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("wrtFords"),
        attribution: [],
      }),
      defaultAttribute: "rainfall",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("wrtFords"),
            }),
            surveyAttr("surveyId", "Survey"),
            attachmentAttr("photographs", "Photographs"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Data",
          attributes: [
            stringAttr({
              attributeId: "rainfall",
              label: "Rainfall (past 24h)",
              buckets: [
                lookupBucket(
                  WrtRainfallEnum.None,
                  marker.fromColor("#0099ff"),
                  WrtRainfallEnum.labelOf(WrtRainfallEnum.None)
                ),
                lookupBucket(
                  WrtRainfallEnum.Light,
                  marker.fromColor("#0066bb"),
                  WrtRainfallEnum.labelOf(WrtRainfallEnum.Light)
                ),
                lookupBucket(
                  WrtRainfallEnum.Heavy,
                  marker.fromColor("#003366"),
                  WrtRainfallEnum.labelOf(WrtRainfallEnum.Heavy)
                ),
              ],
            }),
            enumAttr({
              attributeId: "landUse",
              label: "Land Use",
              enum: WrtFordsLandUseEnum,
            }),
            stringAttr({
              attributeId: "flow",
              label: "Flow",
              buckets: [
                lookupBucket(
                  WrtWestcountryCsiFlowLevelEnum.Still,
                  marker.fromColor("#0099ff"),
                  WrtWestcountryCsiFlowLevelEnum.labelOf(
                    WrtWestcountryCsiFlowLevelEnum.Still
                  )
                ),
                lookupBucket(
                  WrtWestcountryCsiFlowLevelEnum.Slow,
                  marker.fromColor("#0077cc"),
                  WrtWestcountryCsiFlowLevelEnum.labelOf(
                    WrtWestcountryCsiFlowLevelEnum.Slow
                  )
                ),
                lookupBucket(
                  WrtWestcountryCsiFlowLevelEnum.Steady,
                  marker.fromColor("#005599"),
                  WrtWestcountryCsiFlowLevelEnum.labelOf(
                    WrtWestcountryCsiFlowLevelEnum.Steady
                  )
                ),
                lookupBucket(
                  WrtWestcountryCsiFlowLevelEnum.Surging,
                  marker.fromColor("#003366"),
                  WrtWestcountryCsiFlowLevelEnum.labelOf(
                    WrtWestcountryCsiFlowLevelEnum.Surging
                  )
                ),
              ],
            }),
            numberAttr({
              attributeId: "totalDissolvedSolids",
              label: "Total dissolved solids",
              unit: "ppm",
              buckets: [
                rangeBucket(null, 100, marker.fromColor("#ccc")),
                rangeBucket(100, 200, marker.fromColor("#bbb")),
                rangeBucket(200, 300, marker.fromColor("#aaa")),
                rangeBucket(300, 500, marker.fromColor("#999")),
                rangeBucket(500, 1000, marker.fromColor("#888")),
                rangeBucket(1000, 1500, marker.fromColor("#777")),
                rangeBucket(1500, null, marker.fromColor("#666")),
              ],
            }),
            numberAttr({
              attributeId: "turbidity",
              label: "Turbidity",
              unit: "NTU",
              buckets: [
                rangeBucket(null, 12, marker.fromColor("#00acec"), "<12"),
                rangeBucket(12, 25, marker.fromColor("#00aad0"), "12-25"),
                rangeBucket(25, 30, marker.fromColor("#66a6a2"), "25-30"),
                rangeBucket(30, 40, marker.fromColor("#a9a178"), "30-40"),
                rangeBucket(40, 50, marker.fromColor("#dd9948"), "40-50"),
                rangeBucket(50, 75, marker.fromColor("#f69532"), "50-75"),
                rangeBucket(75, 150, marker.fromColor("#cf8033"), "75-150"),
                rangeBucket(150, 240, marker.fromColor("#a26730"), "150-240"),
                rangeBucket(240, null, marker.fromColor("#754d2c"), ">240"),
              ],
            }),
            numberAttr({
              attributeId: "phosphate",
              label: "Phosphate",
              unit: "ppb",
              buckets: [
                rangeBucket(0, 99, marker.fromColor("#d0d0c5"), "0"),
                rangeBucket(100, 199, marker.fromColor("#a5b5ab"), "100"),
                rangeBucket(200, 299, marker.fromColor("#7a9797"), "200"),
                rangeBucket(300, 499, marker.fromColor("#5b8794"), "300"),
                rangeBucket(500, 999, marker.fromColor("#446a85"), "500"),
                rangeBucket(1000, 2499, marker.fromColor("#224b74"), "1000"),
                rangeBucket(2500, null, marker.fromColor("#123164"), "2500"),
              ],
            }),
            numberAttr({
              attributeId: "nitrate",
              label: "Nitrate",
              unit: "ppm",
              buckets: [
                rangeBucket(0, 9, marker.fromColor("#d2cac7"), "0"),
                rangeBucket(10, 29, marker.fromColor("#d1beb7"), "10"),
                rangeBucket(30, 59, marker.fromColor("#cfaaa7"), "30"),
                rangeBucket(60, 119, marker.fromColor("#c98f93"), "60"),
                rangeBucket(120, 199, marker.fromColor("#c5677c"), "120"),
                rangeBucket(200, null, marker.fromColor("#c15c6d"), "200"),
              ],
            }),
            numberAttr({
              attributeId: "ammonia",
              label: "Ammonia",
              unit: "ppm",
              buckets: [
                rangeBucket(0, 0.49, marker.fromColor("#d9cf81"), "0"),
                rangeBucket(0.5, 0.99, marker.fromColor("#d1ca6d"), "0.5"),
                rangeBucket(1.0, 2.99, marker.fromColor("#c2c489"), "1.0"),
                rangeBucket(3.0, 5.99, marker.fromColor("#9bb384"), "3.0"),
                rangeBucket(6.0, null, marker.fromColor("#6a9685"), "6.0"),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
