/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { Guard, GuardError, guardError } from "@cartographerio/guard";
import { prettyPrintJson } from "@cartographerio/util";
import { Option } from "./Option";

export type ResultLike<E, A> = A | Result<E, A>;

type AnyResultTuple<X> = readonly Result<X, unknown>[];

type UnpackResultTuple<A extends AnyResultTuple<unknown>> = {
  [K in keyof A]: A[K] extends Result<unknown, infer T> ? T : A[K];
};

type ExtractFailure<A extends AnyResultTuple<unknown>> =
  A extends AnyResultTuple<infer X> ? X : never;

function wrapGuardHint(guard: unknown, hint?: string): string {
  if (hint != null) {
    return hint;
  } else if (typeof guard === "string") {
    return guard;
  } else if (typeof guard === "function" && guard.name != null) {
    return guard.name;
  } else {
    return "<UnknownGuard>";
  }
}

/** Data type representing a value of type `A` or an error of type `E`.
 * Similar to the result of a `Promise` without the async execution.
 * Provides methods allowing you to safely chain operations
 * and capture errors without using exceptions.
 *
 * For example, the following code would
 * return `"Nope!"` if `value`` is `null` or `(value+1)*2` otherwise:
 *
 * ```
 * const value: number | null | undefined = ...;
 *
 * const result: string | number =
 *   Result.wrap(() => "Nope!")(value)
 *     .chain(n => n + 1)
 *     .chain(n => n * 2)
 *     .union();
 * ```
 */
export interface Result<E, A> {
  /** Call `func` on the value in this `Result` and return a new `Result`.
   * If this `Result` is a `Failure`, this is a noop.
   *
   * `func` can return a plain value or a `Result`.
   */
  chain<B>(func: (a: A) => ResultLike<E, B>): Result<E, B>;

  /** Call `func` on the value in this `Result` and return a new `Result`.
   * If this `Result` is a `Failure`, this is a noop.
   *
   * `func` must return a plain value.
   */
  map<B>(func: (a: A) => B): Result<E, B>;

  /** Call `func` on the value in this `Result` and return a new `Result`.
   * If this `Result` is a `Failure`, this is a noop.
   *
   * `func` must return a `Result` with the same error type `E`.
   */
  flatMap<B>(func: (a: A) => Result<E, B>): Result<E, B>;

  /** Call `func` on the value in this `Result` and return a new `Result`.
   * If this `Result` is a `Failure`, this is a noop.
   *
   * `func` must return a `Result` with an arbitrary error type.
   */
  flatMapU<F, B>(func: (a: A) => Result<F, B>): Result<E | F, B>;

  /** Call `func` on the value in this `Result` and ignore the result.
   * If this `Result` is a `Failure`, this is a noop.
   *
   * `func` can return a plain value or a `Result`.
   */
  tap(func: (a: A) => unknown): Result<E, A>;

  /** Call `func` on the error in this `Result` and return a new `Result`.
   * If this `Result` is a `Success`, this is a noop.
   *
   * `func` must return a plain error value.
   */
  mapError<F>(func: (a: E) => F): Result<F, A>;

  /** Call `func` on the error in this `Result` and ignore the result.
   * If this `Result` is a `Success`, this is a noop.
   *
   * `func` can return a plain value or a `Result`.
   */
  tapError(func: (e: E) => unknown): Result<E, A>;

  /** If this is a `Failure`, "catch" the error and call `func`.
   * Useful for converting a `Failure` to a `Success`.
   */
  recover<B>(func: (e: E) => ResultLike<E, B>): Result<E, A | B>;

  /** "Filter" this `Result` using the supplied `func``.
   * If `func` returns `true` return this `Result`.
   * If `func` returns `false` return a `Failure` containing the result of calling `orElse`.
   */
  filter(func: (a: A) => boolean, orElse: () => E): Result<E, A>;

  /** "Filter" this `Result` using the supplied `guard` function.
   * If `func` returns `true` return this `Result`.
   * If `func` returns `false` return a `Failure` containing the result of calling `orElse`.
   */
  guardWith<F, B>(guard: Guard<B>, orElse: (a: A) => F): Result<E | F, B>;

  /** "Filter" this `Result` using the supplied `guard` function.
   * If `func` returns `true` return this `Result`.
   * If `func` returns `false` return a `Failure` containing a `GuardError`.
   */
  guard<B>(guard: Guard<B>, hint?: string): Result<E | GuardError, B>;

  /** Call `ifFailure` or `ifSuccess` as appropriate. Return the result. */
  fold<R>(ifFailure: (e: E) => R, ifSuccess: (a: A) => R): R;

  /** Run `func` if this is a success. Otherwise no-op. */
  forEach(func: (a: A) => void): void;

  /** Return a `Result` with the success and failure cases swapped. */
  swap(): Result<A, E>;

  /** "Unwrap" this `Result` return either the value or error contained within. */
  union(): E | A;

  /** Returns `true` iff this `result` is a `Success`. */
  isSuccess(): boolean;

  /** Returns `true` iff this `result` is a `Failure`. */
  isFailure(): boolean;

  /** Converts this `Result` to an `Option` of a value: `Success` to `Some` and `Failure` to `None`. */
  toOption(): Option<A>;

  /** Converts this `Result` to an `Option` of an error: `Failure` to `Some` and `Success` to `None`. */
  toErrorOption(): Option<E>;

  /** Converts this `Result` to an `Array` of a value: `Success` to a non-empty array and `Failure` to an empty array. */
  toArray(): A[];

  /** Returns the value in this `Result` or throws an exception. */
  unsafeGet(): A;

  /** Returns the error in this `Result` or throws an exception. */
  unsafeError(): E;

  /** Returns the value in this `Result`, or the result of `func` if this is a `Failure`. */
  orElse<E, B>(func: () => Result<E, B>): Result<E, A | B>;

  /** Returns the value in this `Result`, or the result of `func` if this is a `Failure`. */
  getOrElse<B>(func: (error: E) => B): A | B;

  /** Returns the value in this `Result`, or throws the stored error if this is a `Failure`. */
  getOrThrow(): A;

  /** Returns the value in this `Result`, or `null` if this is a `Failure`. */
  getOrNull(): A | null;

  /** Returns the value in this `Result`, or `undefined` if this is a `Failure`. */
  getOrUndefined(): A | undefined;
}

export class Success<E, A> implements Result<E, A> {
  _value: A;

  constructor(value: A) {
    this._value = value;
  }

  chain = <B>(func: (a: A) => ResultLike<E, B>): Result<E, B> => {
    const b = func(this._value);
    if (b instanceof Success || b instanceof Failure) {
      return b;
    } else {
      return new Success(b as B);
    }
  };

  map = <B>(func: (a: A) => B): Result<E, B> => {
    return new Success(func(this._value));
  };

  flatMap = <B>(func: (a: A) => Result<E, B>): Result<E, B> => {
    return func(this._value);
  };

  flatMapU = <F, B>(func: (a: A) => Result<F, B>): Result<E | F, B> => {
    return func(this._value);
  };

  tap = (func: (a: A) => unknown): Result<E, A> => {
    return this.map(a => {
      func(a);
      return a;
    });
  };

  mapError = <F>(_func: (a: E) => F): Result<F, A> => {
    return this as unknown as Result<F, A>;
  };

  tapError = (_func: (e: E) => unknown): Result<E, A> => {
    return this;
  };

  recover = <B>(_func: (e: E) => ResultLike<E, B>): Result<E, A | B> => {
    return this as unknown as Result<E, A | B>;
  };

  filter = (func: (a: A) => boolean, orElse: () => E): Result<E, A> => {
    return func(this._value) ? this : new Failure(orElse());
  };

  guardWith = <F, B>(
    guard: Guard<B>,
    orElse: (a: A) => F
  ): Result<E | F, B> => {
    return guard(this._value)
      ? (this as unknown as Result<E | F, B>)
      : new Failure(orElse(this._value));
  };

  guard = <B>(guard: Guard<B>, hint?: string): Result<E | GuardError, B> => {
    return this.guardWith(guard, value =>
      guardError(wrapGuardHint(guard, hint), guard, value)
    );
  };

  fold = <R>(_ifFailure: (e: E) => R, ifSuccess: (a: A) => R): R => {
    return ifSuccess(this._value);
  };

  forEach = (func: (a: A) => void): void => {
    func(this._value);
  };

  swap = (): Result<A, E> => {
    return new Failure<A, E>(this._value);
  };

  union = (): E | A => {
    return this._value;
  };

  isSuccess = (): boolean => {
    return true;
  };

  isFailure = (): boolean => {
    return false;
  };

  toOption = (): Option<A> => {
    return Option.some(this._value);
  };

  toErrorOption = (): Option<E> => {
    return Option.none();
  };

  toArray = (): A[] => {
    return [this._value];
  };

  orElse = <E, B>(_func: () => Result<E, B>): Result<E, A | B> => {
    return this as unknown as Result<E, A | B>;
  };

  unsafeGet = (): A => {
    return this._value;
  };

  unsafeError = (): E => {
    throw new Error(
      `Called unsafeError on a Success: ${prettyPrintJson(this._value)}`
    );
  };

  getOrElse = <B>(_func: (error: E) => B): A | B => {
    return this._value;
  };

  getOrThrow = (): A => {
    return this._value;
  };

  getOrNull = (): A | null => {
    return this._value;
  };

  getOrUndefined = (): A | undefined => {
    return this._value;
  };
}

export class Failure<E, A> implements Result<E, A> {
  _error: E;

  constructor(error: E) {
    this._error = error;
  }

  chain = <B>(_func: (a: A) => ResultLike<E, B>): Result<E, B> => {
    return this as unknown as Result<E, B>;
  };

  map = <B>(_func: (a: A) => B): Result<E, B> => {
    return this as unknown as Result<E, B>;
  };

  flatMap = <B>(_func: (a: A) => Result<E, B>): Result<E, B> => {
    return this as unknown as Result<E, B>;
  };

  flatMapU = <F, B>(_func: (a: A) => Result<F, B>): Result<E | F, B> => {
    return new Failure<E | F, B>(this._error);
  };

  tap = (_func: (a: A) => unknown): Result<E, A> => {
    return this;
  };

  mapError = <F>(func: (a: E) => F): Result<F, A> => {
    return new Failure(func(this._error));
  };

  tapError = (func: (e: E) => unknown): Result<E, A> => {
    return this.mapError(e => {
      func(e);
      return e;
    });
  };

  recover = <B>(func: (e: E) => ResultLike<E, B>): Result<E, A | B> => {
    const b = func(this._error);
    if (b instanceof Success || b instanceof Failure) {
      return b as Result<E, B>;
    } else {
      return new Success(b as B);
    }
  };

  filter = (_func: (a: A) => boolean, _orElse: () => E): Result<E, A> => {
    return this;
  };

  guardWith = <F, B>(
    _guard: Guard<B>,
    _orElse: (a: A) => F
  ): Result<E | F, B> => {
    return this as unknown as Result<E | F, B>;
  };

  guard = <B>(_guard: Guard<B>, _hint?: string): Result<E | GuardError, B> => {
    return this as unknown as Result<E | GuardError, B>;
  };

  fold = <R>(ifFailure: (e: E) => R, _ifSuccess: (a: A) => R): R => {
    return ifFailure(this._error);
  };

  forEach = (_func: (a: A) => void): void => {
    return;
  };

  swap = (): Result<A, E> => {
    return new Success<A, E>(this._error);
  };

  union = (): E | A => {
    return this._error;
  };

  isSuccess = (): boolean => {
    return false;
  };

  isFailure = (): boolean => {
    return true;
  };

  toOption = (): Option<A> => {
    return Option.none();
  };

  toErrorOption = (): Option<E> => {
    return Option.some(this._error);
  };

  toArray = (): A[] => {
    return [];
  };

  orElse = <E, B>(func: () => Result<E, B>): Result<E, A | B> => {
    return func();
  };

  unsafeGet = (): A => {
    throw new Error(
      `Called unsafeGet on a Failure: ${prettyPrintJson(this._error)}`
    );
  };

  unsafeError = (): E => {
    return this._error;
  };

  getOrElse = <B>(func: (error: E) => B): A | B => {
    return func(this._error);
  };

  getOrThrow = (): A => {
    throw this._error;
  };

  getOrNull = (): A | null => {
    return null;
  };

  getOrUndefined = (): A | undefined => {
    return undefined;
  };
}

function pure<E, A>(value: A): Result<E, A> {
  return new Success(value);
}

function pass<E, A>(value: A): Result<E, A> {
  return new Success(value);
}

function fail<E, A>(error: E): Result<E, A> {
  return new Failure(error);
}

function wrap<E>(error: () => E) {
  return function <A>(value: A | null | undefined): Result<E, A> {
    return value == null ? fail(error()) : pure(value);
  };
}

function cond(cond: boolean) {
  return function <E, A>(fail: () => E, pass: () => A): Result<E, A> {
    return cond ? Result.pass<E, A>(pass()) : Result.fail<E, A>(fail());
  };
}

function tupled<const A extends AnyResultTuple<unknown>>(
  results: A
): Result<ExtractFailure<A>, UnpackResultTuple<A>> {
  const values: unknown[] = [];

  for (const result of results) {
    if (result instanceof Success) {
      values.push(result._value);
    } else if (result instanceof Failure) {
      return fail(result._error);
    } else {
      throw new Error("Unknown Result type: " + result);
    }
  }

  return pure(values as UnpackResultTuple<A>);
}

function when<const A extends AnyResultTuple<unknown>>(
  ...results: A
): <R>(
  func: (...args: UnpackResultTuple<A>) => R
) => Result<ExtractFailure<A>, R> {
  const result = tupled(results);
  return func => result.map(values => func(...values));
}

function sequence<X, A>(results: Result<X, A>[]): Result<X, A[]> {
  const args: A[] = [];

  for (const result of results) {
    if (result instanceof Success) {
      args.push(result._value);
    } else if (result instanceof Failure) {
      return fail(result._error);
    } else {
      throw new Error("Unknown Result type: " + result);
    }
  }

  return pass(args);
}

function traverse<A>(as: A[]) {
  return function <X, B>(func: (a: A) => Result<X, B>): Result<X, B[]> {
    const args: B[] = [];

    for (const a of as) {
      const result = func(a);

      if (result instanceof Success) {
        args.push(result._value);
      } else if (result instanceof Failure) {
        return fail(result._error);
      } else {
        throw new Error("Unknown Result type: " + result);
      }
    }

    return pass(args);
  };
}

function reduceWith<E, A, B>(
  as: A[],
  memo: Result<E, B>,
  func: (b: B, a: A) => Result<E, B>
): Result<E, B> {
  return as.reduce((b, a) => b.flatMap(b => func(b, a)), memo);
}

function reduce<E, A, B>(
  as: A[],
  memo: B,
  func: (b: B, a: A) => Result<E, B>
): Result<E, B> {
  return reduceWith(as, Result.pure(memo), func);
}

function guard<A>(guard: Guard<A>, hint?: string) {
  return (value: unknown): Result<GuardError, A> => {
    return Result.pure<GuardError, unknown>(value).guard(guard, hint);
  };
}

function guardWith<E, A>(guard: Guard<A>, orElse: (a: unknown) => E) {
  return (value: unknown): Result<E, A> => {
    return Result.pure<E, unknown>(value).guardWith(guard, orElse);
  };
}

export const Result = {
  pure,
  pass,
  fail,
  wrap,
  cond,
  when,
  tupled,
  sequence,
  traverse,
  reduce,
  reduceWith,
  guard,
  guardWith,
};

export function isResult<E, A>(
  isE: (value: unknown) => value is E,
  isA: (value: unknown) => value is A
) {
  return function (value: unknown): value is Result<E, A> {
    if (value instanceof Failure) {
      return isE(value._error);
    } else if (value instanceof Success) {
      return isA(value._value);
    } else {
      return false;
    }
  };
}

/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-explicit-any */
