// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopWaterLevel =
  | "Dry"
  | "VeryLow"
  | "Low"
  | "High"
  | "Flooded";

const Dry: UclPitStopWaterLevel = "Dry";
const VeryLow: UclPitStopWaterLevel = "VeryLow";
const Low: UclPitStopWaterLevel = "Low";
const High: UclPitStopWaterLevel = "High";
const Flooded: UclPitStopWaterLevel = "Flooded";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopWaterLevelEnum = {
  ...createEnum("UclPitStopWaterLevel", [
    [Dry, "Dry"],
    [
      VeryLow,
      "Very low - I can see the water level is below the high water mark/vegetation line and the pond is almost dry",
    ],
    [
      Low,
      "Low - I can see the water level is below the high water mark/vegetation line",
    ],
    [High, "High to full"],
    [Flooded, "Flooded - the water level is above the pond basin perimeter"],
  ]),
  Dry,
  VeryLow,
  Low,
  High,
  Flooded,
};

export default UclPitStopWaterLevelEnum;
