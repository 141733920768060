import { findAndMap } from "@cartographerio/util";
import { QualificationId } from "../../core";
import { QualificationRole, QualificationRoleName } from "../types.generated";
import { qualificationRoleName, roleQualificationId } from "./parse";

export function findQualificationRoleName(
  roles: QualificationRole[],
  qualificationId: QualificationId
): QualificationRoleName | null {
  return findAndMap(roles, role =>
    roleQualificationId(role) === qualificationId
      ? qualificationRoleName(role)
      : null
  );
}
