// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsMarginalFeature =
  | "NO"
  | "NV"
  | "EC"
  | "SC"
  | "PB"
  | "VP"
  | "SB"
  | "VS"
  | "NB";

const None: UrsMarginalFeature = "NO";
const NotVisible: UrsMarginalFeature = "NV";
const ErodingCliff: UrsMarginalFeature = "EC";
const StableCliff: UrsMarginalFeature = "SC";
const UnvegetatedPointBar: UrsMarginalFeature = "PB";
const VegetatedPointBar: UrsMarginalFeature = "VP";
const UnvegetatedSideBar: UrsMarginalFeature = "SB";
const VegetatedSideBar: UrsMarginalFeature = "VS";
const NaturalBerm: UrsMarginalFeature = "NB";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsMarginalFeatureEnum = {
  ...createEnum("UrsMarginalFeature", [
    [None, "None"],
    [NotVisible, "Not Visible"],
    [ErodingCliff, "Eroding Cliff"],
    [StableCliff, "Stable Cliff"],
    [UnvegetatedPointBar, "Unvegetated Point Bar"],
    [VegetatedPointBar, "Vegetated Point Bar"],
    [UnvegetatedSideBar, "Unvegetated Side Bar"],
    [VegetatedSideBar, "Vegetated Side Bar"],
    [NaturalBerm, "Natural Berm"],
  ]),
  None,
  NotVisible,
  ErodingCliff,
  StableCliff,
  UnvegetatedPointBar,
  VegetatedPointBar,
  UnvegetatedSideBar,
  VegetatedSideBar,
  NaturalBerm,
};

export default UrsMarginalFeatureEnum;
