import {
  PartialParams,
  WorkspaceSearchOptionsV2,
  endpoints,
} from "@cartographerio/client";
import { Option } from "@cartographerio/fp";
import { ApiParams, BannerMessage, WorkspaceRef } from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type WorkspaceBannerKey =
  | ["workspace", "banner"]
  | ["workspace", "banner", "v1", "read", WorkspaceRef]
  | ["workspace", "banner", "v1", "readOpt", WorkspaceRef]
  | [
      "workspace",
      "banner",
      "v1",
      "search",
      PartialParams<WorkspaceSearchOptionsV2>
    ];

export function workspaceBannerKey(
  key: WorkspaceBannerKey
): WorkspaceBannerKey {
  return key;
}

export function readOrNull(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<BannerMessage | null, WorkspaceBannerKey> {
  return {
    queryKey: ["workspace", "banner", "v1", "read", workspaceRef],
    queryFn: () =>
      endpoints.workspace.banner.v1
        .readOption(apiParams, workspaceRef)
        .map(opt => opt.getOrNull())
        .unsafeRun(),
  };
}

export function readOption(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<Option<BannerMessage>, WorkspaceBannerKey> {
  return {
    queryKey: ["workspace", "banner", "v1", "readOpt", workspaceRef],
    queryFn: () =>
      endpoints.workspace.banner.v1
        .readOption(apiParams, workspaceRef)
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  banner: BannerMessage
) {
  return endpoints.workspace.banner.v1
    .save(apiParams, workspaceRef, banner)
    .tap(_ => queryClient.invalidateQueries(["workspace", "banner"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
) {
  return endpoints.workspace.banner.v1
    .remove(apiParams, workspaceRef)
    .tap(_ => queryClient.invalidateQueries(["workspace", "banner"]));
}
