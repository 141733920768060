import { OtherSpecify, isOtherSpecify } from "@cartographerio/topo-form";
import { Option, Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isArrayOf,
  isNullable,
  isObject,
} from "@cartographerio/guard";
import {
  NwcSeedbankCollection,
  NwcSeedbankCollectionEnum,
  NwcSeedbankPermission,
  NwcSeedbankPermissionEnum,
  NwcSeedbankSpecies,
  NwcSeedbankSpeciesEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { chain } from "lodash";

export interface PartialNwcSeedbankData {
  recorded?: Timestamp | null;
  location?: Point | null;
  collection?: NwcSeedbankCollection | null;
  permission?: NwcSeedbankPermission | null;
  species: OtherSpecify<NwcSeedbankSpecies[]>;
}

export function isPartialData(data: unknown): data is PartialNwcSeedbankData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(
      data,
      "collection",
      isNullable(NwcSeedbankCollectionEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "permission",
      isNullable(NwcSeedbankPermissionEnum.isValue)
    ) &&
    hasKey(
      data,
      "species",
      isOtherSpecify(isArrayOf(NwcSeedbankSpeciesEnum.isValue))
    )
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialNwcSeedbankData"
  )(data).map(data => {
    const ngrLabel: string = Option.wrap(data.location)
      .map(str => safePointToNgr(str))
      .getOrElse(() => "Unknown location");

    let collectionLabel: string;
    switch (data.collection) {
      case "Intending":
        switch (data.permission) {
          case "Yes":
            collectionLabel = "Intention to Collect, Permission Obtained";
            break;

          case "No":
          case null:
          case undefined:
            collectionLabel = "Intention to Collect, No Permission";
            break;

          default:
            checkExhausted(data.permission);
        }
        break;

      case "Collected":
        collectionLabel = "Seeds Collected";
        break;

      case "None":
      case null:
      case undefined:
        collectionLabel = "No Seeds Collected";
        break;

      default:
        checkExhausted(data.collection);
    }

    const NUM_SPECIES_TO_LIST = 1;

    const selectedSpecies: NwcSeedbankSpecies[] = data.species.selected;
    const selectedNames: NwcSeedbankSpecies[] = chain(selectedSpecies)
      .omit(NwcSeedbankSpeciesEnum.Other)
      .value();
    const listedSpecies: NwcSeedbankSpecies[] = chain(selectedNames)
      .take(Math.min(NUM_SPECIES_TO_LIST, selectedNames.length))
      .value();

    const numListed: number = listedSpecies.length;
    const numUnlisted: number = selectedSpecies.length - numListed;

    const speciesLabel: string =
      numUnlisted > 0
        ? `${listedSpecies
            .map(NwcSeedbankSpeciesEnum.labelOf)
            .join(", ")} and ${numUnlisted} other speces`
        : numListed > 0
          ? listedSpecies.map(NwcSeedbankSpeciesEnum.labelOf).join(", ")
          : "No species";

    return [ngrLabel, collectionLabel, speciesLabel].join(", ");
  });
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialNwcSeedbankData"
  )(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialNwcSeedbankData"
  )(data).map(data => data.recorded ?? null);
}

export function copyData(
  data: unknown
): Result<GuardError, PartialNwcSeedbankData> {
  return Result.guard(
    isPartialData,
    "PartialNwcSeedbankData"
  )(data).map(data => ({
    ...data,
    recorded: null,
  }));
}
