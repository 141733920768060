import {
  attachmentField,
  columns2,
  form,
  numberField,
  page,
  pointField,
  required,
  requiredIff,
  section,
  select,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import {
  SelectOption,
  WrtFordsLandCoverStageEnum,
  WrtFordsLandUseEnum,
  WrtFordsLivestockEnum,
  WrtRainfallEnum,
  WrtWestcountryCsiFlowLevelEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

function option(value: number, units: string): SelectOption<number> {
  return { value, label: `${value} ${units}` };
}

export default form({
  title: "FORDs",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            columns2(
              vstack(
                textField({
                  label: "Site Name",
                  path: ["data", "siteName"],
                  required: required("info"),
                }),
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                  required: required("info"),
                  help: "Date and time the readings were taken.",
                })
              ),
              pointField({
                label: "Location",
                path: ["data", "location"],
                required: required("info"),
              })
            ),
          ],
        }),
        section({
          title: "Weather, Site, and Surroundings",
          path: [],
          blocks: [
            select({
              label: "Rain in Previous 24 Hours",
              path: ["data", "rainfall"],
              options: WrtRainfallEnum.entries,
              required: required("info"),
              appearance: "radiogroup",
              columns: 3,
            }),
            select({
              label: "Land Use in Immediate Adjacent Field",
              path: ["data", "landUse"],
              options: WrtFordsLandUseEnum.entries,
              required: required("info"),
              appearance: "radiogroup",
              columns: 3,
            }),
            textField({
              label: "Arable Crop (if applicable)",
              path: ["data", "arableCrop"],
              customRules: requiredIff({
                level: "info",
                requiredMessage: "Please specify the crop.",
                forbiddenMessage:
                  "Only enter a value if you recorded 'Arable' land use above.",
                otherPath: ["data", "landUse"],
                otherTest: value => value === WrtFordsLandUseEnum.Arable,
              }),
            }),
            textField({
              label: "Other Land Use",
              path: ["data", "otherLandUse"],
              customRules: requiredIff({
                level: "info",
                requiredMessage: "Please specify the other land use.",
                forbiddenMessage:
                  "Only enter a value if you recorded 'Other' land use above.",
                otherPath: ["data", "landUse"],
                otherTest: value => value === WrtFordsLandUseEnum.Other,
              }),
            }),
            select({
              label: "Land Cover Stage",
              path: ["data", "landCoverStage"],
              options: WrtFordsLandCoverStageEnum.entries,
              required: required("info"),
              appearance: "radiogroup",
              columns: 3,
            }),
            select({
              label: "Livestock",
              path: ["data", "livestock"],
              options: WrtFordsLivestockEnum.entries,
              required: required("info"),
              appearance: "radiogroup",
              columns: 3,
            }),
            textArea({
              label: "Operations and Observations",
              path: ["data", "operationsAndObservations"],
              rows: 4,
              help: outdent`
              Any relevant activity in field / vicinity in past weeks?
              e.g. slurry spreading, field cultivation, fertiliser / FYM application,
              stock moved in/out, road or other inputs...
              `,
            }),
            columns2(
              numberField({
                label: "Estimated Channel Width",
                path: ["data", "channelWidth"],
                units: "m",
              }),
              numberField({
                label: "Estimated Channel Depth",
                path: ["data", "channelDepth"],
                units: "m",
              })
            ),
            select({
              label: "Flow",
              path: ["data", "flow"],
              options: WrtWestcountryCsiFlowLevelEnum.entries,
              required: required("info"),
              help: outdent`
              "Steady" is walking speed;
              "Slow" is slower than walking speed;
              "Surging" is faster than walking speed.
              `,
              appearance: "radiogroup",
              columns: 2,
            }),
          ],
        }),
        section({
          title: "Water Quality",
          path: [],
          blocks: [
            numberField({
              label: "Total Dissolved Solids",
              path: ["data", "totalDissolvedSolids"],
              units: "ppm",
            }),
            numberField({
              label: "Turbidity",
              path: ["data", "turbidity"],
              units: "NTU",
            }),
            select({
              label: "Phosphates",
              path: ["data", "phosphate"],
              appearance: "radiogroup",
              columns: 3,
              options: [
                option(0, "ppb"),
                option(100, "ppb"),
                option(200, "ppb"),
                option(300, "ppb"),
                option(500, "ppb"),
                option(1000, "ppb"),
                option(2500, "ppb"),
              ],
              help: outdent`
              1. Bend strip and place in test tube cap
              2. Replace cap and invert slowly 5 times
              3. Remove cap and compare colour through tube to coloured squares
              `,
            }),
            select({
              label: "Nitrates",
              path: ["data", "nitrate"],
              appearance: "radiogroup",
              columns: 3,
              options: [
                option(0, "ppm"),
                option(10, "ppm"),
                option(30, "ppm"),
                option(60, "ppm"),
                option(120, "ppm"),
                option(200, "ppm"),
              ],
              help: outdent`
              1. Immerse pad in sample for **2 seconds**
              2. Remove pad and hold level -- do not shake off excess water
              3. **After 60 seconds** compare against coloured squares
              `,
            }),
            select({
              label: "Ammonia",
              path: ["data", "ammonia"],
              appearance: "radiogroup",
              columns: 3,
              options: [
                option(0, "ppm"),
                option(0.5, "ppm"),
                option(1.0, "ppm"),
                option(3.0, "ppm"),
                option(6.0, "ppm"),
              ],
              help: outdent`
              1. Immerse pad in sample for **5 seconds**
              2. Remove pad and hold level -- do not shake off excess water
              3. **After 60 seconds** compare against coloured squares
              `,
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 3,
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          help: outdent`
          Please take up to four photos to go with your survey data. One photo
          should be taken from the same place on each visit and will capture some
          part of the waterbody and surroundings.
          `,
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
      ],
    }),
  ],
});
