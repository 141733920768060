import { Path } from "@cartographerio/topo-core";
import {
  topoExpr,
  TopoExpr,
  attachmentField,
  Block,
  checkbox,
  columns2,
  customRule,
  CustomRule,
  form,
  page,
  pointField,
  required,
  requiredIfV2,
  RequiredRule,
  section,
  select,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import { isBoolean, isNullable } from "@cartographerio/guard";
import {
  FhtPriorityPondsAnnex1TypeEnum,
  FhtPriorityPondsPsymAssessmentBasisEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

interface BooleanSelectProps {
  label: string;
  path: Path;
  help?: string;
  visible?: TopoExpr<boolean>;
  required?: RequiredRule;
  defaultValue?: boolean;
  customRules?: CustomRule[];
}

const PRIORITY_PONDS_HELP_URL =
  "https://freshwaterhabitats.org.uk/research/priority-ponds/how-to-identify-a-priority-pond/";

function booleanSelect(props: BooleanSelectProps): Block {
  const {
    label,
    path,
    help,
    visible,
    required,
    defaultValue = false,
    customRules,
  } = props;

  return select({
    label,
    path,
    options: [
      { value: false, label: "No" },
      { value: true, label: "Yes" },
    ],
    help,
    visible,
    required,
    defaultValue,
    customRules,
  });
}

const criterionApplies = (num: 1 | 2 | 3 | 4 | 5 | 6 | 7): TopoExpr<boolean> =>
  topoExpr(
    env => env.getAbsolute(["data", `criterion${num}`, "applies"]) === true
  );

const criterion1Annex1TypeSpecified: TopoExpr<boolean> = topoExpr(env =>
  env
    .getAbsoluteAs(
      ["data", "criterion1", "annex1Type"],
      isNullable(FhtPriorityPondsAnnex1TypeEnum.isValue)
    )
    .map(value => value !== null && value !== "None")
    .getOrElse(() => false)
);

const criterion1Passes = customRule({
  level: "error",
  message: "For this criterion to apply you must select a habitat type.",
  triggerWhen: topoExpr((env, run) =>
    run(criterionApplies(1))
      ? env
          .getAbsoluteAs(
            ["data", "criterion1", "annex1Type"],
            isNullable(FhtPriorityPondsAnnex1TypeEnum.isValue)
          )
          .map(
            value =>
              value == null || value === FhtPriorityPondsAnnex1TypeEnum.None
          )
          .getOrElse(() => false)
      : false
  ),
});

const criterion2OtherSpecies: TopoExpr<boolean> = topoExpr(env =>
  env
    .getAbsoluteAs(["data", "criterion2", "other"], isNullable(isBoolean))
    .map(value => value === true)
    .getOrElse(() => false)
);

const criterion2AnySpecies: TopoExpr<boolean> = topoExpr(env => {
  const a = env
    .getAbsoluteAs(
      ["data", "criterion2", "greaterCrestedNewt"],
      isNullable(isBoolean)
    )
    .map(value => value === true)
    .getOrElse(() => false);

  const b = env
    .getAbsoluteAs(["data", "criterion2", "commonToad"], isNullable(isBoolean))
    .map(value => value === true)
    .getOrElse(() => false);

  const c = env
    .getAbsoluteAs(["data", "criterion2", "other"], isNullable(isBoolean))
    .map(value => value === true)
    .getOrElse(() => false);

  return a || b || c;
});

const criterion2Passes = customRule({
  level: "error",
  message:
    "For this criterion to apply, you must select 'Yes' in one of these categories.",
  triggerWhen: topoExpr(
    (_env, run) => run(criterionApplies(2)) && !run(criterion2AnySpecies)
  ),
});

const criterion3Passes = customRule({
  level: "error",
  message:
    "For this criterion to apply, you must select 'Yes' in one of these categories.",
  triggerWhen: topoExpr(
    (env, run) =>
      run(criterionApplies(3)) &&
      !env.getAbsolute(["data", "criterion3", "amphibianAssemblage"]) &&
      !env.getAbsolute(["data", "criterion3", "dragonflyAssemblage"]) &&
      !env.getAbsolute(["data", "criterion3", "other"]) &&
      !env.getAbsolute(["data", "criterion3", "richSiteForPlants"]) &&
      !env.getAbsolute([
        "data",
        "criterion3",
        "richSiteForAquaticInvertebrates",
      ])
  ),
});

const criterion4GoodPsymScore: TopoExpr<boolean> = topoExpr(env =>
  env
    .getAbsoluteAs(
      ["data", "criterion4", "goodPsymScore"],
      isNullable(isBoolean)
    )
    .map(value => value === true)
    .getOrElse(() => false)
);

const criterion4Passes = customRule({
  level: "error",
  message: "For this criterion to apply, you must select 'Yes'.",
  triggerWhen: topoExpr(
    (env, run) =>
      run(criterionApplies(4)) &&
      !env.getAbsolute(["data", "criterion4", "goodPsymScore"])
  ),
});

const criterion5OtherPondType: TopoExpr<boolean> = topoExpr(env =>
  env
    .getAbsoluteAs(["data", "criterion5", "other"], isNullable(isBoolean))
    .map(value => value === true)
    .getOrElse(() => false)
);

const criterion5Passes = customRule({
  level: "error",
  message:
    "For this criterion to apply, you must select 'Yes' in one of these categories.",
  triggerWhen: topoExpr(
    (env, run) =>
      run(criterionApplies(5)) &&
      !env.getAbsolute(["data", "criterion5", "iceAgePond"]) &&
      !env.getAbsolute(["data", "criterion5", "duneSlackPond"]) &&
      !env.getAbsolute(["data", "criterion5", "floatingVegetationMat"]) &&
      !env.getAbsolute(["data", "criterion5", "other"])
  ),
});

const criterion6Passes = customRule({
  level: "error",
  message: "For this criterion to apply, you must select 'Yes'.",
  triggerWhen: topoExpr(
    (env, run) =>
      run(criterionApplies(6)) &&
      !env.getAbsolute(["data", "criterion6", "priority"])
  ),
});

const criterion7LowNitrateOrPhosphate: TopoExpr<boolean> = topoExpr(env => {
  const a = env
    .getAbsoluteAs(["data", "criterion7", "lowNitrate"], isNullable(isBoolean))
    .map(value => value === true)
    .getOrElse(() => false);

  const b = env
    .getAbsoluteAs(
      ["data", "criterion7", "lowPhosphate"],
      isNullable(isBoolean)
    )
    .map(value => value === true)
    .getOrElse(() => false);

  return a || b;
});

function criterion7Passes(field: "lowNitrate" | "lowPhosphate") {
  return customRule({
    level: "error",
    message:
      "For this criterion to apply, you must select 'Yes' in both of these categories.",
    triggerWhen: topoExpr(
      (env, run) =>
        run(criterionApplies(7)) &&
        !env.getAbsolute(["data", "criterion7", field])
    ),
  });
}

export default form({
  title: "Priority Ponds",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            pointField({
              label: "Location of the Pond",
              path: ["data", "location"],
              fullWidth: true,
              help: outdent`
              **Searching the map**

              Either:

              1. Search by grid reference (using the box above), or
              2. Scroll the map. Note: use the + / - buttons to zoom in and out,
                 and your mouse of touchpad to move about the map.

              **Adding ponds**

              Click on the map to place the marker in the centre of your pond.
              It may help to toggle to satellite view to see the pond better
              (bottom button to the right of the map).
              A grid reference for your marker point will be generated automatically.

              Once you are happy with your marker's location,
              fill in the relevant data for the pond below.
              `,
            }),
            timestampField({
              label: "Survey Date/Time",
              path: ["data", "recorded"],
              required: required(),
              defaultValue: "now",
              help: outdent`
              If you are unsure of the exact date,
              choose the first day of the relevant month e.g. 01/05/2023.
              If you are unsure of the time, you can change this to 12:00.
              `,
            }),
            textField({
              label: "Organisation (if relevant to the survey)",
              path: ["data", "organisationName"],
            }),
            textField({
              label: "Project (if relevant)",
              path: ["data", "projectName"],
            }),
            textArea({
              label: "Names of Other Surveyors (if present)",
              path: ["data", "otherSurveyors"],
              rows: 3,
            }),
          ],
        }),
        section({
          title: "Priority Ponds",
          path: [],
          help: `To qualify as a priority habitat, a pond needs to meet any one of the following five criteria.  [Read more about these criteria here](${PRIORITY_PONDS_HELP_URL}). There are also two additional ways for identifying if a pond is likely to be a priority pond (see below).`,
          blocks: [],
        }),
        section({
          title: "Criterion 1 - Habitats of High Conservation Importance",
          path: ["data", "criterion1"],
          help: `These are ponds that fit the criteria for one of the pond-associated Annex 1 Habitats Directive types that are present in England.`,
          blocks: [
            checkbox({
              label: "Criterion Applies",
              checkboxLabel: "Does this criterion apply to the pond?",
              path: ["applies"],
            }),
            timestampField({
              label: "Date/Time Checked",
              path: ["recorded"],
              help: "If the value differs significantly from the Survey Date/Time at the top of the form.",
              visible: criterionApplies(1),
            }),
            select({
              label:
                "Does the pond fit the criteria for any of the following Annex 1 Habitat Directive types?",
              path: ["annex1Type"],
              help: `Select the most relevant type. [Read about the criteria here](${PRIORITY_PONDS_HELP_URL}).`,
              options: FhtPriorityPondsAnnex1TypeEnum.entries,
              visible: criterionApplies(1),
              defaultValue: FhtPriorityPondsAnnex1TypeEnum.None,
              customRules: [
                criterion1Passes,
                requiredIfV2({
                  level: "error",
                  otherTest: criterionApplies(1),
                  message: "You must enter a value.",
                }),
              ],
            }),
            textArea({
              label:
                "What is the evidence that the pond fits this Habitat Directive type?",
              path: ["evidence"],
              help: `To answer this question look at the last column of the [Habitats Directive Pond types table](${PRIORITY_PONDS_HELP_URL}) (headed "Defining Characteristics") and describe how your pond meets each of the numbered key features.`,
              rows: 5,
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(1)) && run(criterion1Annex1TypeSpecified)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "You should enter a value",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(1)) &&
                      run(criterion1Annex1TypeSpecified)
                  ),
                }),
              ],
            }),
            textArea({
              label:
                "Does your pond lie within the appropriate area of occurrence shown on the distribution map?",
              path: ["occurrence"],
              help: "Note that not all qualifying ponds will fall into areas shown on the map, but if not, please describe your area/habitat.",
              rows: 3,
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(1)) && run(criterion1Annex1TypeSpecified)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "You should enter a value",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(1)) &&
                      run(criterion1Annex1TypeSpecified)
                  ),
                }),
              ],
            }),
            attachmentField({
              label: "Verification",
              path: ["verificationPhotographs"],
              help: "Please upload any verification you have e.g. photos of species, photos of the pond. You can also upload documents e.g. species lists, or send photos of species lists.",
              maxFiles: 4,
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(1)) && run(criterion1Annex1TypeSpecified)
              ),
            }),
          ],
        }),
        section({
          title:
            "Criterion 2 - Ponds with Species of High Conservation Importance",
          path: ["data", "criterion2"],
          help: `These are ponds that support species with a conservation designation. This includes: Red Data Book species, UK BAP species, species protected under the W&CA Schedule 5 and 8, Habitats Directive Annex II species, Nationally Scarce wetland and aquatic invertebrate species. [A list of qualifying freshwater species can be found here](${PRIORITY_PONDS_HELP_URL}).`,
          blocks: [
            checkbox({
              label: "Criterion Applies",
              checkboxLabel: "Does this criterion apply to the pond?",
              path: ["applies"],
            }),
            timestampField({
              label: "Date/Time Checked",
              path: ["recorded"],
              help: "If the value differs significantly from the Survey Date/Time at the top of the form.",
              visible: criterionApplies(2),
            }),
            booleanSelect({
              label: "Great Crested Newt",
              path: ["greatCrestedNewt"],
              visible: criterionApplies(2),
              customRules: [
                criterion2Passes,
                requiredIfV2({
                  level: "error",
                  message: "You should enter a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(2))),
                }),
              ],
            }),
            booleanSelect({
              label: "Common Toad",
              path: ["commonToad"],
              visible: criterionApplies(2),
              customRules: [
                criterion2Passes,
                requiredIfV2({
                  level: "error",
                  message: "You should enter a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(2))),
                }),
              ],
            }),
            booleanSelect({
              label: "Other (please specify)",
              path: ["other"],
              visible: criterionApplies(2),
              customRules: [
                criterion2Passes,
                requiredIfV2({
                  level: "error",
                  message: "You should enter a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(2))),
                }),
              ],
            }),
            columns2(
              textField({
                label: "Other Scientific Name",
                path: ["otherScientificName"],
                help: "e.g. Typha latifolia",
                visible: topoExpr(
                  (env, run) =>
                    run(criterionApplies(2)) && run(criterion2OtherSpecies)
                ),
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message: "Please enter a scientific name",
                    otherTest: topoExpr(
                      (env, run) =>
                        run(criterionApplies(2)) && run(criterion2OtherSpecies)
                    ),
                  }),
                ],
              }),
              textField({
                label: "Other Common Name (Where Known)",
                path: ["otherCommonName"],
                help: "e.g. Bulrush",
                visible: topoExpr(
                  (env, run) =>
                    run(criterionApplies(2)) && run(criterion2OtherSpecies)
                ),
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message: "Please enter a scientific name",
                    otherTest: topoExpr(
                      (env, run) =>
                        run(criterionApplies(2)) && run(criterion2OtherSpecies)
                    ),
                  }),
                ],
              })
            ),
            textArea({
              label: "Additional Information",
              path: ["additionalInformation"],
              help: "Use this text box add comments or further information e.g. any designated non-freshwater species that are strongly associated with the pond.",
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(2)) && run(criterion2AnySpecies)
              ),
            }),
            booleanSelect({
              label: "Has your species been verified by an expert?",
              path: ["verifiedByExpert"],
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(2)) && run(criterion2AnySpecies)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "Please select a value",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(2)) && run(criterion2AnySpecies)
                  ),
                }),
              ],
            }),
            booleanSelect({
              label: "Has your species been verified by eDNA?",
              path: ["verifiedByEDna"],
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(2)) && run(criterion2AnySpecies)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "Please select a value",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(2)) && run(criterion2AnySpecies)
                  ),
                }),
              ],
            }),
            textArea({
              label:
                "Provide information about how your record has been verified.",
              path: ["verificationNotes"],
              help: "e.g. your own expertise, or the name and status of another person who verified your record",
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(2)) && run(criterion2AnySpecies)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "Please select a value",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(2)) && run(criterion2AnySpecies)
                  ),
                }),
              ],
            }),
            attachmentField({
              label: "Verification",
              path: ["verificationPhotographs"],
              visible: criterionApplies(2),
              help: "Please upload any verification you have e.g. photos of species, document copies of verification letter from expert, photos of the pond. You can upload photos of documents or emails if that is easier.",
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title:
            "Criterion 3 - Ponds with Exceptional Populations or Numbers of Key Species",
          path: ["data", "criterion3"],
          help: `This includes ponds that support (i) amphibian or dragonfly assemblages of sufficient quality that they would typically qualify for SSSI status, or (ii) ponds that have high numbers of plant or invertebrate species.`,
          blocks: [
            checkbox({
              label: "Criterion Applies",
              checkboxLabel: "Does this criterion apply to the pond?",
              path: ["applies"],
            }),
            timestampField({
              label: "Date/Time Checked",
              path: ["recorded"],
              help: "If the value differs significantly from the Survey Date/Time at the top of the form.",
              visible: criterionApplies(3),
            }),
            booleanSelect({
              label: "Are the Amphibian SSSI criteria met?",
              path: ["amphibianAssemblage"],
              help: `[View the criteria here](${PRIORITY_PONDS_HELP_URL}).`,
              visible: criterionApplies(3),
              customRules: [
                criterion3Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(3))),
                }),
              ],
            }),
            booleanSelect({
              label: "Are the Dragonfly SSSI criteria met?",
              path: ["dragonflyAssemblage"],
              help: `[View the criteria here](${PRIORITY_PONDS_HELP_URL}).`,
              visible: criterionApplies(3),
              customRules: [
                criterion3Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(3))),
                }),
              ],
            }),
            booleanSelect({
              label: "Is this an exceptionally rich site for plants?",
              path: ["richSiteForPlants"],
              help: "(supporting 30 or more wetland plant species)",
              visible: criterionApplies(3),
              customRules: [
                criterion3Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(3))),
                }),
              ],
            }),
            booleanSelect({
              label:
                "Is this an exceptionally rich site for aquatic invertebrates?",
              path: ["richSiteForAquaticInvertebrates"],
              help: "(supporting 50 or species)",
              visible: criterionApplies(3),
              customRules: [
                criterion3Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(3))),
                }),
              ],
            }),
            attachmentField({
              label: "Verification",
              path: ["verificationPhotographs"],
              help: "Please upload any verification you have e.g. excel species list, or photos of species lists.",
              visible: criterionApplies(3),
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Criterion 4 - Ponds of High Ecological Quality",
          path: ["data", "criterion4"],
          help: `PSYM (the Predictive SYstem for Multimetrics) is a method developed by Freshwater Habitats Trust to assess the biological quality of still waters in England and Wales. Ponds that fall into the top PSYM category (“good”) classify as Priority Ponds. To enter results in this section, data needs to have been collected using [standard PSYM methods](https://bit.ly/PSYM1) and then submitted to Freshwater Habitats Trust for analysis.`,
          blocks: [
            checkbox({
              label: "Criterion Applies",
              checkboxLabel: "Does this criterion apply to the pond?",
              path: ["applies"],
            }),
            timestampField({
              label: "Date/Time Checked",
              path: ["recorded"],
              visible: criterionApplies(4),
              help: "If the value differs significantly from the Survey Date/Time at the top of the form.",
            }),
            booleanSelect({
              label: "Did the pond classify as Good using PSYM?",
              path: ["goodPsymScore"],
              help: "(i.e. had a PSYM score of at least 75%)",
              visible: criterionApplies(4),
              customRules: [
                criterion4Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(4))),
                }),
              ],
            }),
            select({
              label: "What was the PSYM assessment based on?",
              path: ["psymAssessmentBasis"],
              options: FhtPriorityPondsPsymAssessmentBasisEnum.entries,
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(4)) && run(criterion4GoodPsymScore)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "You should select a value",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(4)) && run(criterion4GoodPsymScore)
                  ),
                }),
              ],
            }),
            textField({
              label: "Verification Site Name",
              path: ["verificationSiteName"],
              help: "The name of the site provided when your data were sent to Freshwater Habitats Trust for PSYM calculation.",
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(4)) && run(criterion4GoodPsymScore)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "You should select a value",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(4)) && run(criterion4GoodPsymScore)
                  ),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Criterion 5 - Other Important Ponds",
          path: ["data", "criterion5"],
          help: `Other important ponds: Individual ponds or groups of ponds with a limited geographic distribution recognised as important because of their age, rarity of type or landscape context [e.g. pingos, duneslack ponds, machair ponds). Information about two of these pond types, Ice age ponds and Ponds with floating vegetation mats, (can be found here](${PRIORITY_PONDS_HELP_URL}).`,
          blocks: [
            checkbox({
              label: "Criterion Applies",
              checkboxLabel: "Does this criterion apply to the pond?",
              path: ["applies"],
            }),
            timestampField({
              label: "Date/Time Checked",
              path: ["recorded"],
              visible: criterionApplies(5),
              help: "If the value differs significantly from the Survey Date/Time at the top of the form.",
            }),
            booleanSelect({
              label: "Is this an ice age pond (or pingo)?",
              path: ["iceAgePond"],
              visible: criterionApplies(5),
              customRules: [
                criterion5Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(5))),
                }),
              ],
            }),
            booleanSelect({
              label: "Is this a dune slack pond?",
              path: ["duneSlackPond"],
              visible: criterionApplies(5),
              customRules: [
                criterion5Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(5))),
                }),
              ],
            }),
            booleanSelect({
              label: "Is this a pond with a floating vegetation mat?",
              path: ["floatingVegetationMat"],
              visible: criterionApplies(5),
              customRules: [
                criterion5Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(5))),
                }),
              ],
            }),
            booleanSelect({
              label: "Is this another important pond type? (please specify)",
              path: ["other"],
              visible: criterionApplies(5),
              customRules: [
                criterion5Passes,
                requiredIfV2({
                  level: "error",
                  message: "Please select a value",
                  otherTest: topoExpr((env, run) => run(criterionApplies(5))),
                }),
              ],
            }),
            textArea({
              label: "Other important pond type",
              path: ["otherDescription"],
              help: "Please specify the type of pond.",
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(5)) && run(criterion5OtherPondType)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "You should select a value",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(5)) && run(criterion5OtherPondType)
                  ),
                }),
              ],
            }),
            attachmentField({
              label: "Verification",
              path: ["verificationPhotographs"],
              visible: criterionApplies(5),
              help: `Please upload any verification you have e.g. photos, reports, geophysical results`,
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Additional Criteria",
          path: [],
          help: `In association with Natural England, we have identified two further indicators for Priority Ponds.`,
          blocks: [],
        }),
        section({
          title: "Criterion 6 - Priority Pond Predictor (PASS)",
          path: ["data", "criterion6"],
          help: `This method identifies ponds that are likely to have priority status based on their physical characteristics alone. Note that only around 60% of priority ponds will be identified using this method. So even if a negative result is obtained the pond may still have priority status [based on other criteria](${PRIORITY_PONDS_HELP_URL}).`,
          blocks: [
            checkbox({
              label: "Criterion Applies",
              checkboxLabel: "Does this criterion apply to the pond?",
              path: ["applies"],
            }),
            timestampField({
              label: "Date/Time Checked",
              path: ["recorded"],
              visible: criterionApplies(6),
              help: "If the value differs significantly from the Survey Date/Time at the top of the form.",
            }),
            booleanSelect({
              label: `Is the pond predicted to be a Priority pond based on this	criterion?`,
              visible: criterionApplies(6),
              path: ["priority"],
              customRules: [
                criterion6Passes,
                requiredIfV2({
                  level: "error",
                  otherTest: criterionApplies(6),
                  message: "Please enter a value",
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Criterion 7 - Clean Water Kits",
          path: ["data", "criterion7"],
          help: `Ponds that have clean water, particularly low nitrate and low phosphate, are identified as potential priority ponds. Both elements need to be low to qualify. [You can find out more about one possible testing method here](${PRIORITY_PONDS_HELP_URL}).`,
          blocks: [
            checkbox({
              label: "Criterion Applies",
              checkboxLabel: "Does this criterion apply to the pond?",
              path: ["applies"],
            }),
            timestampField({
              label: "Date/Time Checked",
              path: ["recorded"],
              visible: criterionApplies(7),
              help: "If the value differs significantly from the Survey Date/Time at the top of the form.",
            }),
            booleanSelect({
              label:
                "Does the pond have a low level of nitrate? (< 0.5 mg/l (ppm))",
              path: ["lowNitrate"],
              visible: criterionApplies(7),
              customRules: [criterion7Passes("lowNitrate")],
            }),
            booleanSelect({
              label:
                "Does the pond have a low level of phosphate? (< 0.5 mg/l (ppm))",
              path: ["lowPhosphate"],
              visible: criterionApplies(7),
              customRules: [criterion7Passes("lowPhosphate")],
            }),
            textArea({
              label: "Methodology",
              path: ["methodology"],
              help: `Please describe the method used for analysis e.g. laboratory analysis or Kyoritsu quick kits`,
              visible: topoExpr(
                (env, run) =>
                  run(criterionApplies(7)) &&
                  run(criterion7LowNitrateOrPhosphate)
              ),
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "Please enter a description",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(criterionApplies(7)) &&
                      run(criterion7LowNitrateOrPhosphate)
                  ),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          help: `Add any comments you have about the pond e.g. it's condition, threats, presence of invasive non-native species.`,
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 5,
            }),
          ],
        }),
      ],
    }),
  ],
});
