import {
  attachmentField,
  columns2,
  form,
  integerField,
  minValue,
  multiSelect,
  page,
  pointField,
  required,
  section,
  textArea,
  textField,
  timestampField,
  userRefField,
  vstack,
} from "@cartographerio/topo-form";
import {
  Thames21BadgedGroupEventActivityEnum,
  Thames21BadgedGroupEventLargeRubbishEnum,
  Thames21BadgedGroupEventSettingEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

export default form({
  title: "River Action Group Event",
  surveyorLabel: "Event Leader",
  surveyorHelp: "The main event leader.",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: columns2(
            textField({
              label: "Name of Group",
              path: ["data", "badgedGroupName"],
              required: required("info"),
            }),
            userRefField({
              label: "Second Event Leader",
              path: ["data", "secondSurveyor"],
              help: "A second event leader, if applicable.",
            }),
            userRefField({
              label: "Third Event Leader",
              path: ["data", "thirdSurveyor"],
              help: "A third event leader, if applicable.",
            })
          ),
        }),
        section({
          title: "Date, Time, and Setting",
          path: [],
          blocks: columns2(
            timestampField({
              label: "Date and Time of Event",
              path: ["data", "recorded"],
              defaultValue: "now",
              required: required("info"),
              help: "The date and time of the start of the event.",
            }),
            integerField({
              label: "Duration",
              path: ["data", "duration"],
              units: "mins",
              required: required("info"),
              help: "The event duration in minutes.",
            })
          ),
        }),
        section({
          title: "Volunteers",
          path: [],
          blocks: columns2(
            integerField({
              label: "Number of Volunteers",
              path: ["data", "numberOfVolunteers"],
              required: required("info"),
              bounds: minValue(0),
              help: "The total number of volunteers who attended.",
            }),
            integerField({
              label: "Number of Youth Volunteers",
              path: ["data", "numberOfYouthVolunteers"],
              bounds: minValue(0),
              help: "If there were any volunteers under 16, enter the number here.",
            })
          ),
        }),
        section({
          title: "Location",
          path: [],
          blocks: columns2(
            pointField({
              label: "Upstream",
              path: ["data", "location", "upstream"],
              required: required("info"),
              help: "The location of the upstream extent of the event area.",
            }),
            pointField({
              label: "Downstream",
              path: ["data", "location", "downstream"],
              required: required("info"),
              help: "The location of the downstream extent of the event area.",
            }),
            textField({
              label: "Location Name",
              path: ["data", "location", "name"],
              required: required("info"),
              help: "Descriptive name of the location.",
            }),
            integerField({
              label: "Area Covered",
              path: ["data", "location", "areaCovered"],
              required: required("info"),
              bounds: minValue(0),
              help: "Area covered during the event.",
            }),
            textArea({
              label: "Area Covered Comments",
              path: ["data", "location", "areaCoveredComments"],
              fullWidth: true,
              rows: 5,
              help: outdent`
                Please enter any additional comments
                about the area covered during the event.
                (e.g. Was there a starting point and ending point?
                Were there surrounding green spaces covered in addition to the waterway?)
                `,
            })
          ),
        }),
        section({
          title: "Setting and Activities",
          path: [],
          blocks: columns2(
            multiSelect({
              label: "Event Setting",
              path: ["data", "setting"],
              options: Thames21BadgedGroupEventSettingEnum.entries,
              help: outdent`
                What kind of setting was the focus of the event?
                Select all that apply.
                If you select "Other Green Space" or "Other",
                please provide details in the Comments at the end of the form.`,
            }),
            multiSelect({
              label: "Activities Undertaken",
              path: ["data", "activities"],
              options: Thames21BadgedGroupEventActivityEnum.entries,
              help: outdent`
                What activities did your group engage in during the event?
                Select all that apply.
                If you select "Other",
                please provide details in the Comments at the end of the form.`,
            })
          ),
        }),
        section({
          title: "Rubbish Collected",
          path: [],
          blocks: columns2(
            vstack(
              integerField({
                label: "Amount of Rubbish Collected",
                path: ["data", "rubbishBagsCollected"],
                units: "bags",
                required: required("info"),
                bounds: minValue(0),
                help: "Total number of bags of rubbish collected.",
              }),
              integerField({
                label: "Amount of Recycling Collected",
                path: ["data", "recyclingBagsCollected"],
                units: "bags",
                required: required("info"),
                bounds: minValue(0),
                help: "Total number of bags of recycling collected.",
              }),
              integerField({
                label: "Large/Bulk Rubbish Count",
                path: ["data", "largeRubbishCount"],
                units: "items",
                required: required("info"),
                bounds: minValue(0),
                help: "Total number of large/bulk rubbish items collected.",
              })
            ),
            multiSelect({
              label: "Types of Large/Bulk Rubbish Collected",
              path: ["data", "largeRubbishTypes"],
              options: Thames21BadgedGroupEventLargeRubbishEnum.entries,
              help: outdent`
                Select all that apply.
                If you select "Other",
                please provide details in the Comments at the end of the form.`,
            })
          ),
        }),
        section({
          title: "Partering Agencies and Organisations",
          path: [],
          blocks: columns2(
            textArea({
              label: "Rubbish Collected By",
              path: ["data", "rubbishCollectedBy"],
              rows: 3,
              help: outdent`
                Who (e.g. local authority, contracted waste removal company, etc)
                removed your rubbish from the cleanup site?`,
            }),
            textArea({
              label: "Partnering Agencies",
              path: ["data", "partneringAgencies"],
              rows: 3,
              help: outdent`
                List any other partnering agencies, if any,
                who were involved in your event.
                These could be agencies you had to seek permissions from,
                businesses that made donations or helped you publicise your event,
                media outlets that covered your event, etc.`,
            })
          ),
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
              help: "Photographs of the cleanup site.",
            }),
          ],
        }),
        section({
          title: "Comments",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 5,
              help: "Please include any comments including details of any additional surveyors.",
            }),
          ],
        }),
      ],
    }),
  ],
});
