import { Env } from "@cartographerio/topo-core";
import {
  topoExpr,
  attachmentField,
  checkbox,
  columns2,
  CustomRule,
  customRule,
  dynamicImage,
  enumSelectOptions,
  form,
  multiSelect,
  numberField,
  page,
  pointField,
  required,
  requiredIff,
  section,
  select,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { Result } from "@cartographerio/fp";
import { isArray, isNullable, isString } from "@cartographerio/guard";
import {
  NwcSeedbankAreaEnum,
  NwcSeedbankCollectionEnum,
  NwcSeedbankEvidenceOfPollutionEnum,
  NwcSeedbankLandUseEnum,
  NwcSeedbankPermissionEnum,
  NwcSeedbankProblemPlantSpecies,
  NwcSeedbankProblemPlantSpeciesEnum,
  NwcSeedbankSoilTypeEnum,
  NwcSeedbankSpeciesEnum,
  NwcSeedbankVegetationEnum,
} from "@cartographerio/inventory-enums";
import { isBoolean } from "lodash";

function problemPlantSpeciesImage(
  species: NwcSeedbankProblemPlantSpecies | null | undefined
): string | null {
  switch (species) {
    case "Other":
    case null:
    case undefined:
      return null;
    default:
      return `https://media.cartographer.io/static/images/nwc-seedbank/problem-plant-species/${species}.jpg`;
  }
}

function problemPlantSpeciesLabel(
  species: NwcSeedbankProblemPlantSpecies | null | undefined
): string | null {
  switch (species) {
    case "Other":
    case null:
    case undefined:
      return null;
    default:
      return NwcSeedbankProblemPlantSpeciesEnum.labelOf(species);
  }
}

function fetchCollectionAndPermission(env: Env) {
  return Result.tupled([
    env.getAs(
      ["data", "collection"],
      isNullable(NwcSeedbankCollectionEnum.isValue)
    ),
    env.getAs(
      ["data", "permission"],
      isNullable(NwcSeedbankPermissionEnum.isValue)
    ),
  ]);
}

const permissionRules: CustomRule[] = [
  customRule({
    level: "info",
    message:
      "Please ensure you obtain landowner permission prior to collecting seeds.",
    triggerWhen: topoExpr(env =>
      fetchCollectionAndPermission(env)
        .map(
          ([collection, permission]) =>
            collection === "Intending" && permission !== "Yes"
        )
        .getOrElse(() => false)
    ),
  }),
  customRule({
    level: "error",
    message:
      "We cannot accept seeds if you did not obtain landowner permission prior to collection.",
    triggerWhen: topoExpr(env =>
      fetchCollectionAndPermission(env)
        .map(
          ([collection, permission]) =>
            collection === "Collected" && permission !== "Yes"
        )
        .getOrElse(() => false)
    ),
  }),
];

const wildlifeSpeciesRule = customRule({
  level: "info",
  message: "You should specify which species you observed.",
  triggerWhen: topoExpr(env =>
    Result.tupled([
      env
        .getAs(["data", "wildlifeSpotted"], isNullable(isBoolean))
        .recover(_ => false),
      env
        .getAs(["data", "wildlifeSpeciesPollinators"], isNullable(isString))
        .recover(_ => null),
      env
        .getAs(["data", "wildlifeSpeciesOther"], isNullable(isString))
        .recover(_ => null),
    ])
      .map(
        ([spotted, pollinatorSpecies, otherSpecies]) =>
          !!(spotted && !pollinatorSpecies && !otherSpecies)
      )
      .getOrElse(() => false)
  ),
});

export default form({
  title: "Wildflower Warriors",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date and Time",
              path: ["data", "recorded"],
              defaultValue: "now",
              required: required("info"),
              help: "Date and time the observation/sample was taken in the field.",
            }),
            pointField({
              label: "Location",
              path: ["data", "location"],
              help: "Location of the wildflower.",
              fullWidth: true,
              required: required("info"),
            }),
            textField({
              label: "Postcode",
              path: ["data", "postcode"],
              help: "Postcode of the location (if applicable).",
            }),
          ],
        }),
        section({
          title: "Collection",
          path: [],
          blocks: [
            select({
              label: "Are you collecting seeds?",
              path: ["data", "collection"],
              options: enumSelectOptions(NwcSeedbankCollectionEnum),
            }),
            select({
              label:
                "Have you obtained permission from the landowner prior to collection?",
              path: ["data", "permission"],
              options: enumSelectOptions(NwcSeedbankPermissionEnum),
              customRules: permissionRules,
            }),
          ],
        }),
        section({
          title: "Conditions",
          path: [],
          blocks: [
            textField({
              label: "Weather",
              path: ["data", "weather"],
              help: "The weather conditions at the date/time and location of collection (optional).",
            }),
            numberField({
              label: "Air Temperature",
              path: ["data", "airTemperature"],
              units: "°C",
              help: "The air temperature at the date/time and location of collection (optional).",
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Wildflower Species",
          path: [],
          blocks: [
            multiSelect({
              label: "Species Identified for Seed Collection",
              path: ["data", "species", "selected"],
              options: enumSelectOptions(NwcSeedbankSpeciesEnum),
            }),
            textField({
              label: "Other Species",
              path: ["data", "species", "other"],
              help: 'If you selected "Other" above, please specify.',
              customRules: requiredIff({
                level: "info",
                requiredMessage: "You should specify the species.",
                forbiddenMessage:
                  "Only enter a value if you selected 'Other' above.",
                otherPath: ["data", "species", "selected"],
                otherTest: value =>
                  isArray(value) &&
                  value.includes(NwcSeedbankSpeciesEnum.Other),
              }),
            }),
            select({
              label: "Approximate Area",
              path: ["data", "area"],
              options: enumSelectOptions(NwcSeedbankAreaEnum),
              help: "Approximate area covered by the wildflower species.",
            }),
          ],
        }),
        section({
          title: "Land Use",
          path: [],
          blocks: [
            select({
              label: "Dominant Land Use (Within 50m)",
              path: ["data", "dominantLandUse", "selected"],
              options: enumSelectOptions(NwcSeedbankLandUseEnum),
              required: required("info"),
            }),
            textField({
              label: "Other Dominant Land Use",
              path: ["data", "dominantLandUse", "other"],
              help: 'If you selected "Other" above, please specify.',
              customRules: requiredIff({
                level: "info",
                requiredMessage: "You should specify the land use.",
                forbiddenMessage:
                  "Only enter a value if you selected 'Other' above.",
                otherPath: ["data", "dominantLandUse", "selected"],
                otherTest: value => value === NwcSeedbankLandUseEnum.Other,
              }),
            }),
          ],
        }),
        section({
          title: "Vegetation",
          path: [],
          blocks: [
            select({
              label: "Dominant Vegetation",
              path: ["data", "dominantVegetation", "selected"],
              options: enumSelectOptions(NwcSeedbankVegetationEnum),
              required: required("info"),
            }),
            textField({
              label: "Other Dominant Vegetation",
              path: ["data", "dominantVegetation", "other"],
              help: 'If you selected "Other" above, please specify.',
              customRules: requiredIff({
                level: "info",
                requiredMessage: "You should specify the vegetation.",
                forbiddenMessage:
                  "Only enter a value if you selected 'Other' above.",
                otherPath: ["data", "dominantVegetation", "selected"],
                otherTest: value => value === NwcSeedbankVegetationEnum.Other,
              }),
            }),
          ],
        }),
        section({
          title: "Soil Type",
          path: [],
          blocks: [
            select({
              label: "Soil Type",
              path: ["data", "soilType", "selected"],
              options: enumSelectOptions(NwcSeedbankSoilTypeEnum),
              required: required("info"),
            }),
            textField({
              label: "Other Soil Type",
              path: ["data", "soilType", "other"],
              help: 'If you selected "Other" above, please specify.',
              customRules: requiredIff({
                level: "info",
                requiredMessage: "You should specify the soil type.",
                forbiddenMessage:
                  "Only enter a value if you selected 'Other' above.",
                otherPath: ["data", "soilType", "selected"],
                otherTest: value => value === NwcSeedbankSoilTypeEnum.Other,
              }),
            }),
          ],
        }),
        section({
          title: "Problem Plant Species",
          path: [],
          blocks: [
            columns2(
              vstack(
                select({
                  label: "Problem Plant Species",
                  path: ["data", "problemPlantSpecies", "selected"],
                  options: enumSelectOptions(
                    NwcSeedbankProblemPlantSpeciesEnum
                  ),
                }),
                textField({
                  label: "Other Problem Plant Species",
                  path: ["data", "problemPlantSpecies", "other"],
                  help: 'If you selected "Other" above, please specify.',
                  customRules: requiredIff({
                    level: "info",
                    requiredMessage: "You should specify the species.",
                    forbiddenMessage:
                      "Only enter a value if you selected 'Other' above.",
                    otherPath: ["data", "problemPlantSpecies", "selected"],
                    otherTest: value =>
                      value === NwcSeedbankProblemPlantSpeciesEnum.Other,
                  }),
                })
              ),
              dynamicImage({
                url: topoExpr(env =>
                  env
                    .getAs(
                      ["data", "problemPlantSpecies", "selected"],
                      isNullable(NwcSeedbankProblemPlantSpeciesEnum.isValue)
                    )
                    .recover(_ => undefined)
                    .map(problemPlantSpeciesImage)
                    .getOrNull()
                ),
                caption: topoExpr(env =>
                  env
                    .getAs(
                      ["data", "problemPlantSpecies", "selected"],
                      isNullable(NwcSeedbankProblemPlantSpeciesEnum.isValue)
                    )
                    .recover(_ => undefined)
                    .map(problemPlantSpeciesLabel)
                    .getOrNull()
                ),
                precacheUrls: NwcSeedbankProblemPlantSpeciesEnum.values
                  .map(problemPlantSpeciesImage)
                  .flatMap(url => (url == null ? [] : [url])),
              })
            ),
          ],
        }),
        section({
          title: "Wildlife",
          path: [],
          blocks: [
            checkbox({
              label: "Wildlife Spotted",
              checkboxLabel: "Wildlife spotted?",
              path: ["data", "wildlifeSpotted"],
            }),
            textField({
              label: "Pollinator Species",
              path: ["data", "wildlifeSpeciesPollinators"],
              help: "Please list any pollinator species spotted, if applicable.",
              customRules: [wildlifeSpeciesRule],
            }),
            textField({
              label: "Other Wildlife Species",
              path: ["data", "wildlifeSpeciesOther"],
              help: "Please list any other species spotted, if applicable.",
              customRules: [wildlifeSpeciesRule],
            }),
          ],
        }),
        section({
          title: "Pollution",
          path: [],
          blocks: [
            multiSelect({
              label: "Evidence of Pollution",
              path: ["data", "evidenceOfPollution", "selected"],
              options: enumSelectOptions(NwcSeedbankEvidenceOfPollutionEnum),
            }),
            textField({
              label: "Other Evidence of Pollution",
              path: ["data", "evidenceOfPollution", "other"],
              help: 'If you selected "Other" above, please specify.',
              customRules: requiredIff({
                level: "info",
                requiredMessage: "You should specify the evidence.",
                forbiddenMessage:
                  "Only enter a value if you selected 'Other' above.",
                otherPath: ["data", "evidenceOfPollution", "selected"],
                otherTest: value =>
                  isArray(value) &&
                  value.includes(NwcSeedbankProblemPlantSpeciesEnum.Other),
              }),
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: "Notes",
              path: ["data", "notes"],
              rows: 3,
            }),
          ],
        }),
      ],
    }),
  ],
});
