// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesPathWidth = "A" | "N" | "M" | "W";

const Absent: CesPathWidth = "A";
const Narrow: CesPathWidth = "N";
const Medium: CesPathWidth = "M";
const Wide: CesPathWidth = "W";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesPathWidthEnum = {
  ...createEnum("CesPathWidth", [
    [Absent, "Absent"],
    [Narrow, "<1m"],
    [Medium, "1-5m"],
    [Wide, ">5m"],
  ]),
  Absent,
  Narrow,
  Medium,
  Wide,
};

export default CesPathWidthEnum;
