// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopFishStocking = "High" | "Low" | "None";

const High: UclPitStopFishStocking = "High";
const Low: UclPitStopFishStocking = "Low";
const None: UclPitStopFishStocking = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopFishStockingEnum = {
  ...createEnum("UclPitStopFishStocking", [
    [
      High,
      "High - Large evidence of fish during visit (e.g. seen or surfacing)",
    ],
    [Low, "Low - Evidence of some fish presence during visit (e.g. surfacing)"],
    [None, "None - No evidence of fish presence"],
  ]),
  High,
  Low,
  None,
};

export default UclPitStopFishStockingEnum;
