// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessFishSpecies =
  | "Bullhead"
  | "Pike"
  | "BrownTrout"
  | "Perch";

const Bullhead: NeLakeNaturalnessFishSpecies = "Bullhead";
const Pike: NeLakeNaturalnessFishSpecies = "Pike";
const BrownTrout: NeLakeNaturalnessFishSpecies = "BrownTrout";
const Perch: NeLakeNaturalnessFishSpecies = "Perch";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessFishSpeciesEnum = {
  ...createEnum("NeLakeNaturalnessFishSpecies", [
    [Bullhead, "Bullhead (Cottus gobio)"],
    [Pike, "Pike (Esox lucius)"],
    [BrownTrout, "Brown trout (Salmo trutta)"],
    [Perch, "Perch (Perca fluviatilis)"],
  ]),
  Bullhead,
  Pike,
  BrownTrout,
  Perch,
};

export default NeLakeNaturalnessFishSpeciesEnum;
