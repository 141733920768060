// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityFlowObstacle =
  | "None"
  | "Weir"
  | "Tree"
  | "DebrisDam"
  | "BridgeOrCulvert"
  | "Other";

const None: ArrtWaterQualityFlowObstacle = "None";
const Weir: ArrtWaterQualityFlowObstacle = "Weir";
const Tree: ArrtWaterQualityFlowObstacle = "Tree";
const DebrisDam: ArrtWaterQualityFlowObstacle = "DebrisDam";
const BridgeOrCulvert: ArrtWaterQualityFlowObstacle = "BridgeOrCulvert";
const Other: ArrtWaterQualityFlowObstacle = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityFlowObstacleEnum = {
  ...createEnum("ArrtWaterQualityFlowObstacle", [
    [None, "None"],
    [Weir, "Weir"],
    [Tree, "Fallen or Submerged Tree"],
    [DebrisDam, "Debris Dam"],
    [BridgeOrCulvert, "Bridge/Culvert"],
    [Other, "Other Artificial Structure"],
  ]),
  None,
  Weir,
  Tree,
  DebrisDam,
  BridgeOrCulvert,
  Other,
};

export default ArrtWaterQualityFlowObstacleEnum;
