// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeNameSource = "MapData" | "Knowledge" | "Assigned";

const MapData: NeNameSource = "MapData";
const Knowledge: NeNameSource = "Knowledge";
const Assigned: NeNameSource = "Assigned";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeNameSourceEnum = {
  ...createEnum("NeNameSource", [
    [MapData, "Map data"],
    [Knowledge, "Local knowledge"],
    [Assigned, "Personally assigned"],
  ]),
  MapData,
  Knowledge,
  Assigned,
};

export default NeNameSourceEnum;
