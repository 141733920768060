// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusStudyAreaOwnership = "public" | "private" | "mixture";

const Public: EcostatusStudyAreaOwnership = "public";
const Private: EcostatusStudyAreaOwnership = "private";
const Mixture: EcostatusStudyAreaOwnership = "mixture";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusStudyAreaOwnershipEnum = {
  ...createEnum("EcostatusStudyAreaOwnership", [
    [Public, "Public"],
    [Private, "Private"],
    [Mixture, "Mixture"],
  ]),
  Public,
  Private,
  Mixture,
};

export default EcostatusStudyAreaOwnershipEnum;
