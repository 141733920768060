// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsNuisanceSpecies =
  | "himalayanBalsam"
  | "japaneseKnotweed"
  | "giantHogweed"
  | "floatingPennywort"
  | "australianSwampStonecrop"
  | "parrotsFeather"
  | "creepingWaterPrimrose"
  | "other";

const HimalayanBalsam: UrsNuisanceSpecies = "himalayanBalsam";
const JapaneseKnotweed: UrsNuisanceSpecies = "japaneseKnotweed";
const GiantHogweed: UrsNuisanceSpecies = "giantHogweed";
const FloatingPennywort: UrsNuisanceSpecies = "floatingPennywort";
const AustralianSwampStonecrop: UrsNuisanceSpecies = "australianSwampStonecrop";
const ParrotsFeather: UrsNuisanceSpecies = "parrotsFeather";
const CreepingWaterPrimrose: UrsNuisanceSpecies = "creepingWaterPrimrose";
const Other: UrsNuisanceSpecies = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsNuisanceSpeciesEnum = {
  ...createEnum("UrsNuisanceSpecies", [
    [HimalayanBalsam, "Himalayan Balsam"],
    [JapaneseKnotweed, "Japanese Knotweed"],
    [GiantHogweed, "Giant Hogweed"],
    [FloatingPennywort, "Floating Pennywort"],
    [AustralianSwampStonecrop, "Australian Swamp Stonecrop"],
    [ParrotsFeather, "Parrot's Feather"],
    [CreepingWaterPrimrose, "Creeping Water Primrose"],
    [Other, "Other (specify)"],
  ]),
  HimalayanBalsam,
  JapaneseKnotweed,
  GiantHogweed,
  FloatingPennywort,
  AustralianSwampStonecrop,
  ParrotsFeather,
  CreepingWaterPrimrose,
  Other,
};

export default UrsNuisanceSpeciesEnum;
