// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopEdgeGradient = "Shallow" | "Medium" | "Steep";

const Shallow: UclPitStopEdgeGradient = "Shallow";
const Medium: UclPitStopEdgeGradient = "Medium";
const Steep: UclPitStopEdgeGradient = "Steep";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopEdgeGradientEnum = {
  ...createEnum("UclPitStopEdgeGradient", [
    [Shallow, "Shallow sloping edge (20 degrees angle or less)"],
    [Medium, "Medium sloping edge (between 20 and 45 degrees)"],
    [Steep, "Steep sloping edge (between 45 degrees and 90)"],
  ]),
  Shallow,
  Medium,
  Steep,
};

export default UclPitStopEdgeGradientEnum;
