import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import { ProjectRoleNameEnum, TeamAlias } from "@cartographerio/types";
import { useDisclosure } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import Button from "../../../components/Button";
import InvitationModal from "../../../components/InvitationModal";
import PageTopBar from "../../../components/PageTopBar";
import Popover from "../../../components/Popover";
import Select from "../../../components/Select";
import WithPermission from "../../../components/WithPermission";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useProjectHasTeams } from "../../../hooks/useProjectHasTeams";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import useUserLimitReason from "../../../hooks/useUserLimitReason";
import { routes } from "../../../routes";
import BaseInvitationListPage from "../../base/BaseInvitationListPage";
import ProjectPageHeader from "./ProjectPageHeader";

export default function ProjectInvitationListPage(
  props: RouteProps<typeof routes.workspace.project.invitation.list>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const multiTeam = useProjectHasTeams(workspace, project);

  const teams = useSuspenseSearchResults(
    queries.when(multiTeam, () =>
      queries.team.v2.forProject(apiParams, project.id)
    )
  );

  const userLimitReason = useUserLimitReason(workspace);

  useRequirePermissionRedirect(checks.project.viewInvitations(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  usePageTitle(`Invitations - ${project.name} - ${workspace.name}`);

  const selectedTeam = useMemo(
    () => teams?.find(({ alias }) => query.team === alias),
    [query.team, teams]
  );

  const teamOptions = useMemo<SelectOption<TeamAlias>[] | undefined>(
    () =>
      teams == null || teams.length === 0
        ? undefined
        : teams.map(({ name, alias }) => ({ label: name, value: alias })),
    [teams]
  );

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="invitation-single"
      />

      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="invitation-single"
      />

      <WithPermission check={checks.project.grantAccess(project)}>
        {canCreate => (
          <BaseInvitationListPage
            route={routes.workspace.project.invitation.list}
            query={query}
            updateQuery={updateQuery}
            project={project}
            projectRole={query.role}
            roleSelect={
              <Select.Nullable
                value={query.role}
                options={ProjectRoleNameEnum.entries}
                onChange={role => updateQuery({ ...query, role, page: 0 })}
                placeholder="All Roles"
                background="transparent"
                w="fit-content"
                maxW="52"
              />
            }
            team={selectedTeam}
            teamOptions={teamOptions}
            onTeamChange={team =>
              updateQuery({ ...query, team: team ?? undefined })
            }
            addButton={
              canCreate && (
                <Popover
                  enabled={userLimitReason != null}
                  placement="bottom-start"
                  body={userLimitReason}
                >
                  <Button
                    label="Invite"
                    colorScheme="blue"
                    onClick={onModalOpen}
                    disabled={userLimitReason != null}
                  />
                </Popover>
              )
            }
            itemLink={invitation =>
              routes.workspace.project.invitation.view.url([
                workspace.alias,
                project.alias,
                invitation.id,
              ])
            }
          />
        )}
      </WithPermission>

      <InvitationModal.Project
        title="Invite to Project"
        isOpen={isModalOpen}
        onClose={onModalClose}
        workspace={workspace}
        project={project}
        teams={teams}
      />
    </>
  );
}
