// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterSourceSize = "Small" | "Medium" | "Large";

const Small: MrsMudSpotterSourceSize = "Small";
const Medium: MrsMudSpotterSourceSize = "Medium";
const Large: MrsMudSpotterSourceSize = "Large";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterSourceSizeEnum = {
  ...createEnum("MrsMudSpotterSourceSize", [
    [Small, "Small"],
    [Medium, "Medium"],
    [Large, "Large"],
  ]),
  Small,
  Medium,
  Large,
};

export default MrsMudSpotterSourceSizeEnum;
