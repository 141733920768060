// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsUad = "U" | "D" | "A";

const Upstream: MrsUad = "U";
const Downstream: MrsUad = "D";
const Adjacent: MrsUad = "A";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsUadEnum = {
  ...createEnum("MrsUad", [
    [Upstream, "Upstream"],
    [Downstream, "Downstream"],
    [Adjacent, "Adjacent"],
  ]),
  Upstream,
  Downstream,
  Adjacent,
};

export default MrsUadEnum;
