import { Tagged, unsafeTag } from "@cartographerio/util";

export const iso8601Regex =
  /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?Z/;

export function isIso8601(value: unknown): value is string {
  return typeof value === "string" && iso8601Regex.test(value);
}

// date-fns formatting string
// https://date-fns.org/v2.19.0/docs/format
export type TimestampFormat = Tagged<"TimestampFormat">;

export function unsafeTimestampFormat(str: string): TimestampFormat {
  return unsafeTag(str);
}

export const ddmmyyyy = unsafeTimestampFormat("dd/MM/yyyy");

export const yyyymmdd = unsafeTimestampFormat("yyyy-MM-dd");

export const hhmm = unsafeTimestampFormat("HH:mm");

export const yyyymmddhhmm = unsafeTimestampFormat(`${yyyymmdd} ${hhmm}`);

export const defaultTimestampFormat = unsafeTimestampFormat(
  `${ddmmyyyy} ${hhmm}`
);

export const iso8601TimestampFormat = unsafeTimestampFormat(
  "yyyy-MM-dd'T'kk:mm:ss.SSSX"
);
