import { chain } from "lodash";
import {
  Project,
  QualificationRole,
  Role,
  SurveyStatus,
  SurveyStatusEnum,
  Team,
  User,
  UserRef,
  Workspace,
  WorkspaceId,
  nowTimestamp,
  projectApproverRole,
  projectCoordinatorRole,
  projectMemberRole,
  projectSurveyorRole,
  randomSurveyId,
  randomUserId,
  randomWorkspaceId,
  teamApproverRole,
  teamCoordinatorRole,
  teamMemberRole,
  teamSurveyorRole,
  unsafeScreenName,
  userRef,
  workspaceActiveRole,
  workspaceAdminRole,
  workspaceDisabledRole,
  workspaceOwnerRole,
} from "..";
import {
  Attachment,
  AttachmentFolder,
  ContentType,
  randomAttachmentFolder,
  randomAttachmentId,
  unsafeContentType,
} from "../attachment";
import {
  DataLicenseEnum,
  ProjectId,
  SurveyId,
  SurveyModuleId,
  TeamId,
  randomProjectId,
  randomTeamId,
  unsafeEmail,
  unsafeProjectAlias,
  unsafeTeamAlias,
  unsafeWorkspaceAlias,
} from "../core";
import {
  SchemaVersion,
  SurveyF,
  Survey,
  initialSchemaVersion,
} from "../survey";

export function testWorkspace(n: number): Workspace {
  return {
    id: randomWorkspaceId(),
    name: `Workspace ${n}`,
    alias: unsafeWorkspaceAlias(`workspace${n}`),
    features: [],
    accessLevel: "Full",
  };
}

export function testProject(
  n: number,
  workspaceId: WorkspaceId = randomWorkspaceId()
): Project {
  return {
    id: randomProjectId(),
    name: `Project ${n}`,
    alias: unsafeProjectAlias(`project${n}`),
    workspaceId,
    dataLicense: "Default",
    permissionModel: "Default",
    projectVisibility: "Workspace",
    mapVisibility: "Project",
    teamIds: [],
    features: [],
    qualificationIds: [],
    moduleIds: [],
    mapIds: [],
  };
}

export function testTeam(
  n: number,
  workspaceId: WorkspaceId = randomWorkspaceId()
): Team {
  return {
    id: randomTeamId(),
    name: `Team ${n}`,
    alias: unsafeTeamAlias(`team${n}`),
    workspaceId,
    projectIds: [],
    features: [],
  };
}

export function testProjectsAndTeams(
  linkage: [number, number][],
  workspaceId: WorkspaceId = randomWorkspaceId()
): [Project[], Team[]] {
  const pn = chain(linkage)
    .map(([p, _]) => p)
    .uniq()
    .sort()
    .value();
  const tn = chain(linkage)
    .map(([_, t]) => t)
    .uniq()
    .sort()
    .value();

  const projectIds = pn.map(_ => randomProjectId());
  const teamIds = tn.map(_ => randomTeamId());

  const projects = pn.map<Project>((n, index) => ({
    id: projectIds[index],
    name: `Project ${n}`,
    alias: unsafeProjectAlias(`project${n}`),
    workspaceId,
    dataLicense: "Default",
    permissionModel: "Default",
    projectVisibility: "Workspace",
    mapVisibility: "Project",
    teamIds: linkage
      .filter(([p, _]) => p === n)
      .map(([_, t]) => teamIds[tn.indexOf(t)]),
    features: [],
    qualificationIds: [],
    moduleIds: [],
    mapIds: [],
  }));

  const teams = tn.map<Team>((n, index) => ({
    id: teamIds[index],
    name: `Team ${n}`,
    alias: unsafeTeamAlias(`team${n}`),
    workspaceId,
    projectIds: linkage
      .filter(([_, t]) => t === n)
      .map(([p, _]) => projectIds[pn.indexOf(p)]),
    features: [],
  }));
  return [projects, teams];
}

export interface TestSurveyProps<A = unknown> {
  moduleId: SurveyModuleId;
  projectId: ProjectId;
  teamId?: TeamId | null;
  surveyor: UserRef;
  status?: SurveyStatus;
  schemaVersion?: SchemaVersion;
  data: A;
}

export function testSurvey<A>(props: TestSurveyProps<A>): SurveyF<A> {
  const {
    moduleId,
    projectId,
    teamId = null,
    surveyor,
    status,
    schemaVersion,
    data,
  } = props;

  return {
    id: randomSurveyId(),
    moduleId,
    projectId,
    teamId,
    surveyor,
    status: status ?? SurveyStatusEnum.Draft,
    schemaVersion: schemaVersion ?? initialSchemaVersion,
    data,
    created: nowTimestamp(),
    updated: nowTimestamp(),
  };
}

export function testUserRef(n: number): UserRef {
  return userRef(unsafeScreenName(`UserRef ${n}`), randomUserId());
}

export interface TestAttachmentProps {
  surveyId: SurveyId;
  folder?: AttachmentFolder;
  author?: UserRef;
  contentType?: ContentType;
  filename?: string;
}

export function testAttachment(
  n: number,
  props: TestAttachmentProps
): Attachment {
  const { surveyId, folder, author, contentType, filename } = props;

  return {
    surveyId,
    folder: folder ?? randomAttachmentFolder(),
    filename: filename ?? `attachment${n}.jpg`,
    contentType: contentType ?? unsafeContentType("image/jpeg"),
    author: author ?? userRef(),
    license: DataLicenseEnum.Default,
    created: nowTimestamp(),
    updated: nowTimestamp(),
    id: randomAttachmentId(),
  };
}

export interface TestAttachmentForSurveyProps {
  folder?: AttachmentFolder;
  author?: UserRef;
  contentType?: ContentType;
  filename?: string;
}

export function testAttachmentForSurvey(
  n: number,
  survey: Survey,
  folder: AttachmentFolder
): Attachment {
  const { id: surveyId, surveyor } = survey;

  return {
    surveyId,
    folder,
    filename: `attachment${n}.jpg`,
    contentType: unsafeContentType("image/jpeg"),
    author: surveyor,
    license: DataLicenseEnum.Default,
    created: nowTimestamp(),
    updated: nowTimestamp(),
    id: randomAttachmentId(),
  };
}

export function testUser(
  lastName: string,
  roles: Role[],
  qualificationRoles: QualificationRole[] = []
): User {
  const emailName = lastName.replace(/\s+/, "-").toLowerCase();
  return {
    firstName: "Joe",
    lastName,
    email: unsafeEmail(`${emailName}@example.com`),
    screenName: unsafeScreenName(`Joe ${lastName}`),
    roles,
    qualificationRoles,
    features: [],
    created: nowTimestamp(),
    updated: nowTimestamp(),
    id: randomUserId(),
  };
}

export function testProjectMember(
  n: number,
  workspaceId: WorkspaceId,
  projectId: ProjectId
): User {
  return testUser(`Project Member ${n}`, [
    workspaceActiveRole(workspaceId),
    projectMemberRole(projectId),
  ]);
}

export function testWorkspaceDisabled(
  n: number,
  workspaceId: WorkspaceId
): User {
  return testUser(`Workspace Disabled ${n}`, [
    workspaceDisabledRole(workspaceId),
  ]);
}

export function testWorkspaceActive(n: number, workspaceId: WorkspaceId): User {
  return testUser(`Workspace Active ${n}`, [workspaceActiveRole(workspaceId)]);
}

export function testWorkspaceAdmin(n: number, workspaceId: WorkspaceId): User {
  return testUser(`Workspace Admin ${n}`, [workspaceAdminRole(workspaceId)]);
}

export function testWorkspaceOwner(n: number, workspaceId: WorkspaceId): User {
  return testUser(`Workspace Owner ${n}`, [workspaceOwnerRole(workspaceId)]);
}

export function testTeamMember(
  n: number,
  workspaceId: WorkspaceId,
  teamId: TeamId
): User {
  return testUser(`Team Member ${n}`, [
    workspaceActiveRole(workspaceId),
    teamMemberRole(teamId),
  ]);
}

export function testProjectSurveyor(
  n: number,
  workspaceId: WorkspaceId,
  projectId: ProjectId
): User {
  return testUser(`Project Surveyor ${n}`, [
    workspaceActiveRole(workspaceId),
    projectSurveyorRole(projectId),
  ]);
}

export function testTeamSurveyor(
  n: number,
  workspaceId: WorkspaceId,
  teamId: TeamId
): User {
  return testUser(`Team Surveyor ${n}`, [
    workspaceActiveRole(workspaceId),
    teamSurveyorRole(teamId),
  ]);
}

export function testProjectApprover(
  n: number,
  workspaceId: WorkspaceId,
  projectId: ProjectId
): User {
  return testUser(`Project Approver ${n}`, [
    workspaceActiveRole(workspaceId),
    projectApproverRole(projectId),
  ]);
}

export function testTeamApprover(
  n: number,
  workspaceId: WorkspaceId,
  teamId: TeamId
): User {
  return testUser(`Team Approver ${n}`, [
    workspaceActiveRole(workspaceId),
    teamApproverRole(teamId),
  ]);
}

export function testProjectCoordinator(
  n: number,
  workspaceId: WorkspaceId,
  projectId: ProjectId
): User {
  return testUser(`Project Coordinator ${n}`, [
    workspaceActiveRole(workspaceId),
    projectCoordinatorRole(projectId),
  ]);
}

export function testTeamCoordinator(
  n: number,
  workspaceId: WorkspaceId,
  teamId: TeamId
): User {
  return testUser(`Team Coordinator ${n}`, [
    workspaceActiveRole(workspaceId),
    teamCoordinatorRole(teamId),
  ]);
}
