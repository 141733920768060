// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRiverWoodHydraulicType = "LT" | "FT" | "HJ" | "PJ" | "CJ" | "AJ";

const LeaningTree: MrsRiverWoodHydraulicType = "LT";
const FallenTree: MrsRiverWoodHydraulicType = "FT";
const HighJam: MrsRiverWoodHydraulicType = "HJ";
const PartialJam: MrsRiverWoodHydraulicType = "PJ";
const CompleteJam: MrsRiverWoodHydraulicType = "CJ";
const ActiveJam: MrsRiverWoodHydraulicType = "AJ";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRiverWoodHydraulicTypeEnum = {
  ...createEnum("MrsRiverWoodHydraulicType", [
    [LeaningTree, "Leaning tree"],
    [FallenTree, "Fallen tree"],
    [HighJam, "High jam"],
    [PartialJam, "Partial jam"],
    [CompleteJam, "Complete jam"],
    [ActiveJam, "Active jam"],
  ]),
  LeaningTree,
  FallenTree,
  HighJam,
  PartialJam,
  CompleteJam,
  ActiveJam,
};

export default MrsRiverWoodHydraulicTypeEnum;
