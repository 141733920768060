import {
  PermissionCheckRunner,
  check,
  checks,
} from "@cartographerio/permission";
import {
  Project,
  Survey,
  qualificationTraineeRole,
} from "@cartographerio/types";
import { useMemo } from "react";

export interface UseFormPermissionsResult {
  hasQualifications: boolean;
  canUpdateSurveyor: boolean;
  canUpdateData: boolean;
  canUpdateStatus: boolean;
  canApprove: boolean;
  canCopy: boolean;
  canDelete: boolean;
  canViewOnMap: boolean;
}

export interface UseFormPermissionsProps {
  runPermissionCheck: PermissionCheckRunner;
  project: Project;
  defaultSurvey: Survey;
  creating: boolean;
}

export function useFormPermissions(
  props: UseFormPermissionsProps
): UseFormPermissionsResult {
  const { runPermissionCheck, project, defaultSurvey, creating } = props;

  return useMemo(
    () => ({
      hasQualifications: runPermissionCheck(
        check.and(
          ...project.qualificationIds.map(qualId =>
            check.hasQualification(qualificationTraineeRole(qualId))
          )
        )
      ),
      canUpdateSurveyor: runPermissionCheck(
        checks.survey.updateSurveyor(project, defaultSurvey)
      ),
      canUpdateData: runPermissionCheck(
        checks.survey.updateData(project, defaultSurvey)
      ),
      canUpdateStatus: runPermissionCheck(
        checks.survey.updateStatus(project, defaultSurvey)
      ),
      canApprove: runPermissionCheck(
        checks.survey.approveWithTeam(project, defaultSurvey.teamId ?? null)
      ),
      canCopy:
        !creating &&
        runPermissionCheck(
          checks.survey.copy(
            project,
            project,
            defaultSurvey.teamId ?? null,
            defaultSurvey.teamId ?? null
          )
        ),
      canDelete: !creating,
      canViewOnMap: !creating,
    }),
    [creating, defaultSurvey, project, runPermissionCheck]
  );
}
