// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesBankProfile =
  | "Vn"
  | "Vu"
  | "Vo"
  | "St"
  | "Gt"
  | "Fl"
  | "Cm"
  | "Tg"
  | "Rs"
  | "Ts"
  | "Rb"
  | "Rv"
  | "Em"
  | "Pc";

const VerticalNatural: MrsMorphEstuariesBankProfile = "Vn";
const VerticalUndercut: MrsMorphEstuariesBankProfile = "Vu";
const VerticalOverhang: MrsMorphEstuariesBankProfile = "Vo";
const Steep: MrsMorphEstuariesBankProfile = "St";
const Gentle: MrsMorphEstuariesBankProfile = "Gt";
const Flat: MrsMorphEstuariesBankProfile = "Fl";
const Composite: MrsMorphEstuariesBankProfile = "Cm";
const TerracedGentle: MrsMorphEstuariesBankProfile = "Tg";
const ResectionedSloping: MrsMorphEstuariesBankProfile = "Rs";
const TerracedSteep: MrsMorphEstuariesBankProfile = "Ts";
const ResectionedBoltOn: MrsMorphEstuariesBankProfile = "Rb";
const ResectionedVertical: MrsMorphEstuariesBankProfile = "Rv";
const Embanked: MrsMorphEstuariesBankProfile = "Em";
const Poached: MrsMorphEstuariesBankProfile = "Pc";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesBankProfileEnum = {
  ...createEnum("MrsMorphEstuariesBankProfile", [
    [VerticalNatural, "Vertical (natural)"],
    [VerticalUndercut, "Undercut or vertical with undercut"],
    [VerticalOverhang, "Vertical with top overhang"],
    [Steep, "Steep (> 45 degrees)"],
    [Gentle, "Gentle (< 45 degrees)"],
    [Flat, "Flat / very gentle slope (< 5 degrees)"],
    [Composite, "Composite"],
    [TerracedGentle, "Terraced - gentle"],
    [ResectionedSloping, "Resectioned - sloping"],
    [TerracedSteep, "Terraced - steep / two-stage"],
    [ResectionedBoltOn, "Resectioned + bolt-on timbers"],
    [ResectionedVertical, "Resectioned - vertical"],
    [Embanked, "Embanked"],
    [Poached, "Poached"],
  ]),
  VerticalNatural,
  VerticalUndercut,
  VerticalOverhang,
  Steep,
  Gentle,
  Flat,
  Composite,
  TerracedGentle,
  ResectionedSloping,
  TerracedSteep,
  ResectionedBoltOn,
  ResectionedVertical,
  Embanked,
  Poached,
};

export default MrsMorphEstuariesBankProfileEnum;
