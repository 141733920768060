import { Option, Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  checkedToResult,
  isChecked,
  isSearchResults,
  isWorkspace,
  Message,
  SearchResults,
  WorkspaceRef,
  Workspace,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs, optionalContentAs } from "../../response";
import { UrlParts } from "../../url";
import { PartialParams } from "../params";
import { WorkspaceSearchOptionsV2 } from "./common";

const basePath = "/workspace/v2";

const defaultSearchOptions: PartialParams<WorkspaceSearchOptionsV2> = {
  order: "name-asc",
};

export function search(
  apiParams: ApiParams,
  options: PartialParams<WorkspaceSearchOptionsV2> = defaultSearchOptions
): IO<SearchResults<Workspace>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .chain(contentAs("SearchResults<Workspace>", isSearchResults(isWorkspace)));
}

export function searchUrl(
  options: PartialParams<WorkspaceSearchOptionsV2> = defaultSearchOptions
): UrlParts {
  return {
    path: basePath,
    query: { ...options },
  };
}

export function readOrFail(
  apiParams: ApiParams,
  workspace: WorkspaceRef
): IO<Workspace> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${workspace}` } })
    .chain(contentAs("Workspace", isWorkspace));
}

export function readOption(
  apiParams: ApiParams,
  workspace: WorkspaceRef
): IO<Option<Workspace>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${workspace}` } })
    .chain(optionalContentAs("Workspace", isWorkspace));
}

export function save(
  apiParams: ApiParams,
  workspace: Workspace
): IO<Result<Message[], Workspace>> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${workspace.id}` },
      body: workspace,
    })
    .flatMap(contentAs("Checked<Workspace>", isChecked(isWorkspace)))
    .map(checkedToResult);
}

export function remove(apiParams: ApiParams, ref: WorkspaceRef): IO<void> {
  return fetch
    .remove({ apiParams, url: { path: `${basePath}/${ref}` } })
    .void();
}
