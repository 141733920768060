// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankHorizontalProfile = "S" | "C" | "I";

const Straight: CesBankHorizontalProfile = "S";
const SmoothlyCurved: CesBankHorizontalProfile = "C";
const Irregular: CesBankHorizontalProfile = "I";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankHorizontalProfileEnum = {
  ...createEnum("CesBankHorizontalProfile", [
    [Straight, "Straight"],
    [SmoothlyCurved, "Smoothly curved"],
    [Irregular, "Irregular"],
  ]),
  Straight,
  SmoothlyCurved,
  Irregular,
};

export default CesBankHorizontalProfileEnum;
