import {
  Attribute,
  enumAttr,
  lookupBucket,
  marker,
  numberAttr,
  stringAttr,
} from "@cartographerio/topo-map";
import {
  MrsBedMaterialParticleSizeEnum,
  MrsRtaRiverCategoryEnum,
  MrsRtaRiverTypeEnum,
  UrsSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { range } from "lodash";

export const moduleNumberAttr = numberAttr({
  label: "Module Number",
  attributeId: "moduleNumber",
  decimalPlaces: 0,
  buckets: range(1, 11).map(value =>
    lookupBucket(value, marker.hue(36 * (value - 1)))
  ),
});

export const moduleNumbersAttr = stringAttr({
  attributeId: "moduleNumbers",
  label: "Module Numbers",
  buckets: "auto",
});

export const surveyTypeAttr = enumAttr({
  attributeId: "surveyType",
  label: "Survey Type",
  enum: UrsSurveyTypeEnum,
});

export function negativeIndex(attributeId: string, label: string): Attribute {
  return numberAttr({
    attributeId,
    label,
    decimalPlaces: 3,
    buckets: [
      lookupBucket(-4, marker.quality(0 / 8)),
      lookupBucket(-3, marker.quality(1 / 8)),
      lookupBucket(-2, marker.quality(2 / 8)),
      lookupBucket(-1, marker.quality(3 / 8)),
      lookupBucket(0, marker.quality(4 / 8)),
    ],
  });
}

export function positiveIndex(attributeId: string, label: string): Attribute {
  return numberAttr({
    attributeId,
    label,
    decimalPlaces: 3,
    buckets: [
      lookupBucket(0, marker.quality(4 / 8)),
      lookupBucket(1, marker.quality(5 / 8)),
      lookupBucket(2, marker.quality(6 / 8)),
      lookupBucket(3, marker.quality(7 / 8)),
      lookupBucket(4, marker.quality(8 / 8)),
    ],
  });
}

export function particleSizeAttr(
  attributeId: string,
  label: string,
  includeBedrock: boolean = true
): Attribute {
  const bedrockValues = includeBedrock
    ? [
        lookupBucket(
          MrsBedMaterialParticleSizeEnum.Bedrock,
          marker.quality(0.0),
          "Bedrock"
        ),
      ]
    : [];

  return stringAttr({
    attributeId,
    label,
    buckets: [
      ...bedrockValues,
      lookupBucket(
        MrsBedMaterialParticleSizeEnum.Boulder,
        marker.quality(0.1),
        "Boulder"
      ),
      lookupBucket(
        MrsBedMaterialParticleSizeEnum.Cobble,
        marker.quality(0.3),
        "Cobble"
      ),
      lookupBucket(
        MrsBedMaterialParticleSizeEnum.GravelPebble,
        marker.quality(0.5),
        "Gravel-Pebble"
      ),
      lookupBucket(
        MrsBedMaterialParticleSizeEnum.Sand,
        marker.quality(0.8),
        "Sand"
      ),
      lookupBucket(
        MrsBedMaterialParticleSizeEnum.Silt,
        marker.quality(0.9),
        "Silt"
      ),
      lookupBucket(
        MrsBedMaterialParticleSizeEnum.Clay,
        marker.quality(1.0),
        "Clay"
      ),
    ],
  });
}

export function invasivePlantAttr(
  attributeId: string,
  label: string
): Attribute {
  return stringAttr({
    attributeId,
    label,
    buckets: [
      lookupBucket("A", marker.quality(0.8), "Absent (0% cover)"),
      lookupBucket("T", marker.quality(0.5), "Trace (1-4% cover)"),
      lookupBucket("P", marker.quality(0.3), "Present (5-33% cover)"),
      lookupBucket("E", marker.quality(0.1), "Extensive (>33% cover)"),
    ],
  });
}

export function riverTypeAttr(attributeId: string, label: string): Attribute {
  return enumAttr({
    attributeId,
    label,
    enum: MrsRtaRiverTypeEnum,
    markers: {
      Navigable: marker.fromColor("#777"),
      Large: marker.fromColor("#eee"),
      A: marker.hue(Math.floor((360 * 0) / 12)),
      B: marker.hue(Math.floor((360 * 1) / 12)),
      C: marker.hue(Math.floor((360 * 2) / 12)),
      D: marker.hue(Math.floor((360 * 3) / 12)),
      E: marker.hue(Math.floor((360 * 4) / 12)),
      F: marker.hue(Math.floor((360 * 5) / 12)),
      G: marker.hue(Math.floor((360 * 6) / 12)),
      H: marker.hue(Math.floor((360 * 7) / 12)),
      I: marker.hue(Math.floor((360 * 8) / 12)),
      J: marker.hue(Math.floor((360 * 9) / 12)),
      K: marker.hue(Math.floor((360 * 10) / 12)),
      L: marker.hue(Math.floor((360 * 11) / 12)),
      M: marker.hue(Math.floor((360 * 12) / 12)),
    },
  });
}

export function riverCategoryAttr(
  attributeId: string,
  label: string
): Attribute {
  return enumAttr({
    attributeId,
    label,
    enum: MrsRtaRiverCategoryEnum,
    markers: {
      Navigable: marker.fromColor("#777"),
      Large: marker.fromColor("#eee"),
      Other: marker.fromColor("#0dd"),
    },
  });
}
