// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityPublicAccess = "Good" | "Poor" | "Other";

const Good: EsrtWaterQualityPublicAccess = "Good";
const Poor: EsrtWaterQualityPublicAccess = "Poor";
const Other: EsrtWaterQualityPublicAccess = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityPublicAccessEnum = {
  ...createEnum("EsrtWaterQualityPublicAccess", [
    [Good, "Good"],
    [Poor, "Poor"],
    [Other, "Other"],
  ]),
  Good,
  Poor,
  Other,
};

export default EsrtWaterQualityPublicAccessEnum;
