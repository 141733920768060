// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopEcologicalHealth = "Good" | "Moderate" | "Poor" | "Bad";

const Good: UclPitStopEcologicalHealth = "Good";
const Moderate: UclPitStopEcologicalHealth = "Moderate";
const Poor: UclPitStopEcologicalHealth = "Poor";
const Bad: UclPitStopEcologicalHealth = "Bad";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopEcologicalHealthEnum = {
  ...createEnum("UclPitStopEcologicalHealth", [
    [
      Good,
      "Good \u2013 You expect that water supports an abundant and diverse invertebrate community. Large areas of submerged plants",
    ],
    [Moderate, "Moderate \u2013 You expect reasonable invertebrate diversity"],
    [
      Poor,
      "Poor - Low invertebrate diversity (e.g. species such as midge and mosquito larvae). Few submerged plants",
    ],
    [
      Bad,
      "Bad - Clearly polluted, only pollution-tolerant invertebrates (such as rat-tailed maggots), no submerged plants",
    ],
  ]),
  Good,
  Moderate,
  Poor,
  Bad,
};

export default UclPitStopEcologicalHealthEnum;
