import {
  Bucket,
  attachmentAttr,
  attributeGroup,
  enumAttr,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  surveyAttr,
  surveyorAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  OutfallSafariActivityEnum,
  OutfallSafariAestheticsEnum,
  OutfallSafariBankEnum,
  OutfallSafariFlowOfDischargeEnum,
  OutfallSafariVisualImpactEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

function eaScoreBuckets(m: number): Bucket<number | null>[] {
  return [
    rangeBucket(m * 0, m * 2, marker.quality(10 / 10), String(m * 0)),
    rangeBucket(m * 2, m * 4, marker.quality(8 / 10), String(m * 2)),
    rangeBucket(m * 4, m * 6, marker.quality(6 / 10), String(m * 4)),
    rangeBucket(m * 6, m * 10, marker.quality(4 / 10), String(m * 6)),
    rangeBucket(m * 10, null, marker.quality(0 / 10), String(m * 10)),
    lookupBucket(null, marker.empty, "-"),
  ];
}

export const OutfallSafari = mapSchemaWithDefaults({
  mapId: unsafeMapId("OutfallSafari"),
  title: "Outfall Safari",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("OutfallSafari"),
      title: "Outfall Safari",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("OutfallSafari"),
        attribution: [],
      }),
      defaultAttribute: "overallScore",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            surveyAttr("surveyId", "Survey"),
            surveyorAttr(),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "activity",
              label: "Activity",
              enum: OutfallSafariActivityEnum,
              markers: {
                [OutfallSafariActivityEnum.Start]:
                  marker.fromColor("limegreen"),
                [OutfallSafariActivityEnum.Outfall]: marker.fromColor("cyan"),
                [OutfallSafariActivityEnum.Inaccessible]:
                  marker.fromColor("magenta"),
                [OutfallSafariActivityEnum.End]: marker.fromColor("orange"),
              },
            }),
          ],
        }),
        attributeGroup({
          label: "Outfall",
          attributes: [
            enumAttr({
              attributeId: "outfallBank",
              label: "Outfall Bank",
              enum: OutfallSafariBankEnum,
              markers: {
                [OutfallSafariBankEnum.Left]: marker.fromColor("orange"),
                [OutfallSafariBankEnum.Right]: marker.fromColor("cyan"),
              },
            }),
            enumAttr({
              attributeId: "flowOfDischarge",
              label: "Flow of Discharge",
              enum: OutfallSafariFlowOfDischargeEnum,
            }),
            enumAttr({
              attributeId: "visualImpact",
              label: "Visual Impact",
              enum: OutfallSafariVisualImpactEnum,
            }),
            enumAttr({
              attributeId: "aesthetics",
              label: "Aesthetics",
              enum: OutfallSafariAestheticsEnum,
            }),
            attachmentAttr("photographs", "Photographs of Outfalls"),
          ],
        }),
        attributeGroup({
          label: "Environment Agency Scores",
          attributes: [
            numberAttr({
              attributeId: "visualImpactScore",
              label: "Visual Impact Score",
              buckets: eaScoreBuckets(1),
              decimalPlaces: 0,
            }),
            numberAttr({
              attributeId: "aestheticsScore",
              label: "Aesthetics Score",
              buckets: eaScoreBuckets(1),
              decimalPlaces: 0,
            }),
            numberAttr({
              attributeId: "overallScore",
              label: "Overall Score",
              buckets: eaScoreBuckets(2),
              decimalPlaces: 0,
            }),
          ],
        }),
      ],
    }),
  ],
});
