import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { MouseEventHandler, ReactNode } from "react";

import ButtonLink from "./ButtonLink";
import MenuItemLink from "./MenuItemLink";
import Popover from "./Popover";

export type ButtonMenuOption = {
  label: string;
  disabledReason?: ReactNode;
} & ({ to?: string } | { onClick?: MouseEventHandler<HTMLButtonElement> });

export interface ButtonMenuProps {
  label: string;
  options: ButtonMenuOption[];
  colorScheme?: ButtonProps["colorScheme"];
}

export default function ButtonMenu(props: ButtonMenuProps): ReactNode {
  const { label, options, colorScheme, ...rest } = props;

  const firstOption = options[0];

  return firstOption == null ? null : options.length === 1 ? (
    <Popover
      enabled={firstOption.disabledReason != null}
      placement="bottom-start"
      body={firstOption.disabledReason}
    >
      {"to" in firstOption && firstOption.to != null ? (
        <ButtonLink.Internal colorScheme={colorScheme} to={firstOption.to}>
          {label}
        </ButtonLink.Internal>
      ) : (
        <Button
          colorScheme={colorScheme}
          onClick={"onClick" in firstOption ? firstOption.onClick : undefined}
        >
          {label}
        </Button>
      )}
    </Popover>
  ) : (
    <Menu placement="bottom-end">
      <MenuButton as={Button} colorScheme={colorScheme} {...rest}>
        {label}
      </MenuButton>
      <MenuList zIndex="popover">
        {options.map(option => (
          <Popover
            key={option.label}
            enabled={option.disabledReason != null}
            placement="bottom-start"
            body={option.disabledReason}
          >
            {"to" in option && option.to != null ? (
              <MenuItemLink.Internal
                to={option.to}
                isDisabled={option.disabledReason != null}
              >
                {option.label}
              </MenuItemLink.Internal>
            ) : (
              <MenuItem
                isDisabled={option.disabledReason != null}
                onClick={"onClick" in option ? option.onClick : undefined}
              >
                {option.label}
              </MenuItem>
            )}
          </Popover>
        ))}
      </MenuList>
    </Menu>
  );
}
