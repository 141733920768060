// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesReinforcementExtent = "W" | "U" | "L";

const Whole: CesReinforcementExtent = "W";
const Upper: CesReinforcementExtent = "U";
const Lower: CesReinforcementExtent = "L";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesReinforcementExtentEnum = {
  ...createEnum("CesReinforcementExtent", [
    [Whole, "Whole bank reinforced"],
    [Upper, "Upper bank reinforced"],
    [Lower, "Lower bank reinforced"],
  ]),
  Whole,
  Upper,
  Lower,
};

export default CesReinforcementExtentEnum;
