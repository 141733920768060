// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesDominance = "D" | "S1" | "S2";

const Dominant: CesDominance = "D";
const Subdominant1: CesDominance = "S1";
const Subdominant2: CesDominance = "S2";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesDominanceEnum = {
  ...createEnum("CesDominance", [
    [Dominant, "Dominant"],
    [Subdominant1, "Subdominant 1"],
    [Subdominant2, "Subdominant 2"],
  ]),
  Dominant,
  Subdominant1,
  Subdominant2,
};

export default CesDominanceEnum;
