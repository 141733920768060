// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankTopVegetationStructure = "TS" | "MI" | "GH";

const TreesAndShrubs: CesBankTopVegetationStructure = "TS";
const Mixed: CesBankTopVegetationStructure = "MI";
const GrassesAndHerbs: CesBankTopVegetationStructure = "GH";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankTopVegetationStructureEnum = {
  ...createEnum("CesBankTopVegetationStructure", [
    [TreesAndShrubs, "Mainly trees and shrubs"],
    [Mixed, "Mixed"],
    [GrassesAndHerbs, "Mainly grasses and herbs"],
  ]),
  TreesAndShrubs,
  Mixed,
  GrassesAndHerbs,
};

export default CesBankTopVegetationStructureEnum;
