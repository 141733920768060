import { raise } from "./raise";

export function envvar(name: string): string {
  return process.env[name] ?? raise(`envvar not defined: ${name}`);
}

export function optEnvvar(name: string): string | undefined {
  return process.env[name];
}

export function envvarOrDefault<A>(name: string, orElse: A): string | A {
  return optEnvvar(name) ?? orElse;
}
