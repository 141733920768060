import { PermissionCheck } from "@cartographerio/types";
import { check } from "../check";

export function canReadNotes(): PermissionCheck {
  return check.named("Can read admin notes", check.superuser);
}

export function canUpdateNotes(): PermissionCheck {
  return check.named("Can update admin notes", check.superuser);
}
