import {
  attachmentField,
  enumSelectOptions,
  form,
  page,
  pointField,
  required,
  section,
  select,
  textArea,
  timestampField,
} from "@cartographerio/topo-form";
import {
  StdInvasiveSpeciesAmountEnum,
  StdInvasiveSpeciesEnum,
  StdInvasiveSpeciesTreatmentEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

export default form({
  title: "Invasive Species",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date and Time",
              path: ["data", "recorded"],
              required: required("info"),
              help: "When was the survey completed in the field?",
              defaultValue: "now",
            }),
            pointField({
              label: "Location",
              path: ["data", "location"],
              required: required("info"),
              help: "Location of the survey site.",
              fullWidth: true,
            }),
          ],
        }),
        section({
          title: "Sighting",
          path: [],
          blocks: [
            select({
              label: "Species",
              path: ["data", "species"],
              options: enumSelectOptions(StdInvasiveSpeciesEnum),
              required: required("info"),
              help: outdent`
              What species did you observe (complete separate survey forms for each species).
              `,
            }),
            select({
              label: "Amount",
              path: ["data", "amount"],
              options: enumSelectOptions(StdInvasiveSpeciesAmountEnum),
              required: required("info"),
              help: outdent`
              What was the coverage of this species at the location?
              `,
            }),
            select({
              label: "Treatment",
              path: ["data", "treatment"],
              options: enumSelectOptions(StdInvasiveSpeciesTreatmentEnum),
              required: required("info"),
              help: outdent`
              Had the affected area been treated?
              `,
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
              help: outdent`
              Capture the extent of the species in one or more photographs.
              `,
            }),
          ],
        }),
        section({
          title: "Comments",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 5,
              help: outdent`
              Please include any comments, including details of any treatment
              and any additional surveyors.
              `,
            }),
          ],
        }),
      ],
    }),
  ],
});
