import { Path } from "@cartographerio/topo-core";
import {
  attachmentField,
  Block,
  enumSelectOptions,
  form,
  grid,
  multiSelect,
  page,
  required,
  section,
  select,
  SelectValue,
  textArea,
  timestampField,
} from "@cartographerio/topo-form";
import {
  NeRestorationRelevanceEnum,
  NeRiverRestorationPrioritiesBiologicalMeasureEnum,
  NeRiverRestorationPrioritiesChemicalMeasureEnum,
  NeRiverRestorationPrioritiesHydrologicalMeasureEnum,
  NeRiverRestorationPrioritiesPhysicalMeasureEnum,
} from "@cartographerio/inventory-enums";
import { Enum } from "@cartographerio/util";
import { outdent } from "outdent";
import { neRiverSiteInfoSection } from "../NeRiverNaturalness/form";

export const riverRestorationPrioritiesGuidanceUrl =
  "https://priorityhabitats.org/river-restoration-priorities-form-guidance";

interface RestorationPrioritiesSelectionsSectionOpts {
  title: string;
  guidanceLink: string;
  relevancePath: Path;
  measuresPath: Path;
  measuresEnum: Enum<SelectValue>;
  otherMeasuresPath: Path;
}

export function restorationPrioritiesSelectionsSection(
  opts: RestorationPrioritiesSelectionsSectionOpts
): Block {
  const {
    title,
    guidanceLink,
    relevancePath,
    measuresPath,
    measuresEnum,
    otherMeasuresPath,
  } = opts;

  return section({
    title,
    path: [],
    blocks: [
      grid({
        columns: 2,
        blocks: [
          select({
            label: "Is this Relevant to the Envisaged Restoration?",
            path: relevancePath,
            fullWidth: true,
            options: enumSelectOptions(NeRestorationRelevanceEnum),
            required: required("info"),
          }),
          multiSelect({
            label: "Restoration Measures",
            path: measuresPath,
            help: "Tick all measures that apply",
            options: enumSelectOptions(measuresEnum, false),
          }),
          textArea({
            label: "Additional Information",
            path: otherMeasuresPath,
            rows: 10,
            help: outdent`
            Add further description of appropriate measures
            including any not listed.
            Check suitability of measures in the [guidance document](${guidanceLink}).
            `,
          }),
        ],
      }),
    ],
  });
}

export const riverRestorationPrioritiesSelectionsSections = [
  restorationPrioritiesSelectionsSection({
    title: "Physical Naturalness",
    guidanceLink: riverRestorationPrioritiesGuidanceUrl,
    relevancePath: ["data", "selections", "physical"],
    measuresPath: ["data", "selections", "physicalMeasures"],
    measuresEnum: NeRiverRestorationPrioritiesPhysicalMeasureEnum,
    otherMeasuresPath: ["data", "selections", "otherPhysicalMeasures"],
  }),
  restorationPrioritiesSelectionsSection({
    title: "Hydrological Naturalness",
    guidanceLink: riverRestorationPrioritiesGuidanceUrl,
    relevancePath: ["data", "selections", "hydrological"],
    measuresPath: ["data", "selections", "hydrologicalMeasures"],
    measuresEnum: NeRiverRestorationPrioritiesHydrologicalMeasureEnum,
    otherMeasuresPath: ["data", "selections", "otherHydrologicalMeasures"],
  }),
  restorationPrioritiesSelectionsSection({
    title: "Chemical Naturalness",
    guidanceLink: riverRestorationPrioritiesGuidanceUrl,
    relevancePath: ["data", "selections", "chemical"],
    measuresPath: ["data", "selections", "chemicalMeasures"],
    measuresEnum: NeRiverRestorationPrioritiesChemicalMeasureEnum,
    otherMeasuresPath: ["data", "selections", "otherChemicalMeasures"],
  }),
  restorationPrioritiesSelectionsSection({
    title: "Biological Naturalness",
    guidanceLink: riverRestorationPrioritiesGuidanceUrl,
    relevancePath: ["data", "selections", "biological"],
    measuresPath: ["data", "selections", "biologicalMeasures"],
    measuresEnum: NeRiverRestorationPrioritiesBiologicalMeasureEnum,
    otherMeasuresPath: ["data", "selections", "otherBiologicalMeasures"],
  }),
];

export const restorationPrioritiesPhotographsSection = section({
  title: "Photographs",
  path: [],
  help: outdent`
  Please provide up to four photos showing the general character of the site
  and key impacts that would be addressed by restoration.
  `,
  blocks: [
    attachmentField({
      label: null,
      path: ["data", "photographs"],
      maxFiles: 4,
    }),
  ],
});

export const restorationPrioritiesNotesSection = section({
  title: "Any Other Relevant Information",
  path: [],
  help: outdent`
  Briefly describe how far thinking/planning has got,
  including any landowner liaison, funding, obstacles to implementation,
  indications of local stakeholder support, etc.
  `,
  blocks: [
    textArea({
      label: null,
      path: ["data", "notes"],
      rows: 10,
    }),
  ],
});

export default form({
  title: "River/Stream Restoration Priorities (Reach Based)",
  surveyorLabel: "Proposer",
  pages: [
    page({
      title: null,
      path: [],
      help: outdent`
      For further information on completing this assessment,
      please see the [guidance document](${riverRestorationPrioritiesGuidanceUrl})
      `,
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                }),
              ],
            }),
          ],
        }),
        neRiverSiteInfoSection(),
        ...riverRestorationPrioritiesSelectionsSections,
        restorationPrioritiesPhotographsSection,
        restorationPrioritiesNotesSection,
      ],
    }),
  ],
});
