import {
  WorkspaceLimits,
  WorkspaceUnsubscribed,
  WorkspaceUsage,
} from "@cartographerio/types";
import { Card, CardBody, SimpleGrid } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import queries from "../../queries";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import TrialSection from "./TrialSection";
import UsageLimitsSection from "./UsageLimitsSection";
import UsageSection from "./UsageSection";

export interface WorkspaceUnsubscribedViewProps {
  summary: WorkspaceUnsubscribed;
  limits: WorkspaceLimits;
  usage: WorkspaceUsage;
}

export default function WorkspaceUnsubscribedView(
  props: WorkspaceUnsubscribedViewProps
) {
  const {
    summary: { workspaceId, workspaceAlias, settings, usage: billingUsage },
    limits,
    usage: workspaceUsage,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();

  const onLimitsChange = useCallback(
    (newLimits: WorkspaceLimits) =>
      queries.workspace.limits.v1
        .save(queryClient, apiParams, workspaceId, newLimits)
        .unsafeRun(),
    [apiParams, queryClient, workspaceId]
  );

  return (
    <Spaced spacing="8">
      <TrialSection
        workspaceAlias={workspaceAlias}
        usage={billingUsage}
        trialEnd={settings.unsubscribedTrialEnd}
        couponId={settings.unsubscribedCouponId}
      />
      <SimpleGrid columns={[1, 2]} gap="8">
        <Card>
          <CardBody>
            <UsageSection usage={workspaceUsage} />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <UsageLimitsSection
              limits={limits}
              usage={workspaceUsage}
              onLimitsChange={onLimitsChange}
            />
          </CardBody>
        </Card>
      </SimpleGrid>
    </Spaced>
  );
}
