// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21BadgedGroupEventActivity =
  | "LandBasedLitterPick"
  | "WaterBasedLitterPick"
  | "ForeshoreCleanup"
  | "ClearingInvasives"
  | "ClearingVegetation"
  | "PlantingOrGardening"
  | "WildlifeEnhancements"
  | "SurveyingOrMonitoring"
  | "Other";

const LandBasedLitterPick: Thames21BadgedGroupEventActivity =
  "LandBasedLitterPick";
const WaterBasedLitterPick: Thames21BadgedGroupEventActivity =
  "WaterBasedLitterPick";
const ForeshoreCleanup: Thames21BadgedGroupEventActivity = "ForeshoreCleanup";
const ClearingInvasives: Thames21BadgedGroupEventActivity = "ClearingInvasives";
const ClearingVegetation: Thames21BadgedGroupEventActivity =
  "ClearingVegetation";
const PlantingOrGardening: Thames21BadgedGroupEventActivity =
  "PlantingOrGardening";
const WildlifeEnhancements: Thames21BadgedGroupEventActivity =
  "WildlifeEnhancements";
const SurveyingOrMonitoring: Thames21BadgedGroupEventActivity =
  "SurveyingOrMonitoring";
const Other: Thames21BadgedGroupEventActivity = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21BadgedGroupEventActivityEnum = {
  ...createEnum("Thames21BadgedGroupEventActivity", [
    [LandBasedLitterPick, "Land-Based Litter Pick"],
    [WaterBasedLitterPick, "Water-Based Litter Pick"],
    [ForeshoreCleanup, "Foreshore Cleanup"],
    [ClearingInvasives, "Clearing Invasives"],
    [ClearingVegetation, "Clearing Vegetation/Weeding"],
    [PlantingOrGardening, "Planting/Gardening"],
    [WildlifeEnhancements, "Wildlife Enhancements (e.g. building bird boxes)"],
    [SurveyingOrMonitoring, "Surveying/Monitoring"],
    [Other, "Other"],
  ]),
  LandBasedLitterPick,
  WaterBasedLitterPick,
  ForeshoreCleanup,
  ClearingInvasives,
  ClearingVegetation,
  PlantingOrGardening,
  WildlifeEnhancements,
  SurveyingOrMonitoring,
  Other,
};

export default Thames21BadgedGroupEventActivityEnum;
