// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsReinforcementVerticalExtent = "A" | "T" | "B" | "W";

const Absent: MrsReinforcementVerticalExtent = "A";
const Top: MrsReinforcementVerticalExtent = "T";
const Bottom: MrsReinforcementVerticalExtent = "B";
const Whole: MrsReinforcementVerticalExtent = "W";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsReinforcementVerticalExtentEnum = {
  ...createEnum("MrsReinforcementVerticalExtent", [
    [Absent, "Absent"],
    [Top, "Top"],
    [Bottom, "Bottom"],
    [Whole, "Whole"],
  ]),
  Absent,
  Top,
  Bottom,
  Whole,
};

export default MrsReinforcementVerticalExtentEnum;
