import { PricingPlan, allFeatures } from "@cartographerio/pricing";
import {
  BillingInterval,
  PlanLimits,
  PricingScheme,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { List, ListItem } from "@chakra-ui/react";
import { ReactNode } from "react";

import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { formatAmount, pluralize } from "./helpers";

export interface PlanFeaturesProps {
  pricingPlan: PricingPlan;
  pricingScheme: PricingScheme;
  limits: PlanLimits;
  billingInterval: BillingInterval;
  premium: boolean;
}

type AnyFeatureSupport = Record<string, Record<string, boolean>>;

export default function PlanFeatures({
  pricingPlan,
  pricingScheme,
  limits,
  billingInterval,
  premium,
}: PlanFeaturesProps) {
  const featureSupport: AnyFeatureSupport = pricingPlan.features;

  const injectIntoProjectsAndUsers = (
    <>
      <PricingSchemeItems
        pricingScheme={pricingScheme}
        billingInterval={billingInterval}
        limits={limits}
        premium={premium}
      />
    </>
  );

  return (
    <>
      {allFeatures.map(category => (
        <FeatureList key={category.id} title={category.title}>
          {category.id === "ProjectsAndUsers" && injectIntoProjectsAndUsers}
          {category.features.map(feature => (
            <FeatureItem
              key={feature.id}
              text={feature.label ?? feature.title}
              checked={featureSupport[category.id]?.[feature.id] ?? false}
            />
          ))}
        </FeatureList>
      ))}
    </>
  );
}

interface FeatureListProps {
  title: string;
  mobile?: "hidden";
  children: ReactNode;
}

function FeatureList({ title, mobile, children }: FeatureListProps) {
  return (
    <Spaced
      spacing="2"
      display={mobile === "hidden" ? ["none", "block"] : undefined}
    >
      <Para fontWeight="bold" color="black" fontSize="sm">
        {title}
      </Para>
      <List spacing="2" fontSize="sm">
        {children}
      </List>
    </Spaced>
  );
}

interface PricingSchemeItemsProps {
  pricingScheme: PricingScheme;
  billingInterval: BillingInterval;
  limits: PlanLimits;
  premium: boolean;
}

function PricingSchemeItems({
  pricingScheme,
  billingInterval,
  limits,
  premium,
}: PricingSchemeItemsProps) {
  const intervalLabel = billingInterval === "Monthly" ? "month" : "year";

  if (premium) {
    return (
      <>
        <FeatureItem checked={true} text="Unlimited users" />
        <FeatureItem checked={true} text="Unlimited projects" />
      </>
    );
  } else {
    switch (pricingScheme.type) {
      case "Free": {
        const { maxUsers, maxProjects } = limits;

        return (
          <>
            {maxUsers != null && maxUsers > 0 && (
              <FeatureItem
                checked={true}
                text={`Maximum of ${maxUsers} ${pluralize(
                  maxUsers ?? 0,
                  "user"
                )}`}
              />
            )}
            {maxProjects != null && maxProjects > 0 && (
              <FeatureItem
                checked={true}
                text={`Maximum of ${maxProjects} ${pluralize(
                  maxProjects ?? 0,
                  "project"
                )}`}
              />
            )}
          </>
        );
      }

      case "PerProject": {
        const { inclusiveUnits: num, unitAmount: amt } = pricingScheme;
        return (
          <>
            <FeatureItem
              checked={true}
              text={
                num === 0
                  ? "Unlimited projects"
                  : `${num} ${pluralize(num, "project")} included in base price`
              }
            />
            <FeatureItem
              checked={true}
              text={
                num === 0
                  ? `${formatAmount(
                      amt,
                      billingInterval
                    )} per project per ${intervalLabel}`
                  : `+${formatAmount(amt, billingInterval)} per extra project`
              }
            />
          </>
        );
      }

      case "PerUser": {
        const { inclusiveUnits: num, unitAmount: amt } = pricingScheme;
        return (
          <>
            <FeatureItem
              checked={true}
              text={
                num === 0
                  ? "Unlimited users"
                  : `${num} ${pluralize(num, "user")} included`
              }
            />
            <FeatureItem
              checked={true}
              text={
                num === 0
                  ? `${formatAmount(
                      amt,
                      billingInterval
                    )} per user per ${intervalLabel}`
                  : `+${formatAmount(amt, billingInterval)} per extra user`
              }
            />
          </>
        );
      }

      default:
        return checkExhausted(pricingScheme);
    }
  }
}

interface FeatureItemProps {
  checked: boolean;
  text: string;
}

function FeatureItem({ checked, text }: FeatureItemProps) {
  return (
    <ListItem>
      {checked ? "✅" : "❌"} {text}
    </ListItem>
  );
}
