import proj4 from "proj4";
import { Position } from "./type";

// Definition taken from:
// https://spatialreference.org/ref/epsg/27700/proj4/
proj4.defs(
  "OSGB36",
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs"
);

const projection = proj4("OSGB36", "WGS84");

export function osgb36ToWgs84([easting, northing]: Position): Position {
  return projection.forward([easting, northing]);
}

export function wgs84ToOsgb36([lng, lat]: Position): Position {
  return projection.inverse([lng, lat]);
}
