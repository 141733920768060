import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  SearchResults,
  WorkspaceBillingSummary,
  WorkspaceRef,
  isSearchResults,
  isWorkspaceBillingSummary,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";
import { PartialParams } from "../../params";
import { WorkspaceBillingSummarySearchOptionsV1 } from "./common";

const basePath = "/billing/summary/v1";

const defaultSearchOptions: PartialParams<WorkspaceBillingSummarySearchOptionsV1> =
  {
    order: "name-asc",
  };

export function search(
  apiParams: ApiParams,
  options: PartialParams<WorkspaceBillingSummarySearchOptionsV1> = defaultSearchOptions
): IO<SearchResults<WorkspaceBillingSummary>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .chain(
      contentAs(
        "SearchResults<WorkspaceBillingSummary>",
        isSearchResults(isWorkspaceBillingSummary)
      )
    );
}

export function searchUrl(
  options: PartialParams<WorkspaceBillingSummarySearchOptionsV1> = defaultSearchOptions
): UrlParts {
  return {
    path: basePath,
    query: { ...options },
  };
}

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<WorkspaceBillingSummary> {
  const url = { path: `${basePath}/${workspaceRef}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(contentAs("WorkspaceBillingSummary", isWorkspaceBillingSummary));
}

export function readOption(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<Option<WorkspaceBillingSummary>> {
  const url = { path: `${basePath}/${workspaceRef}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(
      optionalContentAs("WorkspaceBillingSummary", isWorkspaceBillingSummary)
    );
}
