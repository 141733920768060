import { Option } from "@cartographerio/fp";
import {
  PermissionCheck,
  PermissionCheckParams,
  forbiddenError,
  internalError,
  superuserRole,
  workspaceAccessLevelGte,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import canGrantQualification from "./runCanGrantQualification";
import { canGrantRole } from "./runCanGrantRole";
import hasQualification from "./runHasQualification";
import { hasRole, hasWorkspaceRole } from "./runHasRole";

export type PermissionCheckRunner = (check: PermissionCheck) => boolean;

export function permissionCheckPasses(
  check: PermissionCheck,
  params: PermissionCheckParams,
  graph: WorkspaceGraph | null = null
): boolean {
  switch (check.type) {
    case "Always":
      return true;

    case "Never":
      return false;

    case "CanGrantRole":
      if (graph == null) {
        throw internalError(
          "Tried running GanGrantRole permission check without a workspace graph!"
        );
      } else {
        return canGrantRole(params.roles, graph, check.role);
      }

    case "CanGrantQualification":
      return canGrantQualification(
        params.roles,
        params.qualificationRoles,
        check.role
      );

    case "HasQualification":
      return hasQualification(
        params.roles,
        params.qualificationRoles,
        check.role
      );

    case "HasRole":
      return hasRole(params.roles, check.role);

    case "HasAccount":
      return params.id != null;

    case "HasUserId":
      return params.id === check.userId;

    case "And":
      return check.args.every(arg => permissionCheckPasses(arg, params, graph));

    case "Or":
      return check.args.some(arg => permissionCheckPasses(arg, params, graph));

    case "InAnyWorkspace":
      return hasWorkspaceRole(params.roles, check.name);

    case "Named":
      return permissionCheckPasses(check.check, params, graph);

    case "WorkspaceAccess":
      if (graph == null) {
        throw internalError(
          "Tried running WorkspaceAccess permission check without a workspace graph!"
        );
      } else {
        return (
          params.roles.includes(superuserRole) ||
          Option.wrap(graph.optFindWorkspaceById(check.workspaceId)).fold(
            () => false,
            ws => workspaceAccessLevelGte(ws.accessLevel, check.minAccess)
          )
        );
      }

    default:
      return checkExhausted(check);
  }
}

export function runPermissionCheck(
  check: PermissionCheck,
  user: PermissionCheckParams,
  graph?: WorkspaceGraph
): void {
  if (!permissionCheckPasses(check, user, graph)) {
    throw forbiddenError(check, user);
  }
}
