import { unsafeTag } from "@cartographerio/util";
import { AccessToken, ResetPasswordToken } from "./types.generated";

export function unsafeAccessToken(uuid: string): AccessToken {
  return unsafeTag<"AccessToken">(uuid);
}

export function unsafeResetPasswordToken(uuid: string): ResetPasswordToken {
  return unsafeTag<"ResetPasswordToken">(uuid);
}

// export interface LoginStartPayload<R> {
//   request: LoginRequest;
//   onLogin?: (credentials: Credentials<R>) => void;
// }
