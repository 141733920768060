// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesSmallFragmentDistribution = "C" | "P";

const Continuous: MrsMorphEstuariesSmallFragmentDistribution = "C";
const Patchy: MrsMorphEstuariesSmallFragmentDistribution = "P";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesSmallFragmentDistributionEnum = {
  ...createEnum("MrsMorphEstuariesSmallFragmentDistribution", [
    [Continuous, "Continuous"],
    [Patchy, "Patchy"],
  ]),
  Continuous,
  Patchy,
};

export default MrsMorphEstuariesSmallFragmentDistributionEnum;
