import {
  QualificationRole,
  Role,
  optParseQualificationRole,
  qualificationCoordinatorRole,
  qualificationTrainerRole,
  superuserRole,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import hasQualification from "./runHasQualification";

export default function canGrantQualification(
  roles: Role[],
  qualRoles: QualificationRole[],
  qualRole: QualificationRole
): boolean {
  if (roles.includes(superuserRole)) {
    return true;
  } else {
    const parts = optParseQualificationRole(qualRole);

    if (parts != null) {
      const [name, id] = parts;
      switch (name) {
        case "Expired":
        case "Trainee":
        case "Qualified":
          return hasQualification(
            roles,
            qualRoles,
            qualificationTrainerRole(id)
          );
        case "Trainer":
        case "Coordinator":
          return hasQualification(
            roles,
            qualRoles,
            qualificationCoordinatorRole(id)
          );
        default:
          return checkExhausted(name);
      }
    } else {
      return false;
    }
  }
}
