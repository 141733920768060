import * as fetch from "./fetch";
import { contentAs, optionalContentAs } from "./response";
import * as status from "./status";
export * from "./endpoints";
export * from "./headers";
export * from "./logging";
export * from "./url";

export { fetch, status, contentAs, optionalContentAs };

export { FetchLogger, NoopFetchLogger, ConsoleFetchLogger } from "./logging";

export {
  HttpMethod,
  defaultHeaders,
  setDefaultHeaders,
  fetchLogger,
  setFetchLogger,
} from "./fetchHelpers";
