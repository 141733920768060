import Semaphore from "semaphore-promise";

let nextId = 0;

export interface Mutex {
  name: string;
  semaphore: Semaphore;
}

export function createMutex(name?: string): Mutex {
  try {
    const prefixedName =
      name == null ? `mutex${nextId}` : `mutex${nextId}-${name}`;
    return {
      name: prefixedName,
      semaphore: new Semaphore(1, { name: prefixedName }),
    };
  } finally {
    nextId++;
  }
}
