import {
  attachmentField,
  form,
  grid,
  integerField,
  page,
  pointField,
  required,
  section,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";

import { outdent } from "outdent";

export default form({
  title: "Green Wall Module",
  surveyorHelp:
    "If the survey has been completed by multiple surveyors, please enter additional names in the Notes field.",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                grid({
                  columns: 1,
                  blocks: [
                    textField({
                      label: "Collected on behalf of",
                      path: ["data", "collectedOnBehalfOf"],
                      help: outdent`If the survey was collected on behalf of an organization, enter its name here.`,
                    }),
                    timestampField({
                      label: "Date and Time",
                      path: ["data", "recorded"],
                      help: outdent`Date and time the survey data was collected in the field.`,
                      required: required(
                        "info",
                        "You should specify the survey date."
                      ),
                    }),
                  ],
                }),
                pointField({
                  label: "Location",
                  region: "uk",
                  path: ["data", "location"],
                  help: outdent`Location of the survey site. Click on your survey location to give a 10-digit OS grid reference. Set the map mode to Satellite to zoom in further.`,
                  required: required("info", "You should enter a location"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Observations",
          path: ["data"],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                integerField({
                  label: "Number of plants",
                  path: ["numberOfPlants"],
                  required: required("info", "You should enter a number."),
                }),
                integerField({
                  label: "Percentage plant cover",
                  path: ["percentagePlantCover"],
                  units: "%",
                  required: required("info", "You should enter a percentage."),
                }),
              ],
            }),
            textArea({
              label: "Plant species",
              path: ["plantSpecies"],
              rows: 8,
              required: required("info", "You should record some species."),
            }),
            textArea({
              label: "Invasive plant species",
              path: ["invasivePlantSpecies"],
              rows: 8,
            }),
            textArea({
              label: "Wildlife",
              path: ["wildlife"],
              rows: 8,
            }),
          ],
        }),
        section({
          title: "Comments",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 8,
            }),
          ],
        }),
      ],
    }),
  ],
});
