import { HStack } from "@chakra-ui/react";
import { ReactElement } from "react";

import CallUsLink from "../components/CallUsLink";
import EmailUsLink from "../components/EmailUsLink";
import Fieldset from "../components/Fieldset";
import Para from "../components/Para";

export default function BillingDisabled(): ReactElement {
  return (
    <Fieldset legend="Billing Disabled" legendJustify="center">
      <Para __css={{ textWrap: "balance" }} textAlign="center">
        Subscription billing isn&apos;t set up for this workspace. You are
        either on a free trial or plan, or are being invoiced via external
        means.
      </Para>

      <Para __css={{ textWrap: "balance" }} textAlign="center">
        Please get in touch if you have any questions about billing:
      </Para>

      <HStack gap="4" justify="center">
        <CallUsLink />
        <EmailUsLink />
      </HStack>
    </Fieldset>
  );
}
