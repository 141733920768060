/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { Timestamp, isTimestamp } from "../core/types.generated";

export interface DateActivity {
  date: Timestamp;
  count: number;
}

export interface LabeledDateActivity {
  label: string;
  date: Timestamp;
  count: number;
}

export const isDateActivity = (v: any): v is DateActivity => {
  return (
    typeof v === "object" &&
    v != null &&
    "date" in v &&
    isTimestamp(v.date) &&
    "count" in v &&
    typeof v.count === "number"
  );
};

export const isLabeledDateActivity = (v: any): v is LabeledDateActivity => {
  return (
    typeof v === "object" &&
    v != null &&
    "label" in v &&
    typeof v.label === "string" &&
    "date" in v &&
    isTimestamp(v.date) &&
    "count" in v &&
    typeof v.count === "number"
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
