// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopNorfolkMostWanted =
  | "Charophytes"
  | "TurtleDove"
  | "GreatCrestedNewt"
  | "PalmateNewt"
  | "Toad"
  | "CrucianCarp";

const Charophytes: UclPitStopNorfolkMostWanted = "Charophytes";
const TurtleDove: UclPitStopNorfolkMostWanted = "TurtleDove";
const GreatCrestedNewt: UclPitStopNorfolkMostWanted = "GreatCrestedNewt";
const PalmateNewt: UclPitStopNorfolkMostWanted = "PalmateNewt";
const Toad: UclPitStopNorfolkMostWanted = "Toad";
const CrucianCarp: UclPitStopNorfolkMostWanted = "CrucianCarp";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopNorfolkMostWantedEnum = {
  ...createEnum("UclPitStopNorfolkMostWanted", [
    [Charophytes, "Charophytes (Stoneworts)"],
    [TurtleDove, "Turtle Dove"],
    [GreatCrestedNewt, "Great Crested Newt"],
    [PalmateNewt, "Palmate Newt"],
    [Toad, "Toad"],
    [CrucianCarp, "Crucian Carp"],
  ]),
  Charophytes,
  TurtleDove,
  GreatCrestedNewt,
  PalmateNewt,
  Toad,
  CrucianCarp,
};

export default UclPitStopNorfolkMostWantedEnum;
