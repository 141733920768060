import {
  attachmentAttr,
  attributeGroup,
  booleanAttr,
  enumAttr,
  lookupBucket,
  marker,
  pointLayer,
  surveyAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  NwcSeedbankAreaEnum,
  NwcSeedbankCollectionEnum,
  NwcSeedbankLandUseEnum,
  NwcSeedbankProblemPlantSpeciesEnum,
  NwcSeedbankSoilTypeEnum,
  NwcSeedbankSpecies,
  NwcSeedbankSpeciesEnum,
  NwcSeedbankVegetationEnum,
} from "@cartographerio/inventory-enums";
import { checkExhausted } from "@cartographerio/util";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

function speciesColor(species: NwcSeedbankSpecies): string {
  switch (species) {
    case "Betony":
      return "#be6ff7";
    case "BirdsfootTrefoil":
      return "#f7cffc";
    case "Bluebell":
      return "#646bf6";
    case "CommonCatsEar":
      return "#b1facf";
    case "CommonKnapweed":
      return "#ea3ef7";
    case "CornChamomile":
      return "#fffed1";
    case "CornMarigold":
      return "#fffd54";
    case "CornPoppy":
      return "#eb3223";
    case "CommonSorrel":
      return "#b8902f";
    case "Corncockle":
      return "#bb30c5";
    case "Cornflower":
      return "#3f99f7";
    case "CowParsley":
      return "#fffed1";
    case "DevilsBitScabious":
      return "#73fbfd";
    case "FieldScabious":
      return "#ed72c8";
    case "Foxglove":
      return "#eb4a97";
    case "GreaterBirdsfootTrefoil":
      return "#f8cd76";
    case "HedgeBedstraw":
      return "#b1f97b";
    case "LadysBedstraw":
      return "#eaeaea";
    case "MeadowButtercup":
      return "#f8cb46";
    case "Meadowsweet":
      return "#f7cecd";
    case "MuskMallow":
      return "#989bf8";
    case "OxeyeDaisy":
      return "#ffffff";
    case "PurpleLoosestrife":
      return "#5c1fc4";
    case "RaggedRobin":
      return "#be3f1e";
    case "RedCampion":
      return "#bb2965";
    case "RedClover":
      return "#8c3b64";
    case "RibwortPlantain":
      return "#8c3816";
    case "SelfHeal":
      return "#8b26c3";
    case "TuftedVetch":
      return "#0839c4";
    case "VipersBugloss":
      return "#5c1fc4";
    case "WildCarrot":
      return "#d5fda4";
    case "Yarrow":
      return "#c39a33";
    case "YellowFlagIris":
      return "#fefc7e";
    case "YellowRattle":
      return "#cbc942";
    case "Other":
      return "#ffcc00";
    default:
      return checkExhausted(species);
  }
}

export const NwcSeedbank = mapSchemaWithDefaults({
  mapId: unsafeMapId("NwcSeedbank"),
  title: "Wildflower Warriors",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("NwcSeedbank"),
      title: "Wildflower Warriors",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("NwcSeedbank"),
        attribution: [],
      }),
      defaultAttribute: "collection",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            surveyAttr("surveyId", "Survey"),
            attachmentAttr("photographs", "Photographs"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Seed Collection",
          attributes: [
            enumAttr({
              attributeId: "collection",
              label: "Seed Collection",
              enum: NwcSeedbankCollectionEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Wildflower Species",
          attributes: NwcSeedbankSpeciesEnum.entries.map(({ value, label }) =>
            booleanAttr({
              attributeId: `species${value}`,
              label,
              buckets: [
                lookupBucket(
                  true,
                  marker.fromColor(speciesColor(value)),
                  "Yes (Species Observed)"
                ),
                lookupBucket(false, marker.empty, "No (Species Not Observed)"),
              ],
            })
          ),
        }),
        attributeGroup({
          label: "Area",
          attributes: [
            enumAttr({
              attributeId: "area",
              label: "Approx Area",
              unit: "m2",
              enum: NwcSeedbankAreaEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Land Use",
          attributes: [
            enumAttr({
              attributeId: "dominantLandUse",
              label: "Dominant Land Use",
              enum: NwcSeedbankLandUseEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Vegetation",
          attributes: [
            enumAttr({
              attributeId: "dominantVegetation",
              label: "Dominant Vegetation",
              enum: NwcSeedbankVegetationEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Soil Type",
          attributes: [
            enumAttr({
              attributeId: "dominantSoilType",
              label: "Dominant Soil Type",
              enum: NwcSeedbankSoilTypeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Problem Plant Species",
          attributes: [
            enumAttr({
              attributeId: "problemPlantSpecies",
              label: "Problem Plant Species",
              enum: NwcSeedbankProblemPlantSpeciesEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Wildlife Spotted",
          attributes: [
            booleanAttr({
              attributeId: "wildlifeSpottedPollinators",
              label: "Pollinator Species",
              buckets: [
                lookupBucket(true, marker.fromColor("#0f0"), "Present"),
                lookupBucket(false, marker.fromColor("#aaa"), "Absent"),
              ],
            }),
            booleanAttr({
              attributeId: "wildlifeSpottedOther",
              label: "Other Wildlife Species",
              buckets: [
                lookupBucket(true, marker.fromColor("#0f0"), "Present"),
                lookupBucket(false, marker.fromColor("#aaa"), "Absent"),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
