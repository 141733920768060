// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesAquaticInvasivePlant = "FP" | "ASC" | "PF" | "CWP";

const FloatingPennywort: CesAquaticInvasivePlant = "FP";
const AustralianSwampStonecrop: CesAquaticInvasivePlant = "ASC";
const ParrotsFeather: CesAquaticInvasivePlant = "PF";
const CreepingWaterPrimrose: CesAquaticInvasivePlant = "CWP";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesAquaticInvasivePlantEnum = {
  ...createEnum("CesAquaticInvasivePlant", [
    [FloatingPennywort, "Floating pennywort"],
    [AustralianSwampStonecrop, "Australian swamp stonecrop"],
    [ParrotsFeather, "Parrot's feather"],
    [CreepingWaterPrimrose, "Creeping water primrose"],
  ]),
  FloatingPennywort,
  AustralianSwampStonecrop,
  ParrotsFeather,
  CreepingWaterPrimrose,
};

export default CesAquaticInvasivePlantEnum;
