import {
  attributeGroup,
  lookupBucket,
  MapSchema,
  marker,
  pointLayer,
  remoteTileSource,
  standardAttribution,
  stringAttr,
  thresholdAttr,
  thresholdBucket,
  timestampAttr,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { point } from "@cartographerio/geometry";
import { MapBaseEnum, unsafeMapId } from "@cartographerio/types";
import { mapSchemaWithDefaults } from "../../core";

export const RoyalParksSpotlighting: MapSchema = mapSchemaWithDefaults({
  mapId: unsafeMapId("RoyalParksSpotlighting"),
  title: "Regents Parks Spotlighting",
  defaultBase: MapBaseEnum.Satellite,
  defaultViewport: {
    center: point(-0.16, 51.53),
    zoom: 14,
  },
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("RoyalParksSpotlighting"),
      title: "Spotlighting",
      source: remoteTileSource({
        url: "mapbox://cartographerio.royal_parks_spotlighting",
        sourceLayer: "spotlighting",
        attribution: [
          standardAttribution({
            what: "Hedgehog Data",
            who: "Royal Parks Foundation",
            url: "https://www.royalparks.org.uk/managing-the-parks/conservation-and-improvement-projects/hedgehogs",
            from: "2014",
          }),
        ],
      }),
      defaultAttribute: "sex",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Hedgehog Details",
          attributes: [
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "sex",
              label: "Sex",
              buckets: [
                lookupBucket("male", marker.fromColor("#00ccff"), "Male"),
                lookupBucket("female", marker.fromColor("#ff5555"), "Female"),
                lookupBucket("unknown", marker.fromColor("#cccccc"), "Unknown"),
              ],
            }),
            stringAttr({
              attributeId: "age",
              label: "Age",
              buckets: [
                lookupBucket(
                  "juvenile",
                  marker.fromColor("#cc5500"),
                  "Juvenile"
                ),
                lookupBucket(
                  "subadult",
                  marker.fromColor("#ee8800"),
                  "Subadult"
                ),
                lookupBucket("adult", marker.fromColor("#ffcc00"), "Adult"),
              ],
            }),
            thresholdAttr({
              attributeId: "weight",
              label: "Weight",
              unit: "g",
              showDistribution: false,
              showTimeline: false,
              buckets: [
                thresholdBucket(200, marker.numeric(0.0)),
                thresholdBucket(400, marker.numeric(0.1)),
                thresholdBucket(600, marker.numeric(0.2)),
                thresholdBucket(800, marker.numeric(0.4)),
                thresholdBucket(1000, marker.numeric(0.6)),
                thresholdBucket(1200, marker.numeric(0.8)),
                thresholdBucket(1400, marker.numeric(1.0)),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
