import { checkExhausted } from "@cartographerio/util";
import { FeatureFieldLayer, FeatureFieldMapStyle } from "./type";

export function featureFieldSelectableLayer(
  mapStyle: FeatureFieldMapStyle
): FeatureFieldLayer {
  switch (mapStyle.type) {
    case "Cartographer":
      switch (mapStyle.geometryType) {
        case "Point":
        case "MultiPoint":
          return { id: `${mapStyle.layer}_circle`, type: "circle" };
        case "LineString":
        case "MultiLineString":
          return { id: `${mapStyle.layer}_line`, type: "line" };
        case "Polygon":
        case "MultiPolygon":
          return { id: `${mapStyle.layer}_fill`, type: "fill" };

        default:
          return checkExhausted(mapStyle.geometryType);
      }

    case "External":
      return mapStyle.selectableLayer;

    default:
      return checkExhausted(mapStyle);
  }
}

/**
 * @deprecated We need to move away from maps that support baked-in "selected" layers
 * because this technique doesn't correctly display features
 * that are recorded in surveys and subsequently moved on the underlying map.
 */
export function featureFieldSelectedLayers(
  mapStyle: FeatureFieldMapStyle
): FeatureFieldLayer[] {
  switch (mapStyle.type) {
    case "Cartographer":
      switch (mapStyle.geometryType) {
        case "Point":
        case "MultiPoint":
          return [{ id: `${mapStyle.layer}_selected_circle`, type: "circle" }];
        case "LineString":
        case "MultiLineString":
          return [{ id: `${mapStyle.layer}_selected_line`, type: "line" }];
        case "Polygon":
        case "MultiPolygon":
          return [
            { id: `${mapStyle.layer}_selected_fill`, type: "fill" },
            { id: `${mapStyle.layer}_selected_line`, type: "line" },
          ];

        default:
          return checkExhausted(mapStyle.geometryType);
      }

    case "External":
      return mapStyle.selectedLayers;

    default:
      return checkExhausted(mapStyle);
  }
}
