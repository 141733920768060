// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type RiverflyFirstBreachAction = "Repeat" | "None";

const Repeat: RiverflyFirstBreachAction = "Repeat";
const None: RiverflyFirstBreachAction = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RiverflyFirstBreachActionEnum = {
  ...createEnum("RiverflyFirstBreachAction", [
    [Repeat, "Second Sample Planned"],
    [None, "None"],
  ]),
  Repeat,
  None,
};

export default RiverflyFirstBreachActionEnum;
