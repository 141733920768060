import { SurveyStatusEnum } from "@cartographerio/types";
import { userRefSchema } from ".";
import { Schema } from "./type";
import {
  enumSchema,
  nullableSchema,
  productSchema,
  timestampSchema,
  uuidSchema,
} from "./create";

export function surveySchema(dataSchema: Schema): Schema {
  return productSchema({
    id: uuidSchema,
    projectId: uuidSchema,
    teamId: nullableSchema(uuidSchema),
    created: timestampSchema,
    updated: timestampSchema,
    surveyor: userRefSchema,
    status: enumSchema(SurveyStatusEnum.values),
    data: dataSchema,
  });
}
