// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRiverWoodRetainedByNaturalFeature =
  | "OT"
  | "BT"
  | "SC"
  | "SI"
  | "SB"
  | "IS"
  | "SF"
  | "SS"
  | "SV";

const OverlappingBankTop: MrsRiverWoodRetainedByNaturalFeature = "OT";
const PartBuriedInBank: MrsRiverWoodRetainedByNaturalFeature = "BT";
const SnaggedInChannelConstruction: MrsRiverWoodRetainedByNaturalFeature = "SC";
const SnaggedInBankIrregularity: MrsRiverWoodRetainedByNaturalFeature = "SI";
const SnaggedAgainstBar: MrsRiverWoodRetainedByNaturalFeature = "SB";
const SnaggedAgainstIsland: MrsRiverWoodRetainedByNaturalFeature = "IS";
const SnaggedAgainstFallenTree: MrsRiverWoodRetainedByNaturalFeature = "SF";
const SnaggedAgainstStandingTree: MrsRiverWoodRetainedByNaturalFeature = "SS";
const SnaggedByOther: MrsRiverWoodRetainedByNaturalFeature = "SV";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRiverWoodRetainedByNaturalFeatureEnum = {
  ...createEnum("MrsRiverWoodRetainedByNaturalFeature", [
    [OverlappingBankTop, "Overlapping the bank top"],
    [PartBuriedInBank, "Part buried in bank face/top"],
    [SnaggedInChannelConstruction, "Snagged in channel construction"],
    [SnaggedInBankIrregularity, "Snagged in bank irregularity"],
    [SnaggedAgainstBar, "Snagged against a bar"],
    [SnaggedAgainstIsland, "Snagged against an island"],
    [SnaggedAgainstFallenTree, "Snagged against (incorporates) a fallen tree"],
    [SnaggedAgainstStandingTree, "Snagged against a standing tree"],
    [SnaggedByOther, "Snagged by other riparian vegetation"],
  ]),
  OverlappingBankTop,
  PartBuriedInBank,
  SnaggedInChannelConstruction,
  SnaggedInBankIrregularity,
  SnaggedAgainstBar,
  SnaggedAgainstIsland,
  SnaggedAgainstFallenTree,
  SnaggedAgainstStandingTree,
  SnaggedByOther,
};

export default MrsRiverWoodRetainedByNaturalFeatureEnum;
