import { Email, Workspace } from "@cartographerio/types";
import { VStack } from "@chakra-ui/react";
import { ReactElement } from "react";

import { useApiParams } from "../contexts/auth";
import LoadingSpinner from "../form/AttachmentField/LoadingSpinner";
import { FrontContext } from "./context";
import { FrontWorkspaceItem } from "./FrontWorkspaceItem";
import {
  useFrontWorkspacesBySearchString,
  useFrontWorkspacesByUser,
} from "./useFrontWorkspaces";

export interface FrontWorkspacesTabProps {
  context: FrontContext;
  searchString?: string | null;
  emails?: Email[];
}

export default function FrontWorkspacesTab(
  props: FrontWorkspacesTabProps
): ReactElement {
  const { context, searchString = null, emails = [] } = props;

  return searchString == null ? (
    <FrontWorkspacesByUser context={context} emails={emails} />
  ) : (
    <FrontWorkspacesBySearchString
      context={context}
      searchString={searchString}
    />
  );
}

interface FrontWorkspacesByUserProps {
  context: FrontContext;
  emails: Email[];
}

function FrontWorkspacesByUser(props: FrontWorkspacesByUserProps) {
  const { context, emails } = props;

  const apiParams = useApiParams();

  const workspaces = useFrontWorkspacesByUser(apiParams, emails);

  return <FrontWorkspaces context={context} workspaces={workspaces} />;
}

interface FrontWorkspacesBySearchStringProps {
  context: FrontContext;
  searchString: string;
}

function FrontWorkspacesBySearchString(
  props: FrontWorkspacesBySearchStringProps
) {
  const { context, searchString } = props;

  const apiParams = useApiParams();

  const workspaces = useFrontWorkspacesBySearchString(apiParams, searchString);

  return <FrontWorkspaces context={context} workspaces={workspaces} />;
}

interface FrontWorkspacesProps {
  context: FrontContext;
  workspaces: Workspace[] | undefined;
}

function FrontWorkspaces(props: FrontWorkspacesProps) {
  const { context, workspaces } = props;

  return workspaces == null ? (
    <LoadingSpinner />
  ) : (
    <VStack alignItems="full">
      {workspaces.map(workspace => (
        <FrontWorkspaceItem
          key={workspace.id}
          context={context}
          workspace={workspace}
        />
      ))}
    </VStack>
  );
}
