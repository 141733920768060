import {
  attributeGroup,
  enumAttr,
  marker,
  pointLayer,
  stringAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { SasWaterQualitySiteTypeEnum } from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const SasWaterQualitySite = mapSchemaWithDefaults({
  mapId: unsafeMapId("SasWaterQualitySite"),
  title: "Coliforms Sites",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("SasWaterQualitySite"),
      title: "Coliforms Sites",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("SasWaterQualitySite"),
        attribution: [],
      }),
      defaultAttribute: "site",
      attributes: [
        attributeGroup({
          label: "Location",
          attributes: [
            stringAttr({
              attributeId: "river",
              label: "River",
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "site",
              label: "Site",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "siteType",
              label: "Site Type",
              enum: SasWaterQualitySiteTypeEnum,
              markers: {
                Upstream: marker.fromColor("limegreen"),
                Midstream: marker.fromColor("orange"),
                Downstream: marker.fromColor("deepskyblue"),
              },
            }),
          ],
        }),
      ],
    }),
  ],
});
