import { isBoolean, isNumber, isString } from "@cartographerio/guard";
import { checkExhausted } from "@cartographerio/util";
import { PropertyValue } from "../bucket";
import { Attribute } from "./type";
import { TeamId, isTeamId } from "@cartographerio/types";

export function isAutoAttribute(attr: Attribute): boolean {
  switch (attr.type) {
    case "FeatureAttribute":
      return false;
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
      switch (attr.buckets.type) {
        case "AutoBuckets":
          return true;
        case "StandardBuckets":
          return false;
        default:
          return checkExhausted(attr.buckets);
      }
    case "SurveyAttribute":
    case "TeamAttribute":
    case "AttachmentAttribute":
      return false;
    default:
      return checkExhausted(attr);
  }
}

export function attributePropertyName(attr: Attribute): string | null {
  switch (attr.type) {
    case "FeatureAttribute":
      return null;
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "SurveyAttribute":
    case "TeamAttribute":
      return attr.propertyName;
    case "AttachmentAttribute":
      return null;
    default:
      return checkExhausted(attr);
  }
}

export interface FormatAttributeValueOptions {
  teamLabel?: (teamId: TeamId) => string | null;
}

export function formatAttributeValue(
  attribute: Attribute,
  value: PropertyValue,
  options: FormatAttributeValueOptions = {}
): string | null {
  const { teamLabel = teamId => `Team ${teamId}` } = options;

  switch (attribute.type) {
    case "FeatureAttribute":
      return null;
    case "StringAttribute":
      return isString(value) ? attribute.format(value) : null;
    case "NumberAttribute":
      return isNumber(value) ? attribute.format(value) : null;
    case "BooleanAttribute":
      return isBoolean(value) ? attribute.format(value) : null;
    case "TimestampAttribute":
      return isNumber(value) ? attribute.format(value) : null;
    case "SurveyAttribute":
      return isString(value) ? `Survey ${value}` : null;
    case "TeamAttribute":
      return isTeamId(value) ? teamLabel(value) : null;
    case "AttachmentAttribute":
      return isString(value) ? "Attachments" : null;
    default:
      return checkExhausted(attribute);
  }
}

export function attributeUnit(attr: Attribute): string | null {
  switch (attr.type) {
    case "FeatureAttribute":
      return null;
    case "StringAttribute":
    case "NumberAttribute":
      return attr.unit ?? null;
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "SurveyAttribute":
    case "TeamAttribute":
    case "AttachmentAttribute":
      return null;
    default:
      return checkExhausted(attr);
  }
}
