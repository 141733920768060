// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type OutfallSafariVisualImpact =
  | "None"
  | "Under2m"
  | "Under10m"
  | "Under30m"
  | "Over30m";

const None: OutfallSafariVisualImpact = "None";
const Under2m: OutfallSafariVisualImpact = "Under2m";
const Under10m: OutfallSafariVisualImpact = "Under10m";
const Under30m: OutfallSafariVisualImpact = "Under30m";
const Over30m: OutfallSafariVisualImpact = "Over30m";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OutfallSafariVisualImpactEnum = {
  ...createEnum("OutfallSafariVisualImpact", [
    [None, "No visible effect (0)"],
    [Under2m, "Within 2m of the outfall (2)"],
    [Under10m, "Between 2m and 10m from the outfall (4)"],
    [Under30m, "Between 10m and 30m from the outfall (6)"],
    [Over30m, "Greater than 30m from the outfall (10)"],
  ]),
  None,
  Under2m,
  Under10m,
  Under30m,
  Over30m,
};

export default OutfallSafariVisualImpactEnum;
