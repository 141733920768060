import {
  attributeGroup,
  lookupBucket,
  MapId,
  MapSchema,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { MrsRtaLevelOfConfinementEnum } from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { particleSizeAttr, riverCategoryAttr, riverTypeAttr } from "./helpers";

function rtaMap(mapId: MapId, title: string): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,
    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId as string),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId as string),
          attribution: [],
        }),
        defaultAttribute: "finalRiverType",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "Survey Details",
            attributes: [
              stringAttr({
                attributeId: "riverName",
                label: "River",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "reachName",
                label: "Reach",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectName",
                label: "Project Name",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectCode",
                label: "MoRPh Correlation Code",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "wfdWaterBodyId",
                label: "WFD Water Body ID",
                buckets: "auto",
              }),
              surveyAttr("surveyId", "Survey"),
              // teamAttr("teamId", "Team"),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
            ],
          }),
          attributeGroup({
            label: "River Type",
            attributes: [riverTypeAttr("finalRiverType", "River Type")],
          }),
          attributeGroup({
            label: "Calculations",
            attributes: [
              riverCategoryAttr("riverCategory", "River Category"),
              numberAttr({
                attributeId: "a1",
                label: "A1: Braiding Index",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(null, 1.1, marker.quality(6 / 12)),
                  rangeBucket(1.1, 1.5, marker.quality(5 / 12)),
                  rangeBucket(1.5, null, marker.quality(4 / 12)),
                ],
              }),
              numberAttr({
                attributeId: "a2",
                label: "A2: Sinuosity Index",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(null, 1.05, marker.quality(6 / 12)),
                  rangeBucket(1.05, 1.5, marker.quality(5 / 12)),
                  rangeBucket(1.5, null, marker.quality(4 / 12)),
                ],
              }),
              numberAttr({
                attributeId: "a3",
                label: "A3: Anabranching Index",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(null, 1.05, marker.quality(6 / 12)),
                  rangeBucket(1.05, 1.5, marker.quality(5 / 12)),
                  rangeBucket(1.5, null, marker.quality(4 / 12)),
                ],
              }),
              stringAttr({
                attributeId: "a4",
                label: "A4: Level of Confinement",
                buckets: [
                  lookupBucket(
                    MrsRtaLevelOfConfinementEnum.Confined,
                    marker.quality(4 / 12),
                    "Confined"
                  ),
                  lookupBucket(
                    MrsRtaLevelOfConfinementEnum.PartlyConfined,
                    marker.quality(5 / 12),
                    "Partly confined"
                  ),
                  lookupBucket(
                    MrsRtaLevelOfConfinementEnum.Unconfined,
                    marker.quality(6 / 12),
                    "Unconfined"
                  ),
                ],
              }),
              numberAttr({
                attributeId: "a5",
                label: "A5: Reach Valley Gradient",
                decimalPlaces: 5,
                unit: "m/m",
                buckets: [
                  rangeBucket(null, 0.01, marker.quality(4 / 12)),
                  rangeBucket(0.01, null, marker.quality(6 / 12)),
                ],
              }),
              stringAttr({
                attributeId: "a6",
                label: "A6: Bedrock Reach?",
                buckets: "auto",
              }),
              particleSizeAttr("a7", "A7: Coarsest Bed Material", true),
              particleSizeAttr("a8", "A8: Average Bed Material", false),
              riverTypeAttr("calculatedRiverType", "Calculated River Type"),
              riverTypeAttr("overriddenRiverType", "Overridden River Type"),
            ],
          }),
        ],
      }),
    ],
  });
}

export const MrsRta = rtaMap(unsafeMapId("mrsRta"), "River Type");

export const MrsRtaPro = rtaMap(unsafeMapId("mrsRtaPro"), "River Type");
