// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityNitrate =
  | "N0"
  | "N1"
  | "N2"
  | "N5"
  | "N10"
  | "N20"
  | "N50";

const N0: ArrtWaterQualityNitrate = "N0";
const N1: ArrtWaterQualityNitrate = "N1";
const N2: ArrtWaterQualityNitrate = "N2";
const N5: ArrtWaterQualityNitrate = "N5";
const N10: ArrtWaterQualityNitrate = "N10";
const N20: ArrtWaterQualityNitrate = "N20";
const N50: ArrtWaterQualityNitrate = "N50";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityNitrateEnum = {
  ...createEnum("ArrtWaterQualityNitrate", [
    [N0, "0"],
    [N1, "1"],
    [N2, "2"],
    [N5, "5"],
    [N10, "10"],
    [N20, "20"],
    [N50, "50"],
  ]),
  N0,
  N1,
  N2,
  N5,
  N10,
  N20,
  N50,
};

export default ArrtWaterQualityNitrateEnum;
