import { errorMessage } from "@cartographerio/topo-core";
import { checks, permissionCheckPasses } from "@cartographerio/permission";
import { RuleBuilder } from "./types";

/**
 * Check whether the current user is able to create a survey in the selected team
 */
export const ownTeamRule: RuleBuilder =
  ({ identity, graph, project }) =>
  survey => {
    const { teamId } = survey;

    const passes = permissionCheckPasses(
      checks.survey.createWithTeam(project, teamId ?? null),
      {
        id: identity.userId,
        roles: identity.roles,
        qualificationRoles: identity.qualificationRoles,
      },
      graph
    );

    return passes
      ? []
      : [errorMessage("You cannot save surveys for this team")];
  };
