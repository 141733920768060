// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessBirdSpecies =
  | "Dipper"
  | "Kingfisher"
  | "GreyWagtail";

const Dipper: NeRiverNaturalnessBirdSpecies = "Dipper";
const Kingfisher: NeRiverNaturalnessBirdSpecies = "Kingfisher";
const GreyWagtail: NeRiverNaturalnessBirdSpecies = "GreyWagtail";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessBirdSpeciesEnum = {
  ...createEnum("NeRiverNaturalnessBirdSpecies", [
    [Dipper, "Dipper (Cinclus cinclus)"],
    [Kingfisher, "Kingfisher (Alcedo atthis)"],
    [GreyWagtail, "Grey wagtail (Motacilla cinerea)"],
  ]),
  Dipper,
  Kingfisher,
  GreyWagtail,
};

export default NeRiverNaturalnessBirdSpeciesEnum;
