import { checkExhausted } from "@cartographerio/util";
import { Field } from "./type";

export function fieldIsNullable(field: Field): boolean {
  switch (field.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
    case "IntegerField":
    case "NumberField":
    case "UserRefField":
    case "TimestampField":
    case "TeamField":
    case "PointField":
    case "LineStringField":
    case "PolygonField":
    case "Select":
      return field.required == null || field.required.level === "info";
    case "FeatureField":
    case "NearestFeatureField":
      return true;
    case "Checkbox":
    case "AttachmentField":
    case "MultiSelect":
      return false;
    default:
      return checkExhausted(field);
  }
}
