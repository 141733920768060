import {
  attributeGroup,
  enumAttr,
  pointLayer,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { SurveyStatusEnum, unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const Thames21GreenWallModule = mapSchemaWithDefaults({
  mapId: unsafeMapId("thames21GreenWallModule"),
  title: "Green Wall Module",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("thames21GreenWallModule"),
      title: "Green Wall Module",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("thames21GreenWallModule"),
        attribution: [],
      }),
      defaultAttribute: "status",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "General",
          attributes: [
            enumAttr({
              attributeId: "status",
              label: "Status",
              enum: SurveyStatusEnum,
            }),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/time",
              buckets: "auto",
            }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("thames21GreenWallModule"),
            }),
            surveyAttr("surveyId", "Survey"),
          ],
        }),
      ],
    }),
  ],
});
