// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopBufferInvasive = "Nettles" | "Balsam" | "Neither";

const Nettles: UclPitStopBufferInvasive = "Nettles";
const Balsam: UclPitStopBufferInvasive = "Balsam";
const Neither: UclPitStopBufferInvasive = "Neither";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopBufferInvasiveEnum = {
  ...createEnum("UclPitStopBufferInvasive", [
    [Nettles, "Nettles"],
    [Balsam, "Himalayan Balsam"],
    [Neither, "Neither seen"],
  ]),
  Nettles,
  Balsam,
  Neither,
};

export default UclPitStopBufferInvasiveEnum;
