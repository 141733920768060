import { Option } from "@cartographerio/fp";
import { Guard, guardError } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import {
  ApiError,
  Checked,
  checkedFailure,
  checkedSuccess,
  isApiError,
  isNotFoundError,
  isValidationError,
  networkError,
} from "@cartographerio/types";
import { is2xx } from "./status";

export interface SuccessResponseF<A> {
  type: "SuccessResponse";
  status: number;
  headers: Headers;
  redirected: boolean;
  url: string;
  content: A;
}

export interface ApiErrorResponse {
  type: "ApiErrorResponse";
  status: number;
  headers: Headers;
  redirected: boolean;
  url: string;
  error: ApiError;
}

export interface UnknownErrorResponse {
  type: "UnknownErrorResponse";
  status: number;
  headers: Headers;
  redirected: boolean;
  url: string;
  content: unknown;
}

export type ResponseF<A> =
  | SuccessResponseF<A>
  | ApiErrorResponse
  | UnknownErrorResponse;

export function successResponse<A>(
  params: Omit<SuccessResponseF<A>, "type">
): SuccessResponseF<A> {
  const { status, headers, redirected, url, content } = params;
  return { type: "SuccessResponse", status, headers, redirected, url, content };
}

export function apiErrorResponse(
  params: Omit<ApiErrorResponse, "type">
): ApiErrorResponse {
  const { status, headers, redirected, url, error } = params;
  return {
    type: "ApiErrorResponse",
    status,
    headers,
    redirected,
    url,
    error,
  };
}

export function unknownErrorResponse(
  params: Omit<UnknownErrorResponse, "type">
): UnknownErrorResponse {
  const { status, headers, redirected, url, content } = params;
  return {
    type: "UnknownErrorResponse",
    status,
    headers,
    redirected,
    url,
    content,
  };
}

export function debugResponse(message: string) {
  return function <A>(response: ResponseF<A>): ResponseF<A> {
    console.warn(message, response);
    return response;
  };
}

export function createResponse(
  response: Response,
  content: unknown
): ResponseF<unknown> {
  const { status, headers, redirected, url } = response;
  if (is2xx(status)) {
    return successResponse({
      status,
      headers,
      redirected,
      url,
      content,
    });
  } else if (isApiError(content)) {
    return apiErrorResponse({
      status,
      headers,
      redirected,
      url,
      error: content,
    });
  } else {
    return unknownErrorResponse({
      status,
      headers,
      redirected,
      url: url,
      content,
    });
  }
}

interface FoldResponseCallbacks<A, R> {
  success: (response: SuccessResponseF<A>) => R;
  failure: (response: ApiErrorResponse) => R;
  unknown: (response: UnknownErrorResponse) => R;
}

export function foldResponse<A, R>(
  response: ResponseF<A>,
  callbacks: FoldResponseCallbacks<A, R>
): R {
  switch (response.type) {
    case "SuccessResponse":
      return callbacks.success(response);
    case "ApiErrorResponse":
      return callbacks.failure(response);
    case "UnknownErrorResponse":
      return callbacks.unknown(response);
  }
}

export function mapResponse<A, B>(
  response: ResponseF<A>,
  func: (a: A) => B
): ResponseF<B> {
  return foldResponse(response, {
    success: r => successResponse({ ...r, content: func(r.content) }),
    failure: r => r as ResponseF<B>,
    unknown: r => r as ResponseF<B>,
  });
}

export function parseAs<A>(hint: string, guard: Guard<A>) {
  return (response: ResponseF<unknown>): IO<ResponseF<A>> => {
    return foldResponse(response, {
      success: response =>
        guard(response.content)
          ? IO.pure({ ...response, content: response.content })
          : IO.fail(guardError(hint, guard, response.content)),
      failure: response => IO.fail(response.error),
      unknown: response => IO.fail(badGatewayToNetworkError(response)),
    });
  };
}

export function validationErrorsToChecked<A>(
  response: ResponseF<A>
): ResponseF<Checked<A>> {
  return foldResponse(response, {
    success: response => mapResponse(response, checkedSuccess),
    failure: response =>
      isValidationError(response.error)
        ? successResponse({
            ...response,
            content: checkedFailure<A>(response.error.errors),
          })
        : response,
    unknown: response => response,
  });
}

export function notFoundToOption<A>(
  response: ResponseF<A>
): ResponseF<Option<A>> {
  return foldResponse(response, {
    success: response => ({
      ...response,
      content: Option.some(response.content),
    }),
    failure: response =>
      isNotFoundError(response.error)
        ? successResponse({ ...response, content: Option.none<A>() })
        : response,
    unknown: response => response as ResponseF<Option<A>>,
  });
}

interface RecoverUnknownErrorsHandlers<R> {
  [status: number]: (content: unknown) => content is R;
}

// Convert unhandled 4xx and 5xx responses to successes
// (provided their content matches the supplied format).
export function recoverUnknownErrors<R>(
  handlers: RecoverUnknownErrorsHandlers<R>
) {
  return <R2>(response: ResponseF<R2>): ResponseF<R | R2> =>
    foldResponse<R2, ResponseF<R | R2>>(response, {
      success: response => response,
      failure: response => response,
      unknown: ({ status, content }) =>
        handlers[status]?.(content)
          ? successResponse({ ...response, content })
          : response,
    });
}

export function contentAs<A>(hint: string, guard: Guard<A>) {
  return (response: ResponseF<unknown>): IO<A> =>
    foldResponse(response, {
      success: response =>
        guard(response.content)
          ? IO.pure(response.content)
          : IO.fail(guardError(hint, guard, response.content)),
      failure: response => IO.fail<A>(response.error),
      unknown: response => IO.fail<A>(badGatewayToNetworkError(response)),
    });
}

export function optionalContentAs<A>(hint: string, guard: Guard<A>) {
  return (response: ResponseF<unknown>): IO<Option<A>> =>
    foldResponse(response, {
      success: response => contentAs(hint, guard)(response).map(Option.some),
      failure: response =>
        isNotFoundError(response.error)
          ? IO.pure(Option.none<A>())
          : IO.fail(response.error),
      unknown: response => IO.fail(badGatewayToNetworkError(response)),
    });
}

export function badGatewayToNetworkError(response: UnknownErrorResponse) {
  return response.status === 502
    ? networkError("502 Bad Gateway", response)
    : response.content;
}
