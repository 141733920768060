// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsSpeciesFrequency = "N" | "I" | "C" | "F" | "E";

const None: UrsSpeciesFrequency = "N";
const Individuals: UrsSpeciesFrequency = "I";
const IsolatedClumps: UrsSpeciesFrequency = "C";
const Frequent: UrsSpeciesFrequency = "F";
const Extensive: UrsSpeciesFrequency = "E";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsSpeciesFrequencyEnum = {
  ...createEnum("UrsSpeciesFrequency", [
    [None, "None"],
    [Individuals, "Individual plant(s)"],
    [IsolatedClumps, "Isolated clumps (<5% cover)"],
    [Frequent, "Frequent (5-33% cover)"],
    [Extensive, "Extensive (>33% cover)"],
  ]),
  None,
  Individuals,
  IsolatedClumps,
  Frequent,
  Extensive,
};

export default UrsSpeciesFrequencyEnum;
