import { checks } from "@cartographerio/permission";
import { Workspace } from "@cartographerio/types";
import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";

import queries from "../../queries";
import RequirePermissionLink from "../components/RequirePermissionLink";
import { useApiParams } from "../contexts/auth";
import { routes } from "../routes";
import { useSuspenseQueryData } from "./useSuspenseQueryData";

export default function useUserLimitReason(
  workspace: Workspace
): ReactElement | null {
  const apiParams = useApiParams();

  const { hasRoom: isUnderLimit } = useSuspenseQueryData(
    queries.workspace.limits.v1.checkUsers(apiParams, workspace.id)
  );

  return isUnderLimit ? null : (
    <Box>
      Your workspace has reached the member limit set on{" "}
      <RequirePermissionLink.Internal
        check={checks.billing.workspace(workspace.id)}
        to={routes.workspace.billing.settings.url([workspace.alias])}
        display="inline"
      >
        your billing settings page
      </RequirePermissionLink.Internal>
      . Increase the limit to invite more members.
    </Box>
  );
}
