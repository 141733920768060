// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterFlow = "None" | "Small" | "Medium" | "Large";

const None: MrsMudSpotterFlow = "None";
const Small: MrsMudSpotterFlow = "Small";
const Medium: MrsMudSpotterFlow = "Medium";
const Large: MrsMudSpotterFlow = "Large";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterFlowEnum = {
  ...createEnum("MrsMudSpotterFlow", [
    [None, "None"],
    [Small, "Small"],
    [Medium, "Medium"],
    [Large, "Large"],
  ]),
  None,
  Small,
  Medium,
  Large,
};

export default MrsMudSpotterFlowEnum;
