// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsBridgeType = "footOrCycle" | "road" | "rail";

const FootOrCycle: UrsBridgeType = "footOrCycle";
const Road: UrsBridgeType = "road";
const Rail: UrsBridgeType = "rail";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsBridgeTypeEnum = {
  ...createEnum("UrsBridgeType", [
    [FootOrCycle, "Foot/cycle"],
    [Road, "Road"],
    [Rail, "Rail"],
  ]),
  FootOrCycle,
  Road,
  Rail,
};

export default UrsBridgeTypeEnum;
