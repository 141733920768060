import {
  Attribute,
  BucketsProp,
  attributeGroup,
  booleanAttr,
  enumAttr,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  surveyAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { FhtPriorityPondsAnnex1TypeEnum } from "@cartographerio/inventory-enums";
import Color from "color";
import { range } from "lodash";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const customEmptyMarker = marker.fromColorFunc(isFill =>
  isFill ? Color("transparent") : Color("rgba(128, 128, 128, .4)")
);

export const FhtPriorityPonds = mapSchemaWithDefaults({
  mapId: unsafeMapId("FhtPriorityPonds"),
  title: "Priority Ponds",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("FhtPriorityPonds"),
      title: "Priority Ponds",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("FhtPriorityPonds"),
        attribution: [],
      }),
      defaultAttribute: "displayCriterion",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey Details",
          attributes: [
            timestampAttr({
              label: "Date/Time",
              attributeId: "timestamp",
              buckets: "auto",
            }),
            // teamAttr("teamId", "Team"),
            surveyAttr("surveyId", "Survey"),
            numberAttr({
              attributeId: "displayCriterion",
              label: "Criterion",
              buckets: [
                ...range(0, 7).map(n =>
                  lookupBucket(
                    n + 1,
                    marker.hue((380 * n) / 7),
                    `Criterion ${n + 1}`
                  )
                ),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
          ],
        }),
        attributeGroup({
          label: "1. Annex 1 Habitats",
          attributes: [
            criterionAppliesAttr(1),
            enumAttr({
              label: "Annex 1 Habitat Directive Type",
              attributeId: "criterion1_annex1Type",
              enum: FhtPriorityPondsAnnex1TypeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "2. Important Species",
          attributes: [
            criterionAppliesAttr(2),
            booleanAttr({
              label: "Great Crested Newt",
              attributeId: "criterion2_greatCrestedNewt",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "Common Toad",
              attributeId: "criterion2_commonToad",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "Other Species",
              attributeId: "criterion2_other",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "eDNA Record",
              attributeId: "criterion2_verifiedByEDna",
              buckets: booleanBuckets("lightgreen"),
            }),
          ],
        }),
        attributeGroup({
          label: "3. Rich Species Assemblages",
          attributes: [
            criterionAppliesAttr(3),
            booleanAttr({
              label: "Amphibian Assemblage",
              attributeId: "criterion3_amphibianAssemblage",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "Dragonfly Assemblage",
              attributeId: "criterion3_dragonflyAssemblage",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "Extremely Rich Site For Plants",
              attributeId: "criterion3_richSiteForPlants",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "Extremely Rich Site For Aquatic Invertebrates",
              attributeId: "criterion3_richSiteForAquaticInvertebrates",
              buckets: booleanBuckets("lightgreen"),
            }),
          ],
        }),
        attributeGroup({
          label: "4. High Quality (PSYM)",
          attributes: [
            criterionAppliesAttr(4),
            booleanAttr({
              label: "PSYM Classification 'Good'",
              attributeId: "criterion4_goodPsymScore",
              buckets: booleanBuckets("lightgreen"),
            }),
          ],
        }),
        attributeGroup({
          label: "5. Important Pond Types",
          attributes: [
            criterionAppliesAttr(5),
            booleanAttr({
              label: "Ice Age Pond",
              attributeId: "criterion5_iceAgePond",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "Dune Slack Pond",
              attributeId: "criterion5_duneSlackPond",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "Floating Vegetation Mat",
              attributeId: "criterion5_floatingVegetationMat",
              buckets: booleanBuckets("lightgreen"),
            }),
            booleanAttr({
              label: "Other Important Pond Type",
              attributeId: "criterion5_other",
              buckets: booleanBuckets("lightgreen"),
            }),
          ],
        }),
        attributeGroup({
          label: "6. Priority Pond Assessment (PASS)",
          attributes: [
            criterionAppliesAttr(6),
            booleanAttr({
              label: "Priority Pond Predicted (PASS)",
              attributeId: "criterion6_priority",
              buckets: booleanBuckets("lightgreen"),
            }),
          ],
        }),
        attributeGroup({
          label: "7. Clean Water",
          attributes: [
            criterionAppliesAttr(7),
            booleanAttr({
              label: "Low Nitrate and Phosphate",
              attributeId: "criterion7_lowNitrateAndPhosphate",
              buckets: booleanBuckets("lightgreen"),
            }),
          ],
        }),
      ],
    }),
  ],
});

function criterionAppliesAttr(num: 1 | 2 | 3 | 4 | 5 | 6 | 7): Attribute {
  return booleanAttr({
    label: "Criterion Applies",
    attributeId: `criterion${num}_applies`,
    buckets: booleanBuckets("yellow"),
  });
}

function booleanBuckets(yesColor: string): BucketsProp<boolean | null> {
  return [
    lookupBucket(true, marker.fromColor(yesColor)),
    lookupBucket(false, customEmptyMarker),
  ];
}
