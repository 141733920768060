import { formatLatLng, Point } from "@cartographerio/geometry";
import {
  PlainDate,
  ProjectRef,
  SurveyId,
  TeamRef,
  WorkspaceRef,
} from "@cartographerio/types";
import { UrlQuery } from "../../../url";
import { PartialParams, SortOrder } from "../../params";
import { NonEmptyArray } from "@cartographerio/util";

export type FeatureFormat = "kml" | "geojson" | "csv";
export type FeatureSortKey = string;

export interface FeatureSearchParamsV2 {
  project: NonEmptyArray<ProjectRef>;
  workspace: WorkspaceRef | null;
  team: TeamRef | null;
  sw: Point | null;
  ne: Point | null;
  from: PlainDate | null;
  to: PlainDate | null;
  survey: SurveyId | null;
  format: FeatureFormat;
  simplify: boolean;
  promoteId: string | null;
  order: SortOrder<FeatureSortKey>;
  skip: number;
  limit: number;
}

export function formatFeatureSearchParamsV2(
  params: PartialParams<FeatureSearchParamsV2>
): UrlQuery {
  const {
    project,
    workspace,
    team,
    sw,
    ne,
    from,
    to,
    survey,
    format = "geojson",
    simplify,
    promoteId,
    order,
    skip,
    limit,
  } = params;

  return {
    project,
    workspace,
    team,
    sw: sw == null ? undefined : formatLatLng(sw),
    ne: ne == null ? undefined : formatLatLng(ne),
    from: from == null ? undefined : from,
    to: to == null ? undefined : to,
    survey,
    format,
    simplify,
    promoteId,
    order,
    skip,
    limit,
  };
}
