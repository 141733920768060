// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopAccessedBy =
  | "PrivateLand"
  | "PublicLand"
  | "PublicFootpath"
  | "RoadOrTrack";

const PrivateLand: UclPitStopAccessedBy = "PrivateLand";
const PublicLand: UclPitStopAccessedBy = "PublicLand";
const PublicFootpath: UclPitStopAccessedBy = "PublicFootpath";
const RoadOrTrack: UclPitStopAccessedBy = "RoadOrTrack";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopAccessedByEnum = {
  ...createEnum("UclPitStopAccessedBy", [
    [PrivateLand, "On private land with landowners permission"],
    [PublicLand, "In public land (park/nature reserve etc)"],
    [PublicFootpath, "From public footpath"],
    [RoadOrTrack, "From road / track"],
  ]),
  PrivateLand,
  PublicLand,
  PublicFootpath,
  RoadOrTrack,
};

export default UclPitStopAccessedByEnum;
