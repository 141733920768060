// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusLitter = "largeLitter" | "smallLitter" | "dogWaste";

const LargeLitter: EcostatusLitter = "largeLitter";
const SmallLitter: EcostatusLitter = "smallLitter";
const DogWaste: EcostatusLitter = "dogWaste";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusLitterEnum = {
  ...createEnum("EcostatusLitter", [
    [LargeLitter, "Large litter (e.g. shopping trolleys)"],
    [SmallLitter, "Small litter (e.g. cans, bottles, paper)"],
    [DogWaste, "Dog waste"],
  ]),
  LargeLitter,
  SmallLitter,
  DogWaste,
};

export default EcostatusLitterEnum;
