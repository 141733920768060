export type GlobalIOErrorReporter = (error: unknown) => void;

let _globalIOErrorReporter: GlobalIOErrorReporter = _ => undefined;

/** Returns the global IO error reporter used by `unsafeRunAsPromise` and `unsafeRunAsSaga`.
 * The default implementation ignores the error (it's a no-op).
 * See `setGlobalIOErrorReporter` for examples.
 */
export function globalIOErrorReporter(): GlobalIOErrorReporter {
  return _globalIOErrorReporter;
}

/** Sets the global IO error reporter used by `unsafeRunAsPromise` and `unsafeRunAsSaga`.
 * The default implementation ignores the error (it's a no-op).
 *
 * Examples:
 *
 * ```
 * // Do nothing when an error happens:
 * setGlobalIOErrorReporter(_error => undefined);
 *
 * // Log the error:
 * setGlobalIOErrorReporter(error => console.error(error));
 * ```
 */
export function setGlobalIOErrorReporter(handler: GlobalIOErrorReporter): void {
  _globalIOErrorReporter = handler;
}
