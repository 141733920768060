/* eslint-disable @typescript-eslint/no-use-before-define */

import { checkExhausted, prettyPrintJson } from "@cartographerio/util";
import {
  CallbackIO,
  CleanupIO,
  DelayIO,
  FailIO,
  FlatMapIO,
  IO,
  IOPromiseLike,
  LimitIO,
  LimitOrIO,
  PureIO,
  PutIO,
  RecoverIO,
  SelectIO,
  SequenceIO,
  SleepIO,
} from "./IO";
import { IOEnvironment, defaultEnvironment } from "./env";
import { globalIOErrorReporter } from "./error";

async function runIOLike<A, State, Action>(
  arg: IOPromiseLike<A>,
  env: IOEnvironment<State, Action>
): Promise<A> {
  if (arg instanceof IO) {
    return runIO(arg, env);
  } else if (arg instanceof Promise) {
    return arg;
  } else {
    return Promise.resolve(arg);
  }
}

async function runIO<A, State, Action>(
  io: IO<A>,
  env: IOEnvironment<State, Action>
): Promise<A> {
  const { dispatch, getState } = env;

  if (io instanceof PureIO) {
    return io._value;
  } else if (io instanceof FailIO) {
    throw io._error;
  } else if (io instanceof DelayIO) {
    const ioLike = io._func(...io._args);
    const result = await runIOLike(ioLike, env);
    return runIOLike(result, env);
  } else if (io instanceof CallbackIO) {
    return new Promise(io._func);
  } else if (io instanceof FlatMapIO) {
    const first = await runIO(io._first, env);
    const second = await runIO(io._next(first), env);
    return second;
  } else if (io instanceof RecoverIO) {
    try {
      const first = await runIO(io._body, env);
      return first;
    } catch (error) {
      const second = await runIO(io._handler(error), env);
      return second;
    }
  } else if (io instanceof CleanupIO) {
    try {
      return await runIO(io._body, env);
    } finally {
      await runIO(io._cleanup, env);
    }
  } else if (io instanceof SelectIO) {
    return Promise.resolve(io._selector(getState()));
  } else if (io instanceof PutIO) {
    return new Promise((resolve, reject) => {
      try {
        dispatch(io._action);
        resolve(undefined as unknown as A);
      } catch (error) {
        reject(error);
      }
    });
  } else if (io instanceof SleepIO) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => resolve(undefined as unknown as A), io._millis);
    });
  } else if (io instanceof LimitIO) {
    return io._mutex.semaphore
      .acquire()
      .then(release => runIO(io._body, env).finally(release));
  } else if (io instanceof LimitOrIO) {
    try {
      const release = io._mutex.semaphore.tryAcquire();
      return runIO(io._body, env).finally(release);
    } catch (error) {
      return runIO(io._fallback, env);
    }
  } else if (io instanceof SequenceIO) {
    let values: unknown[];

    switch (io._parallelism) {
      case "Seq": {
        values = [];

        for (const step of io._params) {
          values.push(await runIO(step, env));
        }

        break;
      }

      case "Par": {
        values = await Promise.all(
          io._params.map((io: IO<unknown>) => runIO(io, env))
        );
        break;
      }

      default:
        checkExhausted(io._parallelism);
    }

    return io._func(values) as unknown as A;
  } else if (io instanceof IO) {
    throw new Error("Unknown IO subtype: " + prettyPrintJson(io));
  } else {
    throw new Error("Not an IO subtype: " + prettyPrintJson(io));
  }
}

/** Run an `IO` by converting it to a `Promise`.
 * The `Promise` will resolve with the result of the `IO` or reject with an error.
 *
 * For example:
 *
 * ```
 * const io: IO<string> = ...;
 *
 * const promise: Promise<string> = unsafeRunAsPromise(io);
 *
 * promise.then(
 *   value => console.log(`Resolved with ${value}`),
 *   error => console.log(`Rejected with ${error}`)
 * );
 */
export async function unsafeRunAsPromise<R, State, Action>(
  io: IO<R>,
  env: IOEnvironment<State, Action> = defaultEnvironment()
): Promise<R> {
  return await runIO(io.tapError(globalIOErrorReporter()), env);
}

/* eslint-enable @typescript-eslint/no-use-before-define */
