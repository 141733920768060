// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiLandUse =
  | "woodland"
  | "moorlandOrHeath"
  | "urbanResidential"
  | "industrialOrCommercial"
  | "parklandOrGardens"
  | "grasslandOrPasture"
  | "agriculture"
  | "tilledLand"
  | "other";

const Woodland: WrtWestcountryCsiLandUse = "woodland";
const MoorlandOrHeath: WrtWestcountryCsiLandUse = "moorlandOrHeath";
const UrbanResidential: WrtWestcountryCsiLandUse = "urbanResidential";
const IndustrialOrCommercial: WrtWestcountryCsiLandUse =
  "industrialOrCommercial";
const ParklandOrGardens: WrtWestcountryCsiLandUse = "parklandOrGardens";
const GrasslandOrPasture: WrtWestcountryCsiLandUse = "grasslandOrPasture";
const Agriculture: WrtWestcountryCsiLandUse = "agriculture";
const TilledLand: WrtWestcountryCsiLandUse = "tilledLand";
const Other: WrtWestcountryCsiLandUse = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiLandUseEnum = {
  ...createEnum("WrtWestcountryCsiLandUse", [
    [Woodland, "Woodland"],
    [MoorlandOrHeath, "Moorland/heath"],
    [UrbanResidential, "Urban residential"],
    [IndustrialOrCommercial, "Industrial/commercial"],
    [ParklandOrGardens, "Parkland/gardens"],
    [GrasslandOrPasture, "Grassland/pasture"],
    [Agriculture, "Agriculture"],
    [TilledLand, "Tilled land"],
    [Other, "Other (please specify)"],
  ]),
  Woodland,
  MoorlandOrHeath,
  UrbanResidential,
  IndustrialOrCommercial,
  ParklandOrGardens,
  GrasslandOrPasture,
  Agriculture,
  TilledLand,
  Other,
};

export default WrtWestcountryCsiLandUseEnum;
