/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  Geometry,
  isGeometry,
  GeometryType,
  isGeometryType,
} from "@cartographerio/geometry";
import {
  MapId,
  isMapId,
  MapLayerId,
  isMapLayerId,
  Timestamp,
  isTimestamp,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";
import { SurveyStatus, isSurveyStatus } from "../survey/types.generated";
import { Tagged, bridgesIsTaggedHack as isTagged } from "@cartographerio/util";

export type FeatureDepsId = Tagged<"FeatureDepsId">;

export interface FeatureDepsRef {
  layerId: MapLayerId;
  featureId: FeatureDepsId;
}

export type MapBase = "terrain" | "satellite";

export type MapAttribution = string;

export interface BooleanAttributeSchema {
  type: "Boolean";
}

export interface IntegerAttributeSchema {
  type: "Integer";
}

export interface NumberAttributeSchema {
  type: "Number";
}

export interface StringAttributeSchema {
  type: "String";
}

export interface TimestampAttributeSchema {
  type: "Timestamp";
}

export interface JsonAttributeSchema {
  type: "Json";
}

export interface NullableAttributeSchema {
  type: "Nullable";
  param: AttributeSchema;
}

export type AttributeSchema =
  | BooleanAttributeSchema
  | IntegerAttributeSchema
  | NumberAttributeSchema
  | StringAttributeSchema
  | TimestampAttributeSchema
  | JsonAttributeSchema
  | NullableAttributeSchema;

export interface MapLayerSummary {
  layerId: MapLayerId;
  title: string;
  geometryType?: GeometryType | null;
  attribution: MapAttribution[];
}

export interface MapSchemaSummary {
  mapId: MapId;
  title: string;
  layers: MapLayerSummary[];
}

export interface FeatureDepsF<A> {
  geometry: Geometry;
  timestamp: Timestamp;
  data: A;
  status: SurveyStatus;
  workspaceIds: WorkspaceId[];
  id: FeatureDepsId;
}

export const isFeatureDepsId = (v: any): v is FeatureDepsId => {
  return isTagged((a0: any): a0 is "FeatureDepsId" => a0 === "FeatureDepsId")(
    v
  );
};

export const isFeatureDepsRef = (v: any): v is FeatureDepsRef => {
  return (
    typeof v === "object" &&
    v != null &&
    "layerId" in v &&
    isMapLayerId(v.layerId) &&
    "featureId" in v &&
    isFeatureDepsId(v.featureId)
  );
};

export const isMapBase = (v: any): v is MapBase => {
  return v === "terrain" || v === "satellite";
};

export const isMapAttribution = (v: any): v is MapAttribution => {
  return typeof v === "string";
};

export const isBooleanAttributeSchema = (
  v: any
): v is BooleanAttributeSchema => {
  return (
    typeof v === "object" && v != null && "type" in v && v.type === "Boolean"
  );
};

export const isIntegerAttributeSchema = (
  v: any
): v is IntegerAttributeSchema => {
  return (
    typeof v === "object" && v != null && "type" in v && v.type === "Integer"
  );
};

export const isNumberAttributeSchema = (v: any): v is NumberAttributeSchema => {
  return (
    typeof v === "object" && v != null && "type" in v && v.type === "Number"
  );
};

export const isStringAttributeSchema = (v: any): v is StringAttributeSchema => {
  return (
    typeof v === "object" && v != null && "type" in v && v.type === "String"
  );
};

export const isTimestampAttributeSchema = (
  v: any
): v is TimestampAttributeSchema => {
  return (
    typeof v === "object" && v != null && "type" in v && v.type === "Timestamp"
  );
};

export const isJsonAttributeSchema = (v: any): v is JsonAttributeSchema => {
  return typeof v === "object" && v != null && "type" in v && v.type === "Json";
};

export const isNullableAttributeSchema = (
  v: any
): v is NullableAttributeSchema => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "Nullable" &&
    "param" in v &&
    isAttributeSchema(v.param)
  );
};

export const isAttributeSchema = (v: any): v is AttributeSchema => {
  return (
    isBooleanAttributeSchema(v) ||
    isIntegerAttributeSchema(v) ||
    isNumberAttributeSchema(v) ||
    isStringAttributeSchema(v) ||
    isTimestampAttributeSchema(v) ||
    isJsonAttributeSchema(v) ||
    isNullableAttributeSchema(v)
  );
};

export const isMapLayerSummary = (v: any): v is MapLayerSummary => {
  return (
    typeof v === "object" &&
    v != null &&
    "layerId" in v &&
    isMapLayerId(v.layerId) &&
    "title" in v &&
    typeof v.title === "string" &&
    (!("geometryType" in v) ||
      isGeometryType(v.geometryType) ||
      v.geometryType === null) &&
    "attribution" in v &&
    Array.isArray(v.attribution) &&
    v.attribution.every((i: any) => isMapAttribution(i))
  );
};

export const isMapSchemaSummary = (v: any): v is MapSchemaSummary => {
  return (
    typeof v === "object" &&
    v != null &&
    "mapId" in v &&
    isMapId(v.mapId) &&
    "title" in v &&
    typeof v.title === "string" &&
    "layers" in v &&
    Array.isArray(v.layers) &&
    v.layers.every((i: any) => isMapLayerSummary(i))
  );
};

export const isFeatureDepsF =
  <A>(isA: (a: any) => a is A) =>
  (v: any): v is FeatureDepsF<A> => {
    return (
      typeof v === "object" &&
      v != null &&
      "geometry" in v &&
      isGeometry(v.geometry) &&
      "timestamp" in v &&
      isTimestamp(v.timestamp) &&
      "data" in v &&
      isA(v.data) &&
      "status" in v &&
      isSurveyStatus(v.status) &&
      "workspaceIds" in v &&
      Array.isArray(v.workspaceIds) &&
      v.workspaceIds.every((i: any) => isWorkspaceId(i)) &&
      "id" in v &&
      isFeatureDepsId(v.id)
    );
  };

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
