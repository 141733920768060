// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityWildlife =
  | "Otter"
  | "Mink"
  | "WaterVole"
  | "Kingfisher"
  | "Cormorant"
  | "Heron"
  | "Fish"
  | "GrassSnake"
  | "DragonfliesOrDamselflies"
  | "Other";

const Otter: EsrtWaterQualityWildlife = "Otter";
const Mink: EsrtWaterQualityWildlife = "Mink";
const WaterVole: EsrtWaterQualityWildlife = "WaterVole";
const Kingfisher: EsrtWaterQualityWildlife = "Kingfisher";
const Cormorant: EsrtWaterQualityWildlife = "Cormorant";
const Heron: EsrtWaterQualityWildlife = "Heron";
const Fish: EsrtWaterQualityWildlife = "Fish";
const GrassSnake: EsrtWaterQualityWildlife = "GrassSnake";
const DragonfliesOrDamselflies: EsrtWaterQualityWildlife =
  "DragonfliesOrDamselflies";
const Other: EsrtWaterQualityWildlife = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityWildlifeEnum = {
  ...createEnum("EsrtWaterQualityWildlife", [
    [Otter, "Otter"],
    [Mink, "Mink"],
    [WaterVole, "Water vole"],
    [Kingfisher, "Kingfisher"],
    [Cormorant, "Cormorant"],
    [Heron, "Heron"],
    [Fish, "Fish"],
    [GrassSnake, "Grass Snake"],
    [DragonfliesOrDamselflies, "Dragonflies/Damselflies"],
    [Other, "Other"],
  ]),
  Otter,
  Mink,
  WaterVole,
  Kingfisher,
  Cormorant,
  Heron,
  Fish,
  GrassSnake,
  DragonfliesOrDamselflies,
  Other,
};

export default EsrtWaterQualityWildlifeEnum;
