import { Path } from "@cartographerio/topo-core";
import {
  topoExpr,
  attachmentField,
  bounds,
  checkbox,
  checkboxGroup,
  columns2,
  columns3,
  enumSelectOptions,
  Form,
  form,
  integerField,
  minValue,
  numberField,
  Page,
  page,
  percentageDistributionGroup,
  pointField,
  positiveCountsGroup,
  required,
  requiredIf,
  requiredIff,
  rule,
  section,
  select,
  selectGroup,
  textArea,
  textField,
  timestampField,
  userRefField,
} from "@cartographerio/topo-form";
import {
  isNullable,
  isNumber,
  isRecordOf,
  isString,
} from "@cartographerio/guard";
import {
  UrsApe,
  UrsApeEnum,
  UrsArtificialBankProfileEnum,
  UrsArtificialBankReinforcementEnum,
  UrsArtificialFeatureEnum,
  UrsBankMaterialEnum,
  UrsBankProtectionEnum,
  UrsBankStructureEnum,
  UrsBridgeTypeEnum,
  UrsChannelDynamicsExtentEnum,
  UrsChannelFeatureEnum,
  UrsChannelSubstrateEnum,
  UrsCountedHabitatFeatureEnum,
  UrsCrossProfileEnum,
  UrsEcologicalCharacteristicEnum,
  UrsFlowTypeEnum,
  UrsLandUseEnum,
  UrsManagementFeatureEnum,
  UrsMarginalFeatureEnum,
  UrsNaturalBankProfileEnum,
  UrsNuisanceSpeciesEnum,
  UrsPercentageHabitatFeatureEnum,
  UrsPlanformEnum,
  UrsPollutionIndicatorEnum,
  UrsProtectedSpeciesEnum,
  UrsReinforcementLevelEnum,
  UrsSpecialFeatureEnum,
  UrsSpeciesFrequency,
  UrsSpeciesFrequencyEnum,
  UrsSurveyBankEnum,
  UrsSurveyStartEnum,
  UrsSurveyTypeEnum,
  UrsTreeDistributionEnum,
  UrsTreeFeatureEnum,
  UrsVegetationEnum,
  UrsWaterClarityEnum,
  YesNoUnknownEnum,
} from "@cartographerio/inventory-enums";
import outdent from "outdent";
import {
  interleaveFields,
  selectOptionImages,
  selectOptionLabels,
} from "./helpers";

const spotCheckPage = (path: Path, title: string): Page =>
  page({
    title,
    path,
    help: outdent`
    The spot check measurements used in the URS are closely based
    on those from the Environment Agency's River Habitat Survey.

    10 spot-check measurements are taken within
    a 1m transect at equally spaced distances along the stretch
    (e.g. every 50m for a 500m stretch).

    Spot check measurements are usually taken from the bank.
    Where possible, the location of each spot check
    should be recorded using a GPS device.

    The spot check dimensions illustrated below provide
    an indication of the survey transect areas for each type of record:

    ![Spot check dimensions](https://media.cartographer.io/static/images/urs/spot-check-dimensions.png)
    `,
    blocks: [
      section({
        title: "Location",
        path: [],
        blocks: [
          pointField({
            label: null,
            path: ["location"],
            fullWidth: true,
            help: "Spot check location.",
          }),
        ],
      }),
      section({
        title: "Bank Materials",
        path: [],
        help: outdent`
        The main type of any exposed natural bank materials
        appearing within a *1m transect across the river channel*.

        Note: Bank material should only be recorded as Artificial (AR)
        if the natural bank materials are *completely concealed*,
        since bank protection is recorded separately.
        `,
        blocks: columns2(
          select({
            label: "Bank Material",
            secondaryLabel: "Left Bank",
            path: ["leftBankMaterial"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsBankMaterialEnum, true),
              pattern: "urs/bank-material/*.jpg",
            }),
            required: required("info"),
          }),
          select({
            label: "Bank Material",
            secondaryLabel: "Right Bank",
            path: ["rightBankMaterial"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsBankMaterialEnum, true),
              pattern: "urs/bank-material/*.jpg",
            }),
            required: required("info"),
          })
        ),
      }),
      section({
        title: "Bank Protection",
        path: [],
        help: outdent`
        The main type of any bank protection
        appearing within a *1m transect across the river channel*.
        `,
        blocks: columns2(
          select({
            label: "Bank Protection",
            secondaryLabel: "Left Bank",
            path: ["leftBankProtection"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsBankProtectionEnum, true),
              pattern: "urs/bank-protection/*.jpg",
            }),
            required: required("info"),
          }),
          select({
            label: "Bank Protection",
            secondaryLabel: "Right Bank",
            path: ["rightBankProtection"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsBankProtectionEnum, true),
              pattern: "urs/bank-protection/*.jpg",
            }),
            required: required("info"),
          })
        ),
      }),
      section({
        title: "Bank and Marginal Features",
        path: [],
        help: outdent`
        The presence or absence of any bank or marginal features
        appearing within a *1m transect across the river channel*.

        Note: Here the URS differs from the RHS
        by recording features that may have been created
        in response to river channel modifications or additions.
        `,
        blocks: columns2(
          select({
            label: "Left Marginal Feature",
            path: ["leftBankMarginalFeature"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsMarginalFeatureEnum, true),
              pattern: "urs/marginal-feature/*.jpg",
            }),
            required: required("info"),
          }),
          select({
            label: "Right Marginal Feature",
            path: ["rightBankMarginalFeature"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsMarginalFeatureEnum, true),
              pattern: "urs/marginal-feature/*.jpg",
            }),
            required: required("info"),
          })
        ),
      }),
      section({
        title: "Channel Physical Attributes",
        path: [],
        blocks: columns2(
          select({
            label: "Channel Substrate",
            path: ["channelSubstrate"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsChannelSubstrateEnum, true),
              pattern: "urs/channel-substrate/*.jpg",
            }),
            required: required("info"),
            help: outdent`
              The dominant substrate
              appearing within a *1m transect across the river channel*.

              It is important that both mobile and immobile substrates
              are recorded when they occur together,
              so record whichever dominates at each spot check
              to gain a representative picture.

              If a substrate type only occurs between spot checks,
              make sure this is recorded in the sweep up section.
              `,
          }),
          select({
            label: "Water Surface Flow Types",
            path: ["flowType"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsFlowTypeEnum, true),
              pattern: "urs/flow-type/*.jpg",
            }),
            required: required("info"),
            help: outdent`
              The dominant flow type appearing within
              a *1m transect across the river channel*.

              Flow types are identified from patterns and flow directions
              on distinct areas of the water surface,
              and sometimes the additional influence of particles on the river bed.
              `,
          }),
          select({
            label: "Channel Feature",
            path: ["channelFeature"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsChannelFeatureEnum, true),
              pattern: "urs/channel-feature/*.jpg",
            }),
            required: required("info"),
            help: outdent`
              Naturally and artificially generated physical features
              appearing within a *1m transect across the river channel*.

              Note: Here the URS differs from the RHS by recording features
              that may have been created in response to
              river channel modifications or additions.
              `,
          })
        ),
      }),
      section({
        title: "Land Use",
        path: [],
        help: outdent`
        Land use for a *10m transect within 5m of the bank top*.

        Land use codes are adapted from those in RHS with additional urban
        land use detail based on the UK National Land Use Database.

        If observations indicate land use outside the listed categories,
        select nearest land use type and note the details in the
        *Other notes* field on the *Artificial Influences* page.
        `,
        blocks: columns2(
          select({
            label: "Land Use",
            secondaryLabel: "Left Bank",
            path: ["leftBankLandUse"],
            options: enumSelectOptions(UrsLandUseEnum, true),
            required: required("info"),
          }),
          select({
            label: "Land Use",
            secondaryLabel: "Right Bank",
            path: ["rightBankLandUse"],
            options: enumSelectOptions(UrsLandUseEnum, true),
            required: required("info"),
          })
        ),
      }),
      section({
        title: "Bank Top Vegetation Structure",
        path: [],
        help: outdent`
        Vegetation structure for a *10m transect within 1m of the bank top*:

        ![Substrate size guide](https://media.cartographer.io/static/images/urs/vegetation-structure.png)

        Image source: River Habitat Survey (2003 edition).
        `,
        blocks: columns2(
          select({
            label: "Top Vegetation Structure",
            secondaryLabel: "Left Bank",
            path: ["leftBankTopStructure"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsBankStructureEnum, true),
              pattern: "urs/bank-structure/*.jpg",
            }),
            required: required("info"),
          }),
          select({
            label: "Top Vegetation Structure",
            secondaryLabel: "Right Bank",
            path: ["rightBankTopStructure"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsBankStructureEnum, true),
              pattern: "urs/bank-structure/*.jpg",
            }),
            required: required("info"),
          })
        ),
      }),
      section({
        title: "Bank Face Vegetation Structure",
        path: [],
        help: outdent`
        Vegetation structure within a 10m transect on the bank face:

        ![Vegetation structure](https://media.cartographer.io/static/images/urs/vegetation-structure.png)
        `,
        blocks: columns2(
          select({
            label: "Bank Face Vegetation Structure",
            secondaryLabel: "Left Bank",
            path: ["leftBankFaceStructure"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsBankStructureEnum, true),
              pattern: "urs/bank-structure/*.jpg",
            }),
            required: required("info"),
          }),
          select({
            label: "Bank Face Vegetation Structure",
            secondaryLabel: "Right Bank",
            path: ["rightBankFaceStructure"],
            options: selectOptionImages({
              options: enumSelectOptions(UrsBankStructureEnum, true),
              pattern: "urs/bank-structure/*.jpg",
            }),
            required: required("info"),
          })
        ),
      }),
      section({
        title: "Vegetation",
        path: [],
        help: outdent`
        In-channel and marginal vegetation types
        observed within a *10m transect across the channel*.

        Vegetation types are the same as those recorded in the RHS.
        Percentage cover is normally recorded to
        the nearest 5% of the transect area to the bank top,
        but smaller percentages may be recorded
        to indicate rarer vegetation types.
        `,
        blocks: columns2(
          ...percentageDistributionGroup({
            path: ["vegetation"],
            fields: selectOptionImages({
              options: enumSelectOptions(UrsVegetationEnum),
              pattern: "urs/vegetation/*.jpg",
            }),
            defaultValue: 0,
          })
        ),
      }),
    ],
  });

export function ursForm(title: string): Form {
  return form({
    title,
    pages: [
      page({
        title: "Survey Details",
        path: [],
        blocks: [
          section({
            title: "Project Details",
            path: [],
            blocks: columns2(
              textField({
                label: "Project Name",
                path: ["data", "surveyDetails", "projectName"],
                help: "Name of the river restoration project being monitored.",
              }),
              textField({
                label: "Project Code",
                path: ["data", "surveyDetails", "projectCode"],
                help: "Code for the restoration project being monitored.",
              }),
              select({
                label: "Survey Type",
                path: ["data", "surveyDetails", "surveyType"],
                options: enumSelectOptions(UrsSurveyTypeEnum),
                help: "Survey type (if applicable).",
              }),
              textField({
                label: "Scenario Name",
                path: ["data", "surveyDetails", "scenarioName"],
                help: outdent`
                  If you selected *scenario* above, enter a scenario name here.
                  The name will be searchable on the survey list.
                  `,
                customRules: requiredIff({
                  level: "info",
                  requiredMessage: "You should name the scenario.",
                  forbiddenMessage:
                    "Only enter a scenario name if you selected 'Scenario' above.",
                  otherPath: ["data", "surveyDetails", "surveyType"],
                  otherTest: value => value === UrsSurveyTypeEnum.Scenario,
                }),
              })
            ),
          }),
          section({
            title: "River and Stretch Details",
            path: [],
            blocks: columns2(
              textField({
                label: "River Name",
                path: ["data", "surveyDetails", "riverName"],
                required: required("info"),
                help: outdent`
                  Name of the river or stream
                  (e.g. as shown on 1:50,000 scale maps).

                  Unnamed tributaries should be named
                  with reference to a main watercourse
                  (e.g. "Tributary of River Brent").
                  `,
              }),
              textField({
                label: "River Stretch Name",
                path: ["data", "surveyDetails", "stretchName"],
                required: required("info"),
                help: outdent`
                  Unique reference name for the stretch.
                  `,
              }),
              textField({
                label: "River Stretch Number",
                path: ["data", "surveyDetails", "stretchCode"],
                required: required("info"),
                help: outdent`
                  Unique stretch identification number.
                  `,
              }),
              textField({
                label: "WFD Water Body ID",
                path: ["data", "surveyDetails", "wfdWaterBodyId"],
                help: outdent`
                  (Optional) ID used to identify the water body
                  for the purposes of Water Framework Directive reporting.
                  `,
              })
            ),
          }),
          section({
            title: "Survey Details",
            path: [],
            blocks: columns2(
              timestampField({
                label: "Date/Time",
                path: ["data", "recorded"],
                help: "Date and time the survey was recorded in the field.",
                defaultValue: "now",
                required: required("info"),
              }),
              userRefField({
                label: "Second Surveyor",
                path: ["data", "secondSurveyor"],
              })
            ),
          }),
        ],
      }),
      page({
        title: "River Stretch Information",
        path: [],
        blocks: [
          section({
            title: "Stretch Length",
            path: [],
            help: outdent`
            URS stretches are lengths of river of a single engineering type,
            identified by their planform, cross-profile, and level of reinforcement.

            Stretches should be 500m long.
            If absolutely necessary, shorter stretches may be surveyed,
            but they should never less than 300m.
            `,
            blocks: columns2(
              integerField({
                label: null,
                path: ["data", "siteInformation", "stretchLength"],
                units: "m",
                required: required("info"),
                bounds: minValue(0),
                help: "Enter the total length of the surveyed stretch, determined to the nearest 50m using 1m paces.",
              })
            ),
          }),
          section({
            title: "Survey Location",
            path: [],
            help: "Locations of the upstream and downstream ends of the stretch.",
            blocks: columns2(
              pointField({
                label: "Upstream",
                path: ["data", "siteInformation", "upstreamLocation"],
                required: required("info"),
              }),
              pointField({
                label: "Downstream",
                path: ["data", "siteInformation", "downstreamLocation"],
                required: required("info"),
              })
            ),
          }),
          section({
            title: "Survey Bank and Start Point",
            path: [],
            blocks: columns2(
              select({
                label: "Survey Bank",
                path: ["data", "siteInformation", "surveyBank"],
                options: enumSelectOptions(UrsSurveyBankEnum),
                required: required("info"),
                help: outdent`
                  From which bank was the survey carried out? Record left, right, or both banks when facing downstream.

                  Where access is limited (e.g. by land use or for wide and deep rivers), surveys may only be possible from one bank.
                  `,
              }),
              select({
                label: "Survey Start",
                path: ["data", "siteInformation", "surveyStart"],
                options: enumSelectOptions(UrsSurveyStartEnum),
                required: required("info"),
                help: "Is spot check 1 at the upstream or downstream end of the stretch?",
              })
            ),
          }),
          section({
            title: "Survey Conditions",
            path: [],
            blocks: columns2(
              checkbox({
                label: "Bed Visible",
                path: ["data", "siteInformation", "bedVisible"],
                checkboxLabel:
                  "Was the channel bed visible at the time of the survey?",
              }),
              checkbox({
                label: "Adverse Conditions",
                path: ["data", "siteInformation", "adverseConditions"],
                checkboxLabel:
                  "Were there adverse conditions at the time of the survey?",
                help: outdent`
                  Adverse conditions include: limited access, high levels of riparian vegetation,
                  and windy or poor light conditions.
                  `,
              }),
              textArea({
                label: "Adverse Conditions Summary",
                path: ["data", "siteInformation", "adverseConditionsSummary"],
                fullWidth: true,
                rows: 5,
                customRules: [
                  requiredIf({
                    level: "info",
                    message: "You should summarize the adverse conditions.",
                    otherPath: ["data", "siteInformation", "adverseConditions"],
                    otherTest: value => value === true,
                  }),
                ],
                help: "Provide details of any adverse conditions occurring at the time of survey.",
              })
            ),
          }),
          section({
            title: "Distance and Slope (Optional)",
            path: [],
            blocks: columns2(
              integerField({
                label: "Distance from Source",
                path: ["data", "siteInformation", "distanceFromSource"],
                units: "m",
                bounds: minValue(0),
                help: outdent`
                  Estimate the distance (m) of the middle of the stretch from the river source
                  using a 1:25,000 scale (or more detailed) OS topographic map.
                  `,
              }),
              numberField({
                label: "Slope",
                path: ["data", "siteInformation", "slope"],
                units: "m/km",
                bounds: minValue(0),
                help: outdent`
                  Estimate the slope of the river (m/km)
                  using a 1:25,000 scale (or more detailed) OS topographic map.
                  `,
              })
            ),
          }),
          section({
            title: "Geology (Optional)",
            path: [],
            blocks: columns2(
              textField({
                label: "Solid Geology Code",
                path: ["data", "siteInformation", "solidGeologyCode"],
                help: "Solid geology code for the area using [British Geological Survey](http://www.bgs.ac.uk/geoindex/) maps.",
              }),
              textField({
                label: "Drift Geology Code",
                path: ["data", "siteInformation", "driftGeologyCode"],
                help: "Drift geology code for the area using [British Geological Survey](http://www.bgs.ac.uk/geoindex/) maps.",
              })
            ),
          }),
          section({
            title: "River Stretch Photographs",
            path: [],
            blocks: columns2(
              attachmentField({
                label: "Photographs",
                path: ["data", "siteInformation", "photographs"],
                fullWidth: true,
                maxFiles: 4,
              }),
              textField({
                label: "Photograph Credit",
                path: ["data", "siteInformation", "photoCredit"],
                help: "The name of the site photographer for use in image credits.",
              }),
              textArea({
                label: "Photograph References and Details",
                path: ["data", "siteInformation", "photoReferences"],
                rows: 5,
                fullWidth: true,
                help: outdent`
                  Write notes on the content and location of your photographs.
                  Also add any other general comments about the river stretch.
                  `,
              })
            ),
          }),
        ],
      }),
      page({
        title: "Stretch Engineering",
        path: [],
        blocks: [
          section({
            title: null,
            path: [],
            blocks: columns2(
              select({
                label: "Planform",
                path: ["data", "stretchEngineering", "planform"],
                options: selectOptionImages({
                  options: enumSelectOptions(UrsPlanformEnum),
                  pattern: "urs/planform/*.jpg",
                }),
                showValues: true,
                required: required("info"),
                help: outdent`
                  The characteristic plan geometry of the stretch, as observed from above.

                  ![River Planform](https://media.cartographer.io/static/images/urs/planform/planform.jpg)
                  `,
              }),
              select({
                label: "Cross Profile",
                path: ["data", "stretchEngineering", "crossProfile"],
                options: selectOptionImages({
                  options: enumSelectOptions(UrsCrossProfileEnum),
                  pattern: "urs/cross-profile/*.jpg",
                }),
                showValues: true,
                required: required("info"),
                help: outdent`
                  The characteristic geometry of a vertical cross-section through the stretch.

                  ![River Cross Profile](https://media.cartographer.io/static/images/urs/cross-profile/cross-profile.jpg)
                  `,
              }),
              select({
                label: "Reinforcement Level",
                path: ["data", "stretchEngineering", "reinforcementLevel"],
                options: selectOptionImages({
                  options: enumSelectOptions(UrsReinforcementLevelEnum),
                  pattern: "urs/reinforcement-level/*.jpg",
                }),
                required: required("info"),
                help: outdent`
                  The characteristic/predominant level of reinforcement along the stretch.

                  Consider a bank or bed as "reinforced" where there is some level of reinforcement along all or most of the survey stretch.
                  `,
              })
            ),
          }),
        ],
      }),
      page({
        title: "Channel Dimensions",
        path: ["data", "channelDimensions"],
        help: outdent`
        Record measurements below as they are indicated in the following figure:

        ![Channel Engineering](https://media.cartographer.io/static/images/urs/stretch-length.png)

        Image source: River Habitat Survey (2003 version).
        `,
        blocks: [
          section({
            title: "Measurement Location",
            path: [],
            blocks: [
              integerField({
                label: "Distance from Upstream",
                path: ["onceOnlyDistanceFromUpstream"],
                units: "m",
                bounds: minValue(0),
                help: outdent`
                (Optional) Approximate distance of the site of the channel dimension measurements
                from the upstream end of the stretch.
                `,
              }),
              pointField({
                label: "Location",
                path: ["onceOnlyLocation"],
                help: outdent`
                (Optional) Location of the site of the channel dimension measurements.
                `,
                fullWidth: true,
              }),
              checkbox({
                label: "Measured at Riffle or Run?",
                path: ["onceOnlyAtRiffleOrRun"],
                checkboxLabel:
                  "Were the channel dimensions measured at a riffle or run?",
              }),
              integerField({
                label: "Measured at spot check?",
                path: ["onceOnlySpotCheck"],
                bounds: bounds(1, 10),
                help: outdent`
                If the channel dimensions were measured at a spot check, state its number (1 to 10).
                `,
              }),
            ],
          }),
          section({
            title: "Channel Dimensions",
            path: [],
            help: outdent`
            Record measurements below as they are indicated in the following figure:

            ![Annotated channel cross-section](https://media.cartographer.io/static/images/urs/stretch-length.png)

            Image source: River Habitat Survey (2003 version).
            `,
            blocks: [
              numberField({
                label: "Water Width",
                path: ["channelWaterWidth"],
                required: required("info"),
                bounds: minValue(0),
                decimalPlaces: 2,
                units: "m",
                help: outdent`
                Width of the wetted part of the channel.
                `,
              }),
              numberField({
                label: "Bankfull Width",
                path: ["channelBankfullWidth"],
                required: required("info"),
                bounds: minValue(0),
                decimalPlaces: 2,
                units: "m",
                customRules: [
                  rule({
                    level: "info",
                    message:
                      "Bankfull width should be greater than water width.",
                    triggerWhen: topoExpr(env => {
                      const a = env
                        .getFocusedAs(isNullable(isNumber))
                        .getOrElse(() => 0);
                      const b = env
                        .getAbsoluteAs(
                          ["data", "channelDimensions", "channelWaterWidth"],
                          isNullable(isNumber)
                        )
                        .getOrElse(() => 0);

                      return isNumber(a) && isNumber(b) && a < b;
                    }),
                  }),
                ],
                help: outdent`
                Horizontal distance across the channel from at the level where the river first spills out onto the floodplain.
                `,
              }),
              numberField({
                label: "Water Depth",
                path: ["channelWaterDepth"],
                required: required("info"),
                bounds: minValue(0),
                decimalPlaces: 2,
                units: "m",
                help: outdent`
                Average depth of the water in the cross-section. An average of 3 equally spaced depths using a ranging pole is adequate. Where access is not feasible, estimate the water depth.
                `,
              }),
            ],
          }),
          section({
            title: "Bank Measurements",
            path: [],
            blocks: columns2(
              numberField({
                label: "Bank Top Height",
                secondaryLabel: "Left Bank",
                path: ["leftBankTopHeight"],
                required: required("info"),
                bounds: minValue(0),
                decimalPlaces: 2,
                units: "m",
                help: outdent`
                  Vertical distance from the water level to the bank top / floodplain level.
                  `,
              }),
              numberField({
                label: "Bank Top Height",
                secondaryLabel: "Right Bank",
                path: ["rightBankTopHeight"],
                required: required("info"),
                bounds: minValue(0),
                decimalPlaces: 2,
                units: "m",
                help: outdent`
                  Vertical distance from the water level to the bank top / floodplain level.
                  `,
              }),
              numberField({
                label: "Left Embanked / Flood Wall Height",
                path: ["leftEmbankedHeight"],
                required: required("info"),
                bounds: minValue(0),
                decimalPlaces: 2,
                units: "m",
                help: outdent`
                  Extra vertical height about the bank top / floodplain level created by embanked material including flood walls and set-back embankments. Enter zero when embankments are not present.
                  `,
              }),
              numberField({
                label: "Right Embanked / Flood Wall Height",
                path: ["rightEmbankedHeight"],
                required: required("info"),
                bounds: minValue(0),
                decimalPlaces: 2,
                units: "m",
                help: outdent`
                  Extra vertical height about the bank top / floodplain level created by embanked material including flood walls and set-back embankments. Enter zero when embankments are not present.
                  `,
              })
            ),
          }),
        ],
      }),
      spotCheckPage(["data", "spotChecks", 0], "Spot Check 1"),
      spotCheckPage(["data", "spotChecks", 1], "Spot Check 2"),
      spotCheckPage(["data", "spotChecks", 2], "Spot Check 3"),
      spotCheckPage(["data", "spotChecks", 3], "Spot Check 4"),
      spotCheckPage(["data", "spotChecks", 4], "Spot Check 5"),
      spotCheckPage(["data", "spotChecks", 5], "Spot Check 6"),
      spotCheckPage(["data", "spotChecks", 6], "Spot Check 7"),
      spotCheckPage(["data", "spotChecks", 7], "Spot Check 8"),
      spotCheckPage(["data", "spotChecks", 8], "Spot Check 9"),
      spotCheckPage(["data", "spotChecks", 9], "Spot Check 10"),
      page({
        title: "Sweep-Up",
        path: ["data", "sweepUp"],
        blocks: [
          section({
            title: "Channel Physical Attributes",
            path: [],
            blocks: columns2(
              select({
                label: "Channel Substrate",
                path: ["sweepUpChannelSubstrate"],
                options: selectOptionImages({
                  options: enumSelectOptions(UrsChannelSubstrateEnum),
                  pattern: "urs/channel-substrate/*.jpg",
                }),
                showValues: true,
                help: outdent`
                  Substrate types observed between spot checks covering >1% of channel bed
                  or forming a patch with diameter greater than 50% channel width.
                  `,
              })
            ),
          }),
          section({
            title: "Channel Vegetation",
            path: [],
            help: "Channel vegetation types observed in between spot-checks.",
            blocks: columns2(
              ...positiveCountsGroup({
                path: ["sweepUpChannelVegetation"],
                fields: selectOptionImages({
                  options: enumSelectOptions(UrsVegetationEnum),
                  pattern: "urs/vegetation/*.jpg",
                }),
              })
            ),
          }),
          section({
            title: "Macrophytes",
            path: [],
            blocks: [
              select({
                label: "Channel choked with macrophytes?",
                path: ["chokedWithMacrophytes"],
                options: enumSelectOptions(YesNoUnknownEnum),
                defaultValue: YesNoUnknownEnum.Unknown,
                required: required(),
                help: outdent`
                (Optional) Is the channel blocked by vegetation to the extent that no moving water is visible?
                `,
              }),
              textArea({
                label: "Additional Notes",
                path: ["macrophyteNotes"],
                rows: 5,
                fullWidth: true,
                customRules: [
                  requiredIf({
                    level: "info",
                    message: "You should add notes on the macrophytes present.",
                    otherPath: ["data", "sweepUp", "chokedWithMacrophytes"],
                    otherTest: value => value === "yes",
                  }),
                ],
                help: outdent`
                Additional spot check details or further observations
                of the river channel between spot checks.

                For example: macrophyte species
                according to particular interests of surveyor or linked observations.
                `,
              }),
            ],
          }),
        ],
      }),
      page({
        title: "Bank Profile and Protection",
        path: ["data", "bankProfileAndProtection"],
        help: outdent`
        Bank profiles are recorded in three categories:
        *natural/unmodified*, *artificial (reinforcement profile)*,
        and *artificial (bank profile)*.
        Each category appears separately below.

        It is possible for a bank section
        to fall into more than one of the three categories.
        For example, a bank might have a natural lower profile (e.g. undercut)
        but have a resectioned upper profile
        and also be reinforced in the upper part of the bank.

        Each category includes a "none" class to ensure that
        percentages (nearest 5%) relating to all classes
        within any of the three categories sum to 100% over the reach.
        `,
        blocks: [
          section({
            title: "Natural/Unmodified Bank Profile",
            path: [],
            help: outdent`
            Percentage cover of *natural* bank profile types for each bank,
            including the extent of "none" for completely artificial areas:

            ![Natural bank profile types](https://media.cartographer.io/static/images/urs/natural-bank-profile.png)
            `,
            blocks: columns2(
              ...interleaveFields(
                percentageDistributionGroup({
                  path: ["leftBankNaturalProfile"],
                  fields: enumSelectOptions(UrsNaturalBankProfileEnum).map(
                    ({ value, label }) => ({
                      value,
                      label,
                      secondaryLabel: "Left Bank",
                    })
                  ),
                }),
                percentageDistributionGroup({
                  path: ["rightBankNaturalProfile"],
                  fields: enumSelectOptions(UrsNaturalBankProfileEnum).map(
                    ({ value, label }) => ({
                      value,
                      label,
                      secondaryLabel: "Right Bank",
                    })
                  ),
                })
              )
            ),
          }),
          section({
            title: "Artificial (Reinforcement)",
            path: [],
            help: outdent`
            Percentage cover of artificial reinforcement profile types for each bank,
            including the extent of "none" for unreinforced areas:

            ![Artificial bank protection types](https://media.cartographer.io/static/images/urs/artificial-bank-protection.png)
            `,
            blocks: columns2(
              ...interleaveFields(
                percentageDistributionGroup({
                  path: ["leftBankArtificialReinforcement"],
                  fields: enumSelectOptions(
                    UrsArtificialBankReinforcementEnum
                  ).map(({ value, label }) => ({
                    value,
                    label,
                    secondaryLabel: "Left Bank",
                  })),
                }),
                percentageDistributionGroup({
                  path: ["rightBankArtificialReinforcement"],
                  fields: enumSelectOptions(
                    UrsArtificialBankReinforcementEnum
                  ).map(({ value, label }) => ({
                    value,
                    label,
                    secondaryLabel: "Right Bank",
                  })),
                })
              )
            ),
          }),
          section({
            title: "Artificial (Bank Profile)",
            path: [],
            help: outdent`
            Percentage cover of *artificial* bank profile types,
            including the extent of "none" for completely natural areas:

            ![Artificial bank profile types](https://media.cartographer.io/static/images/urs/artificial-bank-profile.png)
            `,
            blocks: columns2(
              ...interleaveFields(
                percentageDistributionGroup({
                  path: ["leftBankArtificialProfile"],
                  fields: enumSelectOptions(UrsArtificialBankProfileEnum).map(
                    ({ value, label }) => ({
                      value,
                      label,
                      secondaryLabel: "Left Bank",
                    })
                  ),
                }),
                percentageDistributionGroup({
                  path: ["rightBankArtificialProfile"],
                  fields: enumSelectOptions(UrsArtificialBankProfileEnum).map(
                    ({ value, label }) => ({
                      value,
                      label,
                      secondaryLabel: "Right Bank",
                    })
                  ),
                })
              )
            ),
          }),
          section({
            title: "Bank Protection",
            path: [],
            help: outdent`
            Bank protection in terms of the length of bank
            affected to the nearest 5%.

            Record "none" to indicate the percentage of banks
            with no protection at any level of the bank profile.

            Note: Total protection along a bank
            can sum to more than 100% if
            different protection types are applied to
            different parts of the bank profile
            (e.g. toe is protected using a different material
            from the bank face).
            `,
            blocks: columns2(
              ...interleaveFields(
                percentageDistributionGroup({
                  path: ["leftBankProtection"],
                  fields: selectOptionImages({
                    options: enumSelectOptions(UrsBankProtectionEnum),
                    pattern: "urs/bank-protection/*.jpg",
                    exclude: [UrsBankProtectionEnum.Other],
                  }).map(({ value, label, image }) => ({
                    value,
                    label,
                    secondaryLabel: "Left Bank",
                    image,
                  })),
                }),
                percentageDistributionGroup({
                  path: ["rightBankProtection"],
                  fields: selectOptionImages({
                    options: enumSelectOptions(UrsBankProtectionEnum),
                    pattern: "urs/bank-protection/*.jpg",
                    exclude: [UrsBankProtectionEnum.Other],
                  }).map(({ value, label, image }) => ({
                    value,
                    label,
                    secondaryLabel: "Right Bank",
                    image,
                  })),
                })
              )
            ),
          }),
        ],
      }),
      page({
        title: "Channel Dynamics",
        path: ["data", "channelDynamics"],
        blocks: [
          section({
            title: null,
            path: ["channelDynamics"],
            blocks: columns2(
              select({
                label: "One Bank Eroding",
                path: ["oneBankEroding"],
                help: "Vertical / vertical and toe / undercut",
                options: enumSelectOptions(UrsChannelDynamicsExtentEnum),
                defaultValue: UrsChannelDynamicsExtentEnum.None,
                required: required("info"),
              }),
              select({
                label: "Opposite Banks Eroding",
                path: ["oppositeBanksEroding"],
                help: "Vertical / vertical and toe / undercut",
                options: enumSelectOptions(UrsChannelDynamicsExtentEnum),
                defaultValue: UrsChannelDynamicsExtentEnum.None,
                required: required("info"),
              }),
              select({
                label: "Opposite banks depositing",
                path: ["oppositeBanksDepositing"],
                help: "Vegetated bars / berms",
                options: enumSelectOptions(UrsChannelDynamicsExtentEnum),
                defaultValue: UrsChannelDynamicsExtentEnum.None,
                required: required("info"),
              }),
              select({
                label: "One or more banks with berm >25% width",
                path: ["berm"],
                options: enumSelectOptions(UrsChannelDynamicsExtentEnum),
                defaultValue: UrsChannelDynamicsExtentEnum.None,
                required: required("info"),
              })
            ),
          }),
          section({
            title: "Channel Dynamics Features",
            path: ["channelDynamicsFeatures"],
            blocks: [
              select({
                label: "Buried Soil within Bank Profile",
                path: ["buriedSoil"],
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
              select({
                label: "Burial of River Bed with Fine Sediment",
                path: ["fineSediment"],
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
              select({
                label: "Burial of Base of Structures",
                path: ["structureBases"],
                help: "e.g. bridge piers",
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
              select({
                label: "Burial of Bbase of Established Vegetation",
                path: ["vegetationBases"],
                help: "e.g. trees",
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
              select({
                label: "River Channel Narrow Relative to Bridge Openings",
                path: ["narrowChannel"],
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
              select({
                label: "Bed Sediment Exposed within Bank Profile",
                path: ["bedSediment"],
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
              select({
                label:
                  "Trees with Exposed Roots / Collapsing / Leaning into Channel on Both Banks",
                path: ["exposedRoots"],
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
              select({
                label: "Exposure of Foundations of Structures",
                path: ["exposedFoundations"],
                help: "e.g. bridge piers",
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
              select({
                label: "Heavily Compacted and Armoured Bed",
                path: ["compactedBed"],
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
                required: required("info"),
              }),
            ],
          }),
        ],
      }),
      page({
        title: "Artificial Influences",
        path: ["data", "artificialInfluences"],
        blocks: [
          section({
            title: "Artificial Features",
            path: [],
            help: outdent`
            Record any bridges, weirs, deflectors or culverts
            according to the types and impacts defined
            by the RHS definitions and illustrated below:

            ![Bridges and Weirs. Image source: River Habitat Survey manual (2003 edition)](https://media.cartographer.io/static/images/urs/bridges-and-weirs.png)

            Weirs that have completely collapsed and only extend partially across channel
            should be recorded as deflectors / groynes.
            `,
            blocks: [
              ...positiveCountsGroup({
                path: ["artificialFeatures"],
                fields: enumSelectOptions(UrsArtificialFeatureEnum),
              }),
              textArea({
                label: "Artificial Influences Notes",
                path: ["artificialFeaturesNotes"],
                rows: 5,
                customRules: [
                  rule({
                    level: "info",
                    message:
                      "You should summarise the artificial features recorded under 'other'.",
                    triggerWhen: topoExpr(env => {
                      const features = env
                        .getAs(
                          [
                            "data",
                            "artificialInfluences",
                            "artificialFeatures",
                          ],
                          isRecordOf(isString, isNumber)
                        )
                        .getOrNull();
                      const featuresRequiringNotes = [
                        "majorOther",
                        "intermediateOther",
                        "minorOther",
                      ];

                      return (
                        env.getFocused() == null &&
                        features != null &&
                        featuresRequiringNotes.some(f => features[f] > 0)
                      );
                    }),
                  }),
                ],
                help: outdent`
                Specify any "other" features noted above.
                `,
              }),
            ],
          }),
          section({
            title: "Bridges",
            path: [],
            help: outdent`
            Divide the bridges listed in *Articial features* above into the following categories based on use.
            `,
            blocks: positiveCountsGroup({
              path: ["bridgeTypes"],
              fields: enumSelectOptions(UrsBridgeTypeEnum),
            }),
          }),
          section({
            title: "Recent Management",
            path: [],
            help: outdent`
            Obvious, recent river channel management activities.

            Record whether each listed activity is
            *absent* (0% cover), *present* (&lt;33% cover), or *extensive* (&gt;33% cover),
            along the whole survey stretch.
            `,
            blocks: [
              ...selectGroup<UrsApe>({
                path: ["recentManagement"],
                fields: enumSelectOptions(UrsManagementFeatureEnum),
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
              }),
              textArea({
                label: "Recent Management Notes",
                path: ["recentManagementNotes"],
                rows: 8,
                fullWidth: true,
                customRules: [
                  rule({
                    level: "info",
                    message:
                      "You should describe the other management activities.",
                    triggerWhen: topoExpr(env => {
                      const valuesRequiringNotes: unknown[] = [
                        UrsApeEnum.Present,
                        UrsApeEnum.Extensive,
                      ];
                      return (
                        env.getFocused() == null &&
                        valuesRequiringNotes.includes(
                          env.getAbsolute([
                            "data",
                            "artificialInfluences",
                            "recentManagement",
                            "other",
                          ])
                        )
                      );
                    }),
                  }),
                ],
                help: outdent`
                Briefly describe other management activities
                and their extent as *present* or *extensive*.
                `,
              }),
            ],
          }),
          section({
            title: "Nuisance Species",
            path: [],
            help: outdent`
            The abundance of nuisance species as follows:

            - *individuals* - isolated plants occupying &lt;1% cover;
            - *isolated clumps* - distinct clumps of plants but with low cover (&lt;5%);
            - *frequent* - 5-33% cover;
            - *extensive* - &gt;33% cover.
            `,
            blocks: columns2(
              ...selectGroup<UrsSpeciesFrequency>({
                path: ["nuisanceSpecies"],
                fields: selectOptionImages({
                  options: enumSelectOptions(UrsNuisanceSpeciesEnum),
                  pattern: "urs/nuisance-species/*.jpg",
                }).map(({ value, label, image }) => ({
                  value,
                  label,
                  image,
                })),
                options: enumSelectOptions(UrsSpeciesFrequencyEnum),
                defaultValue: UrsSpeciesFrequencyEnum.None,
              }),
              textArea({
                label: "Nuisance Species Notes",
                path: ["otherNuisanceSpecies"],
                rows: 8,
                fullWidth: true,
                customRules: [
                  rule({
                    level: "info",
                    message:
                      "You should summarise the species recorded under 'other'.",
                    triggerWhen: topoExpr(env => {
                      const frequenciesRequiringNotes: unknown[] = [
                        UrsSpeciesFrequencyEnum.Individuals,
                        UrsSpeciesFrequencyEnum.IsolatedClumps,
                        UrsSpeciesFrequencyEnum.Frequent,
                        UrsSpeciesFrequencyEnum.Extensive,
                      ];
                      return (
                        env.getFocused() == null &&
                        frequenciesRequiringNotes.includes(
                          env.getAbsolute([
                            "data",
                            "artificialInfluences",
                            "nuisanceSpecies",
                            "other",
                          ])
                        )
                      );
                    }),
                  }),
                ],
                help: outdent`
                  Any other observed nuisance riparian or aquatic species (e.g. Floating Pennywort).
                  `,
              })
            ),
          }),
          section({
            title: "Other Notes",
            path: [],
            blocks: [
              textArea({
                label: null,
                path: ["otherNotes"],
                rows: 5,
                fullWidth: true,
                help: outdent`
                Any other notes related to artificial influences or land use,
                including information gained from aerial imagery.

                Reference to air photos / imagery should be included where
                visibility is restricted at ground level.
                `,
              }),
            ],
          }),
        ],
      }),
      page({
        title: "Extent of Pollution",
        path: ["data", "extentOfPollution"],
        help: outdent`
        **If you identify a pollution incident,
        report it to the Environment Agency's hotline:
        [0800 80 70 60](tel:44800807060).**
        `,
        blocks: [
          section({
            title: "Pollution Sources",
            path: [],
            blocks: columns2(
              ...positiveCountsGroup({
                path: ["pollutionSources"],
                fields: [
                  {
                    label: "Input Pipes",
                    value: "inputPipes",
                    image:
                      "https://media.cartographer.io/static/images/urs/pollution-source/inputpipes.jpg",
                    help: outdent`
                    *Count of input pipes*, including all sewage outfalls,
                    industrial effluent pipes and surface runoff pipes >10cm diameter.
                    `,
                  },
                  {
                    label: "Leach Points",
                    value: "leachPoints",
                    image:
                      "https://media.cartographer.io/static/images/urs/pollution-source/leachpoints.jpg",
                    help: outdent`
                    *Count of leach points*, including natural leaching
                    and small land drains <10cm with signs of active seepage.
                    `,
                  },
                ],
              })
            ),
          }),
          section({
            title: "Pollution Indicators",
            path: [],
            help: outdent`
            Water or sediment odours, oils, surface scum, and gross pollution.

            Choose *absent* (0%), *present* (<33%), or *extensive* (>33%) according to the length of the stretch affected.
            `,
            blocks: columns2(
              ...selectGroup({
                path: ["pollutionIndicators"],
                fields: selectOptionImages({
                  options: enumSelectOptions(UrsPollutionIndicatorEnum),
                  pattern: "urs/pollution-indicator/*.jpg",
                }).map(({ value, label, image }) => ({
                  value,
                  label,
                  image,
                })),
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
              })
            ),
          }),
          section({
            title: "Water Clarity",
            path: [],
            blocks: [
              select({
                label: "Water Clarity",
                path: ["waterClarity"],
                options: enumSelectOptions(UrsWaterClarityEnum),
                required: required("info"),
                help: outdent`
                An assessment of the general water clarity along the stretch at the time of the survey.
                `,
              }),
              textArea({
                label: "Water Clarity Notes",
                path: ["waterClarityNotes"],
                rows: 5,
                fullWidth: true,
              }),
            ],
          }),
        ],
      }),
      page({
        title: "Habitat Features",
        path: [],
        blocks: [
          section({
            title: "Counted Habitat Features",
            path: ["data", "habitatFeatures"],
            help: outdent`
            Number of discrete habitat features in the stretch. Backwater habitat types are illustrated:

            ![Habitat features](https://media.cartographer.io/static/images/urs/backwater-types.png)
            `,
            blocks: columns2(
              ...positiveCountsGroup({
                path: ["countedHabitatFeatures"],
                fields: selectOptionImages({
                  options: enumSelectOptions(UrsCountedHabitatFeatureEnum),
                  pattern: "urs/counted-habitat-feature/*.jpg",
                }).map(({ value, label, image }) => ({
                  value,
                  label,
                  image,
                })),
              })
            ),
          }),
          section({
            title: "Percentage Habitat Features",
            path: ["data", "habitatFeatures"],
            help: outdent`
            Percentage water surface area (nearest 5%) categorised as various flow (habitat) types.
            These figures represent areas of the channel predominantly affected the flow (patch) types.
            `,
            blocks: columns2(
              ...percentageDistributionGroup({
                path: ["percentageHabitatFeatures"],
                fields: selectOptionImages({
                  options: enumSelectOptions(UrsPercentageHabitatFeatureEnum),
                  pattern: "urs/percentage-habitat-feature/*.jpg",
                }).map(({ value, label, image }) => ({
                  value,
                  label,
                  image,
                })),
              })
            ),
          }),
        ],
      }),
      page({
        title: "Special Features",
        path: ["data", "specialFeatures"],
        blocks: [
          section({
            title: "Special Features",
            path: [],
            help: outdent`
            Special features of the stretch - extremely rare in urban river corridors.

            Special features are fully described in the RHS manual (2003).
            `,
            blocks: columns2(
              ...selectGroup<UrsApe>({
                path: ["specialFeatures"],
                fields: selectOptionImages({
                  options: enumSelectOptions(UrsSpecialFeatureEnum),
                  pattern: "urs/special-feature/*.jpg",
                }).map(({ value, label, image }) => ({
                  value,
                  label,
                  image,
                })),
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
              })
            ),
          }),
          section({
            title: "Special Features Photographs",
            path: [],
            help: outdent`
            Up to four photographs representing the characteristic engineering type of the survey stretch.
            `,
            blocks: [
              attachmentField({
                label: null,
                path: ["photographs"],
                maxFiles: 4,
              }),
            ],
          }),
          section({
            title: "Tree Features",
            path: [],
            help: outdent`
            Tree features, scored as *absent* (0%), *present* (<33%), or *extensive* (<33%)
            of the length of the stretch affected. Record <em>present</em> even if cover is only 1%.

            Channel shading and overhanging boughs are recorded according to their cover of the low flow channel area.

            Exposed bankside roots, underwater roots, fallen trees and large wood debris
            (i.e. wood pieces and accumulations containing at least one piece
            greater than 1m in length and 10cm in diameter)
            are recorded according to their presence on / along either bank along the length of the stretch.
            `,
            blocks: columns2(
              ...selectGroup<UrsApe>({
                path: ["treeFeatures"],
                fields: selectOptionImages({
                  options: selectOptionLabels({
                    options: enumSelectOptions(UrsTreeFeatureEnum),
                    suffix: "(Either Bank)",
                  }),
                  pattern: "urs/tree-feature/*.jpg",
                }).map(({ value, label, image }) => ({
                  value,
                  label,
                  image,
                })),
                options: enumSelectOptions(UrsApeEnum),
                showValues: true,
                defaultValue: UrsApeEnum.Absent,
              })
            ),
          }),
          section({
            title: "Tree Distribution",
            path: [],
            help: outdent`
            ![Tree Distribution](https://media.cartographer.io/static/images/urs/tree-distribution.png)

            Image source: River Habitat Survey (2003 edition).
            `,
            blocks: columns2(
              select({
                label: "Tree Distribution",
                secondaryLabel: "Left Bank",
                path: ["leftBankTreeDistribution"],
                options: enumSelectOptions(UrsTreeDistributionEnum),
                showValues: true,
                required: required("info"),
              }),
              select({
                label: "Tree Distribution",
                secondaryLabel: "Right Bank",
                path: ["rightBankTreeDistribution"],
                options: enumSelectOptions(UrsTreeDistributionEnum),
                showValues: true,
                required: required("info"),
              })
            ),
          }),
        ],
      }),
      page({
        title: "Ecological Characteristics",
        path: ["data", "ecologicalCharacteristics"],
        blocks: [
          section({
            title: "European Protected Species",
            path: [],
            blocks: columns3(
              ...interleaveFields(
                checkboxGroup({
                  path: ["observedProtectedSpecies"],
                  fields: enumSelectOptions(UrsProtectedSpeciesEnum).map(
                    ({ value, label }) => ({
                      value,
                      label,
                      checkboxLabel: "Observed?",
                    })
                  ),
                }),
                checkboxGroup({
                  path: ["physicalSignsOfProtectedSpecies"],
                  fields: enumSelectOptions(UrsProtectedSpeciesEnum).map(
                    ({ value, label }) => ({
                      value,
                      label,
                      checkboxLabel: "Signs of presence?",
                    })
                  ),
                }),
                checkboxGroup({
                  path: ["suitableHabitatForProtectedSpecies"],
                  fields: enumSelectOptions(UrsProtectedSpeciesEnum).map(
                    ({ value, label }) => ({
                      value,
                      label,
                      checkboxLabel: "Suitable habitat?",
                    })
                  ),
                })
              )
            ),
          }),
          section({
            title: "Other Observed Species: Amphibians/Reptiles",
            path: [],
            blocks: checkboxGroup({
              path: ["ecologicalCharacteristics"],
              fields: [
                UrsEcologicalCharacteristicEnum.AmphibianOrReptileCommonFrog,
                UrsEcologicalCharacteristicEnum.AmphibianOrReptileToad,
                UrsEcologicalCharacteristicEnum.AmphibianOrReptileGrassSnake,
                UrsEcologicalCharacteristicEnum.AmphibianOrReptileSlowWorm,
                UrsEcologicalCharacteristicEnum.AmphibianOrReptileOther,
              ]
                .map(UrsEcologicalCharacteristicEnum.entryOf)
                .map(({ value, label }) => ({
                  value,
                  label,
                  checkboxLabel: "Observed?",
                })),
            }),
          }),
          section({
            title: "Other Observed Species: Aquatic Invertebrates",
            path: [],
            blocks: checkboxGroup({
              path: ["ecologicalCharacteristics"],
              fields: [
                UrsEcologicalCharacteristicEnum.AquaticInvertebrateDragonfly,
                UrsEcologicalCharacteristicEnum.AquaticInvertebrateDamselfly,
                UrsEcologicalCharacteristicEnum.AquaticInvertebrateBandedDamoiselle,
                UrsEcologicalCharacteristicEnum.AquaticInvertebrateMidge,
                UrsEcologicalCharacteristicEnum.AquaticInvertebrateOther,
              ]
                .map(UrsEcologicalCharacteristicEnum.entryOf)
                .map(({ value, label }) => ({
                  value,
                  label,
                  checkboxLabel: "Observed?",
                })),
            }),
          }),
          section({
            title: "Other Observed Species: Birds",
            path: [],
            blocks: checkboxGroup({
              path: ["ecologicalCharacteristics"],
              fields: [
                UrsEcologicalCharacteristicEnum.BirdBlackRedstart,
                UrsEcologicalCharacteristicEnum.BirdGreyHeron,
                UrsEcologicalCharacteristicEnum.BirdGreyWagtail,
                UrsEcologicalCharacteristicEnum.BirdHouseMartin,
                UrsEcologicalCharacteristicEnum.BirdHouseSparrow,
                UrsEcologicalCharacteristicEnum.BirdKingfisher,
                UrsEcologicalCharacteristicEnum.BirdRobin,
                UrsEcologicalCharacteristicEnum.BirdSandMartin,
                UrsEcologicalCharacteristicEnum.BirdSwift,
                UrsEcologicalCharacteristicEnum.BirdOther,
              ]
                .map(UrsEcologicalCharacteristicEnum.entryOf)
                .map(({ value, label }) => ({
                  value,
                  label,
                  checkboxLabel: "Observed?",
                })),
            }),
          }),
          section({
            title: "Other Observed Species: Fish",
            path: [],
            blocks: checkboxGroup({
              path: ["ecologicalCharacteristics"],
              fields: [
                UrsEcologicalCharacteristicEnum.FishBullhead,
                UrsEcologicalCharacteristicEnum.FishMinnow,
                UrsEcologicalCharacteristicEnum.FishOther,
              ]
                .map(UrsEcologicalCharacteristicEnum.entryOf)
                .map(({ value, label }) => ({
                  value,
                  label,
                  checkboxLabel: "Observed?",
                })),
            }),
          }),
          section({
            title: "Other Observed Species: Mammals",
            path: [],
            blocks: checkboxGroup({
              path: ["ecologicalCharacteristics"],
              fields: [
                UrsEcologicalCharacteristicEnum.MammalHedgehog,
                UrsEcologicalCharacteristicEnum.MammalOther,
              ]
                .map(UrsEcologicalCharacteristicEnum.entryOf)
                .map(({ value, label }) => ({
                  value,
                  label,
                  checkboxLabel: "Observed?",
                })),
            }),
          }),
          section({
            title: "Other Observed Species: Plants",
            path: [],
            blocks: checkboxGroup({
              path: ["ecologicalCharacteristics"],
              fields: [
                UrsEcologicalCharacteristicEnum.PlantWaterCrowfoot,
                UrsEcologicalCharacteristicEnum.PlantWaterCress,
                UrsEcologicalCharacteristicEnum.PlantPurpleLoosestrife,
                UrsEcologicalCharacteristicEnum.PlantCommonReed,
                UrsEcologicalCharacteristicEnum.PlantOther,
              ]
                .map(UrsEcologicalCharacteristicEnum.entryOf)
                .map(({ value, label }) => ({
                  value,
                  label,
                  checkboxLabel: "Observed?",
                })),
            }),
          }),
          section({
            title: "Other Observed Species: Terrestrial Invertebrates",
            path: [],
            blocks: checkboxGroup({
              path: ["ecologicalCharacteristics"],
              fields: [
                UrsEcologicalCharacteristicEnum.TerrestrialInvertebrateStagBeetle,
                UrsEcologicalCharacteristicEnum.TerrestrialInvertebrateBombusHumilis,
                UrsEcologicalCharacteristicEnum.TerrestrialInvertebrateBeetle,
                UrsEcologicalCharacteristicEnum.TerrestrialInvertebrateOther,
              ]
                .map(UrsEcologicalCharacteristicEnum.entryOf)
                .map(({ value, label }) => ({
                  value,
                  label,
                  checkboxLabel: "Observed?",
                })),
            }),
          }),
          section({
            title: "Other Observed Species: Trees",
            path: [],
            blocks: checkboxGroup({
              path: ["ecologicalCharacteristics"],
              fields: [
                UrsEcologicalCharacteristicEnum.TreeMistletoe,
                UrsEcologicalCharacteristicEnum.TreeBlackPoplar,
                UrsEcologicalCharacteristicEnum.TreeCrackWillow,
                UrsEcologicalCharacteristicEnum.TreeAlder,
                UrsEcologicalCharacteristicEnum.TreeOther,
              ]
                .map(UrsEcologicalCharacteristicEnum.entryOf)
                .map(({ value, label }) => ({
                  value,
                  label,
                  checkboxLabel: "Observed?",
                })),
            }),
          }),
          section({
            title: "Notes and Additional Observations",
            path: [],
            help: outdent`
            Any further information about observed species within the survey stretch.
            `,
            blocks: [
              textArea({
                label: null,
                path: ["ecologicalCharacteristicsNotes"],
                rows: 8,
                fullWidth: true,
              }),
            ],
          }),
        ],
      }),
    ],
  });
}

export default ursForm("Urban River Survey");
