import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Coupon,
  isCoupon,
  StripeCouponId,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";
import { isArrayOf } from "@cartographerio/guard";

const basePath = "/billing/coupon/v1";

export function list(apiParams: ApiParams): IO<Coupon[]> {
  return fetch
    .get({ apiParams, url: { path: basePath } })
    .flatMap(contentAs("Coupon[]", isArrayOf(isCoupon)));
}

export function readOrFail(
  apiParams: ApiParams,
  couponId: StripeCouponId
): IO<Coupon> {
  const url = { path: `${basePath}/${couponId}` };
  return fetch.get({ apiParams, url }).flatMap(contentAs("Coupon", isCoupon));
}

export function readOption(
  apiParams: ApiParams,
  couponId: StripeCouponId
): IO<Option<Coupon>> {
  const url = { path: `${basePath}/${couponId}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(optionalContentAs("Coupon", isCoupon));
}
