import { hasKeyOfAnyType, hasTypeTag } from "@cartographerio/guard";
import { Locale } from "date-fns";
import { format as dftzFormat, toZonedTime } from "date-fns-tz";
import { formatDistance } from "date-fns/formatDistance";
import { parse } from "date-fns/parse";
import { parseISO } from "date-fns/parseISO";
import {
  TimestampFormat,
  ddmmyyyy,
  defaultTimestampFormat,
  isIso8601,
  iso8601TimestampFormat,
  yyyymmdd,
} from "./format";
import { TimeZone, defaultTimeZone, utc } from "./timeZone";
import { Timestamp, isTimestamp } from "./types.generated";

// We would call this isTimestamp
// but there's already a guard defined in core.generated.ts.
// This function does more checks in the iso8601 field.
export function isValidTimestamp(value: unknown): value is Timestamp {
  return (
    hasTypeTag(value, "Timestamp") &&
    hasKeyOfAnyType(value, "iso8601") &&
    isIso8601(value.iso8601)
  );
}

export function timestampToDate(timestamp: Timestamp): Date {
  return parseISO(timestamp.iso8601);
}

export function dateToTimestamp(date: Date): Timestamp {
  return {
    type: "Timestamp",
    iso8601: date.toISOString(),
  };
}

export function nowTimestamp(): Timestamp {
  return dateToTimestamp(new Date());
}

export function epochTimestamp(epoch: number): Timestamp {
  return dateToTimestamp(new Date(epoch));
}

export function iso8601Timestamp(iso8601: string): Timestamp {
  return {
    type: "Timestamp",
    iso8601,
  };
}

export function parseTimestamp(
  string: string,
  format: TimestampFormat,
  reference: Timestamp | Date | number = new Date(),
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: 1 | 4;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
): Timestamp | undefined {
  try {
    return dateToTimestamp(
      parse(
        string,
        format,
        isTimestamp(reference) ? timestampToDate(reference) : reference,
        options
      )
    );
  } catch (exn) {
    return undefined;
  }
}

export const parseAnyTimestamp = (param: string): Timestamp | undefined =>
  parseTimestamp(param, yyyymmdd) ?? parseTimestamp(param, ddmmyyyy);

interface FormatTimestampOpts {
  // A locale string, e.g. "Europe/London"
  timeZone?: TimeZone;
  // A date/time format: https://date-fns.org/v2.19.0/docs/format
  format?: TimestampFormat;
}

export function formatTimestamp(
  timestamp: Timestamp,
  opts: FormatTimestampOpts = {}
): string {
  const { timeZone = defaultTimeZone, format = defaultTimestampFormat } = opts;
  const zoned = toZonedTime(parseISO(timestamp.iso8601), timeZone);
  return dftzFormat(zoned, format, { timeZone });
}

export function formatTimestampIso8601(timestamp: Timestamp): string {
  return formatTimestamp(timestamp, {
    timeZone: utc,
    format: iso8601TimestampFormat,
  });
}

export function formatTimestampAgo(
  timestamp: Timestamp,
  now: Timestamp = nowTimestamp()
): string {
  const tsDate = timestampToDate(timestamp);
  const nowDate = timestampToDate(now);
  const distance = formatDistance(tsDate, nowDate);
  return nowDate.getTime() > tsDate.getTime()
    ? `${distance} ago`
    : `in ${distance}`;
}

export function timestampEpoch(timestamp: Timestamp): number {
  return timestampToDate(timestamp).getTime();
}

export function timestampEq(x: Timestamp, y: Timestamp): boolean {
  return timestampEpoch(x) === timestampEpoch(y);
}

export function timestampNeq(x: Timestamp, y: Timestamp): boolean {
  return timestampEpoch(x) !== timestampEpoch(y);
}

export function timestampGt(x: Timestamp, y: Timestamp): boolean {
  return timestampEpoch(x) > timestampEpoch(y);
}

export function timestampLt(x: Timestamp, y: Timestamp): boolean {
  return timestampEpoch(x) < timestampEpoch(y);
}

export function timestampGte(x: Timestamp, y: Timestamp): boolean {
  return timestampEpoch(x) >= timestampEpoch(y);
}

export function timestampLte(x: Timestamp, y: Timestamp): boolean {
  return timestampEpoch(x) <= timestampEpoch(y);
}
