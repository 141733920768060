// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { WorkspaceRoleName } from "../types.generated";

const Disabled: WorkspaceRoleName = "Disabled";
const Active: WorkspaceRoleName = "Active";
const Admin: WorkspaceRoleName = "Admin";
const Owner: WorkspaceRoleName = "Owner";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WorkspaceRoleNameEnum = {
  ...createEnum("WorkspaceRoleName", [
    [Disabled, "Disabled"],
    [Active, "Active"],
    [Admin, "Workspace Admin"],
    [Owner, "Workspace Owner"],
  ]),
  Disabled,
  Active,
  Admin,
  Owner,
};

export default WorkspaceRoleNameEnum;
