/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { Geometry, isGeometry } from "@cartographerio/geometry";
import {
  ProjectId,
  isProjectId,
  SurveyId,
  isSurveyId,
  SurveyModuleId,
  isSurveyModuleId,
  TeamId,
  isTeamId,
  Timestamp,
  isTimestamp,
} from "../core/types.generated";
import { UserRef, isUserRef } from "../user/types.generated";
import {
  TaggedNumber,
  bridgesIsTaggedNumberHack as isTaggedNumber,
} from "@cartographerio/util";

export interface SurveyNames {
  shortName: string;
  longName: string;
}

export type SchemaVersion = TaggedNumber<"SchemaVersion">;

export interface SurveyModuleSummary {
  moduleId: SurveyModuleId;
  names: SurveyNames;
}

export type SurveyStatus =
  | "draft"
  | "complete"
  | "approved"
  | "review"
  | "rejected";

export interface SurveyF<A> {
  id: SurveyId;
  moduleId: SurveyModuleId;
  projectId: ProjectId;
  teamId?: TeamId | null;
  created: Timestamp;
  updated: Timestamp;
  surveyor: UserRef;
  status: SurveyStatus;
  schemaVersion: SchemaVersion;
  data: A;
}

export interface SurveyMetadata {
  geometry?: Geometry | null;
  timestamp?: Timestamp | null;
  workspaceName: string;
  projectName: string;
  teamName?: string | null;
  description?: string | null;
}

export type Survey = SurveyF<unknown>;

export type SurveySummary = SurveyF<SurveyMetadata>;

export const isSurveyNames = (v: any): v is SurveyNames => {
  return (
    typeof v === "object" &&
    v != null &&
    "shortName" in v &&
    typeof v.shortName === "string" &&
    "longName" in v &&
    typeof v.longName === "string"
  );
};

export const isSchemaVersion = (v: any): v is SchemaVersion => {
  return isTaggedNumber(
    (a0: any): a0 is "SchemaVersion" => a0 === "SchemaVersion"
  )(v);
};

export const isSurveyModuleSummary = (v: any): v is SurveyModuleSummary => {
  return (
    typeof v === "object" &&
    v != null &&
    "moduleId" in v &&
    isSurveyModuleId(v.moduleId) &&
    "names" in v &&
    isSurveyNames(v.names)
  );
};

export const isSurveyStatus = (v: any): v is SurveyStatus => {
  return (
    v === "draft" ||
    v === "complete" ||
    v === "approved" ||
    v === "review" ||
    v === "rejected"
  );
};

export const isSurveyF =
  <A>(isA: (a: any) => a is A) =>
  (v: any): v is SurveyF<A> => {
    return (
      typeof v === "object" &&
      v != null &&
      "id" in v &&
      isSurveyId(v.id) &&
      "moduleId" in v &&
      isSurveyModuleId(v.moduleId) &&
      "projectId" in v &&
      isProjectId(v.projectId) &&
      (!("teamId" in v) || isTeamId(v.teamId) || v.teamId === null) &&
      "created" in v &&
      isTimestamp(v.created) &&
      "updated" in v &&
      isTimestamp(v.updated) &&
      "surveyor" in v &&
      isUserRef(v.surveyor) &&
      "status" in v &&
      isSurveyStatus(v.status) &&
      "schemaVersion" in v &&
      isSchemaVersion(v.schemaVersion) &&
      "data" in v &&
      isA(v.data)
    );
  };

export const isSurveyMetadata = (v: any): v is SurveyMetadata => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("geometry" in v) || isGeometry(v.geometry) || v.geometry === null) &&
    (!("timestamp" in v) || isTimestamp(v.timestamp) || v.timestamp === null) &&
    "workspaceName" in v &&
    typeof v.workspaceName === "string" &&
    "projectName" in v &&
    typeof v.projectName === "string" &&
    (!("teamName" in v) ||
      typeof v.teamName === "string" ||
      v.teamName === null) &&
    (!("description" in v) ||
      typeof v.description === "string" ||
      v.description === null)
  );
};

export const isSurvey = (v: any): v is Survey => {
  return isSurveyF((a0: any): a0 is unknown => true)(v);
};

export const isSurveySummary = (v: any): v is SurveySummary => {
  return isSurveyF((a0: any): a0 is SurveyMetadata => isSurveyMetadata(a0))(v);
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
