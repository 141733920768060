import { checkExhausted } from "@cartographerio/util";
import { Schema } from "./type";

/** Render a schema to a string for debugging purposes. */
export function describeSchema(schema: Schema): string {
  switch (schema.type) {
    case "Boolean":
      return "Boolean";

    case "Integer":
      return "Integer";

    case "Number":
      return "Number";

    case "String":
      return "String";

    case "Uuid":
      return "Uuid";

    case "Timestamp":
      return "Timestamp";

    case "Geometry":
      return schema.geometryType == null
        ? "Geometry"
        : `Geometry(${schema.geometryType})`;

    case "Unknown":
      return "Unknown";

    case "Enum":
      return `Enum(${schema.values.join(",")})`;

    case "Nullable":
      return `Nullable(${describeSchema(schema.param)})`;

    case "Array":
      return `Array(${describeSchema(schema.param)})`;

    case "Tuple":
      return `Tuple(${schema.items.map(describeSchema).join(",")})`;

    case "Dict":
      return `Dict(${describeSchema(schema.key)},${describeSchema(
        schema.value
      )})`;

    case "Product":
      return `Product(${Object.entries(schema.fields)
        .map(([name, schema]) => `${name}=${describeSchema(schema)}`)
        .join(",")})`;

    case "Sum":
      return `Sum(${Object.entries(schema.products)
        .map(([name, schema]) => `${name}=${describeSchema(schema)}`)
        .join(",")})`;

    default:
      return checkExhausted(schema);
  }
}
