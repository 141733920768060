// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRtaCoarsestBedMaterial =
  | "BE"
  | "BO"
  | "CO"
  | "GP"
  | "SA"
  | "SI"
  | "CL";

const Bedrock: MrsRtaCoarsestBedMaterial = "BE";
const Boulder: MrsRtaCoarsestBedMaterial = "BO";
const Cobble: MrsRtaCoarsestBedMaterial = "CO";
const GravelPebble: MrsRtaCoarsestBedMaterial = "GP";
const Sand: MrsRtaCoarsestBedMaterial = "SA";
const Silt: MrsRtaCoarsestBedMaterial = "SI";
const Clay: MrsRtaCoarsestBedMaterial = "CL";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRtaCoarsestBedMaterialEnum = {
  ...createEnum("MrsRtaCoarsestBedMaterial", [
    [Bedrock, "Bedrock"],
    [Boulder, "Boulder"],
    [Cobble, "Cobble"],
    [GravelPebble, "Gravel/Pebble"],
    [Sand, "Sand"],
    [Silt, "Silt"],
    [Clay, "Clay"],
  ]),
  Bedrock,
  Boulder,
  Cobble,
  GravelPebble,
  Sand,
  Silt,
  Clay,
};

export default MrsRtaCoarsestBedMaterialEnum;
