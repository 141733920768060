// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusStudyAreaAccessRestriction =
  | "restricted"
  | "appropriate"
  | "extensive";

const Restricted: EcostatusStudyAreaAccessRestriction = "restricted";
const Appropriate: EcostatusStudyAreaAccessRestriction = "appropriate";
const Extensive: EcostatusStudyAreaAccessRestriction = "extensive";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusStudyAreaAccessRestrictionEnum = {
  ...createEnum("EcostatusStudyAreaAccessRestriction", [
    [Restricted, "Restricted"],
    [Appropriate, "Appropriate"],
    [Extensive, "Extensive"],
  ]),
  Restricted,
  Appropriate,
  Extensive,
};

export default EcostatusStudyAreaAccessRestrictionEnum;
