import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Email,
  QualificationRef,
  QualificationRegisterEntry,
  SearchResults,
  UserId,
  isQualificationRegisterEntry,
  isSearchResults,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";
import { PartialParams } from "../../params";
import { QualificationRegisterSearchOptions } from "./common";

const basePath = "/qualification/register/v1";

export function qualificationSearch(
  apiParams: ApiParams,
  qualification: QualificationRef,
  options: PartialParams<QualificationRegisterSearchOptions> = {}
): IO<SearchResults<QualificationRegisterEntry>> {
  return fetch
    .get({ apiParams, url: qualificationSearchUrl(qualification, options) })
    .flatMap(
      contentAs(
        "SearchResults<QualificationRegisterEntry>",
        isSearchResults(isQualificationRegisterEntry)
      )
    );
}

export function qualificationSearchUrl(
  qualification: QualificationRef,
  options: PartialParams<QualificationRegisterSearchOptions> = {}
): UrlParts {
  return {
    path: `${basePath}/qualification/${qualification}`,
    query: { ...options },
  };
}

export function userSearch(
  apiParams: ApiParams,
  user: UserId | Email
): IO<SearchResults<QualificationRegisterEntry>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/user/${user}` } })
    .flatMap(
      contentAs(
        "SearchResults<QualificationRegisterEntry>",
        isSearchResults(isQualificationRegisterEntry)
      )
    );
}

export function readOrFail(
  apiParams: ApiParams,
  entryId: string
): IO<QualificationRegisterEntry> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/entry/${entryId}` } })
    .flatMap(
      contentAs("QualificationRegisterEntry", isQualificationRegisterEntry)
    );
}

export function readOption(
  apiParams: ApiParams,
  entryId: string
): IO<Option<QualificationRegisterEntry>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/entry/${entryId}` } })
    .flatMap(
      optionalContentAs(
        "QualificationRegisterEntry",
        isQualificationRegisterEntry
      )
    );
}
