import {
  attachmentAttr,
  attributeGroup,
  booleanAttr,
  lookupBucket,
  MapId,
  MapSchema,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  surveyAttr,
  teamAttr,
  thresholdAttr,
  thresholdBucket,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

function waterQualityMap(mapId: MapId, title: string): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,
    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId as string),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId as string),
          attribution: [],
        }),
        defaultAttribute: "temperature",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "Survey",
            attributes: [
              teamAttr("teamId", "Team", {
                module: unsafeSurveyModuleId("thames21WaterQuality"),
              }),
              surveyAttr("surveyId", "Survey"),
              attachmentAttr("photographs", "Photographs"),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
            ],
          }),
          attributeGroup({
            label: title,
            attributes: [
              numberAttr({
                attributeId: "ph",
                label: "pH",
                buckets: [
                  rangeBucket(0, 1, marker.ph(0)),
                  rangeBucket(1, 2, marker.ph(1)),
                  rangeBucket(2, 3, marker.ph(2)),
                  rangeBucket(3, 4, marker.ph(3)),
                  rangeBucket(4, 5, marker.ph(4)),
                  rangeBucket(5, 6, marker.ph(5)),
                  rangeBucket(6, 7, marker.ph(6)),
                  rangeBucket(7, 8, marker.ph(7)),
                  rangeBucket(8, 9, marker.ph(8)),
                  rangeBucket(9, 10, marker.ph(9)),
                  rangeBucket(10, 11, marker.ph(10)),
                  rangeBucket(11, 12, marker.ph(11)),
                  rangeBucket(12, 13, marker.ph(12)),
                  rangeBucket(13, 14, marker.ph(13), undefined, true),
                ],
              }),
              numberAttr({
                attributeId: "temperature",
                label: "Temperature",
                unit: "C",
                buckets: [
                  rangeBucket(null, 3, marker.fromColor("#2e3090")),
                  rangeBucket(3, 6, marker.fromColor("#0f4ca0")),
                  rangeBucket(6, 9, marker.fromColor("#0865b1")),
                  rangeBucket(9, 12, marker.fromColor("#0c7cc4")),
                  rangeBucket(12, 15, marker.fromColor("#1094d8")),
                  rangeBucket(15, 18, marker.fromColor("#14aded")),
                  rangeBucket(18, 21, marker.fromColor("#15a9c2")),
                  rangeBucket(21, 24, marker.fromColor("#6ea59f")),
                  rangeBucket(24, 29, marker.fromColor("#ea8133")),
                  rangeBucket(29, null, marker.fromColor("#be2432")),
                ],
              }),
              thresholdAttr({
                attributeId: "turbidity",
                label: "Turbidity",
                unit: "NTU",
                buckets: [
                  thresholdBucket(0, marker.fromColor("#00acec")),
                  thresholdBucket(12, marker.fromColor("#00aad0")),
                  thresholdBucket(13, marker.fromColor("#24a8b9")),
                  thresholdBucket(14, marker.fromColor("#66a6a2")),
                  thresholdBucket(15, marker.fromColor("#8ba48d")),
                  thresholdBucket(17, marker.fromColor("#a9a178")),
                  thresholdBucket(19, marker.fromColor("#c49e60")),
                  thresholdBucket(21, marker.fromColor("#dd9948")),
                  thresholdBucket(25, marker.fromColor("#f69532")),
                  thresholdBucket(30, marker.fromColor("#e28b32")),
                  thresholdBucket(40, marker.fromColor("#cf8033")),
                  thresholdBucket(50, marker.fromColor("#be7633")),
                  thresholdBucket(75, marker.fromColor("#ae6d32")),
                  thresholdBucket(100, marker.fromColor("#9e6531")),
                  thresholdBucket(150, marker.fromColor("#905d2f")),
                  thresholdBucket(200, marker.fromColor("#82552e")),
                  thresholdBucket(240, marker.fromColor("#754d2c")),
                ],
              }),
              thresholdAttr({
                attributeId: "dissolvedOxygen",
                label: "Dissolved Oxygen",
                unit: "ppm",
                buckets: [
                  thresholdBucket(0, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.2)),
                  thresholdBucket(2, marker.quality(0.4)),
                  thresholdBucket(4, marker.quality(0.5)),
                  thresholdBucket(6, marker.quality(0.7)),
                  thresholdBucket(8, marker.quality(0.8)),
                  thresholdBucket(10, marker.quality(1.0)),
                ],
              }),
              booleanAttr({
                attributeId: "coliforms",
                label: "Coliforms",
                buckets: [
                  lookupBucket(false, marker.quality(0.8), "Absent (good)"),
                  lookupBucket(true, marker.quality(0.2), "Present (bad)"),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
}

export const Thames21WaterQuality = waterQualityMap(
  unsafeMapId("thames21WaterQuality"),
  "Water Quality"
);

export const Thames21ThamesWaterQuality = waterQualityMap(
  unsafeMapId("thames21ThamesWaterQuality"),
  "Thames Water Quality"
);
