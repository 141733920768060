import {
  SelectOption,
  topoExpr,
  attachmentField,
  bounds,
  checkbox,
  columns2,
  customRule,
  form,
  minValue,
  multiSelect,
  numberField,
  page,
  pointField,
  required,
  requiredIf,
  requiredIfV2,
  section,
  select,
  text,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { isArrayOf, isNullable } from "@cartographerio/guard";
import {
  WrtAmmoniaEnum,
  WrtRainfallEnum,
  WrtWestcountryCsiBankVegetationEnum,
  WrtWestcountryCsiChannelSubstrateEnum,
  WrtWestcountryCsiFlowImpedanceEnum,
  WrtWestcountryCsiFlowLevelEnum,
  WrtWestcountryCsiInvasivePlantEnum,
  WrtWestcountryCsiLandUseEnum,
  WrtWestcountryCsiNitrateEnum,
  WrtWestcountryCsiPollutionEvidenceEnum,
  WrtWestcountryCsiPollutionSourceEnum,
  WrtWestcountryCsiWaterBodyTypeEnum,
  WrtWestcountryCsiWaterLevelEnum,
  WrtWestcountryCsiWildlifeEnum,
} from "@cartographerio/inventory-enums";
import { isArray, isNumber } from "lodash";
import { outdent } from "outdent";

const LOW_TURBIDITY_REGEX = /^<12$/i;
const NUMERIC_TURBIDITY_REGEX = /^[0-9]+$/i;
const HIGH_TURBIDITY_REGEX = /^>240$/i;

function option(value: number, units: string): SelectOption<number> {
  return { value, label: `${value} ${units}` };
}

const dryRunUnchecked = topoExpr<boolean>(env => {
  return env.getAbsolute(["data", "riverChannel", "dryRun"]) !== true;
});

const additionalTestingKitChecked = topoExpr<boolean>(env => {
  return (
    // This flag was added late in CSI's life.
    // The absence of data in the survey equates to `true` so we use `!== false` instead of `=== true`.
    env.getAbsolute(["data", "riverChannel", "additionalTestingKit"]) !== false
  );
});

export function appendUnit(unit: string) {
  return function <A extends string>(option: SelectOption<A>): SelectOption<A> {
    return { ...option, label: `${option.label} ${unit}` };
  };
}

export default form({
  title: "Westcountry CSI",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: "Survey Details",
          path: [],
          blocks: [
            columns2(
              select({
                label: "Dynamic Risk Assessment Completed/Reviewed?",
                path: ["data", "details", "dynamicRiskAssessment"],
                options: [
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ],
                required: required("info"),
              })
            ),
            columns2(
              vstack(
                textField({
                  label: "River/Stream Name",
                  path: ["data", "details", "river"],
                  required: required("error"),
                }),
                textField({
                  label: "Location Name",
                  path: ["data", "details", "site"],
                  required: required("error"),
                }),
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                  defaultValue: "now",
                  required: required("error"),
                  help: "When was the data collected in the field?",
                })
              ),
              pointField({
                label: "Location",
                path: ["data", "details", "location"],
                required: required("error"),
                help: "Location extent of the survey.",
              })
            ),
            select({
              label: "Type of Water Body (select one)",
              path: ["data", "details", "waterBodyType"],
              options: WrtWestcountryCsiWaterBodyTypeEnum.entries,
              required: required("error"),
              appearance: "radiogroup",
              columns: 3,
            }),
            textField({
              label: "Other Water Body Type",
              path: ["data", "details", "otherWaterBodyType"],
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "You should describe other water body type.",
                  otherTest: topoExpr(env =>
                    env
                      .getAbsoluteAs(
                        ["data", "details", "waterBodyType"],
                        isNullable(WrtWestcountryCsiWaterBodyTypeEnum.isValue)
                      )
                      .map(
                        value =>
                          value === WrtWestcountryCsiWaterBodyTypeEnum.Other
                      )
                      .getOrElse(() => false)
                  ),
                }),
              ],
              help: 'If you selected "Other", please describe.',
            }),
            select({
              label: "Rain in Previous 24 Hours (select one)",
              path: ["data", "details", "recentRain"],
              options: WrtRainfallEnum.entries,
              required: required("error"),
              appearance: "radiogroup",
              columns: 3,
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          help: outdent`
          📷 Please take up to four photos to go with your survey data. One photo
          should be taken from the same place on each visit and will capture some
          part of the waterbody and surroundings. OPTIONAL - one of these photos can be your risk assessment.

          Other features where photos would be useful are highlighted with a camera icon.
          `,
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "details", "photographs"],
              fullWidth: true,
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "General Ecosystem Observations",
          path: [],
          blocks: [
            multiSelect({
              label: "Land Use Nearby (select all that apply)",
              path: ["data", "ecosystem", "landUse"],
              options: WrtWestcountryCsiLandUseEnum.entries,
              required: required("error"),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            textField({
              label: "Other Dominant Land Use Nearby",
              path: ["data", "ecosystem", "otherLandUse"],
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "You should describe the other land use.",
                  otherTest: topoExpr(env =>
                    env
                      .getAbsoluteAs(
                        ["data", "ecosystem", "landUse"],
                        isArrayOf(WrtWestcountryCsiLandUseEnum.isValue)
                      )
                      .map(array =>
                        array.includes(WrtWestcountryCsiLandUseEnum.Other)
                      )
                      .getOrElse(() => false)
                  ),
                }),
              ],
              help: 'If you selected "Other", please describe.',
            }),
            multiSelect({
              label:
                "Dominant Vegetation Cover on River Bank (select all that apply)",
              path: ["data", "ecosystem", "bankVegetation"],
              options: WrtWestcountryCsiBankVegetationEnum.entries,
              required: required("error"),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            textField({
              label: "Other Dominant Vegetation Cover on River Bank",
              path: ["data", "ecosystem", "otherBankVegetation"],
              customRules: [
                requiredIfV2({
                  level: "info",
                  message: "You should describe the other vegetation.",
                  otherTest: topoExpr(env =>
                    env
                      .getAbsoluteAs(
                        ["data", "ecosystem", "bankVegetation"],
                        isArrayOf(WrtWestcountryCsiBankVegetationEnum.isValue)
                      )
                      .map(array =>
                        array.includes(
                          WrtWestcountryCsiBankVegetationEnum.Other
                        )
                      )
                      .getOrElse(() => false)
                  ),
                }),
              ],
              help: 'If you selected "Other", please describe.',
            }),
            multiSelect({
              label: "📷 Problem Plant Species (select all that apply)",
              path: ["data", "ecosystem", "invasivePlants"],
              options: WrtWestcountryCsiInvasivePlantEnum.entries,
              noneOption: WrtWestcountryCsiInvasivePlantEnum.None,
              required: required("error"),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            textField({
              label: "Other Problem Plant Species",
              path: ["data", "ecosystem", "otherInvasivePlants"],
              customRules: [
                requiredIf({
                  level: "info",
                  message: "You should describe the other species.",
                  otherPath: ["data", "ecosystem", "invasivePlants"],
                  otherTest: value =>
                    isArray(value)
                      ? value.includes(WrtWestcountryCsiInvasivePlantEnum.Other)
                      : true,
                }),
              ],
              help: 'If you selected "Other", please describe.',
            }),
            multiSelect({
              label: "📷 Wildlife Spotted (select all that apply)",
              path: ["data", "ecosystem", "wildlife"],
              options: WrtWestcountryCsiWildlifeEnum.entries,
              noneOption: WrtWestcountryCsiWildlifeEnum.None,
              required: required("error"),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            textField({
              label: "Other Wildlife Spotted",
              path: ["data", "ecosystem", "otherWildlife"],
              customRules: [
                requiredIf({
                  level: "info",
                  message: "You should describe the other wildlife.",
                  otherPath: ["data", "ecosystem", "wildlife"],
                  otherTest: value =>
                    isArray(value)
                      ? value.includes(WrtWestcountryCsiWildlifeEnum.Other)
                      : true,
                }),
              ],
              help: 'If you selected "Other", please describe.',
            }),
          ],
        }),
        section({
          title: "Evidence of Pollution",
          path: [],
          blocks: [
            multiSelect({
              label: "📷 Pollution Sources (select all that apply)",
              path: ["data", "pollution", "pollutionSources"],
              options: WrtWestcountryCsiPollutionSourceEnum.entries,
              noneOption: WrtWestcountryCsiPollutionSourceEnum.None,
              required: required("error"),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            textField({
              label: "Other Pollution Sources",
              path: ["data", "pollution", "otherPollutionSources"],
              customRules: [
                requiredIf({
                  level: "info",
                  message: "You should describe the other source.",
                  otherPath: ["data", "pollution", "pollutionSources"],
                  otherTest: value =>
                    isArray(value)
                      ? value.includes(
                          WrtWestcountryCsiPollutionSourceEnum.Other
                        )
                      : true,
                }),
              ],
              help: 'If you selected "Other", please describe.',
            }),
            multiSelect({
              label: "📷 Evidence of Recent Pollution (select all that apply)",
              path: ["data", "pollution", "pollutionEvidence"],
              options: WrtWestcountryCsiPollutionEvidenceEnum.entries,
              noneOption: WrtWestcountryCsiPollutionEvidenceEnum.None,
              required: required("error"),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            textField({
              label: "Other Evidence of Recent Pollution",
              path: ["data", "pollution", "otherPollutionEvidence"],
              customRules: [
                requiredIf({
                  level: "info",
                  message: "You should describe the other evidence.",
                  otherPath: ["data", "pollution", "pollutionEvidence"],
                  otherTest: value =>
                    isArray(value)
                      ? value.includes(
                          WrtWestcountryCsiPollutionEvidenceEnum.Other
                        )
                      : true,
                }),
              ],
              help: 'If you selected "Other", please describe.',
            }),
          ],
        }),
        section({
          title: "River Channel Observations",
          path: [],
          blocks: [
            columns2(
              numberField({
                label: "Estimated Width at Water Level",
                path: ["data", "riverChannel", "estimatedWidth"],
                units: "m",
                decimalPlaces: 2,
                required: required("error"),
                bounds: minValue(0),
              }),
              numberField({
                label: "Estimated Average Water Depth",
                path: ["data", "riverChannel", "estimatedDepth"],
                units: "m",
                decimalPlaces: 2,
                required: required("error"),
                bounds: minValue(0),
              }),
              select({
                label: "Flow Conditions",
                path: ["data", "riverChannel", "waterFlow"],
                options: WrtWestcountryCsiFlowLevelEnum.entries,
                required: required("error"),
                help: outdent`
                "Steady" is walking speed, "Surging" is faster,
                and "Slow" is slower than walking speed.
                `,
              }),
              select({
                label: "Water Level",
                path: ["data", "riverChannel", "waterLevel"],
                options: WrtWestcountryCsiWaterLevelEnum.entries,
                required: required("error"),
                help: outdent`
                  You can find clues to previous water levels
                  by looking for "trash" lines on the bank.
                  `,
              })
            ),
            multiSelect({
              label: "📷 Obstacles to Fish or Flow (select all that apply)",
              path: ["data", "riverChannel", "flowImpedance"],
              options: WrtWestcountryCsiFlowImpedanceEnum.entries,
              noneOption: WrtWestcountryCsiFlowImpedanceEnum.None,
              required: required("error"),
              help: outdent`
                  Water and fish need to move freely.
                  Is there an obstruction in the channel (within 50m)
                  which might stop or inhibit natural flow or fish movement?
                  `,
              appearance: "checkboxgroup",
              columns: 2,
            }),
            select({
              label: "Predominant Substrate (select one)",
              path: ["data", "riverChannel", "predominateSubstrate"],
              options: WrtWestcountryCsiChannelSubstrateEnum.entries,
              required: required("error"),
              appearance: "radiogroup",
              columns: 2,
            }),
            textField({
              label: "Other Predominant Substrate",
              path: ["data", "riverChannel", "otherPredominantSubstrate"],
              customRules: [
                requiredIf({
                  level: "info",
                  message: "You should describe the other substrate.",
                  otherPath: ["data", "riverChannel", "predominateSubstrate"],
                  otherTest: value =>
                    value === WrtWestcountryCsiChannelSubstrateEnum.Other,
                }),
              ],
              help: 'If you selected "Other", please describe.',
            }),
          ],
        }),
        section({
          title: "Water Quality Measurements",
          path: [],
          blocks: [
            checkbox({
              label: "Dry Run",
              path: ["data", "riverChannel", "dryRun"],
              checkboxLabel: "Is this survey a dry run?",
            }),
            text({
              appearance: "help",
              visible: dryRunUnchecked,
              markdown: outdent`
                Enter your measurements below.
                If you are unable to take any measurement,
                for example due to poor conditions or faulty kit,
                please leave the relevant field blank:
                `,
            }),
            columns2(
              numberField({
                label: "Temperature",
                path: ["data", "riverChannel", "temperature"],
                units: "C",
                decimalPlaces: 1,
                bounds: bounds(0, 100),
                visible: dryRunUnchecked,
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message:
                      "Enter a value if you were able to take this measurement.",
                    otherTest: dryRunUnchecked,
                  }),
                ],
              }),
              numberField({
                label: "Total Dissolved Solids",
                path: ["data", "riverChannel", "totalDissolvedSolids"],
                units: "ppm",
                decimalPlaces: 1,
                bounds: minValue(0),
                visible: dryRunUnchecked,
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message:
                      "Enter a value if you were able to take this measurement.",
                    otherTest: dryRunUnchecked,
                  }),
                  customRule({
                    level: "error",
                    triggerWhen: topoExpr(env => {
                      const value = env
                        .getFocusedAs(isNullable(isNumber))
                        .getOrElse(() => null);

                      return value != null && Math.trunc(value) !== value;
                    }),
                    message:
                      "Make sure you are using the correct units - TDS is a whole number.",
                  }),
                ],
              }),
              textField({
                label: "Turbidity",
                path: ["data", "riverChannel", "turbidity"],
                units: "NTU",
                defaultValue: null,
                visible: dryRunUnchecked,
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message:
                      "Enter a value if you were able to take this measurement.",
                    otherTest: dryRunUnchecked,
                  }),
                  customRule({
                    level: "error",
                    message: `Enter a number from 12 to 240, "<12", or ">240".`,
                    triggerWhen: topoExpr(env => {
                      const value = env.getFocused();

                      if (typeof value === "string") {
                        if (NUMERIC_TURBIDITY_REGEX.test(value)) {
                          const num = parseInt(value, 10);
                          return num < 12 || num > 240;
                        } else {
                          return (
                            !LOW_TURBIDITY_REGEX.test(value) &&
                            !HIGH_TURBIDITY_REGEX.test(value)
                          );
                        }
                      } else if (value == null) {
                        return false; // ok
                      } else {
                        return true; // not ok
                      }
                    }),
                  }),
                ],
                help: outdent`
                  Enter a turbidity reading in the range 12 to 240,
                  or enter "<12" or ">240" if the turbidity is
                  outside the readable range of the Secchi tube.
                  `,
              }),
              select({
                label: "Phosphate (PO4)",
                path: ["data", "riverChannel", "phosphate"],
                options: [
                  option(0, "ppb"),
                  option(100, "ppb"),
                  option(200, "ppb"),
                  option(300, "ppb"),
                  option(500, "ppb"),
                  option(1000, "ppb"),
                  option(2500, "ppb"),
                ],
                help: outdent`
                  Bend strip and place in test tube cap.
                  Replace cap and invert slowly 5 times.
                  **REMOVE CAP AND TEST STRIP**
                  and compare colour through tube to colour chart.
                  `,
                visible: dryRunUnchecked,
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message:
                      "Enter a value if you were able to take this measurement.",
                    otherTest: dryRunUnchecked,
                  }),
                ],
              })
            ),
          ],
        }),
        section({
          title: "Optional Water Quality Measurements",
          path: [],
          help: outdent`
          **⚠️ EXPERIMENTAL** - Only complete this section
          if you have been allocated additional testing kit
          and a Westcountry CSI administrator has asked you to do so.
          `,
          blocks: [
            checkbox({
              label: "Additional Testing Kit?",
              path: ["data", "riverChannel", "additionalTestingKit"],
              checkedWhenNull: true,
              checkboxLabel: "Have you been allocated additional testing kit?",
            }),
            columns2(
              numberField({
                label: "pH",
                path: ["data", "riverChannel", "ph"],
                visible: additionalTestingKitChecked,
                decimalPlaces: 1,
                bounds: bounds(0, 14),
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message:
                      "Enter a value if you were able to take this measurement.",
                    otherTest: additionalTestingKitChecked,
                  }),
                ],
              }),
              select({
                label: "Nitrate (NO3)",
                path: ["data", "riverChannel", "nitrate"],
                options: WrtWestcountryCsiNitrateEnum.entries.map(
                  appendUnit("ppm")
                ),
                visible: additionalTestingKitChecked,
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message:
                      "Enter a value if you were able to take this measurement.",
                    otherTest: additionalTestingKitChecked,
                  }),
                ],
                help: outdent`
                  Immerse strip for **2 seconds**.
                  Remove with pad face up for **60 seconds** and compare to colour chart.
                  **DO NOT SHAKE OFF EXCESS WATER.**
                  `,
              }),
              select({
                label: "Ammonia (NH3)",
                path: ["data", "riverChannel", "ammonia"],
                options: WrtAmmoniaEnum.entries.map(appendUnit("ppm")),
                visible: additionalTestingKitChecked,
                customRules: [
                  requiredIfV2({
                    level: "info",
                    message:
                      "Enter a value if you were able to take this measurement.",
                    otherTest: additionalTestingKitChecked,
                  }),
                ],
                help: outdent`
                  Immerse strip for **5 seconds**.
                  Remove with pad face up for **60 seconds** and compare to colour chart.
                  **DO NOT SHAKE OFF EXCESS WATER.**
                  `,
              })
            ),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 5,
            }),
          ],
        }),
      ],
    }),
  ],
});
