import { unsafeTagNumber } from "@cartographerio/util";
import { SchemaVersion } from "./types.generated";

export function unsafeSchemaVersion(num: number): SchemaVersion {
  return unsafeTagNumber<"SchemaVersion">(num);
}

export const initialSchemaVersion = unsafeSchemaVersion(1);

export function incrememtSchemaVersion(version: SchemaVersion): SchemaVersion {
  return unsafeSchemaVersion(version + 1);
}
