// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessHerpetofaunaSpecies = "CommonFrog" | "GrassSnake";

const CommonFrog: NeLakeNaturalnessHerpetofaunaSpecies = "CommonFrog";
const GrassSnake: NeLakeNaturalnessHerpetofaunaSpecies = "GrassSnake";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessHerpetofaunaSpeciesEnum = {
  ...createEnum("NeLakeNaturalnessHerpetofaunaSpecies", [
    [CommonFrog, "Common frog (Rana temporaria)"],
    [GrassSnake, "Grass snake (Natrix natrix)"],
  ]),
  CommonFrog,
  GrassSnake,
};

export default NeLakeNaturalnessHerpetofaunaSpeciesEnum;
