import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { AdminNote, ApiParams, WorkspaceRef } from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type AdminNotesKey =
  | ["admin", "notes"]
  | ["admin", "notes", "v1", "readOrNull", WorkspaceRef]
  | ["admin", "notes", "v1", "readOrBlank", WorkspaceRef]
  | ["admin", "notes", "v1", "readOpt", WorkspaceRef];

export function blankAdminNote(): AdminNote {
  return { text: "" };
}

export function readOrNull(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<AdminNote | null, AdminNotesKey> {
  return {
    queryKey: ["admin", "notes", "v1", "readOrNull", workspaceRef],
    queryFn: () =>
      endpoints.admin.notes.v1
        .readOption(apiParams, workspaceRef)
        .map(opt => opt.getOrNull())
        .unsafeRun(),
  };
}

export function readOrBlank(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<AdminNote | null, AdminNotesKey> {
  return {
    queryKey: ["admin", "notes", "v1", "readOrBlank", workspaceRef],
    queryFn: () =>
      endpoints.admin.notes.v1
        .readOption(apiParams, workspaceRef)
        .map(opt => opt.getOrElse(blankAdminNote))
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  note: AdminNote
): IO<AdminNote> {
  return endpoints.admin.notes.v1
    .save(apiParams, workspaceRef, note)
    .tap(_ => queryClient.invalidateQueries(["admin", "notes"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<void> {
  return endpoints.admin.notes.v1
    .remove(apiParams, workspaceRef)
    .tap(_ => queryClient.invalidateQueries(["admin", "notes"]));
}
