import {
  attributeGroup,
  lineLayer,
  lookupBucket,
  MapLayer,
  MapLayerId,
  marker,
  MarkerStyle,
  remoteTileSource,
  standardAttribution,
  standardBuckets,
  stringAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { MapBaseEnum } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export function publishedChalkRiversLayer(
  layerId: MapLayerId,
  title: string,
  highCertaintyMarker: MarkerStyle,
  lowCertaintyMarker: MarkerStyle
): MapLayer {
  return lineLayer({
    layerId,
    title,
    source: remoteTileSource({
      url: "mapbox://cartographerio.ne_chalk_rivers_processed",
      sourceLayer: "default",
      attribution: [
        standardAttribution({
          what: "Provisional Chalk Rivers Data",
          who: "Natural England",
          from: "June 2020",
          url: "https://priorityhabitats.org",
        }),
      ],
    }),
    attributes: [
      attributeGroup({
        attributes: [
          stringAttr({
            attributeId: "highOnly",
            propertyName: "category",
            label: "High Certainty Only",
            buckets: standardBuckets([
              lookupBucket("H", highCertaintyMarker, "High Certainty"),
            ]),
            showDistribution: false,
          }),
          // stringAttr({
          //   attributeId: "lowOnly",
          //   propertyName: "category",
          //   label: "Low Certainty Only",
          //   buckets: standardBuckets([
          //     lookupBucket("L", lowCertaintyMarker, "Low Certainty"),
          //   ]),
          //   showDistribution: false,
          // }),
          stringAttr({
            attributeId: "highAndLow",
            propertyName: "category",
            label: "All Certainties",
            buckets: standardBuckets([
              lookupBucket("H", highCertaintyMarker, "High Certainty"),
              lookupBucket("L", lowCertaintyMarker, "Low Certainty"),
            ]),
            showDistribution: false,
          }),
        ],
      }),
    ],
  });
}

export const proposedChalkRiversModificationsLayer = lineLayer({
  layerId: unsafeMapLayerId("neChalkRivers"),
  title: "Proposed Chalk Rivers Additions/Deletions",
  source: cartographerSourceWithDefaults({
    layerId: unsafeMapLayerId("neChalkRivers"),
    simplifyZoom: 10,
    attribution: [],
  }),
  attributes: [
    attributeGroup({
      attributes: [
        stringAttr({
          attributeId: "modification",
          label: "Modification Type",
          buckets: standardBuckets([
            lookupBucket("Addition", marker.quality(0.75)),
            lookupBucket("Deletion", marker.quality(0.35)),
          ]),
        }),
      ],
    }),
  ],
});

export const NeChalkRivers = mapSchemaWithDefaults({
  mapId: unsafeMapId("neChalkRivers"),
  title: "Chalk Rivers",
  defaultBase: MapBaseEnum.Satellite,
  layers: [
    publishedChalkRiversLayer(
      unsafeMapLayerId("chalkRivers"),
      "Published Chalk Rivers Map",
      marker.fromColor("hsl(300, 100%, 70%)"),
      marker.fromColor("hsl(300, 100%, 40%)")
    ),
    proposedChalkRiversModificationsLayer,
  ],
});
