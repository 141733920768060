import { Path } from "@cartographerio/topo-core";
import {
  attachmentField,
  Block,
  checkbox,
  featureFieldExternalMapStyle,
  Field,
  form,
  grid,
  multiSelect,
  nearestFeatureField,
  otherSpecify,
  page,
  section,
  select,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { stringAttr } from "@cartographerio/topo-map";
import { isArrayOf } from "@cartographerio/guard";
import {
  NeNameSourceEnum,
  NeNaturalnessClassEnum,
  NeNaturalnessConfidenceClassEnum,
  NeNaturalnessInvasivePlantSpeciesEnum,
  NeRiverNaturalnessBiologicalAssessmentFormEnum,
  NeRiverNaturalnessBirdSpeciesEnum,
  NeRiverNaturalnessBryophyteSpeciesEnum,
  NeRiverNaturalnessChemicalAssessmentFormEnum,
  NeRiverNaturalnessFeatureEnum,
  NeRiverNaturalnessFernSpeciesEnum,
  NeRiverNaturalnessFishSpeciesEnum,
  NeRiverNaturalnessHerpetofaunaSpeciesEnum,
  NeRiverNaturalnessHigherPlantSpeciesEnum,
  NeRiverNaturalnessHydrologicalAssessmentFormEnum,
  NeRiverNaturalnessInvertebrateSpeciesEnum,
  NeRiverNaturalnessMammalSpeciesEnum,
  NeRiverNaturalnessPhysicalAssessmentFormEnum,
  NeYesNoUncertainEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

export function naturalnessSection(props: {
  title: string;
  path: Path;
  blocks: Block[];
}): Block {
  const { title, path, blocks } = props;
  return section({
    title,
    path,
    help: outdent`
    Choose the class that most closely reflects the naturalness of the site.
    Describe how confident you are in your assessment of each category.

    See the [guidance document](https://priorityhabitats.org/lake-naturalness-form-guidance)
    for detailed guidance on each category and option.
    Record any _other_ options in the notes field below.
    `,
    blocks: [
      grid({
        columns: 2,
        blocks: [
          select({
            label: "Naturalness Class",
            path: ["naturalnessClass"],
            options: NeNaturalnessClassEnum.entries,
          }),
          select({
            label: "Confidence Class",
            path: ["confidenceClass"],
            options: NeNaturalnessConfidenceClassEnum.entries,
          }),
          ...blocks,
        ],
      }),
    ],
  });
}

interface NeRiverSiteInfoSectionOptions {
  styleUrl?: string;
  mapAttribution?: string;
  extraHelp?: string;
  showChalkAttribute?: boolean;
  showCustomAttributeFields?: boolean;
  extraFields?: Field[];
}

export function neRiverSiteInfoSection(
  opts: NeRiverSiteInfoSectionOptions = {}
): Block {
  const {
    styleUrl = "mapbox://styles/cartographerio/cl0jqz0b9000515o3mmodeuvk",
    mapAttribution = '<a href="https://data.gov.uk/dataset/b71a3a95-9cbb-4f21-9d18-90ff1ce45b14/detailed-river-network-line-feature-class-afa036">&copy; Environment Agency</a>',
    extraHelp,
    showChalkAttribute = false,
    showCustomAttributeFields = true,
    extraFields = [],
  } = opts;

  return section({
    title: "Watercourse Location",
    path: [],
    help: [
      outdent`
      Click on the map or enter a 10-digit NGR or GPS coordinate.
      The nearest segment of river will be highlighted automatically.
      `,
      ...(extraHelp == null ? [] : [extraHelp]),
    ].join("\n\n"),
    blocks: [
      grid({
        columns: 2,
        blocks: [
          nearestFeatureField({
            label: null,
            path: ["data", "site", "picked"],
            fullWidth: true,
            mapOptions: {
              mapStyle: featureFieldExternalMapStyle({
                styleUrl,
                sourceId: "ne_river_sites",
                primaryKey: "drn_id",
                selectableLayer: { id: "ne_river_sites", type: "line" },
                selectedLayers: [
                  { id: "ne_river_sites_selected", type: "line" },
                ],
              }),
              selectMinZoom: 13,
              attribution: [mapAttribution],
              attributes: [
                stringAttr({
                  attributeId: "rivername",
                  label: "Watercourse Name",
                  buckets: "auto",
                }),
                stringAttr({
                  attributeId: "drn_id",
                  label: "Digital Stretch ID",
                  buckets: "auto",
                }),
                stringAttr({
                  attributeId: "catchid",
                  label: "WFD Catchment ID",
                  buckets: "auto",
                }),
                stringAttr({
                  attributeId: "wb_id",
                  label: "WFD Waterbody ID",
                  buckets: "auto",
                }),
                ...(showChalkAttribute
                  ? [
                      stringAttr({
                        attributeId: "chalk",
                        label: "Current Chalk Certainty",
                        buckets: "auto",
                      }),
                    ]
                  : []),
              ],
            },
          }),
          ...(showCustomAttributeFields
            ? [
                textField({
                  label: "Custom Watercourse Name",
                  path: ["data", "site", "customReachName"],
                  help: outdent`
                If you need to you can manually specify a name here.
                `,
                }),
                select({
                  label: "Watercourse Name Source",
                  path: ["data", "site", "reachNameSource"],
                  options: NeNameSourceEnum.entries,
                  help: outdent`
                Indicate the source if you manually entered a watercourse name above.
                `,
                }),
              ]
            : []),
          ...extraFields,
        ],
      }),
    ],
  });
}

export default form({
  title: "River/Stream Naturalness",
  pages: [
    page({
      title: null,
      path: [],
      help: outdent`
      For further information on completing this assessment,
      please see the [guidance document](https://priorityhabitats.org/river-naturalness-form-guidance)
      `,
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                timestampField({
                  label: "Date and Time of Survey",
                  path: ["data", "recorded"],
                }),
              ],
            }),
          ],
        }),
        neRiverSiteInfoSection({
          extraFields: [
            select({
              label: "Representative?",
              path: ["data", "naturalness", "representative"],
              options: NeYesNoUncertainEnum.entries,
              help: outdent`
              Is the surveyed reach representative of the watercourse
              as defined on the map?

              This may be difficult to judge
              if you only have access to part of the watercourse.
              Choose Uncertain if you are not in a position to judge this.
              `,
            }),
          ],
        }),
        naturalnessSection({
          title: "Physical Naturalness",
          path: ["data", "naturalness", "physical"],
          blocks: [
            multiSelect({
              label: "Forms of Assessment",
              path: ["formsOfAssessment"],
              options: NeRiverNaturalnessPhysicalAssessmentFormEnum.entries,
            }),
          ],
        }),
        naturalnessSection({
          title: "Hydrological Naturalness",
          path: ["data", "naturalness", "hydrological"],
          blocks: [
            multiSelect({
              label: "Forms of Assessment",
              path: ["formsOfAssessment"],
              options: NeRiverNaturalnessHydrologicalAssessmentFormEnum.entries,
            }),
          ],
        }),
        naturalnessSection({
          title: "Chemical Naturalness",
          path: ["data", "naturalness", "chemical"],
          blocks: [
            multiSelect({
              label: "Forms of Assessment",
              path: ["formsOfAssessment"],
              options: NeRiverNaturalnessChemicalAssessmentFormEnum.entries,
            }),
          ],
        }),
        naturalnessSection({
          title: "Biological Naturalness",
          path: ["data", "naturalness", "biological"],
          blocks: [
            vstack(
              multiSelect({
                label: "Forms of Assessment",
                path: ["formsOfAssessment"],
                options: NeRiverNaturalnessBiologicalAssessmentFormEnum.entries,
              }),
              multiSelect({
                label: "Invasive Plant Species",
                path: ["invasivePlantSpecies"],
                options: NeNaturalnessInvasivePlantSpeciesEnum.entries.map(
                  ({ value, label }) => ({
                    value,
                    label:
                      value === "Other" ? `${label} (please specify)` : label,
                  })
                ),
              }),
              otherSpecify({
                level: "info",
                label: "Other Invasive Plant Species",
                thisPath: ["otherInvasivePlantSpecies"],
                thatPath: ["invasivePlantSpecies"],
                test: (value: unknown) =>
                  isArrayOf(NeNaturalnessInvasivePlantSpeciesEnum.isValue)(
                    value
                  ) &&
                  value.includes(NeNaturalnessInvasivePlantSpeciesEnum.Other),
                visible: "auto",
              })
            ),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          help: outdent`
          Provide a general description of the site in relation to each naturalness component,
          drawing attention to any particular features of interest or impacts of concern.

          If you selected any _other_ forms of assessment above, please record them here.
          `,
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 8,
            }),
          ],
        }),
        section({
          title: "Intermittent Flow",
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                checkbox({
                  label: "Intermittent Flow",
                  checkboxLabel:
                    "Does the site have a section containing no water (i.e. a section with intermittent flow)?",
                  path: ["data", "naturalness", "intermittentFlow"],
                }),
                select({
                  label: "Intermittent Flow Natural?",
                  path: ["data", "naturalness", "intermittentFlowNatural"],
                  options: NeYesNoUncertainEnum.entries,
                  help: outdent`
                  Is the intermittent flow likely to be a natural phenomenon?
                  (It could alternatively be the result of abstraction.)
                  `,
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Photographs and Other Files",
          path: [],
          help: outdent`
          Take at least one photo of the general physical character of the channel and riparian zone.
          Other photos can focus on particular features of the watercourse.
          You may want to attach a file providing more detail about the site,
          such as a detailed site description or a species list.
          `,
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Habitat Features",
          path: [],
          help: outdent`
           Record any notable habitat features at the site.
           There is a photographic library of features in the
           [guidance document](https://priorityhabitats.org/river-naturalness-form-guidance) to help you.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                multiSelect({
                  label: null,
                  path: ["data", "habitatFeatures"],
                  options: NeRiverNaturalnessFeatureEnum.entries,
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Species of Interest",
          path: ["data", "species"],
          help: outdent`
          If you happen to see any of the species below please tick the appropriate box.
          Please refer to the [guidance document](https://priorityhabitats.org/river-naturalness-form-guidance)
          for more information about recording rare and threatened species,
          and use [iRecord](https://www.brc.ac.uk/irecord/)
          to make sure important sightings are captured in national species inventories.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                multiSelect({
                  label: "Mammals",
                  path: ["mammals"],
                  options: NeRiverNaturalnessMammalSpeciesEnum.entries,
                }),
                multiSelect({
                  label: "Birds",
                  path: ["birds"],
                  options: NeRiverNaturalnessBirdSpeciesEnum.entries,
                }),
                multiSelect({
                  label: "Fish",
                  path: ["fish"],
                  options: NeRiverNaturalnessFishSpeciesEnum.entries,
                }),
                multiSelect({
                  label: "Herpetofauna",
                  path: ["herpetofauna"],
                  options: NeRiverNaturalnessHerpetofaunaSpeciesEnum.entries,
                }),
                multiSelect({
                  label: "Invertebrates",
                  path: ["invertebrates"],
                  options: NeRiverNaturalnessInvertebrateSpeciesEnum.entries,
                }),
                multiSelect({
                  label: "Flowering Plants",
                  path: ["higherPlants"],
                  options: NeRiverNaturalnessHigherPlantSpeciesEnum.entries,
                }),
                multiSelect({
                  label: "Ferns",
                  path: ["ferns"],
                  options: NeRiverNaturalnessFernSpeciesEnum.entries,
                }),
                multiSelect({
                  label: "Bryophytes",
                  path: ["bryophytes"],
                  options: NeRiverNaturalnessBryophyteSpeciesEnum.entries,
                }),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
