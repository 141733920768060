import { findAndMap } from "@cartographerio/util";
import { RuleBuilder } from "./types";

export function ruleSequence(...builders: RuleBuilder[]): RuleBuilder {
  return props => {
    const rules = builders.map(builder => builder(props));
    return survey =>
      findAndMap(rules, rule => {
        const messages = rule(survey);
        return messages.length > 0 ? messages : null;
      }) ?? [];
  };
}
