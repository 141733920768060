import Color from "color";
import { MarkerStyle } from "./type";

const FADE_LEVEL = 0.15;

/**
 * Create a marker from a colour-producing function.
 * The function is called twice, once for the fill colour and once for the stroke.
 * `fromColor` then generates normal and selected variants of the marker.
 *
 * @param color A function from a boolean flag `isFill` to a color.
 */
export function fromColorFunc(
  color: (isFill: boolean) => Color | string,
  sizeMultiplier: number = 1,
  strokeMultiplier: number = 1
): MarkerStyle {
  return {
    normal: {
      size: 1 * sizeMultiplier,
      fillColor: Color(color(true)).string(),
      strokeColor: Color(color(false)).string(),
      strokeWidth: 1 * strokeMultiplier,
    },
    selected: {
      size: 1.5 * sizeMultiplier,
      fillColor: Color(color(true)).string(),
      strokeColor: Color(color(false)).string(),
      strokeWidth: 2 * strokeMultiplier,
    },
  };
}

export function fromColor(
  baseColor: Color | string,
  sizeMultiplier: number = 1,
  strokeMultiplier: number = 1
): MarkerStyle {
  const base = Color(baseColor);
  return fromColorFunc(
    isFill => (isFill ? base : base.darken(0.4)),
    sizeMultiplier,
    strokeMultiplier
  );
}

export function fromColors(
  fillColor: Color | string,
  strokeColor: Color | string,
  sizeMultiplier: number = 1,
  strokeMultiplier: number = 1
): MarkerStyle {
  return fromColorFunc(
    isFill => (isFill ? fillColor : strokeColor),
    sizeMultiplier,
    strokeMultiplier
  );
}

/**
 * Transparent marker to indicate that no value is available in a location.
 */
export const invisible: MarkerStyle = fromColorFunc(
  isFill => (isFill ? Color("transparent") : Color("transparent")),
  0,
  0
);

/**
 * Minimal marker to indicate that no value is available in a location.
 */
export const empty: MarkerStyle = fromColorFunc(isFill =>
  isFill ? Color("transparent") : Color("rgba(128, 128, 128, .2)")
);

/**
 * Create a marker of a specific hue.
 *
 * @param degrees A value in the range [0,360].
 */
export function hue(degrees: number): MarkerStyle {
  return fromColorFunc(fill =>
    Color.hsl(degrees % 360, 100, fill ? 60 : 40).fade(fill ? FADE_LEVEL : 0.0)
  );
}

/**
 * A "standard" marker to use when you only need one colour.
 */
export const standard: MarkerStyle = hue(60);

/**
 * Create a marker to represent a choice from an arbitrary set of values.
 * Markers come out in a variety of different hues/lightnesses.
 *
 * For example, to display different place names in different colours,
 * simply assign each place name to an integer and call this function for each.
 *
 * The first 16 or so colours are visually distinct.
 * After this you'll get diminishing returns.
 *
 * @param value An arbitrary number.
 */
export function lookup(value: number = 0): MarkerStyle {
  return fromColorFunc(fill =>
    Color.hsl(
      (50 * value + 180) % 360,
      100,
      40 + ((Math.floor(value / 10) * 5) % 30) + (fill ? 30 : 0)
    ).fade(fill ? FADE_LEVEL : 0.0)
  );
}

/**
 * Create a marker schema for a value that ranges from "bad" to "good".
 *
 * @param value A number in the range [0,1].
 * 0 means "red", 1 means "green", intermediate values range through yellow.
 */
export function quality(value: number = 0.5): MarkerStyle {
  const red = (lightness: number) => Color.hsl(0, 100, lightness);

  const yellow = (lightness: number) => Color.hsl(60, 100, lightness);

  const green = (lightness: number) => Color.hsl(120, 100, lightness);

  const rescale = (value: number, min: number, max: number): number =>
    (value - min) / (max - min);

  return fromColorFunc(fill => {
    const lightness = fill ? 50 : 30;

    const base =
      value < 0.2
        ? red(lightness * 0.65).mix(red(lightness), rescale(value, 0, 0.2))
        : value < 0.5
          ? red(lightness).mix(yellow(lightness), rescale(value, 0.2, 0.5))
          : value < 0.75
            ? yellow(lightness).mix(green(lightness), rescale(value, 0.5, 0.75))
            : green(lightness).mix(
                green(lightness * 0.5),
                rescale(value, 0.75, 1.0)
              );

    return base.fade(fill ? FADE_LEVEL : 0.0);
  });
}

/**
 * Create an HSV marker.
 *
 * We tweak the range of the parameters
 * so they don't cover the complete HSV range.
 * For example, a `value` of 0 or 1 is rendered as
 * a darker or lighter color of the relevant `hue`,
 * rather than black or white.
 *
 * @param hue A number in the range [0,360].
 * @param saturation A number in the range [0,1].
 * @param value A number in the range [0,1].
 */
export function gradient(
  hue: number,
  saturation: number,
  value: number = 0.5
): MarkerStyle {
  return fromColorFunc(fill =>
    Color.hsl(
      hue,
      saturation * 100,
      fill ? 40 + 45 * value : 15 + 35 * value
    ).fade(fill ? FADE_LEVEL : 0.0)
  );
}

/**
 * Create a marker representing a numeric value,
 * The lightness varies but the marker uses a default hue and saturation.
 *
 * @param value A number in the range [0,1].
 * @param hue A hue in the range [0,360] (default 200).
 * @param saturation A saturation in the range [0,1] (default 1).
 */
export function numeric(
  value: number,
  hue: number = 200,
  saturation: number = 1.0
): MarkerStyle {
  return gradient(hue, saturation, value);
}

/**
 * Create a marker representing a mix of two colours.
 *
 * @param color1 The first colour.
 * @param color2 The second colour.
 * @param weight A number in the range [0,1] from 0 = color1 to 1 = color2.
 */
export function mix(
  color1: Color | string,
  color2: Color | string,
  weight: number = 0.5
): MarkerStyle {
  return fromColor(Color(color1).mix(Color(color2), weight));
}

export function ph(value: number): MarkerStyle {
  return value < 1.0
    ? fromColor("#e4222e")
    : value < 2.0
      ? fromColor("#eb7831")
      : value < 3.0
        ? fromColor("#f19932")
        : value < 4.0
          ? fromColor("#f2bb33")
          : value < 5.0
            ? fromColor("#f7d730")
            : value < 6.0
              ? fromColor("#f3e934")
              : value < 7.0
                ? fromColor("#c9d53e")
                : value < 8.0
                  ? fromColor("#aac556")
                  : value < 9.0
                    ? fromColor("#7daaa1")
                    : value < 10.0
                      ? fromColor("#4294c2")
                      : value < 11.0
                        ? fromColor("#4582c0")
                        : value < 12.0
                          ? fromColor("#426fb3")
                          : value < 13.0
                            ? fromColor("#5e529f")
                            : fromColor("#654598");
}
