import { Field, SelectOption, SelectValue } from "@cartographerio/topo-form";

const imageRoot = "https://media.cartographer.io/static/images";
const missingImage = `${imageRoot}/urs/missing-image.jpg`;

export function fieldImage(path: string): string {
  return `${imageRoot}/${path}`;
}

export interface SelectOptionLabelsArgs<A extends SelectValue> {
  options: SelectOption<A>[];
  prefix?: string;
  suffix?: string;
}

export function selectOptionLabels<A extends SelectValue>(
  args: SelectOptionLabelsArgs<A>
): SelectOption<A>[] {
  const { options, prefix, suffix } = args;

  return options.map(({ label, ...rest }) => ({
    label: prefix
      ? suffix
        ? `${prefix} ${label} ${suffix}`
        : `${prefix} ${label}`
      : suffix
        ? `${label} ${suffix}`
        : label,
    ...rest,
  }));
}

export interface SelectOptionImagesArgs<A extends SelectValue> {
  options: SelectOption<A>[];
  pattern: string;
  exclude?: A[];
}

export function selectOptionImages<A extends SelectValue>(
  args: SelectOptionImagesArgs<A>
): SelectOption<A>[] {
  const { options, pattern, exclude } = args;

  return options.map(option => {
    if (exclude?.includes(option.value)) {
      return option;
    } else if (option.value == null) {
      return {
        ...option,
        image: missingImage,
      };
    } else {
      const path = pattern.replace("*", `${option.value}`.toLowerCase());

      return {
        ...option,
        image: `${imageRoot}/${path}`,
      };
    }
  });
}

export function interleaveFields(...fields: Field[][]): Field[] {
  const ans: Field[] = [];

  for (let j = 0; j < fields[0].length; j++) {
    for (let i = 0; i < fields.length; i++) {
      ans.push(fields[i][j]);
    }
  }

  return ans;
}
