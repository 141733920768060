import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Attachment,
  AttachmentCreate,
  attachmentCreateFormData,
  AttachmentId,
  AttachmentNaming,
  AttachmentNamingEnum,
  AttachmentUpdate,
  attachmentUpdateFormData,
  AttachmentVariant,
  attachmentVariant,
  isAttachment,
  isResolveResponse,
  isSearchResults,
  ResolveMethod,
  ResolveResponse,
  SearchResults,
  SurveyId,
} from "@cartographerio/types";
import { formatUrl } from "../..";
import * as fetch from "../../fetch";
import { authHeaders } from "../../headers";
import { contentAs, optionalContentAs } from "../../response";
import { UrlParts } from "../../url";
import { PartialParams } from "../params";
import { AttachmentSearchOptions } from "./common";

const basePath = "/attachment/v3";

export function search(
  apiParams: ApiParams,
  surveyId: SurveyId,
  options: PartialParams<AttachmentSearchOptions> = {}
): IO<SearchResults<Attachment>> {
  const url: UrlParts = {
    path: basePath,
    query: {
      survey: surveyId,
      folder: options.folder?.folder,
      start: options.start,
      count: options.count,
    },
  };

  return fetch
    .get({ apiParams, url })
    .chain(
      contentAs("SearchResults<Attachment>", isSearchResults(isAttachment))
    );
}

export function create(
  apiParams: ApiParams,
  attach: AttachmentCreate,
  file: File,
  attachId?: AttachmentId | null
): IO<Attachment> {
  const method = attachId == null ? "POST" : "PUT";

  const url: string = formatUrl(apiParams.apiConfig.baseUrl, {
    path: attachId == null ? basePath : `${basePath}/${attachId}`,
  });

  const body = attachmentCreateFormData(attach);
  body.append("file", file);

  const headers = authHeaders(apiParams.apiConfig, apiParams.auth);

  return fetch
    .request(url, { method, body, headers })
    .flatMap(contentAs("Attachment", isAttachment));
}

export function readOrFail(
  apiParams: ApiParams,
  id: AttachmentId
): IO<Attachment> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${id}` } })
    .flatMap(contentAs("Attachment", isAttachment));
}

export function readOption(
  apiParams: ApiParams,
  id: AttachmentId
): IO<Option<Attachment>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${id}` } })
    .flatMap(optionalContentAs("Attachment", isAttachment));
}

export function update(
  apiParams: ApiParams,
  attachId: AttachmentId,
  attach: AttachmentUpdate,
  file?: File | null
): IO<Attachment> {
  const method = "PUT";

  const url: string = formatUrl(apiParams.apiConfig.baseUrl, {
    path: `${basePath}/${attachId}`,
  });

  const body = attachmentUpdateFormData(attach);
  if (file != null) {
    body.append("file", file);
  }

  const headers = authHeaders(apiParams.apiConfig, apiParams.auth);

  return fetch
    .request(url, { method, body, headers })
    .flatMap(contentAs("Attachment", isAttachment));
}

export function remove(apiParams: ApiParams, id: AttachmentId): IO<void> {
  const url: UrlParts = {
    path: `${basePath}/${id}`,
  };

  return fetch.remove({ apiParams, url }).void();
}

export function resolve(
  apiParams: ApiParams,
  id: AttachmentId,
  variant: AttachmentVariant = attachmentVariant.original,
  naming: AttachmentNaming = AttachmentNamingEnum.Title,
  method: ResolveMethod = "get"
): IO<ResolveResponse> {
  const url: UrlParts = {
    path: `${basePath}/${id}/${variant.id}/url`,
    query: { method, naming },
  };

  return fetch
    .get({ apiParams, url })
    .flatMap(contentAs("ResolveResponse", isResolveResponse));
}

export function resolvePreview(
  apiParams: ApiParams,
  id: AttachmentId,
  naming: AttachmentNaming = AttachmentNamingEnum.Title,
  method: ResolveMethod = "get"
): IO<ResolveResponse> {
  return resolve(apiParams, id, attachmentVariant.preview, naming, method);
}

export function resolveThumbnail(
  apiParams: ApiParams,
  id: AttachmentId,
  naming: AttachmentNaming = AttachmentNamingEnum.Title,
  method: ResolveMethod = "get"
): IO<ResolveResponse> {
  return resolve(
    apiParams,
    id,
    attachmentVariant.largeThumbnail,
    naming,
    method
  );
}
