import { Option, Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  pointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
} from "@cartographerio/guard";
import {
  Thames21River,
  Thames21RiverEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface PartialLitterData {
  recorded?: Timestamp | null;
  location?: Point | null;
  river?: Thames21River | null;
}

export function isPartialData(data: unknown): data is PartialLitterData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "river", isNullable(Thames21RiverEnum.isValue))
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialThames21Litter"
  )(data).map(data =>
    [
      Option.wrap(data.river)
        .map(Thames21RiverEnum.labelOf)
        .getOrElse(() => "Unknown River"),
      Option.wrap(data.location)
        .nullMap(pointToNgr)
        .getOrElse(() => "Unknown NGR"),
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialThames21Litter"
  )(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialThames21Litter"
  )(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, PartialLitterData> {
  return Result.guard(
    isPartialData,
    "PartialThames21Litter"
  )(data).map(data => ({ ...data, recorded: null }));
}
