/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  Email,
  isEmail,
  InvitationCodeId,
  isInvitationCodeId,
  InvitationCodeRef,
  isInvitationCodeRef,
  InvitationId,
  isInvitationId,
  Message,
  isMessage,
  ScreenName,
  isScreenName,
  UnencryptedPassword,
  isUnencryptedPassword,
  UserFeature,
  isUserFeature,
  UserId,
  isUserId,
  WorkspaceAlias,
  isWorkspaceAlias,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";
import {
  QualificationRole,
  isQualificationRole,
  Role,
  isRole,
} from "../permission/types.generated";
import { Tagged, bridgesIsTaggedHack as isTagged } from "@cartographerio/util";

export type AccessToken = Tagged<"AccessToken">;

export interface Identity {
  userId: UserId;
  firstName: string;
  lastName: string;
  screenName: ScreenName;
  email: Email;
  roles: Role[];
  qualificationRoles: QualificationRole[];
  features?: UserFeature[] | null;
}

export interface Credentials {
  token: AccessToken;
  identity: Identity;
}

export interface InvitationSignupRequest {
  firstName: string;
  lastName: string;
  email: Email;
  screenName: ScreenName;
  password: UnencryptedPassword;
  acceptTerms: boolean;
}

export interface InvitationChallengeNotFound {
  invitation: InvitationId;
}

export interface InvitationChallengeExpired {
  invitation: InvitationId;
}

export interface InvitationChallengeCanceled {
  invitation: InvitationId;
}

export interface InvitationChallengeAlreadyAccepted {
  invitation: InvitationId;
}

export interface InvitationChallengeIncorrectEmail {
  invitation: InvitationId;
}

export interface InvitationChallengeSignupRequired {
  invitation: InvitationId;
  firstName: string;
  lastName: string;
  email: Email;
}

export type InvitationChallenge =
  | ({
      type: "InvitationChallengeAlreadyAccepted";
    } & InvitationChallengeAlreadyAccepted)
  | ({ type: "InvitationChallengeCanceled" } & InvitationChallengeCanceled)
  | ({ type: "InvitationChallengeExpired" } & InvitationChallengeExpired)
  | ({
      type: "InvitationChallengeIncorrectEmail";
    } & InvitationChallengeIncorrectEmail)
  | ({ type: "InvitationChallengeNotFound" } & InvitationChallengeNotFound)
  | ({
      type: "InvitationChallengeSignupRequired";
    } & InvitationChallengeSignupRequired);

export interface InvitationAccepted {
  invitationId: InvitationId;
  userId: UserId;
}

export type InvitationAcceptResponse =
  | InvitationChallenge
  | ({ type: "InvitationAccepted" } & InvitationAccepted);

export interface InvitationCodeUseRequest {
  invitationCode: InvitationCodeRef;
  user: UserId | Email;
}

export interface InvitationCodeSignupRequest {
  invitationCode: InvitationCodeRef;
  firstName: string;
  lastName: string;
  email: Email;
  screenName: ScreenName;
  acceptTerms: boolean;
}

export interface InvitationCodeChallengeNotFound {
  invitationCode: InvitationCodeRef;
}

export interface InvitationCodeChallengeExpired {
  invitationCode: InvitationCodeRef;
}

export interface InvitationCodeChallengeCanceled {
  invitationCode: InvitationCodeRef;
}

export interface InvitationCodeChallengeAlreadyUsed {
  invitationCode: InvitationCodeRef;
}

export interface InvitationCodeChallengeRolesUnchanged {
  invitationCode: InvitationCodeRef;
}

export interface InvitationCodeChallengeLimitsReached {
  invitationCode: InvitationCodeRef;
  message: string;
}

export type InvitationCodeChallenge =
  | ({
      type: "InvitationCodeChallengeAlreadyUsed";
    } & InvitationCodeChallengeAlreadyUsed)
  | ({
      type: "InvitationCodeChallengeCanceled";
    } & InvitationCodeChallengeCanceled)
  | ({
      type: "InvitationCodeChallengeExpired";
    } & InvitationCodeChallengeExpired)
  | ({
      type: "InvitationCodeChallengeLimitsReached";
    } & InvitationCodeChallengeLimitsReached)
  | ({
      type: "InvitationCodeChallengeNotFound";
    } & InvitationCodeChallengeNotFound)
  | ({
      type: "InvitationCodeChallengeRolesUnchanged";
    } & InvitationCodeChallengeRolesUnchanged);

export interface SignupChallengeCodeNotFound {
  invitationCode: InvitationCodeRef;
}

export interface SignupChallengeCodeExpired {
  invitationCode: InvitationCodeRef;
}

export interface SignupChallengeCodeCanceled {
  invitationCode: InvitationCodeRef;
}

export interface SignupChallengeLimitsReached {
  invitationCode: InvitationCodeRef;
  message: string;
}

export interface SignupChallengeEmailVerificationRequired {
  invitationCode: InvitationCodeRef;
}

export interface SignupChallengeValidationErrors {
  errors: Message[];
}

export type SignupChallenge =
  | ({ type: "SignupChallengeCodeCanceled" } & SignupChallengeCodeCanceled)
  | ({ type: "SignupChallengeCodeExpired" } & SignupChallengeCodeExpired)
  | ({ type: "SignupChallengeCodeNotFound" } & SignupChallengeCodeNotFound)
  | ({
      type: "SignupChallengeEmailVerificationRequired";
    } & SignupChallengeEmailVerificationRequired)
  | ({ type: "SignupChallengeLimitsReached" } & SignupChallengeLimitsReached)
  | ({
      type: "SignupChallengeValidationErrors";
    } & SignupChallengeValidationErrors);

export type SignupResponse =
  | SignupChallenge
  | { type: "SignupSuccess"; credentials: Credentials };

export interface InvitationSignupChallengeExpired {
  invitation: InvitationId;
}

export interface InvitationSignupChallengeCanceled {
  invitation: InvitationId;
}

export interface InvitationSignupChallengeAlreadyAccepted {
  invitation: InvitationId;
}

export interface InvitationSignupChallengeEmailVerificationRequired {
  invitation: InvitationId;
}

export interface InvitationSignupChallengeValidationErrors {
  errors: Message[];
}

export type InvitationSignupChallenge =
  | ({
      type: "InvitationSignupChallengeAlreadyAccepted";
    } & InvitationSignupChallengeAlreadyAccepted)
  | ({
      type: "InvitationSignupChallengeCanceled";
    } & InvitationSignupChallengeCanceled)
  | ({
      type: "InvitationSignupChallengeEmailVerificationRequired";
    } & InvitationSignupChallengeEmailVerificationRequired)
  | ({
      type: "InvitationSignupChallengeExpired";
    } & InvitationSignupChallengeExpired)
  | ({
      type: "InvitationSignupChallengeValidationErrors";
    } & InvitationSignupChallengeValidationErrors);

export type InvitationSignupResponse =
  | InvitationSignupChallenge
  | { type: "SignupSuccess"; credentials: Credentials };

export interface InvitationCodeSignupApprovalRequest {
  invitationCode: InvitationCodeRef;
  user: UserId | Email;
}

export interface InvitationCodeSignupApproved {
  invitationCode: InvitationCodeRef;
  userId: UserId;
  emailVerificationRequired: boolean;
}

export interface InvitationCodeSignupRejected {
  invitationCode: InvitationCodeRef;
  userId: UserId;
}

export interface InvitationCodeSignupAlreadyApproved {
  invitationCode: InvitationCodeRef;
  userId: UserId;
}

export interface InvitationCodeSignupAlreadyRejected {
  invitationCode: InvitationCodeRef;
  userId: UserId;
}

export type InvitationCodeSignupApprovalResponse =
  | ({
      type: "InvitationCodeSignupAlreadyApproved";
    } & InvitationCodeSignupAlreadyApproved)
  | ({
      type: "InvitationCodeSignupAlreadyRejected";
    } & InvitationCodeSignupAlreadyRejected)
  | ({ type: "InvitationCodeSignupApproved" } & InvitationCodeSignupApproved)
  | ({ type: "InvitationCodeSignupRejected" } & InvitationCodeSignupRejected);

export interface SigninRequest {
  email: string;
  password?: UnencryptedPassword | null;
  invitationCode?: InvitationCodeRef | null;
  signinAs?: Email | null;
  acceptTerms?: boolean | null;
}

export interface InvalidEmail {
  email: string;
}

export interface AccountNotFound {
  email: string;
}

export interface PasswordRequired {
  email: Email;
}

export interface PasswordIncorrect {
  email: Email;
}

export interface TermsNotAccepted {
  email: Email;
  version: number;
}

export type SigninChallenge =
  | ({ type: "InvalidEmail" } & InvalidEmail)
  | ({ type: "AccountNotFound" } & AccountNotFound)
  | ({ type: "PasswordRequired" } & PasswordRequired)
  | ({ type: "PasswordIncorrect" } & PasswordIncorrect)
  | ({ type: "TermsNotAccepted" } & TermsNotAccepted);

export interface SigninSuccess {
  credentials: Credentials;
  codeChallenge?: InvitationCodeChallenge | null;
}

export type SigninResponse =
  | SigninChallenge
  | ({ type: "SigninSuccess" } & SigninSuccess);

export type ResetPasswordToken = Tagged<"ResetPasswordToken">;

export interface ForgotPasswordRequest {
  email: Email;
}

export interface ResetPasswordRequest {
  token: ResetPasswordToken;
  password: UnencryptedPassword;
}

export interface ResetPasswordSignedIn {
  credentials: Credentials;
  result: CheckResetPasswordTokenResult;
}

export interface ResetPasswordSignInRequired {
  result: CheckResetPasswordTokenResult;
}

export interface ResetPasswordFailed {
  result: CheckResetPasswordTokenResult;
}

export type ResetPasswordResult =
  | ({ type: "ResetPasswordSignedIn" } & ResetPasswordSignedIn)
  | ({ type: "ResetPasswordSignInRequired" } & ResetPasswordSignInRequired)
  | ({ type: "ResetPasswordFailed" } & ResetPasswordFailed);

export interface CheckSigninEmailResponse {
  exists: boolean;
}

export type CheckResetPasswordTokenResult = "expired" | "invalid" | "valid";

export interface CheckResetPasswordTokenResponse {
  token: ResetPasswordToken;
  result: CheckResetPasswordTokenResult;
}

export type WorkspaceAccessDeniedReason =
  | "AccountDisabled"
  | "WorkspaceDisabled"
  | "NoAccess";

export interface WorkspaceAccessGranted {
  id: WorkspaceId;
  userId: UserId;
  name: string;
  alias: WorkspaceAlias;
  logo?: string | null;
  pendingInvitationCodeIds?: InvitationCodeId[] | null;
  pendingRoles?: Role[] | null;
}

export interface WorkspaceAccessUnapproved {
  id: WorkspaceId;
  userId: UserId;
  name: string;
  alias: WorkspaceAlias;
  logo?: string | null;
  pendingInvitationCodeIds: InvitationCodeId[];
  pendingRoles: Role[];
}

export interface WorkspaceAccessDenied {
  id: WorkspaceId;
  reason: WorkspaceAccessDeniedReason;
  userId: UserId;
  name: string;
  alias: WorkspaceAlias;
  logo?: string | null;
}

export type CheckWorkspaceAccessResult =
  | ({ type: "WorkspaceAccessDenied" } & WorkspaceAccessDenied)
  | ({ type: "WorkspaceAccessGranted" } & WorkspaceAccessGranted)
  | ({ type: "WorkspaceAccessUnapproved" } & WorkspaceAccessUnapproved);

export const isAccessToken = (v: any): v is AccessToken => {
  return isTagged((a0: any): a0 is "AccessToken" => a0 === "AccessToken")(v);
};

export const isIdentity = (v: any): v is Identity => {
  return (
    typeof v === "object" &&
    v != null &&
    "userId" in v &&
    isUserId(v.userId) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "screenName" in v &&
    isScreenName(v.screenName) &&
    "email" in v &&
    isEmail(v.email) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i)) &&
    (!("features" in v) ||
      (Array.isArray(v.features) &&
        v.features.every((i: any) => isUserFeature(i))) ||
      v.features === null)
  );
};

export const isCredentials = (v: any): v is Credentials => {
  return (
    typeof v === "object" &&
    v != null &&
    "token" in v &&
    isAccessToken(v.token) &&
    "identity" in v &&
    isIdentity(v.identity)
  );
};

export const isInvitationSignupRequest = (
  v: any
): v is InvitationSignupRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "email" in v &&
    isEmail(v.email) &&
    "screenName" in v &&
    isScreenName(v.screenName) &&
    "password" in v &&
    isUnencryptedPassword(v.password) &&
    "acceptTerms" in v &&
    typeof v.acceptTerms === "boolean"
  );
};

export const isInvitationChallengeNotFound = (
  v: any
): v is InvitationChallengeNotFound => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationChallengeExpired = (
  v: any
): v is InvitationChallengeExpired => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationChallengeCanceled = (
  v: any
): v is InvitationChallengeCanceled => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationChallengeAlreadyAccepted = (
  v: any
): v is InvitationChallengeAlreadyAccepted => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationChallengeIncorrectEmail = (
  v: any
): v is InvitationChallengeIncorrectEmail => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationChallengeSignupRequired = (
  v: any
): v is InvitationChallengeSignupRequired => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "email" in v &&
    isEmail(v.email)
  );
};

export const isInvitationChallenge = (v: any): v is InvitationChallenge => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "InvitationChallengeAlreadyAccepted" &&
      isInvitationChallengeAlreadyAccepted(v)) ||
      (v.type === "InvitationChallengeCanceled" &&
        isInvitationChallengeCanceled(v)) ||
      (v.type === "InvitationChallengeExpired" &&
        isInvitationChallengeExpired(v)) ||
      (v.type === "InvitationChallengeIncorrectEmail" &&
        isInvitationChallengeIncorrectEmail(v)) ||
      (v.type === "InvitationChallengeNotFound" &&
        isInvitationChallengeNotFound(v)) ||
      (v.type === "InvitationChallengeSignupRequired" &&
        isInvitationChallengeSignupRequired(v)))
  );
};

export const isInvitationAccepted = (v: any): v is InvitationAccepted => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationId" in v &&
    isInvitationId(v.invitationId) &&
    "userId" in v &&
    isUserId(v.userId)
  );
};

export const isInvitationAcceptResponse = (
  v: any
): v is InvitationAcceptResponse => {
  return (
    isInvitationChallenge(v) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "InvitationAccepted" &&
      isInvitationAccepted(v))
  );
};

export const isInvitationCodeUseRequest = (
  v: any
): v is InvitationCodeUseRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "user" in v &&
    (isUserId(v.user) || isEmail(v.user))
  );
};

export const isInvitationCodeSignupRequest = (
  v: any
): v is InvitationCodeSignupRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "email" in v &&
    isEmail(v.email) &&
    "screenName" in v &&
    isScreenName(v.screenName) &&
    "acceptTerms" in v &&
    typeof v.acceptTerms === "boolean"
  );
};

export const isInvitationCodeChallengeNotFound = (
  v: any
): v is InvitationCodeChallengeNotFound => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isInvitationCodeChallengeExpired = (
  v: any
): v is InvitationCodeChallengeExpired => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isInvitationCodeChallengeCanceled = (
  v: any
): v is InvitationCodeChallengeCanceled => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isInvitationCodeChallengeAlreadyUsed = (
  v: any
): v is InvitationCodeChallengeAlreadyUsed => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isInvitationCodeChallengeRolesUnchanged = (
  v: any
): v is InvitationCodeChallengeRolesUnchanged => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isInvitationCodeChallengeLimitsReached = (
  v: any
): v is InvitationCodeChallengeLimitsReached => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "message" in v &&
    typeof v.message === "string"
  );
};

export const isInvitationCodeChallenge = (
  v: any
): v is InvitationCodeChallenge => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "InvitationCodeChallengeAlreadyUsed" &&
      isInvitationCodeChallengeAlreadyUsed(v)) ||
      (v.type === "InvitationCodeChallengeCanceled" &&
        isInvitationCodeChallengeCanceled(v)) ||
      (v.type === "InvitationCodeChallengeExpired" &&
        isInvitationCodeChallengeExpired(v)) ||
      (v.type === "InvitationCodeChallengeLimitsReached" &&
        isInvitationCodeChallengeLimitsReached(v)) ||
      (v.type === "InvitationCodeChallengeNotFound" &&
        isInvitationCodeChallengeNotFound(v)) ||
      (v.type === "InvitationCodeChallengeRolesUnchanged" &&
        isInvitationCodeChallengeRolesUnchanged(v)))
  );
};

export const isSignupChallengeCodeNotFound = (
  v: any
): v is SignupChallengeCodeNotFound => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isSignupChallengeCodeExpired = (
  v: any
): v is SignupChallengeCodeExpired => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isSignupChallengeCodeCanceled = (
  v: any
): v is SignupChallengeCodeCanceled => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isSignupChallengeLimitsReached = (
  v: any
): v is SignupChallengeLimitsReached => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "message" in v &&
    typeof v.message === "string"
  );
};

export const isSignupChallengeEmailVerificationRequired = (
  v: any
): v is SignupChallengeEmailVerificationRequired => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode)
  );
};

export const isSignupChallengeValidationErrors = (
  v: any
): v is SignupChallengeValidationErrors => {
  return (
    typeof v === "object" &&
    v != null &&
    "errors" in v &&
    Array.isArray(v.errors) &&
    v.errors.every((i: any) => isMessage(i))
  );
};

export const isSignupChallenge = (v: any): v is SignupChallenge => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "SignupChallengeCodeCanceled" &&
      isSignupChallengeCodeCanceled(v)) ||
      (v.type === "SignupChallengeCodeExpired" &&
        isSignupChallengeCodeExpired(v)) ||
      (v.type === "SignupChallengeCodeNotFound" &&
        isSignupChallengeCodeNotFound(v)) ||
      (v.type === "SignupChallengeEmailVerificationRequired" &&
        isSignupChallengeEmailVerificationRequired(v)) ||
      (v.type === "SignupChallengeLimitsReached" &&
        isSignupChallengeLimitsReached(v)) ||
      (v.type === "SignupChallengeValidationErrors" &&
        isSignupChallengeValidationErrors(v)))
  );
};

export const isSignupResponse = (v: any): v is SignupResponse => {
  return (
    isSignupChallenge(v) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "SignupSuccess" &&
      "credentials" in v &&
      isCredentials(v.credentials))
  );
};

export const isInvitationSignupChallengeExpired = (
  v: any
): v is InvitationSignupChallengeExpired => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationSignupChallengeCanceled = (
  v: any
): v is InvitationSignupChallengeCanceled => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationSignupChallengeAlreadyAccepted = (
  v: any
): v is InvitationSignupChallengeAlreadyAccepted => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationSignupChallengeEmailVerificationRequired = (
  v: any
): v is InvitationSignupChallengeEmailVerificationRequired => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationId(v.invitation)
  );
};

export const isInvitationSignupChallengeValidationErrors = (
  v: any
): v is InvitationSignupChallengeValidationErrors => {
  return (
    typeof v === "object" &&
    v != null &&
    "errors" in v &&
    Array.isArray(v.errors) &&
    v.errors.every((i: any) => isMessage(i))
  );
};

export const isInvitationSignupChallenge = (
  v: any
): v is InvitationSignupChallenge => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "InvitationSignupChallengeAlreadyAccepted" &&
      isInvitationSignupChallengeAlreadyAccepted(v)) ||
      (v.type === "InvitationSignupChallengeCanceled" &&
        isInvitationSignupChallengeCanceled(v)) ||
      (v.type === "InvitationSignupChallengeEmailVerificationRequired" &&
        isInvitationSignupChallengeEmailVerificationRequired(v)) ||
      (v.type === "InvitationSignupChallengeExpired" &&
        isInvitationSignupChallengeExpired(v)) ||
      (v.type === "InvitationSignupChallengeValidationErrors" &&
        isInvitationSignupChallengeValidationErrors(v)))
  );
};

export const isInvitationSignupResponse = (
  v: any
): v is InvitationSignupResponse => {
  return (
    isInvitationSignupChallenge(v) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "SignupSuccess" &&
      "credentials" in v &&
      isCredentials(v.credentials))
  );
};

export const isInvitationCodeSignupApprovalRequest = (
  v: any
): v is InvitationCodeSignupApprovalRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "user" in v &&
    (isUserId(v.user) || isEmail(v.user))
  );
};

export const isInvitationCodeSignupApproved = (
  v: any
): v is InvitationCodeSignupApproved => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "userId" in v &&
    isUserId(v.userId) &&
    "emailVerificationRequired" in v &&
    typeof v.emailVerificationRequired === "boolean"
  );
};

export const isInvitationCodeSignupRejected = (
  v: any
): v is InvitationCodeSignupRejected => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "userId" in v &&
    isUserId(v.userId)
  );
};

export const isInvitationCodeSignupAlreadyApproved = (
  v: any
): v is InvitationCodeSignupAlreadyApproved => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "userId" in v &&
    isUserId(v.userId)
  );
};

export const isInvitationCodeSignupAlreadyRejected = (
  v: any
): v is InvitationCodeSignupAlreadyRejected => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCode" in v &&
    isInvitationCodeRef(v.invitationCode) &&
    "userId" in v &&
    isUserId(v.userId)
  );
};

export const isInvitationCodeSignupApprovalResponse = (
  v: any
): v is InvitationCodeSignupApprovalResponse => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "InvitationCodeSignupAlreadyApproved" &&
      isInvitationCodeSignupAlreadyApproved(v)) ||
      (v.type === "InvitationCodeSignupAlreadyRejected" &&
        isInvitationCodeSignupAlreadyRejected(v)) ||
      (v.type === "InvitationCodeSignupApproved" &&
        isInvitationCodeSignupApproved(v)) ||
      (v.type === "InvitationCodeSignupRejected" &&
        isInvitationCodeSignupRejected(v)))
  );
};

export const isSigninRequest = (v: any): v is SigninRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "email" in v &&
    typeof v.email === "string" &&
    (!("password" in v) ||
      isUnencryptedPassword(v.password) ||
      v.password === null) &&
    (!("invitationCode" in v) ||
      isInvitationCodeRef(v.invitationCode) ||
      v.invitationCode === null) &&
    (!("signinAs" in v) || isEmail(v.signinAs) || v.signinAs === null) &&
    (!("acceptTerms" in v) ||
      typeof v.acceptTerms === "boolean" ||
      v.acceptTerms === null)
  );
};

export const isInvalidEmail = (v: any): v is InvalidEmail => {
  return (
    typeof v === "object" &&
    v != null &&
    "email" in v &&
    typeof v.email === "string"
  );
};

export const isAccountNotFound = (v: any): v is AccountNotFound => {
  return (
    typeof v === "object" &&
    v != null &&
    "email" in v &&
    typeof v.email === "string"
  );
};

export const isPasswordRequired = (v: any): v is PasswordRequired => {
  return typeof v === "object" && v != null && "email" in v && isEmail(v.email);
};

export const isPasswordIncorrect = (v: any): v is PasswordIncorrect => {
  return typeof v === "object" && v != null && "email" in v && isEmail(v.email);
};

export const isTermsNotAccepted = (v: any): v is TermsNotAccepted => {
  return (
    typeof v === "object" &&
    v != null &&
    "email" in v &&
    isEmail(v.email) &&
    "version" in v &&
    typeof v.version === "number"
  );
};

export const isSigninChallenge = (v: any): v is SigninChallenge => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "InvalidEmail" && isInvalidEmail(v)) ||
      (v.type === "AccountNotFound" && isAccountNotFound(v)) ||
      (v.type === "PasswordRequired" && isPasswordRequired(v)) ||
      (v.type === "PasswordIncorrect" && isPasswordIncorrect(v)) ||
      (v.type === "TermsNotAccepted" && isTermsNotAccepted(v)))
  );
};

export const isSigninSuccess = (v: any): v is SigninSuccess => {
  return (
    typeof v === "object" &&
    v != null &&
    "credentials" in v &&
    isCredentials(v.credentials) &&
    (!("codeChallenge" in v) ||
      isInvitationCodeChallenge(v.codeChallenge) ||
      v.codeChallenge === null)
  );
};

export const isSigninResponse = (v: any): v is SigninResponse => {
  return (
    isSigninChallenge(v) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "SigninSuccess" &&
      isSigninSuccess(v))
  );
};

export const isResetPasswordToken = (v: any): v is ResetPasswordToken => {
  return isTagged(
    (a0: any): a0 is "ResetPasswordToken" => a0 === "ResetPasswordToken"
  )(v);
};

export const isForgotPasswordRequest = (v: any): v is ForgotPasswordRequest => {
  return typeof v === "object" && v != null && "email" in v && isEmail(v.email);
};

export const isResetPasswordRequest = (v: any): v is ResetPasswordRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "token" in v &&
    isResetPasswordToken(v.token) &&
    "password" in v &&
    isUnencryptedPassword(v.password)
  );
};

export const isResetPasswordSignedIn = (v: any): v is ResetPasswordSignedIn => {
  return (
    typeof v === "object" &&
    v != null &&
    "credentials" in v &&
    isCredentials(v.credentials) &&
    "result" in v &&
    isCheckResetPasswordTokenResult(v.result)
  );
};

export const isResetPasswordSignInRequired = (
  v: any
): v is ResetPasswordSignInRequired => {
  return (
    typeof v === "object" &&
    v != null &&
    "result" in v &&
    isCheckResetPasswordTokenResult(v.result)
  );
};

export const isResetPasswordFailed = (v: any): v is ResetPasswordFailed => {
  return (
    typeof v === "object" &&
    v != null &&
    "result" in v &&
    isCheckResetPasswordTokenResult(v.result)
  );
};

export const isResetPasswordResult = (v: any): v is ResetPasswordResult => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "ResetPasswordSignedIn" && isResetPasswordSignedIn(v)) ||
      (v.type === "ResetPasswordSignInRequired" &&
        isResetPasswordSignInRequired(v)) ||
      (v.type === "ResetPasswordFailed" && isResetPasswordFailed(v)))
  );
};

export const isCheckSigninEmailResponse = (
  v: any
): v is CheckSigninEmailResponse => {
  return (
    typeof v === "object" &&
    v != null &&
    "exists" in v &&
    typeof v.exists === "boolean"
  );
};

export const isCheckResetPasswordTokenResult = (
  v: any
): v is CheckResetPasswordTokenResult => {
  return v === "expired" || v === "invalid" || v === "valid";
};

export const isCheckResetPasswordTokenResponse = (
  v: any
): v is CheckResetPasswordTokenResponse => {
  return (
    typeof v === "object" &&
    v != null &&
    "token" in v &&
    isResetPasswordToken(v.token) &&
    "result" in v &&
    isCheckResetPasswordTokenResult(v.result)
  );
};

export const isWorkspaceAccessDeniedReason = (
  v: any
): v is WorkspaceAccessDeniedReason => {
  return (
    v === "AccountDisabled" || v === "WorkspaceDisabled" || v === "NoAccess"
  );
};

export const isWorkspaceAccessGranted = (
  v: any
): v is WorkspaceAccessGranted => {
  return (
    typeof v === "object" &&
    v != null &&
    "id" in v &&
    isWorkspaceId(v.id) &&
    "userId" in v &&
    isUserId(v.userId) &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isWorkspaceAlias(v.alias) &&
    (!("logo" in v) || typeof v.logo === "string" || v.logo === null) &&
    (!("pendingInvitationCodeIds" in v) ||
      (Array.isArray(v.pendingInvitationCodeIds) &&
        v.pendingInvitationCodeIds.every((i: any) => isInvitationCodeId(i))) ||
      v.pendingInvitationCodeIds === null) &&
    (!("pendingRoles" in v) ||
      (Array.isArray(v.pendingRoles) &&
        v.pendingRoles.every((i: any) => isRole(i))) ||
      v.pendingRoles === null)
  );
};

export const isWorkspaceAccessUnapproved = (
  v: any
): v is WorkspaceAccessUnapproved => {
  return (
    typeof v === "object" &&
    v != null &&
    "id" in v &&
    isWorkspaceId(v.id) &&
    "userId" in v &&
    isUserId(v.userId) &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isWorkspaceAlias(v.alias) &&
    (!("logo" in v) || typeof v.logo === "string" || v.logo === null) &&
    "pendingInvitationCodeIds" in v &&
    Array.isArray(v.pendingInvitationCodeIds) &&
    v.pendingInvitationCodeIds.every((i: any) => isInvitationCodeId(i)) &&
    "pendingRoles" in v &&
    Array.isArray(v.pendingRoles) &&
    v.pendingRoles.every((i: any) => isRole(i))
  );
};

export const isWorkspaceAccessDenied = (v: any): v is WorkspaceAccessDenied => {
  return (
    typeof v === "object" &&
    v != null &&
    "id" in v &&
    isWorkspaceId(v.id) &&
    "reason" in v &&
    isWorkspaceAccessDeniedReason(v.reason) &&
    "userId" in v &&
    isUserId(v.userId) &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isWorkspaceAlias(v.alias) &&
    (!("logo" in v) || typeof v.logo === "string" || v.logo === null)
  );
};

export const isCheckWorkspaceAccessResult = (
  v: any
): v is CheckWorkspaceAccessResult => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "WorkspaceAccessDenied" && isWorkspaceAccessDenied(v)) ||
      (v.type === "WorkspaceAccessGranted" && isWorkspaceAccessGranted(v)) ||
      (v.type === "WorkspaceAccessUnapproved" &&
        isWorkspaceAccessUnapproved(v)))
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
