// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessOutflowStructure = "Absent" | "Present";

const Absent: NeLakeNaturalnessOutflowStructure = "Absent";
const Present: NeLakeNaturalnessOutflowStructure = "Present";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessOutflowStructureEnum = {
  ...createEnum("NeLakeNaturalnessOutflowStructure", [
    [Absent, "Absent"],
    [Present, "Present"],
  ]),
  Absent,
  Present,
};

export default NeLakeNaturalnessOutflowStructureEnum;
