import { Result } from "@cartographerio/fp";
import {
  Feature,
  GeometryAtom,
  isFeatureF,
  isGeometry,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isBoolean,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { Timestamp } from "@cartographerio/types";
import { isPartialData, PartialData } from "../NeRiverNaturalness/parts";

export { PartialData, isPartialData };

interface RiverAreaAttributes {
  ea_wb_id?: string | null;
  headwater?: boolean | null;
}

function isRiverAreaAttributes(v: unknown): v is RiverAreaAttributes {
  return (
    isObject(v) &&
    hasOptionalKey(v, "ea_wb_id", isNullable(isString)) &&
    hasOptionalKey(v, "headwater", isNullable(isBoolean))
  );
}

export interface RiverAreaInfo {
  feature?: Feature<GeometryAtom, RiverAreaAttributes> | null;
}

export function isRiverAreaInfo(v: unknown): v is RiverAreaInfo {
  return (
    isObject(v) &&
    hasOptionalKey(
      v,
      "feature",
      isNullable(isFeatureF(isGeometry, isRiverAreaAttributes))
    )
  );
}

const g = Result.guard(isPartialData(isRiverAreaInfo), "PartialData");

function createDescription(...args: Array<string | null>): string {
  return args.filter(arg => arg != null).join(" ");
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    createDescription(
      data.site.feature?.properties.ea_wb_id ?? "Unknown Waterbody",
      data.site.feature?.properties.headwater ? "(Headwater)" : "(Larger River)"
    )
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.site?.feature?.geometry ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}

export function copyData(
  data: unknown
): Result<GuardError, PartialData<RiverAreaInfo>> {
  return g(data).map(data => ({
    ...data,
    // recorded: null,
    // site: {},
    // selections: {
    //   physical: null,
    //   physicalMeasures: [],
    //   otherPhysicalMeasures: null,
    //   hydrological: null,
    //   hydrologicalMeasures: [],
    //   otherHydrologicalMeasures: null,
    //   chemical: null,
    //   chemicalMeasures: [],
    //   otherChemicalMeasures: null,
    //   biological: null,
    //   biologicalMeasures: [],
    //   otherBiologicalMeasures: null,
    // },
    // notes: null,
  }));
}
