// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityRainfall = "None" | "Light" | "Heavy";

const None: EsrtWaterQualityRainfall = "None";
const Light: EsrtWaterQualityRainfall = "Light";
const Heavy: EsrtWaterQualityRainfall = "Heavy";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityRainfallEnum = {
  ...createEnum("EsrtWaterQualityRainfall", [
    [None, "None"],
    [Light, "Light rain/showers"],
    [Heavy, "Heavy or prolonged rain"],
  ]),
  None,
  Light,
  Heavy,
};

export default EsrtWaterQualityRainfallEnum;
