import { unsafeTagNumber } from "@cartographerio/util";
import { CurrencyAmount, Gbp } from "./types.generated";

export function unsafeCurrencyAmount(amount: number): CurrencyAmount {
  return unsafeTagNumber<"CurrencyAmount">(amount);
}

export function unsafeGbp(pence: number): Gbp {
  return unsafeTagNumber<"Gbp">(pence);
}
