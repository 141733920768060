// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopInOrOutflow =
  | "InactivePipe"
  | "ActivePipe"
  | "InactiveDitch"
  | "ActiveDitch"
  | "None";

const InactivePipe: UclPitStopInOrOutflow = "InactivePipe";
const ActivePipe: UclPitStopInOrOutflow = "ActivePipe";
const InactiveDitch: UclPitStopInOrOutflow = "InactiveDitch";
const ActiveDitch: UclPitStopInOrOutflow = "ActiveDitch";
const None: UclPitStopInOrOutflow = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopInOrOutflowEnum = {
  ...createEnum("UclPitStopInOrOutflow", [
    [InactivePipe, "Pipe present but not flowing"],
    [ActivePipe, "Pipe present and flowing"],
    [InactiveDitch, "Ditch present but not flowing"],
    [ActiveDitch, "Ditch present and flowing"],
    [None, "No evidence"],
  ]),
  InactivePipe,
  ActivePipe,
  InactiveDitch,
  ActiveDitch,
  None,
};

export default UclPitStopInOrOutflowEnum;
