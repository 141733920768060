// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityFlowLevel = "Surging" | "Steady" | "Slow" | "Still";

const Surging: EsrtWaterQualityFlowLevel = "Surging";
const Steady: EsrtWaterQualityFlowLevel = "Steady";
const Slow: EsrtWaterQualityFlowLevel = "Slow";
const Still: EsrtWaterQualityFlowLevel = "Still";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityFlowLevelEnum = {
  ...createEnum("EsrtWaterQualityFlowLevel", [
    [Surging, "Surging"],
    [Steady, "Steady"],
    [Slow, "Slow"],
    [Still, "Still"],
  ]),
  Surging,
  Steady,
  Slow,
  Still,
};

export default EsrtWaterQualityFlowLevelEnum;
