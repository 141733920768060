import {
  globalRoleLevel,
  parseRole,
  projectRoleLevel,
  Role,
  superuserRole,
  teamRoleLevel,
  workspaceRoleLevel,
  WorkspaceRoleName,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";

export function hasRole(roles: Role[], role: Role): boolean {
  if (roles.includes(superuserRole)) {
    return true;
  } else {
    const parts = parseRole(role);

    return roles.some(role2 => {
      const parts2 = parseRole(role2);
      switch (parts[0]) {
        case "G":
          return (
            parts2[0] === "G" &&
            globalRoleLevel(parts2[1]) >= globalRoleLevel(parts[1])
          );

        case "W":
          return (
            parts2[0] === "W" &&
            workspaceRoleLevel(parts2[1]) >= workspaceRoleLevel(parts[1]) &&
            parts2[2] === parts[2]
          );

        case "P":
          return (
            parts2[0] === "P" &&
            projectRoleLevel(parts2[1]) >= projectRoleLevel(parts[1]) &&
            parts2[2] === parts[2]
          );

        case "T":
          return (
            parts2[0] === "T" &&
            teamRoleLevel(parts2[1]) >= teamRoleLevel(parts[1]) &&
            parts2[2] === parts[2]
          );

        default:
          return checkExhausted(parts[0]);
      }
    });
  }
}

export function hasWorkspaceRole(
  roles: Role[],
  name: WorkspaceRoleName
): boolean {
  if (roles.includes(superuserRole)) {
    return true;
  } else {
    const level = workspaceRoleLevel(name);

    switch (name) {
      case "Disabled":
      case "Active":
      case "Admin": {
        const adminLevel = globalRoleLevel("Admin");

        return roles.some(role => {
          const parts = parseRole(role);
          return (
            parts != null &&
            ((parts[0] === "W" && workspaceRoleLevel(parts[1]) >= level) ||
              (parts[0] === "G" && globalRoleLevel(parts[1]) >= adminLevel))
          );
        });
      }

      case "Owner":
        return roles.some(role => {
          const parts = parseRole(role);
          return (
            parts != null &&
            parts[0] === "W" &&
            workspaceRoleLevel(parts[1]) >= level
          );
        });
    }
  }
}
