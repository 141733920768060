import { Tagged, unsafeTag } from "@cartographerio/util";
import { format } from "date-fns/format";
import { clamp, padStart } from "lodash";
import { yyyymmdd } from "./format";

export type PlainDate = Tagged<"PlainDate">;

const yyyymmddRegex =
  /^(?<year>\d{2}(\d{2})?)-(?<month>\d{1,2})-(?<day>\d{1,2})$/;
const ddmmyyyyRegex =
  /^(?<day>\d{1,2})\/(?<month>\d{1,2})\/(?<year>\d{2}(\d{2})?)$/;

export function unsafePlainDate(str: string): PlainDate {
  return unsafeTag(str);
}

export function plainDate(yyyy: number, mm: number, dd: number): PlainDate {
  return unsafePlainDate(
    format(
      new Date(
        clamp(
          yyyy < 50 ? 2000 + yyyy : yyyy < 1000 ? 1900 + yyyy : yyyy,
          0,
          9999
        ),
        mm - 1,
        dd
      ),
      yyyymmdd
    )
  );
}

export const isValidPlainDate = (value: unknown): value is PlainDate => {
  return typeof value === "string" && yyyymmddRegex.test(value);
};

export function ddmmyyyyToPlainDate(str: string): PlainDate | undefined {
  return ddmmyyyyRegex.test(str)
    ? unsafePlainDate(str.replace(ddmmyyyyRegex, "$<year>-$<month>-$<day>"))
    : undefined;
}

export function plainDateToDdmmyyyy(plainDate: PlainDate): string {
  return plainDate.replace(yyyymmddRegex, "$<day>/$<month>/$<year>");
}

export function parseAnyPlainDate(str: string): PlainDate | undefined {
  const match = ddmmyyyyRegex.exec(str) ?? yyyymmddRegex.exec(str);

  if (match == null) {
    return undefined;
  } else {
    let year = match.groups?.["year"] ?? "";

    if (year.length === 2) {
      year = Number(year) <= 50 ? `20${year}` : `19${year}`;
    }

    const month = padStart(match.groups?.["month"] ?? "1", 2, "0");
    const day = padStart(match.groups?.["day"] ?? "1", 2, "0");

    return unsafePlainDate([year, month, day].join("-"));
  }
}

export function plainDateToDate(plainDate: PlainDate): Date | undefined {
  const result = yyyymmddRegex.exec(plainDate);

  const date = new Date(
    Number(result?.groups?.["year"]),
    Number(result?.groups?.["month"]) - 1,
    Number(result?.groups?.["day"])
  );

  return !isNaN(date.getTime()) ? date : undefined;
}

export function dateToPlainDate(date: Date): PlainDate {
  return unsafePlainDate(format(date, yyyymmdd));
}

export function nowPlainDate(): PlainDate {
  return dateToPlainDate(new Date());
}
