// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type RiverflyBreachType = "Unconfirmed" | "Confirmed";

const Unconfirmed: RiverflyBreachType = "Unconfirmed";
const Confirmed: RiverflyBreachType = "Confirmed";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RiverflyBreachTypeEnum = {
  ...createEnum("RiverflyBreachType", [
    [Unconfirmed, "Unconfirmed Breach"],
    [Confirmed, "Confirmed Breach"],
  ]),
  Unconfirmed,
  Confirmed,
};

export default RiverflyBreachTypeEnum;
