// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiPollutionEvidence =
  | "none"
  | "sewageFungus"
  | "oilySheen"
  | "sewageRelatedLitter"
  | "unpleasantOdour"
  | "litterOrFlyTipping"
  | "foam"
  | "smotheringAlgae"
  | "deadFish"
  | "deadInvertebrates"
  | "other";

const None: WrtWestcountryCsiPollutionEvidence = "none";
const SewageFungus: WrtWestcountryCsiPollutionEvidence = "sewageFungus";
const OilySheen: WrtWestcountryCsiPollutionEvidence = "oilySheen";
const SewageRelatedLitter: WrtWestcountryCsiPollutionEvidence =
  "sewageRelatedLitter";
const UnpleasantOdour: WrtWestcountryCsiPollutionEvidence = "unpleasantOdour";
const LitterOrFlyTipping: WrtWestcountryCsiPollutionEvidence =
  "litterOrFlyTipping";
const Foam: WrtWestcountryCsiPollutionEvidence = "foam";
const SmotheringAlgae: WrtWestcountryCsiPollutionEvidence = "smotheringAlgae";
const DeadFish: WrtWestcountryCsiPollutionEvidence = "deadFish";
const DeadInvertebrates: WrtWestcountryCsiPollutionEvidence =
  "deadInvertebrates";
const Other: WrtWestcountryCsiPollutionEvidence = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiPollutionEvidenceEnum = {
  ...createEnum("WrtWestcountryCsiPollutionEvidence", [
    [None, "None"],
    [SewageFungus, "Sewage fungus"],
    [OilySheen, "Oily sheen"],
    [SewageRelatedLitter, "Sewage related litter"],
    [UnpleasantOdour, "Unpleasant odour"],
    [LitterOrFlyTipping, "Litter/fly-tipping"],
    [Foam, "Foam"],
    [SmotheringAlgae, "Smothering algae"],
    [DeadFish, "Dead fish"],
    [DeadInvertebrates, "Dead invertebrates"],
    [Other, "Other (please specify)"],
  ]),
  None,
  SewageFungus,
  OilySheen,
  SewageRelatedLitter,
  UnpleasantOdour,
  LitterOrFlyTipping,
  Foam,
  SmotheringAlgae,
  DeadFish,
  DeadInvertebrates,
  Other,
};

export default WrtWestcountryCsiPollutionEvidenceEnum;
