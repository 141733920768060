// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiWaterLevel = "high" | "average" | "low";

const High: WrtWestcountryCsiWaterLevel = "high";
const Average: WrtWestcountryCsiWaterLevel = "average";
const Low: WrtWestcountryCsiWaterLevel = "low";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiWaterLevelEnum = {
  ...createEnum("WrtWestcountryCsiWaterLevel", [
    [High, "High"],
    [Average, "Average"],
    [Low, "Low"],
  ]),
  High,
  Average,
  Low,
};

export default WrtWestcountryCsiWaterLevelEnum;
