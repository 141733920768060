// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityWildlife =
  | "None"
  | "Otter"
  | "WaterVole"
  | "Kingfisher"
  | "GreyWagtail"
  | "Heron"
  | "Fish"
  | "DragonfliesOrDamselflies"
  | "Duck"
  | "MoorhenOrCoot"
  | "Goose"
  | "Swan"
  | "Other";

const None: ArrtWaterQualityWildlife = "None";
const Otter: ArrtWaterQualityWildlife = "Otter";
const WaterVole: ArrtWaterQualityWildlife = "WaterVole";
const Kingfisher: ArrtWaterQualityWildlife = "Kingfisher";
const GreyWagtail: ArrtWaterQualityWildlife = "GreyWagtail";
const Heron: ArrtWaterQualityWildlife = "Heron";
const Fish: ArrtWaterQualityWildlife = "Fish";
const DragonfliesOrDamselflies: ArrtWaterQualityWildlife =
  "DragonfliesOrDamselflies";
const Duck: ArrtWaterQualityWildlife = "Duck";
const MoorhenOrCoot: ArrtWaterQualityWildlife = "MoorhenOrCoot";
const Goose: ArrtWaterQualityWildlife = "Goose";
const Swan: ArrtWaterQualityWildlife = "Swan";
const Other: ArrtWaterQualityWildlife = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityWildlifeEnum = {
  ...createEnum("ArrtWaterQualityWildlife", [
    [None, "None"],
    [Otter, "Otter"],
    [WaterVole, "Water vole"],
    [Kingfisher, "Kingfisher"],
    [GreyWagtail, "Grey Wagtail"],
    [Heron, "Heron"],
    [Fish, "Fish"],
    [DragonfliesOrDamselflies, "Dragonflies/Damselflies"],
    [Duck, "Duck"],
    [MoorhenOrCoot, "Moorhen/Coot"],
    [Goose, "Goose"],
    [Swan, "Swan"],
    [Other, "Other"],
  ]),
  None,
  Otter,
  WaterVole,
  Kingfisher,
  GreyWagtail,
  Heron,
  Fish,
  DragonfliesOrDamselflies,
  Duck,
  MoorhenOrCoot,
  Goose,
  Swan,
  Other,
};

export default ArrtWaterQualityWildlifeEnum;
