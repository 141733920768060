// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsManagementFeature =
  | "aquaticWeedCutting"
  | "dredging"
  | "mowingBanks"
  | "treePollarding"
  | "other";

const AquaticWeedCutting: UrsManagementFeature = "aquaticWeedCutting";
const Dredging: UrsManagementFeature = "dredging";
const MowingBanks: UrsManagementFeature = "mowingBanks";
const TreePollarding: UrsManagementFeature = "treePollarding";
const Other: UrsManagementFeature = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsManagementFeatureEnum = {
  ...createEnum("UrsManagementFeature", [
    [AquaticWeedCutting, "Aquatic Weed Cutting"],
    [Dredging, "Dredging"],
    [MowingBanks, "Mowing Banks"],
    [TreePollarding, "Tree Pollarding"],
    [Other, "Other (specify)"],
  ]),
  AquaticWeedCutting,
  Dredging,
  MowingBanks,
  TreePollarding,
  Other,
};

export default UrsManagementFeatureEnum;
