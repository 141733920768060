// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterSourceType =
  | "DisturbedBankFace"
  | "OverlandFlow"
  | "Ditch"
  | "Pipe"
  | "Culvert";

const DisturbedBankFace: MrsMudSpotterSourceType = "DisturbedBankFace";
const OverlandFlow: MrsMudSpotterSourceType = "OverlandFlow";
const Ditch: MrsMudSpotterSourceType = "Ditch";
const Pipe: MrsMudSpotterSourceType = "Pipe";
const Culvert: MrsMudSpotterSourceType = "Culvert";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterSourceTypeEnum = {
  ...createEnum("MrsMudSpotterSourceType", [
    [DisturbedBankFace, "Disturbed Bank Face"],
    [OverlandFlow, "Overland Flow"],
    [Ditch, "Ditch"],
    [Pipe, "Pipe"],
    [Culvert, "Culvert"],
  ]),
  DisturbedBankFace,
  OverlandFlow,
  Ditch,
  Pipe,
  Culvert,
};

export default MrsMudSpotterSourceTypeEnum;
