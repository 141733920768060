import {
  topoExpr,
  TopoExpr,
  attachmentField,
  columns2,
  enumSelectOptions,
  form,
  grid,
  integerField,
  minValue,
  multiSelect,
  page,
  pointField,
  repeat,
  required,
  section,
  select,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import {
  SelectOption,
  Thames21ForeshoreEnum,
  Thames21LitterCleanupFocusEnum,
  Thames21LitterItem,
  Thames21LitterItemEnum,
  Thames21LitterMetric,
  Thames21LitterPolystyreneCoverEnum,
  Thames21LitterSmallPlasticCountEnum,
  Thames21LitterSurveyTypeEnum,
  Thames21RiverEnum,
} from "@cartographerio/inventory-enums";
import { litterItemLabel } from "./litterItemLabels";
import { litterItemMetric } from "./litterItemMetric";

function litterItemOptions(): SelectOption<Thames21LitterItem | null>[] {
  return Thames21LitterItemEnum.values.map(item => ({
    value: item,
    label: litterItemLabel(item),
  }));
}

function itemHasMetric(metric: Thames21LitterMetric): TopoExpr<boolean> {
  return topoExpr(env =>
    env
      .getAs(["item"], Thames21LitterItemEnum.isValue)
      .map(item => litterItemMetric(item) === metric)
      .getOrElse(() => false)
  );
}

export default form({
  title: "Invasive Species",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "River",
                  path: ["data", "river"],
                  options: Thames21RiverEnum.entries,
                  placeholder: "Type to select a river...",
                  appearance: "searchable",
                  required: required("info"),
                }),
                textField({
                  label: "Collected On Behalf Of",
                  path: ["data", "collectedOnBehalfOf"],
                }),
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                  defaultValue: "now",
                  required: required("info"),
                }),
                pointField({
                  label: "Location",
                  path: ["data", "location"],
                  help: "Location of the cleanup site.",
                  fullWidth: true,
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Litter Survey Details",
          path: [],
          blocks: [
            select({
              label: "Survey Type",
              path: ["data", "litterSurveyType"],
              options: enumSelectOptions(Thames21LitterSurveyTypeEnum),
              required: required(),
            }),
            textField({
              label: "Site Name",
              path: ["data", "siteName"],
              required: required("info"),
            }),
            select({
              label: "Foreshore",
              path: ["data", "foreshore"],
              options: enumSelectOptions(Thames21ForeshoreEnum),
            }),
            integerField({
              label: "Survey Duration",
              path: ["data", "litterSurveyDuration"],
              units: "mins",
              bounds: minValue(0),
            }),
            multiSelect({
              label: "Cleanup Focus",
              path: ["data", "cleanupFocus"],
              options: enumSelectOptions(Thames21LitterCleanupFocusEnum),
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Quadrats",
          path: [],
          blocks: [
            repeat({
              path: ["data", "quadrats"],
              appearance: "card",
              confirmOnDelete: true,
              defaultLength: 1,
              addButtonLabel: "Add Quadrat",
              emptyPrompt: 'Click "Add" to add a quadrat',
              deletePrompt: "Delete this quadrat?",
              block: vstack(
                repeat({
                  path: ["collected"],
                  appearance: "compact",
                  confirmOnDelete: false,
                  addMultiple: 5,
                  defaultLength: 5,
                  addButtonLabel: "Add Item(s)",
                  emptyPrompt: 'Click "Add" to add a litter item',
                  deletePrompt: "Delete this item?",
                  block: columns2(
                    select({
                      label: null,
                      placeholder: "Type to select a litter item...",
                      path: ["item"],
                      options: litterItemOptions(),
                      required: required("info"),
                      appearance: "searchable",
                    }),
                    integerField({
                      label: null,
                      placeholder: "Count",
                      path: ["count"],
                      required: required("info"),
                      bounds: minValue(0),
                      visible: itemHasMetric("count"),
                    }),
                    select({
                      label: null,
                      placeholder: "- Select presence -",
                      path: ["present"],
                      options: [
                        { value: true, label: "Present" },
                        { value: false, label: "Absent" },
                      ],
                      required: required("info"),
                      visible: itemHasMetric("presence"),
                    }),
                    select({
                      label: null,
                      placeholder: "- Select cover -",
                      path: ["polystyreneCover"],
                      options: enumSelectOptions(
                        Thames21LitterPolystyreneCoverEnum
                      ),
                      required: required("info"),
                      visible: itemHasMetric("polystyreneCover"),
                    }),
                    select({
                      label: null,
                      placeholder: "- Select count -",
                      path: ["smallPlasticCount"],
                      options: enumSelectOptions(
                        Thames21LitterSmallPlasticCountEnum
                      ),
                      required: required("info"),
                      visible: itemHasMetric("smallPlasticCount"),
                    })
                  ),
                }),
                textArea({
                  label: "Other Litter",
                  path: ["otherLitter"],
                  rows: 5,
                  help: "Please describe",
                })
              ),
            }),
          ],
        }),
        section({
          title: "Bottle Count Data",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "bottleCountData"],
              rows: 5,
            }),
          ],
        }),
        section({
          title: "Other Surveyors",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "otherSurveyors"],
              rows: 5,
              help: "Name any other surveyors involved.",
            }),
          ],
        }),
        section({
          title: "Other Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 5,
              help: "Observations of behaviour, litter left behind, etc.",
            }),
          ],
        }),
      ],
    }),
  ],
});
