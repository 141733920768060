// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusStudyAreaAccessProvision =
  | "pedestrian"
  | "busServices"
  | "carParks"
  | "cycleRacks"
  | "wheelChair"
  | "horses";

const Pedestrian: EcostatusStudyAreaAccessProvision = "pedestrian";
const BusServices: EcostatusStudyAreaAccessProvision = "busServices";
const CarParks: EcostatusStudyAreaAccessProvision = "carParks";
const CycleRacks: EcostatusStudyAreaAccessProvision = "cycleRacks";
const WheelChair: EcostatusStudyAreaAccessProvision = "wheelChair";
const Horses: EcostatusStudyAreaAccessProvision = "horses";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusStudyAreaAccessProvisionEnum = {
  ...createEnum("EcostatusStudyAreaAccessProvision", [
    [Pedestrian, "Pedestrian"],
    [BusServices, "Bus Services"],
    [CarParks, "Car Parks"],
    [CycleRacks, "Cycle Racks"],
    [WheelChair, "Wheel Chair"],
    [Horses, "Horses"],
  ]),
  Pedestrian,
  BusServices,
  CarParks,
  CycleRacks,
  WheelChair,
  Horses,
};

export default EcostatusStudyAreaAccessProvisionEnum;
