// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { MapBase } from "../types.generated";

const Terrain: MapBase = "terrain";
const Satellite: MapBase = "satellite";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MapBaseEnum = {
  ...createEnum("MapBase", [
    [Terrain, "Terrain"],
    [Satellite, "Satellite"],
  ]),
  Terrain,
  Satellite,
};

export default MapBaseEnum;
