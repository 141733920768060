// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopDogs = "High" | "Medium" | "None";

const High: UclPitStopDogs = "High";
const Medium: UclPitStopDogs = "Medium";
const None: UclPitStopDogs = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopDogsEnum = {
  ...createEnum("UclPitStopDogs", [
    [High, "High - Evidence of dogs entering the pond during visit"],
    [Medium, "Medium - Evidence of dog pawprints around pond"],
    [None, "None - No evidence of use by dogs at pond"],
  ]),
  High,
  Medium,
  None,
};

export default UclPitStopDogsEnum;
