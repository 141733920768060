// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsWildInvadersAccessibleBy = "Foot" | "Boat" | "Other";

const Foot: MrsWildInvadersAccessibleBy = "Foot";
const Boat: MrsWildInvadersAccessibleBy = "Boat";
const Other: MrsWildInvadersAccessibleBy = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsWildInvadersAccessibleByEnum = {
  ...createEnum("MrsWildInvadersAccessibleBy", [
    [Foot, "Foot"],
    [Boat, "Boat"],
    [Other, "Other"],
  ]),
  Foot,
  Boat,
  Other,
};

export default MrsWildInvadersAccessibleByEnum;
