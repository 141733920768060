import { IO } from "@cartographerio/io";
import {
  ApiParams,
  PricingCalculation,
  isPricingCalculation,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";
import { CalculationReadOptions } from "./common";

const basePath = "/billing/calculation/v1";

export function readOrFail(
  apiParams: ApiParams,
  options: CalculationReadOptions
): IO<PricingCalculation> {
  return fetch
    .get({ apiParams, url: { path: basePath, query: { ...options } } })
    .flatMap(contentAs("PricingCalculation", isPricingCalculation));
}
