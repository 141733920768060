import { Email } from "./types.generated";

const EMAIL_REGEX = /^([^@]+)@([^@]+)$/;

export function isValidEmail(email: string): email is Email {
  return EMAIL_REGEX.test(email);
}

export function emailDomain(email: Email): string | null {
  const parts = email.match(EMAIL_REGEX);
  return parts == null ? null : parts[2];
}
