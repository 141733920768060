// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21LitterCleanupFocus =
  | "thamesForeshore"
  | "bank"
  | "footpath"
  | "canalChannel"
  | "riverChannel"
  | "surroundingArea";

const ThamesForeshore: Thames21LitterCleanupFocus = "thamesForeshore";
const Bank: Thames21LitterCleanupFocus = "bank";
const Footpath: Thames21LitterCleanupFocus = "footpath";
const CanalChannel: Thames21LitterCleanupFocus = "canalChannel";
const RiverChannel: Thames21LitterCleanupFocus = "riverChannel";
const SurroundingArea: Thames21LitterCleanupFocus = "surroundingArea";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21LitterCleanupFocusEnum = {
  ...createEnum("Thames21LitterCleanupFocus", [
    [ThamesForeshore, "Thames foreshore"],
    [Bank, "Bank"],
    [Footpath, "Footpath"],
    [CanalChannel, "Canal channel"],
    [RiverChannel, "River channel"],
    [SurroundingArea, "Surrounding area"],
  ]),
  ThamesForeshore,
  Bank,
  Footpath,
  CanalChannel,
  RiverChannel,
  SurroundingArea,
};

export default Thames21LitterCleanupFocusEnum;
