import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isUrlResponse,
  UrlResponse,
  WorkspaceRef,
} from "@cartographerio/types";

import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";
import { CheckoutStartParamsV1 } from "./common";

const basePath = "/billing/checkout/v1";

export function startUrl(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  { plan, interval }: CheckoutStartParamsV1
): IO<UrlResponse> {
  const url = {
    path: `${basePath}/${workspaceRef}`,
    query: { plan, interval },
  };
  return fetch
    .get({ apiParams, url })
    .flatMap(contentAs("UrlResponse", isUrlResponse));
}
