// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesGrazing = "L" | "M" | "H";

const Light: MrsMorphEstuariesGrazing = "L";
const Medium: MrsMorphEstuariesGrazing = "M";
const Heavy: MrsMorphEstuariesGrazing = "H";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesGrazingEnum = {
  ...createEnum("MrsMorphEstuariesGrazing", [
    [Light, "Light"],
    [Medium, "Medium"],
    [Heavy, "Heavy"],
  ]),
  Light,
  Medium,
  Heavy,
};

export default MrsMorphEstuariesGrazingEnum;
