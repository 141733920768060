// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsArtificialFeatureCategory =
  | "bridge"
  | "weirOrSluice"
  | "deflectorOrGroyne"
  | "culvert"
  | "other";

const Bridge: UrsArtificialFeatureCategory = "bridge";
const WeirOrSluice: UrsArtificialFeatureCategory = "weirOrSluice";
const DeflectorOrGroyne: UrsArtificialFeatureCategory = "deflectorOrGroyne";
const Culvert: UrsArtificialFeatureCategory = "culvert";
const Other: UrsArtificialFeatureCategory = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsArtificialFeatureCategoryEnum = {
  ...createEnum("UrsArtificialFeatureCategory", [
    [Bridge, "Bridge"],
    [WeirOrSluice, "Weir / sluice"],
    [DeflectorOrGroyne, "Deflector / groyne"],
    [Culvert, "Culvert"],
    [Other, "Other"],
  ]),
  Bridge,
  WeirOrSluice,
  DeflectorOrGroyne,
  Culvert,
  Other,
};

export default UrsArtificialFeatureCategoryEnum;
