import { errorMessage, Message } from "@cartographerio/topo-core";
import lodash from "lodash";
import { isField } from "./guard";
import { Block, Form, Page } from "./type";
import { isString } from "@cartographerio/guard";
import { checkExhausted } from "@cartographerio/util";

function findDuplicates(strings: string[]): string[] {
  const totals = strings.reduce(
    (acc: Record<string, number>, str) => ({
      ...acc,
      [str]: acc[str] ?? 0 + 1,
    }),
    {}
  );

  return Object.entries(totals)
    .filter(([_str, total]) => total > 1)
    .map(([str]) => str);
}

interface CheckOutput {
  messages: Message[];
  lastHeading?: string | null;
}

function checkBlockLabels(
  pageNumber: number,
  block: Block,
  lastHeading?: string | null
): CheckOutput {
  if (isField(block)) {
    return lastHeading === block.label
      ? {
          messages: [
            errorMessage(
              `Page ${pageNumber} has a field label following a heading with the same name: "${lastHeading}"`
            ),
          ],
        }
      : { messages: [] };
  } else {
    switch (block.type) {
      case "Heading":
        return { messages: [], lastHeading: block.text };
      case "Card":
      case "FullWidth":
      case "RequireRole":
      case "Repeat":
      case "Scope":
      case "Visibility":
        return checkBlockLabels(pageNumber, block.block, lastHeading);
      case "Group":
        return checkBlocksLabels(pageNumber, block.blocks, lastHeading);
      case "Text":
      case "DynamicValue":
      case "DynamicImage":
      case "DynamicGrid":
      case "PrimarySurveyorField":
      case "PrimaryTeamField":
        return { messages: [], lastHeading };
      default:
        return checkExhausted(block);
    }
  }
}

function checkBlocksLabels(
  pageNumber: number,
  blocks: Block[],
  lastHeading?: string | null
): CheckOutput {
  return blocks.reduce(
    (acc: CheckOutput, block) => {
      const output = checkBlockLabels(pageNumber, block, lastHeading);
      return {
        messages: acc.messages.concat(output.messages),
        lastHeading: output.lastHeading,
      };
    },
    { messages: [] }
  );
}

function checkPageLabels(page: Page, pageIndex: number): Message[] {
  const firstHeadingSame: Message[] =
    page.blocks.length === 1 &&
    page.blocks[0].type === "Heading" &&
    page.blocks[0].text === page.title
      ? [
          errorMessage(
            `Page ${
              pageIndex + 1
            } has a heading at the top with the same title as the page: ${
              page.title
            }`
          ),
        ]
      : [];

  return [
    ...firstHeadingSame,
    ...checkBlocksLabels(pageIndex + 1, page.blocks).messages,
  ];
}

export function checkFormLabels(form: Form): Message[] {
  const singletonPageTitle: Message[] =
    form.pages.length === 1 &&
    form.pages[0].title != null &&
    form.pages[0].title === form.title
      ? [
          errorMessage(
            `Form has a single page with the same title as the form: ${form.title}`
          ),
        ]
      : [];

  const uniquePageTitles: Message[] = findDuplicates(
    form.pages.map(page => page.title).filter(isString)
  ).map(title => errorMessage(`Duplicate Page title: ${title}`));

  const pages: Message[] = lodash.flatMap(form.pages, (page, pageIndex) =>
    checkPageLabels(page, pageIndex)
  );

  return [...singletonPageTitle, ...uniquePageTitles, ...pages];
}
