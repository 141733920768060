// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsEcologicalCategory =
  | "aquaticInvertebrate"
  | "terrestrialInvertebrate"
  | "mammal"
  | "amphibianOrReptile"
  | "bird"
  | "fish"
  | "tree"
  | "plant";

const AquaticInvertebrate: UrsEcologicalCategory = "aquaticInvertebrate";
const TerrestrialInvertebrate: UrsEcologicalCategory =
  "terrestrialInvertebrate";
const Mammal: UrsEcologicalCategory = "mammal";
const AmphibianOrReptile: UrsEcologicalCategory = "amphibianOrReptile";
const Bird: UrsEcologicalCategory = "bird";
const Fish: UrsEcologicalCategory = "fish";
const Tree: UrsEcologicalCategory = "tree";
const Plant: UrsEcologicalCategory = "plant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsEcologicalCategoryEnum = {
  ...createEnum("UrsEcologicalCategory", [
    [AquaticInvertebrate, "Aquatic invertebrates"],
    [TerrestrialInvertebrate, "Terrestrial invertebrates"],
    [Mammal, "Mammals"],
    [AmphibianOrReptile, "Amphibians / reptiles"],
    [Bird, "Birds"],
    [Fish, "Fish"],
    [Tree, "Trees"],
    [Plant, "Plants"],
  ]),
  AquaticInvertebrate,
  TerrestrialInvertebrate,
  Mammal,
  AmphibianOrReptile,
  Bird,
  Fish,
  Tree,
  Plant,
};

export default UrsEcologicalCategoryEnum;
