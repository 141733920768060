import {
  QualificationRole,
  Role,
  optParseQualificationRole,
  qualificationRoleLevel,
  superuserRole,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";

export default function hasQualification(
  roles: Role[],
  qualRoles: QualificationRole[],
  qualRole: QualificationRole
): boolean {
  if (roles.includes(superuserRole)) {
    return true;
  } else {
    const minRoleParts = optParseQualificationRole(qualRole);

    return (
      minRoleParts != null &&
      filterAndMap(qualRoles, optParseQualificationRole).some(
        ownParts =>
          ownParts != null &&
          minRoleParts[1] === ownParts[1] &&
          qualificationRoleLevel(ownParts[0]) >=
            qualificationRoleLevel(minRoleParts[0])
      )
    );
  }
}
