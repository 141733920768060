import {
  attributeGroup,
  lookupBucket,
  marker,
  pointLayer,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const MrsWildInvaders = mapSchemaWithDefaults({
  mapId: unsafeMapId("mrsWildInvaders"),
  title: "Wild Invaders",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("mrsWildInvaders"),
      title: "Invasive Species",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("mrsWildInvaders"),
        attribution: [],
      }),
      defaultAttribute: "species",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("mrsWildInvaders"),
            }),
            surveyAttr("surveyId", "Survey"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Invasive Species",
          attributes: [
            stringAttr({
              attributeId: "species",
              label: "Species",
              buckets: [
                lookupBucket(
                  "HimalayanBalsam",
                  marker.lookup(1),
                  "Flora - Himalayan Balsam"
                ),
                lookupBucket(
                  "GiantHogweed",
                  marker.lookup(2),
                  "Flora - Giant Hogweed"
                ),
                lookupBucket(
                  "JapaneseKnotweed",
                  marker.lookup(3),
                  "Flora - Japanese Knotweed"
                ),
                lookupBucket(
                  "FloatingPennywort",
                  marker.lookup(4),
                  "Flora - Floating Pennywort"
                ),
                lookupBucket("OtherFlora", marker.lookup(5), "Flora - Other"),
                lookupBucket(
                  "SignalCrayfish",
                  marker.lookup(6),
                  "Fauna - Signal Crayfish"
                ),
                lookupBucket(
                  "KillerShrimp",
                  marker.lookup(7),
                  "Fauna - Killer Shrimp"
                ),
                lookupBucket(
                  "QuaggaMussel",
                  marker.lookup(8),
                  "Fauna - Quagga Mussel"
                ),
                lookupBucket(
                  "ChineseMittenCrab",
                  marker.lookup(9),
                  "Fauna - Chines Mitten Crab"
                ),
                lookupBucket("OtherFauna", marker.lookup(10), "Fauna - Other"),
              ],
            }),
            stringAttr({
              attributeId: "growthStage",
              label: "Growth Stage",
              buckets: [
                lookupBucket(
                  "SeedlingOrLarvae",
                  marker.lookup(1),
                  "Seedling or larvae"
                ),
                lookupBucket("Juvenile", marker.lookup(2), "Juvenile"),
                lookupBucket("Adult", marker.lookup(3), "Adult"),
                lookupBucket("DeadOrDying", marker.lookup(4), "Dead or dying"),
              ],
            }),
            stringAttr({
              attributeId: "coverage",
              label: "Coverage",
              buckets: [
                lookupBucket(
                  "VerySmall",
                  marker.lookup(1),
                  "Very small (isolated specimens)"
                ),
                lookupBucket("Small", marker.lookup(2), "Small (<1 m2)"),
                lookupBucket("Medium", marker.lookup(3), "Medium (1-5 m2)"),
                lookupBucket("Large", marker.lookup(4), "Large (5-50 m2)"),
                lookupBucket(
                  "VeryLarge",
                  marker.lookup(5),
                  "Very large (>50 m2)"
                ),
              ],
            }),
            stringAttr({
              attributeId: "management",
              label: "Management",
              buckets: [
                lookupBucket(
                  "NoSign",
                  marker.lookup(1),
                  "No sign of treatment"
                ),
                lookupBucket(
                  "RemovedManually",
                  marker.lookup(2),
                  "Removed by hand"
                ),
                lookupBucket(
                  "RemovedMechanically",
                  marker.lookup(3),
                  "Removed mechanically"
                ),
                lookupBucket("DugOut", marker.lookup(4), "Dug out"),
                lookupBucket("Sprayed", marker.lookup(5), "Sprayed"),
                lookupBucket("StemInjected", marker.lookup(6), "Stem injected"),
                lookupBucket("Other", marker.lookup(7), "Other treatment"),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
