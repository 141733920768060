import {
  SelectOption,
  SelectSection,
  selectOption,
} from "@cartographerio/topo-form";
import { Option } from "@cartographerio/fp";
import {
  AnonymisedUser,
  TeamId,
  Team,
  UserId,
  UserRef,
  roleGte,
  teamSurveyorRole,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";

interface CalcPrimarySurveyorOptionsProps {
  users: AnonymisedUser[];
  teams: Team[];
  initialSurveyor: UserRef | null;
  primarySurveyor: UserRef | null;
  primaryTeamId: TeamId | null;
}

/**
 * Collects a list of SelectSections for the primary surveyor field,
 * sorted in the order they should appear in the list of options.
 */
export function calcPrimarySurveyorOptions(
  props: CalcPrimarySurveyorOptionsProps
): SelectSection<UserId>[] {
  const { users, teams, initialSurveyor, primarySurveyor, primaryTeamId } =
    props;

  const selectedSection: SelectSection<UserId> = {
    heading: "Current Surveyor",
    options: arrayify(
      userRefToOption(primarySurveyor, "Current Surveyor", "current-user")
    ),
  };

  const seenUserIds: Set<UserId> = new Set(
    primarySurveyor?.userId != null ? [primarySurveyor.userId] : []
  );

  const teamSections = teams.map((team): [TeamId, SelectSection<UserId>] => [
    team.id,
    {
      heading: team.name,
      options: users
        .filter(({ roles }) =>
          roles.some(role => roleGte(role, teamSurveyorRole(team.id)))
        )
        .map(user => {
          seenUserIds.add(user.id);
          return userToOption(user, team.id);
        }),
    },
  ]);

  const selectedTeamSection = teamSections.find(
    ([id]) => id === primaryTeamId
  )?.[1];

  const otherTeamSections = filterAndMap(teamSections, ([id, section]) =>
    id !== primaryTeamId ? section : null
  );

  const restSection: SelectSection<UserId> = {
    heading: "Other Users",
    options: users
      .filter(({ id }) => !seenUserIds.has(id))
      .map(user => userToOption(user, "other-users")),
  };

  const originalSection: SelectSection<UserId> = {
    heading: "Original Surveyor",
    options:
      initialSurveyor?.userId != null && seenUserIds.has(initialSurveyor.userId)
        ? []
        : arrayify(
            userRefToOption(
              initialSurveyor,
              "Original Surveyor",
              "original-user"
            )
          ),
  };

  return [
    selectedSection,
    originalSection,
    ...(selectedTeamSection != null ? [selectedTeamSection] : []),
    ...otherTeamSections,
    restSection,
  ].filter(({ options: items }) => items.length > 0);
}

function userRefToOption(
  ref: UserRef | null,
  description: string,
  keyPrefix?: string
): SelectOption<UserId> | null {
  return ref?.userId == null
    ? null
    : selectOption(
        ref.userId,
        ref.screenName ?? `User ${ref.userId} (${description})`,
        Option.wrap(ref.userId)
          .nullMap(id => (keyPrefix != null ? `${keyPrefix}.${id}` : id))
          .getOrUndefined()
      );
}

function userToOption(
  user: AnonymisedUser,
  keyPrefix?: string
): SelectOption<UserId> {
  return {
    label: user.screenName,
    value: user.id,
    key: keyPrefix != null ? `${keyPrefix}.${user.id}` : user.id,
  };
}

function arrayify<A>(value: A | null | undefined): A[] {
  return value == null ? [] : [value];
}
