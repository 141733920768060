// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRiverWoodArtificialElement = "ST" | "MW" | "PT" | "WP" | "Other";

const Stakes: MrsRiverWoodArtificialElement = "ST";
const MetalWires: MrsRiverWoodArtificialElement = "MW";
const PlasticTies: MrsRiverWoodArtificialElement = "PT";
const WoodPieces: MrsRiverWoodArtificialElement = "WP";
const Other: MrsRiverWoodArtificialElement = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRiverWoodArtificialElementEnum = {
  ...createEnum("MrsRiverWoodArtificialElement", [
    [Stakes, "Stakes pushed into the stream bed/banks"],
    [MetalWires, "Metal wires"],
    [PlasticTies, "Plastic ties"],
    [WoodPieces, "Treated/trimmed wood pieces/sheets"],
    [Other, "Other artificial element"],
  ]),
  Stakes,
  MetalWires,
  PlasticTies,
  WoodPieces,
  Other,
};

export default MrsRiverWoodArtificialElementEnum;
