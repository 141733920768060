// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPondCountWhyMissed = "A" | "M" | "I" | "U";

const NotAccessible: FhtPondCountWhyMissed = "A";
const NotSeen: FhtPondCountWhyMissed = "M";
const Misidentified: FhtPondCountWhyMissed = "I";
const Unknown: FhtPondCountWhyMissed = "U";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPondCountWhyMissedEnum = {
  ...createEnum("FhtPondCountWhyMissed", [
    [NotAccessible, "Pond was not previously accessible"],
    [NotSeen, "Pond probably missed / not seen before"],
    [Misidentified, "Pond probably misidentified as another habitat type"],
    [Unknown, "Reason not clear"],
  ]),
  NotAccessible,
  NotSeen,
  Misidentified,
  Unknown,
};

export default FhtPondCountWhyMissedEnum;
