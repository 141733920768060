import { isArray } from "@cartographerio/guard";
import {
  PermissionCheck,
  ProjectId,
  ProjectPermissionModel,
  ProjectRoleName,
  Project,
  SurveyId,
  SurveyStatus,
  TeamId,
  UserRef,
  WorkspaceId,
  projectRole,
  qualificationTraineeRole,
  teamRole,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { check } from "../check";
import { always, never } from "../check/create";

// Survey stripped of all unused fields
interface PartialSurvey {
  id?: SurveyId;
  status: SurveyStatus;
  teamId?: TeamId | null;
  surveyor?: UserRef | null;
}

const updateableStatuses: SurveyStatus[] = ["draft", "complete", "review"];

const failSurveyLocked = check.never(
  "Approved surveys cannot be modified. Unapprove the survey first"
);

export function view(project?: Project | null): PermissionCheck {
  if (project == null) {
    return check.named(
      `List/view surveys across all projects`,
      check.globalAdmin
    );
  } else {
    const named = (body: PermissionCheck) =>
      check.named(`List/view surveys in project ${project.id}`, body);

    switch (project.projectVisibility) {
      case "Private":
        return named(
          check.and(
            check.workspaceReadOnlyAccess(project.workspaceId),
            check.projectMember(
              project.id,
              project.workspaceId,
              project.teamIds
            )
          )
        );

      case "Workspace":
        return named(
          check.and(
            check.workspaceReadOnlyAccess(project.workspaceId),
            check.workspaceActive(project.workspaceId)
          )
        );

      default:
        return checkExhausted(project.projectVisibility);
    }
  }
}

export function hasRequiredRole(
  roleName: ProjectRoleName,
  project: Project,
  teamId?: TeamId | null
): PermissionCheck {
  return check.named(
    "Has required role",
    check.or(
      check.workspaceAdmin(project.workspaceId),
      check.hasRole(projectRole(roleName, project.id)),
      teamId == null ? check.never() : check.hasRole(teamRole(roleName, teamId))
    )
  );
}

export function createWithAnyTeam(project: Project): PermissionCheck {
  return check.named(
    `Create surveys in ${project.name} (any team)`,
    check.and(
      check.workspaceFullAccess(project.workspaceId),
      hasProjectQualifications(project),
      check.projectSurveyor(
        project.permissionModel,
        project.id,
        project.workspaceId,
        project.teamIds
      )
    )
  );
}

export function createWithTeam(
  project: Project,
  teamId: TeamId | null
): PermissionCheck {
  return check.named(
    `Create surveys in ${project.name} (${
      teamId == null ? "no team" : teamId
    })`,
    surveyUpdateableOr({
      project,
      survey: { teamId, status: "draft" },
      surveyorCheck: always,
      firstPartyCheck: check.projectSurveyor,
      thirdPartyCheck: check.projectApprover,
      orElse: failSurveyLocked,
    })
  );
}

export function copy(
  fromProject: Project,
  toProject: Project,
  fromTeamId: TeamId | null,
  toTeamId: TeamId | null
): PermissionCheck {
  const description: string = [
    `Copy survey from ${fromProject.name} `,
    `(${fromTeamId ? `team ${fromTeamId}` : "no team"}) `,
    `to project ${toProject.name} `,
    `(${toTeamId ? `team ${toTeamId}` : "no team"})`,
  ].join(" ");

  return check.named(
    description,
    check.and(view(fromProject), createWithTeam(toProject, toTeamId))
  );
}

export function updateStatus(
  project: Project,
  survey: PartialSurvey
): PermissionCheck {
  const description: string = [
    `Change status of survey ${survey.id}`,
    `in project ${project.name}`,
    `(${survey.teamId ? `team ${survey.teamId}` : `no team`})`,
    `(currently ${survey.status})`,
  ].join(" ");

  return check.named(
    description,
    surveyUpdateableOr({
      project,
      survey,
      surveyorCheck: userIsSurveyor(survey.surveyor),
      firstPartyCheck: check.projectSurveyor,
      thirdPartyCheck: check.projectApprover,
      orElse: check.and(
        check.workspaceFullAccess(project.workspaceId),
        hasProjectQualifications(project),
        check.projectApprover(
          project.permissionModel,
          project.id,
          project.workspaceId,
          survey.teamId
        )
      ),
    })
  );
}

export function submit(
  project: Project,
  teamId: TeamId | null
): PermissionCheck {
  return check.named(
    [
      `Submit survey in project ${project.name}`,
      `(${teamId ? `team ${teamId}` : `no team`})`,
    ].join(""),
    check.and(
      check.workspaceFullAccess(project.workspaceId),
      check.projectSurveyor(
        project.permissionModel,
        project.id,
        project.workspaceId,
        teamId
      )
    )
  );
}

export function approveWithAnyTeam(project: Project): PermissionCheck {
  const description = `Approve surveys in project ${project.name} (any team)`;

  return check.named(
    description,
    check.and(
      check.workspaceFullAccess(project.workspaceId),
      check.projectApprover(
        project.permissionModel,
        project.id,
        project.workspaceId,
        project.teamIds
      )
    )
  );
}

export function approveWithTeam(
  project: Project,
  teamIds: TeamId[] | TeamId | null
): PermissionCheck {
  const teamDescription = isArray(teamIds)
    ? teamIds.length === 0
      ? "no team"
      : teamIds.join(", ")
    : teamIds == null
      ? "no team"
      : teamIds;

  const description = `Approve survey in project ${project.name} (${teamDescription})`;

  return check.named(
    description,
    check.and(
      check.workspaceFullAccess(project.workspaceId),
      check.projectApprover(
        project.permissionModel,
        project.id,
        project.workspaceId,
        teamIds
      )
    )
  );
}

export function updateSurveyor(
  project: Project,
  survey: PartialSurvey
): PermissionCheck {
  return check.named(
    `Update surveyor in ${survey.id}`,
    surveyUpdateableOr({
      project,
      survey,
      surveyorCheck: userIsSurveyor(survey.surveyor),
      firstPartyCheck: check.projectApprover,
      thirdPartyCheck: check.projectApprover,
      orElse: failSurveyLocked,
    })
  );
}

export function updateData(
  project: Project,
  survey: PartialSurvey
): PermissionCheck {
  return check.named(
    `Update data in ${survey.id}`,
    surveyUpdateableOr({
      project,
      survey,
      surveyorCheck: userIsSurveyor(survey.surveyor),
      firstPartyCheck: check.projectSurveyor,
      thirdPartyCheck: check.projectApprover,
      orElse: failSurveyLocked,
    })
  );
}

export function remove(
  project: Project,
  survey: PartialSurvey
): PermissionCheck {
  return check.named(
    `Delete survey ${survey.id}`,
    surveyUpdateableOr({
      project,
      survey,
      surveyorCheck: userIsSurveyor(survey.surveyor),
      firstPartyCheck: check.projectSurveyor,
      thirdPartyCheck: check.projectApprover,
      orElse: failSurveyLocked,
    })
  );
}

export function refreshMetadata(): PermissionCheck {
  return check.named(`Refresh survey metadata`, check.superuser);
}

export function hasProjectQualifications(project: Project): PermissionCheck {
  return check.named(
    "User is qualified",
    check.and(
      ...project.qualificationIds.map(qualId =>
        check.hasQualification(qualificationTraineeRole(qualId))
      )
    )
  );
}

function userIsSurveyor(surveyor: UserRef | null | undefined): PermissionCheck {
  return surveyor?.userId != null
    ? check.hasUserId(surveyor.userId)
    : never("Cannot save a survey with no surveyor");
}

interface SurveyUpdateableOrParams {
  project: Project;
  survey: PartialSurvey;
  surveyorCheck: PermissionCheck;
  firstPartyCheck: (
    model: ProjectPermissionModel,
    projectId: ProjectId,
    workspaceId: WorkspaceId,
    teamId: TeamId | null | undefined
  ) => PermissionCheck;
  thirdPartyCheck: (
    model: ProjectPermissionModel,
    projectId: ProjectId,
    workspaceId: WorkspaceId,
    teamId: TeamId | null | undefined
  ) => PermissionCheck;
  orElse: PermissionCheck;
}

function surveyUpdateableOr({
  project,
  survey,
  surveyorCheck,
  firstPartyCheck,
  thirdPartyCheck,
  orElse,
}: SurveyUpdateableOrParams): PermissionCheck {
  return updateableStatuses.includes(survey.status)
    ? check.and(
        check.workspaceFullAccess(project.workspaceId),
        hasProjectQualifications(project),
        check.or(
          thirdPartyCheck(
            project.permissionModel,
            project.id,
            project.workspaceId,
            survey.teamId
          ),
          check.named(
            `User is current owner of survey ${survey.id}`,
            check.and(
              surveyorCheck,
              firstPartyCheck(
                project.permissionModel,
                project.id,
                project.workspaceId,
                survey.teamId
              )
            )
          )
        )
      )
    : orElse;
}
