// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { ProjectPermissionModel } from "../types.generated";

const Default: ProjectPermissionModel = "Default";
const CoordinatorOnly: ProjectPermissionModel = "CoordinatorOnly";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProjectPermissionModelEnum = {
  ...createEnum("ProjectPermissionModel", [
    [Default, "Default"],
    [CoordinatorOnly, "Coordinator only"],
  ]),
  Default,
  CoordinatorOnly,
};

export default ProjectPermissionModelEnum;
