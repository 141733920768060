import { raise } from "@cartographerio/util";
import {
  NodeWalker as CmWalker,
  NodeWalkingStep as CmStep,
  Parser as CmParser,
} from "commonmark";
import { MarkdownHandlers } from "./handlers";

export function walkMarkdown<R>(
  walker: CmWalker,
  handlers: MarkdownHandlers<R>
): R {
  let step: CmStep | null;

  while ((step = walker.next()) != null) {
    const { entering, node } = step;

    if (entering) {
      switch (node.type) {
        case "text":
          node.literal == null
            ? raise(new Error("No literal in text node"))
            : handlers.text(node.literal);
          break;
        case "softbreak":
          handlers.softBreak();
          break;
        case "linebreak":
          handlers.lineBreak();
          break;
        case "emph":
          handlers.openEmph();
          break;
        case "strong":
          handlers.openStrong();
          break;
        case "html_inline":
          node.literal == null
            ? raise(new Error("No literal in html_inline node"))
            : handlers.htmlInline(node.literal);
          break;
        case "link":
          node.destination == null
            ? raise(new Error("No destination in link node"))
            : handlers.openLink(node.destination);
          break;
        case "image":
          node.destination == null
            ? raise(new Error("No destination in image node"))
            : handlers.openImage(node.destination);
          break;
        case "code":
          node.literal == null
            ? raise(new Error("No literal in code node"))
            : handlers.code(node.literal);
          break;
        case "document":
          handlers.openDocument();
          break;
        case "paragraph":
          handlers.openParagraph();
          break;
        case "block_quote":
          handlers.openBlockQuote();
          break;
        case "item":
          handlers.openItem();
          break;
        case "list":
          handlers.openList(
            node.listType,
            node.listDelimiter,
            node.listStart,
            node.listTight
          );
          break;
        case "heading":
          handlers.openHeading(node.level);
          break;
        case "code_block":
          node.literal == null
            ? raise(new Error("No literal in code_block node"))
            : handlers.codeBlock(node.info ?? null, node.literal);
          break;
        case "html_block":
          node.literal == null
            ? raise(new Error("No literal in html_block node"))
            : handlers.htmlBlock(node.literal);
          break;
        case "thematic_break":
          handlers.thematicBreak();
          break;
        case "custom_inline":
          node.literal == null
            ? raise(new Error("No literal in custom_inline node"))
            : handlers.customInline(node.literal);
          break;
        case "custom_block":
          node.literal == null
            ? raise(new Error("No literal in custom_block node"))
            : handlers.customBlock(node.literal);
          break;
      }
    } else {
      switch (node.type) {
        case "text":
          throw new Error("Unexpected close for text node");
        case "softbreak":
          throw new Error("Unexpected close for softbreak node");
        case "linebreak":
          throw new Error("Unexpected close for linebreak node");
        case "emph":
          handlers.closeEmph();
          break;
        case "strong":
          handlers.closeStrong();
          break;
        case "html_inline":
          throw new Error("Unexpected close for html_inline node");
        case "link":
          handlers.closeLink();
          break;
        case "image":
          handlers.closeImage();
          break;
        case "code":
          throw new Error("Unexpected close for code node");
        case "document":
          handlers.closeDocument();
          break;
        case "paragraph":
          handlers.closeParagraph();
          break;
        case "block_quote":
          handlers.closeBlockQuote();
          break;
        case "item":
          handlers.closeItem();
          break;
        case "list":
          handlers.closeList();
          break;
        case "heading":
          handlers.closeHeading();
          break;
        case "code_block":
          throw new Error("Unexpected close for code_block node");
        case "html_block":
          throw new Error("Unexpected close for html_block node");
        case "thematic_break":
          throw new Error("Unexpected close for thematic_break node");
        case "custom_inline":
          throw new Error("Unexpected close for custom_inline node");
        case "custom_block":
          throw new Error("Unexpected close for custom_block node");
      }
    }
  }

  return handlers.result();
}

export function parseMarkdown<R>(
  markdown: string,
  handlers: MarkdownHandlers<R>
): R {
  const parser = new CmParser();
  const ast = parser.parse(markdown);
  return walkMarkdown(ast.walker(), handlers);
}
