import ArrtWaterQualityBankVegetationEnum, {
  ArrtWaterQualityBankVegetation,
} from "./ArrtWaterQualityBankVegetationEnum.generated";
import ArrtWaterQualityChannelSubstrateEnum, {
  ArrtWaterQualityChannelSubstrate,
} from "./ArrtWaterQualityChannelSubstrateEnum.generated";
import ArrtWaterQualityFlowConditionsEnum, {
  ArrtWaterQualityFlowConditions,
} from "./ArrtWaterQualityFlowConditionsEnum.generated";
import ArrtWaterQualityFlowObstacleEnum, {
  ArrtWaterQualityFlowObstacle,
} from "./ArrtWaterQualityFlowObstacleEnum.generated";
import ArrtWaterQualityInvasivePlantEnum, {
  ArrtWaterQualityInvasivePlant,
} from "./ArrtWaterQualityInvasivePlantEnum.generated";
import ArrtWaterQualityLandUseEnum, {
  ArrtWaterQualityLandUse,
} from "./ArrtWaterQualityLandUseEnum.generated";
import ArrtWaterQualityNitrateEnum, {
  ArrtWaterQualityNitrate,
} from "./ArrtWaterQualityNitrateEnum.generated";
import ArrtWaterQualityOutfallEnum, {
  ArrtWaterQualityOutfall,
} from "./ArrtWaterQualityOutfallEnum.generated";
import ArrtWaterQualityPollutionEvidenceEnum, {
  ArrtWaterQualityPollutionEvidence,
} from "./ArrtWaterQualityPollutionEvidenceEnum.generated";
import ArrtWaterQualityPollutionSourceEnum, {
  ArrtWaterQualityPollutionSource,
} from "./ArrtWaterQualityPollutionSourceEnum.generated";
import ArrtWaterQualityRainfallEnum, {
  ArrtWaterQualityRainfall,
} from "./ArrtWaterQualityRainfallEnum.generated";
import ArrtWaterQualityWaterLevelEnum, {
  ArrtWaterQualityWaterLevel,
} from "./ArrtWaterQualityWaterLevelEnum.generated";
import ArrtWaterQualityWaterbodyTypeEnum, {
  ArrtWaterQualityWaterbodyType,
} from "./ArrtWaterQualityWaterbodyTypeEnum.generated";
import ArrtWaterQualityWildlifeEnum, {
  ArrtWaterQualityWildlife,
} from "./ArrtWaterQualityWildlifeEnum.generated";
import ArrtWaterqualityChannelVegetationEnum, {
  ArrtWaterqualityChannelVegetation,
} from "./ArrtWaterqualityChannelVegetationEnum.generated";
import CesAbundanceEnum, { CesAbundance } from "./CesAbundanceEnum.generated";
import CesAquaticInvasivePlantEnum, {
  CesAquaticInvasivePlant,
} from "./CesAquaticInvasivePlantEnum.generated";
import CesAquaticVegetationFormEnum, {
  CesAquaticVegetationForm,
} from "./CesAquaticVegetationFormEnum.generated";
import CesBankFeatureEnum, {
  CesBankFeature,
} from "./CesBankFeatureEnum.generated";
import CesBankHorizontalProfileEnum, {
  CesBankHorizontalProfile,
} from "./CesBankHorizontalProfileEnum.generated";
import CesBankTerrestrialVegetationEnum, {
  CesBankTerrestrialVegetation,
} from "./CesBankTerrestrialVegetationEnum.generated";
import CesBankTopMaterialEnum, {
  CesBankTopMaterial,
} from "./CesBankTopMaterialEnum.generated";
import CesBankTopTerrestrialVegetationEnum, {
  CesBankTopTerrestrialVegetation,
} from "./CesBankTopTerrestrialVegetationEnum.generated";
import CesBankTopTreeFeatureEnum, {
  CesBankTopTreeFeature,
} from "./CesBankTopTreeFeatureEnum.generated";
import CesBankTopVegetationManagementEnum, {
  CesBankTopVegetationManagement,
} from "./CesBankTopVegetationManagementEnum.generated";
import CesBankTopVegetationStructureEnum, {
  CesBankTopVegetationStructure,
} from "./CesBankTopVegetationStructureEnum.generated";
import CesBankTreeFeatureEnum, {
  CesBankTreeFeature,
} from "./CesBankTreeFeatureEnum.generated";
import CesBankVerticalProfileEnum, {
  CesBankVerticalProfile,
} from "./CesBankVerticalProfileEnum.generated";
import CesConditionEnum, { CesCondition } from "./CesConditionEnum.generated";
import CesContinuityEnum, {
  CesContinuity,
} from "./CesContinuityEnum.generated";
import CesDominanceEnum, { CesDominance } from "./CesDominanceEnum.generated";
import CesFringeContinuityEnum, {
  CesFringeContinuity,
} from "./CesFringeContinuityEnum.generated";
import CesFringeWidthEnum, {
  CesFringeWidth,
} from "./CesFringeWidthEnum.generated";
import CesJettyAlignmentEnum, {
  CesJettyAlignment,
} from "./CesJettyAlignmentEnum.generated";
import CesJettySizeEnum, { CesJettySize } from "./CesJettySizeEnum.generated";
import CesLandCoverCategoryEnum, {
  CesLandCoverCategory,
} from "./CesLandCoverCategoryEnum.generated";
import CesLandCoverTypeEnum, {
  CesLandCoverType,
} from "./CesLandCoverTypeEnum.generated";
import CesNaturalMaterialEnum, {
  CesNaturalMaterial,
} from "./CesNaturalMaterialEnum.generated";
import CesPathWidthEnum, { CesPathWidth } from "./CesPathWidthEnum.generated";
import CesReinforcementExtentEnum, {
  CesReinforcementExtent,
} from "./CesReinforcementExtentEnum.generated";
import CesReinforcementMaterialEnum, {
  CesReinforcementMaterial,
} from "./CesReinforcementMaterialEnum.generated";
import CesSurveyedFromEnum, {
  CesSurveyedFrom,
} from "./CesSurveyedFromEnum.generated";
import CesTerrestrialInvasivePlantEnum, {
  CesTerrestrialInvasivePlant,
} from "./CesTerrestrialInvasivePlantEnum.generated";
import CesTypicalHedgeWidthEnum, {
  CesTypicalHedgeWidth,
} from "./CesTypicalHedgeWidthEnum.generated";
import CesWaterFeatureConditionEnum, {
  CesWaterFeatureCondition,
} from "./CesWaterFeatureConditionEnum.generated";
import CesWoodySpeciesCountEnum, {
  CesWoodySpeciesCount,
} from "./CesWoodySpeciesCountEnum.generated";
import EcostatusAbsentMinorMajorEnum, {
  EcostatusAbsentMinorMajor,
} from "./EcostatusAbsentMinorMajorEnum.generated";
import EcostatusConditionAndUseEnum, {
  EcostatusConditionAndUse,
} from "./EcostatusConditionAndUseEnum.generated";
import EcostatusConfidenceLevelEnum, {
  EcostatusConfidenceLevel,
} from "./EcostatusConfidenceLevelEnum.generated";
import EcostatusConnectivityEnum, {
  EcostatusConnectivity,
} from "./EcostatusConnectivityEnum.generated";
import EcostatusHygieneEnum, {
  EcostatusHygiene,
} from "./EcostatusHygieneEnum.generated";
import EcostatusLitterDisposalEnum, {
  EcostatusLitterDisposal,
} from "./EcostatusLitterDisposalEnum.generated";
import EcostatusLitterEnum, {
  EcostatusLitter,
} from "./EcostatusLitterEnum.generated";
import EcostatusNfmCategoryEnum, {
  EcostatusNfmCategory,
} from "./EcostatusNfmCategoryEnum.generated";
import EcostatusNfmPositionEnum, {
  EcostatusNfmPosition,
} from "./EcostatusNfmPositionEnum.generated";
import EcostatusNoneOneMoreEnum, {
  EcostatusNoneOneMore,
} from "./EcostatusNoneOneMoreEnum.generated";
import EcostatusNoneSomeManyEnum, {
  EcostatusNoneSomeMany,
} from "./EcostatusNoneSomeManyEnum.generated";
import EcostatusPersonalSafetyEnum, {
  EcostatusPersonalSafety,
} from "./EcostatusPersonalSafetyEnum.generated";
import EcostatusPollutionTypeEnum, {
  EcostatusPollutionType,
} from "./EcostatusPollutionTypeEnum.generated";
import EcostatusPoorModerateGoodEnum, {
  EcostatusPoorModerateGood,
} from "./EcostatusPoorModerateGoodEnum.generated";
import EcostatusRailAccessEnum, {
  EcostatusRailAccess,
} from "./EcostatusRailAccessEnum.generated";
import EcostatusRecreationalFacilityEnum, {
  EcostatusRecreationalFacility,
} from "./EcostatusRecreationalFacilityEnum.generated";
import EcostatusStudyAreaAccessProvisionEnum, {
  EcostatusStudyAreaAccessProvision,
} from "./EcostatusStudyAreaAccessProvisionEnum.generated";
import EcostatusStudyAreaAccessRestrictionEnum, {
  EcostatusStudyAreaAccessRestriction,
} from "./EcostatusStudyAreaAccessRestrictionEnum.generated";
import EcostatusStudyAreaBankEnum, {
  EcostatusStudyAreaBank,
} from "./EcostatusStudyAreaBankEnum.generated";
import EcostatusStudyAreaOwnershipEnum, {
  EcostatusStudyAreaOwnership,
} from "./EcostatusStudyAreaOwnershipEnum.generated";
import EsrtWaterQualityAmmoniaEnum, {
  EsrtWaterQualityAmmonia,
} from "./EsrtWaterQualityAmmoniaEnum.generated";
import EsrtWaterQualityBankVegetationEnum, {
  EsrtWaterQualityBankVegetation,
} from "./EsrtWaterQualityBankVegetationEnum.generated";
import EsrtWaterQualityEstimatedDepthEnum, {
  EsrtWaterQualityEstimatedDepth,
} from "./EsrtWaterQualityEstimatedDepthEnum.generated";
import EsrtWaterQualityEstimatedWidthEnum, {
  EsrtWaterQualityEstimatedWidth,
} from "./EsrtWaterQualityEstimatedWidthEnum.generated";
import EsrtWaterQualityFlowLevelEnum, {
  EsrtWaterQualityFlowLevel,
} from "./EsrtWaterQualityFlowLevelEnum.generated";
import EsrtWaterQualityFlowObstacleEnum, {
  EsrtWaterQualityFlowObstacle,
} from "./EsrtWaterQualityFlowObstacleEnum.generated";
import EsrtWaterQualityInvasivePlantEnum, {
  EsrtWaterQualityInvasivePlant,
} from "./EsrtWaterQualityInvasivePlantEnum.generated";
import EsrtWaterQualityLandUseEnum, {
  EsrtWaterQualityLandUse,
} from "./EsrtWaterQualityLandUseEnum.generated";
import EsrtWaterQualityNitrateEnum, {
  EsrtWaterQualityNitrate,
} from "./EsrtWaterQualityNitrateEnum.generated";
import EsrtWaterQualityOutfallEnum, {
  EsrtWaterQualityOutfall,
} from "./EsrtWaterQualityOutfallEnum.generated";
import EsrtWaterQualityPhosphateEnum, {
  EsrtWaterQualityPhosphate,
} from "./EsrtWaterQualityPhosphateEnum.generated";
import EsrtWaterQualityPollutionEvidenceEnum, {
  EsrtWaterQualityPollutionEvidence,
} from "./EsrtWaterQualityPollutionEvidenceEnum.generated";
import EsrtWaterQualityPollutionSourceEnum, {
  EsrtWaterQualityPollutionSource,
} from "./EsrtWaterQualityPollutionSourceEnum.generated";
import EsrtWaterQualityPublicAccessEnum, {
  EsrtWaterQualityPublicAccess,
} from "./EsrtWaterQualityPublicAccessEnum.generated";
import EsrtWaterQualityRainfallEnum, {
  EsrtWaterQualityRainfall,
} from "./EsrtWaterQualityRainfallEnum.generated";
import EsrtWaterQualityRecreationalActivityEnum, {
  EsrtWaterQualityRecreationalActivity,
} from "./EsrtWaterQualityRecreationalActivityEnum.generated";
import EsrtWaterQualityRiverbedCompositionEnum, {
  EsrtWaterQualityRiverbedComposition,
} from "./EsrtWaterQualityRiverbedCompositionEnum.generated";
import EsrtWaterQualityWaterLevelEnum, {
  EsrtWaterQualityWaterLevel,
} from "./EsrtWaterQualityWaterLevelEnum.generated";
import EsrtWaterQualityWaterbodyTypeEnum, {
  EsrtWaterQualityWaterbodyType,
} from "./EsrtWaterQualityWaterbodyTypeEnum.generated";
import EsrtWaterQualityWildlifeEnum, {
  EsrtWaterQualityWildlife,
} from "./EsrtWaterQualityWildlifeEnum.generated";
import ExtendedRiverflySpeciesEnum, {
  ExtendedRiverflySpecies,
} from "./ExtendedRiverflySpeciesEnum.generated";
import FhtPondCountPresenceEnum, {
  FhtPondCountPresence,
} from "./FhtPondCountPresenceEnum.generated";
import FhtPondCountSizeEnum, {
  FhtPondCountSize,
} from "./FhtPondCountSizeEnum.generated";
import FhtPondCountWhyAbsentEnum, {
  FhtPondCountWhyAbsent,
} from "./FhtPondCountWhyAbsentEnum.generated";
import FhtPondCountWhyCreatedEnum, {
  FhtPondCountWhyCreated,
} from "./FhtPondCountWhyCreatedEnum.generated";
import FhtPondCountWhyLostOrDestroyedEnum, {
  FhtPondCountWhyLostOrDestroyed,
} from "./FhtPondCountWhyLostOrDestroyedEnum.generated";
import FhtPondCountWhyMissedEnum, {
  FhtPondCountWhyMissed,
} from "./FhtPondCountWhyMissedEnum.generated";
import FhtPondCountWhyNewEnum, {
  FhtPondCountWhyNew,
} from "./FhtPondCountWhyNewEnum.generated";
import FhtPondCountWhySizeChangedEnum, {
  FhtPondCountWhySizeChanged,
} from "./FhtPondCountWhySizeChangedEnum.generated";
import FhtPriorityPondsAnnex1TypeEnum, {
  FhtPriorityPondsAnnex1Type,
} from "./FhtPriorityPondsAnnex1TypeEnum.generated";
import FhtPriorityPondsPsymAssessmentBasisEnum, {
  FhtPriorityPondsPsymAssessmentBasis,
} from "./FhtPriorityPondsPsymAssessmentBasisEnum.generated";
import MrsArtificialGroundCoverEnum, {
  MrsArtificialGroundCover,
} from "./MrsArtificialGroundCoverEnum.generated";
import MrsAtpeEnum, { MrsAtpe } from "./MrsAtpeEnum.generated";
import MrsBankProfileEnum, {
  MrsBankProfile,
} from "./MrsBankProfileEnum.generated";
import MrsBedMaterialParticleSizeEnum, {
  MrsBedMaterialParticleSize,
} from "./MrsBedMaterialParticleSizeEnum.generated";
import MrsChannelCoverageEnum, {
  MrsChannelCoverage,
} from "./MrsChannelCoverageEnum.generated";
import MrsFlowTypeEnum, { MrsFlowType } from "./MrsFlowTypeEnum.generated";
import MrsHorizontalOrientationEnum, {
  MrsHorizontalOrientation,
} from "./MrsHorizontalOrientationEnum.generated";
import MrsMajorIntermediateMinorEnum, {
  MrsMajorIntermediateMinor,
} from "./MrsMajorIntermediateMinorEnum.generated";
import MrsMorphEstuariesBankProfileEnum, {
  MrsMorphEstuariesBankProfile,
} from "./MrsMorphEstuariesBankProfileEnum.generated";
import MrsMorphEstuariesCreekTypeEnum, {
  MrsMorphEstuariesCreekType,
} from "./MrsMorphEstuariesCreekTypeEnum.generated";
import MrsMorphEstuariesDaysToSpringTideEnum, {
  MrsMorphEstuariesDaysToSpringTide,
} from "./MrsMorphEstuariesDaysToSpringTideEnum.generated";
import MrsMorphEstuariesDrainageNetworkTypeEnum, {
  MrsMorphEstuariesDrainageNetworkType,
} from "./MrsMorphEstuariesDrainageNetworkTypeEnum.generated";
import MrsMorphEstuariesFloodWallDimensionEnum, {
  MrsMorphEstuariesFloodWallDimension,
} from "./MrsMorphEstuariesFloodWallDimensionEnum.generated";
import MrsMorphEstuariesFragmentationEnum, {
  MrsMorphEstuariesFragmentation,
} from "./MrsMorphEstuariesFragmentationEnum.generated";
import MrsMorphEstuariesFrontBackCreekMarginEnum, {
  MrsMorphEstuariesFrontBackCreekMargin,
} from "./MrsMorphEstuariesFrontBackCreekMarginEnum.generated";
import MrsMorphEstuariesGrazingEnum, {
  MrsMorphEstuariesGrazing,
} from "./MrsMorphEstuariesGrazingEnum.generated";
import MrsMorphEstuariesMarginTypeEnum, {
  MrsMorphEstuariesMarginType,
} from "./MrsMorphEstuariesMarginTypeEnum.generated";
import MrsMorphEstuariesSaltMarshLocationEnum, {
  MrsMorphEstuariesSaltMarshLocation,
} from "./MrsMorphEstuariesSaltMarshLocationEnum.generated";
import MrsMorphEstuariesSmallFragmentDistributionEnum, {
  MrsMorphEstuariesSmallFragmentDistribution,
} from "./MrsMorphEstuariesSmallFragmentDistributionEnum.generated";
import MrsMorphEstuariesSubtidalSedimentSizeEnum, {
  MrsMorphEstuariesSubtidalSedimentSize,
} from "./MrsMorphEstuariesSubtidalSedimentSizeEnum.generated";
import MrsMorphEstuariesTidalFloodBarrierEnum, {
  MrsMorphEstuariesTidalFloodBarrier,
} from "./MrsMorphEstuariesTidalFloodBarrierEnum.generated";
import MrsMorphEstuariesVegetatedIntertidalSedimentSizeEnum, {
  MrsMorphEstuariesVegetatedIntertidalSedimentSize,
} from "./MrsMorphEstuariesVegetatedIntertidalSedimentSizeEnum.generated";
import MrsMorphEstuariesVisibilityEnum, {
  MrsMorphEstuariesVisibility,
} from "./MrsMorphEstuariesVisibilityEnum.generated";
import MrsMudSpotterDisturbedBankFaceSourceTypeEnum, {
  MrsMudSpotterDisturbedBankFaceSourceType,
} from "./MrsMudSpotterDisturbedBankFaceSourceTypeEnum.generated";
import MrsMudSpotterFlowEnum, {
  MrsMudSpotterFlow,
} from "./MrsMudSpotterFlowEnum.generated";
import MrsMudSpotterLegacySourceSubTypeEnum, {
  MrsMudSpotterLegacySourceSubType,
} from "./MrsMudSpotterLegacySourceSubTypeEnum.generated";
import MrsMudSpotterOverlandFlowSourceTypeEnum, {
  MrsMudSpotterOverlandFlowSourceType,
} from "./MrsMudSpotterOverlandFlowSourceTypeEnum.generated";
import MrsMudSpotterRainfallIntensityEnum, {
  MrsMudSpotterRainfallIntensity,
} from "./MrsMudSpotterRainfallIntensityEnum.generated";
import MrsMudSpotterSedimentEnum, {
  MrsMudSpotterSediment,
} from "./MrsMudSpotterSedimentEnum.generated";
import MrsMudSpotterSourceSizeEnum, {
  MrsMudSpotterSourceSize,
} from "./MrsMudSpotterSourceSizeEnum.generated";
import MrsMudSpotterSourceTypeEnum, {
  MrsMudSpotterSourceType,
} from "./MrsMudSpotterSourceTypeEnum.generated";
import MrsMudSpotterSurveyTypeEnum, {
  MrsMudSpotterSurveyType,
} from "./MrsMudSpotterSurveyTypeEnum.generated";
import MrsReinforcementTypeEnum, {
  MrsReinforcementType,
} from "./MrsReinforcementTypeEnum.generated";
import MrsReinforcementVerticalExtentEnum, {
  MrsReinforcementVerticalExtent,
} from "./MrsReinforcementVerticalExtentEnum.generated";
import MrsUadEnum, { MrsUad } from "./MrsUadEnum.generated";
import MrsRiverWoodArtificialElementEnum, {
  MrsRiverWoodArtificialElement,
} from "./MrsRiverWoodArtificialElementEnum.generated";
import MrsRiverWoodJamTypeEnum, {
  MrsRiverWoodJamType,
} from "./MrsRiverWoodJamTypeEnum.generated";
import MrsRiverWoodHydraulicTypeEnum, {
  MrsRiverWoodHydraulicType,
} from "./MrsRiverWoodHydraulicTypeEnum.generated";
import MrsRiverWoodRetainedByArtificialFeatureEnum, {
  MrsRiverWoodRetainedByArtificialFeature,
} from "./MrsRiverWoodRetainedByArtificialFeatureEnum.generated";
import MrsRiverWoodRetainedByNaturalFeatureEnum, {
  MrsRiverWoodRetainedByNaturalFeature,
} from "./MrsRiverWoodRetainedByNaturalFeatureEnum.generated";
import MrsRtaAverageBedMaterialEnum, {
  MrsRtaAverageBedMaterial,
} from "./MrsRtaAverageBedMaterialEnum.generated";
import MrsRtaCoarsestBedMaterialEnum, {
  MrsRtaCoarsestBedMaterial,
} from "./MrsRtaCoarsestBedMaterialEnum.generated";
import MrsRtaConditionClassEnum, {
  MrsRtaConditionClass,
} from "./MrsRtaConditionClassEnum.generated";
import MrsRtaLevelOfConfinementEnum, {
  MrsRtaLevelOfConfinement,
} from "./MrsRtaLevelOfConfinementEnum.generated";
import MrsRtaRiverCategoryEnum, {
  MrsRtaRiverCategory,
} from "./MrsRtaRiverCategoryEnum.generated";
import MrsRtaRiverTypeEnum, {
  MrsRtaRiverType,
} from "./MrsRtaRiverTypeEnum.generated";
import MrsSedimentSizeEnum, {
  MrsSedimentSize,
} from "./MrsSedimentSizeEnum.generated";
import MrsTreeTypeEnum, { MrsTreeType } from "./MrsTreeTypeEnum.generated";
import MrsWildInvadersAccessibleByEnum, {
  MrsWildInvadersAccessibleBy,
} from "./MrsWildInvadersAccessibleByEnum.generated";
import MrsWildInvadersCoverageEnum, {
  MrsWildInvadersCoverage,
} from "./MrsWildInvadersCoverageEnum.generated";
import MrsWildInvadersGrowthStageEnum, {
  MrsWildInvadersGrowthStage,
} from "./MrsWildInvadersGrowthStageEnum.generated";
import MrsWildInvadersManagementEnum, {
  MrsWildInvadersManagement,
} from "./MrsWildInvadersManagementEnum.generated";
import MrsWildInvadersProximityEnum, {
  MrsWildInvadersProximity,
} from "./MrsWildInvadersProximityEnum.generated";
import MrsWildInvadersSpeciesEnum, {
  MrsWildInvadersSpecies,
} from "./MrsWildInvadersSpeciesEnum.generated";
import NeChalkRiversFeatureEnum, {
  NeChalkRiversFeature,
} from "./NeChalkRiversFeatureEnum.generated";
import NeChalkRiversModificationTypeEnum, {
  NeChalkRiversModificationType,
} from "./NeChalkRiversModificationTypeEnum.generated";
import NeChalkRiversPhEnum, {
  NeChalkRiversPh,
} from "./NeChalkRiversPhEnum.generated";
import NeChalkRiversTreeShadingEnum, {
  NeChalkRiversTreeShading,
} from "./NeChalkRiversTreeShadingEnum.generated";
import NeLakeNaturalnessBirdSpeciesEnum, {
  NeLakeNaturalnessBirdSpecies,
} from "./NeLakeNaturalnessBirdSpeciesEnum.generated";
import NeLakeNaturalnessChemicalAssessmentFormEnum, {
  NeLakeNaturalnessChemicalAssessmentForm,
} from "./NeLakeNaturalnessChemicalAssessmentFormEnum.generated";
import NeLakeNaturalnessEmergentFringeEnum, {
  NeLakeNaturalnessEmergentFringe,
} from "./NeLakeNaturalnessEmergentFringeEnum.generated";
import NeLakeNaturalnessFishSpeciesEnum, {
  NeLakeNaturalnessFishSpecies,
} from "./NeLakeNaturalnessFishSpeciesEnum.generated";
import NeLakeNaturalnessHerpetofaunaSpeciesEnum, {
  NeLakeNaturalnessHerpetofaunaSpecies,
} from "./NeLakeNaturalnessHerpetofaunaSpeciesEnum.generated";
import NeLakeNaturalnessHigherPlantSpeciesEnum, {
  NeLakeNaturalnessHigherPlantSpecies,
} from "./NeLakeNaturalnessHigherPlantSpeciesEnum.generated";
import NeLakeNaturalnessInvertebrateSpeciesEnum, {
  NeLakeNaturalnessInvertebrateSpecies,
} from "./NeLakeNaturalnessInvertebrateSpeciesEnum.generated";
import NeLakeNaturalnessMammalSpeciesEnum, {
  NeLakeNaturalnessMammalSpecies,
} from "./NeLakeNaturalnessMammalSpeciesEnum.generated";
import NeLakeNaturalnessOutflowStructureEnum, {
  NeLakeNaturalnessOutflowStructure,
} from "./NeLakeNaturalnessOutflowStructureEnum.generated";
import NeLakeNaturalnessPerimeterTreesEnum, {
  NeLakeNaturalnessPerimeterTrees,
} from "./NeLakeNaturalnessPerimeterTreesEnum.generated";
import NeLakeNaturalnessPlantFunctionalGroupEnum, {
  NeLakeNaturalnessPlantFunctionalGroup,
} from "./NeLakeNaturalnessPlantFunctionalGroupEnum.generated";
import NeLakeNaturalnessRiparianZoneEnum, {
  NeLakeNaturalnessRiparianZone,
} from "./NeLakeNaturalnessRiparianZoneEnum.generated";
import NeLakeNaturalnessShorelineModificationEnum, {
  NeLakeNaturalnessShorelineModification,
} from "./NeLakeNaturalnessShorelineModificationEnum.generated";
import NeLakeRestorationPrioritiesBiologicalMeasureEnum, {
  NeLakeRestorationPrioritiesBiologicalMeasure,
} from "./NeLakeRestorationPrioritiesBiologicalMeasureEnum.generated";
import NeLakeRestorationPrioritiesChemicalMeasureEnum, {
  NeLakeRestorationPrioritiesChemicalMeasure,
} from "./NeLakeRestorationPrioritiesChemicalMeasureEnum.generated";
import NeLakeRestorationPrioritiesHydrologicalMeasureEnum, {
  NeLakeRestorationPrioritiesHydrologicalMeasure,
} from "./NeLakeRestorationPrioritiesHydrologicalMeasureEnum.generated";
import NeLakeRestorationPrioritiesPhysicalMeasureEnum, {
  NeLakeRestorationPrioritiesPhysicalMeasure,
} from "./NeLakeRestorationPrioritiesPhysicalMeasureEnum.generated";
import NeNameSourceEnum, { NeNameSource } from "./NeNameSourceEnum.generated";
import NeNaturalnessClassEnum, {
  NeNaturalnessClass,
} from "./NeNaturalnessClassEnum.generated";
import NeNaturalnessConfidenceClassEnum, {
  NeNaturalnessConfidenceClass,
} from "./NeNaturalnessConfidenceClassEnum.generated";
import NeNaturalnessInvasivePlantSpeciesEnum, {
  NeNaturalnessInvasivePlantSpecies,
} from "./NeNaturalnessInvasivePlantSpeciesEnum.generated";
import NeRestorationRelevanceEnum, {
  NeRestorationRelevance,
} from "./NeRestorationRelevanceEnum.generated";
import NeRiverNaturalnessBiologicalAssessmentFormEnum, {
  NeRiverNaturalnessBiologicalAssessmentForm,
} from "./NeRiverNaturalnessBiologicalAssessmentFormEnum.generated";
import NeRiverNaturalnessBirdSpeciesEnum, {
  NeRiverNaturalnessBirdSpecies,
} from "./NeRiverNaturalnessBirdSpeciesEnum.generated";
import NeRiverNaturalnessBryophyteSpeciesEnum, {
  NeRiverNaturalnessBryophyteSpecies,
} from "./NeRiverNaturalnessBryophyteSpeciesEnum.generated";
import NeRiverNaturalnessChemicalAssessmentFormEnum, {
  NeRiverNaturalnessChemicalAssessmentForm,
} from "./NeRiverNaturalnessChemicalAssessmentFormEnum.generated";
import NeRiverNaturalnessFeatureEnum, {
  NeRiverNaturalnessFeature,
} from "./NeRiverNaturalnessFeatureEnum.generated";
import NeRiverNaturalnessFernSpeciesEnum, {
  NeRiverNaturalnessFernSpecies,
} from "./NeRiverNaturalnessFernSpeciesEnum.generated";
import NeRiverNaturalnessFishSpeciesEnum, {
  NeRiverNaturalnessFishSpecies,
} from "./NeRiverNaturalnessFishSpeciesEnum.generated";
import NeRiverNaturalnessHerpetofaunaSpeciesEnum, {
  NeRiverNaturalnessHerpetofaunaSpecies,
} from "./NeRiverNaturalnessHerpetofaunaSpeciesEnum.generated";
import NeRiverNaturalnessHigherPlantSpeciesEnum, {
  NeRiverNaturalnessHigherPlantSpecies,
} from "./NeRiverNaturalnessHigherPlantSpeciesEnum.generated";
import NeRiverNaturalnessHydrologicalAssessmentFormEnum, {
  NeRiverNaturalnessHydrologicalAssessmentForm,
} from "./NeRiverNaturalnessHydrologicalAssessmentFormEnum.generated";
import NeRiverNaturalnessInvertebrateSpeciesEnum, {
  NeRiverNaturalnessInvertebrateSpecies,
} from "./NeRiverNaturalnessInvertebrateSpeciesEnum.generated";
import NeRiverNaturalnessMammalSpeciesEnum, {
  NeRiverNaturalnessMammalSpecies,
} from "./NeRiverNaturalnessMammalSpeciesEnum.generated";
import NeRiverNaturalnessPhysicalAssessmentFormEnum, {
  NeRiverNaturalnessPhysicalAssessmentForm,
} from "./NeRiverNaturalnessPhysicalAssessmentFormEnum.generated";
import NeRiverRestorationPrioritiesBiologicalMeasureEnum, {
  NeRiverRestorationPrioritiesBiologicalMeasure,
} from "./NeRiverRestorationPrioritiesBiologicalMeasureEnum.generated";
import NeRiverRestorationPrioritiesChemicalMeasureEnum, {
  NeRiverRestorationPrioritiesChemicalMeasure,
} from "./NeRiverRestorationPrioritiesChemicalMeasureEnum.generated";
import NeRiverRestorationPrioritiesHydrologicalMeasureEnum, {
  NeRiverRestorationPrioritiesHydrologicalMeasure,
} from "./NeRiverRestorationPrioritiesHydrologicalMeasureEnum.generated";
import NeRiverRestorationPrioritiesPhysicalMeasureEnum, {
  NeRiverRestorationPrioritiesPhysicalMeasure,
} from "./NeRiverRestorationPrioritiesPhysicalMeasureEnum.generated";
import NeRiverTypesConfidenceEnum, {
  NeRiverTypesConfidence,
} from "./NeRiverTypesConfidenceEnum.generated";
import NeRiverTypesFeatureEnum, {
  NeRiverTypesFeature,
} from "./NeRiverTypesFeatureEnum.generated";
import NeYesNoUncertainEnum, {
  NeYesNoUncertain,
} from "./NeYesNoUncertainEnum.generated";
import NwcSeedbankAreaEnum, {
  NwcSeedbankArea,
} from "./NwcSeedbankAreaEnum.generated";
import NwcSeedbankCollectionEnum, {
  NwcSeedbankCollection,
} from "./NwcSeedbankCollectionEnum.generated";
import NwcSeedbankEvidenceOfPollutionEnum, {
  NwcSeedbankEvidenceOfPollution,
} from "./NwcSeedbankEvidenceOfPollutionEnum.generated";
import NwcSeedbankLandUseEnum, {
  NwcSeedbankLandUse,
} from "./NwcSeedbankLandUseEnum.generated";
import NwcSeedbankPermissionEnum, {
  NwcSeedbankPermission,
} from "./NwcSeedbankPermissionEnum.generated";
import NwcSeedbankProblemPlantSpeciesEnum, {
  NwcSeedbankProblemPlantSpecies,
} from "./NwcSeedbankProblemPlantSpeciesEnum.generated";
import NwcSeedbankSoilTypeEnum, {
  NwcSeedbankSoilType,
} from "./NwcSeedbankSoilTypeEnum.generated";
import NwcSeedbankSpeciesEnum, {
  NwcSeedbankSpecies,
} from "./NwcSeedbankSpeciesEnum.generated";
import NwcSeedbankVegetationEnum, {
  NwcSeedbankVegetation,
} from "./NwcSeedbankVegetationEnum.generated";
import OutfallSafariActivityEnum, {
  OutfallSafariActivity,
} from "./OutfallSafariActivityEnum.generated";
import OutfallSafariAestheticsEnum, {
  OutfallSafariAesthetics,
} from "./OutfallSafariAestheticsEnum.generated";
import OutfallSafariBankEnum, {
  OutfallSafariBank,
} from "./OutfallSafariBankEnum.generated";
import OutfallSafariFlowOfDischargeEnum, {
  OutfallSafariFlowOfDischarge,
} from "./OutfallSafariFlowOfDischargeEnum.generated";
import OutfallSafariInaccessibleAreaLengthEnum, {
  OutfallSafariInaccessibleAreaLength,
} from "./OutfallSafariInaccessibleAreaLengthEnum.generated";
import OutfallSafariVisualImpactEnum, {
  OutfallSafariVisualImpact,
} from "./OutfallSafariVisualImpactEnum.generated";
import RiverflyBreachTypeEnum, {
  RiverflyBreachType,
} from "./RiverflyBreachTypeEnum.generated";
import RiverflyFirstBreachActionEnum, {
  RiverflyFirstBreachAction,
} from "./RiverflyFirstBreachActionEnum.generated";
import RiverflyReasonNotTakenEnum, {
  RiverflyReasonNotTaken,
} from "./RiverflyReasonNotTakenEnum.generated";
import RiverflySampleTypeEnum, {
  RiverflySampleType,
} from "./RiverflySampleTypeEnum.generated";
import RiverflySecondBreachActionEnum, {
  RiverflySecondBreachAction,
} from "./RiverflySecondBreachActionEnum.generated";
import RiverflySpeciesEnum, {
  RiverflySpecies,
} from "./RiverflySpeciesEnum.generated";
import SasWaterQualitySiteTypeEnum, {
  SasWaterQualitySiteType,
} from "./SasWaterQualitySiteTypeEnum.generated";
import SasWaterQualityWeatherEnum, {
  SasWaterQualityWeather,
} from "./SasWaterQualityWeatherEnum.generated";
import StSmartRiversProjectEnum, {
  StSmartRiversProject,
} from "./StSmartRiversProjectEnum.generated";
import StSmartRiversRoleEnum, {
  StSmartRiversRole,
} from "./StSmartRiversRoleEnum.generated";
import StdActivityTypeEnum, {
  StdActivityType,
} from "./StdActivityTypeEnum.generated";
import StdInvasiveSpeciesAmountEnum, {
  StdInvasiveSpeciesAmount,
} from "./StdInvasiveSpeciesAmountEnum.generated";
import StdInvasiveSpeciesTreatmentEnum, {
  StdInvasiveSpeciesTreatment,
} from "./StdInvasiveSpeciesTreatmentEnum.generated";
import StdInvasiveSpeciesEnum, {
  StdInvasiveSpecies,
} from "./StdInvasiveSpeciesEnum.generated";
import StormPhosphatesLevelTrendEnum, {
  StormPhosphatesLevelTrend,
} from "./StormPhosphatesLevelTrendEnum.generated";
import StormPhosphatesLevelEnum, {
  StormPhosphatesLevel,
} from "./StormPhosphatesLevelEnum.generated";
import Thames21BadgedGroupEventActivityEnum, {
  Thames21BadgedGroupEventActivity,
} from "./Thames21BadgedGroupEventActivityEnum.generated";
import Thames21BadgedGroupEventLargeRubbishEnum, {
  Thames21BadgedGroupEventLargeRubbish,
} from "./Thames21BadgedGroupEventLargeRubbishEnum.generated";
import Thames21BadgedGroupEventSettingEnum, {
  Thames21BadgedGroupEventSetting,
} from "./Thames21BadgedGroupEventSettingEnum.generated";
import Thames21ChannelSideEnum, {
  Thames21ChannelSide,
} from "./Thames21ChannelSideEnum.generated";
import Thames21ForeshoreEnum, {
  Thames21Foreshore,
} from "./Thames21ForeshoreEnum.generated";
import Thames21LitterCategoryEnum, {
  Thames21LitterCategory,
} from "./Thames21LitterCategoryEnum.generated";
import Thames21LitterCleanupFocusEnum, {
  Thames21LitterCleanupFocus,
} from "./Thames21LitterCleanupFocusEnum.generated";
import Thames21LitterItemEnum, {
  Thames21LitterItem,
} from "./Thames21LitterItemEnum.generated";
import Thames21LitterMetricEnum, {
  Thames21LitterMetric,
} from "./Thames21LitterMetricEnum.generated";
import Thames21LitterPolystyreneCoverEnum, {
  Thames21LitterPolystyreneCover,
} from "./Thames21LitterPolystyreneCoverEnum.generated";
import Thames21LitterSmallPlasticCountEnum, {
  Thames21LitterSmallPlasticCount,
} from "./Thames21LitterSmallPlasticCountEnum.generated";
import Thames21LitterSurveyTypeEnum, {
  Thames21LitterSurveyType,
} from "./Thames21LitterSurveyTypeEnum.generated";
import Thames21RiverEnum, {
  Thames21River,
} from "./Thames21RiverEnum.generated";
import Thames21SlowFlowFeatureTypeEnum, {
  Thames21SlowFlowFeatureType,
} from "./Thames21SlowFlowFeatureTypeEnum.generated";
import Thames21SlowFlowPondTypeEnum, {
  Thames21SlowFlowPondType,
} from "./Thames21SlowFlowPondTypeEnum.generated";
import Thames21SlowFlowServiceTypeEnum, {
  Thames21SlowFlowServiceType,
} from "./Thames21SlowFlowServiceTypeEnum.generated";
import Thames21SlowFlowSiteEnum, {
  Thames21SlowFlowSite,
} from "./Thames21SlowFlowSiteEnum.generated";
import Thames21SlowFlowSuitabilityEnum, {
  Thames21SlowFlowSuitability,
} from "./Thames21SlowFlowSuitabilityEnum.generated";
import Thames21SlowFlowSurveyTypeEnum, {
  Thames21SlowFlowSurveyType,
} from "./Thames21SlowFlowSurveyTypeEnum.generated";
import Thames21SlowFlowVegetationTypeEnum, {
  Thames21SlowFlowVegetationType,
} from "./Thames21SlowFlowVegetationTypeEnum.generated";
import Thames21VegetationOriginEnum, {
  Thames21VegetationOrigin,
} from "./Thames21VegetationOriginEnum.generated";
import Thames21VegetationSiteEnum, {
  Thames21VegetationSite,
} from "./Thames21VegetationSiteEnum.generated";
import Thames21VegetationStructureEnum, {
  Thames21VegetationStructure,
} from "./Thames21VegetationStructureEnum.generated";
import Thames21VegetationTypeEnum, {
  Thames21VegetationType,
} from "./Thames21VegetationTypeEnum.generated";
import UclPitStopAccessEnum, {
  UclPitStopAccess,
} from "./UclPitStopAccessEnum.generated";
import UclPitStopAccessedByEnum, {
  UclPitStopAccessedBy,
} from "./UclPitStopAccessedByEnum.generated";
import UclPitStopAquaticFaunaEnum, {
  UclPitStopAquaticFauna,
} from "./UclPitStopAquaticFaunaEnum.generated";
import UclPitStopAquaticPlantsEnum, {
  UclPitStopAquaticPlants,
} from "./UclPitStopAquaticPlantsEnum.generated";
import UclPitStopBufferCompositionEnum, {
  UclPitStopBufferComposition,
} from "./UclPitStopBufferCompositionEnum.generated";
import UclPitStopBufferCoverEnum, {
  UclPitStopBufferCover,
} from "./UclPitStopBufferCoverEnum.generated";
import UclPitStopBufferInvasiveEnum, {
  UclPitStopBufferInvasive,
} from "./UclPitStopBufferInvasiveEnum.generated";
import UclPitStopCrassulaHelmsiiEnum, {
  UclPitStopCrassulaHelmsii,
} from "./UclPitStopCrassulaHelmsiiEnum.generated";
import UclPitStopDescriptionEnum, {
  UclPitStopDescription,
} from "./UclPitStopDescriptionEnum.generated";
import UclPitStopDogsEnum, {
  UclPitStopDogs,
} from "./UclPitStopDogsEnum.generated";
import UclPitStopEcologicalHealthEnum, {
  UclPitStopEcologicalHealth,
} from "./UclPitStopEcologicalHealthEnum.generated";
import UclPitStopEdgeFeatureEnum, {
  UclPitStopEdgeFeature,
} from "./UclPitStopEdgeFeatureEnum.generated";
import UclPitStopEdgeGradientEnum, {
  UclPitStopEdgeGradient,
} from "./UclPitStopEdgeGradientEnum.generated";
import UclPitStopFishKnowledgeEnum, {
  UclPitStopFishKnowledge,
} from "./UclPitStopFishKnowledgeEnum.generated";
import UclPitStopFishStockingEnum, {
  UclPitStopFishStocking,
} from "./UclPitStopFishStockingEnum.generated";
import UclPitStopFoundByEnum, {
  UclPitStopFoundBy,
} from "./UclPitStopFoundByEnum.generated";
import UclPitStopGameBirdFeedingEnum, {
  UclPitStopGameBirdFeeding,
} from "./UclPitStopGameBirdFeedingEnum.generated";
import UclPitStopHabitatAvailabilityEnum, {
  UclPitStopHabitatAvailability,
} from "./UclPitStopHabitatAvailabilityEnum.generated";
import UclPitStopHabitatEnum, {
  UclPitStopHabitat,
} from "./UclPitStopHabitatEnum.generated";
import UclPitStopHistoricalKnowledgeEnum, {
  UclPitStopHistoricalKnowledge,
} from "./UclPitStopHistoricalKnowledgeEnum.generated";
import UclPitStopInOrOutflowEnum, {
  UclPitStopInOrOutflow,
} from "./UclPitStopInOrOutflowEnum.generated";
import UclPitStopInflowSourceEnum, {
  UclPitStopInflowSource,
} from "./UclPitStopInflowSourceEnum.generated";
import UclPitStopInvertebrateEnum, {
  UclPitStopInvertebrate,
} from "./UclPitStopInvertebrateEnum.generated";
import UclPitStopLandUseEnum, {
  UclPitStopLandUse,
} from "./UclPitStopLandUseEnum.generated";
import UclPitStopLitterEnum, {
  UclPitStopLitter,
} from "./UclPitStopLitterEnum.generated";
import UclPitStopMapNeighbourEnum, {
  UclPitStopMapNeighbour,
} from "./UclPitStopMapNeighbourEnum.generated";
import UclPitStopMapPointEnum, {
  UclPitStopMapPoint,
} from "./UclPitStopMapPointEnum.generated";
import UclPitStopNorfolkMostWantedEnum, {
  UclPitStopNorfolkMostWanted,
} from "./UclPitStopNorfolkMostWantedEnum.generated";
import UclPitStopPoachingEnum, {
  UclPitStopPoaching,
} from "./UclPitStopPoachingEnum.generated";
import UclPitStopPondCountEnum, {
  UclPitStopPondCount,
} from "./UclPitStopPondCountEnum.generated";
import UclPitStopPondStatusEnum, {
  UclPitStopPondStatus,
} from "./UclPitStopPondStatusEnum.generated";
import UclPitStopRecreationalFishingEnum, {
  UclPitStopRecreationalFishing,
} from "./UclPitStopRecreationalFishingEnum.generated";
import UclPitStopSettingEnum, {
  UclPitStopSetting,
} from "./UclPitStopSettingEnum.generated";
import UclPitStopSubstrateDepthEnum, {
  UclPitStopSubstrateDepth,
} from "./UclPitStopSubstrateDepthEnum.generated";
import UclPitStopSurfaceFeatureEnum, {
  UclPitStopSurfaceFeature,
} from "./UclPitStopSurfaceFeatureEnum.generated";
import UclPitStopTreeSpeciesEnum, {
  UclPitStopTreeSpecies,
} from "./UclPitStopTreeSpeciesEnum.generated";
import UclPitStopWaterColorEnum, {
  UclPitStopWaterColor,
} from "./UclPitStopWaterColorEnum.generated";
import UclPitStopWaterLevelEnum, {
  UclPitStopWaterLevel,
} from "./UclPitStopWaterLevelEnum.generated";
import UclPitStopWaterfowlEnum, {
  UclPitStopWaterfowl,
} from "./UclPitStopWaterfowlEnum.generated";
import UrbanRiverflySpeciesEnum, {
  UrbanRiverflySpecies,
} from "./UrbanRiverflySpeciesEnum.generated";
import UrsApeEnum, { UrsApe } from "./UrsApeEnum.generated";
import UrsArtificialBankProfileEnum, {
  UrsArtificialBankProfile,
} from "./UrsArtificialBankProfileEnum.generated";
import UrsArtificialBankReinforcementEnum, {
  UrsArtificialBankReinforcement,
} from "./UrsArtificialBankReinforcementEnum.generated";
import UrsArtificialFeatureCategoryEnum, {
  UrsArtificialFeatureCategory,
} from "./UrsArtificialFeatureCategoryEnum.generated";
import UrsArtificialFeatureEnum, {
  UrsArtificialFeature,
} from "./UrsArtificialFeatureEnum.generated";
import UrsBankMaterialEnum, {
  UrsBankMaterial,
} from "./UrsBankMaterialEnum.generated";
import UrsBankProtectionEnum, {
  UrsBankProtection,
} from "./UrsBankProtectionEnum.generated";
import UrsBankStructureEnum, {
  UrsBankStructure,
} from "./UrsBankStructureEnum.generated";
import UrsBridgeTypeEnum, {
  UrsBridgeType,
} from "./UrsBridgeTypeEnum.generated";
import UrsChannelDynamicsCategoryEnum, {
  UrsChannelDynamicsCategory,
} from "./UrsChannelDynamicsCategoryEnum.generated";
import UrsChannelDynamicsExtentEnum, {
  UrsChannelDynamicsExtent,
} from "./UrsChannelDynamicsExtentEnum.generated";
import UrsChannelDynamicsFeatureEnum, {
  UrsChannelDynamicsFeature,
} from "./UrsChannelDynamicsFeatureEnum.generated";
import UrsChannelFeatureEnum, {
  UrsChannelFeature,
} from "./UrsChannelFeatureEnum.generated";
import UrsChannelSubstrateEnum, {
  UrsChannelSubstrate,
} from "./UrsChannelSubstrateEnum.generated";
import UrsCountedHabitatFeatureEnum, {
  UrsCountedHabitatFeature,
} from "./UrsCountedHabitatFeatureEnum.generated";
import UrsCrossProfileEnum, {
  UrsCrossProfile,
} from "./UrsCrossProfileEnum.generated";
import UrsEcologicalCategoryEnum, {
  UrsEcologicalCategory,
} from "./UrsEcologicalCategoryEnum.generated";
import UrsEcologicalCharacteristicEnum, {
  UrsEcologicalCharacteristic,
} from "./UrsEcologicalCharacteristicEnum.generated";
import UrsFlowTypeEnum, { UrsFlowType } from "./UrsFlowTypeEnum.generated";
import UrsLandUseEnum, { UrsLandUse } from "./UrsLandUseEnum.generated";
import UrsManagementFeatureEnum, {
  UrsManagementFeature,
} from "./UrsManagementFeatureEnum.generated";
import UrsMarginalFeatureEnum, {
  UrsMarginalFeature,
} from "./UrsMarginalFeatureEnum.generated";
import UrsNaturalBankProfileEnum, {
  UrsNaturalBankProfile,
} from "./UrsNaturalBankProfileEnum.generated";
import UrsNuisanceSpeciesEnum, {
  UrsNuisanceSpecies,
} from "./UrsNuisanceSpeciesEnum.generated";
import UrsPercentageHabitatFeatureEnum, {
  UrsPercentageHabitatFeature,
} from "./UrsPercentageHabitatFeatureEnum.generated";
import UrsPlanformEnum, { UrsPlanform } from "./UrsPlanformEnum.generated";
import UrsPollutionIndicatorEnum, {
  UrsPollutionIndicator,
} from "./UrsPollutionIndicatorEnum.generated";
import UrsPollutionSourceEnum, {
  UrsPollutionSource,
} from "./UrsPollutionSourceEnum.generated";
import UrsProtectedSpeciesCategoryEnum, {
  UrsProtectedSpeciesCategory,
} from "./UrsProtectedSpeciesCategoryEnum.generated";
import UrsProtectedSpeciesEnum, {
  UrsProtectedSpecies,
} from "./UrsProtectedSpeciesEnum.generated";
import UrsReinforcementLevelEnum, {
  UrsReinforcementLevel,
} from "./UrsReinforcementLevelEnum.generated";
import UrsSpecialFeatureEnum, {
  UrsSpecialFeature,
} from "./UrsSpecialFeatureEnum.generated";
import UrsSpeciesFrequencyEnum, {
  UrsSpeciesFrequency,
} from "./UrsSpeciesFrequencyEnum.generated";
import UrsSurveyBankEnum, {
  UrsSurveyBank,
} from "./UrsSurveyBankEnum.generated";
import UrsSurveyStartEnum, {
  UrsSurveyStart,
} from "./UrsSurveyStartEnum.generated";
import UrsSurveyTypeEnum, {
  UrsSurveyType,
} from "./UrsSurveyTypeEnum.generated";
import UrsTreeDistributionEnum, {
  UrsTreeDistribution,
} from "./UrsTreeDistributionEnum.generated";
import UrsTreeFeatureEnum, {
  UrsTreeFeature,
} from "./UrsTreeFeatureEnum.generated";
import UrsVegetationEnum, {
  UrsVegetation,
} from "./UrsVegetationEnum.generated";
import UrsWaterClarityEnum, {
  UrsWaterClarity,
} from "./UrsWaterClarityEnum.generated";
import WrtWestcountryCsiFlowImpedanceEnum, {
  WrtWestcountryCsiFlowImpedance,
} from "./WrtWestcountryCsiFlowImpedanceEnum.generated";
import WrtWestcountryCsiFlowLevelEnum, {
  WrtWestcountryCsiFlowLevel,
} from "./WrtWestcountryCsiFlowLevelEnum.generated";
import WrtAmmoniaEnum, { WrtAmmonia } from "./WrtAmmoniaEnum.generated";
import WrtFordsLandCoverStageEnum, {
  WrtFordsLandCoverStage,
} from "./WrtFordsLandCoverStageEnum.generated";
import WrtFordsLandUseEnum, {
  WrtFordsLandUse,
} from "./WrtFordsLandUseEnum.generated";
import WrtFordsLivestockEnum, {
  WrtFordsLivestock,
} from "./WrtFordsLivestockEnum.generated";
import WrtWestcountryCsiNitrateEnum, {
  WrtWestcountryCsiNitrate,
} from "./WrtWestcountryCsiNitrateEnum.generated";
import WrtUpstreamThinkingLandUseEnum, {
  WrtUpstreamThinkingLandUse,
} from "./WrtUpstreamThinkingLandUseEnum.generated";
import WrtRainfallEnum, { WrtRainfall } from "./WrtRainfallEnum.generated";
import WrtUpstreamThinkingCatchmentEnum, {
  WrtUpstreamThinkingCatchment,
} from "./WrtUpstreamThinkingCatchmentEnum.generated";
import WrtWestcountryCsiBankVegetationEnum, {
  WrtWestcountryCsiBankVegetation,
} from "./WrtWestcountryCsiBankVegetationEnum.generated";
import WrtWestcountryCsiChannelSubstrateEnum, {
  WrtWestcountryCsiChannelSubstrate,
} from "./WrtWestcountryCsiChannelSubstrateEnum.generated";
import WrtWestcountryCsiInvasivePlantEnum, {
  WrtWestcountryCsiInvasivePlant,
} from "./WrtWestcountryCsiInvasivePlantEnum.generated";
import WrtWestcountryCsiLandUseEnum, {
  WrtWestcountryCsiLandUse,
} from "./WrtWestcountryCsiLandUseEnum.generated";
import WrtWestcountryCsiLegacyPollutionSourceEnum, {
  WrtWestcountryCsiLegacyPollutionSource,
} from "./WrtWestcountryCsiLegacyPollutionSourceEnum.generated";
import WrtWestcountryCsiPollutionEvidenceEnum, {
  WrtWestcountryCsiPollutionEvidence,
} from "./WrtWestcountryCsiPollutionEvidenceEnum.generated";
import WrtWestcountryCsiPollutionSourceEnum, {
  WrtWestcountryCsiPollutionSource,
} from "./WrtWestcountryCsiPollutionSourceEnum.generated";
import WrtWestcountryCsiWaterBodyTypeEnum, {
  WrtWestcountryCsiWaterBodyType,
} from "./WrtWestcountryCsiWaterBodyTypeEnum.generated";
import WrtWestcountryCsiWaterLevelEnum, {
  WrtWestcountryCsiWaterLevel,
} from "./WrtWestcountryCsiWaterLevelEnum.generated";
import WrtWestcountryCsiWildlifeEnum, {
  WrtWestcountryCsiWildlife,
} from "./WrtWestcountryCsiWildlifeEnum.generated";
import YesNoUnknownEnum, { YesNoUnknown } from "./YesNoUnknownEnum.generated";
import YesNoEnum, { YesNo } from "./YesNoEnum.generated";

export type {
  ArrtWaterQualityBankVegetation,
  ArrtWaterQualityChannelSubstrate,
  ArrtWaterQualityFlowConditions,
  ArrtWaterQualityFlowObstacle,
  ArrtWaterQualityInvasivePlant,
  ArrtWaterQualityLandUse,
  ArrtWaterQualityNitrate,
  ArrtWaterQualityOutfall,
  ArrtWaterQualityPollutionEvidence,
  ArrtWaterQualityPollutionSource,
  ArrtWaterQualityRainfall,
  ArrtWaterQualityWaterLevel,
  ArrtWaterQualityWaterbodyType,
  ArrtWaterQualityWildlife,
  ArrtWaterqualityChannelVegetation,
  CesAbundance,
  CesAquaticInvasivePlant,
  CesAquaticVegetationForm,
  CesBankFeature,
  CesBankHorizontalProfile,
  CesBankTerrestrialVegetation,
  CesBankTopMaterial,
  CesBankTopTerrestrialVegetation,
  CesBankTopTreeFeature,
  CesBankTopVegetationManagement,
  CesBankTopVegetationStructure,
  CesBankTreeFeature,
  CesBankVerticalProfile,
  CesCondition,
  CesContinuity,
  CesDominance,
  CesFringeContinuity,
  CesFringeWidth,
  CesJettyAlignment,
  CesJettySize,
  CesLandCoverCategory,
  CesLandCoverType,
  CesNaturalMaterial,
  CesPathWidth,
  CesReinforcementExtent,
  CesReinforcementMaterial,
  CesSurveyedFrom,
  CesTerrestrialInvasivePlant,
  CesTypicalHedgeWidth,
  CesWaterFeatureCondition,
  CesWoodySpeciesCount,
  EcostatusAbsentMinorMajor,
  EcostatusConditionAndUse,
  EcostatusConfidenceLevel,
  EcostatusConnectivity,
  EcostatusHygiene,
  EcostatusLitterDisposal,
  EcostatusLitter,
  EcostatusNfmCategory,
  EcostatusNfmPosition,
  EcostatusNoneOneMore,
  EcostatusNoneSomeMany,
  EcostatusPersonalSafety,
  EcostatusPollutionType,
  EcostatusPoorModerateGood,
  EcostatusRailAccess,
  EcostatusRecreationalFacility,
  EcostatusStudyAreaAccessProvision,
  EcostatusStudyAreaAccessRestriction,
  EcostatusStudyAreaBank,
  EcostatusStudyAreaOwnership,
  EsrtWaterQualityAmmonia,
  EsrtWaterQualityBankVegetation,
  EsrtWaterQualityEstimatedDepth,
  EsrtWaterQualityEstimatedWidth,
  EsrtWaterQualityFlowLevel,
  EsrtWaterQualityFlowObstacle,
  EsrtWaterQualityInvasivePlant,
  EsrtWaterQualityLandUse,
  EsrtWaterQualityNitrate,
  EsrtWaterQualityOutfall,
  EsrtWaterQualityPhosphate,
  EsrtWaterQualityPollutionEvidence,
  EsrtWaterQualityPollutionSource,
  EsrtWaterQualityPublicAccess,
  EsrtWaterQualityRainfall,
  EsrtWaterQualityRecreationalActivity,
  EsrtWaterQualityRiverbedComposition,
  EsrtWaterQualityWaterLevel,
  EsrtWaterQualityWaterbodyType,
  EsrtWaterQualityWildlife,
  ExtendedRiverflySpecies,
  FhtPondCountPresence,
  FhtPondCountSize,
  FhtPondCountWhyAbsent,
  FhtPondCountWhyCreated,
  FhtPondCountWhyLostOrDestroyed,
  FhtPondCountWhyMissed,
  FhtPondCountWhyNew,
  FhtPondCountWhySizeChanged,
  FhtPriorityPondsAnnex1Type,
  FhtPriorityPondsPsymAssessmentBasis,
  MrsArtificialGroundCover,
  MrsAtpe,
  MrsBankProfile,
  MrsBedMaterialParticleSize,
  MrsChannelCoverage,
  MrsFlowType,
  MrsHorizontalOrientation,
  MrsMajorIntermediateMinor,
  MrsMorphEstuariesBankProfile,
  MrsMorphEstuariesCreekType,
  MrsMorphEstuariesDaysToSpringTide,
  MrsMorphEstuariesDrainageNetworkType,
  MrsMorphEstuariesFloodWallDimension,
  MrsMorphEstuariesFragmentation,
  MrsMorphEstuariesFrontBackCreekMargin,
  MrsMorphEstuariesGrazing,
  MrsMorphEstuariesMarginType,
  MrsMorphEstuariesSaltMarshLocation,
  MrsMorphEstuariesSmallFragmentDistribution,
  MrsMorphEstuariesSubtidalSedimentSize,
  MrsMorphEstuariesTidalFloodBarrier,
  MrsMorphEstuariesVegetatedIntertidalSedimentSize,
  MrsMorphEstuariesVisibility,
  MrsMudSpotterDisturbedBankFaceSourceType,
  MrsMudSpotterFlow,
  MrsMudSpotterLegacySourceSubType,
  MrsMudSpotterOverlandFlowSourceType,
  MrsMudSpotterRainfallIntensity,
  MrsMudSpotterSediment,
  MrsMudSpotterSourceSize,
  MrsMudSpotterSourceType,
  MrsMudSpotterSurveyType,
  MrsReinforcementType,
  MrsReinforcementVerticalExtent,
  MrsUad,
  MrsRiverWoodArtificialElement,
  MrsRiverWoodJamType,
  MrsRiverWoodHydraulicType,
  MrsRiverWoodRetainedByArtificialFeature,
  MrsRiverWoodRetainedByNaturalFeature,
  MrsRtaAverageBedMaterial,
  MrsRtaCoarsestBedMaterial,
  MrsRtaConditionClass,
  MrsRtaLevelOfConfinement,
  MrsRtaRiverCategory,
  MrsRtaRiverType,
  MrsSedimentSize,
  MrsTreeType,
  MrsWildInvadersAccessibleBy,
  MrsWildInvadersCoverage,
  MrsWildInvadersGrowthStage,
  MrsWildInvadersManagement,
  MrsWildInvadersProximity,
  MrsWildInvadersSpecies,
  NeChalkRiversFeature,
  NeChalkRiversModificationType,
  NeChalkRiversPh,
  NeChalkRiversTreeShading,
  NeLakeNaturalnessBirdSpecies,
  NeLakeNaturalnessChemicalAssessmentForm,
  NeLakeNaturalnessEmergentFringe,
  NeLakeNaturalnessFishSpecies,
  NeLakeNaturalnessHerpetofaunaSpecies,
  NeLakeNaturalnessHigherPlantSpecies,
  NeLakeNaturalnessInvertebrateSpecies,
  NeLakeNaturalnessMammalSpecies,
  NeLakeNaturalnessOutflowStructure,
  NeLakeNaturalnessPerimeterTrees,
  NeLakeNaturalnessPlantFunctionalGroup,
  NeLakeNaturalnessRiparianZone,
  NeLakeNaturalnessShorelineModification,
  NeLakeRestorationPrioritiesBiologicalMeasure,
  NeLakeRestorationPrioritiesChemicalMeasure,
  NeLakeRestorationPrioritiesHydrologicalMeasure,
  NeLakeRestorationPrioritiesPhysicalMeasure,
  NeNameSource,
  NeNaturalnessClass,
  NeNaturalnessConfidenceClass,
  NeNaturalnessInvasivePlantSpecies,
  NeRestorationRelevance,
  NeRiverNaturalnessBiologicalAssessmentForm,
  NeRiverNaturalnessBirdSpecies,
  NeRiverNaturalnessBryophyteSpecies,
  NeRiverNaturalnessChemicalAssessmentForm,
  NeRiverNaturalnessFeature,
  NeRiverNaturalnessFernSpecies,
  NeRiverNaturalnessFishSpecies,
  NeRiverNaturalnessHerpetofaunaSpecies,
  NeRiverNaturalnessHigherPlantSpecies,
  NeRiverNaturalnessHydrologicalAssessmentForm,
  NeRiverNaturalnessInvertebrateSpecies,
  NeRiverNaturalnessMammalSpecies,
  NeRiverNaturalnessPhysicalAssessmentForm,
  NeRiverRestorationPrioritiesBiologicalMeasure,
  NeRiverRestorationPrioritiesChemicalMeasure,
  NeRiverRestorationPrioritiesHydrologicalMeasure,
  NeRiverRestorationPrioritiesPhysicalMeasure,
  NeRiverTypesConfidence,
  NeRiverTypesFeature,
  NeYesNoUncertain,
  NwcSeedbankArea,
  NwcSeedbankCollection,
  NwcSeedbankEvidenceOfPollution,
  NwcSeedbankLandUse,
  NwcSeedbankPermission,
  NwcSeedbankProblemPlantSpecies,
  NwcSeedbankSoilType,
  NwcSeedbankSpecies,
  NwcSeedbankVegetation,
  OutfallSafariActivity,
  OutfallSafariAesthetics,
  OutfallSafariBank,
  OutfallSafariFlowOfDischarge,
  OutfallSafariInaccessibleAreaLength,
  OutfallSafariVisualImpact,
  RiverflyBreachType,
  RiverflyFirstBreachAction,
  RiverflyReasonNotTaken,
  RiverflySampleType,
  RiverflySecondBreachAction,
  RiverflySpecies,
  SasWaterQualitySiteType,
  SasWaterQualityWeather,
  StSmartRiversProject,
  StSmartRiversRole,
  StdActivityType,
  StdInvasiveSpeciesAmount,
  StdInvasiveSpeciesTreatment,
  StdInvasiveSpecies,
  StormPhosphatesLevelTrend,
  StormPhosphatesLevel,
  Thames21BadgedGroupEventActivity,
  Thames21BadgedGroupEventLargeRubbish,
  Thames21BadgedGroupEventSetting,
  Thames21ChannelSide,
  Thames21Foreshore,
  Thames21LitterCategory,
  Thames21LitterCleanupFocus,
  Thames21LitterItem,
  Thames21LitterMetric,
  Thames21LitterPolystyreneCover,
  Thames21LitterSmallPlasticCount,
  Thames21LitterSurveyType,
  Thames21River,
  Thames21SlowFlowFeatureType,
  Thames21SlowFlowPondType,
  Thames21SlowFlowServiceType,
  Thames21SlowFlowSite,
  Thames21SlowFlowSuitability,
  Thames21SlowFlowSurveyType,
  Thames21SlowFlowVegetationType,
  Thames21VegetationOrigin,
  Thames21VegetationSite,
  Thames21VegetationStructure,
  Thames21VegetationType,
  UclPitStopAccess,
  UclPitStopAccessedBy,
  UclPitStopAquaticFauna,
  UclPitStopAquaticPlants,
  UclPitStopBufferComposition,
  UclPitStopBufferCover,
  UclPitStopBufferInvasive,
  UclPitStopCrassulaHelmsii,
  UclPitStopDescription,
  UclPitStopDogs,
  UclPitStopEcologicalHealth,
  UclPitStopEdgeFeature,
  UclPitStopEdgeGradient,
  UclPitStopFishKnowledge,
  UclPitStopFishStocking,
  UclPitStopFoundBy,
  UclPitStopGameBirdFeeding,
  UclPitStopHabitatAvailability,
  UclPitStopHabitat,
  UclPitStopHistoricalKnowledge,
  UclPitStopInOrOutflow,
  UclPitStopInflowSource,
  UclPitStopInvertebrate,
  UclPitStopLandUse,
  UclPitStopLitter,
  UclPitStopMapNeighbour,
  UclPitStopMapPoint,
  UclPitStopNorfolkMostWanted,
  UclPitStopPoaching,
  UclPitStopPondCount,
  UclPitStopPondStatus,
  UclPitStopRecreationalFishing,
  UclPitStopSetting,
  UclPitStopSubstrateDepth,
  UclPitStopSurfaceFeature,
  UclPitStopTreeSpecies,
  UclPitStopWaterColor,
  UclPitStopWaterLevel,
  UclPitStopWaterfowl,
  UrbanRiverflySpecies,
  UrsApe,
  UrsArtificialBankProfile,
  UrsArtificialBankReinforcement,
  UrsArtificialFeatureCategory,
  UrsArtificialFeature,
  UrsBankMaterial,
  UrsBankProtection,
  UrsBankStructure,
  UrsBridgeType,
  UrsChannelDynamicsCategory,
  UrsChannelDynamicsExtent,
  UrsChannelDynamicsFeature,
  UrsChannelFeature,
  UrsChannelSubstrate,
  UrsCountedHabitatFeature,
  UrsCrossProfile,
  UrsEcologicalCategory,
  UrsEcologicalCharacteristic,
  UrsFlowType,
  UrsLandUse,
  UrsManagementFeature,
  UrsMarginalFeature,
  UrsNaturalBankProfile,
  UrsNuisanceSpecies,
  UrsPercentageHabitatFeature,
  UrsPlanform,
  UrsPollutionIndicator,
  UrsPollutionSource,
  UrsProtectedSpeciesCategory,
  UrsProtectedSpecies,
  UrsReinforcementLevel,
  UrsSpecialFeature,
  UrsSpeciesFrequency,
  UrsSurveyBank,
  UrsSurveyStart,
  UrsSurveyType,
  UrsTreeDistribution,
  UrsTreeFeature,
  UrsVegetation,
  UrsWaterClarity,
  WrtWestcountryCsiFlowImpedance,
  WrtWestcountryCsiFlowLevel,
  WrtAmmonia,
  WrtFordsLandCoverStage,
  WrtFordsLandUse,
  WrtFordsLivestock,
  WrtWestcountryCsiNitrate,
  WrtUpstreamThinkingLandUse,
  WrtRainfall,
  WrtUpstreamThinkingCatchment,
  WrtWestcountryCsiBankVegetation,
  WrtWestcountryCsiChannelSubstrate,
  WrtWestcountryCsiInvasivePlant,
  WrtWestcountryCsiLandUse,
  WrtWestcountryCsiLegacyPollutionSource,
  WrtWestcountryCsiPollutionEvidence,
  WrtWestcountryCsiPollutionSource,
  WrtWestcountryCsiWaterBodyType,
  WrtWestcountryCsiWaterLevel,
  WrtWestcountryCsiWildlife,
  YesNoUnknown,
  YesNo,
};

export {
  ArrtWaterQualityBankVegetationEnum,
  ArrtWaterQualityChannelSubstrateEnum,
  ArrtWaterQualityFlowConditionsEnum,
  ArrtWaterQualityFlowObstacleEnum,
  ArrtWaterQualityInvasivePlantEnum,
  ArrtWaterQualityLandUseEnum,
  ArrtWaterQualityNitrateEnum,
  ArrtWaterQualityOutfallEnum,
  ArrtWaterQualityPollutionEvidenceEnum,
  ArrtWaterQualityPollutionSourceEnum,
  ArrtWaterQualityRainfallEnum,
  ArrtWaterQualityWaterLevelEnum,
  ArrtWaterQualityWaterbodyTypeEnum,
  ArrtWaterQualityWildlifeEnum,
  ArrtWaterqualityChannelVegetationEnum,
  CesAbundanceEnum,
  CesAquaticInvasivePlantEnum,
  CesAquaticVegetationFormEnum,
  CesBankFeatureEnum,
  CesBankHorizontalProfileEnum,
  CesBankTerrestrialVegetationEnum,
  CesBankTopMaterialEnum,
  CesBankTopTerrestrialVegetationEnum,
  CesBankTopTreeFeatureEnum,
  CesBankTopVegetationManagementEnum,
  CesBankTopVegetationStructureEnum,
  CesBankTreeFeatureEnum,
  CesBankVerticalProfileEnum,
  CesConditionEnum,
  CesContinuityEnum,
  CesDominanceEnum,
  CesFringeContinuityEnum,
  CesFringeWidthEnum,
  CesJettyAlignmentEnum,
  CesJettySizeEnum,
  CesLandCoverCategoryEnum,
  CesLandCoverTypeEnum,
  CesNaturalMaterialEnum,
  CesPathWidthEnum,
  CesReinforcementExtentEnum,
  CesReinforcementMaterialEnum,
  CesSurveyedFromEnum,
  CesTerrestrialInvasivePlantEnum,
  CesTypicalHedgeWidthEnum,
  CesWaterFeatureConditionEnum,
  CesWoodySpeciesCountEnum,
  EcostatusAbsentMinorMajorEnum,
  EcostatusConditionAndUseEnum,
  EcostatusConfidenceLevelEnum,
  EcostatusConnectivityEnum,
  EcostatusHygieneEnum,
  EcostatusLitterDisposalEnum,
  EcostatusLitterEnum,
  EcostatusNfmCategoryEnum,
  EcostatusNfmPositionEnum,
  EcostatusNoneOneMoreEnum,
  EcostatusNoneSomeManyEnum,
  EcostatusPersonalSafetyEnum,
  EcostatusPollutionTypeEnum,
  EcostatusPoorModerateGoodEnum,
  EcostatusRailAccessEnum,
  EcostatusRecreationalFacilityEnum,
  EcostatusStudyAreaAccessProvisionEnum,
  EcostatusStudyAreaAccessRestrictionEnum,
  EcostatusStudyAreaBankEnum,
  EcostatusStudyAreaOwnershipEnum,
  EsrtWaterQualityAmmoniaEnum,
  EsrtWaterQualityBankVegetationEnum,
  EsrtWaterQualityEstimatedDepthEnum,
  EsrtWaterQualityEstimatedWidthEnum,
  EsrtWaterQualityFlowLevelEnum,
  EsrtWaterQualityFlowObstacleEnum,
  EsrtWaterQualityInvasivePlantEnum,
  EsrtWaterQualityLandUseEnum,
  EsrtWaterQualityNitrateEnum,
  EsrtWaterQualityOutfallEnum,
  EsrtWaterQualityPhosphateEnum,
  EsrtWaterQualityPollutionEvidenceEnum,
  EsrtWaterQualityPollutionSourceEnum,
  EsrtWaterQualityPublicAccessEnum,
  EsrtWaterQualityRainfallEnum,
  EsrtWaterQualityRecreationalActivityEnum,
  EsrtWaterQualityRiverbedCompositionEnum,
  EsrtWaterQualityWaterLevelEnum,
  EsrtWaterQualityWaterbodyTypeEnum,
  EsrtWaterQualityWildlifeEnum,
  ExtendedRiverflySpeciesEnum,
  FhtPondCountPresenceEnum,
  FhtPondCountSizeEnum,
  FhtPondCountWhyAbsentEnum,
  FhtPondCountWhyCreatedEnum,
  FhtPondCountWhyLostOrDestroyedEnum,
  FhtPondCountWhyMissedEnum,
  FhtPondCountWhyNewEnum,
  FhtPondCountWhySizeChangedEnum,
  FhtPriorityPondsAnnex1TypeEnum,
  FhtPriorityPondsPsymAssessmentBasisEnum,
  MrsArtificialGroundCoverEnum,
  MrsAtpeEnum,
  MrsBankProfileEnum,
  MrsBedMaterialParticleSizeEnum,
  MrsChannelCoverageEnum,
  MrsFlowTypeEnum,
  MrsHorizontalOrientationEnum,
  MrsMajorIntermediateMinorEnum,
  MrsMorphEstuariesBankProfileEnum,
  MrsMorphEstuariesCreekTypeEnum,
  MrsMorphEstuariesDaysToSpringTideEnum,
  MrsMorphEstuariesDrainageNetworkTypeEnum,
  MrsMorphEstuariesFloodWallDimensionEnum,
  MrsMorphEstuariesFragmentationEnum,
  MrsMorphEstuariesFrontBackCreekMarginEnum,
  MrsMorphEstuariesGrazingEnum,
  MrsMorphEstuariesMarginTypeEnum,
  MrsMorphEstuariesSaltMarshLocationEnum,
  MrsMorphEstuariesSmallFragmentDistributionEnum,
  MrsMorphEstuariesSubtidalSedimentSizeEnum,
  MrsMorphEstuariesTidalFloodBarrierEnum,
  MrsMorphEstuariesVegetatedIntertidalSedimentSizeEnum,
  MrsMorphEstuariesVisibilityEnum,
  MrsMudSpotterDisturbedBankFaceSourceTypeEnum,
  MrsMudSpotterFlowEnum,
  MrsMudSpotterLegacySourceSubTypeEnum,
  MrsMudSpotterOverlandFlowSourceTypeEnum,
  MrsMudSpotterRainfallIntensityEnum,
  MrsMudSpotterSedimentEnum,
  MrsMudSpotterSourceSizeEnum,
  MrsMudSpotterSourceTypeEnum,
  MrsMudSpotterSurveyTypeEnum,
  MrsReinforcementTypeEnum,
  MrsReinforcementVerticalExtentEnum,
  MrsUadEnum,
  MrsRiverWoodArtificialElementEnum,
  MrsRiverWoodJamTypeEnum,
  MrsRiverWoodHydraulicTypeEnum,
  MrsRiverWoodRetainedByArtificialFeatureEnum,
  MrsRiverWoodRetainedByNaturalFeatureEnum,
  MrsRtaAverageBedMaterialEnum,
  MrsRtaCoarsestBedMaterialEnum,
  MrsRtaConditionClassEnum,
  MrsRtaLevelOfConfinementEnum,
  MrsRtaRiverCategoryEnum,
  MrsRtaRiverTypeEnum,
  MrsSedimentSizeEnum,
  MrsTreeTypeEnum,
  MrsWildInvadersAccessibleByEnum,
  MrsWildInvadersCoverageEnum,
  MrsWildInvadersGrowthStageEnum,
  MrsWildInvadersManagementEnum,
  MrsWildInvadersProximityEnum,
  MrsWildInvadersSpeciesEnum,
  NeChalkRiversFeatureEnum,
  NeChalkRiversModificationTypeEnum,
  NeChalkRiversPhEnum,
  NeChalkRiversTreeShadingEnum,
  NeLakeNaturalnessBirdSpeciesEnum,
  NeLakeNaturalnessChemicalAssessmentFormEnum,
  NeLakeNaturalnessEmergentFringeEnum,
  NeLakeNaturalnessFishSpeciesEnum,
  NeLakeNaturalnessHerpetofaunaSpeciesEnum,
  NeLakeNaturalnessHigherPlantSpeciesEnum,
  NeLakeNaturalnessInvertebrateSpeciesEnum,
  NeLakeNaturalnessMammalSpeciesEnum,
  NeLakeNaturalnessOutflowStructureEnum,
  NeLakeNaturalnessPerimeterTreesEnum,
  NeLakeNaturalnessPlantFunctionalGroupEnum,
  NeLakeNaturalnessRiparianZoneEnum,
  NeLakeNaturalnessShorelineModificationEnum,
  NeLakeRestorationPrioritiesBiologicalMeasureEnum,
  NeLakeRestorationPrioritiesChemicalMeasureEnum,
  NeLakeRestorationPrioritiesHydrologicalMeasureEnum,
  NeLakeRestorationPrioritiesPhysicalMeasureEnum,
  NeNameSourceEnum,
  NeNaturalnessClassEnum,
  NeNaturalnessConfidenceClassEnum,
  NeNaturalnessInvasivePlantSpeciesEnum,
  NeRestorationRelevanceEnum,
  NeRiverNaturalnessBiologicalAssessmentFormEnum,
  NeRiverNaturalnessBirdSpeciesEnum,
  NeRiverNaturalnessBryophyteSpeciesEnum,
  NeRiverNaturalnessChemicalAssessmentFormEnum,
  NeRiverNaturalnessFeatureEnum,
  NeRiverNaturalnessFernSpeciesEnum,
  NeRiverNaturalnessFishSpeciesEnum,
  NeRiverNaturalnessHerpetofaunaSpeciesEnum,
  NeRiverNaturalnessHigherPlantSpeciesEnum,
  NeRiverNaturalnessHydrologicalAssessmentFormEnum,
  NeRiverNaturalnessInvertebrateSpeciesEnum,
  NeRiverNaturalnessMammalSpeciesEnum,
  NeRiverNaturalnessPhysicalAssessmentFormEnum,
  NeRiverRestorationPrioritiesBiologicalMeasureEnum,
  NeRiverRestorationPrioritiesChemicalMeasureEnum,
  NeRiverRestorationPrioritiesHydrologicalMeasureEnum,
  NeRiverRestorationPrioritiesPhysicalMeasureEnum,
  NeRiverTypesConfidenceEnum,
  NeRiverTypesFeatureEnum,
  NeYesNoUncertainEnum,
  NwcSeedbankAreaEnum,
  NwcSeedbankCollectionEnum,
  NwcSeedbankEvidenceOfPollutionEnum,
  NwcSeedbankLandUseEnum,
  NwcSeedbankPermissionEnum,
  NwcSeedbankProblemPlantSpeciesEnum,
  NwcSeedbankSoilTypeEnum,
  NwcSeedbankSpeciesEnum,
  NwcSeedbankVegetationEnum,
  OutfallSafariActivityEnum,
  OutfallSafariAestheticsEnum,
  OutfallSafariBankEnum,
  OutfallSafariFlowOfDischargeEnum,
  OutfallSafariInaccessibleAreaLengthEnum,
  OutfallSafariVisualImpactEnum,
  RiverflyBreachTypeEnum,
  RiverflyFirstBreachActionEnum,
  RiverflyReasonNotTakenEnum,
  RiverflySampleTypeEnum,
  RiverflySecondBreachActionEnum,
  RiverflySpeciesEnum,
  SasWaterQualitySiteTypeEnum,
  SasWaterQualityWeatherEnum,
  StSmartRiversProjectEnum,
  StSmartRiversRoleEnum,
  StdActivityTypeEnum,
  StdInvasiveSpeciesAmountEnum,
  StdInvasiveSpeciesTreatmentEnum,
  StdInvasiveSpeciesEnum,
  StormPhosphatesLevelTrendEnum,
  StormPhosphatesLevelEnum,
  Thames21BadgedGroupEventActivityEnum,
  Thames21BadgedGroupEventLargeRubbishEnum,
  Thames21BadgedGroupEventSettingEnum,
  Thames21ChannelSideEnum,
  Thames21ForeshoreEnum,
  Thames21LitterCategoryEnum,
  Thames21LitterCleanupFocusEnum,
  Thames21LitterItemEnum,
  Thames21LitterMetricEnum,
  Thames21LitterPolystyreneCoverEnum,
  Thames21LitterSmallPlasticCountEnum,
  Thames21LitterSurveyTypeEnum,
  Thames21RiverEnum,
  Thames21SlowFlowFeatureTypeEnum,
  Thames21SlowFlowPondTypeEnum,
  Thames21SlowFlowServiceTypeEnum,
  Thames21SlowFlowSiteEnum,
  Thames21SlowFlowSuitabilityEnum,
  Thames21SlowFlowSurveyTypeEnum,
  Thames21SlowFlowVegetationTypeEnum,
  Thames21VegetationOriginEnum,
  Thames21VegetationSiteEnum,
  Thames21VegetationStructureEnum,
  Thames21VegetationTypeEnum,
  UclPitStopAccessEnum,
  UclPitStopAccessedByEnum,
  UclPitStopAquaticFaunaEnum,
  UclPitStopAquaticPlantsEnum,
  UclPitStopBufferCompositionEnum,
  UclPitStopBufferCoverEnum,
  UclPitStopBufferInvasiveEnum,
  UclPitStopCrassulaHelmsiiEnum,
  UclPitStopDescriptionEnum,
  UclPitStopDogsEnum,
  UclPitStopEcologicalHealthEnum,
  UclPitStopEdgeFeatureEnum,
  UclPitStopEdgeGradientEnum,
  UclPitStopFishKnowledgeEnum,
  UclPitStopFishStockingEnum,
  UclPitStopFoundByEnum,
  UclPitStopGameBirdFeedingEnum,
  UclPitStopHabitatAvailabilityEnum,
  UclPitStopHabitatEnum,
  UclPitStopHistoricalKnowledgeEnum,
  UclPitStopInOrOutflowEnum,
  UclPitStopInflowSourceEnum,
  UclPitStopInvertebrateEnum,
  UclPitStopLandUseEnum,
  UclPitStopLitterEnum,
  UclPitStopMapNeighbourEnum,
  UclPitStopMapPointEnum,
  UclPitStopNorfolkMostWantedEnum,
  UclPitStopPoachingEnum,
  UclPitStopPondCountEnum,
  UclPitStopPondStatusEnum,
  UclPitStopRecreationalFishingEnum,
  UclPitStopSettingEnum,
  UclPitStopSubstrateDepthEnum,
  UclPitStopSurfaceFeatureEnum,
  UclPitStopTreeSpeciesEnum,
  UclPitStopWaterColorEnum,
  UclPitStopWaterLevelEnum,
  UclPitStopWaterfowlEnum,
  UrbanRiverflySpeciesEnum,
  UrsApeEnum,
  UrsArtificialBankProfileEnum,
  UrsArtificialBankReinforcementEnum,
  UrsArtificialFeatureCategoryEnum,
  UrsArtificialFeatureEnum,
  UrsBankMaterialEnum,
  UrsBankProtectionEnum,
  UrsBankStructureEnum,
  UrsBridgeTypeEnum,
  UrsChannelDynamicsCategoryEnum,
  UrsChannelDynamicsExtentEnum,
  UrsChannelDynamicsFeatureEnum,
  UrsChannelFeatureEnum,
  UrsChannelSubstrateEnum,
  UrsCountedHabitatFeatureEnum,
  UrsCrossProfileEnum,
  UrsEcologicalCategoryEnum,
  UrsEcologicalCharacteristicEnum,
  UrsFlowTypeEnum,
  UrsLandUseEnum,
  UrsManagementFeatureEnum,
  UrsMarginalFeatureEnum,
  UrsNaturalBankProfileEnum,
  UrsNuisanceSpeciesEnum,
  UrsPercentageHabitatFeatureEnum,
  UrsPlanformEnum,
  UrsPollutionIndicatorEnum,
  UrsPollutionSourceEnum,
  UrsProtectedSpeciesCategoryEnum,
  UrsProtectedSpeciesEnum,
  UrsReinforcementLevelEnum,
  UrsSpecialFeatureEnum,
  UrsSpeciesFrequencyEnum,
  UrsSurveyBankEnum,
  UrsSurveyStartEnum,
  UrsSurveyTypeEnum,
  UrsTreeDistributionEnum,
  UrsTreeFeatureEnum,
  UrsVegetationEnum,
  UrsWaterClarityEnum,
  WrtWestcountryCsiFlowImpedanceEnum,
  WrtWestcountryCsiFlowLevelEnum,
  WrtAmmoniaEnum,
  WrtFordsLandCoverStageEnum,
  WrtFordsLandUseEnum,
  WrtFordsLivestockEnum,
  WrtWestcountryCsiNitrateEnum,
  WrtUpstreamThinkingLandUseEnum,
  WrtRainfallEnum,
  WrtUpstreamThinkingCatchmentEnum,
  WrtWestcountryCsiBankVegetationEnum,
  WrtWestcountryCsiChannelSubstrateEnum,
  WrtWestcountryCsiInvasivePlantEnum,
  WrtWestcountryCsiLandUseEnum,
  WrtWestcountryCsiLegacyPollutionSourceEnum,
  WrtWestcountryCsiPollutionEvidenceEnum,
  WrtWestcountryCsiPollutionSourceEnum,
  WrtWestcountryCsiWaterBodyTypeEnum,
  WrtWestcountryCsiWaterLevelEnum,
  WrtWestcountryCsiWildlifeEnum,
  YesNoUnknownEnum,
  YesNoEnum,
};
