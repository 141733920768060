import { Result } from "@cartographerio/fp";
import { GeometryAtom, isPoint, Point } from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  Thames21SlowFlowSite,
  Thames21SlowFlowSiteEnum,
  Thames21SlowFlowSurveyType,
  Thames21SlowFlowSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface PartialSlowFlowData {
  recorded?: Timestamp | null;
  location?: Point | null;
  surveyType?: Thames21SlowFlowSurveyType | null;
  site?: Thames21SlowFlowSite | null;
  otherSite?: string | null;
}

export function isPartialData(data: unknown): data is PartialSlowFlowData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(
      data,
      "surveyType",
      isNullable(Thames21SlowFlowSurveyTypeEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "site",
      isNullable(Thames21SlowFlowSiteEnum.isValue)
    ) &&
    hasOptionalKey(data, "otherSite", isNullable(isString))
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialThames21SlowFlow"
  )(data).map(data =>
    [
      data.surveyType == null
        ? "Unknown Survey Type"
        : Thames21SlowFlowSurveyTypeEnum.labelOf(data.surveyType),
      data.site == null
        ? data.otherSite ?? "Unknown Location"
        : Thames21SlowFlowSiteEnum.labelOf(data.site),
    ].join(" at ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialThames21SlowFlow"
  )(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialThames21SlowFlow"
  )(data).map(data => data.recorded ?? null);
}

export function copyData(
  data: unknown
): Result<GuardError, PartialSlowFlowData> {
  return Result.guard(
    isPartialData,
    "PartialThames21SlowFlow"
  )(data).map(data => ({
    ...data,
    recorded: null,
  }));
}
