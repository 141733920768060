import { Path } from "@cartographerio/topo-core";
import {
  attachmentField,
  Block,
  bounds,
  checkbox,
  checkboxGroup,
  columns2,
  columns3,
  enumSelectOptions,
  form,
  grid,
  integerField,
  minValue,
  page,
  percentageDistributionGroup,
  pointField,
  repeat,
  required,
  requiredIff,
  section,
  select,
  selectGroup,
  SelectOption,
  textArea,
  textField,
  timestampField,
  userRefField,
  vstack,
} from "@cartographerio/topo-form";
import { isNumber } from "@cartographerio/guard";
import {
  EcostatusAbsentMinorMajorEnum,
  EcostatusConditionAndUse,
  EcostatusConditionAndUseEnum,
  EcostatusConfidenceLevelEnum,
  EcostatusConnectivityEnum,
  EcostatusNfmCategoryEnum,
  EcostatusNfmPositionEnum,
  EcostatusNoneOneMore,
  EcostatusNoneOneMoreEnum,
  EcostatusNoneSomeManyEnum,
  EcostatusPoorModerateGoodEnum,
  EcostatusRailAccessEnum,
  EcostatusRecreationalFacilityEnum,
  EcostatusStudyAreaAccessProvisionEnum,
  EcostatusStudyAreaAccessRestrictionEnum,
  EcostatusStudyAreaBankEnum,
  EcostatusStudyAreaOwnershipEnum,
  UrsApe,
  UrsApeEnum,
  UrsLandUseEnum,
  UrsProtectedSpecies,
  UrsProtectedSpeciesEnum,
  UrsSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { AttachmentCategoryEnum } from "@cartographerio/types";
import { chain } from "lodash";
import outdent from "outdent";
import { interleaveFields } from "../Urs/helpers";

function studyAreaMapSection(path: Path): Block {
  return section({
    title: "Study Area Map",
    path: [],
    help: outdent`
    One or more annotated maps showing the limits of the study area and fringe
    and the locations of any features that you wish to highlight.
    These maps appear on all of the study area information,
    heritage features, and high level assessment pages of the survey form.
    `,
    blocks: [
      attachmentField({
        label: null,
        path,
        maxFiles: 4,
        suggestedCategories: [
          AttachmentCategoryEnum.Image,
          AttachmentCategoryEnum.Document,
        ],
      }),
    ],
  });
}

interface ProtectedSpeciesSectionProps {
  title: string;
  species: SelectOption<UrsProtectedSpecies>[];
}

function protectedSpeciesSection(props: ProtectedSpeciesSectionProps) {
  const { title, species } = props;
  return section({
    title,
    path: [],
    blocks: [
      grid({
        columns: 3,
        blocks: interleaveFields(
          checkboxGroup({
            path: ["observedProtectedSpecies"],
            fields: species.map(({ value, label }) => ({
              value,
              label,
              checkboxLabel: "Observed?",
            })),
          }),
          checkboxGroup({
            path: ["physicalSignsOfProtectedSpecies"],
            fields: species.map(({ value, label }) => ({
              value,
              label,
              checkboxLabel: "Physical Signs?",
            })),
          }),
          checkboxGroup({
            path: ["suitableHabitatForProtectedSpecies"],
            fields: species.map(({ value, label }) => ({
              value,
              label,
              checkboxLabel: "Suitable Habitat?",
            })),
          })
        ),
      }),
    ],
  });
}

function heritageFeaturesSection(props: {
  title: string;
  path: Path;
  help?: string;
}): Block {
  const { title, path, help } = props;
  return section({
    title,
    path: [],
    help,
    blocks: [
      repeat({
        path,
        block: grid({
          columns: 2,
          blocks: [
            textField({
              label: "Name/Description",
              path: ["description"],
              fullWidth: true,
              required: required("info"),
            }),
            textField({
              label: "Reference Number",
              path: ["number"],
            }),
            checkbox({
              label: "Situated on Fringe?",
              path: ["situatedOnFringe"],
              checkboxLabel: "Is the feature situated on the fringe?",
            }),
            pointField({
              label: "Location",
              fullWidth: true,
              path: ["location"],
            }),
            select({
              label: "Condition",
              path: ["condition"],
              options: enumSelectOptions(EcostatusConditionAndUseEnum),
              required: required("info"),
            }),
            textField({
              label: "Period",
              path: ["period"],
              required: required("info"),
            }),
            attachmentField({
              label: "Photographs",
              path: ["photographs"],
              fullWidth: true,
              maxFiles: 2,
            }),
            textArea({
              label: "Comments",
              path: ["comments"],
              fullWidth: true,
              rows: 5,
            }),
          ],
        }),
      }),
    ],
  });
}

interface HlaScore {
  label: string;
  assessmentPath: Path;
  confidencePath: Path;
}

function hlaScoresSection(props: {
  title: string;
  path: Path;
  help?: string;
  scores: HlaScore[];
}): Block {
  const { title, path, help, scores } = props;
  return section({
    title,
    path,
    help,
    blocks: [
      grid({
        columns: 2,
        blocks: chain(scores)
          .map(({ label, assessmentPath, confidencePath }) => [
            integerField({
              label: `${label} Assessment Score`,
              path: assessmentPath,
              placeholder: "Score from 1 to 5",
              required: required("info"),
              bounds: bounds(1, 5, "error"),
            }),
            select({
              label: `${label} Confidence`,
              path: confidencePath,
              options: enumSelectOptions(EcostatusConfidenceLevelEnum),
              required: required("info"),
            }),
          ])
          .flatten()
          .value(),
      }),
    ],
  });
}

function hlaKeyPointsSection(props: {
  title: string;
  path: Path;
  help?: string;
}): Block {
  const { title, path, help } = props;
  return section({
    title,
    path: [],
    help,
    blocks: [
      repeat({
        path,
        block: vstack(
          textArea({
            label: "Key Point",
            path: ["point"],
            rows: 5,
            required: required("info"),
          }),
          textField({
            label: "Reference Number",
            path: ["number"],
          }),
          pointField({
            label: "Location",
            path: ["location"],
          }),
          attachmentField({
            label: "Photographs",
            path: ["photographs"],
            maxFiles: 2,
          })
        ),
      }),
    ],
  });
}

export default form({
  title: "PlaceMarker Study Area",
  pages: [
    page({
      title: "Survey Details",
      path: [],
      blocks: [
        section({
          title: "Project Details",
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                textField({
                  label: "Project Name",
                  path: ["data", "surveyDetails", "projectName"],
                  help: "Name of the river restoration project being monitored.",
                }),
                textField({
                  label: "Project Code",
                  path: ["data", "surveyDetails", "projectCode"],
                  help: "Code for the restoration project being monitored.",
                }),
                select({
                  label: "Survey Type",
                  path: ["data", "surveyDetails", "surveyType"],
                  options: enumSelectOptions(UrsSurveyTypeEnum),
                  help: outdent`
                  Is this survey for general *monitoring() purposes,
                  is it a *pre-project*, *post-project* or *post-recovery* assessment,
                  is it testing a hypothetical *scenario*,
                  or is it a dummy survey created during a *training* course?
                  `,
                }),
                textField({
                  label: "Scenario Name",
                  path: ["data", "surveyDetails", "scenarioName"],
                  help: outdent`
                  If you selected scenario above, enter a scenario name here.
                  The name will be searchable on the survey list.
                  `,
                  customRules: requiredIff({
                    level: "info",
                    requiredMessage: "You should name the scenario.",
                    forbiddenMessage:
                      "Only enter a scenario name if you selected 'Scenario' above.",
                    otherPath: ["data", "surveyDetails", "surveyType"],
                    otherTest: value => value === UrsSurveyTypeEnum.Scenario,
                  }),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "River and Study Area Details",
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                textField({
                  label: "WFD Water Body ID",
                  path: ["data", "surveyDetails", "wfdWaterBodyId"],
                  fullWidth: true,
                  help: outdent`
                  ID used to identify the water body
                  for the purposes of Water Framework Directive reporting.
                  `,
                }),
                textField({
                  label: "River Name",
                  path: ["data", "surveyDetails", "riverName"],
                  fullWidth: true,
                  required: required("info"),
                  help: outdent`
                  Name of the river or stream
                  (e.g. as shown on 1:50,000 scale maps).
                  Unnamed tributaries should be named
                  with reference to a main watercourse
                  (e.g. "Tributary of River Brent").
                  `,
                }),
                textField({
                  label: "Study Area Name",
                  path: ["data", "surveyDetails", "studyAreaName"],
                  required: required("info"),
                  help: outdent`
                  Reference name for the study area.
                  `,
                }),
                textField({
                  label: "Study Area Number",
                  path: ["data", "surveyDetails", "studyAreaCode"],
                  required: required("info"),
                  help: outdent`
                  Unique study area identification number.
                  `,
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Survey Details",
          path: [],
          blocks: [
            userRefField({
              label: "Second Surveyor",
              path: ["data", "secondSurveyor"],
              help: outdent`
              Name and email address of a second surveyor (if present).
              `,
            }),
            timestampField({
              label: "Recorded",
              path: ["data", "recorded"],
              required: required("info"),
              help: outdent`
              Date and time the survey was recorded in the field.
              `,
              defaultValue: "now",
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Site Information",
      path: ["data", "siteInformation"],
      blocks: [
        section({
          title: "Study Area Extent",
          path: [],
          help: outdent`
          The *study area* includes the project site and surrounding area that is likely to be indirectly influenced by the proposed project.
          The visual envelope surrounding the study area is described as the *fringe*:

          ![Study Area Example](https://media.cartographer.io/static/images/ecostatus/study-area.jpg)

          Record whether the study area is on the left, right, or both banks of the river,
          and record the GPS positions of the upstream and downstream extents of the river
          as it crosses the study area boundary.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Study Area Banks",
                  path: ["studyAreaBanks"],
                  fullWidth: true,
                  options: enumSelectOptions(EcostatusStudyAreaBankEnum),
                  required: required("info"),
                }),
                pointField({
                  label: "Upstream Location",
                  path: ["upstreamLocation"],
                  required: required("info"),
                }),
                pointField({
                  label: "Downstream Location",
                  path: ["downstreamLocation"],
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        studyAreaMapSection(["siteMap"]),
        section({
          title: "Study Area Photographs",
          path: [],
          help: outdent`
          Up to four photographs of the study area.
          `,
          blocks: [
            attachmentField({
              label: null,
              path: ["photographs"],
              maxFiles: 4,
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Land Cover",
      path: ["data", "landCover"],
      blocks: [
        section({
          title: "Study Area Land Use",
          path: [],
          help: outdent`
          Percentage cover of the specified land use types within the study area.
          Percentages can be checked using Google Earth or other aerial imagery.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                ...interleaveFields(
                  percentageDistributionGroup({
                    path: ["leftBankStudyAreaLandUse"],
                    fields: enumSelectOptions(UrsLandUseEnum).map(
                      ({ value, label }) => ({
                        value,
                        label: `${label} (${value})`,
                        secondaryLabel: "Left Bank",
                      })
                    ),
                  }),
                  percentageDistributionGroup({
                    path: ["rightBankStudyAreaLandUse"],
                    fields: enumSelectOptions(UrsLandUseEnum).map(
                      ({ value, label }) => ({
                        value,
                        label: `${label} (${value})`,
                        secondaryLabel: "Right Bank",
                      })
                    ),
                  })
                ),
                textArea({
                  label: "Other Study Area Land Use",
                  secondaryLabel: "Left Bank",
                  path: ["otherLeftBankStudyAreaLandUse"],
                  rows: 5,
                  customRules: requiredIff({
                    level: "info",
                    requiredMessage: "Please specify the other land use.",
                    forbiddenMessage:
                      "Only enter a value id you recorded 'Other' land use above.",
                    otherPath: [
                      "leftBankStudyAreaLandUse",
                      UrsLandUseEnum.Other,
                    ],
                    otherTest: value => isNumber(value) && value > 0,
                  }),
                }),
                textArea({
                  label: "Other Study Area Land Use",
                  secondaryLabel: "Right Bank",
                  path: ["otherRightBankStudyAreaLandUse"],
                  rows: 5,
                  customRules: requiredIff({
                    level: "info",
                    requiredMessage: "Please specify the other land use.",
                    forbiddenMessage:
                      "Only enter a value id you recorded 'Other' land use above.",
                    otherPath: [
                      "rightBankStudyAreaLandUse",
                      UrsLandUseEnum.Other,
                    ],
                    otherTest: value => isNumber(value) && value > 0,
                  }),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Fringe Land Use",
          path: [],
          help: outdent`
          Percentage cover of the specified land use types within the study area.
          Percentages can be checked using Google Earth or other aerial imagery.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                ...interleaveFields(
                  percentageDistributionGroup({
                    path: ["leftBankFringeLandUse"],
                    fields: enumSelectOptions(UrsLandUseEnum).map(
                      ({ value, label }) => ({
                        value,
                        label: `${label} (${value})`,
                        secondaryLabel: "Left Bank",
                      })
                    ),
                  }),
                  percentageDistributionGroup({
                    path: ["rightBankFringeLandUse"],
                    fields: enumSelectOptions(UrsLandUseEnum).map(
                      ({ value, label }) => ({
                        value,
                        label: `${label} (${value})`,
                        secondaryLabel: "Right Bank",
                      })
                    ),
                  })
                ),
                textArea({
                  label: "Other Fringe Land Use",
                  secondaryLabel: "Left Bank",
                  path: ["otherLeftBankFringeLandUse"],
                  rows: 5,
                  customRules: requiredIff({
                    level: "info",
                    requiredMessage: "Please specify the other land use.",
                    forbiddenMessage:
                      "Only enter a value id you recorded 'Other' land use above.",
                    otherPath: ["leftBankFringeLandUse", UrsLandUseEnum.Other],
                    otherTest: value => isNumber(value) && value > 0,
                  }),
                }),
                textArea({
                  label: "Other Fringe Land Use",
                  secondaryLabel: "Right Bank",
                  path: ["otherRightBankFringeLandUse"],
                  rows: 5,
                  customRules: requiredIff({
                    level: "info",
                    requiredMessage: "Please specify the other land use.",
                    forbiddenMessage:
                      "Only enter a value id you recorded 'Other' land use above.",
                    otherPath: ["rightBankFringeLandUse", UrsLandUseEnum.Other],
                    otherTest: value => isNumber(value) && value > 0,
                  }),
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Recreation, Leisure, and Education",
      path: ["data", "recreationLeisureAndEducation"],
      blocks: [
        section({
          title: "Recreational Facilities",
          path: [],
          help: outdent`
          Record the presence and condition of any of the following
          recreational facilities within the study area.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                ...selectGroup<EcostatusConditionAndUse>({
                  path: ["recreationalFacilities"],
                  fields: enumSelectOptions(
                    EcostatusRecreationalFacilityEnum
                  ).map(({ value, label }) => ({ value, label })),
                  options: enumSelectOptions(EcostatusConditionAndUseEnum),
                  required: required("info"),
                  defaultValue: EcostatusConditionAndUseEnum.NotPresent,
                }),
                textArea({
                  label: "Other Types of Recreational Facility",
                  fullWidth: true,
                  path: ["recreationalFacilitiesNotes"],
                  rows: 8,
                  customRules: requiredIff({
                    level: "info",
                    requiredMessage: "You should specify a value.",
                    forbiddenMessage:
                      "Only enter a value if you recorded an 'Other' facility above.",
                    otherPath: [
                      "recreationalFacilities",
                      EcostatusRecreationalFacilityEnum.Other,
                    ],
                    otherTest: value =>
                      EcostatusConditionAndUseEnum.isValue(value) &&
                      value !== EcostatusConditionAndUseEnum.NotPresent,
                  }),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Interpretation Boards",
          path: [],
          help: outdent`
          Record qualitatively the presence, quality, and condition of any interpretation boards.
          `,
          blocks: [
            select({
              label: "Interpretation Board Presence",
              path: ["interpretationBoardPresence"],
              options: enumSelectOptions(UrsApeEnum),
              defaultValue: UrsApeEnum.Absent,
              required: required("info"),
            }),
            select({
              label: "Interpretation Board Information Quality",
              path: ["interpretationBoardQuality"],
              options: enumSelectOptions(EcostatusPoorModerateGoodEnum),
              required: required("info"),
            }),
            select({
              label: "Interpretation Board Condition",
              path: ["interpretationBoardCondition"],
              options: enumSelectOptions(EcostatusPoorModerateGoodEnum),
              required: required("info"),
            }),
          ],
        }),
        section({
          title: "Educational Facilities (e.g. Centres, Classrooms)",
          path: [],
          help: outdent`
          Record qualitatively the presence and condition of formal educational facilities.
          `,
          blocks: [
            select({
              label: "Educational Facility Presence",
              path: ["educationalFacilityPresence"],
              options: enumSelectOptions(EcostatusAbsentMinorMajorEnum),
              defaultValue: EcostatusAbsentMinorMajorEnum.Absent,
              required: required("info"),
            }),
            select({
              label: "Educational Facility Condition",
              path: ["educationalFacilityCondition"],
              options: enumSelectOptions(EcostatusPoorModerateGoodEnum),
              customRules: requiredIff({
                level: "info",
                requiredMessage: "Please choose a value.",
                forbiddenMessage:
                  "Only choose a value if you recorded an Educational Facility.",
                otherPath: ["educationalFacilityPresence"],
                otherTest: value =>
                  EcostatusAbsentMinorMajorEnum.isValue(value) &&
                  value !== EcostatusAbsentMinorMajorEnum.Absent,
              }),
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Human Connectivity",
      path: ["data", "humanConnectivity"],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Study Area Ownership",
                  path: ["ownership"],
                  options: enumSelectOptions(EcostatusStudyAreaOwnershipEnum),
                  required: required("info"),
                }),
                checkbox({
                  label: "Study Area Access",
                  path: ["accessAllowed"],
                  checkboxLabel: "Is access to the study area allowed?",
                  defaultValue: true,
                }),
                select({
                  label: "Getting Into the Study Area",
                  path: ["accessRestriction"],
                  help: outdent`
                  Provide an overall assessment of provision
                  for access into the study area for *all potential users*.
                  `,
                  options: enumSelectOptions(
                    EcostatusStudyAreaAccessRestrictionEnum
                  ),
                  required: required("info"),
                }),
                select({
                  label: "Sign Posts To/Through the Study Area",
                  path: ["signPosts"],
                  help: outdent`
                  Provide an overall assessment of the provision of
                  signage to the study area and also through it:
                  `,
                  options: enumSelectOptions(UrsApeEnum),
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Provision of Access for all Potential Users",
          path: [],
          help: outdent`
          Provide information on getting to/into the study area
          for users adopting the following access methods.
          `,
          blocks: columns2(
            ...selectGroup<EcostatusNoneOneMore>({
              path: ["accessProvision"],
              fields: enumSelectOptions(
                EcostatusStudyAreaAccessProvisionEnum
              ).map(({ value, label }) => ({ value, label })),
              options: enumSelectOptions(EcostatusNoneOneMoreEnum),
              defaultValue: EcostatusNoneOneMoreEnum.None,
              required: required("info"),
            }),
            select({
              label: "Rail Access (Overground/Underground)",
              path: ["railAccess"],
              options: enumSelectOptions(EcostatusRailAccessEnum),
              required: required("info"),
              help: outdent`
                Indicate the proximity of the nearest overground/underground rail access.
                `,
            })
          ),
        }),
        section({
          title: "Bridges",
          path: [],
          help: outdent`
          Record the number of bridges of each type crossing the river(s) within the study area.

          Note: Bridge crossing measurements are recorded separately on the *Artificial influences* page.
          `,
          blocks: [
            integerField({
              label: "Footbridges",
              path: ["footBridges"],
              defaultValue: 0,
              required: required("info"),
              bounds: minValue(0),
            }),
            integerField({
              label: "Road Bridges",
              path: ["roadBridges"],
              defaultValue: 0,
              required: required("info"),
              bounds: minValue(0),
            }),
            integerField({
              label: "Railway Bridges",
              path: ["railBridges"],
              defaultValue: 0,
              required: required("info"),
              bounds: minValue(0),
            }),
          ],
        }),
        section({
          title: "Access/Connectivity Within and Through the Study Area",
          path: [],
          help: outdent`
          Types of access/connectivity within and through the study area are recorded below.
          Within-site connectivity is assessed separately for the left and right banks,
          whereas through-site links are assessed for the entire study area.
          `,
          blocks: columns3(
            ...selectGroup<UrsApe>({
              path: ["internalConnectivity"],
              fields: [
                EcostatusConnectivityEnum.FootpathsLeftBank,
                EcostatusConnectivityEnum.FootpathsRightBank,
                EcostatusConnectivityEnum.FootpathsThroughArea,
                EcostatusConnectivityEnum.CyclePathsLeftBank,
                EcostatusConnectivityEnum.CyclePathsRightBank,
                EcostatusConnectivityEnum.CyclePathsThroughArea,
                EcostatusConnectivityEnum.WheelChairAccessLeftBank,
                EcostatusConnectivityEnum.WheelChairAccessRightBank,
                EcostatusConnectivityEnum.WheelChairAccessThroughArea,
                EcostatusConnectivityEnum.OtherPathsLeftBank,
                EcostatusConnectivityEnum.OtherPathsRightBank,
                EcostatusConnectivityEnum.OtherPathsThroughArea,
              ]
                .map(EcostatusConnectivityEnum.entryOf)
                .map(({ value, label }) => {
                  const [a, b] = label.split(":").map(str => str.trim());
                  return {
                    value,
                    label: a,
                    secondaryLabel: b,
                  };
                }),
              options: enumSelectOptions(UrsApeEnum),
              defaultValue: UrsApeEnum.Absent,
              required: required("info"),
            }),
            textArea({
              label: "Description of Other Connectivity",
              path: ["otherConnectivityNotes"],
              fullWidth: true,
              rows: 5,
            })
          ),
        }),
        section({
          title: "Barriers to Access",
          path: [],
          blocks: columns3(
            ...selectGroup<UrsApe>({
              path: ["internalConnectivity"],
              fields: [
                EcostatusConnectivityEnum.BarriersToAccessLeftBank,
                EcostatusConnectivityEnum.BarriersToAccessRightBank,
                EcostatusConnectivityEnum.BarriersToAccessThroughArea,
              ]
                .map(EcostatusConnectivityEnum.entryOf)
                .map(({ value, label }) => {
                  const [a, b] = label.split(":").map(str => str.trim());
                  return {
                    value,
                    label: a,
                    secondaryLabel: b,
                  };
                }),
              options: enumSelectOptions(UrsApeEnum),
              defaultValue: UrsApeEnum.Absent,
              required: required("info"),
            })
          ),
        }),
        section({
          title: "Visibility",
          path: [],
          blocks: columns2(
            ...selectGroup<UrsApe>({
              path: ["internalConnectivity"],
              fields: [
                EcostatusConnectivityEnum.RiverVisibilityLeftBank,
                EcostatusConnectivityEnum.RiverVisibilityRightBank,
                EcostatusConnectivityEnum.UrbanVisibilityLeftBank,
                EcostatusConnectivityEnum.UrbanVisibilityRightBank,
              ]
                .map(EcostatusConnectivityEnum.entryOf)
                .map(({ value, label }) => {
                  const [a, b] = label.split(":").map(str => str.trim());
                  return {
                    value,
                    label: a,
                    secondaryLabel: b,
                  };
                }),
              options: enumSelectOptions(UrsApeEnum),
              defaultValue: UrsApeEnum.Absent,
              required: required("info"),
            })
          ),
        }),
        section({
          title: "River Viewing Platforms",
          path: [],
          blocks: [
            select({
              label: null,
              path: ["riverViewingPlatforms"],
              options: enumSelectOptions(EcostatusNoneOneMoreEnum),
              defaultValue: EcostatusNoneOneMoreEnum.None,
              required: required("info"),
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Health and Safety",
      path: ["data", "healthAndSafety"],
      blocks: [
        section({
          title: "Pollution",
          path: ["pollution"],
          help: outdent`
          Air pollution is assessed qualitatively across the study area
          to indicate the level of airborne odours, particles, and dusts
          emanating from adjacent areas.

          Noise pollution is assessed qualitatively across the study area
          to indicate the level of background noise from adjacent land use.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Air Pollution",
                  path: ["airPollution"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Noise Pollution",
                  path: ["noisePollution"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Signs of Water Pollution",
                  path: ["waterPollution"],
                  help: "e.g. low water clarity, oils, odours, litter, etc",
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Litter",
          path: ["litter"],
          help: outdent`
          Litter is recorded qualitatively as
          *absent*, *present*, or *extensive/numerous*.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Large Litter",
                  path: ["largeLitter"],
                  help: "e.g. shopping trolleys",
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Small Litter",
                  path: ["smallLitter"],
                  help: "e.g. cans, bottles, paper",
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Dog Waste",
                  path: ["dogWaste"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Litter Disposal",
          path: ["litterDisposal"],
          help: outdent`
          Litter and waste disposal is recorded qualitatively as
          *absent*, *present*, or *extensive/numerous*.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Litter Bins",
                  path: ["litterBins"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Dog Waste Bins",
                  path: ["dogWasteBins"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Personal Safety",
          path: ["personalSafety"],
          help: outdent`
          Indicators of personal safety are recorded as
          *absent*, *present*, or *extensive/plentiful/good/very accessible*.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "CCTV",
                  path: ["cctv"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Emergency Float Aids",
                  path: ["emergencyFloatAids"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Social Issues",
                  path: ["socialIssues"],
                  help: "e.g. alcohol/drug abuse, sexual activity, vandalism, rough sleeping",
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Main Footpath Lighting",
                  path: ["mainFootpathLighting"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Main Footpath Visibility",
                  path: ["mainFootpathVisibility"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
                select({
                  label: "Emergency Contact Numbers",
                  path: ["emergencyContactNumbers"],
                  options: enumSelectOptions(UrsApeEnum),
                  defaultValue: UrsApeEnum.Absent,
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Hygiene",
          path: ["hygiene"],
          help: outdent`
          Indicators of personal safety are recorded as
          *absent*, *present*, or *extensive/plentiful/good/very accessible*.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Toilets with Hand Wash Facilities",
                  path: ["toiletsWithHandWashFacilities"],
                  options: enumSelectOptions(EcostatusNoneOneMoreEnum),
                  defaultValue: EcostatusNoneOneMoreEnum.None,
                  required: required("info"),
                }),
                select({
                  label: "Drinking Fountains / Taps",
                  path: ["drinkingFountainsOrTaps"],
                  options: enumSelectOptions(EcostatusNoneOneMoreEnum),
                  defaultValue: EcostatusNoneOneMoreEnum.None,
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Hazards",
          path: [],
          blocks: [
            checkbox({
              label: "Hazards present?",
              path: ["hazardsPresent"],
              checkboxLabel: "Are there hazards present?",
            }),
            textArea({
              label: "Hazard Types and Descriptions",
              path: ["hazardNotes"],
              fullWidth: true,
              rows: 8,
              customRules: requiredIff({
                level: "info",
                requiredMessage: "Please enter a description.",
                forbiddenMessage:
                  "Only enter a description if hazards are present.",
                otherPath: ["hazardsPresent"],
                otherTest: value => !!value,
              }),
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Economic and Social Value",
      path: ["data", "economicAndSocialValue"],
      blocks: [
        section({
          title: "Properties",
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Properties Benefitting from Views",
                  path: ["propertiesBenefittingFromViews"],
                  options: enumSelectOptions(EcostatusNoneSomeManyEnum),
                  defaultValue: EcostatusNoneSomeManyEnum.None,
                  required: required("info"),
                  help: outdent`
                Do residential or commercial properties within the study area or fringe
                face on to the river (directly or across the study area)?
                `,
                }),
                select({
                  label: "Properties Designed to Benefit From Views",
                  path: ["propertiesDesignedToBenefitFromViews"],
                  options: enumSelectOptions(EcostatusNoneSomeManyEnum),
                  defaultValue: EcostatusNoneSomeManyEnum.None,
                  required: required("info"),
                  help: outdent`
                Have properties within the study area or fringe been designed and constructed to enjoy the views into / across the study area?
                `,
                }),
                select({
                  label: "Properties Benefitting from Vegetation",
                  path: ["propertiesBenefittingFromVegetation"],
                  options: enumSelectOptions(EcostatusNoneSomeManyEnum),
                  defaultValue: EcostatusNoneSomeManyEnum.None,
                  required: required("info"),
                  help: outdent`
                Do properties benefit in terms of screening from vegetation within the Study Area?
                `,
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Community Involvement",
          path: [],
          blocks: [
            select({
              label: "Evidence of Local Community Involvement",
              path: ["evidenceOfPublicInvolvement"],
              options: enumSelectOptions(EcostatusNoneSomeManyEnum),
              defaultValue: EcostatusNoneSomeManyEnum.None,
              required: required("info"),
              help: outdent`
              Is there evidence of loval community involvement in the study area
              (e.g. posters detailing events, on-going organised activities, park use groups)?
              `,
            }),
            textArea({
              label: "Comments",
              path: ["evidenceOfPublicInvolvementNotes"],
              help: "Please provide details of the local community involvement.",
              fullWidth: true,
              rows: 8,
              customRules: requiredIff({
                level: "info",
                requiredMessage: "Please provide details.",
                forbiddenMessage:
                  "Only enter a value if there is evidence of community involvement.",
                otherPath: ["evidenceOfPublicInvolvement"],
                otherTest: value =>
                  EcostatusNoneSomeManyEnum.isValue(value) &&
                  value !== EcostatusNoneSomeManyEnum.None,
              }),
            }),
          ],
        }),
        section({
          title: "Businesses",
          path: [],
          blocks: [
            select({
              label: "Businesses Depending on the Study Area",
              path: ["dependentBusinesses"],
              options: enumSelectOptions(EcostatusNoneOneMoreEnum),
              defaultValue: EcostatusNoneSomeManyEnum.None,
              required: required("info"),
              help: outdent`
              Number of businesses that depend on the study area
              (e.g. cafes, boat hire).
              `,
            }),
            textArea({
              label: "Types of Business Present",
              path: ["dependentBusinessNotes"],
              fullWidth: true,
              rows: 8,
              help: outdent`
              List the types of business within the study area or fringe
              that depend on the study area.
              `,
              customRules: requiredIff({
                level: "info",
                requiredMessage: "Please comment on types of business present.",
                forbiddenMessage:
                  "Only enter a value if businesses are present.",
                otherPath: ["dependentBusinesses"],
                otherTest: value =>
                  EcostatusNoneOneMoreEnum.isValue(value) &&
                  value !== EcostatusNoneOneMoreEnum.None,
              }),
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Heritage and Landscape Features",
      path: [],
      blocks: [
        studyAreaMapSection(["data", "siteInformation", "siteMap"]),
        heritageFeaturesSection({
          title: "Buildings and Structures",
          path: ["data", "heritageFeatures", "buildingsAndStructures"],
          help: outdent`
          Record any buildings or structures of cultural, symbolic historical, or landscape importance.
          `,
        }),
        heritageFeaturesSection({
          title: "Other Heritage and Landscape Features",
          path: ["data", "heritageFeatures", "otherHeritageFeatures"],
          help: outdent`
          Record any buildings or structures of cultural, symbolic historical, or landscape importance.
          `,
        }),
      ],
    }),
    page({
      title: "Natural Flood Management",
      path: ["data", "nfm"],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            repeat({
              path: ["opportunities"],
              block: grid({
                columns: 2,
                blocks: [
                  textField({
                    label: "Reference Number",
                    path: ["number"],
                    fullWidth: true,
                  }),
                  select({
                    label: "Category",
                    path: ["category"],
                    options: enumSelectOptions(EcostatusNfmCategoryEnum),
                    required: required("info"),
                  }),
                  select({
                    label: "Position",
                    path: ["position"],
                    options: enumSelectOptions(EcostatusNfmPositionEnum),
                    required: required("info"),
                  }),
                  pointField({
                    label: "Location",
                    path: ["location"],
                    fullWidth: true,
                    required: required("info"),
                  }),
                  attachmentField({
                    label: "Photographs",
                    path: ["photographs"],
                    fullWidth: true,
                    maxFiles: 2,
                  }),
                  textArea({
                    label: "Comments",
                    path: ["comments"],
                    fullWidth: true,
                    rows: 5,
                  }),
                ],
              }),
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Ecological Characteristics",
      path: ["data", "ecologicalCharacteristics"],
      blocks: [
        protectedSpeciesSection({
          title: "European Protected Species",
          species: enumSelectOptions(
            [
              UrsProtectedSpeciesEnum.Bat,
              UrsProtectedSpeciesEnum.GreatCrestedNewt,
              UrsProtectedSpeciesEnum.Otter,
              UrsProtectedSpeciesEnum.Dormouse,
              UrsProtectedSpeciesEnum.SandLizard,
              UrsProtectedSpeciesEnum.NatterjackToad,
              UrsProtectedSpeciesEnum.SmoothSnake,
              UrsProtectedSpeciesEnum.LesserWhirlpoolRamshornSnail,
            ].map(value => ({
              value,
              label: UrsProtectedSpeciesEnum.labelOf(value),
            }))
          ),
        }),
        protectedSpeciesSection({
          title: "Nationally Protected Species",
          species: enumSelectOptions(
            [
              UrsProtectedSpeciesEnum.Adder,
              UrsProtectedSpeciesEnum.Badger,
              UrsProtectedSpeciesEnum.WaterVole,
              UrsProtectedSpeciesEnum.WhiteClawedCrayfish,
            ].map(value => ({
              value,
              label: UrsProtectedSpeciesEnum.labelOf(value),
            }))
          ),
        }),
        section({
          title: "Other Observed Species",
          path: [],
          help: outdent`
          Any further information about observed species within the survey stretch.
          `,
          blocks: [
            textArea({
              label: null,
              path: ["otherSpecies"],
              rows: 8,
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Additional Notes",
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "additionalNotes"],
              rows: 10,
            }),
          ],
        }),
      ],
    }),
    page({
      title: "Habitat and Biodiversity",
      path: [],
      help: outdent`
      Based upon impressions gained from the river surveys and from walking the study area, provide a single high-level assessment of habitat and potential biodiversity - the Study Area *Habitat Quality Assessment score (HaQA)*.

      The impression-based HaQA is assigned a score of 1 (Good) to 5 (Poor) supported by a level of confidence (High, Medium, Low) as follows:

      - HaQA = 1 - The habitat assemblage of the study area and contained river sub-reach(es) is as naturally-functioning and complex as is realistically achievable within the natural environment constraints at this location and is in very good condition (no pollution indicators, nuisance species or signs of channel modification / reinforcement).
      - HaQA = 2 - Although the habitat assemblage of the study area and contained river sub-reach(es) is largely naturally-functioning and complex within the natural environment constraints at this location, it is degraded to some extent. However, modest interventions (e.g. litter removal, minor reinforcement removal, management of early stages of non-native plant invasion) would achieve important improvements.
      - HaQA = 3 - The habitat assemblage of the study area and contained river sub-reach(es)has some natural function and is moderately complex within the natural environment constraints at this location but the habitat structure and naturalness are constrained by engineering interventions, particularly widespread patches of bank reinforcement, and / or, the presence of extensive gross pollution (oil drums, car parts, shopping trolleys etc.). Significant improvements could be achieved at moderate cost.
      - HaQA = 4 - The habitat assemblage of the study area and contained river sub-reach(es) has low complexity and its naturalness is heavily constrained by significant engineering interventions, particularly extensive (> 50%) bank length reinforcement of the lower or entire bank face. Nevertheless, significant improvements could be achieved by removal or redesign of engineering interventions, in some cases coupled with other interventions (planting, invasive species control, litter removal, pollution control). Although relatively expensive, such interventions would yield major benefits.
      - HaQA = 5 - The habitat assemblage of the study area and contained river sub-reach(es) has low complexity and function as a result of severe engineering intervention (e.g. full or both banks completely reinforcement) and/or extreme pollution. Either the site constraints prevent improvements or the costs would be extremely high with the opportunities of only small improvements.

      *Complexity, Connectivity and Condition* assessment scores (1-Good to 5-Poor) can be used to support the HaQA assessment. These should focus on the entire study area including the river within the context of the visual envelope of the fringe:

      - Complexity assessment reflects the variety / diversity in the habitat assemblage present.
      - Connectivity assessment reflects the way that the habitats are arranged to allow species to flow from one to another, in particular between the river, riparian zone and across the study area.
      - Condition assessment reflects the degree to which the habitat assemblage displays and supports natural function, shows minimal degradation and, where management is apparent, it is sympathetic to the maintenance of natural functioning.
      `,
      blocks: [
        hlaScoresSection({
          title: "Habitat and Biodiversity Assessment (HaQA) Scores",
          path: ["data", "habitatAndBiodiversity"],
          scores: [
            {
              label: "SHQA",
              assessmentPath: ["overallAssessment"],
              confidencePath: ["overallConfidence"],
            },
            {
              label: "Complexity",
              assessmentPath: ["complexityAssessment"],
              confidencePath: ["complexityConfidence"],
            },
            {
              label: "Connectivity",
              assessmentPath: ["connectivityAssessment"],
              confidencePath: ["connectivityConfidence"],
            },
            {
              label: "Condition",
              assessmentPath: ["conditionAssessment"],
              confidencePath: ["conditionConfidence"],
            },
          ],
        }),
        studyAreaMapSection(["data", "siteInformation", "siteMap"]),
        hlaKeyPointsSection({
          title: "Habitat and Biodiversity Key Points",
          path: ["data", "habitatAndBiodiversity", "keyPoints"],
          help: outdent`
          Key Points should:

          1. support the HaQA assessment;
          2. identify the positive and negative aspects of the study area's Habitat / potential Biodiversity;
          3. identify important features (that should, for example be incorporated into the project design at the pre-project stage);
          4. identify specific measures (particularly during the pre-project survey) that could enhance the study area's Habitat / potential Biodiversity;
          5. make use of information drawn from the river assessments in addition to observations from the broader study area. It could also be useful to list the RCA outcomes in a key point.
          `,
        }),
      ],
    }),
    page({
      title: "Landscape",
      path: [],
      help: outdent`
      Landscape is 'the appearance of the land' and so Landscape assessment is concerned with the assemblage of land cover types from semi-natural to entirely human-constructed. Study area and river survey data that can support the assessment include the RCAs for the river sub-reaches incorporated within the study area; observations relating to physical and visual connectivity and land cover of the study area and its fringes. To avoid double-counting, man-made features should only be included in the assessments to the extent that they form important components of the landscape and not because of their heritage value.

      *Landscape Quality Assessment scores (LQA)* range from 1 to 5 (good, fairly good, moderate, fairly poor, poor) and provide an integrated impression of the quality of the study area at the time of survey based upon the component scores for Complexity, Coherence, Interest and Condition and the *Key Points* (to be accompanied by a level of surveyor confidence: High-H, Medium - M, Low - L):

      - *Complexity* assessment reflects the variety / diversity in the land cover assemblage of natural and man-made features.
      - *Coherence* assessment reflects the way that the landscape elements are arranged - do they complement one another, flow from one to another, integrate into a coherent whole?
      - *Interest* assessment reflects the degree to which the landscape assemblage is stimulating and interesting.
      - *Condition* assessment reflects the level of sympathetic maintenance of the landscape and its elements.

      *Complexity, Coherence and Interest assessment scores* (1 to 5) should focus on the landscape of **the study area within the context of the visual envelope of the fringe**, should be supported by the Key Points and accompanied by a level of confidence: High-H, Medium - M, Low - L):

      1. Good: The landscape elements and assemblage of the study area within the context of the visual envelope of the fringe offer as much **complexity**, **coherence** or **interest** as is realistically possible at this location.
      2. Fairly good: The landscape elements and assemblage of the study area within the context of the visual envelope of the fringe offer high **complexity**, **coherence** or **interest** (potential for modest improvements should be apparent from the Key Points).
      3. Average / moderate: The landscape elements and assemblage of the study area within the context of the visual envelope of the fringe offer moderate **complexity**, **coherence** or **interest** (potential for improvements should be apparent from the Key Points).
      4. Fairly poor: The landscape elements and assemblage of the study area within the context of the visual envelope of the fringe offer limited **complexity**, **coherence** or **interest** (the nature / potential / difficulty of potential improvements should be apparent from the Key Points).
      5. Poor: The landscape elements and assemblage of the study area within the context of the visual envelope of the fringe offer negligible **complexity**, **coherence** or **interest** (the nature / potential / difficulty of potential improvements should be apparent from the Key Points).

      *Condition assessment scores* (1 to 5) should focus on **the study area**:

      1. Good condition in the context of the character of the landscape under study;
      2. Fairly good condition in the context of the character of the landscape under study;
      3. Average condition in the context of the character of the landscape under study;
      4. Fairly poor condition in the context of the character of the landscape under study;
      5. Poor condition in the context of the character of the landscape under study.
      `,
      blocks: [
        hlaScoresSection({
          title: "Landscape Assessment (LQA) Scores",
          path: ["data", "landscape"],
          scores: [
            {
              label: "LQA",
              assessmentPath: ["overallAssessment"],
              confidencePath: ["overallConfidence"],
            },
            {
              label: "Complexity",
              assessmentPath: ["complexityAssessment"],
              confidencePath: ["complexityConfidence"],
            },
            {
              label: "Coherence",
              assessmentPath: ["coherenceAssessment"],
              confidencePath: ["coherenceConfidence"],
            },
            {
              label: "Interest",
              assessmentPath: ["interestAssessment"],
              confidencePath: ["interestConfidence"],
            },
            {
              label: "Condition",
              assessmentPath: ["conditionAssessment"],
              confidencePath: ["conditionConfidence"],
            },
          ],
        }),
        studyAreaMapSection(["data", "siteInformation", "siteMap"]),
        hlaKeyPointsSection({
          title: "Landscape Key Points",
          path: ["data", "landscape", "keyPoints"],
          help: outdent`
          Key Points should:

          1. support the LQA, Complexity, Coherence, Interest and Condition Assessments;
          2. identify the positive and negative aspects of the study area's landscape and important landscape features (that should, for example be incorporated into the project design at the pre-project stage);
          3. identify specific measures that could enhance the study area's landscape (particularly during the pre-project survey).
          `,
        }),
      ],
    }),
    page({
      title: "Heritage",
      path: [],
      help: outdent`
      The **Heritage Assessment** is also heavily based on the impressions and study area-specific observations (and Key Points)
      made by the surveyor but formal data gathering is also a crucial aspect of this assessment.
      Heritage relates to the historic environment of the study area.
      This includes designated structures but, importantly,
      any other (potentially) historic features that are observed during the survey.
      Study area data collected in support of the heritage assessment include:
      (i) a record of buildings or structures of cultural, symbolic or historical importance within the study area or fringe;
      (ii) a record of other heritage features within the study area or fringe.

      **Heritage Quality Assessment scores (HeQA)** range from 1 to 5 (good to poor)
      and provide an integrated impression of the heritage quality of the study area (and, where relevant, related features in the fringe)
      based upon the component scores for Complexity, Coherence, Interest and Condition;
      the Key Points; and data collected on individual heritage features during the study area survey
      (to be accompanied by a level of surveyor confidence: High-H, Medium - M, Low - L).

      - *Complexity* assessment reflects the variety / diversity of heritage features within the study area or fringe.
      - *Coherence* assessment reflects the way that the heritage features are arranged - do they complement or relate to one another, integrate into a coherent whole that is more than the sum of the individual elements?
      - *Interest* assessment reflects the degree to which the heritage is stimulating and interesting.
      - *Condition* assessment reflects condition relative to what might be expected given the age of the features and the nature of the environment in which they are located.

      *Complexity assessment scores* (1 to 5) focus on heritage features *within the study area*:

      1. Good: The study area offers an extremely complex suite of heritage features (these should be apparent from the Key Points)
      2. Fairly good: The study area offers a complex suite of heritage features (these should be apparent from the Key Points).
      3. Average / moderate: The study area offers a moderately complex suite of heritage features (these should be apparent from the Key Points).
      4. Fairly poor: The study area offers low complexity in relation to heritage features (any significant heritage features should be apparent from the Key Points).
      5. Poor: The study area offers negligible heritage features.

      *Coherence and Interest assessment scores* (1 to 5) could incorporate complementary features within the fringe as well as the study area:

      1. Good: The study area offers very high coherence or interest relating to heritage features with little opportunity for improvement.
      2. Fairly good: The study area offers high coherence or interest relating to heritage features (potential for modest improvements should be apparent from the Key Points).
      3. Average / moderate: The study area offers moderate coherence or interest relating to heritage features (opportunities for improvement should be apparent from the Key Points).
      4. Fairly poor: The study area offers limited coherence or interest relating to heritage features (any opportunities for improvement should be apparent from the Key Points).
      5. Poor: The study area offers negligible coherence or interest relating to heritage features (reasons for low score and any opportunities for improvement should be apparent from the Key Points).

      *Condition assessment scores* (1 to 5) should be interpreted in relation to what might be expected for the age of the historic features and the environment in which they sit:

      1. The heritage features are typically in good condition;
      2. The heritage features are typically in fairly good condition;
      3. The heritage features are typically in average or variable condition;
      4. The heritage features are typically in fairly poor condition;
      5. The heritage features are typically in poor condition.
      `,
      blocks: [
        hlaScoresSection({
          title: "Heritage Assessment (HeQA) Scores",
          path: ["data", "heritage"],
          scores: [
            {
              label: "HQA",
              assessmentPath: ["overallAssessment"],
              confidencePath: ["overallConfidence"],
            },
            {
              label: "Complexity",
              assessmentPath: ["complexityAssessment"],
              confidencePath: ["complexityConfidence"],
            },
            {
              label: "Coherence",
              assessmentPath: ["coherenceAssessment"],
              confidencePath: ["coherenceConfidence"],
            },
            {
              label: "Interest",
              assessmentPath: ["interestAssessment"],
              confidencePath: ["interestConfidence"],
            },
            {
              label: "Condition",
              assessmentPath: ["conditionAssessment"],
              confidencePath: ["conditionConfidence"],
            },
          ],
        }),
        studyAreaMapSection(["data", "siteInformation", "siteMap"]),
        hlaKeyPointsSection({
          title: "Heritage Key Points",
          path: ["data", "heritage", "keyPoints"],
          help: outdent`
          Key Points should:

          1. support the HeQA, Complexity, Coherence, Interest and Condition Assessments;
          2. identify the positive and negative aspects of the study area's heritage features in terms of their quality and condition;
          3. identify important heritage features that could be enhanced and connected to one another (including connection to features in the fringe), and / or (in a pre-project survey) should be incorporated into the project design;
          4. identify specific measures that could enhance the study area's heritage appeal and condition.
          `,
        }),
      ],
    }),
    page({
      title: "Amenity",
      path: [],
      help: outdent`
      Amenity relates to the convenience, comfort and pleasure provided by the study area for humans and relates to both informal and formal human activities. A significant body of field measurements from the study area survey supports the assessment of amenity. The measurements relate to accessibility and connectivity, recreation, education, health and safety, and economic value.

      *Amenity Quality Assessment scores (AQA)* range from 1 to 5 (good to poor) and provide an integrated impression of the quality of amenity provision within study area based upon the component scores for Complexity, Coherence, Interest and Condition; the *Key Points*; and the field measurements collected (accessibility and connectivity, recreation, education, health and safety, and economic value). Assessment is accompanied by a level of confidence: High-H, Medium - M, Low - L):

      - *Complexity* assessment reflects the variety / diversity of formal and informal activities that are supported by the study area.
      - *Coherence* assessment reflects the way that the activities are arranged - are related activities arranged together or do they conflict with one another; where relevant, do activities on the study area relate to activities beyond the study area; do the activities integrate into a coherent whole that is more than the sum of the individual elements; is the study area sufficiently connected both internally and externally to support the activities that are available and to make them accessible?
      - *Interest* assessment reflects the degree to which the amenities provide a stimulating and interesting environment in which to spend time.
      - *Condition* assessment reflects the state of repair as well as the general condition of the amenity facilities in the study area.

      *Complexity, Coherence and Interest assessment scores* (1 to 5) use following score definitions:

      1. Good: The study area offers as much **complexity**, **coherence** or **interest** in amenity provision as is realistically possible at this location.
      2. Fairly good: The study area offers high **complexity**, **coherence** or **interest** in amenity provision (potential for modest improvements should be apparent from the *Key Points*).
      3. Average / moderate: The study area offers moderate **complexity**, **coherence** or **interest** in amenity provision (opportunities for improvement should be apparent from the *Key Points*).
      4. Fairly poor: The study area offers low **complexity**, **coherence** or **interest** in amenity (opportunities / limitations in relation to improvement should be apparent from the *Key Points*).
      5. Poor: The study area offers negligible **complexity**, **coherence** or **interest** in amenity provision (opportunities / limitations in relation to improvement should be apparent from the *Key Points*).

      *Condition assessment scores* (1 to 5):

      1. The facilities available for supporting informal and formal human activities in the study area are typically in good condition;
      2. The facilities available for supporting informal and formal human activities in the study area are typically in fairly good condition (but some may need attention);
      3. The facilities available for supporting informal and formal human activities in the study area are typically in average or variable condition (but would benefit from some attention);
      4. The facilities available for supporting informal and formal human activities in the study area are typically in fairly poor condition (they are in need of significant attention);
      5. The facilities available for supporting informal and formal human activities in the study area are typically in poor condition (possibly including significant vandalism).
      `,
      blocks: [
        hlaScoresSection({
          title: "Amenity Assessment (AQA) Scores",
          path: ["data", "amenity"],
          scores: [
            {
              label: "LQA",
              assessmentPath: ["overallAssessment"],
              confidencePath: ["overallConfidence"],
            },
            {
              label: "Complexity",
              assessmentPath: ["complexityAssessment"],
              confidencePath: ["complexityConfidence"],
            },
            {
              label: "Coherence",
              assessmentPath: ["coherenceAssessment"],
              confidencePath: ["coherenceConfidence"],
            },
            {
              label: "Interest",
              assessmentPath: ["interestAssessment"],
              confidencePath: ["interestConfidence"],
            },
            {
              label: "Condition",
              assessmentPath: ["conditionAssessment"],
              confidencePath: ["conditionConfidence"],
            },
          ],
        }),
        studyAreaMapSection(["data", "siteInformation", "siteMap"]),
        hlaKeyPointsSection({
          title: "Amenity Key Points",
          path: ["data", "amenity", "keyPoints"],
          help: outdent`
          Key Points should:

          1. support the AQA, Complexity, Coherence, Interest and Condition Assessments
          2. identify the positive and negative aspects of the study area's amenity assemblage in terms of quality, condition, safety;
          3. identify important opportunities for adding, enhancing and connecting amenity features that could be incorporated into the project design (in a pre-project survey); survey)
          4. identify specific measures that could enhance the study area's amenity appeal, condition and accessibility (also in a pre-project survey).
          `,
        }),
      ],
    }),
  ],
});
