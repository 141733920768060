import {
  ApiParams,
  Member,
  ProjectRef,
  QualificationRef,
  SearchResults,
  TeamRef,
  WorkspaceRef,
  isMember,
  isSearchResults,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { PartialParams } from "../params";
import {
  GlobalMemberSearchOptions,
  ProjectMemberSearchOptions,
  TeamMemberSearchOptions,
  WorkspaceMemberSearchOptions,
} from "./common";
import { IO } from "@cartographerio/io";
import { contentAs } from "../../response";
import { UrlParts } from "../../url";

const basePath = "/member/v1";

export function globalSearchUrl(
  options: PartialParams<GlobalMemberSearchOptions>
): UrlParts {
  return { path: `${basePath}/global`, query: options };
}

export function globalSearch(
  apiParams: ApiParams,
  options: PartialParams<GlobalMemberSearchOptions>
): IO<SearchResults<Member>> {
  return fetch
    .get({
      apiParams,
      url: globalSearchUrl(options),
    })
    .flatMap(contentAs("SearchResults<Member>", isSearchResults(isMember)));
}

export function workspaceSearchUrl(
  workspace: WorkspaceRef,
  options: PartialParams<WorkspaceMemberSearchOptions>
): UrlParts {
  return {
    path: `${basePath}/workspace/${workspace}`,
    query: options,
  };
}

export function workspaceSearch(
  apiParams: ApiParams,
  workspace: WorkspaceRef,
  options: PartialParams<WorkspaceMemberSearchOptions>
): IO<SearchResults<Member>> {
  return fetch
    .get({
      apiParams,
      url: workspaceSearchUrl(workspace, options),
    })
    .flatMap(contentAs("SearchResults<Member>", isSearchResults(isMember)));
}

export function projectSearchUrl(
  project: ProjectRef,
  workspace: WorkspaceRef | null,
  options: PartialParams<ProjectMemberSearchOptions>
): UrlParts {
  return {
    path: `${basePath}/project/${project}`,
    query: { workspace, ...options },
  };
}

export function projectSearch(
  apiParams: ApiParams,
  project: ProjectRef,
  workspace: WorkspaceRef | null,
  options: PartialParams<ProjectMemberSearchOptions>
): IO<SearchResults<Member>> {
  return fetch
    .get({
      apiParams,
      url: projectSearchUrl(project, workspace, options),
    })
    .flatMap(contentAs("SearchResults<Member>", isSearchResults(isMember)));
}

export function teamSearchUrl(
  team: TeamRef,
  workspace: WorkspaceRef | null,
  options: PartialParams<TeamMemberSearchOptions>
): UrlParts {
  return {
    path: `${basePath}/team/${team}`,
    query: { workspace, ...options },
  };
}

export function teamSearch(
  apiParams: ApiParams,
  team: TeamRef,
  workspace: WorkspaceRef | null,
  options: PartialParams<TeamMemberSearchOptions>
): IO<SearchResults<Member>> {
  return fetch
    .get({
      apiParams,
      url: teamSearchUrl(team, workspace, options),
    })
    .flatMap(contentAs("SearchResults<Member>", isSearchResults(isMember)));
}

export function qualificationSearchUrl(
  qualification: QualificationRef,
  options: PartialParams<TeamMemberSearchOptions>
): UrlParts {
  return {
    path: `${basePath}/qualification/${qualification}`,
    query: options,
  };
}

export function qualificationSearch(
  apiParams: ApiParams,
  qualification: QualificationRef,
  options: PartialParams<TeamMemberSearchOptions>
): IO<SearchResults<Member>> {
  return fetch
    .get({
      apiParams,
      url: qualificationSearchUrl(qualification, options),
    })
    .flatMap(contentAs("SearchResults<Member>", isSearchResults(isMember)));
}
