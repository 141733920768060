import { PermissionCheck } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";

export function arrayifyPermissionCheck(check: PermissionCheck): unknown[] {
  switch (check.type) {
    case "Always":
      return ["Always"];

    case "Never":
      return ["Never", ...(check.reason == null ? [] : [check.reason])];

    case "HasRole":
      return ["HasRole", check.role];

    case "CanGrantRole":
      return ["CanGrantRole", check.role];

    case "CanGrantQualification":
      return ["CanGrantQualification", check.role];

    case "HasQualification":
      return ["HasQualification", check.role];

    case "HasAccount":
      return ["HasAccount"];

    case "HasUserId":
      return ["HasUserId", check.userId];

    case "And":
      return ["And", ...check.args.map(arrayifyPermissionCheck)];

    case "Or":
      return ["Or", ...check.args.map(arrayifyPermissionCheck)];

    case "InAnyWorkspace": {
      return ["InAnyWorkspace", check.name];
    }

    case "Named":
      return ["Named", check.name, arrayifyPermissionCheck(check.check)];

    case "WorkspaceAccess":
      return ["WorkspaceAccess", check.workspaceId, check.minAccess];

    default:
      return checkExhausted(check);
  }
}
