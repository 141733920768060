// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { MapVisibility } from "../types.generated";

const Project: MapVisibility = "Project";
const Public: MapVisibility = "Public";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MapVisibilityEnum = {
  ...createEnum("MapVisibility", [
    [Project, "Project"],
    [Public, "Public"],
  ]),
  Project,
  Public,
};

export default MapVisibilityEnum;
