// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21SlowFlowVegetationType =
  | "Hedgerow"
  | "RiversideWoodland"
  | "FloodplainWoodland"
  | "CrossSlopeWoodland"
  | "CatchmentWoodland";

const Hedgerow: Thames21SlowFlowVegetationType = "Hedgerow";
const RiversideWoodland: Thames21SlowFlowVegetationType = "RiversideWoodland";
const FloodplainWoodland: Thames21SlowFlowVegetationType = "FloodplainWoodland";
const CrossSlopeWoodland: Thames21SlowFlowVegetationType = "CrossSlopeWoodland";
const CatchmentWoodland: Thames21SlowFlowVegetationType = "CatchmentWoodland";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21SlowFlowVegetationTypeEnum = {
  ...createEnum("Thames21SlowFlowVegetationType", [
    [Hedgerow, "Hedgerow"],
    [RiversideWoodland, "Riparian or riverside woodland"],
    [FloodplainWoodland, "Floodplain woodland"],
    [CrossSlopeWoodland, "Cross-slope woodland"],
    [CatchmentWoodland, "Catchment woodland"],
  ]),
  Hedgerow,
  RiversideWoodland,
  FloodplainWoodland,
  CrossSlopeWoodland,
  CatchmentWoodland,
};

export default Thames21SlowFlowVegetationTypeEnum;
