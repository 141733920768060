// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessMammalSpecies = "Otter" | "WaterVole";

const Otter: NeLakeNaturalnessMammalSpecies = "Otter";
const WaterVole: NeLakeNaturalnessMammalSpecies = "WaterVole";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessMammalSpeciesEnum = {
  ...createEnum("NeLakeNaturalnessMammalSpecies", [
    [Otter, "Otter (Lutra lutra)"],
    [WaterVole, "Water vole (Arvicola terrestris)"],
  ]),
  Otter,
  WaterVole,
};

export default NeLakeNaturalnessMammalSpeciesEnum;
