// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type RiverflySpecies =
  | "casedCaddis"
  | "caselessCaddis"
  | "ephemeridae"
  | "ephemerellidae"
  | "heptageniidae"
  | "baetidae"
  | "stoneflies"
  | "gammarus";

const CasedCaddis: RiverflySpecies = "casedCaddis";
const CaselessCaddis: RiverflySpecies = "caselessCaddis";
const Ephemeridae: RiverflySpecies = "ephemeridae";
const Ephemerellidae: RiverflySpecies = "ephemerellidae";
const Heptageniidae: RiverflySpecies = "heptageniidae";
const Baetidae: RiverflySpecies = "baetidae";
const Stoneflies: RiverflySpecies = "stoneflies";
const Gammarus: RiverflySpecies = "gammarus";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RiverflySpeciesEnum = {
  ...createEnum("RiverflySpecies", [
    [CasedCaddis, "Cased Caddis"],
    [CaselessCaddis, "Caseless Caddis"],
    [Ephemeridae, "Mayfly (Ephemeridae)"],
    [Ephemerellidae, "Blue-winged olive (Ephemerellidae)"],
    [Heptageniidae, "Flat-bodied stone clinger (Heptageniidae)"],
    [Baetidae, "Olives (Baetidae)"],
    [Stoneflies, "Stoneflies (Plecoptera)"],
    [Gammarus, "Freshwater Shrimp (Gammarus)"],
  ]),
  CasedCaddis,
  CaselessCaddis,
  Ephemeridae,
  Ephemerellidae,
  Heptageniidae,
  Baetidae,
  Stoneflies,
  Gammarus,
};

export default RiverflySpeciesEnum;
