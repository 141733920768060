import { Option } from "@cartographerio/fp";
import { isRecordOf } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Email,
  QualificationId,
  QualificationRef,
  QualificationRegisterSettings,
  UserId,
  isQualificationId,
  isQualificationRegisterSettings,
} from "@cartographerio/types";
import * as fetch from "../../../../fetch";
import { contentAs, optionalContentAs } from "../../../../response";

const basePath = "/qualification/register/settings/v1";

export function readOrFail(
  apiParams: ApiParams,
  user: UserId | Email,
  qualification: QualificationRef
): IO<QualificationRegisterSettings> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${user}/${qualification}` } })
    .flatMap(
      contentAs(
        "QualificationRegisterSettings",
        isQualificationRegisterSettings
      )
    );
}

export function readOption(
  apiParams: ApiParams,
  user: UserId | Email,
  qualification: QualificationRef
): IO<Option<QualificationRegisterSettings>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${user}/${qualification}` } })
    .flatMap(
      optionalContentAs(
        "QualificationRegisterSettings",
        isQualificationRegisterSettings
      )
    );
}

export function save(
  apiParams: ApiParams,
  user: UserId | Email,
  qualification: QualificationRef,
  settings: QualificationRegisterSettings
): IO<void> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${user}/${qualification}` },
      body: settings,
    })
    .void();
}

export function readAll(
  apiParams: ApiParams,
  user: UserId | Email
): IO<Record<QualificationId, QualificationRegisterSettings>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${user}` } })
    .flatMap(
      contentAs(
        "Record<QualificationId, QualificationRegisterSettings>",
        isRecordOf(isQualificationId, isQualificationRegisterSettings)
      )
    );
}

export function saveAll(
  apiParams: ApiParams,
  user: UserId | Email,
  settings: Record<QualificationId, QualificationRegisterSettings>
): IO<void> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${user}` },
      body: settings,
    })
    .void();
}
