import { Identity } from "../auth";
import { PermissionCheckParams } from "../permission";
import { AnonymisedUser, UserRef, User } from "./types.generated";

export type NonNullableValues<T> = {
  [K in keyof T]-?: NonNullable<T[K]>;
};

export function identityToCheckIdentity(
  identity: Identity
): PermissionCheckParams {
  return {
    id: identity.userId,
    roles: identity.roles,
    qualificationRoles: identity.qualificationRoles,
  };
}

export function userToCheckIdentity(user: User): PermissionCheckParams {
  return {
    id: user.id,
    roles: user.roles,
    qualificationRoles: user.qualificationRoles,
  };
}

export function userToIdentity(user: User): Identity {
  return {
    userId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    screenName: user.screenName,
    email: user.email,
    roles: user.roles,
    qualificationRoles: user.qualificationRoles,
    features: user.features,
  };
}

export function userToUserRef(
  user: AnonymisedUser
): NonNullableValues<UserRef> {
  return { userId: user.id, screenName: user.screenName };
}

export function identityToUserRef(
  identity: Identity
): NonNullableValues<UserRef> {
  return {
    userId: identity.userId,
    screenName: identity.screenName,
  };
}
