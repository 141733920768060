// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsProtectedSpecies =
  | "bat"
  | "greatCrestedNewt"
  | "otter"
  | "dormouse"
  | "sandLizard"
  | "natterjackToad"
  | "smoothSnake"
  | "lesserWhirlpoolRamshornSnail"
  | "adder"
  | "badger"
  | "waterVole"
  | "whiteClawedCrayfish";

const Bat: UrsProtectedSpecies = "bat";
const GreatCrestedNewt: UrsProtectedSpecies = "greatCrestedNewt";
const Otter: UrsProtectedSpecies = "otter";
const Dormouse: UrsProtectedSpecies = "dormouse";
const SandLizard: UrsProtectedSpecies = "sandLizard";
const NatterjackToad: UrsProtectedSpecies = "natterjackToad";
const SmoothSnake: UrsProtectedSpecies = "smoothSnake";
const LesserWhirlpoolRamshornSnail: UrsProtectedSpecies =
  "lesserWhirlpoolRamshornSnail";
const Adder: UrsProtectedSpecies = "adder";
const Badger: UrsProtectedSpecies = "badger";
const WaterVole: UrsProtectedSpecies = "waterVole";
const WhiteClawedCrayfish: UrsProtectedSpecies = "whiteClawedCrayfish";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsProtectedSpeciesEnum = {
  ...createEnum("UrsProtectedSpecies", [
    [Bat, "Bats"],
    [GreatCrestedNewt, "Great Crested Newt"],
    [Otter, "Otter"],
    [Dormouse, "Dormouse"],
    [SandLizard, "Sand Lizard"],
    [NatterjackToad, "Natterjack Toad"],
    [SmoothSnake, "Smooth Snake"],
    [LesserWhirlpoolRamshornSnail, "Lesser Whirlpool Ramshorn Snail"],
    [Adder, "Adder"],
    [Badger, "Badger"],
    [WaterVole, "Water Vole"],
    [WhiteClawedCrayfish, "White Clawed Crayfish"],
  ]),
  Bat,
  GreatCrestedNewt,
  Otter,
  Dormouse,
  SandLizard,
  NatterjackToad,
  SmoothSnake,
  LesserWhirlpoolRamshornSnail,
  Adder,
  Badger,
  WaterVole,
  WhiteClawedCrayfish,
};

export default UrsProtectedSpeciesEnum;
