/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  ProjectId,
  isProjectId,
  TeamAlias,
  isTeamAlias,
  TeamId,
  isTeamId,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";

export type KnownTeamFeature = "LockAlias";

export type TeamFeature = "LockAlias" | string;

export interface Team {
  name: string;
  alias: TeamAlias;
  workspaceId: WorkspaceId;
  projectIds: ProjectId[];
  features: TeamFeature[];
  id: TeamId;
}

export interface TeamCreate {
  name: string;
  alias: TeamAlias;
  workspaceId: WorkspaceId;
  projectIds: ProjectId[];
  features?: TeamFeature[] | null;
  id?: TeamId | null;
}

export interface TeamUpdate {
  name?: string | null;
  alias?: TeamAlias | null;
  workspaceId?: WorkspaceId | null;
  projectIds?: ProjectId[] | null;
  features?: TeamFeature[] | null;
}

export const isKnownTeamFeature = (v: any): v is KnownTeamFeature => {
  return v === "LockAlias";
};

export const isTeamFeature = (v: any): v is TeamFeature => {
  return v === "LockAlias" || typeof v === "string";
};

export const isTeam = (v: any): v is Team => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isTeamAlias(v.alias) &&
    "workspaceId" in v &&
    isWorkspaceId(v.workspaceId) &&
    "projectIds" in v &&
    Array.isArray(v.projectIds) &&
    v.projectIds.every((i: any) => isProjectId(i)) &&
    "features" in v &&
    Array.isArray(v.features) &&
    v.features.every((i: any) => isTeamFeature(i)) &&
    "id" in v &&
    isTeamId(v.id)
  );
};

export const isTeamCreate = (v: any): v is TeamCreate => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isTeamAlias(v.alias) &&
    "workspaceId" in v &&
    isWorkspaceId(v.workspaceId) &&
    "projectIds" in v &&
    Array.isArray(v.projectIds) &&
    v.projectIds.every((i: any) => isProjectId(i)) &&
    (!("features" in v) ||
      (Array.isArray(v.features) &&
        v.features.every((i: any) => isTeamFeature(i))) ||
      v.features === null) &&
    (!("id" in v) || isTeamId(v.id) || v.id === null)
  );
};

export const isTeamUpdate = (v: any): v is TeamUpdate => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("name" in v) || typeof v.name === "string" || v.name === null) &&
    (!("alias" in v) || isTeamAlias(v.alias) || v.alias === null) &&
    (!("workspaceId" in v) ||
      isWorkspaceId(v.workspaceId) ||
      v.workspaceId === null) &&
    (!("projectIds" in v) ||
      (Array.isArray(v.projectIds) &&
        v.projectIds.every((i: any) => isProjectId(i))) ||
      v.projectIds === null) &&
    (!("features" in v) ||
      (Array.isArray(v.features) &&
        v.features.every((i: any) => isTeamFeature(i))) ||
      v.features === null)
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
