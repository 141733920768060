import {
  appendPath,
  errorMessage,
  Message,
  Path,
  pathToString,
} from "@cartographerio/topo-core";
import {
  describeSchema,
  isAnySchema,
  isArraySchema,
  isAttachmentFolderSchema,
  isBooleanSchema,
  isFeatureSchema,
  isGeometrySchema,
  isIntegerSchema,
  isMaybeNullableSchema,
  isNullableSchema,
  isNumberSchema,
  isNumericSchema,
  isPickedSchema,
  isSchema,
  isStringSchema,
  isTimestampSchema,
  isUserRefSchema,
  isUuidSchema,
  Schema,
  SchemaPredicate,
  schemaRef,
} from "@cartographerio/topo-survey";
import {
  isBoolean,
  isInteger,
  isNullable,
  isNumber,
  isString,
} from "@cartographerio/guard";
import { checkExhausted } from "@cartographerio/util";
import lodash, { chain, isEqual } from "lodash";
import { fieldIsNullable } from "./nullable";
import { Block, Field, Form, Page, SelectOption, SelectValue } from "./type";

function isSelectSchema(options: SelectOption[]) {
  const normalize = (values: SelectValue[]): unknown[] =>
    chain(values).uniq().sort().value();

  const isEnum = (schema: Schema, options: SelectOption[]): boolean =>
    schema.type === "Enum" &&
    isEqual(
      normalize(schema.values),
      normalize(options.map(option => option.value))
    );

  const isNullableEnum = (schema: Schema, options: SelectOption[]): boolean =>
    schema.type === "Nullable" &&
    (isEnum(
      schema.param,
      options.filter(o => o.value != null)
    ) ||
      isNullableEnum(
        schema.param,
        options.filter(o => o.value != null)
      ));

  const isAllString = (schema: Schema) =>
    options.every(option => isNullable(isString)(option.value)) &&
    isMaybeNullableSchema(isStringSchema)(schema);

  const isAllInteger = (schema: Schema) =>
    options.every(option => isNullable(isInteger)(option.value)) &&
    isMaybeNullableSchema(isIntegerSchema)(schema);

  const isAllNumeric = (schema: Schema) =>
    options.every(option => isNullable(isNumber)(option.value)) &&
    isMaybeNullableSchema(isNumberSchema)(schema);

  const isAllBoolean = (schema: Schema) =>
    options.every(option => isNullable(isBoolean)(option.value)) &&
    isMaybeNullableSchema(isBooleanSchema)(schema);

  return (schema: Schema) =>
    isEnum(schema, options) ||
    isNullableEnum(schema, options) ||
    isAllString(schema) ||
    isAllInteger(schema) ||
    isAllNumeric(schema) ||
    isAllBoolean(schema);
}

const formatSelectOptions = (options: SelectOption[]): string =>
  options.map(o => o.value).join(", ");

const fieldTypeName = (field: Field, name: string): string =>
  fieldIsNullable(field) ? `nullable ${name}` : `maybe nullable ${name}`;

const fieldPredicate: (
  field: Field,
  pred: SchemaPredicate
) => SchemaPredicate = (field, pred) => schema =>
  fieldIsNullable(field)
    ? isNullableSchema(pred)(schema)
    : isMaybeNullableSchema(pred)(schema);

function check(
  path: Path,
  formSchema: Block,
  schema: Schema | undefined,
  expected: string,
  pred: SchemaPredicate
): Message[] {
  const pathString = pathToString(path);

  if (schema == null) {
    return [
      errorMessage(
        `${formSchema.type} at ${pathString} expects schema of type: ${expected}, found nothing`,
        path
      ),
    ];
  } else {
    return pred(schema)
      ? []
      : [
          errorMessage(
            `${
              formSchema.type
            } at ${pathString} expects schema of type: ${expected}, found ${describeSchema(
              schema
            )}`,
            path
          ),
        ];
  }
}

export function selfCheckField(
  field: Field,
  path0: Path,
  schema: Schema
): Message[] {
  const path = [...path0, ...field.path];

  switch (field.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "string"),
        fieldPredicate(field, isStringSchema)
      );
    }

    case "IntegerField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "integer"),
        fieldPredicate(field, isNumericSchema)
      );
    }

    case "NumberField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "number"),
        fieldPredicate(field, isNumberSchema)
      );
    }

    case "UserRefField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "user reference"),
        fieldPredicate(field, isUserRefSchema)
      );
    }

    case "TimestampField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "timestamp"),
        fieldPredicate(field, isTimestampSchema)
      );
    }

    case "AttachmentField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        "attachment folder",
        isAttachmentFolderSchema
      );
    }

    case "PointField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "point"),
        fieldPredicate(field, isGeometrySchema("Point"))
      );
    }

    case "LineStringField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "line string"),
        fieldPredicate(field, isGeometrySchema("LineString"))
      );
    }

    case "PolygonField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "polygon"),
        fieldPredicate(field, isGeometrySchema("Polygon"))
      );
    }

    case "FeatureField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "feature"),
        fieldPredicate(field, isFeatureSchema())
      );
    }

    case "NearestFeatureField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "picked feature"),
        fieldPredicate(field, isPickedSchema())
      );
    }

    case "Checkbox": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "possibly nullable boolean"),
        isMaybeNullableSchema(isBooleanSchema)
      );
    }

    case "Select": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(
          field,
          `enum value (${formatSelectOptions(field.options)})`
        ),
        isSelectSchema(field.options)
      );
    }

    case "MultiSelect": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        `array of enum values (${formatSelectOptions(field.options)})`,
        isMaybeNullableSchema(isArraySchema(isSelectSchema(field.options)))
      );
    }

    case "TeamField": {
      return check(
        path,
        field,
        schemaRef(schema, path),
        fieldTypeName(field, "team id"),
        fieldPredicate(field, isUuidSchema)
      );
    }

    default:
      return checkExhausted(field);
  }
}

export function selfCheckBlock(
  block: Block,
  path: Path,
  schema: Schema
): Message[] {
  switch (block.type) {
    case "Heading":
    case "Text":
    case "DynamicValue":
    case "DynamicGrid":
    case "DynamicImage":
    case "PrimarySurveyorField":
    case "PrimaryTeamField":
      return [];

    case "Group":
      return block.blocks.flatMap(b => selfCheckBlock(b, path, schema));

    case "Repeat": {
      const arrayPath = appendPath(path, block.path);
      const arraySchema = schemaRef(schema, arrayPath);

      if (isSchema(arraySchema) && isArraySchema(isAnySchema)(arraySchema)) {
        return selfCheckBlock(block.block, appendPath(arrayPath, 0), schema);
      } else {
        return check(
          path,
          block,
          arraySchema,
          `array`,
          isArraySchema(isSchema)
        );
      }
    }

    case "Card":
    case "FullWidth":
    case "Visibility":
    case "RequireRole":
      return selfCheckBlock(block.block, path, schema);

    case "Scope":
      return selfCheckBlock(block.block, appendPath(path, block.path), schema);

    default:
      return selfCheckField(block, path, schema);
  }
}

export function selfCheckPage(
  page: Page,
  path0: Path,
  schema: Schema
): Message[] {
  const path: Path = [...path0, ...page.path];
  return lodash.flatMap(page.blocks, block =>
    selfCheckBlock(block, path, schema)
  );
}

export function selfCheckForm(form: Form, schema: Schema): Message[] {
  return lodash.flatMap(form.pages, page => selfCheckPage(page, [], schema));
}
