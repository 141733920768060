/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  DataLicense,
  isDataLicense,
  SurveyId,
  isSurveyId,
  Timestamp,
  isTimestamp,
} from "../core/types.generated";
import { UserRef, isUserRef } from "../user/types.generated";
import { Tagged, bridgesIsTaggedHack as isTagged } from "@cartographerio/util";

export type AttachmentId = Tagged<"AttachmentId">;

export type ContentType = Tagged<"ContentType">;

export interface AttachmentFolder {
  folder: string;
}

export type AttachmentVariantId =
  | "original"
  | "preview"
  | "320x240"
  | "640x480"
  | "1280x960";

export type AttachmentCategory =
  | "image"
  | "text"
  | "document"
  | "spreadsheet"
  | "presentation"
  | "archive"
  | "unknown";

export type AttachmentNaming = "Original" | "Title" | "Id";

export interface Attachment {
  surveyId: SurveyId;
  folder: AttachmentFolder;
  filename: string;
  contentType: ContentType;
  title?: string | null;
  description?: string | null;
  author: UserRef;
  license: DataLicense;
  created: Timestamp;
  updated: Timestamp;
  id: AttachmentId;
}

export interface AttachmentCreate {
  surveyId: SurveyId;
  folder: AttachmentFolder;
  title?: string | null;
  description?: string | null;
  author?: UserRef | null;
  license?: DataLicense | null;
}

export interface AttachmentUpdate {
  title?: string | null;
  description?: string | null;
  author?: UserRef | null;
  license?: DataLicense | null;
}

export type ResolveMethod = "get" | "head";

export interface ResolveResponse {
  method: string;
  url: string;
}

export const isAttachmentId = (v: any): v is AttachmentId => {
  return isTagged((a0: any): a0 is "AttachmentId" => a0 === "AttachmentId")(v);
};

export const isContentType = (v: any): v is ContentType => {
  return isTagged((a0: any): a0 is "ContentType" => a0 === "ContentType")(v);
};

export const isAttachmentFolder = (v: any): v is AttachmentFolder => {
  return (
    typeof v === "object" &&
    v != null &&
    "folder" in v &&
    typeof v.folder === "string"
  );
};

export const isAttachmentVariantId = (v: any): v is AttachmentVariantId => {
  return (
    v === "original" ||
    v === "preview" ||
    v === "320x240" ||
    v === "640x480" ||
    v === "1280x960"
  );
};

export const isAttachmentCategory = (v: any): v is AttachmentCategory => {
  return (
    v === "image" ||
    v === "text" ||
    v === "document" ||
    v === "spreadsheet" ||
    v === "presentation" ||
    v === "archive" ||
    v === "unknown"
  );
};

export const isAttachmentNaming = (v: any): v is AttachmentNaming => {
  return v === "Original" || v === "Title" || v === "Id";
};

export const isAttachment = (v: any): v is Attachment => {
  return (
    typeof v === "object" &&
    v != null &&
    "surveyId" in v &&
    isSurveyId(v.surveyId) &&
    "folder" in v &&
    isAttachmentFolder(v.folder) &&
    "filename" in v &&
    typeof v.filename === "string" &&
    "contentType" in v &&
    isContentType(v.contentType) &&
    (!("title" in v) || typeof v.title === "string" || v.title === null) &&
    (!("description" in v) ||
      typeof v.description === "string" ||
      v.description === null) &&
    "author" in v &&
    isUserRef(v.author) &&
    "license" in v &&
    isDataLicense(v.license) &&
    "created" in v &&
    isTimestamp(v.created) &&
    "updated" in v &&
    isTimestamp(v.updated) &&
    "id" in v &&
    isAttachmentId(v.id)
  );
};

export const isAttachmentCreate = (v: any): v is AttachmentCreate => {
  return (
    typeof v === "object" &&
    v != null &&
    "surveyId" in v &&
    isSurveyId(v.surveyId) &&
    "folder" in v &&
    isAttachmentFolder(v.folder) &&
    (!("title" in v) || typeof v.title === "string" || v.title === null) &&
    (!("description" in v) ||
      typeof v.description === "string" ||
      v.description === null) &&
    (!("author" in v) || isUserRef(v.author) || v.author === null) &&
    (!("license" in v) || isDataLicense(v.license) || v.license === null)
  );
};

export const isAttachmentUpdate = (v: any): v is AttachmentUpdate => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("title" in v) || typeof v.title === "string" || v.title === null) &&
    (!("description" in v) ||
      typeof v.description === "string" ||
      v.description === null) &&
    (!("author" in v) || isUserRef(v.author) || v.author === null) &&
    (!("license" in v) || isDataLicense(v.license) || v.license === null)
  );
};

export const isResolveMethod = (v: any): v is ResolveMethod => {
  return v === "get" || v === "head";
};

export const isResolveResponse = (v: any): v is ResolveResponse => {
  return (
    typeof v === "object" &&
    v != null &&
    "method" in v &&
    typeof v.method === "string" &&
    "url" in v &&
    typeof v.url === "string"
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
