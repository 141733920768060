/**!
 * Ordnance Survey Grid Reference conversion functions.
 *
 * Adapted from the [Geodesy]() library by Chris Veness.
 *
 * [Geodesy]: https://github.com/chrisveness/geodesy/blob/master/osgridref.js
 *
 * @copyright Chris Veness 2005-2020
 * @license MIT
 */

import { Position } from "./type";

export const minEasting = 0;
export const minNorthing = 0;
export const maxEasting = 699999;
export const maxNorthing = 1199999;

export type NgrDigits = 2 | 4 | 6 | 8 | 10; // algorithm works with 12, 14, and 16

const charCodeForA = "A".charCodeAt(0);

export function osgb36ToNgr(
  [e, n]: Position,
  digits: NgrDigits = 10,
  spaces: boolean = true
): string | undefined {
  if (e < minEasting || e > maxEasting || n < minNorthing || n > maxNorthing) {
    return undefined;
  }

  // get the 100km-grid indices
  const e100km = Math.floor(e / 100000);
  const n100km = Math.floor(n / 100000);

  // translate those into numeric equivalents of the grid letters
  let l1 = 19 - n100km - ((19 - n100km) % 5) + Math.floor((e100km + 10) / 5);
  let l2 = (((19 - n100km) * 5) % 25) + (e100km % 5);

  // compensate for skipped 'I' and build grid letter-pairs
  if (l1 > 7) l1++;
  if (l2 > 7) l2++;

  const letterPair = String.fromCharCode(l1 + charCodeForA, l2 + charCodeForA);

  // strip 100km-grid indices from easting & northing, and reduce precision
  e = Math.floor((e % 100000) / Math.pow(10, 5 - digits / 2));
  n = Math.floor((n % 100000) / Math.pow(10, 5 - digits / 2));

  // pad eastings & northings with leading zeros
  const eString = e.toString().padStart(digits / 2, "0");
  const nString = n.toString().padStart(digits / 2, "0");

  return spaces
    ? `${letterPair} ${eString} ${nString}`
    : `${letterPair}${eString}${nString}`;
}

export function ngrDigits(ngr: string): NgrDigits | undefined {
  const gridref = ngr.trim().toUpperCase().replace(/\s+/g, "");
  const match = gridref.match(/^[HNST][ABCDEFGHJKLMNOPQRSTUVWXYZ]([0-9]+)$/i);

  if (match == null) {
    return undefined;
  } else {
    const digits = match[1];

    switch (digits.length) {
      case 2:
        return 2;
      case 4:
        return 4;
      case 6:
        return 6;
      case 8:
        return 8;
      case 10:
        return 10;
      default:
        return undefined;
    }
  }
}

export function ngrToOsgb36(ngr: string): Position | undefined {
  const gridref = ngr.trim().toUpperCase().replace(/\s+/g, "");

  // check for fully numeric comma-separated gridref format
  let match = gridref.match(/^(\d+),\s*(\d+)$/);
  if (match) return [parseInt(match[1], 10), parseInt(match[2], 10)];

  // validate format
  match = gridref.match(
    /^[HNST][ABCDEFGHJKLMNOPQRSTUVWXYZ]\s*[0-9]+\s*[0-9]+$/i
  );
  if (!match) return undefined;

  // get numeric values of letter references, mapping A->0, B->1, C->2, etc:
  let l1 = gridref.charCodeAt(0) - charCodeForA; // 500km square
  let l2 = gridref.charCodeAt(1) - charCodeForA; // 100km square

  // shuffle down letters after 'I' since 'I' is not used in grid:
  if (l1 > 7) l1--;
  if (l2 > 7) l2--;

  // convert grid letters into 100km-square indexes from false origin (grid square SV):
  const e100km = ((l1 - 2) % 5) * 5 + (l2 % 5);
  const n100km = 19 - Math.floor(l1 / 5) * 5 - Math.floor(l2 / 5);

  // skip grid letters to get numeric (easting/northing) part of ref
  let en = gridref.slice(2).trim().split(/\s+/);

  // if e/n not whitespace separated, split half way
  if (en.length === 1) {
    en = [en[0].slice(0, en[0].length / 2), en[0].slice(en[0].length / 2)];
  }

  // validation
  if (en[0].length !== en[1].length) {
    return undefined;
  }

  // split and standardise to 10-digit refs (metres)
  // standardise to 10-digit refs (metres)
  en[0] = en[0].padEnd(5, "0");
  en[1] = en[1].padEnd(5, "0");

  const e = e100km + en[0];
  const n = n100km + en[1];

  return [parseInt(e, 10), parseInt(n, 10)];
}
