import { unsafeMapId } from "@cartographerio/topo-map";
import { mapSchemaWithDefaults } from "../../core";
import {
  neLakeNaturalnessLayer,
  priorityLakeHabitatLayer,
} from "./naturalnessHelpers";
import { MapBaseEnum } from "@cartographerio/types";

export const NeLakeNaturalness = mapSchemaWithDefaults({
  mapId: unsafeMapId("neLakeNaturalness"),
  title: "Lake Naturalness",
  defaultBase: MapBaseEnum.Satellite,
  layers: [priorityLakeHabitatLayer, neLakeNaturalnessLayer],
});
