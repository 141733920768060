// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsAtpe = "A" | "T" | "P" | "E";

const Absent: MrsAtpe = "A";
const Trace: MrsAtpe = "T";
const Present: MrsAtpe = "P";
const Extensive: MrsAtpe = "E";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsAtpeEnum = {
  ...createEnum("MrsAtpe", [
    [Absent, "Absent"],
    [Trace, "Trace"],
    [Present, "Present"],
    [Extensive, "Extensive"],
  ]),
  Absent,
  Trace,
  Present,
  Extensive,
};

export default MrsAtpeEnum;
