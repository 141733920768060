import { Path } from "@cartographerio/topo-core";
import {
  topoExpr,
  attachmentField,
  bounds,
  checkbox,
  columns2,
  enumSelectOptions,
  forbiddenIf,
  form,
  Form,
  integerField,
  numberField,
  page,
  pointField,
  required,
  requiredIf,
  requiredIff,
  section,
  select,
  selectOption,
  subsection,
  subsectionHeading,
  subsubsection,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";

import {
  MrsArtificialGroundCoverEnum,
  MrsChannelCoverageEnum,
  MrsMajorIntermediateMinorEnum,
  MrsMorphEstuariesBankProfileEnum,
  MrsMorphEstuariesCreekTypeEnum,
  MrsMorphEstuariesDaysToSpringTideEnum,
  MrsMorphEstuariesDrainageNetworkTypeEnum,
  MrsMorphEstuariesFloodWallDimensionEnum,
  MrsMorphEstuariesFragmentationEnum,
  MrsMorphEstuariesFrontBackCreekMarginEnum,
  MrsMorphEstuariesMarginTypeEnum,
  MrsMorphEstuariesSaltMarshLocationEnum,
  MrsMorphEstuariesSmallFragmentDistributionEnum,
  MrsMorphEstuariesSubtidalSedimentSizeEnum,
  MrsMorphEstuariesVegetatedIntertidalSedimentSizeEnum,
  MrsMorphEstuariesVisibilityEnum,
  MrsReinforcementTypeEnum,
  MrsReinforcementVerticalExtentEnum,
  MrsTreeTypeEnum,
  UrsSurveyBankEnum,
  UrsSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { checkExhausted } from "@cartographerio/util";
import { flatten } from "lodash";
import { outdent } from "outdent";
import {
  banksSubsubsection,
  channelSubsubsection,
  morphApeSelect,
  morphAtpeSelect,
  morphCount,
  morphSelect,
  morphText,
} from "./formHelpers";

export function mrsMorphEstuariesForm(title: string): Form {
  return form({
    title,
    pages: [
      page({
        title: "1 General Information",
        path: [],
        blocks: [
          section({
            title: "Project Details",
            path: [],
            blocks: [
              columns2(
                vstack(
                  textField({
                    label: "Project Name",
                    path: ["data", "surveyDetails", "projectName"],
                    help: "Name of the river restoration project being monitored (if applicable).",
                  }),
                  textField({
                    label: "MoRPh Correlation Code",
                    path: ["data", "surveyDetails", "projectCode"],
                    help: "Code for the restoration project being monitored (if applicable).",
                  })
                ),
                vstack(
                  // textField({
                  //   label: "WFD Waterbody ID",
                  //   path: ["data", "surveyDetails", "wfdWaterBodyId"],
                  //   help: "ID used to identify the water body for the purposes of Water Framework Directive reporting.",
                  // }),
                  select({
                    label: "Survey Type",
                    path: ["data", "surveyDetails", "surveyType"],
                    options: enumSelectOptions(UrsSurveyTypeEnum),
                    help: outdent`
                    Is this survey for general *monitoring* purposes,
                    is it a *pre-project, post-project or post-recovery assessment*,
                    is it testing a hypothetical *scenario*,
                    or is it a dummy survey created during a *training* course?
                    `,
                  }),
                  textField({
                    label: "Scenario Name",
                    path: ["data", "surveyDetails", "scenarioName"],
                    help: outdent`
                    If you selected *scenario* above, enter a scenario name here.
                    The name will be searchable on the survey list.
                    `,
                    customRules: requiredIff({
                      level: "info",
                      requiredMessage: "You should name the scenario.",
                      forbiddenMessage:
                        "Only enter a scenario name if you selected 'Scenario' above.",
                      otherPath: ["data", "surveyDetails", "surveyType"],
                      otherTest: value => value === UrsSurveyTypeEnum.Scenario,
                    }),
                  })
                )
              ),
            ],
          }),
          section({
            title: "1.1 Surveyor and Survey Conditions",
            path: [],
            blocks: [
              columns2(
                vstack(
                  timestampField({
                    label: "Survey Date and Time",
                    path: ["data", "recorded"],
                    required: required("info"),
                    help: "Date and time the survey was recorded in the field",
                    defaultValue: "now",
                  }),
                  checkbox({
                    label: "Time to Low Tide",
                    path: ["data", "surveyDetails", "lowTide"],
                    checkboxLabel:
                      "Is your survey within 2 hours of today's Low Tide?",
                  }),
                  select({
                    label: "Number of Days to Nearest Spring Tide",
                    path: ["data", "surveyDetails", "daysToSpringTide"],
                    options: enumSelectOptions(
                      MrsMorphEstuariesDaysToSpringTideEnum
                    ),
                    required: required("info"),
                    help: "If 'Other' - record no. of days in notes.",
                  }),
                  select({
                    label: "Which Estuary Margin is Being Surveyed",
                    path: ["data", "surveyDetails", "surveyBank"],
                    options: enumSelectOptions(UrsSurveyBankEnum),
                    required: required("info"),
                  }),
                  select({
                    label: "Is the Subtidal Channel Bed Visible?",
                    path: ["data", "surveyDetails", "subtidalZoneVisibility"],
                    options: enumSelectOptions(MrsMorphEstuariesVisibilityEnum),
                    required: required("info"),
                  }),
                  select({
                    label: "Drift Line / High Water Mark Visibility",
                    path: ["data", "surveyDetails", "driftLineVisibility"],
                    options: enumSelectOptions(MrsMorphEstuariesVisibilityEnum),
                    required: required("info"),
                    help: "Is the drift line / high water mark visible?",
                  }),
                  checkbox({
                    label: "Any Adverse Conditions?",
                    path: ["data", "surveyDetails", "adverseConditions"],
                    checkboxLabel:
                      "Were there adverse conditions at the time of the survey?",
                    defaultValue: false,
                    help: outdent`
                  Examples of adverse conditions include: limited access, high river flows,
                  impenetrable riparian vegetation, and poor light conditions.
                  `,
                  }),
                  textField({
                    label: "Adverse Conditions Notes",
                    path: [
                      "data",
                      "surveyDetails",
                      "adverseConditionsDescription",
                    ],
                    help: "If there were adverse conditions, please describe them.",
                    visible: topoExpr(
                      env =>
                        env.get([
                          "data",
                          "surveyDetails",
                          "adverseConditions",
                        ]) === true
                    ),
                    customRules: [
                      requiredIf({
                        level: "info",
                        message: "Please describe the adverse conditions.",
                        otherPath: [
                          "data",
                          "surveyDetails",
                          "adverseConditions",
                        ],
                        otherTest: value => value === true,
                      }),
                    ],
                  })
                )
              ),
            ],
          }),
          section({
            title: "1.2 Module Name and Location",
            path: [],
            blocks: [
              columns2(
                vstack(
                  textField({
                    label: "Estuary Name",
                    path: ["data", "surveyDetails", "riverName"],
                    required: required("info"),
                    help: "Name of the estuary being surveyed.",
                  }),
                  textField({
                    label: "Location / Reach Name",
                    path: ["data", "surveyDetails", "reachName"],
                    required: required("info"),
                    help: "Name of the reach in which the module is located.",
                  }),
                  textField({
                    label: "Subreach Name",
                    path: ["data", "surveyDetails", "subreachName"],
                    required: required("info"),
                    help: "Used to reference a sub-reach of contiguous modules.",
                  }),
                  integerField({
                    label: "Module Number",
                    path: ["data", "surveyDetails", "moduleNumber"],
                    required: required("info"),
                    bounds: bounds(1, 10, "error"),
                    help: "1, 2, 3 from upstream to downstream within subreach.",
                  }),
                  select({
                    label: "Module Length",
                    path: ["data", "surveyDetails", "moduleLength"],
                    required: required("info"),
                    options: [
                      selectOption(10, "10m (river <5m wide)"),
                      selectOption(20, "20m (river 5m to <10m wide)"),
                      selectOption(30, "30m (river 10m to <20m wide)"),
                      selectOption(40, "40m (river 20m to <30m wide)"),
                      selectOption(50, "50m (river 30m wide or more)"),
                    ],
                    help: outdent`
                    Module length is based on the *predominant active river width*,
                    estimated as the typical water width plus any area of bare sediment
                    or emergent aquatic plants at the edge of the water.
                    `,
                  })
                )
              ),
              pointField({
                label: "Module Mid-Point Location",
                path: ["data", "surveyDetails", "location"],
                required: required("info"),
              }),
            ],
          }),
          section({
            title: "1.3 Channel Dimensions",
            path: [],
            blocks: [
              subsection({
                title: "1.3a Marginal Zones and Widths",
                path: [],
                help: "Estimate the average values for Left Bank (LB) and Right Bank (RB) along the module length (m)",
                blocks: [
                  channelDimensions(intertidalVegetated),
                  channelDimensions(intertidalUnvegetated),
                  channelDimensions(subtidalWaterWidth),
                  channelDimensions(waterDepth),
                ],
              }),
              subsection({
                title: "1.3b Mid-Lower Estuary Only",
                path: [],
                help: outdent`
                Tick or Estimate the average widths IF you can identify marsh zones.
                Please refer to field guide diagrams and other sources as needed.
                Include additional photographic or other evidence in supporting reports.
                `,
                blocks: [
                  channelDimensions(supratidalUpperMarsh),
                  channelDimensions(intertidalVegetatedMiddleMarsh),
                  channelDimensions(intertidalVegetatedLowerMarsh),
                  channelDimensions(intertidalVegetatedPioneerMarsh),
                ],
              }),
            ],
          }),
          section({
            title: "1.4 Estuary Bank or Marginal Profile",
            path: ["data", "surveyDetails", "bankProfile"],
            blocks: [
              subsection({
                title: "1.4a Intertidal Vegetated Natural/Artificial Profile",
                path: [],
                blocks: [
                  banksSubsubsection({
                    label: "Dominant Intertidal Vegetated Profile",
                    path: ["vegetatedIntertidalDominant"],
                    code: morphSelect({
                      secondaryLabel: "Profile Type",
                      source: MrsMorphEstuariesBankProfileEnum,
                      showValues: true,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Dominant Intertidal Vegetated Profile",
                    sectionTitle: null,
                    path: ["vegetatedIntertidalDominant"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Subdominant Intertidal Vegetated Profile",
                    path: ["vegetatedIntertidalSubdominant"],
                    code: morphSelect({
                      secondaryLabel: "Profile Type",
                      source: MrsMorphEstuariesBankProfileEnum,
                      showValues: true,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Subdominant Intertidal Vegetated Profile",
                    sectionTitle: null,
                    path: ["vegetatedIntertidalSubdominant"],
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
              subsection({
                title: "1.4b Intertidal Unvegetated Natural/Artificial Profile",
                path: [],
                blocks: [
                  banksSubsubsection({
                    label: "Dominant Intertidal Unvegetated Profile",
                    path: ["unvegetatedIntertidalDominant"],
                    code: morphSelect({
                      secondaryLabel: "Profile",
                      source: MrsMorphEstuariesBankProfileEnum,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Dominant Intertidal Unvegetated Profile",
                    sectionTitle: null,
                    path: ["unvegetatedIntertidalDominant"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Subdominant Intertidal Unvegetated Profile",
                    path: ["unvegetatedIntertidalSubdominant"],
                    code: morphSelect({
                      secondaryLabel: "Profile",
                      source: MrsMorphEstuariesBankProfileEnum,
                      showValues: true,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Subdominant Intertidal Unvegetated Profile",
                    sectionTitle: null,
                    path: ["unvegetatedIntertidalSubdominant"],
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
              subsection({
                title: "1.4c Flood Defence Structure",
                path: [],
                blocks: [
                  banksSubsubsection({
                    label: "Flood Embankment / Wall",
                    path: ["floodEmbankmentOrWall"],
                    code: morphSelect({
                      secondaryLabel: "Height",
                      source: MrsMorphEstuariesFloodWallDimensionEnum,
                    }),
                  }),
                ],
              }),
            ],
          }),
          photographsAndNotes("1.5 Photographs and Notes"),
        ],
      }),
      page({
        title: "2 Supratidal Zone",
        path: [],
        blocks: [
          section({
            title: "2.1 Artificial/Managed Ground Cover",
            path: ["data", "supratidal", "artificialMaterials"],
            blocks: [
              subsection({
                title: "Artificial Ground Cover",
                path: [],
                blocks: [
                  banksSubsubsection({
                    label: "Dominant Artificial Ground Cover",
                    path: ["dominantGroundCover"],
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      secondaryLabel: "Dominant Cover",
                      source: MrsArtificialGroundCoverEnum,
                      showValues: true,
                    }),
                    help: "Within 10m of the bank edge / high water mark",
                  }),
                  banksSubsubsection({
                    label: "Subdominant Artificial Ground Cover",
                    path: ["subdominantGroundCover"],
                    abundance: morphApeSelect(),
                    code: morphSelect({
                      secondaryLabel: "Subdominant Cover",
                      source: MrsArtificialGroundCoverEnum,
                      showValues: true,
                    }),
                    help: "Within 10m of the bank edge / high water mark",
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "2.2 Artificial Features",
            path: [],
            blocks: [
              subsection({
                title: "2.2a Artificial Materials / Reinforcement",
                path: ["data", "supratidal", "artificialMaterials"],
                blocks: [
                  banksSubsubsection({
                    label: "Vertical Extent",
                    path: ["reinforcementVerticalExtent"],
                    abundance: morphSelect({
                      source: MrsReinforcementVerticalExtentEnum,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Horizontal Extent",
                    path: ["reinforcementHorizontalExtent"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Dominant Reinforcement Type",
                    path: ["dominantReinforcement"],
                    code: morphSelect({
                      source: MrsReinforcementTypeEnum,
                      showValues: true,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Subdominant Reinforcement Type",
                    path: ["subdominantReinforcement"],
                    code: morphSelect({
                      source: MrsReinforcementTypeEnum,
                      showValues: true,
                    }),
                  }),
                ],
              }),
              subsection({
                title: "2.2b Artificial Habitat Enhancements",
                path: ["data", "supratidal", "artificialFeatures"],
                blocks: flatten([
                  banksSubsubsection({
                    label: "Terracing - Non Zig Zag",
                    path: ["terraceNonZigZag"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Terracing - Zig Zag",
                    path: ["terraceZigZag"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Bolt-On Timbers",
                    path: ["boltOnTimbers"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Other Vertical Enhancement",
                    path: ["otherVerticalEnhancement"],
                    abundance: morphAtpeSelect(),
                    code: morphText({
                      secondaryLabel: "Enhancement",
                      required: false,
                    }),
                  }),
                ]),
              }),
            ],
          }),
          section({
            title: "2.3 Natural Features / Lightly Managed Ground Cover",
            path: [],
            blocks: [
              subsection({
                title: "2.3a Terrestrial Vegetation",
                path: [
                  "data",
                  "supratidal",
                  "naturalFeatures",
                  "terrestrialVegetation",
                ],
                blocks: [
                  banksSubsubsection({
                    path: ["unvegetated"],
                    label: "Unvegetated (Bare Soil/Rock)",
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Mosses/Lichens",
                    path: ["bryophytes"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/bryophytes.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Short/Creeping Herbs/Grasses",
                    path: ["shortHerbsOrGrasses"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/short-herbs-or-grasses.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Tall Herbs/Grasses (Including Reeds)",
                    path: ["tallHerbsOrGrasses"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/tall-herbs-or-grasses.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Scrub or Shrubs",
                    path: ["scrubOrShrubs"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/scrub-or-shrubs.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Saplings or Trees",
                    path: ["saplingsOrTrees"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/saplings-or-trees.jpg",
                    }),
                  }),
                ],
              }),
              subsection({
                title: "2.3b Tree Features",
                path: ["data", "supratidal", "naturalFeatures", "treeFeatures"],
                blocks: [
                  banksSubsubsection({
                    label: "Fallen Trees",
                    path: ["fallenTrees"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/fallen-trees.jpg",
                    }),
                    help: "ONLY those with a significant proportion on bank top",
                  }),
                  banksSubsubsection({
                    label: "Leaning Trees",
                    path: ["leaningTrees"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/leaning-trees.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "J-Shaped Trees",
                    path: ["jShapedTrees"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/j-shaped-trees.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Tree/Shrub Branches Trailing Into Channel",
                    path: ["trailingTreeOrShrubBranches"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/trailing-tree-or-shrub-branches.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Large Wood",
                    path: ["largeWood"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "bank-top-terrestrial-vegetation/large-wood.jpg",
                    }),
                    help: "Wood pieces >1m long, >10cm diameter",
                  }),
                  banksSubsubsection({
                    label: "Predominant Tree Type",
                    path: ["predominantTreeType"],
                    code: morphSelect({ source: MrsTreeTypeEnum }),
                  }),
                ],
              }),
              subsection({
                title: "2.3c Non-Native Invasive Plant Species",
                path: [
                  "data",
                  "supratidal",
                  "naturalFeatures",
                  "nuisancePlantSpecies",
                ],
                blocks: [
                  banksSubsubsection({
                    label: "Himalayan Balsam",
                    path: ["himalayanBalsam"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "nuisance-plant-species/himalayan-balsam.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Japanese Knotweed",
                    path: ["japaneseKnotweed"],
                    abundance: morphAtpeSelect({
                      defaultImage:
                        "nuisance-plant-species/japanese-knotweed.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Giant Hogweed",
                    path: ["giantHogweed"],
                    abundance: morphAtpeSelect({
                      defaultImage: "nuisance-plant-species/giant-hogweed.jpg",
                    }),
                  }),
                  banksSubsubsection({
                    label: "Other Species 1",
                    path: ["otherSpecies1"],
                    abundance: morphAtpeSelect(),
                    code: morphText({ secondaryLabel: "Species" }),
                  }),
                  banksSubsubsection({
                    label: "Other Species 2",
                    path: ["otherSpecies2"],
                    abundance: morphAtpeSelect(),
                    code: morphText({ secondaryLabel: "Species" }),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "2.4 Freshwater Related Features",
            path: [],
            blocks: [
              subsection({
                title: "2.4a Ponds/Lagoons",
                path: ["data", "supratidal", "freshwaterFeatures"],
                blocks: [
                  banksSubsubsection({
                    label: "Disconnected From Estuary at Time of Survey",
                    path: ["disconnectedPond"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Connected to Estuary",
                    path: ["connectedPond"],
                    abundance: morphAtpeSelect(),
                    help: "By water-filled channel at time of survey",
                  }),
                ],
              }),
              subsection({
                title: "2.4b Side Channels",
                path: ["data", "supratidal", "freshwaterFeatures"],
                blocks: [
                  banksSubsubsection({
                    label: "Free Flowing Separate Channel",
                    path: ["freeFlowingSideChannel"],
                    abundance: morphAtpeSelect(),
                    help: "Including tributaries and fish passes",
                  }),
                  banksSubsubsection({
                    label: "Non-Flowing With Control Structure",
                    path: ["nonFlowingSideChannel"],
                    abundance: morphAtpeSelect(),
                    help: "e.g. gate/sluice/tidal flaps etc",
                  }),
                ],
              }),
              subsection({
                title: "2.4c Wetland",
                path: ["data", "supratidal", "freshwaterFeatures"],
                help: "Recorded by dominant vegetation type",
                blocks: [
                  banksSubsubsection({
                    label: "Short Non-Woody Vegetation",
                    path: ["wetlandShortNonWoody"],
                    abundance: morphAtpeSelect(),
                    help: "e.g. mosses, sedges",
                  }),
                  banksSubsubsection({
                    label: "Tall, Non-Woody Vegetation",
                    path: ["wetlandTallNonWoody"],
                    abundance: morphAtpeSelect(),
                    help: "e.g. reeds, rushes",
                  }),
                  banksSubsubsection({
                    label: "Shrubs and Trees",
                    path: ["wetlandShrubsAndTrees"],
                    abundance: morphAtpeSelect(),
                    help: "e.g. alder / willow carr",
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "2.5 Mid-Lower Estuarine Features",
            path: [],
            blocks: [
              subsection({
                title: "Supratidal Natural Features",
                path: ["data", "supratidal", "brackishFeatures"],
                blocks: [
                  banksSubsubsection({
                    label: "Upper Salt Marsh Vegetation Community",
                    path: ["vegetation"],
                    abundance: morphAtpeSelect(),
                    help: "See field guide for indicator species.",
                  }),
                  banksSubsubsection({
                    label: "Reedbeds",
                    path: ["reedbeds"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Algae",
                    path: ["algae"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Dry Scrapes",
                    path: ["dryScrapes"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Dunes",
                    path: ["dunes"],
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "2.6 Litter",
            path: ["data", "supratidal", "litter"],
            blocks: [
              banksSubsubsection({
                label: "Large Immobile Litter",
                path: ["largeImmobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. shopping trolleys, tyres, etc",
                }),
              }),
              banksSubsubsection({
                label: "Mobile / Floating Litter",
                path: ["floatingMobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. bottles, polystyrene blocks, etc",
                }),
              }),
              banksSubsubsection({
                label: "Mobile / Sinking Litter",
                path: ["sinkingMobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. plastic bags, wet wipes, etc",
                }),
              }),
              banksSubsubsection({
                label: "Small Fragments / Visible Particles *",
                path: ["smallFragments"],
                abundance: morphAtpeSelect({
                  help: "e.g. polystyrene balls, etc",
                }),
              }),
              banksSubsubsection({
                label:
                  "* Fragments or Particles Present as a Continuous Drape or Patchy Deposits?",
                path: ["smallFragmentDistribution"],
                abundance: morphSelect({
                  source: MrsMorphEstuariesSmallFragmentDistributionEnum,
                }),
              }),
              banksSubsubsection({
                label: "Other Litter",
                path: ["other"],
                code: morphText({
                  secondaryLabel: "Type of Litter",
                  required: false,
                }),
                abundance: morphAtpeSelect(),
              }),
            ],
          }),
          notesOnly("Notes"),
        ],
      }),
      page({
        title: "3 Vegetated Intertidal Zone",
        path: [],
        blocks: [
          section({
            title: "3.1 Materials",
            path: [],
            blocks: [
              subsection({
                title: "3.1a Natural Materials",
                path: ["data", "vegetatedIntertidal", "naturalMaterials"],
                blocks: [
                  banksSubsubsection({
                    label: "Dominant Sediment",
                    path: ["dominant"],
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      secondaryLabel: "Dominant Sediment",
                      source:
                        MrsMorphEstuariesVegetatedIntertidalSedimentSizeEnum,
                      showValues: true,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Subdominant Sediment",
                    path: ["subdominant"],
                    abundance: morphApeSelect(),
                    code: morphSelect({
                      secondaryLabel: "Subdominant Sediment",
                      source:
                        MrsMorphEstuariesVegetatedIntertidalSedimentSizeEnum,
                      showValues: true,
                    }),
                  }),
                ],
              }),
              subsection({
                title: "3.1b Artificial Materials / Reinforcement",
                path: ["data", "vegetatedIntertidal", "artificialMaterials"],
                blocks: [
                  banksSubsubsection({
                    label: "Vertical Reinforcement Extent",
                    path: ["reinforcementVerticalExtent"],
                    abundance: morphSelect({
                      source: MrsReinforcementVerticalExtentEnum,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Horizontal Reinforcement Extent",
                    path: ["reinforcementHorizontalExtent"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Dominant Reinforcement Type",
                    path: ["dominantReinforcement"],
                    code: morphSelect({
                      secondaryLabel: "Dominant Type",
                      source: MrsReinforcementTypeEnum,
                      showValues: true,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Subdominant Reinforcement Type",
                    path: ["subdominantReinforcement"],
                    code: morphSelect({
                      secondaryLabel: "Subdominant Type",
                      source: MrsReinforcementTypeEnum,
                      showValues: true,
                    }),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "3.2 Artificial Features",
            path: ["data", "vegetatedIntertidal", "artificialFeatures"],
            blocks: [
              banksSubsubsection({
                label: "Terrace Planform - Non Zig Zag",
                path: ["terraceNonZigZag"],
                abundance: morphAtpeSelect(),
              }),
              banksSubsubsection({
                label: "Terrace Planform - Zig Zag",
                path: ["terraceZigZag"],
                abundance: morphAtpeSelect(),
              }),
              banksSubsubsection({
                label: "Bolt-On Timbers",
                path: ["boltOnTimbers"],
                abundance: morphAtpeSelect(),
              }),
              banksSubsubsection({
                label: "Deflector / Groyne",
                path: ["deflectorOrGroyne"],
                abundance: morphSelect({
                  source: MrsMajorIntermediateMinorEnum,
                }),
              }),
              banksSubsubsection({
                label: "Jetty / Pontoon",
                path: ["jettyOrPontoon"],
                abundance: morphSelect({
                  source: MrsMajorIntermediateMinorEnum,
                }),
              }),
              banksSubsubsection({
                label: "Other Artificial Feature",
                path: ["other"],
                abundance: morphSelect({
                  source: MrsMajorIntermediateMinorEnum,
                }),
                code: morphText({
                  secondaryLabel: "Feature",
                  required: false,
                }),
              }),
            ],
          }),
          section({
            title: "3.3 Freshwater Features",
            path: [],
            blocks: [
              subsection({
                title: "Freshwater Inlets",
                path: ["data", "vegetatedIntertidal", "freshwaterFeatures"],
                blocks: [
                  banksSubsubsection({
                    label: "Tributary River/Stream Junctions/Confluences",
                    path: ["confluences"],
                    abundance: morphCount(),
                  }),
                  banksSubsubsection({
                    label: "Pipes/Outfalls",
                    path: ["outfalls"],
                    abundance: morphCount(),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "3.4 Margin Form & Creek Character",
            path: [],
            blocks: [
              subsection({
                title: "3.4a Margin Form & Creek Network",
                path: ["data", "vegetatedIntertidal", "marginAndCreek"],
                blocks: [
                  banksSubsubsection({
                    label: "Estuary Margin - Dominant Form",
                    path: ["estuaryMargin"],
                    code: morphSelect({
                      source: MrsMorphEstuariesMarginTypeEnum,
                      showValues: true,
                    }),
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Creek Drainage Network",
                    path: ["drainageNetwork"],
                    code: morphSelect({
                      source: MrsMorphEstuariesDrainageNetworkTypeEnum,
                      showValues: true,
                    }),
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Creek Character",
                    path: ["creek"],
                    code: morphSelect({
                      source: MrsMorphEstuariesCreekTypeEnum,
                      showValues: true,
                    }),
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
              subsection({
                title: "3.4b Salt Marsh Fragmentation",
                path: ["data", "vegetatedIntertidal", "marginAndCreek"],
                blocks: [
                  banksSubsubsection({
                    label: "Degree of Salt Marsh Fragmentation",
                    path: ["fragmentation"],
                    code: morphSelect({
                      source: MrsMorphEstuariesFragmentationEnum,
                      showValues: true,
                    }),
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
            ],
          }),
          intertidalVegetatedZone("middle", "3.5 Middle Marsh Zone"),
          intertidalVegetatedZone("lower", "3.6 Lower Marsh Zone"),
          intertidalVegetatedZone("pioneer", "3.7 Pioneer Marsh Zone"),
          section({
            title: "3.8 Estuarine Features",
            path: [],
            blocks: [
              subsection({
                title: "3.8a Intertidal (Vegetated) Zone Natural Features",
                path: ["data", "vegetatedIntertidal", "naturalFeatures"],
                blocks: [
                  banksSubsubsection({
                    label: "Salt Pans",
                    path: ["saltPans"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Pockets of Natural Gravels, Shells, or Sands",
                    path: ["pocketsOfNaturalGravelsShellsOrSands"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Natural Large Wood",
                    path: ["naturalLargeWood"],
                    help: "> 1m long, > 10cm diameter",
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesSaltMarshLocationEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                  banksSubsubsection({
                    label: "Discrete Organic Accumulation",
                    path: ["discreteOrganicAccumulation"],
                    help: "e.g. leaves, twigs",
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesSaltMarshLocationEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                  banksSubsubsection({
                    label: "Rotational Slumps",
                    path: ["rotationalSlumps"],
                    help: "Specify if middle/lower/pioneer in notes.",
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesFrontBackCreekMarginEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                  banksSubsubsection({
                    label: "Toppling Features",
                    path: ["topplingFeatures"],
                    help: "Specify if middle/lower/pioneer in notes.",
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesFrontBackCreekMarginEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                  banksSubsubsection({
                    label: "Nest Holes / Burrows",
                    path: ["nestHolesOrBurrows"],
                    help: "Specify if middle/lower/pioneer in notes.",
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesFrontBackCreekMarginEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                  banksSubsubsection({
                    label: "Stable Cliff (> 0.5m)",
                    path: ["stableCliff"],
                    help: "Specify if middle/lower/pioneer in notes.",
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesFrontBackCreekMarginEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                  banksSubsubsection({
                    label: "Eroding Cliff (> 0.5m)",
                    path: ["erodingCliff"],
                    help: "Specify if middle/lower/pioneer in notes.",
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesFrontBackCreekMarginEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                ],
              }),
              subsection({
                title: "3.8b Pressures on Salt Marshes",
                path: ["data", "vegetatedIntertidal", "pressures"],
                blocks: [
                  banksSubsubsection({
                    label: "Trampling / Poaching / Crushing",
                    path: ["tramplingOrPoachingOrCrushing"],
                    help: "by people / livestock / machinery etc",
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesSaltMarshLocationEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                  banksSubsubsection({
                    label: "Scour Around Vessels",
                    path: ["scourAroundVessels"],
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesSaltMarshLocationEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                  banksSubsubsection({
                    label: "Scour Around Artificial Structures / Pillars",
                    path: ["scourAroundStructures"],
                    abundance: morphAtpeSelect(),
                    code: morphSelect({
                      source: MrsMorphEstuariesSaltMarshLocationEnum,
                      secondaryLabel: "Location",
                    }),
                    fieldOrder: "abundance-then-code",
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "3.9 Litter",
            path: ["data", "vegetatedIntertidal", "litter"],
            blocks: [
              banksSubsubsection({
                label: "Large Immobile Litter",
                path: ["largeImmobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. shopping trolleys, tyres, etc",
                }),
              }),
              banksSubsubsection({
                label: "Mobile / Floating Litter",
                path: ["floatingMobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. bottles, polystyrene blocks, etc",
                }),
              }),
              banksSubsubsection({
                label: "Mobile / Sinking Litter",
                path: ["sinkingMobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. plastic bags, wet wipes, etc",
                }),
              }),
              banksSubsubsection({
                label: "Small Fragments / Visible Particles *",
                path: ["smallFragments"],
                abundance: morphAtpeSelect({
                  help: "e.g. polystyrene balls, etc",
                }),
              }),
              banksSubsubsection({
                label:
                  "* Fragments or Particles Present as a Continuous Drape or Patchy Deposits?",
                path: ["smallFragmentDistribution"],
                abundance: morphSelect({
                  source: MrsMorphEstuariesSmallFragmentDistributionEnum,
                }),
              }),
              banksSubsubsection({
                label: "Other Litter",
                path: ["other"],
                code: morphText({
                  secondaryLabel: "Type of Litter",
                  required: false,
                }),
                abundance: morphAtpeSelect(),
              }),
            ],
          }),
          notesOnly("Notes"),
        ],
      }),
      page({
        title: "4a Unvegetated Intertidal (Mudflat) Zone",
        path: [],
        blocks: [
          section({
            title: "4.1 Materials",
            path: [],
            blocks: [
              subsection({
                title: "4.1a Natural Materials",
                path: ["data", "unvegetatedIntertidal", "naturalMaterials"],
                blocks: [
                  banksSubsubsection({
                    label: "Bedrock (BE)",
                    path: ["bedrock"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Boulder (BO)",
                    path: ["boulder"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Cobble (CO)",
                    path: ["cobble"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Gravel/Pebble (GP)",
                    path: ["gravelPebble"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Shelly Material (SM)",
                    path: ["shellyMaterial"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Sand (SA)",
                    path: ["sand"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Fine Sediments (FS)",
                    path: ["fineSediments"],
                    abundance: morphAtpeSelect(),
                    help: "e.g. silt & clay",
                  }),
                  banksSubsubsection({
                    label: "Fluid Mud (FM)",
                    path: ["fluidMud"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Organic (OR)",
                    path: ["organic"],
                    help: "Leaves, twigs etc. Not fully decomposed.",
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
              subsection({
                title: "4.1b Artificial Materials / Reinforcement",
                path: ["data", "unvegetatedIntertidal", "artificialMaterials"],
                blocks: [
                  banksSubsubsection({
                    label: "Vertical Reinforcement Extent",
                    path: ["reinforcementVerticalExtent"],
                    abundance: morphSelect({
                      source: MrsReinforcementVerticalExtentEnum,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Horizontal Reinforcement Extent",
                    path: ["reinforcementHorizontalExtent"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Dominant Reinforcement Type",
                    path: ["dominantReinforcement"],
                    code: morphSelect({
                      secondaryLabel: "Dominant Type",
                      source: MrsReinforcementTypeEnum,
                      showValues: true,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Subdominant Reinforcement Type",
                    path: ["subdominantReinforcement"],
                    code: morphSelect({
                      secondaryLabel: "Subdominant Type",
                      source: MrsReinforcementTypeEnum,
                      showValues: true,
                    }),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "4.2 Features",
            path: [],
            blocks: [
              subsection({
                title: "4.2a Natural Physical & Vegetation Features",
                path: ["data", "unvegetatedIntertidal", "features"],
                blocks: [
                  banksSubsubsection({
                    label: "Drainage Runnels",
                    path: ["drainageRunnels"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Biogenic Reef",
                    path: ["biogenicReef"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Natural Large Wood",
                    path: ["naturalLargeWood"],
                    abundance: morphAtpeSelect(),
                    help: "Pieces >1m long, >10cm diameter",
                  }),
                  banksSubsubsection({
                    label: "Micro Algae / Biofilm",
                    path: ["microAlgae"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Macro Algae (Seaweeds)",
                    path: ["macroAlgae"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Discrete Organic Accumulation",
                    path: ["discreteOrganicAccumulation"],
                    abundance: morphAtpeSelect(),
                    help: "e.g. leaves, twigs",
                  }),
                  banksSubsubsection({
                    label: "Other Natural Feature",
                    path: ["other"],
                    abundance: morphAtpeSelect(),
                    code: morphText({
                      secondaryLabel: "Feature",
                      required: false,
                    }),
                  }),
                ],
              }),
              subsection({
                title: "4.2b Habitat Enhancements",
                path: ["data", "unvegetatedIntertidal", "features"],
                blocks: [
                  banksSubsubsection({
                    label: "Terraces - Non Zig Zag",
                    path: ["terraceNonZigZag"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Terraces - Zig Zag",
                    path: ["terraceZigZag"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Artificial Wood / Fixed Timbers",
                    path: ["artificialWood"],
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "4.3 Pressures",
            path: [],
            blocks: [
              subsection({
                title: "4.3a Artificial Structures",
                path: ["data", "unvegetatedIntertidal", "pressures"],
                blocks: [
                  banksSubsubsection({
                    label: "Jetty / Pontoon",
                    path: ["jettyOrPontoon"],
                    abundance: morphSelect({
                      source: MrsMajorIntermediateMinorEnum,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Groyne / Deflector",
                    path: ["deflectorOrGroyne"],
                    abundance: morphSelect({
                      source: MrsMajorIntermediateMinorEnum,
                    }),
                  }),
                  banksSubsubsection({
                    label: "Boat Mooring",
                    path: ["boatMooring"],
                    abundance: morphCount(),
                  }),
                  banksSubsubsection({
                    label: "Other Structure",
                    path: ["otherStructure"],
                    code: morphText({
                      secondaryLabel: "Structure",
                      required: false,
                    }),
                    abundance: morphSelect({
                      source: MrsMajorIntermediateMinorEnum,
                    }),
                  }),
                ],
              }),
              subsection({
                title: "4.3b Compaction or Erosion",
                path: ["data", "unvegetatedIntertidal", "pressures"],
                blocks: [
                  banksSubsubsection({
                    label: "Compaction",
                    path: ["compaction"],
                    help: "By vehicles / livestock / people",
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Intertidal Zone Excavation",
                    path: ["excavation"],
                    abundance: morphAtpeSelect(),
                    help: "e.g. channels cut or other earthworks",
                  }),
                  banksSubsubsection({
                    label:
                      "Local Patches of Scour / Erosion Around Artificial Structures",
                    path: ["scourAroundStructures"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label:
                      "Local Patches of Scour / Erosion Around or Due to Vessels",
                    path: ["scourAroundVessels"],
                    abundance: morphAtpeSelect(),
                  }),
                  banksSubsubsection({
                    label: "Marginal Backwater",
                    path: ["marginalBackwater"],
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
              subsection({
                title: "4.3c Wave wash",
                path: ["data", "unvegetatedIntertidal", "pressures"],
                blocks: [
                  banksSubsubsection({
                    label: "Visible Wave Wash",
                    path: ["waveWash"],
                    help: "Wakes appearing from vessels passing during survey.",
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
            ],
          }),
          subsection({
            title: "4.4 Litter",
            path: ["data", "unvegetatedIntertidal", "litter"],
            blocks: [
              banksSubsubsection({
                label: "Large Immobile Litter",
                path: ["largeImmobile"],
                abundance: morphAtpeSelect(),
                help: "e.g. shopping trolleys, tyres, etc",
              }),
              banksSubsubsection({
                label: "Mobile / Floating Litter",
                path: ["floatingMobile"],
                abundance: morphAtpeSelect(),
                help: "e.g. bottles, polystyrene blocks, etc",
              }),
              banksSubsubsection({
                label: "Mobile / Sinking Litter",
                path: ["sinkingMobile"],
                abundance: morphAtpeSelect(),
                help: "e.g. plastic bags, wet wipes, etc",
              }),
              banksSubsubsection({
                label: "Small Fragments / Visible Particles",
                path: ["smallFragments"],
                abundance: morphAtpeSelect(),
                help: "e.g. polystyrene balls, etc",
              }),
              banksSubsubsection({
                label: "Other Litter",
                path: ["other"],
                code: morphText({
                  secondaryLabel: "Type of Litter",
                  required: false,
                }),
                abundance: morphAtpeSelect(),
              }),
            ],
          }),
          notesOnly("Notes"),
        ],
      }),
      page({
        title: "4b Subtidal Zone",
        path: [],
        blocks: [
          section({
            title: "4.5 Materials",
            path: [],
            blocks: [
              subsection({
                title: "4.5a Channel Bed Sediments",
                path: ["data", "subtidal", "materials"],
                blocks: [
                  channelSubsubsection({
                    label: "Dominant Channel Bed Sediments",
                    path: ["dominant"],
                    code: morphSelect({
                      secondaryLabel: "Dominant Sediment Type",
                      source: MrsMorphEstuariesSubtidalSedimentSizeEnum,
                      showValues: true,
                    }),
                    abundance: morphAtpeSelect({
                      secondaryLabel: "Dominant Sediment Abundance",
                    }),
                  }),
                  channelSubsubsection({
                    label: "Subdominant Channel Bed Sediments",
                    path: ["subdominant"],
                    code: morphSelect({
                      secondaryLabel: "Subdominant Sediment Type",
                      source: MrsMorphEstuariesSubtidalSedimentSizeEnum,
                      showValues: true,
                    }),
                    abundance: morphApeSelect({
                      secondaryLabel: "Subdominant Sediment Abundance",
                    }),
                  }),
                ],
              }),
              subsection({
                title: "4.5b Channel Bed Reinforcement",
                path: ["data", "subtidal", "materials"],
                blocks: [
                  channelSubsubsection({
                    label: "Dominant Reinforcement",
                    path: ["dominantReinforcement"],
                    code: morphSelect({
                      secondaryLabel: "Dominant Reinforcement Type",
                      source: MrsReinforcementTypeEnum,
                    }),
                    abundance: morphAtpeSelect({
                      secondaryLabel: "Dominant Reinforcement Abundance",
                    }),
                  }),
                  channelSubsubsection({
                    label: "Subdominant Reinforcement",
                    path: ["subdominantReinforcement"],
                    code: morphSelect({
                      secondaryLabel: "Subdominant Reinforcement Type",
                      source: MrsReinforcementTypeEnum,
                    }),
                    abundance: morphApeSelect({
                      secondaryLabel: "Subdominant Reinforcement Abundance",
                    }),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "4.6 Water Surface Flow",
            path: [],
            blocks: [
              subsection({
                title: "4.6a Flow Types",
                path: ["data", "subtidal", "flowTypes"],
                blocks: [
                  channelSubsubsection({
                    label: "Free Fall (FF)",
                    path: ["freeFall"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Chute (CH)",
                    path: ["chute"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Broken Standing Wave (BW)",
                    path: ["brokenStandingWave"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Unbroken Standing Wave (UW)",
                    path: ["unbrokenStandingWave"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Upwelling (UP)",
                    path: ["upwelling"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Rippled (RP)",
                    path: ["rippled"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Smooth (SM)",
                    path: ["smooth"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "No Perceptible Flow (NP)",
                    path: ["noPerceptibleFlow"],
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "4.7 Physical Features",
            path: [],
            blocks: [
              subsection({
                title: "4.7a Natural Physical Features",
                path: ["data", "subtidal", "naturalFeatures"],
                blocks: [
                  channelSubsubsection({
                    label: "Exposed Bedrock",
                    path: ["bedrock"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Exposed Boulder (Unvegetated)",
                    path: ["unvegetatedBoulder"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Exposed Boulder (Vegetated)",
                    path: ["vegetatedBoulder"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Island",
                    path: ["island"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Mid-Channel Bar (Unvegetated)",
                    path: ["unvegetatedMidChannelBar"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Mid-Channel Bar (Vegetated)",
                    path: ["vegetatedMidChannelBar"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Riffle",
                    path: ["riffle"],
                    abundance: morphCount(),
                  }),
                  channelSubsubsection({
                    label: "Pool",
                    path: ["pool"],
                    abundance: morphCount(),
                  }),
                ],
              }),
              subsection({
                title: "4.7b Artificial Features & Pressures",
                path: ["data", "subtidal", "artificialFeatures"],
                blocks: [
                  channelSubsubsection({
                    label: "Weir",
                    path: ["weir"],
                    abundance: morphSelect({
                      source: MrsMajorIntermediateMinorEnum,
                    }),
                  }),
                  channelSubsubsection({
                    label: "Jetty / Pontoon",
                    path: ["jettyOrPontoon"],
                    abundance: morphSelect({
                      source: MrsMajorIntermediateMinorEnum,
                    }),
                  }),
                  channelSubsubsection({
                    label: "Deflector / Groyne",
                    path: ["deflectorOrGroyne"],
                    abundance: morphSelect({
                      source: MrsMajorIntermediateMinorEnum,
                    }),
                  }),
                  channelSubsubsection({
                    label: "Boat Mooring",
                    path: ["boatMooring"],
                    abundance: morphCount(),
                  }),
                  channelSubsubsection({
                    label: "Bridge Pillars",
                    path: ["bridgePillars"],
                    abundance: morphCount(),
                  }),
                  channelSubsubsection({
                    label: "Bridge Shadow",
                    path: ["bridgeShadow"],
                    abundance: morphSelect({
                      secondaryLabel: "Size",
                      source: MrsChannelCoverageEnum,
                    }),
                  }),
                  channelSubsubsection({
                    label: "Shading from Tower Blocks",
                    path: ["shadingFromTowerBlocks"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Other Structure",
                    path: ["otherStructure"],
                    code: morphText({
                      secondaryLabel: "Structure",
                      required: false,
                    }),
                    abundance: morphSelect({
                      secondaryLabel: "Size",
                      source: MrsMajorIntermediateMinorEnum,
                    }),
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "4.8 Vegetation",
            path: [],
            blocks: [
              subsection({
                title: "4.8a Vegetation Within Wetted Channel",
                path: ["data", "subtidal", "vegetation"],
                blocks: [
                  channelSubsubsection({
                    label: "Unvegetated (bare river bed)",
                    path: ["unvegetated"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Liverworts / Mosses / Lichens / Microflora",
                    path: ["liverwortsOrMossesOrLichens"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Emergent Broad Leaf",
                    path: ["emergentBroadLeaf"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Emergent Linear",
                    path: ["emergentLinear"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Floating Leaved (Rooted / Unrooted)",
                    path: ["floatingLeaved"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Submerged Broad Leaf",
                    path: ["submergedBroadLeaf"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Submerged Linear / Fine",
                    path: ["submergedLinear"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Filamentous Algae / Channel Choked",
                    path: ["filamentousAlgae"],
                    abundance: morphAtpeSelect(),
                  }),
                ],
              }),
              subsection({
                title: "4.8b Vegetation Interacting with Wetted Channel",
                path: ["data", "subtidal", "vegetation"],
                blocks: [
                  channelSubsubsection({
                    label: "Vegetation Shading Channel",
                    path: ["vegetationShadingChannel"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Submerged Tree Roots",
                    path: ["submergedTreeRoots"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Large Wood in Channel",
                    path: ["largeWoodInChannel"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Discrete Organic Accumulations",
                    path: ["discreteOrganicAccumulations"],
                    abundance: morphAtpeSelect(),
                  }),
                  channelSubsubsection({
                    label: "Large Wood Dam",
                    path: ["largeWoodDam"],
                    abundance: morphCount(),
                    help: "In channel and crosses entire channel.",
                  }),
                  channelSubsubsection({
                    label: "Fallen Trees",
                    path: ["fallenTrees"],
                    abundance: morphCount(),
                    help: "Only those with a significant portion in the channel.",
                  }),
                ],
              }),
              subsection({
                title: "4.8c Invasive Species",
                path: ["data", "subtidal", "vegetation"],
                blocks: [
                  channelSubsubsection({
                    label: "Invasive Species 1",
                    path: ["invasiveSpecies1"],
                    abundance: morphAtpeSelect(),
                    code: morphText({
                      secondaryLabel: "Species",
                      required: false,
                    }),
                  }),
                  channelSubsubsection({
                    label: "Invasive Species 2",
                    path: ["invasiveSpecies1"],
                    abundance: morphAtpeSelect(),
                    code: morphText({
                      secondaryLabel: "Species",
                      required: false,
                    }),
                  }),
                ],
              }),
            ],
          }),
          subsection({
            title: "4.9 Litter",
            path: ["data", "subtidal", "litter"],
            blocks: [
              channelSubsubsection({
                label: "Large Immobile Litter",
                path: ["largeImmobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. shopping trolleys, tyres, etc",
                }),
              }),
              channelSubsubsection({
                label: "Mobile / Floating Litter",
                path: ["floatingMobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. bottles, polystyrene blocks, etc",
                }),
              }),
              channelSubsubsection({
                label: "Mobile / Sinking Litter",
                path: ["sinkingMobile"],
                abundance: morphAtpeSelect({
                  help: "e.g. plastic bags, wet wipes, etc",
                }),
              }),
              channelSubsubsection({
                label: "Small Fragments / Visible Particles",
                path: ["smallFragments"],
                abundance: morphAtpeSelect({
                  help: "e.g. polystyrene balls, etc",
                }),
              }),
              channelSubsubsection({
                label: "Other Litter",
                path: ["other"],
                code: morphText({
                  secondaryLabel: "Type of Litter",
                  required: false,
                }),
                abundance: morphAtpeSelect(),
              }),
            ],
          }),
          notesOnly("Notes"),
        ],
      }),
    ],
  });
}

interface ChannelDimension {
  title?: string;
  path: Path;
  hasBanks: boolean;
  hasVisibility: boolean;
  visibilityLabel?: string;
  dimensionLabel: string;
  help?: string;
}

const intertidalVegetated: ChannelDimension = {
  title: "Intertidal Zone",
  path: ["data", "surveyDetails", "channelDimensions", "intertidalVegetated"],
  hasBanks: true,
  hasVisibility: false,
  dimensionLabel: "Vegetated Width",
};

const intertidalUnvegetated: ChannelDimension = {
  path: ["data", "surveyDetails", "channelDimensions", "intertidalUnvegetated"],
  hasBanks: true,
  hasVisibility: false,
  dimensionLabel: "Unvegetated Width",
};

const subtidalWaterWidth: ChannelDimension = {
  title: "Subtidal: Water Width at Low Tide",
  path: ["data", "surveyDetails", "channelDimensions", "subtidalWaterWidth"],
  hasBanks: false,
  hasVisibility: false,
  dimensionLabel: "Width at Low Tide",
};

const waterDepth: ChannelDimension = {
  title: "Water Depth (Upper Estuary Only)",
  path: ["data", "surveyDetails", "channelDimensions", "waterDepth"],
  hasBanks: false,
  hasVisibility: false,
  dimensionLabel: "Depth",
};

const supratidalUpperMarsh: ChannelDimension = {
  title: "Supratidal Upper Marsh",
  path: ["data", "surveyDetails", "channelDimensions", "supratidalUpperMarsh"],
  hasBanks: true,
  hasVisibility: true,
  visibilityLabel: "Upper Marsh Visibility",
  dimensionLabel: "Upper Marsh Width",
  help: "The area dominated by brackish marsh vegetation above and within 10m of the driftline.",
};

const intertidalVegetatedMiddleMarsh: ChannelDimension = {
  title: "Intertidal Vegetated: Middle Marsh",
  path: [
    "data",
    "surveyDetails",
    "channelDimensions",
    "intertidalVegetatedMiddleMarsh",
  ],
  hasBanks: true,
  hasVisibility: true,
  visibilityLabel: "Middle Marsh Visibility",
  dimensionLabel: "Middle Marsh Width",
};

const intertidalVegetatedLowerMarsh: ChannelDimension = {
  title: "Intertidal Vegetated: Lower Marsh",
  path: [
    "data",
    "surveyDetails",
    "channelDimensions",
    "intertidalVegetatedLowerMarsh",
  ],
  hasBanks: true,
  hasVisibility: true,
  visibilityLabel: "Lower Marsh Visible?",
  dimensionLabel: "Lower Marsh Width",
};

const intertidalVegetatedPioneerMarsh: ChannelDimension = {
  title: "Intertidal Vegetated: Pioneer Zone",
  path: [
    "data",
    "surveyDetails",
    "channelDimensions",
    "intertidalVegetatedPioneerMarsh",
  ],
  hasBanks: true,
  hasVisibility: true,
  visibilityLabel: "Poineer Zone Visibile?",
  dimensionLabel: "Poineer Zone Width",
};

function channelDimensions(props: ChannelDimension) {
  const {
    title = null,
    path,
    hasBanks = true,
    hasVisibility,
    visibilityLabel = "Visibility",
    dimensionLabel,
    help,
  } = props;

  function visibilityField(bankLabel?: string, bankField?: string) {
    return checkbox({
      label: createLabel(bankLabel, visibilityLabel),
      checkboxLabel: createLabel(bankLabel, "Visible?"),
      path: createPath(...path, bankField, "visible"),
    });
  }

  function widthField(bankLabel?: string, bankField?: string) {
    return numberField({
      label: createLabel(bankLabel, dimensionLabel),
      path: createPath(...path, bankField, "dimension"),
      units: "m",
      customRules: hasVisibility
        ? [
            forbiddenIf({
              level: "info",
              message:
                "You should only specify a value if the zone is visible.",
              otherPath: createPath(...path, bankField, "visible"),
              otherTest: value => value !== true,
            }),
          ]
        : [],
    });
  }

  return subsubsection({
    title,
    path: [],
    help,
    blocks: hasBanks
      ? hasVisibility
        ? [
            columns2(
              visibilityField("Left Bank", "leftBank"),
              visibilityField("Right Bank", "rightBank"),
              widthField("Left Bank", "leftBank"),
              widthField("Right Bank", "rightBank")
            ),
          ]
        : [
            columns2(
              widthField("Left Bank", "leftBank"),
              widthField("Right Bank", "rightBank")
            ),
          ]
      : hasVisibility
        ? [columns2(visibilityField(), widthField())]
        : [columns2(widthField())],
  });
}

function intertidalVegetatedZone(
  type: "middle" | "lower" | "pioneer",
  title: string
) {
  let path: string;
  let otherInvasiveSpeciesLabel: string;
  let includeTallReeds: boolean;

  switch (type) {
    case "middle":
      path = "middleMarsh";
      otherInvasiveSpeciesLabel = "Other Invasive Species - Middle Salt Marsh";
      includeTallReeds = true;
      break;
    case "lower":
      path = "lowerMarsh";
      otherInvasiveSpeciesLabel = "Other Invasive Species - Lower Salt Marsh";
      includeTallReeds = false;
      break;
    case "pioneer":
      path = "pioneerMarsh";
      otherInvasiveSpeciesLabel = "Other Invasive Species - Pioneer Salt Marsh";
      includeTallReeds = false;
      break;
    default:
      checkExhausted(type);
  }

  return section({
    title,
    path: ["data", "vegetatedIntertidal", path],
    blocks: [
      subsectionHeading("Marsh Vegetation"),
      banksSubsubsection({
        label: "Unvegetated (Bare Sediments / Rock)",
        path: ["unvegetated"],
        abundance: morphAtpeSelect(),
      }),
      banksSubsubsection({
        label: "Algae / Biofilm",
        path: ["algae"],
        abundance: morphAtpeSelect(),
      }),
      banksSubsubsection({
        label: "Salt Marsh Community",
        path: ["vegetation"],
        abundance: morphAtpeSelect(),
        help: "See field guide for indicator species.",
      }),
      ...(includeTallReeds
        ? [
            banksSubsubsection({
              label: "Tall Reeds",
              path: ["tallReeds"],
              abundance: morphAtpeSelect(),
            }),
          ]
        : []),
      subsectionHeading("Invasive Species"),
      banksSubsubsection({
        label: "Cord Grass (Spartina Anglica)",
        path: ["cordGrass"],
        abundance: morphAtpeSelect(),
      }),
      banksSubsubsection({
        label: otherInvasiveSpeciesLabel,
        path: ["otherInvasive"],
        code: morphText({ secondaryLabel: "Species", required: false }),
        abundance: morphAtpeSelect(),
      }),
    ],
  });
}

function photographsAndNotes(
  title: string,
  path: Path = ["data", "surveyDetails"]
) {
  return section({
    title,
    path: path,
    blocks: [
      attachmentField({
        label: "Photographs",
        path: ["photographs"],
        fullWidth: true,
        maxFiles: 4,
      }),
      textArea({
        label: "Notes",
        path: ["notes"],
        fullWidth: true,
        rows: 8,
        help: "(This field appears on all pages of the form.)",
      }),
    ],
  });
}

function notesOnly(title: string, path: Path = ["data", "surveyDetails"]) {
  return section({
    title,
    path: path,
    blocks: [
      textArea({
        label: "Notes",
        path: ["notes"],
        fullWidth: true,
        rows: 8,
        help: "(This field appears on all pages of the form.)",
      }),
    ],
  });
}

function createLabel(...parts: (string | undefined)[]): string {
  return parts.filter((part): part is string => part != null).join(" ");
}

function createPath(...parts: (string | number | undefined)[]): Path {
  return parts.filter((part): part is string => part != null);
}

export default mrsMorphEstuariesForm("MoRPh Estuaries");
