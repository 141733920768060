import {
  isProjectId,
  isTeamId,
  Project,
  ProjectId,
  ProjectRef,
  Team,
  TeamId,
  TeamRef,
  Workspace,
  WorkspaceId,
  WorkspaceRef,
} from "@cartographerio/types";
import { filterAndMap, raise } from "@cartographerio/util";
import { sortBy } from "lodash";
import { memoize1, memoize2 } from "./memoize";

export interface WorkspaceGraphProps {
  workspaces: Workspace | Workspace[];
  projects: Project[];
  teams: Team[];
}

export class WorkspaceGraph {
  private readonly workspaces: Workspace[];
  private readonly projects: Project[];
  private readonly teams: Team[];

  constructor(props: WorkspaceGraphProps) {
    this.workspaces = sortBy(arrayify(props.workspaces), ws => ws.alias);
    this.projects = sortBy(props.projects, p => p.alias);
    this.teams = sortBy(props.teams, t => t.alias);
  }

  allWorkspaces = (): Workspace[] => {
    return [...this.workspaces];
  };

  allProjects = (): Project[] => {
    return [...this.projects];
  };

  allTeams = (): Team[] => {
    return [...this.teams];
  };

  optFindWorkspaceById = memoize1((id: WorkspaceId): Workspace | null => {
    return this.workspaces.find(ws => ws.id === id) ?? null;
  });

  findWorkspaceById = (id: WorkspaceId): Workspace => {
    return (
      this.optFindWorkspaceById(id) ??
      raise<Workspace>(new Error(`Workspace not found: ${id}`))
    );
  };

  optFindProjectById = memoize1((id: ProjectId): Project | null => {
    return this.projects.find(p => p.id === id) ?? null;
  });

  findProjectById = (id: ProjectId): Project => {
    return (
      this.optFindProjectById(id) ??
      raise<Project>(new Error("Project not found"))
    );
  };

  optFindTeamById = memoize1((id: TeamId): Team | null => {
    return this.teams.find(t => t.id === id) ?? null;
  });

  findTeamById = (id: TeamId): Team => {
    return this.optFindTeamById(id) ?? raise<Team>(new Error("Team not found"));
  };

  optFindWorkspaceByProjectId = memoize1((id: ProjectId): Workspace | null => {
    const p = this.optFindProjectById(id);
    return p == null ? null : this.optFindWorkspaceById(p.workspaceId);
  });

  findWorkspaceByProjectId = (id: ProjectId): Workspace => {
    return (
      this.optFindWorkspaceByProjectId(id) ??
      raise<Workspace>(new Error(`Workspace not found for project: ${id}`))
    );
  };

  optFindWorkspaceByTeamId = memoize1((id: TeamId): Workspace | null => {
    const t = this.optFindTeamById(id);
    return t == null ? null : this.optFindWorkspaceById(t.workspaceId);
  });

  findWorkspaceByTeamId = (id: TeamId): Workspace => {
    return (
      this.optFindWorkspaceByTeamId(id) ??
      raise<Workspace>(new Error(`Workspace not found for team: ${id}`))
    );
  };

  findProjectsByWorkspaceId = memoize1((id: WorkspaceId): Project[] => {
    return this.projects.filter(p => p.workspaceId === id);
  });

  findProjectsByTeamId = memoize1((id: TeamId): Project[] => {
    const t = this.optFindTeamById(id);
    return t == null ? [] : filterAndMap(t.projectIds, this.optFindProjectById);
  });

  findTeamsByWorkspaceId = memoize1((id: WorkspaceId): Team[] => {
    return this.teams.filter(t => t.workspaceId === id);
  });

  findTeamsByProjectId = memoize1((id: ProjectId): Team[] => {
    return this.teams.filter(t => t.projectIds.includes(id));
  });

  optFindWorkspaceByRef = memoize1((ref: WorkspaceRef): Workspace | null => {
    return (
      this.workspaces.find(ws => ws.id === ref || ws.alias === ref) ?? null
    );
  });

  findWorkspaceByRef = (ref: WorkspaceRef): Workspace => {
    return (
      this.optFindWorkspaceByRef(ref) ??
      raise<Workspace>(new Error(`Workspace not found: ${ref}`))
    );
  };

  optFindProjectByRef = memoize2(
    (pref: ProjectRef, wref?: WorkspaceRef): Project | null => {
      const p = isProjectId(pref) ? this.optFindProjectById(pref) : null;

      if (p != null) {
        return p;
      } else {
        const ws = wref == null ? null : this.optFindWorkspaceByRef(wref);

        return ws == null
          ? null
          : this.findProjectsByWorkspaceId(ws.id).find(p => p.alias === pref) ??
              null;
      }
    }
  );

  findProjectByRef = (pref: ProjectRef, wref?: WorkspaceRef): Project => {
    return (
      this.optFindProjectByRef(pref, wref) ??
      raise<Project>(new Error("Project not found"))
    );
  };

  optFindTeamByRef = memoize2(
    (tref: TeamRef, wref?: WorkspaceRef): Team | null => {
      const t = isTeamId(tref) ? this.optFindTeamById(tref) : null;

      if (t != null) {
        return t;
      } else {
        const ws = wref == null ? null : this.optFindWorkspaceByRef(wref);

        return ws == null
          ? null
          : this.findTeamsByWorkspaceId(ws.id).find(
              t => t.id === tref || t.alias === tref
            ) ?? null;
      }
    }
  );

  findTeamByRef = (tref: TeamRef, wref?: WorkspaceRef): Team => {
    return (
      this.optFindTeamByRef(tref, wref) ??
      raise<Team>(new Error("Team not found"))
    );
  };

  findProjectsByWorkspaceRef = memoize1((wref: WorkspaceRef): Project[] => {
    const ws = this.optFindWorkspaceByRef(wref);
    return ws == null ? [] : this.projects.filter(t => t.workspaceId === ws.id);
  });

  findProjectsByTeamRef = memoize2(
    (tref: TeamRef, wref?: WorkspaceRef): Project[] => {
      const t = this.optFindTeamByRef(tref, wref);
      return t == null
        ? []
        : this.projects.filter(p => p.teamIds.includes(t.id));
    }
  );

  findTeamsByWorkspaceRef = memoize1((wref: WorkspaceRef): Team[] => {
    const ws = this.optFindWorkspaceByRef(wref);
    return ws == null ? [] : this.teams.filter(t => t.workspaceId === ws.id);
  });

  findTeamsByProjectRef = memoize2(
    (pref: ProjectRef, wref?: WorkspaceRef): Team[] => {
      const p = this.optFindProjectByRef(pref, wref);
      return p == null
        ? []
        : this.teams.filter(t => t.projectIds.includes(p.id));
    }
  );

  stringify = (): string => {
    const ans: string[] = [];

    for (const ws of this.workspaces) {
      ans.push(`- workspace ${ws.id} / ${ws.alias}`);

      for (const p of this.findProjectsByWorkspaceId(ws.id)) {
        ans.push(`  - project ${p.id} / ${p.alias}`);

        for (const t of this.findTeamsByProjectId(p.id)) {
          ans.push(`    - team ${t.id} / ${t.alias}`);
        }
      }
    }

    return ans.join("\n");
  };
}

function arrayify<A>(value: A | A[]): A[] {
  return Array.isArray(value) ? value : [value];
}
