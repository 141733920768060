// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopMapNeighbour =
  | "Pond"
  | "Stream"
  | "Lake"
  | "River"
  | "Wetland"
  | "Other";

const Pond: UclPitStopMapNeighbour = "Pond";
const Stream: UclPitStopMapNeighbour = "Stream";
const Lake: UclPitStopMapNeighbour = "Lake";
const River: UclPitStopMapNeighbour = "River";
const Wetland: UclPitStopMapNeighbour = "Wetland";
const Other: UclPitStopMapNeighbour = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopMapNeighbourEnum = {
  ...createEnum("UclPitStopMapNeighbour", [
    [Pond, "Pond"],
    [Stream, "Stream"],
    [Lake, "Lake"],
    [River, "River"],
    [Wetland, "Wetland"],
    [Other, "Other"],
  ]),
  Pond,
  Stream,
  Lake,
  River,
  Wetland,
  Other,
};

export default UclPitStopMapNeighbourEnum;
