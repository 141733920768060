import { tuple } from "@cartographerio/util";
import outdent from "outdent";

export type Feature<A extends string> = {
  id: A;
  title: string;
  label?: string;
  comingSoon?: boolean;
  commercialOnly?: boolean;
  description: string;
  hideIfUnsupported?: boolean;
};

export type FeatureCategory<A extends string, F extends readonly string[]> = {
  id: A;
  title: string;
  features: { [K in keyof F]: Feature<F[K]> };
};

function feature<const A extends string>(feature: Feature<A>): Feature<A> {
  return feature;
}

function featureCategory<
  const A extends string,
  const F extends readonly string[],
>(category: FeatureCategory<A, F>): FeatureCategory<A, F> {
  return category;
}

export const projectsAndUsers = featureCategory({
  id: "ProjectsAndUsers",
  title: "Projects and Users",
  features: [
    feature({
      id: "Teams",
      title: "Local Teams",
      label: "Local teams",
      description: outdent`
      Split large-scale national and multi-national projects into
      semi-autonomous teams. Grant admin rights to local
      and regional coordinators without giving them national-level control.
      `,
    }),
  ],
});

export type ProjectsAndUsersFeature = keyof typeof projectsAndUsers;

export const dataEntry = featureCategory({
  id: "DataEntry",
  title: "Data Entry",
  features: [
    feature({
      id: "UploadDataAndFiles",
      title: "Upload Data and Photos",
      label: "Upload data and photos",
      description: outdent`
      Upload as many surveys and photographs as you like without paying extra.
      We don't limit the amount of data you can enter on Cartographer.
      `,
    }),
    feature({
      id: "ExportAtAnyTime",
      title: "Export Data at Any Time",
      label: "Export data at any time",
      description: outdent`
      There's no such thing as vendor lock-in with Cartographer. We
      provide comprehensive downloads of all survey data you've recorded.
      You can download everything at any time in a variety of useful
      formats.
      `,
    }),
    feature({
      id: "WebSite",
      title: "Data Entry on the Web",
      label: "Data entry on the web",
      description: outdent`
      Enter data using our web site.
      `,
    }),
    feature({
      id: "MobileApp",
      title: "Data Entry on Mobile",
      label: "Data entry on mobile",
      description: outdent`
      Enter data in the field using our mobile app,
      including in areas with no network connection.
      `,
    }),
    feature({
      id: "OfflineModeOnMobile",
      title: "Offline Maps on Mobile",
      label: "Offline maps on mobile",
      description: outdent`
      Pre-download map data so you can see your location
      in the field without a network connection.
      `,
    }),
  ],
});

export type DataEntryFeature = keyof typeof dataEntry;

export const locationEntry = featureCategory({
  id: "LocationEntry",
  title: "Location Entry",
  features: [
    feature({
      id: "ClickOnMap",
      title: "Click on Map",
      label: "Click on map",
      description: outdent`
      The simplest way to record your location is to click on a map.
      We provide detailed terrain maps for the whole globe.
      `,
    }),
    feature({
      id: "ClickOnAerialPhoto",
      title: "Click on Aerial Photo",
      label: "Click on aerial photo",
      description: outdent`
      We also provide an aerial photograph view for those rural areas
      where standard maps don't quite give the detail you need.
      `,
    }),
    feature({
      id: "Geolocation",
      title: "Geolocation",
      description: outdent`
      Our web site and mobile app both support geolocation.
      Click (or tap) a button and Cartographer will zoom
      straight to your current location.
      `,
    }),
    feature({
      id: "GpsOrNgr",
      title: "GPS/NGR",
      description: outdent`
      Enter locations as GPS coordinates or 10-digit National Grid References (NGRs).
      `,
    }),
    feature({
      id: "What3words",
      title: "what3words",
      comingSoon: true,
      description: outdent`
      Enter locations using [what3words'](https://what3words.com)
      memorable three-word coordinate system.
      `,
    }),
    feature({
      id: "PreDefinedSites",
      title: "Select Pre-Defined Sites",
      label: "Select pre-defined sites",
      description: outdent`
      For more advanced use cases we also provide the ability to select
      locations from a set of existing sample sites. Either set up sites
      within Cartographer, or import locations from a reference map hosted
      on third party GIS service.
      `,
    }),
  ],
});

export type LocationEntryFeature = keyof typeof locationEntry;

export const maps = featureCategory({
  id: "Maps",
  title: "Maps",
  features: [
    feature({
      id: "MapWidgets",
      title: "Embed Maps on Your Web Site",
      label: "Embed maps on your web site",
      description: outdent`
      Embed a fully interactive Cartographer map on your web site
      to communicate with your audience.
      `,
    }),
    feature({
      id: "OfflineMapsOnMobile",
      title: "Offline Maps on Mobile",
      label: "Offline maps on mobile",
      description: outdent`
      Download maps in our mobile app for offline viewing without a network connection.
      `,
    }),
    feature({
      id: "WhiteLabelMaps",
      title: "Remove Branding from Maps",
      label: "Remove branding from maps",
      description: outdent`
      Remove the Cartographer logo from map widgets embedded on your web site.
      `,
    }),
  ],
});

export type MapsFeature = keyof typeof maps;

export const integrations = featureCategory({
  id: "Integrations",
  title: "Integrations",
  features: [
    feature({
      id: "ArcgisOnline",
      title: "ArcGIS Online",
      description: outdent`
      Set up live-links between Cartographer and ArcGIS Online.
      Visualise and analyse your data using ESRI tools such as StoryMaps.
      Cartographer keeps ArcGIS up-to-date automatically without you having to lift a finger.
      `,
    }),
    feature({
      id: "GoogleDrive",
      title: "Google Drive",
      comingSoon: true,
      description: outdent`
      Send newly recorded survey data to Google Drive,
      either as a backup or to share with colleagues.
      `,
    }),
    feature({
      id: "Mailchimp",
      title: "MailChimp",
      comingSoon: true,
      description: outdent`
      Keep the list of users in a project or team synchronised with MailChimp,
      so you can reach out to them at any time.
      `,
    }),
    feature({
      id: "Custom",
      title: "Custom Integrations",
      label: "Custom integrations",
      comingSoon: true,
      description: outdent`
      Integrate Cartographer with your own apps, web sites, and infrastructure.
      Chat to our development team to find out more!
      `,
    }),
  ],
});

export type IntegrationsFeature = keyof typeof integrations;

export const billingAndSupport = featureCategory({
  id: "BillingAndSupport",
  title: "Billing and Support",
  features: [
    feature({
      id: "SetupAssistance",
      title: "Assistance with Setup",
      label: "Assistance with setup",
      description: outdent`
      We roll out the red carpet for every new customer.
      Our team will help you get set up on Cartographer,
      taking all of your requirements into account
      and configuring everything just right before you go live with your project.
      `,
    }),
    feature({
      id: "StandardSupport",
      title: "Standard Support",
      label: "Standard support",
      description: outdent`
      Contact our support team via email during normal office hours.
      `,
      hideIfUnsupported: true,
    }),
    feature({
      id: "PrioritySupport",
      title: "Priority Support",
      label: "Priority support",
      description: outdent`
      Jump to the front of the support queue and add telephone support to the mix.
      `,
      hideIfUnsupported: true,
    }),
    feature({
      id: "PremiumSupport",
      title: "Premium Support",
      label: "Premium support",
      description: outdent`
      Jump to the front of the support queue and get additional help from our team,
      including custom integrations and SLAs.
      `,
      hideIfUnsupported: true,
    }),
    feature({
      id: "PayByInvoice",
      title: "Pay by Purchase Order / Invoice",
      label: "Pay by Purchase Order / invoice",
      description: outdent`
      Pay bills by invoice (as opposed to by credit/debit card).
      `,
    }),
  ],
});

export type BillingAndSupportFeature = keyof typeof billingAndSupport;

export const allFeatures = tuple(
  projectsAndUsers,
  dataEntry,
  locationEntry,
  maps,
  integrations,
  billingAndSupport
);

type DeriveFeatureCategorySupport<
  Cat extends FeatureCategory<string, readonly string[]>,
> =
  Cat extends FeatureCategory<infer CatId, infer FeatIds>
    ? { [S in CatId]: { [F in FeatIds[number]]: boolean } }
    : never;

type DeriveFeatureCategoriesSupport<
  Cats extends FeatureCategory<string, readonly string[]>[],
> = Cats extends readonly [FeatureCategory<infer A, infer F>, ...infer Rest]
  ? DeriveFeatureCategorySupport<FeatureCategory<A, F>> &
      (Rest extends FeatureCategory<string, readonly string[]>[]
        ? DeriveFeatureCategoriesSupport<Rest>
        : unknown)
  : unknown;

export type FeatureSupport = DeriveFeatureCategoriesSupport<typeof allFeatures>;
