import { Path } from "@cartographerio/topo-core";
import {
  isNumber,
  isOptional,
  isRecordOf,
  isString,
} from "@cartographerio/guard";
import { TopoExpr, topoExpr } from "../expr";
import { SelectValue } from "../type";
import { boundsRulePasses } from "./pass";
import { boundsRuleDefaultMessage } from "./text";
import {
  BoundsRule,
  CustomRule,
  RequiredRule,
  RuleLevel,
  TimestampBoundsRule,
} from "./type";
import { Timestamp } from "@cartographerio/types";

export function required(
  level: RuleLevel = "error",
  message?: string
): RequiredRule {
  return {
    type: "RequiredRule",
    level,
    message,
  };
}

export function rule(args: Omit<CustomRule, "type">): CustomRule {
  return { type: "CustomRule", ...args };
}

export function minValue(
  minValue: number,
  level: RuleLevel = "error",
  message?: string
): BoundsRule {
  return {
    type: "BoundsRule",
    minValue,
    level,
    message,
  };
}

export function maxValue(
  maxValue: number,
  level: RuleLevel = "error",
  message?: string
): BoundsRule {
  return {
    type: "BoundsRule",
    maxValue,
    level,
    message,
  };
}

export function bounds(
  minValue: number,
  maxValue: number,
  level: RuleLevel = "error",
  message?: string
): BoundsRule {
  return {
    type: "BoundsRule",
    minValue,
    maxValue,
    level,
    message,
  };
}

export function minTimestamp(
  minValue: Timestamp | "now",
  level: RuleLevel = "error",
  validateTime: boolean = true,
  message?: string
): TimestampBoundsRule {
  return {
    type: "TimestampBoundsRule",
    minValue,
    validateTime,
    level,
    message,
  };
}

export function maxTimestamp(
  maxValue: Timestamp | "now",
  level: RuleLevel = "error",
  validateTime: boolean = true,
  message?: string
): TimestampBoundsRule {
  return {
    type: "TimestampBoundsRule",
    maxValue,
    validateTime,
    level,
    message,
  };
}

export function timestampBounds(
  minValue: Timestamp | "now",
  maxValue: Timestamp | "now",
  level: RuleLevel = "error",
  validateTime: boolean = true,
  message?: string
): TimestampBoundsRule {
  return {
    type: "TimestampBoundsRule",
    minValue,
    maxValue,
    validateTime,
    level,
    message,
  };
}

export function totals(
  path: Path,
  minValue: number | undefined,
  maxValue: number | undefined,
  level: RuleLevel = "error"
): CustomRule {
  return rule({
    level,
    triggerWhen: topoExpr(env => {
      const value = env.get(path);

      return (
        isRecordOf(isString, isOptional(isNumber))(value) &&
        !boundsRulePasses(
          minValue,
          maxValue,
          Object.values(value).reduce((a, b) => (a ?? 0) + (b ?? 0), 0)
        )
      );
    }),
    message: boundsRuleDefaultMessage(level, minValue, maxValue, `The total`),
  });
}

export function customRule(opts: Omit<CustomRule, "type">): CustomRule {
  return { type: "CustomRule", ...opts };
}

export interface RequiredIfOpts {
  level?: RuleLevel;
  message: string;
  blankTest?: (value: unknown) => boolean;
  otherPath: Path;
  otherTest: (value: unknown) => boolean;
}

export function requiredIf(opts: RequiredIfOpts): CustomRule {
  const {
    level = "error",
    message,
    blankTest = value => value == null,
    otherPath,
    otherTest,
  } = opts;

  return {
    type: "CustomRule",
    level,
    message,
    triggerWhen: topoExpr(env =>
      blankTest(env.getFocused()) ? otherTest(env.get(otherPath)) : false
    ),
  };
}

export interface RequiredIfV2Opts {
  level?: RuleLevel;
  message: string;
  blankTest?: (value: unknown) => boolean;
  otherTest: TopoExpr<boolean>;
}

export function requiredIfV2(opts: RequiredIfV2Opts): CustomRule {
  const {
    level = "error",
    message,
    blankTest = value => value == null,
    otherTest,
  } = opts;

  return {
    type: "CustomRule",
    level,
    message,
    triggerWhen: topoExpr((env, run) =>
      blankTest(env.getFocused()) ? run(otherTest) : false
    ),
  };
}

export function forbiddenIf(opts: RequiredIfOpts): CustomRule {
  const {
    level = "error",
    message,
    blankTest = value => value == null,
    otherPath,
    otherTest,
  } = opts;

  return {
    type: "CustomRule",
    level,
    message,
    triggerWhen: topoExpr(env =>
      blankTest(env.getFocused()) ? false : otherTest(env.get(otherPath))
    ),
  };
}

export interface ForbiddenIfV2Opts {
  level?: RuleLevel;
  message: string;
  blankTest?: (value: unknown) => boolean;
  otherTest: TopoExpr<boolean>;
}

export function forbiddenIfV2(opts: ForbiddenIfV2Opts): CustomRule {
  const {
    level = "error",
    message,
    blankTest = value => value == null,
    otherTest,
  } = opts;

  return {
    type: "CustomRule",
    level,
    message,
    triggerWhen: topoExpr((env, run) =>
      blankTest(env.getFocused()) ? false : run(otherTest)
    ),
  };
}

export interface RequiredIffOpts {
  level?: RuleLevel;
  requiredLevel?: RuleLevel;
  forbiddenLevel?: RuleLevel;
  requiredMessage: string;
  forbiddenMessage: string;
  enabled?: TopoExpr<boolean>;
  blankTest?: (value: unknown) => boolean;
  otherPath: Path;
  otherTest: (value: unknown) => boolean;
}

export function requiredIff(opts: RequiredIffOpts): CustomRule[] {
  const {
    level,
    requiredLevel = level,
    forbiddenLevel = level,
    requiredMessage,
    forbiddenMessage,
    enabled = topoExpr(_ => true),
    blankTest = value => value == null,
    otherPath,
    otherTest,
  } = opts;

  return [
    requiredIfV2({
      level: requiredLevel,
      message: requiredMessage,
      blankTest,
      otherTest: topoExpr((env, run) =>
        run(enabled)
          ? blankTest(env.getFocused())
            ? otherTest(env.get(otherPath))
            : false
          : false
      ),
    }),
    forbiddenIfV2({
      level: forbiddenLevel,
      message: forbiddenMessage,
      blankTest,
      otherTest: topoExpr((env, run) =>
        run(enabled)
          ? blankTest(env.getFocused())
            ? false
            : !otherTest(env.get(otherPath))
          : false
      ),
    }),
  ];
}

export interface MatchesRegexOpts {
  level?: RuleLevel;
  message: string;
  regex: RegExp;
}

export function matchesRegex(opts: MatchesRegexOpts): CustomRule {
  const { level = "error", message, regex } = opts;

  return {
    type: "CustomRule",
    level,
    message,
    triggerWhen: topoExpr(env => {
      const value = env.getFocused();
      return typeof value === "string" && !regex.test(value);
    }),
  };
}

export interface OneOfOpts<A extends SelectValue> {
  level?: RuleLevel;
  message?: string;
  options: A[];
}

export function oneOf<A extends SelectValue>(opts: OneOfOpts<A>): CustomRule {
  const {
    level = "error",
    message = "You must choose one of the options",
    options,
  } = opts;
  return {
    type: "CustomRule",
    level,
    message,
    triggerWhen: topoExpr(
      env => !(options as unknown[]).includes(env.getFocused() ?? null)
    ),
  };
}
