import { Message } from "@cartographerio/types";
import {
  BlockState,
  ContentState,
  FieldState,
  FormState,
  PageState,
} from "./reducer";
import { checkExhausted } from "@cartographerio/util";
import { isFieldState } from "./guard";
import { prefixMessage } from "@cartographerio/topo-core";

export function allFormMessages(formState: FormState): Message[] {
  const messages: Message[] = [];

  function handleField(state: FieldState): void {
    state.messages.forEach(msg =>
      messages.push(prefixMessage(state.absolutePath, msg))
    );
  }

  function handleContent(state: ContentState): void {
    switch (state.type) {
      case "DynamicGridState":
      case "DynamicValueState":
      case "DynamicImageState":
      case "TextState":
      case "HeadingState":
        break;
      case "GroupState":
        state.blockStates.forEach(handleBlock);
        break;
      case "RepeatState":
        state.childStates.forEach(handleBlock);
        break;
      case "CardState":
      case "FullWidthState":
      case "RequireRoleState":
      case "ScopeState":
      case "VisibilityState":
        handleBlock(state.childState);
        break;
      case "PrimarySurveyorFieldState":
      case "PrimaryTeamFieldState":
        state.messages.forEach(msg => messages.push(msg));
        break;
      default:
        checkExhausted(state);
    }
  }

  function handleBlock(state: BlockState): void {
    if (isFieldState(state)) {
      handleField(state);
    } else {
      handleContent(state);
    }
  }

  function handlePage(pageState: PageState): void {
    pageState.blockStates.forEach(handleBlock);
  }

  formState.pageStates.forEach(handlePage);

  return messages;
}
