/* eslint-disable @typescript-eslint/no-explicit-any */

import { isEqual } from "lodash";
import { Optional } from "./type";
import { NonEmptyArray } from "@cartographerio/util";

type Key = keyof any;

export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value != null;
}

export function hasKeyOfAnyType<K extends Key>(
  value: unknown,
  key: K
): value is { [k_ in K]: unknown } {
  return typeof value === "object" && value != null && key in value;
}

export function hasKey<K extends Key, V>(
  value: unknown,
  key: K,
  guard: (value: unknown) => value is V
): value is { [_ in K]: V } {
  return hasKeyOfAnyType(value, key) && guard(value[key]);
}

export function hasOptionalKey<K extends Key, V>(
  value: unknown,
  key: K,
  guard: (value: unknown) => value is V
): value is { [_ in K]: V } {
  return hasKeyOfAnyType(value, key)
    ? guard(value[key]) || isUndefined(value[key])
    : isObject(value);
}

export function hasTypeTag<T extends Key>(
  value: unknown,
  type: T
): value is { type: T } {
  return hasKeyOfAnyType(value, "type") && value.type === type;
}

export function isLabeled<T extends Key, A>(
  type: T,
  isA: (value: unknown) => value is A
): (value: unknown) => value is { type: T } & A {
  return (value: unknown): value is { type: T } & A => {
    return isObject(value) && hasTypeTag(value, type) && isA(value);
  };
}

export function isExactly<A>(target: A) {
  return (value: unknown): value is A => isEqual(value, target);
}

export function isUnknown(_value: unknown): _value is unknown {
  return true;
}

export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === "boolean";
}

export function isInteger(value: unknown): value is number {
  return typeof value === "number" && Number.isInteger(value);
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isUnion<A, B>(
  isA: (value: unknown) => value is A,
  isB: (value: unknown) => value is B
) {
  return function (value: unknown): value is A | B {
    return isA(value) || isB(value);
  };
}

export function isOneOf<A>(...values: A[]) {
  return function (value: unknown): value is A {
    return (values as unknown[]).includes(value);
  };
}

export function isNullable<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is A | null {
    return value === null || guard(value);
  };
}

export function isUndefinable<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is A | undefined {
    return value === undefined || guard(value);
  };
}

export function isOptional<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is Optional<A> {
    return value == null || guard(value);
  };
}

export function isNonNullable<A>(val: A | null | undefined): val is A {
  return val != null;
}

export function isArray(value: unknown): value is unknown[] {
  return Array.isArray(value);
}

export function isArrayOf<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is A[] {
    return Array.isArray(value) && value.every(guard);
  };
}

export function isNonEmptyArray(
  value: unknown
): value is NonEmptyArray<unknown> {
  return Array.isArray(value) && value.length >= 1;
}

export function isNonEmptyArrayOf<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is NonEmptyArray<A> {
    return Array.isArray(value) && value.length >= 1 && value.every(guard);
  };
}

export function isRecordOf<A extends string | number | symbol, B>(
  isA: (arg: any) => arg is A,
  isB: (arg: any) => arg is B
) {
  return function (value: any): value is Record<A, B> {
    return (
      typeof value === "object" &&
      !Array.isArray(value) &&
      value != null &&
      Object.keys(value).every(isA) &&
      Object.values(value).every(isB)
    );
  };
}

/* eslint-enable @typescript-eslint/no-explicit-any */
