import { unsafeTag } from "@cartographerio/util";
import { v4 as createUuid } from "uuid";
import {
  Email,
  InvitationCodeAlias,
  InvitationCodeId,
  InvitationId,
  MapId,
  MapLayerId,
  PlanId,
  ProjectAlias,
  ProjectId,
  QualificationAlias,
  QualificationId,
  ScreenName,
  Subdomain,
  SurveyId,
  SurveyModuleId,
  TeamAlias,
  TeamId,
  UnencryptedPassword,
  UserId,
  Uuid,
  WorkspaceAlias,
  WorkspaceId,
  WorkspaceRef,
} from "./types.generated";

export function randomUuid(): Uuid {
  return unsafeTag<"Uuid">(createUuid());
}

export function unsafeUuid(uuid: string): Uuid {
  return unsafeTag<"Uuid">(uuid);
}

export function unsafeEmail(address: string): Email {
  return unsafeTag<"Email">(address);
}

export function unsafeScreenName(uuid: string): ScreenName {
  return unsafeTag<"ScreenName">(uuid);
}

export function randomUserId(): UserId {
  return unsafeTag<"UserId">(createUuid());
}

export function unsafeUserId(uuid: string): UserId {
  return unsafeTag<"UserId">(uuid);
}

export function unsafeSurveyModuleId(name: string): SurveyModuleId {
  return unsafeTag<"SurveyModuleId">(name);
}

export function randomSurveyId(): SurveyId {
  return unsafeTag<"SurveyId">(createUuid());
}

export function unsafeSurveyId(uuid: string): SurveyId {
  return unsafeTag<"SurveyId">(uuid);
}

export function unsafeMapId(str: string): MapId {
  return unsafeTag<"MapId">(str);
}

export function unsafeMapLayerId(str: string): MapLayerId {
  return unsafeTag<"MapLayerId">(str);
}

export function randomWorkspaceId(): WorkspaceId {
  return unsafeTag<"WorkspaceId">(createUuid());
}

export function unsafeWorkspaceId(uuid: string): WorkspaceId {
  return unsafeTag<"WorkspaceId">(uuid);
}

/** @deprecated use unsafeWorkspaceAlias */
export function unsafeSubdomain(text: string): Subdomain {
  return unsafeWorkspaceAlias(text);
}

export function unsafeWorkspaceAlias(text: string): WorkspaceAlias {
  return unsafeTag<"WorkspaceAlias">(text);
}

export function unsafeWorkspaceRef(uuid: string): WorkspaceRef {
  return unsafeTag(uuid);
}

export function randomProjectId(): ProjectId {
  return unsafeTag<"ProjectId">(createUuid());
}

export function unsafeProjectId(uuid: string): ProjectId {
  return unsafeTag<"ProjectId">(uuid);
}

export function randomTeamId(): TeamId {
  return unsafeTag<"TeamId">(createUuid());
}

export function unsafeTeamId(uuid: string): TeamId {
  return unsafeTag<"TeamId">(uuid);
}

export function randomQualificationId(): QualificationId {
  return unsafeTag<"QualificationId">(createUuid());
}

export function unsafeQualificationId(uuid: string): QualificationId {
  return unsafeTag<"QualificationId">(uuid);
}

export function unsafeQualificationAlias(str: string): QualificationAlias {
  return unsafeTag<"QualificationAlias">(str);
}

export function unsafeProjectAlias(uuid: string): ProjectAlias {
  return unsafeTag<"ProjectAlias">(uuid);
}

export function unsafeTeamAlias(uuid: string): TeamAlias {
  return unsafeTag<"TeamAlias">(uuid);
}

export function unsafeUnencryptedPassword(text: string): UnencryptedPassword {
  return unsafeTag<"UnencryptedPassword">(text);
}

export function unsafeInvitationId(uuid: string): InvitationId {
  return unsafeTag<"InvitationId">(uuid);
}

export function randomInvitationId(): InvitationId {
  return unsafeTag<"InvitationId">(createUuid());
}

export function unsafeInvitationCodeId(uuid: string): InvitationCodeId {
  return unsafeTag<"InvitationCodeId">(uuid);
}

export function randomInvitationCodeId(): InvitationCodeId {
  return unsafeTag<"InvitationCodeId">(createUuid());
}

export function unsafeInvitationCodeAlias(str: string): InvitationCodeAlias {
  return unsafeTag(str);
}

export function unsafePlanId(str: string): PlanId {
  return unsafeTag(str);
}
