import {
  BillingInterval,
  CurrencyAmount,
  PricingScheme,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box } from "@chakra-ui/react";

import Spaced from "../components/Spaced";
import { formatAmount } from "./helpers";

export interface PriceLabelProps {
  pricingScheme: PricingScheme;
  billingInterval: BillingInterval;
}

export default function PriceLabel(props: PriceLabelProps) {
  const { pricingScheme, billingInterval } = props;

  switch (pricingScheme.type) {
    case "Free":
      return <FreePriceLabel />;
    case "PerUser":
      return (
        <QuantityBasedPriceLabel
          flatAmount={pricingScheme.flatAmount}
          unitAmount={pricingScheme.unitAmount}
          unitLabel="user"
          billingInterval={billingInterval}
        />
      );
    case "PerProject":
      return (
        <QuantityBasedPriceLabel
          flatAmount={pricingScheme.flatAmount}
          unitAmount={pricingScheme.unitAmount}
          unitLabel="project"
          billingInterval={billingInterval}
        />
      );
    default:
      return checkExhausted(pricingScheme);
  }
}

function FreePriceLabel() {
  return <BasePriceLabel label="Free" />;
}

interface QuantityBasedPriceLabelProps {
  flatAmount: CurrencyAmount;
  unitAmount: CurrencyAmount;
  unitLabel: string;
  billingInterval: BillingInterval;
}

function QuantityBasedPriceLabel(props: QuantityBasedPriceLabelProps) {
  const { flatAmount, unitAmount, unitLabel, billingInterval } = props;

  const intervalLabel = billingInterval === "Monthly" ? "month" : "year";

  if (flatAmount > 0) {
    return (
      <BasePriceLabel
        label={formatAmount(flatAmount, billingInterval)}
        units={`Per ${intervalLabel}`}
      />
    );
  } else {
    return (
      <BasePriceLabel
        label={formatAmount(unitAmount, billingInterval)}
        units={`Per ${unitLabel}/${intervalLabel}`}
      />
    );
  }
}

export function ContactUsPriceLabel() {
  return <BasePriceLabel label="Custom" units="Contact us for details" />;
}

interface BasePriceLabelProps {
  label: string;
  units?: string;
}

function BasePriceLabel(props: BasePriceLabelProps) {
  const { label, units = "" } = props;

  return (
    <Spaced spacing="1">
      <Box textAlign="center" fontSize="2xl" color="black">
        {label}
      </Box>
      <Box textAlign="center" fontSize="sm" color="gray.500">
        {units}&nbsp;
      </Box>
    </Spaced>
  );
}
