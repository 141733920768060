// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesVisibility = "Yes" | "No" | "Partial";

const Yes: MrsMorphEstuariesVisibility = "Yes";
const No: MrsMorphEstuariesVisibility = "No";
const Partial: MrsMorphEstuariesVisibility = "Partial";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesVisibilityEnum = {
  ...createEnum("MrsMorphEstuariesVisibility", [
    [Yes, "Yes"],
    [No, "No"],
    [Partial, "Partially"],
  ]),
  Yes,
  No,
  Partial,
};

export default MrsMorphEstuariesVisibilityEnum;
