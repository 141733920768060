import {
  topoExpr,
  attachmentField,
  form,
  grid,
  integerField,
  multiSelect,
  page,
  pointField,
  required,
  section,
  select,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import {
  Thames21ChannelSideEnum,
  Thames21VegetationOriginEnum,
  Thames21VegetationSiteEnum,
  Thames21VegetationStructureEnum,
  Thames21VegetationTypeEnum,
} from "@cartographerio/inventory-enums";

import { outdent } from "outdent";

export default form({
  title: "Vegetation",
  surveyorHelp:
    "If the survey has been completed by multiple surveyors, please enter additional names in the Notes field.",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                grid({
                  columns: 1,
                  blocks: [
                    select({
                      label: "Type of Site",
                      path: ["data", "typeOfSite"],
                      options: Thames21VegetationSiteEnum.entries,
                      defaultValue: "potential",
                    }),
                    textField({
                      label: "Collected on behalf of",
                      path: ["data", "collectedOnBehalfOf"],
                      help: outdent`If the survey was collected on behalf of an organization, enter its name here.`,
                    }),
                    timestampField({
                      label: "Date and Time",
                      path: ["data", "recorded"],
                      help: outdent`Date and time the survey data was collected in the field.`,
                      required: required(
                        "info",
                        "You should specify the survey date."
                      ),
                    }),
                  ],
                }),
                grid({
                  columns: 1,
                  blocks: [
                    pointField({
                      label: "Location",
                      region: "uk",
                      path: ["data", "location"],
                      help: outdent`Location of the survey site. Click on your survey location to give a 10-digit OS grid reference. Set the map mode to Satellite to zoom in further.`,
                      required: required(
                        "info",
                        "You must enter the location of the site"
                      ),
                    }),
                    textField({
                      label: "Location Name",
                      path: ["data", "locationName"],
                      help: outdent`Description of the location of the area being surveyed (e.g. "West Wood").`,
                      required: required(
                        "info",
                        "You should enter a name or describe the location."
                      ),
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Observations",
          path: ["data"],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                multiSelect({
                  label: "Vegetation Types",
                  path: ["vegetationTypes"],
                  options: Thames21VegetationTypeEnum.entries,
                  required: required(
                    "info",
                    "You should specify one or more vegetation types."
                  ),
                }),
                select({
                  label: "Side of Channel",
                  path: ["channelSide"],
                  options: Thames21ChannelSideEnum.entries,
                  required: required("info", "You should specify a side."),
                }),
                integerField({
                  label: "Length",
                  path: ["length"],
                  required: required("info", "You should specify a length."),
                  units: "m",
                }),
                integerField({
                  label: "Width into channel",
                  path: ["widthIntoChannel"],
                  required: required("info", "You should specify a width."),
                  units: "m",
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Potential vegetation site",
          path: ["data", "potential"],
          visible: topoExpr(env =>
            env
              .getAs(["data", "typeOfSite"], Thames21VegetationSiteEnum.isValue)
              .map(siteType => siteType === "potential")
              .getOrElse(() => false)
          ),
          blocks: [
            select({
              label: "Side of channel",
              path: ["artificialHabitat"],
              options: [
                { label: "Artificial", value: true },
                { label: "Natural", value: false },
              ],
              defaultValue: false,
            }),
            textArea({
              label: "Artificial habitat notes",
              path: ["artificialHabitatNotes"],
              rows: 8,
              required: required(
                "info",
                "You should record notes about the habitat."
              ),
            }),
          ],
        }),
        section({
          title: "Existing vegetation site",
          path: ["data", "existing"],
          visible: topoExpr(env =>
            env
              .getAs(["data", "typeOfSite"], Thames21VegetationSiteEnum.isValue)
              .map(siteType => siteType === "existing")
              .getOrElse(() => false)
          ),
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Vegetation structure",
                  path: ["vegetationStructure"],
                  options: Thames21VegetationStructureEnum.entries,
                  required: required("info", "You should specify a structure."),
                }),
                select({
                  label: "Vegetation origin",
                  path: ["vegetationOrigin"],
                  options: Thames21VegetationOriginEnum.entries,
                  required: required("info", "You should specify an origin."),
                }),
              ],
            }),
            textArea({
              label: "Plant species",
              path: ["plantSpecies"],
              rows: 8,
              required: required("info", "You should specify a species."),
            }),
            textArea({
              label: "Invasive plant species",
              path: ["invasivePlantSpecies"],
              rows: 8,
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Comments",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 8,
            }),
          ],
        }),
      ],
    }),
  ],
});
