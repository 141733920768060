import { FetchLogger, NoopFetchLogger } from "./logging";

export type HttpMethod =
  | "GET"
  | "POST"
  | "PUT"
  | "DELETE"
  | "HEAD"
  | "OPTIONS"
  | "PATCH"
  | "TRACE";

let _defaultHeaders: Record<string, string> = {};

export function defaultHeaders(): Record<string, string> {
  return _defaultHeaders;
}

export function setDefaultHeaders(headers: Record<string, string>): void {
  _defaultHeaders = headers;
}

let _fetchLogger: FetchLogger<unknown> = new NoopFetchLogger();

export function fetchLogger(): FetchLogger<unknown> {
  return _fetchLogger;
}

export function setFetchLogger<A>(logger: FetchLogger<A>): void {
  _fetchLogger = logger;
}
