// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21SlowFlowSurveyType = "NewFeature" | "ExistingFeature";

const NewFeature: Thames21SlowFlowSurveyType = "NewFeature";
const ExistingFeature: Thames21SlowFlowSurveyType = "ExistingFeature";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21SlowFlowSurveyTypeEnum = {
  ...createEnum("Thames21SlowFlowSurveyType", [
    [NewFeature, "A location suitable for a new NFM feature"],
    [ExistingFeature, "An existing NFM feature"],
  ]),
  NewFeature,
  ExistingFeature,
};

export default Thames21SlowFlowSurveyTypeEnum;
