/* eslint-disable @typescript-eslint/no-use-before-define */

import { Option, Result } from "@cartographerio/fp";
import { Guard, guardError } from "@cartographerio/guard";
import { filterAndMap } from "@cartographerio/util";
import lodash from "lodash";
import { IOEnvironment, defaultEnvironment } from "./env";
import { Mutex } from "./mutex";
import { unsafeRunAsPromise } from "./unsafeRunAsPromise";

export type IOLike<A> = A | IO<A>;
export type IOPromiseLike<A> = A | IO<A> | Promise<A>;
export type IOThunkLike<A> = IO<A> | (() => IOPromiseLike<A>);

type AnyIOTuple = readonly IO<unknown>[];

type UnpackIOTuple<A extends AnyIOTuple> = {
  [K in keyof A]: A[K] extends IO<infer T> ? T : A[K];
};

export type IORecord<A> = {
  [K in keyof A]: IO<A[K]>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyIORecord = IORecord<any>;

type UnpackIORecord<A extends AnyIORecord> = IO<{
  [K in keyof A]: A[K] extends IO<infer T> ? T : never;
}>;

interface AttemptArgs<A, B> {
  before?: IOThunkLike<unknown>;
  attempt: IOThunkLike<A>;
  recover?: (error: unknown) => IOPromiseLike<B>;
  cleanup?: IOThunkLike<unknown>;
}

/**
 * A value that asynchronously computes a result of type `A`.
 * Similar to a `Promise<A>` except that an `IO` doesn't start running
 * until you manually start it.
 *
 * An `IO` can be run by turning it into a `Promise`
 * using `io.unsafeRun()` or `unsafeRunAsPromise(io)`.
 *
 * The promise is either "resolved" as a value of type `A`
 * or "rejected" as an error of unknown type.
 *
 * For example:
 *
 * ```
 * import { IO } from "@cartographerio/io";
 *
 * const io: IO<number> = IO.fromValue(20)
 *   .chain(n => n + 1)
 *   .chain(n => n * 2);
 *
 * const promise: Promise<number> = io.unsafeRun();
 * ```
 *
 * `IO` has methods for doing useful things like
 * running tasks in sequence and parallel, sleeping for a period,
 * and interacting with Redux.
 *
 * `IO` is unfortunately not compatible with `async/await` syntax.
 * You have to manually call methods like `io.chain()`.
 *
 * @param A The type of value returned when you run this `IO`.
 */
export abstract class IO<A> {
  /** Chain an extra callback on the end of this `IO`.
   * Similar to `chain` except that the callback can only return an `IO`.
   */
  flatMap = <B>(func: (a: A) => IO<B>): IO<B> => {
    return new FlatMapIO<A, B>(this, func);
  };

  /** Chain an extra callback on the end of this `IO`.
   * Similar to `chain` except that the callback can only return a plain value.
   */
  map = <B>(func: (a: A) => B): IO<B> => {
    return this.flatMap(a => IO.pure(func(a)));
  };

  /** Adds a callback to the chain of operations preformed by this `IO`.
   * Similar to the `then` method of `Promise`.
   * The callback can return a plain value, a `Promise`, or another `IO`.
   *
   * Creates a new `IO` that, when run, does the following:
   *
   * - waits for this `IO` to resolve;
   * - passes the value through `func` to create a new `IO`;
   * - waits for that `IO` to resolve;
   * - resolves with the result.
   *
   * For example:
   *
   * ```
   * const url: string = ...;
   * const text: IO<string> = IO.fromValue(url)
   *   .chain(url => fetch(url))
   *   .chain(response => response.text())
   *   .chain(text => `The response was: ${text}`);
   * ```
   */
  chain = <B>(func: (a: A) => IOPromiseLike<B>): IO<B> => {
    return this.flatMap(a => IO.wrap(func, a));
  };

  /** Creates an IO that runs `this` and `that` IO in sequence.
   * Like `chain` except the "callback" isn't a function.
   * The resulting IO resolves with the result of `that`.
   */
  andThen = <B>(that: IO<B>): IO<B> => {
    return this.flatMap(_ => that);
  };

  /** Creates an IO that runs `this`, ignores the result,
   * and returns `value`.
   */
  as = <B>(value: B): IO<B> => {
    return this.flatMap(_ => IO.pure(value));
  };

  /** Ignore the result of this IO and resolve with `undefined` instead. */
  void = (): IO<void> => {
    return this.as(undefined);
  };

  /** Access the value computed by this `IO` without affecting the result.
   * Similar to `chain` except that the result of the vallback is ignored.
   * Useful for debugging. For example:
   *
   * ```
   * const myIO: IO<number> = IO.pure(20)
   *   .tap(n => console.log(`The number is ${n}`))
   *   .chain(n => n + 1)
   *   .tap(n => console.log(`The number is one higher now: ${n}`)));
   *   .chain(n => n * 2);
   * ```
   */
  tap = <B>(func: (a: A) => IOPromiseLike<B>): IO<A> => {
    return this.flatMap(a => IO.wrap(func, a).map(_ => a));
  };

  /** If this `IO` is rejected, "catch" the resulting error
   * and turn it back into a successful `IO`.
   * Similar to the `catch` method of `Promise`.
   */
  recover = <B>(func: (error: unknown) => IOPromiseLike<B>): IO<A | B> => {
    return new RecoverIO<A, B>(this, error => IO.wrap(func, error));
  };

  /** If this `IO` is rejected and the error matches `guard`,
   * "catch" the resulting error and turn it back into a successful `IO`.
   * Otherwise allow the rejection to continue unaffected.
   */
  partialRecover = <B, C>(
    guard: (error: unknown) => error is B,
    func: (b: B) => IOLike<C>
  ): IO<A | C> => {
    return this.recover(error =>
      guard(error) ? func(error) : new FailIO(error)
    );
  };

  attempt = (): IO<Result<unknown, A>> => {
    type R = Result<unknown, A>;
    return this.map<R>(a => Result.pass(a)).recover<R>(e => Result.fail(e));
  };

  partialAttempt = <E>(
    guard: (error: unknown) => error is E
  ): IO<Result<E, A>> => {
    type R = Result<E, A>;
    return this.map<R>(a => Result.pass(a)).partialRecover<E, R>(guard, e =>
      Result.fail(e)
    );
  };

  /** If this `IO` is rejected, transform the resulting error with `func`.
   */
  mapError = (func: (e: unknown) => unknown): IO<A> => {
    return this.recover(e => IO.fail(func(e)));
  };

  /** If this `IO` is rejected, access the resulting error without affecting the outcome.
   */
  tapError = (func: (e: unknown) => IOPromiseLike<unknown>): IO<A> => {
    return this.recover(e => IO.wrap(func, e).andThen(IO.fail(e)));
  };

  /** Run `func` after this `IO` resolves or is rejected.
   * Like a `finally` clause in a regular Javascript `try`.
   */
  cleanup = <B>(func: IOThunkLike<B>): IO<A> => {
    return new CleanupIO<A>(this, IO.wrap(func));
  };

  /** Sleep up to `millis` before returning the result of this IO. */
  sleepUpTo = (millis: number): IO<A> => {
    return IO.parWhen<[IO<A>, IO<void>]>(this, IO.sleep(millis))((a, _) => a);
  };

  /** Run this IO as a promise. */
  unsafeRun = <State, Action>(
    env: IOEnvironment<State, Action> = defaultEnvironment()
  ): Promise<A> => {
    return unsafeRunAsPromise(this, env);
  };

  /** Creates an `IO` that, when run, resolves immediately with the supplied `value`.
   * Alias for `IO.const()`.
   */
  static pure = <A>(value: A): IO<A> => {
    return new PureIO(value);
  };

  /** Creates an `IO` that, when run, resolves immediately with the supplied `value`.
   * Alias for `IO.pure()`.
   */
  static const = <A>(value: A): IO<A> => {
    return IO.pure(value);
  };

  /** Creates an `IO` that, when run, does nothing and resolves immediately with `undefined`.
   * Alias for `IO.void()`.
   */
  static noop = (): IO<void> => {
    return IO.pure(undefined);
  };

  /** Creates an `IO` that, when run, does nothing and resolves immediately with `undefined`.
   * Alias for `IO.noop()`.
   */
  static void = (): IO<void> => {
    return IO.pure(undefined);
  };

  /** Creates an `IO` that, when run, is rejected immediately with the supplied `error`.
   */
  static fail = <A>(error: unknown): IO<A> => {
    return new FailIO<A>(error);
  };

  /** Creates an `IO` that resolves or rejects based on the value or error in `result`.
   */
  static fromResult = <A>(result: Result<unknown, A>): IO<A> => {
    return result.fold<IO<A>>(
      error => IO.fail(error),
      value => IO.pure(value)
    );
  };

  /** Creates an `IO` that resolves if `opt` contains a value
   * and rejects with `error()` if it does not.
   */
  static fromOption = <A>(onError: () => unknown) => {
    return (opt: Option<A>): IO<A> => {
      return opt.fold<IO<A>>(
        () => IO.fail(onError()),
        value => IO.pure(value)
      );
    };
  };

  /** Creates an `IO` that, when run, calls `func` with two callbacks:
   *
   * - `resolve` the body of `func` can call this to resolve the IO;
   * - `reject` the body of `func` can call this to reject the IO.
   *
   * Similar to the constructor for `Promise`.
   *
   * For example:
   *
   * ```
   * const io: IO<string> = IO.callback((resolve, reject) => {
   *   if(Math.random() > 0.5) {
   *     resolve("It worked!");
   *   } else {
   *     reject(new Error("It didn't work!"));
   *   }
   * });
   * ```
   */
  static callback = <A>(func: CallbackFunc<A>): IO<A> => {
    return new CallbackIO<A>(func);
  };

  /**
   * Creates an `IO` that, when run, calls `func` with the supplied parameters.
   * Similar to `IO.fromPromise()`
   */
  static wrap<const A extends unknown[], R>(
    body: R | IOThunkLike<R> | ((...args: A) => IOPromiseLike<R>),
    ...args: A
  ): IO<R> {
    return body instanceof IO
      ? new DelayIO(() => body, [])
      : typeof body === "function"
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          new DelayIO(body as (...args: any[]) => R, args)
        : new PureIO(body);
  }

  /** Creates an `IO` that, when run, resolves or rejects immediately
   * with the result of the supplied `Promise`.
   * The `Promise` is wrapped in a function to prevent it starting
   * until the `IO` is run.
   */
  static fromPromise<A>(func: () => Promise<A>): IO<A> {
    return IO.wrap(func);
  }

  /** Creates an IO with `try/catch/finally` semantics. Example:
   *
   * ```
   * const io = IO.attempt({
   *   before: () => IO.blah(...),
   *   attempt: () => IO.blah(...),
   *   recover: (error: unknown) => IO.blah(...),
   *   cleanup: () => IO.blah(...),
   * });
   * ```
   */
  static attempt = <A, B>(args: AttemptArgs<A, B>): IO<A | B> => {
    const { before, attempt, recover, cleanup } = args;

    const attemptIO: IO<A> =
      before == null
        ? IO.wrap(attempt)
        : IO.wrap(before).andThen(IO.wrap(attempt));

    const recoverIO: IO<A | B> =
      recover == null ? attemptIO : attemptIO.recover(recover);

    if (cleanup == null) {
      return recoverIO;
    } else {
      return recoverIO.cleanup(cleanup);
    }
  };

  /**
   * Creates an IO that, when run, selects a value from Redux.
   * A reference to the Redux store must be passed as a parameter
   * to `unsafeRunAsPromise()` or `unsafeRunAsSaga()`.
   */
  static select = <State, A>(selector: (s: State) => A): IO<A> => {
    return new SelectIO<State, A>(selector);
  };

  /**
   * Creates an IO that, when run, runs an Redux action.
   * A reference to the Redux store must be passed as a parameter
   * to `unsafeRunAsPromise()` or `unsafeRunAsSaga()`.
   */
  static put = (action: unknown): IO<void> => {
    return new PutIO(action);
  };

  /**
   * Creates a "critical section" around `func`, using the supplied `mutex`
   * to ensure that only one call to `func` can be running at a time.
   * Subsequent calls are queued until previous calls are resolved/rejected.
   * Useful for preventing concurrent calls to API endpoints or databases.
   *
   * For example, the code below calls an API endpoint several times in parallel using `IO.all()`.
   * However, the call to `IO.limit()` ensures that only one request/response is in flight at a time.
   *
   * ```
   * const mutex = createMutex("example");
   * const io = IO.limit(mutex, () => fetch(url));
   *
   * unsafeRunAsPromise(IO.all(io, io, io, io, io));
   * ```
   */
  static limit = <A>(mutex: Mutex, body: IOThunkLike<A>): IO<A> => {
    return new LimitIO(mutex, IO.wrap(body));
  };

  /**
   * Checks to see if an IO is currently running on `mutex`.
   * If one isn't, runs `body` on `mutex` (the same as `IO.limit`).
   * If one is, runs `fallback` immediately instead.
   */
  static limitOr = <A>(
    mutex: Mutex,
    body: IOThunkLike<A>,
    fallback: IOThunkLike<A>
  ): IO<A> => {
    return new LimitOrIO(mutex, IO.wrap(body), IO.wrap(fallback));
  };

  /** Creates an IO that, when run, pauses for a number of `millis` and resolves with `undefined`. */
  static sleep = (millis: number): IO<void> => {
    return new SleepIO(millis);
  };

  /** Creates an `IO` that, when run, runs the supplied `IOs` in sequence
   * (one after the other) and passes the results to `func`.
   */
  static when<const A extends AnyIOTuple>(
    ...ios: A
  ): <R>(func: (...args: UnpackIOTuple<A>) => R) => IO<R> {
    return function <R>(func: (...args: UnpackIOTuple<A>) => R): IO<R> {
      return new SequenceIO<A, R>("Seq", ios, args => func(...args));
    };
  }

  /** Creates an `IO` that, when run, runs the supplied `IOs` in parallel
   * and passes the results to `func`.
   */
  static parWhen<const A extends AnyIOTuple>(
    ...ios: A
  ): <R>(func: (...args: UnpackIOTuple<A>) => R) => IO<R> {
    return function <R>(func: (...args: UnpackIOTuple<A>) => R): IO<R> {
      return new SequenceIO<A, R>("Par", ios, args => func(...args));
    };
  }

  /** Creates an `IO` that, when run, runs the supplied `IOs` in sequence
   * (one after the other) and resolves with a tuple of their results.
   */
  static tupled<const A extends AnyIOTuple>(ios: A): IO<UnpackIOTuple<A>> {
    return new SequenceIO("Seq", ios, args => args);
  }

  /** Creates an `IO` that, when run, runs the supplied `IOs` in parallel
   * and resolves with a tuple of their results.
   */
  static parTupled<const A extends AnyIOTuple>(ios: A): IO<UnpackIOTuple<A>> {
    return new SequenceIO("Par", ios, args => args);
  }

  /** The `Record` equivalent of `IO.tupled`. Creates an `IO` that, when run,
   * runs the `IOs` in the supplied record in field order (one after the other)
   * and resolves with a record of their results.
   */
  static record<const A extends AnyIORecord>(ios: A): UnpackIORecord<A> {
    return IO.sequence(
      Object.entries(ios).map(([name, io]) => io.map(ans => [name, ans]))
    ).map(Object.fromEntries);
  }

  /** The `Record` equivalent of `IO.parTupled`. Creates an `IO` that, when run,
   * runs the `IOs` in the supplied record in parallel
   * and resolves with a record of their results.
   */
  static parRecord<const A extends AnyIORecord>(ios: A): UnpackIORecord<A> {
    return IO.parallel(
      Object.entries(ios).map(([name, io]) => io.map(ans => [name, ans]))
    ).map(Object.fromEntries);
  }

  /** Creates an `IO` that, when run, runs the supplied array of `IOs` in sequence
   * (one after the other) and resolves with an array of their results.
   * Supports an arbitrarily sized array of `IOs`, all with the same result type.
   */
  static sequence = <A>(ios: Array<IO<A>>): IO<Array<A>> => {
    return new SequenceIO("Seq", ios, args => args);
  };

  /** Creates an `IO` that, when run, runs the supplied array of `IOs` in parallel
   * and resolves with an array of their results.
   * Supports an arbitrarily sized array of `IOs`, all with the same result type.
   */
  static parallel = <A>(ios: Array<IO<A>>): IO<Array<A>> => {
    return new SequenceIO("Par", ios, args => args);
  };

  /** Creates an `IO` that, when run, runs the supplied array of `IOs` in sequence
   * (one after the other) and ignores their results.
   * Supports an arbitrarily sized array of `IOs`.
   */
  static forEach = (ios: Array<IO<void>>): IO<void> => {
    return new SequenceIO("Seq", ios, () => undefined).void();
  };

  /** Creates an `IO` that, when run, runs the supplied array of `IOs` in parallel
   * and ignores their results.
   * Supports an arbitrarily sized array of `IOs`.
   */
  static parForEach = (ios: Array<IO<void>>): IO<void> => {
    return new SequenceIO("Par", ios, () => undefined).void();
  };

  // prettier-ignore
  static filter = <A>(arr: Array<A>, test: (a: A) => IO<boolean>): IO<Array<A>> => {
    return IO.sequence(arr.map(test)).chain(flags =>
      filterAndMap(lodash.zip(arr, flags), ([item, flag]) => (flag ? item : null))
    );
  }

  /** Creates a function that accepts an `Option` as a parameter and returns an IO.
   * If the option is empty, the `IO` rejects with the supplied `error`.
   * Otherwise it resolves with the value from the option.
   *
   * Useful for creating functions that fail when a value is not found. For example:
   *
   * ```
   * function findUser(username: string): IO<Option<User>> {
   *   // ... access a database or API ...
   * }
   *
   * function findUserOrFail(username: String): IO<User> {
   *   return findUser(username).chain(IO.orFail(userNotFoundError(username)));
   * }
   *
   * ```
   */
  static orFail = <E, A>(error: E) => {
    return function (opt: Option<A>): IO<A> {
      return opt.fold(
        () => IO.fail(error),
        a => IO.pure(a)
      );
    };
  };

  /** Like `IO.orFail` but accepts a plain nullable value instead of an `Option`. */
  static orFailNullable = <E, A>(error: E) => {
    return function (a: A | null | undefined): IO<A> {
      return a == null ? IO.fail(error) : IO.pure(a);
    };
  };

  /** Creates a function that accepts a `value`, checks it with a `guard` function,
   * and either returns the result or fails with a `guardError`.
   *
   * Useful for guarding against malformed data from a database or API. For example:
   *
   * ```
   * function findUser(username: string): IO<User> {
   *   return IO.fromPromise(() => fetch(`http://example.com/user/${username}`))
   *     .chain(IO.guard(isUser, "User"));
   * }
   * ```
   *
   * See the `@cartographerio/guard` package for useful guard functions.
   */
  static guard = <A>(guard: Guard<A>, hint?: string) => {
    return function (value: unknown): IO<A> {
      if (guard(value)) {
        return IO.pure(value);
      } else {
        return IO.fail(
          guardError(hint ?? guard.name ?? "<UnknownGuard>", guard, value)
        );
      }
    };
  };
}

export class PureIO<A> extends IO<A> {
  _value: A;

  constructor(value: A) {
    super();
    this._value = value;
  }
}

export class FailIO<A> extends IO<A> {
  _error: unknown;

  constructor(error: unknown) {
    super();
    this._error = error;
  }
}

export class DelayIO<A> extends IO<A> {
  _func: (...args: unknown[]) => IOPromiseLike<A>;
  _args: unknown[];

  constructor(func: (...args: unknown[]) => IOPromiseLike<A>, args: unknown[]) {
    super();
    this._func = func;
    this._args = args;
  }
}

export type CallbackFunc<A> = (
  resolve: (value?: IOLike<A>) => void,
  reject: (error?: unknown) => void
) => void;

export class CallbackIO<A> extends IO<A> {
  _func: CallbackFunc<A>;

  constructor(func: CallbackFunc<A>) {
    super();
    this._func = func;
  }
}

export class FlatMapIO<A, B> extends IO<B> {
  _first: IO<A>;
  _next: (a: A) => IO<B>;

  constructor(first: IO<A>, next: (a: A) => IO<B>) {
    super();
    this._first = first;
    this._next = next;
  }
}

export class RecoverIO<A, B> extends IO<A | B> {
  _body: IO<A>;
  _handler: (error: unknown) => IO<B>;

  constructor(io: IO<A>, handler: (error: unknown) => IO<B>) {
    super();
    this._body = io;
    this._handler = handler;
  }
}

export class CleanupIO<A> extends IO<A> {
  _body: IO<A>;
  _cleanup: IO<unknown>;

  constructor(body: IO<A>, cleanup: IO<unknown>) {
    super();
    this._body = body;
    this._cleanup = cleanup;
  }
}

export class SelectIO<S, A> extends IO<A> {
  _selector: (state: S) => A;

  constructor(selector: (state: S) => A) {
    super();
    this._selector = selector;
  }
}

export class PutIO<A> extends IO<void> {
  _action: A;

  constructor(action: A) {
    super();
    this._action = action;
  }
}

export class LimitIO<A> extends IO<A> {
  _mutex: Mutex;
  _body: IO<A>;

  constructor(mutex: Mutex, func: IO<A>) {
    super();
    this._mutex = mutex;
    this._body = func;
  }
}

export class LimitOrIO<A> extends IO<A> {
  _mutex: Mutex;
  _body: IO<A>;
  _fallback: IO<A>;

  constructor(mutex: Mutex, body: IO<A>, fallback: IO<A>) {
    super();
    this._mutex = mutex;
    this._body = body;
    this._fallback = fallback;
  }
}

export class SleepIO extends IO<void> {
  _millis: number;

  constructor(millis: number) {
    super();
    this._millis = millis;
  }
}

type Parallelism = "Seq" | "Par";

export class SequenceIO<const A extends AnyIOTuple, R> extends IO<R> {
  _parallelism: Parallelism;
  _params: A;
  _func: (args: UnpackIOTuple<A>) => R;

  constructor(
    parallelism: Parallelism,
    ios: A,
    func: (args: UnpackIOTuple<A>) => R
  ) {
    super();
    this._parallelism = parallelism;
    this._params = ios;
    this._func = func;
  }
}

/* eslint-enable @typescript-eslint/no-use-before-define */
