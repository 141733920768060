import { Option, Result } from "@cartographerio/fp";
import { isPoint, Point, safePointToNgr } from "@cartographerio/geometry";

import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isArrayOf,
  isBoolean,
  isInteger,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  Thames21ChannelSide,
  Thames21ChannelSideEnum,
  Thames21VegetationOrigin,
  Thames21VegetationOriginEnum,
  Thames21VegetationSite,
  Thames21VegetationSiteEnum,
  Thames21VegetationStructure,
  Thames21VegetationStructureEnum,
  Thames21VegetationType,
  Thames21VegetationTypeEnum,
} from "@cartographerio/inventory-enums";
import {
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  Timestamp,
} from "@cartographerio/types";

interface Existing {
  vegetationStructure?: Thames21VegetationStructure | null;
  vegetationOrigin?: Thames21VegetationOrigin | null;
  plantSpecies?: string | null;
  invasivePlantSpecies?: string | null;
}

function isExisting(existing: unknown): existing is Existing {
  return (
    isObject(existing) &&
    hasOptionalKey(
      existing,
      "vegetationStructure",
      isNullable(Thames21VegetationStructureEnum.isValue)
    ) &&
    hasOptionalKey(
      existing,
      "vegetationOrigin",
      isNullable(Thames21VegetationOriginEnum.isValue)
    ) &&
    hasOptionalKey(existing, "plantSpecies", isNullable(isString)) &&
    hasOptionalKey(existing, "invasivePlantSpecies", isNullable(isString))
  );
}

interface Potential {
  artificialHabitat: boolean;
  artificialHabitatNotes?: string | null;
}

function isPotential(potential: unknown): potential is Potential {
  return (
    isObject(potential) &&
    hasKey(potential, "artificialHabitat", isBoolean) &&
    hasOptionalKey(potential, "artificialHabitatNotes", isNullable(isString))
  );
}

interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  locationName?: string | null;
  collectedOnBehalfOf?: string | null;
  typeOfSite?: Thames21VegetationSite | null;
  channelSide?: Thames21ChannelSide | null;
  length?: number | null;
  widthIntoChannel?: number | null;
  vegetationTypes: Thames21VegetationType[];
  existing: Existing;
  potential: Potential;
  photographs: AttachmentFolder;
  comments?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "locationName", isNullable(isString)) &&
    hasOptionalKey(data, "collectedOnBehalfOf", isNullable(isString)) &&
    hasOptionalKey(
      data,
      "typeOfSite",
      isNullable(Thames21VegetationSiteEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "channelSide",
      isNullable(Thames21ChannelSideEnum.isValue)
    ) &&
    hasOptionalKey(data, "length", isNullable(isInteger)) &&
    hasOptionalKey(data, "widthIntoChannel", isNullable(isInteger)) &&
    hasKey(
      data,
      "vegetationTypes",
      isArrayOf(Thames21VegetationTypeEnum.isValue)
    ) &&
    hasKey(data, "existing", isExisting) &&
    hasKey(data, "potential", isPotential) &&
    hasKey(data, "photographs", isAttachmentFolder) &&
    hasOptionalKey(data, "comments", isNullable(isString))
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data.locationName ?? "Unknown site",
      Option.wrap(data.location)
        .map(safePointToNgr)
        .getOrElse(() => "Unknown NGR"),
    ].join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.recorded ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data?.location ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data);
}
