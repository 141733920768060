// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopSurfaceFeature =
  | "None"
  | "Duckweed"
  | "Algae"
  | "Litter"
  | "OilySheen";

const None: UclPitStopSurfaceFeature = "None";
const Duckweed: UclPitStopSurfaceFeature = "Duckweed";
const Algae: UclPitStopSurfaceFeature = "Algae";
const Litter: UclPitStopSurfaceFeature = "Litter";
const OilySheen: UclPitStopSurfaceFeature = "OilySheen";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopSurfaceFeatureEnum = {
  ...createEnum("UclPitStopSurfaceFeature", [
    [None, "None"],
    [Duckweed, "Full duckweed coverage"],
    [Algae, "Floating algae"],
    [Litter, "Litter / dumped waste"],
    [OilySheen, "Oily sheen"],
  ]),
  None,
  Duckweed,
  Algae,
  Litter,
  OilySheen,
};

export default UclPitStopSurfaceFeatureEnum;
