import { findAndMap } from "@cartographerio/util";
import {
  GlobalRoleName,
  ProjectRoleName,
  Role,
  TeamRoleName,
  WorkspaceRoleName,
} from "../types.generated";
import {
  globalRoleName,
  projectRoleName,
  roleProjectId,
  roleTeamId,
  roleWorkspaceId,
  teamRoleName,
  workspaceRoleName,
} from "./parse";
import { ProjectId, TeamId, WorkspaceId } from "../../core";

export function findGlobalRoleName(roles: Role[]): GlobalRoleName | null {
  return findAndMap(roles, globalRoleName);
}

export function findWorkspaceRoleName(
  roles: Role[],
  workspaceId: WorkspaceId
): WorkspaceRoleName | null {
  return findAndMap(roles, role =>
    roleWorkspaceId(role) === workspaceId ? workspaceRoleName(role) : null
  );
}

export function findProjectRoleName(
  roles: Role[],
  projectId: ProjectId
): ProjectRoleName | null {
  return findAndMap(roles, role =>
    roleProjectId(role) === projectId ? projectRoleName(role) : null
  );
}

export function findTeamRoleName(
  roles: Role[],
  teamId: TeamId
): TeamRoleName | null {
  return findAndMap(roles, role =>
    roleTeamId(role) === teamId ? teamRoleName(role) : null
  );
}
