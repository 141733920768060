import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { errorMessage } from "@cartographerio/topo-core";
import {
  ApiConfig,
  Credentials,
  Email,
  InvitationCodeChallenge,
  InvitationCodeSummary,
  isValidEmail,
  unsafeUnencryptedPassword,
  validationError,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { QueryClient } from "@tanstack/react-query";
import { omit } from "lodash";

import queries from "../../../queries";
import { signupRule } from "../../../schema/signup";
import { ErrorAlertFunc } from "../../components/Alert";
import { manageLoadingFlag } from "./helpers";
import { SigninDetails, SignupDetails, State } from "./state";

export interface LoadingAction {
  type: "Loading";
  loading: boolean;
}

export interface EditSigninAction {
  type: "EditSignin";
  signin: Partial<SigninDetails>;
}

export interface SigninSuccessAction {
  type: "SigninSuccessAction";
  credentials: Credentials;
  codeChallenge?: InvitationCodeChallenge | null;
}

export interface EditSignupAction {
  type: "EditSignup";
  signup: Partial<SignupDetails>;
}

export interface EditEmailAction {
  type: "EditEmail";
  email: Email;
}

export interface ReenterEmailAction {
  type: "ReenterEmail";
}

export interface NextStateAction {
  type: "NextState";
  userExists: boolean;
  needsEmailVerification: boolean;
  invitation?: InvitationCodeSummary;
}

export type Action =
  | LoadingAction
  | SigninSuccessAction
  | EditSigninAction
  | EditSignupAction
  | EditEmailAction
  | ReenterEmailAction
  | NextStateAction;

export function loadingAction(loading: boolean): Action {
  return { type: "Loading", loading };
}

export function signinSuccessAction(
  credentials: Credentials,
  codeChallenge?: InvitationCodeChallenge | null
): SigninSuccessAction {
  return { type: "SigninSuccessAction", credentials, codeChallenge };
}

export function editSigninAction(
  signin: Partial<SigninDetails>
): EditSigninAction {
  return { type: "EditSignin", signin };
}

export function editSignupAction(
  signup: Partial<SignupDetails>
): EditSignupAction {
  return { type: "EditSignup", signup };
}

export function editEmailAction(email: Email): EditEmailAction {
  return { type: "EditEmail", email };
}

export const reenterEmailAction: ReenterEmailAction = { type: "ReenterEmail" };

export function nextStateAction(
  userExists: boolean,
  invitation?: InvitationCodeSummary,
  needsEmailVerification: boolean = false
): NextStateAction {
  return { type: "NextState", userExists, invitation, needsEmailVerification };
}

export function submitAction(
  queryClient: QueryClient,
  apiConfig: ApiConfig,
  state: State,
  errorAlert: ErrorAlertFunc<IO<void>>
): IO<void> {
  return IO.put(editSigninAction({ reacceptTerms: false })).flatMap(() => {
    switch (state.type) {
      case "EnterEmail":
        if (isValidEmail(state.email)) {
          return manageLoadingFlag(
            endpoints.auth.v2.checkSigninEmail(apiConfig, state.email)
          )
            .flatMap(({ exists }) =>
              exists || state.signin.code == null
                ? IO.put(nextStateAction(exists))
                : endpoints.invitation.code.v3
                    .readSummary(apiConfig, state.signin.code)
                    .flatMap(invitation =>
                      IO.put(nextStateAction(false, invitation))
                    )
            )
            .recover(errorAlert);
        } else {
          return IO.put(
            editSigninAction({
              messages: [
                errorMessage(
                  state.email.length > 0
                    ? "Invalid email address."
                    : "Enter your email address.",
                  ["email"]
                ),
              ],
            })
          );
        }

      case "EnterPassword":
        return manageLoadingFlag(
          queries.auth.v2.signin(queryClient, apiConfig, {
            email: state.email,
            password:
              state.signin.password != null
                ? unsafeUnencryptedPassword(state.signin.password)
                : null,
            acceptTerms: state.signin.acceptTerms,
            invitationCode: state.signin.code,
          })
        )
          .flatMap(result =>
            IO.fromResult(
              result.mapError(messages =>
                validationError(
                  { email: state.email },
                  messages,
                  "Signin failed",
                  400
                )
              )
            )
          )
          .flatMap(response => {
            switch (response.type) {
              case "SigninSuccess":
                return IO.put(
                  signinSuccessAction(
                    response.credentials,
                    response.codeChallenge
                  )
                );

              case "AccountNotFound":
              case "InvalidEmail":
                return IO.put(
                  editSigninAction({
                    messages: [
                      errorMessage("Invalid email address.", ["email"]),
                    ],
                  })
                );

              case "PasswordRequired":
                return IO.put(
                  editSigninAction({
                    messages: [
                      errorMessage("You must provide a password", ["password"]),
                    ],
                  })
                );

              case "PasswordIncorrect":
                return IO.put(
                  editSigninAction({
                    messages: [
                      errorMessage("Incorrect password", ["password"]),
                    ],
                  })
                );

              case "TermsNotAccepted":
                return IO.put(
                  editSigninAction({
                    reacceptTerms: true,
                    messages: [
                      errorMessage(
                        "You haven't accepted the terms and conditions",
                        ["acceptTerms"]
                      ),
                    ],
                  })
                );

              default:
                return checkExhausted(response);
            }
          })
          .recover(errorAlert);

      case "EnterCode":
        return (
          state.signin.code == null
            ? IO.fail<void>("No code supplied")
            : manageLoadingFlag(
                endpoints.invitation.code.v3.readSummary(
                  apiConfig,
                  state.signin.code
                )
              )
                .recover(_err => null)
                .flatMap(invitation =>
                  invitation == null
                    ? IO.put(
                        editSigninAction({
                          messages: [errorMessage("Invalid code", ["code"])],
                        })
                      )
                    : IO.put(nextStateAction(false, invitation))
                )
        ).recover(errorAlert);

      case "Signup": {
        const { email } = state;
        return !isValidEmail(email)
          ? IO.noop()
          : IO.pure(
              signupRule({
                invitationCode: state.code.id,
                ...state.signup,
                email,
              })
            )
              .flatMap(messages =>
                messages.length > 0
                  ? IO.put(editSignupAction({ messages }))
                  : manageLoadingFlag(
                      queries.invitation.code.signup.v3.signup(
                        queryClient,
                        apiConfig,
                        {
                          invitationCode: state.code.id,
                          ...omit(state.signup, "password"),
                          email,
                        }
                      )
                    ).flatMap(response => {
                      switch (response.type) {
                        case "SignupSuccess":
                          return IO.put(
                            signinSuccessAction(response.credentials)
                          );

                        case "SignupChallengeCodeNotFound":
                          return IO.put(
                            editSignupAction({
                              messages: [errorMessage("Invitation not found")],
                            })
                          );

                        case "SignupChallengeCodeExpired":
                          return IO.put(
                            editSignupAction({
                              messages: [
                                errorMessage("Invitation has expired"),
                              ],
                            })
                          );

                        case "SignupChallengeCodeCanceled":
                          return IO.put(
                            editSignupAction({
                              messages: [
                                errorMessage("Invitation was canceled"),
                              ],
                            })
                          );

                        case "SignupChallengeLimitsReached":
                          return IO.put(
                            editSignupAction({
                              messages: [
                                errorMessage(
                                  "Workspace has no room for more users"
                                ),
                              ],
                            })
                          );

                        case "SignupChallengeValidationErrors":
                          return IO.put(
                            editSigninAction({
                              messages: response.errors,
                            })
                          );

                        case "SignupChallengeEmailVerificationRequired":
                          return IO.put(
                            nextStateAction(false, state.code, true)
                          );

                        default:
                          return checkExhausted(response);
                      }
                    })
              )
              .recover(errorAlert);
      }

      case "NeedsEmailVerification":
        return IO.noop();

      default:
        return checkExhausted(state);
    }
  });
}
