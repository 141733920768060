import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isQualification,
  isSearchResults,
  Qualification,
  QualificationRef,
  SearchResults,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs, optionalContentAs } from "../../response";
import { UrlParts } from "../../url";
import { PartialParams } from "../params";
import { QualificationSearchOptions } from "./common";

const basePath = "/qualification/v1";

export function search(
  apiParams: ApiParams,
  options: PartialParams<QualificationSearchOptions> = {}
): IO<SearchResults<Qualification>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(
      contentAs(
        "SearchResults<Qualification>",
        isSearchResults(isQualification)
      )
    );
}

export function searchUrl(
  options: PartialParams<QualificationSearchOptions> = {}
): UrlParts {
  return {
    path: basePath,
    query: { ...options },
  };
}

export function readOrFail(
  apiParams: ApiParams,
  ref: QualificationRef
): IO<Qualification> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(contentAs("Qualification", isQualification));
}

export function readOption(
  apiParams: ApiParams,
  ref: QualificationRef
): IO<Option<Qualification>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(optionalContentAs("Qualification", isQualification));
}
