import {
  form,
  grid,
  page,
  section,
  timestampField,
} from "@cartographerio/topo-form";
import {
  NeLakeRestorationPrioritiesBiologicalMeasureEnum,
  NeLakeRestorationPrioritiesChemicalMeasureEnum,
  NeLakeRestorationPrioritiesHydrologicalMeasureEnum,
  NeLakeRestorationPrioritiesPhysicalMeasureEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
import { neLakeSiteInfoSection } from "../NeLakeNaturalness/form";
import {
  restorationPrioritiesNotesSection,
  restorationPrioritiesPhotographsSection,
  restorationPrioritiesSelectionsSection,
} from "../NeRiverRestorationPriorities/form";

const lakeRestorationPrioritiesGuidanceUrl =
  "https://priorityhabitats.org/lake-restoration-priorities-form-guidance";

export default form({
  title: "Lake Restoration Priorities",
  surveyorLabel: "Proposer",
  pages: [
    page({
      title: null,
      path: [],
      help: outdent`
      For further information on completing this assessment,
      please see the [guidance document](${lakeRestorationPrioritiesGuidanceUrl})
      `,
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                }),
              ],
            }),
          ],
        }),
        neLakeSiteInfoSection(),
        restorationPrioritiesSelectionsSection({
          title: "Physical Naturalness",
          guidanceLink: lakeRestorationPrioritiesGuidanceUrl,
          relevancePath: ["data", "selections", "physical"],
          measuresPath: ["data", "selections", "physicalMeasures"],
          measuresEnum: NeLakeRestorationPrioritiesPhysicalMeasureEnum,
          otherMeasuresPath: ["data", "selections", "otherPhysicalMeasures"],
        }),
        restorationPrioritiesSelectionsSection({
          title: "Hydrological Naturalness",
          guidanceLink: lakeRestorationPrioritiesGuidanceUrl,
          relevancePath: ["data", "selections", "hydrological"],
          measuresPath: ["data", "selections", "hydrologicalMeasures"],
          measuresEnum: NeLakeRestorationPrioritiesHydrologicalMeasureEnum,
          otherMeasuresPath: [
            "data",
            "selections",
            "otherHydrologicalMeasures",
          ],
        }),
        restorationPrioritiesSelectionsSection({
          title: "Chemical Naturalness",
          guidanceLink: lakeRestorationPrioritiesGuidanceUrl,
          relevancePath: ["data", "selections", "chemical"],
          measuresPath: ["data", "selections", "chemicalMeasures"],
          measuresEnum: NeLakeRestorationPrioritiesChemicalMeasureEnum,
          otherMeasuresPath: ["data", "selections", "otherChemicalMeasures"],
        }),
        restorationPrioritiesSelectionsSection({
          title: "Biological Naturalness",
          guidanceLink: lakeRestorationPrioritiesGuidanceUrl,
          relevancePath: ["data", "selections", "biological"],
          measuresPath: ["data", "selections", "biologicalMeasures"],
          measuresEnum: NeLakeRestorationPrioritiesBiologicalMeasureEnum,
          otherMeasuresPath: ["data", "selections", "otherBiologicalMeasures"],
        }),
        restorationPrioritiesPhotographsSection,
        restorationPrioritiesNotesSection,
      ],
    }),
  ],
});
