// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsLandUse =
  | "Re"
  | "Cm"
  | "In"
  | "Ic"
  | "Tr"
  | "Fp"
  | "Sw"
  | "Ld"
  | "Sy"
  | "Dr"
  | "Cr"
  | "Pa"
  | "Or"
  | "Fa"
  | "Al"
  | "Co"
  | "Dd"
  | "Ow"
  | "Op"
  | "Rg"
  | "Fm"
  | "Rc"
  | "Ce"
  | "He"
  | "Sc"
  | "La"
  | "Rv"
  | "Ca"
  | "Qu"
  | "Tb"
  | "Ma"
  | "Wl"
  | "Ot";

const Residential: UrsLandUse = "Re";
const Commercial: UrsLandUse = "Cm";
const Industrial: UrsLandUse = "In";
const IndustrialCommercial: UrsLandUse = "Ic";
const TransportInfrastructure: UrsLandUse = "Tr";
const Footpath: UrsLandUse = "Fp";
const SewageTreatmentWorks: UrsLandUse = "Sw";
const LandfillOrRefuseDeposits: UrsLandUse = "Ld";
const ScrapOrWasteRecyclingYard: UrsLandUse = "Sy";
const DerelictLand: UrsLandUse = "Dr";
const Cropland: UrsLandUse = "Cr";
const Pasture: UrsLandUse = "Pa";
const Orchard: UrsLandUse = "Or";
const IntensiveAnimalHusbandry: UrsLandUse = "Fa";
const Allotment: UrsLandUse = "Al";
const Coniferous: UrsLandUse = "Co";
const Deciduous: UrsLandUse = "Dd";
const OpenWoodland: UrsLandUse = "Ow";
const OpenParkland: UrsLandUse = "Op";
const RoughGrassland: UrsLandUse = "Rg";
const FormalParklandOrGardens: UrsLandUse = "Fm";
const RecreationalLand: UrsLandUse = "Rc";
const CemeteriesOrCrematoria: UrsLandUse = "Ce";
const Heathland: UrsLandUse = "He";
const Scrub: UrsLandUse = "Sc";
const LakeOrPond: UrsLandUse = "La";
const Reservoir: UrsLandUse = "Rv";
const Canal: UrsLandUse = "Ca";
const Quarry: UrsLandUse = "Qu";
const Tributary: UrsLandUse = "Tb";
const Marina: UrsLandUse = "Ma";
const Wetland: UrsLandUse = "Wl";
const Other: UrsLandUse = "Ot";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsLandUseEnum = {
  ...createEnum("UrsLandUse", [
    [Residential, "Residential"],
    [Commercial, "Commercial"],
    [Industrial, "Industrial"],
    [IndustrialCommercial, "Industrial/Commercial"],
    [TransportInfrastructure, "Transport Infrastructure (roads, railways)"],
    [Footpath, "Pedestrianised / footpath"],
    [SewageTreatmentWorks, "Sewage Treatment Works"],
    [LandfillOrRefuseDeposits, "Landfill/Refuse Deposits"],
    [ScrapOrWasteRecyclingYard, "Scrap/Waste Recycling Yard"],
    [DerelictLand, "Derelict Land"],
    [Cropland, "Cropland"],
    [Pasture, "Pasture"],
    [Orchard, "Orchard"],
    [IntensiveAnimalHusbandry, "Intensive Animal Husbandry"],
    [Allotment, "Allotment"],
    [Coniferous, "Coniferous Woodland"],
    [Deciduous, "Deciduous and Mixed Woodland"],
    [OpenWoodland, "Open Woodland"],
    [OpenParkland, "Open Parkland (Community Grass etc.)"],
    [RoughGrassland, "Rough Grassland or Herbs"],
    [FormalParklandOrGardens, "Formal Parkland / Gardens"],
    [RecreationalLand, "Recreational Land (Playing Fields)"],
    [CemeteriesOrCrematoria, "Cemeteries/Crematoria"],
    [Heathland, "Heathland or Moorland"],
    [Scrub, "Scrub"],
    [LakeOrPond, "Lake/Pond"],
    [Reservoir, "Reservoir"],
    [Canal, "Canal"],
    [Quarry, "Reclaimed (Flooded) Quarry"],
    [Tributary, "Tributary / Stream Channel"],
    [Marina, "Marina / Working Harbour"],
    [Wetland, "Wetland"],
    [Other, "Other (please specify)"],
  ]),
  Residential,
  Commercial,
  Industrial,
  IndustrialCommercial,
  TransportInfrastructure,
  Footpath,
  SewageTreatmentWorks,
  LandfillOrRefuseDeposits,
  ScrapOrWasteRecyclingYard,
  DerelictLand,
  Cropland,
  Pasture,
  Orchard,
  IntensiveAnimalHusbandry,
  Allotment,
  Coniferous,
  Deciduous,
  OpenWoodland,
  OpenParkland,
  RoughGrassland,
  FormalParklandOrGardens,
  RecreationalLand,
  CemeteriesOrCrematoria,
  Heathland,
  Scrub,
  LakeOrPond,
  Reservoir,
  Canal,
  Quarry,
  Tributary,
  Marina,
  Wetland,
  Other,
};

export default UrsLandUseEnum;
