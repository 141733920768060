// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsWildInvadersSpecies =
  | "HimalayanBalsam"
  | "GiantHogweed"
  | "JapaneseKnotweed"
  | "FloatingPennywort"
  | "OtherFlora"
  | "SignalCrayfish"
  | "KillerShrimp"
  | "QuaggaMussel"
  | "ChineseMittenCrab"
  | "OtherFauna";

const HimalayanBalsam: MrsWildInvadersSpecies = "HimalayanBalsam";
const GiantHogweed: MrsWildInvadersSpecies = "GiantHogweed";
const JapaneseKnotweed: MrsWildInvadersSpecies = "JapaneseKnotweed";
const FloatingPennywort: MrsWildInvadersSpecies = "FloatingPennywort";
const OtherFlora: MrsWildInvadersSpecies = "OtherFlora";
const SignalCrayfish: MrsWildInvadersSpecies = "SignalCrayfish";
const KillerShrimp: MrsWildInvadersSpecies = "KillerShrimp";
const QuaggaMussel: MrsWildInvadersSpecies = "QuaggaMussel";
const ChineseMittenCrab: MrsWildInvadersSpecies = "ChineseMittenCrab";
const OtherFauna: MrsWildInvadersSpecies = "OtherFauna";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsWildInvadersSpeciesEnum = {
  ...createEnum("MrsWildInvadersSpecies", [
    [HimalayanBalsam, "Flora - Himalayan Balsam"],
    [GiantHogweed, "Flora - Giant Hogweed"],
    [JapaneseKnotweed, "Flora - Japanese Knotweed"],
    [FloatingPennywort, "Flora - Floating Pennywort"],
    [OtherFlora, "Flora - Other"],
    [SignalCrayfish, "Fauna - Signal Crayfish"],
    [KillerShrimp, "Fauna - Killer Shrimp"],
    [QuaggaMussel, "Fauna - Quagga Mussel"],
    [ChineseMittenCrab, "Fauna - Chines Mitten Crab"],
    [OtherFauna, "Fauna - Other"],
  ]),
  HimalayanBalsam,
  GiantHogweed,
  JapaneseKnotweed,
  FloatingPennywort,
  OtherFlora,
  SignalCrayfish,
  KillerShrimp,
  QuaggaMussel,
  ChineseMittenCrab,
  OtherFauna,
};

export default MrsWildInvadersSpeciesEnum;
