import { isArrayOf } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Plan,
  PlanId,
  PlanOptions,
  WorkspaceRef,
  isPlan,
  isPlanOptions,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";
import { UrlParts } from "../../../url";

const basePath = "/billing/plan/v1";

export function list(apiParams: ApiParams): IO<Plan[]> {
  return fetch
    .get({ apiParams, url: listUrl() })
    .flatMap(contentAs("Plan[]", isArrayOf(isPlan)));
}

export function options(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<PlanOptions> {
  const url = { path: `${basePath}/options/${workspaceRef}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(contentAs("PlanOptions", isPlanOptions));
}

export function listUrl(): UrlParts {
  return { path: basePath };
}

export function read(apiParams: ApiParams, planId: PlanId): IO<Plan> {
  const url = { path: `${basePath}/${planId}` };
  return fetch.get({ apiParams, url }).flatMap(contentAs("Plan", isPlan));
}
