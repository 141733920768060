import {
  attachmentField,
  form,
  minValue,
  numberField,
  page,
  pointField,
  required,
  section,
  select,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import {
  StormPhosphatesLevelEnum,
  StormPhosphatesLevelTrendEnum,
} from "@cartographerio/inventory-enums";

export default form({
  title: "Phosphates",
  surveyorHelp: "Who took the sample?",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date and Time",
              path: ["data", "recorded"],
              required: required("info"),
              defaultValue: "now",
              help: "Date/time the sample was taken.",
            }),
            textField({
              label: "River/Stream Name",
              path: ["data", "riverName"],
              required: required("info"),
            }),
            pointField({
              label: "Location",
              path: ["data", "location"],
              required: required("info"),
              help: "Location the sample was taken.",
            }),
            select({
              label: "Level at Nearest Gauge",
              path: ["data", "level"],
              options: StormPhosphatesLevelEnum.entries,
              help: "Qualitative water level at the nearest level gauge.",
            }),
            select({
              label: "Level Trend at Nearest Gauge",
              path: ["data", "levelTrend"],
              options: StormPhosphatesLevelTrendEnum.entries,
              help: "Trend in the water level at the nearest level gauge.",
            }),
          ],
        }),
        section({
          title: "Measurements",
          path: [],
          blocks: [
            numberField({
              label: "Phosphates",
              path: ["data", "phosphate"],
              bounds: minValue(0, "error", "Must be positive."),
              units: "ppm",
            }),
          ],
        }),
        section({
          title: "Photographs",
          help: "Include photographs of the survey location and anything of note.",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 4,
              help: "Any other notes.",
            }),
          ],
        }),
      ],
    }),
  ],
});
