import {
  attachmentAttr,
  attributeGroup,
  lookupBucket,
  MapId,
  MapSchema,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { UrsNuisanceSpeciesEnum } from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { SurveyModuleId } from "@cartographerio/types";

export function ursMap(
  mapId: MapId,
  module: SurveyModuleId,
  title: string
): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,
    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId as string),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId as string),
          attribution: [],
        }),
        defaultAttribute: "ursShqi",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "Survey",
            attributes: [
              surveyAttr("surveyId", "Survey"),
              teamAttr("teamId", "Team", { module }),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
              attachmentAttr("photographs", "Photographs"),
            ],
          }),
          attributeGroup({
            label: "Classifications",
            attributes: [
              numberAttr({
                attributeId: "ursShqi",
                label: "Stretch Habitat Quality Index (SHQI)",
                buckets: [
                  rangeBucket(3, 5, marker.quality(0.9), "Very Good (3-4)"),
                  rangeBucket(5, 7, marker.quality(0.7), "Good (5-6)"),
                  rangeBucket(7, 10, marker.quality(0.5), "Average (7-9)"),
                  rangeBucket(
                    10,
                    12,
                    marker.quality(0.4),
                    "Below Average (10-11)"
                  ),
                  rangeBucket(12, 15, marker.quality(0.2), "Poor (12-14)"),
                  rangeBucket(15, 17, marker.quality(0.0), "Very Poor (15-16)"),
                ],
              }),
              stringAttr({
                attributeId: "ursMaterialsClass",
                label: "Materials class",
                buckets: [
                  lookupBucket(
                    "SNF",
                    marker.quality(1.0),
                    "SNF: Semi-natural fine"
                  ),
                  lookupBucket(
                    "SNC",
                    marker.quality(0.9),
                    "SNC: Semi-natural coarse"
                  ),
                  lookupBucket(
                    "SNM",
                    marker.quality(0.8),
                    "SNM: Semi-natural mixed"
                  ),
                  lookupBucket(
                    "LE",
                    marker.quality(0.5),
                    "LE: Lightly engineered"
                  ),
                  lookupBucket("EN", marker.quality(0.2), "EN: Engineered"),
                  lookupBucket(
                    "HE",
                    marker.quality(0.1),
                    "HE: Heavily engineered"
                  ),
                  lookupBucket(
                    "VHE",
                    marker.quality(0.0),
                    "VHE: Very heavily engineered"
                  ),
                ],
              }),
              stringAttr({
                attributeId: "ursPhysicalHabitatClass",
                label: "Physical habitat class",
                buckets: [
                  lookupBucket(
                    "SNAct",
                    marker.quality(1.0),
                    "SNAct: Semi-natural active"
                  ),
                  lookupBucket(
                    "SNMAct",
                    marker.quality(0.8),
                    "SNMAct: Semi-natural moderately active"
                  ),
                  lookupBucket(
                    "SNSt",
                    marker.quality(0.6),
                    "SNSt: Semi-natural stable"
                  ),
                  lookupBucket("Adj", marker.quality(0.6), "Adj: Adjusting"),
                  lookupBucket("St", marker.quality(0.4), "St: Stable"),
                  lookupBucket(
                    "UAdj",
                    marker.quality(0.2),
                    "UAdj: Uniform adjusting"
                  ),
                  lookupBucket(
                    "USt",
                    marker.quality(0.0),
                    "USt: Uniform stable"
                  ),
                ],
              }),
              stringAttr({
                attributeId: "ursVegetationClass",
                label: "Vegetation class",
                buckets: [
                  lookupBucket(
                    "LTLV",
                    marker.quality(0.0),
                    "LTLV: Low tree cover, low channel veg"
                  ),
                  lookupBucket(
                    "LTV",
                    marker.quality(0.0),
                    "LTV: Low tree cover, channel veg"
                  ),
                  lookupBucket(
                    "HTdisconn",
                    marker.quality(0.0),
                    "HTdisconn: High tree cover, disconnected from channel"
                  ),
                  lookupBucket(
                    "LTVdiv",
                    marker.quality(0.4),
                    "LTVdiv: Low tree cover, diverse channel veg"
                  ),
                  lookupBucket(
                    "MTLV",
                    marker.quality(0.4),
                    "MTLV: Moderate tree cover, low channel veg"
                  ),
                  lookupBucket(
                    "MTV",
                    marker.quality(0.4),
                    "MTV: Moderate tree cover, channel veg"
                  ),
                  lookupBucket(
                    "HTLV",
                    marker.quality(0.7),
                    "HTLV: High tree cover, some connectivity, low channel veg"
                  ),
                  lookupBucket(
                    "HTV",
                    marker.quality(0.7),
                    "HTV: High tree cover, some connectivity, channel veg"
                  ),
                  lookupBucket(
                    "MTVdiv",
                    marker.quality(1.0),
                    "MTVdiv: Moderate tree cover, diverse channel veg"
                  ),
                  lookupBucket(
                    "HTconn",
                    marker.quality(1.0),
                    "HTconn: High tree cover, well connected with channel"
                  ),
                ],
              }),
            ],
          }),
          // These four classifications removed on 26/09/2022 as AMG found their outputs to be erratic:
          // stringAttr("ursComplexityClass", "Complexity class", null, [
          //   { value: "VeryLow"         , color: crtMarkerColor.quality(0.0)  , label: "Very Low"                                                  }
          //   { value: "Low"             , color: crtMarkerColor.quality(0.3)  , label: "Low"                                                       }
          //   { value: "Average"         , color: crtMarkerColor.quality(0.5)  , label: "Average"                                                   }
          //   { value: "AboveAverage"    , color: crtMarkerColor.quality(0.7)  , label: "Above Average"                                             }
          //   { value: "High"            , color: crtMarkerColor.quality(1.0) , label: "High"                                                      }
          // ])
          // stringAttr("ursStabilityClass", "Stability class", null, [
          //   { value: "VeryStable"      , color: crtMarkerColor.quality(1.0) , label: "Very Stable"                                               }
          //   { value: "SlightlyDynamic" , color: crtMarkerColor.quality(0.7)  , label: "Slightly Dynamic"                                          }
          //   { value: "Dynamic"         , color: crtMarkerColor.quality(0.3)  , label: "Dynamic"                                                   }
          //   { value: "HighlyDynamic"   , color: crtMarkerColor.quality(0.0)  , label: "Highly Dynamic"                                            }
          // ])
          // stringAttr("ursChannelConditionClass", "Channel condition class", null, [
          //   { value: "Good"            , color: crtMarkerColor.quality(1.0) , label: "Good"                                                      }
          //   { value: "Average"         , color: crtMarkerColor.quality(0.7)  , label: "Average"                                                   }
          //   { value: "Poor"            , color: crtMarkerColor.quality(0.5)  , label: "Poor"                                                      }
          //   { value: "VeryPoor"        , color: crtMarkerColor.quality(0.3)  , label: "Very Poor"                                                 }
          //   { value: "ExtremelyPoor"   , color: crtMarkerColor.quality(0.0)  , label: "Extremely Poor"                                            }
          // ])
          // stringAttr("ursConnectivityClass", "Connectivity class", null, [
          //   { value: "VeryHigh"        , color: crtMarkerColor.quality(1.0) , label: "Very High"                                                 }
          //   { value: "High"            , color: crtMarkerColor.quality(0.7)  , label: "High"                                                      }
          //   { value: "Moderate"        , color: crtMarkerColor.quality(0.5)  , label: "Moderate"                                                  }
          //   { value: "Poor"            , color: crtMarkerColor.quality(0.3)  , label: "Poor"                                                      }
          //   { value: "ExtremelyPoor"   , color: crtMarkerColor.quality(0.0)  , label: "Extremely Poor"                                            }
          // ])
          attributeGroup({
            label: "Indices",
            attributes: [
              numberAttr({
                attributeId: "ursDomSub",
                label: "DomSub: Dominant channel substrate",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursSedcal",
                label: "Sedcal: Bed sediment calibre index",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursDomBkMat",
                label: "DomBkMat: Dominant bank material type",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursBankcal",
                label: "Bankcal: Bank sediment calibre index",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumBedSed",
                label:
                  "NumBedSed: Number of mineral bed sediment calibre classes",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursDomFlow",
                label: "DomFlow: Dominant flow type",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumFlow",
                label: "NumFlow: Number of flow types",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumFlowHab",
                label: "NumFlowHab: Number of flow habitats",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropPools",
                label: "PropPools: Proportion of pools",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropMarginalWater",
                label: "PropMarginalWater: Proportion of marginal deadwater",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropGlides",
                label: "PropGlides: Proportion of glides",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropRiffles",
                label: "PropRiffles: Proportion of riffles",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropRuns",
                label: "PropRuns: Proportion of runs",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropPondedReach",
                label: "PropPondedReach: Proportion of ponded water",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropRapid",
                label: "PropRapid: Proportion of cascades and rapids",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountHab",
                label: "CountHab: Count of in-channel habitats",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountVS",
                label: "CountVS: Count of vegetated side bars",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountUS",
                label: "CountUS: Count of unvegetated side bars",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountSS",
                label: "CountSS: Count of sand or silt deposits",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountMB",
                label:
                  "CountMB: Count of vegetated and unvegetated mid-channel bars",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountPB",
                label: "CountPB: Count of vegetated and unvegetated point bars",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumBarTypes",
                label: "NumBarTypes: Number of bar types",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursDomNatBk",
                label: "DomNatBk: Dominant natural bank profile type",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumNatBk",
                label: "NumNatBk: Number of natural bank profile types",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumNatBkHab",
                label: "NumNatBkHab: Number of natural bank habitats",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursDomArtBk",
                label: "DomArtBk: Dominant artificial bank profile type",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumArtBk",
                label: "NumArtBk: Number of artificial bank profile types",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropNatBk",
                label: "PropNatBk: Proportion natural bank profiles",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropNoBk",
                label: "PropNoBk: Proportion no bank protection",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropArtBk",
                label: "PropArtBk: Proportion artificial bank profiles",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursAveVeg",
                label: "AveVeg: Average channel vegetation cover",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumVeg",
                label: "NumVeg: Number of channel vegetation types",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursDomVeg",
                label: "DomVeg: Dominant channel vegetation type",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountTreeFeatures",
                label: "CountTreeFeatures: Count of tree features",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursComplexityFace",
                label: "ComplexityFace: Complexity bank face structure",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursComplexityTop",
                label: "ComplexityTop: Complexity bank top structure",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursComplexityTree",
                label: "ComplexityTree: Complexity tree cover",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumVegHab",
                label: "NumVegHab: Number of vegetation habitats",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursBankProt",
                label: "BankProt: Bank protection",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursDomBkMatPro",
                label: "DomBkMatPro: Dominant bank material protection type",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursDomBkPro",
                label: "DomBkPro: Dominant bank protection type",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumBkPro",
                label: "NumBkPro: Number of bank protection types",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropBio",
                label: "PropBio: Proportion biodegradable bank protection",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropOpenMatrix",
                label: "PropOpenMatrix: Proportion open matrix bank protection",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropSolid",
                label: "PropSolid: Proportion solid bank protection",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropImmBk",
                label: "PropImmBk: Proportion immobile bank materials",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropArtSub",
                label: "PropArtSub: Proportion artificial substrate",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursPropImmSub",
                label: "PropImmSub: Proportion immobile substrate",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursDisConnectivity",
                label:
                  "DisConnectivity: Extent of disruption of longitudinal continuity by in-channel structures",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumPollution",
                label: "NumPollution: Number of pollution types",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursExtentLitter",
                label: "ExtentLitter: Extent of trash and gross pollution",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumNuisance",
                label: "NumNuisance: Number of nuisance riparian species",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursExtentNuisance",
                label: "ExtentNuisance: Extent of nuisance riparian species",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNuisanceInvasion",
                label:
                  "NuisanceInvasion: Severity of invasion by nuisance plant species",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountInputPipes",
                label: "CountInputPipes: Number of input pipes",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountLeachPoints",
                label: "CountLeachPoints: Number of leach points",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursCountInputAndLeach",
                label: "CountInputAndLeach: Number input and leach points",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursTotalPollIndicators",
                label:
                  "TotalPollIndicators: Potential river pollution intensity",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursChannelCondition",
                label:
                  "ChannelCondition: Condition of channel in relation to pollution potential and nuisance species invasion",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursHeavyVeg",
                label: "HeavyVeg: Heavily vegetated banks and bars",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNoBkErosion",
                label: "NoBkErosion: Negligible bank erosion",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursExtMatureTrees",
                label: "ExtMatureTrees: Extensive mature trees along banks",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursStableChannel",
                label: "StableChannel: Stable channel",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursMigrating",
                label: "Migrating: Evidence for lateral migration",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursWidening",
                label: "Widening: Evidence for channel widening",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNarrowing",
                label: "Narrowing: Evidence for channel narrowing",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursBedIncisionLikelihood",
                label: "BedIncisionLikelihood: Potential channel bed incision",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursBedIncision",
                label: "BedIncision: Evidence for channel bed incision",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursAggradation",
                label: "Aggradation: Evidence for bed and/or bank aggradation",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursComplexity",
                label: "Complexity: Complexity",
                buckets: "auto",
              }),
              numberAttr({
                attributeId: "ursNumSpecialFeatures",
                label: "NumSpecialFeatures: Number of special features",
                buckets: "auto",
              }),
            ],
          }),
          attributeGroup({
            label: "Nuisance Species",
            attributes: UrsNuisanceSpeciesEnum.entries.map(({ value, label }) =>
              stringAttr({
                attributeId: value,
                label,
                buckets: [
                  lookupBucket("N", marker.quality(1.0), "None"),
                  lookupBucket("I", marker.quality(0.8), "Individual plant(s)"),
                  lookupBucket(
                    "C",
                    marker.quality(0.5),
                    "Isolated clumps (<5% cover)"
                  ),
                  lookupBucket(
                    "F",
                    marker.quality(0.2),
                    "Frequent (5-33% cover)"
                  ),
                  lookupBucket(
                    "E",
                    marker.quality(0.0),
                    "Extensive (>33% cover)"
                  ),
                ],
              })
            ),
          }),
        ],
      }),
    ],
  });
}
