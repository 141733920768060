import { Result } from "@cartographerio/fp";
import {
  Geometry,
  GeometryAtom,
  isGeometry,
  isPickedF,
  Picked,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";

interface PartialSiteAttributes {
  pondNumber?: string | null;
}

export interface PartialData {
  recorded?: Timestamp | null;
  picked?: Picked<Geometry, PartialSiteAttributes>;
}

export function isPartialSiteAttributes(
  data: unknown
): data is PartialSiteAttributes {
  return (
    isObject(data) && hasOptionalKey(data, "pondNumber", isNullable(isString))
  );
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(
      data,
      "picked",
      isNullable(isPickedF(isGeometry, isPartialSiteAttributes))
    )
  );
}

const g = Result.guard(isPartialData, "PartialFhtPondCount");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    [
      data.picked?.feature?.properties?.pondNumber ?? "Unnamed Pond",
      data.picked?.point == null
        ? "Unknown NGR"
        : safePointToNgr(data.picked.point),
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.picked?.point ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return g(data).map(data => ({
    ...data,
    recorded: null,
  }));
}
