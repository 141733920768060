import { mapLink, surveyModuleFromJson } from "../SurveyModule";
import {
  dataDescription,
  dataGeometry,
  dataTimestamp,
  copyData,
} from "./parts";
import formSchema from "./form";
import moduleJson from "./module.generated.json";
import { unsafeMapId } from "@cartographerio/types";

export default surveyModuleFromJson({
  moduleJson,
  formSchema,
  dataDescription,
  dataGeometry,
  dataTimestamp,
  copyData,
  mapLinks: [
    mapLink({
      label: "Water Quality",
      mapId: unsafeMapId("extendedRiverflyWaterQuality"),
    }),
    mapLink({
      label: "Silt and Flow",
      mapId: unsafeMapId("extendedRiverflySiltAndFlow"),
    }),
  ],
}).unsafeGet();
