import ArrtWaterQuality from "./ArrtWaterQuality";
import Ces from "./Ces";
import EsrtWaterQuality from "./EsrtWaterQuality";
import ExtendedRiverfly from "./ExtendedRiverfly";
import FbaWindermere from "./FbaWindermere";
import FbaWindermereSite from "./FbaWindermereSite";
import FhtPondCount from "./FhtPondCount";
import FhtPriorityPonds from "./FhtPriorityPonds";
import MrsMorph from "./MrsMorph";
import MrsMorphEstuaries from "./MrsMorphEstuaries";
import MrsMorphEstuariesPro from "./MrsMorphEstuariesPro";
import MrsMorphPro from "./MrsMorphPro";
import MrsMudSpotter from "./MrsMudSpotter";
import MrsRiverWood from "./MrsRiverWood";
import MrsRta from "./MrsRta";
import MrsRtaPro from "./MrsRtaPro";
import MrsWildInvaders from "./MrsWildInvaders";
import NeChalkRivers from "./NeChalkRivers";
import NeLakeNaturalness from "./NeLakeNaturalness";
import NeLakeRestorationPriorities from "./NeLakeRestorationPriorities";
import NeRiverNaturalness from "./NeRiverNaturalness";
import NeRiverRestorationPriorities from "./NeRiverRestorationPriorities";
import NeRiverRestorationPriorityAreas from "./NeRiverRestorationPriorityAreas";
import NeRiverTypes from "./NeRiverTypes";
import NwcSeedbank from "./NwcSeedbank";
import OutfallSafari from "./OutfallSafari";
import PlacemarkerRiverStretch from "./PlacemarkerRiverStretch";
import PlacemarkerStudyArea from "./PlacemarkerStudyArea";
import Riverfly from "./Riverfly";
import RiverflySite from "./RiverflySite";
import SasWaterQuality from "./SasWaterQuality";
import SasWaterQualitySite from "./SasWaterQualitySite";
import StdActivityDiary from "./StdActivityDiary";
import StdInvasiveSpeciesSighting from "./StdInvasiveSpeciesSighting";
import StormPhosphates from "./StormPhosphates";
import StSmartRivers from "./StSmartRivers";
import StSmartRiversSite from "./StSmartRiversSite";
import {
  surveyModuleInventory,
  SurveyModuleInventory,
} from "./SurveyModuleInventory";
import Thames21BadgedGroupEvent from "./Thames21BadgedGroupEvent";
import Thames21GreenWallModule from "./Thames21GreenWallModule";
import Thames21Litter from "./Thames21Litter";
import Thames21SlowFlow from "./Thames21SlowFlow";
import Thames21Vegetation from "./Thames21Vegetation";
import Thames21WaterQuality from "./Thames21WaterQuality";
import UclPitStop from "./UclPitStop";
import UrbanRiverfly from "./UrbanRiverfly";
import Urs from "./Urs";
import WrtFords from "./WrtFords";
import WrtUpstreamThinking from "./WrtUpstreamThinking";
import WrtWestcountryCsi from "./WrtWestcountryCsi";

export function productionSurveyModules(): SurveyModuleInventory {
  return surveyModuleInventory([
    OutfallSafari,
    ArrtWaterQuality,
    Ces,
    EsrtWaterQuality,
    ExtendedRiverfly,
    FbaWindermere,
    FbaWindermereSite,
    FhtPondCount,
    FhtPriorityPonds,
    MrsMorph,
    MrsMorphPro,
    MrsMorphEstuaries,
    MrsMorphEstuariesPro,
    MrsMudSpotter,
    MrsRiverWood,
    MrsRta,
    MrsRtaPro,
    MrsWildInvaders,
    NeChalkRivers,
    NeRiverTypes,
    NeLakeNaturalness,
    NeLakeRestorationPriorities,
    NeRiverNaturalness,
    NeRiverRestorationPriorities,
    NeRiverRestorationPriorityAreas,
    NwcSeedbank,
    PlacemarkerRiverStretch,
    PlacemarkerStudyArea,
    Riverfly,
    RiverflySite,
    SasWaterQuality,
    SasWaterQualitySite,
    StdActivityDiary,
    StdInvasiveSpeciesSighting,
    StormPhosphates,
    StSmartRivers,
    StSmartRiversSite,
    Thames21BadgedGroupEvent,
    Thames21GreenWallModule,
    Thames21Litter,
    Thames21SlowFlow,
    Thames21Vegetation,
    Thames21WaterQuality,
    UclPitStop,
    Urs,
    UrbanRiverfly,
    WrtFords,
    WrtUpstreamThinking,
    WrtWestcountryCsi,
  ]);
}
