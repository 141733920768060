import {
  bounds,
  columns2,
  form,
  integerField,
  page,
  pointField,
  required,
  section,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { outdent } from "outdent";

export default form({
  title: "SmartRivers",
  surveyorLabel: "Creator",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            columns2(
              vstack(
                textField({
                  label: "Catchment Name",
                  path: ["data", "catchment"],
                  required: required("info"),
                }),
                textField({
                  label: "River Name",
                  path: ["data", "river"],
                  required: required("info"),
                }),
                textField({
                  label: "Site Name",
                  path: ["data", "site"],
                  required: required("info"),
                }),
                timestampField({
                  label: "Date and Time Established",
                  path: ["data", "established"],
                  required: required("info"),
                })
              ),
              pointField({
                label: "Location",
                path: ["data", "location"],
                required: required("info"),
                help: "Location of the monitoring site.",
              })
            ),
          ],
        }),
        section({
          title: "TRPI",
          path: [],
          blocks: [
            integerField({
              label: "TRPI River Type",
              path: ["data", "trpiRiverType"],
              bounds: bounds(
                1,
                5,
                "error",
                "River type must be 1-5 inclusive."
              ),
              help: outdent`
              The river type to use for TRPI calculations,
              as shown in the figure below:

              ![TRPI River Types](https://media.cartographer.io/static/images/smartrivers/trpi-river-types.png)
              `,
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 8,
            }),
          ],
        }),
      ],
    }),
  ],
});
