import {
  Workspace,
  WorkspaceBillingSummary,
  WorkspaceSubscribed,
  WorkspaceUnsubscribed,
  ddmmyyyy,
  formatTimestamp,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box, Card, CardBody, HStack } from "@chakra-ui/react";
import { ReactElement } from "react";

import queries from "../../queries";
import AdminNoteEditor from "../components/AdminNoteEditor";
import ClickToCopy from "../components/ClickToCopy";
import Heading from "../components/Heading";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import { routes } from "../routes";
import { FrontContext } from "./context";
import { FrontInfoItem, FrontInfoList } from "./FrontInfoList";
import FrontLink from "./FrontLink";
import { webAppUrl } from "./webApp";

export interface FrontWorkspaceItemProps {
  context: FrontContext;
  workspace: Workspace;
}

export function FrontWorkspaceItem(
  props: FrontWorkspaceItemProps
): ReactElement {
  const { context, workspace } = props;

  const apiParams = useApiParams();

  const summary = useSuspenseQueryData(
    queries.billing.summary.v1.readOrFail(apiParams, workspace.id)
  );

  return (
    <Card>
      <CardBody>
        <Spaced>
          <HStack>
            <Heading flexGrow={1} flexShrink={1} level="subsubsection" my="0">
              {workspace.name}
            </Heading>

            {/* <IconButton
              size="sm"
              variant="outline"
              aria-label="Edit"
              icon={<IoChevronForward />}
              onClick={() => onClick(workspace)}
            /> */}
          </HStack>

          <FrontInfoList>
            <FrontInfoItem label="Workspace ID">
              <ClickToCopy size="sm" value={workspace.id} />
            </FrontInfoItem>

            <FrontInfoItem label="Alias">
              <ClickToCopy size="sm" value={workspace.alias} />
            </FrontInfoItem>

            <FrontInfoItem label="Billing Type">
              {summary.settings.billingType}
            </FrontInfoItem>

            <FrontInfoItem label="Usage">
              {summary.usage.numUsers} users, {summary.usage.numProjects}{" "}
              projects, {summary.usage.features.toLowerCase()} features
            </FrontInfoItem>

            {foldSummary(
              summary,
              sub => (
                <FrontInfoItem label="Plan">{sub.plan.name}</FrontInfoItem>
              ),
              unsub => (
                <FrontInfoItem label="Trial End">
                  {unsub.settings.unsubscribedTrialEnd == null
                    ? "-"
                    : formatTimestamp(unsub.settings.unsubscribedTrialEnd, {
                        format: ddmmyyyy,
                      })}
                </FrontInfoItem>
              )
            )}

            <FrontInfoItem label="Links">
              <HStack spacing="2" justifyContent="flex-start" fontSize="sm">
                <FrontLink
                  context={context}
                  to={webAppUrl(
                    apiParams,
                    routes.workspace.home.url([workspace.alias])
                  )}
                >
                  Home
                </FrontLink>
                <FrontLink
                  context={context}
                  to={webAppUrl(
                    apiParams,
                    routes.workspace.settings.url([workspace.alias])
                  )}
                >
                  Settings
                </FrontLink>
                <FrontLink
                  context={context}
                  to={webAppUrl(
                    apiParams,
                    routes.workspace.billing.settings.url([workspace.alias])
                  )}
                >
                  Billing
                </FrontLink>
                <FrontLink
                  context={context}
                  to={webAppUrl(
                    apiParams,
                    routes.workspace.member.list.url([workspace.alias])
                  )}
                >
                  Members
                </FrontLink>
              </HStack>
            </FrontInfoItem>

            <FrontInfoItem label="Notes">
              <Box bg="gray.100" rounded="md" p="1">
                <AdminNoteEditor workspaceRef={workspace.id} />
              </Box>
            </FrontInfoItem>
          </FrontInfoList>
        </Spaced>
      </CardBody>
    </Card>
  );
}

function foldSummary<R>(
  summary: WorkspaceBillingSummary,
  sub: (summary: WorkspaceSubscribed) => R,
  unsub: (summary: WorkspaceUnsubscribed) => R
): R | undefined {
  switch (summary.type) {
    case "WorkspaceSubscribed":
      return sub(summary);
    case "WorkspaceUnsubscribed":
      return unsub(summary);
    default:
      return checkExhausted(summary);
  }
}
