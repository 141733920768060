import { IO } from "@cartographerio/io";

export interface FetchLogger<A> {
  logFetchRequest(url: string, options: RequestInit): IO<A>;
  logFetchResponse(context: A, response: Response, content: string): IO<void>;
  logFetchError(context: A, error: unknown): IO<void>;
}

export class NoopFetchLogger implements FetchLogger<void> {
  logFetchRequest = (_url: string, _options: RequestInit): IO<void> =>
    IO.noop();

  logFetchResponse = (
    _context: void,
    _response: Response,
    _content: string
  ): IO<void> => IO.noop();

  logFetchError = (_context: void, _error: unknown): IO<void> => IO.noop();
}

const now = IO.wrap(() => new Date());

interface ConsoleFetchLoggerContext {
  url: string;
  options: RequestInit;
  startTime: Date;
}

export class ConsoleFetchLogger
  implements FetchLogger<ConsoleFetchLoggerContext>
{
  logBodies: boolean;

  constructor(logBodies: boolean = false) {
    this.logBodies = logBodies;
  }

  logFetchRequest = (
    url: string,
    options: RequestInit
  ): IO<ConsoleFetchLoggerContext> => {
    if (this.logBodies) {
      console.log(">>", options.method, url, options.body);
    } else {
      console.log(">>", options.method, url);
    }

    return now.chain(startTime => ({ url, options, startTime }));
  };

  logFetchResponse = (
    context: ConsoleFetchLoggerContext,
    response: Response,
    content: string
  ): IO<void> => {
    const { url, options, startTime } = context;
    return now
      .chain(endTime => `${endTime.getTime() - startTime.getTime()}ms`)
      .chain(duration => {
        if (this.logBodies) {
          console.log(
            "<<",
            options.method,
            url,
            duration,
            response.status,
            content
          );
        } else {
          console.log("<<", options.method, url, duration);
        }

        return IO.noop();
      });
  };

  logFetchError = (
    context: ConsoleFetchLoggerContext,
    error: unknown
  ): IO<void> => {
    const { url, options, startTime } = context;
    return now
      .chain(endTime => `${startTime.getTime() - endTime.getTime()}ms`)
      .chain(duration => {
        console.log("!!", options.method, url, duration, error);
        return IO.noop();
      });
  };
}
