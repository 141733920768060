// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsCountedHabitatFeature =
  | "BE"
  | "RO"
  | "WF"
  | "RI"
  | "PO"
  | "MBW"
  | "CBW"
  | "DBW"
  | "SL"
  | "MI"
  | "MB"
  | "VM"
  | "PB"
  | "VP"
  | "SB"
  | "VS"
  | "OM"
  | "WD"
  | "WJ";

const Bedrock: UrsCountedHabitatFeature = "BE";
const RockOrBoulder: UrsCountedHabitatFeature = "RO";
const Waterfall: UrsCountedHabitatFeature = "WF";
const Riffle: UrsCountedHabitatFeature = "RI";
const Pool: UrsCountedHabitatFeature = "PO";
const MarginalBackwater: UrsCountedHabitatFeature = "MBW";
const ConnectedBackwater: UrsCountedHabitatFeature = "CBW";
const DisconnectedBackwater: UrsCountedHabitatFeature = "DBW";
const DiscreteSandOrSiltDeposit: UrsCountedHabitatFeature = "SL";
const Island: UrsCountedHabitatFeature = "MI";
const UnvegetatedMidChannelBar: UrsCountedHabitatFeature = "MB";
const VegetatedMidChannelBar: UrsCountedHabitatFeature = "VM";
const UnvegetatedPointBar: UrsCountedHabitatFeature = "PB";
const VegetatedPointBar: UrsCountedHabitatFeature = "VP";
const UnvegetatedSideBar: UrsCountedHabitatFeature = "SB";
const VegetatedSideBar: UrsCountedHabitatFeature = "VS";
const DiscreteOrganicMatterDeposit: UrsCountedHabitatFeature = "OM";
const WoodyDebris: UrsCountedHabitatFeature = "WD";
const WoodJam: UrsCountedHabitatFeature = "WJ";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsCountedHabitatFeatureEnum = {
  ...createEnum("UrsCountedHabitatFeature", [
    [Bedrock, "Bedrock"],
    [RockOrBoulder, "Rock/boulder"],
    [Waterfall, "Waterfall"],
    [Riffle, "Riffle"],
    [Pool, "Pool"],
    [MarginalBackwater, "Marginal backwater"],
    [ConnectedBackwater, "Connected backwater"],
    [DisconnectedBackwater, "Disconnected backwater"],
    [DiscreteSandOrSiltDeposit, "Discrete sand / silt deposits"],
    [Island, "Island"],
    [UnvegetatedMidChannelBar, "Mid-channel bar (unvegetated)"],
    [VegetatedMidChannelBar, "Mid-channel bar (vegetated)"],
    [UnvegetatedPointBar, "Point bar (unvegetated)"],
    [VegetatedPointBar, "Point bar (vegetated)"],
    [UnvegetatedSideBar, "Side bar (unvegetated)"],
    [VegetatedSideBar, "Side bar (vegetated)"],
    [DiscreteOrganicMatterDeposit, "Discrete organic matter deposit"],
    [WoodyDebris, "Woody debris accumulation"],
    [WoodJam, "Wood jam (blocking channel)"],
  ]),
  Bedrock,
  RockOrBoulder,
  Waterfall,
  Riffle,
  Pool,
  MarginalBackwater,
  ConnectedBackwater,
  DisconnectedBackwater,
  DiscreteSandOrSiltDeposit,
  Island,
  UnvegetatedMidChannelBar,
  VegetatedMidChannelBar,
  UnvegetatedPointBar,
  VegetatedPointBar,
  UnvegetatedSideBar,
  VegetatedSideBar,
  DiscreteOrganicMatterDeposit,
  WoodyDebris,
  WoodJam,
};

export default UrsCountedHabitatFeatureEnum;
