// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeRestorationPrioritiesHydrologicalMeasure =
  | "RestoreWaterLevelFluctuations"
  | "ManageWaterLevelRegime"
  | "RemoveArtificialDrainage"
  | "RestoreInflows"
  | "RestoreOutflows";

const RestoreWaterLevelFluctuations: NeLakeRestorationPrioritiesHydrologicalMeasure =
  "RestoreWaterLevelFluctuations";
const ManageWaterLevelRegime: NeLakeRestorationPrioritiesHydrologicalMeasure =
  "ManageWaterLevelRegime";
const RemoveArtificialDrainage: NeLakeRestorationPrioritiesHydrologicalMeasure =
  "RemoveArtificialDrainage";
const RestoreInflows: NeLakeRestorationPrioritiesHydrologicalMeasure =
  "RestoreInflows";
const RestoreOutflows: NeLakeRestorationPrioritiesHydrologicalMeasure =
  "RestoreOutflows";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeRestorationPrioritiesHydrologicalMeasureEnum = {
  ...createEnum("NeLakeRestorationPrioritiesHydrologicalMeasure", [
    [RestoreWaterLevelFluctuations, "Restore natural water-level fluctuations"],
    [
      ManageWaterLevelRegime,
      "Manage water-level regime to reflect natural water level fluctuations",
    ],
    [
      RemoveArtificialDrainage,
      "Remove artificial drainage from the riparian zone",
    ],
    [RestoreInflows, "Restore natural inflows"],
    [RestoreOutflows, "Restore natural outflows"],
  ]),
  RestoreWaterLevelFluctuations,
  ManageWaterLevelRegime,
  RemoveArtificialDrainage,
  RestoreInflows,
  RestoreOutflows,
};

export default NeLakeRestorationPrioritiesHydrologicalMeasureEnum;
