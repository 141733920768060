// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { QualificationRegisterPrivacy } from "../types.generated";

const Email: QualificationRegisterPrivacy = "Email";
const Name: QualificationRegisterPrivacy = "Name";
const ScreenName: QualificationRegisterPrivacy = "ScreenName";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const QualificationRegisterPrivacyEnum = {
  ...createEnum("QualificationRegisterPrivacy", [
    [Email, "Show real name and email"],
    [Name, "Show real name but not email"],
    [ScreenName, "Show screen name only"],
  ]),
  Email,
  Name,
  ScreenName,
};

export default QualificationRegisterPrivacyEnum;
