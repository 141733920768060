// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsWildInvadersProximity =
  | "VeryClose"
  | "Close"
  | "Far"
  | "VeryFar";

const VeryClose: MrsWildInvadersProximity = "VeryClose";
const Close: MrsWildInvadersProximity = "Close";
const Far: MrsWildInvadersProximity = "Far";
const VeryFar: MrsWildInvadersProximity = "VeryFar";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsWildInvadersProximityEnum = {
  ...createEnum("MrsWildInvadersProximity", [
    [VeryClose, "Within 1m"],
    [Close, "1-5m"],
    [Far, "5-10m"],
    [VeryFar, "Over 10m"],
  ]),
  VeryClose,
  Close,
  Far,
  VeryFar,
};

export default MrsWildInvadersProximityEnum;
