import {
  FormDispatch,
  SelectSection,
  changeSurveyorAction,
  changeTeamIdAction,
} from "@cartographerio/topo-form";
import {
  Project,
  TeamId,
  Team,
  UserId,
  UserRef,
  internalError,
  userToUserRef,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { UserWorkspaceGraph } from "@cartographerio/workspace-graph";
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { calcPrimarySurveyorOptions } from "./calcPrimarySurveyorOptions";
import { calcPrimaryTeamOptions } from "./calcPrimaryTeamOptions";
import { calcPossibleSurveyorUsers } from "./calcPossibleSurveyorUsers";

export interface SurveyorTeamContext {
  primarySurveyor: UserRef | null;
  primaryTeamId: TeamId | null;
  primarySurveyorOptions: SelectSection<UserId>[];
  primaryTeamOptions: SelectSection<TeamId | null>[];
  onPrimarySurveyorChange: (surveyorId: UserId) => void;
  onPrimaryTeamChange: (teamId: TeamId | null) => void;
}

const SurveyorTeamContext = createContext<SurveyorTeamContext | null>(null);

interface SurveyorTeamContextProps {
  initialSurveyor: UserRef | null;
  initialTeamId: TeamId | null;
  primarySurveyor: UserRef | null;
  primaryTeamId: TeamId | null;
  project: Project;
  formDispatch: FormDispatch;
  workspaceGraph: UserWorkspaceGraph;
  currentUser: UserRef;
  children: ReactNode;
}

export function useSurveyorTeamContext(): SurveyorTeamContext {
  return (
    useContext(SurveyorTeamContext) ??
    raise(internalError("Surveyor team context hasn't been initialised"))
  );
}

export function SurveyorTeamProvider(
  props: SurveyorTeamContextProps
): ReactElement {
  const {
    initialSurveyor,
    initialTeamId,
    primarySurveyor,
    primaryTeamId,
    project,
    formDispatch,
    workspaceGraph,
    currentUser,
    children,
  } = props;

  const users = useMemo(
    () => calcPossibleSurveyorUsers({ project, workspaceGraph }),
    [project, workspaceGraph]
  );

  const teams = useMemo(
    (): Team[] => workspaceGraph.findTeamsByWorkspaceId(project.workspaceId),
    [project, workspaceGraph]
  );

  const primarySurveyorOptions = useMemo(
    (): SelectSection<UserId>[] =>
      calcPrimarySurveyorOptions({
        initialSurveyor,
        primarySurveyor,
        primaryTeamId,
        teams,
        users,
      }),
    [initialSurveyor, primarySurveyor, primaryTeamId, teams, users]
  );

  const primaryTeamOptions = useMemo(
    (): SelectSection<TeamId | null>[] =>
      calcPrimaryTeamOptions({
        users,
        teams,
        projectId: project.id,
        initialTeamId,
        primarySurveyor,
        primaryTeamId,
      }),
    [initialTeamId, primarySurveyor, primaryTeamId, project.id, teams, users]
  );

  const onPrimarySurveyorChange = useCallback(
    (newUserId: UserId | null) =>
      newUserId !== (primarySurveyor?.userId ?? null)
        ? formDispatch(
            changeSurveyorAction(
              newUserId == null
                ? null
                : // The current user could be a superuser, in which case they won't be in the graph:
                  newUserId === currentUser.userId
                  ? currentUser
                  : userToUserRef(workspaceGraph.findUserById(newUserId))
            )
          )
        : null,
    [primarySurveyor?.userId, formDispatch, currentUser, workspaceGraph]
  );

  const onPrimaryTeamChange = useCallback(
    (newTeamId: TeamId | null) =>
      newTeamId !== primaryTeamId
        ? formDispatch(changeTeamIdAction(newTeamId))
        : null,
    [primaryTeamId, formDispatch]
  );

  const context = useMemo(
    (): SurveyorTeamContext => ({
      primarySurveyor,
      primaryTeamId,
      primarySurveyorOptions,
      primaryTeamOptions: primaryTeamOptions,
      onPrimarySurveyorChange,
      onPrimaryTeamChange,
    }),
    [
      primarySurveyor,
      primaryTeamId,
      primarySurveyorOptions,
      primaryTeamOptions,
      onPrimarySurveyorChange,
      onPrimaryTeamChange,
    ]
  );

  return (
    <SurveyorTeamContext.Provider value={context}>
      {children}
    </SurveyorTeamContext.Provider>
  );
}
