// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopBufferCover = "P100" | "P75" | "P50" | "P25" | "P0";

const P100: UclPitStopBufferCover = "P100";
const P75: UclPitStopBufferCover = "P75";
const P50: UclPitStopBufferCover = "P50";
const P25: UclPitStopBufferCover = "P25";
const P0: UclPitStopBufferCover = "P0";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopBufferCoverEnum = {
  ...createEnum("UclPitStopBufferCover", [
    [P100, "100%"],
    [P75, "75%"],
    [P50, "50%"],
    [P25, "25%"],
    [P0, "0"],
  ]),
  P100,
  P75,
  P50,
  P25,
  P0,
};

export default UclPitStopBufferCoverEnum;
