import { unsafeMapId } from "@cartographerio/topo-map";
import { mapSchemaWithDefaults } from "../../core";
import {
  neRiverNaturalnessLayer,
  priorityRiverHabitatLayer,
} from "./naturalnessHelpers";
import { MapBaseEnum } from "@cartographerio/types";

export const NeRiverNaturalness = mapSchemaWithDefaults({
  mapId: unsafeMapId("neRiverNaturalness"),
  title: "River/Stream Naturalness",
  defaultBase: MapBaseEnum.Satellite,
  layers: [priorityRiverHabitatLayer, neRiverNaturalnessLayer],
});
