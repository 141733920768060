import { commonLocations, Point } from "@cartographerio/geometry";
import {
  nowTimestamp,
  randomUuid,
  Timestamp,
  Uuid,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import lodash from "lodash";
import { ProductSchema, Schema } from "./type";
import { typeCheck } from "./typeCheck";

interface BlankValueOptions {
  genUuid: () => Uuid;
  genTimestamp: () => Timestamp;
  genPoint: () => Point;
}

export const defaultBlankValueOptions: BlankValueOptions = {
  genUuid: randomUuid,
  genTimestamp: nowTimestamp,
  genPoint: () => commonLocations.greatBritain.center,
};

export function schemaBlankValue(
  schema: Schema,
  opts: BlankValueOptions = defaultBlankValueOptions
): unknown {
  const value = internalBlankValue(schema, opts);

  const errors = typeCheck(schema, value);
  if (errors.length > 0) {
    console.error(
      "schemaBlankValue returned a value that did not type check against the schema",
      { schema, value, errors }
    );
  }
  return value;
}

function internalProductBlankValue(
  schema: ProductSchema,
  options: BlankValueOptions
): Record<string, unknown> {
  return (
    lodash
      .chain(schema.fields)
      .toPairs()
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .map(([key, value]) => [key, internalBlankValue(value, options)])
      .fromPairs()
      .value()
  );
}

function internalBlankValue(
  schema: Schema,
  options: BlankValueOptions
): unknown {
  if (schema.defaultValue != null) {
    return schema.defaultValue;
  } else {
    switch (schema.type) {
      case "Boolean":
        return false;

      case "Integer":
        return 0;

      case "Number":
        return 0;

      case "String":
        return "";

      case "Uuid":
        return options.genUuid();

      case "Timestamp":
        return options.genTimestamp();

      case "Geometry":
        switch (schema.geometryType) {
          case "Point":
            return options.genPoint();
          case "MultiPoint":
          case "LineString":
          case "MultiLineString":
          case "Polygon":
          case "MultiPolygon":
            return null;
          case undefined:
            return options.genPoint();
          default:
            return checkExhausted(schema.geometryType);
        }

      case "Enum":
        return schema.values.length > 0 ? schema.values[0] : undefined;

      case "Nullable":
        return null;

      case "Array":
        return lodash
          .range(0, schema.defaultLength || 0)
          .map(_index => internalBlankValue(schema.param, options));

      case "Tuple":
        return schema.items.map(item => internalBlankValue(item, options));

      case "Dict":
        return {};

      case "Product":
        return internalProductBlankValue(schema, options);

      case "Sum": {
        const pairs = lodash.toPairs(schema.products);
        if (pairs.length > 0) {
          const [name, product] = pairs[0];
          return { ...internalProductBlankValue(product, options), type: name };
        } else {
          return undefined;
        }
      }

      case "Unknown":
        return null;

      default:
        return checkExhausted(schema);
    }
  }
}
