import { Result } from "@cartographerio/fp";
import {
  Checked,
  CheckedFailure,
  CheckedSuccess,
  Message,
} from "./types.generated";

export function checkedSuccess<A>(value: A): Checked<A> {
  return {
    type: "CheckedSuccess",
    value,
  };
}

export function checkedFailure<A>(errors: Message[]): Checked<A> {
  return {
    type: "CheckedFailure",
    errors,
  };
}

export interface CheckedHandlers<A, R> {
  success(value: A): R;
  failure(errors: Message[]): R;
}

export function foldChecked<A, R>(
  checked: Checked<A>,
  handlers: CheckedHandlers<A, R>
): R {
  switch (checked.type) {
    case "CheckedSuccess":
      return handlers.success(checked.value);
    case "CheckedFailure":
      return handlers.failure(checked.errors);
  }
}

export function mapChecked<A, B>(
  checked: Checked<A>,
  func: (a: A) => B
): Checked<B> {
  return foldChecked(checked, {
    success: value => checkedSuccess(func(value)),
    failure: errors => checkedFailure(errors),
  });
}

export function checkedToResult<A>(checked: Checked<A>): Result<Message[], A> {
  return foldChecked(checked, {
    success: value => Result.pure(value),
    failure: messages => Result.fail(messages),
  });
}

export function forEachChecked<A>(
  checked: Checked<A>,
  func: (a: A) => void
): void {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  foldChecked(checked, { success: func, failure: () => {} });
}

export function checkedIsSuccess<A>(
  checked: Checked<A>
): checked is CheckedSuccess<A> {
  return checked.type === "CheckedSuccess";
}

export function checkedIsFailure<A>(
  checked: Checked<A>
): checked is CheckedFailure {
  return checked.type === "CheckedFailure";
}
