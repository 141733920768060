import {
  dataDescription,
  dataGeometry,
  dataTimestamp,
  copyData,
} from "./parts";
import { surveyModuleFromJson } from "../SurveyModule";
import mrsRtaForm from "./form";
import moduleJson from "./module.generated.json";

const formSchema = mrsRtaForm("MoRPh River Type");

export default surveyModuleFromJson({
  moduleJson,
  formSchema,
  dataDescription,
  dataGeometry,
  dataTimestamp,
  copyData,
}).unsafeGet();
