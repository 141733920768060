// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21VegetationType =
  | "bryophytes"
  | "shortHerbsOrGrasses"
  | "tallHerbsOrGrasses"
  | "scrubOrBrambles"
  | "saplingsAndTrees";

const Bryophytes: Thames21VegetationType = "bryophytes";
const ShortHerbsOrGrasses: Thames21VegetationType = "shortHerbsOrGrasses";
const TallHerbsOrGrasses: Thames21VegetationType = "tallHerbsOrGrasses";
const ScrubOrBrambles: Thames21VegetationType = "scrubOrBrambles";
const Saplings: Thames21VegetationType = "saplingsAndTrees";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21VegetationTypeEnum = {
  ...createEnum("Thames21VegetationType", [
    [Bryophytes, "Bryophytes (e.g. moss)"],
    [ShortHerbsOrGrasses, "Short herbs / grasses"],
    [TallHerbsOrGrasses, "Tall herbs / grasses"],
    [ScrubOrBrambles, "Scrub, brambles, etc"],
    [Saplings, "Saplings / trees"],
  ]),
  Bryophytes,
  ShortHerbsOrGrasses,
  TallHerbsOrGrasses,
  ScrubOrBrambles,
  Saplings,
};

export default Thames21VegetationTypeEnum;
