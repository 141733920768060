/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  Email,
  isEmail,
  InvitationCodeAlias,
  isInvitationCodeAlias,
  InvitationCodeId,
  isInvitationCodeId,
  InvitationId,
  isInvitationId,
  NamedEmail,
  isNamedEmail,
  Timestamp,
  isTimestamp,
  UserId,
  isUserId,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";
import {
  QualificationRole,
  isQualificationRole,
  Role,
  isRole,
} from "../permission/types.generated";
import { UserRef, isUserRef } from "../user/types.generated";

export interface Invitation {
  workspaceId?: WorkspaceId | null;
  firstName: string;
  lastName: string;
  email: Email;
  roles: Role[];
  qualificationRoles: QualificationRole[];
  accepted?: Timestamp | null;
  acceptedBy?: UserRef | null;
  expires?: Timestamp | null;
  canceled?: Timestamp | null;
  created: Timestamp;
  updated: Timestamp;
  createdBy: UserRef;
  id: InvitationId;
}

export interface InvitationCode {
  workspaceId?: WorkspaceId | null;
  name: string;
  alias: InvitationCodeAlias;
  roles: Role[];
  qualificationRoles: QualificationRole[];
  expires?: Timestamp | null;
  canceled?: Timestamp | null;
  requireApproval: boolean;
  approvalEmails?: NamedEmail[] | null;
  approvalDomainAllowList?: string[] | null;
  delayEmailVerification?: boolean | null;
  created: Timestamp;
  updated: Timestamp;
  createdBy: UserRef;
  id: InvitationCodeId;
}

export interface InvitationCreate {
  workspaceId?: WorkspaceId | null;
  firstName: string;
  lastName: string;
  email: Email;
  roles: Role[];
  qualificationRoles: QualificationRole[];
}

export interface InvitationUpdate {
  firstName?: string | null;
  lastName?: string | null;
  email?: Email | null;
  roles?: Role[] | null;
  qualificationRoles?: QualificationRole[] | null;
}

export interface InvitationSummary {
  firstName?: string | null;
  lastName?: string | null;
  email?: Email | null;
  accepted?: Timestamp | null;
  expires?: Timestamp | null;
  canceled?: Timestamp | null;
  id: InvitationId;
}

export interface InvitationCodeCreate {
  name: string;
  workspaceId?: WorkspaceId | null;
  roles: Role[];
  qualificationRoles: QualificationRole[];
  expires?: Timestamp | null;
  requireApproval: boolean;
  approvalEmails?: NamedEmail[] | null;
  approvalDomainAllowList?: string[] | null;
  delayEmailVerification?: boolean | null;
}

export interface InvitationCodeUpdate {
  name?: string | null;
  roles?: Role[] | null;
  qualificationRoles?: QualificationRole[] | null;
  expires?: Timestamp | null;
  requireApproval?: boolean | null;
  approvalEmails?: NamedEmail[] | null;
  approvalDomainAllowList?: string[] | null;
  delayEmailVerification?: boolean | null;
}

export interface InvitationCodeSummary {
  alias: InvitationCodeAlias;
  requireApproval: boolean;
  delayEmailVerification?: boolean | null;
  expires?: Timestamp | null;
  canceled?: Timestamp | null;
  id: InvitationCodeId;
}

export interface InvitationCodeSignup {
  invitationCodeId: InvitationCodeId;
  userId: UserId;
  emailVerified?: Timestamp | null;
  approved?: Timestamp | null;
  approvedBy?: UserRef | null;
  rejected?: Timestamp | null;
  rejectedBy?: UserRef | null;
  created: Timestamp;
}

export type InvitationStatus = "Accepted" | "Canceled" | "Expired" | "Pending";

export type InvitationCodeStatus = "Canceled" | "Expired" | "Pending";

export const isInvitation = (v: any): v is Invitation => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("workspaceId" in v) ||
      isWorkspaceId(v.workspaceId) ||
      v.workspaceId === null) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "email" in v &&
    isEmail(v.email) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i)) &&
    (!("accepted" in v) || isTimestamp(v.accepted) || v.accepted === null) &&
    (!("acceptedBy" in v) ||
      isUserRef(v.acceptedBy) ||
      v.acceptedBy === null) &&
    (!("expires" in v) || isTimestamp(v.expires) || v.expires === null) &&
    (!("canceled" in v) || isTimestamp(v.canceled) || v.canceled === null) &&
    "created" in v &&
    isTimestamp(v.created) &&
    "updated" in v &&
    isTimestamp(v.updated) &&
    "createdBy" in v &&
    isUserRef(v.createdBy) &&
    "id" in v &&
    isInvitationId(v.id)
  );
};

export const isInvitationCode = (v: any): v is InvitationCode => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("workspaceId" in v) ||
      isWorkspaceId(v.workspaceId) ||
      v.workspaceId === null) &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isInvitationCodeAlias(v.alias) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i)) &&
    (!("expires" in v) || isTimestamp(v.expires) || v.expires === null) &&
    (!("canceled" in v) || isTimestamp(v.canceled) || v.canceled === null) &&
    "requireApproval" in v &&
    typeof v.requireApproval === "boolean" &&
    (!("approvalEmails" in v) ||
      (Array.isArray(v.approvalEmails) &&
        v.approvalEmails.every((i: any) => isNamedEmail(i))) ||
      v.approvalEmails === null) &&
    (!("approvalDomainAllowList" in v) ||
      (Array.isArray(v.approvalDomainAllowList) &&
        v.approvalDomainAllowList.every((i: any) => typeof i === "string")) ||
      v.approvalDomainAllowList === null) &&
    (!("delayEmailVerification" in v) ||
      typeof v.delayEmailVerification === "boolean" ||
      v.delayEmailVerification === null) &&
    "created" in v &&
    isTimestamp(v.created) &&
    "updated" in v &&
    isTimestamp(v.updated) &&
    "createdBy" in v &&
    isUserRef(v.createdBy) &&
    "id" in v &&
    isInvitationCodeId(v.id)
  );
};

export const isInvitationCreate = (v: any): v is InvitationCreate => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("workspaceId" in v) ||
      isWorkspaceId(v.workspaceId) ||
      v.workspaceId === null) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "email" in v &&
    isEmail(v.email) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i))
  );
};

export const isInvitationUpdate = (v: any): v is InvitationUpdate => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("firstName" in v) ||
      typeof v.firstName === "string" ||
      v.firstName === null) &&
    (!("lastName" in v) ||
      typeof v.lastName === "string" ||
      v.lastName === null) &&
    (!("email" in v) || isEmail(v.email) || v.email === null) &&
    (!("roles" in v) ||
      (Array.isArray(v.roles) && v.roles.every((i: any) => isRole(i))) ||
      v.roles === null) &&
    (!("qualificationRoles" in v) ||
      (Array.isArray(v.qualificationRoles) &&
        v.qualificationRoles.every((i: any) => isQualificationRole(i))) ||
      v.qualificationRoles === null)
  );
};

export const isInvitationSummary = (v: any): v is InvitationSummary => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("firstName" in v) ||
      typeof v.firstName === "string" ||
      v.firstName === null) &&
    (!("lastName" in v) ||
      typeof v.lastName === "string" ||
      v.lastName === null) &&
    (!("email" in v) || isEmail(v.email) || v.email === null) &&
    (!("accepted" in v) || isTimestamp(v.accepted) || v.accepted === null) &&
    (!("expires" in v) || isTimestamp(v.expires) || v.expires === null) &&
    (!("canceled" in v) || isTimestamp(v.canceled) || v.canceled === null) &&
    "id" in v &&
    isInvitationId(v.id)
  );
};

export const isInvitationCodeCreate = (v: any): v is InvitationCodeCreate => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    (!("workspaceId" in v) ||
      isWorkspaceId(v.workspaceId) ||
      v.workspaceId === null) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i)) &&
    (!("expires" in v) || isTimestamp(v.expires) || v.expires === null) &&
    "requireApproval" in v &&
    typeof v.requireApproval === "boolean" &&
    (!("approvalEmails" in v) ||
      (Array.isArray(v.approvalEmails) &&
        v.approvalEmails.every((i: any) => isNamedEmail(i))) ||
      v.approvalEmails === null) &&
    (!("approvalDomainAllowList" in v) ||
      (Array.isArray(v.approvalDomainAllowList) &&
        v.approvalDomainAllowList.every((i: any) => typeof i === "string")) ||
      v.approvalDomainAllowList === null) &&
    (!("delayEmailVerification" in v) ||
      typeof v.delayEmailVerification === "boolean" ||
      v.delayEmailVerification === null)
  );
};

export const isInvitationCodeUpdate = (v: any): v is InvitationCodeUpdate => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("name" in v) || typeof v.name === "string" || v.name === null) &&
    (!("roles" in v) ||
      (Array.isArray(v.roles) && v.roles.every((i: any) => isRole(i))) ||
      v.roles === null) &&
    (!("qualificationRoles" in v) ||
      (Array.isArray(v.qualificationRoles) &&
        v.qualificationRoles.every((i: any) => isQualificationRole(i))) ||
      v.qualificationRoles === null) &&
    (!("expires" in v) || isTimestamp(v.expires) || v.expires === null) &&
    (!("requireApproval" in v) ||
      typeof v.requireApproval === "boolean" ||
      v.requireApproval === null) &&
    (!("approvalEmails" in v) ||
      (Array.isArray(v.approvalEmails) &&
        v.approvalEmails.every((i: any) => isNamedEmail(i))) ||
      v.approvalEmails === null) &&
    (!("approvalDomainAllowList" in v) ||
      (Array.isArray(v.approvalDomainAllowList) &&
        v.approvalDomainAllowList.every((i: any) => typeof i === "string")) ||
      v.approvalDomainAllowList === null) &&
    (!("delayEmailVerification" in v) ||
      typeof v.delayEmailVerification === "boolean" ||
      v.delayEmailVerification === null)
  );
};

export const isInvitationCodeSummary = (v: any): v is InvitationCodeSummary => {
  return (
    typeof v === "object" &&
    v != null &&
    "alias" in v &&
    isInvitationCodeAlias(v.alias) &&
    "requireApproval" in v &&
    typeof v.requireApproval === "boolean" &&
    (!("delayEmailVerification" in v) ||
      typeof v.delayEmailVerification === "boolean" ||
      v.delayEmailVerification === null) &&
    (!("expires" in v) || isTimestamp(v.expires) || v.expires === null) &&
    (!("canceled" in v) || isTimestamp(v.canceled) || v.canceled === null) &&
    "id" in v &&
    isInvitationCodeId(v.id)
  );
};

export const isInvitationCodeSignup = (v: any): v is InvitationCodeSignup => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationCodeId" in v &&
    isInvitationCodeId(v.invitationCodeId) &&
    "userId" in v &&
    isUserId(v.userId) &&
    (!("emailVerified" in v) ||
      isTimestamp(v.emailVerified) ||
      v.emailVerified === null) &&
    (!("approved" in v) || isTimestamp(v.approved) || v.approved === null) &&
    (!("approvedBy" in v) ||
      isUserRef(v.approvedBy) ||
      v.approvedBy === null) &&
    (!("rejected" in v) || isTimestamp(v.rejected) || v.rejected === null) &&
    (!("rejectedBy" in v) ||
      isUserRef(v.rejectedBy) ||
      v.rejectedBy === null) &&
    "created" in v &&
    isTimestamp(v.created)
  );
};

export const isInvitationStatus = (v: any): v is InvitationStatus => {
  return (
    v === "Accepted" || v === "Canceled" || v === "Expired" || v === "Pending"
  );
};

export const isInvitationCodeStatus = (v: any): v is InvitationCodeStatus => {
  return v === "Canceled" || v === "Expired" || v === "Pending";
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
