import {
  attachmentAttr,
  attributeGroup,
  marker,
  pointLayer,
  surveyAttr,
  teamAttr,
  thresholdAttr,
  thresholdBucket,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { countedLitterAttr } from "./helpers";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const Thames21BadgedGroupEvent = mapSchemaWithDefaults({
  mapId: unsafeMapId("thames21BadgedGroupEvent"),
  title: "River Action Group Event",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("thames21BadgedGroupEvent"),
      title: "River Action Group Event",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("thames21BadgedGroupEvent"),
        attribution: [],
      }),
      defaultAttribute: "rubbishBagsCollected",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "River Action Group Event",
          attributes: [
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            thresholdAttr({
              attributeId: "numberOfVolunteers",
              label: "Number of Volunteers",
              buckets: [
                thresholdBucket(0, marker.numeric(0.0)),
                thresholdBucket(10, marker.numeric(0.3)),
                thresholdBucket(30, marker.numeric(0.5)),
                thresholdBucket(50, marker.numeric(0.8)),
                thresholdBucket(100, marker.numeric(1.0)),
              ],
            }),
            countedLitterAttr(
              "rubbishBagsCollected",
              "Rubbish Collected",
              "bags"
            ),
            countedLitterAttr(
              "largeRubbishCount",
              "Large Rubbish Collected",
              "items"
            ),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("thames21BadgedGroupEvent"),
            }),
            surveyAttr("surveyId", "Survey"),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
      ],
    }),
  ],
});
