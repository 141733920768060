// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtFordsLandUse =
  | "PermanentGrassland"
  | "TemporaryGrassland"
  | "WoodlandOrForestry"
  | "Arable"
  | "FarmyardInfrastructure"
  | "Other";

const PermanentGrassland: WrtFordsLandUse = "PermanentGrassland";
const TemporaryGrassland: WrtFordsLandUse = "TemporaryGrassland";
const WoodlandOrForestry: WrtFordsLandUse = "WoodlandOrForestry";
const Arable: WrtFordsLandUse = "Arable";
const FarmyardInfrastructure: WrtFordsLandUse = "FarmyardInfrastructure";
const Other: WrtFordsLandUse = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtFordsLandUseEnum = {
  ...createEnum("WrtFordsLandUse", [
    [PermanentGrassland, "Permanent grassland"],
    [TemporaryGrassland, "Temporary grassland"],
    [WoodlandOrForestry, "Woodland / forestry"],
    [Arable, "Arable"],
    [FarmyardInfrastructure, "Farmyard infrastructure"],
    [Other, "Other"],
  ]),
  PermanentGrassland,
  TemporaryGrassland,
  WoodlandOrForestry,
  Arable,
  FarmyardInfrastructure,
  Other,
};

export default WrtFordsLandUseEnum;
