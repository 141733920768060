// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21SlowFlowPondType = "Offline" | "Online";

const Offline: Thames21SlowFlowPondType = "Offline";
const Online: Thames21SlowFlowPondType = "Online";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21SlowFlowPondTypeEnum = {
  ...createEnum("Thames21SlowFlowPondType", [
    [Offline, "Offline pond, disconnected from flow paths"],
    [Online, "Online pond, connected to flow paths"],
  ]),
  Offline,
  Online,
};

export default Thames21SlowFlowPondTypeEnum;
