import { IO } from "@cartographerio/io";
import {
  ApiParams,
  BannerMessage,
  isBannerMessage,
  isSearchResults,
  SearchResults,
  WorkspaceRef,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";

const basePath = "/workspace/cta/v1";

export function search(
  apiParams: ApiParams,
  workspace: WorkspaceRef
): IO<SearchResults<BannerMessage>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${workspace}` } })
    .flatMap(
      contentAs(
        "SearchResults<BannerMessage>",
        isSearchResults(isBannerMessage)
      )
    );
}
