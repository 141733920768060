import { AttachmentCreate, AttachmentUpdate } from "./types.generated";

// Typesafe version of data.append(...):
function put(data: FormData, key: string, value: string): void {
  data.append(key, value);
}

// Typesafe version of data.append(...):
function optPut(
  data: FormData,
  key: string,
  value: string | null | undefined
): void {
  if (typeof value === "string") {
    data.append(key, value);
  }
}

export function attachmentCreateFormData(create: AttachmentCreate): FormData {
  const data = new FormData();
  put(data, "surveyId", create.surveyId);
  put(data, "folder", create.folder.folder);
  optPut(data, "title", create.title);
  optPut(data, "description", create.description);
  optPut(data, "author.userId", create.author?.userId);
  optPut(data, "author.screenName", create.author?.screenName);
  optPut(data, "license", create.license);
  return data;
}

export function attachmentUpdateFormData(update: AttachmentUpdate): FormData {
  const data = new FormData();
  optPut(data, "title", update.title);
  optPut(data, "description", update.description);
  optPut(data, "author.userId", update.author?.userId);
  optPut(data, "author.screenName", update.author?.screenName);
  optPut(data, "license", update.license);
  return data;
}
