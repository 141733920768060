// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterqualityChannelVegetation =
  | "None"
  | "Extensive"
  | "Present"
  | "NotVisible"
  | "Other";

const None: ArrtWaterqualityChannelVegetation = "None";
const Extensive: ArrtWaterqualityChannelVegetation = "Extensive";
const Present: ArrtWaterqualityChannelVegetation = "Present";
const NotVisible: ArrtWaterqualityChannelVegetation = "NotVisible";
const Other: ArrtWaterqualityChannelVegetation = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterqualityChannelVegetationEnum = {
  ...createEnum("ArrtWaterqualityChannelVegetation", [
    [None, "None"],
    [Extensive, "Extensive"],
    [Present, "Present"],
    [NotVisible, "Not Visible"],
    [Other, "Other"],
  ]),
  None,
  Extensive,
  Present,
  NotVisible,
  Other,
};

export default ArrtWaterqualityChannelVegetationEnum;
