// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityAmmonia = "A0" | "A05" | "A1" | "A3" | "A6";

const A0: EsrtWaterQualityAmmonia = "A0";
const A05: EsrtWaterQualityAmmonia = "A05";
const A1: EsrtWaterQualityAmmonia = "A1";
const A3: EsrtWaterQualityAmmonia = "A3";
const A6: EsrtWaterQualityAmmonia = "A6";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityAmmoniaEnum = {
  ...createEnum("EsrtWaterQualityAmmonia", [
    [A0, "0"],
    [A05, "0.5"],
    [A1, "1.0"],
    [A3, "3.0"],
    [A6, "6.0"],
  ]),
  A0,
  A05,
  A1,
  A3,
  A6,
};

export default EsrtWaterQualityAmmoniaEnum;
