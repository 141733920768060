import { AttachmentVariantId } from "./types.generated";

export interface AttachmentVariant {
  id: AttachmentVariantId;
  width?: number;
  height?: number;
}

const original: AttachmentVariant = {
  id: "original",
};

const preview: AttachmentVariant = {
  id: "preview",
};

const smallThumbnail: AttachmentVariant = {
  id: "320x240",
  width: 320,
  height: 240,
};

const mediumThumbnail: AttachmentVariant = {
  id: "640x480",
  width: 640,
  height: 480,
};

const largeThumbnail: AttachmentVariant = {
  id: "1280x960",
  width: 1280,
  height: 960,
};

export const attachmentVariant = {
  original,
  preview,
  smallThumbnail,
  mediumThumbnail,
  largeThumbnail,
};
