// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusLitterDisposal = "litterBins" | "dogWasteBins";

const LitterBins: EcostatusLitterDisposal = "litterBins";
const DogWasteBins: EcostatusLitterDisposal = "dogWasteBins";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusLitterDisposalEnum = {
  ...createEnum("EcostatusLitterDisposal", [
    [LitterBins, "Litter bins"],
    [DogWasteBins, "Dog waste bins"],
  ]),
  LitterBins,
  DogWasteBins,
};

export default EcostatusLitterDisposalEnum;
