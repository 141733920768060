import {
  attributeGroup,
  booleanAttr,
  enumAttr,
  lineLayer,
  lookupBucket,
  MapSource,
  marker,
  numberAttr,
  pointLayer,
  remoteTileSource,
  standardAttribution,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  NeRiverTypesConfidenceEnum,
  NeRiverTypesFeatureEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import {
  proposedChalkRiversModificationsLayer,
  publishedChalkRiversLayer,
} from "./NeChalkRivers";
import { MapBaseEnum } from "@cartographerio/types";

const predHabSizeMul = 0.5; // smaller markers for ne_pred_hab_map_uk data source
const predhabStrokeMul = 0.25; // thinner strokes for ne_pred_hab_map_uk data source

const chalkRiversSizeMul = 0.75;
const chalkRiversStrokeMul = 0.25;

const nePredHabMapUkSource: MapSource = remoteTileSource({
  url: "mapbox://cartographerio.ne_pred_hab_map_uk",
  sourceLayer: "ne_pred_hab_map_uk",
  attribution: [
    standardAttribution({
      what: "Predicted Habitat Map",
      who: "Natural England",
      from: "November 2022",
      url: "https://priorityhabitats.org",
    }),
  ],
});

const featureBuckets = [
  lookupBucket(true, marker.hue(90), "Yes"),
  lookupBucket(false, marker.empty, "No"),
];

export const proposedRiverTypesModificationsLayer = lineLayer({
  layerId: unsafeMapLayerId("neRiverTypes"),
  title: "Proposed River Types",
  source: cartographerSourceWithDefaults({
    layerId: unsafeMapLayerId("neRiverTypes"),
    simplifyZoom: 10,
    attribution: [],
  }),
  attributes: [
    attributeGroup({
      label: "Dominant Type",
      attributes: [
        enumAttr({
          attributeId: `dominant`,
          label: "Dominant Type",
          enum: NeRiverTypesFeatureEnum,
        }),
      ],
    }),
    attributeGroup({
      label: "Applicable Types",
      attributes: NeRiverTypesFeatureEnum.entries.flatMap(({ value, label }) =>
        booleanAttr({
          attributeId: `selected_${value}`,
          label,
          buckets: featureBuckets,
        })
      ),
    }),
    attributeGroup({
      label: "Confidence",
      attributes: NeRiverTypesFeatureEnum.entries.flatMap(({ value, label }) =>
        enumAttr({
          attributeId: `confidence_${value}`,
          label,
          enum: NeRiverTypesConfidenceEnum,
        })
      ),
    }),
  ],
});

export const NeRiverTypes = mapSchemaWithDefaults({
  mapId: unsafeMapId("neRiverTypes"),
  title: "River Types",
  defaultBase: MapBaseEnum.Satellite,
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("h3260"),
      title: "Habitats Directive H3260",
      source: nePredHabMapUkSource,
      attributes: [
        attributeGroup({
          attributes: [
            numberAttr({
              attributeId: "h3260_high",
              label: "High Certainty Only",
              propertyName: "h3260",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#f7f", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
              ],
            }),
            numberAttr({
              attributeId: "h3260_all",
              label: "All Certainties",
              propertyName: "h3260",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#f7f", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
                lookupBucket(
                  2,
                  marker.fromColor("#b5b", predHabSizeMul, predhabStrokeMul),
                  "Medium"
                ),
                lookupBucket(
                  1,
                  marker.fromColor("#848", predHabSizeMul, predhabStrokeMul),
                  "Low"
                ),
              ],
            }),
          ],
        }),
      ],
    }),
    publishedChalkRiversLayer(
      unsafeMapLayerId("chalkRivers"),
      "Chalk Rivers",
      marker.fromColor("#ff0", chalkRiversSizeMul, chalkRiversStrokeMul),
      marker.fromColor("#aa0", chalkRiversSizeMul, chalkRiversStrokeMul)
    ),
    pointLayer({
      layerId: unsafeMapLayerId("hw"),
      title: "Headwater Streams",
      source: nePredHabMapUkSource,
      attributes: [
        attributeGroup({
          attributes: [
            numberAttr({
              attributeId: "hw_high",
              label: "High Certainty Only",
              propertyName: "hw",
              buckets: [
                lookupBucket(
                  1,
                  marker.fromColor("#0ff", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
              ],
            }),
            numberAttr({
              attributeId: "hw_all",
              label: "All Certainties",
              propertyName: "hw",
              buckets: [
                lookupBucket(
                  1,
                  marker.fromColor("#0ff", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
                lookupBucket(
                  0,
                  marker.fromColor("#0aa", predHabSizeMul, predhabStrokeMul),
                  "Low"
                ),
              ],
            }),
          ],
        }),
      ],
    }),
    pointLayer({
      layerId: unsafeMapLayerId("pccrlsas"),
      title: "Active Shingle Rivers",
      source: nePredHabMapUkSource,
      attributes: [
        attributeGroup({
          attributes: [
            numberAttr({
              attributeId: "pccrlsas_high",
              label: "High Certainty Only",
              propertyName: "pccrlsas",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#fff", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
              ],
            }),
            numberAttr({
              attributeId: "pccrlsas_all",
              label: "All Certainties",
              propertyName: "pccrlsas",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#fff", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
                lookupBucket(
                  2,
                  marker.fromColor("#ccc", predHabSizeMul, predhabStrokeMul),
                  "Medium"
                ),
                lookupBucket(
                  1,
                  marker.fromColor("#aaa", predHabSizeMul, predhabStrokeMul),
                  "Low"
                ),
              ],
            }),
          ],
        }),
      ],
    }),
    pointLayer({
      layerId: unsafeMapLayerId("pccrlc22a"),
      title: "C2.2a High Energy",
      source: nePredHabMapUkSource,
      attributes: [
        attributeGroup({
          attributes: [
            numberAttr({
              attributeId: "pccrlc22a_high",
              label: "High Certainty Only",
              propertyName: "pccrlc22a",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#f77", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
              ],
            }),
            numberAttr({
              attributeId: "pccrlc22a_all",
              label: "All Certainties",
              propertyName: "pccrlc22a",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#f77", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
                lookupBucket(
                  2,
                  marker.fromColor("#b55", predHabSizeMul, predhabStrokeMul),
                  "Medium"
                ),
                lookupBucket(
                  1,
                  marker.fromColor("#844", predHabSizeMul, predhabStrokeMul),
                  "Low"
                ),
              ],
            }),
          ],
        }),
      ],
    }),
    pointLayer({
      layerId: unsafeMapLayerId("pccrlc22b"),
      title: "C2.2b Moderate Energy",
      source: nePredHabMapUkSource,
      attributes: [
        attributeGroup({
          attributes: [
            numberAttr({
              attributeId: "pccrlc22b_high",
              label: "High Certainty Only",
              propertyName: "pccrlc22b",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#0f0", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
              ],
            }),
            numberAttr({
              attributeId: "pccrlc22b_all",
              label: "All Certainties",
              propertyName: "pccrlc22b",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#0f0", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
                lookupBucket(
                  2,
                  marker.fromColor("#0b0", predHabSizeMul, predhabStrokeMul),
                  "Medium"
                ),
                lookupBucket(
                  1,
                  marker.fromColor("#080", predHabSizeMul, predhabStrokeMul),
                  "Low"
                ),
              ],
            }),
          ],
        }),
      ],
    }),
    pointLayer({
      layerId: unsafeMapLayerId("pccrlc23"),
      title: "C2.3 Low Energy",
      source: nePredHabMapUkSource,
      attributes: [
        attributeGroup({
          attributes: [
            numberAttr({
              attributeId: "pccrlc23_high",
              label: "High Certainty Only",
              propertyName: "pccrlc23",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#77f", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
              ],
            }),
            numberAttr({
              attributeId: "pccrlc23_all",
              label: "All Certainties",
              propertyName: "pccrlc23",
              buckets: [
                lookupBucket(
                  3,
                  marker.fromColor("#77f", predHabSizeMul, predhabStrokeMul),
                  "High"
                ),
                lookupBucket(
                  2,
                  marker.fromColor("#55b", predHabSizeMul, predhabStrokeMul),
                  "Medium"
                ),
                lookupBucket(
                  1,
                  marker.fromColor("#448", predHabSizeMul, predhabStrokeMul),
                  "Low"
                ),
              ],
            }),
          ],
        }),
      ],
    }),
    pointLayer({
      layerId: unsafeMapLayerId("tidal"),
      title: "Tidally Influenced Rivers",
      source: nePredHabMapUkSource,
      attributes: [
        attributeGroup({
          attributes: [
            numberAttr({
              attributeId: "tidal",
              label: "Tidally Influenced Rivers",
              propertyName: "tidal",
              buckets: [
                lookupBucket(
                  1,
                  marker.fromColor("#33f", predHabSizeMul, predhabStrokeMul),
                  "Tidally Influenced"
                ),
              ],
            }),
          ],
        }),
      ],
    }),
    proposedChalkRiversModificationsLayer,
    proposedRiverTypesModificationsLayer,
  ],
});
