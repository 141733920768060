/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  UserId,
  isUserId,
  WorkspaceAccessLevel,
  isWorkspaceAccessLevel,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";
import { Tagged, bridgesIsTaggedHack as isTagged } from "@cartographerio/util";

export type Role = Tagged<"Role">;

export type QualificationRole = Tagged<"QualificationRole">;

export type GlobalRoleName = "MapViewer" | "Admin" | "Superuser";

export type WorkspaceRoleName = "Disabled" | "Active" | "Admin" | "Owner";

export type ProjectRoleName =
  | "Member"
  | "Surveyor"
  | "Approver"
  | "Coordinator";

export type TeamRoleName = "Member" | "Surveyor" | "Approver" | "Coordinator";

export type QualificationRoleName =
  | "Expired"
  | "Trainee"
  | "Qualified"
  | "Trainer"
  | "Coordinator";

export interface Always {}

export interface Never {
  reason?: string | null;
}

export interface And {
  args: PermissionCheck[];
}

export interface Or {
  args: PermissionCheck[];
}

export interface HasRole {
  role: Role;
}

export interface CanGrantRole {
  role: Role;
}

export interface HasQualification {
  role: QualificationRole;
}

export interface CanGrantQualification {
  role: QualificationRole;
}

export interface HasAccount {}

export interface HasUserId {
  userId: UserId;
}

export interface InAnyWorkspace {
  name: WorkspaceRoleName;
}

export interface Named {
  name: string;
  check: PermissionCheck;
}

export interface WorkspaceAccess {
  workspaceId: WorkspaceId;
  minAccess: WorkspaceAccessLevel;
}

export type PermissionCheck =
  | ({ type: "Always" } & Always)
  | ({ type: "And" } & And)
  | ({ type: "CanGrantQualification" } & CanGrantQualification)
  | ({ type: "CanGrantRole" } & CanGrantRole)
  | ({ type: "HasAccount" } & HasAccount)
  | ({ type: "HasQualification" } & HasQualification)
  | ({ type: "HasRole" } & HasRole)
  | ({ type: "HasUserId" } & HasUserId)
  | ({ type: "InAnyWorkspace" } & InAnyWorkspace)
  | ({ type: "Named" } & Named)
  | ({ type: "Never" } & Never)
  | ({ type: "Or" } & Or)
  | ({ type: "WorkspaceAccess" } & WorkspaceAccess);

export const isRole = (v: any): v is Role => {
  return isTagged((a0: any): a0 is "Role" => a0 === "Role")(v);
};

export const isQualificationRole = (v: any): v is QualificationRole => {
  return isTagged(
    (a0: any): a0 is "QualificationRole" => a0 === "QualificationRole"
  )(v);
};

export const isGlobalRoleName = (v: any): v is GlobalRoleName => {
  return v === "MapViewer" || v === "Admin" || v === "Superuser";
};

export const isWorkspaceRoleName = (v: any): v is WorkspaceRoleName => {
  return v === "Disabled" || v === "Active" || v === "Admin" || v === "Owner";
};

export const isProjectRoleName = (v: any): v is ProjectRoleName => {
  return (
    v === "Member" ||
    v === "Surveyor" ||
    v === "Approver" ||
    v === "Coordinator"
  );
};

export const isTeamRoleName = (v: any): v is TeamRoleName => {
  return (
    v === "Member" ||
    v === "Surveyor" ||
    v === "Approver" ||
    v === "Coordinator"
  );
};

export const isQualificationRoleName = (v: any): v is QualificationRoleName => {
  return (
    v === "Expired" ||
    v === "Trainee" ||
    v === "Qualified" ||
    v === "Trainer" ||
    v === "Coordinator"
  );
};

export const isAlways = (v: any): v is Always => {
  return typeof v === "object" && v != null;
};

export const isNever = (v: any): v is Never => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("reason" in v) || typeof v.reason === "string" || v.reason === null)
  );
};

export const isAnd = (v: any): v is And => {
  return (
    typeof v === "object" &&
    v != null &&
    "args" in v &&
    Array.isArray(v.args) &&
    v.args.every((i: any) => isPermissionCheck(i))
  );
};

export const isOr = (v: any): v is Or => {
  return (
    typeof v === "object" &&
    v != null &&
    "args" in v &&
    Array.isArray(v.args) &&
    v.args.every((i: any) => isPermissionCheck(i))
  );
};

export const isHasRole = (v: any): v is HasRole => {
  return typeof v === "object" && v != null && "role" in v && isRole(v.role);
};

export const isCanGrantRole = (v: any): v is CanGrantRole => {
  return typeof v === "object" && v != null && "role" in v && isRole(v.role);
};

export const isHasQualification = (v: any): v is HasQualification => {
  return (
    typeof v === "object" &&
    v != null &&
    "role" in v &&
    isQualificationRole(v.role)
  );
};

export const isCanGrantQualification = (v: any): v is CanGrantQualification => {
  return (
    typeof v === "object" &&
    v != null &&
    "role" in v &&
    isQualificationRole(v.role)
  );
};

export const isHasAccount = (v: any): v is HasAccount => {
  return typeof v === "object" && v != null;
};

export const isHasUserId = (v: any): v is HasUserId => {
  return (
    typeof v === "object" && v != null && "userId" in v && isUserId(v.userId)
  );
};

export const isInAnyWorkspace = (v: any): v is InAnyWorkspace => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    isWorkspaceRoleName(v.name)
  );
};

export const isNamed = (v: any): v is Named => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "check" in v &&
    isPermissionCheck(v.check)
  );
};

export const isWorkspaceAccess = (v: any): v is WorkspaceAccess => {
  return (
    typeof v === "object" &&
    v != null &&
    "workspaceId" in v &&
    isWorkspaceId(v.workspaceId) &&
    "minAccess" in v &&
    isWorkspaceAccessLevel(v.minAccess)
  );
};

export const isPermissionCheck = (v: any): v is PermissionCheck => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "Always" && isAlways(v)) ||
      (v.type === "And" && isAnd(v)) ||
      (v.type === "CanGrantQualification" && isCanGrantQualification(v)) ||
      (v.type === "CanGrantRole" && isCanGrantRole(v)) ||
      (v.type === "HasAccount" && isHasAccount(v)) ||
      (v.type === "HasQualification" && isHasQualification(v)) ||
      (v.type === "HasRole" && isHasRole(v)) ||
      (v.type === "HasUserId" && isHasUserId(v)) ||
      (v.type === "InAnyWorkspace" && isInAnyWorkspace(v)) ||
      (v.type === "Named" && isNamed(v)) ||
      (v.type === "Never" && isNever(v)) ||
      (v.type === "Or" && isOr(v)) ||
      (v.type === "WorkspaceAccess" && isWorkspaceAccess(v)))
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
