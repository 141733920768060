import {
  AdminNote,
  BannerMessage,
  KnownWorkspaceFeature,
  KnownWorkspaceFeatureEnum,
  Message,
  Workspace,
  WorkspaceAccessLevelEnum,
  WorkspaceEmailSettings,
  unsafeWorkspaceAlias,
} from "@cartographerio/types";
import outdent from "outdent";
import { ReactElement, useMemo } from "react";

import { splitMessages } from "../../schema/rule/errors";
import { workspaceErrorKeys } from "../../schema/workspace";
import { useControlledSubset } from "../hooks/useSubset";
import AdminContent from "./AdminContent";
import Checkbox from "./Checkbox";
import { WorkspaceEmailSettingsEditor } from "./EmailSettingsEditor";
import Fieldset from "./Fieldset";
import MarkdownEditor from "./MarkdownEditor";
import MessageFormControl from "./MessageFormControl";
import Select from "./Select";
import Spaced from "./Spaced";
import TestTarget from "./TestTarget";
import TextField from "./TextField";

interface WorkspaceEditorProps {
  workspace: Workspace;
  onWorkspaceChange: (value: Workspace) => void;
  workspaceMessages: Message[];

  banner?: BannerMessage;
  onBannerChange?: (value: BannerMessage) => void;
  bannerMessages: Message[];

  adminNote?: AdminNote | null;
  onAdminNoteChange?: (value: AdminNote) => void;
  adminNoteMessages: Message[];

  emailSettings: WorkspaceEmailSettings;
  onEmailSettingsChange?: (value: WorkspaceEmailSettings) => void;
  emailSettingsMessages: Message[];
}

export default function WorkspaceEditor(
  props: WorkspaceEditorProps
): ReactElement {
  const {
    workspace,
    onWorkspaceChange,
    workspaceMessages,

    banner,
    onBannerChange,
    bannerMessages,

    adminNote,
    onAdminNoteChange,
    adminNoteMessages,

    emailSettings,
    onEmailSettingsChange,
    emailSettingsMessages,
  } = props;

  const errors = useMemo(
    () => splitMessages(workspaceMessages, workspaceErrorKeys),
    [workspaceMessages]
  );

  const { has: hasFeature, toggle: toggleFeature } = useControlledSubset(
    workspace.features,
    features => onWorkspaceChange({ ...workspace, features })
  );

  return (
    <Spaced spacing="8">
      <Fieldset legend="Workspace Settings">
        <MessageFormControl
          label="Name"
          messages={errors.name}
          help={NAME_HELP}
        >
          <TextField.String
            defaultValue={workspace.name}
            onChange={name => onWorkspaceChange({ ...workspace, name })}
          />
        </MessageFormControl>

        <MessageFormControl
          label="Alias"
          messages={errors.alias}
          help={ALIAS_HELP}
        >
          <TextField.String
            defaultValue={workspace.alias}
            onChange={alias =>
              onWorkspaceChange({
                ...workspace,
                alias: unsafeWorkspaceAlias(alias),
              })
            }
            disabled={hasFeature(KnownWorkspaceFeatureEnum.LockAlias)}
          />
        </MessageFormControl>

        <MessageFormControl label="Logo" messages={[]} help={LOGO_HELP}>
          <TextField.String
            defaultValue={workspace.logo ?? undefined}
            onChange={logo => onWorkspaceChange({ ...workspace, logo })}
          />
        </MessageFormControl>
      </Fieldset>

      <AdminContent fallback={<TestTarget testId="access-level-disabled" />}>
        <Fieldset
          legend="Access Level"
          help={ACCESS_LEVEL_HELP}
          helpAppearance="popover"
        >
          <Select.Standard
            value={workspace.accessLevel}
            options={WorkspaceAccessLevelEnum.entries}
            onChange={accessLevel =>
              onWorkspaceChange({ ...workspace, accessLevel })
            }
          />
        </Fieldset>
      </AdminContent>

      <AdminContent fallback={<TestTarget testId="features-disabled" />}>
        <Fieldset
          legend="Features"
          help={FEATURES_HELP}
          helpAppearance="popover"
        >
          <Spaced spacing="1">
            {KnownWorkspaceFeatureEnum.entries.map(({ value, label }) => (
              <Checkbox
                key={value}
                checkboxLabel={label}
                checkboxHelp={FEATURE_HELP[value]}
                value={hasFeature(value)}
                onChange={checked => toggleFeature(value, checked)}
              />
            ))}
          </Spaced>
        </Fieldset>
      </AdminContent>

      {banner != null && (
        <Fieldset legend="Banner Message" help={BANNER_HELP}>
          <MessageFormControl messages={bannerMessages}>
            <MarkdownEditor
              value={banner.text}
              onChange={text => onBannerChange?.({ ...banner, text })}
            />
          </MessageFormControl>
        </Fieldset>
      )}

      <AdminContent isSuperuser={true}>
        <Fieldset legend="Admin Notes">
          <MessageFormControl messages={adminNoteMessages}>
            <MarkdownEditor
              value={adminNote?.text ?? ""}
              onChange={text => onAdminNoteChange?.({ ...adminNote, text })}
            />
          </MessageFormControl>
        </Fieldset>
      </AdminContent>

      <Fieldset legend="Workspace Operator" help={OPERATOR_HELP}>
        <MessageFormControl
          label="Operator Name"
          messages={[]}
          help={OPERATOR_NAME_HELP}
        >
          <TextField.NullableString
            defaultValue={workspace.operator ?? undefined}
            onChange={operator => onWorkspaceChange({ ...workspace, operator })}
          />
        </MessageFormControl>

        <MessageFormControl
          label="Operator Homepage"
          messages={[]}
          help={OPERATOR_HOMEPAGE_HELP}
        >
          <TextField.NullableString
            defaultValue={workspace.homepage ?? undefined}
            onChange={homepage => onWorkspaceChange({ ...workspace, homepage })}
          />
        </MessageFormControl>

        <MessageFormControl
          label="Operator Privacy Policy"
          messages={errors.privacyPolicy}
          help={OPERATOR_PRIVACY_POLICY_HELP}
        >
          <TextField.NullableString
            defaultValue={workspace.privacyPolicy ?? undefined}
            onChange={privacyPolicy =>
              onWorkspaceChange({ ...workspace, privacyPolicy })
            }
          />
        </MessageFormControl>
      </Fieldset>

      <Fieldset legend="Email Settings">
        <WorkspaceEmailSettingsEditor
          value={emailSettings}
          onChange={onEmailSettingsChange}
          messages={emailSettingsMessages}
        />
      </Fieldset>
    </Spaced>
  );
}

const ACCESS_LEVEL_HELP = outdent`
  Restrict access, e.g. in the event of a billing dispute:

  - *Full Access* - people can use the workspace as normal;
  - *Read-Only Access* - prevents adding/editing surveys/attachments;
  - *No Access* - prevents all access to the workspace.
`;

const NAME_HELP = outdent`
  The name of the workspace.
  Normally the name of a company that owns the workspace or the project being recorded there.

  Appears in the top left of the workspace web site and elsewhere.
`;

const ALIAS_HELP = outdent`
  A short name that appears in web addresses that point to the workspace.
  Can only contain lowerase letters and numbers.

  For example \`myworkspace\` in \`https://app.cartographer.io/ws/myworkspace\`.
`;

const LOGO_HELP = outdent`
  The web address of a square logo to use for the workspace.
`;

const BANNER_HELP = outdent`
  A message appearing near the top of the workspace home page.
  Use this for announcements and key contact information,
  or to place links to important web sites and documentation.
`;

const OPERATOR_HELP = outdent`
  The *Operator* of a workspace is the company or organisation that runs it.

  The Workspace Operator is referred to in Cartographer's [Platform Terms](https://cartographer.io/legal/platform-terms)
  with respect to data and personal data collected in the workspace.
  `;

const OPERATOR_NAME_HELP = outdent`
  The name of the Operator appears in the Cartographer web site footer and elsewhere.
`;

const OPERATOR_HOMEPAGE_HELP = outdent`
  The web address of the Workspace Operator's main web page.
  Appears in the Cartographer web site footer and elsewhere.
`;

const OPERATOR_PRIVACY_POLICY_HELP = outdent`
  The web address of the Workspace Operator's privacy policy.
  Appears in the Cartographer web site footer and elsewhere.

  This privacy policy is referred to in Cartographer's [Platform Terms](https://cartographer.io/legal/platform-terms)
  with respect to the processing of workspace members' personal data.
`;

const FEATURES_HELP = outdent`
Specific features that impact different aspects of Cartographer.
Some features are tied to specific plans - these will be selected/deselected
automatically when the workspace owner changes plans in Billing Settings.
`;

const FEATURE_HELP: Record<KnownWorkspaceFeature, string> = {
  EnableMobile: "Enable this workspace in the mobile app.",
  OfflineMaps: "Enable offline caching of map data in the mobile app.",
  EnableArcgisIntegration: "Enable the ArcGIS integration in project settings.",
  EnableInvitationCodes:
    "Enable invitation codes in workspace, project, and team settings.",
  EnableTeams: "Enable teams in workspace and project settings.",
  LockAlias:
    "Prevent non-superusers editing this workspace's alias (useful if it's hard-coded somewhere and changing it may break things).",
  PreferTeams:
    "Break things down by team instead of by project in various parts of the UI.",
};
