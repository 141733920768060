// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type StdInvasiveSpeciesTreatment =
  | "None"
  | "Sprayed"
  | "StemInjected"
  | "StrimmedOrCut"
  | "HandPulled"
  | "Grazed"
  | "DugOut"
  | "Dredged"
  | "Unknown";

const None: StdInvasiveSpeciesTreatment = "None";
const Sprayed: StdInvasiveSpeciesTreatment = "Sprayed";
const StemInjected: StdInvasiveSpeciesTreatment = "StemInjected";
const StrimmedOrCut: StdInvasiveSpeciesTreatment = "StrimmedOrCut";
const HandPulled: StdInvasiveSpeciesTreatment = "HandPulled";
const Grazed: StdInvasiveSpeciesTreatment = "Grazed";
const DugOut: StdInvasiveSpeciesTreatment = "DugOut";
const Dredged: StdInvasiveSpeciesTreatment = "Dredged";
const Unknown: StdInvasiveSpeciesTreatment = "Unknown";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StdInvasiveSpeciesTreatmentEnum = {
  ...createEnum("StdInvasiveSpeciesTreatment", [
    [None, "None"],
    [Sprayed, "Sprayed"],
    [StemInjected, "Stem Injected"],
    [StrimmedOrCut, "Strimmed or Cut"],
    [HandPulled, "Hand Pulled"],
    [Grazed, "Grazed"],
    [DugOut, "Dug Out"],
    [Dredged, "Dredged"],
    [Unknown, "Don't Know"],
  ]),
  None,
  Sprayed,
  StemInjected,
  StrimmedOrCut,
  HandPulled,
  Grazed,
  DugOut,
  Dredged,
  Unknown,
};

export default StdInvasiveSpeciesTreatmentEnum;
