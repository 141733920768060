// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesFragmentation = "LF" | "MF" | "HF";

const LF: MrsMorphEstuariesFragmentation = "LF";
const MF: MrsMorphEstuariesFragmentation = "MF";
const HF: MrsMorphEstuariesFragmentation = "HF";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesFragmentationEnum = {
  ...createEnum("MrsMorphEstuariesFragmentation", [
    [LF, "Low fragmentation"],
    [MF, "Medium fragmentation"],
    [HF, "High fragmentation"],
  ]),
  LF,
  MF,
  HF,
};

export default MrsMorphEstuariesFragmentationEnum;
