import { isOtherSpecify, OtherSpecify } from "@cartographerio/topo-form";
import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isArrayOf,
  isBoolean,
  isNullable,
  isNumber,
  isObject,
  isString,
  isUnknown,
} from "@cartographerio/guard";
import {
  EsrtWaterQualityAmmonia,
  EsrtWaterQualityAmmoniaEnum,
  EsrtWaterQualityBankVegetation,
  EsrtWaterQualityBankVegetationEnum,
  EsrtWaterQualityEstimatedDepth,
  EsrtWaterQualityEstimatedDepthEnum,
  EsrtWaterQualityEstimatedWidth,
  EsrtWaterQualityEstimatedWidthEnum,
  EsrtWaterQualityFlowLevel,
  EsrtWaterQualityFlowLevelEnum,
  EsrtWaterQualityFlowObstacle,
  EsrtWaterQualityFlowObstacleEnum,
  EsrtWaterQualityInvasivePlant,
  EsrtWaterQualityInvasivePlantEnum,
  EsrtWaterQualityLandUse,
  EsrtWaterQualityLandUseEnum,
  EsrtWaterQualityNitrate,
  EsrtWaterQualityNitrateEnum,
  EsrtWaterQualityPhosphate,
  EsrtWaterQualityPhosphateEnum,
  EsrtWaterQualityPollutionEvidence,
  EsrtWaterQualityPollutionEvidenceEnum,
  EsrtWaterQualityPollutionSource,
  EsrtWaterQualityPollutionSourceEnum,
  EsrtWaterQualityPublicAccess,
  EsrtWaterQualityPublicAccessEnum,
  EsrtWaterQualityRainfall,
  EsrtWaterQualityRainfallEnum,
  EsrtWaterQualityRecreationalActivity,
  EsrtWaterQualityRecreationalActivityEnum,
  EsrtWaterQualityRiverbedComposition,
  EsrtWaterQualityRiverbedCompositionEnum,
  EsrtWaterQualityWaterbodyType,
  EsrtWaterQualityWaterbodyTypeEnum,
  EsrtWaterQualityWaterLevel,
  EsrtWaterQualityWaterLevelEnum,
  EsrtWaterQualityWildlife,
  EsrtWaterQualityWildlifeEnum,
} from "@cartographerio/inventory-enums";
import {
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  Timestamp,
} from "@cartographerio/types";

export interface PartialData {
  recorded?: Timestamp | null;
  details: {
    river?: string | null;
    site?: string | null;
    location?: Point | null;
    publicAccess: OtherSpecify<EsrtWaterQualityPublicAccess | null>;
    waterbodyType: OtherSpecify<EsrtWaterQualityWaterbodyType | null>;
    recentRain?: EsrtWaterQualityRainfall | null;
    photographs: AttachmentFolder;
  };
  ecosystem: {
    landUse: OtherSpecify<EsrtWaterQualityLandUse[]>;
    bankVegetation: OtherSpecify<EsrtWaterQualityBankVegetation[]>;
    invasivePlants: OtherSpecify<EsrtWaterQualityInvasivePlant[]>;
    wildlife: OtherSpecify<EsrtWaterQualityWildlife[]>;
    recreationalActivities: OtherSpecify<
      EsrtWaterQualityRecreationalActivity[]
    >;
  };
  pollution: {
    pollutionSources: OtherSpecify<EsrtWaterQualityPollutionSource[]>;
    pollutionEvidence: OtherSpecify<EsrtWaterQualityPollutionEvidence[]>;
  };
  riverChannel: {
    estimatedWidth?: EsrtWaterQualityEstimatedWidth | null;
    estimatedDepth?: EsrtWaterQualityEstimatedDepth | null;
    waterLevel?: EsrtWaterQualityWaterLevel | null;
    waterFlow?: EsrtWaterQualityFlowLevel | null;
    obstacles: EsrtWaterQualityFlowObstacle[];
    riverbedComposition: OtherSpecify<EsrtWaterQualityRiverbedComposition | null>;
    sampleTaken?: boolean | null;
    temperature?: number | null;
    dissolvedSolids?: number | null;
    turbidity?: number | null;
    ammonia?: EsrtWaterQualityAmmonia | null;
    phosphate?: EsrtWaterQualityPhosphate | null;
    nitrate?: EsrtWaterQualityNitrate | null;
    additionalTests: unknown[];
  };
  notes?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasKey(
      data,
      "details",
      (details: unknown): details is PartialData["details"] =>
        isObject(details) &&
        hasOptionalKey(details, "river", isNullable(isString)) &&
        hasOptionalKey(details, "site", isNullable(isString)) &&
        hasOptionalKey(details, "location", isNullable(isPoint)) &&
        hasKey(
          details,
          "publicAccess",
          isOtherSpecify(isNullable(EsrtWaterQualityPublicAccessEnum.isValue))
        ) &&
        hasKey(
          details,
          "waterbodyType",
          isOtherSpecify(isNullable(EsrtWaterQualityWaterbodyTypeEnum.isValue))
        ) &&
        hasKey(details, "photographs", isAttachmentFolder) &&
        hasOptionalKey(
          details,
          "recentRain",
          isNullable(EsrtWaterQualityRainfallEnum.isValue)
        )
    ) &&
    hasKey(
      data,
      "ecosystem",
      (ecosystem: unknown): ecosystem is PartialData["ecosystem"] =>
        isObject(ecosystem) &&
        hasKey(
          ecosystem,
          "landUse",
          isOtherSpecify(isArrayOf(EsrtWaterQualityLandUseEnum.isValue))
        ) &&
        hasKey(
          ecosystem,
          "bankVegetation",
          isOtherSpecify(isArrayOf(EsrtWaterQualityBankVegetationEnum.isValue))
        ) &&
        hasKey(
          ecosystem,
          "invasivePlants",
          isOtherSpecify(isArrayOf(EsrtWaterQualityInvasivePlantEnum.isValue))
        ) &&
        hasKey(
          ecosystem,
          "wildlife",
          isOtherSpecify(isArrayOf(EsrtWaterQualityWildlifeEnum.isValue))
        ) &&
        hasKey(
          ecosystem,
          "recreationalActivities",
          isOtherSpecify(
            isArrayOf(EsrtWaterQualityRecreationalActivityEnum.isValue)
          )
        )
    ) &&
    hasKey(
      data,
      "pollution",
      (pollution: unknown): pollution is PartialData["pollution"] =>
        isObject(pollution) &&
        hasKey(
          pollution,
          "pollutionSources",
          isOtherSpecify(isArrayOf(EsrtWaterQualityPollutionSourceEnum.isValue))
        ) &&
        hasKey(
          pollution,
          "pollutionEvidence",
          isOtherSpecify(
            isArrayOf(EsrtWaterQualityPollutionEvidenceEnum.isValue)
          )
        )
    ) &&
    hasKey(
      data,
      "riverChannel",
      (riverChannel: unknown): riverChannel is PartialData["riverChannel"] =>
        isObject(riverChannel) &&
        hasOptionalKey(
          riverChannel,
          "estimatedWidth",
          isNullable(EsrtWaterQualityEstimatedWidthEnum.isValue)
        ) &&
        hasOptionalKey(
          riverChannel,
          "estimatedDepth",
          isNullable(EsrtWaterQualityEstimatedDepthEnum.isValue)
        ) &&
        hasOptionalKey(
          riverChannel,
          "waterLevel",
          isNullable(EsrtWaterQualityWaterLevelEnum.isValue)
        ) &&
        hasOptionalKey(
          riverChannel,
          "waterFlow",
          isNullable(EsrtWaterQualityFlowLevelEnum.isValue)
        ) &&
        hasKey(
          riverChannel,
          "obstacles",
          isArrayOf(EsrtWaterQualityFlowObstacleEnum.isValue)
        ) &&
        hasKey(
          riverChannel,
          "riverbedComposition",
          isOtherSpecify(
            isNullable(EsrtWaterQualityRiverbedCompositionEnum.isValue)
          )
        ) &&
        hasOptionalKey(riverChannel, "sampleTaken", isNullable(isBoolean)) &&
        hasOptionalKey(riverChannel, "temperature", isNullable(isNumber)) &&
        hasOptionalKey(riverChannel, "dissolvedSolids", isNullable(isNumber)) &&
        hasOptionalKey(riverChannel, "turbidity", isNullable(isNumber)) &&
        hasOptionalKey(
          riverChannel,
          "ammonia",
          isNullable(EsrtWaterQualityAmmoniaEnum.isValue)
        ) &&
        hasOptionalKey(
          riverChannel,
          "phosphate",
          isNullable(EsrtWaterQualityPhosphateEnum.isValue)
        ) &&
        hasOptionalKey(
          riverChannel,
          "nitrate",
          isNullable(EsrtWaterQualityNitrateEnum.isValue)
        ) &&
        hasKey(riverChannel, "additionalTests", isArrayOf(isUnknown))
    ) &&
    hasOptionalKey(data, "notes", isNullable(isString))
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialEsrtWaterQualityData"
  )(data).map(data =>
    [
      data.details.river ?? "Unknown River",
      data.details.site ?? "Unknown Site",
      data.details.location
        ? safePointToNgr(data.details.location)
        : "Unknown NGR",
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialEsrtWaterQualityData"
  )(data).map(data => data.details.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialEsrtWaterQualityData"
  )(data).map(data => data.recorded ?? null);
}

const nullOtherSpecify = { selected: null, other: null };
const emptyArrayOtherSpecify = { selected: [], other: null };

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return Result.guard(
    isPartialData,
    "PartialEsrtWaterQualityData"
  )(data).map(data => ({
    ...data,
    recorded: null,
    details: {
      ...data.details,
      waterbodyType: nullOtherSpecify,
      recentRain: null,
    },
    ecosystem: {
      ...data.ecosystem,
      wildlife: emptyArrayOtherSpecify,
      recreationalActivities: emptyArrayOtherSpecify,
    },
    pollution: {
      ...data.pollution,
    },
    riverChannel: {
      ...data.riverChannel,
      waterLevel: null,
      waterFlow: null,
      sampleTaken: null,
      temperature: null,
      dissolvedSolids: null,
      turbidity: null,
      ammonia: null,
      phosphate: null,
      nitrate: null,
      additionalTests: [],
    },
    notes: null,
  }));
}
