import { Path } from "@cartographerio/topo-core";
import {
  Block,
  checkbox,
  Columns,
  columns2,
  columns3,
  Field,
  integerField,
  minValue,
  required,
  RequiredRule,
  section,
  select,
  SelectOption,
  SelectValue,
  textField,
  vstack,
} from "@cartographerio/topo-form";
import {
  MrsAtpe,
  MrsAtpeEnum,
  UrsApeEnum,
} from "@cartographerio/inventory-enums";
import { checkExhausted, Enum } from "@cartographerio/util";
import { flatten, isArray } from "lodash";

export interface MorphCheckboxProps {
  type: "checkbox";
  secondaryLabel?: string;
  checkboxLabel: string;
  image?: string;
  required?: RequiredRule | false;
  defaultValue?: boolean;
}

export interface MorphTextProps {
  type: "text";
  secondaryLabel?: string;
  image?: string;
  required?: RequiredRule | false;
  defaultValue?: string;
}

export interface MorphNumberProps {
  type: "number";
  secondaryLabel?: string;
  units?: string;
  help?: string;
  image?: string;
  required?: RequiredRule | false;
  defaultValue?: number;
}

export interface MorphSelectProps<A extends SelectValue> {
  type: "select";
  secondaryLabel?: string;
  help?: string;
  defaultImage?: string;
  imagePattern?: string;
  required?: RequiredRule | false;
  source: Enum<A> | SelectOption<A>[];
  showValues?: boolean;
  defaultValue?: A;
}

type SpecificSelectProps = Omit<
  Omit<Omit<MorphSelectProps<MrsAtpe>, "type">, "source">,
  "default"
>;

export interface MorphFieldsProps<
  A extends SelectValue,
  B extends SelectValue,
> {
  label: string;
  path: Path;
  help?: string;
  code?: MorphTextProps | MorphSelectProps<A>;
  abundance?: MorphNumberProps | MorphCheckboxProps | MorphSelectProps<B>;
  location?: MorphSelectProps<B>;
  field?: MorphFieldProps;
}

export function morphSelect<A extends SelectValue>(
  props: Omit<MorphSelectProps<A>, "type">
): MorphSelectProps<A> {
  return { ...props, type: "select" };
}

export function morphAtpeSelect(
  props: SpecificSelectProps = {}
): MorphSelectProps<MrsAtpe> {
  return morphSelect({
    source: MrsAtpeEnum,
    defaultValue: MrsAtpeEnum.Absent,
    required: required("info"),
    ...props,
  });
}

export function morphApeSelect(
  props: SpecificSelectProps = {}
): MorphSelectProps<MrsAtpe> {
  return morphSelect({
    source: UrsApeEnum,
    defaultValue: UrsApeEnum.Absent,
    required: required("info"),
    ...props,
  });
}

export function morphCheckbox(
  props: Omit<MorphCheckboxProps, "type">
): MorphCheckboxProps {
  return { ...props, type: "checkbox" };
}

export function morphText(
  props: Omit<MorphTextProps, "type"> = {}
): MorphTextProps {
  const { required: _required = required("info"), ...rest } = props;
  return { type: "text", required: _required, ...rest };
}

export function morphCount(
  props: Omit<MorphNumberProps, "type"> = {}
): MorphNumberProps {
  const {
    secondaryLabel = "Count",
    defaultValue = 0,
    required: _required = required("info"),
    ...rest
  } = props;
  return {
    secondaryLabel,
    defaultValue,
    required: _required,
    ...rest,
    type: "number",
  };
}

export function morphLength(
  props: Omit<MorphNumberProps, "type"> = {}
): MorphNumberProps {
  return { units: "m", ...props, type: "number" };
}

export type MorphFieldProps =
  | MorphSelectProps<SelectValue>
  | MorphCheckboxProps
  | MorphTextProps
  | MorphNumberProps;

function isPattern(image: string | undefined): image is string {
  return image != null && image.indexOf("*") >= 0;
}

const imageRoot = "https://media.cartographer.io/static/images/mrs-morph";

export function enumImages<A extends SelectValue>(
  source: Enum<A> | SelectOption<A>[],
  image: string | undefined
): SelectOption<A>[] {
  const entries = isArray(source) ? source : source.entries;

  return isPattern(image)
    ? entries.map(option => {
        const path = image.replace("*", `${option.value}`).toLowerCase();

        return {
          ...option,
          image: `${imageRoot}/${path}`,
        };
      })
    : entries;
}

export function fieldImage(image: string | undefined): string | undefined {
  return image == null ? undefined : `${imageRoot}/${image}`;
}

export function morphBanks<A extends SelectValue, B extends SelectValue>(
  props: MorphFieldsProps<A, B>
): Field[] {
  const { label, path, help } = props;

  function subfield(
    fieldPath: Path,
    field: MorphFieldProps,
    defaultSecondaryLabel: string = ""
  ): Field[] {
    switch (field.type) {
      case "select":
        return [
          select({
            label,
            secondaryLabel: `Left Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "leftBank"],
            options: enumImages(field.source, field?.imagePattern),
            showValues: field.showValues,
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            image: fieldImage(field.defaultImage),
            help,
          }),
          select({
            label,
            secondaryLabel: `Right Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "rightBank"],
            showValues: field.showValues,
            options: enumImages(field.source, field?.imagePattern),
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            image: fieldImage(field.defaultImage),
            help,
          }),
        ];

      case "number":
        return [
          integerField({
            label,
            secondaryLabel: `Left Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "leftBank"],
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            bounds: minValue(0, "error"),
            units: field.units,
            image: fieldImage(field.image),
            help,
          }),
          integerField({
            label,
            secondaryLabel: `Right Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "rightBank"],
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            units: field.units,
            bounds: minValue(0, "error"),
            image: fieldImage(field.image),
            help,
          }),
        ];

      case "text":
        return [
          textField({
            label,
            secondaryLabel: `Left Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "leftBank"],
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            image: fieldImage(field.image),
            help,
          }),
          textField({
            label,
            secondaryLabel: `Right Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "rightBank"],
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            image: fieldImage(field.image),
            help,
          }),
        ];

      case "checkbox":
        return [
          checkbox({
            label,
            secondaryLabel: `Left Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            checkboxLabel: field.checkboxLabel,
            path: [...path, ...fieldPath, "leftBank"],
            help,
            defaultValue: field.defaultValue,
          }),
          checkbox({
            label,
            secondaryLabel: `Right Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            checkboxLabel: field.checkboxLabel,
            path: [...path, ...fieldPath, "rightBank"],
            help,
            defaultValue: field.defaultValue,
          }),
        ];

      default:
        return checkExhausted(field);
    }
  }

  return [
    ...(props.code != null ? subfield(["code"], props.code) : []),
    ...(props.abundance != null
      ? subfield(["abundance"], props.abundance, "Abundance")
      : []),
    ...(props.location != null
      ? subfield(["location"], props.location, "Location")
      : []),
    ...(props.field != null ? subfield([], props.field) : []),
  ];
}

export function morphChannel<A extends SelectValue, B extends SelectValue>(
  props: MorphFieldsProps<A, B>
): Field[] {
  const { label, path, help } = props;

  let ans: Field[] = [];

  if (props.code != null) {
    switch (props.code.type) {
      case "select":
        ans = [
          ...ans,
          select({
            label,
            secondaryLabel: props.code.secondaryLabel,
            path: [...path, "code"],
            image: fieldImage(props.code.defaultImage),
            help,
            options: enumImages(props.code.source, props.code.imagePattern),
            showValues: props.code.showValues,
            required:
              props.code.required === false ? undefined : props.code.required,
            defaultValue: props.code.defaultValue,
          }),
        ];
        break;

      case "text":
        ans = [
          ...ans,
          textField({
            label,
            secondaryLabel: props.code.secondaryLabel,
            path: [...path, "code"],
            image: fieldImage(props.code.image),
            help,
            required:
              props.code.required === false ? undefined : props.code.required,
            defaultValue: props.code.defaultValue,
          }),
        ];
        break;

      default:
        checkExhausted(props.code);
    }
  }

  if (props.abundance != null) {
    switch (props.abundance.type) {
      case "select":
        ans = [
          ...ans,
          select({
            label: `${label} Abundance`,
            secondaryLabel: props.abundance.secondaryLabel,
            path: [...path, "abundance"],
            image: fieldImage(props.abundance.defaultImage),
            help,
            options: enumImages(
              props.abundance.source,
              props.abundance.imagePattern
            ),
            showValues: props.abundance.showValues,
            required:
              props.abundance.required === false
                ? undefined
                : props.abundance.required,
            defaultValue: props.abundance.defaultValue,
          }),
        ];
        break;

      case "number":
        ans = [
          ...ans,
          integerField({
            label: `${label} Abundance`,
            secondaryLabel: props.abundance.secondaryLabel,
            path: [...path, "abundance"],
            units: props.abundance.units,
            image: fieldImage(props.abundance.image),
            help,
            required:
              props.abundance.required === false
                ? undefined
                : props.abundance.required,
            defaultValue: props.abundance.defaultValue,
            bounds: minValue(0, "error"),
          }),
        ];
        break;

      case "checkbox":
        ans = [
          ...ans,
          checkbox({
            label: `${label} Abundance`,
            path: [...path, "abundance"],
            checkboxLabel: props.abundance.checkboxLabel,
            help,
          }),
        ];
        break;

      default:
        checkExhausted(props.abundance);
    }
  }

  return ans;
}

export function morphSection(props: {
  title: string;
  path: Path;
  help?: string;
  columns?: Columns;
  blocks: Block[][];
}): Block {
  const { title, path, help, columns, blocks } = props;
  return section({
    title,
    path,
    help,
    blocks:
      columns === 3
        ? [columns3(...flatten(blocks))]
        : columns === 2
          ? [columns2(...flatten(blocks))]
          : // Make the channel bed page take half the width of a wide screen:
            [columns2(vstack(...flatten(blocks)))],
  });
}
