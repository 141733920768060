// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesFloodWallDimension = "Eq0m" | "Lt10m" | "Gt10m";

const Eq0m: MrsMorphEstuariesFloodWallDimension = "Eq0m";
const Lt10m: MrsMorphEstuariesFloodWallDimension = "Lt10m";
const Gt10m: MrsMorphEstuariesFloodWallDimension = "Gt10m";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesFloodWallDimensionEnum = {
  ...createEnum("MrsMorphEstuariesFloodWallDimension", [
    [Eq0m, "0m"],
    [Lt10m, "<10m"],
    [Gt10m, ">10m"],
  ]),
  Eq0m,
  Lt10m,
  Gt10m,
};

export default MrsMorphEstuariesFloodWallDimensionEnum;
