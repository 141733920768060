import { checkExhausted } from "@cartographerio/util";
import { BlockState } from "./reducer";

export function blockIsVisible(state: BlockState): boolean {
  switch (state.type) {
    case "FieldState":
    case "TextState":
    case "VisibilityState":
      return state.visible;
    case "CardState":
    case "FullWidthState":
    case "GroupState":
    case "DynamicValueState":
    case "DynamicGridState":
    case "DynamicImageState":
    case "HeadingState":
    case "RepeatState":
    case "RequireRoleState":
    case "ScopeState":
    case "PrimarySurveyorFieldState":
    case "PrimaryTeamFieldState":
      return true;
    default:
      return checkExhausted(state);
  }
}
