import { Env } from "@cartographerio/topo-core";
import {
  topoExpr,
  attachmentField,
  Block,
  bodyText,
  checkbox,
  columns2,
  columns3,
  customRule,
  dynamicAttr,
  DynamicAttr,
  dynamicGrid,
  enumSelectOptions,
  featureField,
  featureFieldCartographerMapStyle,
  form,
  grid,
  integerField,
  maxTimestamp,
  minValue,
  multiSelect,
  otherSpecify,
  page,
  required,
  requiredIfV2,
  section,
  select,
  text,
  textArea,
  textField,
  timestampField,
  visibility,
  vstack,
} from "@cartographerio/topo-form";
import { numberAttr, stringAttr, teamAttr } from "@cartographerio/topo-map";
import { Result } from "@cartographerio/fp";
import {
  Feature,
  isFeature,
  isFeatureF,
  isPoint,
  Point,
} from "@cartographerio/geometry";
import {
  Guard,
  hasKey,
  isArray,
  isArrayOf,
  isNullable,
  isNumber,
  isObject,
  isRecordOf,
} from "@cartographerio/guard";
import {
  RiverflyFirstBreachAction,
  RiverflyFirstBreachActionEnum,
  RiverflyReasonNotTakenEnum,
  RiverflySampleType,
  RiverflySampleTypeEnum,
  RiverflySecondBreachAction,
  RiverflySecondBreachActionEnum,
  RiverflySpecies,
  RiverflySpeciesEnum,
} from "@cartographerio/inventory-enums";
import {
  Path,
  unsafeMapLayerId,
  unsafeProjectAlias,
} from "@cartographerio/types";
import { checkExhausted, Enum } from "@cartographerio/util";
import { outdent } from "outdent";
import {
  Calculations,
  Conversions,
  NullableCounts,
  riverflyCalculations,
  riverflyConversions,
} from "./calculations";

const hasTriggerLevel = (env: Env) =>
  env.getAbsoluteAs(["data", "site"], isFeature).getOrNull()?.properties
    ?.triggerLevel != null;

function sampleTypeLabel(value: RiverflySampleType): string {
  switch (value) {
    case "First":
      return "First Sample (normal sample, default)";
    case "Second":
      return "Second Sample (confirming a previous breach)";
    case "Combined":
      return "Combined (legacy option - do not use on new surveys)";
    default:
      return checkExhausted(value);
  }
}

function firstBreachActionLabel(value: RiverflyFirstBreachAction): string {
  switch (value) {
    case "Repeat":
      return "Yes - I plan to take a second sample to confirm the breach within 48 hours.";
    case "None":
      return "No - I do NOT plan to take a second sample (please explain)";
    default:
      return checkExhausted(value);
  }
}

function secondBreachActionLabel(value: RiverflySecondBreachAction): string {
  switch (value) {
    case "Coordinator":
      return "Yes - I have reported the breach to my Riverfly Coordinator";
    case "Hotline":
      return "Yes - I have reported the breach to the Incident Hotline";
    case "None":
      return "No - I have NOT reported the breach (please explain)";
    default:
      return checkExhausted(value);
  }
}

const sampleTakenPath: Path = ["data", "observations", "sampleTaken"];

const sampleReasonNotTakenPath: Path = [
  "data",
  "observations",
  "sampleReasonNotTaken",
];

const sampleTaken = topoExpr((env: Env) => env.get(sampleTakenPath) === true);

const sampleNotTaken = topoExpr(
  (env: Env) => env.get(sampleTakenPath) !== true
);

export function riverflyTopSections(
  _formType: "riverfly" | "urbanRiverfly" | "extendedRiverfly"
): Block[] {
  return [
    section({
      title: null,
      path: [],
      blocks: [
        grid({
          columns: 2,
          blocks: [
            timestampField({
              label: "Date and Time of Survey",
              path: ["data", "observations", "recorded"],
              help: outdent`Date and time the survey data was collected in the field.`,
              required: required("error"),
              defaultValue: "now",
              bounds: maxTimestamp("now", "error"),
            }),
            textArea({
              label: "Additional Surveyors",
              path: ["data", "observations", "additionalSurveyors"],
              help: outdent`If you are working with any other surveyors, please record their names.`,
              rows: 3,
            }),
            featureField({
              label: "Site",
              region: "uk",
              path: ["data", "site"],
              required: required(),
              fullWidth: true,
              mapOptions: {
                mapStyle: featureFieldCartographerMapStyle({
                  project: unsafeProjectAlias("riverflysites"),
                  layer: unsafeMapLayerId("riverflySite"),
                  geometryType: "Point",
                  primaryKey: "surveyId",
                }),
                selectMinZoom: 13,
                attributes: [
                  // stringAttr({
                  //   attributeId: "catchment",
                  //   label: "Catchment",
                  //   buckets: "auto",
                  // }),
                  stringAttr({
                    attributeId: "river",
                    label: "River",
                    buckets: "auto",
                  }),
                  stringAttr({
                    attributeId: "site",
                    label: "Site",
                    buckets: "auto",
                  }),
                  teamAttr("teamId", "Team"),
                  numberAttr({
                    attributeId: "triggerLevel",
                    label: "Trigger Level",
                    buckets: "auto",
                  }),
                ],
              },
            }),
          ],
        }),
      ],
    }),
    section({
      title: "Sample Type",
      path: [],
      blocks: columns2(
        vstack(
          // Specify hidden options (legacy) where if it's selected, it's visible, and if not, hide it, unless coordinator/whatever else
          select({
            label: "First or Second Sample",
            path: ["data", "observations", "sampleType"],
            options: RiverflySampleTypeEnum.entries.map(({ value }) => ({
              value,
              label: sampleTypeLabel(value),
            })),
            defaultValue: "First",
            hiddenOptions: [RiverflySampleTypeEnum.Combined],
            appearance: "radiogroup",
            fullWidth: true,
            visible: topoExpr(hasTriggerLevel),
            help: `The standard ARMI protocol following a trigger level breach is to record a second sample within 48 hours to confirm the result. What kind of sample are you recording?`,
            customRules: [
              customRule({
                level: "error",
                message: "You must specify what type of sample you're taking",
                triggerWhen: topoExpr(
                  env => env.getFocused() == null && hasTriggerLevel(env)
                ),
              }),
            ],
          }),
          checkbox({
            label: "Sample Taken?",
            path: sampleTakenPath,
            checkboxLabel: "Were you able to take the sample?",
            defaultValue: true,
            help: "If you were unable to take the sample, please *uncheck* this box.",
          }),
          visibility({
            visible: sampleNotTaken,
            block: columns2(
              vstack(
                select({
                  label: "Reason Sample Not Taken",
                  path: [...sampleReasonNotTakenPath, "selected"],
                  options: enumSelectOptions(RiverflyReasonNotTakenEnum),
                  customRules: [
                    requiredIfV2({
                      level: "error",
                      message:
                        "Please indicate why you were unable to take the sample.",
                      otherTest: sampleNotTaken,
                    }),
                  ],
                }),
                otherSpecify({
                  label: "Other Reason Sample Not Taken",
                  basePath: sampleReasonNotTakenPath,
                  test: value => value === RiverflyReasonNotTakenEnum.Other,
                  visible: topoExpr(
                    (env, run) =>
                      run(sampleNotTaken) &&
                      env.get([...sampleReasonNotTakenPath, "selected"]) ===
                        RiverflyReasonNotTakenEnum.Other
                  ),
                  requiredLevel: "error",
                  help: "Please describe why you were unable to take the sample.",
                  rows: 3,
                })
              )
            ),
          })
        )
      ),
    }),
  ];
}

const hasSampleType = (sampleType: RiverflySampleType) => (env: Env) =>
  hasTriggerLevel(env) &&
  env
    .getAbsoluteAs(
      ["data", "observations", "sampleType"],
      RiverflySampleTypeEnum.isValue
    )
    .getOrNull() === sampleType;

const isFirstSample = hasSampleType(RiverflySampleTypeEnum.First);
const isSecondSample = hasSampleType(RiverflySampleTypeEnum.Second);
const isCombinedSample = hasSampleType(RiverflySampleTypeEnum.Combined);

export const riverflyFixedPointPhotographsSection = section({
  title: "Fixed Point Photographs",
  path: [],
  help: outdent`Upload one or two fixed point photographs as a reference to show the flow level.`,
  blocks: [
    attachmentField({
      label: null,
      path: ["data", "observations", "fixedPointPhotographs"],
      maxFiles: 2,
    }),
  ],
});

function countsGrid<A extends string>(
  SpeciesEnum: Enum<A>,
  makeImageUrl: (species: string) => string,
  speciesConversions: Conversions<A, RiverflySpecies>,
  extraCalculations: Calculations<A>[]
): Block {
  const speciesPath = (species: string): Path => [
    "data",
    "observations",
    "counts",
    species,
  ];

  const requiredRule = (species: A) =>
    speciesConversions.forwardIncludes(species)
      ? [
          customRule({
            level: "error",
            message: "You must record counts for ARMI groups.",
            triggerWhen: topoExpr(
              (env, run) => run(sampleTaken) && env.getFocused() === null
            ),
          }),
        ]
      : [];

  const armiAttribute = (species: A): DynamicAttr =>
    speciesConversions.forwardIncludes(species)
      ? dynamicAttr({
          label: "ARMI Score",
          valueType: "number",
          value: topoExpr(env =>
            env
              .getAs(speciesPath(species), isNumber)
              .map(count =>
                riverflyCalculations.score(
                  speciesConversions.forwardLookup(species),
                  count
                )
              )
              .getOrNull()
          ),
        })
      : dynamicAttr({
          label: "ARMI Score",
          valueType: "string",
          value: topoExpr(_env => "N/A"),
        });

  const extraAttributes = (species: A): DynamicAttr[] =>
    extraCalculations.map(calculations =>
      dynamicAttr({
        label: `${calculations.label} Score`,
        valueType: "number",
        value: topoExpr(env =>
          env
            .getAs(speciesPath(species), isNumber)
            .map(count => calculations.score(species, count))
            .getOrNull()
        ),
      })
    );

  return columns3(
    ...SpeciesEnum.entries.map(({ label, value: species }) =>
      grid({
        columns: 1,
        blocks: [
          integerField({
            label,
            path: speciesPath(species),
            bounds: minValue(0),
            image: makeImageUrl(species),
            customRules: requiredRule(species),
          }),
          dynamicGrid({
            label: "Scores",
            columns: 1,
            attributes: [armiAttribute(species), ...extraAttributes(species)],
          }),
        ],
      })
    )
  );
}

export function sampleCountsSection<A extends string>(
  SpeciesEnum: Enum<A>,
  makeImageUrl: (species: string) => string,
  speciesConversions: Conversions<A, RiverflySpecies>,
  extraCalculations: Calculations<A>[] = []
): Block {
  return section({
    title: "Sample Counts",
    path: [],
    visible: sampleTaken,
    help: outdent`
    Enter estimated counts for each group. Survey scores will be derived automatically for the site and shown below this section.

    Identification images Copyright John Davy-Bowker. All rights reserved.
    `,
    blocks: [
      countsGrid(
        SpeciesEnum,
        makeImageUrl,
        speciesConversions,
        extraCalculations
      ),
    ],
  });
}

export function armiTriggerLevelBreachOkSection(
  breached: (env: Env) => boolean | null
): Block {
  return section({
    title: "Trigger Level OK",
    path: [],
    visible: topoExpr(
      (env, run) =>
        run(sampleTaken) && hasTriggerLevel(env) && breached(env) === false
    ),
    appearance: "success",
    blocks: [
      bodyText(
        "Your total score safely exceeds the trigger level for this site."
      ),
    ],
  });
}

function firstSampleBreachedSection(
  breached: (env: Env) => boolean | null
): Block {
  const sectionVisible = topoExpr(
    (env, run) =>
      run(sampleTaken) && isFirstSample(env) && (breached(env) ?? false)
  );

  const getAction = topoExpr((env, run) =>
    run(sectionVisible)
      ? env
          .getAbsoluteAs(
            ["data", "observations", "firstBreachAction"],
            RiverflyFirstBreachActionEnum.isValue
          )
          .getOrNull()
      : null
  );

  return section({
    title: "Unconfirmed ARMI Trigger Level Breach",
    path: ["data", "observations"],
    help: outdent`
      Your total score breaches the trigger level for this site.
      Please repeat the sample to confirm it!
    `,
    visible: sectionVisible,
    appearance: "warning",
    blocks: [
      select({
        appearance: "radiogroup",
        path: ["firstBreachAction"],
        label: null,
        options: RiverflyFirstBreachActionEnum.entries.map(({ value }) => ({
          value,
          label: firstBreachActionLabel(value),
        })),
        customRules: [
          customRule({
            level: "error",
            message: "You must select a value",
            triggerWhen: topoExpr(
              (env, run) => env.getFocused() === null && run(sectionVisible)
            ),
          }),
        ],
      }),
      text({
        appearance: "help",
        visible: topoExpr(
          (env, run) => run(getAction) === RiverflyFirstBreachActionEnum.Repeat
        ),
        markdown: outdent`
          Complete and save this form to record the results of this first sample.
          Then record a second sample at the same site within 48 hours to confirm the breach.
          Record the results for your second sample on a new survey form.
          Set the *Survey Type* at the top of the new form to "Second Survey".
        `,
      }),
      textArea({
        path: ["firstBreachReasonNotRepeated"],
        label: "Reasons for Not Recording a Second Sample",
        visible: topoExpr(
          (env, run) => run(getAction) === RiverflyFirstBreachActionEnum.None
        ),
        rows: 8,
        customRules: [
          customRule({
            level: "error",
            message: "You must record a reason",
            triggerWhen: topoExpr(
              (env, run) =>
                env.getFocused() === null &&
                run(getAction) === RiverflyFirstBreachActionEnum.None
            ),
          }),
        ],
      }),
    ],
  });
}

function secondSampleBreachedSection(
  breached: (env: Env) => boolean | null
): Block {
  const sectionVisible = topoExpr(
    (env, run) =>
      run(sampleTaken) &&
      (isSecondSample(env) || isCombinedSample(env)) &&
      (breached(env) ?? false)
  );

  const hasAction = (action: RiverflySecondBreachAction) =>
    topoExpr((env, run) =>
      run(sectionVisible)
        ? env
            .getAbsoluteAs(
              ["data", "observations", "secondBreachAction"],
              isArrayOf(RiverflySecondBreachActionEnum.isValue)
            )
            .map(actions => actions.includes(action))
            .getOrElse(() => false)
        : false
    );

  return section({
    title: "Confirmed ARMI Trigger Level Breach",
    path: ["data", "observations"],
    help: outdent`
      Your total score confirms the trigger level breach for this site.
      The confirmed breach should be reported to your group/hub coordinator
      and/or statutory body ecology contact during working hours.

      If there are obvious signs of pollution and/or dead fish,
      and no so signs of invertebrate life,
      please report immediately to the relevant statutory body:

      - EA, SEPA, NIEA - [0800 807060](tel:0800807060)
      - NRW - [0300 065 3000](tel:03000653000)
    `,
    visible: sectionVisible,
    appearance: "warning",
    blocks: [
      multiSelect({
        label: "Breach Reported? (select all that apply)",
        path: ["secondBreachAction"],
        options: RiverflySecondBreachActionEnum.entries.map(({ value }) => ({
          value,
          label: secondBreachActionLabel(value),
        })),
        noneOption: RiverflySecondBreachActionEnum.None,
        customRules: [
          customRule({
            level: "error",
            message: "You must select at least one action",
            triggerWhen: topoExpr(
              (env, run) =>
                env
                  .getFocusedAs(isArray)
                  .map(arr => arr.length === 0)
                  .getOrElse(() => false) && run(sectionVisible)
            ),
          }),
        ],
      }),
      textArea({
        label: "Reasons for Not Reporting",
        path: ["secondBreachReasonNotReported"],
        visible: hasAction(RiverflySecondBreachActionEnum.None),
        customRules: [
          customRule({
            level: "error",
            message: "You must enter a reason",
            triggerWhen: topoExpr(
              (env, run) =>
                env.getFocused() === null &&
                run(hasAction(RiverflySecondBreachActionEnum.None))
            ),
          }),
        ],
      }),
      textField({
        label: "Hotline Incident Number",
        path: ["secondBreachIncidentNumber"],
        visible: hasAction(RiverflySecondBreachActionEnum.Hotline),
        customRules: [
          customRule({
            level: "error",
            message: "You must enter the incident number",
            triggerWhen: topoExpr(
              (env, run) =>
                env.getFocused() === null &&
                run(hasAction(RiverflySecondBreachActionEnum.Hotline))
            ),
          }),
        ],
      }),
    ],
  });
}

export function armiTriggerLevelBreachedSections(
  breached: (env: Env) => boolean | null
): Block[] {
  return [
    firstSampleBreachedSection(breached),
    secondSampleBreachedSection(breached),
  ];
}

export function riverflyPollutionPhotographsSection(
  breached: (env: Env) => boolean | null
): Block {
  return section({
    title: "Photographs of Pollution",
    path: [],
    help: outdent`
  Please upload any photographic evidence you have of the pollution
  incident.
  `,
    visible: topoExpr(env => breached(env) ?? false),
    blocks: [
      attachmentField({
        label: null,
        path: ["data", "observations", "breachPhotographs"],
        maxFiles: 2,
      }),
    ],
  });
}

export function isRiverflyARMICounts<A extends string>(
  SpeciesEnum: Enum<A>,
  speciesConversions: Conversions<A, RiverflySpecies>
): Guard<NullableCounts<A>> {
  const isNullableCounts = isRecordOf(
    SpeciesEnum.isValue,
    isNullable(isNumber)
  );
  const hasArmiValues = (counts: NullableCounts<A>) =>
    (Object.entries(counts) as [A, number | null][]).every(
      ([species, value]) =>
        !speciesConversions.forwardIncludes(species) ||
        speciesConversions.forwardLookup(species) == null ||
        value != null
    );
  return (counts): counts is NullableCounts<A> =>
    isNullableCounts(counts) && hasArmiValues(counts);
}

export function armiScoreAttribute<A extends string>(
  SpeciesEnum: Enum<A>,
  speciesConversions: Conversions<A, RiverflySpecies>,
  countsPath: Path = ["data", "observations", "counts"]
): DynamicAttr {
  return dynamicAttr({
    label: "ARMI Score",
    valueType: "number",
    value: topoExpr(env =>
      env
        .getAs(
          countsPath,
          isRiverflyARMICounts(SpeciesEnum, speciesConversions)
        )
        .map(counts =>
          riverflyCalculations.total(
            riverflyCalculations.scores(speciesConversions.forward(counts, 0))
          )
        )
        .getOrNull()
    ),
  });
}

export const armiTriggerLevelAttribute = dynamicAttr({
  label: "Trigger Level",
  valueType: "number",
  value: topoExpr(env => {
    const feature = env
      .getAs(
        ["data", "site"],
        isNullable(
          isFeatureF<Point, { triggerLevel: number }>(
            isPoint,
            (v: unknown): v is { triggerLevel: number } =>
              isObject(v) && hasKey(v, "triggerLevel", isNumber)
          )
        )
      )
      .getOrNull();
    return feature?.properties?.triggerLevel ?? "-";
  }),
});

interface RiverflySiteAttributes {
  triggerLevel: number;
}

const isRiverflySiteAttributes = (v: unknown): v is RiverflySiteAttributes =>
  isObject(v) && hasKey(v, "triggerLevel", isNumber);

type RiverflySiteFeature = Feature<Point, RiverflySiteAttributes>;

const isRiverflySiteFeature: Guard<RiverflySiteFeature> = isFeatureF<
  Point,
  RiverflySiteAttributes
>(isPoint, isRiverflySiteAttributes);

export function armiTriggerLevelBreached<A extends string>(
  SpeciesEnum: Enum<A>,
  speciesConversions: Conversions<A, RiverflySpecies>,
  countsPath: Path = ["data", "observations", "counts"]
): (env: Env) => boolean | null {
  return env => {
    const triggerLevel = env
      .getAs(["data", "site"], isRiverflySiteFeature)
      .map(feature => feature?.properties?.triggerLevel);

    const counts = env.getAs(
      countsPath,
      isRiverflyARMICounts(SpeciesEnum, speciesConversions)
    );

    return Result.when(
      triggerLevel,
      counts
    )(
      (triggerLevel, counts) =>
        riverflyCalculations.total(
          riverflyCalculations.scores(speciesConversions.forward(counts, 0))
        ) < triggerLevel
    ).getOrNull();
  };
}

export function armiTriggerLevelBreachedAttribute<A extends string>(
  SpeciesEnum: Enum<A>,
  speciesConversions: Conversions<A, RiverflySpecies>,
  countsPath?: Path
): DynamicAttr {
  const breached = armiTriggerLevelBreached<A>(
    SpeciesEnum,
    speciesConversions,
    countsPath
  );
  return {
    label: "Trigger Level Breach?",
    valueType: "boolean",
    value: topoExpr(env => breached(env)),
  };
}

const riverflyTriggerLevelBreached = armiTriggerLevelBreached<RiverflySpecies>(
  RiverflySpeciesEnum,
  riverflyConversions
);

export const riverflyNotesSection = section({
  title: "Notes",
  path: [],
  help: outdent`Please include any notes, e.g. INNS, dead invertebrates, sewage fungus, low flow.`,
  blocks: [
    textArea({
      label: null,
      path: ["data", "observations", "notes"],
      rows: 8,
    }),
  ],
});

export default form({
  title: "Riverfly",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        ...riverflyTopSections("riverfly"),
        sampleCountsSection<RiverflySpecies>(
          RiverflySpeciesEnum,
          value =>
            `https://media.cartographer.io/static/images/riverfly/${value}.jpg`,
          riverflyConversions
        ),
        section({
          title: "Sample Scores",
          path: [],
          visible: topoExpr(
            env => env.get(["data", "observations", "sampleTaken"]) === true
          ),
          blocks: [
            dynamicGrid({
              columns: 3,
              attributes: [
                armiScoreAttribute<RiverflySpecies>(
                  RiverflySpeciesEnum,
                  riverflyConversions
                ),
                armiTriggerLevelAttribute,
                armiTriggerLevelBreachedAttribute<RiverflySpecies>(
                  RiverflySpeciesEnum,
                  riverflyConversions
                ),
              ],
            }),
          ],
        }),
        ...armiTriggerLevelBreachedSections(riverflyTriggerLevelBreached),
        armiTriggerLevelBreachOkSection(riverflyTriggerLevelBreached),
        riverflyPollutionPhotographsSection(riverflyTriggerLevelBreached),
        riverflyFixedPointPhotographsSection,
        riverflyNotesSection,
      ],
    }),
  ],
});
