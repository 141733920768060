import { Schema, schemaBlankValue } from "@cartographerio/topo-survey";
import { Result } from "@cartographerio/fp";
import {
  ApiError,
  ProjectId,
  SchemaVersion,
  SurveyId,
  SurveyModuleId,
  Survey,
  TeamId,
  Timestamp,
  UserRef,
  initialSchemaVersion,
  internalError,
  isSurvey,
  nowTimestamp,
  randomSurveyId,
} from "@cartographerio/types";
import { merge } from "lodash";
import {
  InitialiseOpts,
  defaultInitialiseOpts,
  initialiseForm,
} from "./initialise";
import { Form } from "./type";

export interface BlankSurveyOpts extends InitialiseOpts {
  id?: SurveyId;
  surveyor?: UserRef;
  created?: Timestamp;
  updated?: Timestamp;
  schemaVersion?: SchemaVersion;
}

export const defaultBlankSurveyOpts: BlankSurveyOpts = defaultInitialiseOpts;

export function blankSurvey(
  form: Form,
  schema: Schema,
  moduleId: SurveyModuleId,
  projectId: ProjectId,
  teamId: TeamId | null,
  opts: BlankSurveyOpts = defaultBlankSurveyOpts
): Result<ApiError, Survey> {
  const now = nowTimestamp();

  const {
    id = randomSurveyId(),
    surveyor = undefined,
    created = now,
    updated = now,
    schemaVersion = initialSchemaVersion,
  } = opts;

  return initialiseForm(form, schema, [], schemaBlankValue(schema), opts)
    .map(doc =>
      merge(doc, {
        id,
        moduleId,
        projectId,
        teamId,
        surveyor,
        created,
        updated,
        schemaVersion,
      })
    )
    .mapError(msg =>
      internalError(
        `Error creating blank ${moduleId} data: ${JSON.stringify(msg)}`
      )
    )
    .guardWith(isSurvey, s =>
      internalError("blankSurvey returned non-survey | " + JSON.stringify(s))
    );
}
