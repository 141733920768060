import { PricingPlan } from "@cartographerio/pricing";
import { BillingInterval, Plan } from "@cartographerio/types";
import { Box, Card, CardBody } from "@chakra-ui/react";

import Button from "../components/Button";
import ButtonLink from "../components/ButtonLink";
import Heading from "../components/Heading";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import PlanFeatures from "./PlanFeatures";
import PriceLabel from "./PriceLabel";

export type PlanClickFunc = (
  plan: Plan,
  billingInterval: BillingInterval
) => void;

export interface PlanChooserColumnProps {
  plan: Plan;
  pricingPlan: PricingPlan | null;
  billingInterval: BillingInterval;
  disabled?: boolean;
  disabledReason?: string;
  planLink?: string;
  onPlanClick?: PlanClickFunc;
}

export default function PlanChooserColumn(props: PlanChooserColumnProps) {
  const {
    plan,
    pricingPlan,
    billingInterval,
    disabled = false,
    disabledReason,
    planLink,
    onPlanClick,
  } = props;

  const { name, limits, pricingScheme } = plan;

  return (
    <Card
      as="section"
      shadow="lg"
      border="1px solid"
      borderColor="gray.200"
      w={["100%", "25ch"]}
      minW="28ch"
    >
      <CardBody>
        <Spaced spacing="6" pb="2">
          <Heading
            level="section"
            textAlign="center"
            fontSize="md"
            color="orange.500"
          >
            {name}
          </Heading>
          <Box
            textAlign="center"
            fontSize="2xl"
            fontWeight="semibold"
            color="black"
          >
            <PriceLabel
              pricingScheme={pricingScheme}
              billingInterval={billingInterval}
            />
          </Box>
          <Para textAlign="center">
            {planLink != null ? (
              <ButtonLink.Internal
                to={planLink}
                disabled={disabled}
                title={disabled ? disabledReason : undefined}
              >
                Choose
              </ButtonLink.Internal>
            ) : (
              onPlanClick != null && (
                <Button
                  label="Choose"
                  onClick={() => onPlanClick(plan, billingInterval)}
                  disabled={disabled}
                  title={disabled ? disabledReason : undefined}
                />
              )
            )}
          </Para>
          {pricingPlan != null && (
            <PlanFeatures
              pricingPlan={pricingPlan}
              pricingScheme={pricingScheme}
              limits={limits}
              billingInterval={billingInterval}
              premium={false}
            />
          )}
        </Spaced>
      </CardBody>
    </Card>
  );
}
