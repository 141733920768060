/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { BBox, isBBox, Point, isPoint } from "@cartographerio/geometry";
import {
  DataLicense,
  isDataLicense,
  InvitationEmailSettings,
  isInvitationEmailSettings,
  MapId,
  isMapId,
  ProjectAlias,
  isProjectAlias,
  ProjectBreadcrumbId,
  isProjectBreadcrumbId,
  ProjectId,
  isProjectId,
  ProjectTemplateId,
  isProjectTemplateId,
  QualificationAlias,
  isQualificationAlias,
  QualificationId,
  isQualificationId,
  SurveyApprovedEmailSettings,
  isSurveyApprovedEmailSettings,
  SurveyCompleteEmailSettings,
  isSurveyCompleteEmailSettings,
  SurveyModuleId,
  isSurveyModuleId,
  TeamId,
  isTeamId,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";

export type MapVisibility = "Project" | "Public";

export type SurveyVisibility = "Project" | "Workspace";

export interface ProjectMapSettings {
  defaultBounds: BBox;
  fallbackCenter: Point;
  fallbackZoom: number;
}

export interface ProjectEmailSettings {
  invitation: InvitationEmailSettings;
  complete: SurveyCompleteEmailSettings;
  approved: SurveyApprovedEmailSettings;
}

export type KnownProjectFeature = "LockAlias";

export type ProjectFeature = "LockAlias" | string;

export type ProjectPermissionModel = "Default" | "CoordinatorOnly";

export type ProjectVisibility = "Private" | "Workspace";

export interface Project {
  name: string;
  alias: ProjectAlias;
  workspaceId: WorkspaceId;
  dataLicense: DataLicense;
  permissionModel: ProjectPermissionModel;
  projectVisibility: ProjectVisibility;
  mapVisibility: MapVisibility;
  teamIds: TeamId[];
  features: ProjectFeature[];
  qualificationIds: QualificationId[];
  moduleIds: SurveyModuleId[];
  mapIds: MapId[];
  id: ProjectId;
}

export interface ProjectCreate {
  name: string;
  alias: ProjectAlias;
  workspaceId: WorkspaceId;
  dataLicense?: DataLicense | null;
  permissionModel?: ProjectPermissionModel | null;
  projectVisibility?: ProjectVisibility | null;
  mapVisibility?: MapVisibility | null;
  teamIds?: TeamId[] | null;
  features?: ProjectFeature[] | null;
  qualificationIds?: QualificationId[] | null;
  moduleIds?: SurveyModuleId[] | null;
  mapIds?: MapId[] | null;
  id?: ProjectId | null;
}

export interface ProjectUpdate {
  name?: string | null;
  alias?: ProjectAlias | null;
  dataLicense?: DataLicense | null;
  permissionModel?: ProjectPermissionModel | null;
  projectVisibility?: ProjectVisibility | null;
  mapVisibility?: MapVisibility | null;
  teamIds?: TeamId[] | null;
  features?: ProjectFeature[] | null;
  qualificationIds?: QualificationId[] | null;
  moduleIds?: SurveyModuleId[] | null;
  mapIds?: MapId[] | null;
}

export interface ProjectBreadcrumb {
  title: string;
  srcWorkspaceId: WorkspaceId;
  desWorkspaceId: WorkspaceId;
  desProjectId: ProjectId;
  desTeamId?: TeamId | null;
  id: ProjectBreadcrumbId;
}

export interface ProjectTemplate {
  name: string;
  alias: ProjectAlias;
  dataLicense: DataLicense;
  permissionModel: ProjectPermissionModel;
  projectVisibility: ProjectVisibility;
  mapVisibility: MapVisibility;
  features: ProjectFeature[];
  qualifications: QualificationAlias[];
  moduleIds: SurveyModuleId[];
  mapIds: MapId[];
  id: ProjectTemplateId;
}

export const isMapVisibility = (v: any): v is MapVisibility => {
  return v === "Project" || v === "Public";
};

export const isSurveyVisibility = (v: any): v is SurveyVisibility => {
  return v === "Project" || v === "Workspace";
};

export const isProjectMapSettings = (v: any): v is ProjectMapSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "defaultBounds" in v &&
    isBBox(v.defaultBounds) &&
    "fallbackCenter" in v &&
    isPoint(v.fallbackCenter) &&
    "fallbackZoom" in v &&
    typeof v.fallbackZoom === "number"
  );
};

export const isProjectEmailSettings = (v: any): v is ProjectEmailSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationEmailSettings(v.invitation) &&
    "complete" in v &&
    isSurveyCompleteEmailSettings(v.complete) &&
    "approved" in v &&
    isSurveyApprovedEmailSettings(v.approved)
  );
};

export const isKnownProjectFeature = (v: any): v is KnownProjectFeature => {
  return v === "LockAlias";
};

export const isProjectFeature = (v: any): v is ProjectFeature => {
  return v === "LockAlias" || typeof v === "string";
};

export const isProjectPermissionModel = (
  v: any
): v is ProjectPermissionModel => {
  return v === "Default" || v === "CoordinatorOnly";
};

export const isProjectVisibility = (v: any): v is ProjectVisibility => {
  return v === "Private" || v === "Workspace";
};

export const isProject = (v: any): v is Project => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isProjectAlias(v.alias) &&
    "workspaceId" in v &&
    isWorkspaceId(v.workspaceId) &&
    "dataLicense" in v &&
    isDataLicense(v.dataLicense) &&
    "permissionModel" in v &&
    isProjectPermissionModel(v.permissionModel) &&
    "projectVisibility" in v &&
    isProjectVisibility(v.projectVisibility) &&
    "mapVisibility" in v &&
    isMapVisibility(v.mapVisibility) &&
    "teamIds" in v &&
    Array.isArray(v.teamIds) &&
    v.teamIds.every((i: any) => isTeamId(i)) &&
    "features" in v &&
    Array.isArray(v.features) &&
    v.features.every((i: any) => isProjectFeature(i)) &&
    "qualificationIds" in v &&
    Array.isArray(v.qualificationIds) &&
    v.qualificationIds.every((i: any) => isQualificationId(i)) &&
    "moduleIds" in v &&
    Array.isArray(v.moduleIds) &&
    v.moduleIds.every((i: any) => isSurveyModuleId(i)) &&
    "mapIds" in v &&
    Array.isArray(v.mapIds) &&
    v.mapIds.every((i: any) => isMapId(i)) &&
    "id" in v &&
    isProjectId(v.id)
  );
};

export const isProjectCreate = (v: any): v is ProjectCreate => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isProjectAlias(v.alias) &&
    "workspaceId" in v &&
    isWorkspaceId(v.workspaceId) &&
    (!("dataLicense" in v) ||
      isDataLicense(v.dataLicense) ||
      v.dataLicense === null) &&
    (!("permissionModel" in v) ||
      isProjectPermissionModel(v.permissionModel) ||
      v.permissionModel === null) &&
    (!("projectVisibility" in v) ||
      isProjectVisibility(v.projectVisibility) ||
      v.projectVisibility === null) &&
    (!("mapVisibility" in v) ||
      isMapVisibility(v.mapVisibility) ||
      v.mapVisibility === null) &&
    (!("teamIds" in v) ||
      (Array.isArray(v.teamIds) && v.teamIds.every((i: any) => isTeamId(i))) ||
      v.teamIds === null) &&
    (!("features" in v) ||
      (Array.isArray(v.features) &&
        v.features.every((i: any) => isProjectFeature(i))) ||
      v.features === null) &&
    (!("qualificationIds" in v) ||
      (Array.isArray(v.qualificationIds) &&
        v.qualificationIds.every((i: any) => isQualificationId(i))) ||
      v.qualificationIds === null) &&
    (!("moduleIds" in v) ||
      (Array.isArray(v.moduleIds) &&
        v.moduleIds.every((i: any) => isSurveyModuleId(i))) ||
      v.moduleIds === null) &&
    (!("mapIds" in v) ||
      (Array.isArray(v.mapIds) && v.mapIds.every((i: any) => isMapId(i))) ||
      v.mapIds === null) &&
    (!("id" in v) || isProjectId(v.id) || v.id === null)
  );
};

export const isProjectUpdate = (v: any): v is ProjectUpdate => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("name" in v) || typeof v.name === "string" || v.name === null) &&
    (!("alias" in v) || isProjectAlias(v.alias) || v.alias === null) &&
    (!("dataLicense" in v) ||
      isDataLicense(v.dataLicense) ||
      v.dataLicense === null) &&
    (!("permissionModel" in v) ||
      isProjectPermissionModel(v.permissionModel) ||
      v.permissionModel === null) &&
    (!("projectVisibility" in v) ||
      isProjectVisibility(v.projectVisibility) ||
      v.projectVisibility === null) &&
    (!("mapVisibility" in v) ||
      isMapVisibility(v.mapVisibility) ||
      v.mapVisibility === null) &&
    (!("teamIds" in v) ||
      (Array.isArray(v.teamIds) && v.teamIds.every((i: any) => isTeamId(i))) ||
      v.teamIds === null) &&
    (!("features" in v) ||
      (Array.isArray(v.features) &&
        v.features.every((i: any) => isProjectFeature(i))) ||
      v.features === null) &&
    (!("qualificationIds" in v) ||
      (Array.isArray(v.qualificationIds) &&
        v.qualificationIds.every((i: any) => isQualificationId(i))) ||
      v.qualificationIds === null) &&
    (!("moduleIds" in v) ||
      (Array.isArray(v.moduleIds) &&
        v.moduleIds.every((i: any) => isSurveyModuleId(i))) ||
      v.moduleIds === null) &&
    (!("mapIds" in v) ||
      (Array.isArray(v.mapIds) && v.mapIds.every((i: any) => isMapId(i))) ||
      v.mapIds === null)
  );
};

export const isProjectBreadcrumb = (v: any): v is ProjectBreadcrumb => {
  return (
    typeof v === "object" &&
    v != null &&
    "title" in v &&
    typeof v.title === "string" &&
    "srcWorkspaceId" in v &&
    isWorkspaceId(v.srcWorkspaceId) &&
    "desWorkspaceId" in v &&
    isWorkspaceId(v.desWorkspaceId) &&
    "desProjectId" in v &&
    isProjectId(v.desProjectId) &&
    (!("desTeamId" in v) || isTeamId(v.desTeamId) || v.desTeamId === null) &&
    "id" in v &&
    isProjectBreadcrumbId(v.id)
  );
};

export const isProjectTemplate = (v: any): v is ProjectTemplate => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isProjectAlias(v.alias) &&
    "dataLicense" in v &&
    isDataLicense(v.dataLicense) &&
    "permissionModel" in v &&
    isProjectPermissionModel(v.permissionModel) &&
    "projectVisibility" in v &&
    isProjectVisibility(v.projectVisibility) &&
    "mapVisibility" in v &&
    isMapVisibility(v.mapVisibility) &&
    "features" in v &&
    Array.isArray(v.features) &&
    v.features.every((i: any) => isProjectFeature(i)) &&
    "qualifications" in v &&
    Array.isArray(v.qualifications) &&
    v.qualifications.every((i: any) => isQualificationAlias(i)) &&
    "moduleIds" in v &&
    Array.isArray(v.moduleIds) &&
    v.moduleIds.every((i: any) => isSurveyModuleId(i)) &&
    "mapIds" in v &&
    Array.isArray(v.mapIds) &&
    v.mapIds.every((i: any) => isMapId(i)) &&
    "id" in v &&
    isProjectTemplateId(v.id)
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
