// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusRailAccess = "under200m" | "200To1000m" | "over1000m";

const ShortDistance: EcostatusRailAccess = "under200m";
const MediumDistance: EcostatusRailAccess = "200To1000m";
const LongDistance: EcostatusRailAccess = "over1000m";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusRailAccessEnum = {
  ...createEnum("EcostatusRailAccess", [
    [ShortDistance, "<200m"],
    [MediumDistance, "200m-1km"],
    [LongDistance, ">1km"],
  ]),
  ShortDistance,
  MediumDistance,
  LongDistance,
};

export default EcostatusRailAccessEnum;
