// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesVegetatedIntertidalSedimentSize =
  | "AR"
  | "BE"
  | "BO"
  | "CO"
  | "GP"
  | "SM"
  | "SA"
  | "FS"
  | "OR"
  | "EA"
  | "NV";

const Artificial: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "AR";
const Bedrock: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "BE";
const Boulder: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "BO";
const Cobble: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "CO";
const GravelPebble: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "GP";
const ShellyMaterial: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "SM";
const Sand: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "SA";
const FineSediments: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "FS";
const Organic: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "OR";
const Earth: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "EA";
const NotVisible: MrsMorphEstuariesVegetatedIntertidalSedimentSize = "NV";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesVegetatedIntertidalSedimentSizeEnum = {
  ...createEnum("MrsMorphEstuariesVegetatedIntertidalSedimentSize", [
    [Artificial, "Artificial"],
    [Bedrock, "Bedrock"],
    [Boulder, "Boulder"],
    [Cobble, "Cobble"],
    [GravelPebble, "Gravel-Pebble"],
    [ShellyMaterial, "Shelly Material"],
    [Sand, "Sand"],
    [FineSediments, "Fine Sediments"],
    [Organic, "Organic"],
    [Earth, "Earth"],
    [NotVisible, "Not visible"],
  ]),
  Artificial,
  Bedrock,
  Boulder,
  Cobble,
  GravelPebble,
  ShellyMaterial,
  Sand,
  FineSediments,
  Organic,
  Earth,
  NotVisible,
};

export default MrsMorphEstuariesVegetatedIntertidalSedimentSizeEnum;
