// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankPermission = "No" | "Yes";

const No: NwcSeedbankPermission = "No";
const Yes: NwcSeedbankPermission = "Yes";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankPermissionEnum = {
  ...createEnum("NwcSeedbankPermission", [
    [No, "No - I have not obtained permission"],
    [Yes, "Yes - I have obtained permission"],
  ]),
  No,
  Yes,
};

export default NwcSeedbankPermissionEnum;
