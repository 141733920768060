// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtUpstreamThinkingCatchment =
  | "Avon"
  | "Axe"
  | "Brue"
  | "Camel"
  | "Cober"
  | "Dart"
  | "Erme"
  | "Exe"
  | "ExmoorStreams"
  | "Fal"
  | "Fowey"
  | "FromAndPiddle"
  | "GannelPorthAndMenalhyl"
  | "HartlandAndClovelly"
  | "HayleRedRiverAndNorthernStreams"
  | "HelfordAndTheLizard"
  | "LimAndAxe"
  | "Lynher"
  | "NorthCoastStreams"
  | "Otter"
  | "Parrett"
  | "ParStAustellAndCaerhays"
  | "PenwithPeninsula"
  | "Plym"
  | "PooleAndPurbeck"
  | "SeatonLooeAndPolperro"
  | "Sid"
  | "StratNeet"
  | "Tamar"
  | "Tavy"
  | "Taw"
  | "Teign"
  | "Torridge"
  | "WestDorsetRivers"
  | "WestSomersetStreams"
  | "Yealm"
  | "Yeo";

const Avon: WrtUpstreamThinkingCatchment = "Avon";
const Axe: WrtUpstreamThinkingCatchment = "Axe";
const Brue: WrtUpstreamThinkingCatchment = "Brue";
const Camel: WrtUpstreamThinkingCatchment = "Camel";
const Cober: WrtUpstreamThinkingCatchment = "Cober";
const Dart: WrtUpstreamThinkingCatchment = "Dart";
const Erme: WrtUpstreamThinkingCatchment = "Erme";
const Exe: WrtUpstreamThinkingCatchment = "Exe";
const ExmoorStreams: WrtUpstreamThinkingCatchment = "ExmoorStreams";
const Fal: WrtUpstreamThinkingCatchment = "Fal";
const Fowey: WrtUpstreamThinkingCatchment = "Fowey";
const FromAndPiddle: WrtUpstreamThinkingCatchment = "FromAndPiddle";
const GannelPorthAndMenalhyl: WrtUpstreamThinkingCatchment =
  "GannelPorthAndMenalhyl";
const HartlandAndClovelly: WrtUpstreamThinkingCatchment = "HartlandAndClovelly";
const HayleRedRiverAndNorthernStreams: WrtUpstreamThinkingCatchment =
  "HayleRedRiverAndNorthernStreams";
const HelfordAndTheLizard: WrtUpstreamThinkingCatchment = "HelfordAndTheLizard";
const LimAndAxe: WrtUpstreamThinkingCatchment = "LimAndAxe";
const Lynher: WrtUpstreamThinkingCatchment = "Lynher";
const NorthCoastStreams: WrtUpstreamThinkingCatchment = "NorthCoastStreams";
const Otter: WrtUpstreamThinkingCatchment = "Otter";
const Parrett: WrtUpstreamThinkingCatchment = "Parrett";
const ParStAustellAndCaerhays: WrtUpstreamThinkingCatchment =
  "ParStAustellAndCaerhays";
const PenwithPeninsula: WrtUpstreamThinkingCatchment = "PenwithPeninsula";
const Plym: WrtUpstreamThinkingCatchment = "Plym";
const PooleAndPurbeck: WrtUpstreamThinkingCatchment = "PooleAndPurbeck";
const SeatonLooeAndPolperro: WrtUpstreamThinkingCatchment =
  "SeatonLooeAndPolperro";
const Sid: WrtUpstreamThinkingCatchment = "Sid";
const StratNeet: WrtUpstreamThinkingCatchment = "StratNeet";
const Tamar: WrtUpstreamThinkingCatchment = "Tamar";
const Tavy: WrtUpstreamThinkingCatchment = "Tavy";
const Taw: WrtUpstreamThinkingCatchment = "Taw";
const Teign: WrtUpstreamThinkingCatchment = "Teign";
const Torridge: WrtUpstreamThinkingCatchment = "Torridge";
const WestDorsetRivers: WrtUpstreamThinkingCatchment = "WestDorsetRivers";
const WestSomersetStreams: WrtUpstreamThinkingCatchment = "WestSomersetStreams";
const Yealm: WrtUpstreamThinkingCatchment = "Yealm";
const Yeo: WrtUpstreamThinkingCatchment = "Yeo";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtUpstreamThinkingCatchmentEnum = {
  ...createEnum("WrtUpstreamThinkingCatchment", [
    [Avon, "Avon"],
    [Axe, "Axe"],
    [Brue, "Brue"],
    [Camel, "Camel"],
    [Cober, "Cober"],
    [Dart, "Dart"],
    [Erme, "Erme"],
    [Exe, "Exe "],
    [ExmoorStreams, "Exmoor Streams"],
    [Fal, "Fal"],
    [Fowey, "Fowey"],
    [FromAndPiddle, "From and Piddle"],
    [GannelPorthAndMenalhyl, "Gannel, Porth and Menalhyl"],
    [HartlandAndClovelly, "Hartland and Clovelly"],
    [HayleRedRiverAndNorthernStreams, "Hayle, Red River and Northern Streams"],
    [HelfordAndTheLizard, "Helford and the Lizard"],
    [LimAndAxe, "Lim and Axe"],
    [Lynher, "Lynher"],
    [NorthCoastStreams, "North Coast Streams"],
    [Otter, "Otter"],
    [Parrett, "Parrett"],
    [ParStAustellAndCaerhays, "Par, St Austell and Caerhays"],
    [PenwithPeninsula, "Penwith Peninsula "],
    [Plym, "Plym"],
    [PooleAndPurbeck, "Poole and Purbeck"],
    [SeatonLooeAndPolperro, "Seaton, Looe and Polperro"],
    [Sid, "Sid"],
    [StratNeet, "Strat/Neet"],
    [Tamar, "Tamar"],
    [Tavy, "Tavy"],
    [Taw, "Taw"],
    [Teign, "Teign"],
    [Torridge, "Torridge"],
    [WestDorsetRivers, "West Dorset Rivers"],
    [WestSomersetStreams, "West Somerset Streams"],
    [Yealm, "Yealm"],
    [Yeo, "Yeo"],
  ]),
  Avon,
  Axe,
  Brue,
  Camel,
  Cober,
  Dart,
  Erme,
  Exe,
  ExmoorStreams,
  Fal,
  Fowey,
  FromAndPiddle,
  GannelPorthAndMenalhyl,
  HartlandAndClovelly,
  HayleRedRiverAndNorthernStreams,
  HelfordAndTheLizard,
  LimAndAxe,
  Lynher,
  NorthCoastStreams,
  Otter,
  Parrett,
  ParStAustellAndCaerhays,
  PenwithPeninsula,
  Plym,
  PooleAndPurbeck,
  SeatonLooeAndPolperro,
  Sid,
  StratNeet,
  Tamar,
  Tavy,
  Taw,
  Teign,
  Torridge,
  WestDorsetRivers,
  WestSomersetStreams,
  Yealm,
  Yeo,
};

export default WrtUpstreamThinkingCatchmentEnum;
