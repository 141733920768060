import {
  isUuid,
  unsafeTeamAlias,
  unsafeTeamId,
  TeamAlias,
  TeamId,
  isTeamId,
  isTeamAlias,
} from "../core";

export type TeamRef = TeamAlias | TeamId;

export function unsafeTeamRef(str: string): TeamRef {
  return isUuid(str) ? unsafeTeamId(str) : unsafeTeamAlias(str);
}

export function isTeamRef(value: unknown): value is TeamRef {
  return isTeamId(value) || isTeamAlias(value);
}
