// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopSetting = "Low" | "High" | "Neutral" | "DontKnow";

const Low: UclPitStopSetting = "Low";
const High: UclPitStopSetting = "High";
const Neutral: UclPitStopSetting = "Neutral";
const DontKnow: UclPitStopSetting = "DontKnow";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopSettingEnum = {
  ...createEnum("UclPitStopSetting", [
    [Low, "In a depression/low point (likely to gain run off)"],
    [High, "In a high point (unlikely to gain run off)"],
    [Neutral, "Level with surrounding land"],
    [DontKnow, "Don't know"],
  ]),
  Low,
  High,
  Neutral,
  DontKnow,
};

export default UclPitStopSettingEnum;
