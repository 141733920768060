import { Option, Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isGeometry,
  isPickedF,
  Picked,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  NeNameSource,
  NeNameSourceEnum,
} from "@cartographerio/inventory-enums";
import { Timestamp } from "@cartographerio/types";
import { isPartialData, PartialData } from "../NeRiverNaturalness/parts";

export interface LakeSiteAttributes {
  NAME?: string | null;
  WBID?: number | null;
}

function isLakeSiteAttributes(v: unknown): v is LakeSiteAttributes {
  return (
    isObject(v) &&
    hasOptionalKey(v, "NAME", isNullable(isString)) &&
    hasOptionalKey(v, "WBID", isNullable(isNumber))
  );
}

export interface LakeSiteInfo {
  picked?: Picked<GeometryAtom, LakeSiteAttributes> | null;
  lakeNameSource?: NeNameSource | null;
  customLakeName?: string | null;
  customWaterbodyId?: number | null;
}

export function isLakeSiteInfo(v: unknown): v is LakeSiteInfo {
  return (
    isObject(v) &&
    hasOptionalKey(
      v,
      "picked",
      isNullable(isPickedF(isGeometry, isLakeSiteAttributes))
    ) &&
    hasOptionalKey(v, "lakeNameSource", isNullable(NeNameSourceEnum.isValue)) &&
    hasOptionalKey(v, "customLakeName", isNullable(isString)) &&
    hasOptionalKey(v, "customWaterbodyId", isNullable(isNumber))
  );
}

function createDescription(...args: Array<string | null>): string {
  return args.filter(arg => arg != null).join(", ");
}

const g = Result.guard(isPartialData(isLakeSiteInfo), "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    createDescription(
      Option.wrap(data.site.customLakeName)
        .orElseNullable(() => data.site.picked?.feature?.properties.NAME)
        .getOrElse(() => "Unknown Lake"),
      Option.wrap(data.site.customWaterbodyId)
        .orElseNullable(() => data.site.picked?.feature?.properties.WBID)
        .map(wbid => `WBID ${wbid}`)
        .getOrElse(() => "Unknown Waterbody ID")
    )
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.site?.picked?.point ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}

export function copyData(
  data: unknown
): Result<GuardError, PartialData<LakeSiteInfo>> {
  return g(data).map(data => ({
    ...data,
    // recorded: null,
    // site: {},
    // naturalness: {
    //   physical: { subCategoriesAssessed: {} },
    //   hydrological: { subCategoriesAssessed: {} },
    //   chemical: { formsOfAssessment: [] },
    //   biological: { subCategoriesAssessed: {} },
    // },
    // plantFunctionalGroups: [],
    // species: {
    //   mammals: [],
    //   birds: [],
    //   fish: [],
    //   herpetofauna: [],
    //   invertebrates: [],
    //   higherPlants: [],
    // },
    // habitatFeatures: {
    //   ditches: null,
    //   perimeterTrees: null,
    //   outflowStructure: null,
    //   emergentFringe: null,
    // },
    // notes: null,
  }));
}
