// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityOutfall = "None" | "Dormant" | "Discharging";

const None: ArrtWaterQualityOutfall = "None";
const Dormant: ArrtWaterQualityOutfall = "Dormant";
const Discharging: ArrtWaterQualityOutfall = "Discharging";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityOutfallEnum = {
  ...createEnum("ArrtWaterQualityOutfall", [
    [None, "None"],
    [Dormant, "Dormant (not discharging)"],
    [Discharging, "Active (discharging)"],
  ]),
  None,
  Dormant,
  Discharging,
};

export default ArrtWaterQualityOutfallEnum;
