import { Option, Result } from "@cartographerio/fp";
import { isPoint, Point, safePointToNgr } from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isString,
} from "@cartographerio/guard";
import {
  SasWaterQualitySiteType,
  SasWaterQualitySiteTypeEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

interface PartialData {
  established?: Timestamp | null;
  location?: Point | null;
  river?: string | null;
  site?: string | null;
  siteType?: SasWaterQualitySiteType | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    hasOptionalKey(data, "established", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "river", isNullable(isString)) &&
    hasOptionalKey(data, "site", isNullable(isString)) &&
    hasOptionalKey(
      data,
      "siteType",
      isNullable(SasWaterQualitySiteTypeEnum.isValue)
    )
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data.river,
      data.site,
      data.siteType,
      Option.wrap(data.location).map(safePointToNgr).getOrNull(),
    ]
      .filter(isString)
      .join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.established ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data.location ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
  }));
}
