// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessFishSpecies =
  | "Bullhead"
  | "BrookLamprey"
  | "BrownTrout"
  | "AtlanticSalmon";

const Bullhead: NeRiverNaturalnessFishSpecies = "Bullhead";
const BrookLamprey: NeRiverNaturalnessFishSpecies = "BrookLamprey";
const BrownTrout: NeRiverNaturalnessFishSpecies = "BrownTrout";
const AtlanticSalmon: NeRiverNaturalnessFishSpecies = "AtlanticSalmon";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessFishSpeciesEnum = {
  ...createEnum("NeRiverNaturalnessFishSpecies", [
    [Bullhead, "Bullhead (Cottus gobio)"],
    [BrookLamprey, "Brook lamprey (Lampetra planerii)"],
    [BrownTrout, "Brown trout (Salmo trutta)"],
    [AtlanticSalmon, "Atlantic salmon (Salmo salar)"],
  ]),
  Bullhead,
  BrookLamprey,
  BrownTrout,
  AtlanticSalmon,
};

export default NeRiverNaturalnessFishSpeciesEnum;
