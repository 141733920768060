import { endpoints } from "@cartographerio/client";
import { ApiParams, WorkspaceRef, WorkspaceUsage } from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type WorkspaceUsageKey =
  | ["workspace", "usage"]
  | ["workspace", "usage", "v1", "read", WorkspaceRef];

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<WorkspaceUsage, WorkspaceUsageKey> {
  return {
    queryKey: ["workspace", "usage", "v1", "read", workspaceRef],
    queryFn: () =>
      endpoints.workspace.usage.v1
        .readOrFail(apiParams, workspaceRef)
        .unsafeRun(),
  };
}
