// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityLandUse =
  | "Woodland"
  | "MoorlandOrHeath"
  | "UrbanOrResidential"
  | "IndustrialOrCommercial"
  | "GrasslandOrPasture"
  | "Agriculture"
  | "TilledLand"
  | "Other";

const Woodland: ArrtWaterQualityLandUse = "Woodland";
const MoorlandOrHeath: ArrtWaterQualityLandUse = "MoorlandOrHeath";
const UrbanOrResidential: ArrtWaterQualityLandUse = "UrbanOrResidential";
const IndustrialOrCommercial: ArrtWaterQualityLandUse =
  "IndustrialOrCommercial";
const GrasslandOrPasture: ArrtWaterQualityLandUse = "GrasslandOrPasture";
const Agriculture: ArrtWaterQualityLandUse = "Agriculture";
const TilledLand: ArrtWaterQualityLandUse = "TilledLand";
const Other: ArrtWaterQualityLandUse = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityLandUseEnum = {
  ...createEnum("ArrtWaterQualityLandUse", [
    [Woodland, "Woodland"],
    [MoorlandOrHeath, "Moorland/Heath"],
    [UrbanOrResidential, "Urban/Residential"],
    [IndustrialOrCommercial, "Industrial/Commercial"],
    [GrasslandOrPasture, "Grassland/Pasture"],
    [Agriculture, "Agriculture (Crops)"],
    [TilledLand, "Tilled Land (Ploughed/Cultivated Field)"],
    [Other, "Other"],
  ]),
  Woodland,
  MoorlandOrHeath,
  UrbanOrResidential,
  IndustrialOrCommercial,
  GrasslandOrPasture,
  Agriculture,
  TilledLand,
  Other,
};

export default ArrtWaterQualityLandUseEnum;
