// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankTopMaterial =
  | "EA"
  | "CC"
  | "SP"
  | "GA"
  | "OT"
  | "CB"
  | "WP"
  | "WS"
  | "BW"
  | "BC"
  | "AS"
  | "BR"
  | "RR"
  | "WO";

const BareEarth: CesBankTopMaterial = "EA";
const Concrete: CesBankTopMaterial = "CC";
const MetalSheets: CesBankTopMaterial = "SP";
const Gabions: CesBankTopMaterial = "GA";
const OtherCompacted: CesBankTopMaterial = "OT";
const ConcreteAndBrick: CesBankTopMaterial = "CB";
const WoodPanels: CesBankTopMaterial = "WP";
const WillowSpiling: CesBankTopMaterial = "WS";
const BuildersWaste: CesBankTopMaterial = "BW";
const Biotextiles: CesBankTopMaterial = "BC";
const Asphalt: CesBankTopMaterial = "AS";
const BrickOrStoneBlocks: CesBankTopMaterial = "BR";
const RipRap: CesBankTopMaterial = "RR";
const WashedOut: CesBankTopMaterial = "WO";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankTopMaterialEnum = {
  ...createEnum("CesBankTopMaterial", [
    [BareEarth, "Bare earth / soil"],
    [Concrete, "Concrete (including slabs/piling)"],
    [MetalSheets, "Metal sheets/piling/fencing"],
    [Gabions, "Gabions/rock rolls"],
    [
      OtherCompacted,
      "Other compacted material (e.g. wood chips, shale, gravel)",
    ],
    [ConcreteAndBrick, "Concrete & brick/stone (cemented)"],
    [WoodPanels, "Wood panels/planks/piling"],
    [WillowSpiling, "Willow spiling"],
    [BuildersWaste, "Builder's waste / hard core (tipped)"],
    [Biotextiles, "Biotextiles / coir"],
    [Asphalt, "Asphalt"],
    [BrickOrStoneBlocks, "Brick/stone/blocks (cemented)"],
    [RipRap, "Rip-rap (large stones, not cemented)"],
    [WashedOut, "Washed out / collapsed"],
  ]),
  BareEarth,
  Concrete,
  MetalSheets,
  Gabions,
  OtherCompacted,
  ConcreteAndBrick,
  WoodPanels,
  WillowSpiling,
  BuildersWaste,
  Biotextiles,
  Asphalt,
  BrickOrStoneBlocks,
  RipRap,
  WashedOut,
};

export default CesBankTopMaterialEnum;
