// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21LitterPolystyreneCover =
  | "50-100"
  | "20-49"
  | "10-19"
  | "5-9"
  | "0-4";

const From50To100: Thames21LitterPolystyreneCover = "50-100";
const From20To49: Thames21LitterPolystyreneCover = "20-49";
const From10To19: Thames21LitterPolystyreneCover = "10-19";
const From5To9: Thames21LitterPolystyreneCover = "5-9";
const From0To4: Thames21LitterPolystyreneCover = "0-4";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21LitterPolystyreneCoverEnum = {
  ...createEnum("Thames21LitterPolystyreneCover", [
    [From50To100, "50-100% cover"],
    [From20To49, "20-49% cover"],
    [From10To19, "10-19% cover"],
    [From5To9, "5-9% cover"],
    [From0To4, ">0-4% cover"],
  ]),
  From50To100,
  From20To49,
  From10To19,
  From5To9,
  From0To4,
};

export default Thames21LitterPolystyreneCoverEnum;
