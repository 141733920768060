import {
  attributeGroup,
  booleanAttr,
  enumAttr,
  pointLayer,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  FhtPondCountPresenceEnum,
  FhtPondCountSizeEnum,
  FhtPondCountWhyCreatedEnum,
  FhtPondCountWhyLostOrDestroyedEnum,
  FhtPondCountWhyMissedEnum,
  FhtPondCountWhySizeChangedEnum,
} from "@cartographerio/inventory-enums";
import { unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const FhtPondCount = mapSchemaWithDefaults({
  mapId: unsafeMapId("FhtPondCount"),
  title: "Urban Pond Count",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("FhtPondCount"),
      title: "Urban Pond Count",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("FhtPondCount"),
        attribution: [],
      }),
      defaultAttribute: "presence",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey Details",
          attributes: [
            timestampAttr({
              label: "Date/Time",
              attributeId: "timestamp",
              buckets: "auto",
            }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("FhtPondCount"),
            }),
            surveyAttr("surveyId", "Survey"),
          ],
        }),
        attributeGroup({
          label: "Existing Pond",
          attributes: [
            stringAttr({
              label: "Square Number",
              attributeId: "map_squareNumber",
              buckets: "auto",
            }),
            stringAttr({
              label: "Square Name",
              attributeId: "map_squareName",
              buckets: "auto",
            }),
            stringAttr({
              label: "Pond Number",
              attributeId: "map_pondNumber",
              buckets: "auto",
            }),
            enumAttr({
              label: "Pond Area",
              attributeId: "map_size",
              enum: FhtPondCountSizeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Presence or Absence",
          attributes: [
            enumAttr({
              label: "Presence or Absence",
              attributeId: "presence",
              enum: FhtPondCountPresenceEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Newly Found Ponds",
          attributes: [
            booleanAttr({
              label: "Why Newly Found?",
              attributeId: "newPond_whyNew",
              buckets: "auto",
            }),
            ...FhtPondCountWhyCreatedEnum.entries.map(({ value, label }) =>
              booleanAttr({
                label,
                attributeId: `newPond_whyCreated_${value}`,
                buckets: "auto",
              })
            ),
            enumAttr({
              label: "Why Previously Missed?",
              attributeId: "newPond_whyMissed",
              enum: FhtPondCountWhyMissedEnum,
            }),
            enumAttr({
              label: "Pond Area",
              attributeId: "newPond_size",
              enum: FhtPondCountSizeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Existing Ponds",
          attributes: [
            booleanAttr({
              label: "Existing Area Correct?",
              attributeId: "existingPond_sizeCorrect",
              buckets: "auto",
            }),
            enumAttr({
              label: "Updated Area",
              attributeId: "existingPond_updatedSize",
              enum: FhtPondCountSizeEnum,
            }),
            enumAttr({
              label: "Why Area Changed",
              attributeId: "existingPond_whySizeChanged",
              enum: FhtPondCountWhySizeChangedEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Absent Ponds",
          attributes: [
            booleanAttr({
              label: "Misidentified?",
              attributeId: "absentPond_misidentified",
              buckets: "auto",
            }),
            booleanAttr({
              label: "Lost or Destroyed?",
              attributeId: "absentPond_lostOrDestroyed",
              buckets: "auto",
            }),
            ...FhtPondCountWhyLostOrDestroyedEnum.entries.map(
              ({ value, label }) =>
                booleanAttr({
                  label,
                  attributeId: `absentPond_whyLostOrDestroyed_${value}`,
                  buckets: "auto",
                })
            ),
          ],
        }),
      ],
    }),
  ],
});
