import { Textarea } from "@chakra-ui/react";
import autosize from "autosize";
import { MutableRefObject, ReactElement, useEffect, useRef } from "react";

import { useInputHighlight } from "../../hooks/highlight";
import useDebouncedTextValue from "../../hooks/useDebouncedTextValue";
import { useInputFocus } from "../../hooks/useInputFocus";
import { BaseTextAreaProps, MappedTextAreaProps } from "./base";

export interface DefaultTextAreaProps<A>
  extends BaseTextAreaProps<A>,
    MappedTextAreaProps<A> {}

export default function DefaultTextArea<A>(
  props: DefaultTextAreaProps<A>
): ReactElement {
  const {
    value,
    defaultValue,
    onChange,
    rows = 5,
    format,
    validate,
    highlight,
    debounce,
    bg = "white",
    autoSize = false,
    ...rest
  } = props;

  const [focused, _handleFocus, _handleBlur] = useInputFocus();

  const { textValue, localError, handleBlur, handleFocus, handleTextChange } =
    useDebouncedTextValue<A, HTMLTextAreaElement>({
      value,
      defaultValue,
      format,
      validate,
      onFocus: _handleFocus,
      onBlur: _handleBlur,
      onChange,
      debounce,
    });

  const { borderColor, borderWidth } = useInputHighlight(
    localError ? "error" : highlight,
    focused
  );

  const ref = useRef<HTMLTextAreaElement>();

  useAutoSize(autoSize, ref);

  return (
    <Textarea
      ref={ref}
      bg={bg}
      value={textValue}
      onChange={handleTextChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      rows={rows}
      borderColor={borderColor}
      borderWidth={borderWidth}
      {...rest}
    />
  );
}

function useAutoSize(
  enable: boolean,
  ref: MutableRefObject<HTMLTextAreaElement | undefined>
) {
  useEffect(() => {
    const textarea = ref.current;

    if (enable && textarea != null) {
      autosize(textarea);
    }

    return () => {
      if (enable && textarea != null) {
        autosize.destroy(textarea);
      }
    };
  }, [enable, ref]);
}
