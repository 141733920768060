import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  WorkspaceLimits,
  WorkspaceSubscribed,
  WorkspaceUsage,
  nowTimestamp,
} from "@cartographerio/types";
import {
  Card,
  CardBody,
  SimpleGrid,
  Spinner,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useRef } from "react";

import queries from "../../queries";
import { useIOConfirm } from "../components/Alert";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import { useVolatileState } from "../hooks/useVolatileState";
import { formatIntervalAmount } from "./format";
import { calculateNewLimit, invoiceTotalAmount } from "./helpers";
import PlanSection, { SubscriptionChangeFunc } from "./PlanSection";
import UsageLimitsSection from "./UsageLimitsSection";
import UsageSection from "./UsageSection";

export interface WorkspaceSubscribedViewProps {
  summary: WorkspaceSubscribed;
  limits: WorkspaceLimits;
  usage: WorkspaceUsage;
}

export default function WorkspaceSubscribedView(
  props: WorkspaceSubscribedViewProps
) {
  const {
    summary: {
      workspaceId,
      workspaceAlias,
      plan,
      usage: billingUsage,
      subscription,
    },
    limits,
    usage: workspaceUsage,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const confirm = useIOConfirm();
  const toast = useToast();

  const newPlanAmountRef = useRef<HTMLElement>(null);

  const [lastInternalUpdate, setLastInternalUpdate] = useVolatileState(
    useCallback(
      () => subscription.lastInternalUpdate,
      [subscription.lastInternalUpdate]
    )
  );

  const handleSubscriptionChange = useCallback<SubscriptionChangeFunc>(
    ({
      plan: newPlan = plan,
      interval: newInterval = subscription.billingInterval,
    }) => {
      IO.parallel([
        endpoints.billing.calculation.v1
          .readOrFail(apiParams, {
            workspace: workspaceId,
            plan: newPlan.id,
            interval: newInterval,
          })
          .tap(({ previewTotal }) => {
            if (newPlanAmountRef.current != null) {
              newPlanAmountRef.current.innerHTML = formatIntervalAmount(
                invoiceTotalAmount(previewTotal),
                newInterval
              );
            }
          })
          .void(),
        confirm({
          title: "Confirm Subscription Change",
          message: (
            <SimpleGrid columns={2} gap="4">
              <Card>
                <CardBody>
                  <Spaced spacing="2">
                    <Para fontWeight="bold">From {plan.name}</Para>
                    {plan.pricingScheme.type === "PerUser" ? (
                      <Para>
                        With {limits.maxUsers ?? billingUsage.numUsers} users
                      </Para>
                    ) : (
                      <Para>
                        With {limits.maxProjects ?? billingUsage.numProjects}{" "}
                        projects
                      </Para>
                    )}
                    <Para>
                      {formatIntervalAmount(
                        invoiceTotalAmount(subscription.nextInvoiceTotal),
                        subscription.billingInterval
                      )}
                    </Para>
                  </Spaced>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Spaced spacing="2">
                    <Para fontWeight="bold">To {newPlan.name}</Para>
                    {newPlan.pricingScheme.type === "PerUser" ? (
                      <Para>
                        With{" "}
                        {calculateNewLimit(
                          billingUsage.numUsers,
                          newPlan.limits.maxUsers
                        )}{" "}
                        users
                      </Para>
                    ) : (
                      limits.maxProjects != null && (
                        <Para>
                          With{" "}
                          {calculateNewLimit(
                            billingUsage.numProjects,
                            limits.maxProjects
                          )}{" "}
                          projects
                        </Para>
                      )
                    )}
                    <Para>
                      <chakra.span ref={newPlanAmountRef}>
                        <Spinner w="1em" h="1em" color="gray.500" />
                      </chakra.span>
                    </Para>
                  </Spaced>
                </CardBody>
              </Card>
            </SimpleGrid>
          ),
        })
          .flatMap(confirmed =>
            confirmed
              ? IO.wrap(setLastInternalUpdate(nowTimestamp()))
                  .andThen(
                    queries.billing.subscription.v1.update(
                      queryClient,
                      apiParams,
                      workspaceId,
                      { planId: newPlan.id, billingInterval: newInterval }
                    )
                  )
                  .tap(() => {
                    toast({
                      title: "Subscription updated.",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                  })
                  .recover(_err =>
                    toast({
                      title: "Could not update your subscription.",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    })
                  )
                  .void()
              : IO.noop()
          )
          .void(),
      ]).unsafeRun();
    },
    [
      apiParams,
      confirm,
      limits,
      plan,
      queryClient,
      setLastInternalUpdate,
      subscription.billingInterval,
      subscription.nextInvoiceTotal,
      toast,
      billingUsage.numProjects,
      billingUsage.numUsers,
      workspaceId,
    ]
  );

  const onLimitsChange = useCallback(
    (newLimits: WorkspaceLimits) =>
      queries.workspace.limits.v1
        .save(queryClient, apiParams, workspaceId, newLimits)
        .tap(() =>
          toast({
            description: "Usage limits updated",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .unsafeRun(),
    [apiParams, queryClient, toast, workspaceId]
  );

  return (
    <Spaced spacing="8">
      <PlanSection
        workspaceAlias={workspaceAlias}
        usage={billingUsage}
        plan={plan}
        subscription={subscription}
        lastInternalUpdate={lastInternalUpdate}
        onSubscriptionChange={handleSubscriptionChange}
      />
      <SimpleGrid columns={[1, 2]} gap="8">
        <Card>
          <CardBody>
            <UsageSection
              usage={workspaceUsage}
              pricingSchemeType={plan.pricingScheme.type}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <UsageLimitsSection
              limits={limits}
              usage={workspaceUsage}
              onLimitsChange={onLimitsChange}
            />
          </CardBody>
        </Card>
      </SimpleGrid>
    </Spaced>
  );
}
