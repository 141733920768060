/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  ProjectId,
  isProjectId,
  SurveyId,
  isSurveyId,
  SurveyModuleId,
  isSurveyModuleId,
  TeamId,
  isTeamId,
  UserId,
  isUserId,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";

export interface SurveyTransferRequest {
  srcProject: ProjectId;
  srcModule: SurveyModuleId;
  desProject: ProjectId;
  desModule: SurveyModuleId;
  surveys: SurveyId[];
  teamMappings: [TeamId | null, TeamId | null][];
  copy: boolean;
}

export interface UserTransferRequest {
  srcWorkspace: WorkspaceId;
  desWorkspace: WorkspaceId;
  users: UserId[];
  projectMappings: [ProjectId, ProjectId | null][];
  teamMappings: [TeamId, TeamId | null][];
  transferSurveys: boolean;
}

export interface ProjectTransferRequest {
  srcProject: ProjectId;
  desWorkspace: WorkspaceId;
  transferMembers: boolean;
  leaveBreadcrumb: boolean;
}

export interface TransferResult<A> {
  failed: A[];
  successful: A[];
}

export interface UserTransferResult {
  users: TransferResult<UserId>;
  surveys: TransferResult<SurveyId>;
}

export interface SurveyTransferResult {
  surveys: TransferResult<SurveyId>;
}

export interface ProjectTransferResult {
  projects: TransferResult<ProjectId>;
}

export const isSurveyTransferRequest = (v: any): v is SurveyTransferRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "srcProject" in v &&
    isProjectId(v.srcProject) &&
    "srcModule" in v &&
    isSurveyModuleId(v.srcModule) &&
    "desProject" in v &&
    isProjectId(v.desProject) &&
    "desModule" in v &&
    isSurveyModuleId(v.desModule) &&
    "surveys" in v &&
    Array.isArray(v.surveys) &&
    v.surveys.every((i: any) => isSurveyId(i)) &&
    "teamMappings" in v &&
    Array.isArray(v.teamMappings) &&
    v.teamMappings.every(
      (i: any) =>
        Array.isArray(i) &&
        i.length === 2 &&
        (isTeamId(i[0]) || i[0] === null) &&
        (isTeamId(i[1]) || i[1] === null)
    ) &&
    "copy" in v &&
    typeof v.copy === "boolean"
  );
};

export const isUserTransferRequest = (v: any): v is UserTransferRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "srcWorkspace" in v &&
    isWorkspaceId(v.srcWorkspace) &&
    "desWorkspace" in v &&
    isWorkspaceId(v.desWorkspace) &&
    "users" in v &&
    Array.isArray(v.users) &&
    v.users.every((i: any) => isUserId(i)) &&
    "projectMappings" in v &&
    Array.isArray(v.projectMappings) &&
    v.projectMappings.every(
      (i: any) =>
        Array.isArray(i) &&
        i.length === 2 &&
        isProjectId(i[0]) &&
        (isProjectId(i[1]) || i[1] === null)
    ) &&
    "teamMappings" in v &&
    Array.isArray(v.teamMappings) &&
    v.teamMappings.every(
      (i: any) =>
        Array.isArray(i) &&
        i.length === 2 &&
        isTeamId(i[0]) &&
        (isTeamId(i[1]) || i[1] === null)
    ) &&
    "transferSurveys" in v &&
    typeof v.transferSurveys === "boolean"
  );
};

export const isProjectTransferRequest = (
  v: any
): v is ProjectTransferRequest => {
  return (
    typeof v === "object" &&
    v != null &&
    "srcProject" in v &&
    isProjectId(v.srcProject) &&
    "desWorkspace" in v &&
    isWorkspaceId(v.desWorkspace) &&
    "transferMembers" in v &&
    typeof v.transferMembers === "boolean" &&
    "leaveBreadcrumb" in v &&
    typeof v.leaveBreadcrumb === "boolean"
  );
};

export const isTransferResult =
  <A>(isA: (a: any) => a is A) =>
  (v: any): v is TransferResult<A> => {
    return (
      typeof v === "object" &&
      v != null &&
      "failed" in v &&
      Array.isArray(v.failed) &&
      v.failed.every((i: any) => isA(i)) &&
      "successful" in v &&
      Array.isArray(v.successful) &&
      v.successful.every((i: any) => isA(i))
    );
  };

export const isUserTransferResult = (v: any): v is UserTransferResult => {
  return (
    typeof v === "object" &&
    v != null &&
    "users" in v &&
    isTransferResult((a0: any): a0 is UserId => isUserId(a0))(v.users) &&
    "surveys" in v &&
    isTransferResult((a0: any): a0 is SurveyId => isSurveyId(a0))(v.surveys)
  );
};

export const isSurveyTransferResult = (v: any): v is SurveyTransferResult => {
  return (
    typeof v === "object" &&
    v != null &&
    "surveys" in v &&
    isTransferResult((a0: any): a0 is SurveyId => isSurveyId(a0))(v.surveys)
  );
};

export const isProjectTransferResult = (v: any): v is ProjectTransferResult => {
  return (
    typeof v === "object" &&
    v != null &&
    "projects" in v &&
    isTransferResult((a0: any): a0 is ProjectId => isProjectId(a0))(v.projects)
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
