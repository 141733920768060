import {
  appendPath,
  errorMessage,
  Path,
  pathGet,
  pathSet,
} from "@cartographerio/topo-core";
import {
  ArraySchema,
  isAnySchema,
  isArraySchema,
  isSchema,
  Schema,
  schemaBlankValue,
  schemaRef,
  unknownSchema,
} from "@cartographerio/topo-survey";
import { Result } from "@cartographerio/fp";
import {
  commonLocations,
  isFeature,
  isLineString,
  isPicked,
  isPoint,
  isPolygon,
  Point,
} from "@cartographerio/geometry";
import {
  isArray,
  isBoolean,
  isNullable,
  isOneOf,
  isString,
} from "@cartographerio/guard";
import {
  randomAttachmentFolder,
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  isUserRef,
  Message,
  nowTimestamp,
  Timestamp,
  userRef,
  UserRef,
  isTeamRef,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { isNumber } from "lodash";
import { isField } from "./guard";
import { fieldIsNullable } from "./nullable";
import { Block, Content, Field, Form, Page } from "./type";

export interface InitialiseOpts {
  genUserRef: () => UserRef;
  genAttachmentFolder: () => AttachmentFolder;
  genTimestamp: () => Timestamp;
  genPoint: () => Point;
}

export const defaultInitialiseOpts: InitialiseOpts = {
  genUserRef: () => userRef(),
  genAttachmentFolder: randomAttachmentFolder,
  genTimestamp: nowTimestamp,
  genPoint: () => commonLocations.greatBritain.center,
};

export function initialiseField(
  field: Field,
  parentPath: Path,
  doc: unknown,
  opts: InitialiseOpts = defaultInitialiseOpts
): Result<Message, unknown> {
  const path: Path = appendPath(parentPath, field.path);

  const initialise = (
    defaultValue: unknown,
    guard: (value: unknown) => boolean,
    fallbackValue: () => unknown
  ): Result<Message, unknown> => {
    if (defaultValue === undefined) {
      return guard(pathGet(doc, path))
        ? Result.pure(doc)
        : pathSet(doc, path, fallbackValue());
    } else {
      return pathSet(doc, path, defaultValue);
    }
  };

  const nullable = fieldIsNullable(field);

  switch (field.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
      return initialise(
        field.defaultValue,
        nullable ? isNullable(isString) : isString,
        nullable ? () => null : () => ""
      );

    case "IntegerField":
    case "NumberField":
      return initialise(field.defaultValue, isNullable(isNumber), () => null);

    case "UserRefField":
      return initialise(
        undefined,
        nullable ? isNullable(isUserRef) : isUserRef,
        nullable ? () => null : opts.genUserRef
      );

    case "TimestampField":
      return initialise(
        field.defaultValue === "now" ? opts.genTimestamp() : field.defaultValue,
        nullable ? isNullable(isTimestamp) : isTimestamp,
        nullable ? () => null : opts.genTimestamp
      );

    case "AttachmentField":
      return initialise(
        undefined,
        isAttachmentFolder,
        opts.genAttachmentFolder
      );

    case "TeamField":
      return initialise(
        undefined,
        nullable ? isNullable(isTeamRef) : isTeamRef,
        () => null
      );

    case "PointField":
      return initialise(field.defaultValue, isNullable(isPoint), () => null);

    case "LineStringField":
      return initialise(
        field.defaultValue,
        isNullable(isLineString),
        () => null
      );

    case "PolygonField":
      return initialise(field.defaultValue, isNullable(isPolygon), () => null);

    case "FeatureField":
      return initialise(field.defaultValue, isNullable(isFeature), () => null);

    case "NearestFeatureField":
      return initialise(field.defaultValue, isNullable(isPicked), () => null);

    case "Checkbox":
      return initialise(field.defaultValue, isBoolean, () => false);

    case "Select": {
      const isValue = isOneOf(field.options.map(opt => opt.value));

      return initialise(field.defaultValue, isNullable(isValue), () => null);
    }

    case "MultiSelect":
      return initialise(field.defaultValue, isArray, () => []);

    default:
      checkExhausted(field);
  }
}

export function initialiseContent(
  block: Content,
  schema: Schema,
  parentPath: Path,
  doc: unknown,
  opts: InitialiseOpts = defaultInitialiseOpts
): Result<Message, unknown> {
  switch (block.type) {
    case "DynamicValue":
    case "DynamicGrid":
    case "DynamicImage":
    case "Heading":
    case "Text":
    case "PrimarySurveyorField":
    case "PrimaryTeamField":
      return Result.pure(doc);

    case "Group":
      return Result.reduce(block.blocks, doc, (survey, block) =>
        initialiseBlock(block, schema, parentPath, survey, opts)
      );

    case "Repeat": {
      const arrayPath = appendPath(parentPath, block.path);
      const arraySchema = schemaRef(schema, block.path) ?? unknownSchema;
      const targetLength = block.defaultLength ?? 0;

      return Result.pass<Message, Schema>(arraySchema)
        .guardWith(
          (s: unknown): s is ArraySchema =>
            isSchema(s) && isArraySchema(isAnySchema)(s),
          () =>
            errorMessage(
              "Schema for repeat block isn't an array",
              arrayPath,
              schema
            )
        )
        .flatMap(arraySchema =>
          initialiseBlock(
            block.block,
            arraySchema.param,
            [],
            schemaBlankValue(arraySchema.param),
            opts
          )
        )
        .map(arrayItem => new Array(targetLength).fill(arrayItem))
        .flatMap(array => pathSet(doc, arrayPath, array));
    }

    case "Card":
    case "FullWidth":
    case "Visibility":
    case "RequireRole":
      return initialiseBlock(block.block, schema, parentPath, doc, opts);
    case "Scope": {
      return initialiseBlock(
        block.block,
        schemaRef(schema, block.path) ?? unknownSchema,
        appendPath(parentPath, block.path),
        doc,
        opts
      );
    }

    default:
      return checkExhausted(block);
  }
}

export function initialiseBlock(
  block: Block,
  schema: Schema,
  parentPath: Path,
  doc: unknown,
  opts: InitialiseOpts = defaultInitialiseOpts
): Result<Message, unknown> {
  if (isField(block)) {
    return initialiseField(block, parentPath, doc, opts);
  } else {
    return initialiseContent(block, schema, parentPath, doc, opts);
  }
}

export function initialisePage(
  page: Page,
  schema: Schema,
  parentPath: Path,
  doc: unknown,
  opts: InitialiseOpts = defaultInitialiseOpts
): Result<Message, unknown> {
  const path = appendPath(parentPath, page.path);

  return Result.reduce(page.blocks, doc, (survey, block) =>
    initialiseBlock(block, schema, path, survey, opts)
  );
}

export function initialiseForm(
  form: Form,
  schema: Schema,
  path: Path,
  doc: unknown,
  opts: InitialiseOpts = defaultInitialiseOpts
): Result<Message, unknown> {
  return Result.reduce(form.pages, doc, (survey, page) => {
    const pageSchema = schemaRef(schema, page.path) ?? unknownSchema;
    return initialisePage(page, pageSchema, path, survey, opts);
  });
}
