// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsPollutionSource = "inputPipes" | "leachPoints";

const InputPipes: UrsPollutionSource = "inputPipes";
const LeachPoints: UrsPollutionSource = "leachPoints";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsPollutionSourceEnum = {
  ...createEnum("UrsPollutionSource", [
    [InputPipes, "Input pipes"],
    [LeachPoints, "Leach points"],
  ]),
  InputPipes,
  LeachPoints,
};

export default UrsPollutionSourceEnum;
