import { parseMarkdown } from "../core";
import { astHandlers } from "./handlers";
import { Node } from "./type";

export * from "./create";
export * from "./functions";
export * from "./handlers";
export * from "./type";

export function markdownAst(markdown: string): Node {
  return parseMarkdown(markdown, astHandlers());
}
