import {
  BillingInterval,
  BillingUsage,
  Plan,
  PlanId,
  WorkspaceRef,
} from "@cartographerio/types";
import {
  Alert,
  AlertDescription,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import Spaced from "../components/Spaced";
import SuspenseBoundary from "../components/SuspenseBoundary";
import PlanChooser from "./PlanChooser";

interface PlanChooserModalProps {
  workspaceRef: WorkspaceRef;
  usage: BillingUsage;
  defaultPlanId?: PlanId;
  defaultBillingInterval?: BillingInterval;
  showCouponAlert?: boolean;
  isOpen: boolean;
  onClose: () => void;
  planLink?: (plan: Plan, billingInterval: BillingInterval) => string;
  onPlanClick?: (plan: Plan, billingInterval: BillingInterval) => void;
}

export default function PlanChooserModal(
  props: PlanChooserModalProps
): ReactElement {
  const {
    workspaceRef,
    usage,
    defaultPlanId,
    defaultBillingInterval,
    showCouponAlert,
    isOpen,
    onClose,
    planLink,
    onPlanClick,
  } = props;

  const handlePlanClick = useCallback(
    (plan: Plan, billingInterval: BillingInterval) => {
      onClose();
      onPlanClick?.(plan, billingInterval);
    },
    [onClose, onPlanClick]
  );

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="3xl" marginInline="6">
        <ModalHeader>Choose a Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody px="0" pb="0">
          <SuspenseBoundary>
            <Spaced>
              {showCouponAlert && (
                <Alert status="info" justifyContent="center">
                  <AlertDescription textAlign="center">
                    Discounts will be applied after you choose your plan and
                    shown on the checkout page.
                  </AlertDescription>
                </Alert>
              )}
              <PlanChooser
                workspaceRef={workspaceRef}
                usage={usage}
                defaultPlanId={defaultPlanId}
                defaultBillingInterval={defaultBillingInterval}
                planLink={planLink}
                onPlanClick={handlePlanClick}
              />
            </Spaced>
          </SuspenseBoundary>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
