import { Result } from "@cartographerio/fp";
import { hasKeyOfAnyType, isObject } from "@cartographerio/guard";
import lodash from "lodash";
import { errorMessage } from "./message";
import { Message, Path, PathLike } from "./type";

export function isPath(p: unknown): p is Path {
  return (
    Array.isArray(p) &&
    p.reduce(
      (accum, item) =>
        accum && (typeof item === "string" || typeof item === "number"),
      true
    )
  );
}

export function pathToString(path: Path): string {
  return "/" + path.join("/");
}

export function unsafeStringToPath(str: string): Path {
  return str.split(".").map(field => {
    const index = parseInt(field, 10);
    return isNaN(index) ? field : index;
  });
}

export function createPath(path: PathLike): Path {
  if (typeof path === "string" || typeof path == "number") {
    return [path];
  } else {
    return path;
  }
}

export function appendPath(path: Path, suffix: PathLike): Path {
  if (typeof suffix === "string" || typeof suffix == "number") {
    return [...path, suffix];
  } else {
    return [...path, ...suffix];
  }
}

export function prefixPath(prefix: PathLike, path: Path): Path {
  if (typeof prefix === "string" || typeof prefix == "number") {
    return [prefix, ...path];
  } else {
    return [...prefix, ...path];
  }
}

export function prefixPaths(prefix: PathLike, paths: Path[]): Path[] {
  return paths.map(path => prefixPath(prefix, path));
}

// Fetch a value at a specified path into a document.
export function pathGet(document: unknown, path: Path): unknown {
  if (path.length === 0) {
    return document;
  } else {
    const [head, ...tail] = path;
    if (typeof head === "string") {
      return hasKeyOfAnyType(document, head)
        ? pathGet(document[head], tail)
        : undefined;
    } else {
      return Array.isArray(document)
        ? pathGet(document[head], tail)
        : undefined;
    }
  }
}

function trimArray<A>(arr: A[]): A[] {
  let end = arr.length - 1;

  while (end >= 0 && arr[end] === undefined) {
    end = end - 1;
  }

  if (end === arr.length - 1) {
    return arr;
  } else {
    return arr.slice(0, end + 1);
  }
}

export function pathUpdate(
  doc: unknown,
  path: Path,
  func: (orig: unknown) => unknown
): Result<Message, unknown> {
  if (path.length === 0) {
    return Result.pure(func(doc));
  } else {
    const [head, ...tail] = path;

    if (typeof head === "string") {
      if (doc == null) {
        return pathUpdate(null, tail, func).map(replacement => ({
          [head]: replacement,
        }));
      } else if (hasKeyOfAnyType(doc, head)) {
        return pathUpdate(doc[head], tail, func).flatMap(replacement => {
          if (replacement === undefined) {
            const copy = { ...doc };
            delete copy[head];
            return Result.pure(copy);
          } else {
            return Result.pure({ ...doc, [head]: replacement });
          }
        });
      } else if (isObject(doc) && !Array.isArray(doc)) {
        return pathUpdate(null, tail, func).map(replacement =>
          replacement === undefined ? doc : { ...doc, [head]: replacement }
        );
      } else {
        return Result.fail(errorMessage("Could not update field", path));
      }
    } else {
      if (doc == null) {
        const ans = lodash.fill<unknown>(Array(head + 1), null);
        ans[head] = pathUpdate(null, tail, func).getOrUndefined();
        return Result.pure(ans);
      } else if (Array.isArray(doc) && head >= 0) {
        if (head >= doc.length) {
          return pathUpdate(null, tail, func).map(replacement =>
            replacement === undefined
              ? doc
              : trimArray([
                  ...doc,
                  ...lodash.fill(Array(head - doc.length), null),
                  replacement,
                ])
          );
        } else {
          return pathUpdate(doc[head], tail, func).map(replacement =>
            trimArray([
              ...doc.slice(0, head),
              replacement,
              ...doc.slice(head + 1),
            ])
          );
        }
      } else {
        return Result.fail(errorMessage("Could not update index", path));
      }
    }
  }
}

export function pathSet(
  document: unknown,
  path: Path,
  value: unknown
): Result<Message, unknown> {
  return pathUpdate(document, path, () => value);
}

export function pathDel(
  document: unknown,
  path: Path
): Result<Message, unknown> {
  return pathUpdate(document, path, () => undefined);
}
