// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsFlowType =
  | "FF"
  | "CH"
  | "BW"
  | "UW"
  | "CF"
  | "RP"
  | "UP"
  | "SM"
  | "NP"
  | "DR";

const FreeFall: UrsFlowType = "FF";
const Chute: UrsFlowType = "CH";
const BrokenStandingWave: UrsFlowType = "BW";
const UnbrokenStandingWave: UrsFlowType = "UW";
const ChaoticFlow: UrsFlowType = "CF";
const Rippled: UrsFlowType = "RP";
const Upwelling: UrsFlowType = "UP";
const Smooth: UrsFlowType = "SM";
const NoPerceptableFlow: UrsFlowType = "NP";
const Dry: UrsFlowType = "DR";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsFlowTypeEnum = {
  ...createEnum("UrsFlowType", [
    [FreeFall, "Free Fall"],
    [Chute, "Chute"],
    [BrokenStandingWave, "Broken standing waves"],
    [UnbrokenStandingWave, "Unbroken standing waves"],
    [ChaoticFlow, "Chaotic Flow"],
    [Rippled, "Rippled"],
    [Upwelling, "Upwelling"],
    [Smooth, "Smooth"],
    [NoPerceptableFlow, "No perceptable flow"],
    [Dry, "Dry"],
  ]),
  FreeFall,
  Chute,
  BrokenStandingWave,
  UnbrokenStandingWave,
  ChaoticFlow,
  Rippled,
  Upwelling,
  Smooth,
  NoPerceptableFlow,
  Dry,
};

export default UrsFlowTypeEnum;
