// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverRestorationPrioritiesBiologicalMeasure =
  | "RemoveNonNativeSpecies"
  | "ReduceFishStocking"
  | "ReduceFisheryDrivenRemoval"
  | "ReduceWeedCutting"
  | "ReduceHeavyGrazing";

const RemoveNonNativeSpecies: NeRiverRestorationPrioritiesBiologicalMeasure =
  "RemoveNonNativeSpecies";
const ReduceFishStocking: NeRiverRestorationPrioritiesBiologicalMeasure =
  "ReduceFishStocking";
const ReduceFisheryDrivenRemoval: NeRiverRestorationPrioritiesBiologicalMeasure =
  "ReduceFisheryDrivenRemoval";
const ReduceWeedCutting: NeRiverRestorationPrioritiesBiologicalMeasure =
  "ReduceWeedCutting";
const ReduceHeavyGrazing: NeRiverRestorationPrioritiesBiologicalMeasure =
  "ReduceHeavyGrazing";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverRestorationPrioritiesBiologicalMeasureEnum = {
  ...createEnum("NeRiverRestorationPrioritiesBiologicalMeasure", [
    [
      RemoveNonNativeSpecies,
      "Strategic area-based control of non-native species",
    ],
    [ReduceFishStocking, "Reduce intensity of/halt fish stocking"],
    [
      ReduceFisheryDrivenRemoval,
      "Halt fishery-driven removal of non-target native fish species",
    ],
    [ReduceWeedCutting, "Reduce/halt in-channel and marginal weed-cutting"],
    [ReduceHeavyGrazing, "Eliminate heavy grazing of riparian vegetation"],
  ]),
  RemoveNonNativeSpecies,
  ReduceFishStocking,
  ReduceFisheryDrivenRemoval,
  ReduceWeedCutting,
  ReduceHeavyGrazing,
};

export default NeRiverRestorationPrioritiesBiologicalMeasureEnum;
