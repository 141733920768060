import { surveyModuleFromJson } from "../SurveyModule";
import { dataDescription, dataGeometry, dataTimestamp } from "../Urs/parts";
import { ursForm } from "../Urs/form";
import moduleJson from "./module.generated.json";

const formSchema = ursForm("PlaceMarker River Stretch");

export default surveyModuleFromJson({
  moduleJson,
  formSchema,
  dataDescription,
  dataGeometry,
  dataTimestamp,
}).unsafeGet();
