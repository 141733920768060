import {
  AnonymisedUser,
  KnownWorkspaceFeatureEnum,
  ProjectId,
  UserRef,
} from "@cartographerio/types";
import { UserWorkspaceGraph } from "@cartographerio/workspace-graph";

export function getSurveyorUser(
  graph: UserWorkspaceGraph,
  surveyor: UserRef | null
): AnonymisedUser | null {
  return surveyor?.userId != null
    ? graph.optFindUserById(surveyor.userId)
    : null;
}

export function checkMultiTeam(
  graph: UserWorkspaceGraph,
  projectId: ProjectId
): boolean {
  const project = graph.findProjectById(projectId);
  const workspace = graph.findWorkspaceById(project.workspaceId);

  return (
    workspace.features.includes(KnownWorkspaceFeatureEnum.EnableTeams) &&
    project.teamIds.length > 0
  );
}
