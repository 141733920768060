// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesFringeContinuity = "N" | "C" | "E" | "F";

const None: CesFringeContinuity = "N";
const Continuous: CesFringeContinuity = "C";
const ExtendedPatches: CesFringeContinuity = "E";
const Fragmented: CesFringeContinuity = "F";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesFringeContinuityEnum = {
  ...createEnum("CesFringeContinuity", [
    [None, "None"],
    [Continuous, "Continuous"],
    [ExtendedPatches, "Extended patches"],
    [Fragmented, "Fragmented"],
  ]),
  None,
  Continuous,
  ExtendedPatches,
  Fragmented,
};

export default CesFringeContinuityEnum;
