import { findPricingPlan } from "@cartographerio/pricing";
import {
  BillingInterval,
  BillingUsage,
  Plan,
  PlanId,
  WorkspaceRef,
} from "@cartographerio/types";
import { HStack, Stack, Switch } from "@chakra-ui/react";
import { ReactElement, useMemo, useState } from "react";

import queries from "../../queries";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import ContactUsPlanColumn from "./ContactUsPlanColumn";
import PlanChooserColumn, { PlanClickFunc } from "./PlanChooserColumn";

export interface PlanChooserProps {
  workspaceRef: WorkspaceRef;
  usage: BillingUsage;
  defaultPlanId?: PlanId | null;
  defaultBillingInterval?: BillingInterval;
  planLink?: (plan: Plan, billingInterval: BillingInterval) => string;
  onPlanClick?: PlanClickFunc;
}

export default function PlanChooser(props: PlanChooserProps): ReactElement {
  const {
    workspaceRef,
    usage,
    defaultPlanId = null,
    defaultBillingInterval = "Yearly",
    planLink,
    onPlanClick,
  } = props;

  const apiParams = useApiParams();

  const { options: _options, showCallUs } = useSuspenseQueryData(
    queries.billing.plan.v1.options(apiParams, workspaceRef)
  );

  const [billingInterval, setBillingInterval] = useState(
    defaultBillingInterval
  );

  const options = useMemo(
    () =>
      _options.map(({ plan, selectable }) => ({
        plan,
        pricingPlan: findPricingPlan(plan.id),
        selectable,
      })),
    [_options]
  );

  const disabledReasons = useMemo(
    (): Record<PlanId, string> =>
      options.reduce((acc, { plan, selectable }) => {
        let reason: null | string = null;

        if (
          plan.id === defaultPlanId &&
          billingInterval === defaultBillingInterval
        ) {
          reason = "You are already on this plan";
        } else if (
          (plan.limits.maxProjects != null &&
            usage.numProjects > plan.limits.maxProjects) ||
          (plan.limits.maxUsers != null &&
            usage.numUsers > plan.limits.maxUsers)
        ) {
          reason = "You are already using more than this plan's limits";
        } else if (!selectable) {
          reason = "Your workspace does not meet this plan's requirements";
        }

        return reason != null ? { ...acc, [plan.id]: reason } : acc;
      }, {}),
    [
      billingInterval,
      defaultBillingInterval,
      defaultPlanId,
      options,
      usage.numProjects,
      usage.numUsers,
    ]
  );

  return (
    <Spaced>
      <HStack justify="center">
        <span>Billed Monthly</span>
        <Switch
          id="isChecked"
          isChecked={billingInterval === "Yearly"}
          onChange={() =>
            setBillingInterval(
              billingInterval === "Monthly" ? "Yearly" : "Monthly"
            )
          }
          colorScheme="green"
        />
        <span>Billed Yearly</span>
      </HStack>

      <Stack
        direction={["column", "row"]}
        alignItems={["center", "stretch"]}
        justify="flex-start"
        overflowX="auto"
        pt="4"
        pb="8"
        px="4"
        bg="gray.50"
        roundedBottom="lg"
        border="1px solid"
        borderColor="gray.100"
        columnGap="4"
        rowGap="8"
      >
        {options.map(({ plan, pricingPlan }) => (
          <PlanChooserColumn
            key={plan.id}
            plan={plan}
            pricingPlan={pricingPlan}
            billingInterval={billingInterval}
            disabled={plan.id in disabledReasons}
            disabledReason={disabledReasons[plan.id]}
            planLink={planLink?.(plan, billingInterval)}
            onPlanClick={onPlanClick}
          />
        ))}
        {showCallUs && (
          <ContactUsPlanColumn billingInterval={billingInterval} />
        )}
      </Stack>

      <Para fontSize="sm" color="gray.400" textAlign="center" pt="2" mb="6">
        Prices are pro-rata and discounts are not reflected in this preview. The
        final billed amount may differ.
      </Para>
    </Spaced>
  );
}
