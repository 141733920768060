import {
  attachmentAttr,
  attributeGroup,
  enumAttr,
  numberAttr,
  pointLayer,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  Thames21SlowFlowFeatureTypeEnum,
  Thames21SlowFlowPondTypeEnum,
  Thames21SlowFlowServiceTypeEnum,
  Thames21SlowFlowSuitabilityEnum,
  Thames21SlowFlowSurveyTypeEnum,
  Thames21SlowFlowVegetationTypeEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const Thames21SlowFlow = mapSchemaWithDefaults({
  mapId: unsafeMapId("Thames21SlowFlow"),
  title: "Slow the Flow",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("Thames21SlowFlow"),
      title: "Slow the Flow",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("Thames21SlowFlow"),
        attribution: [],
      }),
      defaultAttribute: "surveyType",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "General",
          attributes: [
            stringAttr({
              attributeId: "site",
              label: "Site",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "surveyType",
              label: "New/existing feature",
              enum: Thames21SlowFlowSurveyTypeEnum,
            }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("Thames21SlowFlow"),
            }),
            surveyAttr("surveyId", "Survey"),
            attachmentAttr("photographs", "Photographs"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "New NFM features",
          attributes: [
            enumAttr({
              attributeId: "newFeatureSuitability",
              label: "Suitability of location",
              enum: Thames21SlowFlowSuitabilityEnum,
            }),
            enumAttr({
              attributeId: "newFeatureVegetationType",
              label: "Type of woodland/hedgerow",
              enum: Thames21SlowFlowVegetationTypeEnum,
            }),
            enumAttr({
              attributeId: "newFeaturePondType",
              label: "Type of pond",
              enum: Thames21SlowFlowPondTypeEnum,
            }),
            enumAttr({
              attributeId: "newFeatureServiceType",
              label: "NFM service potential",
              enum: Thames21SlowFlowServiceTypeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Existing NFM features",
          attributes: [
            enumAttr({
              attributeId: "existingFeatureType",
              label: "Type of existing structure",
              enum: Thames21SlowFlowFeatureTypeEnum,
            }),
            enumAttr({
              attributeId: "existingFeatureServiceType",
              label: "NFM service provided",
              enum: Thames21SlowFlowServiceTypeEnum,
            }),
            numberAttr({
              attributeId: "existingFeatureStorageArea",
              label: "Storage area",
              unit: "m2",
              buckets: "auto",
            }),
          ],
        }),
      ],
    }),
  ],
});
