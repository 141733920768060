// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21BadgedGroupEventSetting =
  | "river"
  | "riverbank"
  | "foreshore"
  | "pondOrLake"
  | "canal"
  | "towpath"
  | "park"
  | "otherGreenSpace"
  | "other";

const River: Thames21BadgedGroupEventSetting = "river";
const Riverbank: Thames21BadgedGroupEventSetting = "riverbank";
const Foreshore: Thames21BadgedGroupEventSetting = "foreshore";
const PondOrLake: Thames21BadgedGroupEventSetting = "pondOrLake";
const Canal: Thames21BadgedGroupEventSetting = "canal";
const Towpath: Thames21BadgedGroupEventSetting = "towpath";
const Park: Thames21BadgedGroupEventSetting = "park";
const OtherGreenSpace: Thames21BadgedGroupEventSetting = "otherGreenSpace";
const Other: Thames21BadgedGroupEventSetting = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21BadgedGroupEventSettingEnum = {
  ...createEnum("Thames21BadgedGroupEventSetting", [
    [River, "River"],
    [Riverbank, "Riverbank"],
    [Foreshore, "Foreshore"],
    [PondOrLake, "Pond/Lake"],
    [Canal, "Canal"],
    [Towpath, "Towpath"],
    [Park, "Park"],
    [OtherGreenSpace, "Other Green Space"],
    [Other, "Other"],
  ]),
  River,
  Riverbank,
  Foreshore,
  PondOrLake,
  Canal,
  Towpath,
  Park,
  OtherGreenSpace,
  Other,
};

export default Thames21BadgedGroupEventSettingEnum;
