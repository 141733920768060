import { Button, ButtonProps } from "@chakra-ui/react";
import { ReactElement } from "react";
import { IoCloseSharp } from "react-icons/io5";

interface ClearButtonProps extends ButtonProps {
  onClick: () => void;
}

export default function ClearButton(props: ClearButtonProps): ReactElement {
  const { onClick, px = 0, ...rest } = props;

  return (
    <Button variant="ghost" onClick={onClick} px={px} {...rest}>
      <IoCloseSharp size="1.5rem" />
    </Button>
  );
}
