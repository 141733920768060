// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityBankVegetation =
  | "TreesOrShrubs"
  | "Grass"
  | "EmergentPlants"
  | "BareEarth"
  | "ArtificialSurface"
  | "Other";

const TreesOrShrubs: EsrtWaterQualityBankVegetation = "TreesOrShrubs";
const Grass: EsrtWaterQualityBankVegetation = "Grass";
const EmergentPlants: EsrtWaterQualityBankVegetation = "EmergentPlants";
const BareEarth: EsrtWaterQualityBankVegetation = "BareEarth";
const ArtificialSurface: EsrtWaterQualityBankVegetation = "ArtificialSurface";
const Other: EsrtWaterQualityBankVegetation = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityBankVegetationEnum = {
  ...createEnum("EsrtWaterQualityBankVegetation", [
    [TreesOrShrubs, "Trees/shrubs"],
    [Grass, "Grass"],
    [EmergentPlants, "Emergent plants (reeds/rushes)"],
    [BareEarth, "None (bare earth)"],
    [ArtificialSurface, "Artificial surface (e.g. concrete)"],
    [Other, "Other"],
  ]),
  TreesOrShrubs,
  Grass,
  EmergentPlants,
  BareEarth,
  ArtificialSurface,
  Other,
};

export default EsrtWaterQualityBankVegetationEnum;
