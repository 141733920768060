// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsPercentageHabitatFeature =
  | "CC"
  | "BL"
  | "RP"
  | "RI"
  | "RU"
  | "GL"
  | "PO"
  | "MD"
  | "PR";

const Cascade: UrsPercentageHabitatFeature = "CC";
const Boil: UrsPercentageHabitatFeature = "BL";
const Rapid: UrsPercentageHabitatFeature = "RP";
const Riffle: UrsPercentageHabitatFeature = "RI";
const Run: UrsPercentageHabitatFeature = "RU";
const Glide: UrsPercentageHabitatFeature = "GL";
const Pool: UrsPercentageHabitatFeature = "PO";
const MarginalDeadwater: UrsPercentageHabitatFeature = "MD";
const Ponded: UrsPercentageHabitatFeature = "PR";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsPercentageHabitatFeatureEnum = {
  ...createEnum("UrsPercentageHabitatFeature", [
    [Cascade, "Cascade"],
    [Boil, "Boil"],
    [Rapid, "Rapid"],
    [Riffle, "Riffle"],
    [Run, "Run"],
    [Glide, "Glide"],
    [Pool, "Pool"],
    [MarginalDeadwater, "Marginal deadwater"],
    [Ponded, "Ponded (behind obstruction, e.g. weir, water, surface)"],
  ]),
  Cascade,
  Boil,
  Rapid,
  Riffle,
  Run,
  Glide,
  Pool,
  MarginalDeadwater,
  Ponded,
};

export default UrsPercentageHabitatFeatureEnum;
