export type TaggedNumber<A extends string> = number & { _tag_: A };

export function unsafeIsTaggedNumber<A extends string>(
  value: unknown
): value is TaggedNumber<A> {
  return typeof value === "number";
}

export function untagNumber<A extends string>(value: TaggedNumber<A>): number {
  return value;
}

export function unsafeTagNumber<A extends string>(
  value: number
): TaggedNumber<A> {
  return value as TaggedNumber<A>;
}

/**
 * Workaround for defficiency in Bridges until we can fix it in the library itself.
 * Don't use this in hand-written Typescript code!
 *
 * Explanation:
 *
 * Bridges (the Scala library we use to generate Typescript code from Scala type definitions)
 * makes some assumptions about the shape of guard functions based on the shape of the types we're guarding.
 *
 * For a type `TaggedNumber<A>`, the library expects a function of the following type:
 *
 *     `const isTaggedNumberA = (isA: (a: any) => a is A) => (value: any) => value is TaggedNumber<A>`
 *
 * Because the `A` in `TaggedNumber<A>` is a phantom type, we don't actually need the `isA` parameter.
 * However, Bridges expects it to be there. We provide this hack that ignores `isA`.
 */
export function bridgesIsTaggedNumberHack<A extends string>(
  _isA: (a: unknown) => a is A
) {
  return function (value: unknown): value is TaggedNumber<A> {
    return typeof value === "number";
  };
}
