import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isUrlResponse,
  UrlResponse,
  WorkspaceRef,
} from "@cartographerio/types";

import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";

const basePath = "/billing/dashboard/v1";

export function redirect(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<UrlResponse> {
  const url = {
    path: `${basePath}/${workspaceRef}`,
  };
  return fetch
    .get({ apiParams, url })
    .flatMap(contentAs("UrlResponse", isUrlResponse));
}
