// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiInvasivePlant =
  | "none"
  | "himalayanBalsam"
  | "japaneseKnotweed"
  | "giantHogweed"
  | "floatingPennywort"
  | "curlyWaterweed"
  | "skunkCabbage"
  | "other";

const None: WrtWestcountryCsiInvasivePlant = "none";
const HimalayanBalsam: WrtWestcountryCsiInvasivePlant = "himalayanBalsam";
const JapaneseKnotweed: WrtWestcountryCsiInvasivePlant = "japaneseKnotweed";
const GiantHogweed: WrtWestcountryCsiInvasivePlant = "giantHogweed";
const FloatingPennywort: WrtWestcountryCsiInvasivePlant = "floatingPennywort";
const CurlyWaterweed: WrtWestcountryCsiInvasivePlant = "curlyWaterweed";
const SkunkCabbage: WrtWestcountryCsiInvasivePlant = "skunkCabbage";
const Other: WrtWestcountryCsiInvasivePlant = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiInvasivePlantEnum = {
  ...createEnum("WrtWestcountryCsiInvasivePlant", [
    [None, "None"],
    [HimalayanBalsam, "Himalayan Balsam"],
    [JapaneseKnotweed, "Japanese Knotweed"],
    [GiantHogweed, "Giant Hogweed"],
    [FloatingPennywort, "Floating Pennywort"],
    [CurlyWaterweed, "Curly Waterweed"],
    [SkunkCabbage, "Skunk Cabbage"],
    [Other, "Other"],
  ]),
  None,
  HimalayanBalsam,
  JapaneseKnotweed,
  GiantHogweed,
  FloatingPennywort,
  CurlyWaterweed,
  SkunkCabbage,
  Other,
};

export default WrtWestcountryCsiInvasivePlantEnum;
