import { IO } from "@cartographerio/io";
import {
  ApiConfig,
  ApiParams,
  InvitationCodeSignup,
  InvitationCodeSignupApprovalRequest,
  InvitationCodeSignupApprovalResponse,
  InvitationCodeSignupRequest,
  InvitationCodeUseRequest,
  SearchResults,
  SigninSuccess,
  SignupResponse,
  isInvitationCodeSignup,
  isInvitationCodeSignupApprovalResponse,
  isSearchResults,
  isSigninSuccess,
  isSignupResponse,
} from "@cartographerio/types";
import * as fetch from "../../../../fetch";
import { contentAs } from "../../../../response";
import { UrlParts } from "../../../../url";
import { PartialParams } from "../../../params";
import { InvitationCodeSignupSearchOptionsV3 } from "./common";

const basePath = "/invitation/code/signup/v3";

export function search(
  apiParams: ApiParams,
  options: PartialParams<InvitationCodeSignupSearchOptionsV3> = {}
): IO<SearchResults<InvitationCodeSignup>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(
      contentAs(
        "SearchResults<InvitationCodeSignup>",
        isSearchResults(isInvitationCodeSignup)
      )
    );
}

export function searchUrl(
  options: PartialParams<InvitationCodeSignupSearchOptionsV3> = {}
): UrlParts {
  return { path: basePath, query: { ...options } };
}

export function use(
  apiParams: ApiParams,
  request: InvitationCodeUseRequest
): IO<SigninSuccess> {
  return fetch
    .post({
      apiParams,
      url: { path: `${basePath}/use` },
      body: request,
    })
    .flatMap(contentAs("SigninSuccess", isSigninSuccess));
}

export function signup(
  apiConfig: ApiConfig,
  request: InvitationCodeSignupRequest
): IO<SignupResponse> {
  return fetch
    .post({
      apiParams: { apiConfig },
      url: { path: `${basePath}/signup` },
      body: request,
    })
    .flatMap(contentAs("SignupResponse", isSignupResponse));
}

export function approve(
  apiParams: ApiParams,
  body: InvitationCodeSignupApprovalRequest
): IO<InvitationCodeSignupApprovalResponse> {
  return fetch
    .post({
      apiParams,
      url: { path: `${basePath}/approve` },
      body,
    })
    .flatMap(
      contentAs(
        "InvitationCodeSignupApprovalResponse",
        isInvitationCodeSignupApprovalResponse
      )
    );
}

export function reject(
  apiParams: ApiParams,
  body: InvitationCodeSignupApprovalRequest
): IO<InvitationCodeSignupApprovalResponse> {
  return fetch
    .post({
      apiParams,
      url: { path: `${basePath}/reject` },
      body,
    })
    .flatMap(
      contentAs(
        "InvitationCodeSignupApprovalResponse",
        isInvitationCodeSignupApprovalResponse
      )
    );
}
