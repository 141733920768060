import { MarkdownHandlers } from "../core/handlers";
import { MarkdownHeading, MarkdownSummary } from "./type";

export function summaryHandlers(): MarkdownHandlers<MarkdownSummary> {
  const headings: MarkdownHeading[] = [];
  const linkHrefs: string[] = [];
  const imageUrls: string[] = [];

  let currentHeading: MarkdownHeading | undefined = undefined;

  return {
    text(str: string): void {
      if (currentHeading != null) {
        currentHeading = { ...currentHeading, text: currentHeading.text + str };
      }
    },
    softBreak(): void {
      // Do nothing
    },
    lineBreak(): void {
      // Do nothing
    },
    htmlInline(_html: string): void {
      // Do nothing
    },
    code(_text: string): void {
      // Do nothing
    },
    thematicBreak(): void {
      // Do nothing
    },
    codeBlock(_info: string | null, _code: string): void {
      // Do nothing
    },
    htmlBlock(_html: string): void {
      // Do nothing
    },
    customInline(_text: string): void {
      // Do nothing
    },
    customBlock(_text: string): void {
      // Do nothing
    },
    openEmph(): void {
      // Do nothing
    },
    closeEmph(): void {
      // Do nothing
    },
    openStrong(): void {
      // Do nothing
    },
    closeStrong(): void {
      // Do nothing
    },
    openLink(href: string): void {
      linkHrefs.push(href);
    },
    closeLink(): void {
      // Do nothing
    },
    openImage(src: string): void {
      imageUrls.push(src);
    },
    closeImage(): void {
      // Do nothing
    },
    openDocument(): void {
      // Do nothing
    },
    closeDocument(): void {
      // Do nothing
    },
    openParagraph(): void {
      // Do nothing
    },
    closeParagraph(): void {
      // Do nothing
    },
    openBlockQuote(): void {
      // Do nothing
    },
    closeBlockQuote(): void {
      // Do nothing
    },
    openItem(): void {
      // Do nothing
    },
    closeItem(): void {
      // Do nothing
    },
    openList(
      _type: "bullet" | "ordered",
      _delimiter: ")" | "." | null,
      _start: number | null,
      _tight: boolean
    ): void {
      // Do nothing
    },
    closeList(): void {
      // Do nothing
    },
    openHeading(level: number): void {
      currentHeading = { level, text: "" };
    },
    closeHeading(): void {
      if (currentHeading != null) {
        headings.push(currentHeading);
        currentHeading = undefined;
      }
    },
    result(): MarkdownSummary {
      return {
        headings,
        linkHrefs,
        imageUrls,
      };
    },
  };
}
