// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type RiverflySampleType = "First" | "Second" | "Combined";

const First: RiverflySampleType = "First";
const Second: RiverflySampleType = "Second";
const Combined: RiverflySampleType = "Combined";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RiverflySampleTypeEnum = {
  ...createEnum("RiverflySampleType", [
    [First, "First Sample"],
    [Second, "Second Sample"],
    [Combined, "Combined Samples (Legacy)"],
  ]),
  First,
  Second,
  Combined,
};

export default RiverflySampleTypeEnum;
