// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityRecreationalActivity =
  | "Fishing"
  | "Boating"
  | "PaddleboardsOrCanoes"
  | "Bathing"
  | "Walkers"
  | "Other";

const Fishing: EsrtWaterQualityRecreationalActivity = "Fishing";
const Boating: EsrtWaterQualityRecreationalActivity = "Boating";
const PaddleboardsOrCanoes: EsrtWaterQualityRecreationalActivity =
  "PaddleboardsOrCanoes";
const Bathing: EsrtWaterQualityRecreationalActivity = "Bathing";
const Walkers: EsrtWaterQualityRecreationalActivity = "Walkers";
const Other: EsrtWaterQualityRecreationalActivity = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityRecreationalActivityEnum = {
  ...createEnum("EsrtWaterQualityRecreationalActivity", [
    [Fishing, "Fishing"],
    [Boating, "Boating"],
    [PaddleboardsOrCanoes, "Paddleboards/canoes"],
    [Bathing, "Bathing"],
    [Walkers, "Walkers (inc. dog)"],
    [Other, "Other"],
  ]),
  Fishing,
  Boating,
  PaddleboardsOrCanoes,
  Bathing,
  Walkers,
  Other,
};

export default EsrtWaterQualityRecreationalActivityEnum;
