// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityWaterbodyType =
  | "River"
  | "Stream"
  | "Ditch"
  | "Stillwater"
  | "EstuaryOrTidal"
  | "Other";

const River: EsrtWaterQualityWaterbodyType = "River";
const Stream: EsrtWaterQualityWaterbodyType = "Stream";
const Ditch: EsrtWaterQualityWaterbodyType = "Ditch";
const Stillwater: EsrtWaterQualityWaterbodyType = "Stillwater";
const EstuaryOrTidal: EsrtWaterQualityWaterbodyType = "EstuaryOrTidal";
const Other: EsrtWaterQualityWaterbodyType = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityWaterbodyTypeEnum = {
  ...createEnum("EsrtWaterQualityWaterbodyType", [
    [River, "River"],
    [Stream, "Stream"],
    [Ditch, "Ditch"],
    [Stillwater, "Stillwater"],
    [EstuaryOrTidal, "Estuary/tidal"],
    [Other, "Other"],
  ]),
  River,
  Stream,
  Ditch,
  Stillwater,
  EstuaryOrTidal,
  Other,
};

export default EsrtWaterQualityWaterbodyTypeEnum;
