// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsChannelCoverage = "W" | "I" | "N";

const Wide: MrsChannelCoverage = "W";
const Intermediate: MrsChannelCoverage = "I";
const Narrow: MrsChannelCoverage = "N";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsChannelCoverageEnum = {
  ...createEnum("MrsChannelCoverage", [
    [Wide, "Wide"],
    [Intermediate, "Intermediate"],
    [Narrow, "Narrow"],
  ]),
  Wide,
  Intermediate,
  Narrow,
};

export default MrsChannelCoverageEnum;
