import {
  isUuid,
  unsafeProjectAlias,
  unsafeProjectId,
  ProjectAlias,
  ProjectId,
} from "../core";

export type ProjectRef = ProjectAlias | ProjectId;

export function unsafeProjectRef(str: string): ProjectRef {
  return isUuid(str) ? unsafeProjectId(str) : unsafeProjectAlias(str);
}
