import { Option, Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  checkedToResult,
  isChecked,
  isProject,
  isSearchResults,
  Message,
  Project,
  ProjectId,
  ProjectRef,
  SearchResults,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs, optionalContentAs } from "../../response";
import { UrlParts } from "../../url";
import { PartialParams } from "../params";
import { ProjectSearchOptions } from "./common";

const basePath = "/project/v2";

export function search(
  apiParams: ApiParams,
  options: PartialParams<ProjectSearchOptions> = { order: "name-asc" }
): IO<SearchResults<Project>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(contentAs("SearchResults<Project>", isSearchResults(isProject)));
}

export function searchUrl(
  options: PartialParams<ProjectSearchOptions> = { order: "name-asc" }
): UrlParts {
  return {
    path: basePath,
    query: { ...options },
  };
}

export function readOrFail(apiParams: ApiParams, ref: ProjectRef): IO<Project> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(contentAs("Project", isProject));
}

export function readOption(
  apiParams: ApiParams,
  ref: ProjectRef
): IO<Option<Project>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(optionalContentAs("Project", isProject));
}

export function save(
  apiParams: ApiParams,
  project: Project
): IO<Result<Message[], Project>> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${project.id}` },
      body: project,
    })
    .flatMap(contentAs("Checked<Project>", isChecked(isProject)))
    .map(checkedToResult);
}

export function remove(apiParams: ApiParams, id: ProjectId): IO<void> {
  return fetch.remove({ apiParams, url: { path: `${basePath}/${id}` } }).void();
}
