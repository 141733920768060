// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21BadgedGroupEventLargeRubbish =
  | "bicycles"
  | "motorbikes"
  | "shoppingTrolleys"
  | "trafficCones"
  | "signs"
  | "furniture"
  | "buildingMaterials"
  | "appliances"
  | "other";

const Bicycles: Thames21BadgedGroupEventLargeRubbish = "bicycles";
const Motorbikes: Thames21BadgedGroupEventLargeRubbish = "motorbikes";
const ShoppingTrolleys: Thames21BadgedGroupEventLargeRubbish =
  "shoppingTrolleys";
const TrafficCones: Thames21BadgedGroupEventLargeRubbish = "trafficCones";
const Signs: Thames21BadgedGroupEventLargeRubbish = "signs";
const Furniture: Thames21BadgedGroupEventLargeRubbish = "furniture";
const BuildingMaterials: Thames21BadgedGroupEventLargeRubbish =
  "buildingMaterials";
const Appliances: Thames21BadgedGroupEventLargeRubbish = "appliances";
const Other: Thames21BadgedGroupEventLargeRubbish = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21BadgedGroupEventLargeRubbishEnum = {
  ...createEnum("Thames21BadgedGroupEventLargeRubbish", [
    [Bicycles, "Bicycles"],
    [Motorbikes, "Motorbikes"],
    [ShoppingTrolleys, "Shopping Trolleys"],
    [TrafficCones, "Traffic Cones"],
    [Signs, "Signs"],
    [Furniture, "Furniture"],
    [BuildingMaterials, "Building Materials"],
    [Appliances, "Appliances"],
    [Other, "Other"],
  ]),
  Bicycles,
  Motorbikes,
  ShoppingTrolleys,
  TrafficCones,
  Signs,
  Furniture,
  BuildingMaterials,
  Appliances,
  Other,
};

export default Thames21BadgedGroupEventLargeRubbishEnum;
