import { isSchema } from "@cartographerio/topo-survey";
import { Result } from "@cartographerio/fp";
import { GuardError } from "@cartographerio/guard";
import {
  isSurveyModuleId,
  isSurveyNames,
  MapId,
  MapLayerId,
  unsafeMapLayerId,
} from "@cartographerio/types";
import { isObject } from "lodash";
import { MapLink, SurveyModule } from "./module";
import { SurveyModuleParts, SurveyModulePartsFromJson } from "./parts";

export function surveyModule(parts: SurveyModuleParts): SurveyModule {
  return new SurveyModule(parts);
}

export function surveyModuleFromJson(
  parts: SurveyModulePartsFromJson
): Result<GuardError, SurveyModule> {
  const {
    moduleJson,
    formSchema,
    dataDescription,
    dataGeometry,
    dataTimestamp,
    copyData,
    mapLinks,
  } = parts;

  return Result.guard(
    isObject,
    "object"
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  )(moduleJson).flatMap((json: any) => {
    const { moduleId, names, dataSchema } = json;

    return Result.tupled([
      Result.guard(isSurveyModuleId, "SurveyModuleId")(moduleId),
      Result.guard(isSurveyNames, "SurveyNames")(names),
      Result.guard(isSchema, "Schema")(dataSchema),
    ]).map(([moduleId, names, dataSchema]) =>
      surveyModule({
        moduleId,
        names,
        dataSchema,
        formSchema,
        dataDescription,
        dataGeometry,
        dataTimestamp,
        copyData,
        mapLinks,
      })
    );
  });
}

export interface MapLinkOpts {
  label: string;
  mapId: MapId;
  layerId?: MapLayerId;
  attributeId?: string;
}

export function mapLink(opts: MapLinkOpts): MapLink {
  const {
    label,
    mapId,
    layerId = unsafeMapLayerId(mapId),
    attributeId = "surveyId",
  } = opts;

  return {
    label,
    mapId,
    layerId,
    attributeId,
  };
}
