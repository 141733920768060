import { checks } from "@cartographerio/permission";
import { Box, Card, CardBody, CardProps } from "@chakra-ui/react";
import { ReactNode } from "react";

import { useAdminContentVisible } from "../hooks/useAdminContentVisible";
import RequirePermission from "./RequirePermission";
import Spaced from "./Spaced";

interface AdminContentProps extends Omit<CardProps, "appearance"> {
  appearance?: "default" | "compact";
  fallback?: ReactNode;
  isSuperuser?: boolean;
  children: ReactNode;
}

export const SUPERUSER_CONTENT_BACKGROUND = "red.100";
export const ADMIN_CONTENT_BACKGROUND = "orange.100";

export default function AdminContent(props: AdminContentProps): ReactNode {
  const {
    appearance = "default",
    fallback,
    children,
    isSuperuser = false,
    ...rest
  } = props;

  const [enabled] = useAdminContentVisible();

  return (
    enabled && (
      <RequirePermission
        check={isSuperuser ? checks.auth.superuser : checks.auth.globalAdmin}
        fallback={fallback}
      >
        <Card
          bg={
            isSuperuser
              ? SUPERUSER_CONTENT_BACKGROUND
              : ADMIN_CONTENT_BACKGROUND
          }
          position="relative"
          {...rest}
        >
          <CardBody>
            <Spaced spacing="1">
              {children}
              {appearance === "default" && (
                <Box
                  fontSize="sm"
                  color={isSuperuser ? "red.500" : "orange.500"}
                  fontStyle="italic"
                  textAlign="right"
                >
                  {isSuperuser ? "Superuser Settings" : "Admin Settings"}
                </Box>
              )}
            </Spaced>
          </CardBody>
        </Card>
      </RequirePermission>
    )
  );
}
