// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21VegetationSite = "potential" | "existing";

const Potential: Thames21VegetationSite = "potential";
const Existing: Thames21VegetationSite = "existing";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21VegetationSiteEnum = {
  ...createEnum("Thames21VegetationSite", [
    [Potential, "Potential"],
    [Existing, "Existing"],
  ]),
  Potential,
  Existing,
};

export default Thames21VegetationSiteEnum;
