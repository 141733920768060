import { Point } from "@cartographerio/geometry";
import {
  AttachmentFolder,
  randomAttachmentFolder,
  SurveyF,
  TestSurveyProps,
  testSurvey,
  Timestamp,
  unsafeSurveyModuleId,
} from "@cartographerio/types";

export interface TestSurvey3KeyPoint {
  text?: string | null;
  number?: number | null;
}

export interface TestSurvey3Data {
  location?: Point | null;
  timestamp?: Timestamp | null;
  keyPoints: TestSurvey3KeyPoint[];
  photographs: AttachmentFolder;
}

export type TestSurvey3 = SurveyF<TestSurvey3Data>;

export function testSurvey3(
  props: TestSurveyProps<TestSurvey3Data>
): TestSurvey3 {
  return testSurvey({
    ...props,
    moduleId: unsafeSurveyModuleId("TestSurvey3"),
    data: props.data ?? {
      location: null,
      timestamp: null,
      keyPoints: [],
      photographs: randomAttachmentFolder(),
    },
  });
}
