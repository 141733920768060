import { Field } from "../type";
import { checkExhausted } from "@cartographerio/util";

export function fieldImage(field: Field, value: unknown): string | undefined {
  switch (field.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
    case "IntegerField":
    case "NumberField":
      return field.image;

    case "Select":
    case "MultiSelect":
      return (
        field.options.find(opt => opt.value === value)?.image ?? field.image
      );

    case "AttachmentField":
    case "Checkbox":
    case "PointField":
    case "LineStringField":
    case "PolygonField":
    case "TeamField":
    case "FeatureField":
    case "NearestFeatureField":
    case "TimestampField":
    case "UserRefField":
      return undefined;

    default:
      return checkExhausted(field);
  }
}
