import { checks } from "@cartographerio/permission";
import { AdminNote, WorkspaceRef } from "@cartographerio/types";
import {
  Box,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { MdCheck, MdClose, MdDelete, MdEdit } from "react-icons/md";

import queries from "../../queries";
import { blankAdminNote } from "../../queries/admin/notes/v1";
import { useApiParams } from "../contexts/auth";
import { usePermissionCheckPasses } from "../hooks/usePermissionCheckPasses";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import { useVolatileState } from "../hooks/useVolatileState";
import Button from "./Button";
import Markdown from "./Markdown";
import TextArea from "./TextArea";

interface AdminNoteEditorProps {
  workspaceRef: WorkspaceRef;
}

const blank = blankAdminNote();

export default function AdminNoteEditor(
  props: AdminNoteEditorProps
): ReactElement {
  const { workspaceRef } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();

  const defaultNote =
    useSuspenseQueryData(
      queries.when(usePermissionCheckPasses(checks.admin.canReadNotes()), () =>
        queries.admin.notes.v1.readOrNull(apiParams, workspaceRef)
      )
    ) ?? blank;

  const [note, setNote] = useVolatileState(
    useCallback(() => defaultNote, [defaultNote])
  );

  const saveNote = useCallback(
    (note: AdminNote) => {
      console.log("saveNote", note);
      note.text.trim().length > 0
        ? queries.admin.notes.v1
            .save(queryClient, apiParams, workspaceRef, note)
            .unsafeRun()
        : queries.admin.notes.v1
            .remove(queryClient, apiParams, workspaceRef)
            .unsafeRun();
    },
    [apiParams, queryClient, workspaceRef]
  );

  const deleteNote = useCallback(() => {
    console.log("deleteNote");
    queries.admin.notes.v1
      .remove(queryClient, apiParams, workspaceRef)
      .unsafeRun();
  }, [apiParams, queryClient, workspaceRef]);

  const [editing, setEditing] = useState(false);

  const {
    isOpen: isHovering,
    onOpen: onStartHovering,
    onClose: onStopHovering,
  } = useDisclosure();

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const handleSave = useCallback<MouseEventHandler<HTMLButtonElement>>(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      setEditing(false);
      const text = note.text.trim();
      if (text.length === 0) {
        deleteNote?.();
      } else {
        saveNote?.({ ...note, text });
      }
    },
    [note, deleteNote, saveNote]
  );

  const handleDeleteOpen = useCallback<MouseEventHandler<HTMLElement>>(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      onConfirmOpen();
    },
    [onConfirmOpen]
  );

  const handleDeleteCancel = useCallback(
    (evt?: MouseEvent<HTMLElement>) => {
      evt?.preventDefault();
      evt?.stopPropagation();
      onConfirmClose();
    },
    [onConfirmClose]
  );

  const handleDeleteConfirm = useCallback<MouseEventHandler<HTMLButtonElement>>(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      onConfirmClose();
      deleteNote?.();
    },
    [onConfirmClose, deleteNote]
  );

  const handleCancelChanges = useCallback<MouseEventHandler<HTMLButtonElement>>(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      setNote(defaultNote);
      setEditing(false);
    },
    [defaultNote, setNote]
  );

  const onStartEditing = useCallback<MouseEventHandler<HTMLElement>>(evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setEditing(true);
  }, []);

  return (
    <Box
      position="relative"
      onMouseEnter={onStartHovering}
      onMouseLeave={onStopHovering}
      rounded="md"
    >
      <Flex align="stretch" minH="14">
        {editing ? (
          <Box position="relative" flexGrow="1">
            <TextArea.String
              size="xs"
              value={note.text}
              bg="whiteAlpha.700"
              onChange={text => setNote({ ...note, text })}
              autoSize={true}
            />
            <ButtonGroup
              size="xs"
              position="absolute"
              top="1"
              right="1"
              zIndex="popover"
            >
              <IconButton
                variant="outline"
                bg="whiteAlpha.600"
                lineHeight="0"
                aria-label="Save changes"
                icon={<Icon as={MdCheck} width="1.25em" height="1.25em" />}
                onClick={handleSave}
              />
              <IconButton
                variant="outline"
                bg="whiteAlpha.600"
                lineHeight="0"
                aria-label="Cancel changes"
                icon={<Icon as={MdClose} width="1.25em" height="1.25em" />}
                onClick={handleCancelChanges}
              />
            </ButtonGroup>
          </Box>
        ) : (
          <Box position="relative" flexGrow="1" onClick={onStartEditing}>
            <Markdown fontSize="sm" text={note.text} />
            {isHovering && (
              <ButtonGroup
                size="xs"
                position="absolute"
                top="1"
                right="1"
                zIndex="popover"
              >
                <IconButton
                  variant="unstyled"
                  bg="whiteAlpha.600"
                  lineHeight="0"
                  aria-label="Edit note"
                  icon={<Icon as={MdEdit} width="1.25em" height="1.25em" />}
                  onClick={onStartEditing}
                />
                <IconButton
                  variant="unstyled"
                  bg="whiteAlpha.600"
                  lineHeight="0"
                  aria-label="Delete note"
                  icon={<Icon as={MdDelete} width="1.25em" height="1.25em" />}
                  onClick={handleDeleteOpen}
                />
              </ButtonGroup>
            )}
          </Box>
        )}
      </Flex>

      <Modal isOpen={isConfirmOpen} onClose={handleDeleteCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody overflowWrap="break-word">
            Are you sure you want to delete this admin note?
          </ModalBody>
          <ModalFooter as={ButtonGroup}>
            <Button label="Cancel" onClick={handleDeleteCancel} />
            <Button
              label="Delete"
              colorScheme="red"
              onClick={handleDeleteConfirm}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
