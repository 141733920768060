import { createMessage } from "@cartographerio/topo-core";
import { checks, permissionCheckPasses } from "@cartographerio/permission";
import { roleGte, teamMemberRole } from "@cartographerio/types";
import { getSurveyorUser } from "../helpers";
import { RuleBuilder } from "./types";

/**
 * Checks whether the primary surveyor is a surveyor in the selected team
 */
export const surveyorTeamRule: RuleBuilder = props => {
  const { identity, graph, project } = props;
  return survey => {
    const { surveyor, teamId } = survey;

    const surveyorUser = getSurveyorUser(graph, surveyor);

    if (surveyorUser == null) {
      return [];
    }

    if (teamId == null) {
      return [];
    }

    const passes = surveyorUser.roles.some(role =>
      roleGte(role, teamMemberRole(teamId))
    );

    if (passes) {
      return [];
    }

    // We want this to be an error message if you're an approver for a team,
    // and a info message if you're an approver for the whole project.
    // We need to take qualifications and project permission models into account.
    // So this is equivalent to being able to save a survey without a teamId.
    const level = permissionCheckPasses(
      checks.survey.approveWithTeam(project, teamId),
      {
        id: identity.userId,
        roles: identity.roles,
        qualificationRoles: identity.qualificationRoles,
      },
      graph
    )
      ? "info"
      : "error";

    return [
      createMessage(level, "The selected user is not a surveyor in this team"),
    ];
  };
};
