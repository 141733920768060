import {
  attachmentField,
  checkbox,
  columns2,
  form,
  grid,
  page,
  requiredIff,
  section,
  select,
  timestampField,
} from "@cartographerio/topo-form";
import {
  NeRiverTypesConfidenceEnum,
  NeRiverTypesFeatureEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
import { neRiverSiteInfoSection } from "../NeRiverNaturalness/form";

const riverTypesGuidanceUrl =
  "https://priorityhabitats.org/river-types-form-guidance";

export default form({
  title: "Mapping River/Stream Types",
  surveyorLabel: "Contributor",
  pages: [
    page({
      title: null,
      path: [],
      help: outdent`
      This form allows you to assist with
      refining our understanding of the distribution of
      key river/stream types (note there is a separate form for chalk streams/rivers).
      Zoom into a location you know well and look at the types predicted to occur.
      Indicate which types you think fit the natural character of that section of watercourse.
      For more information about different river/stream types and their predicted distribution
      see [this guidance](${riverTypesGuidanceUrl}).
      `,
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                }),
              ],
            }),
          ],
        }),
        neRiverSiteInfoSection({
          styleUrl: "mapbox://styles/cartographerio/cld080wg0000114p9mk2tnden",
          extraHelp: outdent`
          Predicted distributions of river/stream types are shown
          on a 50-metre grid of circular icons overlaid on the river/stream network.
          Types predicted to occur are indicated using coloured quadrants within each icon:

          - Top-left - Habitats Directive H3260 (orange)
          - Top-right - Active shingle rivers (yellow)
          - Bottom-left - Headwaters (light pink), tidal stretches (dark pink)
          - Bottom-right - C2.2a High Energy (light blue), C2.2b Moderare Energy (medium blue), C2.3 Low Energy (dark blue)
          `,
          showCustomAttributeFields: false,
          showChalkAttribute: false,
        }),
        section({
          title: "Types Matching Local Natural Character",
          path: [],
          help: outdent`
          Select the type(s) you think reflect the natural character of the reach.
          See [this guidance](${riverTypesGuidanceUrl}) for an explanation of the different types.
          `,
          blocks: columns2(
            ...NeRiverTypesFeatureEnum.entries.flatMap(({ value, label }) => [
              checkbox({
                label,
                checkboxLabel:
                  "This type reflects the natural character of the reach",
                path: ["data", "features", value, "selected"],
              }),
              select({
                label: `${label} Confidence`,
                path: ["data", "features", value, "confidence"],
                options: NeRiverTypesConfidenceEnum.entries,
                customRules: requiredIff({
                  level: "info",
                  requiredMessage: "Record your confidence level",
                  forbiddenMessage: `Only select a confidence level if you checked "${label}"`,
                  otherPath: ["data", "features", value, "selected"],
                  otherTest: value => value === true,
                }),
              }),
            ]),
            select({
              label: "Dominant Type",
              path: ["data", "dominant"],
              options: NeRiverTypesFeatureEnum.entries,
              help: "Select the type that most strongly reflects the natural character of the reach.",
            })
          ),
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
              help: "Photographs are not essential but will help us understand the physical character of the section.",
            }),
          ],
        }),
      ],
    }),
  ],
});
