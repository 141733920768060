import { Path } from "@cartographerio/topo-core";
import { checkbox, integerField, select } from "./create";
import { bounds, BoundsRule, minValue, RequiredRule, totals } from "./rule";
import {
  Checkbox,
  IntegerField,
  Select,
  SelectOption,
  SelectValue,
} from "./type";

// Functions for assembling lists of fields related to a dictionary value.
// For example, if you have a value of type { [name: string]: boolean },
// you can use checkboxGroup to generate a list of fields for each key value pair.

export interface CheckboxGroupFieldProps {
  value: string; // field name
  label: string;
  checkboxLabel?: string;
  fullWidth?: boolean;
}

export interface SelectGroupFieldProps {
  value: string; // field name
  label: string;
  secondaryLabel?: string;
  image?: string;
  help?: string;
}

export interface CountsGroupFieldProps {
  value: string; // field name
  label: string;
  secondaryLabel?: string;
  image?: string;
  help?: string;
}

export type GroupFieldProps =
  | CheckboxGroupFieldProps
  | SelectGroupFieldProps
  | CountsGroupFieldProps;

export interface SelectGroupProps<A extends SelectValue = SelectValue> {
  path: Path;
  fields: Array<SelectGroupFieldProps>;
  options: SelectOption<A>[];
  showValues?: boolean;
  defaultValue?: A;
  required?: RequiredRule;
}

export interface CheckboxGroupProps {
  path: Path;
  fields: Array<CheckboxGroupFieldProps>;
  defaultValue?: boolean;
}

export interface CountsGroupProps {
  path: Path;
  units?: string;
  singleBounds?: BoundsRule;
  totalBounds?: BoundsRule;
  fields: Array<CountsGroupFieldProps>;
  defaultValue?: number;
}

export function checkboxGroup(props: CheckboxGroupProps): Checkbox[] {
  const { fields, path, defaultValue } = props;
  return fields.map(({ value, label, checkboxLabel }) =>
    checkbox({
      label,
      checkboxLabel,
      path: [...path, value],
      defaultValue: defaultValue ?? false,
    })
  );
}

export function selectGroup<A extends SelectValue>(
  props: SelectGroupProps<A>
): Select[] {
  const { path, fields, options, showValues, defaultValue, required } = props;

  return fields.map(({ value, label, secondaryLabel, image, help }) =>
    select<A>({
      label,
      secondaryLabel,
      path: [...path, value],
      image,
      help,
      options,
      showValues,
      defaultValue,
      required,
    })
  );
}

export function countsGroup(props: CountsGroupProps): IntegerField[] {
  const { fields, path, units, defaultValue, singleBounds, totalBounds } =
    props;

  return fields.map(({ value, label, secondaryLabel, image, help }) =>
    integerField({
      label,
      secondaryLabel,
      path: [...path, value],
      units: units,
      bounds: singleBounds,
      defaultValue: defaultValue ?? 0,
      image,
      help,
      customRules:
        totalBounds == null
          ? []
          : [
              totals(
                path,
                totalBounds.minValue,
                totalBounds.maxValue,
                totalBounds.level
              ),
            ],
    })
  );
}

export function positiveCountsGroup(props: CountsGroupProps): IntegerField[] {
  return countsGroup({
    ...props,
    singleBounds: props.singleBounds ?? minValue(0),
    defaultValue: 0,
  });
}

export function percentageDistributionGroup(
  props: CountsGroupProps
): IntegerField[] {
  return countsGroup({
    ...props,
    units: props.units ?? "%",
    singleBounds: props.singleBounds ?? bounds(0, 100),
    totalBounds: props.totalBounds ?? bounds(100, 100, "info"),
    defaultValue: props.defaultValue,
  });
}
