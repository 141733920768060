// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankSoilType =
  | "Sandy"
  | "Clay"
  | "Loamy"
  | "Peaty"
  | "Garden"
  | "Unknown"
  | "Other";

const Sandy: NwcSeedbankSoilType = "Sandy";
const Clay: NwcSeedbankSoilType = "Clay";
const Loamy: NwcSeedbankSoilType = "Loamy";
const Peaty: NwcSeedbankSoilType = "Peaty";
const Garden: NwcSeedbankSoilType = "Garden";
const Unknown: NwcSeedbankSoilType = "Unknown";
const Other: NwcSeedbankSoilType = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankSoilTypeEnum = {
  ...createEnum("NwcSeedbankSoilType", [
    [Sandy, "Sandy (Sand Dune / Sandy)"],
    [Clay, "Clay"],
    [Loamy, "Loamy (Balanced)"],
    [Peaty, "Peaty"],
    [Garden, "Garden"],
    [Unknown, "Unknown"],
    [Other, "Other"],
  ]),
  Sandy,
  Clay,
  Loamy,
  Peaty,
  Garden,
  Unknown,
  Other,
};

export default NwcSeedbankSoilTypeEnum;
