import {
  Attribute,
  AttributeGroup,
  attributeGroup,
  booleanAttr,
  Buckets,
  lookupBucket,
  MapSource,
  marker,
  standardBuckets,
  stringAttr,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  NeLakeRestorationPrioritiesBiologicalMeasureEnum,
  NeLakeRestorationPrioritiesChemicalMeasureEnum,
  NeLakeRestorationPrioritiesHydrologicalMeasureEnum,
  NeLakeRestorationPrioritiesPhysicalMeasureEnum,
  NeRiverRestorationPrioritiesBiologicalMeasureEnum,
  NeRiverRestorationPrioritiesChemicalMeasureEnum,
  NeRiverRestorationPrioritiesHydrologicalMeasureEnum,
  NeRiverRestorationPrioritiesPhysicalMeasureEnum,
} from "@cartographerio/inventory-enums";
import { cartographerSourceWithDefaults } from "../../core";

export const lakesSource: MapSource = cartographerSourceWithDefaults({
  layerId: unsafeMapLayerId("neLakeRestorationPriorities"),
  simplifyZoom: 10,
  attribution: [],
});

export const riversSource: MapSource = cartographerSourceWithDefaults({
  layerId: unsafeMapLayerId("neRiverRestorationPriorities"),
  simplifyZoom: 10,
  attribution: [],
});

export const riverAreasSource: MapSource = cartographerSourceWithDefaults({
  layerId: unsafeMapLayerId("neRiverRestorationPriorityAreas"),
  simplifyZoom: 6,
  attribution: [],
});

export const overallColor = "yellow";
export const physicalColor = "orange";
export const hydrologicalColor = "cyan";
export const chemicalColor = "magenta";
export const biologicalColor = "lime";

export function presenceBuckets(baseColor: string): Buckets<boolean | null> {
  return standardBuckets([
    lookupBucket(true, marker.fromColor(baseColor), "Yes"),
    lookupBucket(false, marker.fromColor("#eee"), "No"),
    lookupBucket(null, marker.empty, "Unknown"),
  ]);
}

export const overallPriorityAttribute = booleanAttr({
  attributeId: "allComponents",
  label: "Restoration Priority (Any Component)",
  buckets: presenceBuckets(overallColor),
  showDistribution: false,
});

export const physicalPriorityAttribute = booleanAttr({
  attributeId: "physical",
  label: "Physical Restoration Priority (Any Measure)",
  buckets: presenceBuckets(physicalColor),
  showDistribution: false,
});

export const hydrologicalPriorityAttribute = booleanAttr({
  attributeId: "hydrological",
  label: "Hydrological Restoration Priority (Any Measure)",
  buckets: presenceBuckets(hydrologicalColor),
  showDistribution: false,
});

export const chemicalPriorityAttribute = booleanAttr({
  attributeId: "chemical",
  label: "Chemical Restoration Priority (Any Measure)",
  buckets: presenceBuckets(chemicalColor),
  showDistribution: false,
});

export const biologicalPriorityAttribute = booleanAttr({
  attributeId: "biological",
  label: "Biological Restoration Priority (Any Measure)",
  buckets: presenceBuckets(biologicalColor),
  showDistribution: false,
});

export function riverRestorationPrioritiesAttributeGroups(
  locationAttribute: Attribute
): AttributeGroup[] {
  return [
    attributeGroup({
      label: "General",
      attributes: [locationAttribute, overallPriorityAttribute],
    }),
    attributeGroup({
      label: "Physical",
      attributes: [
        physicalPriorityAttribute,
        ...NeRiverRestorationPrioritiesPhysicalMeasureEnum.entries.map(entry =>
          booleanAttr({
            attributeId: `physicalMeasure${entry.value}`,
            label: entry.label,
            buckets: presenceBuckets(physicalColor),
            showDistribution: false,
          })
        ),
      ],
    }),
    attributeGroup({
      label: "Hydrological",
      attributes: [
        hydrologicalPriorityAttribute,
        ...NeRiverRestorationPrioritiesHydrologicalMeasureEnum.entries.map(
          entry =>
            booleanAttr({
              attributeId: `hydrologicalMeasure${entry.value}`,
              label: entry.label,
              buckets: presenceBuckets(hydrologicalColor),
              showDistribution: false,
            })
        ),
      ],
    }),
    attributeGroup({
      label: "Chemical",
      attributes: [
        chemicalPriorityAttribute,
        ...NeRiverRestorationPrioritiesChemicalMeasureEnum.entries.map(entry =>
          booleanAttr({
            attributeId: `chemicalMeasure${entry.value}`,
            label: entry.label,
            buckets: presenceBuckets(chemicalColor),
            showDistribution: false,
          })
        ),
      ],
    }),
    attributeGroup({
      label: "Biological",
      attributes: [
        biologicalPriorityAttribute,
        ...NeRiverRestorationPrioritiesBiologicalMeasureEnum.entries.map(
          entry =>
            booleanAttr({
              attributeId: `biologicalMeasure${entry.value}`,
              label: entry.label,
              buckets: presenceBuckets(biologicalColor),
              showDistribution: false,
            })
        ),
      ],
    }),
  ];
}

export const lakeRestorationPrioritiesAttributeGroups = [
  attributeGroup({
    label: "General",
    attributes: [
      stringAttr({
        attributeId: "lakeName",
        label: "Lake Name",
        buckets: "auto",
        showDistribution: false,
      }),
      overallPriorityAttribute,
    ],
  }),
  attributeGroup({
    label: "Physical",
    attributes: [
      physicalPriorityAttribute,
      ...NeLakeRestorationPrioritiesPhysicalMeasureEnum.entries.map(entry =>
        booleanAttr({
          attributeId: `physicalMeasure${entry.value}`,
          label: entry.label,
          buckets: presenceBuckets(physicalColor),
          showDistribution: false,
        })
      ),
    ],
  }),
  attributeGroup({
    label: "Hydrological",
    attributes: [
      hydrologicalPriorityAttribute,
      ...NeLakeRestorationPrioritiesHydrologicalMeasureEnum.entries.map(entry =>
        booleanAttr({
          attributeId: `hydrologicalMeasure${entry.value}`,
          label: entry.label,
          buckets: presenceBuckets(hydrologicalColor),
          showDistribution: false,
        })
      ),
    ],
  }),
  attributeGroup({
    label: "Chemical",
    attributes: [
      chemicalPriorityAttribute,
      ...NeLakeRestorationPrioritiesChemicalMeasureEnum.entries.map(entry =>
        booleanAttr({
          attributeId: `chemicalMeasure${entry.value}`,
          label: entry.label,
          buckets: presenceBuckets(chemicalColor),
          showDistribution: false,
        })
      ),
    ],
  }),
  attributeGroup({
    label: "Biological",
    attributes: [
      biologicalPriorityAttribute,
      ...NeLakeRestorationPrioritiesBiologicalMeasureEnum.entries.map(entry =>
        booleanAttr({
          attributeId: `biologicalMeasure${entry.value}`,
          label: entry.label,
          buckets: presenceBuckets(biologicalColor),
          showDistribution: false,
        })
      ),
    ],
  }),
];
