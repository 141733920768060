// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityNitrate =
  | "N0"
  | "N10"
  | "N30"
  | "N60"
  | "N120"
  | "N200";

const N0: EsrtWaterQualityNitrate = "N0";
const N10: EsrtWaterQualityNitrate = "N10";
const N30: EsrtWaterQualityNitrate = "N30";
const N60: EsrtWaterQualityNitrate = "N60";
const N120: EsrtWaterQualityNitrate = "N120";
const N200: EsrtWaterQualityNitrate = "N200";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityNitrateEnum = {
  ...createEnum("EsrtWaterQualityNitrate", [
    [N0, "0"],
    [N10, "10"],
    [N30, "30"],
    [N60, "60"],
    [N120, "120"],
    [N200, "200"],
  ]),
  N0,
  N10,
  N30,
  N60,
  N120,
  N200,
};

export default EsrtWaterQualityNitrateEnum;
