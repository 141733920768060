import { raise } from "@cartographerio/util";

export interface IOEnvironment<State, Action> {
  dispatch: (action: Action) => void;
  getState: () => State;
}

export function defaultEnvironment<S, A>(): IOEnvironment<S, A> {
  return {
    dispatch: (_action: A) =>
      raise(new Error("No dispatch function supplied to unsafeRunAsPromise")),
    getState: () =>
      raise(new Error("No getState function supplied to unsafeRunAsPromise")),
  };
}
