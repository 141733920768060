// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { BillingInterval } from "../types.generated";

const Monthly: BillingInterval = "Monthly";
const Yearly: BillingInterval = "Yearly";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BillingIntervalEnum = {
  ...createEnum("BillingInterval", [
    [Monthly, "Monthly"],
    [Yearly, "Yearly"],
  ]),
  Monthly,
  Yearly,
};

export default BillingIntervalEnum;
