// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRtaConditionClass =
  | "Good"
  | "FairlyGood"
  | "Moderate"
  | "FairlyPoor"
  | "Poor";

const Good: MrsRtaConditionClass = "Good";
const FairlyGood: MrsRtaConditionClass = "FairlyGood";
const Moderate: MrsRtaConditionClass = "Moderate";
const FairlyPoor: MrsRtaConditionClass = "FairlyPoor";
const Poor: MrsRtaConditionClass = "Poor";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRtaConditionClassEnum = {
  ...createEnum("MrsRtaConditionClass", [
    [Good, "Good"],
    [FairlyGood, "Fairly Good"],
    [Moderate, "Moderate"],
    [FairlyPoor, "Fairly Poor"],
    [Poor, "Poor"],
  ]),
  Good,
  FairlyGood,
  Moderate,
  FairlyPoor,
  Poor,
};

export default MrsRtaConditionClassEnum;
