import { Option, Result } from "@cartographerio/fp";
import { hasKey, isObject, isString } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  ArcgisAuthCode,
  ArcgisIntegration,
  ArcgisIntegrationCreate,
  ArcgisIntegrationId,
  ArcgisIntegrationStatus,
  ArcgisIntegrationUpdate,
  Message,
  ProjectRef,
  SearchResults,
  WorkspaceRef,
  checkedToResult,
  isArcgisIntegration,
  isArcgisIntegrationStatus,
  isChecked,
  isSearchResults,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";
import { PartialParams } from "../../params";
import { ArcgisIntegrationSearchOptionsV1 } from "./common";

const basePath = "/integration/arcgis/v1";

export function search(
  apiParams: ApiParams,
  options: PartialParams<ArcgisIntegrationSearchOptionsV1> = {}
): IO<SearchResults<ArcgisIntegrationStatus>> {
  return fetch
    .get({
      apiParams,
      url: searchUrl(options),
    })
    .flatMap(
      contentAs(
        "SearchResults<ArcgisIntegrationStatus>",
        isSearchResults(isArcgisIntegrationStatus)
      )
    );
}

export function searchUrl(
  options: PartialParams<ArcgisIntegrationSearchOptionsV1> = {}
): UrlParts {
  return { path: basePath, query: options };
}

export function create(
  apiParams: ApiParams,
  create: ArcgisIntegrationCreate
): IO<Result<Message[], ArcgisIntegration>> {
  return fetch
    .post({ apiParams, url: { path: basePath }, body: create })
    .flatMap(
      contentAs("Checked<ArcgisIntegration>", isChecked(isArcgisIntegration))
    )
    .map(checkedToResult);
}

export function readOrFail(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId
): IO<ArcgisIntegration> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${integration}` } })
    .flatMap(contentAs("ArcgisIntegration", isArcgisIntegration));
}

export function readOption(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId
): IO<Option<ArcgisIntegration>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${integration}` } })
    .flatMap(optionalContentAs("ArcgisIntegration", isArcgisIntegration));
}

export function readStatusOrFail(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId
): IO<ArcgisIntegrationStatus> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${integration}/status` } })
    .flatMap(contentAs("ArcgisIntegrationStatus", isArcgisIntegrationStatus));
}

export function readStatusOption(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId
): IO<Option<ArcgisIntegrationStatus>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${integration}/status` } })
    .flatMap(
      optionalContentAs("ArcgisIntegrationStatus", isArcgisIntegrationStatus)
    );
}

export function update(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId,
  update: ArcgisIntegrationUpdate
): IO<Result<Message[], ArcgisIntegration>> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${integration}` },
      body: update,
    })
    .flatMap(
      contentAs("Checked<ArcgisIntegration>", isChecked(isArcgisIntegration))
    )
    .map(checkedToResult);
}

export function loginUrl(
  apiParams: ApiParams,
  redirectUri?: string
): IO<string> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/login-url`, query: { redirectUri } },
    })
    .flatMap(contentAs("LoginUrlResponse", isLoginUrlResponse))
    .map((response: LoginUrlResponse) => response.loginUrl);
}

export function authorize(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId,
  code: ArcgisAuthCode,
  redirectUri: string
): IO<void> {
  return fetch
    .get({
      apiParams,
      url: {
        path: `${basePath}/${integration}/authorize`,
        query: { code, redirectUri },
      },
    })
    .void();
}

export function exportOne(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId
): IO<void> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/${integration}/export` },
    })
    .void();
}

export function exportAll(
  apiParams: ApiParams,
  project?: ProjectRef,
  workspace?: WorkspaceRef
): IO<void> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/export-all`, query: { project, workspace } },
    })
    .void();
}

interface LoginUrlResponse {
  loginUrl: string;
}

function isLoginUrlResponse(response: unknown): response is LoginUrlResponse {
  return isObject(response) && hasKey(response, "loginUrl", isString);
}
