import {
  attachmentField,
  bounds,
  columns2,
  form,
  minValue,
  numberField,
  page,
  pointField,
  required,
  section,
  select,
  selectOption,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { Thames21RiverEnum } from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

export default form({
  title: "Water Quality",
  surveyorHelp:
    "If the survey has been completed by multiple surveyors, please enter additional names in the *Comments* field.",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: columns2(
            vstack(
              select({
                label: "River",
                path: ["data", "river"],
                options: Thames21RiverEnum.entries,
                placeholder: "Type to select a river...",
                appearance: "searchable",
                help: "The surveyed river.",
              }),
              textField({
                label: "Collected On Behalf Of",
                path: ["data", "collectedOnBehalfOf"],
                help: "If the survey was collected on behalf of an organization, enter its name here.",
              }),
              timestampField({
                label: "Date and Time",
                path: ["data", "recorded"],
                defaultValue: "now",
                required: required("info"),
              })
            ),
            vstack(
              pointField({
                label: "Location",
                path: ["data", "location"],
                help: "Location of the survey site.",
                required: required("info"),
              })
            )
          ),
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Observations",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "observations"],
              rows: 5,
              help: outdent`
              Note any signs of pollution that you have observed
              e.g. smells, foam on the water, oily sheen on the water,
              water colour, growth of algae in the water,
              any dead fish, other dead animals, debris,
              e.g. litter in waterway.`,
            }),
          ],
        }),
        section({
          title: "Readings",
          path: [],
          blocks: columns2(
            numberField({
              label: "Temperature",
              path: ["data", "temperature"],
              units: "°C",
              bounds: minValue(0),
            }),
            numberField({
              label: "pH",
              path: ["data", "ph"],
              bounds: bounds(0, 14),
            }),
            numberField({
              label: "Nitrate",
              path: ["data", "nitrate"],
              units: "ppm",
              bounds: minValue(0),
            }),
            numberField({
              label: "Phosphate",
              path: ["data", "phosphate"],
              units: "ppm",
              bounds: minValue(0),
            }),
            numberField({
              label: "Turbidity",
              path: ["data", "turbidity"],
              units: "NTU",
              bounds: bounds(0, 240),
              help: "Turbidity reading according to NTU scale on the Secchi disc tube.",
              // TODO: CUSTOM TURBIDITY VALIDATION RULE
            }),
            numberField({
              label: "Dissolved Oxygen",
              path: ["data", "dissolvedOxygen"],
              units: "ppm",
              bounds: minValue(0),
            }),
            numberField({
              label: "Total Dissolved Solids",
              path: ["data", "totalDissolvedSolids"],
              units: "ppm",
              bounds: minValue(0),
              help: outdent`
                Only complete this field
                if you are using an electrical conductivity meter.`,
            }),
            numberField({
              label: "Electrical Conductivity",
              path: ["data", "electricalConductivity"],
              units: "µS/cm",
              bounds: minValue(0),
              help: outdent`
                Only complete this field
                if you are using an electrical conductivity meter.`,
            }),
            select({
              label: "Coliforms",
              path: ["data", "coliforms"],
              options: [
                selectOption(null, "No coliform reading taken"),
                selectOption(true, "Present (>40 coliform colonies per litre)"),
                selectOption(false, "Absent (<40 coliform colonies per litre)"),
              ],
              help: "Did the coliform test indicate coliforms were present or absent?",
            })
          ),
        }),
        section({
          title: "Comments",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 5,
              help: "Please include any comments including details of any additional surveyors.",
            }),
          ],
        }),
      ],
    }),
  ],
});
