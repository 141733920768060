// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesTerrestrialInvasivePlant = "HB" | "JK" | "GH";

const HimalayanBalsam: CesTerrestrialInvasivePlant = "HB";
const JapaneseKnotweed: CesTerrestrialInvasivePlant = "JK";
const GiantHogweed: CesTerrestrialInvasivePlant = "GH";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesTerrestrialInvasivePlantEnum = {
  ...createEnum("CesTerrestrialInvasivePlant", [
    [HimalayanBalsam, "Himalayan balsam"],
    [JapaneseKnotweed, "Japanese knotweed"],
    [GiantHogweed, "Giant hogweed"],
  ]),
  HimalayanBalsam,
  JapaneseKnotweed,
  GiantHogweed,
};

export default CesTerrestrialInvasivePlantEnum;
