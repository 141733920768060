import {
  OtherSpecifyOptions,
  topoExpr,
  attachmentField,
  bounds,
  checkbox,
  columns2,
  columns3,
  customRule,
  enumSelectOptions,
  form,
  minValue,
  multiSelect,
  numberField,
  otherSpecify,
  page,
  pointField,
  repeat,
  required,
  requiredIfV2,
  section,
  select,
  text,
  textArea,
  textField,
  timestampField,
  visibility,
  vstack,
} from "@cartographerio/topo-form";
import { isArray, isNullable, isNumber } from "@cartographerio/guard";
import {
  EsrtWaterQualityAmmoniaEnum,
  EsrtWaterQualityBankVegetationEnum,
  EsrtWaterQualityEstimatedDepthEnum,
  EsrtWaterQualityEstimatedWidthEnum,
  EsrtWaterQualityFlowLevelEnum,
  EsrtWaterQualityFlowObstacleEnum,
  EsrtWaterQualityInvasivePlantEnum,
  EsrtWaterQualityLandUseEnum,
  EsrtWaterQualityNitrateEnum,
  EsrtWaterQualityPhosphateEnum,
  EsrtWaterQualityPollutionEvidenceEnum,
  EsrtWaterQualityPollutionSourceEnum,
  EsrtWaterQualityPublicAccessEnum,
  EsrtWaterQualityRainfallEnum,
  EsrtWaterQualityRecreationalActivityEnum,
  EsrtWaterQualityRiverbedCompositionEnum,
  EsrtWaterQualityWaterLevelEnum,
  EsrtWaterQualityWaterbodyTypeEnum,
  EsrtWaterQualityWildlifeEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
import { appendUnit } from "../WrtWestcountryCsi/form";

const sampleTakenChecked = topoExpr<boolean>(env => {
  return env.getAbsolute(["data", "riverChannel", "sampleTaken"]) === true;
});

function narrowOtherSpecify(options: OtherSpecifyOptions) {
  return columns2(otherSpecify(options));
}

export default form({
  title: "ESRT Water Quality",
  surveyorHelp: "Observer name",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: "Survey Details",
          path: ["data"],
          blocks: [
            columns2(
              vstack(
                textField({
                  label: "River/Stream Name",
                  path: ["details", "river"],
                  required: required("error"),
                }),
                textField({
                  label: "Location Name",
                  path: ["details", "site"],
                  required: required("error"),
                }),
                timestampField({
                  label: "Date and Time",
                  path: ["recorded"],
                  defaultValue: "now",
                  required: required("error"),
                  help: "When was the data collected in the field?",
                })
              ),
              pointField({
                label: "Location",
                path: ["details", "location"],
                required: required("error"),
                help: "Location extent of the survey.",
                selectMinZoom: 12,
              }),
              vstack(
                select({
                  label: "Public Access",
                  path: ["details", "publicAccess", "selected"],
                  options: enumSelectOptions(EsrtWaterQualityPublicAccessEnum),
                  required: required("error"),
                }),
                otherSpecify({
                  label: "Other Public Access",
                  basePath: ["details", "publicAccess"],
                  level: "error",
                  test: value =>
                    value === EsrtWaterQualityPublicAccessEnum.Other,
                  visible: "auto",
                  help: "If you have selected 'other', please describe.",
                }),
                select({
                  label: "Type of Waterbody (select one)",
                  path: ["details", "waterbodyType", "selected"],
                  options: enumSelectOptions(EsrtWaterQualityWaterbodyTypeEnum),
                  required: required("error"),
                }),
                otherSpecify({
                  label: "Other Waterbody Type",
                  basePath: ["details", "waterbodyType"],
                  level: "error",
                  test: value =>
                    value === EsrtWaterQualityWaterbodyTypeEnum.Other,
                  visible: "auto",
                  help: "If you have selected 'other', please describe.",
                }),
                select({
                  label: "Rain in Previous 24 Hours (select one)",
                  path: ["details", "recentRain"],
                  options: enumSelectOptions(EsrtWaterQualityRainfallEnum),
                  required: required("error"),
                })
              )
            ),
          ],
        }),
        section({
          title: "Photographs",
          path: ["data"],
          help: outdent`
          📸 Please take up to four photos to go with your survey data. One photo
          should be taken from the same place on each visit and will capture some
          part of the waterbody and surroundings. Other features where photos would
          be useful are highlighted with a camera icon.
          `,
          blocks: [
            attachmentField({
              label: null,
              path: ["details", "photographs"],
              fullWidth: true,
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "General Observations",
          path: ["data", "ecosystem"],
          blocks: [
            multiSelect({
              label:
                "Dominant Land Use within ~50m (~150m ft) (tick all that apply)",
              path: ["landUse", "selected"],
              options: enumSelectOptions(EsrtWaterQualityLandUseEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Dominant Land Use",
              basePath: ["landUse"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(EsrtWaterQualityLandUseEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label:
                "Dominant Bankside Vegetation within ~5m (~15 ft) (tick all that apply) 📸",
              path: ["bankVegetation", "selected"],
              options: enumSelectOptions(EsrtWaterQualityBankVegetationEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Dominant Bankside Vegetation",
              basePath: ["bankVegetation"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(EsrtWaterQualityBankVegetationEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label: "Problem Invasive Plant Species (tick all that apply) 📸",
              path: ["invasivePlants", "selected"],
              options: enumSelectOptions(EsrtWaterQualityInvasivePlantEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Problem Invasive Plant Species",
              basePath: ["invasivePlants"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(EsrtWaterQualityInvasivePlantEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label: "Wildlife Signs or Sightings (tick all that apply)",
              path: ["wildlife", "selected"],
              options: enumSelectOptions(EsrtWaterQualityWildlifeEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Wildlife Signs or Sightings",
              basePath: ["wildlife"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(EsrtWaterQualityWildlifeEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label: "Recreational Activites Present (tick all that apply)",
              path: ["recreationalActivities", "selected"],
              options: enumSelectOptions(
                EsrtWaterQualityRecreationalActivityEnum
              ),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Recreational Activities Present",
              basePath: ["recreationalActivities"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(EsrtWaterQualityRecreationalActivityEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
          ],
        }),
        section({
          title: "Evidence of Pollution",
          path: ["data", "pollution"],
          help: outdent`
          If you see any potential pollution incidents,
          call the Environment Agency 24-hour incident hotline 0800 807060.
          Indicators include: grey water discharge, oil, chemical or sewage odour,
          dead fish, or fish gasping for air.
          `,
          blocks: [
            multiSelect({
              label: "Observed Pollution Sources (tick all that apply) 📸",
              path: ["pollutionSources", "selected"],
              options: enumSelectOptions(EsrtWaterQualityPollutionSourceEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Observed Pollution Sources",
              basePath: ["pollutionSources"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(EsrtWaterQualityPollutionSourceEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label:
                "Observed Evidence of Recent Pollution (tick all that apply) 📸",
              path: ["pollutionEvidence", "selected"],
              options: enumSelectOptions(EsrtWaterQualityPollutionEvidenceEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Observed Evidence of Recent Pollution",
              basePath: ["pollutionEvidence"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(EsrtWaterQualityPollutionEvidenceEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
          ],
        }),
        section({
          title: "River Channel Characteristics",
          path: ["data", "riverChannel"],
          blocks: [
            columns2(
              vstack(
                select({
                  label: "Estimated Width at Water Level",
                  path: ["estimatedWidth"],
                  options: enumSelectOptions(
                    EsrtWaterQualityEstimatedWidthEnum
                  ),
                  required: required("error"),
                }),
                select({
                  label: "Estimated Average Depth",
                  path: ["estimatedDepth"],
                  options: enumSelectOptions(
                    EsrtWaterQualityEstimatedDepthEnum
                  ),
                  required: required("error"),
                }),
                select({
                  label: "Water Level",
                  path: ["waterLevel"],
                  options: enumSelectOptions(EsrtWaterQualityWaterLevelEnum),
                  required: required("error"),
                  help: outdent`
                  You can find clues to previous water levels
                  by looking for "trash" lines on the bank.
                  `,
                }),
                select({
                  label: "Flow Condition",
                  path: ["waterFlow"],
                  options: enumSelectOptions(EsrtWaterQualityFlowLevelEnum),
                  required: required("error"),
                  help: outdent`
                "Steady" is walking speed, "Surging" is faster,
                and "Slow" is slower than walking speed.
                `,
                })
              )
            ),
            multiSelect({
              label:
                "Obstacles to Fish, Flow, and Other Key Features (tick all that apply)",
              path: ["obstacles"],
              options: enumSelectOptions(EsrtWaterQualityFlowObstacleEnum),
              help: outdent`
                  Water and fish need to move freely.
                  Is there an obstruction in the channel (within 50m)
                  which might stop or inhibit natural flow or fish movement?
                  `,
              appearance: "checkboxgroup",
              columns: 2,
            }),
            columns2(
              select({
                label: "Predominant Riverbed Composition",
                path: ["riverbedComposition", "selected"],
                options: enumSelectOptions(
                  EsrtWaterQualityRiverbedCompositionEnum
                ),
                required: required("error"),
                help: outdent`
              Size guide:

              - cobble/stone - <25cm to >6cm (football to tennis ball)
              - gravel/pebble - <6cm to >3mm (tennis ball to small pea)
              - sand - <2mm
              - silt/mud - fine muds, not sand
              - artificial - e.g. rubble or concrete
              `,
              })
            ),
            narrowOtherSpecify({
              label: "Other Predominant Riverbed Composition",
              basePath: ["riverbedComposition"],
              level: "error",
              test: value =>
                value === EsrtWaterQualityRiverbedCompositionEnum.Other,
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
          ],
        }),
        section({
          title: "Water Quality Measurements",
          path: ["data", "riverChannel"],
          blocks: [
            checkbox({
              label: "Water Quality Tested",
              path: ["sampleTaken"],
              checkboxLabel: "Did you test the water quality?",
              defaultValue: true,
            }),
            text({
              appearance: "help",
              visible: sampleTakenChecked,
              markdown: outdent`
                  Enter your measurements below.
                  If you are unable to take any measurement,
                  for example due to poor conditions or faulty kit,
                  please leave the relevant field blank:
                  `,
            }),
            visibility({
              visible: sampleTakenChecked,
              block: columns2(
                vstack(
                  numberField({
                    label: "Temperature",
                    path: ["temperature"],
                    units: "°C",
                    decimalPlaces: 1,
                    bounds: bounds(0, 100),
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                    ],
                  }),
                  numberField({
                    label: "Dissolved Solids",
                    path: ["dissolvedSolids"],
                    units: "ppm",
                    decimalPlaces: 1,
                    bounds: minValue(0),
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                      customRule({
                        level: "error",
                        triggerWhen: topoExpr(env => {
                          const value = env
                            .getFocusedAs(isNullable(isNumber))
                            .getOrElse(() => null);

                          return value != null && value % 1 !== 0;
                        }),
                        message:
                          "Make sure you are using the correct units - TDS is a whole number.",
                      }),
                    ],
                  }),
                  numberField({
                    label: "Turbidity",
                    path: ["turbidity"],
                    units: "NTU",
                    defaultValue: null,
                    help: "Enter a turbidity reading in the range 12 to 240.",
                    required: required("info"),
                    bounds: bounds(12, 240),
                  }),
                  select({
                    label: "Ammonia (NH3)",
                    path: ["ammonia"],
                    options: enumSelectOptions(EsrtWaterQualityAmmoniaEnum).map(
                      appendUnit("ppm")
                    ),
                    help: outdent`
                    Immerse strip for **5 seconds**.
                    Remove with pad face up for **60 seconds** and compare to colour chart.
                    **DO NOT SHAKE OFF EXCESS WATER.**
                    `,
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                    ],
                  }),
                  select({
                    label: "Phosphate (PO4)",
                    path: ["phosphate"],
                    options: enumSelectOptions(
                      EsrtWaterQualityPhosphateEnum
                    ).map(appendUnit("ppb")),
                    help: outdent`
                    Bend strip and place in test tube cap.
                    Replace cap and invert slowly 5 times.
                    **REMOVE CAP AND TEST STRIP**
                    and compare colour through tube to colour chart.
                    `,
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                    ],
                  }),
                  select({
                    label: "Nitrate (NO3)",
                    path: ["nitrate"],
                    options: enumSelectOptions(EsrtWaterQualityNitrateEnum).map(
                      appendUnit("ppm")
                    ),
                    help: outdent`
                    Immerse strip for **2 seconds**.
                    Remove with pad face up for **60 seconds** and compare to colour chart.
                    **DO NOT SHAKE OFF EXCESS WATER.**
                    `,
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                    ],
                  })
                )
              ),
            }),
          ],
        }),
        section({
          title: "Additional Tests",
          path: ["data", "riverChannel"],
          visible: sampleTakenChecked,
          help: outdent`
          Only fill out this section if you have an additional test to complete,
          such as pH, dissolved oxygen, or bacteria.
          Please use the Notes section if necessary.
          `,
          blocks: repeat({
            path: ["additionalTests"],
            addButtonLabel: "Add Test",
            emptyPrompt: 'Click "Add Test" to add an additional test.',
            appearance: "compact",
            confirmOnDelete: false,
            block: columns3(
              textField({
                label: "Test Name",
                path: ["name"],
              }),
              numberField({
                label: "Result",
                path: ["result"],
              }),
              textField({
                label: "Units",
                path: ["units"],
              })
            ),
          }),
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 5,
            }),
          ],
        }),
      ],
    }),
  ],
});
