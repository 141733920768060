import {
  PartialParams,
  SearchResultsFormat,
  WorkspaceMemberSearchOptions,
  endpoints,
} from "@cartographerio/client";
import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { selectOption } from "@cartographerio/topo-form";
import {
  CurrentMember,
  PendingMember,
  WorkspaceRoleNameEnum,
  addRole,
  qualificationRole,
  roleProjectId,
  roleQualificationId,
  roleTeamId,
  roleWorkspaceId,
  unsafeProjectAlias,
  unsafeTeamAlias,
} from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { omit } from "lodash";
import { ReactElement, useCallback, useEffect, useMemo } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import { useIOConfirm, useIOErrorAlert } from "../../../components/Alert";
import ButtonLink from "../../../components/ButtonLink";
import MemberList, {
  useApproveCallback,
  useRejectCallback,
  useRemoveMemberRolesCallback,
} from "../../../components/MemberList";
import {
  OnMemberQualificationChange,
  OnMemberRoleChange,
} from "../../../components/MemberList/column";
import MemberListToolbar from "../../../components/MemberListToolbar";
import PageContainer from "../../../components/PageContainer";
import PageTopBar from "../../../components/PageTopBar";
import Popover from "../../../components/Popover";
import ResetFiltersAlert from "../../../components/ResetFiltersAlert";
import Select from "../../../components/Select";
import Spaced from "../../../components/Spaced";
import { useApiParams } from "../../../contexts/auth";
import { useApiUrlFormatter } from "../../../hooks/useApiUrl";
import { usePageTitle } from "../../../hooks/usePageTitle";
import usePermissionCheckRunner from "../../../hooks/usePermissionCheckRunner";
import { usePreferTeams } from "../../../hooks/usePreferTeams";
import useRequirePermission from "../../../hooks/useRequirePermission";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { useTeamsEnabled } from "../../../hooks/useTeamsEnabled";
import useUserLimitReason from "../../../hooks/useUserLimitReason";
import { useCurrentWorkspaceGraph } from "../../../hooks/useWorkspaceGraph";
import { routes } from "../../../routes";
import WorkspacePageHeader from "../WorkspacePageHeader";
import { workspaceMemberListColumns } from "./columns";

export default function WorkspaceMemberListPage(
  props: RouteProps<typeof routes.workspace.member.list>
): ReactElement {
  const {
    path: { workspaceRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const confirmAlert = useIOConfirm();
  const errorAlert = useIOErrorAlert();
  const permissionCheckPasses = usePermissionCheckRunner();

  const graph = useCurrentWorkspaceGraph(workspaceRef);

  const [workspace] = useMemo(() => graph.allWorkspaces(), [graph]);

  const {
    page,
    count,
    order,
    role,
    q: searchTerm,
    project: projectFilter,
    team: teamFilter,
    type: memberType,
  } = query;

  useRequirePermission(checks.workspace.admin(workspace.id));

  usePageTitle(`Members - ${workspace.name}`);

  const qualificationIds = useMemo(
    () =>
      new Set(graph.allProjects().flatMap(project => project.qualificationIds)),
    [graph]
  );
  const workspaceQualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams),
    qualifications =>
      qualifications.filter(({ id }) => qualificationIds.has(id))
  );

  const multiTeam = useTeamsEnabled(workspace);
  const preferTeams = usePreferTeams(workspace);

  const userLimitReason = useUserLimitReason(workspace);

  useEffect(() => {
    if (preferTeams && query.project != null) {
      updateQuery({ ...query, project: undefined });
    } else if (!preferTeams && query.team != null) {
      updateQuery({ ...query, team: undefined });
    }
  }, [query, updateQuery, preferTeams]);

  const selectedProject = useMemo(
    () =>
      projectFilter != null
        ? graph.optFindProjectByRef(projectFilter, workspace.id)
        : null,
    [graph, projectFilter, workspace.id]
  );

  const selectedTeam = useMemo(
    () =>
      teamFilter != null
        ? graph.optFindTeamByRef(teamFilter, workspace.id)
        : null,
    [graph, teamFilter, workspace.id]
  );

  const handleRoleChange: OnMemberRoleChange = useCallback(
    (member, role, include = true) => {
      queries.user.v2
        .save(queryClient, apiParams, member.userId, {
          ...member,
          roles: include
            ? addRole(member.roles, role, "replace")
            : member.roles.filter(otherRole => otherRole !== role),
        })
        .tap(() =>
          toast({
            title: "Role updated successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun();
    },
    [apiParams, errorAlert, queryClient, toast]
  );

  const handleQualificationChange = useCallback<OnMemberQualificationChange>(
    (member, qualificationId, roleName) =>
      confirmAlert({
        title: "Role change confirmation",
        message: (
          <>
            This will change the user&apos;s level of access across all projects
            that include this qualification. Are you sure you want to continue?
          </>
        ),
      })
        .flatMap(confirmed =>
          confirmed
            ? queries.user.v2
                .save(queryClient, apiParams, member.userId, {
                  ...member,
                  qualificationRoles: [
                    ...member.qualificationRoles.filter(
                      role => roleQualificationId(role) !== qualificationId
                    ),
                    ...(roleName != null
                      ? [qualificationRole(roleName, qualificationId)]
                      : []),
                  ],
                })
                .tapError(errorAlert)
            : IO.fail("Cancelled")
        )
        .tap(() =>
          toast({ status: "success", title: "Role updated successfully" })
        )
        .recover(() =>
          toast({ status: "error", description: "Role change cancelled" })
        )
        .unsafeRun(),
    [apiParams, confirmAlert, errorAlert, queryClient, toast]
  );

  const canRemove = useCallback(
    (_user: CurrentMember | PendingMember) =>
      permissionCheckPasses(checks.user.removeFromWorkspace(workspace.id)),
    [permissionCheckPasses, workspace.id]
  );

  const handleRemove = useRemoveMemberRolesCallback(
    "Remove this person from the workspace? They will also be removed from all projects and teams.",
    "Person removed from workspace",
    useCallback(
      roles =>
        roles.filter(
          role =>
            roleWorkspaceId(role) !== workspace.id &&
            Option.wrap(roleProjectId(role))
              .nullMap(graph.optFindProjectById)
              .isNone() &&
            Option.wrap(roleTeamId(role))
              .nullMap(graph.optFindTeamById)
              .isNone()
        ),
      [graph, workspace.id]
    )
  );

  const handleApprove = useApproveCallback();
  const handleReject = useRejectCallback();

  const projectOptions = useMemo(
    () =>
      graph.allProjects().map(({ name, alias }) => selectOption(alias, name)),
    [graph]
  );

  const teamOptions = useMemo(
    () => graph.allTeams().map(({ name, alias }) => selectOption(alias, name)),
    [graph]
  );

  const columns = useMemo(
    () =>
      workspaceMemberListColumns({
        workspace: workspace.id,
        qualifications: workspaceQualifications,
        permissionCheckPasses,
        onRoleChange: handleRoleChange,
        onQualificationChange: handleQualificationChange,
        ...(preferTeams
          ? {
              teams: multiTeam ? graph.allTeams() : [],
              selectedTeam: selectedTeam?.id,
            }
          : {
              projects: graph.allProjects(),
              selectedProject: selectedProject?.id,
            }),
      }),
    [
      workspace.id,
      workspaceQualifications,
      permissionCheckPasses,
      handleRoleChange,
      handleQualificationChange,
      preferTeams,
      multiTeam,
      graph,
      selectedTeam,
      selectedProject,
    ]
  );

  const searchOpts = useMemo(
    (): PartialParams<WorkspaceMemberSearchOptions> => ({
      q: query.q,
      project: selectedProject?.id,
      team: selectedTeam?.id,
      memberType,
      role: query.role,
      order: query.order,
      skip: query.page * query.count,
      limit: query.count,
    }),
    [
      memberType,
      query.count,
      query.order,
      query.page,
      query.q,
      query.role,
      selectedProject?.id,
      selectedTeam?.id,
    ]
  );

  const { data, error } = useQuery(
    queries.member.v1.workspaceSearch(apiParams, workspace.id, searchOpts)
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.member.v1.workspaceSearchUrl(workspace.id, {
          ...omit(searchOpts, "skip", "limit"),
          format,
        })
      ),
    [formatApiUrl, searchOpts, workspace.id]
  );

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="members" />
      <WorkspacePageHeader workspace={workspace} selected="members" />
      <PageContainer width="wide">
        <Spaced>
          <MemberListToolbar
            searchTerm={searchTerm}
            memberType={memberType}
            workspace={workspace}
            roleSelect={
              <Select.Nullable
                value={role}
                options={WorkspaceRoleNameEnum.entries}
                onChange={role => updateQuery({ ...query, role, page: 0 })}
                placeholder="All Roles"
                background="transparent"
                w="fit-content"
                maxW="52"
              />
            }
            inGroup={preferTeams ? teamFilter : projectFilter}
            defaultGroupLabel={preferTeams ? "All Teams" : "All Projects"}
            groupOptions={preferTeams ? teamOptions : projectOptions}
            onGroupChange={projectOrTeamAlias =>
              updateQuery({
                ...query,
                page: 0,
                ...(preferTeams
                  ? {
                      team:
                        projectOrTeamAlias != null
                          ? unsafeTeamAlias(projectOrTeamAlias)
                          : undefined,
                    }
                  : {
                      project:
                        projectOrTeamAlias != null
                          ? unsafeProjectAlias(projectOrTeamAlias)
                          : undefined,
                    }),
              })
            }
            onSearchTermChange={q =>
              updateQuery({ ...query, q: q ?? undefined, page: 0 })
            }
            onMemberTypeChange={type => updateQuery({ ...query, type })}
            addButton={
              <Popover
                enabled={userLimitReason != null}
                placement="bottom-start"
                body={userLimitReason}
              >
                <ButtonLink.Internal
                  colorScheme="blue"
                  to={routes.workspace.invitation.create.url(
                    [workspace.alias],
                    { project: projectFilter }
                  )}
                  disabled={userLimitReason != null}
                >
                  Invite
                </ButtonLink.Internal>
              </Popover>
            }
            downloadUrl={downloadUrl}
          />
          <ResetFiltersAlert
            route={routes.workspace.member.list}
            query={query}
            updateQuery={updateQuery}
          />
          <MemberList
            data={data ?? null}
            error={error}
            page={page}
            count={count}
            order={order}
            workspace={workspace}
            columns={columns}
            changeIdentityRedirect={routes.workspace.home.url([
              workspace.alias,
            ])}
            canRemove={canRemove}
            onRemove={handleRemove}
            onApprove={handleApprove}
            onReject={handleReject}
            itemLink={member =>
              member.type === "InvitedMember"
                ? routes.workspace.invitation.view.url([
                    workspace.alias,
                    member.invitationId,
                  ])
                : routes.workspace.member.update.url([
                    workspace.alias,
                    member.userId,
                  ])
            }
            onPageChange={page => updateQuery({ ...query, page })}
            onOrderChange={order => updateQuery({ ...query, order })}
          />
        </Spaced>
      </PageContainer>
    </>
  );
}
