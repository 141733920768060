// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsArtificialGroundCover =
  | "Fp"
  | "Tr"
  | "Ic"
  | "Re"
  | "Sy"
  | "Ld"
  | "Ar"
  | "Pv"
  | "Pr"
  | "Pw"
  | "Ow";

const PedestrianisedFootpath: MrsArtificialGroundCover = "Fp";
const TransportInfrastucture: MrsArtificialGroundCover = "Tr";
const BuildingsCommercial: MrsArtificialGroundCover = "Ic";
const BuildingsResidential: MrsArtificialGroundCover = "Re";
const StorageArea: MrsArtificialGroundCover = "Sy";
const LandfillArea: MrsArtificialGroundCover = "Ld";
const ArableAgriculture: MrsArtificialGroundCover = "Ar";
const PermanentlyVegetatedAgriculture: MrsArtificialGroundCover = "Pv";
const PermanentlyVegetatedRecreation: MrsArtificialGroundCover = "Pr";
const PlantationWoodland: MrsArtificialGroundCover = "Pw";
const OpenWater: MrsArtificialGroundCover = "Ow";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsArtificialGroundCoverEnum = {
  ...createEnum("MrsArtificialGroundCover", [
    [PedestrianisedFootpath, "Pedestrianised, footpath"],
    [
      TransportInfrastucture,
      "Transport infrastructure (road, railway, car park)",
    ],
    [BuildingsCommercial, "Buildings (commercial / industrial)"],
    [BuildingsResidential, "Buildings (residential)"],
    [StorageArea, "Storage area"],
    [LandfillArea, "Landfill area"],
    [ArableAgriculture, "Arable agriculture / allotments"],
    [
      PermanentlyVegetatedAgriculture,
      "Permanently vegetated agriculture (e.g. pasture, orchard)",
    ],
    [
      PermanentlyVegetatedRecreation,
      "Permanently vegetated recreation (e.g. playing fields)",
    ],
    [PlantationWoodland, "Plantation woodland"],
    [OpenWater, "Open water (e.g. canal, reservoir)"],
  ]),
  PedestrianisedFootpath,
  TransportInfrastucture,
  BuildingsCommercial,
  BuildingsResidential,
  StorageArea,
  LandfillArea,
  ArableAgriculture,
  PermanentlyVegetatedAgriculture,
  PermanentlyVegetatedRecreation,
  PlantationWoodland,
  OpenWater,
};

export default MrsArtificialGroundCoverEnum;
