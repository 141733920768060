import { Path } from "@cartographerio/topo-core";
import { isEqual, uniqWith, values } from "lodash";
import { nullableSchema } from "./create";
import { ProductSchema, Schema } from "./type";
import { typeCheck } from "./typeCheck";

function sumSchemaRef(
  products: { [name: string]: ProductSchema },
  path: Path
): Schema[] {
  const schemas = values(products).reduce<Schema[]>(
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    (memo, schema) => [...memo, ...schemaRefAll(schema, path)],
    []
  );

  return uniqWith(schemas, isEqual);
}

function ensureNullable(schema: Schema): Schema {
  switch (schema.type) {
    case "Nullable":
      return schema;
    default:
      return nullableSchema(schema);
  }
}

/**
 * Find the schema(s) at `path` within `schema`.
 *
 * This function returns an array to represent
 * a union of the fields that have this path inside sum types.
 */
export function schemaRefAll(schema: Schema, path: Path): Schema[] {
  if (path.length === 0) {
    return [schema];
  } else if (typeof path[0] === "string") {
    switch (schema.type) {
      case "Dict": {
        const [head, ...tail] = path;
        // Dictionaries are partially specified on the set of possible keys,
        // so the schema for its values is always a number:
        if (typeCheck(schema.key, head).length === 0) {
          return schemaRefAll(schema.value, tail).map(ensureNullable);
        } else {
          return [];
        }
      }

      case "Product": {
        const [head, ...tail] = path;
        const fieldSchema: Schema | null = schema.fields[head];
        return fieldSchema == null ? [] : schemaRefAll(fieldSchema, tail);
      }

      case "Sum": {
        return sumSchemaRef(schema.products, path);
      }

      default:
        return [];
    }
  } else {
    switch (schema.type) {
      case "Array": {
        const [, ...tail] = path;
        return schemaRefAll(schema.param, tail);
      }

      case "Tuple": {
        const [head, ...tail] = path;
        const fieldSchema: Schema | null = schema.items[head] || null;
        return schema == null ? [] : schemaRefAll(fieldSchema, tail);
      }

      case "Sum": {
        return sumSchemaRef(schema.products, path);
      }

      default:
        return [];
    }
  }
}

/** Find a single subschema at `path` within `schema`.
 *
 * Returns `undefined` if `path` is not found or returns multiple subschemas.
 */
export function schemaRef(schema: Schema, path: Path): Schema | undefined {
  const results = schemaRefAll(schema, path);

  if (results.length === 1) {
    return results[0];
  } else {
    return undefined;
  }
}
