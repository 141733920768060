// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21Foreshore =
  | "N001"
  | "N002"
  | "N003"
  | "N004"
  | "N005"
  | "N006a"
  | "N006b"
  | "N006c"
  | "N007a"
  | "N007b"
  | "N007c"
  | "N008"
  | "N009"
  | "N010"
  | "N011"
  | "N012"
  | "N013"
  | "N014"
  | "N015"
  | "N016"
  | "N017"
  | "N018"
  | "N019"
  | "N020"
  | "N021"
  | "N022"
  | "N023"
  | "N024a"
  | "N024b"
  | "N024c"
  | "N025a"
  | "N025b"
  | "N025c"
  | "N026"
  | "N027"
  | "N028"
  | "N029"
  | "N030"
  | "N031"
  | "N032"
  | "N033"
  | "N034"
  | "N035a"
  | "N035b"
  | "N036a"
  | "N036b"
  | "N036c"
  | "N037a"
  | "N037b"
  | "N038"
  | "N039"
  | "N040"
  | "N041"
  | "N042"
  | "N043"
  | "N044"
  | "N045"
  | "N046"
  | "N047"
  | "N048"
  | "N049"
  | "N050"
  | "N051"
  | "N052"
  | "N053"
  | "N054"
  | "N055"
  | "N056"
  | "N057"
  | "N058"
  | "N059a"
  | "N059b"
  | "N060"
  | "N061"
  | "N062"
  | "N063"
  | "N064"
  | "N065"
  | "N066"
  | "N067"
  | "N068"
  | "N069"
  | "N070"
  | "N071"
  | "N072"
  | "N073"
  | "N074"
  | "N075"
  | "N076"
  | "N077"
  | "N078"
  | "N079"
  | "N080"
  | "N081"
  | "N082"
  | "N083"
  | "N084"
  | "N085"
  | "N086"
  | "N087"
  | "N088"
  | "N089"
  | "N090"
  | "N091"
  | "N092"
  | "N093"
  | "N094"
  | "N095"
  | "N096"
  | "N097"
  | "N098"
  | "N099"
  | "N100"
  | "N101"
  | "N102"
  | "N103"
  | "N104"
  | "N105"
  | "N106"
  | "N107"
  | "N108"
  | "N109"
  | "N110"
  | "N111"
  | "N112"
  | "N113"
  | "N114"
  | "N115"
  | "N116"
  | "N117"
  | "N118"
  | "N119"
  | "N120"
  | "N121"
  | "N122"
  | "N123"
  | "N124"
  | "N125"
  | "N126"
  | "N127"
  | "N128"
  | "N129"
  | "N130"
  | "N131"
  | "N132"
  | "N133"
  | "N134"
  | "N135"
  | "N136"
  | "N137"
  | "N138"
  | "N139"
  | "N140"
  | "N141"
  | "N142"
  | "N143"
  | "N144"
  | "N145"
  | "N146"
  | "N147"
  | "N148"
  | "N149"
  | "N150"
  | "N151"
  | "N152"
  | "N153"
  | "N154"
  | "N155"
  | "N156"
  | "N157"
  | "N158"
  | "N159"
  | "N160"
  | "N161"
  | "N162"
  | "N163"
  | "N164"
  | "N165"
  | "N166"
  | "N167"
  | "N168"
  | "N169"
  | "N170"
  | "N171"
  | "N172"
  | "N173"
  | "N174"
  | "N175"
  | "N176"
  | "N177"
  | "N178"
  | "N179"
  | "N180"
  | "N181"
  | "N182"
  | "N183"
  | "N184"
  | "N185"
  | "N186"
  | "N187"
  | "N188"
  | "N189"
  | "N190"
  | "N191"
  | "N192"
  | "N193"
  | "N194"
  | "N195"
  | "N196"
  | "N197"
  | "N198"
  | "N199"
  | "N200"
  | "N201"
  | "N202"
  | "N203"
  | "N204"
  | "N205"
  | "N206"
  | "N207"
  | "N208"
  | "N209"
  | "N210"
  | "N211"
  | "N212"
  | "N213"
  | "N214"
  | "N215"
  | "N216"
  | "N217"
  | "N218"
  | "N219"
  | "N220"
  | "N221"
  | "N222"
  | "N223"
  | "N224"
  | "N225"
  | "N226"
  | "N227"
  | "N228"
  | "N229"
  | "N230"
  | "N231"
  | "N232"
  | "N233"
  | "N234"
  | "N235"
  | "N236"
  | "N237"
  | "N238"
  | "N239"
  | "N240"
  | "N241"
  | "N242"
  | "N243"
  | "N244"
  | "N245"
  | "N246"
  | "N247"
  | "N248"
  | "N249"
  | "N250"
  | "N251"
  | "S001"
  | "S002"
  | "S003"
  | "S004"
  | "S005"
  | "S006a"
  | "S006b"
  | "S007a"
  | "S007b"
  | "S008"
  | "S009"
  | "S010"
  | "S011"
  | "S012"
  | "S013"
  | "S014"
  | "S015"
  | "S016"
  | "S017"
  | "S018"
  | "S019"
  | "S020"
  | "S021"
  | "S022"
  | "S023"
  | "S024"
  | "S025"
  | "S026"
  | "S027"
  | "S028"
  | "S029"
  | "S030"
  | "S031"
  | "S032"
  | "S033"
  | "S034"
  | "S035"
  | "S036"
  | "S037"
  | "S038"
  | "S039"
  | "S040"
  | "S041"
  | "S042"
  | "S043"
  | "S044"
  | "S045"
  | "S046"
  | "S047"
  | "S048"
  | "S049"
  | "S050"
  | "S051"
  | "S052"
  | "S053"
  | "S054"
  | "S055"
  | "S056"
  | "S057"
  | "S058"
  | "S059"
  | "S060"
  | "S061"
  | "S062"
  | "S063"
  | "S064"
  | "S065"
  | "S066"
  | "S067"
  | "S068"
  | "S069"
  | "S070"
  | "S071"
  | "S072"
  | "S073"
  | "S074"
  | "S075"
  | "S076"
  | "S077"
  | "S078"
  | "S079"
  | "S080"
  | "S081"
  | "S082"
  | "S083"
  | "S084"
  | "S085"
  | "S086"
  | "S087"
  | "S088"
  | "S089"
  | "S090"
  | "S091"
  | "S092"
  | "S093"
  | "S094"
  | "S095"
  | "S096"
  | "S097"
  | "S098"
  | "S099"
  | "S100"
  | "S101"
  | "S102"
  | "S103"
  | "S104"
  | "S105"
  | "S106"
  | "S107"
  | "S108"
  | "S109"
  | "S110"
  | "S111"
  | "S112"
  | "S113"
  | "S114"
  | "S115"
  | "S116"
  | "S117"
  | "S118"
  | "S119"
  | "S120"
  | "S121"
  | "S122"
  | "S123"
  | "S124"
  | "S125"
  | "S126"
  | "S127"
  | "S128"
  | "S129"
  | "S130"
  | "S131"
  | "S132"
  | "S133"
  | "S134"
  | "S135"
  | "S136"
  | "S137"
  | "S138"
  | "S139"
  | "S140"
  | "S141"
  | "S142"
  | "S143"
  | "S144"
  | "S145"
  | "S146"
  | "S147"
  | "S148"
  | "S149"
  | "S150"
  | "S151"
  | "S152"
  | "S153"
  | "S154"
  | "S155"
  | "S156"
  | "S157"
  | "S158"
  | "S159"
  | "S160"
  | "S161"
  | "S162"
  | "S163"
  | "S164"
  | "S165"
  | "S166"
  | "S167"
  | "S168"
  | "S169"
  | "S170"
  | "S171"
  | "S172"
  | "S173"
  | "S174"
  | "S175"
  | "S176"
  | "S177"
  | "S178"
  | "S179"
  | "S180"
  | "S181"
  | "S182"
  | "S183"
  | "S184"
  | "S185"
  | "S186"
  | "S187"
  | "S188"
  | "S189"
  | "S190"
  | "S191"
  | "S192"
  | "S193"
  | "S194"
  | "S195"
  | "S196"
  | "S197"
  | "S198"
  | "S199"
  | "S200"
  | "S201"
  | "S202"
  | "S203"
  | "S204"
  | "S205"
  | "S206"
  | "S207"
  | "S208"
  | "S209"
  | "S210"
  | "S211"
  | "S212"
  | "S213"
  | "S214"
  | "S215"
  | "S216"
  | "S217"
  | "S218"
  | "S219"
  | "S220"
  | "S221"
  | "S222"
  | "S223"
  | "S224"
  | "S225"
  | "S226"
  | "S227"
  | "S228"
  | "S229"
  | "S230";

const N001: Thames21Foreshore = "N001";
const N002: Thames21Foreshore = "N002";
const N003: Thames21Foreshore = "N003";
const N004: Thames21Foreshore = "N004";
const N005: Thames21Foreshore = "N005";
const N006a: Thames21Foreshore = "N006a";
const N006b: Thames21Foreshore = "N006b";
const N006c: Thames21Foreshore = "N006c";
const N007a: Thames21Foreshore = "N007a";
const N007b: Thames21Foreshore = "N007b";
const N007c: Thames21Foreshore = "N007c";
const N008: Thames21Foreshore = "N008";
const N009: Thames21Foreshore = "N009";
const N010: Thames21Foreshore = "N010";
const N011: Thames21Foreshore = "N011";
const N012: Thames21Foreshore = "N012";
const N013: Thames21Foreshore = "N013";
const N014: Thames21Foreshore = "N014";
const N015: Thames21Foreshore = "N015";
const N016: Thames21Foreshore = "N016";
const N017: Thames21Foreshore = "N017";
const N018: Thames21Foreshore = "N018";
const N019: Thames21Foreshore = "N019";
const N020: Thames21Foreshore = "N020";
const N021: Thames21Foreshore = "N021";
const N022: Thames21Foreshore = "N022";
const N023: Thames21Foreshore = "N023";
const N024a: Thames21Foreshore = "N024a";
const N024b: Thames21Foreshore = "N024b";
const N024c: Thames21Foreshore = "N024c";
const N025a: Thames21Foreshore = "N025a";
const N025b: Thames21Foreshore = "N025b";
const N025c: Thames21Foreshore = "N025c";
const N026: Thames21Foreshore = "N026";
const N027: Thames21Foreshore = "N027";
const N028: Thames21Foreshore = "N028";
const N029: Thames21Foreshore = "N029";
const N030: Thames21Foreshore = "N030";
const N031: Thames21Foreshore = "N031";
const N032: Thames21Foreshore = "N032";
const N033: Thames21Foreshore = "N033";
const N034: Thames21Foreshore = "N034";
const N035a: Thames21Foreshore = "N035a";
const N035b: Thames21Foreshore = "N035b";
const N036a: Thames21Foreshore = "N036a";
const N036b: Thames21Foreshore = "N036b";
const N036c: Thames21Foreshore = "N036c";
const N037a: Thames21Foreshore = "N037a";
const N037b: Thames21Foreshore = "N037b";
const N038: Thames21Foreshore = "N038";
const N039: Thames21Foreshore = "N039";
const N040: Thames21Foreshore = "N040";
const N041: Thames21Foreshore = "N041";
const N042: Thames21Foreshore = "N042";
const N043: Thames21Foreshore = "N043";
const N044: Thames21Foreshore = "N044";
const N045: Thames21Foreshore = "N045";
const N046: Thames21Foreshore = "N046";
const N047: Thames21Foreshore = "N047";
const N048: Thames21Foreshore = "N048";
const N049: Thames21Foreshore = "N049";
const N050: Thames21Foreshore = "N050";
const N051: Thames21Foreshore = "N051";
const N052: Thames21Foreshore = "N052";
const N053: Thames21Foreshore = "N053";
const N054: Thames21Foreshore = "N054";
const N055: Thames21Foreshore = "N055";
const N056: Thames21Foreshore = "N056";
const N057: Thames21Foreshore = "N057";
const N058: Thames21Foreshore = "N058";
const N059a: Thames21Foreshore = "N059a";
const N059b: Thames21Foreshore = "N059b";
const N060: Thames21Foreshore = "N060";
const N061: Thames21Foreshore = "N061";
const N062: Thames21Foreshore = "N062";
const N063: Thames21Foreshore = "N063";
const N064: Thames21Foreshore = "N064";
const N065: Thames21Foreshore = "N065";
const N066: Thames21Foreshore = "N066";
const N067: Thames21Foreshore = "N067";
const N068: Thames21Foreshore = "N068";
const N069: Thames21Foreshore = "N069";
const N070: Thames21Foreshore = "N070";
const N071: Thames21Foreshore = "N071";
const N072: Thames21Foreshore = "N072";
const N073: Thames21Foreshore = "N073";
const N074: Thames21Foreshore = "N074";
const N075: Thames21Foreshore = "N075";
const N076: Thames21Foreshore = "N076";
const N077: Thames21Foreshore = "N077";
const N078: Thames21Foreshore = "N078";
const N079: Thames21Foreshore = "N079";
const N080: Thames21Foreshore = "N080";
const N081: Thames21Foreshore = "N081";
const N082: Thames21Foreshore = "N082";
const N083: Thames21Foreshore = "N083";
const N084: Thames21Foreshore = "N084";
const N085: Thames21Foreshore = "N085";
const N086: Thames21Foreshore = "N086";
const N087: Thames21Foreshore = "N087";
const N088: Thames21Foreshore = "N088";
const N089: Thames21Foreshore = "N089";
const N090: Thames21Foreshore = "N090";
const N091: Thames21Foreshore = "N091";
const N092: Thames21Foreshore = "N092";
const N093: Thames21Foreshore = "N093";
const N094: Thames21Foreshore = "N094";
const N095: Thames21Foreshore = "N095";
const N096: Thames21Foreshore = "N096";
const N097: Thames21Foreshore = "N097";
const N098: Thames21Foreshore = "N098";
const N099: Thames21Foreshore = "N099";
const N100: Thames21Foreshore = "N100";
const N101: Thames21Foreshore = "N101";
const N102: Thames21Foreshore = "N102";
const N103: Thames21Foreshore = "N103";
const N104: Thames21Foreshore = "N104";
const N105: Thames21Foreshore = "N105";
const N106: Thames21Foreshore = "N106";
const N107: Thames21Foreshore = "N107";
const N108: Thames21Foreshore = "N108";
const N109: Thames21Foreshore = "N109";
const N110: Thames21Foreshore = "N110";
const N111: Thames21Foreshore = "N111";
const N112: Thames21Foreshore = "N112";
const N113: Thames21Foreshore = "N113";
const N114: Thames21Foreshore = "N114";
const N115: Thames21Foreshore = "N115";
const N116: Thames21Foreshore = "N116";
const N117: Thames21Foreshore = "N117";
const N118: Thames21Foreshore = "N118";
const N119: Thames21Foreshore = "N119";
const N120: Thames21Foreshore = "N120";
const N121: Thames21Foreshore = "N121";
const N122: Thames21Foreshore = "N122";
const N123: Thames21Foreshore = "N123";
const N124: Thames21Foreshore = "N124";
const N125: Thames21Foreshore = "N125";
const N126: Thames21Foreshore = "N126";
const N127: Thames21Foreshore = "N127";
const N128: Thames21Foreshore = "N128";
const N129: Thames21Foreshore = "N129";
const N130: Thames21Foreshore = "N130";
const N131: Thames21Foreshore = "N131";
const N132: Thames21Foreshore = "N132";
const N133: Thames21Foreshore = "N133";
const N134: Thames21Foreshore = "N134";
const N135: Thames21Foreshore = "N135";
const N136: Thames21Foreshore = "N136";
const N137: Thames21Foreshore = "N137";
const N138: Thames21Foreshore = "N138";
const N139: Thames21Foreshore = "N139";
const N140: Thames21Foreshore = "N140";
const N141: Thames21Foreshore = "N141";
const N142: Thames21Foreshore = "N142";
const N143: Thames21Foreshore = "N143";
const N144: Thames21Foreshore = "N144";
const N145: Thames21Foreshore = "N145";
const N146: Thames21Foreshore = "N146";
const N147: Thames21Foreshore = "N147";
const N148: Thames21Foreshore = "N148";
const N149: Thames21Foreshore = "N149";
const N150: Thames21Foreshore = "N150";
const N151: Thames21Foreshore = "N151";
const N152: Thames21Foreshore = "N152";
const N153: Thames21Foreshore = "N153";
const N154: Thames21Foreshore = "N154";
const N155: Thames21Foreshore = "N155";
const N156: Thames21Foreshore = "N156";
const N157: Thames21Foreshore = "N157";
const N158: Thames21Foreshore = "N158";
const N159: Thames21Foreshore = "N159";
const N160: Thames21Foreshore = "N160";
const N161: Thames21Foreshore = "N161";
const N162: Thames21Foreshore = "N162";
const N163: Thames21Foreshore = "N163";
const N164: Thames21Foreshore = "N164";
const N165: Thames21Foreshore = "N165";
const N166: Thames21Foreshore = "N166";
const N167: Thames21Foreshore = "N167";
const N168: Thames21Foreshore = "N168";
const N169: Thames21Foreshore = "N169";
const N170: Thames21Foreshore = "N170";
const N171: Thames21Foreshore = "N171";
const N172: Thames21Foreshore = "N172";
const N173: Thames21Foreshore = "N173";
const N174: Thames21Foreshore = "N174";
const N175: Thames21Foreshore = "N175";
const N176: Thames21Foreshore = "N176";
const N177: Thames21Foreshore = "N177";
const N178: Thames21Foreshore = "N178";
const N179: Thames21Foreshore = "N179";
const N180: Thames21Foreshore = "N180";
const N181: Thames21Foreshore = "N181";
const N182: Thames21Foreshore = "N182";
const N183: Thames21Foreshore = "N183";
const N184: Thames21Foreshore = "N184";
const N185: Thames21Foreshore = "N185";
const N186: Thames21Foreshore = "N186";
const N187: Thames21Foreshore = "N187";
const N188: Thames21Foreshore = "N188";
const N189: Thames21Foreshore = "N189";
const N190: Thames21Foreshore = "N190";
const N191: Thames21Foreshore = "N191";
const N192: Thames21Foreshore = "N192";
const N193: Thames21Foreshore = "N193";
const N194: Thames21Foreshore = "N194";
const N195: Thames21Foreshore = "N195";
const N196: Thames21Foreshore = "N196";
const N197: Thames21Foreshore = "N197";
const N198: Thames21Foreshore = "N198";
const N199: Thames21Foreshore = "N199";
const N200: Thames21Foreshore = "N200";
const N201: Thames21Foreshore = "N201";
const N202: Thames21Foreshore = "N202";
const N203: Thames21Foreshore = "N203";
const N204: Thames21Foreshore = "N204";
const N205: Thames21Foreshore = "N205";
const N206: Thames21Foreshore = "N206";
const N207: Thames21Foreshore = "N207";
const N208: Thames21Foreshore = "N208";
const N209: Thames21Foreshore = "N209";
const N210: Thames21Foreshore = "N210";
const N211: Thames21Foreshore = "N211";
const N212: Thames21Foreshore = "N212";
const N213: Thames21Foreshore = "N213";
const N214: Thames21Foreshore = "N214";
const N215: Thames21Foreshore = "N215";
const N216: Thames21Foreshore = "N216";
const N217: Thames21Foreshore = "N217";
const N218: Thames21Foreshore = "N218";
const N219: Thames21Foreshore = "N219";
const N220: Thames21Foreshore = "N220";
const N221: Thames21Foreshore = "N221";
const N222: Thames21Foreshore = "N222";
const N223: Thames21Foreshore = "N223";
const N224: Thames21Foreshore = "N224";
const N225: Thames21Foreshore = "N225";
const N226: Thames21Foreshore = "N226";
const N227: Thames21Foreshore = "N227";
const N228: Thames21Foreshore = "N228";
const N229: Thames21Foreshore = "N229";
const N230: Thames21Foreshore = "N230";
const N231: Thames21Foreshore = "N231";
const N232: Thames21Foreshore = "N232";
const N233: Thames21Foreshore = "N233";
const N234: Thames21Foreshore = "N234";
const N235: Thames21Foreshore = "N235";
const N236: Thames21Foreshore = "N236";
const N237: Thames21Foreshore = "N237";
const N238: Thames21Foreshore = "N238";
const N239: Thames21Foreshore = "N239";
const N240: Thames21Foreshore = "N240";
const N241: Thames21Foreshore = "N241";
const N242: Thames21Foreshore = "N242";
const N243: Thames21Foreshore = "N243";
const N244: Thames21Foreshore = "N244";
const N245: Thames21Foreshore = "N245";
const N246: Thames21Foreshore = "N246";
const N247: Thames21Foreshore = "N247";
const N248: Thames21Foreshore = "N248";
const N249: Thames21Foreshore = "N249";
const N250: Thames21Foreshore = "N250";
const N251: Thames21Foreshore = "N251";
const S001: Thames21Foreshore = "S001";
const S002: Thames21Foreshore = "S002";
const S003: Thames21Foreshore = "S003";
const S004: Thames21Foreshore = "S004";
const S005: Thames21Foreshore = "S005";
const S006a: Thames21Foreshore = "S006a";
const S006b: Thames21Foreshore = "S006b";
const S007a: Thames21Foreshore = "S007a";
const S007b: Thames21Foreshore = "S007b";
const S008: Thames21Foreshore = "S008";
const S009: Thames21Foreshore = "S009";
const S010: Thames21Foreshore = "S010";
const S011: Thames21Foreshore = "S011";
const S012: Thames21Foreshore = "S012";
const S013: Thames21Foreshore = "S013";
const S014: Thames21Foreshore = "S014";
const S015: Thames21Foreshore = "S015";
const S016: Thames21Foreshore = "S016";
const S017: Thames21Foreshore = "S017";
const S018: Thames21Foreshore = "S018";
const S019: Thames21Foreshore = "S019";
const S020: Thames21Foreshore = "S020";
const S021: Thames21Foreshore = "S021";
const S022: Thames21Foreshore = "S022";
const S023: Thames21Foreshore = "S023";
const S024: Thames21Foreshore = "S024";
const S025: Thames21Foreshore = "S025";
const S026: Thames21Foreshore = "S026";
const S027: Thames21Foreshore = "S027";
const S028: Thames21Foreshore = "S028";
const S029: Thames21Foreshore = "S029";
const S030: Thames21Foreshore = "S030";
const S031: Thames21Foreshore = "S031";
const S032: Thames21Foreshore = "S032";
const S033: Thames21Foreshore = "S033";
const S034: Thames21Foreshore = "S034";
const S035: Thames21Foreshore = "S035";
const S036: Thames21Foreshore = "S036";
const S037: Thames21Foreshore = "S037";
const S038: Thames21Foreshore = "S038";
const S039: Thames21Foreshore = "S039";
const S040: Thames21Foreshore = "S040";
const S041: Thames21Foreshore = "S041";
const S042: Thames21Foreshore = "S042";
const S043: Thames21Foreshore = "S043";
const S044: Thames21Foreshore = "S044";
const S045: Thames21Foreshore = "S045";
const S046: Thames21Foreshore = "S046";
const S047: Thames21Foreshore = "S047";
const S048: Thames21Foreshore = "S048";
const S049: Thames21Foreshore = "S049";
const S050: Thames21Foreshore = "S050";
const S051: Thames21Foreshore = "S051";
const S052: Thames21Foreshore = "S052";
const S053: Thames21Foreshore = "S053";
const S054: Thames21Foreshore = "S054";
const S055: Thames21Foreshore = "S055";
const S056: Thames21Foreshore = "S056";
const S057: Thames21Foreshore = "S057";
const S058: Thames21Foreshore = "S058";
const S059: Thames21Foreshore = "S059";
const S060: Thames21Foreshore = "S060";
const S061: Thames21Foreshore = "S061";
const S062: Thames21Foreshore = "S062";
const S063: Thames21Foreshore = "S063";
const S064: Thames21Foreshore = "S064";
const S065: Thames21Foreshore = "S065";
const S066: Thames21Foreshore = "S066";
const S067: Thames21Foreshore = "S067";
const S068: Thames21Foreshore = "S068";
const S069: Thames21Foreshore = "S069";
const S070: Thames21Foreshore = "S070";
const S071: Thames21Foreshore = "S071";
const S072: Thames21Foreshore = "S072";
const S073: Thames21Foreshore = "S073";
const S074: Thames21Foreshore = "S074";
const S075: Thames21Foreshore = "S075";
const S076: Thames21Foreshore = "S076";
const S077: Thames21Foreshore = "S077";
const S078: Thames21Foreshore = "S078";
const S079: Thames21Foreshore = "S079";
const S080: Thames21Foreshore = "S080";
const S081: Thames21Foreshore = "S081";
const S082: Thames21Foreshore = "S082";
const S083: Thames21Foreshore = "S083";
const S084: Thames21Foreshore = "S084";
const S085: Thames21Foreshore = "S085";
const S086: Thames21Foreshore = "S086";
const S087: Thames21Foreshore = "S087";
const S088: Thames21Foreshore = "S088";
const S089: Thames21Foreshore = "S089";
const S090: Thames21Foreshore = "S090";
const S091: Thames21Foreshore = "S091";
const S092: Thames21Foreshore = "S092";
const S093: Thames21Foreshore = "S093";
const S094: Thames21Foreshore = "S094";
const S095: Thames21Foreshore = "S095";
const S096: Thames21Foreshore = "S096";
const S097: Thames21Foreshore = "S097";
const S098: Thames21Foreshore = "S098";
const S099: Thames21Foreshore = "S099";
const S100: Thames21Foreshore = "S100";
const S101: Thames21Foreshore = "S101";
const S102: Thames21Foreshore = "S102";
const S103: Thames21Foreshore = "S103";
const S104: Thames21Foreshore = "S104";
const S105: Thames21Foreshore = "S105";
const S106: Thames21Foreshore = "S106";
const S107: Thames21Foreshore = "S107";
const S108: Thames21Foreshore = "S108";
const S109: Thames21Foreshore = "S109";
const S110: Thames21Foreshore = "S110";
const S111: Thames21Foreshore = "S111";
const S112: Thames21Foreshore = "S112";
const S113: Thames21Foreshore = "S113";
const S114: Thames21Foreshore = "S114";
const S115: Thames21Foreshore = "S115";
const S116: Thames21Foreshore = "S116";
const S117: Thames21Foreshore = "S117";
const S118: Thames21Foreshore = "S118";
const S119: Thames21Foreshore = "S119";
const S120: Thames21Foreshore = "S120";
const S121: Thames21Foreshore = "S121";
const S122: Thames21Foreshore = "S122";
const S123: Thames21Foreshore = "S123";
const S124: Thames21Foreshore = "S124";
const S125: Thames21Foreshore = "S125";
const S126: Thames21Foreshore = "S126";
const S127: Thames21Foreshore = "S127";
const S128: Thames21Foreshore = "S128";
const S129: Thames21Foreshore = "S129";
const S130: Thames21Foreshore = "S130";
const S131: Thames21Foreshore = "S131";
const S132: Thames21Foreshore = "S132";
const S133: Thames21Foreshore = "S133";
const S134: Thames21Foreshore = "S134";
const S135: Thames21Foreshore = "S135";
const S136: Thames21Foreshore = "S136";
const S137: Thames21Foreshore = "S137";
const S138: Thames21Foreshore = "S138";
const S139: Thames21Foreshore = "S139";
const S140: Thames21Foreshore = "S140";
const S141: Thames21Foreshore = "S141";
const S142: Thames21Foreshore = "S142";
const S143: Thames21Foreshore = "S143";
const S144: Thames21Foreshore = "S144";
const S145: Thames21Foreshore = "S145";
const S146: Thames21Foreshore = "S146";
const S147: Thames21Foreshore = "S147";
const S148: Thames21Foreshore = "S148";
const S149: Thames21Foreshore = "S149";
const S150: Thames21Foreshore = "S150";
const S151: Thames21Foreshore = "S151";
const S152: Thames21Foreshore = "S152";
const S153: Thames21Foreshore = "S153";
const S154: Thames21Foreshore = "S154";
const S155: Thames21Foreshore = "S155";
const S156: Thames21Foreshore = "S156";
const S157: Thames21Foreshore = "S157";
const S158: Thames21Foreshore = "S158";
const S159: Thames21Foreshore = "S159";
const S160: Thames21Foreshore = "S160";
const S161: Thames21Foreshore = "S161";
const S162: Thames21Foreshore = "S162";
const S163: Thames21Foreshore = "S163";
const S164: Thames21Foreshore = "S164";
const S165: Thames21Foreshore = "S165";
const S166: Thames21Foreshore = "S166";
const S167: Thames21Foreshore = "S167";
const S168: Thames21Foreshore = "S168";
const S169: Thames21Foreshore = "S169";
const S170: Thames21Foreshore = "S170";
const S171: Thames21Foreshore = "S171";
const S172: Thames21Foreshore = "S172";
const S173: Thames21Foreshore = "S173";
const S174: Thames21Foreshore = "S174";
const S175: Thames21Foreshore = "S175";
const S176: Thames21Foreshore = "S176";
const S177: Thames21Foreshore = "S177";
const S178: Thames21Foreshore = "S178";
const S179: Thames21Foreshore = "S179";
const S180: Thames21Foreshore = "S180";
const S181: Thames21Foreshore = "S181";
const S182: Thames21Foreshore = "S182";
const S183: Thames21Foreshore = "S183";
const S184: Thames21Foreshore = "S184";
const S185: Thames21Foreshore = "S185";
const S186: Thames21Foreshore = "S186";
const S187: Thames21Foreshore = "S187";
const S188: Thames21Foreshore = "S188";
const S189: Thames21Foreshore = "S189";
const S190: Thames21Foreshore = "S190";
const S191: Thames21Foreshore = "S191";
const S192: Thames21Foreshore = "S192";
const S193: Thames21Foreshore = "S193";
const S194: Thames21Foreshore = "S194";
const S195: Thames21Foreshore = "S195";
const S196: Thames21Foreshore = "S196";
const S197: Thames21Foreshore = "S197";
const S198: Thames21Foreshore = "S198";
const S199: Thames21Foreshore = "S199";
const S200: Thames21Foreshore = "S200";
const S201: Thames21Foreshore = "S201";
const S202: Thames21Foreshore = "S202";
const S203: Thames21Foreshore = "S203";
const S204: Thames21Foreshore = "S204";
const S205: Thames21Foreshore = "S205";
const S206: Thames21Foreshore = "S206";
const S207: Thames21Foreshore = "S207";
const S208: Thames21Foreshore = "S208";
const S209: Thames21Foreshore = "S209";
const S210: Thames21Foreshore = "S210";
const S211: Thames21Foreshore = "S211";
const S212: Thames21Foreshore = "S212";
const S213: Thames21Foreshore = "S213";
const S214: Thames21Foreshore = "S214";
const S215: Thames21Foreshore = "S215";
const S216: Thames21Foreshore = "S216";
const S217: Thames21Foreshore = "S217";
const S218: Thames21Foreshore = "S218";
const S219: Thames21Foreshore = "S219";
const S220: Thames21Foreshore = "S220";
const S221: Thames21Foreshore = "S221";
const S222: Thames21Foreshore = "S222";
const S223: Thames21Foreshore = "S223";
const S224: Thames21Foreshore = "S224";
const S225: Thames21Foreshore = "S225";
const S226: Thames21Foreshore = "S226";
const S227: Thames21Foreshore = "S227";
const S228: Thames21Foreshore = "S228";
const S229: Thames21Foreshore = "S229";
const S230: Thames21Foreshore = "S230";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21ForeshoreEnum = {
  ...createEnum("Thames21Foreshore", [
    [N001, "Strawberry Vale Upper"],
    [N002, "Strawberry Vale Lower"],
    [N003, "Swan Island"],
    [N004, "Radnor Gardens"],
    [N005, "Radnor Gardens Lower"],
    [N006a, "Wharf Lane"],
    [N006b, "Eel Pie Island Upper Inner"],
    [N006c, "Eel Pie Island Upper Outer"],
    [N007a, "Water Lane"],
    [N007b, "Eel Pie Island Lower Inner"],
    [N007c, "Eel Pie Island Lower Outer"],
    [N008, "Orlean Gardens Lower"],
    [N009, "Orlean Gardens Upper"],
    [N010, "Hammerton Moorings"],
    [N011, "Warren Footpath"],
    [N012, "Meadowbank Club"],
    [N013, "Glovers Island Inner"],
    [N014, "Cambridge Park"],
    [N015, "Richmond Mansions"],
    [N016, "Clevedon Road"],
    [N017, "Richmond Bridge Drawdock"],
    [N018, "Beresford Avenue"],
    [N019, "Corporation Island"],
    [N020, "Twickenham Bridges"],
    [N021, "Ranelagh Drive"],
    [N022, "Richmond Lock Lower"],
    [N023, "Brunel University"],
    [N024a, "Isleworth Ait Inner Upper"],
    [N024b, "Nazereth House"],
    [N024c, "Isleworth Ait Outer Upper"],
    [N025a, "Church Street"],
    [N025b, "Isleworth Ait Inner Lower"],
    [N025c, "Isleworth Ait Outer Lower"],
    [N026, "Isleworth Drawdock"],
    [N027, "Syon Park Upper"],
    [N028, "Syon Park Middle Upper"],
    [N029, "Syon Park Middle"],
    [N030, "Syon Park Middle Lower"],
    [N031, "Syon Park Lower"],
    [N032, "Agustus Close"],
    [N033, "Brentford Marina"],
    [N034, "Ferry Lane and Goat Creek"],
    [N035a, "Waterman's Park Upper"],
    [N035b, "Brentford Ait Outer Upper"],
    [N036a, "Waterman's Park Lower"],
    [N036b, "Brentford Ait Inner"],
    [N036c, "Brentford Ait Outer"],
    [N037a, "Kew Bridge Upper"],
    [N037b, "Brentford Ait Lower"],
    [N038, "Kew Bridge Lower"],
    [N039, "Strand on the Green Upper"],
    [N040, "Strand on the Green Lower"],
    [N041, "Olivers Ait"],
    [N042, "Riverview Grove"],
    [N043, "Hartington Court"],
    [N044, "Boathouses Grove Park"],
    [N045, "Boathouses Thames Village"],
    [N046, "Boathouses Yacht Basin"],
    [N047, "Chiswick Bridge Slipway"],
    [N048, "Ibis Sports Ground"],
    [N049, "Allottment Gardens"],
    [N050, "Hounslow Rifle range"],
    [N051, "Hounslow Tennis Courts"],
    [N052, "Barnes Bridge"],
    [N053, "Hounslow Promenade Upper"],
    [N054, "Hounslow Promenade Lower"],
    [N055, "Riverside Recreation Ground"],
    [N056, "Chiswick Pier"],
    [N057, "Church Wharf"],
    [N058, "Chiswick Ferry Steps"],
    [N059a, "Chiswick Eyot Inner"],
    [N059b, "Chiswick Eyot Outer"],
    [N060, "Durham Wharf"],
    [N061, "Furnival Gardens"],
    [N062, "Hammersmith Pier"],
    [N063, "Mall Road"],
    [N064, "Hammersmith Bridge"],
    [N065, "Hammersmith & Fulham works"],
    [N066, "Thames Wharf Studios"],
    [N067, "Crabtree Wharf"],
    [N068, "Meadowbank Close"],
    [N069, "Eternit Walk"],
    [N070, "Fulham FC"],
    [N071, "Fulham Recreation"],
    [N072, "Bishops Park"],
    [N073, "Fulham Palace"],
    [N074, "All Saints Church"],
    [N075, "Putney Bridge"],
    [N076, "Hurlingham Court"],
    [N077, "Hurlingham Club Gardens"],
    [N078, "Hurlingham House"],
    [N079, "Broomhouse Slipway"],
    [N080, "Whiffin Wharf"],
    [N081, "Hurlingham Wharf"],
    [N082, "Wandsworth Bridge"],
    [N083, "Fulham Wharf"],
    [N084, "Regent on the river"],
    [N085, "Chelsea College"],
    [N086, "Imperial Road"],
    [N087, "Chelsea Harbour"],
    [N088, "Chelsea Creek"],
    [N089, "Chelsea Wharf"],
    [N090, "Chelsea Houseboats"],
    [N091, "Battersea Bridge"],
    [N092, "Cheyne Walk"],
    [N093, "Cadogan Pier"],
    [N094, "Physic Garden"],
    [N095, "Chelsea Embankment Gardens"],
    [N096, "Hospital Gardens"],
    [N097, "Chelsea Bridge and Grosvenor Dock"],
    [N098, "Grosvenor Bridge"],
    [N099, "Telford Terrace"],
    [N100, "Grosvenor Road"],
    [N101, "Dolphin Square"],
    [N102, "Westminster Boating Base"],
    [N103, "Lindsay Square"],
    [N104, "Vauxhall Bridge"],
    [N105, "Millbank Barracks"],
    [N106, "Millbank Towers"],
    [N107, "Victoria Tower Gardens"],
    [N108, "Victoria Tower Gardens"],
    [N109, "Houses of Parliament"],
    [N110, "Westminster Bridge"],
    [N111, "Victoria Embankment"],
    [N112, "Victoria Embankment"],
    [N113, "Hungerford Bridge"],
    [N114, "Waterloo Bridge"],
    [N115, "Temple Station"],
    [N116, "Temple Pier"],
    [N117, "Blackfriars Underpass"],
    [N118, "Blackfriars Bridge"],
    [N119, "City of London School"],
    [N120, "Brooks Wharf"],
    [N121, "Southwark Bridge"],
    [N122, "Watermans Walk"],
    [N123, "London Bridge"],
    [N124, "Old Billingsgate Walk"],
    [N125, "Tower Pier"],
    [N126, "Tower Bridge (Bascule)"],
    [N127, "St Katherine Dock"],
    [N128, "Tower Bridge Wharf"],
    [N129, "Wapping High Street"],
    [N130, "Wapping Pier Head"],
    [N131, "Wapping New Stairs"],
    [N132, "Wapping Pier"],
    [N133, "New Crane Wharf"],
    [N134, "Prospect of Whitby"],
    [N135, "Shadwell Dock Entrance"],
    [N136, "Free Trade Wharf Jetty"],
    [N137, "Ratcliffe Cross Stairs"],
    [N138, "Limehouse Basin Entrance"],
    [N139, "Blyth's Wharf"],
    [N140, "Limehouse Link"],
    [N141, "Westferry Circus"],
    [N142, "Cascades"],
    [N143, "Millenium Harbour"],
    [N144, "Tower Hamlets Southwark"],
    [N145, "St John McDougal Gardens"],
    [N146, "Arnhem Place"],
    [N147, "Crews Street"],
    [N148, "Mast House Terrace"],
    [N149, "Mast House Terrace"],
    [N150, "Great Eastern Pier"],
    [N151, "Pointers Close"],
    [N152, "Locke's Wharf"],
    [N153, "Ferry Street"],
    [N154, "Island Gardens"],
    [N155, "Saunders Ness Road"],
    [N156, "Empire Wharf Road"],
    [N157, "Cubitt Town Wharf"],
    [N158, "Millenium Drive"],
    [N159, "Amsterdam Road"],
    [N160, "Blackwall Reach"],
    [N161, "Manchester Road"],
    [N162, "Preston's Road"],
    [N163, "Northumberland Wharf"],
    [N164, "Blackwall Tunnel"],
    [N165, "Brunswick Wharf"],
    [N166, "Blackwall Pier"],
    [N167, "Bow Creek Wharf"],
    [N168, "Thames Wharf"],
    [N169, "London Airport Pier"],
    [N170, "Minerva Works"],
    [N171, "Plaistow Wharf"],
    [N172, "Manhatten Wharf (oil depot)"],
    [N173, "Lyle Park"],
    [N174, "Kierbeck Business Complex"],
    [N175, "Minoco Wharf (oil depot)"],
    [N176, "Silvertown"],
    [N177, "Thames barrier prospect park"],
    [N178, "Thames Road Industrial Park"],
    [N179, "Thamesside Industrial Estate"],
    [N180, "Tate & Lyle"],
    [N181, "North Woolwich"],
    [N182, "Standard Industrial Estate"],
    [N183, "Henley Road"],
    [N184, "Pier Road N Woolwich"],
    [N185, "Royal Victoria Gardens"],
    [N186, "Woolwich Manorway"],
    [N187, "Gallons Reach"],
    [N188, "Gallions Point"],
    [N189, "King George V Dock"],
    [N190, "Newham College"],
    [N191, "Gallions Reach"],
    [N192, "Radar Mast"],
    [N193, "Old Pier Remains"],
    [N194, "Old Pier Remains"],
    [N195, "Beckton Sewage Farm"],
    [N196, "Sludge Beds"],
    [N197, "Barking creek flood barrier"],
    [N198, "Barking & Dagenham"],
    [N199, "Gravel works"],
    [N200, "Barking or false point"],
    [N201, "Disused power stations"],
    [N202, "River Road"],
    [N203, "River Road"],
    [N204, "Barking & Dagenham"],
    [N205, "Barking & Dagenham"],
    [N206, "Barking & Dagenham Sluice"],
    [N207, "Horse Shoe Corner"],
    [N208, "Thunderer Road"],
    [N209, "Oil storage depot"],
    [N210, "Oil storage depot"],
    [N211, "Oil storage depot"],
    [N212, "Unallocated"],
    [N213, "Unallocated"],
    [N214, "Unallocated"],
    [N215, "Unallocated"],
    [N216, "Unallocated"],
    [N217, "Unallocated"],
    [N218, "Unallocated"],
    [N219, "Unallocated"],
    [N220, "Unallocated"],
    [N221, "Unallocated"],
    [N222, "Unallocated"],
    [N223, "Unallocated"],
    [N224, "Unallocated"],
    [N225, "Unallocated"],
    [N226, "Unallocated"],
    [N227, "Unallocated"],
    [N228, "Unallocated"],
    [N229, "Unallocated"],
    [N230, "Unallocated"],
    [N231, "Unallocated"],
    [N232, "Unallocated"],
    [N233, "Unallocated"],
    [N234, "Unallocated"],
    [N235, "Unallocated"],
    [N236, "Unallocated"],
    [N237, "Unallocated"],
    [N238, "Unallocated"],
    [N239, "Unallocated"],
    [N240, "Unallocated"],
    [N241, "Unallocated"],
    [N242, "Unallocated"],
    [N243, "Unallocated"],
    [N244, "Unallocated"],
    [N245, "Unallocated"],
    [N246, "Unallocated"],
    [N247, "Unallocated"],
    [N248, "Unallocated"],
    [N249, "Unallocated"],
    [N250, "Unallocated"],
    [N251, "Unallocated"],
    [S001, "Ham Riverside Lands"],
    [S002, "Ham Riverside Lands"],
    [S003, "Ham Riverside Lands"],
    [S004, "Twickenham Ham Riverside Lands"],
    [S005, "Ham Riverside Lands"],
    [S006a, "Ham Riverside Lands"],
    [S006b, "Eel Pie Island"],
    [S007a, "Ham Riverside Lands"],
    [S007b, "Eel Pie Island"],
    [S008, "Ham Street"],
    [S009, "Ham House"],
    [S010, "Polo Ground"],
    [S011, "Lodge Woods"],
    [S012, "River Lane"],
    [S013, "Glovers Island"],
    [S014, "Buccleeugh Gardens"],
    [S015, "Petersham Meadows"],
    [S016, "Richmond Landing Stage"],
    [S017, "Richmond Bridge"],
    [S018, "Cholmondeley Walk"],
    [S019, "Cholmondeley Walk"],
    [S020, "Old Deer Park"],
    [S021, "Richmond Lock"],
    [S022, "Old Deer Park"],
    [S023, "Kew Observatory"],
    [S024, "Kew Observatory"],
    [S025, "Kew Observatory"],
    [S026, "Obelisk"],
    [S027, "Golf Course"],
    [S028, "Queens Cottage Grounds"],
    [S029, "Isleworth Ferrry Gate"],
    [S030, "Mount Pleasant"],
    [S031, "Gardens Aboretum"],
    [S032, "River Side Avenue"],
    [S033, "Queen Elizabeth's Lawn"],
    [S034, "Kew Palace"],
    [S035, "Herbanium Library"],
    [S036, "Kreisel Walk"],
    [S037, "Kew Gardens Pier"],
    [S038, "Kew Toll House"],
    [S039, "Allotment Gardens & Pavilion"],
    [S040, "Public Records Office"],
    [S041, "Government Office"],
    [S042, "Sewage Works"],
    [S043, "Refuse Disposal Depot"],
    [S044, "Mortlake Anglian Boat Club"],
    [S045, "Chiswick Bridge"],
    [S046, "Ship Lane"],
    [S047, "Mortlake High Street"],
    [S048, "Ashleigh House"],
    [S049, "The Broadway"],
    [S050, "Barnes Bridge"],
    [S051, "Lonsdale Road"],
    [S052, "Tow Path"],
    [S053, "Leg of mutton nature reserve"],
    [S054, "Leg of mutton nature reserve"],
    [S055, "Leg of mutton nature reserve"],
    [S056, "Leg of mutton nature reserve"],
    [S057, "Swedish school playing fields"],
    [S058, "Games Courts"],
    [S059, "St Paul's School"],
    [S060, "St Paul's School"],
    [S061, "Glentham Gardens"],
    [S062, "Hammersmith Bridge"],
    [S063, "Riverview Gardens"],
    [S064, "Hilst Mansions"],
    [S065, "Barn Elms Water Works"],
    [S066, "Reservoir"],
    [S067, "Barn Elms Water Works"],
    [S068, "Barn Elms Schools Sports Centre"],
    [S069, "Barn Elms Schools Sports Centre"],
    [S070, "Festing Road Embankment"],
    [S071, "Glendarvon Street Embankment"],
    [S072, "Putney Pier"],
    [S073, "Putney Bridge"],
    [S074, "Deodar Road"],
    [S075, "Wandsworth Park"],
    [S076, "Wandsworth Park"],
    [S077, "Lightermans Walk"],
    [S078, "Feathers Wharf"],
    [S079, "Refuse Tip (Public)"],
    [S080, "Wandsworth Bridge"],
    [S081, "Mendip Road"],
    [S082, "Plantation Wharf"],
    [S083, "Bridges Wharf"],
    [S084, "Regent Wharf"],
    [S085, "Vicarage Crescent"],
    [S086, "Vicarage Walk"],
    [S087, "Whistlers Avenue"],
    [S088, "Thorney Crescent"],
    [S089, "Battersea Bridge"],
    [S090, "Albert Bridge"],
    [S091, "Battersea Park"],
    [S092, "Terrace Walk"],
    [S093, "Festival Pleasure Gardens"],
    [S094, "Carriage Drive North"],
    [S095, "Chelsea Bridge"],
    [S096, "Grosvenor Bridge"],
    [S097, "Battersea Power Station"],
    [S098, "Cringle Wharf"],
    [S099, "Nine Elms Lane"],
    [S100, "Bourne Valley Wharf"],
    [S101, "Nine Elms Lane"],
    [S102, "Vauxhall Bridge"],
    [S103, "Gunhouse stairs"],
    [S104, "Albert Embankment Gardens"],
    [S105, "Lambeth Bridge"],
    [S106, "St Thomas's Hospital"],
    [S107, "St Thomas's Hospital"],
    [S108, "Westminster Bridge"],
    [S109, "Kings Reach"],
    [S110, "Hungerford Footbridge"],
    [S111, "Waterloo Bridge"],
    [S112, "Kings Reach National Theatre"],
    [S113, "London Television Centre"],
    [S114, "Old Bargehouse Stairs"],
    [S115, "Blackfriars Road"],
    [S116, "Hopton Street"],
    [S117, "The Globe Theatre"],
    [S118, "Southwark Bridge"],
    [S119, "St Mary Overie,s Dock"],
    [S120, "London Bridge"],
    [S121, "LondonBridge City Pier"],
    [S122, "Symons Wharf"],
    [S123, "Tower Bridge"],
    [S124, "Butler's Wharf Pier"],
    [S125, "St Saviour's Dock"],
    [S126, "Bermondsey Wall West"],
    [S127, "Bermondsey Wall East"],
    [S128, "Cherry Garden Pier"],
    [S129, "Prince's Stairs"],
    [S130, "Cumberland Wharf"],
    [S131, "Surrey Water"],
    [S132, "Ballamy's Wharf"],
    [S133, "Lower Globe Wharf"],
    [S134, "Grand Surrey Wharf"],
    [S135, "Prince Regent Court"],
    [S136, "Sunderland Wharf"],
    [S137, "Nelson Docks"],
    [S138, "Lawrence Wharf"],
    [S139, "Trinity Wharf Business Centre"],
    [S140, "Barnard,s Wharf"],
    [S141, "Greenland Dock"],
    [S142, "St George's Wharf"],
    [S143, "Deptford Wharf"],
    [S144, "Greenwich Reach (Pepys Park)"],
    [S145, "Deptford Royal Naval Yard"],
    [S146, "Payne's Wharf"],
    [S147, "Deptfford Creek"],
    [S148, "Dreadnought Wharf"],
    [S149, "Greenwich Pier"],
    [S150, "Royal Naval College"],
    [S151, "Trinity Hospital (Almshouses)"],
    [S152, "Lovell's Wharf"],
    [S153, "Providence Warehouse"],
    [S154, "Blackwall Reach (Refineries)"],
    [S155, "Victoria Deep Water Terminal"],
    [S156, "Victoria Deep Water Terminal"],
    [S157, "Tunnel Avenue Trading Estate"],
    [S158, "Point Wharf"],
    [S159, "Millennium Dome"],
    [S160, "Blackwall Tunnel"],
    [S161, "Blackwall Point"],
    [S162, "Bugsby's Reach"],
    [S163, "North Greenwich Station"],
    [S164, "North Greenwich Station"],
    [S165, "River Way"],
    [S166, "Mudlarks Way"],
    [S167, "Christie's Wharf"],
    [S168, "Charlton Wharf"],
    [S169, "Anchor Hope Lane"],
    [S170, "Riverside Wharf"],
    [S171, "Thames Barrier"],
    [S172, "Thames Barrier Centre"],
    [S173, "Trinity Wharf"],
    [S174, "Bywater House"],
    [S175, "Tideside Court"],
    [S176, "Resolution Walk"],
    [S177, "St Marys & St Andrews Wharves"],
    [S178, "Woolwich Free Ferry"],
    [S179, "Bell Water Gate"],
    [S180, "Riverside Guard Houses"],
    [S181, "Marlborough Road"],
    [S182, "East Wharf"],
    [S183, "Ware Point Drive"],
    [S184, "Disused Tip (Gallions Reach)"],
    [S185, "Disused Tip (Gallions Reach)"],
    [S186, "Disused Tip (Gallions Reach)"],
    [S187, "Disused Tip (Gallions Reach)"],
    [S188, "Disused Tip (Gallions Reach)"],
    [S189, "Barking Reach Waste Land"],
    [S190, "Barking Reach Waste Land"],
    [S191, "Barking Reach Waste Land"],
    [S192, "Thamesmead Shopping Centre"],
    [S193, "Leisure Centre"],
    [S194, "Barking Reach Waste Land"],
    [S195, "Hoveton Road"],
    [S196, "Barking Reach Marlborough Court"],
    [S197, "Crossway Lake Nature Reserve"],
    [S198, "Crossway Lake Nature Reserve"],
    [S199, "Unallocated"],
    [S200, "Unallocated"],
    [S201, "Unallocated"],
    [S202, "Unallocated"],
    [S203, "Unallocated"],
    [S204, "Unallocated"],
    [S205, "Unallocated"],
    [S206, "Unallocated"],
    [S207, "Unallocated"],
    [S208, "Unallocated"],
    [S209, "Unallocated"],
    [S210, "Unallocated"],
    [S211, "Unallocated"],
    [S212, "Unallocated"],
    [S213, "Unallocated"],
    [S214, "Unallocated"],
    [S215, "Unallocated"],
    [S216, "Unallocated"],
    [S217, "Unallocated"],
    [S218, "Unallocated"],
    [S219, "Unallocated"],
    [S220, "Unallocated"],
    [S221, "Unallocated"],
    [S222, "Unallocated"],
    [S223, "Unallocated"],
    [S224, "Unallocated"],
    [S225, "Unallocated"],
    [S226, "Unallocated"],
    [S227, "Unallocated"],
    [S228, "Unallocated"],
    [S229, "Unallocated"],
    [S230, "Unallocated"],
  ]),
  N001,
  N002,
  N003,
  N004,
  N005,
  N006a,
  N006b,
  N006c,
  N007a,
  N007b,
  N007c,
  N008,
  N009,
  N010,
  N011,
  N012,
  N013,
  N014,
  N015,
  N016,
  N017,
  N018,
  N019,
  N020,
  N021,
  N022,
  N023,
  N024a,
  N024b,
  N024c,
  N025a,
  N025b,
  N025c,
  N026,
  N027,
  N028,
  N029,
  N030,
  N031,
  N032,
  N033,
  N034,
  N035a,
  N035b,
  N036a,
  N036b,
  N036c,
  N037a,
  N037b,
  N038,
  N039,
  N040,
  N041,
  N042,
  N043,
  N044,
  N045,
  N046,
  N047,
  N048,
  N049,
  N050,
  N051,
  N052,
  N053,
  N054,
  N055,
  N056,
  N057,
  N058,
  N059a,
  N059b,
  N060,
  N061,
  N062,
  N063,
  N064,
  N065,
  N066,
  N067,
  N068,
  N069,
  N070,
  N071,
  N072,
  N073,
  N074,
  N075,
  N076,
  N077,
  N078,
  N079,
  N080,
  N081,
  N082,
  N083,
  N084,
  N085,
  N086,
  N087,
  N088,
  N089,
  N090,
  N091,
  N092,
  N093,
  N094,
  N095,
  N096,
  N097,
  N098,
  N099,
  N100,
  N101,
  N102,
  N103,
  N104,
  N105,
  N106,
  N107,
  N108,
  N109,
  N110,
  N111,
  N112,
  N113,
  N114,
  N115,
  N116,
  N117,
  N118,
  N119,
  N120,
  N121,
  N122,
  N123,
  N124,
  N125,
  N126,
  N127,
  N128,
  N129,
  N130,
  N131,
  N132,
  N133,
  N134,
  N135,
  N136,
  N137,
  N138,
  N139,
  N140,
  N141,
  N142,
  N143,
  N144,
  N145,
  N146,
  N147,
  N148,
  N149,
  N150,
  N151,
  N152,
  N153,
  N154,
  N155,
  N156,
  N157,
  N158,
  N159,
  N160,
  N161,
  N162,
  N163,
  N164,
  N165,
  N166,
  N167,
  N168,
  N169,
  N170,
  N171,
  N172,
  N173,
  N174,
  N175,
  N176,
  N177,
  N178,
  N179,
  N180,
  N181,
  N182,
  N183,
  N184,
  N185,
  N186,
  N187,
  N188,
  N189,
  N190,
  N191,
  N192,
  N193,
  N194,
  N195,
  N196,
  N197,
  N198,
  N199,
  N200,
  N201,
  N202,
  N203,
  N204,
  N205,
  N206,
  N207,
  N208,
  N209,
  N210,
  N211,
  N212,
  N213,
  N214,
  N215,
  N216,
  N217,
  N218,
  N219,
  N220,
  N221,
  N222,
  N223,
  N224,
  N225,
  N226,
  N227,
  N228,
  N229,
  N230,
  N231,
  N232,
  N233,
  N234,
  N235,
  N236,
  N237,
  N238,
  N239,
  N240,
  N241,
  N242,
  N243,
  N244,
  N245,
  N246,
  N247,
  N248,
  N249,
  N250,
  N251,
  S001,
  S002,
  S003,
  S004,
  S005,
  S006a,
  S006b,
  S007a,
  S007b,
  S008,
  S009,
  S010,
  S011,
  S012,
  S013,
  S014,
  S015,
  S016,
  S017,
  S018,
  S019,
  S020,
  S021,
  S022,
  S023,
  S024,
  S025,
  S026,
  S027,
  S028,
  S029,
  S030,
  S031,
  S032,
  S033,
  S034,
  S035,
  S036,
  S037,
  S038,
  S039,
  S040,
  S041,
  S042,
  S043,
  S044,
  S045,
  S046,
  S047,
  S048,
  S049,
  S050,
  S051,
  S052,
  S053,
  S054,
  S055,
  S056,
  S057,
  S058,
  S059,
  S060,
  S061,
  S062,
  S063,
  S064,
  S065,
  S066,
  S067,
  S068,
  S069,
  S070,
  S071,
  S072,
  S073,
  S074,
  S075,
  S076,
  S077,
  S078,
  S079,
  S080,
  S081,
  S082,
  S083,
  S084,
  S085,
  S086,
  S087,
  S088,
  S089,
  S090,
  S091,
  S092,
  S093,
  S094,
  S095,
  S096,
  S097,
  S098,
  S099,
  S100,
  S101,
  S102,
  S103,
  S104,
  S105,
  S106,
  S107,
  S108,
  S109,
  S110,
  S111,
  S112,
  S113,
  S114,
  S115,
  S116,
  S117,
  S118,
  S119,
  S120,
  S121,
  S122,
  S123,
  S124,
  S125,
  S126,
  S127,
  S128,
  S129,
  S130,
  S131,
  S132,
  S133,
  S134,
  S135,
  S136,
  S137,
  S138,
  S139,
  S140,
  S141,
  S142,
  S143,
  S144,
  S145,
  S146,
  S147,
  S148,
  S149,
  S150,
  S151,
  S152,
  S153,
  S154,
  S155,
  S156,
  S157,
  S158,
  S159,
  S160,
  S161,
  S162,
  S163,
  S164,
  S165,
  S166,
  S167,
  S168,
  S169,
  S170,
  S171,
  S172,
  S173,
  S174,
  S175,
  S176,
  S177,
  S178,
  S179,
  S180,
  S181,
  S182,
  S183,
  S184,
  S185,
  S186,
  S187,
  S188,
  S189,
  S190,
  S191,
  S192,
  S193,
  S194,
  S195,
  S196,
  S197,
  S198,
  S199,
  S200,
  S201,
  S202,
  S203,
  S204,
  S205,
  S206,
  S207,
  S208,
  S209,
  S210,
  S211,
  S212,
  S213,
  S214,
  S215,
  S216,
  S217,
  S218,
  S219,
  S220,
  S221,
  S222,
  S223,
  S224,
  S225,
  S226,
  S227,
  S228,
  S229,
  S230,
};

export default Thames21ForeshoreEnum;
