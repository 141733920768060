import {
  attachmentAttr,
  attributeGroup,
  pointLayer,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { countedLitterAttr, polystyreneLitterAttr } from "./helpers";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const Thames21Litter = mapSchemaWithDefaults({
  mapId: unsafeMapId("thames21Litter"),
  title: "Litter",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("thames21Litter"),
      title: "Litter",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("thames21Litter"),
        attribution: [],
      }),
      defaultAttribute: "foodRelated",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("thames21Litter"),
            }),
            surveyAttr("surveyId", "Survey"),
            attachmentAttr("photographs", "Photographs"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Litter Collected",
          attributes: [
            countedLitterAttr("foodRelated", "Food Related"),
            countedLitterAttr("packaging", "Packaging"),
            countedLitterAttr("sewageRelated", "Sewage Related"),
            countedLitterAttr("unidentifiedPlastic", "Unidentified Plastic"),
            polystyreneLitterAttr(
              "unidentifiedPolystyrene",
              "Unidentified Polystyrene"
            ),
          ],
        }),
      ],
    }),
  ],
});
