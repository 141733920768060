// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessHigherPlantSpecies =
  | "WaterCrowfoots"
  | "GoldenSaxifrage";

const WaterCrowfoots: NeRiverNaturalnessHigherPlantSpecies = "WaterCrowfoots";
const GoldenSaxifrage: NeRiverNaturalnessHigherPlantSpecies = "GoldenSaxifrage";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessHigherPlantSpeciesEnum = {
  ...createEnum("NeRiverNaturalnessHigherPlantSpecies", [
    [WaterCrowfoots, "Water crowfoots (Ranunculus sp)"],
    [GoldenSaxifrage, "Golden saxifrage (Chrysosplenium sp)"],
  ]),
  WaterCrowfoots,
  GoldenSaxifrage,
};

export default NeRiverNaturalnessHigherPlantSpeciesEnum;
