// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesContinuity = "C" | "D";

const Continuous: CesContinuity = "C";
const Discontinuous: CesContinuity = "D";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesContinuityEnum = {
  ...createEnum("CesContinuity", [
    [Continuous, "Continuous"],
    [Discontinuous, "Discontinuous"],
  ]),
  Continuous,
  Discontinuous,
};

export default CesContinuityEnum;
