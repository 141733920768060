// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusConnectivity =
  | "footpathsLeftBank"
  | "footpathsRightBank"
  | "footpathsThroughArea"
  | "cyclePathsLeftBank"
  | "cyclePathsRightBank"
  | "cyclePathsThroughArea"
  | "wheelChairAccessLeftBank"
  | "wheelChairAccessRightBank"
  | "wheelChairAccessThroughArea"
  | "otherPathsLeftBank"
  | "otherPathsRightBank"
  | "otherPathsThroughArea"
  | "barriersToAccessLeftBank"
  | "barriersToAccessRightBank"
  | "barriersToAccessThroughArea"
  | "riverVisibilityLeftBank"
  | "riverVisibilityRightBank"
  | "urbanVisibilityLeftBank"
  | "urbanVisibilityRightBank";

const FootpathsLeftBank: EcostatusConnectivity = "footpathsLeftBank";
const FootpathsRightBank: EcostatusConnectivity = "footpathsRightBank";
const FootpathsThroughArea: EcostatusConnectivity = "footpathsThroughArea";
const CyclePathsLeftBank: EcostatusConnectivity = "cyclePathsLeftBank";
const CyclePathsRightBank: EcostatusConnectivity = "cyclePathsRightBank";
const CyclePathsThroughArea: EcostatusConnectivity = "cyclePathsThroughArea";
const WheelChairAccessLeftBank: EcostatusConnectivity =
  "wheelChairAccessLeftBank";
const WheelChairAccessRightBank: EcostatusConnectivity =
  "wheelChairAccessRightBank";
const WheelChairAccessThroughArea: EcostatusConnectivity =
  "wheelChairAccessThroughArea";
const OtherPathsLeftBank: EcostatusConnectivity = "otherPathsLeftBank";
const OtherPathsRightBank: EcostatusConnectivity = "otherPathsRightBank";
const OtherPathsThroughArea: EcostatusConnectivity = "otherPathsThroughArea";
const BarriersToAccessLeftBank: EcostatusConnectivity =
  "barriersToAccessLeftBank";
const BarriersToAccessRightBank: EcostatusConnectivity =
  "barriersToAccessRightBank";
const BarriersToAccessThroughArea: EcostatusConnectivity =
  "barriersToAccessThroughArea";
const RiverVisibilityLeftBank: EcostatusConnectivity =
  "riverVisibilityLeftBank";
const RiverVisibilityRightBank: EcostatusConnectivity =
  "riverVisibilityRightBank";
const UrbanVisibilityLeftBank: EcostatusConnectivity =
  "urbanVisibilityLeftBank";
const UrbanVisibilityRightBank: EcostatusConnectivity =
  "urbanVisibilityRightBank";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusConnectivityEnum = {
  ...createEnum("EcostatusConnectivity", [
    [FootpathsLeftBank, "Footpaths: Left bank"],
    [FootpathsRightBank, "Footpaths: Right bank"],
    [FootpathsThroughArea, "Footpaths: Through-area links"],
    [CyclePathsLeftBank, "Cycle paths: Left bank"],
    [CyclePathsRightBank, "Cycle paths: Right bank"],
    [CyclePathsThroughArea, "Cycle paths: Through-area links"],
    [WheelChairAccessLeftBank, "Wheel chair access: Left bank"],
    [WheelChairAccessRightBank, "Wheel chair access: Right bank"],
    [WheelChairAccessThroughArea, "Wheel chair access: Through-area links"],
    [OtherPathsLeftBank, "Other (bridleways, tracks): Left bank"],
    [OtherPathsRightBank, "Other (bridleways, tracks): Right bank"],
    [OtherPathsThroughArea, "Other (bridleways, tracks): Through-area links"],
    [
      BarriersToAccessLeftBank,
      "Barriers to access (surfaces, obstructions): Left bank",
    ],
    [
      BarriersToAccessRightBank,
      "Barriers to access (surfaces, obstructions): Right bank",
    ],
    [
      BarriersToAccessThroughArea,
      "Barriers to access (surfaces, obstructions): Through-area links",
    ],
    [RiverVisibilityLeftBank, "River visibility: From left bank"],
    [RiverVisibilityRightBank, "River visibility: From right bank"],
    [
      UrbanVisibilityLeftBank,
      "Urban/industrial/commercial land use visibility: From left bank",
    ],
    [
      UrbanVisibilityRightBank,
      "Urban/industrial/commercial land use visibility: From right bank",
    ],
  ]),
  FootpathsLeftBank,
  FootpathsRightBank,
  FootpathsThroughArea,
  CyclePathsLeftBank,
  CyclePathsRightBank,
  CyclePathsThroughArea,
  WheelChairAccessLeftBank,
  WheelChairAccessRightBank,
  WheelChairAccessThroughArea,
  OtherPathsLeftBank,
  OtherPathsRightBank,
  OtherPathsThroughArea,
  BarriersToAccessLeftBank,
  BarriersToAccessRightBank,
  BarriersToAccessThroughArea,
  RiverVisibilityLeftBank,
  RiverVisibilityRightBank,
  UrbanVisibilityLeftBank,
  UrbanVisibilityRightBank,
};

export default EcostatusConnectivityEnum;
