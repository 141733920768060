// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsChannelFeature =
  | "NO"
  | "NV"
  | "EB"
  | "RO"
  | "VR"
  | "MB"
  | "VB"
  | "MI"
  | "TR";

const None: UrsChannelFeature = "NO";
const NotVisible: UrsChannelFeature = "NV";
const ExposedBedrock: UrsChannelFeature = "EB";
const ExposedBoulders: UrsChannelFeature = "RO";
const VegetatedRock: UrsChannelFeature = "VR";
const UnvegetatedMidChannelBar: UrsChannelFeature = "MB";
const VegetatedMidChannelBar: UrsChannelFeature = "VB";
const MatureIsland: UrsChannelFeature = "MI";
const Trash: UrsChannelFeature = "TR";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsChannelFeatureEnum = {
  ...createEnum("UrsChannelFeature", [
    [None, "None"],
    [NotVisible, "Not Visible"],
    [ExposedBedrock, "Exposed Bedrock"],
    [ExposedBoulders, "Exposed Boulders"],
    [VegetatedRock, "Vegetated Rock"],
    [UnvegetatedMidChannelBar, "Unvegetated Mid-channel Bar"],
    [VegetatedMidChannelBar, "Vegetated Mid-channel Bar"],
    [MatureIsland, "Mature Island"],
    [Trash, "Trash"],
  ]),
  None,
  NotVisible,
  ExposedBedrock,
  ExposedBoulders,
  VegetatedRock,
  UnvegetatedMidChannelBar,
  VegetatedMidChannelBar,
  MatureIsland,
  Trash,
};

export default UrsChannelFeatureEnum;
