// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessInvertebrateSpecies = "WhiteClawedCrayfish";

const WhiteClawedCrayfish: NeLakeNaturalnessInvertebrateSpecies =
  "WhiteClawedCrayfish";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessInvertebrateSpeciesEnum = {
  ...createEnum("NeLakeNaturalnessInvertebrateSpecies", [
    [WhiteClawedCrayfish, "White-clawed crayfish (Austropotamobius pallipes)"],
  ]),
  WhiteClawedCrayfish,
};

export default NeLakeNaturalnessInvertebrateSpeciesEnum;
