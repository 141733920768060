import { raise } from "@cartographerio/util";
import {
  blockQuote,
  code,
  codeBlock,
  customBlock,
  customInline,
  document,
  emph,
  heading,
  htmlBlock,
  htmlInline,
  image,
  item,
  lineBreak,
  link,
  list,
  paragraph,
  softBreak,
  strong,
  text,
  thematicBreak,
  unsafePushChild,
} from ".";
import { Node, ContainerNode } from ".";
import { MarkdownHandlers } from "../core/handlers";

export function astHandlers(): MarkdownHandlers<Node> {
  const stack: ContainerNode[] = [];
  let ans: Node | undefined;

  function open(node: ContainerNode): void {
    if (stack.length > 0) {
      unsafePushChild(stack[stack.length - 1], node);
    }

    stack.push(node);
  }

  function close(): void {
    ans = stack.pop();
  }

  function atom(node: Node): void {
    if (stack.length > 0) {
      unsafePushChild(stack[stack.length - 1], node);
    }
  }

  return {
    text(str: string): void {
      atom(text(str));
    },
    softBreak(): void {
      atom(softBreak);
    },
    lineBreak(): void {
      atom(lineBreak);
    },
    htmlInline(html: string): void {
      atom(htmlInline(html));
    },
    code(text: string): void {
      atom(code(text));
    },
    thematicBreak(): void {
      atom(thematicBreak);
    },
    codeBlock(info: string | null, code: string): void {
      atom(codeBlock(info, code));
    },
    htmlBlock(html: string): void {
      atom(htmlBlock(html));
    },
    customInline(text: string): void {
      atom(customInline(text));
    },
    customBlock(text: string): void {
      atom(customBlock(text));
    },
    openEmph(): void {
      open(emph([]));
    },
    closeEmph(): void {
      close();
    },
    openStrong(): void {
      open(strong([]));
    },
    closeStrong(): void {
      close();
    },
    openLink(href: string): void {
      open(link(href, []));
    },
    closeLink(): void {
      close();
    },
    openImage(src: string): void {
      open(image(src, []));
    },
    closeImage(): void {
      close();
    },
    openDocument(): void {
      open(document([]));
    },
    closeDocument(): void {
      close();
    },
    openParagraph(): void {
      open(paragraph([]));
    },
    closeParagraph(): void {
      close();
    },
    openBlockQuote(): void {
      open(blockQuote([]));
    },
    closeBlockQuote(): void {
      close();
    },
    openItem(): void {
      open(item([]));
    },
    closeItem(): void {
      close();
    },
    openList(
      type: "bullet" | "ordered",
      delimiter: ")" | "." | null,
      start: number | null,
      tight: boolean
    ): void {
      open(list(type, delimiter, start, tight, []));
    },
    closeList(): void {
      close();
    },
    openHeading(level: number): void {
      open(heading(level, []));
    },
    closeHeading(): void {
      close();
    },
    result(): Node {
      return ans == null ? raise(new Error("Badness")) : ans;
    },
  };
}
