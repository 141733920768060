import {
  attachmentAttr,
  attributeGroup,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

const marker1 = marker.fromColor("#891200");
const marker2 = marker.fromColor("#c62c2c");
const marker3 = marker.fromColor("#d57025");
const marker4 = marker.fromColor("#e5aa16");
const marker5 = marker.fromColor("#8dba38");

export const StSmartRivers = mapSchemaWithDefaults({
  mapId: unsafeMapId("StSmartRivers"),
  title: "SmartRivers",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("StSmartRivers"),
      title: "SmartRivers",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("StSmartRivers"),
        attribution: [],
      }),
      defaultAttribute: "psi",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Time and Location",
          attributes: [
            stringAttr({
              attributeId: "catchment",
              label: "Catchment",
              showDistribution: false,
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "river",
              label: "River",
              showDistribution: false,
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "site",
              label: "Site",
              showDistribution: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "trpiRiverType",
              label: "TRPI River Type",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            stringAttr({
              label: "Season",
              attributeId: "season",
              buckets: [
                lookupBucket("Spring", marker.fromColor("lime")),
                lookupBucket("Autumn", marker.fromColor("orange")),
              ],
            }),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("StSmartRivers"),
            }),
            surveyAttr("surveyId", "Survey"),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
        attributeGroup({
          label: "Calculations",
          attributes: [
            numberAttr({
              attributeId: "psi",
              label: "PSI (Sedimentation Pressure)",
              buckets: [
                rangeBucket(0, 20, marker1, "Heavily Sedimented"),
                rangeBucket(21, 40, marker2, "Sedimented"),
                rangeBucket(41, 60, marker3, "Moderately Sedimented"),
                rangeBucket(61, 80, marker4, "Slightly Sedimented"),
                rangeBucket(81, 100, marker5, "Naturally/Unsedimented"),
              ],
            }),
            numberAttr({
              attributeId: "trpi",
              label: "TRPI (Phosphorus Enrichment Pressure)",
              buckets: [
                rangeBucket(0, 20, marker1, "Very high"),
                rangeBucket(21, 40, marker2, "High"),
                rangeBucket(41, 60, marker3, "Moderate"),
                rangeBucket(61, 80, marker4, "Low"),
                rangeBucket(81, 100, marker5, "Very low"),
              ],
            }),
            numberAttr({
              attributeId: "spear",
              label: "SPEAR (Chemical Pressure)",
              buckets: [
                rangeBucket(0, 11, marker1, "Bad (Heavily Impacted)"),
                rangeBucket(11, 22, marker2, "Poor"),
                rangeBucket(22, 33, marker3, "Moderate"),
                rangeBucket(33, 44, marker4, "Good"),
                rangeBucket(44, 100, marker5, "High (Unimpacted)"),
              ],
            }),
            numberAttr({
              attributeId: "si",
              label: "Saprobic (Organic Enrichment Pressure)",
              buckets: [
                rangeBucket(0, 1.8, marker5, "Minimal Organic Loading"),
                rangeBucket(1.8, 2.3, marker4, "Mild Organic Loading"),
                rangeBucket(2.3, 2.7, marker3, "Moderate Organic Loading"),
                rangeBucket(2.7, 3.2, marker2, "Strong Organic Loading"),
                rangeBucket(3.2, 4.0, marker1, "Extreme Organic Loading"),
              ],
            }),
            numberAttr({
              attributeId: "life",
              label: "LIFE (Flow Related Pressure)",
              buckets: [
                rangeBucket(null, 6, marker1, "Heavily Impacted"),
                rangeBucket(6, 6.5, marker2, "Impacted"),
                rangeBucket(6.5, 7, marker3, "Moderately Impacted"),
                rangeBucket(7, 8, marker4, "Slightly Impacted"),
                rangeBucket(8, null, marker5, "Unimpacted"),
              ],
            }),
            numberAttr({
              attributeId: "bmwp",
              label: "BMWP",
              buckets: [
                rangeBucket(0, 13, marker5, "Heavily Impacted"),
                rangeBucket(13, 36, marker4, "Impacted"),
                rangeBucket(36, 51, marker3, "Moderately Impacted"),
                rangeBucket(51, 71, marker2, "Slightly Impacted"),
                rangeBucket(71, null, marker1, "Unimpacted"),
              ],
            }),
            numberAttr({
              attributeId: "cci",
              label: "CCI",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "aspt",
              label: "ASPT",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "ntaxa",
              label: "NTAXA",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "eptSp",
              label: "EPT sp (taxa)",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "eptAb",
              label: "EPT ab",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "totalAb",
              label: "TOTAL ab",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "totalSpecies",
              label: "TOTAL sp",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "whpt",
              label: "WHPT",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "whptAspt",
              label: "WHPT ASPT",
              showDistribution: false,
              showTimeline: false,
              buckets: "auto",
            }),
          ],
        }),
      ],
    }),
  ],
});
