// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsBankProfile =
  | "V"
  | "Vo"
  | "Vu"
  | "Vt"
  | "St"
  | "Gt"
  | "Cm"
  | "Rs"
  | "Ts"
  | "Em"
  | "Sm"
  | "Pc";

const Vertical: MrsBankProfile = "V";
const VerticalTopOverhang: MrsBankProfile = "Vo";
const VerticalWithUndercut: MrsBankProfile = "Vu";
const VerticalWithToe: MrsBankProfile = "Vt";
const Steep: MrsBankProfile = "St";
const Gentle: MrsBankProfile = "Gt";
const Composite: MrsBankProfile = "Cm";
const Resectioned: MrsBankProfile = "Rs";
const ArtificialTwoStage: MrsBankProfile = "Ts";
const Embanked: MrsBankProfile = "Em";
const SetBackEmbankment: MrsBankProfile = "Sm";
const Poached: MrsBankProfile = "Pc";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsBankProfileEnum = {
  ...createEnum("MrsBankProfile", [
    [Vertical, "Vertical"],
    [VerticalTopOverhang, "Vertical with top overhang"],
    [VerticalWithUndercut, "Undercut or vertical with undercut"],
    [VerticalWithToe, "Vertical with toe"],
    [Steep, "Steep (> 45 degrees)"],
    [Gentle, "Gentle (< 45 degrees)"],
    [Composite, "Composite"],
    [Resectioned, "Obviously reshaped"],
    [ArtificialTwoStage, "Artificial two-stage"],
    [Embanked, "Embanked"],
    [SetBackEmbankment, "Set-back embankment"],
    [Poached, "Poached"],
  ]),
  Vertical,
  VerticalTopOverhang,
  VerticalWithUndercut,
  VerticalWithToe,
  Steep,
  Gentle,
  Composite,
  Resectioned,
  ArtificialTwoStage,
  Embanked,
  SetBackEmbankment,
  Poached,
};

export default MrsBankProfileEnum;
