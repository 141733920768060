// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusConditionAndUse = "none" | "1" | "2" | "3" | "4" | "5";

const NotPresent: EcostatusConditionAndUse = "none";
const UsedAndCaredFor: EcostatusConditionAndUse = "1";
const IntensiveUseAndNeedingAttention: EcostatusConditionAndUse = "2";
const ModerateUseAndNeedingAttention: EcostatusConditionAndUse = "3";
const MisusedOrVandalised: EcostatusConditionAndUse = "4";
const NotUsed: EcostatusConditionAndUse = "5";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusConditionAndUseEnum = {
  ...createEnum("EcostatusConditionAndUse", [
    [NotPresent, "Not present"],
    [UsedAndCaredFor, "Used and cared for"],
    [IntensiveUseAndNeedingAttention, "Intensive use and needing attention"],
    [ModerateUseAndNeedingAttention, "Moderate use and needing attention"],
    [MisusedOrVandalised, "Misused or vandalised"],
    [NotUsed, "Not used"],
  ]),
  NotPresent,
  UsedAndCaredFor,
  IntensiveUseAndNeedingAttention,
  ModerateUseAndNeedingAttention,
  MisusedOrVandalised,
  NotUsed,
};

export default EcostatusConditionAndUseEnum;
