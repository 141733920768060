import { PropsOf, chakra } from "@chakra-ui/react";
import { ForwardedRef, ReactElement, forwardRef } from "react";

export type ParaProps = PropsOf<typeof chakra.p>;

function Para(props: ParaProps, ref: ForwardedRef<HTMLElement>): ReactElement {
  const { children, ...rest } = props;

  return (
    <chakra.p ref={ref} {...rest}>
      {children}
    </chakra.p>
  );
}

export default forwardRef(Para);
