import {
  topoExpr,
  TopoExpr,
  attachmentField,
  bounds,
  customRule,
  form,
  helpText,
  integerField,
  minValue,
  multiSelect,
  otherSpecify,
  page,
  pointField,
  required,
  section,
  select,
  SelectValue,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import { isArray, isNullable, isNumber } from "@cartographerio/guard";
import {
  SelectOption,
  UclPitStopAccessedByEnum,
  UclPitStopAccessEnum,
  UclPitStopAquaticFaunaEnum,
  UclPitStopAquaticPlantsEnum,
  UclPitStopBufferCompositionEnum,
  UclPitStopBufferCoverEnum,
  UclPitStopBufferInvasiveEnum,
  UclPitStopCrassulaHelmsiiEnum,
  UclPitStopDescriptionEnum,
  UclPitStopDogsEnum,
  UclPitStopEcologicalHealthEnum,
  UclPitStopEdgeFeatureEnum,
  UclPitStopEdgeGradientEnum,
  UclPitStopFishKnowledgeEnum,
  UclPitStopFishStockingEnum,
  UclPitStopFoundByEnum,
  UclPitStopGameBirdFeedingEnum,
  UclPitStopHabitatAvailabilityEnum,
  UclPitStopHabitatEnum,
  UclPitStopHistoricalKnowledgeEnum,
  UclPitStopInflowSourceEnum,
  UclPitStopInOrOutflowEnum,
  UclPitStopInvertebrateEnum,
  UclPitStopLandUseEnum,
  UclPitStopLitterEnum,
  UclPitStopMapNeighbourEnum,
  UclPitStopMapPointEnum,
  UclPitStopNorfolkMostWantedEnum,
  UclPitStopPoachingEnum,
  UclPitStopPondCountEnum,
  UclPitStopPondStatusEnum,
  UclPitStopRecreationalFishingEnum,
  UclPitStopSettingEnum,
  UclPitStopSubstrateDepthEnum,
  UclPitStopSurfaceFeatureEnum,
  UclPitStopTreeSpeciesEnum,
  UclPitStopWaterColorEnum,
  UclPitStopWaterfowlEnum,
  UclPitStopWaterLevelEnum,
} from "@cartographerio/inventory-enums";
import { checkExhausted, Enum } from "@cartographerio/util";
import { outdent } from "outdent";

const notGhostPond: TopoExpr<boolean> = topoExpr(env =>
  env
    .getAs(
      ["data", "visit", "status", "selected"],
      UclPitStopPondStatusEnum.isValue
    )
    .map(value => value !== UclPitStopPondStatusEnum.Ghost)
    .getOrElse(() => false)
);

const nearestTenPercent = customRule({
  level: "info",
  message: "Enter a number to the nearest 10%.",
  triggerWhen: topoExpr(env =>
    env
      .getFocusedAs(isNullable(isNumber))
      .map(num => num != null && num % 10 !== 0)
      .getOrElse(() => false)
  ),
});

const imageRoot = "https://media.cartographer.io/static/images/ucl-pit-stop";

export function enumImages<A extends SelectValue>(
  source: Enum<A> | SelectOption<A>[],
  image: string | undefined
): SelectOption<A>[] {
  const entries = isArray(source) ? source : source.entries;

  const isPattern = (image: string | undefined): image is string =>
    image != null && image.indexOf("*") >= 0;

  return isPattern(image)
    ? entries.map(option => {
        const path = image.replace("*", `${option.value}`).toLowerCase();

        return {
          ...option,
          image: `${imageRoot}/${path}`,
        };
      })
    : entries;
}

export default form({
  title: "Project Pit Stop - Norfolk Coast AONB",
  pages: [
    page({
      title: null,
      path: [],
      help: outdent`
      Thank you for joining the Project Pit Stop team
      and volunteering your time towards pond conservation
      in the Norfolk Coast AONB.

      This project is based on an initial map produced by Helene Burningham
      from data collected by Emily Alderton, both at UCL.
      You can adopt a pond to survey from the
      [Project Pit Stop](https://sites.google.com/view/projectpitstop/home) web site
      as a starting point or just enter data as you spot ponds in the field.

      When you've filled out your field survey,
      don't forget to complete the historical survey on the
      [Project Pit Stop](https://sites.google.com/view/projectpitstop/home) site if you wish.
      If we work together, can we record and map all the ponds in the Norfolk Coast AONB?
      `,
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date/Time",
              path: ["data", "recorded"],
              required: required("info", "Please select a date and time."),
            }),
            pointField({
              label: "Pond Location",
              path: ["data", "location"],
              required: required(
                "info",
                "Select a location from the map or by entering a grid reference or GPS location."
              ),
            }),
            select({
              label: "How was the pond found?",
              path: ["data", "preamble", "foundBy"],
              options: UclPitStopFoundByEnum.entries,
              required: required("info", "You should select an option."),
            }),
            integerField({
              label: "Project Pit Stop Pond Number",
              path: ["data", "preamble", "pitStopPondNumber"],
              help: "See the [Project Pit Stop](https://sites.google.com/view/projectpitstop/home) web site for more information.",
              required: required(
                "info",
                "If relevant you should specify your adopted pond number from the Project Pit Stop web site."
              ),
            }),
            select({
              label: "How was the pond accessed?",
              path: ["data", "preamble", "accessedBy"],
              options: UclPitStopAccessedByEnum.entries,
              required: required("info"),
            }),
          ],
        }),
        section({
          title: "Pre-Survey - Pond Representation on Maps",
          path: [],
          help: outdent`
          Questions to look at before going out.

          To access an Ordnance Survey map use [OS Maps](https://osmaps.com)
          or [Streetmap](https://www.streetmap.co.uk).
          It is best to Zoom in to the 1:25000 map,
          which is specified as you change the scales in the bottom right of Streetmap.
          `,
          blocks: [
            select({
              label: "What is Seen On the OS Map?",
              path: ["data", "mapStudy", "point", "selected"],
              help: "On the coordinates where this pond is on a current 1:25000 scale OS map, what is shown at this point?",
              options: UclPitStopMapPointEnum.entries.map(entry => {
                switch (entry.value) {
                  case "Water":
                    return { ...entry, label: "Water (blue on map)" };
                  case "Vegetation":
                    return { ...entry, label: "Vegetation (green on map)" };
                  case "Other":
                    return { ...entry, label: "Other" };
                  default:
                    return checkExhausted(entry.value);
                }
              }),
              required: required("info", "You should select an option."),
            }),
            otherSpecify({
              label: "If 'Other' for OS Map",
              basePath: ["data", "mapStudy", "point"],
              test: value => value === UclPitStopMapPointEnum.Other,
            }),
            multiSelect({
              label: "Freshwater Bodies within 1km",
              path: ["data", "mapStudy", "neighbours", "selected"],
              help: "Are there any freshwater body types (within 1 km) of your pond? (tick all that apply)",
              options: UclPitStopMapNeighbourEnum.entries,
              required: required("info"),
            }),
            otherSpecify({
              label: "Other Freshwater Bodies within 1km",
              basePath: ["data", "mapStudy", "neighbours"],
              test: value =>
                isArray(value) &&
                value.includes(UclPitStopMapNeighbourEnum.Other),
            }),
            integerField({
              label: "Ponds within 1km",
              path: ["data", "mapStudy", "pondCount"],
              help: "Estimate the number of ponds within a 1km radius of your pond on OS Maps (use blue water bodies only)",
              required: required("info"),
            }),
          ],
        }),
        section({
          title: "Local Knowledge",
          path: [],
          help: "Sometimes the landowner or local people know some history about the pond. We do not, however, expect answers to all of these questions.",
          blocks: [
            select({
              label: "Description of Pond",
              path: ["data", "knowledge", "description", "selected"],
              help: "What type of pond is the pond best described as?",
              options: UclPitStopDescriptionEnum.entries,
              required: required("info", "You should select a pond type."),
            }),
            otherSpecify({
              label: "Other Description of Pond",
              basePath: ["data", "knowledge", "description"],
              test: value => value === UclPitStopDescriptionEnum.Other,
            }),
            textField({
              label: "Local Name",
              path: ["data", "knowledge", "localName"],
              help: "If the pond or field/location in which it sits has a name locally, please enter it here.",
              required: required("info"),
            }),
            textArea({
              label: "Local Name Detail",
              path: ["data", "knowledge", "localNameDetail"],
              help: "Please clearly define which the above name refers to if something has been added. Also please use this space  to add any additional local information or history that you would like to share.",
              required: required("info"),
            }),
            select({
              label: "Historical Knowledge of Pond Permanence",
              path: ["data", "knowledge", "historical"],
              help: "How often does the pond dry?",
              options: UclPitStopHistoricalKnowledgeEnum.entries,
              required: required("info", "You should select an option."),
            }),
            select({
              label: "Local Knowledge of Fish Presence",
              path: ["data", "knowledge", "fish"],
              help: "Is there any local knowledge of fish?",
              options: UclPitStopFishKnowledgeEnum.entries,
              required: required("info", "You should select an option."),
            }),
          ],
        }),
        section({
          title: "About the Pond - Your Visit",
          path: [],
          help: "Now you are out on your pond visit we are going to ask you to make a number of observations about the pond and its surrounding landscape. One of the key bits of data will be to take photos of the pond as we can gain a lot of information from photos. During your visit, we will ask you to take some specific photos, but you will also be able to upload one photo of your choice so photograph anything of interest.",
          blocks: [
            attachmentField({
              label: "Arrival Photograph",
              path: ["data", "visit", "arrivalPhotographs"],
              help: "As you approach your ponds, take a photo of the WHOLE of the pond from as close as you can. Try to get the whole of the pond in or as much as possible.",
              maxFiles: 1,
            }),
            select({
              label: "Project Pit Stop Pond Status",
              path: ["data", "visit", "status", "selected"],
              help: "Using our simple terms for pond status for the project please select the best description of the pond.",
              image: `${imageRoot}/pond-status-other.jpg`,
              options: enumImages(
                UclPitStopPondStatusEnum,
                "pond-status-*.jpg"
              ),
              required: required("info"),
            }),
            otherSpecify({
              label: "Other Pond Status",
              basePath: ["data", "visit", "status"],
              test: value => value === UclPitStopPondStatusEnum.Other,
            }),
            select({
              label: "Number of Ponds within 20m",
              path: ["data", "visit", "pondCount"],
              help: "Now you are here, you may notice that there are other ponds near by. How many additional ponds are within 20 metres of the pond you are surveying?",
              options: UclPitStopPondCountEnum.entries,
              required: required("info"),
            }),
            helpText(
              "⚠️ **Approach the pond all the way to the pond basin perimeter** so you have a good view of the pond. Take additional care when doing so as the edges of ponds can be very uneven ground and even areas that don't look wet can sink under your feet!"
            ),
          ],
        }),
        section({
          title: "Pond Permanence",
          path: [],
          visible: notGhostPond,
          help: outdent`
          We are now going to ask you some questions
          about the amount of water in the pond.
          This will help us understand the amount of water
          that is available in the landscape,
          and compare it at different times times of the year.
          To do this we will look for three features;
          the **pond basin perimeter** which is the point at the edge of the pond
          in which the pond basin starts to become lower in height
          in comparison to the surrounding land,
          the **high water mark/vegetation line**,
          which is the highest point the water level usually gets to in a pond in winter
          (you may see a water line on trees in overgrown ponds
          or an obvious line of wetland plants or land plants on open ponds)
          and the **current water level** (where the water is today).
          Please note the area covered by water today
          as we will ask you to pace out the length
          and width of this before you leave.

          ![Features Diagram](${imageRoot}/permanence-features.jpg)
          `,
          blocks: [
            multiSelect({
              label: "Pond Edge Features",
              path: ["data", "permanence", "edgeFeatures"],
              help: "Tick if you can confidently identify the above pond edge features on this particular pond (tick all that apply).",
              options: UclPitStopEdgeFeatureEnum.entries,
            }),
            select({
              label: "Water Level",
              path: ["data", "permanence", "waterLevel"],
              help: "How would you describe the water level in comparison to the high water mark (vegetation line) and pond basin perimeter at present?",
              options: UclPitStopWaterLevelEnum.entries,
            }),
            integerField({
              label: "Water Coverage",
              path: ["data", "permanence", "waterCoverage"],
              units: "%",
              help: outdent`
              What is the proportion of area of water
              currently covering the pond basin
              compared to the high water mark/vegetation line
              (estimate to the closest % range).
              If you cannot see a high water mark / vegetation line,
              compare to the pond basin perimeter.

              ![Water Coverage](${imageRoot}/water-coverage.jpg)
              `,
              customRules: [nearestTenPercent],
            }),
            multiSelect({
              label: "Perimeter Gradient",
              path: ["data", "permanence", "perimeterGradient"],
              help: "The steepness of the pond basin perimeter down to the current water affects access to the pond. What types of edges are currently present? (please tick ALL that apply).",
              options: enumImages(
                UclPitStopEdgeGradientEnum.entries,
                "perimeter-gradient-*.jpg"
              ),
            }),
            integerField({
              label: "Canopy Cover of Water Surface",
              path: ["data", "permanence", "areaShade"],
              help: outdent`
              Look at the tree canopy above the pond (if not in leaf, imagine it is).
              Estimate the % area shade for the pond (to the nearest 10%):

              ![Pond Area Cover Percentages](${imageRoot}/area-shade.jpg)
              `,
              units: "%",
              bounds: bounds(0, 100),
              customRules: [nearestTenPercent],
            }),
            integerField({
              label: "Canopy Cover of Basin Perimeter",
              path: ["data", "permanence", "perimeterShade"],
              help: outdent`
              Estimate the % shade for the pond basin perimeter (to the nearest 10%).

              ![Pond Perimeter Cover Percentages](${imageRoot}/perimeter-shade.jpg)
              `,
              units: "%",
              bounds: bounds(0, 100),
              customRules: [nearestTenPercent],
            }),
            attachmentField({
              label: "Canopy Photograph",
              path: ["data", "permanence", "canopyPhotographs"],
              help: "Take a photo of the tree canopy above the pond. Try to photograph the tree canopy directly over the pond from where you are standing at the perimeter.",
              maxFiles: 1,
            }),
          ],
        }),
        section({
          title: "Pond Water Quality",
          path: [],
          visible: notGhostPond,
          help: "Now we want you to consider the quality of the pond water. Poor water quality can affect the physical and chemical conditions of a pond and restrict the species of plants and animals that can live there.",
          blocks: [
            select({
              label: "Inflow Presence",
              path: ["data", "waterQuality", "inflow"],
              help: outdent`
              Are there any pipes or ditches acting as inflow into the pond?

              ⚠️ It is often difficult to see inflows and outflows around ponds, particularly those that are overgrown.
              Prioritise staying safe and if nothing is obvious there is no need to search.
              `,
              options: UclPitStopInOrOutflowEnum.entries,
            }),
            select({
              label: "Inflow Source",
              path: ["data", "waterQuality", "inflowSource", "selected"],
              help: "Where does this inflow come from?",
              options: UclPitStopInflowSourceEnum.entries,
            }),
            otherSpecify({
              label: "Other Inflow Source",
              basePath: ["data", "waterQuality", "inflowSource"],
              test: value => value === UclPitStopInflowSourceEnum.Other,
            }),
            select({
              label: "Outflow Presence",
              path: ["data", "waterQuality", "outflow"],
              help: outdent`
              Are there any pipes or ditches acting as outflow from the pond?

              ⚠️ It is often difficult to see inflows and outflows around ponds, particularly those that are overgrown. Prioritise staying safe and if nothing is obvious there is no need to search.
              `,
              options: UclPitStopInOrOutflowEnum.entries,
            }),
            textField({
              label: "Outflow Destination",
              path: ["data", "waterQuality", "outflowDestination"],
              help: "If you can see evidence of an outflow, where or what does it attach to?",
            }),
            select({
              label: "Water Colour",
              path: ["data", "waterQuality", "waterColor", "selected"],
              help: "Estimate the water colour.",
              options: UclPitStopWaterColorEnum.entries,
            }),
            otherSpecify({
              label: "Other Water Colour",
              basePath: ["data", "waterQuality", "waterColor"],
              test: value => value === UclPitStopWaterColorEnum.Other,
            }),
            select({
              label: "Depth of Mud/Substrate (Optional)",
              path: ["data", "waterQuality", "substrateDepth"],
              help: outdent`
              If you have a walking pole handy and you feel it is safe to do so,
              estimate the depth of mud / substrate 50cm (an arm's length) from the edge of the pond
              by pushing the pole into the mud / substrate.

              ⚠️ This should only be undertaken where safe to do so
              and may be better in the summer months when there is less water in the pond.
              Always put your own health and safety as a priority.
              `,
              options: UclPitStopSubstrateDepthEnum.entries,
            }),
            multiSelect({
              label: "Surface Features",
              path: ["data", "waterQuality", "surfaceFeatures"],
              help: outdent`
              Are there any of the following on the water surface? (select all that apply)

              ![Clockwise from top left: full duckweed coverage, floating algae, litter / dumped waste, and oily sheen.](${imageRoot}/surface-features.jpg)
              `,
              options: enumImages(
                UclPitStopSurfaceFeatureEnum.entries,
                "surface-features-*.jpg"
              ),
            }),
          ],
        }),
        section({
          title: "Impacts on the Pond Through Use",
          path: [],
          visible: notGhostPond,
          help: "We will now ask you to look for any impacts on the pond through human use. Ponds can have many different uses. High use for some recreational activities may impact ponds as habitat for other types of wildlife.",
          blocks: [
            select({
              label: "Evidence of Fish Stocking",
              path: ["data", "impacts", "fishStocking"],
              help: "Can you see any presence of fish stocking today?",
              options: UclPitStopFishStockingEnum.entries,
            }),
            select({
              label: "Evidence of Poaching",
              path: ["data", "impacts", "poaching"],
              help: "Can you see any evidence of poaching of the pond edges by animals such as cattle?",
              options: UclPitStopPoachingEnum.entries,
            }),
            select({
              label: "Evidence of Waterfowl",
              path: ["data", "impacts", "waterfowl"],
              help: "Can you see any evidence of waterfowl on the pond during your visit? Please be aware of any waterfowl flying off as you approach.",
              options: UclPitStopWaterfowlEnum.entries,
            }),
            select({
              label: "Evidence of Game Bird Feeding",
              path: ["data", "impacts", "gameBirdFeeding"],
              help: "Can you see any evidence of feeding game birds around the pond today (e.g. drum feeders)?",
              options: UclPitStopGameBirdFeedingEnum.entries,
            }),
            select({
              label: "Evidence of Dogs in the Pond",
              path: ["data", "impacts", "dogs"],
              help: "Can you see any evidence of dogs entering the pond?",
              options: UclPitStopDogsEnum.entries,
            }),
            select({
              label: "Evidence of Recreational Fishing",
              path: ["data", "impacts", "recreationalFishing"],
              help: "Can you see any evidence of recreational fishing today?",
              options: UclPitStopRecreationalFishingEnum.entries,
            }),
            select({
              label: "Fly-Tipping/Litter",
              path: ["data", "impacts", "litter"],
              help: "If present, can you estimate the impact of fly-tipping/litter?",
              options: UclPitStopLitterEnum.entries,
            }),
            textArea({
              label: "Other Impacts",
              path: ["data", "impacts", "other"],
              help: "Can you see any evidence of any other activity that may impact the pond (e.g. swimming, use for irrigation)?",
              rows: 5,
            }),
          ],
        }),
        section({
          title: "Pond Wildlife",
          path: [],
          visible: notGhostPond,
          help: "We are now going to ask you look for any wildlife on the pond. Plants and animals will be more visible and easier to identify in late spring or summer so do not worry if you cannot answer these questions.",
          blocks: [
            multiSelect({
              label: "Aquatic Plants",
              path: ["data", "wildlife", "aquaticPlants"],
              help: outdent`
              During your visit what aquatic plants can you see evidence of? (Select all that apply).

              ![Clockwise from top left: plants submerged below the surface of the water, plants emerging from the water, and plants with floating leaves.](${imageRoot}/aquatic-plants.jpg)
              `,
              options: UclPitStopAquaticPlantsEnum.entries,
            }),
            integerField({
              label: "Aquatic Plant Cover",
              path: ["data", "wildlife", "aquaticPlantCover"],
              help: outdent`
              If you can see any of the aquatic plants in the wet area of the pond
              please provide an estimate of percentage cover to the nearest 10%

              ![Pond Area Cover Percentages](${imageRoot}/area-shade.jpg)
              `,
              units: "%",
              bounds: bounds(0, 100),
              customRules: [nearestTenPercent],
            }),
            attachmentField({
              label: "Pond Surface Photograph",
              path: ["data", "wildlife", "aquaticPlantPhotographs"],
              help: "Take a close up photo of the pond surface. If present, focus on the aquatic plants in the pond.",
              maxFiles: 1,
            }),
            multiSelect({
              label: "Aquatic Fauna",
              path: ["data", "wildlife", "aquaticFauna", "selected"],
              help: outdent`
              During your visit what aquatic fauna can you see evidence of? (Select all that apply)

              ![Clockwise from top left: damselfly, dragonfly, Crucian Carp, and frog](${imageRoot}/aquatic-fauna.jpg)
              `,
              options: UclPitStopAquaticFaunaEnum.entries,
            }),
            otherSpecify({
              label: "Other Aquatic Fauna",
              basePath: ["data", "wildlife", "aquaticFauna"],
              test: value =>
                isArray(value) &&
                value.includes(UclPitStopAquaticFaunaEnum.Other),
            }),
            multiSelect({
              label: "Insects/Invertebrates",
              path: ["data", "wildlife", "invertebrates"],
              help: "Can you see any insects/invertebrates moving under the water surface? (please tick all that apply)",
              options: UclPitStopInvertebrateEnum.entries,
            }),
            multiSelect({
              label: "Norfolk Most Wanted",
              path: ["data", "wildlife", "norfolkMostWanted"],
              help: outdent`
              Did you spot any of the 'Norfolk Most Wanted' species at the pond?

              Further information and ID help can be found at [https://norfolkponds.org/norfolks-most-wanted](https://norfolkponds.org/norfolks-most-wanted).
              `,
              options: UclPitStopNorfolkMostWantedEnum.entries,
            }),
            select({
              label: "Crassula helmsii",
              path: ["data", "wildlife", "crassula"],
              help: outdent`
              Is the non-native invasive species *Crassula helmsii* present in the pond:

              More plant identification help can be found on [NI Direct](https://www.nidirect.gov.uk/articles/invasive-non-native-aquatic-plants).

              ![Crassula helmsii](${imageRoot}/crassula.jpg)
              `,
              options: UclPitStopCrassulaHelmsiiEnum.entries,
            }),
            textField({
              label: "Other Invasive Species",
              path: ["data", "wildlife", "otherInvasives"],
              help: "Please note any other non-native invasive species known to be at the pond",
            }),
            select({
              label: "Ecological Health",
              path: ["data", "wildlife", "ecologicalHealth"],
              help: "Estimating pond ecological health (pond quality)",
              options: UclPitStopEcologicalHealthEnum.entries,
            }),
            multiSelect({
              label: "Tree Species",
              path: ["data", "wildlife", "treeSpecies", "selected"],
              help: outdent`
              Can you recognise any of the following tree species within 5m of the pond (please tick all that apply)?

              You can find a link to identifying trees from
              [The Conservation Volunteers](https://treegrowing.tcv.org.uk/identify?gclid=Cj0KCQiA_bieBhDSARIsADU4zLc_nw0QWO6dm9bbqkhlRsb0f-a4V0AfhBmyMHrv4NcZ4jAm_sHYik0aAu61EALw_wcB).
              `,
              options: UclPitStopTreeSpeciesEnum.entries,
            }),
            otherSpecify({
              label: "Other Tree Species",
              basePath: ["data", "wildlife", "treeSpecies"],
              test: value =>
                isArray(value) &&
                value.includes(UclPitStopTreeSpeciesEnum.Other),
            }),
            select({
              label: "Dominant Tree Species",
              path: ["data", "wildlife", "dominantTreeSpecies", "selected"],
              help: outdent`
              Of the tree species present which one is dominant around the pond?

              You can find a link to identifying trees from
              [The Conservation Volunteers](https://treegrowing.tcv.org.uk/identify?gclid=Cj0KCQiA_bieBhDSARIsADU4zLc_nw0QWO6dm9bbqkhlRsb0f-a4V0AfhBmyMHrv4NcZ4jAm_sHYik0aAu61EALw_wcB).
              `,
              options: UclPitStopTreeSpeciesEnum.entries,
            }),
            otherSpecify({
              label: "Other Dominant Tree Species",
              basePath: ["data", "wildlife", "dominantTreeSpecies"],
              test: value => value === UclPitStopTreeSpeciesEnum.Other,
            }),
          ],
        }),
        section({
          title: "Your Pond in the Landscape",
          path: [],
          help: "Walk out from the pond lip away from the pond. As you do so you may notice a margin of more natural vegetation on the perimeter the pond before you hit the next main surrounding landuse (e.g. crops or amenity grassland). This is what we refer to as a 'buffer' as it can protect the pond from inputs from surrounding land uses. Other natural features may exist too - e.g. the pond sits on a hedgerow. Natural land features create connectivity in the landscape allowing wildlife to easily move from pond to pond.",
          blocks: [
            select({
              label: "Natural Margin (Buffer)",
              path: ["data", "landscape", "bufferCover"],
              help: "To the nearest number, what percentage of the pond perimeter is surrounded by a natural margin (buffer)?",
              options: UclPitStopBufferCoverEnum.entries,
            }),
            multiSelect({
              label: "Natural Margin (Buffer) Composition",
              path: ["data", "landscape", "bufferComposition"],
              help: outdent`
              What is this buffer made up of? (tick all that apply)

              ![Clockwise from top left: long grass / wildflowers, trees, brambles / scrub, and short grass](${imageRoot}/buffer-composition.jpg)
              `,
              options: UclPitStopBufferCompositionEnum.entries,
            }),
            integerField({
              label: "Natural Margin (Buffer) Widest Depth",
              path: ["data", "landscape", "bufferWidestDepth"],
              help: "Estimate the depth of this buffer at its widest point in metres.",
              units: "m",
              bounds: minValue(0),
            }),
            integerField({
              label: "Natural Margin (Buffer) Narrowest Depth",
              path: ["data", "landscape", "bufferNarrowestDepth"],
              help: "Estimate the depth of this buffer at its narrowest point in metres.",
              units: "m",
              bounds: minValue(0),
            }),
            multiSelect({
              label: "Nettles / Himalayan Balsam",
              path: ["data", "landscape", "invasives"],
              help: outdent`
              Are nettles or himalayan balsam present?

              ![Left: Nettles; Right: Himalayan Balsam](${imageRoot}/invasives.jpg)
              `,
              options: UclPitStopBufferInvasiveEnum.entries,
            }),
            select({
              label: "Dominant Land Use",
              path: ["data", "landscape", "landUse", "selected"],
              help: "After any buffer what is the main land use in the immediate surroundings to the pond?",
              options: UclPitStopLandUseEnum.entries,
            }),
            otherSpecify({
              label: "Other Land Use",
              basePath: ["data", "landscape", "landUse"],
              test: value => value === UclPitStopLandUseEnum.Other,
            }),
            multiSelect({
              label: "Adjacent Access",
              path: ["data", "landscape", "access"],
              help: "Is the pond within 10m of any of the following (please tick all that apply):",
              options: UclPitStopAccessEnum.entries,
            }),
            select({
              label: "Pond Setting",
              path: ["data", "landscape", "setting"],
              help: "Where does your pond sit within its field or setting?",
              image: `${imageRoot}/setting-high.jpg`,
              options: enumImages(
                UclPitStopSettingEnum.entries,
                "setting-*.jpg"
              ),
            }),
            multiSelect({
              label: "Adjacent Habitats",
              path: ["data", "landscape", "habitat", "selected"],
              help: "Other than the buffer, which natural habitats are adjacent to the pond creating connectivity in the landscape (please tick all that apply)?",
              options: UclPitStopHabitatEnum.entries,
            }),
            otherSpecify({
              label: "Other Adjacent Habitat",
              basePath: ["data", "landscape", "habitat"],
              test: value =>
                isArray(value) && value.includes(UclPitStopHabitatEnum.Other),
            }),
            select({
              label: "Availability of Habitat to Wildlife",
              path: ["data", "landscape", "habitatAvailability"],
              help: "Your estimate of surrounding habitat availability for wildlife",
              options: UclPitStopHabitatAvailabilityEnum.entries,
            }),
            integerField({
              label: "Length of Wet Area",
              path: ["data", "landscape", "wetAreaLength"],
              help: "Finally, estimate the length of the currently wet area of the pond in metres",
              units: "m",
              bounds: minValue(0),
            }),
            integerField({
              label: "Width of Wet Area",
              path: ["data", "landscape", "wetAreaWidth"],
              help: "Finally, estimate the width of the currently wet area of the pond in metres",
              units: "m",
              bounds: minValue(0),
            }),
          ],
        }),
        section({
          title: "After Your Visit",
          path: [],
          blocks: [
            attachmentField({
              label: "Departure Photograph",
              path: ["data", "visit", "departurePhotographs"],
              help: "As you leave the site, take a photo of the pond at a distance so you can get the whole of the surrounding trees, plants, and any buffer in.",
              maxFiles: 1,
            }),
            attachmentField({
              label: "Additional Photograph",
              path: ["data", "visit", "additionalPhotographs"],
              help: "Upload one further photo of interest. This could be of an inflow, activities impacting the ponds, plants or wildlife of interest or a consent form.",
              maxFiles: 1,
            }),
            textArea({
              label: "Description of Additional Photograph",
              path: ["data", "visit", "additionalPhotographsDescription"],
              help: "Describe what is pictured in the photograph.",
            }),
            textArea({
              label: "Additional Observations",
              path: ["data", "visit", "additionalObservations"],
              help: "Please add any additional observations you have made at the pond today that you wish to share (e.g. wildlife seen; pond condition; conversations with landowners / public).",
            }),
          ],
        }),
      ],
    }),
  ],
});
