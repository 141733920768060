// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverTypesConfidence = "H" | "M" | "L";

const High: NeRiverTypesConfidence = "H";
const Medium: NeRiverTypesConfidence = "M";
const Low: NeRiverTypesConfidence = "L";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverTypesConfidenceEnum = {
  ...createEnum("NeRiverTypesConfidence", [
    [High, "High"],
    [Medium, "Medium"],
    [Low, "Low"],
  ]),
  High,
  Medium,
  Low,
};

export default NeRiverTypesConfidenceEnum;
