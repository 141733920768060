import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  pointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  StdInvasiveSpecies,
  StdInvasiveSpeciesEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  activityType?: StdInvasiveSpecies | null;
  siteName?: string | null;
}

function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(
      data,
      "species",
      isNullable(StdInvasiveSpeciesEnum.isValue)
    ) &&
    hasOptionalKey(data, "siteName", isNullable(isString))
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialStdInvasiveSpecies"
  )(data).map(data =>
    [
      data.activityType == null
        ? "Unknown Species"
        : StdInvasiveSpeciesEnum.labelOf(data.activityType),
      data.location == null ? "Unknown Location" : pointToNgr(data.location),
    ].join(" at ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialStdInvasiveSpecies"
  )(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialStdInvasiveSpecies"
  )(data).map(data => data.recorded ?? null);
}
