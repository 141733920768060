// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopFishKnowledge =
  | "Suggested"
  | "Small"
  | "Good"
  | "Past"
  | "None";

const Suggested: UclPitStopFishKnowledge = "Suggested";
const Small: UclPitStopFishKnowledge = "Small";
const Good: UclPitStopFishKnowledge = "Good";
const Past: UclPitStopFishKnowledge = "Past";
const None: UclPitStopFishKnowledge = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopFishKnowledgeEnum = {
  ...createEnum("UclPitStopFishKnowledge", [
    [
      Suggested,
      "No evidence of fish, but local conditions suggest that they may be present",
    ],
    [Small, "Small numbers of fish known to be present"],
    [Good, "Good population of fish known to be present"],
    [Past, "Fish known to have been present in the past"],
    [None, "No local knowledge ascertained"],
  ]),
  Suggested,
  Small,
  Good,
  Past,
  None,
};

export default UclPitStopFishKnowledgeEnum;
