import {
  AttributeGroup,
  attributeGroup,
  Attribution,
  Buckets,
  featureAttr,
  lineLayer,
  localGeojsonSource,
  lookupBucket,
  marker,
  markerMode,
  markerStyle,
  numberAttr,
  pointLayer,
  polygonLayer,
  remoteGeojsonSource,
  standardAttribution,
  standardBuckets,
  stringAttr,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  convertGeometry,
  featureCollection,
  osgb36ToWgs84,
  point,
} from "@cartographerio/geometry";
import { Feature } from "geojson";
import { cartographerSourceWithDefaults } from "../../core";

const priorityHabitatColor = "#00ccff";

function naturalnessBuckets(category: string): Buckets<number> {
  return standardBuckets([
    lookupBucket(1, marker.quality(0.7), `1 (high ${category} naturalness)`),
    lookupBucket(2, marker.quality(0.6), "2"),
    lookupBucket(3, marker.quality(0.5), "3"),
    lookupBucket(4, marker.quality(0.4), "4"),
    lookupBucket(
      5,
      marker.quality(0.3),
      `5 (very low ${category} naturalness!)`
    ),
  ]);
}

const naturalnessAttributes: AttributeGroup = attributeGroup({
  label: "Naturalness Components",
  attributes: [
    numberAttr({
      attributeId: "physical",
      label: "Physical",
      buckets: naturalnessBuckets("physical"),
    }),
    numberAttr({
      attributeId: "hydrological",
      label: "Hydrological",
      buckets: naturalnessBuckets("hydrological"),
    }),
    numberAttr({
      attributeId: "chemical",
      label: "Chemical",
      buckets: naturalnessBuckets("chemical"),
    }),
    numberAttr({
      attributeId: "biological",
      label: "Biological",
      buckets: naturalnessBuckets("biological"),
    }),
  ],
});

export const neLakeNaturalnessSource = cartographerSourceWithDefaults({
  layerId: unsafeMapLayerId("neLakeNaturalness"),
  simplifyZoom: 10,
  attribution: [],
});

export const neRiverNaturalnessSource = cartographerSourceWithDefaults({
  layerId: unsafeMapLayerId("neRiverNaturalness"),
  simplifyZoom: 10,
  attribution: [],
});

export const neRiverNaturalnessLayer = lineLayer({
  layerId: unsafeMapLayerId("neRiverNaturalness"),
  title: "New River Naturalness Data",
  source: neRiverNaturalnessSource,
  defaultAttribute: "physical",
  attributes: [
    attributeGroup({
      label: "Location",
      attributes: [
        stringAttr({
          attributeId: "reachName",
          label: "Watercourse name",
          buckets: "auto",
          showDistribution: false,
        }),
      ],
    }),
    naturalnessAttributes,
  ],
});

export const neLakeNaturalnessLayer = polygonLayer({
  layerId: unsafeMapLayerId("neLakeNaturalness"),
  title: "New Lake Naturalness Data",
  source: neLakeNaturalnessSource,
  defaultAttribute: "physical",
  attributes: [
    attributeGroup({
      label: "Location",
      attributes: [
        stringAttr({
          attributeId: "lakeName",
          label: "Lake name",
          buckets: "auto",
          showDistribution: false,
        }),
      ],
    }),
    naturalnessAttributes,
  ],
});

export const drnAttribution: Attribution = standardAttribution({
  what: "DRN River Outlines",
  who: "Environment Agency",
  from: "2013",
  url: "https://data.gov.uk/dataset/14f5ab89-3b46-41e3-854b-ca0392136b82/detailed-river-network-nodes-afa036",
});

export const priorityRiverHabitatLayer = lineLayer({
  layerId: unsafeMapLayerId("PriorityRiverHabitat"),
  title: "Official Priority River Habitat Map",
  source: remoteGeojsonSource({
    url: "https://opendata.arcgis.com/datasets/7e5dd3c72f424fd5bc6f013d18dd770c_0.geojson",
    attribution: [drnAttribution],
  }),
  attributes: [
    attributeGroup({
      attributes: [
        featureAttr({
          attributeId: "overall",
          label: "Official Priority River Habitat Map",
          marker: marker.fromColor(priorityHabitatColor),
        }),
      ],
    }),
  ],
  primaryKey: "drn_id",
});

function lakeFeature(
  easting: number,
  northing: number,
  name: string,
  waterbodyId: number
): Feature {
  return {
    type: "Feature",
    geometry: convertGeometry(point(easting, northing), osgb36ToWgs84),
    properties: { name, waterbodyId },
  };
}

export const priorityLakeHabitatLayer = pointLayer({
  layerId: unsafeMapLayerId("PriorityLakeHabitat"),
  title: "Official Priority Lake Habitat Map",
  source: localGeojsonSource({
    data: featureCollection({
      features: [
        lakeFeature(389331, 604639, "Linshiels Lake", 27556),
        lakeFeature(627971, 327104, "Captains Pond", 35397),
        lakeFeature(450318, 483202, "Gormire Lake", 29545),
        lakeFeature(360522, 558710, "Tindale Tarn", 28395),
        lakeFeature(389320, 466746, "Malham Tarn", 29844),
        lakeFeature(379042, 569740, "Broomlee Lough", 28172),
        lakeFeature(347788, 476625, "Hawes Water, Silverdale", 29647),
        lakeFeature(479326, 132385, "Cranmer Pond", 44464),
        lakeFeature(329132, 514093, "Blea Tarn", 29097),
        lakeFeature(334879, 512052, "Grisedale Tarn", 29129),
        lakeFeature(334808, 515262, "Red Tarn, Helvellyn", 29083),
        lakeFeature(315778, 496966, "Devoke Water", 29338),
        lakeFeature(318258, 515771, "Buttermere", 29052),
        lakeFeature(330917, 503218, "Little Langdale Tarn", 29231),
        lakeFeature(340275, 512729, "Brothers Water", 29116),
        lakeFeature(318382, 504378, "Burnmoor Tarn", 29215),
        lakeFeature(604074, 287550, "Kenninghall Fen", 37648),
        lakeFeature(334322, 435237, "Marton Mere", 30553),
        lakeFeature(553324, 379220, "Sea Bank Clay Pits", 32880),
        lakeFeature(636646, 318884, "Turf Fen or 'Reedham Water'", 35847),
        lakeFeature(588712, 296038, "West Mere", 37248),
        lakeFeature(322788, 509115, "Sprinkling Tarn", 29163),
        lakeFeature(333654, 531352, "Bowscale Tarn", 28854),
        lakeFeature(342534, 520438, "Ullswater", 28955),
        lakeFeature(638966, 313419, "Upton Broad", 36202),
        lakeFeature(333051, 500005, "Tarn Hows", 29275),
        lakeFeature(315798, 518895, "Crummock Water", 29000),
        lakeFeature(
          419481,
          562825,
          "Sibdon Pond Nature Reserve, Blaydon",
          28314
        ),
        lakeFeature(344861, 510746, "Blea Water", 29146),
        lakeFeature(432851, 548645, "Joes Pond", 28629),
        lakeFeature(632901, 305702, "Wheatfen Broad", 36722),
        lakeFeature(636191, 305540, "Buckenham Broad", 36732),
        lakeFeature(618691, 159919, "Fordwich lakes", 43164),
      ],
    }),
    attribution: [
      standardAttribution({
        what: "Lake Outlines",
        who: "UK Lakes Portal",
        url: "https://eip.ceh.ac.uk/apps/lakes/",
      }),
    ],
  }),
  attributes: [
    attributeGroup({
      attributes: [
        featureAttr({
          attributeId: "overall",
          label: "Official Priority Lake Habitat Map",
          marker: markerStyle({
            normal: markerMode({
              size: 1,
              fillColor: "#ffffff",
              strokeColor: "#0088dd",
              strokeWidth: 1,
            }),
            selected: markerMode({
              size: 1.5,
              fillColor: "#ffffff",
              strokeColor: "#0088dd",
              strokeWidth: 2,
            }),
          }),
        }),
      ],
    }),
  ],
  primaryKey: "waterbodyId",
});
