import { unsafeMapId } from "@cartographerio/topo-map";
import { unsafeProjectAlias } from "@cartographerio/types";
import { mapSchemaWithDefaults } from "../../core";
import {
  extendedRiverflySiltAndFlowLayer,
  extendedRiverflyWaterQualityLayer,
} from "./helpers";

export const ExtendedRiverflyWaterQuality = mapSchemaWithDefaults({
  mapId: unsafeMapId("extendedRiverflyWaterQuality"),
  title: "Extended Riverfly Water Quality",
  layers: [
    extendedRiverflyWaterQualityLayer(
      unsafeProjectAlias("extendedriverfly"),
      "Data from Extended Riverfly Surveys"
    ),
  ],
});

export const ExtendedRiverflySiltAndFlow = mapSchemaWithDefaults({
  mapId: unsafeMapId("extendedRiverflySiltAndFlow"),
  title: "Extended Riverfly Silt and Flow",
  layers: [
    extendedRiverflySiltAndFlowLayer(
      unsafeProjectAlias("extendedriverfly"),
      "Data from Extended Riverfly Surveys"
    ),
  ],
});
