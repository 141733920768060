import { checks, permissionCheckPasses } from "@cartographerio/permission";
import { errorMessage } from "@cartographerio/topo-core";
import { roleGte, teamMemberRole } from "@cartographerio/types";
import { getSurveyorUser } from "../helpers";
import { RuleBuilder } from "./types";

/**
 * Enforces that team approvers can only select surveyors from their own teams
 */
export const teamMateRule: RuleBuilder = ({ identity, graph, project }) => {
  // Teams the current user is an approver in:
  const relevantTeams = graph
    .findTeamsByProjectId(project.id)
    .filter(team =>
      identity.roles.some(role => roleGte(role, teamMemberRole(team.id)))
    );

  // Rule is only relevant if the current user is a team approver but not a project approver (or workspace admin etc):
  const ruleApplies =
    relevantTeams.length > 0 &&
    !permissionCheckPasses(
      checks.survey.createWithTeam(project, null),
      {
        id: identity.userId,
        roles: identity.roles,
        qualificationRoles: identity.qualificationRoles,
      },
      graph
    );

  return survey => {
    const { surveyor } = survey;

    if (!ruleApplies) {
      return [];
    }

    const surveyorUser = getSurveyorUser(graph, surveyor);

    if (surveyorUser == null) {
      return [];
    }

    const passes = surveyorUser.roles.some(role =>
      relevantTeams.some(team => roleGte(role, teamMemberRole(team.id)))
    );

    if (passes) {
      return [];
    }

    return [errorMessage("The selected user is not a member of your team(s)")];
  };
};
