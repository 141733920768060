import {
  attributeGroup,
  lookupBucket,
  marker as m,
  numberAttr,
  pointLayer,
  rangeBucket as rng,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

const phosphorousBuckets = [
  rng(null, 0.004, m.numeric(11 / 11, 120)),
  rng(0.004, 0.005, m.numeric(10 / 11, 120)),
  rng(0.005, 0.0075, m.numeric(9 / 11, 120)),
  rng(0.0075, 0.01, m.numeric(8 / 11, 120)),
  rng(0.01, 0.02, m.numeric(7 / 11, 120)),
  rng(0.02, 0.03, m.numeric(6 / 11, 120)),
  rng(0.03, 0.04, m.numeric(5 / 11, 120)),
  rng(0.04, 0.05, m.numeric(4 / 11, 120)),
  rng(0.05, 0.1, m.numeric(3 / 11, 120)),
  rng(0.1, 0.2, m.numeric(2 / 11, 120)),
  rng(0.2, 0.3, m.numeric(1 / 11, 120)),
  rng(0.3, null, m.numeric(0 / 11, 120)),
];

const nitrateBuckets = [
  rng(null, 0.02, m.numeric(11 / 11, 200)),
  rng(0.02, 0.1, m.numeric(10 / 11, 200)),
  rng(0.1, 0.2, m.numeric(9 / 11, 200)),
  rng(0.2, 0.3, m.numeric(8 / 11, 200)),
  rng(0.3, 0.4, m.numeric(7 / 11, 200)),
  rng(0.4, 0.5, m.numeric(6 / 11, 200)),
  rng(0.5, 0.75, m.numeric(5 / 11, 200)),
  rng(0.75, 1.0, m.numeric(4 / 11, 200)),
  rng(1.0, 1.5, m.numeric(3 / 11, 200)),
  rng(1.5, 2.0, m.numeric(2 / 11, 200)),
  rng(2.0, null, m.numeric(1 / 11, 200)),
];

export const FbaWindermere = mapSchemaWithDefaults({
  mapId: unsafeMapId("FbaWindermere"),
  title: "Windermere",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("FbaWindermere"),
      title: "Windermere",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("FbaWindermere"),
        attribution: [],
      }),
      defaultAttribute: "temperature",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("FbaWindermere"),
            }),
            surveyAttr("surveyId", "Survey"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Site",
          attributes: [
            stringAttr({
              attributeId: "siteName",
              label: "Name",
              buckets: "auto",
            }),
            // stringAttr({ attributeId: "siteDescription", label: "Description", buckets: "auto", }),
            stringAttr({
              attributeId: "lakeOrRiver",
              label: "Lake/River",
              buckets: [
                lookupBucket("Lake", m.fromColor("#27b6f3")),
                lookupBucket("River", m.fromColor("#27bf57")),
              ],
            }),
          ],
        }),
        attributeGroup({
          label: "Measured Parameters",
          attributes: [
            numberAttr({
              attributeId: "temperature",
              label: "Temperature",
              unit: "ºC",
              buckets: [
                rng(null, 0, m.fromColor("#2afffe")),
                rng(0, 5, m.fromColor("#26c576")),
                rng(5, 10, m.fromColor("#7ac420")),
                rng(10, 15, m.fromColor("#fffd2d")),
                rng(15, 20, m.fromColor("#fd8b1f")),
                rng(20, 25, m.fromColor("#fc5619")),
                rng(25, null, m.fromColor("#ea2f00")),
              ],
              decimalPlaces: 0,
            }),
            numberAttr({
              attributeId: "electricalConductivity",
              label: "Electrical Conductivity",
              unit: "µS/cm",
              buckets: [
                rng(null, 25, m.mix("#c28000", "#ffff00", 1 - 25 / 250)),
                rng(25, 50, m.mix("#c28000", "#ffff00", 1 - 50 / 250)),
                rng(50, 75, m.mix("#c28000", "#ffff00", 1 - 75 / 250)),
                rng(75, 100, m.mix("#c28000", "#ffff00", 1 - 100 / 250)),
                rng(100, 125, m.mix("#c28000", "#ffff00", 1 - 125 / 250)),
                rng(125, 150, m.mix("#c28000", "#ffff00", 1 - 150 / 250)),
                rng(150, 200, m.mix("#c28000", "#ffff00", 1 - 200 / 250)),
                rng(200, null, m.mix("#c28000", "#ffff00", 1 - 250 / 250)),
              ],
              decimalPlaces: 0,
            }),
            numberAttr({
              attributeId: "ph",
              label: "pH",
              buckets: [
                rng(null, 5, m.mix("#eef400", "#197fea", 0 / 11)),
                rng(5.0, 5.5, m.mix("#eef400", "#197fea", 1 / 11)),
                rng(5.5, 6.0, m.mix("#eef400", "#197fea", 2 / 11)),
                rng(6.0, 6.5, m.mix("#eef400", "#197fea", 3 / 11)),
                rng(6.5, 7.0, m.mix("#eef400", "#197fea", 4 / 11)),
                rng(7.0, 7.5, m.mix("#eef400", "#197fea", 5 / 11)),
                rng(7.5, 8.0, m.mix("#eef400", "#197fea", 6 / 11)),
                rng(8.0, 8.5, m.mix("#eef400", "#197fea", 7 / 11)),
                rng(8.5, 9.0, m.mix("#eef400", "#197fea", 8 / 11)),
                rng(9.0, 9.5, m.mix("#eef400", "#197fea", 9 / 11)),
                rng(9.5, 10, m.mix("#eef400", "#197fea", 10 / 11)),
                rng(10.0, null, m.mix("#eef400", "#197fea", 11 / 11)),
              ],
              decimalPlaces: 1,
            }),
            // Data not yet ready for release - numberAttr({ attributeId: "alkalinity", label: "Alkalinity", "mg/L as CaCO3", buckets: "auto" }),
            // Data not yet ready for release - numberAttr({ attributeId: "uv", label: "UV", buckets: "auto" }),
            // Data not yet ready for release - numberAttr({ attributeId: "chlorophyllA", label: "Chlorophyll-A", "µg/L", buckets: "auto" }),
            numberAttr({
              attributeId: "totalPhosphorus",
              label: "Total Phosphorus",
              unit: "mg P/L",
              buckets: phosphorousBuckets,
              decimalPlaces: 4,
            }),
            numberAttr({
              attributeId: "totalDissolvedPhosphorus",
              label: "Total Dissolved Phosphorus",
              unit: "mg P/L",
              buckets: phosphorousBuckets,
              decimalPlaces: 4,
            }),
            numberAttr({
              attributeId: "solubleReactivePhosphorus",
              label: "Soluble Reactive Phosphorus",
              unit: "mg P/L",
              buckets: [
                rng(null, 0.0015, m.numeric(14 / 14, 120)),
                rng(0.0015, 0.002, m.numeric(13 / 14, 120)),
                rng(0.002, 0.003, m.numeric(12 / 14, 120)),
                rng(0.003, 0.004, m.numeric(11 / 14, 120)),
                rng(0.004, 0.005, m.numeric(10 / 14, 120)),
                rng(0.005, 0.0075, m.numeric(9 / 14, 120)),
                rng(0.0075, 0.01, m.numeric(8 / 14, 120)),
                rng(0.01, 0.02, m.numeric(7 / 14, 120)),
                rng(0.02, 0.03, m.numeric(6 / 14, 120)),
                rng(0.03, 0.04, m.numeric(5 / 14, 120)),
                rng(0.04, 0.05, m.numeric(4 / 14, 120)),
                rng(0.05, 0.1, m.numeric(3 / 14, 120)),
                rng(0.1, 0.2, m.numeric(2 / 14, 120)),
                rng(0.2, 0.3, m.numeric(1 / 14, 120)),
                rng(0.3, null, m.numeric(0 / 14, 120)),
              ],
              decimalPlaces: 4,
            }),
            stringAttr({
              attributeId: "wfdPhosphorusStatus",
              label: "WFD Phosphorus Status",
              buckets: [
                lookupBucket("High", m.fromColor("#27b6f3")),
                lookupBucket("Good", m.fromColor("#27bf57")),
                lookupBucket("Moderate", m.fromColor("#fffd2d")),
                lookupBucket("Poor", m.fromColor("#f0a923")),
                lookupBucket("Bad", m.fromColor("#df0012")),
              ],
            }),
            numberAttr({
              attributeId: "totalAmmonia",
              label: "Total Ammonia",
              unit: "mg N/L",
              buckets: [
                rng(null, 0.02, m.numeric(5 / 5, 200)),
                rng(0.02, 0.05, m.numeric(4 / 5, 200)),
                rng(0.05, 0.1, m.numeric(3 / 5, 200)),
                rng(0.1, 0.2, m.numeric(2 / 5, 200)),
                rng(0.2, 0.5, m.numeric(1 / 5, 200)),
                rng(0.5, null, m.numeric(0 / 5, 200)),
              ],
              decimalPlaces: 2,
            }),
            numberAttr({
              attributeId: "totalOxidisedNitrogen",
              label: "Total Oxidised Nitrogen",
              unit: "mg N/L",
              buckets: nitrateBuckets,
              decimalPlaces: 2,
            }),
            numberAttr({
              attributeId: "nitrate",
              label: "Nitrate",
              unit: "mg N/L",
              buckets: nitrateBuckets,
              decimalPlaces: 2,
            }),
            numberAttr({
              attributeId: "nitrite",
              label: "Nitrite",
              unit: "mg N/L",
              buckets: [
                rng(null, 0.0002, m.numeric(8 / 8, 200)),
                rng(0.0002, 0.005, m.numeric(7 / 8, 200)),
                rng(0.005, 0.001, m.numeric(6 / 8, 200)),
                rng(0.001, 0.02, m.numeric(5 / 8, 200)),
                rng(0.02, 0.03, m.numeric(4 / 8, 200)),
                rng(0.03, 0.04, m.numeric(3 / 8, 200)),
                rng(0.04, 0.05, m.numeric(2 / 8, 200)),
                rng(0.05, 0.06, m.numeric(1 / 8, 200)),
                rng(0.06, null, m.numeric(0 / 8, 200)),
              ],
              decimalPlaces: 4,
            }),
            numberAttr({
              attributeId: "silica",
              label: "Silica",
              unit: "mg SiO2/L",
              buckets: [
                rng(null, 0.02, m.numeric(10 / 10, 30)),
                rng(0.02, 0.1, m.numeric(9 / 10, 30)),
                rng(0.1, 0.2, m.numeric(8 / 10, 30)),
                rng(0.2, 0.3, m.numeric(7 / 10, 30)),
                rng(0.3, 0.4, m.numeric(6 / 10, 30)),
                rng(0.4, 0.5, m.numeric(5 / 10, 30)),
                rng(0.5, 0.75, m.numeric(4 / 10, 30)),
                rng(0.75, 1.0, m.numeric(3 / 10, 30)),
                rng(1.0, 1.5, m.numeric(2 / 10, 30)),
                rng(1.5, 2.0, m.numeric(1 / 10, 30)),
                rng(2.0, null, m.numeric(0 / 10, 30)),
              ],
              decimalPlaces: 2,
            }),
            // Data not yet ready for release - numberAttr({ attributeId: "dissolvedOrganicCarbon", label: "Dissolved Organic Carbon", "mg C/L", buckets: "auto" }),
            numberAttr({
              attributeId: "totalColiforms",
              label: "Total Coliforms",
              unit: "cfu/100 ml",
              buckets: [
                rng(null, 500, m.numeric(7 / 7, 0)),
                rng(500, 1000, m.numeric(6 / 7, 0)),
                rng(1000, 2000, m.numeric(5 / 7, 0)),
                rng(2000, 3000, m.numeric(4 / 7, 0)),
                rng(3000, 4000, m.numeric(3 / 7, 0)),
                rng(4000, 8000, m.numeric(2 / 7, 0)),
                rng(8000, 16000, m.numeric(1 / 7, 0)),
                rng(16000, null, m.numeric(0 / 7, 0)),
              ],
              decimalPlaces: 0,
            }),
            numberAttr({
              attributeId: "eColi",
              label: "E-Coli",
              unit: "cfu/100 ml",
              buckets: [
                rng(null, 500, m.numeric(6 / 6, 0)),
                rng(500, 1000, m.numeric(5 / 6, 0)),
                rng(1000, 2000, m.numeric(4 / 6, 0)),
                rng(2000, 3000, m.numeric(3 / 6, 0)),
                rng(3000, 4000, m.numeric(2 / 6, 0)),
                rng(4000, 8000, m.numeric(1 / 6, 0)),
                rng(8000, null, m.numeric(0 / 6, 0)),
              ],
              decimalPlaces: 0,
            }),
            numberAttr({
              attributeId: "intestinalEnterococci",
              label: "Intestinal Enterococci",
              unit: "cfu/100 ml",
              buckets: [
                rng(null, 200, m.numeric(5 / 5, 0)),
                rng(200, 400, m.numeric(4 / 5, 0)),
                rng(400, 800, m.numeric(3 / 5, 0)),
                rng(800, 1600, m.numeric(2 / 5, 0)),
                rng(1600, 3200, m.numeric(1 / 5, 0)),
                rng(3200, null, m.numeric(0 / 5, 0)),
              ],
              decimalPlaces: 0,
            }),
            stringAttr({
              attributeId: "bathingWaterQuality",
              label: "Bathing Water Quality",
              buckets: [
                lookupBucket("Excellent", m.fromColor("#27b6f3")),
                lookupBucket("Good", m.fromColor("#27bf57")),
                lookupBucket("Less than Good", m.fromColor("#fffd2d")),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
