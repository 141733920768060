// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityChannelSubstrate =
  | "Boulders"
  | "Stones"
  | "Gravel"
  | "Sand"
  | "SiltOrMud"
  | "Bedrock"
  | "Artificial"
  | "NotVisible"
  | "Other";

const Boulders: ArrtWaterQualityChannelSubstrate = "Boulders";
const Stones: ArrtWaterQualityChannelSubstrate = "Stones";
const Gravel: ArrtWaterQualityChannelSubstrate = "Gravel";
const Sand: ArrtWaterQualityChannelSubstrate = "Sand";
const SiltOrMud: ArrtWaterQualityChannelSubstrate = "SiltOrMud";
const Bedrock: ArrtWaterQualityChannelSubstrate = "Bedrock";
const Artificial: ArrtWaterQualityChannelSubstrate = "Artificial";
const NotVisible: ArrtWaterQualityChannelSubstrate = "NotVisible";
const Other: ArrtWaterQualityChannelSubstrate = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityChannelSubstrateEnum = {
  ...createEnum("ArrtWaterQualityChannelSubstrate", [
    [Boulders, "Boulders"],
    [Stones, "Stones"],
    [Gravel, "Gravel"],
    [Sand, "Sand"],
    [SiltOrMud, "Silt/Mud"],
    [Bedrock, "Bedrock"],
    [Artificial, "Artificial"],
    [NotVisible, "Not Visible"],
    [Other, "Other"],
  ]),
  Boulders,
  Stones,
  Gravel,
  Sand,
  SiltOrMud,
  Bedrock,
  Artificial,
  NotVisible,
  Other,
};

export default ArrtWaterQualityChannelSubstrateEnum;
