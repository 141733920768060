// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesDrainageNetworkType =
  | "LN"
  | "LD"
  | "DN"
  | "MD"
  | "RT"
  | "SI";

const Linear: MrsMorphEstuariesDrainageNetworkType = "LN";
const LinearDendritic: MrsMorphEstuariesDrainageNetworkType = "LD";
const Dendritic: MrsMorphEstuariesDrainageNetworkType = "DN";
const MeanderingDendritic: MrsMorphEstuariesDrainageNetworkType = "MD";
const Reticulate: MrsMorphEstuariesDrainageNetworkType = "RT";
const Superimposed: MrsMorphEstuariesDrainageNetworkType = "SI";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesDrainageNetworkTypeEnum = {
  ...createEnum("MrsMorphEstuariesDrainageNetworkType", [
    [Linear, "Linear"],
    [LinearDendritic, "Linear dendritic"],
    [Dendritic, "Dendritic"],
    [MeanderingDendritic, "Meandering dendritic"],
    [Reticulate, "Reticulate"],
    [Superimposed, "Superimposed"],
  ]),
  Linear,
  LinearDendritic,
  Dendritic,
  MeanderingDendritic,
  Reticulate,
  Superimposed,
};

export default MrsMorphEstuariesDrainageNetworkTypeEnum;
