import { ContainerNode, Node } from "./type";

export function children(node: Node): Node[] | undefined {
  switch (node.type) {
    case "Text":
      return undefined;
    case "SoftBreak":
      return undefined;
    case "LineBreak":
      return undefined;
    case "Emph":
      return node.children;
    case "Strong":
      return node.children;
    case "HtmlInline":
      return undefined;
    case "Link":
      return undefined;
    case "Image":
      return undefined;
    case "Code":
      return undefined;
    case "Document":
      return node.children;
    case "Paragraph":
      return node.children;
    case "BlockQuote":
      return node.children;
    case "List":
      return node.children;
    case "Item":
      return node.children;
    case "Heading":
      return node.children;
    case "CodeBlock":
      return undefined;
    case "HtmlBlock":
      return undefined;
    case "ThematicBreak":
      return undefined;
    case "CustomInline":
      return undefined;
    case "CustomBlock":
      return undefined;
  }
}

export function unsafePushChild(parent: ContainerNode, child: Node): void {
  parent.children.push(child);
}
