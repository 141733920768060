// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankLandUse =
  | "Woodland"
  | "MoorlandOrHeath"
  | "UrbanResidential"
  | "ParklandOrGardens"
  | "RoadsideVerge"
  | "RoadsideBank"
  | "Brownfield"
  | "GrasslandOrPasture"
  | "Agriculture"
  | "RiverOrStream"
  | "Unknown"
  | "Other";

const Woodland: NwcSeedbankLandUse = "Woodland";
const MoorlandOrHeath: NwcSeedbankLandUse = "MoorlandOrHeath";
const UrbanResidential: NwcSeedbankLandUse = "UrbanResidential";
const ParklandOrGardens: NwcSeedbankLandUse = "ParklandOrGardens";
const RoadsideVerge: NwcSeedbankLandUse = "RoadsideVerge";
const RoadsideBank: NwcSeedbankLandUse = "RoadsideBank";
const Brownfield: NwcSeedbankLandUse = "Brownfield";
const GrasslandOrPasture: NwcSeedbankLandUse = "GrasslandOrPasture";
const Agriculture: NwcSeedbankLandUse = "Agriculture";
const RiverOrStream: NwcSeedbankLandUse = "RiverOrStream";
const Unknown: NwcSeedbankLandUse = "Unknown";
const Other: NwcSeedbankLandUse = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankLandUseEnum = {
  ...createEnum("NwcSeedbankLandUse", [
    [Woodland, "Woodland"],
    [MoorlandOrHeath, "Moorland / Heath"],
    [UrbanResidential, "Urban Residential"],
    [ParklandOrGardens, "Parkland / Gardens"],
    [RoadsideVerge, "Roadside Verge"],
    [RoadsideBank, "Roadside Bank"],
    [Brownfield, "Brownfield"],
    [GrasslandOrPasture, "Grassland / Pasture"],
    [Agriculture, "Agriculture"],
    [RiverOrStream, "River / Stream"],
    [Unknown, "Unknown"],
    [Other, "Other"],
  ]),
  Woodland,
  MoorlandOrHeath,
  UrbanResidential,
  ParklandOrGardens,
  RoadsideVerge,
  RoadsideBank,
  Brownfield,
  GrasslandOrPasture,
  Agriculture,
  RiverOrStream,
  Unknown,
  Other,
};

export default NwcSeedbankLandUseEnum;
