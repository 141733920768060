import { IO } from "@cartographerio/io";
import { ApiParams, networkError } from "@cartographerio/types";
import fetch from "cross-fetch";
import { defaultHeaders, fetchLogger, HttpMethod } from "./fetchHelpers";
import { authHeaders, combineHeaders, jsonHeaders } from "./headers";
import { createResponse, ResponseF } from "./response";
import { formatUrl, UrlParts } from "./url";

export function request(
  url: string,
  options: RequestInit
): IO<ResponseF<unknown>> {
  const { logFetchRequest, logFetchResponse, logFetchError } = fetchLogger();

  const fullOptions: RequestInit = {
    ...options,
    headers: {
      ...defaultHeaders(),
      ...(options.headers ?? {}),
    },
  };

  return logFetchRequest(url, fullOptions).chain(ctx =>
    IO.wrap(fetch, url, fullOptions)
      .chain(response =>
        // Be careful about binding `this`:
        IO.wrap(() => response.text())
          .tap(text => logFetchResponse(ctx, response, text))
          .chain(text => JSON.parse(text))
          .chain(content => createResponse(response, content))
      )
      .recover(error =>
        logFetchError(ctx, error).andThen(
          IO.fail(
            networkError("Could not contact the server", {
              method: options.method,
              url,
              error,
            })
          )
        )
      )
  );
}

interface FetchArgsWithoutBody {
  apiParams: ApiParams;
  url: UrlParts;
  redirect?: RequestRedirect;
}

function fetchWithoutBody(method: HttpMethod) {
  return function (args: FetchArgsWithoutBody): IO<ResponseF<unknown>> {
    const {
      apiParams: { apiConfig, auth },
      url,
      redirect,
    } = args;
    return request(formatUrl(apiConfig.baseUrl, url), {
      method,
      headers: combineHeaders(authHeaders(apiConfig, auth), jsonHeaders),
      redirect,
    });
  };
}

interface FetchArgsWithBody extends FetchArgsWithoutBody {
  body: unknown;
}

function fetchWithBody(method: HttpMethod) {
  return function (args: FetchArgsWithBody): IO<ResponseF<unknown>> {
    const {
      apiParams: { apiConfig, auth },
      url,
      redirect,
      body,
    } = args;
    return request(formatUrl(apiConfig.baseUrl, url), {
      method,
      body: body instanceof FormData ? body : JSON.stringify(body),
      headers: combineHeaders(authHeaders(apiConfig, auth), jsonHeaders),
      redirect,
    });
  };
}

export const get = fetchWithoutBody("GET");
export const post = fetchWithBody("POST");
export const put = fetchWithBody("PUT");
export const remove = fetchWithoutBody("DELETE");
export const head = fetchWithoutBody("HEAD");
export const options = fetchWithoutBody("OPTIONS");
export const patch = fetchWithBody("PATCH");
export const trace = fetchWithoutBody("TRACE");
