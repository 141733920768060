// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopAquaticFauna =
  | "Odonta"
  | "Fish"
  | "Frogs"
  | "Aquatic"
  | "None"
  | "Other";

const Odonta: UclPitStopAquaticFauna = "Odonta";
const Fish: UclPitStopAquaticFauna = "Fish";
const Frogs: UclPitStopAquaticFauna = "Frogs";
const Aquatic: UclPitStopAquaticFauna = "Aquatic";
const None: UclPitStopAquaticFauna = "None";
const Other: UclPitStopAquaticFauna = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopAquaticFaunaEnum = {
  ...createEnum("UclPitStopAquaticFauna", [
    [Odonta, "Dragonflies/damselflies (adults or exuvia)"],
    [Fish, "Fish (small native)"],
    [Frogs, "Frogs/toads/newts (adult or eggs)"],
    [Aquatic, "Aquatic birds (e.g. moorhen)"],
    [None, "None"],
    [Other, "Other"],
  ]),
  Odonta,
  Fish,
  Frogs,
  Aquatic,
  None,
  Other,
};

export default UclPitStopAquaticFaunaEnum;
