import { hasKey, hasTypeTag, isObject, isString } from "@cartographerio/guard";
import base64 from "base-64";
import { AccessToken, isAccessToken } from "../auth";
import { Email, UnencryptedPassword } from "../core";

export interface BearerAuth {
  type: "BearerAuth";
  token: AccessToken;
}

export interface BasicAuth {
  type: "BasicAuth";
  encoded: string;
}

export type ApiAuth = BearerAuth | BasicAuth;

export function isBearerAuth(obj: unknown): obj is BearerAuth {
  return (
    isObject(obj) &&
    hasTypeTag(obj, "BearerAuth") &&
    hasKey(obj, "token", isAccessToken)
  );
}

export function isBasicAuth(obj: unknown): obj is BasicAuth {
  return (
    isObject(obj) &&
    hasTypeTag(obj, "BasicAuth") &&
    hasKey(obj, "encoded", isString)
  );
}

export function isApiAuth(obj: unknown): obj is ApiAuth {
  return isBearerAuth(obj) || isBasicAuth(obj);
}

export function bearerAuth(token: AccessToken): BearerAuth {
  return { type: "BearerAuth", token };
}

export function basicAuth(
  email: Email,
  password: UnencryptedPassword
): BasicAuth {
  return {
    type: "BasicAuth",
    encoded: base64.encode(`${email}:${password}`) as string,
  };
}

export function authHeader(auth: ApiAuth): string {
  switch (auth.type) {
    case "BearerAuth":
      return `Bearer ${auth.token}`;
    case "BasicAuth":
      return `Basic ${auth.encoded}`;
  }
}
