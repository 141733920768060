import { Env } from "@cartographerio/topo-core";
import {
  Form,
  columns2,
  primarySurveyorField,
  primaryTeamField,
} from "@cartographerio/topo-form";
import { Identity, Survey, isSurvey } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { UserWorkspaceGraph } from "@cartographerio/workspace-graph";
import { checkMultiTeam } from "./helpers";
import { ownTeamRule, surveyorTeamRule } from "./rule";
import { ruleSequence } from "./rule/rule";
import { teamMateRule } from "./rule/teamMateRule";
import { teamAssociationRule } from "./rule/teamAssociationRule";

function documentAsSurvey(env: Env): Survey {
  const doc = env.doc();
  return isSurvey(doc)
    ? doc
    : raise("Surveyor validation rules not given a survey");
}

export function injectSurveyorTeamFields(
  formSchema: Form,
  identity: Identity,
  graph: UserWorkspaceGraph,
  defaultSurvey: Survey,
  editable: boolean
): Form {
  const project = graph.findProjectById(defaultSurvey.projectId);

  const surveyorRule = editable
    ? ruleSequence(teamMateRule, surveyorTeamRule)({ identity, graph, project })
    : null;

  const teamRule = editable
    ? ruleSequence(
        ownTeamRule,
        teamAssociationRule
      )({ identity, graph, project })
    : null;

  const isMultiTeam = checkMultiTeam(graph, defaultSurvey.projectId);

  const [firstPage, ...tailPages] = formSchema.pages;

  const surveyorField = primarySurveyorField({
    label: formSchema.surveyorLabel ?? "Surveyor",
    help: formSchema.surveyorHelp,
    rule: env => surveyorRule?.(documentAsSurvey(env)) ?? [],
  });

  const identityBlock = isMultiTeam
    ? columns2(
        surveyorField,
        primaryTeamField({
          label: formSchema.teamLabel ?? "Team",
          help: formSchema.teamHelp,
          rule: env => teamRule?.(documentAsSurvey(env)) ?? [],
        })
      )
    : columns2(surveyorField);

  return {
    ...formSchema,
    pages: [
      { ...firstPage, blocks: [identityBlock, ...firstPage.blocks] },
      ...tailPages,
    ],
  };
}
