import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";
import { isNumber } from "lodash";
import {
  WrtWestcountryCsiFlowLevel,
  WrtWestcountryCsiFlowLevelEnum,
  WrtRainfall,
  WrtRainfallEnum,
  WrtWestcountryCsiWaterLevel,
  WrtWestcountryCsiWaterLevelEnum,
} from "@cartographerio/inventory-enums";

export interface PartialData {
  recorded?: Timestamp | null;
  details: {
    river?: string | null;
    site?: string | null;
    location?: Point | null;
    recentRain?: WrtRainfall | null;
  };
  riverChannel: {
    waterFlow?: WrtWestcountryCsiFlowLevel | null;
    waterLevel?: WrtWestcountryCsiWaterLevel | null;
    temperature?: number | null;
    totalDissolvedSolids?: number | null;
    turbidity?: string | null;
    phosphate?: number | null;
  };
  notes?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasKey(
      data,
      "details",
      (details: unknown): details is PartialData["details"] =>
        isObject(details) &&
        hasOptionalKey(details, "river", isNullable(isString)) &&
        hasOptionalKey(details, "site", isNullable(isString)) &&
        hasOptionalKey(details, "location", isNullable(isPoint)) &&
        hasOptionalKey(
          details,
          "recentRain",
          isNullable(WrtRainfallEnum.isValue)
        )
    ) &&
    hasKey(
      data,
      "riverChannel",
      (riverChannel: unknown): riverChannel is PartialData["riverChannel"] =>
        isObject(riverChannel) &&
        hasOptionalKey(
          riverChannel,
          "waterFlow",
          isNullable(WrtWestcountryCsiFlowLevelEnum.isValue)
        ) &&
        hasOptionalKey(
          riverChannel,
          "waterLevel",
          isNullable(WrtWestcountryCsiWaterLevelEnum.isValue)
        ) &&
        hasOptionalKey(riverChannel, "temperature", isNullable(isNumber)) &&
        hasOptionalKey(
          riverChannel,
          "totalDissolvedSolids",
          isNullable(isNumber)
        ) &&
        hasOptionalKey(riverChannel, "turbidity", isNullable(isString)) &&
        hasOptionalKey(riverChannel, "phosphate", isNullable(isNumber))
    ) &&
    hasOptionalKey(data, "notes", isNullable(isString))
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialWrtWestcountryCsiData"
  )(data).map(data =>
    [
      data.details.river ?? "Unknown river",
      data.details.site ?? "Unknown site",
      data.details.location
        ? safePointToNgr(data.details.location)
        : "Unknown NGR",
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialWrtWestcountryCsiData"
  )(data).map(data => data.details.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialWrtWestcountryCsiData"
  )(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return Result.guard(
    isPartialData,
    "PartialWrtWestcountryCsiData"
  )(data).map(data => ({
    ...data,
    recorded: null,
    details: {
      ...data.details,
      recentRain: null,
    },
    riverChannel: {
      ...data.riverChannel,
      waterFlow: null,
      waterLevel: null,
      temperature: null,
      totalDissolvedSolids: null,
      turbidity: null,
      phosphate: null,
    },
    notes: null,
  }));
}
