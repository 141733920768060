// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesSurveyedFrom = "towPathSide" | "oppositeSide" | "bothSides";

const TowPathSide: CesSurveyedFrom = "towPathSide";
const OppositeSide: CesSurveyedFrom = "oppositeSide";
const BothSides: CesSurveyedFrom = "bothSides";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesSurveyedFromEnum = {
  ...createEnum("CesSurveyedFrom", [
    [TowPathSide, "Tow path side"],
    [OppositeSide, "Opposite side"],
    [BothSides, "Both sides"],
  ]),
  TowPathSide,
  OppositeSide,
  BothSides,
};

export default CesSurveyedFromEnum;
