// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiChannelSubstrate =
  | "notVisible"
  | "bedrock"
  | "boulders"
  | "stones"
  | "gravel"
  | "sand"
  | "siltOrMud"
  | "artificial"
  | "other";

const NotVisible: WrtWestcountryCsiChannelSubstrate = "notVisible";
const Bedrock: WrtWestcountryCsiChannelSubstrate = "bedrock";
const Boulders: WrtWestcountryCsiChannelSubstrate = "boulders";
const Stones: WrtWestcountryCsiChannelSubstrate = "stones";
const Gravel: WrtWestcountryCsiChannelSubstrate = "gravel";
const Sand: WrtWestcountryCsiChannelSubstrate = "sand";
const SiltOrMud: WrtWestcountryCsiChannelSubstrate = "siltOrMud";
const Artificial: WrtWestcountryCsiChannelSubstrate = "artificial";
const Other: WrtWestcountryCsiChannelSubstrate = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiChannelSubstrateEnum = {
  ...createEnum("WrtWestcountryCsiChannelSubstrate", [
    [NotVisible, "Not visible"],
    [Bedrock, "Bedrock"],
    [Boulders, "Boulders"],
    [Stones, "Stones"],
    [Gravel, "Gravel"],
    [Sand, "Sand"],
    [SiltOrMud, "Silt/mud"],
    [Artificial, "Artificial"],
    [Other, "Other"],
  ]),
  NotVisible,
  Bedrock,
  Boulders,
  Stones,
  Gravel,
  Sand,
  SiltOrMud,
  Artificial,
  Other,
};

export default WrtWestcountryCsiChannelSubstrateEnum;
