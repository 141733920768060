import { Result } from "@cartographerio/fp";
import { isPoint, Point } from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isNumber,
  isString,
} from "@cartographerio/guard";
import {
  StormPhosphatesLevel,
  StormPhosphatesLevelEnum,
  StormPhosphatesLevelTrend,
  StormPhosphatesLevelTrendEnum,
} from "@cartographerio/inventory-enums";
import {
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  Timestamp,
} from "@cartographerio/types";

interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  riverName?: string | null;
  level?: StormPhosphatesLevel | null;
  levelTrend?: StormPhosphatesLevelTrend | null;
  phosphate?: number | null;
  photographs: AttachmentFolder;
  notes?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "riverName", isNullable(isString)) &&
    hasOptionalKey(
      data,
      "level",
      isNullable(StormPhosphatesLevelEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "levelTrend",
      isNullable(StormPhosphatesLevelTrendEnum.isValue)
    ) &&
    hasKey(data, "photographs", isAttachmentFolder) &&
    hasOptionalKey(data, "phosphate", isNullable(isNumber)) &&
    hasOptionalKey(data, "notes", isNullable(isString))
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data?.riverName || "Unknown River",
      `${data?.phosphate ?? "Unknown"} ppm`,
    ].join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.recorded ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data.location ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
  }));
}
