import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  InvoiceTotal,
  isInvoiceTotal,
  isPayment,
  isSearchResults,
  Payment,
  SearchResults,
  StripeInvoiceId,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";
import { PartialParams } from "../../params";
import {
  BillingPaymentSearchOptions,
  BillingPaymentTotalOptions,
} from "./common";

const basePath = "/billing/payment/v1";

export function list(
  apiParams: ApiParams,
  params: PartialParams<BillingPaymentSearchOptions>
): IO<SearchResults<Payment>> {
  return fetch
    .get({ apiParams, url: { path: basePath, query: { ...params } } })
    .flatMap(contentAs("SearchResults<Payment>", isSearchResults(isPayment)));
}

export function readOrFail(
  apiParams: ApiParams,
  invoiceId: StripeInvoiceId
): IO<Payment> {
  const url = { path: `${basePath}/${invoiceId}` };
  return fetch.get({ apiParams, url }).flatMap(contentAs("Payment", isPayment));
}

export function readOption(
  apiParams: ApiParams,
  invoiceId: StripeInvoiceId
): IO<Option<Payment>> {
  const url = { path: `${basePath}/${invoiceId}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(optionalContentAs("Payment", isPayment));
}

export function total(
  apiParams: ApiParams,
  params: PartialParams<BillingPaymentTotalOptions>
): IO<InvoiceTotal> {
  return fetch
    .get({ apiParams, url: { path: basePath, query: { ...params } } })
    .flatMap(contentAs("InvoiceTotal", isInvoiceTotal));
}
