// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { SurveyStatus } from "../types.generated";

const Draft: SurveyStatus = "draft";
const Complete: SurveyStatus = "complete";
const Approved: SurveyStatus = "approved";
const Review: SurveyStatus = "review";
const Rejected: SurveyStatus = "rejected";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SurveyStatusEnum = {
  ...createEnum("SurveyStatus", [
    [Draft, "Draft"],
    [Complete, "Complete"],
    [Approved, "Approved"],
    [Review, "Review"],
    [Rejected, "Rejected"],
  ]),
  Draft,
  Complete,
  Approved,
  Review,
  Rejected,
};

export default SurveyStatusEnum;
