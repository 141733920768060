// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopFoundBy = "PitStopMap" | "IdentifiedInField";

const PitStopMap: UclPitStopFoundBy = "PitStopMap";
const IdentifiedInField: UclPitStopFoundBy = "IdentifiedInField";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopFoundByEnum = {
  ...createEnum("UclPitStopFoundBy", [
    [PitStopMap, "Project Pit Stop map"],
    [IdentifiedInField, "Identified in the field"],
  ]),
  PitStopMap,
  IdentifiedInField,
};

export default UclPitStopFoundByEnum;
