// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeNaturalnessClass = "One" | "Two" | "Three" | "Four" | "Five";

const One: NeNaturalnessClass = "One";
const Two: NeNaturalnessClass = "Two";
const Three: NeNaturalnessClass = "Three";
const Four: NeNaturalnessClass = "Four";
const Five: NeNaturalnessClass = "Five";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeNaturalnessClassEnum = {
  ...createEnum("NeNaturalnessClass", [
    [One, "1 (high)"],
    [Two, "2"],
    [Three, "3"],
    [Four, "4"],
    [Five, "5 (very low)"],
  ]),
  One,
  Two,
  Three,
  Four,
  Five,
};

export default NeNaturalnessClassEnum;
