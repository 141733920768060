// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsArtificialBankReinforcement = "None" | "ARF" | "ART" | "ARB";

const None: UrsArtificialBankReinforcement = "None";
const ReinforcedWhole: UrsArtificialBankReinforcement = "ARF";
const ReinforcedTopOnly: UrsArtificialBankReinforcement = "ART";
const ReinforcedToeOnly: UrsArtificialBankReinforcement = "ARB";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsArtificialBankReinforcementEnum = {
  ...createEnum("UrsArtificialBankReinforcement", [
    [None, "None (unreinforced)"],
    [ReinforcedWhole, "Fully reinforced"],
    [ReinforcedTopOnly, "Upper bank only"],
    [ReinforcedToeOnly, "Lower bank only"],
  ]),
  None,
  ReinforcedWhole,
  ReinforcedTopOnly,
  ReinforcedToeOnly,
};

export default UrsArtificialBankReinforcementEnum;
