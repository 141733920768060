import { isArray, isBoolean, isNumber, isString } from "@cartographerio/guard";
import {
  epochTimestamp,
  formatTimestamp,
  TimestampFormat,
  TimeZone,
} from "@cartographerio/types";
import { Enum } from "@cartographerio/util";
import {
  autoBuckets,
  Bucket,
  Buckets,
  PropertyValue,
  standardBuckets,
} from "../bucket";
import { PropertyFormatter } from "./type";

const DEFAULT_DECIMAL_PLACES = 2;

export type BucketsProp<A> = "auto" | Bucket<A>[] | Buckets<A>;

export function wrapBuckets<A>(buckets: BucketsProp<A>): Buckets<A> {
  return buckets === "auto"
    ? autoBuckets<A>({})
    : isArray(buckets)
      ? standardBuckets<A>(buckets)
      : buckets;
}

export const stringFormatter: PropertyFormatter = value =>
  isString(value) ? value : null;

export function enumFormatter<A extends string>(
  enumeration: Enum<A>
): PropertyFormatter {
  return (value: PropertyValue) =>
    enumeration.isValue(value) ? enumeration.labelOf(value) : null;
}

export function numberFormatter(
  decimalPlaces: number = DEFAULT_DECIMAL_PLACES
): PropertyFormatter {
  return (num: PropertyValue) =>
    isNumber(num) ? num.toFixed(decimalPlaces) : null;
}

export const booleanFormatter: PropertyFormatter = value =>
  isBoolean(value) ? (value === true ? "Yes" : "No") : null;

export function timestampFormatter(
  format?: TimestampFormat,
  timeZone?: TimeZone
): PropertyFormatter {
  return (epoch: PropertyValue) =>
    isNumber(epoch)
      ? formatTimestamp(epochTimestamp(epoch), { format, timeZone })
      : null;
}
