// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtFordsLandCoverStage =
  | "BareOrTilled"
  | "SeedlingEmergence"
  | "GrowthStage"
  | "Mature"
  | "HarvestOrPostHarvest";

const BareOrTilled: WrtFordsLandCoverStage = "BareOrTilled";
const SeedlingEmergence: WrtFordsLandCoverStage = "SeedlingEmergence";
const GrowthStage: WrtFordsLandCoverStage = "GrowthStage";
const Mature: WrtFordsLandCoverStage = "Mature";
const HarvestOrPostHarvest: WrtFordsLandCoverStage = "HarvestOrPostHarvest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtFordsLandCoverStageEnum = {
  ...createEnum("WrtFordsLandCoverStage", [
    [BareOrTilled, "Bare / tilled"],
    [SeedlingEmergence, "Seedling emergence"],
    [GrowthStage, "Growth stage"],
    [Mature, "Mature"],
    [HarvestOrPostHarvest, "Harvest / post-harvest"],
  ]),
  BareOrTilled,
  SeedlingEmergence,
  GrowthStage,
  Mature,
  HarvestOrPostHarvest,
};

export default WrtFordsLandCoverStageEnum;
