// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesJettyAlignment = "parallel" | "perpendicular";

const Parallel: CesJettyAlignment = "parallel";
const Perpendicular: CesJettyAlignment = "perpendicular";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesJettyAlignmentEnum = {
  ...createEnum("CesJettyAlignment", [
    [Parallel, "Parallel"],
    [Perpendicular, "Perpendicular"],
  ]),
  Parallel,
  Perpendicular,
};

export default CesJettyAlignmentEnum;
