import {
  form,
  grid,
  page,
  pointField,
  required,
  section,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";

export default form({
  title: "Windermere Site",
  surveyorLabel: "Creator",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                grid({
                  columns: 1,
                  blocks: [
                    textField({
                      label: "Site Name",
                      path: ["data", "name"],
                    }),
                    textField({
                      label: "Site Description",
                      path: ["data", "description"],
                    }),
                    timestampField({
                      label: "Date and Time Established",
                      path: ["data", "established"],
                      help: "Date and time the site was established.",
                    }),
                  ],
                }),
                pointField({
                  label: "Location",
                  path: ["data", "location"],
                  required: required("info"),
                  help: "Location of the monitoring site.",
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 4,
            }),
          ],
        }),
      ],
    }),
  ],
});
