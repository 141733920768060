import { errorMessage } from "@cartographerio/topo-core";
import { WorkspaceLimits, WorkspaceUsage } from "@cartographerio/types";

import { rule } from "./rule";

export function workspaceLimitsRule(usage: WorkspaceUsage) {
  const numMembers =
    usage.numUsers + usage.numPendingInvitations + usage.numUnapprovedSignups;
  return rule
    .build<WorkspaceLimits>()
    .optionalField(
      "maxUsers",
      rule.nullable(
        rule.gte(numMembers, [
          errorMessage(
            "This must be greater than or equal to the number of members in your workspace"
          ),
        ])
      )
    )
    .optionalField(
      "maxProjects",
      rule.nullable(
        rule.gte(usage.numProjects, [
          errorMessage(
            "This must be greater than or equal to the number of projects in your workspace"
          ),
        ])
      )
    )
    .finish();
}
