// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesDaysToSpringTide =
  | "Zero"
  | "One"
  | "Two"
  | "Other";

const Zero: MrsMorphEstuariesDaysToSpringTide = "Zero";
const One: MrsMorphEstuariesDaysToSpringTide = "One";
const Two: MrsMorphEstuariesDaysToSpringTide = "Two";
const Other: MrsMorphEstuariesDaysToSpringTide = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesDaysToSpringTideEnum = {
  ...createEnum("MrsMorphEstuariesDaysToSpringTide", [
    [Zero, "0"],
    [One, "1"],
    [Two, "2"],
    [Other, "Other"],
  ]),
  Zero,
  One,
  Two,
  Other,
};

export default MrsMorphEstuariesDaysToSpringTideEnum;
