// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityEstimatedWidth =
  | "W00_05"
  | "W05_10"
  | "W10_20"
  | "W20Plus";

const W00_05: EsrtWaterQualityEstimatedWidth = "W00_05";
const W05_10: EsrtWaterQualityEstimatedWidth = "W05_10";
const W10_20: EsrtWaterQualityEstimatedWidth = "W10_20";
const W20Plus: EsrtWaterQualityEstimatedWidth = "W20Plus";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityEstimatedWidthEnum = {
  ...createEnum("EsrtWaterQualityEstimatedWidth", [
    [W00_05, "0 to 5m"],
    [W05_10, "5 to 10m"],
    [W10_20, "10 to 20m"],
    [W20Plus, ">20m"],
  ]),
  W00_05,
  W05_10,
  W10_20,
  W20Plus,
};

export default EsrtWaterQualityEstimatedWidthEnum;
