import {
  attachmentField,
  checkbox,
  form,
  grid,
  page,
  pointField,
  required,
  requiredIf,
  section,
  select,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import {
  MrsWildInvadersAccessibleByEnum,
  MrsWildInvadersCoverageEnum,
  MrsWildInvadersGrowthStageEnum,
  MrsWildInvadersManagementEnum,
  MrsWildInvadersProximityEnum,
  MrsWildInvadersSpeciesEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
export default form({
  title: "Wild Invaders",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                  defaultValue: "now",
                  required: required("info"),
                }),
                textField({
                  label: "River",
                  path: ["data", "river"],
                  required: required("info"),
                }),
                pointField({
                  label: "Location",
                  path: ["data", "location"],
                  help: "Location of the individual specimen or the center of the patch being surveyed.",
                  fullWidth: true,
                  required: required("info"),
                }),
                textField({
                  label: "Collected On Behalf Of",
                  path: ["data", "collectedOnBehalfOf"],
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Species",
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Species",
                  path: ["data", "species"],
                  help: "Select the species you are recording.",
                  options: MrsWildInvadersSpeciesEnum.entries,
                  required: required("info"),
                }),
                textField({
                  label: "Other Species",
                  path: ["data", "otherSpecies"],
                  help: "If you selected 'Other Fauna' or 'Other Flora' please name the species.",
                  customRules: [
                    requiredIf({
                      message: "You should describe the species.",
                      otherPath: ["data", "species"],
                      otherTest: value =>
                        value === MrsWildInvadersSpeciesEnum.OtherFlora ||
                        value === MrsWildInvadersSpeciesEnum.OtherFauna,
                    }),
                  ],
                }),
                select({
                  label: "Growth Stage",
                  path: ["data", "growthStage"],
                  options: MrsWildInvadersGrowthStageEnum.entries,
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Abundance/Location",
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Area Coverage",
                  path: ["data", "coverage"],
                  options: MrsWildInvadersCoverageEnum.entries,
                  required: required("info"),
                }),
                checkbox({
                  label: "In Water?",
                  path: ["data", "inWater"],
                  checkboxLabel: "Is the species in the water?",
                }),
                select({
                  label: "Proximity to Watercourse",
                  path: ["data", "proximity"],
                  options: MrsWildInvadersProximityEnum.entries,
                  required: required("info"),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Management",
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Management",
                  path: ["data", "management"],
                  options: MrsWildInvadersManagementEnum.entries,
                  required: required("info"),
                }),
                textField({
                  label: "Other Management Technique",
                  path: ["data", "otherManagement"],
                  help: "If you selected 'Other' please describe the type of management.",
                  customRules: [
                    requiredIf({
                      message: "Please describe the management technique.",
                      otherPath: ["data", "management"],
                      otherTest: value =>
                        value === MrsWildInvadersManagementEnum.Other,
                    }),
                  ],
                }),
                checkbox({
                  label: "Access",
                  checkboxLabel: "Is the area accessible?",
                  path: ["data", "accessible"],
                }),
                select({
                  label: "Means of Access",
                  path: ["data", "accessibleBy"],
                  help: "If the area is accessible, how is it accessible?",
                  options: MrsWildInvadersAccessibleByEnum.entries,
                  customRules: [
                    requiredIf({
                      message: "Please describe the means of access.",
                      otherPath: ["data", "accessible"],
                      otherTest: value => value === true,
                    }),
                  ],
                }),
                textField({
                  label: "Other Means of Access",
                  path: ["data", "otherAccessibleBy"],
                  help: "If you selected 'Other' please describe the means of access.",
                  customRules: [
                    requiredIf({
                      message: "Please describe the means of access.",
                      otherPath: ["data", "accessibleBy"],
                      otherTest: value =>
                        value === MrsWildInvadersAccessibleByEnum.Other,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Comments",
          path: [],
          help: outdent`
          Please include any comments including details details of accessibility
          for management or other indicators of active spreading,
          e.g. means of transport to new areas.
          `,
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 5,
            }),
          ],
        }),
      ],
    }),
  ],
});
