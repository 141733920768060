// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiPollutionSource =
  | "none"
  | "activeOutfalls"
  | "inactiveOutfalls"
  | "outfallDiscolouration"
  | "outfallOdour"
  | "farmRunoff"
  | "greyWater"
  | "cattleOrStock"
  | "riverBankCollapse"
  | "soilRunoff"
  | "roadRunoff"
  | "other";

const None: WrtWestcountryCsiPollutionSource = "none";
const ActiveOutfalls: WrtWestcountryCsiPollutionSource = "activeOutfalls";
const InactiveOutfalls: WrtWestcountryCsiPollutionSource = "inactiveOutfalls";
const OutfallDiscolouration: WrtWestcountryCsiPollutionSource =
  "outfallDiscolouration";
const OutfallOdour: WrtWestcountryCsiPollutionSource = "outfallOdour";
const FarmRunoff: WrtWestcountryCsiPollutionSource = "farmRunoff";
const GreyWater: WrtWestcountryCsiPollutionSource = "greyWater";
const CattleOrStock: WrtWestcountryCsiPollutionSource = "cattleOrStock";
const RiverBankCollapse: WrtWestcountryCsiPollutionSource = "riverBankCollapse";
const SoilRunoff: WrtWestcountryCsiPollutionSource = "soilRunoff";
const RoadRunoff: WrtWestcountryCsiPollutionSource = "roadRunoff";
const Other: WrtWestcountryCsiPollutionSource = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiPollutionSourceEnum = {
  ...createEnum("WrtWestcountryCsiPollutionSource", [
    [None, "None"],
    [ActiveOutfalls, "Active outfall(s)"],
    [InactiveOutfalls, "Inactive outfall(s)"],
    [OutfallDiscolouration, "Outfall causing discolouration"],
    [OutfallOdour, "Outfall causing odour"],
    [FarmRunoff, "Farm runoff (slurry/silage)"],
    [GreyWater, "Grey water (misconnection)"],
    [CattleOrStock, "Cattle/stock access to river"],
    [RiverBankCollapse, "Collapsed river bank"],
    [SoilRunoff, "Soil runoff"],
    [RoadRunoff, "Road runoff"],
    [Other, "Other (please specify)"],
  ]),
  None,
  ActiveOutfalls,
  InactiveOutfalls,
  OutfallDiscolouration,
  OutfallOdour,
  FarmRunoff,
  GreyWater,
  CattleOrStock,
  RiverBankCollapse,
  SoilRunoff,
  RoadRunoff,
  Other,
};

export default WrtWestcountryCsiPollutionSourceEnum;
