// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopPoaching = "High" | "Medium" | "Low" | "None";

const High: UclPitStopPoaching = "High";
const Medium: UclPitStopPoaching = "Medium";
const Low: UclPitStopPoaching = "Low";
const None: UclPitStopPoaching = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopPoachingEnum = {
  ...createEnum("UclPitStopPoaching", [
    [High, "High - Evidence of animals during visit; muddy edges very poached"],
    [Medium, "Medium - Evidence of muddy poached edges"],
    [Low, "Low - Slight evidence of trampling around pond margins"],
    [None, "None - No evidence of poaching/trampling"],
  ]),
  High,
  Medium,
  Low,
  None,
};

export default UclPitStopPoachingEnum;
