// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeRestorationPrioritiesChemicalMeasure =
  | "AddressPointPollution"
  | "AddressDiffusePollution"
  | "RemoveNutrientRichSediments";

const AddressPointPollution: NeLakeRestorationPrioritiesChemicalMeasure =
  "AddressPointPollution";
const AddressDiffusePollution: NeLakeRestorationPrioritiesChemicalMeasure =
  "AddressDiffusePollution";
const RemoveNutrientRichSediments: NeLakeRestorationPrioritiesChemicalMeasure =
  "RemoveNutrientRichSediments";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeRestorationPrioritiesChemicalMeasureEnum = {
  ...createEnum("NeLakeRestorationPrioritiesChemicalMeasure", [
    [AddressPointPollution, "Address point sources of pollution"],
    [AddressDiffusePollution, "Address diffuse pollution"],
    [
      RemoveNutrientRichSediments,
      "Remove nutrient-rich sediments (for shallow lakes)",
    ],
  ]),
  AddressPointPollution,
  AddressDiffusePollution,
  RemoveNutrientRichSediments,
};

export default NeLakeRestorationPrioritiesChemicalMeasureEnum;
