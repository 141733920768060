// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPondCountSize = "A" | "B" | "C" | "D" | "E";

const A: FhtPondCountSize = "A";
const B: FhtPondCountSize = "B";
const C: FhtPondCountSize = "C";
const D: FhtPondCountSize = "D";
const E: FhtPondCountSize = "E";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPondCountSizeEnum = {
  ...createEnum("FhtPondCountSize", [
    [A, "1m2 - 25m2"],
    [B, "25m2 - 400m2"],
    [C, "400m2 - 2000m2"],
    [D, "2000m2 - 1ha"],
    [E, "1ha - 2ha"],
  ]),
  A,
  B,
  C,
  D,
  E,
};

export default FhtPondCountSizeEnum;
