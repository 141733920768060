// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21SlowFlowSuitability =
  | "DryFlowPath"
  | "StreamOrDitch"
  | "PondCreation"
  | "WoodlandCreation"
  | "RestorationPotential"
  | "ReconnectionPotential";

const DryFlowPath: Thames21SlowFlowSuitability = "DryFlowPath";
const StreamOrDitch: Thames21SlowFlowSuitability = "StreamOrDitch";
const PondCreation: Thames21SlowFlowSuitability = "PondCreation";
const WoodlandCreation: Thames21SlowFlowSuitability = "WoodlandCreation";
const RestorationPotential: Thames21SlowFlowSuitability =
  "RestorationPotential";
const ReconnectionPotential: Thames21SlowFlowSuitability =
  "ReconnectionPotential";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21SlowFlowSuitabilityEnum = {
  ...createEnum("Thames21SlowFlowSuitability", [
    [DryFlowPath, "Dry, damp or occasional flow path"],
    [StreamOrDitch, "Stream or ditch with visible flow"],
    [PondCreation, "Area suitable for pond creation"],
    [WoodlandCreation, "Area suitable for woodland or hedgerow creation"],
    [RestorationPotential, "River with potential for restoration"],
    [ReconnectionPotential, "River with potential to reconnect to floodplain"],
  ]),
  DryFlowPath,
  StreamOrDitch,
  PondCreation,
  WoodlandCreation,
  RestorationPotential,
  ReconnectionPotential,
};

export default Thames21SlowFlowSuitabilityEnum;
