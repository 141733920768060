// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { QualificationRegisterSharing } from "../types.generated";

const Public: QualificationRegisterSharing = "Public";
const Unlisted: QualificationRegisterSharing = "Unlisted";
const Private: QualificationRegisterSharing = "Private";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const QualificationRegisterSharingEnum = {
  ...createEnum("QualificationRegisterSharing", [
    [Public, "Searchable on public register"],
    [Unlisted, "Sharing by link only"],
    [Private, "Not publicly visible"],
  ]),
  Public,
  Unlisted,
  Private,
};

export default QualificationRegisterSharingEnum;
