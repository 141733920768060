import {
  attachmentAttr,
  attributeGroup,
  enumAttr,
  lookupBucket,
  MapId,
  MapSchema,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  thresholdAttr,
  thresholdBucket,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { SurveyModuleId, unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { moduleNumberAttr, surveyTypeAttr } from "./helpers";
import { UrsSurveyBankEnum } from "@cartographerio/inventory-enums";

function morphEstuariesMap(
  mapId: MapId,
  title: string,
  module: SurveyModuleId
): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,
    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId),
          attribution: [],
        }),
        defaultAttribute: "riverName",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "General",
            attributes: [
              stringAttr({
                attributeId: "riverName",
                label: "Estuary",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "subreachName",
                label: "Subreach",
                buckets: "auto",
              }),
              moduleNumberAttr,
              stringAttr({
                attributeId: "projectName",
                label: "Project Name",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectCode",
                label: "Project Code",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "wfdWaterbodyId",
                label: "WFD Waterbody ID",
                buckets: "auto",
              }),
              surveyTypeAttr,
              teamAttr("teamId", "Team", { module }),
              surveyAttr("surveyId", "Survey"),
              attachmentAttr("photographs", "Photographs"),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
              enumAttr({
                attributeId: "surveyBank",
                label: "Surveyed Bank",
                enum: UrsSurveyBankEnum,
              }),
            ],
          }),
          attributeGroup({
            label: "Indices (WIP)",
            attributes: [
              thresholdAttr({
                attributeId: "E1",
                label:
                  "E1: Number of Present/Extensive (Exposed) Sediment Material Types",
                buckets: [
                  thresholdBucket(0, marker.quality(0 / 6), "<2"),
                  thresholdBucket(2, marker.quality(1 / 6), "2 to <3"),
                  thresholdBucket(3, marker.quality(2 / 6), "3 to <4"),
                  thresholdBucket(4, marker.quality(3 / 6), "4 to <5"),
                  thresholdBucket(5, marker.quality(4 / 6), "5 to <6"),
                  thresholdBucket(6, marker.quality(5 / 6), "6 to <7"),
                  thresholdBucket(7, marker.quality(6 / 6), "7+"),
                ],
              }),
              stringAttr({
                attributeId: "E2",
                label:
                  "E2: Coarsest Present/Extensive Mineral Sediment Material Type",
                buckets: [
                  lookupBucket("Bedrock", marker.hue((7 / 8) * 360), "Bedrock"),
                  lookupBucket("Boulder", marker.hue((6 / 8) * 360), "Boulder"),
                  lookupBucket("Cobble", marker.hue((5 / 8) * 360), "Cobble"),
                  lookupBucket(
                    "GravelPebble",
                    marker.hue((4 / 8) * 360),
                    "Gravel-Pebble"
                  ),
                  lookupBucket(
                    "ShellyMaterial",
                    marker.hue((3 / 8) * 360),
                    "Shelly Material"
                  ),
                  lookupBucket("Sand", marker.hue((2 / 8) * 360), "Sand"),
                  lookupBucket(
                    "FineSediments",
                    marker.hue((1 / 8) * 360),
                    "Fine Sediments"
                  ),
                  lookupBucket(
                    "FluidMud",
                    marker.hue((0 / 8) * 360),
                    "Fluid Mud"
                  ),
                ],
              }),
              numberAttr({
                attributeId: "E3",
                label: "E3: Estuary Margin Profile",
                buckets: [
                  rangeBucket(66, null, marker.quality(7 / 7)),
                  rangeBucket(40, 66, marker.quality(6 / 7)),
                  rangeBucket(18, 40, marker.quality(5 / 7)),
                  rangeBucket(1, 18, marker.quality(4 / 7)),
                  rangeBucket(-20, 1, marker.quality(3 / 7)),
                  rangeBucket(-100, -20, marker.quality(2 / 7)),
                  rangeBucket(-250, -100, marker.quality(1 / 7)),
                  rangeBucket(null, -250, marker.quality(0 / 7)),
                ],
              }),
              numberAttr({
                attributeId: "E4",
                label: "E4: Marginal Habitat Richness for Biota",
                buckets: [
                  rangeBucket(null, 3, marker.quality(5 / 10)),
                  rangeBucket(3, 6, marker.quality(6 / 10)),
                  rangeBucket(6, 9, marker.quality(7 / 10)),
                  rangeBucket(9, 12, marker.quality(8 / 10)),
                  rangeBucket(12, 15, marker.quality(9 / 10)),
                  rangeBucket(15, null, marker.quality(10 / 10)),
                ],
              }),
              numberAttr({
                attributeId: "E5",
                label:
                  "E5: Subtidal & Unvegetated Intertidal Physical Habitat Complexity",
                buckets: [
                  rangeBucket(0, 1, marker.quality(0 / 6)),
                  rangeBucket(1, 2, marker.quality(1 / 6)),
                  rangeBucket(2, 3, marker.quality(2 / 6)),
                  rangeBucket(3, 4, marker.quality(3 / 6)),
                  rangeBucket(4, 5, marker.quality(4 / 6)),
                  rangeBucket(5, 6, marker.quality(5 / 6)),
                  rangeBucket(6, null, marker.quality(6 / 6)),
                ],
              }),
              numberAttr({
                attributeId: "E6",
                label:
                  "E6: Vegetated Intertidal Natural Features Richness (Physical Habitat Complexity)",
                buckets: [
                  rangeBucket(0, 2, marker.quality(5 / 10)),
                  rangeBucket(2, 4, marker.quality(6 / 10)),
                  rangeBucket(4, 6, marker.quality(7 / 10)),
                  rangeBucket(6, 8, marker.quality(8 / 10)),
                  rangeBucket(8, 10, marker.quality(9 / 10)),
                  rangeBucket(10, null, marker.quality(10 / 10)),
                ],
              }),
              numberAttr({
                attributeId: "E7",
                label: "E7: Supratidal & Riparian Physical Habitat Complexity",
                buckets: [
                  rangeBucket(0, 2, marker.quality(5 / 10)),
                  rangeBucket(2, 4, marker.quality(6 / 10)),
                  rangeBucket(4, 6, marker.quality(7 / 10)),
                  rangeBucket(6, 8, marker.quality(8 / 10)),
                  rangeBucket(8, 10, marker.quality(9 / 10)),
                  rangeBucket(10, null, marker.quality(10 / 10)),
                ],
              }),
              numberAttr({
                attributeId: "E8",
                label:
                  "E8: Degree of Human Pressure Imposed by Supratidal Land Cover",
                buckets: [
                  rangeBucket(null, -20, marker.quality(0 / 7)),
                  rangeBucket(-20, -15, marker.quality(1 / 7)),
                  rangeBucket(-15, -10, marker.quality(2 / 7)),
                  rangeBucket(-10, -8, marker.quality(3 / 7)),
                  rangeBucket(-8, -6, marker.quality(4 / 7)),
                  rangeBucket(-6, -4, marker.quality(5 / 7)),
                  rangeBucket(-4, -2, marker.quality(6 / 7)),
                  rangeBucket(-2, 0, marker.quality(7 / 7)),
                ],
              }),
              numberAttr({
                attributeId: "E9",
                label: "E9: Channel Reinforcement",
                buckets: [
                  rangeBucket(null, -10, marker.quality(0 / 10)),
                  rangeBucket(-10, -8, marker.quality(2 / 10)),
                  rangeBucket(-8, -6, marker.quality(4 / 10)),
                  rangeBucket(-6, -4, marker.quality(6 / 10)),
                  rangeBucket(-4, -2, marker.quality(8 / 10)),
                  rangeBucket(-2, 0, marker.quality(10 / 10)),
                ],
              }),
              numberAttr({
                attributeId: "E10",
                label: "E10: Vegetated Intertidal Signs of Erosion",
                buckets: [
                  rangeBucket(null, -10, marker.quality(0 / 10)),
                  rangeBucket(-10, -8, marker.quality(2 / 10)),
                  rangeBucket(-8, -6, marker.quality(4 / 10)),
                  rangeBucket(-6, -4, marker.quality(6 / 10)),
                  rangeBucket(-4, -2, marker.quality(8 / 10)),
                  rangeBucket(-2, 0, marker.quality(10 / 10)),
                ],
              }),
              numberAttr({
                attributeId: "E11",
                label: "E11: Terrestrial Non-Native Invasive Plant Extent",
                buckets: [
                  rangeBucket(null, -10, marker.quality(0 / 10)),
                  rangeBucket(-10, -8, marker.quality(2 / 10)),
                  rangeBucket(-8, -6, marker.quality(4 / 10)),
                  rangeBucket(-6, -4, marker.quality(6 / 10)),
                  rangeBucket(-4, -2, marker.quality(8 / 10)),
                  rangeBucket(-2, 0, marker.quality(10 / 10)),
                ],
              }),
              numberAttr({
                attributeId: "E12",
                label: "E12: Litter",
                buckets: [
                  rangeBucket(null, -30, marker.quality(0 / 9)),
                  rangeBucket(-30, -25, marker.quality(1 / 9)),
                  rangeBucket(-25, -20, marker.quality(2 / 9)),
                  rangeBucket(-20, -15, marker.quality(3 / 9)),
                  rangeBucket(-15, -10, marker.quality(4 / 9)),
                  rangeBucket(-10, -8, marker.quality(5 / 9)),
                  rangeBucket(-8, -6, marker.quality(6 / 9)),
                  rangeBucket(-6, -4, marker.quality(7 / 9)),
                  rangeBucket(-4, -2, marker.quality(8 / 9)),
                  rangeBucket(-2, 0, marker.quality(9 / 9)),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
}

export const MrsMorphEstuaries = morphEstuariesMap(
  unsafeMapId("mrsMorphEstuaries"),
  "MoRPh Estuaries",
  unsafeSurveyModuleId("mrsMorphEstuaries")
);

export const MrsMorphEstuariesPro = morphEstuariesMap(
  unsafeMapId("mrsMorphEstuariesPro"),
  "MoRPh Estuaries",
  unsafeSurveyModuleId("mrsMorphEstuariesPro")
);
