import { unsafeMapId } from "@cartographerio/topo-map";
import { mapSchemaWithDefaults } from "../../core";
import { NeLakeRestorationPriorities } from "./NeLakeRestorationPriorities";
import { NeRiverRestorationPriorities } from "./NeRiverRestorationPriorities";
import { MapBaseEnum } from "@cartographerio/types";

export const NeRestorationPriorities = mapSchemaWithDefaults({
  mapId: unsafeMapId("neRestorationPriorities"),
  title: "River/Stream/Lake Restoration Priorities",
  defaultBase: MapBaseEnum.Satellite,
  layers: [
    ...NeRiverRestorationPriorities.layers,
    ...NeLakeRestorationPriorities.layers,
  ],
});
