import { PlanId, Plan as BillingPlan } from "@cartographerio/types";
import { allCommercialPlans } from "./commercial";
import { allNonCommercialPlans } from "./nonCommercial";
import { PricingPlan } from "./plan";

export * from "./commercial";
export { allFeatures } from "./features";
export type { FeatureSupport } from "./features";
export * from "./nonCommercial";
export type { PricingPlan } from "./plan";
export { allCommercialPlans, allNonCommercialPlans };

export const allPlans = [...allCommercialPlans, ...allNonCommercialPlans];

export function findPricingPlan(planId: PlanId): PricingPlan | null {
  return allPlans.find(plan => plan.id === planId) ?? null;
}

export function groupPlans(
  billingPlans: BillingPlan[]
): [BillingPlan, PricingPlan | null][] {
  return billingPlans.map(billingPlan => [
    billingPlan,
    allPlans.find(pricingPlan => billingPlan.id === pricingPlan.id) ?? null,
  ]);
}
