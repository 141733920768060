// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiWaterBodyType =
  | "river"
  | "stream"
  | "lake"
  | "pond"
  | "estuaryOrTidal"
  | "other";

const River: WrtWestcountryCsiWaterBodyType = "river";
const Stream: WrtWestcountryCsiWaterBodyType = "stream";
const Lake: WrtWestcountryCsiWaterBodyType = "lake";
const Pond: WrtWestcountryCsiWaterBodyType = "pond";
const EstuaryOrTidal: WrtWestcountryCsiWaterBodyType = "estuaryOrTidal";
const Other: WrtWestcountryCsiWaterBodyType = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiWaterBodyTypeEnum = {
  ...createEnum("WrtWestcountryCsiWaterBodyType", [
    [River, "River"],
    [Stream, "Stream"],
    [Lake, "Lake"],
    [Pond, "Pond"],
    [EstuaryOrTidal, "Estuary/tidal"],
    [Other, "Other"],
  ]),
  River,
  Stream,
  Lake,
  Pond,
  EstuaryOrTidal,
  Other,
};

export default WrtWestcountryCsiWaterBodyTypeEnum;
