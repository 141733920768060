// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type OutfallSafariFlowOfDischarge =
  | "NoFlow"
  | "Trickle"
  | "Low"
  | "Moderate"
  | "High";

const NoFlow: OutfallSafariFlowOfDischarge = "NoFlow";
const Trickle: OutfallSafariFlowOfDischarge = "Trickle";
const Low: OutfallSafariFlowOfDischarge = "Low";
const Moderate: OutfallSafariFlowOfDischarge = "Moderate";
const High: OutfallSafariFlowOfDischarge = "High";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OutfallSafariFlowOfDischargeEnum = {
  ...createEnum("OutfallSafariFlowOfDischarge", [
    [NoFlow, "No flow"],
    [Trickle, "Trickle"],
    [Low, "Low flow"],
    [Moderate, "Moderate flow"],
    [High, "High flow"],
  ]),
  NoFlow,
  Trickle,
  Low,
  Moderate,
  High,
};

export default OutfallSafariFlowOfDischargeEnum;
