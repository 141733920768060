import {
  topoExpr,
  dynamicAttr,
  dynamicGrid,
  form,
  page,
  section,
} from "@cartographerio/topo-form";
import {
  ExtendedRiverflySpecies,
  ExtendedRiverflySpeciesEnum,
} from "@cartographerio/inventory-enums";
import {
  extendedRiverflyConversions,
  extendedRiverflySiltAndFlowCalculations,
  extendedRiverflyWaterQualityCalculations,
} from "../Riverfly/calculations";
import {
  armiScoreAttribute,
  armiTriggerLevelAttribute,
  armiTriggerLevelBreached,
  armiTriggerLevelBreachedAttribute,
  armiTriggerLevelBreachedSections,
  armiTriggerLevelBreachOkSection,
  sampleCountsSection,
  riverflyFixedPointPhotographsSection,
  riverflyNotesSection,
  riverflyPollutionPhotographsSection,
  riverflyTopSections,
  isRiverflyARMICounts,
} from "../Riverfly/form";

const waterAndQualityScoreAttribute = dynamicAttr({
  label: "Water Quality Score",
  valueType: "number",
  value: topoExpr(env =>
    env
      .getAs(
        ["data", "observations", "counts"],
        isRiverflyARMICounts(
          ExtendedRiverflySpeciesEnum,
          extendedRiverflyConversions
        )
      )
      .map(counts =>
        extendedRiverflyWaterQualityCalculations.total(
          extendedRiverflyWaterQualityCalculations.scores(counts, 0)
        )
      )
      .getOrNull()
  ),
});

const siltAndFlowScoreAttribute = dynamicAttr({
  label: "Silt and Flow Score",
  valueType: "number",
  value: topoExpr(env =>
    env
      .getAs(
        ["data", "observations", "counts"],
        isRiverflyARMICounts(
          ExtendedRiverflySpeciesEnum,
          extendedRiverflyConversions
        )
      )
      .map(counts =>
        extendedRiverflySiltAndFlowCalculations.total(
          extendedRiverflySiltAndFlowCalculations.scores(counts, 0)
        )
      )
      .getOrNull()
  ),
});

const extendedRiverflyTriggerLevelBreached = armiTriggerLevelBreached(
  ExtendedRiverflySpeciesEnum,
  extendedRiverflyConversions
);

export default form({
  title: "Extended Riverfly",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        ...riverflyTopSections("extendedRiverfly"),
        sampleCountsSection<ExtendedRiverflySpecies>(
          ExtendedRiverflySpeciesEnum,
          value =>
            `https://media.cartographer.io/static/images/extended-riverfly/${value}.jpg`,
          extendedRiverflyConversions,
          [
            extendedRiverflyWaterQualityCalculations,
            extendedRiverflySiltAndFlowCalculations,
          ]
        ),
        section({
          title: "Sample Scores",
          path: [],
          blocks: [
            dynamicGrid({
              columns: 3,
              attributes: [
                armiScoreAttribute<ExtendedRiverflySpecies>(
                  ExtendedRiverflySpeciesEnum,
                  extendedRiverflyConversions
                ),
                armiTriggerLevelAttribute,
                armiTriggerLevelBreachedAttribute<ExtendedRiverflySpecies>(
                  ExtendedRiverflySpeciesEnum,
                  extendedRiverflyConversions
                ),
                waterAndQualityScoreAttribute,
                siltAndFlowScoreAttribute,
              ],
            }),
          ],
        }),
        armiTriggerLevelBreachOkSection(extendedRiverflyTriggerLevelBreached),
        ...armiTriggerLevelBreachedSections(
          extendedRiverflyTriggerLevelBreached
        ),
        riverflyPollutionPhotographsSection(
          extendedRiverflyTriggerLevelBreached
        ),
        riverflyFixedPointPhotographsSection,
        riverflyNotesSection,
      ],
    }),
  ],
});
