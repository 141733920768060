import {
  Thames21LitterCategory,
  Thames21LitterCategoryEnum,
  Thames21LitterItem,
  Thames21LitterItemEnum,
} from "@cartographerio/inventory-enums";

type Cat = Thames21LitterCategory;
type Item = Thames21LitterItem;

const CatEnum = Thames21LitterCategoryEnum;
const ItemEnum = Thames21LitterItemEnum;

function label(cat: Cat, item: Item): string {
  return `${CatEnum.labelOf(cat)}: ${ItemEnum.labelOf(item)}`;
}

export function litterItemLabel(item: Item): string {
  switch (item) {
    case ItemEnum.FoodPlasticCutlery:
    case ItemEnum.FoodPlasticStraw:
    case ItemEnum.FoodPlasticStirrer:
    case ItemEnum.FoodPlasticLollipopStick:
    case ItemEnum.FoodPlasticFoodWrapper:
    case ItemEnum.FoodPlasticTakeAwayContainer:
    case ItemEnum.FoodPlasticDrinkBottle:
    case ItemEnum.FoodPlasticBottleTop:
    case ItemEnum.FoodPlasticPlasticLid:
    case ItemEnum.FoodPlasticCup:
    case ItemEnum.FoodPlasticPlate:
    case ItemEnum.FoodPlastic6Or4PackRings:
    case ItemEnum.FoodPlasticWineCork:
    case ItemEnum.FoodPlasticHardContainer:
    case ItemEnum.FoodPlasticIcePopContainer:
    case ItemEnum.FoodPlasticOther:
      return label(CatEnum.FoodPlastic, item);

    case ItemEnum.FoodMetalCutlery:
    case ItemEnum.FoodMetalDrinksCan:
    case ItemEnum.FoodMetaltinCan:
    case ItemEnum.FoodMetalBottleTop:
    case ItemEnum.FoodMetalTinFoilWrapping:
    case ItemEnum.FoodMetalOther:
      return label(CatEnum.FoodMetal, item);

    case ItemEnum.FoodTetraPakDrinkCarton:
    case ItemEnum.FoodTetraPakCoffeeCup:
      return label(CatEnum.FoodTetraPak, item);

    case ItemEnum.FoodPaperWoodCorkGlassCupOrPaperPlate:
    case ItemEnum.FoodPaperWoodCorkGlassStirrer:
    case ItemEnum.FoodPaperWoodCorkGlassBottle:
    case ItemEnum.FoodPaperWoodCorkGlassCork:
      return label(CatEnum.FoodPaperWoodCorkGlass, item);

    case ItemEnum.FoodPolystyreneTakeAwayContainer:
    case ItemEnum.FoodPolystyreneCup:
    case ItemEnum.FoodPolystyreneOther:
      return label(CatEnum.FoodPolystyrene, item);

    case ItemEnum.FoodOther:
      return label(CatEnum.FoodOther, item);

    case ItemEnum.PackagingPlasticThickShoppingBag:
    case ItemEnum.PackagingPlasticThinShoppingBag:
    case ItemEnum.PackagingPlasticRefuseSack:
    case ItemEnum.PackagingPlasticPlasticBottleOrContainer:
    case ItemEnum.PackagingPlasticOtherPackagingPlastic:
    case ItemEnum.PackagingPlasticFoamRoll:
    case ItemEnum.PackagingPlasticOther:
      return label(CatEnum.PackagingPlastic, item);

    case ItemEnum.SewageMixedCondom:
    case ItemEnum.SewageMixedTamponOrApplicator:
    case ItemEnum.SewageMixedSanitaryTowel:
    case ItemEnum.SewageMixedCottonBudStick:
    case ItemEnum.SewageMixedNappy:
    case ItemEnum.SewageMixedSyringeOrNeedle:
    case ItemEnum.SewageMixedMedicinePackaging:
    case ItemEnum.SewageMixedWetWipesOrBabyWipes:
    case ItemEnum.SewageMixedPlasticGloves:
    case ItemEnum.SewageMixedEyeDropDispenser:
    case ItemEnum.SewageMixedPlaster:
    case ItemEnum.SewageMixedOther:
      return label(CatEnum.SewageMixed, item);

    case ItemEnum.SmokingMixedLighter:
    case ItemEnum.SmokingMixedCigaretteButt:
    case ItemEnum.SmokingMixedCigaretteOrTobaccoPackaging:
    case ItemEnum.SmokingMixedOther:
      return label(CatEnum.SmokingMixed, item);

    case ItemEnum.ClothingMixedFaceMask:
    case ItemEnum.ClothingMixedShoe:
    case ItemEnum.ClothingMixedItemOfClothing:
    case ItemEnum.ClothingMixedOther:
      return label(CatEnum.ClothingMixed, item);

    case ItemEnum.MiscMixedRope:
    case ItemEnum.MiscMixedString:
    case ItemEnum.MiscMixedRubberBand:
    case ItemEnum.MiscMixedSpecksOfPaint:
    case ItemEnum.MiscNurdles:
      return label(CatEnum.MiscMixed, item);

    case ItemEnum.FishingRecreational:
    case ItemEnum.FishingCommercial:
      return label(CatEnum.Fishing, item);

    case ItemEnum.UnidentifiedPlasticSmall:
    case ItemEnum.UnidentifiedPlasticMedium:
    case ItemEnum.UnidentifiedPlasticLarge:
      return label(CatEnum.UnidentifiedPlastic, item);

    case ItemEnum.UnidentifiedPolystyrene:
      return label(CatEnum.UnidentifiedPolystyrene, item);

    case ItemEnum.UnidentifiedClothSmall:
    case ItemEnum.UnidentifiedClothMedium:
    case ItemEnum.UnidentifiedClothLarge:
      return label(CatEnum.UnidentifiedCloth, item);

    case ItemEnum.UnidentifiedFoamSmall:
    case ItemEnum.UnidentifiedFoamMedium:
    case ItemEnum.UnidentifiedFoamLarge:
      return label(CatEnum.UnidentifiedFoam, item);

    case ItemEnum.GlassOther:
      return label(CatEnum.GlassOther, item);

    case ItemEnum.MetalOther:
      return label(CatEnum.MetalOther, item);

    case ItemEnum.LargeMixedShoppingTrolley:
    case ItemEnum.LargeMixedTyre:
    case ItemEnum.LargeMixedTrafficConeOrSignOrBarrier:
    case ItemEnum.LargeMixedLargeAppliance:
    case ItemEnum.LargeMixedSmallAppliance:
    case ItemEnum.LargeMixedMattress:
    case ItemEnum.LargeMixedCarpet:
    case ItemEnum.LargeMixedOther:
      return label(CatEnum.LargeMixed, item);

    case ItemEnum.BuildingPlasticBuildingInsulationPieces:
    case ItemEnum.BuildingPlasticCableTie:
    case ItemEnum.BuildingPlasticRawlPlug:
    case ItemEnum.BuildingPlasticWindowSpacerTiles:
    case ItemEnum.BuildingPlasticScaffoldBungee:
    case ItemEnum.BuildingPlasticPipeLagging:
    case ItemEnum.BuildingPlasticTileCrossSpacers:
      return label(CatEnum.BuildingPlastic, item);

    case ItemEnum.DomesticPlasticBall:
    case ItemEnum.DomesticPlasticBalloonOrStick:
    case ItemEnum.DomesticPlasticGardening:
    case ItemEnum.DomesticPlasticNerfBullet:
    case ItemEnum.DomesticPlasticPackagingPeanut:
    case ItemEnum.DomesticPlasticPen:
      return label(CatEnum.DomesticPlastic, item);
  }
}
