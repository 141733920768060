// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type SasWaterQualityWeather = "Clear" | "LightRain" | "HeavyRain";

const Clear: SasWaterQualityWeather = "Clear";
const LightRain: SasWaterQualityWeather = "LightRain";
const HeavyRain: SasWaterQualityWeather = "HeavyRain";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SasWaterQualityWeatherEnum = {
  ...createEnum("SasWaterQualityWeather", [
    [Clear, "Clear"],
    [LightRain, "Light Rain"],
    [HeavyRain, "Heavy Rain"],
  ]),
  Clear,
  LightRain,
  HeavyRain,
};

export default SasWaterQualityWeatherEnum;
