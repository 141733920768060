// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesWaterFeatureCondition = "1" | "2" | "3" | "4" | "5";

const One: CesWaterFeatureCondition = "1";
const Two: CesWaterFeatureCondition = "2";
const Three: CesWaterFeatureCondition = "3";
const Four: CesWaterFeatureCondition = "4";
const Five: CesWaterFeatureCondition = "5";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesWaterFeatureConditionEnum = {
  ...createEnum("CesWaterFeatureCondition", [
    [One, "No vegetation encroachment"],
    [Two, "Marginal aquatic plants"],
    [Three, "Choked with aquatic plants"],
    [Four, "Marginal encroachment by riparian plants"],
    [Five, "Choked with riparian and aquatic plants"],
  ]),
  One,
  Two,
  Three,
  Four,
  Five,
};

export default CesWaterFeatureConditionEnum;
