// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type RiverflySecondBreachAction = "Coordinator" | "Hotline" | "None";

const Coordinator: RiverflySecondBreachAction = "Coordinator";
const Hotline: RiverflySecondBreachAction = "Hotline";
const None: RiverflySecondBreachAction = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RiverflySecondBreachActionEnum = {
  ...createEnum("RiverflySecondBreachAction", [
    [Coordinator, "Reported to Coordinator"],
    [Hotline, "Reported to Hotline"],
    [None, "None"],
  ]),
  Coordinator,
  Hotline,
  None,
};

export default RiverflySecondBreachActionEnum;
