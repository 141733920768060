import {
  topoExpr,
  TopoExpr,
  attachmentField,
  bodyText,
  customRule,
  dynamicAttr,
  dynamicGrid,
  featureFieldCartographerMapStyle,
  form,
  multiSelect,
  nearestFeatureField,
  otherSpecify,
  page,
  required,
  requiredIfV2,
  section,
  select,
  textArea,
  timestampField,
} from "@cartographerio/topo-form";
import { isPoint, pointToNgr } from "@cartographerio/geometry";
import {
  isArray,
  isArrayOf,
  isBoolean,
  isNullable,
  isNumber,
  isString,
} from "@cartographerio/guard";
import {
  FhtPondCountSizeEnum,
  FhtPondCountPresence as Presence,
  FhtPondCountPresenceEnum as PresenceEnum,
  FhtPondCountSizeEnum as SizeEnum,
  FhtPondCountWhyAbsent as WhyAbsent,
  FhtPondCountWhyAbsentEnum as WhyAbsentEnum,
  FhtPondCountWhyCreated as WhyCreated,
  FhtPondCountWhyCreatedEnum as WhyCreatedEnum,
  FhtPondCountWhyLostOrDestroyed as WhyLostOrDestroyed,
  FhtPondCountWhyLostOrDestroyedEnum as WhyLostOrDestroyedEnum,
  FhtPondCountWhyMissedEnum as WhyMissedEnum,
  FhtPondCountWhyNew as WhyNew,
  FhtPondCountWhyNewEnum as WhyNewEnum,
  FhtPondCountWhySizeChanged as WhySizeChanged,
  FhtPondCountWhySizeChangedEnum as WhySizeChangedEnum,
} from "@cartographerio/inventory-enums";
import { unsafeMapLayerId } from "@cartographerio/types";
import { outdent } from "outdent";

function pondPresenceEquals(presence: Presence): TopoExpr<boolean> {
  return topoExpr(env => {
    const value = env
      .getAbsoluteAs(["data", "presence"], isNullable(PresenceEnum.isValue))
      .getOrElse(() => null);

    return value === presence;
  });
}

function pondWhyNewEquals(why: WhyNew): TopoExpr<boolean> {
  return topoExpr(env => {
    const value = env
      .getAbsoluteAs(
        ["data", "newPond", "whyNew"],
        isNullable(WhyNewEnum.isValue)
      )
      .getOrNull();

    return value === why;
  });
}

function pondSizeCorrectEquals(correct: boolean): TopoExpr<boolean> {
  return topoExpr(env => {
    const value = env
      .getAbsoluteAs(
        ["data", "existingPond", "sizeCorrect"],
        isNullable(isBoolean)
      )
      .getOrElse(() => null);

    return value === correct;
  });
}

function pondSizeWhyChangedEquals(why: WhySizeChanged): TopoExpr<boolean> {
  return topoExpr(env => {
    const value = env.getAbsolute([
      "data",
      "existingPond",
      "whySizeChanged",
      "selected",
    ]);

    return value === why;
  });
}

function pondWhyCreatedIncludes(why: WhyCreated): TopoExpr<boolean> {
  return topoExpr(env => {
    const value = env
      .getAbsoluteAs(
        ["data", "newPond", "whyCreated", "selected"],
        isArrayOf(isString)
      )
      .getOrElse<string[]>(() => []);
    return value.includes(why);
  });
}

function pondWhyAbsentEquals(why: WhyAbsent): TopoExpr<boolean> {
  return topoExpr(env => {
    const value = env
      .getAbsoluteAs(
        ["data", "absentPond", "whyAbsent"],
        isNullable(WhyAbsentEnum.isValue)
      )
      .getOrNull();
    return value === why;
  });
}

function pondWhyLostOrDestroyedIncludes(
  whyLost: WhyLostOrDestroyed
): TopoExpr<boolean> {
  return topoExpr(env => {
    const value = env
      .getAbsoluteAs(
        ["data", "absentPond", "whyLostOrDestroyed", "selected"],
        isArrayOf(isString)
      )
      .getOrElse<string[]>(() => []);

    return value.includes(whyLost);
  });
}

const pondAttributeGrid = dynamicGrid({
  columns: 3,
  attributes: [
    dynamicAttr({
      label: "Square",
      valueType: "string",
      value: topoExpr(env =>
        env
          .getAs(["data", "picked", "point"], isNullable(isPoint))
          .map(point =>
            point == null ? null : pointToNgr(point, 4, false) ?? null
          )
          .getOrNull()
      ),
    }),
    dynamicAttr({
      label: "Pond",
      valueType: "string",
      value: topoExpr(env =>
        env
          .getAs(
            ["data", "picked", "feature", "properties", "Pond_Number"],
            isNullable(isString)
          )
          .getOrNull()
      ),
    }),
    dynamicAttr({
      label: "Area",
      valueType: "string",
      value: topoExpr(env =>
        env
          .getAs(
            ["data", "picked", "feature", "properties", "Area_Class"],
            isNullable(isNumber)
          )
          .map(num => {
            switch (num) {
              case 0:
                return FhtPondCountSizeEnum.labelOf("A");
              case 1:
                return FhtPondCountSizeEnum.labelOf("B");
              case 2:
                return FhtPondCountSizeEnum.labelOf("C");
              case 3:
                return FhtPondCountSizeEnum.labelOf("D");
              case 4:
                return FhtPondCountSizeEnum.labelOf("E");
              default:
                return `Unknown area class: ${num}`;
            }
          })
          .getOrNull()
      ),
    }),
    // dynamicAttr({
    //   label: "OBJECTID",
    //   valueType: "number",
    //   value: topoExpr(env =>
    //     env
    //       .getAs(
    //         ["data", "picked", "feature", "properties", "OBJECTID"],
    //         isNullable(isNumber)
    //       )
    //       .getOrNull()
    //   ),
    //   decimalPlaces: 0,
    // }),
    // dynamicAttr({
    //   label: "Square_Grid_Ref",
    //   valueType: "string",
    //   value: topoExpr(env =>
    //     env
    //       .getAs(
    //         ["data", "picked", "feature", "properties", "Square_Grid_Ref"],
    //         isNullable(isString)
    //       )
    //       .getOrNull()
    //   ),
    // }),
    // dynamicAttr({
    //   label: "Pond_Grid_ref",
    //   valueType: "string",
    //   value: topoExpr(env =>
    //     env
    //       .getAs(
    //         ["data", "picked", "feature", "properties", "Pond_Grid_ref"],
    //         isNullable(isString)
    //       )
    //       .getOrNull()
    //   ),
    // }),
    // dynamicAttr({
    //   label: "Square_name",
    //   valueType: "string",
    //   value: topoExpr(env =>
    //     env
    //       .getAs(
    //         ["data", "picked", "feature", "properties", "Square_name"],
    //         isNullable(isString)
    //       )
    //       .getOrNull()
    //   ),
    // }),
    // dynamicAttr({
    //   label: "Pond_Number",
    //   valueType: "string",
    //   value: topoExpr(env =>
    //     env
    //       .getAs(
    //         ["data", "picked", "feature", "properties", "Pond_Number"],
    //         isNullable(isString)
    //       )
    //       .getOrNull()
    //   ),
    // }),
    // dynamicAttr({
    //   label: "Area_Class",
    //   valueType: "number",
    //   value: topoExpr(env =>
    //     env
    //       .getAs(
    //         ["data", "picked", "feature", "properties", "Area_Class"],
    //         isNullable(isNumber)
    //       )
    //       .getOrNull()
    //   ),
    // }),
  ],
});

export default form({
  title: "Urban Pond Count",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: "Pond Number and Location",
          path: [],
          blocks: [
            nearestFeatureField({
              label: "Location",
              path: ["data", "picked"],
              fullWidth: true,
              mapOptions: {
                mapStyle: featureFieldCartographerMapStyle({
                  layer: unsafeMapLayerId("FhtPondCountSite"),
                  geometryType: "Point",
                  primaryKey: "OBJECTID",
                }),
                selectMinZoom: 11,
                attributeColumns: 3,
                attributes: [],
              },
            }),
            pondAttributeGrid,
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Your Survey",
          path: [],
          blocks: [
            timestampField({
              label: "Date/Time",
              path: ["data", "recorded"],
              required: required(),
              defaultValue: "now",
            }),
            textArea({
              label: "Other Surveyors",
              path: ["data", "otherSurveyors"],
              rows: 3,
              help: "If there were other surveyors, please record each on a separate line.",
            }),
          ],
        }),
        section({
          title: "Pond Presence or Absence",
          path: [],
          blocks: [
            select({
              label: "Is the pond present, absent, or a new pond?",
              path: ["data", "presence"],
              options: [
                {
                  value: PresenceEnum.Existing,
                  label:
                    "Existing pond - the pond marked on your map is still there",
                },
                {
                  value: PresenceEnum.Absent,
                  label:
                    "Absent pond - the pond marked on your map is not there",
                },
                {
                  value: PresenceEnum.New,
                  label:
                    "New pond - a new pond not previously marked on your map",
                },
                {
                  value: PresenceEnum.CouldNotAccess,
                  label: "Could not access the pond",
                },
              ],
              required: required("error"),
              customRules: [
                customRule({
                  level: "error",
                  message:
                    "You must select 'New Pond' if the pond is not already visible on the map.",
                  triggerWhen: topoExpr(env => {
                    const presence = env.getFocused();
                    const point = env.getAbsolute(["data", "picked", "point"]);
                    const feature = env.getAbsolute([
                      "data",
                      "picked",
                      "feature",
                    ]);
                    return (
                      point != null &&
                      feature == null &&
                      presence != null &&
                      presence !== PresenceEnum.New
                    );
                  }),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Existing Pond",
          path: [],
          help: outdent`
          Below is a summary of the pond you selected on the map above.
          `,
          visible: pondPresenceEquals(PresenceEnum.Existing),
          blocks: [
            pondAttributeGrid,
            select({
              label: "Is the pond area category shown above correct?",
              path: ["data", "existingPond", "sizeCorrect"],
              help: "Note: small changes in size do not matter since pond size will only be analysed in four categories: 25m2-400m2, 400m2-2000m2, 2000m2-1ha, and 1ha-2ha.",
              options: [
                { value: true, label: "Yes - The size category is correct" },
                {
                  value: false,
                  label: "No - I would like to update the category",
                },
              ],
              customRules: [
                requiredIfV2({
                  message: "Please make a selection",
                  otherTest: pondPresenceEquals(PresenceEnum.Existing),
                }),
              ],
            }),
            select({
              label: "Please update the size category",
              path: ["data", "existingPond", "updatedSize"],
              visible: topoExpr(
                (env, run) =>
                  run(pondPresenceEquals(PresenceEnum.Existing)) &&
                  run(pondSizeCorrectEquals(false))
              ),
              options: SizeEnum.entries,
              customRules: [
                requiredIfV2({
                  message: "Please make a selection",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(pondPresenceEquals(PresenceEnum.Existing)) &&
                      run(pondSizeCorrectEquals(false))
                  ),
                }),
              ],
            }),
            select({
              label: "Why have you changed the size category?",
              path: ["data", "existingPond", "whySizeChanged", "selected"],
              visible: topoExpr(
                (env, run) =>
                  run(pondPresenceEquals(PresenceEnum.Existing)) &&
                  run(pondSizeCorrectEquals(false))
              ),
              options: WhySizeChangedEnum.entries,
              customRules: [
                requiredIfV2({
                  message: "Please make a selection",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(pondPresenceEquals(PresenceEnum.Existing)) &&
                      run(pondSizeCorrectEquals(false))
                  ),
                }),
              ],
            }),
            otherSpecify({
              requiredLevel: "error",
              forbiddenLevel: "info",
              label: "If you selected 'Other', please specify",
              basePath: ["data", "existingPond", "whySizeChanged"],
              visible: topoExpr(
                (env, run) =>
                  run(pondPresenceEquals(PresenceEnum.Existing)) &&
                  run(pondSizeCorrectEquals(false)) &&
                  run(pondSizeWhyChangedEquals(WhySizeChangedEnum.Other))
              ),
              test: value => value === WhySizeChangedEnum.Other,
              help: null,
            }),
          ],
        }),
        section({
          title: "Newly Found Pond",
          path: [],
          help: "Why is the pond new to the survey?",
          visible: pondPresenceEquals(PresenceEnum.New),
          blocks: [
            select({
              label: "Is this pond newly created or was it missed before?",
              path: ["data", "newPond", "whyNew"],
              options: [
                {
                  value: WhyNewEnum.NewlyCreated,
                  label: "New pond - probably created in the last 5 years",
                },
                {
                  value: WhyNewEnum.PreviouslyMissed,
                  label: "An older pond previously missed from the map",
                },
              ],
              help: "Include ponds where one pond has been divided into two. If distinctions are not clear, chose the most likely option.",
              customRules: [
                requiredIfV2({
                  level: "error",
                  message: "Please make a selection",
                  otherTest: pondPresenceEquals(PresenceEnum.New),
                }),
              ],
            }),
            multiSelect({
              label: "Main reason(s) the pond has been created",
              path: ["data", "newPond", "whyCreated", "selected"],
              options: WhyCreatedEnum.entries,
              columns: 2,
              visible: topoExpr(
                (env, run) =>
                  run(pondPresenceEquals(PresenceEnum.New)) &&
                  run(pondWhyNewEquals(WhyNewEnum.NewlyCreated))
              ),
              customRules: [
                requiredIfV2({
                  level: "error",
                  blankTest: value =>
                    isArray(value) ? value.length === 0 : value == null,
                  otherTest: topoExpr(
                    (env, run) =>
                      run(pondPresenceEquals(PresenceEnum.New)) &&
                      run(pondWhyNewEquals(WhyNewEnum.NewlyCreated))
                  ),
                  message: "Please select one or more reasons",
                }),
              ],
            }),
            otherSpecify({
              requiredLevel: "error",
              forbiddenLevel: "info",
              label: "If you selected 'Other', please specify",
              basePath: ["data", "newPond", "whyCreated"],
              visible: topoExpr(
                (env, run) =>
                  run(pondPresenceEquals(PresenceEnum.New)) &&
                  run(pondWhyNewEquals(WhyNewEnum.NewlyCreated)) &&
                  run(pondWhyCreatedIncludes(WhyCreatedEnum.Other))
              ),
              test: value =>
                isArray(value) && value.includes(WhyCreatedEnum.Other),
              help: null,
            }),
            select({
              label: "Why was the pond missed before?",
              path: ["data", "newPond", "whyMissed"],
              options: WhyMissedEnum.entries,
              visible: topoExpr(
                (env, run) =>
                  run(pondPresenceEquals(PresenceEnum.New)) &&
                  run(pondWhyNewEquals(WhyNewEnum.PreviouslyMissed))
              ),
              customRules: [
                requiredIfV2({
                  level: "error",
                  message: "Please make a selection",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(pondPresenceEquals(PresenceEnum.New)) &&
                      run(pondWhyNewEquals(WhyNewEnum.PreviouslyMissed))
                  ),
                }),
              ],
            }),
            bodyText("---"),
            select({
              label: "Pond area",
              path: ["data", "newPond", "size"],
              options: SizeEnum.entries,
              help: "Note that the pond's area is defined as the area *covered by water* when the pond is at its *fullest in winter and early spring* (see survey booklet for how to define this area at other times of year). The area categories are quite large so for most ponds the correct category will be obvious. For larger ponds, the correct category may be most easily estimated from the map.",
              customRules: [
                requiredIfV2({
                  level: "error",
                  message: "Please make a selection",
                  otherTest: pondPresenceEquals(PresenceEnum.New),
                }),
              ],
            }),
            textArea({
              label: "Brief description of the new pond",
              path: ["data", "newPond", "description"],
              help: "Provide a brief description, e.g. 'tiny new quarry pond'",
            }),
          ],
        }),
        section({
          title: "Absent Pond",
          path: [],
          visible: pondPresenceEquals(PresenceEnum.Absent),
          blocks: [
            select({
              label: "Why is this pond not present?",
              path: ["data", "absentPond", "whyAbsent"],
              options: [
                {
                  value: WhyAbsentEnum.LostOrDestroyed,
                  label: "A pond that was once here has been lost or destroyed",
                },
                {
                  value: WhyAbsentEnum.Misidentified,
                  label:
                    "There has been an error. It is not likely that there was ever a pond here",
                },
              ],
              help: outdent`
              Pond has been lost or destroyed - this option includes cases where two ponds have been merged, so that one pond is now lost. Take care if the pond is dry at the time of survey: only identify the pond as lost if it is likely to be more-or-less permanently dry - temporary ponds that are wet in winter but dry up in summer should still be counted as ponds.

              Select the 'There has been an error' option if there has never been a qualifying pond here, e.g. the feature may be a different habitat type, or a pond may just have been located in the wrong place.
              `,
              customRules: [
                requiredIfV2({
                  level: "error",
                  message: "Please make a selection",
                  otherTest: pondPresenceEquals(PresenceEnum.Absent),
                }),
              ],
            }),
            multiSelect({
              label: "Why has the pond been lost or destroyed?",
              path: ["data", "absentPond", "whyLostOrDestroyed", "selected"],
              help: "Tick all that apply.",
              visible: topoExpr(
                (env, run) =>
                  run(pondPresenceEquals(PresenceEnum.Absent)) &&
                  run(pondWhyAbsentEquals(WhyAbsentEnum.LostOrDestroyed))
              ),
              columns: 2,
              options: WhyLostOrDestroyedEnum.entries,
              customRules: [
                requiredIfV2({
                  level: "error",
                  message: "Please choose one or more reasons",
                  blankTest: value =>
                    isArray(value) ? value.length === 0 : value == null,
                  otherTest: topoExpr(
                    (env, run) =>
                      run(pondPresenceEquals(PresenceEnum.Absent)) &&
                      run(pondWhyAbsentEquals(WhyAbsentEnum.LostOrDestroyed))
                  ),
                }),
              ],
            }),
            otherSpecify({
              requiredLevel: "error",
              forbiddenLevel: "info",
              label: "If 'Other' please specify",
              basePath: ["data", "absentPond", "whyLostOrDestroyed"],
              visible: topoExpr(
                (env, run) =>
                  run(pondPresenceEquals(PresenceEnum.Absent)) &&
                  run(pondWhyAbsentEquals(WhyAbsentEnum.LostOrDestroyed)) &&
                  run(
                    pondWhyLostOrDestroyedIncludes(WhyLostOrDestroyedEnum.Other)
                  )
              ),
              test: value =>
                isArray(value) && value.includes(WhyLostOrDestroyedEnum.Other),
              help: null,
            }),
            textArea({
              label: "Describe why the feature is not a pond",
              path: ["data", "absentPond", "whyMisidentified"],
              visible: pondWhyAbsentEquals(WhyAbsentEnum.Misidentified),
              customRules: [
                requiredIfV2({
                  level: "error",
                  message: "Please enter a description",
                  otherTest: topoExpr(
                    (env, run) =>
                      run(pondPresenceEquals(PresenceEnum.Absent)) &&
                      run(pondWhyAbsentEquals(WhyAbsentEnum.Misidentified))
                  ),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Problems With Access",
          path: [],
          visible: pondPresenceEquals(PresenceEnum.CouldNotAccess),
          blocks: [
            textArea({
              label: null,
              path: ["data", "problemsWithAccess"],
              rows: 5,
              help: "Describe the problems accessing the pond.",
              customRules: [
                requiredIfV2({
                  level: "error",
                  message: "Please enter a description",
                  otherTest: topoExpr((env, run) =>
                    run(pondPresenceEquals(PresenceEnum.CouldNotAccess))
                  ),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 5,
              help: "Add any notes you have about the pond. For example survey or access problems or information that may help future surveyors.",
            }),
          ],
        }),
      ],
    }),
  ],
});
