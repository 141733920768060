import {
  attachmentAttr,
  attributeGroup,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  surveyAttr,
  surveyorAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const WrtUpstreamThinking = mapSchemaWithDefaults({
  mapId: unsafeMapId("wrtUpstreamThinking"),
  title: "Upstream Thinking",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("wrtUpstreamThinking"),
      title: "Upstream Thinking",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("wrtUpstreamThinking"),
        attribution: [],
      }),
      defaultAttribute: "flow",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("wrtUpstreamThinking"),
            }),
            surveyAttr("surveyId", "Survey"),
            surveyorAttr(),
            attachmentAttr("photographs", "Photographs"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Data",
          attributes: [
            numberAttr({
              attributeId: "flow",
              label: "Estimated flow",
              unit: "m3/s",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "temperature",
              label: "Temperature",
              unit: "C",
              buckets: [
                rangeBucket(null, 4, marker.fromColor("#2e3090")),
                rangeBucket(4, 8, marker.fromColor("#0865b1")),
                rangeBucket(8, 12, marker.fromColor("#1094d8")),
                rangeBucket(12, 16, marker.fromColor("#15a9c2")),
                rangeBucket(16, 20, marker.fromColor("#ea8133")),
                rangeBucket(20, null, marker.fromColor("#be2432")),
              ],
            }),
            numberAttr({
              attributeId: "ph",
              label: "pH",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "electricalConductivity",
              label: "Electrical conductivity",
              unit: "µs/cm",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "turbidity",
              label: "Turbidity",
              unit: "FAU",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "suspendedSediment",
              label: "Suspended sediment",
              unit: "mg/l",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "color",
              label: "Colour",
              unit: "Hazen",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "totalDissolvedSolids",
              label: "Total dissolved solids",
              unit: "mg/l",
              buckets: [
                rangeBucket(null, 50, marker.fromColor("hsl(120, 80%, 70%)")),
                rangeBucket(50, 200, marker.fromColor("hsl(120, 80%, 58%)")),
                rangeBucket(200, 350, marker.fromColor("hsl(120, 80%, 48%)")),
                rangeBucket(350, 500, marker.fromColor("hsl(120, 80%, 40%)")),
                rangeBucket(500, 650, marker.fromColor("hsl(120, 80%, 32%)")),
                rangeBucket(650, 800, marker.fromColor("hsl(120, 80%, 22%)")),
                rangeBucket(800, null, marker.fromColor("hsl(120, 80%, 10%)")),
              ],
            }),
            numberAttr({
              attributeId: "tryptophan",
              label: "Tryptophan",
              unit: "counts",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "phosphate",
              label: "Phosphates",
              unit: "mg/l",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "dissolvedOxygenSaturation",
              label: "Dissolved oxygen saturation",
              unit: "%",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "dissolvedOxygenConcentration",
              label: "Dissolved oxygen concentration",
              unit: "mg/l",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "coloredDissolvedOrganicMatter",
              label: "Colored dissolved organic matter",
              unit: "counts",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "opticalBrighteningAgents",
              label: "Optical brightening agents",
              unit: "counts",
              buckets: "auto",
            }),
          ],
        }),
      ],
    }),
  ],
});
