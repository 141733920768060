import { Result } from "@cartographerio/fp";
import { GuardError } from "@cartographerio/guard";
import {
  dataDescription,
  dataGeometry,
  isLakeSiteInfo,
  LakeSiteInfo,
  dataTimestamp,
} from "../NeLakeNaturalness/parts";
import { isPartialData, PartialData } from "../NeRiverNaturalness/parts";

export {
  PartialData,
  isPartialData,
  dataDescription,
  dataGeometry,
  dataTimestamp,
};

const g = Result.guard(isPartialData(isLakeSiteInfo), "PartialData");

export function copyData(
  data: unknown
): Result<GuardError, PartialData<LakeSiteInfo>> {
  return g(data).map(data => ({
    ...data,
    // recorded: null,
    // site: {},
    // selections: {
    //   physical: null,
    //   physicalMeasures: [],
    //   otherPhysicalMeasures: null,
    //   hydrological: null,
    //   hydrologicalMeasures: [],
    //   otherHydrologicalMeasures: null,
    //   chemical: null,
    //   chemicalMeasures: [],
    //   otherChemicalMeasures: null,
    //   biological: null,
    //   biologicalMeasures: [],
    //   otherBiologicalMeasures: null,
    // },
    // notes: null,
  }));
}
