// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRtaRiverType =
  | "Navigable"
  | "Large"
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F"
  | "G"
  | "H"
  | "I"
  | "J"
  | "K"
  | "L"
  | "M";

const Navigable: MrsRtaRiverType = "Navigable";
const Large: MrsRtaRiverType = "Large";
const A: MrsRtaRiverType = "A";
const B: MrsRtaRiverType = "B";
const C: MrsRtaRiverType = "C";
const D: MrsRtaRiverType = "D";
const E: MrsRtaRiverType = "E";
const F: MrsRtaRiverType = "F";
const G: MrsRtaRiverType = "G";
const H: MrsRtaRiverType = "H";
const I: MrsRtaRiverType = "I";
const J: MrsRtaRiverType = "J";
const K: MrsRtaRiverType = "K";
const L: MrsRtaRiverType = "L";
const M: MrsRtaRiverType = "M";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRtaRiverTypeEnum = {
  ...createEnum("MrsRtaRiverType", [
    [Navigable, "Navigable/Canal"],
    [Large, "Large"],
    [A, "A - Straight/sinuous, coarsest BE"],
    [B, "B - Straight/sinuous to cascade, coarsest BO/BE, average BE"],
    [C, "C - Straight/sinuous to step-pool, coarsest BO/BE, average CO"],
    [D, "D - Straight/sinuous to plane bed, coarsest BO/BE, average GP"],
    [E, "E - Island braided/wandering, coarsest CO, average GP"],
    [F, "F - Straight/sinuous, coarsest CO, average GP"],
    [G, "G - Meandering, coarsest CO, average GP"],
    [H, "H - Straight/sinuous, coarsest GP, average SA"],
    [I, "I - Meandering, coarsest GP, average SA"],
    [J, "J - Anabranching, coarsest GP, average SA"],
    [K, "K - Straight/sinuous, coarsest SA, average SI"],
    [L, "L - Meandering, coarsest SA, average SI"],
    [M, "M - Anabranching, coarsest SA, average SI"],
  ]),
  Navigable,
  Large,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
};

export default MrsRtaRiverTypeEnum;
