// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityOutfall = "None" | "Dormant" | "Discharging";

const None: EsrtWaterQualityOutfall = "None";
const Dormant: EsrtWaterQualityOutfall = "Dormant";
const Discharging: EsrtWaterQualityOutfall = "Discharging";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityOutfallEnum = {
  ...createEnum("EsrtWaterQualityOutfall", [
    [None, "None"],
    [Dormant, "Dormant (not discharging)"],
    [Discharging, "Active (discharging)"],
  ]),
  None,
  Dormant,
  Discharging,
};

export default EsrtWaterQualityOutfallEnum;
