import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";
import {
  Thames21River,
  Thames21RiverEnum,
} from "@cartographerio/inventory-enums";

export interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  river?: Thames21River | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "river", isNullable(Thames21RiverEnum.isValue))
  );
}

const g = Result.guard(isPartialData, "PartialThames21WaterQuality");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    [
      data.river == null
        ? "Unknown River"
        : Thames21RiverEnum.labelOf(data.river),
      data.location == null ? "Unknown NGR" : safePointToNgr(data.location),
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return g(data).map(data => ({
    ...data,
    recorded: null,
  }));
}
