// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsWildInvadersGrowthStage =
  | "SeedlingOrLarvae"
  | "Juvenile"
  | "Adult"
  | "DeadOrDying";

const SeedlingOrLarvae: MrsWildInvadersGrowthStage = "SeedlingOrLarvae";
const Juvenile: MrsWildInvadersGrowthStage = "Juvenile";
const Adult: MrsWildInvadersGrowthStage = "Adult";
const DeadOrDying: MrsWildInvadersGrowthStage = "DeadOrDying";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsWildInvadersGrowthStageEnum = {
  ...createEnum("MrsWildInvadersGrowthStage", [
    [SeedlingOrLarvae, "Seedling or larvae"],
    [Juvenile, "Juvenile"],
    [Adult, "Adult"],
    [DeadOrDying, "Dead or dying"],
  ]),
  SeedlingOrLarvae,
  Juvenile,
  Adult,
  DeadOrDying,
};

export default MrsWildInvadersGrowthStageEnum;
