// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ExtendedRiverflySpecies =
  | "Flatworms"
  | "FreshwaterSnails"
  | "Limpets"
  | "Bivalves"
  | "AquaticWorms"
  | "Leeches"
  | "Crayfish"
  | "WaterHoglouse"
  | "FreshwaterShrimps"
  | "InvasiveNonNativeShrimps"
  | "MayfliesAnglersCurse"
  | "MayfliesOlives"
  | "MayfliesProngGilled"
  | "MayfliesFlatbodied"
  | "MayfliesGreenDrake"
  | "MayfliesBlueWingedOlive"
  | "Stoneflies"
  | "DragonfliesAndDamselflies"
  | "WaterBoatmen"
  | "OtherWaterBugs"
  | "WaterBeetles"
  | "Alderflies"
  | "CaselessCaddisfliesGreenSedge"
  | "CaselessCaddisfliesNetSpinners"
  | "CaselessCaddisfliesNonGilled"
  | "CasedCaddisfliesHoodCaseMaker"
  | "CasedCaddisfliesWeightedCaseMaker"
  | "CasedCaddisfliesBushTailed"
  | "CasedCaddisfliesOther"
  | "Craneflies"
  | "Blackflies"
  | "NonBitingMidges"
  | "WaterSnipeFlies";

const Flatworms: ExtendedRiverflySpecies = "Flatworms";
const FreshwaterSnails: ExtendedRiverflySpecies = "FreshwaterSnails";
const Limpets: ExtendedRiverflySpecies = "Limpets";
const Bivalves: ExtendedRiverflySpecies = "Bivalves";
const AquaticWorms: ExtendedRiverflySpecies = "AquaticWorms";
const Leeches: ExtendedRiverflySpecies = "Leeches";
const Crayfish: ExtendedRiverflySpecies = "Crayfish";
const WaterHoglouse: ExtendedRiverflySpecies = "WaterHoglouse";
const FreshwaterShrimps: ExtendedRiverflySpecies = "FreshwaterShrimps";
const InvasiveNonNativeShrimps: ExtendedRiverflySpecies =
  "InvasiveNonNativeShrimps";
const MayfliesAnglersCurse: ExtendedRiverflySpecies = "MayfliesAnglersCurse";
const MayfliesOlives: ExtendedRiverflySpecies = "MayfliesOlives";
const MayfliesProngGilled: ExtendedRiverflySpecies = "MayfliesProngGilled";
const MayfliesFlatbodied: ExtendedRiverflySpecies = "MayfliesFlatbodied";
const MayfliesGreenDrake: ExtendedRiverflySpecies = "MayfliesGreenDrake";
const MayfliesBlueWingedOlive: ExtendedRiverflySpecies =
  "MayfliesBlueWingedOlive";
const Stoneflies: ExtendedRiverflySpecies = "Stoneflies";
const DragonfliesAndDamselflies: ExtendedRiverflySpecies =
  "DragonfliesAndDamselflies";
const WaterBoatmen: ExtendedRiverflySpecies = "WaterBoatmen";
const OtherWaterBugs: ExtendedRiverflySpecies = "OtherWaterBugs";
const WaterBeetles: ExtendedRiverflySpecies = "WaterBeetles";
const Alderflies: ExtendedRiverflySpecies = "Alderflies";
const CaselessCaddisfliesGreenSedge: ExtendedRiverflySpecies =
  "CaselessCaddisfliesGreenSedge";
const CaselessCaddisfliesNetSpinners: ExtendedRiverflySpecies =
  "CaselessCaddisfliesNetSpinners";
const CaselessCaddisfliesNonGilled: ExtendedRiverflySpecies =
  "CaselessCaddisfliesNonGilled";
const CasedCaddisfliesHoodCaseMaker: ExtendedRiverflySpecies =
  "CasedCaddisfliesHoodCaseMaker";
const CasedCaddisfliesWeightedCaseMaker: ExtendedRiverflySpecies =
  "CasedCaddisfliesWeightedCaseMaker";
const CasedCaddisfliesBushTailed: ExtendedRiverflySpecies =
  "CasedCaddisfliesBushTailed";
const CasedCaddisfliesOther: ExtendedRiverflySpecies = "CasedCaddisfliesOther";
const Craneflies: ExtendedRiverflySpecies = "Craneflies";
const Blackflies: ExtendedRiverflySpecies = "Blackflies";
const NonBitingMidges: ExtendedRiverflySpecies = "NonBitingMidges";
const WaterSnipeFlies: ExtendedRiverflySpecies = "WaterSnipeFlies";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExtendedRiverflySpeciesEnum = {
  ...createEnum("ExtendedRiverflySpecies", [
    [Flatworms, "Flatworms"],
    [FreshwaterSnails, "Freshwater Snails"],
    [Limpets, "Limpets"],
    [Bivalves, "Bivalves"],
    [AquaticWorms, "Aquatic Worms"],
    [Leeches, "Leeches"],
    [Crayfish, "Crayfish"],
    [WaterHoglouse, "Water Hoglouse"],
    [FreshwaterShrimps, "Freshwater Shrimps"],
    [InvasiveNonNativeShrimps, "Invasive Non-Native Shrimps"],
    [MayfliesAnglersCurse, "Mayflies - Angler's Curse"],
    [MayfliesOlives, "Mayflies - Olives"],
    [MayfliesProngGilled, "Mayflies - Prong Gilled"],
    [MayfliesFlatbodied, "Mayflies - Flatbodied"],
    [MayfliesGreenDrake, "Mayflies - Green Drake"],
    [MayfliesBlueWingedOlive, "Mayflies - Blue-Winged Olive"],
    [Stoneflies, "Stoneflies"],
    [DragonfliesAndDamselflies, "Dragonflies and Damselflies"],
    [WaterBoatmen, "Water Boatmen"],
    [OtherWaterBugs, "Other Water Bugs"],
    [WaterBeetles, "Water Beetles (Adults and Larvae)"],
    [Alderflies, "Alderflies"],
    [CaselessCaddisfliesGreenSedge, "Caseless Caddisflies - Green Sedge"],
    [CaselessCaddisfliesNetSpinners, "Caseless Caddisflies - Net Spinners"],
    [CaselessCaddisfliesNonGilled, "Caseless Caddisflies - Non-Gilled"],
    [CasedCaddisfliesHoodCaseMaker, "Cased Caddisflies - Hood Case-Maker"],
    [
      CasedCaddisfliesWeightedCaseMaker,
      "Cased Caddisflies - Weighted Case-Maker",
    ],
    [CasedCaddisfliesBushTailed, "Cased Caddisflies - Bush Tailed Caddisfly"],
    [CasedCaddisfliesOther, "Cased Caddisflies - Other"],
    [Craneflies, "Craneflies"],
    [Blackflies, "Blackflies"],
    [NonBitingMidges, "Non-Biting Midges"],
    [WaterSnipeFlies, "Water Snipe Flies"],
  ]),
  Flatworms,
  FreshwaterSnails,
  Limpets,
  Bivalves,
  AquaticWorms,
  Leeches,
  Crayfish,
  WaterHoglouse,
  FreshwaterShrimps,
  InvasiveNonNativeShrimps,
  MayfliesAnglersCurse,
  MayfliesOlives,
  MayfliesProngGilled,
  MayfliesFlatbodied,
  MayfliesGreenDrake,
  MayfliesBlueWingedOlive,
  Stoneflies,
  DragonfliesAndDamselflies,
  WaterBoatmen,
  OtherWaterBugs,
  WaterBeetles,
  Alderflies,
  CaselessCaddisfliesGreenSedge,
  CaselessCaddisfliesNetSpinners,
  CaselessCaddisfliesNonGilled,
  CasedCaddisfliesHoodCaseMaker,
  CasedCaddisfliesWeightedCaseMaker,
  CasedCaddisfliesBushTailed,
  CasedCaddisfliesOther,
  Craneflies,
  Blackflies,
  NonBitingMidges,
  WaterSnipeFlies,
};

export default ExtendedRiverflySpeciesEnum;
