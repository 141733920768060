// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsApe = "A" | "P" | "E";

const Absent: UrsApe = "A";
const Present: UrsApe = "P";
const Extensive: UrsApe = "E";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsApeEnum = {
  ...createEnum("UrsApe", [
    [Absent, "Absent"],
    [Present, "Present"],
    [Extensive, "Extensive"],
  ]),
  Absent,
  Present,
  Extensive,
};

export default UrsApeEnum;
