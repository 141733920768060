import { raise } from "@cartographerio/util";
import { isQualificationId, QualificationId } from "../../core";
import {
  isQualificationRoleName,
  QualificationRoleName,
} from "../types.generated";

export type QualificationRoleParts = [QualificationRoleName, QualificationId];

export function optParseQualificationRole(
  role: string
): QualificationRoleParts | null {
  const parts = role.split(":");

  switch (parts[0]) {
    case "Q":
      return parts.length === 3 &&
        isQualificationRoleName(parts[1]) &&
        isQualificationId(parts[2])
        ? [parts[1], parts[2]]
        : null;

    default:
      return null;
  }
}

export function parseQualificationRole(role: string): QualificationRoleParts {
  return (
    optParseQualificationRole(role) ??
    raise(new Error("Bad qualification role: " + role))
  );
}

export function isValidQualificationRole(role: string): boolean {
  return optParseQualificationRole(role) != null;
}

export function qualificationRoleName(
  role: string
): QualificationRoleName | null {
  const parts = optParseQualificationRole(role);
  return parts?.[0] ?? null;
}

export function roleQualificationId(role: string): QualificationId | null {
  const parts = optParseQualificationRole(role);
  return parts?.[1] ?? null;
}
