import { Option, Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiConfig,
  ApiParams,
  Invitation,
  InvitationAcceptResponse,
  InvitationCreate,
  InvitationId,
  InvitationSignupRequest,
  InvitationSignupResponse,
  InvitationSummary,
  InvitationUpdate,
  Message,
  SearchResults,
  checkedToResult,
  isChecked,
  isInvitation,
  isInvitationAcceptResponse,
  isInvitationSignupResponse,
  isInvitationSummary,
  isSearchResults,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import {
  contentAs,
  notFoundToOption,
  optionalContentAs,
  recoverUnknownErrors,
} from "../../response";
import { UrlParts } from "../../url";
import { PartialParams } from "../params";
import { InvitationSearchOptionsV3 } from "./common";

const basePath = "/invitation/v3";

export function search(
  apiParams: ApiParams,
  options: PartialParams<InvitationSearchOptionsV3> = {}
): IO<SearchResults<Invitation>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(
      contentAs("SearchResults<Invitation>", isSearchResults(isInvitation))
    );
}

export function searchUrl(
  options: PartialParams<InvitationSearchOptionsV3> = {}
): UrlParts {
  return { path: basePath, query: { ...options } };
}

export function readOrFail(
  apiParams: ApiParams,
  invitation: InvitationId
): IO<Invitation> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${invitation}` } })
    .flatMap(contentAs("Invitation", isInvitation));
}

export function readOption(
  apiParams: ApiParams,
  invitation: InvitationId
): IO<Option<Invitation>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${invitation}` } })
    .flatMap(optionalContentAs("Invitation", isInvitation));
}

export function readSummary(
  apiConfig: ApiConfig,
  invitation: InvitationId
): IO<InvitationSummary> {
  return fetch
    .get({
      apiParams: { apiConfig },
      url: { path: `${basePath}/${invitation}/summary` },
    })
    .flatMap(contentAs("InvitationSummary", isInvitationSummary));
}

export function cancel(
  apiParams: ApiParams,
  invitation: InvitationId
): IO<void> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${invitation}/cancel` } })
    .map(notFoundToOption)
    .void();
}

export function create(
  apiParams: ApiParams,
  create: InvitationCreate
): IO<Result<Message[], Invitation>> {
  return fetch
    .post({
      apiParams,
      url: { path: basePath },
      body: create,
    })
    .flatMap(contentAs("Checked<Invitation>", isChecked(isInvitation)))
    .map(checkedToResult);
}

export function save(
  apiParams: ApiParams,
  invitation: InvitationId,
  create: InvitationCreate | InvitationUpdate
): IO<Result<Message[], Invitation>> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${invitation}` },
      body: create,
    })
    .flatMap(contentAs("Checked<Invitation>", isChecked(isInvitation)))
    .map(checkedToResult);
}

export function remove(
  apiParams: ApiParams,
  invitation: InvitationId
): IO<void> {
  return fetch
    .remove({ apiParams, url: { path: `${basePath}/${invitation}` } })
    .void();
}

export function accept(
  apiConfig: ApiConfig,
  invitation: InvitationId
): IO<InvitationAcceptResponse> {
  return fetch
    .get({
      apiParams: { apiConfig },
      url: { path: `${basePath}/${invitation}/accept` },
    })
    .map(
      recoverUnknownErrors({
        409: isInvitationAcceptResponse,
      })
    )
    .flatMap(contentAs("InvitationAcceptResponse", isInvitationAcceptResponse));
}

export function signup(
  apiConfig: ApiConfig,
  invitation: InvitationId,
  signup: InvitationSignupRequest
): IO<InvitationSignupResponse> {
  return fetch
    .post({
      apiParams: { apiConfig },
      url: { path: `${basePath}/${invitation}/signup` },
      body: signup,
    })
    .flatMap(contentAs("InvitationSignupResponse", isInvitationSignupResponse));
}

export function resend(
  apiParams: ApiParams,
  invitation: InvitationId
): IO<Invitation> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${invitation}/resend` } })
    .flatMap(contentAs("Invitation", isInvitation));
}
