// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsWildInvadersCoverage =
  | "VerySmall"
  | "Small"
  | "Medium"
  | "Large"
  | "VeryLarge";

const VerySmall: MrsWildInvadersCoverage = "VerySmall";
const Small: MrsWildInvadersCoverage = "Small";
const Medium: MrsWildInvadersCoverage = "Medium";
const Large: MrsWildInvadersCoverage = "Large";
const VeryLarge: MrsWildInvadersCoverage = "VeryLarge";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsWildInvadersCoverageEnum = {
  ...createEnum("MrsWildInvadersCoverage", [
    [VerySmall, "Very small (isolated specimens)"],
    [Small, "Small (<1 m2)"],
    [Medium, "Medium (1-5 m2)"],
    [Large, "Large (5-50 m2)"],
    [VeryLarge, "Very large (>50 m2)"],
  ]),
  VerySmall,
  Small,
  Medium,
  Large,
  VeryLarge,
};

export default MrsWildInvadersCoverageEnum;
