// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityWaterbodyType =
  | "River"
  | "Stream"
  | "Lake"
  | "Pond"
  | "EstuaryOrTidal"
  | "Other";

const River: ArrtWaterQualityWaterbodyType = "River";
const Stream: ArrtWaterQualityWaterbodyType = "Stream";
const Lake: ArrtWaterQualityWaterbodyType = "Lake";
const Pond: ArrtWaterQualityWaterbodyType = "Pond";
const EstuaryOrTidal: ArrtWaterQualityWaterbodyType = "EstuaryOrTidal";
const Other: ArrtWaterQualityWaterbodyType = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityWaterbodyTypeEnum = {
  ...createEnum("ArrtWaterQualityWaterbodyType", [
    [River, "River"],
    [Stream, "Stream"],
    [Lake, "Lake"],
    [Pond, "Pond"],
    [EstuaryOrTidal, "Estuary/Tidal"],
    [Other, "Other"],
  ]),
  River,
  Stream,
  Lake,
  Pond,
  EstuaryOrTidal,
  Other,
};

export default ArrtWaterQualityWaterbodyTypeEnum;
