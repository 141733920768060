/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { Timestamp, isTimestamp } from "../core/types.generated";
import { Tagged, bridgesIsTaggedHack as isTagged } from "@cartographerio/util";

export type MapboxAccessToken = Tagged<"MapboxAccessToken">;

export interface MapboxAccessTokenResponse {
  token: MapboxAccessToken;
  expires: Timestamp;
}

export const isMapboxAccessToken = (v: any): v is MapboxAccessToken => {
  return isTagged(
    (a0: any): a0 is "MapboxAccessToken" => a0 === "MapboxAccessToken"
  )(v);
};

export const isMapboxAccessTokenResponse = (
  v: any
): v is MapboxAccessTokenResponse => {
  return (
    typeof v === "object" &&
    v != null &&
    "token" in v &&
    isMapboxAccessToken(v.token) &&
    "expires" in v &&
    isTimestamp(v.expires)
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
