// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsWaterClarity = "G" | "A" | "P";

const Good: UrsWaterClarity = "G";
const Average: UrsWaterClarity = "A";
const Poor: UrsWaterClarity = "P";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsWaterClarityEnum = {
  ...createEnum("UrsWaterClarity", [
    [Good, "Good (clear)"],
    [Average, "Average (patchy / fairly clear)"],
    [Poor, "Poor (difficult to see channel bed)"],
  ]),
  Good,
  Average,
  Poor,
};

export default UrsWaterClarityEnum;
