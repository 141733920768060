// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopInvertebrate =
  | "Darting"
  | "Swimming"
  | "Wriggling"
  | "Gliding"
  | "None"
  | "Obscured";

const Darting: UclPitStopInvertebrate = "Darting";
const Swimming: UclPitStopInvertebrate = "Swimming";
const Wriggling: UclPitStopInvertebrate = "Wriggling";
const Gliding: UclPitStopInvertebrate = "Gliding";
const None: UclPitStopInvertebrate = "None";
const Obscured: UclPitStopInvertebrate = "Obscured";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopInvertebrateEnum = {
  ...createEnum("UclPitStopInvertebrate", [
    [
      Darting,
      "Invertebrates that dart quickly over a reasonable distance and then rest",
    ],
    [
      Swimming,
      "Invertebrates that swim steadily through the water at a gradual pace",
    ],
    [
      Wriggling,
      "Invertebrates that wriggle up and down in the water but do not move side to side",
    ],
    [Gliding, "Invertebrates gliding/walking along material in the pond"],
    [None, "No invertebrates visible"],
    [Obscured, "Water too obscured to see any invertebrates"],
  ]),
  Darting,
  Swimming,
  Wriggling,
  Gliding,
  None,
  Obscured,
};

export default UclPitStopInvertebrateEnum;
