import {
  lineLayer,
  polygonLayer,
  stringAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { mapSchemaWithDefaults } from "../../core";
import {
  overallPriorityAttribute,
  riverAreasSource,
  riverRestorationPrioritiesAttributeGroups,
  riversSource,
} from "./restorationPrioritiesHelpers";
import { MapBaseEnum } from "@cartographerio/types";

export const NeRiverRestorationPriorities = mapSchemaWithDefaults({
  mapId: unsafeMapId("neRiverRestorationPriorities"),
  title: "River/Stream Restoration Priorities",
  defaultBase: MapBaseEnum.Satellite,
  layers: [
    polygonLayer({
      layerId: unsafeMapLayerId("neRiverRestorationPriorityAreas"),
      title: "River/Stream Restoration Priorities (Area Based)",
      source: riverAreasSource,
      defaultAttribute: overallPriorityAttribute.propertyName,
      attributes: riverRestorationPrioritiesAttributeGroups(
        stringAttr({
          attributeId: "areaName",
          label: "WFD Waterbody",
          buckets: "auto",
          showDistribution: false,
        })
      ),
    }),
    lineLayer({
      layerId: unsafeMapLayerId("neRiverRestorationPriorities"),
      title: "River/Stream Restoration Priorities (Reach Based)",
      source: riversSource,
      defaultAttribute: overallPriorityAttribute.propertyName,
      attributes: riverRestorationPrioritiesAttributeGroups(
        stringAttr({
          attributeId: "reachName",
          label: "Watercourse Name",
          buckets: "auto",
          showDistribution: false,
        })
      ),
    }),
  ],
});
