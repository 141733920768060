// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankEvidenceOfPollution =
  | "LitterOrFlyTipping"
  | "Oil"
  | "RoadRunOff"
  | "AgriculturalRunOff"
  | "Other";

const LitterOrFlyTipping: NwcSeedbankEvidenceOfPollution = "LitterOrFlyTipping";
const Oil: NwcSeedbankEvidenceOfPollution = "Oil";
const RoadRunOff: NwcSeedbankEvidenceOfPollution = "RoadRunOff";
const AgriculturalRunOff: NwcSeedbankEvidenceOfPollution = "AgriculturalRunOff";
const Other: NwcSeedbankEvidenceOfPollution = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankEvidenceOfPollutionEnum = {
  ...createEnum("NwcSeedbankEvidenceOfPollution", [
    [LitterOrFlyTipping, "Litter / Fly Tipping"],
    [Oil, "Oil"],
    [RoadRunOff, "Road Run-Off"],
    [AgriculturalRunOff, "Agricultural Run-Off"],
    [Other, "Other"],
  ]),
  LitterOrFlyTipping,
  Oil,
  RoadRunOff,
  AgriculturalRunOff,
  Other,
};

export default NwcSeedbankEvidenceOfPollutionEnum;
