import {
  attachmentAttr,
  attributeGroup,
  enumAttr,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  thresholdAttr,
  thresholdBucket,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  SasWaterQualitySiteTypeEnum,
  SasWaterQualityWeatherEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const SasWaterQuality = mapSchemaWithDefaults({
  mapId: unsafeMapId("SasWaterQuality"),
  title: "Coliforms",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("SasWaterQuality"),
      title: "Coliforms",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("SasWaterQuality"),
        attribution: [],
      }),
      defaultAttribute: "site",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Location",
          attributes: [
            stringAttr({
              attributeId: "river",
              label: "River",
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "site",
              label: "Site",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "siteType",
              label: "Site Type",
              enum: SasWaterQualitySiteTypeEnum,
              markers: {
                Downstream: marker.fromColor("#27b6f3"),
                Upstream: marker.fromColor("#27bf57"),
              },
            }),
          ],
        }),
        attributeGroup({
          label: "Survey",
          attributes: [
            surveyAttr("surveyId", "Survey"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
        attributeGroup({
          label: "Field Observations",
          attributes: [
            numberAttr({
              attributeId: "numberOfSwimmers",
              label: "Number of Swimmers",
              buckets: "auto",
              decimalPlaces: 0,
            }),
            numberAttr({
              attributeId: "numberOfOtherWaterUsers",
              label: "Number of Other Water Users",
              buckets: "auto",
              decimalPlaces: 0,
            }),
            enumAttr({
              attributeId: "weather",
              label: "Weather",
              enum: SasWaterQualityWeatherEnum,
            }),
            numberAttr({
              attributeId: "airTemperature",
              label: "Air Temperature",
              unit: "°C",
              buckets: [
                rangeBucket(null, 0, marker.hue(240)),
                rangeBucket(0, 5, marker.hue(210)),
                rangeBucket(5, 10, marker.hue(180)),
                rangeBucket(10, 15, marker.hue(150)),
                rangeBucket(15, 20, marker.hue(120)),
                rangeBucket(20, 25, marker.hue(90)),
                rangeBucket(25, 30, marker.hue(60)),
                rangeBucket(30, 35, marker.hue(30)),
                rangeBucket(35, null, marker.hue(0)),
              ],
              decimalPlaces: 0,
            }),
          ],
        }),
        attributeGroup({
          label: "Lab Results",
          attributes: [
            thresholdAttr({
              attributeId: "escherichiaColi",
              label: "Escherichia Coli",
              unit: "CFU/100ml",
              buckets: [
                thresholdBucket(0, marker.quality(1)),
                thresholdBucket(200, marker.quality(0.8)),
                thresholdBucket(400, marker.quality(0.6)),
                thresholdBucket(600, marker.quality(0.4)),
                thresholdBucket(800, marker.quality(0.2)),
                thresholdBucket(1000, marker.quality(0)),
              ],
              decimalPlaces: 0,
            }),
            thresholdAttr({
              attributeId: "totalThermotolerantColiforms",
              label: "Total Thermotolerant Coliforms",
              unit: "CFU/100ml",
              buckets: [
                thresholdBucket(0, marker.quality(1)),
                thresholdBucket(200, marker.quality(0.8)),
                thresholdBucket(400, marker.quality(0.6)),
                thresholdBucket(600, marker.quality(0.4)),
                thresholdBucket(800, marker.quality(0.2)),
                thresholdBucket(1000, marker.quality(0)),
              ],
              decimalPlaces: 0,
            }),
            thresholdAttr({
              attributeId: "intestinalEnterococci",
              label: "Intestinal Enterococci",
              unit: "CFU/100ml",
              buckets: [
                thresholdBucket(0, marker.quality(1)),
                thresholdBucket(200, marker.quality(0.8)),
                thresholdBucket(400, marker.quality(0.6)),
                thresholdBucket(600, marker.quality(0.4)),
                thresholdBucket(800, marker.quality(0.2)),
                thresholdBucket(1000, marker.quality(0)),
              ],
              decimalPlaces: 0,
            }),
          ],
        }),
      ],
    }),
  ],
});
