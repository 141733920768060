// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsChannelDynamicsExtent =
  | "none"
  | "negligible"
  | "local"
  | "extensive"
  | "veryExtensive";

const None: UrsChannelDynamicsExtent = "none";
const Negligible: UrsChannelDynamicsExtent = "negligible";
const Local: UrsChannelDynamicsExtent = "local";
const Extensive: UrsChannelDynamicsExtent = "extensive";
const VeryExtensive: UrsChannelDynamicsExtent = "veryExtensive";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsChannelDynamicsExtentEnum = {
  ...createEnum("UrsChannelDynamicsExtent", [
    [None, "None"],
    [Negligible, "Negligible - <5%"],
    [Local, "Local - 5-20%"],
    [Extensive, "Extensive - 20-30%"],
    [VeryExtensive, "VeryExtensive - >30%"],
  ]),
  None,
  Negligible,
  Local,
  Extensive,
  VeryExtensive,
};

export default UrsChannelDynamicsExtentEnum;
