import {
  topoExpr,
  attachmentField,
  customRule,
  enumSelectOptions,
  form,
  grid,
  multiSelect,
  page,
  section,
  select,
  selectOption,
  textArea,
  timestampField,
} from "@cartographerio/topo-form";
import { isArrayOf, isString } from "@cartographerio/guard";
import {
  NeChalkRiversFeature,
  NeChalkRiversFeatureEnum,
  NeChalkRiversModificationTypeEnum,
  NeChalkRiversPhEnum,
  NeChalkRiversTreeShadingEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
import { neRiverSiteInfoSection } from "../NeRiverNaturalness/form";

const chalkRiversGuidanceUrl =
  "https://priorityhabitats.org/chalk-rivers-form-guidance";

const featureLabels: Record<NeChalkRiversFeature, string> = {
  [NeChalkRiversFeatureEnum.IntermittentFlow]:
    "Winterbourne (intermittent flow)",
  [NeChalkRiversFeatureEnum.IntermittentFlowNatural]:
    "Is the intermittent flow natural?",
  [NeChalkRiversFeatureEnum.Upwelling]:
    "Upwelling springs along the stream bed or its edges",
  [NeChalkRiversFeatureEnum.UpwellingKnuckerhole]:
    "Knuckerhole (>25cm hole in the streambed pumping directly from the aquifer)",
  [NeChalkRiversFeatureEnum.SpringsInFloodplain]:
    "Springs in floodplain or valley bottom/sides",
  [NeChalkRiversFeatureEnum.ActiveTufaFormation]:
    "Active tufa formation (chalky deposit that precipitates out of very high alkalinity springwater)",
  [NeChalkRiversFeatureEnum.SeepagesRiparian]:
    "Flushes/seepages in the riparian zone",
  [NeChalkRiversFeatureEnum.SeepagesFloodplain]:
    "Flushes/seepages in the wider floodplain",
  [NeChalkRiversFeatureEnum.SeepagesSpringline]:
    "Flushes/seepages on the valleyside springline",
  [NeChalkRiversFeatureEnum.EncroachingMarginalVegetation]:
    "Encroaching marginal vegetation",
  [NeChalkRiversFeatureEnum.GravelBed]: "Gravel bed",
  [NeChalkRiversFeatureEnum.WaterCrowfoot]: "Water-Crowfoot (any species)",
  [NeChalkRiversFeatureEnum.PeatRiparian]: "Peat present in the riparian zone",
  [NeChalkRiversFeatureEnum.PeatFloodplain]:
    "Peat present in the wider floodplain",
  [NeChalkRiversFeatureEnum.PeatSpringline]:
    "Peat present in the valleyside springline",
  [NeChalkRiversFeatureEnum.VegetationTallFen]:
    "Tall fens present beyond immediate bankside",
  [NeChalkRiversFeatureEnum.VegetationReedbed]:
    "Reedbends present beyond immediate bankside",
  [NeChalkRiversFeatureEnum.VegetationWetWoodland]:
    "Wet woodland present beyond immediate bankside",
  [NeChalkRiversFeatureEnum.VegetationOther]:
    "Other vegetation present beyond immediate bankside",
  [NeChalkRiversFeatureEnum.GhyllStream]:
    "Fern/moss/liverwort-dominated ghyll stream (steep incised valley)",
  [NeChalkRiversFeatureEnum.ExposedCoarseSediments]: "Exposed coarse sediments",
};

function dependentFeature(
  required: NeChalkRiversFeature,
  when: NeChalkRiversFeature
) {
  const message = `Only select "${featureLabels[when]}" if you also selected "${featureLabels[required]}`;

  return customRule({
    level: "error",
    message,
    triggerWhen: topoExpr(env =>
      env
        .getFocusedAs(isArrayOf(isString))
        .map(arr => arr.includes(when) && !arr.includes(required))
        .getOrElse(() => false)
    ),
  });
}

export default form({
  title: "Mapping Chalk Rivers/Streams",
  pages: [
    page({
      title: null,
      path: [],
      help: outdent`
      This form allows you to propose a site
      for addition or deletion to the national map of chalk rivers,
      one of the river types listed in the definition of UK priority river habitat.
      Please read this [guidance document](${chalkRiversGuidanceUrl})
      before filling in the form.

      To provide more information on the naturalness of the site,
      please fill in the separate **River/Stream Naturalness Assessment** form.
      `,
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                }),
              ],
            }),
          ],
        }),
        neRiverSiteInfoSection({
          styleUrl: "mapbox://styles/cartographerio/cl3tvtqxk000c14l9lojqrhxp",
          extraHelp: outdent`
          The national chalk rivers map
          (high certainty chalk rivers in green, low certainty chalk rivers in orange)
          is shown to help you identify which sections are currently on the map.
          `,
          showChalkAttribute: true,
        }),
        section({
          title: "Map Modification",
          path: [],
          help: "Identify a river section that may need adding, changing certainty level, or deleting.",
          blocks: [
            select({
              label: "Modification Type",
              path: ["data", "modification"],
              help: "Is this a proposal for addition to or deletion from the map?",
              options: enumSelectOptions(NeChalkRiversModificationTypeEnum),
            }),
            textArea({
              label: "Justification",
              path: ["data", "context"],
              rows: 8,
              help: outdent`
              Explain why you think the selected section should be added/deleted.
              Refer to the [guidance document](${chalkRiversGuidanceUrl}) for relevant considerations.
              `,
            }),
          ],
        }),
        section({
          title: "Chalk River Features (Optional)",
          path: ["data", "features"],
          help: "Are any of the following descriptions relevant to this section (tick as appropriate)?",
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Level of Tree-Shading",
                  path: ["treeShading"],
                  options: enumSelectOptions(NeChalkRiversTreeShadingEnum),
                }),
                select({
                  label: "Water pH",
                  path: ["ph"],
                  options: enumSelectOptions(NeChalkRiversPhEnum),
                }),
                multiSelect({
                  label: "Other Features",
                  path: ["other"],
                  options: NeChalkRiversFeatureEnum.values.map(value =>
                    selectOption(value, featureLabels[value])
                  ),
                  customRules: [
                    dependentFeature(
                      NeChalkRiversFeatureEnum.IntermittentFlow,
                      NeChalkRiversFeatureEnum.IntermittentFlowNatural
                    ),
                    dependentFeature(
                      NeChalkRiversFeatureEnum.Upwelling,
                      NeChalkRiversFeatureEnum.UpwellingKnuckerhole
                    ),
                  ],
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
      ],
    }),
  ],
});
