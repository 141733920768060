// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeNaturalnessConfidenceClass = "High" | "Moderate" | "Low";

const High: NeNaturalnessConfidenceClass = "High";
const Moderate: NeNaturalnessConfidenceClass = "Moderate";
const Low: NeNaturalnessConfidenceClass = "Low";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeNaturalnessConfidenceClassEnum = {
  ...createEnum("NeNaturalnessConfidenceClass", [
    [High, "High"],
    [Moderate, "Moderate"],
    [Low, "Low"],
  ]),
  High,
  Moderate,
  Low,
};

export default NeNaturalnessConfidenceClassEnum;
