// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtUpstreamThinkingLandUse =
  | "urbanResidential"
  | "industrial"
  | "urbanPark"
  | "agriculture"
  | "forest"
  | "grasslandOrShrub"
  | "other";

const UrbanResidential: WrtUpstreamThinkingLandUse = "urbanResidential";
const Industrial: WrtUpstreamThinkingLandUse = "industrial";
const UrbanPark: WrtUpstreamThinkingLandUse = "urbanPark";
const Agriculture: WrtUpstreamThinkingLandUse = "agriculture";
const Forest: WrtUpstreamThinkingLandUse = "forest";
const GrasslandOrShrub: WrtUpstreamThinkingLandUse = "grasslandOrShrub";
const Other: WrtUpstreamThinkingLandUse = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtUpstreamThinkingLandUseEnum = {
  ...createEnum("WrtUpstreamThinkingLandUse", [
    [UrbanResidential, "Urban residential"],
    [Industrial, "Industrial"],
    [UrbanPark, "Urban park"],
    [Agriculture, "Agriculture"],
    [Forest, "Forest"],
    [GrasslandOrShrub, "Grassland/shrub"],
    [Other, "Other (please specify)"],
  ]),
  UrbanResidential,
  Industrial,
  UrbanPark,
  Agriculture,
  Forest,
  GrasslandOrShrub,
  Other,
};

export default WrtUpstreamThinkingLandUseEnum;
