// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type YesNoUnknown = "yes" | "no" | "unknown";

const Yes: YesNoUnknown = "yes";
const No: YesNoUnknown = "no";
const Unknown: YesNoUnknown = "unknown";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const YesNoUnknownEnum = {
  ...createEnum("YesNoUnknown", [
    [Yes, "Yes"],
    [No, "No"],
    [Unknown, "Unknown"],
  ]),
  Yes,
  No,
  Unknown,
};

export default YesNoUnknownEnum;
