import { hasKey, isObject } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import { AccessToken, ApiConfig, isAccessToken } from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";

const basePath = "/map/embed/v1";

export function authorize(apiConfig: ApiConfig): IO<AccessToken> {
  return fetch
    .get({
      apiParams: { apiConfig },
      url: { path: `${basePath}/authorize` },
    })
    .flatMap(contentAs("AuthorizeResponse", isAuthorizeResponse))
    .map(response => response.token);
}

interface AuthorizeResponse {
  token: AccessToken;
}

function isAuthorizeResponse(value: unknown): value is AuthorizeResponse {
  return isObject(value) && hasKey(value, "token", isAccessToken);
}
