// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type StormPhosphatesLevel = "H" | "N" | "L";

const High: StormPhosphatesLevel = "H";
const Normal: StormPhosphatesLevel = "N";
const Low: StormPhosphatesLevel = "L";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StormPhosphatesLevelEnum = {
  ...createEnum("StormPhosphatesLevel", [
    [High, "High"],
    [Normal, "Normal"],
    [Low, "Low"],
  ]),
  High,
  Normal,
  Low,
};

export default StormPhosphatesLevelEnum;
