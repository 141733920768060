import {
  CartographerSource,
  LocalGeojsonSource,
  RemoteGeojsonSource,
  RemoteTileSource,
} from "./type";

export function remoteGeojsonSource(
  params: Omit<RemoteGeojsonSource, "type">
): RemoteGeojsonSource {
  return {
    type: "RemoteGeojsonSource",
    ...params,
  };
}

export function remoteTileSource(
  params: Omit<RemoteTileSource, "type">
): RemoteTileSource {
  return {
    type: "RemoteTileSource",
    ...params,
  };
}

export function localGeojsonSource(
  params: Omit<LocalGeojsonSource, "type">
): LocalGeojsonSource {
  return {
    type: "LocalGeojsonSource",
    ...params,
  };
}

export function cartographerSource(
  params: Omit<CartographerSource, "type">
): CartographerSource {
  return {
    type: "CartographerSource",
    ...params,
  };
}
