import { Option, Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Message,
  SearchResults,
  TeamId,
  TeamRef,
  Team,
  WorkspaceRef,
  checkedToResult,
  isChecked,
  isSearchResults,
  isTeam,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs, optionalContentAs } from "../../response";
import { UrlParts } from "../../url";
import { PartialParams } from "../params";
import { TeamSearchOptions } from "./common";

const basePath = "/team/v2";

export function search(
  apiParams: ApiParams,
  options: PartialParams<TeamSearchOptions> = { order: "name-asc" }
): IO<SearchResults<Team>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(contentAs("SearchResults<Team>", isSearchResults(isTeam)));
}

export function searchUrl(
  options: PartialParams<TeamSearchOptions> = { order: "name-asc" }
): UrlParts {
  return {
    path: basePath,
    query: { ...options },
  };
}

export function readOrFail(
  apiParams: ApiParams,
  ref: TeamRef,
  workspace?: WorkspaceRef
): IO<Team> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/${ref}`, query: { workspace } },
    })
    .flatMap(contentAs("Team", isTeam));
}

export function readOption(
  apiParams: ApiParams,
  ref: TeamRef,
  workspace?: WorkspaceRef
): IO<Option<Team>> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/${ref}`, query: { workspace } },
    })
    .flatMap(optionalContentAs("Team", isTeam));
}

export function save(
  apiParams: ApiParams,
  team: Team
): IO<Result<Message[], Team>> {
  return fetch
    .put({ apiParams, url: { path: `${basePath}/${team.id}` }, body: team })
    .flatMap(contentAs("Checked<Team>", isChecked(isTeam)))
    .map(checkedToResult);
}

export function remove(apiParams: ApiParams, id: TeamId): IO<void> {
  return fetch.remove({ apiParams, url: { path: `${basePath}/${id}` } }).void();
}
