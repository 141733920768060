import {
  attachmentAttr,
  attributeGroup,
  booleanAttr,
  enumAttr,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  ArrtWaterQualityBankVegetationEnum,
  ArrtWaterQualityChannelSubstrateEnum,
  ArrtWaterQualityFlowObstacleEnum,
  ArrtWaterQualityInvasivePlantEnum,
  ArrtWaterQualityPollutionEvidenceEnum,
  ArrtWaterQualityPollutionSourceEnum,
  ArrtWaterQualityWildlifeEnum,
} from "@cartographerio/inventory-enums";
import { unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const ArrtWaterQuality = mapSchemaWithDefaults({
  mapId: unsafeMapId("ArrtWaterQuality"),
  title: "ARRT Water Quality",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("ArrtWaterQuality"),
      title: "ARRT Water Quality",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("ArrtWaterQuality"),
        attribution: [],
      }),
      defaultAttribute: "temperature",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("ArrtWaterQuality"),
            }),
            surveyAttr("surveyId", "Survey"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Photographs",
          attributes: [attachmentAttr("photographs", "Photographs")],
        }),
        attributeGroup({
          label: "Pollution",
          attributes: [
            enumAttr({
              attributeId: "pollutionSource",
              label: "Visible Sources of Pollution",
              enum: ArrtWaterQualityPollutionSourceEnum,
            }),
            enumAttr({
              attributeId: "pollutionEvidence",
              label: "Evidence of Recent Pollution",
              enum: ArrtWaterQualityPollutionEvidenceEnum,
            }),
            stringAttr({
              attributeId: "outfalls",
              label: "Outfall(s)",
              buckets: [
                lookupBucket(null, marker.empty, "None recorded"),
                lookupBucket(
                  "Active",
                  marker.fromColor("rgb(255, 128, 0)"),
                  "Active (discharging)"
                ),
                lookupBucket(
                  "Inactive",
                  marker.fromColor("rgb(255, 255, 0)"),
                  "Dormant (not discharging)"
                ),
              ],
            }),
          ],
        }),
        attributeGroup({
          label: "River Channel",
          attributes: [
            enumAttr({
              attributeId: "obstacles",
              label: "Obstacles to Fish and Flow",
              enum: ArrtWaterQualityFlowObstacleEnum,
            }),
            enumAttr({
              attributeId: "dominantSubstrate",
              label: "Predominant Channel Substrate",
              enum: ArrtWaterQualityChannelSubstrateEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Water Quality Measurements",
          attributes: [
            booleanAttr({
              attributeId: "sampleTaken",
              label: "Sample Taken?",
              buckets: [
                lookupBucket(true, marker.fromColor("limegreen")),
                lookupBucket(false, marker.fromColor("orange")),
                lookupBucket(null, marker.empty),
              ],
            }),
            numberAttr({
              attributeId: "temperature",
              label: "Temperature",
              unit: "°C",
              decimalPlaces: 1,
              buckets: [
                rangeBucket(0, 4, marker.quality(6 / 6)),
                rangeBucket(4, 8, marker.quality(5 / 6)),
                rangeBucket(8, 12, marker.quality(4 / 6)),
                rangeBucket(12, 16, marker.quality(3 / 6)),
                rangeBucket(16, 20, marker.quality(2 / 6)),
                rangeBucket(20, 24, marker.quality(1 / 6)),
                rangeBucket(24, null, marker.quality(0 / 6)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "dissolvedSolids",
              label: "Dissolved Solids (TDS)",
              unit: "ppm",
              decimalPlaces: 0,
              buckets: [
                rangeBucket(0, 50, marker.quality(6 / 6)),
                rangeBucket(50, 150, marker.quality(5 / 6)),
                rangeBucket(150, 250, marker.quality(4 / 6)),
                rangeBucket(250, 350, marker.quality(3 / 6)),
                rangeBucket(350, 500, marker.quality(2 / 6)),
                rangeBucket(500, null, marker.quality(1 / 6)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "phosphate",
              label: "Phosphates",
              unit: "ppm",
              decimalPlaces: 2,
              buckets: [
                rangeBucket(0, 0.05, marker.quality(5 / 5)),
                rangeBucket(0.05, 0.2, marker.quality(4 / 5)),
                rangeBucket(0.2, 0.5, marker.quality(3 / 5)),
                rangeBucket(0.5, 1.0, marker.quality(2 / 5)),
                rangeBucket(1.0, 2.0, marker.quality(1 / 5)),
                rangeBucket(2.0, null, marker.quality(0 / 5)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "turbidity",
              label: "Turbidity",
              unit: "JTU",
              decimalPlaces: 0,
              buckets: [
                rangeBucket(5, 6, marker.quality(4 / 4), "5 JTU"),
                rangeBucket(6, 15, marker.quality(3 / 4), "6-14 JTU"),
                rangeBucket(15, 41, marker.quality(2 / 4), "15-40 JTU"),
                rangeBucket(41, 100, marker.quality(1 / 4), "41-100 JTU"),
                rangeBucket(100, null, marker.quality(0 / 4), "100+ JTU"),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "nitrate",
              label: "Nitrates",
              unit: "ppm",
              decimalPlaces: 0,
              buckets: [
                lookupBucket(0, marker.quality(6 / 6)),
                lookupBucket(1, marker.quality(5 / 6)),
                lookupBucket(2, marker.quality(4 / 6)),
                lookupBucket(5, marker.quality(3 / 6)),
                lookupBucket(10, marker.quality(2 / 6)),
                lookupBucket(20, marker.quality(1 / 6)),
                lookupBucket(50, marker.quality(0 / 6)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
          ],
        }),
        attributeGroup({
          label: "Wildlife",
          attributes: ArrtWaterQualityWildlifeEnum.values
            .filter(value => value !== "None")
            .map(value =>
              booleanAttr({
                attributeId: `wildlife_${value}`,
                label:
                  value === "Other"
                    ? "Other Species"
                    : ArrtWaterQualityWildlifeEnum.labelOf(value),
                buckets: [
                  lookupBucket(true, marker.fromColor("limegreen")),
                  lookupBucket(false, marker.empty),
                ],
              })
            ),
        }),
        attributeGroup({
          label: "Vegetation",
          attributes: [
            enumAttr({
              attributeId: "bankVegetation",
              label: "Bank Vegetation",
              enum: ArrtWaterQualityBankVegetationEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Problem Plant Species",
          attributes: ArrtWaterQualityInvasivePlantEnum.values.map(value =>
            booleanAttr({
              attributeId: `invasivePlants_${value}`,
              label:
                value === "Other"
                  ? "Other Species"
                  : ArrtWaterQualityInvasivePlantEnum.labelOf(value),
              buckets: [
                lookupBucket(true, marker.fromColor("orange")),
                lookupBucket(false, marker.empty),
              ],
            })
          ),
        }),
      ],
    }),
  ],
});
