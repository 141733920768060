// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankTopVegetationManagement = "H" | "M" | "U";

const Heavy: CesBankTopVegetationManagement = "H";
const Moderate: CesBankTopVegetationManagement = "M";
const Unmanaged: CesBankTopVegetationManagement = "U";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankTopVegetationManagementEnum = {
  ...createEnum("CesBankTopVegetationManagement", [
    [Heavy, "Heavy (well pruned / mown)"],
    [Moderate, "Moderate (some evidence of pruning / mowing)"],
    [Unmanaged, "Unmanaged (no evidence of pruning / mowing)"],
  ]),
  Heavy,
  Moderate,
  Unmanaged,
};

export default CesBankTopVegetationManagementEnum;
