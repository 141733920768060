import {
  topoExpr,
  attachmentField,
  checkbox,
  columns2,
  enumSelectOptions,
  form,
  numberField,
  page,
  pointField,
  required,
  rule,
  section,
  select,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import { Result } from "@cartographerio/fp";
import { isOptional } from "@cartographerio/guard";
import {
  MrsMudSpotterDisturbedBankFaceSourceTypeEnum,
  MrsMudSpotterFlowEnum,
  MrsMudSpotterLegacySourceSubTypeEnum,
  MrsMudSpotterOverlandFlowSourceTypeEnum,
  MrsMudSpotterRainfallIntensityEnum,
  MrsMudSpotterSedimentEnum,
  MrsMudSpotterSourceSizeEnum,
  MrsMudSpotterSourceTypeEnum,
  MrsMudSpotterSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

const isLegacy = topoExpr(
  env => env.getAbsolute(["data", "sourceSubType"]) !== null
);

const isDisturbedBankFace = topoExpr(
  env =>
    env.getAbsolute(["data", "sourceType"]) ===
    MrsMudSpotterSourceTypeEnum.DisturbedBankFace
);

const isOverlandFlow = topoExpr(
  env =>
    env.getAbsolute(["data", "sourceType"]) ===
    MrsMudSpotterSourceTypeEnum.OverlandFlow
);

const isOverlandFlowVisible = topoExpr(
  (env, run) =>
    run(isOverlandFlow) &&
    env.getAbsolute(["data", "overlandFlowSourceVisible"]) === true
);

export default form({
  title: "Mud Spotter",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            columns2(
              timestampField({
                label: "Date and Time",
                path: ["data", "recorded"],
                required: required("info"),
                help: "Date and time the survey was recorded in the field.",
                defaultValue: "now",
              }),
              select({
                label: "Survey Type",
                path: ["data", "surveyType"],
                options: enumSelectOptions(MrsMudSpotterSurveyTypeEnum),
                defaultValue: MrsMudSpotterSurveyTypeEnum.Monitoring,
                help: outdent`
                  Is this survey for general *monitoring* purposes
                  or is it a dummy survey created during a *training* course?
                  Training surveys may be deleted once the course is complete.
                  `,
              })
            ),
            pointField({
              label: "Location",
              path: ["data", "location"],
              required: required("info"),
              help: "Location of survey site.",
              fullWidth: true,
            }),
            textField({
              label: "River Name",
              path: ["data", "riverName"],
              required: required("info"),
            }),
            textField({
              label: "Location/Reach Name",
              path: ["data", "reachName"],
              required: required("info"),
            }),
            textField({
              label: "Site Name",
              path: ["data", "siteName"],
              required: required("info"),
            }),
          ],
        }),
        section({
          title: "Weather Conditions",
          path: [],
          help: "At time of survey",
          blocks: [
            select({
              label: "Rainfall Intensity",
              path: ["data", "rainfallIntensity"],
              options: MrsMudSpotterRainfallIntensityEnum.entries,
              required: required("info"),
            }),
            numberField({
              label: "Time Since Rain Started",
              path: ["data", "rainfallDuration"],
              units: "hours",
              decimalPlaces: 1,
              visible: topoExpr(env => {
                const rainfallIntensity = env.getAbsolute([
                  "data",
                  "rainfallIntensity",
                ]);
                console.log("rainfallIntensity 1", rainfallIntensity);
                return (
                  rainfallIntensity != null &&
                  rainfallIntensity !== MrsMudSpotterRainfallIntensityEnum.None
                );
              }),
            }),
            numberField({
              label: "Time Since Rain Stopped",
              path: ["data", "rainStoppedDuration"],
              units: "hours",
              decimalPlaces: 1,
              visible: topoExpr(env => {
                const rainfallIntensity = env.getAbsolute([
                  "data",
                  "rainfallIntensity",
                ]);
                console.log("rainfallIntensity 2", rainfallIntensity);
                return (
                  rainfallIntensity === MrsMudSpotterRainfallIntensityEnum.None
                );
              }),
            }),
          ],
        }),
        section({
          title: "Mud Source Type",
          path: [],
          blocks: [
            select({
              label: "Mud Source Type",
              path: ["data", "sourceType"],
              options: enumSelectOptions(MrsMudSpotterSourceTypeEnum),
              required: required("info"),
              image:
                "https://media.cartographer.io/static/images/mrs-mud-spotter/source-type.png",
            }),
            select({
              label: "Mud Source Sub-Type",
              path: ["data", "sourceSubType"],
              options: enumSelectOptions(MrsMudSpotterLegacySourceSubTypeEnum),
              required: required("info"),
              visible: isLegacy,
              customRules: [
                rule({
                  level: "error",
                  message:
                    "Only select this sub-type if you also selected 'Overland Flow' above.",
                  triggerWhen: topoExpr(env =>
                    Result.when(
                      env.getAs(
                        ["data", "sourceType"],
                        isOptional(MrsMudSpotterSourceTypeEnum.isValue)
                      ),
                      env.getAs(
                        ["data", "sourceSubType"],
                        isOptional(MrsMudSpotterLegacySourceSubTypeEnum.isValue)
                      )
                    )(
                      (sourceType, sourceSubType) =>
                        sourceType !== "OverlandFlow" &&
                        (sourceSubType === "FlowFromImpermeableSurface" ||
                          sourceSubType === "FlowFromOtherSurface")
                    ).getOrElse(() => false)
                  ),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Disturbed Bank Face Source",
          path: [],
          visible: topoExpr(
            (_env, run) => !run(isLegacy) && run(isDisturbedBankFace)
          ),
          blocks: [
            select({
              label: "Disturbed Bank Face Source Sub-Type",
              path: ["data", "disturbedBankFaceSourceType"],
              options: enumSelectOptions(
                MrsMudSpotterDisturbedBankFaceSourceTypeEnum
              ),
              required: required("info"),
            }),
          ],
        }),
        section({
          title: "Overland Flow Source",
          path: [],
          visible: topoExpr(
            (_env, run) => !run(isLegacy) && run(isOverlandFlow)
          ),
          blocks: [
            checkbox({
              label: null,
              path: ["data", "overlandFlowSourceVisible"],
              checkboxLabel: "Is the flow pathway/source visible?",
            }),
            select({
              label: "Overland Flow Path/Source Sub-Type",
              path: ["data", "overlandFlowSourceType"],
              visible: isOverlandFlowVisible,
              options: enumSelectOptions(
                MrsMudSpotterOverlandFlowSourceTypeEnum
              ),
              required: required("info"),
            }),
            textField({
              label: "Other Flow Path/Source Sub-Type",
              path: ["data", "overlandFlowOtherSourceType"],
              visible: topoExpr(
                (env, run) =>
                  run(isOverlandFlowVisible) &&
                  env.getAbsolute(["data", "overlandFlowSourceType"]) ===
                    MrsMudSpotterOverlandFlowSourceTypeEnum.Other
              ),
              required: required("info"),
            }),
            pointField({
              label: "Furthest Visible Location of Flow Path/Source",
              path: ["data", "overlandFlowSourceLocation"],
              visible: isOverlandFlowVisible,
              required: required("info"),
            }),
          ],
        }),
        section({
          title: "Mud Source Size",
          path: [],
          blocks: [
            select({
              label: null,
              path: ["data", "sourceSize"],
              options: enumSelectOptions(MrsMudSpotterSourceSizeEnum),
              required: required("info"),
              image:
                "https://media.cartographer.io/static/images/mrs-mud-spotter/source-size.png",
            }),
          ],
        }),
        section({
          title: "Flow of Water from Mud Source",
          path: [],
          blocks: [
            select({
              label: null,
              path: ["data", "flow"],
              options: enumSelectOptions(MrsMudSpotterFlowEnum),
              required: required("info"),
              image:
                "https://media.cartographer.io/static/images/mrs-mud-spotter/flow.png",
            }),
          ],
        }),
        section({
          title: "Mud Concentration in Flowing Water",
          path: [],
          blocks: [
            select({
              label: null,
              path: ["data", "sediment"],
              options: enumSelectOptions(MrsMudSpotterSedimentEnum),
              required: required("info"),
              image:
                "https://media.cartographer.io/static/images/mrs-mud-spotter/sediment.png",
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
              help: outdent`
              Attach two photographs:

              - A photograph to help fix the location (e.g. photo across river to show details of opposite bank)
              - A photograph to show the source, its type, and function at the time of survey
              `,
            }),
          ],
        }),
        section({
          title: "Comments",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 3,
            }),
          ],
        }),
      ],
    }),
  ],
});
