// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRtaAverageBedMaterial = "BO" | "CO" | "GP" | "SA" | "SI" | "CL";

const Boulder: MrsRtaAverageBedMaterial = "BO";
const Cobble: MrsRtaAverageBedMaterial = "CO";
const GravelPebble: MrsRtaAverageBedMaterial = "GP";
const Sand: MrsRtaAverageBedMaterial = "SA";
const Silt: MrsRtaAverageBedMaterial = "SI";
const Clay: MrsRtaAverageBedMaterial = "CL";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRtaAverageBedMaterialEnum = {
  ...createEnum("MrsRtaAverageBedMaterial", [
    [Boulder, "Boulder"],
    [Cobble, "Cobble"],
    [GravelPebble, "Gravel/Pebble"],
    [Sand, "Sand"],
    [Silt, "Silt"],
    [Clay, "Clay"],
  ]),
  Boulder,
  Cobble,
  GravelPebble,
  Sand,
  Silt,
  Clay,
};

export default MrsRtaAverageBedMaterialEnum;
