// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopInflowSource =
  | "DitchNetwork"
  | "FieldDrains"
  | "Road"
  | "NotApplicable"
  | "Other";

const DitchNetwork: UclPitStopInflowSource = "DitchNetwork";
const FieldDrains: UclPitStopInflowSource = "FieldDrains";
const Road: UclPitStopInflowSource = "Road";
const NotApplicable: UclPitStopInflowSource = "NotApplicable";
const Other: UclPitStopInflowSource = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopInflowSourceEnum = {
  ...createEnum("UclPitStopInflowSource", [
    [DitchNetwork, "Ditch network"],
    [FieldDrains, "Field drains"],
    [Road, "Road"],
    [NotApplicable, "Not applicable"],
    [Other, "Other"],
  ]),
  DitchNetwork,
  FieldDrains,
  Road,
  NotApplicable,
  Other,
};

export default UclPitStopInflowSourceEnum;
