// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverRestorationPrioritiesChemicalMeasure =
  | "TargetPollutionSources"
  | "EstablishHabitatMosaics"
  | "EstablishSoilNutrientConservation";

const TargetPollutionSources: NeRiverRestorationPrioritiesChemicalMeasure =
  "TargetPollutionSources";
const EstablishHabitatMosaics: NeRiverRestorationPrioritiesChemicalMeasure =
  "EstablishHabitatMosaics";
const EstablishSoilNutrientConservation: NeRiverRestorationPrioritiesChemicalMeasure =
  "EstablishSoilNutrientConservation";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverRestorationPrioritiesChemicalMeasureEnum = {
  ...createEnum("NeRiverRestorationPrioritiesChemicalMeasure", [
    [
      TargetPollutionSources,
      "Target critical pollution source areas of the catchment",
    ],
    [
      EstablishHabitatMosaics,
      "Establish naturally functioning habitat mosaics on critical areas",
    ],
    [
      EstablishSoilNutrientConservation,
      "Establish effective soil/nutrient conservation regimes on critical areas",
    ],
  ]),
  TargetPollutionSources,
  EstablishHabitatMosaics,
  EstablishSoilNutrientConservation,
};

export default NeRiverRestorationPrioritiesChemicalMeasureEnum;
