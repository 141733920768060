import { Option, Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isGeometry,
  isPickedF,
  Picked,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  NeNameSource,
  NeNameSourceEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface RiverSiteAttributes {
  drn_id?: string | null;
  rivername?: string | null;
  catchid?: string | null;
  wb_id?: string | null;
}

function isRiverSiteAttributes(v: unknown): v is RiverSiteAttributes {
  return (
    isObject(v) &&
    hasOptionalKey(v, "drn_id", isNullable(isString)) &&
    hasOptionalKey(v, "rivername", isNullable(isString)) &&
    hasOptionalKey(v, "catchid", isNullable(isString)) &&
    hasOptionalKey(v, "wb_id", isNullable(isString))
  );
}

export interface RiverSiteInfo {
  picked?: Picked<GeometryAtom, RiverSiteAttributes> | null;
  reachLength?: number | null;
  reachNameSource?: NeNameSource | null;
  customDrnId?: string | null;
  customReachName?: string | null;
  customWfdCatchmentId?: string | null;
  customWfdWaterbodyId?: string | null;
}

export function isRiverSiteInfo(v: unknown): v is RiverSiteInfo {
  return (
    isObject(v) &&
    hasOptionalKey(
      v,
      "picked",
      isNullable(isPickedF(isGeometry, isRiverSiteAttributes))
    ) &&
    hasOptionalKey(v, "reachLength", isNullable(isNumber)) &&
    hasOptionalKey(
      v,
      "reachNameSource",
      isNullable(NeNameSourceEnum.isValue)
    ) &&
    hasOptionalKey(v, "customDrnId", isNullable(isString)) &&
    hasOptionalKey(v, "customReachName", isNullable(isString)) &&
    hasOptionalKey(v, "customWfdCatchmentId", isNullable(isString)) &&
    hasOptionalKey(v, "customWfdWaterbodyId", isNullable(isString))
  );
}

export interface PartialData<A> {
  recorded?: Timestamp | null;
  site: A;
}

export function isPartialData<A>(isA: (a: unknown) => a is A) {
  return (data: unknown): data is PartialData<A> => {
    return (
      isObject(data) &&
      hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
      hasKey(data, "site", isA)
    );
  };
}

const g = Result.guard(isPartialData(isRiverSiteInfo), "PartialData");

function createDescription(...args: Array<string | null>): string {
  return args.filter(arg => arg != null).join(", ");
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    createDescription(
      Option.wrap(data.site.customReachName)
        .orElseNullable(() => data.site.picked?.feature?.properties.rivername)
        .getOrElse(() => "Unknown River"),
      Option.wrap(data.site.customWfdWaterbodyId)
        .orElseNullable(() => data.site.picked?.feature?.properties.wb_id)
        .map(wbid => `WBID ${wbid}`)
        .getOrElse(() => "Unknown WBID")
    )
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.site?.picked?.point ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}

export function copyData(
  data: unknown
): Result<GuardError, PartialData<RiverSiteInfo>> {
  return g(data).map(data => ({
    ...data,
    // recorded: null,
    // site: {},
    // naturalness: {
    //   physical: { formsOfAssessment: [] },
    //   hydrological: { formsOfAssessment: [] },
    //   chemical: { formsOfAssessment: [] },
    //   biological: { formsOfAssessment: [] },
    //   intermittentFlow: false,
    // },
    // species: {
    //   mammals: [],
    //   birds: [],
    //   fish: [],
    //   herpetofauna: [],
    //   invertebrates: [],
    //   ferns: [],
    //   bryophytes: [],
    //   higherPlants: [],
    // },
    // habitatFeatures: [],
    // notes: null,
  }));
}
