// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsSurveyBank = "left" | "right" | "both";

const Left: UrsSurveyBank = "left";
const Right: UrsSurveyBank = "right";
const Both: UrsSurveyBank = "both";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsSurveyBankEnum = {
  ...createEnum("UrsSurveyBank", [
    [Left, "Left bank"],
    [Right, "Right bank"],
    [Both, "Both banks"],
  ]),
  Left,
  Right,
  Both,
};

export default UrsSurveyBankEnum;
