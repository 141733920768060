// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusAbsentMinorMajor = "absent" | "minor" | "major";

const Absent: EcostatusAbsentMinorMajor = "absent";
const Minor: EcostatusAbsentMinorMajor = "minor";
const Major: EcostatusAbsentMinorMajor = "major";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusAbsentMinorMajorEnum = {
  ...createEnum("EcostatusAbsentMinorMajor", [
    [Absent, "Absent"],
    [Minor, "Minor"],
    [Major, "Major"],
  ]),
  Absent,
  Minor,
  Major,
};

export default EcostatusAbsentMinorMajorEnum;
