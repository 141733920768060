// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesLandCoverType =
  | "Re"
  | "Cm"
  | "In"
  | "Ic"
  | "Tr"
  | "Fp"
  | "Sw"
  | "Ld"
  | "Sy"
  | "Dr"
  | "Cr"
  | "Pa"
  | "Or"
  | "Fa"
  | "Al"
  | "Co"
  | "Dd"
  | "Ow"
  | "Op"
  | "Rg"
  | "Fm"
  | "La"
  | "Rv"
  | "Qu"
  | "Tb"
  | "Ca"
  | "Ma"
  | "WlTs"
  | "WlTg"
  | "WlSg";

const Residential: CesLandCoverType = "Re";
const Commercial: CesLandCoverType = "Cm";
const Industrial: CesLandCoverType = "In";
const IndustrialAndCommercial: CesLandCoverType = "Ic";
const TransportInfrastructure: CesLandCoverType = "Tr";
const PedestrianisedOrFootpath: CesLandCoverType = "Fp";
const SewageTreatmentWorks: CesLandCoverType = "Sw";
const LandfillOrRefuseDeposits: CesLandCoverType = "Ld";
const ScrapRecycling: CesLandCoverType = "Sy";
const DerelictBrownfield: CesLandCoverType = "Dr";
const Cropland: CesLandCoverType = "Cr";
const Pasture: CesLandCoverType = "Pa";
const Orchard: CesLandCoverType = "Or";
const IntensiveAnimalHusbandry: CesLandCoverType = "Fa";
const Allotments: CesLandCoverType = "Al";
const Coniferous: CesLandCoverType = "Co";
const DeciduousOrMixed: CesLandCoverType = "Dd";
const OpenWoodland: CesLandCoverType = "Ow";
const OpenParkland: CesLandCoverType = "Op";
const RoughGrasslandOrHerbs: CesLandCoverType = "Rg";
const FormalParklandOrGardensOrCemetries: CesLandCoverType = "Fm";
const LakeOrPond: CesLandCoverType = "La";
const Reservoir: CesLandCoverType = "Rv";
const ReclaimedQuarry: CesLandCoverType = "Qu";
const StreamOrRiver: CesLandCoverType = "Tb";
const OtherCanal: CesLandCoverType = "Ca";
const MarinaOrBoatYard: CesLandCoverType = "Ma";
const TreeOrShrub: CesLandCoverType = "WlTs";
const TallReedsOrGrassesOrHerbs: CesLandCoverType = "WlTg";
const ShortGrassesOrHerbsOrMosses: CesLandCoverType = "WlSg";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesLandCoverTypeEnum = {
  ...createEnum("CesLandCoverType", [
    [Residential, "Residential"],
    [Commercial, "Commercial"],
    [Industrial, "Industrial"],
    [IndustrialAndCommercial, "Industrial and commercial"],
    [TransportInfrastructure, "Transport infrastructure"],
    [PedestrianisedOrFootpath, "Pedestrianised / footpath"],
    [SewageTreatmentWorks, "Sewage treatment works"],
    [LandfillOrRefuseDeposits, "Landfill / refuse deposits"],
    [ScrapRecycling, "Scrap / recycling"],
    [DerelictBrownfield, "Derelict / brownfield"],
    [Cropland, "Cropland"],
    [Pasture, "Pasture"],
    [Orchard, "Orchard"],
    [IntensiveAnimalHusbandry, "Intensive animal husbandry"],
    [Allotments, "Allotments"],
    [Coniferous, "Coniferous"],
    [DeciduousOrMixed, "Deciduous or mixed"],
    [OpenWoodland, "Open woodland"],
    [OpenParkland, "Open parkland"],
    [RoughGrasslandOrHerbs, "Rough grassland or herbs"],
    [
      FormalParklandOrGardensOrCemetries,
      "Formal parkland / gardens / cemetries",
    ],
    [LakeOrPond, "Lake / pond"],
    [Reservoir, "Reservoir"],
    [ReclaimedQuarry, "Reclaimed (flooded) quarry"],
    [StreamOrRiver, "Stream / river"],
    [OtherCanal, "Canal"],
    [MarinaOrBoatYard, "Marina / boat yard"],
    [TreeOrShrub, "Mainly tree / shrub cover"],
    [TallReedsOrGrassesOrHerbs, "Mainly tall reeds-grasses and herbs"],
    [ShortGrassesOrHerbsOrMosses, "Mainly short grasses, herbs, mosses"],
  ]),
  Residential,
  Commercial,
  Industrial,
  IndustrialAndCommercial,
  TransportInfrastructure,
  PedestrianisedOrFootpath,
  SewageTreatmentWorks,
  LandfillOrRefuseDeposits,
  ScrapRecycling,
  DerelictBrownfield,
  Cropland,
  Pasture,
  Orchard,
  IntensiveAnimalHusbandry,
  Allotments,
  Coniferous,
  DeciduousOrMixed,
  OpenWoodland,
  OpenParkland,
  RoughGrasslandOrHerbs,
  FormalParklandOrGardensOrCemetries,
  LakeOrPond,
  Reservoir,
  ReclaimedQuarry,
  StreamOrRiver,
  OtherCanal,
  MarinaOrBoatYard,
  TreeOrShrub,
  TallReedsOrGrassesOrHerbs,
  ShortGrassesOrHerbsOrMosses,
};

export default CesLandCoverTypeEnum;
