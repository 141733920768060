import { decycle } from "json-cycle";
import { sortJson, SortJsonOptions } from "./sortJson";

export interface PrettyPrintJsonOptions {
  sort?: SortJsonOptions;
  removeCycles?: boolean;
}

export function prettyPrintJson(
  value: unknown,
  options: PrettyPrintJsonOptions = {}
): string {
  const { sort = undefined, removeCycles = true } = options;
  return removeCycles
    ? JSON.stringify(sortJson(decycle(value), sort), null, 2)
    : JSON.stringify(sortJson(value, sort), null, 2);
}
