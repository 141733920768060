import { IO } from "@cartographerio/io";
import {
  ApiParams,
  ClientVersionCheckResult,
  isClientVersionCheckResult,
  isServerVersion,
  ServerVersion,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs } from "../../response";

const basePath = "/version/v1";

export function readServerVersion(apiParams: ApiParams): IO<ServerVersion> {
  const url = { path: basePath };
  return fetch
    .get({ apiParams, url })
    .chain(contentAs("ServerVersion", isServerVersion));
}

export function checkClientVersion(
  apiParams: ApiParams
): IO<ClientVersionCheckResult> {
  const url = { path: `${basePath}/check` };
  return fetch
    .get({ apiParams, url })
    .chain(contentAs("ClientVersionCheckResult", isClientVersionCheckResult));
}
