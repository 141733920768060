import { unsafeMapId } from "@cartographerio/topo-map";
import { mapSchemaWithDefaults } from "../../core";
import { MrsMorph } from "./MrsMorph";
import { MrsMorph10 } from "./MrsMorph10";

export const MrsMorphRivers = mapSchemaWithDefaults({
  mapId: unsafeMapId("mrsMorphRivers"),
  title: "MoRPh Rivers",
  layers: [...MrsMorph.layers, ...MrsMorph10.layers],
});
