// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesMarginType = "ST" | "MC" | "MR";

const SmoothTransition: MrsMorphEstuariesMarginType = "ST";
const MarshCliff: MrsMorphEstuariesMarginType = "MC";
const MarshRamp: MrsMorphEstuariesMarginType = "MR";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesMarginTypeEnum = {
  ...createEnum("MrsMorphEstuariesMarginType", [
    [SmoothTransition, "Smooth transition"],
    [MarshCliff, "Marsh cliff"],
    [MarshRamp, "Marsh ramp"],
  ]),
  SmoothTransition,
  MarshCliff,
  MarshRamp,
};

export default MrsMorphEstuariesMarginTypeEnum;
