import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  ProjectMapSettings,
  TeamId,
  TeamRef,
  WorkspaceRef,
  isProjectMapSettings,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";

const basePath = "/team/map-settings/v2";

function endpointUrl(team: TeamRef, workspace?: WorkspaceRef): UrlParts {
  return { path: `${basePath}/${team}`, query: { workspace } };
}

export function readOption(
  apiParams: ApiParams,
  team: TeamRef,
  workspace?: WorkspaceRef
): IO<Option<ProjectMapSettings>> {
  return fetch
    .get({ apiParams, url: endpointUrl(team, workspace) })
    .chain(optionalContentAs("ProjectMapSettings", isProjectMapSettings));
}

export function readOrNull(
  apiParams: ApiParams,
  team: TeamRef,
  workspace?: WorkspaceRef
): IO<ProjectMapSettings | null> {
  return readOption(apiParams, team, workspace).map(opt => opt.getOrNull());
}

export function save(
  apiParams: ApiParams,
  team: TeamId,
  settings: ProjectMapSettings
): IO<void> {
  return fetch
    .put({ apiParams, url: endpointUrl(team), body: settings })
    .void();
}

export function remove(apiParams: ApiParams, team: TeamId): IO<void> {
  return fetch.remove({ apiParams, url: endpointUrl(team) }).void();
}
