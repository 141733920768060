import { Result } from "@cartographerio/fp";
import { GuardError } from "@cartographerio/guard";
import {
  dataDescription,
  dataGeometry,
  isPartialData,
  isRiverSiteInfo,
  PartialData,
  RiverSiteInfo,
  dataTimestamp,
} from "../NeRiverNaturalness/parts";

export {
  PartialData,
  isPartialData,
  dataDescription,
  dataGeometry,
  dataTimestamp,
};

const g = Result.guard(isPartialData(isRiverSiteInfo), "PartialData");

export function copyData(
  data: unknown
): Result<GuardError, PartialData<RiverSiteInfo>> {
  return g(data).map(data => ({
    ...data,
    // recorded: null,
    // site: {},
    // selections: {
    //   physical: null,
    //   physicalMeasures: [],
    //   otherPhysicalMeasures: null,
    //   hydrological: null,
    //   hydrologicalMeasures: [],
    //   otherHydrologicalMeasures: null,
    //   chemical: null,
    //   chemicalMeasures: [],
    //   otherChemicalMeasures: null,
    //   biological: null,
    //   biologicalMeasures: [],
    //   otherBiologicalMeasures: null,
    // },
    // notes: null,
  }));
}
