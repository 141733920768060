// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusNfmPosition =
  | "River"
  | "RiverMargins"
  | "RemainingStudyArea"
  | "Fringe";

const River: EcostatusNfmPosition = "River";
const RiverMargins: EcostatusNfmPosition = "RiverMargins";
const RemainingStudyArea: EcostatusNfmPosition = "RemainingStudyArea";
const Fringe: EcostatusNfmPosition = "Fringe";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusNfmPositionEnum = {
  ...createEnum("EcostatusNfmPosition", [
    [River, "River"],
    [RiverMargins, "River margins"],
    [RemainingStudyArea, "Remaining study area"],
    [Fringe, "Fringe"],
  ]),
  River,
  RiverMargins,
  RemainingStudyArea,
  Fringe,
};

export default EcostatusNfmPositionEnum;
