import {
  attachmentAttr,
  attributeGroup,
  lookupBucket,
  MapId,
  MapSchema,
  marker,
  pointLayer,
  stringAttr,
  surveyAttr,
  teamAttr,
  thresholdAttr,
  thresholdBucket,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { SurveyModuleId, unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { invasivePlantAttr, moduleNumberAttr } from "./helpers";

function morphMap(
  mapId: MapId,
  title: string,
  module: SurveyModuleId
): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,
    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId as string),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId as string),
          attribution: [],
        }),
        defaultAttribute: "index8",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "Survey Details",
            attributes: [
              stringAttr({
                attributeId: "riverName",
                label: "River",
                showDistribution: false,
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "reachName",
                label: "Reach",
                showDistribution: false,
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "subreachName",
                label: "Subreach",
                showDistribution: false,
                buckets: "auto",
              }),
              moduleNumberAttr,
              stringAttr({
                attributeId: "projectName",
                label: "Project Name",
                showDistribution: false,
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectCode",
                label: "MoRPh Correlation Code",
                showDistribution: false,
                buckets: "auto",
              }),
              surveyAttr("surveyId", "Survey"),
              teamAttr("teamId", "Team", { module }),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
              attachmentAttr("photographs", "Photographs"),
            ],
          }),
          attributeGroup({
            label: "Channel Characteristics",
            attributes: [
              thresholdAttr({
                label: "INDEX 1: Number of Flow Types",
                attributeId: "index1",
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(2, marker.quality(0.3)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.8)),
                  thresholdBucket(5, marker.quality(1.0)),
                ],
              }),
              stringAttr({
                label: "INDEX 2: Highest Energy Present/Extensive Flow Type",
                attributeId: "index2",
                buckets: [
                  lookupBucket("DR", marker.quality(0.0), "Dry"),
                  lookupBucket(
                    "NP",
                    marker.quality(0.1),
                    "No perceptible flow"
                  ),
                  lookupBucket("SM", marker.quality(0.2), "Smooth"),
                  lookupBucket("RP", marker.quality(0.3), "Rippled"),
                  lookupBucket("UP", marker.quality(0.5), "Upwelling"),
                  lookupBucket(
                    "UW",
                    marker.quality(0.6),
                    "Unbroken standing waves"
                  ),
                  lookupBucket(
                    "BW",
                    marker.quality(0.8),
                    "Broken standing waves"
                  ),
                  lookupBucket("CH", marker.quality(0.9), "Chute"),
                  lookupBucket("FF", marker.quality(1.0), "Free fall"),
                ],
              }),
              thresholdAttr({
                label: "INDEX 3: Number of Bed Material Types",
                attributeId: "index3",
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(2, marker.quality(0.1)),
                  thresholdBucket(3, marker.quality(0.3)),
                  thresholdBucket(4, marker.quality(0.5)),
                  thresholdBucket(5, marker.quality(0.8)),
                  thresholdBucket(6, marker.quality(0.9)),
                  thresholdBucket(7, marker.quality(1.0)),
                ],
              }),
              stringAttr({
                label:
                  "INDEX 4: Coarsest Present/Extensive Mineral Bed Material Type",
                attributeId: "index4",
                buckets: [
                  lookupBucket("BE", marker.quality(0.0), "Bedrock"),
                  lookupBucket("BO", marker.quality(0.1), "Boulder"),
                  lookupBucket("CO", marker.quality(0.3), "Cobble"),
                  lookupBucket("GP", marker.quality(0.5), "Gravel-Pebble"),
                  lookupBucket("SA", marker.quality(0.8), "Sand"),
                  lookupBucket("SI", marker.quality(0.9), "Silt"),
                  lookupBucket("CL", marker.quality(1.0), "Clay"),
                ],
              }),
              thresholdAttr({
                label:
                  "INDEX 5: Average Alluvial Bed Material Size (Phi Units)",
                attributeId: "index5",
                decimalPlaces: 0,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(-8, marker.quality(0.1)),
                  thresholdBucket(-6, marker.quality(0.2)),
                  thresholdBucket(-4, marker.quality(0.3)),
                  thresholdBucket(-2, marker.quality(0.4)),
                  thresholdBucket(0, marker.quality(0.5)),
                  thresholdBucket(2, marker.quality(0.6)),
                  thresholdBucket(4, marker.quality(0.8)),
                  thresholdBucket(6, marker.quality(0.9)),
                  thresholdBucket(8, marker.quality(1.0)),
                ],
              }),
              stringAttr({
                label: "INDEX 6: Average Alluvial Bed Material Size Class",
                attributeId: "index6",
                buckets: [
                  lookupBucket("BO", marker.quality(0.1), "Boulder"),
                  lookupBucket("CO", marker.quality(0.3), "Cobble"),
                  lookupBucket("GP", marker.quality(0.5), "Gravel-Pebble"),
                  lookupBucket("SA", marker.quality(0.8), "Sand"),
                  lookupBucket("SI", marker.quality(0.9), "Silt"),
                  lookupBucket("CL", marker.quality(1.0), "Clay"),
                ],
              }),
              thresholdAttr({
                label: "INDEX 7: Extent Of Superficial Bed Siltation",
                attributeId: "index7",
                decimalPlaces: 0,
                buckets: [
                  thresholdBucket(null, marker.quality(1.0)),
                  thresholdBucket(1, marker.quality(0.6)),
                  thresholdBucket(2, marker.quality(0.5)),
                  thresholdBucket(3, marker.quality(0.4)),
                  thresholdBucket(4, marker.quality(0.3)),
                  thresholdBucket(5, marker.quality(0.1)),
                  thresholdBucket(6, marker.quality(0.0)),
                ],
              }),
              thresholdAttr({
                label: "INDEX 8: Channel Physical Habitat Complexity",
                attributeId: "index8",
                decimalPlaces: 0,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.3)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.6)),
                  thresholdBucket(5, marker.quality(0.8)),
                  thresholdBucket(6, marker.quality(0.9)),
                  thresholdBucket(7, marker.quality(1.0)),
                ],
              }),
              thresholdAttr({
                label: "INDEX 9: Number of Aquatic Vegetation Morphotypes",
                attributeId: "index9",
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.3)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.6)),
                  thresholdBucket(5, marker.quality(0.8)),
                  thresholdBucket(6, marker.quality(0.9)),
                  thresholdBucket(7, marker.quality(1.0)),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Riparian Characteristics (Bank Top and Bank Face)",
            attributes: [
              thresholdAttr({
                label: "INDEX 10: Riparian Physical Habitat Complexity",
                attributeId: "index10",
                decimalPlaces: 0,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.5)),
                  thresholdBucket(3, marker.quality(0.8)),
                  thresholdBucket(4, marker.quality(0.9)),
                  thresholdBucket(5, marker.quality(1.0)),
                ],
              }),
              thresholdAttr({
                label: "INDEX 11: Riparian Vegetation Structural Complexity",
                attributeId: "index11",
                decimalPlaces: 0,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.2)),
                  thresholdBucket(3, marker.quality(0.3)),
                  thresholdBucket(4, marker.quality(0.4)),
                  thresholdBucket(5, marker.quality(0.5)),
                  thresholdBucket(6, marker.quality(0.6)),
                  thresholdBucket(7, marker.quality(0.8)),
                  thresholdBucket(8, marker.quality(1.0)),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Human Pressures and Impacts",
            attributes: [
              thresholdAttr({
                label:
                  "INDEX 12: Degree of Human Pressure Imposed by Bank Top Land Cover",
                attributeId: "index12",
                decimalPlaces: 0,
                buckets: [
                  thresholdBucket(null, marker.quality(0.9)),
                  thresholdBucket(1, marker.quality(0.8)),
                  thresholdBucket(2, marker.quality(0.6)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.3)),
                  thresholdBucket(5, marker.quality(0.2)),
                  thresholdBucket(6, marker.quality(0.1)),
                  thresholdBucket(7, marker.quality(0.0)),
                ],
              }),
              thresholdAttr({
                label: "INDEX 13: Channel Reinforcement",
                attributeId: "index13",
                decimalPlaces: 0,
                buckets: [
                  thresholdBucket(null, marker.quality(0.9)),
                  thresholdBucket(1, marker.quality(0.8)),
                  thresholdBucket(2, marker.quality(0.6)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.3)),
                  thresholdBucket(5, marker.quality(0.2)),
                  thresholdBucket(6, marker.quality(0.1)),
                  thresholdBucket(7, marker.quality(0.0)),
                ],
              }),
              thresholdAttr({
                label: "INDEX 14: Non-Native Invasive Plant Species Extent",
                attributeId: "index14",
                decimalPlaces: 0,
                buckets: [
                  thresholdBucket(null, marker.quality(0.8)),
                  thresholdBucket(0.5, marker.quality(0.6)),
                  thresholdBucket(1.0, marker.quality(0.5)),
                  thresholdBucket(1.5, marker.quality(0.4)),
                  thresholdBucket(2.0, marker.quality(0.3)),
                  thresholdBucket(2.5, marker.quality(0.2)),
                  thresholdBucket(3.0, marker.quality(0.1)),
                  thresholdBucket(3.5, marker.quality(0.0)),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Non-Native Invasive Plants",
            attributes: [
              invasivePlantAttr("himalayanBalsam", "Himalayan Balsam"),
              invasivePlantAttr("japaneseKnotweed", "Japanese Knotweed"),
              invasivePlantAttr("giantHogweed", "Giant Hogweed"),
              invasivePlantAttr("floatingPennywort", "Floating Pennywort"),
            ],
          }),
        ],
      }),
    ],
  });
}

export const MrsMorph = morphMap(
  unsafeMapId("mrsMorph"),
  "MoRPh",
  unsafeSurveyModuleId("mrsMorph")
);

export const MrsMorphPro = morphMap(
  unsafeMapId("mrsMorphPro"),
  "MoRPh",
  unsafeSurveyModuleId("mrsMorphPro")
);
