import { Option, Result } from "@cartographerio/fp";
import { isPoint, Point, safePointToNgr } from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";

interface PartialData {
  location?: Point | null;
  established?: Timestamp | null;
  catchment?: string | null;
  river?: string | null;
  site?: string | null;
  triggerLevel?: number | null;
  notes?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "established", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "catchment", isNullable(isString)) &&
    hasOptionalKey(data, "river", isNullable(isString)) &&
    hasOptionalKey(data, "site", isNullable(isString)) &&
    hasOptionalKey(data, "triggerLevel", isNullable(isNumber)) &&
    hasOptionalKey(data, "site", isNullable(isString))
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data.catchment,
      data.river,
      data.site,
      Option.wrap(data.location).map(safePointToNgr).getOrUndefined(),
      Option.wrap(data.triggerLevel)
        .map(level => `Trigger ${level}`)
        .getOrUndefined(),
    ]
      .filter(part => part != null)
      .join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.established ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data?.location ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
  }));
}
