// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopPondStatus = "Ghost" | "Zombie" | "Living" | "Other";

const Ghost: UclPitStopPondStatus = "Ghost";
const Zombie: UclPitStopPondStatus = "Zombie";
const Living: UclPitStopPondStatus = "Living";
const Other: UclPitStopPondStatus = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopPondStatusEnum = {
  ...createEnum("UclPitStopPondStatus", [
    [
      Ghost,
      "Ghost pond - the pond is filled in with no evidence or only a shallow depression still present (the area may be wet)",
    ],
    [
      Zombie,
      "Zombie pond - the pond is overgrown and shaded but the pond basin has not been filled in",
    ],
    [Living, "Living pond - The pond is open to sunlight"],
    [Other, "Other (e.g. woodland)"],
  ]),
  Ghost,
  Zombie,
  Living,
  Other,
};

export default UclPitStopPondStatusEnum;
