import { endpoints } from "@cartographerio/client";
import {
  ApiConfig,
  MapBase,
  MapBaseEnum,
  ProjectRef,
  WorkspaceRef,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { chain } from "lodash";
import { Block, FeatureFieldMapStyle, Form, Page } from "./type";

export interface FormStyleUrlsOptions {
  workspace?: WorkspaceRef | null;
  project?: ProjectRef | null;
  base?: MapBase;
}

export function featureFieldStyleUrl(
  apiConfig: ApiConfig,
  mapStyle: FeatureFieldMapStyle,
  options: FormStyleUrlsOptions
): string {
  const { workspace, project, base = MapBaseEnum.Terrain } = options;

  switch (mapStyle.type) {
    case "Cartographer":
      return endpoints.map.style.v2.singleLayer(apiConfig, mapStyle.layer, {
        project: mapStyle.project ?? project,
        workspace,
        base: mapStyle.base ?? base,
      });

    case "External":
      return typeof mapStyle.styleUrl === "string"
        ? mapStyle.styleUrl
        : mapStyle.styleUrl[base];

    default:
      return checkExhausted(mapStyle);
  }
}

export function blockStyleUrls(
  apiConfig: ApiConfig,
  block: Block,
  options: FormStyleUrlsOptions
): string[] {
  switch (block.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
    case "IntegerField":
    case "NumberField":
    case "Select":
    case "MultiSelect":
    case "Checkbox":
    case "UserRefField":
    case "TimestampField":
    case "AttachmentField":
    case "TeamField":
    case "Heading":
    case "Text":
    case "DynamicValue":
    case "DynamicGrid":
    case "DynamicImage":
    case "PrimarySurveyorField":
    case "PrimaryTeamField":
      return [];

    case "PointField":
    case "LineStringField":
    case "PolygonField":
      return [endpoints.map.style.v2.base(apiConfig, options.base)];

    case "FeatureField":
    case "NearestFeatureField":
      return [
        featureFieldStyleUrl(apiConfig, block.mapOptions.mapStyle, options),
      ];

    case "Group":
      return block.blocks.flatMap(block =>
        blockStyleUrls(apiConfig, block, options)
      );

    case "Card":
    case "FullWidth":
    case "Repeat":
    case "RequireRole":
    case "Scope":
    case "Visibility":
      return blockStyleUrls(apiConfig, block.block, options);

    default:
      return checkExhausted(block);
  }
}

export function pageStyleUrls(
  apiConfig: ApiConfig,
  page: Page,
  options: FormStyleUrlsOptions
): string[] {
  return page.blocks.flatMap(block =>
    blockStyleUrls(apiConfig, block, options)
  );
}

export function formStyleUrls(
  apiConfig: ApiConfig,
  form: Form,
  options: FormStyleUrlsOptions
): string[] {
  return chain(form.pages)
    .flatMap(page => pageStyleUrls(apiConfig, page, options))
    .uniq()
    .sort()
    .value();
}
