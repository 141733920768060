// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsArtificialFeature =
  | "majorBridge"
  | "intermediateBridge"
  | "minorBridge"
  | "majorWeirOrSluice"
  | "intermediateWeirOrSluice"
  | "minorWeirOrSluice"
  | "majorDeflectorOrGroyne"
  | "intermediateDeflectorOrGroyne"
  | "minorDeflectorOrGroyne"
  | "culvert"
  | "majorOther"
  | "intermediateOther"
  | "minorOther";

const MajorBridge: UrsArtificialFeature = "majorBridge";
const IntermediateBridge: UrsArtificialFeature = "intermediateBridge";
const MinorBridge: UrsArtificialFeature = "minorBridge";
const MajorWeirOrSluice: UrsArtificialFeature = "majorWeirOrSluice";
const IntermediateWeirOrSluice: UrsArtificialFeature =
  "intermediateWeirOrSluice";
const MinorWeirOrSluice: UrsArtificialFeature = "minorWeirOrSluice";
const MajorDeflectorOrGroyne: UrsArtificialFeature = "majorDeflectorOrGroyne";
const IntermediateDeflectorOrGroyne: UrsArtificialFeature =
  "intermediateDeflectorOrGroyne";
const MinorDeflectorOrGroyne: UrsArtificialFeature = "minorDeflectorOrGroyne";
const Culvert: UrsArtificialFeature = "culvert";
const MajorOther: UrsArtificialFeature = "majorOther";
const IntermediateOther: UrsArtificialFeature = "intermediateOther";
const MinorOther: UrsArtificialFeature = "minorOther";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsArtificialFeatureEnum = {
  ...createEnum("UrsArtificialFeature", [
    [MajorBridge, "Major bridge"],
    [IntermediateBridge, "Intermediate bridge"],
    [MinorBridge, "Minor bridge"],
    [MajorWeirOrSluice, "Major weir / sluice"],
    [IntermediateWeirOrSluice, "Intermediate weir / sluice"],
    [MinorWeirOrSluice, "Minor weir / sluice"],
    [MajorDeflectorOrGroyne, "Major deflector / groyne"],
    [IntermediateDeflectorOrGroyne, "Intermediate deflector / groyne"],
    [MinorDeflectorOrGroyne, "Minor deflector / groyne"],
    [Culvert, "Culvert"],
    [MajorOther, "Major other (state)"],
    [IntermediateOther, "Intermediate other (state)"],
    [MinorOther, "Minor other (state)"],
  ]),
  MajorBridge,
  IntermediateBridge,
  MinorBridge,
  MajorWeirOrSluice,
  IntermediateWeirOrSluice,
  MinorWeirOrSluice,
  MajorDeflectorOrGroyne,
  IntermediateDeflectorOrGroyne,
  MinorDeflectorOrGroyne,
  Culvert,
  MajorOther,
  IntermediateOther,
  MinorOther,
};

export default UrsArtificialFeatureEnum;
