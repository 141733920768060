import { PermissionCheck, WorkspaceId } from "@cartographerio/types";
import { check } from "../check";

export function active(workspaceId: WorkspaceId): PermissionCheck {
  return check.named(
    `Active member of workspace ${workspaceId}`,
    check.or(check.globalAdmin, check.workspaceActive(workspaceId))
  );
}

export function admin(workspaceId: WorkspaceId): PermissionCheck {
  return check.named(
    `Admin of workspace ${workspaceId}`,
    check.or(check.globalAdmin, check.workspaceAdmin(workspaceId))
  );
}

export function editFeatures(workspaceId: WorkspaceId): PermissionCheck {
  return check.named(
    `Edit feature flags for workspace ${workspaceId}`,
    check.superuser
  );
}

export function grantAccess(workspaceId: WorkspaceId): PermissionCheck {
  return check.named(
    `Grant access to workspace ${workspaceId}`,
    check.or(check.globalAdmin, check.workspaceAdmin(workspaceId))
  );
}

export function remove(workspaceId: WorkspaceId): PermissionCheck {
  return check.named(`Delete workspace ${workspaceId}`, check.superuser);
}

export function readWorkspaceLimits(workspaceId: WorkspaceId): PermissionCheck {
  return check.named(
    `Read workspace limits ${workspaceId}`,
    check.workspaceOwner(workspaceId)
  );
}

export function updateWorkspaceLimits(
  workspaceId: WorkspaceId
): PermissionCheck {
  return check.named(
    `Update workspace limits ${workspaceId}`,
    check.workspaceOwner(workspaceId)
  );
}

export function checkWorkspaceLimits(
  workspaceId: WorkspaceId
): PermissionCheck {
  return check.named(
    `Check workspace limits ${workspaceId}`,
    check.workspaceActive(workspaceId)
  );
}

export function checkAllWorkspaceLimits(): PermissionCheck {
  return check.named("Check all workspace limits", check.globalAdmin);
}
