// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsHorizontalOrientation = "PE" | "PA" | "DI";

const Perpendicular: MrsHorizontalOrientation = "PE";
const Parallel: MrsHorizontalOrientation = "PA";
const Diagonal: MrsHorizontalOrientation = "DI";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsHorizontalOrientationEnum = {
  ...createEnum("MrsHorizontalOrientation", [
    [Perpendicular, "Perpendicular to banks (> 60\u00B0)"],
    [Parallel, "Parallel to banks (< 30\u00B0)"],
    [Diagonal, "Diagonal (30\u00B0 - 60\u00B0)"],
  ]),
  Perpendicular,
  Parallel,
  Diagonal,
};

export default MrsHorizontalOrientationEnum;
