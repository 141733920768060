import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  knowledge: {
    localName?: string | null;
  };
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(
      data,
      "knowledge",
      (knowledge: unknown): knowledge is PartialData["knowledge"] =>
        isObject(knowledge) &&
        hasOptionalKey(knowledge, "localName", isNullable(isString))
    )
  );
}

const g = Result.guard(isPartialData, "PartialUclPitStop");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    [
      data.knowledge.localName ?? "Unnamed Pond",
      data.location == null ? "Unknown NGR" : safePointToNgr(data.location),
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return g(data).map(data => ({
    ...data,
    recorded: null,
  }));
}
