// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessChemicalAssessmentForm =
  | "Visual"
  | "TestKit"
  | "BioSampling"
  | "Wfd"
  | "Other";

const Visual: NeRiverNaturalnessChemicalAssessmentForm = "Visual";
const TestKit: NeRiverNaturalnessChemicalAssessmentForm = "TestKit";
const BioSampling: NeRiverNaturalnessChemicalAssessmentForm = "BioSampling";
const Wfd: NeRiverNaturalnessChemicalAssessmentForm = "Wfd";
const Other: NeRiverNaturalnessChemicalAssessmentForm = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessChemicalAssessmentFormEnum = {
  ...createEnum("NeRiverNaturalnessChemicalAssessmentForm", [
    [Visual, "Simple visual inspection"],
    [TestKit, "Test kit"],
    [BioSampling, "Bankside biological sampling"],
    [Wfd, "Water Framework Directive data"],
    [Other, "Other (please specify)"],
  ]),
  Visual,
  TestKit,
  BioSampling,
  Wfd,
  Other,
};

export default NeRiverNaturalnessChemicalAssessmentFormEnum;
