// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopDescription =
  | "Village"
  | "Farmland"
  | "NatureReserveOrWoodland"
  | "Ornamental"
  | "GardenWildlife"
  | "GardenOrnamental"
  | "Coastal"
  | "Other";

const Village: UclPitStopDescription = "Village";
const Farmland: UclPitStopDescription = "Farmland";
const NatureReserveOrWoodland: UclPitStopDescription =
  "NatureReserveOrWoodland";
const Ornamental: UclPitStopDescription = "Ornamental";
const GardenWildlife: UclPitStopDescription = "GardenWildlife";
const GardenOrnamental: UclPitStopDescription = "GardenOrnamental";
const Coastal: UclPitStopDescription = "Coastal";
const Other: UclPitStopDescription = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopDescriptionEnum = {
  ...createEnum("UclPitStopDescription", [
    [Village, "Village pond"],
    [Farmland, "Farmland pond"],
    [NatureReserveOrWoodland, "Pond in nature reserve or woodland"],
    [Ornamental, "Ornamental pond"],
    [GardenWildlife, "Garden pond (for wildlife)"],
    [GardenOrnamental, "Garden pond (for fish or ornamental)"],
    [Coastal, "Coastal"],
    [Other, "Other"],
  ]),
  Village,
  Farmland,
  NatureReserveOrWoodland,
  Ornamental,
  GardenWildlife,
  GardenOrnamental,
  Coastal,
  Other,
};

export default UclPitStopDescriptionEnum;
