/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  Email,
  isEmail,
  InvitationCodeId,
  isInvitationCodeId,
  InvitationId,
  isInvitationId,
  ScreenName,
  isScreenName,
  Timestamp,
  isTimestamp,
  UserId,
  isUserId,
} from "../core/types.generated";
import {
  QualificationRole,
  isQualificationRole,
  Role,
  isRole,
} from "../permission/types.generated";

export type MemberType = "Current" | "Pending" | "Invited";

export interface CurrentMember {
  userId: UserId;
  firstName: string;
  lastName: string;
  screenName: ScreenName;
  email: Email;
  roles: Role[];
  qualificationRoles: QualificationRole[];
  created: Timestamp;
}

export interface PendingMember {
  userId: UserId;
  firstName: string;
  lastName: string;
  screenName: ScreenName;
  email: Email;
  roles: Role[];
  qualificationRoles: QualificationRole[];
  created: Timestamp;
  invitationCodeId: InvitationCodeId;
  emailVerified?: Timestamp | null;
  signupApproved?: Timestamp | null;
  invitationCodeRoles: Role[];
  invitationCodeQualificationRoles: QualificationRole[];
}

export interface InvitedMember {
  invitationId: InvitationId;
  firstName: string;
  lastName: string;
  email: Email;
  roles: Role[];
  qualificationRoles: QualificationRole[];
  created: Timestamp;
}

export type Member =
  | ({ type: "CurrentMember" } & CurrentMember)
  | ({ type: "PendingMember" } & PendingMember)
  | ({ type: "InvitedMember" } & InvitedMember);

export const isMemberType = (v: any): v is MemberType => {
  return v === "Current" || v === "Pending" || v === "Invited";
};

export const isCurrentMember = (v: any): v is CurrentMember => {
  return (
    typeof v === "object" &&
    v != null &&
    "userId" in v &&
    isUserId(v.userId) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "screenName" in v &&
    isScreenName(v.screenName) &&
    "email" in v &&
    isEmail(v.email) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i)) &&
    "created" in v &&
    isTimestamp(v.created)
  );
};

export const isPendingMember = (v: any): v is PendingMember => {
  return (
    typeof v === "object" &&
    v != null &&
    "userId" in v &&
    isUserId(v.userId) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "screenName" in v &&
    isScreenName(v.screenName) &&
    "email" in v &&
    isEmail(v.email) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i)) &&
    "created" in v &&
    isTimestamp(v.created) &&
    "invitationCodeId" in v &&
    isInvitationCodeId(v.invitationCodeId) &&
    (!("emailVerified" in v) ||
      isTimestamp(v.emailVerified) ||
      v.emailVerified === null) &&
    (!("signupApproved" in v) ||
      isTimestamp(v.signupApproved) ||
      v.signupApproved === null) &&
    "invitationCodeRoles" in v &&
    Array.isArray(v.invitationCodeRoles) &&
    v.invitationCodeRoles.every((i: any) => isRole(i)) &&
    "invitationCodeQualificationRoles" in v &&
    Array.isArray(v.invitationCodeQualificationRoles) &&
    v.invitationCodeQualificationRoles.every((i: any) => isQualificationRole(i))
  );
};

export const isInvitedMember = (v: any): v is InvitedMember => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitationId" in v &&
    isInvitationId(v.invitationId) &&
    "firstName" in v &&
    typeof v.firstName === "string" &&
    "lastName" in v &&
    typeof v.lastName === "string" &&
    "email" in v &&
    isEmail(v.email) &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isRole(i)) &&
    "qualificationRoles" in v &&
    Array.isArray(v.qualificationRoles) &&
    v.qualificationRoles.every((i: any) => isQualificationRole(i)) &&
    "created" in v &&
    isTimestamp(v.created)
  );
};

export const isMember = (v: any): v is Member => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    ((v.type === "CurrentMember" && isCurrentMember(v)) ||
      (v.type === "PendingMember" && isPendingMember(v)) ||
      (v.type === "InvitedMember" && isInvitedMember(v)))
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
