// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsEcologicalCharacteristic =
  | "aquaticInvertebrate_dragonfly"
  | "aquaticInvertebrate_damselfly"
  | "aquaticInvertebrate_bandedDamoiselle"
  | "aquaticInvertebrate_midge"
  | "aquaticInvertebrate_other"
  | "terrestrialInvertebrate_stagBeetle"
  | "terrestrialInvertebrate_bombusHumilis"
  | "terrestrialInvertebrate_beetle"
  | "terrestrialInvertebrate_other"
  | "mammal_hedgehog"
  | "mammal_other"
  | "amphibianOrReptile_commonFrog"
  | "amphibianOrReptile_toad"
  | "amphibianOrReptile_grassSnake"
  | "amphibianOrReptile_slowWorm"
  | "amphibianOrReptile_other"
  | "bird_blackRedstart"
  | "bird_greyHeron"
  | "bird_greyWagtail"
  | "bird_houseMartin"
  | "bird_houseSparrow"
  | "bird_kingfisher"
  | "bird_robin"
  | "bird_sandMartin"
  | "bird_swift"
  | "bird_other"
  | "fish_bullhead"
  | "fish_minnow"
  | "fish_other"
  | "tree_mistletoe"
  | "tree_blackPoplar"
  | "tree_crackWillow"
  | "tree_alder"
  | "tree_other"
  | "plant_waterCrowfoot"
  | "plant_waterCress"
  | "plant_purpleLoosestrife"
  | "plant_commonReed"
  | "plant_other";

const AquaticInvertebrateDragonfly: UrsEcologicalCharacteristic =
  "aquaticInvertebrate_dragonfly";
const AquaticInvertebrateDamselfly: UrsEcologicalCharacteristic =
  "aquaticInvertebrate_damselfly";
const AquaticInvertebrateBandedDamoiselle: UrsEcologicalCharacteristic =
  "aquaticInvertebrate_bandedDamoiselle";
const AquaticInvertebrateMidge: UrsEcologicalCharacteristic =
  "aquaticInvertebrate_midge";
const AquaticInvertebrateOther: UrsEcologicalCharacteristic =
  "aquaticInvertebrate_other";
const TerrestrialInvertebrateStagBeetle: UrsEcologicalCharacteristic =
  "terrestrialInvertebrate_stagBeetle";
const TerrestrialInvertebrateBombusHumilis: UrsEcologicalCharacteristic =
  "terrestrialInvertebrate_bombusHumilis";
const TerrestrialInvertebrateBeetle: UrsEcologicalCharacteristic =
  "terrestrialInvertebrate_beetle";
const TerrestrialInvertebrateOther: UrsEcologicalCharacteristic =
  "terrestrialInvertebrate_other";
const MammalHedgehog: UrsEcologicalCharacteristic = "mammal_hedgehog";
const MammalOther: UrsEcologicalCharacteristic = "mammal_other";
const AmphibianOrReptileCommonFrog: UrsEcologicalCharacteristic =
  "amphibianOrReptile_commonFrog";
const AmphibianOrReptileToad: UrsEcologicalCharacteristic =
  "amphibianOrReptile_toad";
const AmphibianOrReptileGrassSnake: UrsEcologicalCharacteristic =
  "amphibianOrReptile_grassSnake";
const AmphibianOrReptileSlowWorm: UrsEcologicalCharacteristic =
  "amphibianOrReptile_slowWorm";
const AmphibianOrReptileOther: UrsEcologicalCharacteristic =
  "amphibianOrReptile_other";
const BirdBlackRedstart: UrsEcologicalCharacteristic = "bird_blackRedstart";
const BirdGreyHeron: UrsEcologicalCharacteristic = "bird_greyHeron";
const BirdGreyWagtail: UrsEcologicalCharacteristic = "bird_greyWagtail";
const BirdHouseMartin: UrsEcologicalCharacteristic = "bird_houseMartin";
const BirdHouseSparrow: UrsEcologicalCharacteristic = "bird_houseSparrow";
const BirdKingfisher: UrsEcologicalCharacteristic = "bird_kingfisher";
const BirdRobin: UrsEcologicalCharacteristic = "bird_robin";
const BirdSandMartin: UrsEcologicalCharacteristic = "bird_sandMartin";
const BirdSwift: UrsEcologicalCharacteristic = "bird_swift";
const BirdOther: UrsEcologicalCharacteristic = "bird_other";
const FishBullhead: UrsEcologicalCharacteristic = "fish_bullhead";
const FishMinnow: UrsEcologicalCharacteristic = "fish_minnow";
const FishOther: UrsEcologicalCharacteristic = "fish_other";
const TreeMistletoe: UrsEcologicalCharacteristic = "tree_mistletoe";
const TreeBlackPoplar: UrsEcologicalCharacteristic = "tree_blackPoplar";
const TreeCrackWillow: UrsEcologicalCharacteristic = "tree_crackWillow";
const TreeAlder: UrsEcologicalCharacteristic = "tree_alder";
const TreeOther: UrsEcologicalCharacteristic = "tree_other";
const PlantWaterCrowfoot: UrsEcologicalCharacteristic = "plant_waterCrowfoot";
const PlantWaterCress: UrsEcologicalCharacteristic = "plant_waterCress";
const PlantPurpleLoosestrife: UrsEcologicalCharacteristic =
  "plant_purpleLoosestrife";
const PlantCommonReed: UrsEcologicalCharacteristic = "plant_commonReed";
const PlantOther: UrsEcologicalCharacteristic = "plant_other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsEcologicalCharacteristicEnum = {
  ...createEnum("UrsEcologicalCharacteristic", [
    [AquaticInvertebrateDragonfly, "Dragonfly"],
    [AquaticInvertebrateDamselfly, "Damselfly"],
    [AquaticInvertebrateBandedDamoiselle, "Banded Damoiselle"],
    [AquaticInvertebrateMidge, "Midge"],
    [AquaticInvertebrateOther, "Other aquatic invertebrate (specify below)"],
    [TerrestrialInvertebrateStagBeetle, "Stag Beetle"],
    [TerrestrialInvertebrateBombusHumilis, "Bombus Humilis"],
    [TerrestrialInvertebrateBeetle, "Beetle"],
    [
      TerrestrialInvertebrateOther,
      "Other terrestrial invertebrate (specify below)",
    ],
    [MammalHedgehog, "Hedgehog"],
    [MammalOther, "Other mammal (specify below)"],
    [AmphibianOrReptileCommonFrog, "Common Frog"],
    [AmphibianOrReptileToad, "Toad"],
    [AmphibianOrReptileGrassSnake, "Grass Snake"],
    [AmphibianOrReptileSlowWorm, "Slow Worm"],
    [AmphibianOrReptileOther, "Other amphibian or reptile (specify below)"],
    [BirdBlackRedstart, "Black Redstart"],
    [BirdGreyHeron, "Grey Heron"],
    [BirdGreyWagtail, "Grey Wagtail"],
    [BirdHouseMartin, "House Martin"],
    [BirdHouseSparrow, "House Sparrow"],
    [BirdKingfisher, "Kingfisher"],
    [BirdRobin, "Robin"],
    [BirdSandMartin, "Sand Martin"],
    [BirdSwift, "Swift"],
    [BirdOther, "Other bird (specify below)"],
    [FishBullhead, "Bullhead"],
    [FishMinnow, "Minnow"],
    [FishOther, "Other fish (specify below)"],
    [TreeMistletoe, "Mistletoe"],
    [TreeBlackPoplar, "Black Poplar"],
    [TreeCrackWillow, "Crack Willow"],
    [TreeAlder, "Alder (specify healthy or diseased below)"],
    [TreeOther, "Other tree (specify)"],
    [PlantWaterCrowfoot, "Water Crowfoot (Ranunculus species)"],
    [PlantWaterCress, "Water Cress"],
    [PlantPurpleLoosestrife, "Purple Loosestrife"],
    [PlantCommonReed, "Common Reed"],
    [PlantOther, "Other plant (specify below)"],
  ]),
  AquaticInvertebrateDragonfly,
  AquaticInvertebrateDamselfly,
  AquaticInvertebrateBandedDamoiselle,
  AquaticInvertebrateMidge,
  AquaticInvertebrateOther,
  TerrestrialInvertebrateStagBeetle,
  TerrestrialInvertebrateBombusHumilis,
  TerrestrialInvertebrateBeetle,
  TerrestrialInvertebrateOther,
  MammalHedgehog,
  MammalOther,
  AmphibianOrReptileCommonFrog,
  AmphibianOrReptileToad,
  AmphibianOrReptileGrassSnake,
  AmphibianOrReptileSlowWorm,
  AmphibianOrReptileOther,
  BirdBlackRedstart,
  BirdGreyHeron,
  BirdGreyWagtail,
  BirdHouseMartin,
  BirdHouseSparrow,
  BirdKingfisher,
  BirdRobin,
  BirdSandMartin,
  BirdSwift,
  BirdOther,
  FishBullhead,
  FishMinnow,
  FishOther,
  TreeMistletoe,
  TreeBlackPoplar,
  TreeCrackWillow,
  TreeAlder,
  TreeOther,
  PlantWaterCrowfoot,
  PlantWaterCress,
  PlantPurpleLoosestrife,
  PlantCommonReed,
  PlantOther,
};

export default UrsEcologicalCharacteristicEnum;
