// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopCrassulaHelmsii =
  | "None"
  | "SmallFragments"
  | "EasilyVisible";

const None: UclPitStopCrassulaHelmsii = "None";
const SmallFragments: UclPitStopCrassulaHelmsii = "SmallFragments";
const EasilyVisible: UclPitStopCrassulaHelmsii = "EasilyVisible";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopCrassulaHelmsiiEnum = {
  ...createEnum("UclPitStopCrassulaHelmsii", [
    [None, "No"],
    [SmallFragments, "Small fragments"],
    [EasilyVisible, "Easily visible"],
  ]),
  None,
  SmallFragments,
  EasilyVisible,
};

export default UclPitStopCrassulaHelmsiiEnum;
