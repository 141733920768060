// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiBankVegetation =
  | "bareEarth"
  | "impermeableSurface"
  | "treesOrShrubs"
  | "grass"
  | "other";

const BareEarth: WrtWestcountryCsiBankVegetation = "bareEarth";
const ImpermeableSurface: WrtWestcountryCsiBankVegetation =
  "impermeableSurface";
const TreesOrShrubs: WrtWestcountryCsiBankVegetation = "treesOrShrubs";
const Grass: WrtWestcountryCsiBankVegetation = "grass";
const Other: WrtWestcountryCsiBankVegetation = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiBankVegetationEnum = {
  ...createEnum("WrtWestcountryCsiBankVegetation", [
    [BareEarth, "None (bare earth)"],
    [ImpermeableSurface, "None (impermeable surface)"],
    [TreesOrShrubs, "Trees/shrubs"],
    [Grass, "Grass"],
    [Other, "Other (please specify)"],
  ]),
  BareEarth,
  ImpermeableSurface,
  TreesOrShrubs,
  Grass,
  Other,
};

export default WrtWestcountryCsiBankVegetationEnum;
