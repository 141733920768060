// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsVegetation =
  | "none"
  | "liverwortsOrMossesOrLichens"
  | "emergentBroadLeavedHerbs"
  | "emergentReedsOrLinearLeavedOrHorsetails"
  | "floatingLeavedRooted"
  | "freeFloating"
  | "amphibious"
  | "submergedBroadLeaved"
  | "submergedLinearLeaved"
  | "submergedFineLeaved"
  | "filamentousAlgae";

const None: UrsVegetation = "none";
const LiverwortsOrMossesOrLichens: UrsVegetation =
  "liverwortsOrMossesOrLichens";
const EmergentBroadLeavedHerbs: UrsVegetation = "emergentBroadLeavedHerbs";
const EmergentReedsOrLinearLeavedOrHorsetails: UrsVegetation =
  "emergentReedsOrLinearLeavedOrHorsetails";
const FloatingLeavedRooted: UrsVegetation = "floatingLeavedRooted";
const FreeFloating: UrsVegetation = "freeFloating";
const Amphibious: UrsVegetation = "amphibious";
const SubmergedBroadLeaved: UrsVegetation = "submergedBroadLeaved";
const SubmergedLinearLeaved: UrsVegetation = "submergedLinearLeaved";
const SubmergedFineLeaved: UrsVegetation = "submergedFineLeaved";
const FilamentousAlgae: UrsVegetation = "filamentousAlgae";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsVegetationEnum = {
  ...createEnum("UrsVegetation", [
    [None, "None"],
    [LiverwortsOrMossesOrLichens, "Liverworts / mosses / lichens"],
    [EmergentBroadLeavedHerbs, "Emergent broad-leaved herbs"],
    [
      EmergentReedsOrLinearLeavedOrHorsetails,
      "Emergent reeds / linear-leaved / horsetails",
    ],
    [FloatingLeavedRooted, "Floating leaved (rooted)"],
    [FreeFloating, "Free-floating"],
    [Amphibious, "Amphibious"],
    [SubmergedBroadLeaved, "Submerged broad-leaved"],
    [SubmergedLinearLeaved, "Submerged linear-leaved"],
    [SubmergedFineLeaved, "Submerged fine-leaved"],
    [FilamentousAlgae, "Filamentous algae"],
  ]),
  None,
  LiverwortsOrMossesOrLichens,
  EmergentBroadLeavedHerbs,
  EmergentReedsOrLinearLeavedOrHorsetails,
  FloatingLeavedRooted,
  FreeFloating,
  Amphibious,
  SubmergedBroadLeaved,
  SubmergedLinearLeaved,
  SubmergedFineLeaved,
  FilamentousAlgae,
};

export default UrsVegetationEnum;
