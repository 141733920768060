// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessInvertebrateSpecies =
  | "PlectrocnemiaSp"
  | "WormaldiaSp"
  | "LypeSp"
  | "WhiteClawedCrayfish";

const PlectrocnemiaSp: NeRiverNaturalnessInvertebrateSpecies =
  "PlectrocnemiaSp";
const WormaldiaSp: NeRiverNaturalnessInvertebrateSpecies = "WormaldiaSp";
const LypeSp: NeRiverNaturalnessInvertebrateSpecies = "LypeSp";
const WhiteClawedCrayfish: NeRiverNaturalnessInvertebrateSpecies =
  "WhiteClawedCrayfish";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessInvertebrateSpeciesEnum = {
  ...createEnum("NeRiverNaturalnessInvertebrateSpecies", [
    [PlectrocnemiaSp, "Plectrocnemia sp (Caseless caddis-flies)"],
    [WormaldiaSp, "Wormaldia sp (Caseless caddis-flies)"],
    [LypeSp, "Lype sp (Caseless caddis-flies)"],
    [WhiteClawedCrayfish, "White-clawed crayfish (Austropotamobius pallipes)"],
  ]),
  PlectrocnemiaSp,
  WormaldiaSp,
  LypeSp,
  WhiteClawedCrayfish,
};

export default NeRiverNaturalnessInvertebrateSpeciesEnum;
