// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeChalkRiversTreeShading =
  | "None"
  | "VeryLight"
  | "Light"
  | "Medium"
  | "Heavy";

const None: NeChalkRiversTreeShading = "None";
const VeryLight: NeChalkRiversTreeShading = "VeryLight";
const Light: NeChalkRiversTreeShading = "Light";
const Medium: NeChalkRiversTreeShading = "Medium";
const Heavy: NeChalkRiversTreeShading = "Heavy";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeChalkRiversTreeShadingEnum = {
  ...createEnum("NeChalkRiversTreeShading", [
    [None, "None"],
    [VeryLight, "<10%"],
    [Light, "10-33%"],
    [Medium, "33-66%"],
    [Heavy, ">66%"],
  ]),
  None,
  VeryLight,
  Light,
  Medium,
  Heavy,
};

export default NeChalkRiversTreeShadingEnum;
