// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankTerrestrialVegetation =
  | "unvegetated"
  | "mossesOrLichens"
  | "shortCreepingHerbsOrGrasses"
  | "tallHerbsOrGrasses"
  | "scrubOrShrubs"
  | "saplingsOrTrees"
  | "leaningTrees"
  | "fallenTrees";

const Unvegetated: CesBankTerrestrialVegetation = "unvegetated";
const MossesOrLichens: CesBankTerrestrialVegetation = "mossesOrLichens";
const ShortCreepingHerbsOrGrasses: CesBankTerrestrialVegetation =
  "shortCreepingHerbsOrGrasses";
const TallHerbsOrGrasses: CesBankTerrestrialVegetation = "tallHerbsOrGrasses";
const ScrubOrShrubs: CesBankTerrestrialVegetation = "scrubOrShrubs";
const SaplingsOrTrees: CesBankTerrestrialVegetation = "saplingsOrTrees";
const LeaningTrees: CesBankTerrestrialVegetation = "leaningTrees";
const FallenTrees: CesBankTerrestrialVegetation = "fallenTrees";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankTerrestrialVegetationEnum = {
  ...createEnum("CesBankTerrestrialVegetation", [
    [Unvegetated, "Unvegetated (bare soil)"],
    [MossesOrLichens, "Mosses / lichens"],
    [ShortCreepingHerbsOrGrasses, "Short/creeping herbs/grasses"],
    [TallHerbsOrGrasses, "Tall herbs/grasses"],
    [ScrubOrShrubs, "Scrub or shrubs"],
    [SaplingsOrTrees, "Saplings or trees"],
    [LeaningTrees, "Leaning trees"],
    [FallenTrees, "Fallen trees"],
  ]),
  Unvegetated,
  MossesOrLichens,
  ShortCreepingHerbsOrGrasses,
  TallHerbsOrGrasses,
  ScrubOrShrubs,
  SaplingsOrTrees,
  LeaningTrees,
  FallenTrees,
};

export default CesBankTerrestrialVegetationEnum;
