import { Tagged, unsafeTag } from "@cartographerio/util";

export type TimeZone = Tagged<"TimeZone">;

export function unsafeTimeZone(str: string): TimeZone {
  return unsafeTag(str);
}

export const defaultTimeZone = unsafeTimeZone("Europe/London");

export const utc = unsafeTimeZone("UTC");
