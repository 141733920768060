// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankVerticalProfile = "V" | "O" | "S" | "G";

const Vertical: CesBankVerticalProfile = "V";
const Overhang: CesBankVerticalProfile = "O";
const Steep: CesBankVerticalProfile = "S";
const Gentle: CesBankVerticalProfile = "G";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankVerticalProfileEnum = {
  ...createEnum("CesBankVerticalProfile", [
    [Vertical, "(Near) vertical"],
    [Overhang, "Vertical with top overhang"],
    [Steep, "Steep (>45degrees)"],
    [Gentle, "Gentle (<45 degrees)"],
  ]),
  Vertical,
  Overhang,
  Steep,
  Gentle,
};

export default CesBankVerticalProfileEnum;
