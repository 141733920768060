import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isSearchResults,
  isProjectTemplate,
  SearchResults,
  ProjectTemplateId,
  ProjectTemplate,
} from "@cartographerio/types";
import { fetch } from "../../..";
import { contentAs, optionalContentAs } from "../../../response";

const basePath = "/project/template/v1";

export function search(
  apiParams: ApiParams
): IO<SearchResults<ProjectTemplate>> {
  return fetch
    .get({ apiParams, url: { path: basePath } })
    .flatMap(
      contentAs(
        "SearchResults<ProjectTemplate>",
        isSearchResults(isProjectTemplate)
      )
    );
}

export function readOrFail(
  apiParams: ApiParams,
  id: ProjectTemplateId
): IO<ProjectTemplate> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${id}` } })
    .flatMap(contentAs("ProjectTemplate", isProjectTemplate));
}

export function readOption(
  apiParams: ApiParams,
  id: ProjectTemplateId
): IO<Option<ProjectTemplate>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${id}` } })
    .flatMap(optionalContentAs("ProjectTemplate", isProjectTemplate));
}
