// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopGameBirdFeeding = "Medium" | "Low" | "None";

const Medium: UclPitStopGameBirdFeeding = "Medium";
const Low: UclPitStopGameBirdFeeding = "Low";
const None: UclPitStopGameBirdFeeding = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopGameBirdFeedingEnum = {
  ...createEnum("UclPitStopGameBirdFeeding", [
    [Medium, "Medium - Evidence of drum feeders and/or game birds at the pond"],
    [Low, "Low - Evidence of feeding game birds locally but not near the pond"],
    [None, "None - No evidence of feeding game birds"],
  ]),
  Medium,
  Low,
  None,
};

export default UclPitStopGameBirdFeedingEnum;
