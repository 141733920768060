// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopRecreationalFishing = "High" | "Medium" | "None";

const High: UclPitStopRecreationalFishing = "High";
const Medium: UclPitStopRecreationalFishing = "Medium";
const None: UclPitStopRecreationalFishing = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopRecreationalFishingEnum = {
  ...createEnum("UclPitStopRecreationalFishing", [
    [High, "High - Evidence of people fishing at pond during visit"],
    [
      Medium,
      "Medium - No fishing during visit but evidence of the site being used for fishing",
    ],
    [None, "None - No evidence of fishing at the pond"],
  ]),
  High,
  Medium,
  None,
};

export default UclPitStopRecreationalFishingEnum;
