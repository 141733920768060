import {
  CurrentMember,
  PendingMember,
  PermissionCheck,
  Project,
  Team,
  User,
  UserFeature,
  WorkspaceId,
} from "@cartographerio/types";
import { check } from "../check";

export function edit(user: User, workspace: WorkspaceId): PermissionCheck {
  return check.named(
    `Edit user ${user.screenName} (${user.id})`,
    check.workspaceAdmin(workspace)
  );
}

export function editFeature(feature: UserFeature): PermissionCheck {
  const description = `Edit user feature flag ${feature}`;

  switch (feature) {
    case "ChangeIdentity":
      return check.named(description, check.superuser);
    case "IntegrationTest":
    case "NonBillable":
    case "Mobile2Preview":
    case "Mobile2Default":
    case "EnableMobileRiverfly":
    default:
      return check.named(description, check.globalAdmin);
  }
}

export function remove(
  user: User | CurrentMember | PendingMember
): PermissionCheck {
  return check.named(
    `Delete user ${user.screenName} (${"id" in user ? user.id : user.userId})`,
    check.globalAdmin
  );
}

export function removeFromWorkspace(workspaceId: WorkspaceId): PermissionCheck {
  return check.named(
    `Remove user from workspace ${workspaceId}`,
    check.or(check.globalAdmin, check.workspaceAdmin(workspaceId))
  );
}

export function removeFromProject(project: Project): PermissionCheck {
  return check.named(
    `Remove user from project ${project.id}`,
    // No teamIds array below because team coordinators shouldn't be able to remove a user from a project:
    check.projectCoordinator(project.id, project.workspaceId, null)
  );
}

export function removeFromTeam(team: Team): PermissionCheck {
  return check.named(
    `Remove user from team ${team.id}`,
    // No projectIds array below because project coordinators shouldn't be able to remove a user from a team:
    check.teamCoordinator(team.id, team.workspaceId, null)
  );
}
