/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  InvitationEmailSettings,
  isInvitationEmailSettings,
  NamedEmail,
  isNamedEmail,
  WorkspaceAccessLevel,
  isWorkspaceAccessLevel,
  WorkspaceAlias,
  isWorkspaceAlias,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";

export type BannerMessageHighlight =
  | "default"
  | "info"
  | "success"
  | "warning"
  | "error";

export interface BannerMessage {
  text: string;
  highlight?: BannerMessageHighlight | null;
}

export interface WorkspaceUsage {
  numProjects: number;
  numUsers: number;
  numPendingInvitations: number;
  numUnapprovedSignups: number;
}

export interface WorkspaceLimits {
  maxUsers?: number | null;
  maxProjects?: number | null;
}

export interface WorkspaceLimitsCheckResult {
  hasRoom: boolean;
}

export interface WorkspaceJoinerEmailSettings {
  toAdmins: boolean;
  toAddresses: NamedEmail[];
}

export interface WorkspaceEmailSettings {
  invitation: InvitationEmailSettings;
  joiner: WorkspaceJoinerEmailSettings;
}

export type KnownWorkspaceFeature =
  | "EnableMobile"
  | "OfflineMaps"
  | "EnableArcgisIntegration"
  | "EnableInvitationCodes"
  | "EnableTeams"
  | "PreferTeams"
  | "LockAlias";

export type WorkspaceFeature =
  | "EnableMobile"
  | "OfflineMaps"
  | "EnableArcgisIntegration"
  | "EnableInvitationCodes"
  | "EnableTeams"
  | "PreferTeams"
  | "LockAlias"
  | string;

export interface Workspace {
  name: string;
  alias: WorkspaceAlias;
  operator?: string | null;
  homepage?: string | null;
  logo?: string | null;
  privacyPolicy?: string | null;
  features: WorkspaceFeature[];
  accessLevel: WorkspaceAccessLevel;
  id: WorkspaceId;
}

export type WorkspaceSearchRoleParam = "all" | "registered" | "active";

export const isBannerMessageHighlight = (
  v: any
): v is BannerMessageHighlight => {
  return (
    v === "default" ||
    v === "info" ||
    v === "success" ||
    v === "warning" ||
    v === "error"
  );
};

export const isBannerMessage = (v: any): v is BannerMessage => {
  return (
    typeof v === "object" &&
    v != null &&
    "text" in v &&
    typeof v.text === "string" &&
    (!("highlight" in v) ||
      isBannerMessageHighlight(v.highlight) ||
      v.highlight === null)
  );
};

export const isWorkspaceUsage = (v: any): v is WorkspaceUsage => {
  return (
    typeof v === "object" &&
    v != null &&
    "numProjects" in v &&
    typeof v.numProjects === "number" &&
    "numUsers" in v &&
    typeof v.numUsers === "number" &&
    "numPendingInvitations" in v &&
    typeof v.numPendingInvitations === "number" &&
    "numUnapprovedSignups" in v &&
    typeof v.numUnapprovedSignups === "number"
  );
};

export const isWorkspaceLimits = (v: any): v is WorkspaceLimits => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("maxUsers" in v) ||
      typeof v.maxUsers === "number" ||
      v.maxUsers === null) &&
    (!("maxProjects" in v) ||
      typeof v.maxProjects === "number" ||
      v.maxProjects === null)
  );
};

export const isWorkspaceLimitsCheckResult = (
  v: any
): v is WorkspaceLimitsCheckResult => {
  return (
    typeof v === "object" &&
    v != null &&
    "hasRoom" in v &&
    typeof v.hasRoom === "boolean"
  );
};

export const isWorkspaceJoinerEmailSettings = (
  v: any
): v is WorkspaceJoinerEmailSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "toAdmins" in v &&
    typeof v.toAdmins === "boolean" &&
    "toAddresses" in v &&
    Array.isArray(v.toAddresses) &&
    v.toAddresses.every((i: any) => isNamedEmail(i))
  );
};

export const isWorkspaceEmailSettings = (
  v: any
): v is WorkspaceEmailSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "invitation" in v &&
    isInvitationEmailSettings(v.invitation) &&
    "joiner" in v &&
    isWorkspaceJoinerEmailSettings(v.joiner)
  );
};

export const isKnownWorkspaceFeature = (v: any): v is KnownWorkspaceFeature => {
  return (
    v === "EnableMobile" ||
    v === "OfflineMaps" ||
    v === "EnableArcgisIntegration" ||
    v === "EnableInvitationCodes" ||
    v === "EnableTeams" ||
    v === "PreferTeams" ||
    v === "LockAlias"
  );
};

export const isWorkspaceFeature = (v: any): v is WorkspaceFeature => {
  return (
    v === "EnableMobile" ||
    v === "OfflineMaps" ||
    v === "EnableArcgisIntegration" ||
    v === "EnableInvitationCodes" ||
    v === "EnableTeams" ||
    v === "PreferTeams" ||
    v === "LockAlias" ||
    typeof v === "string"
  );
};

export const isWorkspace = (v: any): v is Workspace => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isWorkspaceAlias(v.alias) &&
    (!("operator" in v) ||
      typeof v.operator === "string" ||
      v.operator === null) &&
    (!("homepage" in v) ||
      typeof v.homepage === "string" ||
      v.homepage === null) &&
    (!("logo" in v) || typeof v.logo === "string" || v.logo === null) &&
    (!("privacyPolicy" in v) ||
      typeof v.privacyPolicy === "string" ||
      v.privacyPolicy === null) &&
    "features" in v &&
    Array.isArray(v.features) &&
    v.features.every((i: any) => isWorkspaceFeature(i)) &&
    "accessLevel" in v &&
    isWorkspaceAccessLevel(v.accessLevel) &&
    "id" in v &&
    isWorkspaceId(v.id)
  );
};

export const isWorkspaceSearchRoleParam = (
  v: any
): v is WorkspaceSearchRoleParam => {
  return v === "all" || v === "registered" || v === "active";
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
