// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21SlowFlowSite =
  | "SalmonsBrook"
  | "BentleyPriory"
  | "StanmoreCountryPark"
  | "RiseParkStream"
  | "RiverPinn"
  | "Other";

const SalmonsBrook: Thames21SlowFlowSite = "SalmonsBrook";
const BentleyPriory: Thames21SlowFlowSite = "BentleyPriory";
const StanmoreCountryPark: Thames21SlowFlowSite = "StanmoreCountryPark";
const RiseParkStream: Thames21SlowFlowSite = "RiseParkStream";
const RiverPinn: Thames21SlowFlowSite = "RiverPinn";
const Other: Thames21SlowFlowSite = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21SlowFlowSiteEnum = {
  ...createEnum("Thames21SlowFlowSite", [
    [SalmonsBrook, "Enfield / Salmons Brook"],
    [BentleyPriory, "Harrow / Bentley Priory"],
    [StanmoreCountryPark, "Harrow / Stanmore Country Park"],
    [RiseParkStream, "Havering / Rise Park Stream"],
    [RiverPinn, "Hillingdon / River Pinn"],
    [Other, "Other"],
  ]),
  SalmonsBrook,
  BentleyPriory,
  StanmoreCountryPark,
  RiseParkStream,
  RiverPinn,
  Other,
};

export default Thames21SlowFlowSiteEnum;
