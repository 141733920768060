import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  ProjectEmailSettings,
  ProjectId,
  ProjectRef,
  WorkspaceRef,
  isProjectEmailSettings,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";

const basePath = "/project/email-settings/v1";

function endpointUrl(project: ProjectRef, workspace?: WorkspaceRef): UrlParts {
  return { path: `${basePath}/${project}`, query: { workspace } };
}

export function readOption(
  apiParams: ApiParams,
  project: ProjectRef,
  workspace?: WorkspaceRef
): IO<Option<ProjectEmailSettings>> {
  return fetch
    .get({ apiParams, url: endpointUrl(project, workspace) })
    .chain(optionalContentAs("ProjectEmailSettings", isProjectEmailSettings));
}

export function readOrNull(
  apiParams: ApiParams,
  project: ProjectRef,
  workspace?: WorkspaceRef
): IO<ProjectEmailSettings | null> {
  return readOption(apiParams, project, workspace).map(opt => opt.getOrNull());
}

export function save(
  apiParams: ApiParams,
  project: ProjectId,
  settings: ProjectEmailSettings
): IO<void> {
  return fetch
    .put({ apiParams, url: endpointUrl(project), body: settings })
    .void();
}

export function remove(apiParams: ApiParams, project: ProjectId): IO<void> {
  return fetch.remove({ apiParams, url: endpointUrl(project) }).void();
}
