// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesFrontBackCreekMargin = "F" | "B" | "C";

const F: MrsMorphEstuariesFrontBackCreekMargin = "F";
const B: MrsMorphEstuariesFrontBackCreekMargin = "B";
const C: MrsMorphEstuariesFrontBackCreekMargin = "C";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesFrontBackCreekMarginEnum = {
  ...createEnum("MrsMorphEstuariesFrontBackCreekMargin", [
    [F, "Front"],
    [B, "Back"],
    [C, "Creek margin"],
  ]),
  F,
  B,
  C,
};

export default MrsMorphEstuariesFrontBackCreekMarginEnum;
