// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsArtificialBankProfile =
  | "None"
  | "ARD"
  | "AEM"
  | "ATS"
  | "ASE"
  | "APC";

const None: UrsArtificialBankProfile = "None";
const Resectioned: UrsArtificialBankProfile = "ARD";
const Embanked: UrsArtificialBankProfile = "AEM";
const ArtificialTwoStage: UrsArtificialBankProfile = "ATS";
const SetBackEmbankment: UrsArtificialBankProfile = "ASE";
const Poached: UrsArtificialBankProfile = "APC";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsArtificialBankProfileEnum = {
  ...createEnum("UrsArtificialBankProfile", [
    [None, "None (entirely natural)"],
    [Resectioned, "Resectioned / reprofiled"],
    [Embanked, "Embanked"],
    [ArtificialTwoStage, "Artificial two-stage"],
    [SetBackEmbankment, "Set-back embankment"],
    [Poached, "Poached"],
  ]),
  None,
  Resectioned,
  Embanked,
  ArtificialTwoStage,
  SetBackEmbankment,
  Poached,
};

export default UrsArtificialBankProfileEnum;
