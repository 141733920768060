import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isWorkspaceUsage,
  WorkspaceUsage,
  WorkspaceRef,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";

const basePath = "/workspace/usage/v1";

export function readOrFail(
  apiParams: ApiParams,
  ref: WorkspaceRef
): IO<WorkspaceUsage> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(contentAs("WorkspaceUsage", isWorkspaceUsage));
}
