// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPondCountWhyCreated =
  | "F"
  | "G"
  | "S"
  | "WF"
  | "WL"
  | "D"
  | "R"
  | "U"
  | "O";

const Fishing: FhtPondCountWhyCreated = "F";
const GolfHazard: FhtPondCountWhyCreated = "G";
const Shooting: FhtPondCountWhyCreated = "S";
const Wildfowl: FhtPondCountWhyCreated = "WF";
const Wildlife: FhtPondCountWhyCreated = "WL";
const Divided: FhtPondCountWhyCreated = "D";
const Redug: FhtPondCountWhyCreated = "R";
const Unknown: FhtPondCountWhyCreated = "U";
const Other: FhtPondCountWhyCreated = "O";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPondCountWhyCreatedEnum = {
  ...createEnum("FhtPondCountWhyCreated", [
    [Fishing, "Fishing"],
    [GolfHazard, "Golf Hazard"],
    [Shooting, "Shooting"],
    [Wildfowl, "Wildfowl"],
    [Wildlife, "Wildlife"],
    [Divided, "Divided"],
    [Redug, "Redug"],
    [Unknown, "Unknown"],
    [Other, "Other"],
  ]),
  Fishing,
  GolfHazard,
  Shooting,
  Wildfowl,
  Wildlife,
  Divided,
  Redug,
  Unknown,
  Other,
};

export default FhtPondCountWhyCreatedEnum;
