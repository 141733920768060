// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { GlobalRoleName } from "../types.generated";

const MapViewer: GlobalRoleName = "MapViewer";
const Admin: GlobalRoleName = "Admin";
const Superuser: GlobalRoleName = "Superuser";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const GlobalRoleNameEnum = {
  ...createEnum("GlobalRoleName", [
    [MapViewer, "Map Viewer (temporary role used by map embeds)"],
    [Admin, "Global Admin"],
    [Superuser, "Superuser"],
  ]),
  MapViewer,
  Admin,
  Superuser,
};

export default GlobalRoleNameEnum;
