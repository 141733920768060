import {
  attributeGroup,
  enumAttr,
  pointLayer,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { SurveyStatusEnum, unsafeSurveyModuleId } from "@cartographerio/types";
import {
  MrsRiverWoodHydraulicTypeEnum,
  MrsRiverWoodJamTypeEnum,
} from "@cartographerio/inventory-enums";

export const MrsRiverWood = mapSchemaWithDefaults({
  mapId: unsafeMapId("MrsRiverWood"),
  title: "RiverWood",
  layers: [
    pointLayer({
      title: "RiverWood",
      layerId: unsafeMapLayerId("MrsRiverWood"),
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("MrsRiverWood"),
        attribution: [],
      }),
      defaultAttribute: "hydraulicType",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("MrsRiverWood"),
            }),
            surveyAttr("surveyId", "Survey"),
            enumAttr({
              attributeId: "status",
              label: "Status",
              enum: SurveyStatusEnum,
            }),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            stringAttr({
              label: "River",
              attributeId: "river",
              buckets: "auto",
            }),
            stringAttr({
              label: "Reach",
              attributeId: "reach",
              buckets: "auto",
            }),
            stringAttr({
              label: "Jam Number/ID",
              attributeId: "jamNumber",
              buckets: "auto",
            }),
            enumAttr({
              label: "Jam Type",
              attributeId: "jamType",
              enum: MrsRiverWoodJamTypeEnum,
            }),
            enumAttr({
              label: "Hydraulic Type",
              attributeId: "hydraulicType",
              enum: MrsRiverWoodHydraulicTypeEnum,
            }),
          ],
        }),
      ],
    }),
  ],
});
