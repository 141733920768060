import {
  hasKey,
  hasOptionalKey,
  hasTypeTag,
  isArrayOf,
  isNull,
  isNullable,
  isObject,
} from "@cartographerio/guard";
import { GeoJsonProperties, Geometry, isPoint } from "../geometry";
import { Feature, FeatureCollection, Picked } from "./type";
import { isGeometry } from "../geometry";

export function isGeoJsonProperties(
  value: unknown
): value is GeoJsonProperties {
  return isObject(value) || isNull(value);
}

export function isFeatureF<
  G extends Geometry | null = Geometry,
  P = GeoJsonProperties,
>(
  geomGuard: (geom: unknown) => geom is G,
  propsGuard: (props: unknown) => props is P
) {
  return function (value: unknown): value is Feature<G, P> {
    return (
      isObject(value) &&
      hasTypeTag(value, "Feature") &&
      hasKey(value, "geometry", geomGuard) &&
      hasKey(value, "properties", propsGuard)
    );
  };
}

export const isFeature: (v: unknown) => v is Feature = isFeatureF(
  isGeometry,
  isGeoJsonProperties
);

export function isFeatureCollectionF<
  Geom extends Geometry = Geometry,
  Props = Record<string, unknown>,
>(
  geomGuard: (geom: unknown) => geom is Geom,
  propsGuard: (props: unknown) => props is Props
) {
  return function (value: unknown): value is FeatureCollection<Geom, Props> {
    return (
      isObject(value) &&
      hasTypeTag(value, "FeatureCollection") &&
      hasKey(value, "features", isArrayOf(isFeatureF(geomGuard, propsGuard)))
    );
  };
}

export const isFeatureCollection: (v: unknown) => v is FeatureCollection =
  isFeatureCollectionF(isGeometry, isGeoJsonProperties);

export function isPickedF<
  G extends Geometry | null = Geometry,
  P = GeoJsonProperties,
>(geomGuard: (v: unknown) => v is G, propsGuard: (v: unknown) => v is P) {
  return (v: unknown): v is Picked<G, P> => {
    return (
      isObject(v) &&
      hasKey(v, "point", isPoint) &&
      hasOptionalKey(
        v,
        "feature",
        isNullable(isFeatureF(geomGuard, propsGuard))
      )
    );
  };
}

export const isPicked: (v: unknown) => v is Picked = isPickedF(
  isGeometry,
  isGeoJsonProperties
);
