export const baseStyles = {
  terrain: {
    url: "mapbox://styles/cartographerio/ck48momyg4af61cmm8gtced56",
    insertBefore: "waterway-label",
  },
  satellite: {
    url: "mapbox://styles/cartographerio/ck48o09mu06mw1cmycjkzd4e9",
    insertBefore: "waterway-label",
  },
};
