// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeChalkRiversFeature =
  | "IntermittentFlow"
  | "IntermittentFlowNatural"
  | "Upwelling"
  | "UpwellingKnuckerhole"
  | "SpringsInFloodplain"
  | "ActiveTufaFormation"
  | "SeepagesRiparian"
  | "SeepagesFloodplain"
  | "SeepagesSpringline"
  | "EncroachingMarginalVegetation"
  | "GravelBed"
  | "WaterCrowfoot"
  | "PeatRiparian"
  | "PeatFloodplain"
  | "PeatSpringline"
  | "VegetationTallFen"
  | "VegetationReedbed"
  | "VegetationWetWoodland"
  | "VegetationOther"
  | "GhyllStream"
  | "ExposedCoarseSediments";

const IntermittentFlow: NeChalkRiversFeature = "IntermittentFlow";
const IntermittentFlowNatural: NeChalkRiversFeature = "IntermittentFlowNatural";
const Upwelling: NeChalkRiversFeature = "Upwelling";
const UpwellingKnuckerhole: NeChalkRiversFeature = "UpwellingKnuckerhole";
const SpringsInFloodplain: NeChalkRiversFeature = "SpringsInFloodplain";
const ActiveTufaFormation: NeChalkRiversFeature = "ActiveTufaFormation";
const SeepagesRiparian: NeChalkRiversFeature = "SeepagesRiparian";
const SeepagesFloodplain: NeChalkRiversFeature = "SeepagesFloodplain";
const SeepagesSpringline: NeChalkRiversFeature = "SeepagesSpringline";
const EncroachingMarginalVegetation: NeChalkRiversFeature =
  "EncroachingMarginalVegetation";
const GravelBed: NeChalkRiversFeature = "GravelBed";
const WaterCrowfoot: NeChalkRiversFeature = "WaterCrowfoot";
const PeatRiparian: NeChalkRiversFeature = "PeatRiparian";
const PeatFloodplain: NeChalkRiversFeature = "PeatFloodplain";
const PeatSpringline: NeChalkRiversFeature = "PeatSpringline";
const VegetationTallFen: NeChalkRiversFeature = "VegetationTallFen";
const VegetationReedbed: NeChalkRiversFeature = "VegetationReedbed";
const VegetationWetWoodland: NeChalkRiversFeature = "VegetationWetWoodland";
const VegetationOther: NeChalkRiversFeature = "VegetationOther";
const GhyllStream: NeChalkRiversFeature = "GhyllStream";
const ExposedCoarseSediments: NeChalkRiversFeature = "ExposedCoarseSediments";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeChalkRiversFeatureEnum = {
  ...createEnum("NeChalkRiversFeature", [
    [IntermittentFlow, "Intermittent flow"],
    [IntermittentFlowNatural, "Intermittent flow natural"],
    [Upwelling, "Upwelling"],
    [UpwellingKnuckerhole, "Knuckerhole"],
    [SpringsInFloodplain, "Springs in floodplain"],
    [ActiveTufaFormation, "Active tufa formation"],
    [SeepagesRiparian, "Flushes/seepages: Riparian zon"],
    [SeepagesFloodplain, "Flushes/seepages: Floodplain"],
    [SeepagesSpringline, "Flushes/seepages: Valleyside springline"],
    [EncroachingMarginalVegetation, "Encroaching marginal vegetation"],
    [GravelBed, "Gravel bed"],
    [WaterCrowfoot, "Water Crowfoot"],
    [PeatRiparian, "Peat: Riparian zone"],
    [PeatFloodplain, "Peat: Floodplain"],
    [PeatSpringline, "Peat: Valleyside springline"],
    [VegetationTallFen, "Vegetation: Tall fen"],
    [VegetationReedbed, "Vegetation: Reedbed"],
    [VegetationWetWoodland, "Vegetation: Wet woodland"],
    [VegetationOther, "Vegetation: Other"],
    [GhyllStream, "Ghyll stream"],
    [ExposedCoarseSediments, "Exposed coarse sediments"],
  ]),
  IntermittentFlow,
  IntermittentFlowNatural,
  Upwelling,
  UpwellingKnuckerhole,
  SpringsInFloodplain,
  ActiveTufaFormation,
  SeepagesRiparian,
  SeepagesFloodplain,
  SeepagesSpringline,
  EncroachingMarginalVegetation,
  GravelBed,
  WaterCrowfoot,
  PeatRiparian,
  PeatFloodplain,
  PeatSpringline,
  VegetationTallFen,
  VegetationReedbed,
  VegetationWetWoodland,
  VegetationOther,
  GhyllStream,
  ExposedCoarseSediments,
};

export default NeChalkRiversFeatureEnum;
