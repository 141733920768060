import {
  PermissionCheck,
  qualificationCoordinatorRole,
  QualificationId,
  QualificationRegisterEntry,
  QualificationRoleName,
  qualificationTrainerRole,
  UserId,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { check } from "../check";

export function grant(
  name: QualificationRoleName,
  qualificationId: QualificationId
): PermissionCheck {
  switch (name) {
    case "Expired":
    case "Trainee":
    case "Qualified":
      return check.or(
        check.globalAdmin,
        check.hasQualification(qualificationTrainerRole(qualificationId))
      );
    case "Trainer":
    case "Coordinator":
      return check.or(
        check.globalAdmin,
        check.hasQualification(qualificationCoordinatorRole(qualificationId))
      );
    default:
      return checkExhausted(name);
  }
}

export function viewRegisterSettings(
  userId: UserId,
  _qualificationId: QualificationId
): PermissionCheck {
  return check.named(
    `View register settings of user ${userId}`,
    check.or(check.globalAdmin, check.hasUserId(userId))
  );
}

export function editRegisterSettings(
  userId: UserId,
  _qualificationId: QualificationId
): PermissionCheck {
  return check.named(
    `Edit register settings of user ${userId}`,
    check.or(check.globalAdmin, check.hasUserId(userId))
  );
}

export function canViewFullQualificationCertificate(
  entry: QualificationRegisterEntry
): PermissionCheck {
  return check.or(
    check.hasQualification(qualificationCoordinatorRole(entry.qualificationId)),
    entry.userId == null
      ? check.never("No user ID")
      : check.hasUserId(entry.userId)
  );
}
