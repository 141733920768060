// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPondCountWhyNew = "C" | "M";

const NewlyCreated: FhtPondCountWhyNew = "C";
const PreviouslyMissed: FhtPondCountWhyNew = "M";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPondCountWhyNewEnum = {
  ...createEnum("FhtPondCountWhyNew", [
    [NewlyCreated, "Newly created"],
    [PreviouslyMissed, "Previously missed"],
  ]),
  NewlyCreated,
  PreviouslyMissed,
};

export default FhtPondCountWhyNewEnum;
