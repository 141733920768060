import { unsafeMapId } from "@cartographerio/topo-map";
import { mapSchemaWithDefaults } from "../../core";
import { MrsMorphPro } from "./MrsMorph";
import { MrsMorph5Pro } from "./MrsMorph5";
import { MrsRiverConditionPro } from "./MrsRiverCondition";
import { MrsRtaPro } from "./MrsRta";

export const MrsRcaPro = mapSchemaWithDefaults({
  mapId: unsafeMapId("mrsRcaPro"),
  title: "River Condition Assessment",
  layers: [
    ...MrsMorphPro.layers,
    ...MrsMorph5Pro.layers,
    ...MrsRtaPro.layers,
    ...MrsRiverConditionPro.layers,
  ],
});
