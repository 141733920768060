import {
  OtherSpecifyOptions,
  topoExpr,
  attachmentField,
  bounds,
  checkbox,
  columns2,
  customRule,
  enumSelectOptions,
  form,
  minValue,
  multiSelect,
  numberField,
  otherSpecify,
  page,
  pointField,
  required,
  requiredIfV2,
  section,
  select,
  text,
  textArea,
  textField,
  timestampField,
  visibility,
  vstack,
} from "@cartographerio/topo-form";
import { isArray, isNullable, isNumber } from "@cartographerio/guard";
import {
  ArrtWaterQualityBankVegetationEnum,
  ArrtWaterQualityChannelSubstrateEnum,
  ArrtWaterQualityFlowConditionsEnum,
  ArrtWaterQualityFlowObstacleEnum,
  ArrtWaterQualityInvasivePlantEnum,
  ArrtWaterQualityLandUseEnum,
  ArrtWaterQualityNitrateEnum,
  ArrtWaterQualityPollutionEvidenceEnum,
  ArrtWaterQualityPollutionSourceEnum,
  ArrtWaterQualityRainfallEnum,
  ArrtWaterQualityWaterLevelEnum,
  ArrtWaterQualityWaterbodyTypeEnum,
  ArrtWaterQualityWildlifeEnum,
  ArrtWaterqualityChannelVegetationEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
import { appendUnit } from "../WrtWestcountryCsi/form";

const sampleTakenChecked = topoExpr<boolean>(env => {
  return env.getAbsolute(["data", "riverChannel", "sampleTaken"]) === true;
});

export default form({
  title: "ARRT Water Quality",
  surveyorHelp: "Observer name",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: "Survey Details",
          path: ["data"],
          blocks: [
            columns2(
              vstack(
                textField({
                  label: "River/Stream Name",
                  path: ["details", "river"],
                  required: required("error"),
                }),
                textField({
                  label: "Location Name",
                  path: ["details", "site"],
                  required: required("error"),
                }),
                timestampField({
                  label: "Date and Time",
                  path: ["recorded"],
                  defaultValue: "now",
                  required: required("error"),
                  help: "When was the data collected in the field?",
                })
              ),
              pointField({
                label: "Location",
                path: ["details", "location"],
                required: required("error"),
                help: "Location extent of the survey.",
                selectMinZoom: 12,
              }),
              vstack(
                select({
                  label: "Type of Waterbody (select one)",
                  path: ["details", "waterbodyType", "selected"],
                  options: enumSelectOptions(ArrtWaterQualityWaterbodyTypeEnum),
                  required: required("error"),
                }),
                otherSpecify({
                  label: "Other Waterbody Type",
                  basePath: ["details", "waterbodyType"],
                  level: "error",
                  test: value =>
                    value === ArrtWaterQualityWaterbodyTypeEnum.Other,
                  visible: "auto",
                  help: "If you have selected 'other', please describe.",
                }),
                select({
                  label: "Rain in Previous 24 Hours (select one)",
                  path: ["details", "recentRain"],
                  options: enumSelectOptions(ArrtWaterQualityRainfallEnum),
                  required: required("error"),
                })
              )
            ),
          ],
        }),
        section({
          title: "Photographs",
          path: ["data"],
          help: outdent`
          📸 Please take up to four photos to go with your survey data. One photo
          should be taken from the same place on each visit and will capture some
          part of the waterbody and surroundings. Other features where photos would
          be useful are highlighted with a camera icon.
          `,
          blocks: [
            attachmentField({
              label: null,
              path: ["details", "photographs"],
              fullWidth: true,
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "General Ecosystem Observations",
          path: ["data", "ecosystem"],
          blocks: [
            multiSelect({
              label: "Dominant Land Use within ~50m (tick all that apply)",
              path: ["landUse", "selected"],
              options: enumSelectOptions(ArrtWaterQualityLandUseEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Dominant Land Use",
              basePath: ["landUse"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(ArrtWaterQualityLandUseEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label: "Dominant Bankside Vegetation (tick all that apply) 📸",
              path: ["bankVegetation", "selected"],
              options: enumSelectOptions(ArrtWaterQualityBankVegetationEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Dominant Bankside Vegetation",
              basePath: ["bankVegetation"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(ArrtWaterQualityBankVegetationEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label: "Wildlife Spotted (tick all that apply)",
              path: ["wildlife", "selected"],
              options: enumSelectOptions(ArrtWaterQualityWildlifeEnum),
              appearance: "checkboxgroup",
              columns: 3,
              noneOption: ArrtWaterQualityWildlifeEnum.None,
            }),
            narrowOtherSpecify({
              label: "Other Wildlife Spotted",
              basePath: ["wildlife"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(ArrtWaterQualityWildlifeEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label: "Problem Plant Species (tick all that apply) 📸",
              path: ["invasivePlants", "selected"],
              options: enumSelectOptions(ArrtWaterQualityInvasivePlantEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Problem Plant Species",
              basePath: ["invasivePlants"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(ArrtWaterQualityInvasivePlantEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
          ],
        }),
        section({
          title: "Evidence of Pollution",
          path: ["data", "pollution"],
          help: outdent`
          If you see any potential pollution incidents,
          call the Environment Agency 24-hour incident hotline 0800 807060.
          Indicators include: grey water discharge, oil, chemical or sewage odour,
          dead fish, or fish gasping for air.
          `,
          blocks: [
            multiSelect({
              label: "Pollution Sources (tick all that apply) 📸",
              path: ["pollutionSources", "selected"],
              options: enumSelectOptions(ArrtWaterQualityPollutionSourceEnum),
              appearance: "checkboxgroup",
              columns: 3,
              noneOption: ArrtWaterQualityPollutionSourceEnum.None,
            }),
            narrowOtherSpecify({
              label: "Other Pollution Sources",
              basePath: ["pollutionSources"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(ArrtWaterQualityPollutionSourceEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            multiSelect({
              label: "Evidence of Recent Pollution (tick all that apply) 📸",
              path: ["pollutionEvidence", "selected"],
              options: enumSelectOptions(ArrtWaterQualityPollutionEvidenceEnum),
              appearance: "checkboxgroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Evidence of Recent Pollution",
              basePath: ["pollutionEvidence"],
              level: "error",
              test: value =>
                isArray(value) &&
                value.includes(ArrtWaterQualityPollutionEvidenceEnum.Other),
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
          ],
        }),
        section({
          title: "River Channel Observations",
          path: ["data", "riverChannel"],
          blocks: [
            columns2(
              vstack(
                numberField({
                  label: "Estimated Width at Water Level",
                  path: ["estimatedWidth"],
                  required: required("error"),
                  decimalPlaces: 2,
                  units: "m",
                }),
                numberField({
                  label: "Estimated Average Depth",
                  path: ["estimatedDepth"],
                  required: required("error"),
                  decimalPlaces: 2,
                  units: "m",
                }),
                select({
                  label: "Water Level",
                  path: ["waterLevel"],
                  options: enumSelectOptions(ArrtWaterQualityWaterLevelEnum),
                  required: required("error"),
                  help: outdent`
                  You can find clues to previous water levels
                  by looking for "trash" lines on the bank.
                  `,
                })
              )
            ),
            select({
              label: "Predominant Substrate 📸",
              path: ["dominantSubstrate", "selected"],
              options: enumSelectOptions(ArrtWaterQualityChannelSubstrateEnum),
              appearance: "radiogroup",
              columns: 3,
              help: outdent`
                  Size guide:

                  - boulders - >25cm, football size
                  - stones - 6cm-25cm, tennis ball size
                  - gravel - 4mm-6cm
                  - sand - up to 2mm
                  - silt/mud - identify by eye, looks like mod not sand
                  `,
            }),
            narrowOtherSpecify({
              label: "Other Predominant Substrate",
              basePath: ["dominantSubstrate"],
              level: "error",
              test: value =>
                value === ArrtWaterQualityChannelSubstrateEnum.Other,
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            select({
              label: "Channel Vegetation (tick all that apply) 📸",
              path: ["channelVegetation", "selected"],
              options: enumSelectOptions(ArrtWaterqualityChannelVegetationEnum),
              appearance: "radiogroup",
              columns: 3,
            }),
            narrowOtherSpecify({
              label: "Other Channel Vegetation",
              basePath: ["channelVegetation"],
              level: "error",
              test: value =>
                value === ArrtWaterqualityChannelVegetationEnum.Other,
              visible: "auto",
              help: "If you have selected 'other', please describe.",
            }),
            columns2(
              vstack(
                select({
                  label: "Flow Conditions",
                  path: ["flowConditions"],
                  options: enumSelectOptions(
                    ArrtWaterQualityFlowConditionsEnum
                  ),
                  required: required("error"),
                  help: outdent`
                  Where "steady" is walking speed, "surging" is faster,
                  and "slow" is slower than walking speed.
                  `,
                })
              )
            ),
            multiSelect({
              label: "Obstacles to Fish or Flow (tick all that apply)",
              path: ["obstacles"],
              options: enumSelectOptions(ArrtWaterQualityFlowObstacleEnum),
              help: outdent`
              Water and fish need to move freely.
              Is there an obstruction in the channel (within 50m)
              which might stop or inhibit natural flow or fish movement?
              `,
              appearance: "checkboxgroup",
              columns: 3,
              noneOption: ArrtWaterQualityFlowObstacleEnum.None,
            }),
          ],
        }),
        section({
          title: "Water Quality Measurements",
          path: ["data", "riverChannel"],
          blocks: [
            checkbox({
              label: "Sample Taken?",
              path: ["sampleTaken"],
              checkboxLabel: "Did you test the water quality?",
              defaultValue: true,
            }),
            text({
              appearance: "help",
              visible: sampleTakenChecked,
              markdown: outdent`
              Enter your measurements below.
              If you are unable to take any measurement,
              e.g. due to poor conditions or faulty kit,
              please leave the relevant field blank:
              `,
            }),
            visibility({
              visible: sampleTakenChecked,
              block: columns2(
                vstack(
                  numberField({
                    label: "Temperature",
                    path: ["temperature"],
                    units: "°C",
                    decimalPlaces: 1,
                    bounds: bounds(0, 100),
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                    ],
                  }),
                  numberField({
                    label: "Dissolved Solids (TDS)",
                    path: ["dissolvedSolids"],
                    units: "ppm",
                    help: "Take reading from HM Digital Sensor when reading has stabilised.",
                    decimalPlaces: 1,
                    bounds: minValue(0),
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                      customRule({
                        level: "error",
                        triggerWhen: topoExpr(env => {
                          const value = env
                            .getFocusedAs(isNullable(isNumber))
                            .getOrElse(() => null);

                          return value != null && value % 1 !== 0;
                        }),
                        message:
                          "Make sure you are using the correct units - TDS is a whole number.",
                      }),
                    ],
                  }),
                  numberField({
                    label: "Phosphate (PO4)",
                    path: ["phosphate"],
                    units: "ppm",
                    decimalPlaces: 2,
                    help: "Use Hanna Checker and reagent to measure phosphate reading.",
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                    ],
                  }),
                  numberField({
                    label: "Turbidity",
                    path: ["turbidity"],
                    units: "JTU",
                    defaultValue: null,
                    help: "Enter a turbidity reading in the range 5 to 500.",
                    required: required("info"),
                    bounds: bounds(5, 500),
                  }),
                  select({
                    label: "Nitrate (NO3)",
                    path: ["nitrate"],
                    options: enumSelectOptions(ArrtWaterQualityNitrateEnum).map(
                      appendUnit("ppm")
                    ),
                    help: outdent`
                    Immerse strip for **1 second**.
                    Remove with pad face up for **30 seconds** and compare to colour chart.
                    **DO NOT SHAKE OFF EXCESS WATER.**
                    `,
                    customRules: [
                      requiredIfV2({
                        level: "info",
                        message:
                          "Enter a value if you were able to take this measurement.",
                        otherTest: sampleTakenChecked,
                      }),
                    ],
                  })
                )
              ),
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 5,
            }),
          ],
        }),
      ],
    }),
  ],
});

export function narrowOtherSpecify(options: OtherSpecifyOptions) {
  return columns2(otherSpecify(options));
}
