import {
  PartialParams,
  SearchResultsFormat,
  TeamMemberSearchOptions,
  endpoints,
} from "@cartographerio/client";
import { Result } from "@cartographerio/fp";
import { isNonNullable } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import {
  CurrentMember,
  Message,
  PendingMember,
  QualificationId,
  TeamRoleNameEnum,
  User,
  addRole,
  qualificationRole,
  roleQualificationId,
  roleTeamId,
} from "@cartographerio/types";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { omit } from "lodash";
import { ReactElement, useCallback, useMemo } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import { useIOConfirm, useIOErrorAlert } from "../../../components/Alert";
import ButtonMenu from "../../../components/ButtonMenu";
import Container from "../../../components/Container";
import InvitationModal from "../../../components/InvitationModal";
import MemberList, {
  useApproveCallback,
  useRejectCallback,
  useRemoveMemberRolesCallback,
} from "../../../components/MemberList";
import {
  OnMemberQualificationChange,
  OnMemberRoleChange,
} from "../../../components/MemberList/column";
import MemberListToolbar from "../../../components/MemberListToolbar";
import PageTopBar from "../../../components/PageTopBar";
import ResetFiltersAlert from "../../../components/ResetFiltersAlert";
import Select from "../../../components/Select";
import Spaced from "../../../components/Spaced";
import UserAddModal from "../../../components/UserAddModal";
import { useApiParams } from "../../../contexts/auth";
import { useApiUrlFormatter } from "../../../hooks/useApiUrl";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { usePermissionCheckPasses } from "../../../hooks/usePermissionCheckPasses";
import usePermissionCheckRunner from "../../../hooks/usePermissionCheckRunner";
import useRedirectWhen from "../../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { useTeamsEnabled } from "../../../hooks/useTeamsEnabled";
import useUserLimitReason from "../../../hooks/useUserLimitReason";
import { routes } from "../../../routes";
import TeamPageHeader from "../TeamPageHeader";
import { teamMemberListColumns } from "./columns";

export default function TeamMemberListPage(
  props: RouteProps<typeof routes.workspace.team.member.list>
): ReactElement {
  const {
    path: { workspaceRef, teamRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const confirmAlert = useIOConfirm();
  const errorAlert = useIOErrorAlert();

  const team = useSuspenseQueryData(
    queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, team.workspaceId)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const teamProjects = useSuspenseSearchResults(
    queries.project.v2.search(apiParams, {
      workspace: team.workspaceId,
      team: team.id,
    })
  );

  const qualificationIds = useMemo(
    () =>
      teamProjects.reduce(
        (acc, project) => new Set(project.qualificationIds.concat([...acc])),
        new Set<QualificationId>()
      ),
    [teamProjects]
  );
  const teamQualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams),
    qualifications =>
      qualifications.filter(({ id }) => qualificationIds.has(id))
  );

  const userLimitReason = useUserLimitReason(workspace);

  const { page, count, order, role, q: searchTerm, type: memberType } = query;

  useRequirePermissionRedirect(checks.team.viewMembers(team), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const permissionCheckPasses = usePermissionCheckRunner();

  usePageTitle(`Members - ${team.name} - ${workspace.name}`);

  const handleRoleChange = useCallback<OnMemberRoleChange>(
    (member, role) =>
      confirmAlert({
        title: "Role change confirmation",
        message: (
          <>
            This will change the member&apos;s level of access across all teams
            within the project. Are you sure you want to continue?
          </>
        ),
      })
        .flatMap(confirmed =>
          confirmed
            ? queries.user.v2
                .save(queryClient, apiParams, member.userId, {
                  ...member,
                  roles: addRole(member.roles, role, "replace"),
                })
                .tapError(errorAlert)
            : IO.fail("Cancelled")
        )
        .tap(() =>
          toast({ status: "success", title: "Role updated successfully" })
        )
        .recover(() =>
          toast({ status: "error", description: "Role change cancelled" })
        )
        .unsafeRun(),
    [apiParams, confirmAlert, errorAlert, queryClient, toast]
  );

  const handleQualificationChange = useCallback<OnMemberQualificationChange>(
    (member, qualificationId, roleName) =>
      confirmAlert({
        title: "Role change confirmation",
        message: (
          <>
            This will change the user&apos;s level of access across all projects
            that include this qualification. Are you sure you want to continue?
          </>
        ),
      })
        .flatMap(confirmed =>
          confirmed
            ? queries.user.v2
                .save(queryClient, apiParams, member.userId, {
                  ...member,
                  qualificationRoles: [
                    ...member.qualificationRoles.filter(
                      role => roleQualificationId(role) !== qualificationId
                    ),
                    ...(roleName != null
                      ? [qualificationRole(roleName, qualificationId)]
                      : []),
                  ],
                })
                .tapError(errorAlert)
            : IO.fail<Result<Message[], User>>("Cancelled")
        )
        .tap(() =>
          toast({ status: "success", title: "Role updated successfully" })
        )
        .recover(() =>
          toast({ status: "error", description: "Role change cancelled" })
        )
        .unsafeRun(),
    [apiParams, confirmAlert, errorAlert, queryClient, toast]
  );

  const canRemove = useCallback(
    (_user: CurrentMember | PendingMember) =>
      permissionCheckPasses(checks.user.removeFromTeam(team)),
    [permissionCheckPasses, team]
  );

  const handleRemove = useRemoveMemberRolesCallback(
    "Remove this person from the team? They will still be a member of the workspace.",
    "Person removed from team",
    useCallback(
      roles => roles.filter(role => roleTeamId(role) !== team.id),
      [team.id]
    )
  );

  const handleApprove = useApproveCallback();
  const handleReject = useRejectCallback();

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const {
    isOpen: isInviteOpen,
    onOpen: onInviteOpen,
    onClose: onInviteClose,
  } = useDisclosure();

  const canAddFromWorkspace = usePermissionCheckPasses(
    checks.workspace.admin(workspace.id)
  );
  const canInvite = usePermissionCheckPasses(checks.team.grantAccess(team));

  const canViewMember = canAddFromWorkspace;

  const columns = useMemo(
    () =>
      teamMemberListColumns({
        team,
        qualifications: teamQualifications,
        permissionCheckPasses,
        onRoleChange: handleRoleChange,
        onQualificationChange: handleQualificationChange,
      }),
    [
      handleQualificationChange,
      handleRoleChange,
      permissionCheckPasses,
      team,
      teamQualifications,
    ]
  );

  const searchOpts = useMemo(
    (): PartialParams<TeamMemberSearchOptions> => ({
      ...query,
      memberType,
      skip: query.page * query.count,
      limit: query.count,
    }),
    [memberType, query]
  );

  const { data, error } = useQuery(
    queries.member.v1.teamSearch(
      apiParams,
      team.alias,
      team.workspaceId,
      searchOpts
    )
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.member.v1.teamSearchUrl(team.id, null, {
          ...omit(searchOpts, "skip", "limit"),
          format,
        })
      ),
    [formatApiUrl, searchOpts, team.id]
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="teams"
        team={team}
        teamPage="members"
      />
      <TeamPageHeader workspace={workspace} team={team} selected="members" />
      <Container width="wide">
        <Spaced>
          <MemberListToolbar
            searchTerm={searchTerm}
            workspace={workspace}
            memberType={memberType}
            roleSelect={
              <Select.Nullable
                value={role}
                options={TeamRoleNameEnum.entries}
                onChange={role => updateQuery({ ...query, role, page: 0 })}
                placeholder="All Roles"
                background="transparent"
                w="fit-content"
                maxW="52"
              />
            }
            onSearchTermChange={q =>
              updateQuery({ ...query, q: q ?? undefined, page: 0 })
            }
            onMemberTypeChange={type => updateQuery({ ...query, type })}
            downloadUrl={downloadUrl}
            addButton={
              <ButtonMenu
                label="Add"
                colorScheme="blue"
                options={[
                  canAddFromWorkspace
                    ? { label: "Add from Workspace", onClick: onAddOpen }
                    : null,
                  canInvite
                    ? {
                        label: "Invite by Email",
                        onClick: onInviteOpen,
                        disabledReason: userLimitReason,
                      }
                    : null,
                ].filter(isNonNullable)}
              />
            }
          />
          <ResetFiltersAlert
            route={routes.workspace.team.member.list}
            query={query}
            updateQuery={updateQuery}
          />
          <MemberList
            data={data ?? null}
            error={error}
            page={page}
            count={count}
            order={order}
            workspace={workspace}
            columns={columns}
            changeIdentityRedirect={routes.workspace.home.url([
              workspace.alias,
            ])}
            canRemove={canRemove}
            onRemove={handleRemove}
            onApprove={handleApprove}
            onReject={handleReject}
            itemLink={
              !canViewMember
                ? undefined
                : member =>
                    member.type === "InvitedMember"
                      ? routes.workspace.team.invitation.view.url([
                          workspace.alias,
                          team.alias,
                          member.invitationId,
                        ])
                      : routes.workspace.team.member.update.url([
                          workspace.alias,
                          team.alias,
                          member.userId,
                        ])
            }
            onPageChange={page => updateQuery({ ...query, page })}
            onOrderChange={order => updateQuery({ ...query, order })}
          />
        </Spaced>

        {canAddFromWorkspace && (
          <UserAddModal.Team
            title={`Add to ${team.name}`}
            isOpen={isAddOpen}
            onClose={onAddClose}
            team={team}
            teamProjects={teamProjects}
          />
        )}

        {canInvite && (
          <InvitationModal.Team
            title={`Invite to ${team.name}`}
            isOpen={isInviteOpen}
            onClose={onInviteClose}
            team={team}
            teamProjects={teamProjects}
          />
        )}
      </Container>
    </>
  );
}
