// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankProblemPlantSpecies =
  | "HimalayanBalsam"
  | "JapaneseKnotweed"
  | "GiantHogweed"
  | "HemlockWaterDropwort"
  | "Other";

const HimalayanBalsam: NwcSeedbankProblemPlantSpecies = "HimalayanBalsam";
const JapaneseKnotweed: NwcSeedbankProblemPlantSpecies = "JapaneseKnotweed";
const GiantHogweed: NwcSeedbankProblemPlantSpecies = "GiantHogweed";
const HemlockWaterDropwort: NwcSeedbankProblemPlantSpecies =
  "HemlockWaterDropwort";
const Other: NwcSeedbankProblemPlantSpecies = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankProblemPlantSpeciesEnum = {
  ...createEnum("NwcSeedbankProblemPlantSpecies", [
    [HimalayanBalsam, "Himalayan Balsam"],
    [JapaneseKnotweed, "Japanese Knotweed"],
    [GiantHogweed, "Giant Hogweed"],
    [HemlockWaterDropwort, "Hemlock Water Dropwort"],
    [Other, "Other"],
  ]),
  HimalayanBalsam,
  JapaneseKnotweed,
  GiantHogweed,
  HemlockWaterDropwort,
  Other,
};

export default NwcSeedbankProblemPlantSpeciesEnum;
