// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessHigherPlantSpecies = "CommonReed" | "PeatMoss";

const CommonReed: NeLakeNaturalnessHigherPlantSpecies = "CommonReed";
const PeatMoss: NeLakeNaturalnessHigherPlantSpecies = "PeatMoss";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessHigherPlantSpeciesEnum = {
  ...createEnum("NeLakeNaturalnessHigherPlantSpecies", [
    [CommonReed, "Common reed (Phragmites australis)"],
    [PeatMoss, "Peat moss (Sphagnum sp)"],
  ]),
  CommonReed,
  PeatMoss,
};

export default NeLakeNaturalnessHigherPlantSpeciesEnum;
