// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsChannelDynamicsCategory =
  | "oneBankEroding"
  | "oppositeBanksEroding"
  | "oppositeBanksDepositing"
  | "berm";

const OneBankEroding: UrsChannelDynamicsCategory = "oneBankEroding";
const OppositeBanksEroding: UrsChannelDynamicsCategory = "oppositeBanksEroding";
const OppositeBanksDepositing: UrsChannelDynamicsCategory =
  "oppositeBanksDepositing";
const Berm: UrsChannelDynamicsCategory = "berm";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsChannelDynamicsCategoryEnum = {
  ...createEnum("UrsChannelDynamicsCategory", [
    [OneBankEroding, "One bank eroding (vertical/vertical+toe/undercut)"],
    [
      OppositeBanksEroding,
      "Opposite banks eroding (vertical/vertical+toe/undercut)",
    ],
    [
      OppositeBanksDepositing,
      "Opposite banks depositing (vegetated bars/berms)",
    ],
    [Berm, "One or more banks with berm >25% width"],
  ]),
  OneBankEroding,
  OppositeBanksEroding,
  OppositeBanksDepositing,
  Berm,
};

export default UrsChannelDynamicsCategoryEnum;
