import { Env, Message } from "@cartographerio/topo-core";
import {
  topoExpr,
  checkbox,
  columns2,
  dynamicValue,
  form,
  Form,
  grid,
  maxValue,
  numberField,
  page,
  pointField,
  required,
  rule,
  section,
  select,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { Option, Result } from "@cartographerio/fp";

import {
  GuardError,
  isBoolean,
  isNullable,
  isNumber,
} from "@cartographerio/guard";
import {
  MrsRtaAverageBedMaterialEnum,
  MrsRtaCoarsestBedMaterialEnum,
  MrsRtaLevelOfConfinementEnum,
  MrsRtaRiverCategoryEnum,
  MrsRtaRiverType,
  MrsRtaRiverTypeEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

function calcA2(env: Env): Result<Message, number | null> {
  const riverLength = env
    .getAs(["data", "reachProperties", "reachRiverLength"], isNumber)
    .toOption();

  const valleyLength = env
    .getAs(["data", "reachProperties", "reachValleyLength"], isNumber)
    .toOption();

  const a2 = Option.when(
    riverLength,
    valleyLength
  )((riverLength, valleyLength) => riverLength / valleyLength);

  return Result.pure(a2.getOrNull());
}

function calcA5(env: Env): Result<Message, number | null> {
  const upstreamElevation = env
    .getAs(["data", "reachProperties", "upstreamElevation"], isNumber)
    .toOption();

  const downstreamElevation = env
    .getAs(["data", "reachProperties", "downstreamElevation"], isNumber)
    .toOption();

  const valleyLength = env
    .getAs(["data", "reachProperties", "reachValleyLength"], isNumber)
    .toOption();

  const a5 = Option.when(
    upstreamElevation,
    downstreamElevation,
    valleyLength
  )((a, b, c) => Math.abs(a - b) / (c * 1000));

  return Result.pure(a5.getOrNull());
}

function riverType(env: Env): Result<GuardError, MrsRtaRiverType | null> {
  const riverCategory = env
    .getAs(
      ["data", "reachProperties", "riverCategory"],
      MrsRtaRiverCategoryEnum.isValue
    )
    .toOption();

  const a1 = env
    .getAs(["data", "reachProperties", "braidingIndex"], isNumber)
    .toOption();

  const a2 = calcA2(env).guard(isNumber).toOption();

  const a3 = env
    .getAs(["data", "reachProperties", "anabranchingIndex"], isNumber)
    .toOption();

  // const a4 = env
  //   .getAs(MrsRtaLevelOfConfinementEnum.isValue, [
  //     "data",
  //     "reachProperties",
  //     "levelOfConfinement",
  //   ])
  //   .toOption();

  const a5 = calcA5(env).guard(isNumber).toOption();

  const a6 = env
    .getAs(["data", "reachProperties", "bedrockReach"], isBoolean)
    .toOption();

  const a7 = env
    .getAs(
      ["data", "reachProperties", "coarsestBedMaterial"],
      MrsRtaCoarsestBedMaterialEnum.isValue
    )
    .toOption();

  const a8 = env
    .getAs(
      ["data", "reachProperties", "averageBedMaterial"],
      MrsRtaAverageBedMaterialEnum.isValue
    )
    .toOption();

  const intermetiate1 = (orElseFunc: () => Option<MrsRtaRiverType>) =>
    a5.flatMap(a5 =>
      a5 > 0.01 ? Option.some(MrsRtaRiverTypeEnum.D) : orElseFunc()
    );

  const intermediate2 = () =>
    a1.flatMap(a1 =>
      a1 < 1.1
        ? a2.map(a2 =>
            a2 < 1.5 ? MrsRtaRiverTypeEnum.F : MrsRtaRiverTypeEnum.G
          )
        : Option.some(MrsRtaRiverTypeEnum.E)
    );

  const intermediate3 = () =>
    a3.flatMap(a3 =>
      a3 < 1.5
        ? a2.map(a2 =>
            a2 < 1.5 ? MrsRtaRiverTypeEnum.H : MrsRtaRiverTypeEnum.I
          )
        : Option.some(MrsRtaRiverTypeEnum.J)
    );

  const intermediate4 = () =>
    a3.flatMap(a3 =>
      a3 < 1.5
        ? a2.map(a2 =>
            a2 < 1.5 ? MrsRtaRiverTypeEnum.K : MrsRtaRiverTypeEnum.L
          )
        : Option.some(MrsRtaRiverTypeEnum.M)
    );

  const riverType = riverCategory.flatMap(riverCategory => {
    switch (riverCategory) {
      case MrsRtaRiverCategoryEnum.Navigable:
        return Option.some(MrsRtaRiverTypeEnum.Navigable);
      case MrsRtaRiverCategoryEnum.Large:
        return Option.some(MrsRtaRiverTypeEnum.Large);
      default:
        return a6.flatMap(a6 => {
          if (a6) {
            return Option.some(MrsRtaRiverTypeEnum.A);
          } else {
            return a7.flatMap(a7 => {
              switch (a7) {
                case MrsRtaCoarsestBedMaterialEnum.Bedrock:
                case MrsRtaCoarsestBedMaterialEnum.Boulder:
                  return a8.flatMap(a8 => {
                    switch (a8) {
                      case MrsRtaAverageBedMaterialEnum.Boulder:
                        return Option.some(MrsRtaRiverTypeEnum.B);
                      case MrsRtaAverageBedMaterialEnum.Cobble:
                        return Option.some(MrsRtaRiverTypeEnum.C);
                      case MrsRtaAverageBedMaterialEnum.GravelPebble:
                        return intermetiate1(intermediate2);
                      case MrsRtaAverageBedMaterialEnum.Sand:
                        return intermetiate1(intermediate3);
                      default:
                        return Option.some(null);
                    }
                  });
                case MrsRtaCoarsestBedMaterialEnum.Cobble:
                  return a8.flatMap(a8 => {
                    switch (a8) {
                      case MrsRtaAverageBedMaterialEnum.GravelPebble:
                      case MrsRtaAverageBedMaterialEnum.Cobble:
                        return intermediate2();
                      case MrsRtaAverageBedMaterialEnum.Sand:
                        return intermediate3();
                      default:
                        return Option.some(null);
                    }
                  });
                case MrsRtaCoarsestBedMaterialEnum.GravelPebble:
                  return a8.flatMap(a8 => {
                    switch (a8) {
                      case MrsRtaAverageBedMaterialEnum.GravelPebble:
                      case MrsRtaAverageBedMaterialEnum.Cobble:
                        return intermediate2();
                      case MrsRtaAverageBedMaterialEnum.Sand:
                        return intermediate3();
                      case MrsRtaAverageBedMaterialEnum.Silt:
                      case MrsRtaAverageBedMaterialEnum.Clay:
                        return intermediate4();
                      default:
                        return Option.some(null);
                    }
                  });
                case MrsRtaCoarsestBedMaterialEnum.Sand:
                  return a8.flatMap(a8 => {
                    switch (a8) {
                      case MrsRtaAverageBedMaterialEnum.Sand:
                        return intermediate3();
                      case MrsRtaAverageBedMaterialEnum.Silt:
                      case MrsRtaAverageBedMaterialEnum.Clay:
                        return intermediate4();
                      default:
                        return Option.some(null);
                    }
                  });
                case MrsRtaCoarsestBedMaterialEnum.Silt:
                case MrsRtaCoarsestBedMaterialEnum.Clay:
                  return a8.flatMap(a8 => {
                    switch (a8) {
                      case MrsRtaAverageBedMaterialEnum.Silt:
                      case MrsRtaAverageBedMaterialEnum.Clay:
                        return intermediate4();
                      default:
                        return Option.some(null);
                    }
                  });
              }
            });
          }
        });
    }
  });

  return Result.pure(riverType.getOrNull());
}

function finalRiverType(env: Env): Result<GuardError, MrsRtaRiverType | null> {
  return env
    .getAs(
      ["data", "reachProperties", "riverTypeOverride"],
      isNullable(MrsRtaRiverTypeEnum.isValue)
    )
    .flatMap(override =>
      override != null ? Result.pure(override) : riverType(env)
    );
}

export default function mrsRtaForm(title: string): Form {
  return form({
    title,
    pages: [
      page({
        title: null,
        path: [],
        blocks: [
          section({
            title: null,
            path: [],
            blocks: [
              grid({
                columns: 2,
                blocks: [
                  textField({
                    label: "Project Name",
                    path: ["data", "surveyDetails", "projectName"],
                    required: required(
                      "info",
                      "You should enter a project name."
                    ),
                    help: outdent`Name of the River/Stream Restoration project being monitored (if applicable).`,
                  }),
                  textField({
                    label: "MoRPh Correlation Code",
                    path: ["data", "surveyDetails", "projectCode"],
                    required: required(
                      "info",
                      "You should enter a correlation code."
                    ),
                    help: outdent`Code used to group River Type surveys with MoRPh5 outputs when calculating River Condition outputs. Make sure the values match exactly!`,
                  }),
                  timestampField({
                    label: "Reference Date and Time",
                    path: ["data", "surveyDetails", "referenceDate"],
                    required: required("info", "You should enter a date/time."),
                  }),
                ],
              }),
              grid({
                columns: 2,
                blocks: [
                  textField({
                    label: "River Name",
                    path: ["data", "surveyDetails", "riverName"],
                    required: required(
                      "info",
                      "You should enter a river name."
                    ),
                    help: outdent`Name of the river or stream (e.g. as shown on 1:50,000 scale maps).

                    Unnamed tributaries should be named with reference to a main watercourse (e.g. "Tributary of River Brent").`,
                  }),
                  textField({
                    label: "Reach Name",
                    path: ["data", "surveyDetails", "reachName"],
                    required: required(
                      "info",
                      "You should enter a reach name."
                    ),
                    help: outdent`Name of the reach in which the module is located.`,
                  }),
                  // textField({
                  //   label: "WFD Waterbody ID",
                  //   path: ["data", "surveyDetails", "wfdWaterBodyId"],
                  //   help: outdent`ID used to identify the water body for the purposes of Water Framework Directive reporting.`,
                  // }),
                ],
              }),
            ],
          }),
          section({
            title: "Reach Location",
            path: ["data", "surveyDetails"],
            blocks: [
              grid({
                columns: 2,
                blocks: [
                  pointField({
                    label: "Upstream Location",
                    region: "uk",
                    path: ["upstreamLocation"],
                    required: required(
                      "info",
                      "You should specify an upstream location."
                    ),
                    help: "Locate the upstream point of your River Type reach. Use this location when calculating distances and elevations below.",
                  }),
                  pointField({
                    label: "Downstream Location",
                    region: "uk",
                    path: ["downstreamLocation"],
                    required: required(
                      "info",
                      "You should specify an downstream location."
                    ),
                    help: "Locate the downstream end of your River Type reach. Use this location when calculating distances and elevations below.",
                  }),
                ],
              }),
            ],
          }),
          section({
            title: "Reach Properties",
            path: ["data", "reachProperties"],
            blocks: columns2(
              vstack(
                select({
                  label: "River Category",
                  path: ["riverCategory"],
                  options: MrsRtaRiverCategoryEnum.entries,
                  help: outdent`
                    Large and navigable rivers are treated differently from other river types.
                    If you select *large* or *navigable* above,
                    you don't need to complete the fields A1 to A8 below.
                    `,
                }),
                numberField({
                  label: "A1: Braiding Index",
                  path: ["braidingIndex"],
                  decimalPlaces: 3,
                  help: "The number of wet channels separated by sparsely vegetated or unvegetated bars or islands during low flows. This index is mainly used in rivers where A8 is gravel or coarser.",
                }),
                numberField({
                  label: "Reach River Length",
                  path: ["reachRiverLength"],
                  units: "km",
                  decimalPlaces: 3,
                  required: required(
                    "info",
                    "You should enter a river length."
                  ),
                  bounds: maxValue(
                    50,
                    "info",
                    "This looks high. Make sure you're entering kilomters not meters!"
                  ),
                  help: outdent`
                    The length of river channel from the upstream to downstream locations.
                    Be sure to measure around all meanders.
                    `,
                }),
                numberField({
                  label: "Reach Valley Length",
                  path: ["reachValleyLength"],
                  units: "km",
                  decimalPlaces: 3,
                  required: required(
                    "info",
                    "You should enter a valley length."
                  ),
                  bounds: maxValue(
                    50,
                    "info",
                    "This looks high. Make sure you're entering kilomters not meters!"
                  ),
                  help: outdent`The length of valley from the upstream to downstream locations. Include meanders in the valley but don't follow the wetted channel. The valley length should end up shorter than the river length overall.`,
                }),
                dynamicValue({
                  label: "A2: Sinuosity",
                  secondaryLabel: "Calculated Value",
                  valueType: "number",
                  value: topoExpr(env => calcA2(env).getOrNull()),
                  decimalPlaces: 3,
                  help: "The *reach river length* divided by the *reach valley length*.",
                }),
                numberField({
                  label: "A3: Anabranching Index",
                  path: ["anabranchingIndex"],
                  decimalPlaces: 3,
                  required: required(
                    "info",
                    "You should enter an anabranching index."
                  ),
                  help: "The number of unvegetated channels separated by well vegetated bars or islands during low flows. This index is only used in rivers where A8 is sand or finer.",
                }),
                select({
                  label: "A4: Level of Confinement",
                  path: ["levelOfConfinement"],
                  options: MrsRtaLevelOfConfinementEnum.entries,
                  required: required(
                    "info",
                    "You should select a level of confinement."
                  ),
                  help: "The approximate proportion of the river reach’s bank length that is in contact with (close proximity to) valley side slopes or ancient terraces i.e. not caused by artificial features or land use.",
                }),
                numberField({
                  label: "Upstream elevation",
                  path: ["upstreamElevation"],
                  units: "m",
                  required: required(
                    "info",
                    "You should enter an upstream elevation."
                  ),
                  help: outdent`The elevation at the upstream location.`,
                }),
                numberField({
                  label: "Downstream elevation",
                  path: ["downstreamElevation"],
                  units: "m",
                  required: required(
                    "info",
                    "You should enter a downstream elevation."
                  ),
                  help: outdent`The elevation at the downstream location.`,
                  customRules: [
                    rule({
                      level: "error",
                      message:
                        "The downsteam elevation should be lower than the upstream elevation.",
                      triggerWhen: topoExpr(env => {
                        const upstream = env.getAbsolute([
                          "data",
                          "reachProperties",
                          "upstreamElevation",
                        ]);

                        const downstream = env.getAbsolute([
                          "data",
                          "reachProperties",
                          "downstreamElevation",
                        ]);

                        return (
                          isNumber(upstream) &&
                          isNumber(downstream) &&
                          upstream <= downstream
                        );
                      }),
                    }),
                  ],
                }),
                dynamicValue({
                  label: "A5: Reach Valley Gradient",
                  secondaryLabel: "Calculated Value",
                  valueType: "number",
                  value: topoExpr(env => calcA5(env).getOrNull()),
                  decimalPlaces: 5,
                  units: "m/m",
                  help: outdent`
                    The elevation difference between
                    the *upstream elevation* and *downstream elevation*
                    divided by the *reach valley length*.
                    `,
                }),
                checkbox({
                  label: "A6: Bedrock Reach?",
                  path: ["bedrockReach"],
                  help: "Look at the values of A6 index on the MoRPh5 map and insert here OR choose a value that is representative of the whole reach.",
                }),
                select({
                  label: "A7: Coarsest Bed Material Size Class",
                  path: ["coarsestBedMaterial"],
                  options: MrsRtaCoarsestBedMaterialEnum.entries,
                  help: "Look at the values of A7 index on the MoRPh5 map and insert here OR choose a value that is representative of the whole reach.",
                }),
                select({
                  label: "A8: Average Bed Material Size Class",
                  path: ["averageBedMaterial"],
                  options: MrsRtaAverageBedMaterialEnum.entries,
                  help: "Look at the values of A8 index on the MoRPh5 map and insert here OR choose a value that is representative of the whole reach.",
                }),
                dynamicValue({
                  label: "Calculated River Type",
                  valueType: "string",
                  value: topoExpr(env =>
                    riverType(env)
                      .map(tpe =>
                        tpe == null ? null : MrsRtaRiverTypeEnum.labelOf(tpe)
                      )
                      .getOrNull()
                  ),
                  // help: outdent`The river type calculated from the A1 to A8 values above.`,
                }),
                select({
                  label: "Override River Type",
                  path: ["riverTypeOverride"],
                  placeholder: "- Not overridden -",
                  options: MrsRtaRiverTypeEnum.entries,
                  help: "If the calculated river type isn't coming out as you'd expect for this reach, you can optionally override it here and add notes to explain why in the box below. Leave this field blank if you are happy with the calculated type.",
                }),
                dynamicValue({
                  label: "Final River Type",
                  valueType: "string",
                  value: topoExpr(env =>
                    finalRiverType(env)
                      .map(tpe =>
                        tpe == null ? null : MrsRtaRiverTypeEnum.labelOf(tpe)
                      )
                      .getOrNull()
                  ),
                  help: outdent`
                    The final river type to be used in River Condition calculations.
                    This will be the *calculated river type*
                    unless you have entered an *overridden river type* above.
                    `,
                })
              )
            ),
          }),
          section({
            title: "Notes",
            path: [],
            blocks: [
              textArea({
                label: null,
                path: ["data", "notes"],
                rows: 8,
                help: "Include any notes that might be useful in the future: justification for your selection of gps upstream and downstream locations, reasons for overriding the river type (if applicable), and so on.",
              }),
            ],
          }),
        ],
      }),
    ],
  });
}
