// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopLandUse =
  | "Crops"
  | "Pasture"
  | "Woodland"
  | "GrasslandOrShrubs"
  | "NaturalGrassland"
  | "Meadow"
  | "UrbanResidential"
  | "RuralResidential"
  | "Industrial"
  | "UrbanPark"
  | "Other";

const Crops: UclPitStopLandUse = "Crops";
const Pasture: UclPitStopLandUse = "Pasture";
const Woodland: UclPitStopLandUse = "Woodland";
const GrasslandOrShrubs: UclPitStopLandUse = "GrasslandOrShrubs";
const NaturalGrassland: UclPitStopLandUse = "NaturalGrassland";
const Meadow: UclPitStopLandUse = "Meadow";
const UrbanResidential: UclPitStopLandUse = "UrbanResidential";
const RuralResidential: UclPitStopLandUse = "RuralResidential";
const Industrial: UclPitStopLandUse = "Industrial";
const UrbanPark: UclPitStopLandUse = "UrbanPark";
const Other: UclPitStopLandUse = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopLandUseEnum = {
  ...createEnum("UclPitStopLandUse", [
    [Crops, "Agricultural fields with crops (arable)"],
    [Pasture, "Agricultural fields for pasture"],
    [Woodland, "Woodland"],
    [GrasslandOrShrubs, "Grassland/shrub"],
    [NaturalGrassland, "Natural rough grassland"],
    [Meadow, "Meadow"],
    [UrbanResidential, "Urban residential"],
    [RuralResidential, "Rural residential"],
    [Industrial, "Industrial"],
    [UrbanPark, "Urban park"],
    [Other, "Other"],
  ]),
  Crops,
  Pasture,
  Woodland,
  GrasslandOrShrubs,
  NaturalGrassland,
  Meadow,
  UrbanResidential,
  RuralResidential,
  Industrial,
  UrbanPark,
  Other,
};

export default UclPitStopLandUseEnum;
