import { checkExhausted } from "@cartographerio/util";
import { BlockState, FieldState } from "./reducer";
import { Block, Field } from "./type";

export function isField(block: Block): block is Field {
  switch (block.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
    case "IntegerField":
    case "NumberField":
    case "UserRefField":
    case "TimestampField":
    case "PointField":
    case "LineStringField":
    case "PolygonField":
    case "FeatureField":
    case "TeamField":
    case "NearestFeatureField":
    case "Checkbox":
    case "Select":
    case "MultiSelect":
    case "AttachmentField":
      return true;
    case "DynamicImage":
    case "DynamicValue":
    case "DynamicGrid":
    case "FullWidth":
    case "Heading":
    case "Text":
    case "Group":
    case "Repeat":
    case "RequireRole":
    case "Card":
    case "Scope":
    case "Visibility":
    case "PrimarySurveyorField":
    case "PrimaryTeamField":
      return false;
    default:
      return checkExhausted(block);
  }
}
export function isFieldState(state: BlockState): state is FieldState {
  switch (state.type) {
    case "FieldState":
      return true;
    case "DynamicImageState":
    case "DynamicValueState":
    case "DynamicGridState":
    case "FullWidthState":
    case "HeadingState":
    case "TextState":
    case "GroupState":
    case "RepeatState":
    case "RequireRoleState":
    case "CardState":
    case "ScopeState":
    case "VisibilityState":
    case "PrimarySurveyorFieldState":
    case "PrimaryTeamFieldState":
      return false;
    default:
      return checkExhausted(state);
  }
}
