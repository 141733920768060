import { Result } from "@cartographerio/fp";
import { GeometryAtom, isPoint, Point } from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  StdActivityType,
  StdActivityTypeEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  activityType?: StdActivityType | null;
  siteName?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(
      data,
      "activityType",
      isNullable(StdActivityTypeEnum.isValue)
    ) &&
    hasOptionalKey(data, "siteName", isNullable(isString))
  );
}

const g = Result.guard(isPartialData, "PartialStdActivityDiary");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    [
      data.activityType == null
        ? "Unknown Activity"
        : StdActivityTypeEnum.labelOf(data.activityType),
      data.siteName ?? "Unknown Location",
    ].join(" at ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
    activityType: null,
    duration: null,
    comments: null,
  }));
}
