import { Path } from "@cartographerio/topo-core";
import {
  attachmentField,
  Block,
  bounds,
  checkbox,
  columns2,
  enumSelectOptions,
  form,
  Form,
  integerField,
  minValue,
  numberField,
  page,
  pointField,
  required,
  requiredIf,
  requiredIff,
  section,
  select,
  selectOption,
  textArea,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import {
  MrsArtificialGroundCoverEnum,
  MrsBankProfileEnum,
  MrsChannelCoverageEnum,
  MrsMajorIntermediateMinorEnum,
  MrsReinforcementTypeEnum,
  MrsReinforcementVerticalExtentEnum,
  MrsSedimentSizeEnum,
  MrsTreeTypeEnum,
  UrsSurveyBankEnum,
  UrsSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
import {
  morphApeSelect,
  morphAtpeSelect,
  morphBanks,
  morphChannel,
  morphCheckbox,
  morphCount,
  morphSection,
  morphSelect,
  morphText,
} from "./formHelpers";

function nuisancePlantSpeciesCategory(props: {
  title: string;
  path: Path;
  help?: string;
  useBanks: boolean;
}): Block {
  const { title, path, help, useBanks } = props;
  const banksOrChannel = useBanks ? morphBanks : morphChannel;
  return morphSection({
    title,
    help,
    path,
    columns: useBanks ? 2 : 1,
    blocks: [
      banksOrChannel({
        label: "Himalayan Balsam",
        path: ["himalayanBalsam"],
        abundance: morphAtpeSelect({
          defaultImage: "nuisance-plant-species/himalayan-balsam.jpg",
        }),
      }),
      banksOrChannel({
        label: "Japanese Knotweed",
        path: ["japaneseKnotweed"],
        abundance: morphAtpeSelect({
          defaultImage: "nuisance-plant-species/japanese-knotweed.jpg",
        }),
      }),
      banksOrChannel({
        label: "Giant Hogweed",
        path: ["giantHogweed"],
        abundance: morphAtpeSelect({
          defaultImage: "nuisance-plant-species/giant-hogweed.jpg",
        }),
      }),
      banksOrChannel({
        label: "Floating Pennywort",
        path: ["floatingPennywort"],
        abundance: morphAtpeSelect({
          defaultImage: "nuisance-plant-species/floating-pennywort.jpg",
        }),
      }),
      banksOrChannel({
        label: "Other Species 1",
        path: ["otherSpecies1"],
        abundance: morphAtpeSelect(),
        code: morphText({
          secondaryLabel: "Species",
          required: false,
        }),
      }),
      banksOrChannel({
        label: "Other Species 2",
        path: ["otherSpecies2"],
        abundance: morphAtpeSelect(),
        code: morphText({
          secondaryLabel: "Species",
          required: false,
        }),
      }),
    ],
  });
}

function photographsSection() {
  return section({
    title: "Photographs",
    path: [],
    help: outdent`
    Upload up to four photographs.
    Whenever possible include the following three
    taken from the module midpoint:

    1. Across the channel (showing bank face clearly)
    2. The downstream half of the river channel in the module
    3. The upstream half of the river channel in the module
    `,
    blocks: [
      attachmentField({
        label: null,
        path: ["data", "surveyDetails", "photographs"],
        fullWidth: true,
        maxFiles: 6,
      }),
    ],
  });
}

function notesSection(path: Path) {
  return section({
    title: "Notes",
    path: [],
    blocks: [
      textArea({
        label: null,
        path,
        fullWidth: true,
        rows: 8,
        help: outdent`
        Use this box to record issues arising regarding the fields on this page,
        or to explain where you are unsure of any measurements / records you have made.
        `,
      }),
    ],
  });
}

export function mrsMorphForm(title: string): Form {
  return form({
    title,
    pages: [
      page({
        title: "General Information",
        path: [],
        blocks: [
          section({
            title: "Project Details",
            path: [],
            blocks: columns2(
              textField({
                label: "Project Name",
                path: ["data", "surveyDetails", "projectName"],
                help: "Name of the river restoration project being monitored (if applicable).",
              }),
              textField({
                label: "MoRPh Correlation Code",
                path: ["data", "surveyDetails", "projectCode"],
                help: outdent`
                  Optional. Code for differentiating scenarios on the same MoRPh subreach.
                  Used to group MoRPh modules for the purpose of calculating indices.
                  See the [Modular River Survey web site](https://modularriversurvey.org/morph-pro-calculations/)
                  for help on using this field.
                  `,
              }),
              // textField({
              //   label: "WFD Waterbody ID",
              //   path: ["data", "surveyDetails", "wfdWaterBodyId"],
              //   help: outdent`
              //     ID used to identify the water body for the purposes of
              //     Water Framework Directive reporting.
              //   `,
              // }),
              select({
                label: "Survey Type",
                path: ["data", "surveyDetails", "surveyType"],
                options: enumSelectOptions(UrsSurveyTypeEnum),
                help: outdent`
                  Is this survey for general *monitoring* purposes,
                  is it a *pre-project, post-project or post-recovery assessment*,
                  is it testing a hypothetical *scenario*,
                  or is it a dummy survey created during a *training* course?
                  `,
              }),
              textField({
                label: "Scenario Name",
                path: ["data", "surveyDetails", "scenarioName"],
                help: outdent`
                  Optional.
                  If you selected *Scenario* in the *Survey Type* field, enter a scenario name here.
                  The name will be searchable on the survey list.
                  Note: this field does *not* affect the grouping of MoRPh modules when calculating outputs.
                  `,
                customRules: requiredIff({
                  level: "info",
                  requiredMessage: "You should name the scenario.",
                  forbiddenMessage:
                    "Only enter a scenario name if you selected 'Scenario' above.",
                  otherPath: ["data", "surveyDetails", "surveyType"],
                  otherTest: value => value === UrsSurveyTypeEnum.Scenario,
                }),
              })
            ),
          }),
          section({
            title: "Surveyor and Survey Conditions",
            path: [],
            blocks: [
              columns2(
                timestampField({
                  label: "Survey Date and Time",
                  path: ["data", "recorded"],
                  required: required("info"),
                  help: "Date and time the survey was recorded in the field.",
                  defaultValue: "now",
                })
              ),
              columns2(
                select({
                  label: "Survey Bank",
                  path: ["data", "surveyDetails", "surveyBank"],
                  options: enumSelectOptions(UrsSurveyBankEnum),
                  required: required("info"),
                  help: outdent`
                  From which bank did you conduct the survey?
                  "Left" and "right" refer to the banks as you face in a downstream direction.
                  `,
                }),
                checkbox({
                  label: "Bed Visible?",
                  path: ["data", "surveyDetails", "bedVisible"],
                  checkboxLabel: "Is the bed fully or partially visible?",
                  help: outdent`
                  Check the box if the channel bed is at least partially visible.
                  If it isn't fully visible, use the *Notes* section to record why.
                  `,
                  defaultValue: false,
                }),
                checkbox({
                  label: "Adverse Conditions?",
                  path: ["data", "surveyDetails", "adverseConditions"],
                  checkboxLabel:
                    "Were there adverse conditions at the time of the survey?",
                  defaultValue: false,
                  help: outdent`
                  Examples of adverse conditions include: limited access, high river flows,
                  impenetrable riparian vegetation, and poor light conditions.
                  `,
                }),
                textField({
                  label: "Adverse Conditions Notes",
                  path: [
                    "data",
                    "surveyDetails",
                    "adverseConditionsDescription",
                  ],
                  help: "If there were adverse conditions, please describe them.",
                  customRules: [
                    requiredIf({
                      level: "info",
                      message: "Please describe the adverse conditions.",
                      otherPath: ["data", "surveyDetails", "adverseConditions"],
                      otherTest: other => other === true,
                    }),
                  ],
                })
              ),
            ],
          }),
          section({
            title: "Module Name and Location",
            path: [],
            blocks: columns2(
              textField({
                label: "River Name",
                path: ["data", "surveyDetails", "riverName"],
                required: required("info"),
                help: outdent`
                  Name of the river or stream (e.g. as shown on 1:50,000 scale maps).

                  Unnamed tributaries should be named with reference to a main watercourse (e.g. "Tributary of River Brent").

                  This field is used to group surveys when calculating MoRPh10 outputs
                  (for MoRPh Citizen Science) and MoRPh5 outputs
                  (for the River Condition Assessment).
                  Make sure the values match exactly across your group of surveys!
                `,
              }),
              textField({
                label: "Reach Name",
                path: ["data", "surveyDetails", "reachName"],
                required: required("info"),
                help: outdent`
                  Name of the reach in which the module is located.

                  This field is used to group surveys when calculating MoRPh10 outputs
                  (for MoRPh Citizen Science) and MoRPh5 outputs
                  (for the River Condition Assessment).
                  Make sure the values match exactly across your group of surveys!
                  `,
              }),
              textField({
                label: "Subreach Name",
                path: ["data", "surveyDetails", "subreachName"],
                required: required("info"),
                help: outdent`
                  Code identifying a set of up to 10 contiguous MoRPhs within the reach.

                  This field is used to group surveys when calculating MoRPh10 outputs
                  (for MoRPh Citizen Science) and MoRPh5 outputs
                  (for the River Condition Assessment).
                  Make sure the values match exactly across your group of surveys!
                  `,
              }),
              integerField({
                label: "Module Number",
                path: ["data", "surveyDetails", "moduleNumber"],
                required: required("info"),
                bounds: bounds(1, 10, "error"),
                help: outdent`
                  Module number (1 to 10 from upstream of subreach to downstream).
                  `,
              }),
              checkbox({
                label: "Riverfly Site?",
                path: ["data", "surveyDetails", "riverflySite"],
                checkboxLabel: "Was the survey performed at a Riverfly site?",
                help: outdent`
                  Only complete this field if you are a Riverfly volunteer.
                  `,
              }),
              textField({
                label: "Riverfly Site ID",
                path: ["data", "surveyDetails", "riverflySiteId"],
                help: outdent`
                  Only complete this field if you are a Riverfly volunteer.
                  `,
                customRules: [
                  requiredIf({
                    level: "info",
                    message: "Please specify the ID of the Riverfly site.",
                    otherPath: ["data", "surveyDetails", "riverflySite"],
                    otherTest: value => value === true,
                  }),
                ],
              }),
              select({
                label: "Module Length",
                path: ["data", "surveyDetails", "moduleLength"],
                required: required("info"),
                options: [
                  selectOption(10, "10m (river <5m wide)"),
                  selectOption(20, "20m (river 5m to <10m wide)"),
                  selectOption(30, "30m (river 10m to <20m wide)"),
                  selectOption(40, "40m (river 20m to <30m wide)"),
                  selectOption(50, "50m (large and navigable rivers)"),
                ],
                help: outdent`
                  Module length is based on the *predominant active river width*,
                  estimated as the typical water width plus any area of bare sediment
                  or emergent aquatic plants at the edge of the water.
                  `,
              }),
              pointField({
                label: "Midpoint Location",
                path: ["data", "surveyDetails", "midpointLocation"],
                fullWidth: true,
                required: required("info"),
                help: outdent`
                  Location of the midpoint of the module.
                  `,
              })
            ),
          }),
          section({
            title: "Channel Dimensions",
            path: ["data", "channelDimensions"],
            help: outdent`
            The figure below illustrates the dimensions as seen looking downstream:

            ![Channel Dimensions](https://media.cartographer.io/static/images/mrs-morph/channel-dimensions.png)
            `,
            blocks: columns2(
              numberField({
                label: "MoRPh River Width",
                path: ["morphRiverWidth"],
                units: "m",
                required: required("info"),
                bounds: minValue(0, "error"),
                fullWidth: true,
                help: outdent`
                  Local measurement of the MoRPh River Width
                  (may differ from the average value used to define the MoRPh module length).

                  MoRPh is most suitable on rivers up to 30m wide.
                  For large rivers over 30m wide,
                  MoRPh should only be used where both banks are accessible.
                  `,
              }),
              numberField({
                label: "Left Bank Height",
                path: ["leftBankHeight"],
                units: "m",
                required: required("info"),
                bounds: minValue(0, "error"),
                help: outdent`
                  The height of the left bank from the water level to the bank top / floodplain level.
                  `,
              }),
              numberField({
                label: "Right Bank Height",
                path: ["rightBankHeight"],
                units: "m",
                required: required("info"),
                bounds: minValue(0, "error"),
                help: outdent`
                  The height of the right bank from the water level to the bank top / floodplain level.
                  `,
              }),
              numberField({
                label: "Bankfull Width",
                path: ["bankfullWidth"],
                units: "m",
                required: required("info"),
                bounds: minValue(0, "error"),
                fullWidth: true,
                help: outdent`
                  Horizontal distance across the channel from at the level
                  where the river first spills out onto the floodplain.
                  `,
              }),
              numberField({
                label: "Water Width",
                path: ["waterWidth"],
                units: "m",
                required: required("info"),
                bounds: minValue(0, "error"),
                help: outdent`
                  Width of the wetted part of the channel.
                  `,
              }),
              numberField({
                label: "Water Depth",
                path: ["waterDepth"],
                units: "m",
                required: required("info"),
                bounds: minValue(0, "error"),
                help: outdent`
                  Average depth of the water in the cross-section.
                  An average of 3 equally spaced depths using a ranging pole is adequate.
                  Where access is not feasible, estimate the water depth.
                  `,
              }),
              pointField({
                label: "Location of Cross Section",
                path: ["locationOfCrossSection"],
                fullWidth: true,
                help: outdent`
                  Location of the cross-section measurements,
                  if applicable and if different to to the mid point location.
                  `,
              })
            ),
          }),
          photographsSection(),
          notesSection(["data", "channelDimensions", "notes"]),
        ],
      }),
      page({
        title: "Bank Top",
        path: [],
        blocks: [
          morphSection({
            path: ["data", "bankTop", "artificialGroundCover"],
            title: "2.1 Artificial Ground Cover",
            help: "Within 10m of the bank edge.",
            columns: 2,
            blocks: [
              morphBanks({
                label: "Dominant Artificial Ground Cover",
                path: ["dominantArtificialGroundCover"],
                abundance: morphAtpeSelect(),
                code: morphSelect({
                  secondaryLabel: "Cover Type",
                  source: MrsArtificialGroundCoverEnum,
                  showValues: true,
                  required: false,
                }),
              }),
              morphBanks({
                label: "Subdominant Artificial Ground Cover",
                path: ["subdominantArtificialGroundCover"],
                abundance: morphApeSelect(),
                code: morphSelect({
                  secondaryLabel: "Cover Type",
                  source: MrsArtificialGroundCoverEnum,
                  showValues: true,
                  required: false,
                }),
              }),
            ],
          }),
          morphSection({
            path: ["data", "bankTop", "terrestrialVegetation"],
            title: "2.2a Terrestrial Vegetation",
            help: "Within 10m of the bank edge.",
            columns: 2,
            blocks: [
              morphBanks({
                label: "Unvegetated (Bare Soil/Rock)",
                path: ["unvegetated"],
                abundance: morphAtpeSelect(),
              }),
              morphBanks({
                label: "Mosses/Lichens",
                path: ["bryophytes"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/bryophytes.jpg",
                }),
              }),
              morphBanks({
                label: "Short/Creeping Herbs/Grasses",
                path: ["shortHerbsOrGrasses"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/short-herbs-or-grasses.jpg",
                }),
              }),
              morphBanks({
                label: "Tall Herbs/Grasses",
                path: ["tallHerbsOrGrasses"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/tall-herbs-or-grasses.jpg",
                }),
              }),
              morphBanks({
                label: "Scrub/Shrubs",
                path: ["scrubOrShrubs"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/scrub-or-shrubs.jpg",
                }),
              }),
              morphBanks({
                label: "Saplings/Trees",
                path: ["saplingsOrTrees"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/saplings-or-trees.jpg",
                }),
              }),
              morphBanks({
                label: "Fallen Trees",
                path: ["fallenTrees"],
                help: "ONLY those with a significant proportion on bank top.",
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/fallen-trees.jpg",
                }),
              }),
              morphBanks({
                label: "Leaning Trees",
                path: ["leaningTrees"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/leaning-trees.jpg",
                }),
              }),
              morphBanks({
                label: "J-Shaped Trees",
                path: ["jShapedTrees"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/j-shaped-trees.jpg",
                }),
              }),
              morphBanks({
                label: "Tree/Shrub Branches Trailing Into Channel",
                path: ["trailingTreeOrShrubBranches"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/trailing-tree-or-shrub-branches.jpg",
                }),
              }),
              morphBanks({
                label: "Large Wood (>1m Long, >10cm Diameter)",
                path: ["largeWood"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-terrestrial-vegetation/large-wood.jpg",
                }),
              }),
              morphBanks({
                label: "Predominant Tree Type",
                path: ["predominantTreeType"],
                code: morphSelect({
                  secondaryLabel: "Tree Type",
                  source: MrsTreeTypeEnum,
                  defaultValue: MrsTreeTypeEnum.Absent,
                }),
              }),
            ],
          }),
          nuisancePlantSpeciesCategory({
            title: "2.2b Non-Native Invasive Plant Species",
            path: ["data", "bankTop", "nuisancePlantSpecies"],
            help: "Within 10m of the bank edge.",
            useBanks: true,
          }),
          morphSection({
            path: ["data", "bankTop", "waterRelatedFeatures"],
            title: "2.3 Water-Related Features",
            help: "Within 10m of the bank edge.",
            columns: 2,
            blocks: [
              morphBanks({
                label: "Pond (Disconnected)",
                path: ["disconnectedPond"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-water-related-features/disconnected-pond.jpg",
                }),
                help: "Disconnected from the river.",
              }),
              morphBanks({
                label: "Pond (Connected)",
                path: ["connectedPond"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-water-related-features/connected-pond.jpg",
                }),
                help: "Connected to the river by a water-filled channel.",
              }),
              morphBanks({
                label: "Side Channel",
                path: ["sideChannel"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-water-related-features/side-channel.jpg",
                }),
              }),
              morphBanks({
                label: "Wetland (Short)",
                path: ["wetlandShortNonWoody"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-water-related-features/wetland-short-non-woody.jpg",
                }),
                help: "Short non-woody vegetation.",
              }),
              morphBanks({
                label: "Wetland (Tall)",
                path: ["wetlandTallNonWoody"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-water-related-features/wetland-tall-non-woody.jpg",
                }),
                help: "Tall non-woody vegetation.",
              }),
              morphBanks({
                label: "Wetland (Shrubs and Trees)",
                path: ["wetlandShrubsAndTrees"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-top-water-related-features/wetland-shrubs-and-trees.jpg",
                }),
              }),
            ],
          }),
          notesSection(["data", "bankTop", "notes"]),
        ],
      }),
      page({
        title: "Bank Face",
        path: [],
        blocks: [
          morphSection({
            title: "3.1 Bank Face Profile",
            path: ["data", "bankFace", "profile"],
            columns: 2,
            blocks: [
              morphBanks({
                label: "Dominant Natural/Artificial Bank Profile",
                path: ["dominantBankProfile"],
                abundance: morphAtpeSelect(),
                code: morphSelect({
                  secondaryLabel: "Profile Type",
                  source: MrsBankProfileEnum,
                  defaultImage: "bank-profile/all.jpg",
                  imagePattern: "bank-profile/*.jpg",
                  required: required("info"),
                }),
              }),
              morphBanks({
                label: "Subdominant Natural/Artificial Bank Profile",
                path: ["subdominantBankProfile"],
                abundance: morphApeSelect({
                  help: "**Only record** if it occupies >20% of the area within 10m of the bank edge.",
                }),
                code: morphSelect({
                  secondaryLabel: "Profile Type",
                  source: MrsBankProfileEnum,
                  defaultImage: "bank-profile/all.jpg",
                  imagePattern: "bank-profile/*.jpg",
                  help: "**Only record** if it occupies >20% of the area within 10m of the bank edge.",
                }),
              }),
            ],
          }),
          morphSection({
            title: "3.2a Bank Face Natural Materials",
            path: ["data", "bankFace", "naturalMaterials"],
            columns: 2,
            blocks: [
              morphBanks({
                label: "Dominant Sediment Size (Upper 2/3)",
                path: ["dominantSedimentSizeUpper"],
                help: "Dominant sediment size on upper 2/3 of bank face.",
                code: morphSelect({
                  secondaryLabel: "Sediment Size",
                  source: MrsSedimentSizeEnum,
                  imagePattern: "sediment-size/*.jpg",
                  required: false,
                }),
              }),
              morphBanks({
                label: "Dominant Sediment Size (Lower 1/3)",
                path: ["dominantSedimentSizeLower"],
                help: "Dominant sediment size on lower 1/3 of bank face.",
                code: morphSelect({
                  secondaryLabel: "Sediment Size",
                  source: MrsSedimentSizeEnum,
                  imagePattern: "sediment-size/*.jpg",
                  required: false,
                }),
              }),
            ],
          }),
          morphSection({
            title: "3.2b Bank Face Reinforcement",
            path: ["data", "bankFace", "reinforcement"],
            columns: 2,
            blocks: [
              morphBanks({
                label: "Reinforcement Vertical Extent",
                path: ["verticalExtent"],
                abundance: morphSelect({
                  secondaryLabel: "Predominant Vertical Extent",
                  source: MrsReinforcementVerticalExtentEnum,
                  defaultValue: MrsReinforcementVerticalExtentEnum.Absent,
                  imagePattern: "reinforcement-vertical-extent/*.jpg",
                }),
              }),
              morphBanks({
                label: "Reinforcement Horizontal Extent",
                path: ["horizontalExtent"],
                abundance: morphAtpeSelect({
                  secondaryLabel: "Extent",
                }),
              }),
              morphBanks({
                label: "Dominant Reinforcement Type",
                path: ["dominantType"],
                code: morphSelect({
                  secondaryLabel: "Reinforcement Type",
                  source: MrsReinforcementTypeEnum,
                  imagePattern: "reinforcement-type/*.jpg",
                }),
              }),
              morphBanks({
                label: "Subdominant Reinforcement Type",
                path: ["subdominantType"],
                code: morphSelect({
                  secondaryLabel: "Reinforcement Type",
                  source: MrsReinforcementTypeEnum,
                  imagePattern: "reinforcement-type/*.jpg",
                }),
              }),
            ],
          }),
          morphSection({
            title: "3.3a Bank Face Natural Physical Features",
            path: ["data", "bankFace", "naturalPhysicalFeatures"],
            columns: 2,
            blocks: [
              morphBanks({
                label: "Unvegetated Side Bar",
                path: ["unvegetatedSideBar"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-natural-physical-features/unvegetated-side-bar.jpg",
                }),
                code: morphSelect({
                  secondaryLabel: "Sediment Size",
                  source: MrsSedimentSizeEnum,
                  imagePattern: "sediment-size/*.jpg",
                  required: false,
                }),
                help: outdent`
                **Unvegetated bar:** <50% vegetation cover
                `,
              }),
              morphBanks({
                label: "Vegetated Side Bar",
                path: ["vegetatedSideBar"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-natural-physical-features/vegetated-side-bar.jpg",
                }),
                code: morphSelect({
                  secondaryLabel: "Sediment Size",
                  source: MrsSedimentSizeEnum,
                  imagePattern: "sediment-size/*.jpg",
                  required: false,
                }),
                help: outdent`
                **Vegetated bar:** >50% vegetation cover
                `,
              }),
              morphBanks({
                label: "Berm",
                path: ["berm"],
                abundance: morphAtpeSelect({
                  defaultImage: "bank-face-natural-physical-features/berm.jpg",
                }),
              }),
              morphBanks({
                label: "Bench",
                path: ["bench"],
                abundance: morphAtpeSelect({
                  defaultImage: "bank-face-natural-physical-features/bench.jpg",
                }),
              }),
              morphBanks({
                label: "Stable Cliff (>0.5m) ",
                path: ["stableCliff"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-natural-physical-features/stable-cliff.jpg",
                }),
              }),
              morphBanks({
                label: "Eroding Cliff (>0.5m)",
                path: ["erodingCliff"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-natural-physical-features/eroding-cliff.jpg",
                }),
              }),
              morphBanks({
                label: "Toe",
                path: ["toe"],
                abundance: morphAtpeSelect({
                  defaultImage: "bank-face-natural-physical-features/toe.jpg",
                }),
              }),
              morphBanks({
                label: "Nest Holes and Animal Burrows",
                path: ["animalBurrows"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-natural-physical-features/animal-burrows.jpg",
                }),
              }),
              morphBanks({
                label: "Marginal Backwater",
                path: ["marginalBackwater"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-natural-physical-features/marginal-backwater.jpg",
                }),
              }),
              morphBanks({
                label: "Tributary Confluence",
                path: ["tributaryConfluence"],
                abundance: morphCount({
                  image:
                    "bank-face-natural-physical-features/tributary-confluence.jpg",
                }),
              }),
            ],
          }),
          morphSection({
            title: "3.3b Bank Face Artificial Features",
            path: ["data", "bankFace", "artificialFeatures"],
            columns: 2,
            blocks: [
              morphBanks({
                label: "Pipes/Outfalls",
                path: ["pipesOrOutfalls"],
                abundance: morphCount({
                  image: "bank-face-artificial-features/pipes-or-outfalls.jpg",
                  defaultValue: 0,
                }),
              }),
              morphBanks({
                label: "Jetty",
                path: ["jetty"],
                abundance: morphSelect({
                  secondaryLabel: "Feature Size",
                  source: MrsMajorIntermediateMinorEnum,
                  defaultImage: "bank-face-artificial-features/jetty.jpg",
                  required: false,
                }),
              }),
              morphBanks({
                label: "Deflector",
                path: ["deflector"],
                abundance: morphSelect({
                  secondaryLabel: "Feature Size",
                  source: MrsMajorIntermediateMinorEnum,
                  defaultImage: "bank-face-artificial-features/deflector.jpg",
                  required: false,
                }),
              }),
              morphBanks({
                label: "Other",
                path: ["other"],
                abundance: morphSelect({
                  secondaryLabel: "Feature Size",
                  source: MrsMajorIntermediateMinorEnum,
                  required: false,
                }),
                code: morphText({
                  secondaryLabel: "Feature Type",
                  required: false,
                }),
              }),
            ],
          }),
          morphSection({
            title: "3.4a Bank Face Terrestrial Vegetation",
            path: ["data", "bankFace", "terrestrialVegetation"],
            columns: 2,
            blocks: [
              morphBanks({
                label: "Unvegetated (Bare Soil/Rock)",
                path: ["unvegetated"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/unvegetated.jpg",
                }),
              }),
              morphBanks({
                label: "Mosses/Lichens",
                path: ["bryophytes"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/bryophytes.jpg",
                }),
              }),
              morphBanks({
                label: "Short/Creeping Herbs/Grasses",
                path: ["shortHerbsOrGrasses"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/short-herbs-or-grasses.jpg",
                }),
              }),
              morphBanks({
                label: "Tall Herbs/Grasses",
                path: ["tallHerbsOrGrasses"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/tall-herbs-or-grasses.jpg",
                }),
              }),
              morphBanks({
                label: "Scrub/Shrubs",
                path: ["scrubOrShrubs"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/scrub-or-shrubs.jpg",
                }),
              }),
              morphBanks({
                label: "Saplings/Trees",
                path: ["saplingsOrTrees"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/saplings-or-trees.jpg",
                }),
              }),
              morphBanks({
                label: "Large Wood",
                path: ["largeWood"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/large-wood.jpg",
                }),
                help: outdent`
                >1m long, >10cm diameter
                `,
              }),
              morphBanks({
                label: "Fallen Trees",
                path: ["fallenTrees"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/fallen-trees.jpg",
                }),
                help: outdent`
                **Only** those with a significant proportion on the bank face.
                `,
              }),
              morphBanks({
                label: "Leaning Trees",
                path: ["leaningTrees"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/leaning-trees.jpg",
                }),
              }),
              morphBanks({
                label: "J-Shaped Trees",
                path: ["jShapedTrees"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/j-shaped-trees.jpg",
                }),
              }),
              morphBanks({
                label: "Tree/Shrub Branches Trailing Into Channel",
                path: ["trailingTreeOrShrubBranches"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/trailing-tree-or-shrub-branches.jpg",
                }),
              }),
              morphBanks({
                label: "Exposed Tree Roots",
                path: ["exposedTreeRoots"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/exposed-tree-roots.jpg",
                }),
              }),
              morphBanks({
                label: "Discrete Organic Accumulation",
                path: ["discreteOrganicAccumulations"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "bank-face-terrestrial-vegetation/discrete-organic-accumulations.jpg",
                }),
                help: outdent`
                e.g. leaves, twigs
                `,
              }),
            ],
          }),
          morphSection({
            title: "3.4b Vegetation at Water Margin",
            path: ["data", "bankFace", "vegetationAtWaterMargin"],
            columns: 2,
            blocks: [
              morphBanks({
                label: "Liverworts/Mosses/Lichens",
                path: ["liverwortsOrMossesOrLichens"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "vegetation-at-water-margin/liverworts-or-mosses-or-lichens.jpg",
                }),
              }),
              morphBanks({
                label: "Emergent Broad Leaved",
                path: ["emergentBroadLeaved"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "vegetation-at-water-margin/emergent-broad-leaved.jpg",
                }),
              }),
              morphBanks({
                label: "Emergent Reeds/Linear-Leaved/Horsetails",
                path: ["emergentReedsOrLinearLeaved"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "vegetation-at-water-margin/emergent-reeds-or-linear-leaved.jpg",
                }),
              }),
              morphBanks({
                label: "Amphibious",
                path: ["amphibious"],
                abundance: morphAtpeSelect({
                  defaultImage: "vegetation-at-water-margin/amphibious.jpg",
                }),
              }),
              morphBanks({
                label: "Filamentous Algae",
                path: ["filamentousAlgae"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "vegetation-at-water-margin/filamentous-algae.jpg",
                }),
              }),
            ],
          }),
          nuisancePlantSpeciesCategory({
            title: "3.4c Non-Native Invasive Plants",
            path: ["data", "bankFace", "nuisancePlantSpecies"],
            useBanks: true,
          }),
          notesSection(["data", "bankFace", "notes"]),
        ],
      }),
      page({
        title: "Channel Bed",
        path: [],
        blocks: [
          morphSection({
            title: "4.1a Natural Materials",
            path: ["data", "channelBed", "naturalMaterials"],
            blocks: [
              morphChannel({
                label: "Bedrock",
                path: ["bedrock"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/be.jpg",
                }),
              }),
              morphChannel({
                label: "Boulder",
                path: ["boulder"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/bo.jpg",
                }),
              }),
              morphChannel({
                label: "Cobble",
                path: ["cobble"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/co.jpg",
                }),
              }),
              morphChannel({
                label: "Gravel-Pebble",
                path: ["gravelPebble"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/gp.jpg",
                }),
              }),
              morphChannel({
                label: "Sand",
                path: ["sand"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/sa.jpg",
                }),
              }),
              morphChannel({
                label: "Silt (and Finer Non-Sticky Particles)",
                path: ["silt"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/si.jpg",
                }),
              }),
              morphChannel({
                label: "Clay",
                path: ["clay"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/cl.jpg",
                }),
              }),
              morphChannel({
                label: "Organic",
                path: ["organic"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/or.jpg",
                }),
                help: outdent`
                Leaves, twigs, etc. Not fully decomposed.
                `,
              }),
              morphChannel({
                label: "Peat",
                path: ["peat"],
                abundance: morphAtpeSelect({
                  defaultImage: "sediment-size/pe.jpg",
                }),
              }),
              morphChannel({
                label: "Continuous Silt Layer",
                path: ["continuousSiltLayer"],
                abundance: morphAtpeSelect(),
                help: outdent`
                The form of underlying coarser sediments is visible.
                `,
              }),
              morphChannel({
                label: "Patchy Thin Silt Layer",
                path: ["patchyThinSiltLayer"],
                abundance: morphAtpeSelect(),
                help: outdent`
                Some coarser particles protrude through the layer.
                `,
              }),
            ],
          }),
          morphSection({
            title: "4.1b Reinforcement",
            path: ["data", "channelBed", "reinforcement"],
            blocks: [
              morphChannel({
                label: "Reinforcement Extent",
                path: ["extent"],
                abundance: morphAtpeSelect(),
              }),
              morphChannel({
                label: "Dominant Reinforcement Materials",
                path: ["dominantType"],
                code: morphSelect({
                  secondaryLabel: "Reinforcement Type",
                  source: MrsReinforcementTypeEnum,
                  imagePattern: "reinforcement-type/*.jpg",
                  required: false,
                }),
              }),
              morphChannel({
                label: "Subdominant Reinforcement Materials",
                path: ["subdominantType"],
                code: morphSelect({
                  secondaryLabel: "Reinforcement Type",
                  source: MrsReinforcementTypeEnum,
                  imagePattern: "reinforcement-type/*.jpg",
                  required: false,
                }),
                help: outdent`
                **Only record** if it occupies >20% of the reinforced area.
                `,
              }),
            ],
          }),
          morphSection({
            title: "4.2 Surface Flow Types",
            path: ["data", "channelBed", "surfaceFlowType"],
            blocks: [
              morphChannel({
                label: "Free Fall (FF)",
                path: ["freeFall"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/ff.jpg",
                }),
              }),
              morphChannel({
                label: "Chute (CH)",
                path: ["chute"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/ch.jpg",
                }),
              }),
              morphChannel({
                label: "Broken Standing Waves (BW)",
                path: ["brokenStandingWaves"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/bw.jpg",
                }),
              }),
              morphChannel({
                label: "Unbroken Standing Waves (UW)",
                path: ["unbrokenStandingWaves"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/uw.jpg",
                }),
              }),
              morphChannel({
                label: "Upwelling (UP)",
                path: ["upwelling"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/up.jpg",
                }),
              }),
              morphChannel({
                label: "Rippled (RP)",
                path: ["rippled"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/rp.jpg",
                }),
              }),
              morphChannel({
                label: "Smooth (SM)",
                path: ["smooth"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/sm.jpg",
                }),
              }),
              morphChannel({
                label: "No Perceptible Flow (NP)",
                path: ["noPerceptibleFlow"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/np.jpg",
                }),
              }),
              morphChannel({
                label: "Dry (DR)",
                path: ["dry"],
                abundance: morphAtpeSelect({
                  defaultImage: "flow-type/dr.jpg",
                }),
              }),
            ],
          }),
          morphSection({
            title: "4.3a Natural Physical Features",
            path: ["data", "channelBed", "naturalPhysicalFeatures"],
            blocks: [
              morphChannel({
                label: "Exposed Bedrock",
                path: ["exposedBedrock"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "channel-bed-natural-physical-features/exposed-bedrock.jpg",
                }),
              }),
              morphChannel({
                label: "Exposed Unvegetated Boulders/Rocks",
                path: ["exposedUnvegetatedRocks"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "channel-bed-natural-physical-features/exposed-unvegetated-rocks.jpg",
                }),
              }),
              morphChannel({
                label: "Exposed Vegetated Boulders/Rocks",
                path: ["exposedVegetatedRocks"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "channel-bed-natural-physical-features/exposed-vegetated-rocks.jpg",
                }),
              }),
              morphChannel({
                label: "Bare/Unvegetated Mid Channel Bar",
                path: ["unvegetatedMidChannelBar"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "channel-bed-natural-physical-features/unvegetated-mid-channel-bar.jpg",
                }),
                code: morphSelect({
                  secondaryLabel: "Sediment Size",
                  source: MrsSedimentSizeEnum,
                  imagePattern: "sediment-size/*.jpg",
                }),
                help: outdent`
                **Unvegetated bar:** <50% vegetation cover
                `,
              }),
              morphChannel({
                label: "Vegetated Mid Channel Bar",
                path: ["vegetatedMidChannelBar"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "channel-bed-natural-physical-features/vegetated-mid-channel-bar.jpg",
                }),
                code: morphSelect({
                  secondaryLabel: "Sediment Size",
                  source: MrsSedimentSizeEnum,
                  imagePattern: "sediment-size/*.jpg",
                }),
                help: outdent`
                **Vegetated bar:** >50% vegetation cover
                `,
              }),
              morphChannel({
                label: "Island",
                path: ["island"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "channel-bed-natural-physical-features/island.jpg",
                }),
              }),
              morphChannel({
                label: "Cascade",
                path: ["cascade"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "channel-bed-natural-physical-features/cascade.jpg",
                }),
              }),
              morphChannel({
                label: "Pool",
                path: ["pool"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image: "channel-bed-natural-physical-features/pool.jpg",
                }),
              }),
              morphChannel({
                label: "Riffle",
                path: ["riffle"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image: "channel-bed-natural-physical-features/riffle.jpg",
                }),
              }),
              morphChannel({
                label: "Step",
                path: ["step"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image: "channel-bed-natural-physical-features/step.jpg",
                }),
                help: outdent`
                Steep boulder/bedrock feature <2m high, mainly chute and free fall.
                `,
              }),
              morphChannel({
                label: "Waterfall",
                path: ["waterfall"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image: "channel-bed-natural-physical-features/waterfall.jpg",
                }),
                help: outdent`
                Steep boulder/bedrock feature >2m high, mainly free fall.
                `,
              }),
            ],
          }),
          morphSection({
            title: "4.3b Artificial Features",
            path: ["data", "channelBed", "artificialFeatures"],
            blocks: [
              morphChannel({
                label: "Large Trash",
                path: ["largeTrash"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "channel-bed-artificial-features/large-trash.jpg",
                }),
                help: outdent`
                Car parts, trolleys, traffic cones, etc.
                `,
              }),
              morphChannel({
                label: "Major Weir",
                path: ["majorWeir"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image: "channel-bed-artificial-features/major-weir.jpg",
                }),
                help: outdent`
                **Major weir:** permanent, impermeable, impounding structure across entire channel width.
                `,
              }),
              morphChannel({
                label: "Intermediate Weir",
                path: ["intermediateWeir"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image:
                    "channel-bed-artificial-features/intermediate-weir.jpg",
                }),
                help: outdent`
                **Intermediate weir:** semi-permeable, loose stone / wood structure across the entire channel width.
                `,
              }),
              morphChannel({
                label: "Minor Weir",
                path: ["minorWeir"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image: "channel-bed-artificial-features/minor-weir.jpg",
                }),
                help: outdent`
                **Minor weir:** highly permeable, transient feature across the entire channel width.
                `,
              }),
              morphChannel({
                label: "Bridge Piers in River Bed",
                path: ["bridgePiersInRiverBed"],
                abundance: morphCount({
                  image:
                    "channel-bed-artificial-features/bridge-piers-in-river-bed.jpg",
                }),
              }),
              morphChannel({
                label: "Bridge Shadow",
                path: ["bridgeShadow"],
                abundance: morphSelect({
                  source: MrsChannelCoverageEnum,
                  defaultImage:
                    "channel-bed-artificial-features/bridge-shadow.jpg",
                }),
                help: outdent`
                **Wide:** >25m channel length; **Intermediate:** 10-25m channel length; **Narrow:** <10m channel length.
                `,
              }),
              morphChannel({
                label: "Culvert",
                path: ["culvert"],
                abundance: morphCount({
                  image: "channel-bed-artificial-features/culvert.jpg",
                }),
              }),
            ],
          }),
          morphSection({
            title: "4.4a Sparse Vegetation",
            path: ["data", "channelBed", "inChannelVegetation"],
            blocks: [
              morphChannel({
                label: "Unvegetated (Bare River Bed)",
                path: ["unvegetated"],
                abundance: morphAtpeSelect({
                  defaultImage: "in-channel-vegetation/unvegetated.jpg",
                }),
              }),
              morphChannel({
                label: "Liverworts/Mosses/Lichens (Terrestrial and Aquatic)",
                path: ["liverwortsOrMossesOrLichens"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "in-channel-vegetation/liverworts-or-mosses-or-lichens.jpg",
                }),
              }),
            ],
          }),
          morphSection({
            title: "4.4b Aquatic Vegetation",
            path: ["data", "channelBed", "inChannelVegetation"],
            blocks: [
              morphChannel({
                label: "Emergent Broad-Leaved",
                path: ["emergentBroadLeaved"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "in-channel-vegetation/emergent-broad-leaved.jpg",
                }),
              }),
              morphChannel({
                label: "Emergent Reeds/Linear-Leaved/Horsetails",
                path: ["emergentReedsOrLinearLeaved"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "in-channel-vegetation/emergent-reeds-or-linear-leaved.jpg",
                }),
              }),
              morphChannel({
                label: "Floating Leaved (Rooted)",
                path: ["floatingLeaved"],
                abundance: morphAtpeSelect({
                  defaultImage: "in-channel-vegetation/floating-leaved.jpg",
                }),
              }),
              morphChannel({
                label: "Free-Floating",
                path: ["freeFloating"],
                abundance: morphAtpeSelect({
                  defaultImage: "in-channel-vegetation/free-floating.jpg",
                }),
              }),
              morphChannel({
                label: "Amphibious",
                path: ["amphibious"],
                abundance: morphAtpeSelect({
                  defaultImage: "in-channel-vegetation/amphibious.jpg",
                }),
              }),
              morphChannel({
                label: "Submerged Broad-Leaved",
                path: ["submergedBroadLeaved"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "in-channel-vegetation/submerged-broad-leaved.jpg",
                }),
              }),
              morphChannel({
                label: "Submerged Linear-Leaved",
                path: ["submergedLinearLeaved"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "in-channel-vegetation/submerged-linear-leaved.jpg",
                }),
              }),
              morphChannel({
                label: "Submerged Fine-Leaved",
                path: ["submergedFineLeaved"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "in-channel-vegetation/submerged-fine-leaved.jpg",
                }),
              }),
              morphChannel({
                label: "Filamentous Algae",
                path: ["filamentousAlgae"],
                abundance: morphAtpeSelect({
                  defaultImage: "in-channel-vegetation/filamentous-algae.jpg",
                }),
              }),
              morphChannel({
                label: "Channel Choked with Aquatic Plants?",
                path: ["channelChokedWithPlants"],
                abundance: morphCheckbox({
                  checkboxLabel: "Is the channel choked with aquatic plants?",
                  defaultValue: false,
                  image: "channel-choked-with-plants.jpg",
                  required: required("info"),
                }),
              }),
            ],
          }),
          morphSection({
            title: "4.4b Terrestrial Vegetation",
            help: outdent`
            e.g. On dry areas of the bed, mid-channel bars, or islands.

            **A/T/P/E refer to the total bed area.**
            `,
            path: ["data", "channelBed", "interactingVegetation"],
            blocks: [
              morphChannel({
                label: "Short/Creeping Herbs/Grasses",
                path: ["shortOrCreepingHerbsOrGrasses"],
                abundance: morphAtpeSelect(),
              }),
              morphChannel({
                label: "Tall Herbs/Grasses",
                path: ["tallHerbsOrGrasses"],
                abundance: morphAtpeSelect(),
              }),
              morphChannel({
                label: "Scrub or Shrubs",
                path: ["scrubOrShrubs"],
                abundance: morphAtpeSelect(),
              }),
              morphChannel({
                label: "Saplings or Trees",
                path: ["saplingsOrTrees"],
                abundance: morphAtpeSelect(),
              }),
              morphChannel({
                label: "Vegetation Shading Channel",
                path: ["vegetationShadingChannel"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "interacting-vegetation/vegetation-shading-channel.jpg",
                }),
              }),
              morphChannel({
                label: "Submerged Tree Roots",
                path: ["submergedTreeRoots"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "interacting-vegetation/submerged-tree-roots.jpg",
                }),
              }),
              morphChannel({
                label: "Trees/Shrubs/Saplings",
                path: ["treesOrShrubsOrSaplingsOnBed"],
                abundance: morphAtpeSelect({
                  defaultImage:
                    "interacting-vegetation/trees-or-shrubs-or-saplings-on-bed.jpg",
                }),
                help: outdent`
                Growing from the submerged river bed, not on vegetated bars/islands.
                `,
              }),
              morphChannel({
                label: "Large Wood In Channel",
                path: ["largeWood"],
                abundance: morphAtpeSelect({
                  defaultImage: "interacting-vegetation/large-wood.jpg",
                }),
                help: outdent`
                >1m length, >10cm diameter
                `,
              }),
              morphChannel({
                label: "Discrete Accumulations of Organic Material",
                path: ["discreteOrganicMaterial"],
                abundance: morphAtpeSelect(),
                help: outdent`
                e.g. twigs, leaves
                `,
              }),
              morphChannel({
                label: "Large Wood Dam",
                path: ["largeWoodDam"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image: "interacting-vegetation/large-wood-dam.jpg",
                }),
                help: outdent`
                Crosses entire width of the channel bed.
                `,
              }),
              morphChannel({
                label: "Fallen Trees",
                path: ["fallenTree"],
                abundance: morphCount({
                  secondaryLabel: "Count",
                  image: "interacting-vegetation/fallen-tree.jpg",
                }),
                help: outdent`
                **Only** those with a significant proportion in the channel.
                `,
              }),
            ],
          }),
          nuisancePlantSpeciesCategory({
            title: "4.3c Non-Native Invasive Plants",
            path: ["data", "channelBed", "nuisancePlantSpecies"],
            useBanks: false,
          }),
          notesSection(["data", "channelBed", "notes"]),
        ],
      }),
    ],
  });
}

export default mrsMorphForm("MoRPh");
