import { Option, Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  checkedToResult,
  isChecked,
  isSearchResults,
  isSurveySummary,
  isSurvey,
  Message,
  SearchResults,
  SurveyId,
  SurveyModuleId,
  SurveyStatus,
  SurveySummary,
  Survey,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs, optionalContentAs } from "../../response";
import { UrlParts, UrlQuery } from "../../url";
import { PartialParams } from "../params";
import { formatPointParam, SurveySearchOptionsV3 } from "./common";

const basePath = "/survey/v3";

export function surveySearchQueryParamsV3(
  module: SurveyModuleId,
  options: PartialParams<SurveySearchOptionsV3>
): UrlQuery {
  return {
    module,
    workspace: options.workspace,
    project: options.project,
    team: options.team,
    q: options.q,
    from: options.from ?? undefined,
    to: options.to ?? undefined,
    status: options.status,
    sw: options.sw == null ? undefined : formatPointParam(options.sw),
    ne: options.ne == null ? undefined : formatPointParam(options.ne),
    skip: options.skip,
    limit: options.limit,
    order: options.order,
    format: options.format ?? "json",
  };
}

export function search(
  apiParams: ApiParams,
  module: SurveyModuleId,
  options: PartialParams<SurveySearchOptionsV3> = {}
): IO<SearchResults<Survey>> {
  return fetch
    .get({ apiParams, url: searchUrl(module, options) })
    .chain(contentAs("SearchResults<Survey>", isSearchResults(isSurvey)));
}

export function searchUrl(
  module: SurveyModuleId,
  options: PartialParams<SurveySearchOptionsV3> = {}
): UrlParts {
  return {
    path: basePath,
    query: surveySearchQueryParamsV3(module, options),
  };
}

export function searchSummaries(
  apiParams: ApiParams,
  module: SurveyModuleId,
  options: PartialParams<SurveySearchOptionsV3> = {}
): IO<SearchResults<SurveySummary>> {
  const url: UrlParts = {
    path: `${basePath}/summary`,
    query: surveySearchQueryParamsV3(module, options),
  };

  return fetch
    .get({ apiParams, url })
    .chain(
      contentAs(
        "SearchResults<SurveySummary>",
        isSearchResults(isSurveySummary)
      )
    );
}

export function readOrFail(
  apiParams: ApiParams,
  survey: SurveyId
): IO<Survey> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${survey}` } })
    .flatMap(contentAs("Survey", isSurvey));
}

export function readOption(
  apiParams: ApiParams,
  survey: SurveyId
): IO<Option<Survey>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${survey}` } })
    .flatMap(optionalContentAs("Survey", isSurvey));
}

interface SurveySaveOptions {
  strict: boolean;
  temporary: boolean;
  dryRun: boolean;
  notify: boolean;
}

export function save(
  apiParams: ApiParams,
  survey: Survey,
  options: PartialParams<SurveySaveOptions> = {}
): IO<Result<Message[], Survey>> {
  const url = {
    path: `${basePath}/${survey.id}`,
    query: {
      strict: options.strict,
      temporary: options.temporary,
      dryrun: options.dryRun,
      notify: options.notify,
    },
  };

  return fetch
    .put({ apiParams, url, body: survey })
    .flatMap(contentAs("Checked<Survey>", isChecked(isSurvey)))
    .map(checkedToResult);
}

interface SurveySaveStatusOptions {
  notify: boolean;
}

export function saveStatus(
  apiParams: ApiParams,
  survey: SurveyId,
  status: SurveyStatus,
  options: PartialParams<SurveySaveStatusOptions> = {}
): IO<Result<Message[], Survey>> {
  const url = { path: `${basePath}/${survey}/status` };

  return fetch
    .put({ apiParams, url, body: { status, notify: options.notify } })
    .flatMap(contentAs("Checked<Survey>", isChecked(isSurvey)))
    .map(checkedToResult);
}

export function remove(apiParams: ApiParams, survey: SurveyId): IO<void> {
  const url = {
    path: `${basePath}/${survey}`,
  };

  return fetch.remove({ apiParams, url }).void();
}

export function refreshMetadata(
  apiParams: ApiParams,
  module?: SurveyModuleId
): IO<void> {
  const url = {
    path: `${basePath}/refresh-metadata`,
    query: { module },
  };

  return fetch.get({ apiParams, url }).void();
}
