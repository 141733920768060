import { isBefore } from "date-fns";
import {
  Email,
  Timestamp,
  emailDomain,
  nowTimestamp,
  timestampEpoch,
  timestampToDate,
} from "../core";
import {
  Invitation,
  InvitationCode,
  InvitationStatus,
  InvitationCodeStatus,
} from "./types.generated";

export function invitationStatus(
  invitation: Invitation,
  timestamp: Timestamp = nowTimestamp()
): InvitationStatus {
  if (invitation.accepted != null) {
    return "Accepted";
  } else if (invitation.canceled != null) {
    return "Canceled";
  } else if (
    invitation.expires != null &&
    isBefore(timestampToDate(invitation.expires), timestampToDate(timestamp))
  ) {
    return "Expired";
  } else {
    return "Pending";
  }
}

export function invitationCodeStatus(
  invitation: InvitationCode,
  timestamp: Timestamp = nowTimestamp()
): InvitationCodeStatus {
  if (invitation.canceled != null) {
    return "Canceled";
  } else if (
    invitation.expires != null &&
    isBefore(timestampToDate(invitation.expires), timestampToDate(timestamp))
  ) {
    return "Expired";
  } else {
    return "Pending";
  }
}

export function canCancelInvitation(invitation: Invitation): boolean {
  return (
    invitation.accepted == null &&
    invitation.canceled == null &&
    (invitation.expires == null ||
      timestampEpoch(invitation.expires) >= timestampEpoch(nowTimestamp()))
  );
}

export function canCancelInvitationCode(invitation: InvitationCode): boolean {
  return (
    invitation.canceled == null &&
    (invitation.expires == null ||
      timestampEpoch(invitation.expires) >= timestampEpoch(nowTimestamp()))
  );
}

export function canResendInvitation(invitation: Invitation): boolean {
  return invitation.accepted == null;
}

export function invitationCodeRequiresApproval(
  code: InvitationCode,
  email: Email
): boolean {
  const domain = emailDomain(email);

  if (domain == null || code.approvalDomainAllowList == null) {
    return code.requireApproval;
  } else {
    return (
      code.requireApproval && !code.approvalDomainAllowList.includes(domain)
    );
  }
}
