// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankTopTreeFeature =
  | "leaningTrees"
  | "fallenTrees"
  | "trailingTreesOrShrubs"
  | "largeWood";

const LeaningTrees: CesBankTopTreeFeature = "leaningTrees";
const FallenTrees: CesBankTopTreeFeature = "fallenTrees";
const TrailingTreesOrShrubs: CesBankTopTreeFeature = "trailingTreesOrShrubs";
const LargeWood: CesBankTopTreeFeature = "largeWood";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankTopTreeFeatureEnum = {
  ...createEnum("CesBankTopTreeFeature", [
    [LeaningTrees, "Leaning trees"],
    [FallenTrees, "Fallen trees"],
    [TrailingTreesOrShrubs, "Tree/shrub branches trailing into canal"],
    [LargeWood, "Large wood (>1m long, >10cm diam)"],
  ]),
  LeaningTrees,
  FallenTrees,
  TrailingTreesOrShrubs,
  LargeWood,
};

export default CesBankTopTreeFeatureEnum;
