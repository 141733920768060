// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusPollutionType =
  | "airPollution"
  | "noisePollution"
  | "waterPollution";

const AirPollution: EcostatusPollutionType = "airPollution";
const NoisePollution: EcostatusPollutionType = "noisePollution";
const WaterPollution: EcostatusPollutionType = "waterPollution";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusPollutionTypeEnum = {
  ...createEnum("EcostatusPollutionType", [
    [AirPollution, "Air pollution"],
    [NoisePollution, "Noise pollution"],
    [
      WaterPollution,
      "Signs of water pollution (low water clarity, oils, odours, litter, etc)",
    ],
  ]),
  AirPollution,
  NoisePollution,
  WaterPollution,
};

export default EcostatusPollutionTypeEnum;
