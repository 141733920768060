// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusNfmCategory =
  | "VegetationCover"
  | "InfiltrationEnhancement"
  | "AttenuationOfRunoff"
  | "AttenuationOfChannelFlow"
  | "ChannelFloodplainConnectivity";

const VegetationCover: EcostatusNfmCategory = "VegetationCover";
const InfiltrationEnhancement: EcostatusNfmCategory = "InfiltrationEnhancement";
const AttenuationOfRunoff: EcostatusNfmCategory = "AttenuationOfRunoff";
const AttenuationOfChannelFlow: EcostatusNfmCategory =
  "AttenuationOfChannelFlow";
const ChannelFloodplainConnectivity: EcostatusNfmCategory =
  "ChannelFloodplainConnectivity";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusNfmCategoryEnum = {
  ...createEnum("EcostatusNfmCategory", [
    [VegetationCover, "Vegetation cover"],
    [InfiltrationEnhancement, "Infiltration enhancement"],
    [AttenuationOfRunoff, "Attenuation of runoff"],
    [AttenuationOfChannelFlow, "Attenuation of channel flow"],
    [ChannelFloodplainConnectivity, "Channel-floodplain connectivity"],
  ]),
  VegetationCover,
  InfiltrationEnhancement,
  AttenuationOfRunoff,
  AttenuationOfChannelFlow,
  ChannelFloodplainConnectivity,
};

export default EcostatusNfmCategoryEnum;
