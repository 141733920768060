import { checkExhausted } from "@cartographerio/util";
import { SurveyStatusEnum } from "./enums/index.generated";
import { SurveyStatus } from "./types.generated";

export function surveyIsLocked(status: SurveyStatus): boolean {
  switch (status) {
    case SurveyStatusEnum.Draft:
    case SurveyStatusEnum.Complete:
    case SurveyStatusEnum.Review:
      return false;
    case SurveyStatusEnum.Approved:
    case SurveyStatusEnum.Rejected:
      return true;
    default:
      return checkExhausted(status);
  }
}
