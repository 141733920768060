import { PremiumV1 } from "@cartographerio/pricing";
import {
  BillingInterval,
  PlanLimits,
  PricingScheme,
  unsafeCurrencyAmount,
} from "@cartographerio/types";
import { Box, Card, CardBody, VStack } from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import { useIOAlert } from "../components/Alert";
import Button from "../components/Button";
import CallUsLink from "../components/CallUsLink";
import EmailUsLink from "../components/EmailUsLink";
import Heading from "../components/Heading";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import PlanFeatures from "./PlanFeatures";
import { ContactUsPriceLabel } from "./PriceLabel";

const pricingScheme: PricingScheme = {
  type: "PerProject",
  flatAmount: unsafeCurrencyAmount(0),
  unitAmount: unsafeCurrencyAmount(0),
  inclusiveUnits: 0,
};

const limits: PlanLimits = {
  billingType: null,
  maxProjects: null,
  maxUsers: null,
  features: "Premium",
};

export interface ContactUsPlanColumnProps {
  billingInterval: BillingInterval;
}

export default function ContactUsPlanColumn({
  billingInterval,
}: ContactUsPlanColumnProps): ReactElement {
  const alert = useIOAlert();

  const showContactLinks = useCallback(
    () =>
      alert({
        title: "Contact Us",
        message: (
          <Spaced>
            <Para>Please get in touch to discuss premium plans:</Para>
            <VStack align="center">
              <CallUsLink />
              <EmailUsLink />
            </VStack>
          </Spaced>
        ),
      }).unsafeRun(),
    [alert]
  );

  return (
    <Card
      as="section"
      shadow="lg"
      border="1px solid"
      borderColor="gray.200"
      w={["100%", "25ch"]}
      minW="28ch"
    >
      <CardBody>
        <Spaced spacing="6" pb="2">
          <Heading
            level="section"
            textAlign="center"
            fontSize="md"
            color="orange.500"
          >
            Custom
          </Heading>
          <Box
            textAlign="center"
            fontSize="2xl"
            fontWeight="semibold"
            color="black"
          >
            <ContactUsPriceLabel />
          </Box>
          <Para textAlign="center">
            <Button label="Contact Us" onClick={showContactLinks} />
          </Para>
          <PlanFeatures
            pricingPlan={PremiumV1}
            pricingScheme={pricingScheme}
            limits={limits}
            billingInterval={billingInterval}
            premium={true}
          />
        </Spaced>
      </CardBody>
    </Card>
  );
}
