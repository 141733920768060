import { Env } from "@cartographerio/topo-core";
import { Result } from "@cartographerio/fp";
import { isArray, isString } from "@cartographerio/guard";
import {
  Timestamp,
  formatTimestamp,
  iso8601TimestampFormat,
  nowTimestamp,
  utc,
  yyyymmdd,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { TopoError, TopoExpr } from "../expr";
import { topoEval } from "../expr/run";
import { RequiredRuleField } from "../type";

export function requiredRulePasses(
  fieldType: RequiredRuleField["type"],
  value: unknown
): boolean {
  switch (fieldType) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
      return isString(value) ? value !== "" : value != null;

    case "IntegerField":
    case "NumberField":
    case "UserRefField":
    case "TimestampField":
    case "LineStringField":
    case "PolygonField":
    case "PointField":
    case "TeamField":
    case "FeatureField":
    case "NearestFeatureField":
    case "Select":
      return value != null;

    case "MultiSelect":
      return isArray(value) ? value.length > 0 : value !== null;

    default:
      return checkExhausted(fieldType);
  }
}

export function customRulePasses(
  expr: TopoExpr<boolean>,
  env: Env
): Result<TopoError, boolean> {
  return topoEval(expr, env);
}

function optCompare<T>(
  value: T | null | undefined,
  comparator: (value: T) => boolean,
  orElse: boolean = true
): boolean {
  return value == null ? orElse : comparator(value);
}

export function boundsRulePasses(
  minValue: number | undefined,
  maxValue: number | undefined,
  value: number | null | undefined
): boolean {
  return (
    value == null ||
    (optCompare(minValue, minValue => value >= minValue) &&
      optCompare(maxValue, maxValue => value <= maxValue))
  );
}

export function timestampBoundsRulePasses(
  minValue: Timestamp | "now" | undefined,
  maxValue: Timestamp | "now" | undefined,
  value: Timestamp | null | undefined,
  validateTime: boolean,
  now: Timestamp = nowTimestamp()
): boolean {
  const valueOf = (raw: Timestamp | "now") =>
    formatTimestamp(raw === "now" ? now : raw, {
      format: validateTime ? iso8601TimestampFormat : yyyymmdd,
      timeZone: utc,
    });

  return (
    value == null ||
    (optCompare(minValue, minValue => valueOf(value) >= valueOf(minValue)) &&
      optCompare(maxValue, maxValue => valueOf(value) <= valueOf(maxValue)))
  );
}
