import { pointSchema } from ".";
import {
  geometrySchema,
  nullableSchema,
  productSchema,
  stringSchema,
  sumSchema,
  unknownSchema,
  uuidSchema,
} from "./create";
import {
  isGeometrySchema,
  isLabeledSchema,
  isNullableSchema,
  isProductSchema,
  isStringSchema,
  isUuidSchema,
  productHasField,
  SchemaPredicate,
} from "./guard";
import { GeometrySchema, ProductSchema, Schema } from "./type";

export function labeledSchema(type: string, schema: ProductSchema): Schema {
  return sumSchema({ [type]: schema });
}

export const userRefSchema: Schema = productSchema({
  screenName: nullableSchema(stringSchema),
  userId: nullableSchema(uuidSchema),
});

export function isUserRefSchema(schema: Schema): boolean {
  return (
    isProductSchema(schema) &&
    productHasField(schema, "screenName", isNullableSchema(isStringSchema)) &&
    productHasField(schema, "userId", isNullableSchema(isUuidSchema))
  );
}

export const attachmentFolderSchema: Schema = productSchema({
  folder: uuidSchema,
});

export function isAttachmentFolderSchema(schema: Schema): boolean {
  return (
    isProductSchema(schema) && productHasField(schema, "folder", isUuidSchema)
  );
}

export function featureSchema(
  properties: Schema = unknownSchema,
  geometry: GeometrySchema = geometrySchema()
): Schema {
  return labeledSchema(
    "Feature",
    productSchema({
      geometry,
      properties,
      id: nullableSchema(stringSchema),
    })
  );
}

export function isFeatureSchema(
  isPropertiesSchema: (s: Schema) => boolean = isProductSchema,
  isFeatureGeometrySchema: (s: Schema) => boolean = isGeometrySchema()
): SchemaPredicate {
  return isLabeledSchema(
    "Feature",
    schema =>
      productHasField(schema, "geometry", isFeatureGeometrySchema) &&
      productHasField(schema, "properties", isPropertiesSchema) &&
      productHasField(schema, "id", isNullableSchema(isStringSchema))
  );
}

export function pickedSchema(
  properties: Schema = unknownSchema,
  geometry: GeometrySchema = geometrySchema()
): Schema {
  return productSchema({
    location: pointSchema,
    data: nullableSchema(featureSchema(properties, geometry)),
  });
}

export function isPickedSchema(
  isPropertiesSchema: (s: Schema) => boolean = isProductSchema,
  isFeatureGeometrySchema: (s: Schema) => boolean = isGeometrySchema()
): SchemaPredicate {
  return (schema: Schema): boolean =>
    isProductSchema(schema) &&
    productHasField(schema, "point", isGeometrySchema("Point")) &&
    productHasField(
      schema,
      "feature",
      isNullableSchema(
        isFeatureSchema(isPropertiesSchema, isFeatureGeometrySchema)
      )
    );
}
