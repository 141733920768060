import { Geometry } from "geojson";
import { GeometryAtom } from "./type";
import { checkExhausted } from "@cartographerio/util";

export function flattenGeometry(geom: Geometry): GeometryAtom[] {
  switch (geom.type) {
    case "GeometryCollection":
      return geom.geometries.flatMap(flattenGeometry);
    case "Point":
    case "MultiPoint":
    case "LineString":
    case "MultiLineString":
    case "Polygon":
    case "MultiPolygon":
      return [geom];
    default:
      return checkExhausted(geom);
  }
}
