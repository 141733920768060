import { IO } from "@cartographerio/io";
import {
  ApiParams,
  ProjectBreadcrumb,
  ProjectBreadcrumbId,
  SearchResults,
  isProjectBreadcrumb,
  isSearchResults,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";
import { PartialParams } from "../../params";
import { ProjectBreadcrumbSearchOptions } from "./common";

const basePath = "/project/breadcrumb/v1";

export function search(
  apiParams: ApiParams,
  options: PartialParams<ProjectBreadcrumbSearchOptions> = {}
): IO<SearchResults<ProjectBreadcrumb>> {
  return fetch
    .get({ apiParams, url: { path: basePath, query: { ...options } } })
    .flatMap(
      contentAs(
        "SearchResults<ProjectBreadcrumb>",
        isSearchResults(isProjectBreadcrumb)
      )
    );
}

export function remove(
  apiParams: ApiParams,
  id: ProjectBreadcrumbId
): IO<void> {
  return fetch.remove({ apiParams, url: { path: `${basePath}/${id}` } }).void();
}
