import { checks } from "@cartographerio/permission";
import {
  Workspace,
  WorkspaceBillingSettings,
  WorkspaceBillingSummary,
  WorkspaceLimits,
  WorkspaceUsage,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { FormControl, Stack, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import BillingDisabled from "../../billing/BillingDisabled";
import CheckoutSuccessPanel from "../../billing/CheckoutSuccessPanel";
import WorkspaceBillingSettingsEditor from "../../billing/WorkspaceBillingSettingsEditor";
import WorkspaceSubscribedView from "../../billing/WorkspaceSubscribedView";
import WorkspaceUnsubscribedView from "../../billing/WorkspaceUnsubscribedView";
import AdminContent from "../../components/AdminContent";
import AdminNoteEditor from "../../components/AdminNoteEditor";
import FormLabel from "../../components/FormLabel";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import { useAdminContentVisible } from "../../hooks/useAdminContentVisible";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";
import { StripeCheckoutResult } from "../../routes/queryParams";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceBillingSettingsPage(
  props: RouteProps<typeof routes.workspace.billing.settings>
): ReactElement {
  const {
    path: { workspaceRef },
    query: { checkout },
    updateQuery,
  } = props;
  updateQuery;

  const queryClient = useQueryClient();
  const apiParams = useApiParams();
  const toast = useToast();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const summary = useSuspenseQueryData(
    queries.billing.summary.v1.readOrFail(apiParams, workspaceRef)
  );

  const limits = useSuspenseQueryData(
    queries.workspace.limits.v1.readOrFail(apiParams, workspaceRef)
  );

  const usage = useSuspenseQueryData(
    queries.workspace.usage.v1.readOrFail(apiParams, workspaceRef)
  );

  const clearQuery = useCallback(() => {
    updateQuery({ checkout: null }, { replace: true });
  }, [updateQuery]);

  useEffectOnce(() => {
    if (checkout === "success") {
      toast({
        title: "Checkout successful",
        status: "success",
        isClosable: true,
      });
    } else if (checkout === "cancel") {
      toast({
        title: "Checkout cancelled",
        status: "warning",
        isClosable: true,
      });

      clearQuery();
    }
  });

  useRequirePermission(checks.billing.workspace(workspace.id));
  usePageTitle(`Billing - ${workspace.name}`);

  const onSettingsChange = useCallback(
    (updates: WorkspaceBillingSettings) => {
      queries.billing.settings.v1
        .update(queryClient, apiParams, workspace.id, updates)
        .tap(() => toast({ colorScheme: "green", title: "Settings saved" }))
        .tapError(() =>
          toast({ colorScheme: "red", title: "Could not save settings" })
        )
        .unsafeRun();
    },
    [apiParams, queryClient, toast, workspace.id]
  );

  const [adminContentVisible] = useAdminContentVisible();

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="billing" />
      <WorkspacePageHeader workspace={workspace} selected="billing" />
      <PageContainer width={adminContentVisible ? "wide" : "narrow"}>
        <Stack direction={["column", "column", "row"]} gap="8">
          <MainSummaryPanel
            workspace={workspace}
            limits={limits}
            usage={usage}
            summary={summary}
            checkout={checkout}
            clearQuery={clearQuery}
          />

          <AdminContent
            appearance="compact"
            isSuperuser={true}
            w={["100%", "40ch"]}
          >
            <Spaced spacing="4">
              <FormControl>
                <FormLabel text="Admin Notes" />
                <AdminNoteEditor workspaceRef={workspaceRef} />
              </FormControl>

              <WorkspaceBillingSettingsEditor
                value={summary.settings}
                onChange={onSettingsChange}
              />
            </Spaced>
          </AdminContent>
        </Stack>
      </PageContainer>
    </>
  );
}

interface MainSummaryPanelProps {
  workspace: Workspace;
  limits: WorkspaceLimits;
  usage: WorkspaceUsage;
  summary: WorkspaceBillingSummary;
  checkout: StripeCheckoutResult | null;
  clearQuery: () => void;
}

function MainSummaryPanel(props: MainSummaryPanelProps) {
  const { workspace, limits, usage, summary, checkout, clearQuery } = props;

  if (!summary.settings.enableBilling) {
    return <BillingDisabled />;
  } else {
    switch (summary.type) {
      case "WorkspaceUnsubscribed":
        return summary.settings.billingType === "Free" ? (
          <BillingDisabled />
        ) : checkout === "success" ? (
          <CheckoutSuccessPanel workspace={workspace} clearQuery={clearQuery} />
        ) : (
          <WorkspaceUnsubscribedView
            summary={summary}
            limits={limits}
            usage={usage}
          />
        );

      case "WorkspaceSubscribed":
        return (
          <WorkspaceSubscribedView
            summary={summary}
            limits={limits}
            usage={usage}
          />
        );

      default:
        return checkExhausted(summary);
    }
  }
}
