// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverTypesFeature =
  | "H3260"
  | "Shingle"
  | "Bedrock"
  | "C22a"
  | "C22b"
  | "C23"
  | "Tidal"
  | "Temporary";

const H3260: NeRiverTypesFeature = "H3260";
const Shingle: NeRiverTypesFeature = "Shingle";
const Bedrock: NeRiverTypesFeature = "Bedrock";
const C22a: NeRiverTypesFeature = "C22a";
const C22b: NeRiverTypesFeature = "C22b";
const C23: NeRiverTypesFeature = "C23";
const Tidal: NeRiverTypesFeature = "Tidal";
const Temporary: NeRiverTypesFeature = "Temporary";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverTypesFeatureEnum = {
  ...createEnum("NeRiverTypesFeature", [
    [H3260, "Habitats Directive type H3260"],
    [Shingle, "Active Shingle Sections"],
    [Bedrock, "Bedrock Channel"],
    [C22a, "European Red List type C2.2a (high energy)"],
    [C22b, "European Red List type C2.2b (moderate energy)"],
    [C23, "European Red List type C2.3 (low energy)"],
    [Tidal, "Tidally Influenced"],
    [Temporary, "Temporary Flow"],
  ]),
  H3260,
  Shingle,
  Bedrock,
  C22a,
  C22b,
  C23,
  Tidal,
  Temporary,
};

export default NeRiverTypesFeatureEnum;
