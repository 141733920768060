import {
  attributeGroup,
  booleanAttr,
  lookupBucket,
  MapId,
  MapSchema,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  MrsRtaConditionClassEnum,
  MrsRtaLevelOfConfinementEnum,
} from "@cartographerio/inventory-enums";
import { SurveyModuleId, unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import {
  moduleNumbersAttr,
  particleSizeAttr,
  riverCategoryAttr,
  riverTypeAttr,
  surveyTypeAttr,
} from "./helpers";

function riverConditionMap(
  mapId: MapId,
  title: string,
  module: SurveyModuleId
): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,
    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId as string),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId as string),
          attribution: [],
        }),
        defaultAttribute: "finalConditionClass",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "MoRPh5 Survey Details",
            attributes: [
              stringAttr({
                attributeId: "multiMorphRiverName",
                label: "River",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "multiMorphReachName",
                label: "Reach",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "subreachName",
                label: "Subreach",
                buckets: "auto",
              }),
              moduleNumbersAttr,
              stringAttr({
                attributeId: "wfdWaterBodyId",
                label: "WFD Water Body ID",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectName",
                label: "Project Name",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectCode",
                label: "MoRPh Correlation Code",
                buckets: "auto",
              }),
              surveyTypeAttr,
              teamAttr("teamId", "Team", { module }),
              surveyAttr("surveyId", "Survey"),
              stringAttr({
                attributeId: "scenarioName",
                label: "Scenario",
                buckets: "auto",
              }),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
            ],
          }),
          attributeGroup({
            label: "River Type Survey Details",
            attributes: [
              stringAttr({
                attributeId: "riverName",
                label: "River",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "reachName",
                label: "Reach",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectCode",
                label: "MoRPh Correlation Code",
                buckets: "auto",
              }),
            ],
          }),
          attributeGroup({
            label: "River Condition",
            attributes: [
              numberAttr({
                attributeId: "preliminaryConditionScore",
                label: "Condition Score (Preliminary)",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(-5, -4, marker.quality(0.0)),
                  rangeBucket(-4, -3, marker.quality(0.1)),
                  rangeBucket(-3, -2, marker.quality(0.2)),
                  rangeBucket(-2, -1, marker.quality(0.3)),
                  rangeBucket(-1, 0, marker.quality(0.4)),
                  rangeBucket(0, 1, marker.quality(0.5)),
                  rangeBucket(1, 2, marker.quality(0.6)),
                  rangeBucket(2, 3, marker.quality(0.7)),
                  rangeBucket(3, 4, marker.quality(0.8)),
                  rangeBucket(4, 5, marker.quality(0.9)),
                ],
              }),
              stringAttr({
                attributeId: "finalConditionClass",
                label: "Condition Class / Score (Final)",
                buckets: [
                  lookupBucket(
                    MrsRtaConditionClassEnum.Good,
                    marker.quality(1.0),
                    "Good / 5"
                  ),
                  lookupBucket(
                    MrsRtaConditionClassEnum.FairlyGood,
                    marker.quality(0.75),
                    "Fairly good / 4"
                  ),
                  lookupBucket(
                    MrsRtaConditionClassEnum.Moderate,
                    marker.quality(0.5),
                    "Moderate / 3"
                  ),
                  lookupBucket(
                    MrsRtaConditionClassEnum.FairlyPoor,
                    marker.quality(0.25),
                    "Fairly poor / 2"
                  ),
                  lookupBucket(
                    MrsRtaConditionClassEnum.Poor,
                    marker.quality(0.0),
                    "Poor / 1"
                  ),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Dimensions",
            attributes: [
              numberAttr({
                attributeId: "riverShape",
                label: "River Shape",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(0.0, 0.5, marker.numeric(0 / 6)),
                  rangeBucket(0.5, 1.0, marker.numeric(1 / 6)),
                  rangeBucket(1.0, 1.5, marker.numeric(2 / 6)),
                  rangeBucket(1.5, 2.0, marker.numeric(3 / 6)),
                  rangeBucket(2.0, 5.0, marker.numeric(4 / 6)),
                  rangeBucket(5.0, 10.0, marker.numeric(5 / 6)),
                  rangeBucket(10.0, null, marker.numeric(6 / 6)),
                ],
              }),
              numberAttr({
                attributeId: "averageWidth",
                label: "Average Width",
                unit: "m",
                decimalPlaces: 2,
                buckets: [
                  rangeBucket(0.0, 1.0, marker.numeric(0 / 6)),
                  rangeBucket(1.0, 2.0, marker.numeric(1 / 6)),
                  rangeBucket(2.0, 3.0, marker.numeric(2 / 6)),
                  rangeBucket(3.0, 5.0, marker.numeric(3 / 6)),
                  rangeBucket(5.0, 10.0, marker.numeric(4 / 6)),
                  rangeBucket(10.0, 20.0, marker.numeric(5 / 6)),
                  rangeBucket(20.0, null, marker.numeric(6 / 6)),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Calculations",
            attributes: [
              riverCategoryAttr("riverCategory", "River Category"),
              numberAttr({
                attributeId: "a1",
                label: "A1: Braiding Index",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(null, 1.1, marker.quality(6 / 12)),
                  rangeBucket(1.1, 1.5, marker.quality(5 / 12)),
                  rangeBucket(1.5, null, marker.quality(4 / 12)),
                ],
              }),
              numberAttr({
                attributeId: "a2",
                label: "A2: Sinuosity Index",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(null, 1.05, marker.quality(6 / 12)),
                  rangeBucket(1.05, 1.5, marker.quality(5 / 12)),
                  rangeBucket(1.5, null, marker.quality(4 / 12)),
                ],
              }),
              numberAttr({
                attributeId: "a3",
                label: "A3: Anabranching Index",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(null, 1.05, marker.quality(6 / 12)),
                  rangeBucket(1.05, 1.5, marker.quality(5 / 12)),
                  rangeBucket(1.5, null, marker.quality(4 / 12)),
                ],
              }),
              stringAttr({
                attributeId: "a4",
                label: "A4: Level of Confinement",
                buckets: [
                  lookupBucket(
                    MrsRtaLevelOfConfinementEnum.Confined,
                    marker.quality(4 / 12),
                    "Confined"
                  ),
                  lookupBucket(
                    MrsRtaLevelOfConfinementEnum.PartlyConfined,
                    marker.quality(5 / 12),
                    "Partly confined"
                  ),
                  lookupBucket(
                    MrsRtaLevelOfConfinementEnum.Unconfined,
                    marker.quality(6 / 12),
                    "Unconfined"
                  ),
                ],
              }),
              numberAttr({
                attributeId: "a5",
                label: "A5: Reach Valley Gradient",
                decimalPlaces: 5,
                unit: "m/m",
                buckets: [
                  rangeBucket(null, 0.01, marker.quality(4 / 12)),
                  rangeBucket(0.01, null, marker.quality(6 / 12)),
                ],
              }),
              booleanAttr({
                attributeId: "a6",
                label: "A6: Bedrock Reach?",
                buckets: "auto",
              }),
              particleSizeAttr("a7", "A7: Coarsest Bed Material", true),
              particleSizeAttr("a8", "A8: Average Bed Material", false),
              riverTypeAttr("finalRiverType", "River Type"),
            ],
          }),
        ],
      }),
    ],
  });
}

export const MrsRiverCondition = riverConditionMap(
  unsafeMapId("mrsRiverCondition"),
  "River Condition",
  unsafeSurveyModuleId("mrsRiverCondition")
);

export const MrsRiverConditionPro = riverConditionMap(
  unsafeMapId("mrsRiverConditionPro"),
  "River Condition",
  unsafeSurveyModuleId("mrsRiverConditionPro")
);
