import { Path } from "@cartographerio/topo-core";
import {
  Block,
  checkbox,
  columns2,
  Field,
  integerField,
  minValue,
  select,
  SelectValue,
  subsubsection,
  textField,
  vstack,
} from "@cartographerio/topo-form";
import { checkExhausted } from "@cartographerio/util";
import {
  enumImages,
  fieldImage,
  morphApeSelect,
  morphAtpeSelect,
  morphCount,
  MorphFieldProps,
  MorphFieldsProps,
  morphSelect,
  morphText,
} from "../MrsMorph/formHelpers";

export { morphApeSelect, morphAtpeSelect, morphCount, morphSelect, morphText };

interface BanksSubsubsectionProps<A extends SelectValue, B extends SelectValue>
  extends MorphFieldsProps<A, B> {
  sectionTitle?: string | null;
  fieldOrder?: "code-then-abundance" | "abundance-then-code";
}

export function banksSubsubsection<
  A extends SelectValue,
  B extends SelectValue,
>(props: BanksSubsubsectionProps<A, B>): Block {
  const {
    label,
    sectionTitle = label,
    path,
    fieldOrder = "code-then-abundance",
    help,
  } = props;

  function subfield(
    fieldPath: Path,
    field: MorphFieldProps,
    defaultSecondaryLabel: string = ""
  ): Field[] {
    switch (field.type) {
      case "select":
        return [
          select({
            label: `Left Bank ${field.secondaryLabel ?? defaultSecondaryLabel}`,
            path: [...path, ...fieldPath, "leftBank"],
            options: enumImages(field.source, field?.imagePattern),
            showValues: field.showValues,
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            image: fieldImage(field.defaultImage),
          }),
          select({
            label: `Right Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "rightBank"],
            showValues: field.showValues,
            options: enumImages(field.source, field?.imagePattern),
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            image: fieldImage(field.defaultImage),
          }),
        ];

      case "number":
        return [
          integerField({
            label: `Left Bank ${field.secondaryLabel ?? defaultSecondaryLabel}`,
            path: [...path, ...fieldPath, "leftBank"],
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            bounds: minValue(0, "error"),
            units: field.units,
            image: fieldImage(field.image),
          }),
          integerField({
            label: `Right Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "rightBank"],
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            units: field.units,
            bounds: minValue(0, "error"),
            image: fieldImage(field.image),
          }),
        ];

      case "text":
        return [
          textField({
            label: `Left Bank ${field.secondaryLabel ?? defaultSecondaryLabel}`,
            path: [...path, ...fieldPath, "leftBank"],
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            image: fieldImage(field.image),
          }),
          textField({
            label: `Right Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            path: [...path, ...fieldPath, "rightBank"],
            required: field.required === false ? undefined : field.required,
            defaultValue: field.defaultValue,
            image: fieldImage(field.image),
          }),
        ];

      case "checkbox":
        return [
          checkbox({
            label: `Left Bank ${field.secondaryLabel ?? defaultSecondaryLabel}`,
            checkboxLabel: field.checkboxLabel,
            path: [...path, ...fieldPath, "leftBank"],
            defaultValue: field.defaultValue,
          }),
          checkbox({
            label: `Right Bank ${
              field.secondaryLabel ?? defaultSecondaryLabel
            }`,
            checkboxLabel: field.checkboxLabel,
            path: [...path, ...fieldPath, "rightBank"],
            defaultValue: field.defaultValue,
          }),
        ];

      default:
        return checkExhausted(field);
    }
  }

  const codeFields = props.code != null ? subfield(["code"], props.code) : [];

  const abundanceFields =
    props.abundance != null
      ? subfield(["abundance"], props.abundance, "Abundance")
      : [];

  const fieldFields = props.field != null ? subfield([], props.field) : [];

  return subsubsection({
    title: sectionTitle,
    path: [],
    help,
    blocks: [
      columns2(
        ...(fieldOrder === "code-then-abundance"
          ? codeFields
          : abundanceFields),
        ...(fieldOrder === "code-then-abundance"
          ? abundanceFields
          : codeFields),
        ...fieldFields
      ),
    ],
  });
}

interface ChannelSubsubsectionProps<
  A extends SelectValue,
  B extends SelectValue,
> extends MorphFieldsProps<A, B> {
  sectionTitle?: string | null;
  fieldOrder?: "code-then-abundance" | "abundance-then-code";
}

export function channelSubsubsection<
  A extends SelectValue,
  B extends SelectValue,
>(props: ChannelSubsubsectionProps<A, B>): Block {
  const {
    label,
    sectionTitle = label,
    path,
    fieldOrder = "code-then-abundance",
    help,
  } = props;

  let codeBlocks: Field[] = [];

  if (props.code != null) {
    switch (props.code.type) {
      case "select":
        codeBlocks = [
          ...codeBlocks,
          select({
            label: props.code.secondaryLabel ?? "Code",
            path: [...path, "code"],
            image: fieldImage(props.code.defaultImage),
            help,
            options: enumImages(props.code.source, props.code.imagePattern),
            showValues: props.code.showValues,
            required:
              props.code.required === false ? undefined : props.code.required,
            defaultValue: props.code.defaultValue,
          }),
        ];
        break;

      case "text":
        codeBlocks = [
          ...codeBlocks,
          textField({
            label: props.code.secondaryLabel ?? "Code",
            path: [...path, "code"],
            image: fieldImage(props.code.image),
            help,
            required:
              props.code.required === false ? undefined : props.code.required,
            defaultValue: props.code.defaultValue,
          }),
        ];
        break;

      default:
        checkExhausted(props.code);
    }
  }

  let abundanceBlocks: Field[] = [];

  if (props.abundance != null) {
    switch (props.abundance.type) {
      case "select":
        abundanceBlocks = [
          ...abundanceBlocks,
          select({
            label: props.abundance.secondaryLabel ?? "Abundance",
            path: [...path, "abundance"],
            image: fieldImage(props.abundance.defaultImage),
            help,
            options: enumImages(
              props.abundance.source,
              props.abundance.imagePattern
            ),
            showValues: props.abundance.showValues,
            required:
              props.abundance.required === false
                ? undefined
                : props.abundance.required,
            defaultValue: props.abundance.defaultValue,
          }),
        ];
        break;

      case "number":
        abundanceBlocks = [
          ...abundanceBlocks,
          integerField({
            label: props.abundance.secondaryLabel ?? "Abundance",
            path: [...path, "abundance"],
            units: props.abundance.units,
            image: fieldImage(props.abundance.image),
            help,
            required:
              props.abundance.required === false
                ? undefined
                : props.abundance.required,
            defaultValue: props.abundance.defaultValue,
            bounds: minValue(0, "error"),
          }),
        ];
        break;

      case "checkbox":
        abundanceBlocks = [
          ...abundanceBlocks,
          checkbox({
            label: props.abundance.secondaryLabel ?? "Abundance",
            path: [...path, "abundance"],
            checkboxLabel: props.abundance.checkboxLabel,
            help,
          }),
        ];
        break;

      default:
        checkExhausted(props.abundance);
    }
  }

  return subsubsection({
    title: sectionTitle,
    path: [],
    help,
    blocks: [
      columns2(
        fieldOrder === "code-then-abundance"
          ? vstack(...codeBlocks, ...abundanceBlocks)
          : vstack(...abundanceBlocks, ...codeBlocks)
      ),
    ],
  });
}
