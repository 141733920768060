import { isApiError } from "./types.generated";

export class WrappedError<A = unknown> extends Error {
  readonly cause: A;

  constructor(message: string, cause: A) {
    super(message);
    // https://stackoverflow.com/questions/12789231/class-type-check-in-typescript
    Object.setPrototypeOf(this, WrappedError.prototype);
    this.cause = cause;
  }
}

export function isWrappedError(value: unknown): value is WrappedError {
  return value instanceof WrappedError;
}

export function isWrappedErrorOf<A>(isA: (a: unknown) => a is A) {
  return function (value: unknown): value is WrappedError<A> {
    return value instanceof WrappedError && isA(value.cause);
  };
}

export function unwrapErrorCause(error: unknown): unknown {
  if (isWrappedError(error)) {
    return unwrapErrorCause(error.cause);
  } else {
    return error;
  }
}

/* Wrap an arbitrary JSON error object in an instance of the WrappedError class
 * (which conforms to the Error type and can be reported effectively by Sentry).
 *
 * NOTE: Only use this code at the point of reporting errors to Sentry
 * unless YOU REALLY KNOW WHAT YOU'RE DOING.
 *
 * It's not safe to wrap errors when throwing them or using IO.fail()
 * until you're sure that any error handling code
 * uses isWrappedErrorOf(isFooError) to identify the kind of error
 * instead of plain old isFooError.
 */
export function wrapError(error: unknown): Error {
  if (error instanceof Error) {
    return error;
  } else if (isApiError(error)) {
    switch (error.type) {
      case "ExpiredAuthorizationError":
        return new WrappedError("Expired authorization token", error);
      case "InvalidAuthorizationError":
        return new WrappedError("Invalid authorization header", error);
      case "MissingAuthorizationError":
        return new WrappedError("Missing authorization header", error);
      case "ForbiddenError":
        return new WrappedError("Insufficient permissions", error);
      case "BadRequestError":
        return new WrappedError(`Bad request: ${error.message}`, error);
      case "NotFoundError":
        return new WrappedError(
          `Not found: ${error.itemType}: ${error.item}`,
          error
        );
      case "DuplicateError":
        return new WrappedError(
          `Duplicate: ${error.itemType}: ${error.item}`,
          error
        );
      case "MapQueryUnsupportedError":
        return new WrappedError(
          `The map layer does not support that operation: ${error.message}`,
          error
        );
      case "InternalError":
        return new WrappedError(
          `An internal error occurred: ${error.message}`,
          error
        );
      case "ValidationError":
        return new WrappedError(`Validation error: ${error.message}`, error);
      case "NetworkError":
        return new WrappedError(
          `A network error occurred: ${error.message}`,
          error
        );
      default:
        return new WrappedError(`Unknown error`, error);
    }
  } else {
    return new WrappedError("Unknown error", error);
  }
}
