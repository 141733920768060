// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21LitterSurveyType = "hotspot" | "foreshoreSegment";

const Hotspot: Thames21LitterSurveyType = "hotspot";
const ForeshoreSegment: Thames21LitterSurveyType = "foreshoreSegment";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21LitterSurveyTypeEnum = {
  ...createEnum("Thames21LitterSurveyType", [
    [Hotspot, "Hotspot"],
    [ForeshoreSegment, "Foreshore segment"],
  ]),
  Hotspot,
  ForeshoreSegment,
};

export default Thames21LitterSurveyTypeEnum;
