import {
  attachmentAttr,
  attributeGroup,
  enumAttr,
  pointLayer,
  standardAttribution,
  timestampAttr,
  surveyAttr,
  teamAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  StdInvasiveSpeciesAmountEnum,
  StdInvasiveSpeciesEnum,
  StdInvasiveSpeciesTreatmentEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const StdInvasiveSpeciesSighting = mapSchemaWithDefaults({
  mapId: unsafeMapId("StdInvasiveSpeciesSighting"),
  title: "Invasive Species",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("StdInvasiveSpeciesSighting"),
      title: "Invasive Species",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("StdInvasiveSpeciesSighting"),
        attribution: [
          standardAttribution({
            what: "Invasive Species Dataset",
            who: "Cartographer Studios Ltd",
            url: "https://cartographer.io",
            from: "2012",
          }),
        ],
      }),
      defaultAttribute: "species",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          attributes: [
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "species",
              label: "Species",
              enum: StdInvasiveSpeciesEnum,
            }),
            enumAttr({
              attributeId: "amount",
              label: "Amount",
              enum: StdInvasiveSpeciesAmountEnum,
            }),
            enumAttr({
              attributeId: "treatment",
              label: "Treatment",
              enum: StdInvasiveSpeciesTreatmentEnum,
            }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("StdInvasiveSpeciesSighting"),
            }),
            surveyAttr("surveyId", "Survey"),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
      ],
    }),
  ],
});
