import {
  topoExpr,
  attachmentField,
  card,
  columns2,
  dynamicAttr,
  dynamicGrid,
  enumSelectOptions,
  form,
  page,
  pointField,
  required,
  requiredIfV2,
  section,
  select,
  text,
  textArea,
  textField,
  timestampField,
  visibility,
  vstack,
} from "@cartographerio/topo-form";
import { Result } from "@cartographerio/fp";
import {
  OutfallSafariActivityEnum,
  OutfallSafariAesthetics,
  OutfallSafariAestheticsEnum,
  OutfallSafariBankEnum,
  OutfallSafariFlowOfDischargeEnum,
  OutfallSafariInaccessibleAreaLengthEnum,
  OutfallSafariVisualImpact,
  OutfallSafariVisualImpactEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";

const isOutfall = topoExpr<boolean>(env => {
  return (
    env.getAbsolute(["data", "activity"]) === OutfallSafariActivityEnum.Outfall
  );
});

const requiredIfOutfall = requiredIfV2({
  level: "info",
  message: "Please enter a value",
  otherTest: isOutfall,
});

const isInaccessible = topoExpr<boolean>(env => {
  return (
    env.getAbsolute(["data", "activity"]) ===
    OutfallSafariActivityEnum.Inaccessible
  );
});

const requiredIfInaccessible = requiredIfV2({
  level: "info",
  message: "Please enter a value",
  otherTest: isInaccessible,
});

function eaVisualImpactScore(visualImpact: OutfallSafariVisualImpact) {
  switch (visualImpact) {
    case "None":
      return 0;
    case "Under2m":
      return 2;
    case "Under10m":
      return 4;
    case "Under30m":
      return 6;
    case "Over30m":
      return 10;
  }
}

function eaAestheticsScore(aesthetics: OutfallSafariAesthetics) {
  switch (aesthetics) {
    case "None":
      return 0;
    case "Faint":
      return 2;
    case "Mild":
      return 4;
    case "Strong":
      return 6;
    case "Gross":
      return 10;
  }
}

const vScore = topoExpr<number | null>(env =>
  env
    .getAbsoluteAs(
      ["data", "visualImpact"],
      OutfallSafariVisualImpactEnum.isValue
    )
    .map(eaVisualImpactScore)
    .getOrNull()
);

const aScore = topoExpr<number | null>(env =>
  env
    .getAbsoluteAs(["data", "aesthetics"], OutfallSafariAestheticsEnum.isValue)
    .map(eaAestheticsScore)
    .getOrNull()
);

const eaScore = topoExpr<number | null>(env =>
  Result.when(
    env
      .getAbsoluteAs(
        ["data", "visualImpact"],
        OutfallSafariVisualImpactEnum.isValue
      )
      .map(eaVisualImpactScore),
    env
      .getAbsoluteAs(
        ["data", "aesthetics"],
        OutfallSafariAestheticsEnum.isValue
      )
      .map(eaAestheticsScore)
  )((v, a) => v + a).getOrNull()
);

const eaScoreGte10 = topoExpr<boolean>((env, run) => {
  const score = run(eaScore);
  return score != null && score >= 10;
});

export default form({
  title: "Outfall Safari",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        columns2(
          vstack(
            timestampField({
              label: "Date and time",
              path: ["data", "recorded"],
              defaultValue: "now",
              required: required("info"),
            }),
            select({
              label: "Activity",
              path: ["data", "activity"],
              options: enumSelectOptions(OutfallSafariActivityEnum),
              required: required("info"),
              defaultValue: OutfallSafariActivityEnum.Outfall,
            }),
            textField({
              label: "Describe the nearest landmark",
              path: ["data", "nearestLandmark"],
              required: required("info"),
              help: "Road, building name/number, bridge, etc.",
            })
          )
        ),
        pointField({
          label: "Location",
          path: ["data", "location"],
          required: required("info"),
        }),
        columns2(
          vstack(
            select({
              label: "Select the surveyed bank as seen looking downstream",
              path: ["data", "outfallBank"],
              visible: isOutfall,
              options: enumSelectOptions(OutfallSafariBankEnum),
              customRules: [requiredIfOutfall],
              help: "⚠️ Select the surveyed bank as seen _when looking downstream_.",
            })
          )
        ),
        section({
          title: "Outfall Details",
          path: [],
          visible: isOutfall,
          blocks: [
            columns2(
              vstack(
                select({
                  label: "Rank the flow of discharge from the outfall",
                  path: ["data", "flowOfDischarge"],
                  options: enumSelectOptions(OutfallSafariFlowOfDischargeEnum),
                  customRules: [requiredIfOutfall],
                }),
                select({
                  label: "How big is the visual impact of the outfall?",
                  path: ["data", "visualImpact"],
                  options: enumSelectOptions(OutfallSafariVisualImpactEnum),
                  customRules: [requiredIfOutfall],
                }),
                select({
                  label: "Condition of discharge from the outfall",
                  path: ["data", "aesthetics"],
                  options: enumSelectOptions(OutfallSafariAestheticsEnum),
                  customRules: [requiredIfOutfall],
                  help: "Choose a category that most represents what you see",
                }),
                textArea({
                  label: "List any other forms of pollution at the outfall",
                  path: ["data", "otherFormsOfPollution"],
                  rows: 3,
                  help: "e.g oil or fat (see handout for guidance)",
                }),
                attachmentField({
                  label: "Photographs of outfalls",
                  path: ["data", "photographs"],
                  maxFiles: 3,
                  help: "⚠️ All outfalls should be photographed!",
                }),
                dynamicGrid({
                  label: "Environment Agency Scores",
                  columns: 3,
                  attributes: [
                    dynamicAttr({
                      label: "Visual Impact",
                      valueType: "number",
                      value: vScore,
                      decimalPlaces: 0,
                    }),
                    dynamicAttr({
                      label: "Aesthetics",
                      valueType: "number",
                      value: aScore,
                      decimalPlaces: 0,
                    }),
                    dynamicAttr({
                      label: "Overall",
                      valueType: "number",
                      value: eaScore,
                      decimalPlaces: 0,
                    }),
                  ],
                }),
                visibility({
                  visible: eaScoreGte10,
                  block: card({
                    appearance: "warning",
                    block: text({
                      markdown: outdent`
                      **⚠️ Urgent: Reportable**

                      Outfalls that score ten or higher
                      i.e. "strong smell, strong discolouration, large coverage of grey fungus",
                      suggest a pollution problem that requires urgent attention.

                      **If you see any potential pollution incidents,
                      call the Environment Agency 24-hour Incident Hotline
                      on [0800 807060](tel:0800807060).**

                      **Southern Water wastewater emergency:
                      please call us immediately on [0330 303 0368](tel:03303030368).**

                      Indicators include: grey water discharge, oil,
                      chemical or sewage odour, dead fish, or fish gasping for air.
                      `,
                    }),
                  }),
                })
              )
            ),
          ],
        }),
        section({
          title: "Inaccessible Area",
          path: [],
          visible: isInaccessible,
          blocks: [
            columns2(
              vstack(
                textArea({
                  label: "Describe the inaccessible area",
                  path: ["data", "inaccessibleAreaDescription"],
                  rows: 3,
                  help: "eg. vegetation, road, fence, culvert, etc.",
                  customRules: [requiredIfInaccessible],
                }),
                select({
                  label: "Rough length of the inaccessible area",
                  path: ["data", "inaccessibleAreaLength"],
                  options: enumSelectOptions(
                    OutfallSafariInaccessibleAreaLengthEnum
                  ),
                  customRules: [requiredIfInaccessible],
                })
              )
            ),
          ],
        }),
      ],
    }),
  ],
});
