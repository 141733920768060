// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { AttachmentCategory } from "../types.generated";

const Image: AttachmentCategory = "image";
const Text: AttachmentCategory = "text";
const Document: AttachmentCategory = "document";
const Spreadsheet: AttachmentCategory = "spreadsheet";
const Presentation: AttachmentCategory = "presentation";
const Archive: AttachmentCategory = "archive";
const Unknown: AttachmentCategory = "unknown";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AttachmentCategoryEnum = {
  ...createEnum("AttachmentCategory", [
    [Image, "Image file"],
    [Text, "Text file"],
    [Document, "Downloadable document"],
    [Spreadsheet, "Downloadable spreadsheet"],
    [Presentation, "Downloadable presentation"],
    [Archive, "Downloadable archive"],
    [Unknown, "Unknown file type"],
  ]),
  Image,
  Text,
  Document,
  Spreadsheet,
  Presentation,
  Archive,
  Unknown,
};

export default AttachmentCategoryEnum;
