// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusNoneSomeMany = "none" | "some" | "many";

const None: EcostatusNoneSomeMany = "none";
const Some: EcostatusNoneSomeMany = "some";
const Many: EcostatusNoneSomeMany = "many";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusNoneSomeManyEnum = {
  ...createEnum("EcostatusNoneSomeMany", [
    [None, "None"],
    [Some, "Some"],
    [Many, "Many"],
  ]),
  None,
  Some,
  Many,
};

export default EcostatusNoneSomeManyEnum;
