// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityInvasivePlant =
  | "HimalayanBalsam"
  | "JapaneseKnotweed"
  | "GiantHogweed"
  | "SkunkCabbage"
  | "CurlyWaterweed"
  | "FloatingPennywort"
  | "Other";

const HimalayanBalsam: ArrtWaterQualityInvasivePlant = "HimalayanBalsam";
const JapaneseKnotweed: ArrtWaterQualityInvasivePlant = "JapaneseKnotweed";
const GiantHogweed: ArrtWaterQualityInvasivePlant = "GiantHogweed";
const SkunkCabbage: ArrtWaterQualityInvasivePlant = "SkunkCabbage";
const CurlyWaterweed: ArrtWaterQualityInvasivePlant = "CurlyWaterweed";
const FloatingPennywort: ArrtWaterQualityInvasivePlant = "FloatingPennywort";
const Other: ArrtWaterQualityInvasivePlant = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityInvasivePlantEnum = {
  ...createEnum("ArrtWaterQualityInvasivePlant", [
    [HimalayanBalsam, "Himalayan Balsam"],
    [JapaneseKnotweed, "Japanese Knotweed"],
    [GiantHogweed, "Giant Hogweed"],
    [SkunkCabbage, "Skunk Cabbage"],
    [CurlyWaterweed, "Curly Waterweed"],
    [FloatingPennywort, "Floating Pennywort"],
    [Other, "Other"],
  ]),
  HimalayanBalsam,
  JapaneseKnotweed,
  GiantHogweed,
  SkunkCabbage,
  CurlyWaterweed,
  FloatingPennywort,
  Other,
};

export default ArrtWaterQualityInvasivePlantEnum;
