import { Result } from "@cartographerio/fp";
import {
  Feature,
  isFeatureF,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isArrayOf,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  isSurveyId,
  isTimestamp,
  SurveyId,
  Timestamp,
} from "@cartographerio/types";

interface SiteProperties {
  surveyId: SurveyId;
  site?: string | null;
  catchment?: string | null;
  river?: string | null;
  trpiRiverType?: number | null;
}

interface Count {
  taxon?: string | null;
  count?: number | null;
}

interface PartialObservations {
  recorded?: Timestamp | null;
  counts: Count[];
  otherCounts: Count[];
}

interface PartialData {
  site?: Feature<Point, SiteProperties> | null;
  observations: PartialObservations;
}

function isSiteProperties(data: unknown): data is SiteProperties {
  return (
    isObject(data) &&
    hasKey(data, "surveyId", isSurveyId) &&
    hasOptionalKey(data, "site", isNullable(isString)) &&
    hasOptionalKey(data, "catchment", isNullable(isString)) &&
    hasOptionalKey(data, "river", isNullable(isString)) &&
    hasOptionalKey(data, "trpiRiverType", isNullable(isNumber))
  );
}

function isCount(data: unknown): data is Count {
  return (
    isObject(data) &&
    hasOptionalKey(data, "taxon", isNullable(isString)) &&
    hasOptionalKey(data, "count", isNullable(isNumber))
  );
}

function isPartialObservations(data: unknown): data is PartialObservations {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasKey(data, "counts", isArrayOf(isCount)) &&
    hasKey(data, "otherCounts", isArrayOf(isCount))
  );
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    hasOptionalKey(
      data,
      "site",
      isNullable(isFeatureF(isPoint, isSiteProperties))
    ) && hasOptionalKey(data, "observations", isPartialObservations)
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    data.site == null
      ? "Unknown Site"
      : [
          data.site.properties.catchment,
          data.site.properties.river,
          data.site.properties.site,
          safePointToNgr(data.site.geometry),
        ]
          .filter(x => !!x)
          .join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.observations.recorded ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map(data => data.site?.geometry ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
    observations: {
      ...data.observations,
      counts: data.observations.counts.map(({ taxon }) => ({ taxon })),
      otherCounts: data.observations.counts.map(({ taxon }) => ({ taxon })),
    },
  }));
}
