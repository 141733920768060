// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityPhosphate =
  | "P0"
  | "P100"
  | "P200"
  | "P300"
  | "P500"
  | "P1000"
  | "P2500";

const P0: EsrtWaterQualityPhosphate = "P0";
const P100: EsrtWaterQualityPhosphate = "P100";
const P200: EsrtWaterQualityPhosphate = "P200";
const P300: EsrtWaterQualityPhosphate = "P300";
const P500: EsrtWaterQualityPhosphate = "P500";
const P1000: EsrtWaterQualityPhosphate = "P1000";
const P2500: EsrtWaterQualityPhosphate = "P2500";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityPhosphateEnum = {
  ...createEnum("EsrtWaterQualityPhosphate", [
    [P0, "0"],
    [P100, "100"],
    [P200, "200"],
    [P300, "300"],
    [P500, "500"],
    [P1000, "1000"],
    [P2500, "2500"],
  ]),
  P0,
  P100,
  P200,
  P300,
  P500,
  P1000,
  P2500,
};

export default EsrtWaterQualityPhosphateEnum;
