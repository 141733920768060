// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { KnownTeamFeature } from "../types.generated";

const LockAlias: KnownTeamFeature = "LockAlias";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const KnownTeamFeatureEnum = {
  ...createEnum("KnownTeamFeature", [
    [LockAlias, "Lock team alias (prevent editing)"],
  ]),
  LockAlias,
};

export default KnownTeamFeatureEnum;
