// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesLandCoverCategory =
  | "urban"
  | "agriculture"
  | "woodland"
  | "openLand"
  | "openWater"
  | "wetland";

const Urban: CesLandCoverCategory = "urban";
const Agriculture: CesLandCoverCategory = "agriculture";
const Woodland: CesLandCoverCategory = "woodland";
const OpenLand: CesLandCoverCategory = "openLand";
const OpenWater: CesLandCoverCategory = "openWater";
const Wetland: CesLandCoverCategory = "wetland";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesLandCoverCategoryEnum = {
  ...createEnum("CesLandCoverCategory", [
    [Urban, "Urban"],
    [Agriculture, "Agriculture"],
    [Woodland, "Woodland"],
    [OpenLand, "Open land"],
    [OpenWater, "Open water"],
    [Wetland, "Wetland"],
  ]),
  Urban,
  Agriculture,
  Woodland,
  OpenLand,
  OpenWater,
  Wetland,
};

export default CesLandCoverCategoryEnum;
