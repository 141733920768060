import {
  RiverflySpeciesEnum,
  UrbanRiverflySpeciesEnum,
  ExtendedRiverflySpeciesEnum,
  ExtendedRiverflySpecies,
  UrbanRiverflySpecies,
  RiverflySpecies,
} from "@cartographerio/inventory-enums";

type Coefficients = [number, number, number, number];
type CoefficientMatrix<A extends string> = Partial<Record<A, Coefficients>>;

export type NullableCounts<A extends string> = Record<A, number | null>;
export type Counts<A extends string> = Record<A, number>;
export type Scores<A extends string> = Record<A, number | null>;

interface ScoresFunction<A extends string> {
  (counts: NullableCounts<A>, defaultValue: number): Scores<A>;
  (counts: Counts<A>): Scores<A>;
}

interface ConvertFunction<A extends string, B extends string> {
  (counts: Counts<A>): Counts<B>;
  (counts: NullableCounts<A>, defaultValue: number): Counts<B>;
}

export interface Conversions<A extends string, B extends string> {
  forwardIncludes: (a: A) => boolean;
  forwardLookup: (id: A) => B;
  forward: ConvertFunction<A, B>;
  reverseLookup: (id: B) => A;
  reverse: ConvertFunction<B, A>;
}

export interface Calculations<A extends string> {
  label: string;
  score: (speciesId: A, count: number | null) => number | null;
  scores: ScoresFunction<A>;
  total: (scores: Scores<A>) => number;
  complete: (scores: Scores<A>) => boolean;
}

function createConversions<A extends string, B extends string>(
  _entries: [A, B | null][]
): Conversions<A, B> {
  const _forward: [A, B][] = _entries.flatMap(([a, b]) =>
    b == null ? [] : [[a, b]]
  );

  const _reverse: [B, A][] = _forward.map<[B, A]>(([a, b]) => [b, a]);

  const forwardIncludes = (id: A): boolean =>
    _forward.some(([a, _]) => a === id);

  const forwardLookup = (id: A): B => {
    for (const [a, b] of _forward) {
      if (id === a) {
        return b;
      }
    }
    throw new Error("Tried looking up an invalid type");
  };

  const forward: ConvertFunction<A, B> = (
    counts: NullableCounts<A>,
    defaultValue: number = 0
  ): Counts<B> => {
    const ans: Partial<Counts<B>> = {};

    for (const item of _forward) {
      const [a, b] = item;
      ans[b] = (ans[b] ?? 0) + (counts[a] ?? defaultValue);
    }
    return ans as Counts<B>;
  };

  const reverseLookup = (id: B): A => {
    for (const [b, a] of _reverse) {
      if (id === b) {
        return a;
      }
    }
    throw new Error("Tried looking up an invalid type");
  };

  const reverse: ConvertFunction<B, A> = (
    counts: NullableCounts<B>,
    defaultValue: number = 0
  ): Counts<A> => {
    const ans: Partial<Counts<A>> = {};

    for (const item of _reverse) {
      const [b, a] = item;
      ans[a] = (ans[a] ?? 0) + (counts[b] ?? defaultValue);
    }
    return ans as Counts<A>;
  };

  return { forwardIncludes, forwardLookup, forward, reverseLookup, reverse };
}

function createCalculations<A extends string>(
  label: string,
  _matrix: CoefficientMatrix<A>
): Calculations<A> {
  const _index = (count: number): number | null =>
    !count || count < 1
      ? null
      : count < 10
        ? 0
        : count < 100
          ? 1
          : count < 1000
            ? 2
            : 3;

  const score = (speciesId: A, count: number | null): number | null => {
    const array: Coefficients | undefined = _matrix[speciesId];
    const index = _index(count ?? 0);
    return index == null ? 0 : array?.[index] ?? null;
  };

  const scores: ScoresFunction<A> = (
    counts: NullableCounts<A>,
    defaultValue?: number
  ): Scores<A> => {
    const ans: Partial<Scores<A>> = {};
    for (const speciesId in counts) {
      ans[speciesId] = score(
        speciesId,
        counts[speciesId] ?? defaultValue ?? null
      );
    }
    return ans as Scores<A>;
  };

  const total = (scores: Scores<A>): number => {
    let ans = 0;
    for (const speciesId in scores) {
      ans = ans + (scores[speciesId] ?? 0);
    }
    return ans;
  };

  const complete = (scores: Scores<A>): boolean => {
    for (const speciesId in _matrix) {
      if (scores[speciesId] == null) {
        return false;
      }
    }
    return true;
  };

  return { label, score, scores, total, complete };
}

export const riverflyCalculations = createCalculations<RiverflySpecies>(
  "ARMI",
  {
    [RiverflySpeciesEnum.CasedCaddis]: [1, 2, 3, 4],
    [RiverflySpeciesEnum.CaselessCaddis]: [1, 2, 3, 4],
    [RiverflySpeciesEnum.Ephemeridae]: [1, 2, 3, 4],
    [RiverflySpeciesEnum.Ephemerellidae]: [1, 2, 3, 4],
    [RiverflySpeciesEnum.Heptageniidae]: [1, 2, 3, 4],
    [RiverflySpeciesEnum.Baetidae]: [1, 2, 3, 4],
    [RiverflySpeciesEnum.Stoneflies]: [1, 2, 3, 4],
    [RiverflySpeciesEnum.Gammarus]: [1, 2, 3, 4],
  }
);

export const urbanRiverflyCalculations =
  createCalculations<UrbanRiverflySpecies>("Urban", {
    [UrbanRiverflySpeciesEnum.CasedCaddis]: [1, 2, 3, 4],
    [UrbanRiverflySpeciesEnum.CaselessCaddis]: [1, 2, 3, 4],
    [UrbanRiverflySpeciesEnum.Stoneflies]: [1, 2, 3, 4],
    [UrbanRiverflySpeciesEnum.Ephemeridae]: [1, 2, 3, 4],
    [UrbanRiverflySpeciesEnum.Ephemerellidae]: [1, 2, 3, 4],
    [UrbanRiverflySpeciesEnum.Heptageniidae]: [1, 2, 3, 4],
    [UrbanRiverflySpeciesEnum.Gammarus]: [1, 2, 3, 2],
    [UrbanRiverflySpeciesEnum.Baetidae]: [1, 2, 3, 4],
    [UrbanRiverflySpeciesEnum.Simuliidae]: [1, 2, 2, 0],
    [UrbanRiverflySpeciesEnum.Beetles]: [1, 2, 3, 4],
    [UrbanRiverflySpeciesEnum.Snails]: [1, 1, 1, 0],
    [UrbanRiverflySpeciesEnum.Leeches]: [1, 1, 0, -2],
    [UrbanRiverflySpeciesEnum.Asellidae]: [1, 1, 0, -2],
    [UrbanRiverflySpeciesEnum.Oligochaeta]: [1, 1, 0, -3],
  });

export const extendedRiverflyWaterQualityCalculations =
  createCalculations<ExtendedRiverflySpecies>("Water Quality", {
    [ExtendedRiverflySpeciesEnum.Flatworms]: [1, 1, 1, 1],
    [ExtendedRiverflySpeciesEnum.FreshwaterSnails]: [1, 1, 1, 1],
    [ExtendedRiverflySpeciesEnum.Limpets]: [2, 2, 2, 2],
    [ExtendedRiverflySpeciesEnum.Bivalves]: [1, 1, 1, 0],
    [ExtendedRiverflySpeciesEnum.AquaticWorms]: [1, 0, 0, -3],
    [ExtendedRiverflySpeciesEnum.Leeches]: [1, 1, 0, 0],
    // Crayfish should produce *no* score instead of zero:
    // [ ExtendedRiverflySpeciesEnum.Crayfish]:                           [ 0, 0,  0,  0 ],
    [ExtendedRiverflySpeciesEnum.WaterHoglouse]: [1, 0, 0, 4],
    [ExtendedRiverflySpeciesEnum.FreshwaterShrimps]: [1, 1, 1, 1],
    // Non-Native Shrimps should produce *no* score instead of zero:
    // [ ExtendedRiverflySpeciesEnum.InvasiveNonNativeShrimps]:           [ 0, 0,  0,  0 ],
    [ExtendedRiverflySpeciesEnum.MayfliesAnglersCurse]: [2, 2, 2, 2],
    [ExtendedRiverflySpeciesEnum.MayfliesOlives]: [1, 2, 2, 2],
    [ExtendedRiverflySpeciesEnum.MayfliesProngGilled]: [3, 3, 3, 3],
    [ExtendedRiverflySpeciesEnum.MayfliesFlatbodied]: [3, 4, 4, 4],
    [ExtendedRiverflySpeciesEnum.MayfliesGreenDrake]: [3, 3, 3, 3],
    [ExtendedRiverflySpeciesEnum.MayfliesBlueWingedOlive]: [3, 3, 3, 3],
    [ExtendedRiverflySpeciesEnum.Stoneflies]: [4, 4, 4, 4],
    [ExtendedRiverflySpeciesEnum.DragonfliesAndDamselflies]: [2, 2, 2, 2],
    [ExtendedRiverflySpeciesEnum.WaterBoatmen]: [1, 1, 1, 1],
    [ExtendedRiverflySpeciesEnum.OtherWaterBugs]: [2, 2, 2, 2],
    [ExtendedRiverflySpeciesEnum.WaterBeetles]: [2, 2, 2, 2],
    [ExtendedRiverflySpeciesEnum.Alderflies]: [1, 1, 1, 1],
    [ExtendedRiverflySpeciesEnum.CaselessCaddisfliesGreenSedge]: [3, 3, 3, 3],
    [ExtendedRiverflySpeciesEnum.CaselessCaddisfliesNetSpinners]: [2, 2, 2, 2],
    [ExtendedRiverflySpeciesEnum.CaselessCaddisfliesNonGilled]: [3, 3, 3, 3],
    [ExtendedRiverflySpeciesEnum.CasedCaddisfliesHoodCaseMaker]: [2, 3, 3, 3],
    [ExtendedRiverflySpeciesEnum.CasedCaddisfliesWeightedCaseMaker]: [
      3, 3, 3, 3,
    ],
    [ExtendedRiverflySpeciesEnum.CasedCaddisfliesBushTailed]: [3, 3, 3, 3],
    [ExtendedRiverflySpeciesEnum.CasedCaddisfliesOther]: [2, 3, 3, 3],
    [ExtendedRiverflySpeciesEnum.Craneflies]: [2, 2, 2, 2],
    [ExtendedRiverflySpeciesEnum.Blackflies]: [2, 2, 2, 1],
    [ExtendedRiverflySpeciesEnum.NonBitingMidges]: [0, 0, -4, -4],
    [ExtendedRiverflySpeciesEnum.WaterSnipeFlies]: [3, 3, 3, 3],
  });

export const extendedRiverflySiltAndFlowCalculations =
  createCalculations<ExtendedRiverflySpecies>("Silt and Flow", {
    [ExtendedRiverflySpeciesEnum.Flatworms]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.FreshwaterSnails]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.Limpets]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.Bivalves]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.AquaticWorms]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.Leeches]: [2, 0, -1, -2],
    // Crayfish should produce *no* score instead of zero:
    // [ ExtendedRiverflySpeciesEnum.Crayfish]: [ 0, 0,  0,  0 ],
    [ExtendedRiverflySpeciesEnum.WaterHoglouse]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.FreshwaterShrimps]: [2, 3, 4, 5],
    // Non-Native Shrimps should produce *no* score instead of zero:
    // [ ExtendedRiverflySpeciesEnum.InvasiveNonNativeShrimps]: [ 0, 0,  0,  0 ],
    [ExtendedRiverflySpeciesEnum.MayfliesAnglersCurse]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.MayfliesOlives]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.MayfliesProngGilled]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.MayfliesFlatbodied]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.MayfliesGreenDrake]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.MayfliesBlueWingedOlive]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.Stoneflies]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.DragonfliesAndDamselflies]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.WaterBoatmen]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.OtherWaterBugs]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.WaterBeetles]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.Alderflies]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.CaselessCaddisfliesGreenSedge]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.CaselessCaddisfliesNetSpinners]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.CaselessCaddisfliesNonGilled]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.CasedCaddisfliesHoodCaseMaker]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.CasedCaddisfliesWeightedCaseMaker]: [
      2, 3, 4, 5,
    ],
    [ExtendedRiverflySpeciesEnum.CasedCaddisfliesBushTailed]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.CasedCaddisfliesOther]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.Craneflies]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.Blackflies]: [2, 3, 4, 5],
    [ExtendedRiverflySpeciesEnum.NonBitingMidges]: [2, 0, -1, -2],
    [ExtendedRiverflySpeciesEnum.WaterSnipeFlies]: [2, 3, 4, 5],
  });

// Making a riverfly conversions for type stuffs
export const riverflyConversions = createConversions(
  RiverflySpeciesEnum.values.map(value => [value, value])
);

export const extendedRiverflyConversions = createConversions([
  [ExtendedRiverflySpeciesEnum.Flatworms, null],
  [ExtendedRiverflySpeciesEnum.FreshwaterSnails, null],
  [ExtendedRiverflySpeciesEnum.Limpets, null],
  [ExtendedRiverflySpeciesEnum.Bivalves, null],
  [ExtendedRiverflySpeciesEnum.AquaticWorms, null],
  [ExtendedRiverflySpeciesEnum.Leeches, null],
  [ExtendedRiverflySpeciesEnum.Crayfish, null],
  [ExtendedRiverflySpeciesEnum.WaterHoglouse, null],
  [ExtendedRiverflySpeciesEnum.FreshwaterShrimps, RiverflySpeciesEnum.Gammarus],
  [ExtendedRiverflySpeciesEnum.InvasiveNonNativeShrimps, null],
  [ExtendedRiverflySpeciesEnum.MayfliesAnglersCurse, null],
  [ExtendedRiverflySpeciesEnum.MayfliesOlives, RiverflySpeciesEnum.Baetidae],
  [ExtendedRiverflySpeciesEnum.MayfliesProngGilled, null],
  [
    ExtendedRiverflySpeciesEnum.MayfliesFlatbodied,
    RiverflySpeciesEnum.Heptageniidae,
  ],
  [
    ExtendedRiverflySpeciesEnum.MayfliesGreenDrake,
    RiverflySpeciesEnum.Ephemeridae,
  ],
  [
    ExtendedRiverflySpeciesEnum.MayfliesBlueWingedOlive,
    RiverflySpeciesEnum.Ephemerellidae,
  ],
  [ExtendedRiverflySpeciesEnum.Stoneflies, RiverflySpeciesEnum.Stoneflies],
  [ExtendedRiverflySpeciesEnum.DragonfliesAndDamselflies, null],
  [ExtendedRiverflySpeciesEnum.WaterBoatmen, null],
  [ExtendedRiverflySpeciesEnum.OtherWaterBugs, null],
  [ExtendedRiverflySpeciesEnum.WaterBeetles, null],
  [ExtendedRiverflySpeciesEnum.Alderflies, null],
  [
    ExtendedRiverflySpeciesEnum.CaselessCaddisfliesGreenSedge,
    RiverflySpeciesEnum.CaselessCaddis,
  ],
  [
    ExtendedRiverflySpeciesEnum.CaselessCaddisfliesNetSpinners,
    RiverflySpeciesEnum.CaselessCaddis,
  ],
  [
    ExtendedRiverflySpeciesEnum.CaselessCaddisfliesNonGilled,
    RiverflySpeciesEnum.CaselessCaddis,
  ],
  [
    ExtendedRiverflySpeciesEnum.CasedCaddisfliesHoodCaseMaker,
    RiverflySpeciesEnum.CasedCaddis,
  ],
  [
    ExtendedRiverflySpeciesEnum.CasedCaddisfliesWeightedCaseMaker,
    RiverflySpeciesEnum.CasedCaddis,
  ],
  [
    ExtendedRiverflySpeciesEnum.CasedCaddisfliesBushTailed,
    RiverflySpeciesEnum.CasedCaddis,
  ],
  [
    ExtendedRiverflySpeciesEnum.CasedCaddisfliesOther,
    RiverflySpeciesEnum.CasedCaddis,
  ],
  [ExtendedRiverflySpeciesEnum.Craneflies, null],
  [ExtendedRiverflySpeciesEnum.Blackflies, null],
  [ExtendedRiverflySpeciesEnum.NonBitingMidges, null],
  [ExtendedRiverflySpeciesEnum.WaterSnipeFlies, null],
]);

export const urbanRiverflyConversions = createConversions([
  [UrbanRiverflySpeciesEnum.CasedCaddis, RiverflySpeciesEnum.CasedCaddis],
  [UrbanRiverflySpeciesEnum.CaselessCaddis, RiverflySpeciesEnum.CaselessCaddis],
  [UrbanRiverflySpeciesEnum.Stoneflies, RiverflySpeciesEnum.Stoneflies],
  [UrbanRiverflySpeciesEnum.Ephemeridae, RiverflySpeciesEnum.Ephemeridae],
  [UrbanRiverflySpeciesEnum.Ephemerellidae, RiverflySpeciesEnum.Ephemerellidae],
  [UrbanRiverflySpeciesEnum.Heptageniidae, RiverflySpeciesEnum.Heptageniidae],
  [UrbanRiverflySpeciesEnum.Gammarus, RiverflySpeciesEnum.Gammarus],
  [UrbanRiverflySpeciesEnum.Baetidae, RiverflySpeciesEnum.Baetidae],
  [UrbanRiverflySpeciesEnum.Simuliidae, null],
  [UrbanRiverflySpeciesEnum.Beetles, null],
  [UrbanRiverflySpeciesEnum.Snails, null],
  [UrbanRiverflySpeciesEnum.Leeches, null],
  [UrbanRiverflySpeciesEnum.Asellidae, null],
  [UrbanRiverflySpeciesEnum.Oligochaeta, null],
]);
