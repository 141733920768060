import { unsafeMapId } from "@cartographerio/types";
import { mapLink, surveyModule } from "../SurveyModule";
import {
  copyData,
  dataDescription,
  formSchema,
  dataGeometry,
  moduleId,
  names,
  dataSchema,
  dataTimestamp,
} from "./parts";
export * from "./fixtures";

export default surveyModule({
  moduleId,
  names,
  dataSchema,
  formSchema,
  dataGeometry,
  dataTimestamp,
  dataDescription,
  copyData,
  mapLinks: [
    mapLink({ label: "Test Survey 3", mapId: unsafeMapId("StdActivityDiary") }),
  ],
});
