import { Option, Result } from "@cartographerio/fp";
import {
  Feature,
  isFeatureF,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isInteger,
  isNullable,
  isString,
} from "@cartographerio/guard";
import {
  SasWaterQualitySiteType,
  SasWaterQualitySiteTypeEnum,
  SasWaterQualityWeather,
  SasWaterQualityWeatherEnum,
} from "@cartographerio/inventory-enums";
import {
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  Timestamp,
} from "@cartographerio/types";

interface SiteAttributes {
  river?: string | null;
  site?: string | null;
  siteType?: SasWaterQualitySiteType;
}

function isSiteAttributes(data: unknown): data is SiteAttributes {
  return (
    hasOptionalKey(data, "river", isNullable(isString)) &&
    hasOptionalKey(data, "site", isNullable(isString)) &&
    hasOptionalKey(
      data,
      "siteType",
      isNullable(SasWaterQualitySiteTypeEnum.isValue)
    )
  );
}

interface PartialData {
  recorded?: Timestamp | null;
  site?: Feature<Point, SiteAttributes> | null;
  weather: SasWaterQualityWeather;
  airTemperature: number | null;
  numberOfSwimmers: number | null;
  numberOfOtherWaterUsers: number | null;
  escherichiaColiAsString: string | null;
  totalThermotolerantColiformsAsString: string | null;
  intestinalEnterococciAsString: string | null;
  photographs: AttachmentFolder;
  notes?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(
      data,
      "site",
      isNullable(isFeatureF(isPoint, isSiteAttributes))
    ) &&
    hasOptionalKey(
      data,
      "weather",
      isNullable(SasWaterQualityWeatherEnum.isValue)
    ) &&
    hasOptionalKey(data, "airTemperature", isNullable(isInteger)) &&
    hasOptionalKey(data, "numberOfSwimmers", isNullable(isInteger)) &&
    hasOptionalKey(data, "numberOfOtherWaterUsers", isNullable(isInteger)) &&
    hasOptionalKey(data, "escherichiaColiAsString", isNullable(isString)) &&
    hasOptionalKey(
      data,
      "totalThermotolerantColiformsAsString",
      isNullable(isString)
    ) &&
    hasOptionalKey(
      data,
      "intestinalEnterococciAsString",
      isNullable(isString)
    ) &&
    hasKey(data, "photographs", isAttachmentFolder) &&
    hasOptionalKey(data, "notes", isNullable(isString))
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data.site?.properties.river,
      data.site?.properties.site,
      data.site?.properties.siteType,
      Option.wrap(data.site?.geometry)
        .map(safePointToNgr)
        .getOrNull(),
    ]
      .filter(isString)
      .join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.recorded ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data.site?.geometry ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
    recorded: null,
    weather: null,
    airTemperature: null,
    numberOfSwimmers: null,
    numberOfOtherWaterUsers: null,
    escherichiaColiAsString: null,
    totalThermotolerantColiformsAsString: null,
    intestinalEnterococciAsString: null,
    notes: null,
  }));
}
