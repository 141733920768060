import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "geojson";
import { Picked } from "./type";

export function feature<G extends Geometry = Geometry, P = GeoJsonProperties>(
  props: Omit<Feature<G, P>, "type">
): Feature<G, P> {
  return { type: "Feature", ...props };
}

export function featureCollection<
  G extends Geometry = Geometry,
  P = GeoJsonProperties,
>(props: Omit<FeatureCollection<G, P>, "type">): FeatureCollection<G, P> {
  return { type: "FeatureCollection", ...props };
}

export function picked<G extends Geometry = Geometry, P = GeoJsonProperties>(
  props: Omit<Picked<G, P>, "type">
): Picked<G, P> {
  return {
    type: "Picked",
    ...props,
  };
}
