import lodash from "lodash";
import { Feature } from "geojson";

export function featureProperty(
  feature: Feature,
  propertyName: string
): unknown {
  const property = feature.properties?.[propertyName];
  // HACK: Mapbox GL stealthily converts nulls to "null". Unconvert this. Super dangerous!
  return property === "null" ? null : property;
}

export function featureProperties(
  features: Feature[],
  propertyName: string
): unknown[] {
  return features.map(feature => featureProperty(feature, propertyName));
}

export function uniqueFeatureProperties(
  features: Feature[],
  propertyName: string
): unknown[] {
  return lodash
    .chain(features)
    .map(feature => featureProperty(feature, propertyName))
    .uniq()
    .sort()
    .value();
}
