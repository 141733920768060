import { raise } from "@cartographerio/util";
import {
  isProjectId,
  isTeamId,
  isWorkspaceId,
  ProjectId,
  TeamId,
  WorkspaceId,
} from "../../core";
import {
  GlobalRoleName,
  isGlobalRoleName,
  isProjectRoleName,
  isTeamRoleName,
  isWorkspaceRoleName,
  ProjectRoleName,
  Role,
  TeamRoleName,
  WorkspaceRoleName,
} from "../types.generated";

export type RoleParts =
  | ["G", GlobalRoleName]
  | ["W", WorkspaceRoleName, WorkspaceId]
  | ["P", ProjectRoleName, ProjectId]
  | ["T", TeamRoleName, TeamId];

export function optParseRole(role: string): RoleParts | null {
  const parts = role.split(":");

  switch (parts[0]) {
    case "G":
      return parts.length === 2 && isGlobalRoleName(parts[1])
        ? ["G", parts[1]]
        : null;

    case "W":
      return parts.length === 3 &&
        isWorkspaceRoleName(parts[1]) &&
        isWorkspaceId(parts[2])
        ? ["W", parts[1], parts[2]]
        : null;

    case "P":
      return parts.length === 3 &&
        isProjectRoleName(parts[1]) &&
        isProjectId(parts[2])
        ? ["P", parts[1], parts[2]]
        : null;

    case "T":
      return parts.length === 3 &&
        isTeamRoleName(parts[1]) &&
        isTeamId(parts[2])
        ? ["T", parts[1], parts[2]]
        : null;

    default:
      return null;
  }
}

export function parseRole(role: string): RoleParts {
  return optParseRole(role) ?? raise(new Error("Bad role: " + role));
}

export function roleName(
  role: string
): GlobalRoleName | WorkspaceRoleName | ProjectRoleName | TeamRoleName | null {
  return optParseRole(role)?.[1] ?? null;
}

export function isGlobalRole(role: string): boolean {
  return optParseRole(role)?.[0] === "G";
}

export function globalRoleName(role: string): GlobalRoleName | null {
  const parts = optParseRole(role);
  return parts?.[0] === "G" ? parts[1] : null;
}

export function isWorkspaceRole(role: string): boolean {
  return optParseRole(role)?.[0] === "W";
}

export function workspaceRoleName(role: string): WorkspaceRoleName | null {
  const parts = optParseRole(role);
  return parts?.[0] === "W" ? parts[1] : null;
}

export function roleWorkspaceId(role: string): WorkspaceId | null {
  const parts = optParseRole(role);
  return parts?.[0] === "W" ? parts[2] : null;
}

export function isProjectRole(role: string): boolean {
  return optParseRole(role)?.[0] === "P";
}

export function projectRoleName(role: string): ProjectRoleName | null {
  const parts = optParseRole(role);
  return parts?.[0] === "P" ? parts[1] : null;
}

export function roleProjectId(role: string): ProjectId | null {
  const parts = optParseRole(role);
  return parts?.[0] === "P" ? parts[2] : null;
}

export function isTeamRole(role: string): boolean {
  return optParseRole(role)?.[0] === "T";
}

export function teamRoleName(role: string): TeamRoleName | null {
  const parts = optParseRole(role);
  return parts?.[0] === "T" ? parts[1] : null;
}

export function roleTeamId(role: string): TeamId | null {
  const parts = optParseRole(role);
  return parts?.[0] === "T" ? parts[2] : null;
}

export function registeredWorkspaceIds(roles: Role[]): WorkspaceId[] {
  return roles.reduce((acc: WorkspaceId[], curr: Role): WorkspaceId[] => {
    const id = roleWorkspaceId(curr);
    return id != null ? [...acc, id] : acc;
  }, []);
}
