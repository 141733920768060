// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsSpecialFeature =
  | "sideChannels"
  | "fen"
  | "marshOrBog"
  | "carr"
  | "waterMeadow"
  | "floatingMatOrReedBed"
  | "waterfall";

const SideChannels: UrsSpecialFeature = "sideChannels";
const Fen: UrsSpecialFeature = "fen";
const MarshOrBog: UrsSpecialFeature = "marshOrBog";
const Carr: UrsSpecialFeature = "carr";
const WaterMeadow: UrsSpecialFeature = "waterMeadow";
const FloatingMatOrReedBed: UrsSpecialFeature = "floatingMatOrReedBed";
const Waterfall: UrsSpecialFeature = "waterfall";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsSpecialFeatureEnum = {
  ...createEnum("UrsSpecialFeature", [
    [SideChannels, "Side channels"],
    [Fen, "Fen (low wet land, grassy veg)"],
    [MarshOrBog, "Marsh / Bog"],
    [Carr, "Carr (wetland, woody veg)"],
    [WaterMeadow, "Water meadow"],
    [FloatingMatOrReedBed, "Floating mat / reed bed"],
    [Waterfall, "Waterfall >5m"],
  ]),
  SideChannels,
  Fen,
  MarshOrBog,
  Carr,
  WaterMeadow,
  FloatingMatOrReedBed,
  Waterfall,
};

export default UrsSpecialFeatureEnum;
