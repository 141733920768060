import { isOtherSpecify, OtherSpecify } from "@cartographerio/topo-form";
import { Result } from "@cartographerio/fp";
import { isPoint, Point } from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isArrayOf,
  isBoolean,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  MrsHorizontalOrientation,
  MrsHorizontalOrientationEnum,
  MrsRiverWoodArtificialElement,
  MrsRiverWoodArtificialElementEnum,
  MrsRiverWoodJamType,
  MrsRiverWoodJamTypeEnum,
  MrsRiverWoodRetainedByArtificialFeature,
  MrsRiverWoodRetainedByArtificialFeatureEnum,
  MrsRiverWoodRetainedByNaturalFeature,
  MrsRiverWoodRetainedByNaturalFeatureEnum,
  MrsUad,
  MrsUadEnum,
} from "@cartographerio/inventory-enums";
import {
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  Timestamp,
} from "@cartographerio/types";

interface GeneralInformation {
  recorded?: Timestamp | null;
  bedVisible: boolean;
  adverseConditions: boolean;
  adverseConditionsNotes?: string | null;
  projectName?: string | null;
  riverName?: string | null;
  reachName?: string | null;
  jamNumber?: string | null;
}

function isGeneralInformation(data: unknown): data is GeneralInformation {
  return (
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasKey(data, "bedVisible", isBoolean) &&
    hasKey(data, "adverseConditions", isBoolean) &&
    hasOptionalKey(data, "adverseConditionsNotes", isNullable(isString)) &&
    hasOptionalKey(data, "projectName", isNullable(isString)) &&
    hasOptionalKey(data, "riverName", isNullable(isString)) &&
    hasOptionalKey(data, "reachName", isNullable(isString)) &&
    hasOptionalKey(data, "jamNumber", isNullable(isString))
  );
}

interface JamDetails {
  jamLocation?: Point | null;
  photographs: AttachmentFolder;
  cutByBeavers: boolean;
  presenceOfBeavers: boolean;
  evidenceOfBeavers?: string | null;
  artificialElements: OtherSpecify<MrsRiverWoodArtificialElement[]>;
  accumulationType?: MrsRiverWoodJamType | null;
  isolatedTreeLeaningTree?: boolean | null;
  isolatedTreeFallenTree?: boolean | null;
  isolatedTreeHighJam?: boolean | null;
  woodAccumulationHighJam?: boolean | null;
  woodAccumulationPartialJam?: boolean | null;
  woodAccumulationCompleteJam?: boolean | null;
  hydraulicTypeOverride?: string | null;
}

function isJamDetails(data: unknown): data is JamDetails {
  return (
    hasOptionalKey(data, "jamLocation", isNullable(isPoint)) &&
    hasKey(data, "photographs", isAttachmentFolder) &&
    hasKey(data, "cutByBeavers", isBoolean) &&
    hasKey(data, "presenceOfBeavers", isBoolean) &&
    hasOptionalKey(data, "evidenceOfBeavers", isNullable(isString)) &&
    hasKey(
      data,
      "artificialElements",
      isOtherSpecify(isArrayOf(MrsRiverWoodArtificialElementEnum.isValue))
    ) &&
    hasOptionalKey(
      data,
      "accumulationType",
      isNullable(MrsRiverWoodJamTypeEnum.isValue)
    ) &&
    hasOptionalKey(data, "isolatedTreeLeaningTree", isNullable(isBoolean)) &&
    hasOptionalKey(data, "isolatedTreeFallenTree", isNullable(isBoolean)) &&
    hasOptionalKey(data, "isolatedTreeHighJam", isNullable(isBoolean)) &&
    hasOptionalKey(data, "woodAccumulationHighJam", isNullable(isBoolean)) &&
    hasOptionalKey(data, "woodAccumulationPartialJam", isNullable(isBoolean)) &&
    hasOptionalKey(
      data,
      "woodAccumulationCompleteJam",
      isNullable(isBoolean)
    ) &&
    hasOptionalKey(data, "hydraulicTypeOverride", isNullable(isString))
  );
}

interface RiverChannelDimensions {
  completed: boolean;
  riverLocation?: Point | null;
  morphRiverWidth?: number | null;
  leftBankHeight?: number | null;
  rightBankHeight?: number | null;
  bankfullWidth?: number | null;
  waterWidth?: number | null;
  waterDepth?: number | null;
  notes?: string | null;
}

function isRiverChannelDimensions(
  data: unknown
): data is RiverChannelDimensions {
  return (
    hasKey(data, "completed", isBoolean) &&
    hasOptionalKey(data, "riverLocation", isNullable(isPoint)) &&
    hasOptionalKey(data, "morphRiverWidth", isNullable(isNumber)) &&
    hasOptionalKey(data, "leftBankHeight", isNullable(isNumber)) &&
    hasOptionalKey(data, "rightBankHeight", isNullable(isNumber)) &&
    hasOptionalKey(data, "bankfullWidth", isNullable(isNumber)) &&
    hasOptionalKey(data, "waterWidth", isNullable(isNumber)) &&
    hasOptionalKey(data, "waterDepth", isNullable(isNumber)) &&
    hasOptionalKey(data, "notes", isNullable(isString))
  );
}

interface JamAnchorage {
  completed: boolean;
  artificialFeatures: OtherSpecify<MrsRiverWoodRetainedByArtificialFeature[]>;
  naturalFeatures: MrsRiverWoodRetainedByNaturalFeature[];
  adjacentPool?: MrsUad | null;
  adjacentRiffle?: MrsUad | null;
  adjacentUnvegetatedBar?: MrsUad | null;
  adjacentVegetatedBar?: MrsUad | null;
  adjacentBench?: MrsUad | null;
  adjacentIsland?: MrsUad | null;
  adjacentErodingBankFace?: MrsUad | null;
  adjacentUndercutBankFace?: MrsUad | null;
  adjacentSideChannel?: MrsUad | null;
}

function isJamAnchorage(data: unknown): data is JamAnchorage {
  return (
    hasKey(data, "completed", isBoolean) &&
    hasKey(
      data,
      "artificialFeatures",
      isOtherSpecify(
        isArrayOf(MrsRiverWoodRetainedByArtificialFeatureEnum.isValue)
      )
    ) &&
    hasKey(
      data,
      "naturalFeatures",
      isArrayOf(MrsRiverWoodRetainedByNaturalFeatureEnum.isValue)
    ) &&
    hasOptionalKey(data, "adjacentPool", isNullable(MrsUadEnum.isValue)) &&
    hasOptionalKey(data, "adjacentRiffle", isNullable(MrsUadEnum.isValue)) &&
    hasOptionalKey(
      data,
      "adjacentUnvegetatedBar",
      isNullable(MrsUadEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "adjacentVegetatedBar",
      isNullable(MrsUadEnum.isValue)
    ) &&
    hasOptionalKey(data, "adjacentBench", isNullable(MrsUadEnum.isValue)) &&
    hasOptionalKey(data, "adjacentIsland", isNullable(MrsUadEnum.isValue)) &&
    hasOptionalKey(
      data,
      "adjacentErodingBankFace",
      isNullable(MrsUadEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "adjacentUndercutBankFace",
      isNullable(MrsUadEnum.isValue)
    ) &&
    hasOptionalKey(data, "adjacentSideChannel", isNullable(MrsUadEnum.isValue))
  );
}

interface JamDimensions {
  completed: boolean;
  morphRiverWidth?: number | null;
  acrossChannelWidth?: number | null;
  jamLength?: number | null;
  jamMaxHeightAboveChannelBed?: number | null;
  bottomOfJamHeightAboveChannelBed?: number | null;
  horizontalOrientationOfJam?: MrsHorizontalOrientation | null;
  largestWoodPieceLength?: number | null;
  largestWoodPieceDiameter?: number | null;
}

function isJamDimensions(data: unknown): data is JamDimensions {
  return (
    hasKey(data, "completed", isBoolean) &&
    hasOptionalKey(data, "morphRiverWidth", isNullable(isNumber)) &&
    hasOptionalKey(data, "acrossChannelWidth", isNullable(isNumber)) &&
    hasOptionalKey(data, "jamLength", isNullable(isNumber)) &&
    hasOptionalKey(data, "jamMaxHeightAboveChannelBed", isNullable(isNumber)) &&
    hasOptionalKey(
      data,
      "bottomOfJamHeightAboveChannelBed",
      isNullable(isNumber)
    ) &&
    hasOptionalKey(
      data,
      "horizontalOrientationOfJam",
      isNullable(MrsHorizontalOrientationEnum.isValue)
    ) &&
    hasOptionalKey(data, "largestWoodPieceLength", isNullable(isNumber)) &&
    hasOptionalKey(data, "largestWoodPieceDiameter", isNullable(isNumber))
  );
}

interface PartialData {
  generalInformation: GeneralInformation;
  jamDetails: JamDetails;
  riverChannelDimensions: RiverChannelDimensions;
  jamAnchorage: JamAnchorage;
  jamDimensions: JamDimensions;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasKey(data, "generalInformation", isGeneralInformation) &&
    hasKey(data, "jamDetails", isJamDetails) &&
    hasKey(data, "riverChannelDimensions", isRiverChannelDimensions) &&
    hasKey(data, "jamAnchorage", isJamAnchorage) &&
    hasKey(data, "jamDimensions", isJamDimensions)
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data.generalInformation.riverName ?? "Unknown River",
      data.generalInformation.reachName ?? "Unknown Reach",
      data.generalInformation.jamNumber ?? "Unknown Jam Number",
    ].join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(
    (data: PartialData) => data.generalInformation.recorded ?? null
  );
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map(
    (data: PartialData) => data.jamDetails.jamLocation ?? null
  );
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
    generalInformation: {
      ...data.generalInformation,
      bedVisible: false,
      adverseConditions: false,
      adverseConditionsNotes: null,
    },
  }));
}
