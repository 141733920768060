// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21River =
  | "alburyTributary"
  | "alderBourne"
  | "ardeleyBrook"
  | "ashdownDriveDrain"
  | "ashHerts"
  | "ashSurrey"
  | "ashtonBrook"
  | "aspendenBrook"
  | "astonEndBrook"
  | "barkingCreek"
  | "barnetDitches"
  | "barnetLaneDrain"
  | "barwickTributary"
  | "bayfordBrook"
  | "beam"
  | "beane"
  | "beddingtonEffluentCarrier"
  | "beggarsBushLaneDrain"
  | "bentleyheathBrook"
  | "berdenBrook"
  | "beverleyBrook"
  | "billyLowsLaneDrain"
  | "birchangerBrook"
  | "blacksBrook"
  | "bolderosTributary"
  | "bonesgateStream"
  | "borehamwoodBrook"
  | "borleyGreenTributary"
  | "boundsGreenBrook"
  | "bowCreek"
  | "brandonsTributary"
  | "braughingBourne"
  | "braughingWarrenBourne"
  | "braziersFieldDrain"
  | "brent"
  | "brentPelhamTributary"
  | "brickendonBrook"
  | "broadfieldsDitch"
  | "broadfieldTributary"
  | "brookhouseBrook"
  | "broxbourneDitch"
  | "bucknallsBrook"
  | "bulbourne"
  | "buncesDitch"
  | "burntOakBrook"
  | "buryGreenBrook"
  | "busheyHeathDrain"
  | "butterwickBrook"
  | "buzzardsMouthSewer"
  | "cannonBrook"
  | "canonsBrook"
  | "cartersBrook"
  | "catBrook"
  | "caterhamBourne"
  | "catherineBourne"
  | "chaffinchBrook"
  | "chaffinchBrookWestBranch"
  | "channelseaRiver"
  | "chelsingsTributary"
  | "chess"
  | "chigwellBrook"
  | "churchDriveDrain"
  | "churchLaneDitch"
  | "cityMillRiver"
  | "clarehallBrook"
  | "clattersfordBrook"
  | "clitterhouseBrook"
  | "cloistersBrook"
  | "cobbinsBrook"
  | "collegeBrook"
  | "colne"
  | "colvilleHallBrook"
  | "coombeBrook"
  | "coopersBrook"
  | "coppedHallBrook"
  | "costonsBrook"
  | "cottesbrookDitch"
  | "countyDitch"
  | "cowleyHillDrain"
  | "cradleEndBrook"
  | "cranBrook"
  | "crane"
  | "cray"
  | "cressallsDitch"
  | "cressallsDitchSouth"
  | "cripseyBrook"
  | "croxleyhallStreams"
  | "cuffleyBrook"
  | "dagenhamBrook"
  | "daneEndTributary"
  | "darent"
  | "dartfordCrayfordCreeks"
  | "deansBrook"
  | "decoyBrook"
  | "deersHillRoad"
  | "dollisBrook"
  | "dukeOfNorthumberlands"
  | "easternFloodChannel"
  | "eastPyl"
  | "eastwickBrook"
  | "eastwickBrookWestArm"
  | "edgwareBrook"
  | "edgwareburyBrook"
  | "ellenBrook"
  | "elmRoadDitch"
  | "emersonParkStream"
  | "enfieldDitch"
  | "eppingRoadDrain"
  | "essendonBrook"
  | "ewellCourtStream"
  | "farnhamBourne"
  | "farnhamsTributaries"
  | "farnhamsTributariesNorthWestArm"
  | "fiddlersBrook"
  | "fiddlersBrookWestArm"
  | "figgsMarshDitch"
  | "fireStationDitch"
  | "fleet"
  | "follyBrook"
  | "fraysAvenueLoops"
  | "frogsDitch"
  | "furneauxPelhamTributary"
  | "gade"
  | "gaderBrook"
  | "glenbrookSouthDrain"
  | "goresBrook"
  | "grandUnionCanal"
  | "grandUnionCanalPaddingtonArm"
  | "grandUnionCanalSloughArm"
  | "greatHallingburyBrook"
  | "greatHormeadBrook"
  | "greenLanesStream"
  | "groveParkDitch"
  | "haleyHillDitch"
  | "hansteadDitch"
  | "harlowburyBrook"
  | "hatfieldHydeBrook"
  | "hawthornDitch"
  | "heartsBourne"
  | "hempshillBrook"
  | "hendonCemetryDrain"
  | "hertfordUnionCanal"
  | "highbridgeStream"
  | "highRoadDrain"
  | "highWychDitch"
  | "hilfieldBrook"
  | "hillmansBrook"
  | "hillmansBrookEast"
  | "hogsmill"
  | "holmeLodgeDitch"
  | "holmshillBrook"
  | "holyfieldChannel"
  | "holyhillBrook"
  | "honeyLaneBrook"
  | "honeypotStream"
  | "hookmarshDitch"
  | "hortonStream"
  | "houghtonBrook"
  | "hounsdenGutter"
  | "hunsdonBrook"
  | "ickenhamStream"
  | "ingrebourne"
  | "interceptingDrain"
  | "ivyChimneyBrook"
  | "joelStreetFarmDitch"
  | "kelshallTributary"
  | "kendallsBrook"
  | "kentonBrook"
  | "kidBrook"
  | "kingGeorgeDrain"
  | "kingswoodburyTributary"
  | "kittsEndStream"
  | "kitwellsBrook"
  | "kydBrook"
  | "kydBrookEastBranch"
  | "lambourneEndBrook"
  | "lawrenceAvenueDrain"
  | "lee"
  | "leeFloodReliefChannel"
  | "leegingBeechBrook"
  | "leeNavigationLower"
  | "leeNewCut"
  | "lewseyBrook"
  | "lichenBrook"
  | "limehouseCut"
  | "littleHallingburyBrook"
  | "littleHormeadBrook"
  | "littleLondonBrook"
  | "lloydTaylorDrain"
  | "londonColneyStream"
  | "loughtonBrook"
  | "loughtonHallFarmDitch"
  | "lowerKidBrookNewRoute"
  | "loxfordWater"
  | "lynch"
  | "madanRoadStream"
  | "madBessBrook"
  | "magnavilleRoadDrain"
  | "maplelodgeFarmDitch"
  | "maxwellRoadDrain"
  | "mayesBrook"
  | "meesdenTributary"
  | "merrylHillsBrook"
  | "millerGreenBrook"
  | "mimmshallBrook"
  | "mimmsHallDrain"
  | "mimram"
  | "misbourne"
  | "mitchellsBrook"
  | "monkenMeadBrook"
  | "moorHallTributary"
  | "moorParkStream"
  | "moselleBrook"
  | "mountGraceRoadDrain"
  | "muttonBrook"
  | "nast"
  | "nazeingBrook"
  | "nazeingDrain"
  | "newyearsGreenBourne"
  | "nimneyBourne"
  | "norburyBrook"
  | "northawBrook"
  | "northWealdBassetDrain"
  | "northWealdBrook"
  | "northWealdFloodByPassChan"
  | "nuthampsteadTributary"
  | "oakhamptonRoadDrain"
  | "oakmereDrain"
  | "offeygreenFarmTributary"
  | "oilyDitch"
  | "oldRiverStortCulvert"
  | "osterlyParkBoundaryStream"
  | "overflowBrook"
  | "oxheyStream"
  | "painesBrook"
  | "paperMillLaneDitch"
  | "parkfieldDrain"
  | "parndonBrook"
  | "parndonMeadDitch"
  | "parsonageLaneDitch"
  | "partridgeDriveDitch"
  | "patientEndTributary"
  | "patmoreHallTributary"
  | "pennPonds"
  | "philpottsTributary"
  | "pickleDitch"
  | "pinceyBrook"
  | "pinn"
  | "poleHoleBrook"
  | "poolRiver"
  | "poolRiverWestBranch"
  | "pottersBarBrook"
  | "prescottChannel"
  | "puckeridgeTributary"
  | "pylBrook"
  | "pymmesBrook"
  | "pyrlesBrook"
  | "quaggy"
  | "quaggyEastBranch"
  | "quaggyHitherGreenBranch"
  | "queensBrook"
  | "quin"
  | "quintonHillBrook"
  | "radlettBrook"
  | "ragsBrook"
  | "rainhamCreek"
  | "ravensbourne"
  | "ravensbourneDeptfrdTidal"
  | "ravensbourneDitch"
  | "ravensbourneEastBranch"
  | "ravensbourneHonorOak"
  | "ravensbourneRomford"
  | "ravensbourneSouthBranch"
  | "redbourneDitch"
  | "reedEndTributary"
  | "reedTributary"
  | "regentsCanal"
  | "reliefChannel"
  | "rib"
  | "riverGraveney"
  | "riversideDrain"
  | "roding"
  | "rom"
  | "rowleyLaneDrain"
  | "rowleysRoadDrain"
  | "roydonBrook"
  | "rusholtBrook"
  | "rythe"
  | "saddlersMillStream"
  | "sadlersMeadDrain"
  | "salisburyHallBrook"
  | "salmonsBrook"
  | "sandonTributary"
  | "sawbridgeworthBrook"
  | "shenleyRoadDrain"
  | "shuttle"
  | "silkStream"
  | "smallLee"
  | "spellbrook"
  | "spindleHillDrain"
  | "spitalBrook"
  | "springBrook"
  | "springwoodCrescentDrain"
  | "stanfordHallBrook"
  | "stanhamRiver"
  | "stansteadAbbotsDrain"
  | "stansteadBrook"
  | "stanwellBrook"
  | "stanwellDitches"
  | "stanwellMoorDitch"
  | "stapletonRoadDrain"
  | "stationRoadDrain"
  | "stevenageBrook"
  | "sticklingGreenBrook"
  | "stJamesStream"
  | "stockingPelhamTributary"
  | "stondonHallBrook"
  | "stonebridgeBrook"
  | "stoneRoadDitch"
  | "stort"
  | "stortfordHallPark"
  | "stroodHallBrook"
  | "stThomasCreek"
  | "summerstownDitch"
  | "surbitonStream"
  | "takeleyDrain"
  | "talBrook"
  | "tannisCourtTributary"
  | "thames"
  | "theBeck"
  | "theBeckEastBranch"
  | "theBourne"
  | "theChing"
  | "theOldBourne"
  | "thePasturesDitch"
  | "therapiaLaneDitch"
  | "theRiseDrain"
  | "thistleyValeBrook"
  | "thorleyTributariesNorthArm"
  | "thorleyTributariesWestArm"
  | "thornhillStoragePondDitch"
  | "thornwoodCommonBrook"
  | "threeMillsRiver"
  | "toddBrook"
  | "tommyLeeSewerEast"
  | "townDitch"
  | "tramwayDitchColindale"
  | "trimsGreenBrook"
  | "trinityMarshDitch"
  | "troutLaneDrain"
  | "turkeyBrook"
  | "turnfordBrook"
  | "tyeGreenBrook"
  | "tyttenhangerStream"
  | "ugleyBrook"
  | "valeBrook"
  | "ver"
  | "victoriaWaterCourse"
  | "wadesmillBourne"
  | "wandle"
  | "wandleBeddingtonOrCroydonBranch"
  | "wandleCarshaltonBranch"
  | "wantsunt"
  | "wantzStream"
  | "wareParkMillStream"
  | "watercressStream"
  | "waterendDitch"
  | "waterfieldsWayDitch"
  | "waterworksRiver"
  | "wateryLaneDrain"
  | "watlingDitch"
  | "wealdBrook"
  | "wealdstoneBrook"
  | "wellsBrook"
  | "wembleyBrook"
  | "westBedfontDitches"
  | "westerhamStream"
  | "westonTributary"
  | "whitehallTributary"
  | "whitingsSewer"
  | "whittonBrook"
  | "wildhillBrook"
  | "windmillLaneDitch"
  | "woodhallFarmTributary"
  | "woodhallGateDitch"
  | "woodridingsStream"
  | "woodsideGreenBrook"
  | "wrenwoodDrain"
  | "wrothamParkStream"
  | "wyncham"
  | "yeadingBrook"
  | "yeadingBrookEastArm"
  | "yeoveneyLodgeDitch"
  | "other";

const AlburyTributary: Thames21River = "alburyTributary";
const AlderBourne: Thames21River = "alderBourne";
const ArdeleyBrook: Thames21River = "ardeleyBrook";
const AshdownDriveDrain: Thames21River = "ashdownDriveDrain";
const AshHerts: Thames21River = "ashHerts";
const AshSurrey: Thames21River = "ashSurrey";
const AshtonBrook: Thames21River = "ashtonBrook";
const AspendenBrook: Thames21River = "aspendenBrook";
const AstonEndBrook: Thames21River = "astonEndBrook";
const BarkingCreek: Thames21River = "barkingCreek";
const BarnetDitches: Thames21River = "barnetDitches";
const BarnetLaneDrain: Thames21River = "barnetLaneDrain";
const BarwickTributary: Thames21River = "barwickTributary";
const BayfordBrook: Thames21River = "bayfordBrook";
const Beam: Thames21River = "beam";
const Beane: Thames21River = "beane";
const BeddingtonEffluentCarrier: Thames21River = "beddingtonEffluentCarrier";
const BeggarsBushLaneDrain: Thames21River = "beggarsBushLaneDrain";
const BentleyheathBrook: Thames21River = "bentleyheathBrook";
const BerdenBrook: Thames21River = "berdenBrook";
const BeverleyBrook: Thames21River = "beverleyBrook";
const BillyLowsLaneDrain: Thames21River = "billyLowsLaneDrain";
const BirchangerBrook: Thames21River = "birchangerBrook";
const BlacksBrook: Thames21River = "blacksBrook";
const BolderosTributary: Thames21River = "bolderosTributary";
const BonesgateStream: Thames21River = "bonesgateStream";
const BorehamwoodBrook: Thames21River = "borehamwoodBrook";
const BorleyGreenTributary: Thames21River = "borleyGreenTributary";
const BoundsGreenBrook: Thames21River = "boundsGreenBrook";
const BowCreek: Thames21River = "bowCreek";
const BrandonsTributary: Thames21River = "brandonsTributary";
const BraughingBourne: Thames21River = "braughingBourne";
const BraughingWarrenBourne: Thames21River = "braughingWarrenBourne";
const BraziersFieldDrain: Thames21River = "braziersFieldDrain";
const Brent: Thames21River = "brent";
const BrentPelhamTributary: Thames21River = "brentPelhamTributary";
const BrickendonBrook: Thames21River = "brickendonBrook";
const BroadfieldsDitch: Thames21River = "broadfieldsDitch";
const BroadfieldTributary: Thames21River = "broadfieldTributary";
const BrookhouseBrook: Thames21River = "brookhouseBrook";
const BroxbourneDitch: Thames21River = "broxbourneDitch";
const BucknallsBrook: Thames21River = "bucknallsBrook";
const Bulbourne: Thames21River = "bulbourne";
const BuncesDitch: Thames21River = "buncesDitch";
const BurntOakBrook: Thames21River = "burntOakBrook";
const BuryGreenBrook: Thames21River = "buryGreenBrook";
const BusheyHeathDrain: Thames21River = "busheyHeathDrain";
const ButterwickBrook: Thames21River = "butterwickBrook";
const BuzzardsMouthSewer: Thames21River = "buzzardsMouthSewer";
const CannonBrook: Thames21River = "cannonBrook";
const CanonsBrook: Thames21River = "canonsBrook";
const CartersBrook: Thames21River = "cartersBrook";
const CatBrook: Thames21River = "catBrook";
const CaterhamBourne: Thames21River = "caterhamBourne";
const CatherineBourne: Thames21River = "catherineBourne";
const ChaffinchBrook: Thames21River = "chaffinchBrook";
const ChaffinchBrookWestBranch: Thames21River = "chaffinchBrookWestBranch";
const ChannelseaRiver: Thames21River = "channelseaRiver";
const ChelsingsTributary: Thames21River = "chelsingsTributary";
const Chess: Thames21River = "chess";
const ChigwellBrook: Thames21River = "chigwellBrook";
const ChurchDriveDrain: Thames21River = "churchDriveDrain";
const ChurchLaneDitch: Thames21River = "churchLaneDitch";
const CityMillRiver: Thames21River = "cityMillRiver";
const ClarehallBrook: Thames21River = "clarehallBrook";
const ClattersfordBrook: Thames21River = "clattersfordBrook";
const ClitterhouseBrook: Thames21River = "clitterhouseBrook";
const CloistersBrook: Thames21River = "cloistersBrook";
const CobbinsBrook: Thames21River = "cobbinsBrook";
const CollegeBrook: Thames21River = "collegeBrook";
const Colne: Thames21River = "colne";
const ColvilleHallBrook: Thames21River = "colvilleHallBrook";
const CoombeBrook: Thames21River = "coombeBrook";
const CoopersBrook: Thames21River = "coopersBrook";
const CoppedHallBrook: Thames21River = "coppedHallBrook";
const CostonsBrook: Thames21River = "costonsBrook";
const CottesbrookDitch: Thames21River = "cottesbrookDitch";
const CountyDitch: Thames21River = "countyDitch";
const CowleyHillDrain: Thames21River = "cowleyHillDrain";
const CradleEndBrook: Thames21River = "cradleEndBrook";
const CranBrook: Thames21River = "cranBrook";
const Crane: Thames21River = "crane";
const Cray: Thames21River = "cray";
const CressallsDitch: Thames21River = "cressallsDitch";
const CressallsDitchSouth: Thames21River = "cressallsDitchSouth";
const CripseyBrook: Thames21River = "cripseyBrook";
const CroxleyhallStreams: Thames21River = "croxleyhallStreams";
const CuffleyBrook: Thames21River = "cuffleyBrook";
const DagenhamBrook: Thames21River = "dagenhamBrook";
const DaneEndTributary: Thames21River = "daneEndTributary";
const Darent: Thames21River = "darent";
const DartfordCrayfordCreeks: Thames21River = "dartfordCrayfordCreeks";
const DeansBrook: Thames21River = "deansBrook";
const DecoyBrook: Thames21River = "decoyBrook";
const DeersHillRoad: Thames21River = "deersHillRoad";
const DollisBrook: Thames21River = "dollisBrook";
const DukeOfNorthumberlands: Thames21River = "dukeOfNorthumberlands";
const EasternFloodChannel: Thames21River = "easternFloodChannel";
const EastPyl: Thames21River = "eastPyl";
const EastwickBrook: Thames21River = "eastwickBrook";
const EastwickBrookWestArm: Thames21River = "eastwickBrookWestArm";
const EdgewareBrook: Thames21River = "edgwareBrook";
const EdwareburyBrook: Thames21River = "edgwareburyBrook";
const EllenBrook: Thames21River = "ellenBrook";
const ElmRoadDitch: Thames21River = "elmRoadDitch";
const EmersonParkStream: Thames21River = "emersonParkStream";
const EnfieldDitch: Thames21River = "enfieldDitch";
const EppingRoadDrain: Thames21River = "eppingRoadDrain";
const EssendonBrook: Thames21River = "essendonBrook";
const EwellCourtStream: Thames21River = "ewellCourtStream";
const FarnhamBourne: Thames21River = "farnhamBourne";
const FarnhamsTributaries: Thames21River = "farnhamsTributaries";
const FarnhamsTributariesNorthWestArm: Thames21River =
  "farnhamsTributariesNorthWestArm";
const FiddlersBrook: Thames21River = "fiddlersBrook";
const FiddlersBrookWestArm: Thames21River = "fiddlersBrookWestArm";
const FiggsMarshDitch: Thames21River = "figgsMarshDitch";
const FireStationDitch: Thames21River = "fireStationDitch";
const Fleet: Thames21River = "fleet";
const FollyBrook: Thames21River = "follyBrook";
const FraysAvenueLoops: Thames21River = "fraysAvenueLoops";
const FrogsDitch: Thames21River = "frogsDitch";
const FurneauxPelhamTributary: Thames21River = "furneauxPelhamTributary";
const Gade: Thames21River = "gade";
const GaderBrook: Thames21River = "gaderBrook";
const GlenbrookSouthDrain: Thames21River = "glenbrookSouthDrain";
const GoresBrook: Thames21River = "goresBrook";
const GrandUnionCanal: Thames21River = "grandUnionCanal";
const GrandUnionCanalPaddingtonArm: Thames21River =
  "grandUnionCanalPaddingtonArm";
const GrandUnionCanalSloughArm: Thames21River = "grandUnionCanalSloughArm";
const GreatHallingburyBrook: Thames21River = "greatHallingburyBrook";
const GreatHormeadBrook: Thames21River = "greatHormeadBrook";
const GreenLanesStream: Thames21River = "greenLanesStream";
const GroveParkDitch: Thames21River = "groveParkDitch";
const HaleyHillDitch: Thames21River = "haleyHillDitch";
const HansteadDitch: Thames21River = "hansteadDitch";
const HarlowburyBrook: Thames21River = "harlowburyBrook";
const HatfieldHydeBrook: Thames21River = "hatfieldHydeBrook";
const HawthornDitch: Thames21River = "hawthornDitch";
const HeartsBourne: Thames21River = "heartsBourne";
const HempshillBrook: Thames21River = "hempshillBrook";
const HendonCemetryDrain: Thames21River = "hendonCemetryDrain";
const HertfordUnionCanal: Thames21River = "hertfordUnionCanal";
const HighbridgeStream: Thames21River = "highbridgeStream";
const HighRoadDrain: Thames21River = "highRoadDrain";
const HighWychDitch: Thames21River = "highWychDitch";
const HilfieldBrook: Thames21River = "hilfieldBrook";
const HillmansBrook: Thames21River = "hillmansBrook";
const HillmansBrookEast: Thames21River = "hillmansBrookEast";
const Hogsmill: Thames21River = "hogsmill";
const HolmeLodgeDitch: Thames21River = "holmeLodgeDitch";
const HolmshillBrook: Thames21River = "holmshillBrook";
const HolyfieldChannel: Thames21River = "holyfieldChannel";
const HolyhillBrook: Thames21River = "holyhillBrook";
const HoneyLaneBrook: Thames21River = "honeyLaneBrook";
const HoneypotStream: Thames21River = "honeypotStream";
const HookmarshDitch: Thames21River = "hookmarshDitch";
const HortonStream: Thames21River = "hortonStream";
const HoughtonBrook: Thames21River = "houghtonBrook";
const HounsdenGutter: Thames21River = "hounsdenGutter";
const HunsdonBrook: Thames21River = "hunsdonBrook";
const IckenhamStream: Thames21River = "ickenhamStream";
const Ingrebourne: Thames21River = "ingrebourne";
const InterceptingDrain: Thames21River = "interceptingDrain";
const IvyChimneyBrook: Thames21River = "ivyChimneyBrook";
const JoelStreetFarmDitch: Thames21River = "joelStreetFarmDitch";
const KelshallTributary: Thames21River = "kelshallTributary";
const KendallsBrook: Thames21River = "kendallsBrook";
const KentonBrook: Thames21River = "kentonBrook";
const KidBrook: Thames21River = "kidBrook";
const KingGeorgeDrain: Thames21River = "kingGeorgeDrain";
const KingswoodburyTributary: Thames21River = "kingswoodburyTributary";
const KittsEndStream: Thames21River = "kittsEndStream";
const KitwellsBrook: Thames21River = "kitwellsBrook";
const KydBrook: Thames21River = "kydBrook";
const KydBrookEastBranch: Thames21River = "kydBrookEastBranch";
const LambourneEndBrook: Thames21River = "lambourneEndBrook";
const LawrenceAvenueDrain: Thames21River = "lawrenceAvenueDrain";
const Lee: Thames21River = "lee";
const LeeFloodReliefChannel: Thames21River = "leeFloodReliefChannel";
const LeegingBeechBrook: Thames21River = "leegingBeechBrook";
const LeeNavigationLower: Thames21River = "leeNavigationLower";
const LeeNewCut: Thames21River = "leeNewCut";
const LewseyBrook: Thames21River = "lewseyBrook";
const LichenBrook: Thames21River = "lichenBrook";
const LimehouseCut: Thames21River = "limehouseCut";
const LittleHallingburyBrook: Thames21River = "littleHallingburyBrook";
const LittleHormeadBrook: Thames21River = "littleHormeadBrook";
const LittleLondonBrook: Thames21River = "littleLondonBrook";
const LloydTaylorDrain: Thames21River = "lloydTaylorDrain";
const LondonColneyStream: Thames21River = "londonColneyStream";
const LoughtonBrook: Thames21River = "loughtonBrook";
const LoughtonHallFarmDitch: Thames21River = "loughtonHallFarmDitch";
const LowerKidBrookNewRoute: Thames21River = "lowerKidBrookNewRoute";
const LoxfordWater: Thames21River = "loxfordWater";
const Lynch: Thames21River = "lynch";
const MadanRoadStream: Thames21River = "madanRoadStream";
const MadBessBrook: Thames21River = "madBessBrook";
const MagnavilleRoadDrain: Thames21River = "magnavilleRoadDrain";
const MaplelodgeFarmDitch: Thames21River = "maplelodgeFarmDitch";
const MaxwellRoadDrain: Thames21River = "maxwellRoadDrain";
const MayesBrook: Thames21River = "mayesBrook";
const MeesdenTributary: Thames21River = "meesdenTributary";
const MerrylHillsBrook: Thames21River = "merrylHillsBrook";
const MillerGreenBrook: Thames21River = "millerGreenBrook";
const MimmshallBrook: Thames21River = "mimmshallBrook";
const MimmsHallDrain: Thames21River = "mimmsHallDrain";
const Mimram: Thames21River = "mimram";
const Misbourne: Thames21River = "misbourne";
const MitchellsBrook: Thames21River = "mitchellsBrook";
const MonkenMeadBrook: Thames21River = "monkenMeadBrook";
const MoorHallTributary: Thames21River = "moorHallTributary";
const MoorParkStream: Thames21River = "moorParkStream";
const MoselleBrook: Thames21River = "moselleBrook";
const MountGraceRoadDrain: Thames21River = "mountGraceRoadDrain";
const MuttonBrook: Thames21River = "muttonBrook";
const Nast: Thames21River = "nast";
const NazeingBrook: Thames21River = "nazeingBrook";
const NazeingDrain: Thames21River = "nazeingDrain";
const NewyearsGreenBourne: Thames21River = "newyearsGreenBourne";
const NimneyBourne: Thames21River = "nimneyBourne";
const NorburyBrook: Thames21River = "norburyBrook";
const NorthawBrook: Thames21River = "northawBrook";
const NorthWealdBassetDrain: Thames21River = "northWealdBassetDrain";
const NorthWealdBrook: Thames21River = "northWealdBrook";
const NorthWealdFloodByPassChan: Thames21River = "northWealdFloodByPassChan";
const NuthampsteadTributary: Thames21River = "nuthampsteadTributary";
const OakhamptonRoadDrain: Thames21River = "oakhamptonRoadDrain";
const OakmereDrain: Thames21River = "oakmereDrain";
const OffeygreenFarmTributary: Thames21River = "offeygreenFarmTributary";
const OilyDitch: Thames21River = "oilyDitch";
const OldRiverStortCulvert: Thames21River = "oldRiverStortCulvert";
const OsterlyParkBoundaryStream: Thames21River = "osterlyParkBoundaryStream";
const OverflowBrook: Thames21River = "overflowBrook";
const OxheyStream: Thames21River = "oxheyStream";
const PainesBrook: Thames21River = "painesBrook";
const PaperMillLaneDitch: Thames21River = "paperMillLaneDitch";
const ParkfieldDrain: Thames21River = "parkfieldDrain";
const ParndonBrook: Thames21River = "parndonBrook";
const ParndonMeadDitch: Thames21River = "parndonMeadDitch";
const ParsonageLaneDitch: Thames21River = "parsonageLaneDitch";
const PartridgeDriveDitch: Thames21River = "partridgeDriveDitch";
const PatientEndTributary: Thames21River = "patientEndTributary";
const PatmoreHallTributary: Thames21River = "patmoreHallTributary";
const PennPonds: Thames21River = "pennPonds";
const PhilpottsTributary: Thames21River = "philpottsTributary";
const PickleDitch: Thames21River = "pickleDitch";
const PinceyBrook: Thames21River = "pinceyBrook";
const Pinn: Thames21River = "pinn";
const PoleHoleBrook: Thames21River = "poleHoleBrook";
const PoolRiver: Thames21River = "poolRiver";
const PoolRiverWestBranch: Thames21River = "poolRiverWestBranch";
const PottersBarBrook: Thames21River = "pottersBarBrook";
const PrescottChannel: Thames21River = "prescottChannel";
const PuckeridgeTributary: Thames21River = "puckeridgeTributary";
const PylBrook: Thames21River = "pylBrook";
const PymmesBrook: Thames21River = "pymmesBrook";
const PyrlesBrook: Thames21River = "pyrlesBrook";
const Quaggy: Thames21River = "quaggy";
const QuaggyEastBranch: Thames21River = "quaggyEastBranch";
const QuaggyHitherGreenBranch: Thames21River = "quaggyHitherGreenBranch";
const QueensBrook: Thames21River = "queensBrook";
const Quin: Thames21River = "quin";
const QuintonHillBrook: Thames21River = "quintonHillBrook";
const RadlettBrook: Thames21River = "radlettBrook";
const RagsBrook: Thames21River = "ragsBrook";
const RainhamCreek: Thames21River = "rainhamCreek";
const Ravensbourne: Thames21River = "ravensbourne";
const RavensbourneDeptfrdTidal: Thames21River = "ravensbourneDeptfrdTidal";
const RavensbourneDitch: Thames21River = "ravensbourneDitch";
const RavensbourneEastBranch: Thames21River = "ravensbourneEastBranch";
const RavensbourneHonorOak: Thames21River = "ravensbourneHonorOak";
const RavensbourneRomford: Thames21River = "ravensbourneRomford";
const RavensbourneSouthBranch: Thames21River = "ravensbourneSouthBranch";
const RedbourneDitch: Thames21River = "redbourneDitch";
const ReedEndTributary: Thames21River = "reedEndTributary";
const ReedTributary: Thames21River = "reedTributary";
const RegentsCanal: Thames21River = "regentsCanal";
const ReliefChannel: Thames21River = "reliefChannel";
const Rib: Thames21River = "rib";
const RiverGraveney: Thames21River = "riverGraveney";
const RiversideDrain: Thames21River = "riversideDrain";
const Roding: Thames21River = "roding";
const Rom: Thames21River = "rom";
const RowleyLaneDrain: Thames21River = "rowleyLaneDrain";
const RowleysRoadDrain: Thames21River = "rowleysRoadDrain";
const RoydonBrook: Thames21River = "roydonBrook";
const RusholtBrook: Thames21River = "rusholtBrook";
const Rythe: Thames21River = "rythe";
const SaddlersMillStream: Thames21River = "saddlersMillStream";
const SadlersMeadDrain: Thames21River = "sadlersMeadDrain";
const SalisburyHallBrook: Thames21River = "salisburyHallBrook";
const SalmonsBrook: Thames21River = "salmonsBrook";
const SandonTributary: Thames21River = "sandonTributary";
const SawbridgeworthBrook: Thames21River = "sawbridgeworthBrook";
const ShenleyRoadDrain: Thames21River = "shenleyRoadDrain";
const Shuttle: Thames21River = "shuttle";
const SilkStream: Thames21River = "silkStream";
const SmallLee: Thames21River = "smallLee";
const Spellbrook: Thames21River = "spellbrook";
const SpindleHillDrain: Thames21River = "spindleHillDrain";
const SpitalBrook: Thames21River = "spitalBrook";
const SpringBrook: Thames21River = "springBrook";
const SpringwoodCrescentDrain: Thames21River = "springwoodCrescentDrain";
const StanfordHallBrook: Thames21River = "stanfordHallBrook";
const StanhamRiver: Thames21River = "stanhamRiver";
const StansteadAbbotsDrain: Thames21River = "stansteadAbbotsDrain";
const StansteadBrook: Thames21River = "stansteadBrook";
const StanwellBrook: Thames21River = "stanwellBrook";
const StanwellDitches: Thames21River = "stanwellDitches";
const StanwellMoorDitch: Thames21River = "stanwellMoorDitch";
const StapletonRoadDrain: Thames21River = "stapletonRoadDrain";
const StationRoadDrain: Thames21River = "stationRoadDrain";
const StevenageBrook: Thames21River = "stevenageBrook";
const SticklingGreenBrook: Thames21River = "sticklingGreenBrook";
const StJamesStream: Thames21River = "stJamesStream";
const StockingPelhamTributary: Thames21River = "stockingPelhamTributary";
const StondonHallBrook: Thames21River = "stondonHallBrook";
const StonebridgeBrook: Thames21River = "stonebridgeBrook";
const StoneRoadDitch: Thames21River = "stoneRoadDitch";
const Stort: Thames21River = "stort";
const StortfordHallPark: Thames21River = "stortfordHallPark";
const StroodHallBrook: Thames21River = "stroodHallBrook";
const StThomasCreek: Thames21River = "stThomasCreek";
const SummerstownDitch: Thames21River = "summerstownDitch";
const SurbitonStream: Thames21River = "surbitonStream";
const TakeleyDrain: Thames21River = "takeleyDrain";
const TalBrook: Thames21River = "talBrook";
const TannisCourtTributary: Thames21River = "tannisCourtTributary";
const Thames: Thames21River = "thames";
const TheBeck: Thames21River = "theBeck";
const TheBeckEastBranch: Thames21River = "theBeckEastBranch";
const TheBourne: Thames21River = "theBourne";
const TheChing: Thames21River = "theChing";
const TheOldBourne: Thames21River = "theOldBourne";
const ThePasturesDitch: Thames21River = "thePasturesDitch";
const TherapiaLaneDitch: Thames21River = "therapiaLaneDitch";
const TheRiseDrain: Thames21River = "theRiseDrain";
const ThistleyValeBrook: Thames21River = "thistleyValeBrook";
const ThorleyTributariesNorthArm: Thames21River = "thorleyTributariesNorthArm";
const ThorleyTributariesWestArm: Thames21River = "thorleyTributariesWestArm";
const ThornhillStoragePondDitch: Thames21River = "thornhillStoragePondDitch";
const ThornwoodCommonBrook: Thames21River = "thornwoodCommonBrook";
const ThreeMillsRiver: Thames21River = "threeMillsRiver";
const ToddBrook: Thames21River = "toddBrook";
const TommyLeeSewerEast: Thames21River = "tommyLeeSewerEast";
const TownDitch: Thames21River = "townDitch";
const TramwayDitchColindale: Thames21River = "tramwayDitchColindale";
const TrimsGreenBrook: Thames21River = "trimsGreenBrook";
const TrinityMarshDitch: Thames21River = "trinityMarshDitch";
const TroutLaneDrain: Thames21River = "troutLaneDrain";
const TurkeyBrook: Thames21River = "turkeyBrook";
const TurnfordBrook: Thames21River = "turnfordBrook";
const TyeGreenBrook: Thames21River = "tyeGreenBrook";
const TyttenhangerStream: Thames21River = "tyttenhangerStream";
const UgleyBrook: Thames21River = "ugleyBrook";
const ValeBrook: Thames21River = "valeBrook";
const Ver: Thames21River = "ver";
const VictoriaWaterCourse: Thames21River = "victoriaWaterCourse";
const WadesmillBourne: Thames21River = "wadesmillBourne";
const Wandle: Thames21River = "wandle";
const WandleBeddingtonOrCroydonBranch: Thames21River =
  "wandleBeddingtonOrCroydonBranch";
const WandleCarshaltonBranch: Thames21River = "wandleCarshaltonBranch";
const Wantsunt: Thames21River = "wantsunt";
const WantzStream: Thames21River = "wantzStream";
const WareParkMillStream: Thames21River = "wareParkMillStream";
const WatercressStream: Thames21River = "watercressStream";
const WaterendDitch: Thames21River = "waterendDitch";
const WaterfieldsWayDitch: Thames21River = "waterfieldsWayDitch";
const WaterworksRiver: Thames21River = "waterworksRiver";
const WateryLaneDrain: Thames21River = "wateryLaneDrain";
const WatlingDitch: Thames21River = "watlingDitch";
const WealdBrook: Thames21River = "wealdBrook";
const WealdstoneBrook: Thames21River = "wealdstoneBrook";
const WellsBrook: Thames21River = "wellsBrook";
const WembleyBrook: Thames21River = "wembleyBrook";
const WestBedfontDitches: Thames21River = "westBedfontDitches";
const WesterhamStream: Thames21River = "westerhamStream";
const WestonTributary: Thames21River = "westonTributary";
const WhitehallTributary: Thames21River = "whitehallTributary";
const WhitingsSewer: Thames21River = "whitingsSewer";
const WhittonBrook: Thames21River = "whittonBrook";
const WildhillBrook: Thames21River = "wildhillBrook";
const WindmillLaneDitch: Thames21River = "windmillLaneDitch";
const WoodhallFarmTributary: Thames21River = "woodhallFarmTributary";
const WoodhallGateDitch: Thames21River = "woodhallGateDitch";
const WoodridingsStream: Thames21River = "woodridingsStream";
const WoodsideGreenBrook: Thames21River = "woodsideGreenBrook";
const WrenwoodDrain: Thames21River = "wrenwoodDrain";
const WrothamParkStream: Thames21River = "wrothamParkStream";
const Wyncham: Thames21River = "wyncham";
const YeadingBrook: Thames21River = "yeadingBrook";
const YeadingBrookEastArm: Thames21River = "yeadingBrookEastArm";
const YeoveneyLodgeDitch: Thames21River = "yeoveneyLodgeDitch";
const Other: Thames21River = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21RiverEnum = {
  ...createEnum("Thames21River", [
    [AlburyTributary, "Albury Tributary"],
    [AlderBourne, "Alder Bourne"],
    [ArdeleyBrook, "Ardeley Brook"],
    [AshdownDriveDrain, "Ashdown Drive Drain"],
    [AshHerts, "Ash (Herts)"],
    [AshSurrey, "Ash (Surrey)"],
    [AshtonBrook, "Ashton Brook"],
    [AspendenBrook, "Aspenden Brook"],
    [AstonEndBrook, "Aston End Brook"],
    [BarkingCreek, "Barking Creek"],
    [BarnetDitches, "Barnet Ditches"],
    [BarnetLaneDrain, "Barnet Lane Drain"],
    [BarwickTributary, "Barwick Tributary"],
    [BayfordBrook, "Bayford Brook"],
    [Beam, "Beam"],
    [Beane, "Beane"],
    [BeddingtonEffluentCarrier, "Beddington Effluent Carrier"],
    [BeggarsBushLaneDrain, "Beggars Bush Lane Drain"],
    [BentleyheathBrook, "Bentleyheath Brook"],
    [BerdenBrook, "Berden Brook"],
    [BeverleyBrook, "Beverley Brook"],
    [BillyLowsLaneDrain, "Billy Lows Lane Drain"],
    [BirchangerBrook, "Birchanger Brook"],
    [BlacksBrook, "Blacks Brook"],
    [BolderosTributary, "Bolderos Tributary"],
    [BonesgateStream, "Bonesgate Stream"],
    [BorehamwoodBrook, "Borehamwood Brook"],
    [BorleyGreenTributary, "Borley Green Tributary"],
    [BoundsGreenBrook, "Bounds Green Brook"],
    [BowCreek, "Bow Creek"],
    [BrandonsTributary, "Brandons Tributary"],
    [BraughingBourne, "Braughing Bourne"],
    [BraughingWarrenBourne, "Braughing Warren Bourne"],
    [BraziersFieldDrain, "Braziers Field Drain"],
    [Brent, "Brent"],
    [BrentPelhamTributary, "Brent Pelham Tributary"],
    [BrickendonBrook, "Brickendon Brook"],
    [BroadfieldsDitch, "Broadfields Ditch"],
    [BroadfieldTributary, "Broadfield Tributary"],
    [BrookhouseBrook, "Brookhouse Brook"],
    [BroxbourneDitch, "Broxbourne Ditch"],
    [BucknallsBrook, "Bucknalls Brook"],
    [Bulbourne, "Bulbourne"],
    [BuncesDitch, "Bunces ditch"],
    [BurntOakBrook, "Burnt Oak Brook"],
    [BuryGreenBrook, "Bury Green Brook"],
    [BusheyHeathDrain, "Bushey Heath Drain"],
    [ButterwickBrook, "Butterwick Brook"],
    [BuzzardsMouthSewer, "Buzzards Mouth Sewer"],
    [CannonBrook, "Cannon Brook"],
    [CanonsBrook, "Canons Brook"],
    [CartersBrook, "Carters Brook"],
    [CatBrook, "Cat Brook"],
    [CaterhamBourne, "Caterham Bourne"],
    [CatherineBourne, "Catherine Bourne"],
    [ChaffinchBrook, "Chaffinch Brook"],
    [ChaffinchBrookWestBranch, "Chaffinch Brook (West Branch)"],
    [ChannelseaRiver, "Channelsea River"],
    [ChelsingsTributary, "Chelsings Tributary"],
    [Chess, "Chess"],
    [ChigwellBrook, "Chigwell Brook"],
    [ChurchDriveDrain, "Church Drive Drain"],
    [ChurchLaneDitch, "Church lane ditch"],
    [CityMillRiver, "City Mill River"],
    [ClarehallBrook, "Clarehall Brook"],
    [ClattersfordBrook, "Clattersford Brook"],
    [ClitterhouseBrook, "Clitterhouse Brook"],
    [CloistersBrook, "Cloisters Brook"],
    [CobbinsBrook, "Cobbins Brook"],
    [CollegeBrook, "College Brook"],
    [Colne, "Colne"],
    [ColvilleHallBrook, "Colville Hall Brook"],
    [CoombeBrook, "Coombe brook"],
    [CoopersBrook, "Coopers Brook"],
    [CoppedHallBrook, "Copped Hall Brook"],
    [CostonsBrook, "Costons Brook"],
    [CottesbrookDitch, "Cottesbrook Ditch"],
    [CountyDitch, "County Ditch"],
    [CowleyHillDrain, "Cowley Hill Drain"],
    [CradleEndBrook, "Cradle End Brook"],
    [CranBrook, "Cran Brook"],
    [Crane, "Crane"],
    [Cray, "Cray"],
    [CressallsDitch, "Cressalls Ditch"],
    [CressallsDitchSouth, "Cressalls Ditch (South)"],
    [CripseyBrook, "Cripsey Brook"],
    [CroxleyhallStreams, "Croxleyhall Streams"],
    [CuffleyBrook, "Cuffley Brook"],
    [DagenhamBrook, "Dagenham Brook"],
    [DaneEndTributary, "Dane End Tributary"],
    [Darent, "Darent"],
    [DartfordCrayfordCreeks, "Dartford and Crayford Creeks"],
    [DeansBrook, "Deans Brook"],
    [DecoyBrook, "Decoy Brook"],
    [DeersHillRoad, "Deers Hill Road"],
    [DollisBrook, "Dollis Brook"],
    [DukeOfNorthumberlands, "Duke Of Northumberlands"],
    [EasternFloodChannel, "Eastern Flood Channel"],
    [EastPyl, "East Pyl"],
    [EastwickBrook, "Eastwick Brook"],
    [EastwickBrookWestArm, "Eastwick Brook (West Arm)"],
    [EdgewareBrook, "Edgware Brook"],
    [EdwareburyBrook, "Edgwarebury Brook"],
    [EllenBrook, "Ellen Brook"],
    [ElmRoadDitch, "Elm Road Ditch"],
    [EmersonParkStream, "Emerson Park Stream"],
    [EnfieldDitch, "Enfield Ditch"],
    [EppingRoadDrain, "Epping Road Drain"],
    [EssendonBrook, "Essendon Brook"],
    [EwellCourtStream, "Ewell court stream"],
    [FarnhamBourne, "Farnham Bourne"],
    [FarnhamsTributaries, "Farnhams Tributaries"],
    [FarnhamsTributariesNorthWestArm, "Farnhams Tributaries (NW Arm)"],
    [FiddlersBrook, "Fiddlers Brook"],
    [FiddlersBrookWestArm, "Fiddlers Brook (West Arm)"],
    [FiggsMarshDitch, "Figg's Marsh Ditch"],
    [FireStationDitch, "Fire Station Ditch"],
    [Fleet, "Fleet"],
    [FollyBrook, "Folly Brook"],
    [FraysAvenueLoops, "Frays Avenue Loops"],
    [FrogsDitch, "Frogs Ditch"],
    [FurneauxPelhamTributary, "Furneaux Pelham Tributary"],
    [Gade, "Gade"],
    [GaderBrook, "Gader Brook"],
    [GlenbrookSouthDrain, "Glenbrook South Drain"],
    [GoresBrook, "Gores Brook"],
    [GrandUnionCanal, "Grand Union Canal"],
    [GrandUnionCanalPaddingtonArm, "Grand Union Canal (Paddington Arm)"],
    [GrandUnionCanalSloughArm, "Grand Union Canal (Slough Arm)"],
    [GreatHallingburyBrook, "Great Hallingbury Brook"],
    [GreatHormeadBrook, "Great Hormead Brook"],
    [GreenLanesStream, "Green lanes Stream"],
    [GroveParkDitch, "Grove Park Ditch"],
    [HaleyHillDitch, "Haley Hill Ditch"],
    [HansteadDitch, "Hanstead Ditch"],
    [HarlowburyBrook, "Harlowbury Brook"],
    [HatfieldHydeBrook, "Hatfield Hyde Brook"],
    [HawthornDitch, "Hawthorn Ditch"],
    [HeartsBourne, "Hearts Bourne"],
    [HempshillBrook, "Hempshill Brook"],
    [HendonCemetryDrain, "Hendon Cemetry Drain"],
    [HertfordUnionCanal, "Hertford Union Canal"],
    [HighbridgeStream, "Highbridge Stream"],
    [HighRoadDrain, "High Road Drain"],
    [HighWychDitch, "High Wych Ditch"],
    [HilfieldBrook, "Hilfield Brook"],
    [HillmansBrook, "Hillmans Brook"],
    [HillmansBrookEast, "Hillmans Brook East"],
    [Hogsmill, "Hogsmill"],
    [HolmeLodgeDitch, "Holme Lodge Ditch"],
    [HolmshillBrook, "Holmshill Brook"],
    [HolyfieldChannel, "Holyfield Channel"],
    [HolyhillBrook, "Holyhill Brook"],
    [HoneyLaneBrook, "Honey Lane Brook"],
    [HoneypotStream, "Honeypot Stream"],
    [HookmarshDitch, "Hookmarsh Ditch"],
    [HortonStream, "Horton stream"],
    [HoughtonBrook, "Houghton Brook"],
    [HounsdenGutter, "Hounsden Gutter"],
    [HunsdonBrook, "Hunsdon Brook"],
    [IckenhamStream, "Ickenham Stream"],
    [Ingrebourne, "Ingrebourne"],
    [InterceptingDrain, "Intercepting Drain"],
    [IvyChimneyBrook, "Ivy Chimney Brook"],
    [JoelStreetFarmDitch, "Joel Street Farm Ditch"],
    [KelshallTributary, "Kelshall Tributary"],
    [KendallsBrook, "Kendalls Brook"],
    [KentonBrook, "Kenton Brook (also known as Gadder Brook)"],
    [KidBrook, "Kid Brook"],
    [KingGeorgeDrain, "King George Drain"],
    [KingswoodburyTributary, "Kingswoodbury Tributary"],
    [KittsEndStream, "Kitts End Stream"],
    [KitwellsBrook, "Kitwells Brook"],
    [KydBrook, "Kyd Brook"],
    [KydBrookEastBranch, "Kyd Brook (East Branch)"],
    [LambourneEndBrook, "Lambourne End Brook"],
    [LawrenceAvenueDrain, "Lawrence Avenue Drain"],
    [Lee, "Lee"],
    [LeeFloodReliefChannel, "Lee Flood Relief Channel"],
    [LeegingBeechBrook, "Leeging Beech Brook"],
    [LeeNavigationLower, "Lee Navigation (Lower)"],
    [LeeNewCut, "Lee New Cut"],
    [LewseyBrook, "Lewsey Brook"],
    [LichenBrook, "Lichen Brook"],
    [LimehouseCut, "Limehouse Cut"],
    [LittleHallingburyBrook, "Little Hallingbury Brook"],
    [LittleHormeadBrook, "Little Hormead Brook"],
    [LittleLondonBrook, "Little London Brook"],
    [LloydTaylorDrain, "Lloyd Taylor Drain"],
    [LondonColneyStream, "London Colney Stream"],
    [LoughtonBrook, "Loughton Brook"],
    [LoughtonHallFarmDitch, "Loughton Hall Farm Ditch"],
    [LowerKidBrookNewRoute, "Lower Kid Brook (new route)"],
    [LoxfordWater, "Loxford Water"],
    [Lynch, "Lynch"],
    [MadanRoadStream, "Madan Road Stream"],
    [MadBessBrook, "Mad Bess Brook"],
    [MagnavilleRoadDrain, "Magnaville Road Drain"],
    [MaplelodgeFarmDitch, "Maplelodge Farm Ditch"],
    [MaxwellRoadDrain, "Maxwell Road Drain"],
    [MayesBrook, "Mayes Brook"],
    [MeesdenTributary, "Meesden Tributary"],
    [MerrylHillsBrook, "Merryl Hills Brook"],
    [MillerGreenBrook, "Miller Green Brook"],
    [MimmshallBrook, "Mimmshall Brook"],
    [MimmsHallDrain, "Mimms Hall Drain"],
    [Mimram, "Mimram"],
    [Misbourne, "Misbourne"],
    [MitchellsBrook, "Mitchells Brook (also known as Slade Brook)"],
    [MonkenMeadBrook, "Monken Mead Brook"],
    [MoorHallTributary, "Moor Hall Tributary"],
    [MoorParkStream, "Moor Park Stream"],
    [MoselleBrook, "Moselle Brook"],
    [MountGraceRoadDrain, "Mount Grace Road Drain"],
    [MuttonBrook, "Mutton Brook"],
    [Nast, "Nast"],
    [NazeingBrook, "Nazeing Brook"],
    [NazeingDrain, "Nazeing Drain"],
    [NewyearsGreenBourne, "Newyears Green Bourne"],
    [NimneyBourne, "Nimney Bourne"],
    [NorburyBrook, "Norbury Brook"],
    [NorthawBrook, "Northaw Brook"],
    [NorthWealdBassetDrain, "North Weald Basset Drain"],
    [NorthWealdBrook, "North Weald Brook"],
    [NorthWealdFloodByPassChan, "North Weald Flood By-Pass Chan"],
    [NuthampsteadTributary, "Nuthampstead Tributary"],
    [OakhamptonRoadDrain, "Oakhampton Road Drain"],
    [OakmereDrain, "Oakmere Drain"],
    [OffeygreenFarmTributary, "Offeygreen Farm Tributary"],
    [OilyDitch, "Oily Ditch"],
    [OldRiverStortCulvert, "Old River Stort Culvert"],
    [OsterlyParkBoundaryStream, "Osterly Park Boundary Stream"],
    [OverflowBrook, "Overflow Brook"],
    [OxheyStream, "Oxhey Stream"],
    [PainesBrook, "Paines Brook"],
    [PaperMillLaneDitch, "Paper Mill Lane Ditch"],
    [ParkfieldDrain, "Parkfield Drain"],
    [ParndonBrook, "Parndon Brook"],
    [ParndonMeadDitch, "Parndon Mead Ditch"],
    [ParsonageLaneDitch, "Parsonage Lane Ditch"],
    [PartridgeDriveDitch, "Partridge Drive Ditch"],
    [PatientEndTributary, "Patient End Tributary"],
    [PatmoreHallTributary, "Patmore Hall Tributary"],
    [PennPonds, "Penn Ponds"],
    [PhilpottsTributary, "Philpotts Tributary"],
    [PickleDitch, "Pickle Ditch"],
    [PinceyBrook, "Pincey Brook"],
    [Pinn, "Pinn"],
    [PoleHoleBrook, "Pole Hole Brook"],
    [PoolRiver, "Pool River"],
    [PoolRiverWestBranch, "Pool River (West Branch)"],
    [PottersBarBrook, "Potters Bar Brook"],
    [PrescottChannel, "Prescott Channel"],
    [PuckeridgeTributary, "Puckeridge Tributary"],
    [PylBrook, "Pyl Brook"],
    [PymmesBrook, "Pymmes Brook"],
    [PyrlesBrook, "Pyrles Brook"],
    [Quaggy, "Quaggy"],
    [QuaggyEastBranch, "Quaggy (East Branch)"],
    [QuaggyHitherGreenBranch, "Quaggy (Hither Green Branch)"],
    [QueensBrook, "Queens Brook"],
    [Quin, "Quin"],
    [QuintonHillBrook, "Quinton Hill Brook"],
    [RadlettBrook, "Radlett Brook"],
    [RagsBrook, "Rags Brook"],
    [RainhamCreek, "Rainham Creek"],
    [Ravensbourne, "Ravensbourne"],
    [RavensbourneDeptfrdTidal, "Ravensbourne (Deptfrd - Tidal)"],
    [RavensbourneDitch, "Ravensbourne Ditch"],
    [RavensbourneEastBranch, "Ravensbourne (East Branch)"],
    [RavensbourneHonorOak, "Ravensbourne Honor Oak"],
    [RavensbourneRomford, "Ravensbourne (Romford)"],
    [RavensbourneSouthBranch, "Ravensbourne (South Branch)"],
    [RedbourneDitch, "Redbourne Ditch"],
    [ReedEndTributary, "Reed End Tributary"],
    [ReedTributary, "Reed Tributary"],
    [RegentsCanal, "Regents Canal"],
    [ReliefChannel, "Relief Channel"],
    [Rib, "Rib"],
    [RiverGraveney, "River Graveney"],
    [RiversideDrain, "Riverside Drain"],
    [Roding, "Roding"],
    [Rom, "Rom"],
    [RowleyLaneDrain, "Rowley Lane Drain"],
    [RowleysRoadDrain, "Rowleys Road Drain"],
    [RoydonBrook, "Roydon Brook"],
    [RusholtBrook, "Rusholt Brook"],
    [Rythe, "Rythe"],
    [SaddlersMillStream, "Saddlers Mill Stream"],
    [SadlersMeadDrain, "Sadlers Mead Drain"],
    [SalisburyHallBrook, "Salisbury Hall Brook"],
    [SalmonsBrook, "Salmons Brook"],
    [SandonTributary, "Sandon Tributary"],
    [SawbridgeworthBrook, "Sawbridgeworth Brook"],
    [ShenleyRoadDrain, "Shenley Road Drain"],
    [Shuttle, "Shuttle"],
    [SilkStream, "Silk Stream"],
    [SmallLee, "Small Lee"],
    [Spellbrook, "Spellbrook"],
    [SpindleHillDrain, "Spindle Hill Drain"],
    [SpitalBrook, "Spital Brook"],
    [SpringBrook, "Spring Brook"],
    [SpringwoodCrescentDrain, "Springwood Crescent Drain"],
    [StanfordHallBrook, "Stanford Hall Brook"],
    [StanhamRiver, "Stanham River"],
    [StansteadAbbotsDrain, "Stanstead Abbots Drain"],
    [StansteadBrook, "Stanstead Brook"],
    [StanwellBrook, "Stanwell Brook"],
    [StanwellDitches, "Stanwell Ditches"],
    [StanwellMoorDitch, "Stanwell Moor Ditch"],
    [StapletonRoadDrain, "Stapleton Road Drain"],
    [StationRoadDrain, "Station Road Drain"],
    [StevenageBrook, "Stevenage Brook"],
    [SticklingGreenBrook, "Stickling Green Brook"],
    [StJamesStream, "St James Stream"],
    [StockingPelhamTributary, "Stocking Pelham Tributary"],
    [StondonHallBrook, "Stondon Hall Brook"],
    [StonebridgeBrook, "Stonebridge Brook"],
    [StoneRoadDitch, "Stone Road Ditch"],
    [Stort, "Stort"],
    [StortfordHallPark, "Stortford Hall Park"],
    [StroodHallBrook, "Strood Hall Brook"],
    [StThomasCreek, "St Thomas Creek"],
    [SummerstownDitch, "Summerstown ditch"],
    [SurbitonStream, "Surbiton stream (also called Tolworth brook)"],
    [TakeleyDrain, "Takeley Drain"],
    [TalBrook, "Tal Brook"],
    [TannisCourtTributary, "Tannis Court Tributary"],
    [Thames, "Thames"],
    [TheBeck, "The Beck"],
    [TheBeckEastBranch, "The Beck (East Branch)"],
    [TheBourne, "The Bourne"],
    [TheChing, "The Ching"],
    [TheOldBourne, "The Old Bourne"],
    [ThePasturesDitch, "The Pastures Ditch"],
    [TherapiaLaneDitch, "Therapia lane ditch"],
    [TheRiseDrain, "The Rise Drain"],
    [ThistleyValeBrook, "Thistley Vale Brook"],
    [ThorleyTributariesNorthArm, "Thorley Tributaries (N Arm)"],
    [ThorleyTributariesWestArm, "Thorley Tributaries (West Arm)"],
    [ThornhillStoragePondDitch, "Thornhill Storage Pond Ditch"],
    [ThornwoodCommonBrook, "Thornwood Common Brook"],
    [ThreeMillsRiver, "Three Mills River"],
    [ToddBrook, "Todd Brook"],
    [TommyLeeSewerEast, "Tommy Lee Sewer East"],
    [TownDitch, "Town Ditch"],
    [TramwayDitchColindale, "Tramway Ditch Colindale"],
    [TrimsGreenBrook, "Trims Green Brook"],
    [TrinityMarshDitch, "Trinity Marsh Ditch"],
    [TroutLaneDrain, "Trout Lane Drain"],
    [TurkeyBrook, "Turkey Brook"],
    [TurnfordBrook, "Turnford Brook"],
    [TyeGreenBrook, "Tye Green Brook"],
    [TyttenhangerStream, "Tyttenhanger Stream"],
    [UgleyBrook, "Ugley Brook"],
    [ValeBrook, "Vale Brook"],
    [Ver, "Ver"],
    [VictoriaWaterCourse, "Victoria Water Course"],
    [WadesmillBourne, "Wadesmill Bourne"],
    [Wandle, "Wandle"],
    [WandleBeddingtonOrCroydonBranch, "Wandle Beddington or Croydon Branch"],
    [WandleCarshaltonBranch, "Wandle Carshalton Branch"],
    [Wantsunt, "Wantsunt"],
    [WantzStream, "Wantz Stream"],
    [WareParkMillStream, "Ware Park Mill Stream"],
    [WatercressStream, "Watercress Stream"],
    [WaterendDitch, "Waterend Ditch"],
    [WaterfieldsWayDitch, "Waterfields Way Ditch"],
    [WaterworksRiver, "Waterworks River"],
    [WateryLaneDrain, "Watery Lane Drain"],
    [WatlingDitch, "Watling Ditch (also known as Watling Brook)"],
    [WealdBrook, "Weald Brook"],
    [WealdstoneBrook, "Wealdstone Brook"],
    [WellsBrook, "Wells Brook"],
    [WembleyBrook, "Wembley Brook"],
    [WestBedfontDitches, "West Bedfont Ditches"],
    [WesterhamStream, "Westerham stream"],
    [WestonTributary, "Weston Tributary"],
    [WhitehallTributary, "Whitehall Tributary"],
    [WhitingsSewer, "Whitings Sewer"],
    [WhittonBrook, "Whitton Brook"],
    [WildhillBrook, "Wildhill Brook"],
    [WindmillLaneDitch, "Windmill Lane Ditch"],
    [WoodhallFarmTributary, "Woodhall Farm Tributary"],
    [WoodhallGateDitch, "Woodhall Gate Ditch"],
    [WoodridingsStream, "Woodridings Stream"],
    [WoodsideGreenBrook, "Woodside Green Brook"],
    [WrenwoodDrain, "Wrenwood Drain"],
    [WrothamParkStream, "Wrotham Park Stream"],
    [Wyncham, "Wyncham"],
    [YeadingBrook, "Yeading Brook"],
    [YeadingBrookEastArm, "Yeading Brook (East Arm)"],
    [YeoveneyLodgeDitch, "Yeoveney Lodge Ditch"],
    [Other, "Other Watercourse"],
  ]),
  AlburyTributary,
  AlderBourne,
  ArdeleyBrook,
  AshdownDriveDrain,
  AshHerts,
  AshSurrey,
  AshtonBrook,
  AspendenBrook,
  AstonEndBrook,
  BarkingCreek,
  BarnetDitches,
  BarnetLaneDrain,
  BarwickTributary,
  BayfordBrook,
  Beam,
  Beane,
  BeddingtonEffluentCarrier,
  BeggarsBushLaneDrain,
  BentleyheathBrook,
  BerdenBrook,
  BeverleyBrook,
  BillyLowsLaneDrain,
  BirchangerBrook,
  BlacksBrook,
  BolderosTributary,
  BonesgateStream,
  BorehamwoodBrook,
  BorleyGreenTributary,
  BoundsGreenBrook,
  BowCreek,
  BrandonsTributary,
  BraughingBourne,
  BraughingWarrenBourne,
  BraziersFieldDrain,
  Brent,
  BrentPelhamTributary,
  BrickendonBrook,
  BroadfieldsDitch,
  BroadfieldTributary,
  BrookhouseBrook,
  BroxbourneDitch,
  BucknallsBrook,
  Bulbourne,
  BuncesDitch,
  BurntOakBrook,
  BuryGreenBrook,
  BusheyHeathDrain,
  ButterwickBrook,
  BuzzardsMouthSewer,
  CannonBrook,
  CanonsBrook,
  CartersBrook,
  CatBrook,
  CaterhamBourne,
  CatherineBourne,
  ChaffinchBrook,
  ChaffinchBrookWestBranch,
  ChannelseaRiver,
  ChelsingsTributary,
  Chess,
  ChigwellBrook,
  ChurchDriveDrain,
  ChurchLaneDitch,
  CityMillRiver,
  ClarehallBrook,
  ClattersfordBrook,
  ClitterhouseBrook,
  CloistersBrook,
  CobbinsBrook,
  CollegeBrook,
  Colne,
  ColvilleHallBrook,
  CoombeBrook,
  CoopersBrook,
  CoppedHallBrook,
  CostonsBrook,
  CottesbrookDitch,
  CountyDitch,
  CowleyHillDrain,
  CradleEndBrook,
  CranBrook,
  Crane,
  Cray,
  CressallsDitch,
  CressallsDitchSouth,
  CripseyBrook,
  CroxleyhallStreams,
  CuffleyBrook,
  DagenhamBrook,
  DaneEndTributary,
  Darent,
  DartfordCrayfordCreeks,
  DeansBrook,
  DecoyBrook,
  DeersHillRoad,
  DollisBrook,
  DukeOfNorthumberlands,
  EasternFloodChannel,
  EastPyl,
  EastwickBrook,
  EastwickBrookWestArm,
  EdgewareBrook,
  EdwareburyBrook,
  EllenBrook,
  ElmRoadDitch,
  EmersonParkStream,
  EnfieldDitch,
  EppingRoadDrain,
  EssendonBrook,
  EwellCourtStream,
  FarnhamBourne,
  FarnhamsTributaries,
  FarnhamsTributariesNorthWestArm,
  FiddlersBrook,
  FiddlersBrookWestArm,
  FiggsMarshDitch,
  FireStationDitch,
  Fleet,
  FollyBrook,
  FraysAvenueLoops,
  FrogsDitch,
  FurneauxPelhamTributary,
  Gade,
  GaderBrook,
  GlenbrookSouthDrain,
  GoresBrook,
  GrandUnionCanal,
  GrandUnionCanalPaddingtonArm,
  GrandUnionCanalSloughArm,
  GreatHallingburyBrook,
  GreatHormeadBrook,
  GreenLanesStream,
  GroveParkDitch,
  HaleyHillDitch,
  HansteadDitch,
  HarlowburyBrook,
  HatfieldHydeBrook,
  HawthornDitch,
  HeartsBourne,
  HempshillBrook,
  HendonCemetryDrain,
  HertfordUnionCanal,
  HighbridgeStream,
  HighRoadDrain,
  HighWychDitch,
  HilfieldBrook,
  HillmansBrook,
  HillmansBrookEast,
  Hogsmill,
  HolmeLodgeDitch,
  HolmshillBrook,
  HolyfieldChannel,
  HolyhillBrook,
  HoneyLaneBrook,
  HoneypotStream,
  HookmarshDitch,
  HortonStream,
  HoughtonBrook,
  HounsdenGutter,
  HunsdonBrook,
  IckenhamStream,
  Ingrebourne,
  InterceptingDrain,
  IvyChimneyBrook,
  JoelStreetFarmDitch,
  KelshallTributary,
  KendallsBrook,
  KentonBrook,
  KidBrook,
  KingGeorgeDrain,
  KingswoodburyTributary,
  KittsEndStream,
  KitwellsBrook,
  KydBrook,
  KydBrookEastBranch,
  LambourneEndBrook,
  LawrenceAvenueDrain,
  Lee,
  LeeFloodReliefChannel,
  LeegingBeechBrook,
  LeeNavigationLower,
  LeeNewCut,
  LewseyBrook,
  LichenBrook,
  LimehouseCut,
  LittleHallingburyBrook,
  LittleHormeadBrook,
  LittleLondonBrook,
  LloydTaylorDrain,
  LondonColneyStream,
  LoughtonBrook,
  LoughtonHallFarmDitch,
  LowerKidBrookNewRoute,
  LoxfordWater,
  Lynch,
  MadanRoadStream,
  MadBessBrook,
  MagnavilleRoadDrain,
  MaplelodgeFarmDitch,
  MaxwellRoadDrain,
  MayesBrook,
  MeesdenTributary,
  MerrylHillsBrook,
  MillerGreenBrook,
  MimmshallBrook,
  MimmsHallDrain,
  Mimram,
  Misbourne,
  MitchellsBrook,
  MonkenMeadBrook,
  MoorHallTributary,
  MoorParkStream,
  MoselleBrook,
  MountGraceRoadDrain,
  MuttonBrook,
  Nast,
  NazeingBrook,
  NazeingDrain,
  NewyearsGreenBourne,
  NimneyBourne,
  NorburyBrook,
  NorthawBrook,
  NorthWealdBassetDrain,
  NorthWealdBrook,
  NorthWealdFloodByPassChan,
  NuthampsteadTributary,
  OakhamptonRoadDrain,
  OakmereDrain,
  OffeygreenFarmTributary,
  OilyDitch,
  OldRiverStortCulvert,
  OsterlyParkBoundaryStream,
  OverflowBrook,
  OxheyStream,
  PainesBrook,
  PaperMillLaneDitch,
  ParkfieldDrain,
  ParndonBrook,
  ParndonMeadDitch,
  ParsonageLaneDitch,
  PartridgeDriveDitch,
  PatientEndTributary,
  PatmoreHallTributary,
  PennPonds,
  PhilpottsTributary,
  PickleDitch,
  PinceyBrook,
  Pinn,
  PoleHoleBrook,
  PoolRiver,
  PoolRiverWestBranch,
  PottersBarBrook,
  PrescottChannel,
  PuckeridgeTributary,
  PylBrook,
  PymmesBrook,
  PyrlesBrook,
  Quaggy,
  QuaggyEastBranch,
  QuaggyHitherGreenBranch,
  QueensBrook,
  Quin,
  QuintonHillBrook,
  RadlettBrook,
  RagsBrook,
  RainhamCreek,
  Ravensbourne,
  RavensbourneDeptfrdTidal,
  RavensbourneDitch,
  RavensbourneEastBranch,
  RavensbourneHonorOak,
  RavensbourneRomford,
  RavensbourneSouthBranch,
  RedbourneDitch,
  ReedEndTributary,
  ReedTributary,
  RegentsCanal,
  ReliefChannel,
  Rib,
  RiverGraveney,
  RiversideDrain,
  Roding,
  Rom,
  RowleyLaneDrain,
  RowleysRoadDrain,
  RoydonBrook,
  RusholtBrook,
  Rythe,
  SaddlersMillStream,
  SadlersMeadDrain,
  SalisburyHallBrook,
  SalmonsBrook,
  SandonTributary,
  SawbridgeworthBrook,
  ShenleyRoadDrain,
  Shuttle,
  SilkStream,
  SmallLee,
  Spellbrook,
  SpindleHillDrain,
  SpitalBrook,
  SpringBrook,
  SpringwoodCrescentDrain,
  StanfordHallBrook,
  StanhamRiver,
  StansteadAbbotsDrain,
  StansteadBrook,
  StanwellBrook,
  StanwellDitches,
  StanwellMoorDitch,
  StapletonRoadDrain,
  StationRoadDrain,
  StevenageBrook,
  SticklingGreenBrook,
  StJamesStream,
  StockingPelhamTributary,
  StondonHallBrook,
  StonebridgeBrook,
  StoneRoadDitch,
  Stort,
  StortfordHallPark,
  StroodHallBrook,
  StThomasCreek,
  SummerstownDitch,
  SurbitonStream,
  TakeleyDrain,
  TalBrook,
  TannisCourtTributary,
  Thames,
  TheBeck,
  TheBeckEastBranch,
  TheBourne,
  TheChing,
  TheOldBourne,
  ThePasturesDitch,
  TherapiaLaneDitch,
  TheRiseDrain,
  ThistleyValeBrook,
  ThorleyTributariesNorthArm,
  ThorleyTributariesWestArm,
  ThornhillStoragePondDitch,
  ThornwoodCommonBrook,
  ThreeMillsRiver,
  ToddBrook,
  TommyLeeSewerEast,
  TownDitch,
  TramwayDitchColindale,
  TrimsGreenBrook,
  TrinityMarshDitch,
  TroutLaneDrain,
  TurkeyBrook,
  TurnfordBrook,
  TyeGreenBrook,
  TyttenhangerStream,
  UgleyBrook,
  ValeBrook,
  Ver,
  VictoriaWaterCourse,
  WadesmillBourne,
  Wandle,
  WandleBeddingtonOrCroydonBranch,
  WandleCarshaltonBranch,
  Wantsunt,
  WantzStream,
  WareParkMillStream,
  WatercressStream,
  WaterendDitch,
  WaterfieldsWayDitch,
  WaterworksRiver,
  WateryLaneDrain,
  WatlingDitch,
  WealdBrook,
  WealdstoneBrook,
  WellsBrook,
  WembleyBrook,
  WestBedfontDitches,
  WesterhamStream,
  WestonTributary,
  WhitehallTributary,
  WhitingsSewer,
  WhittonBrook,
  WildhillBrook,
  WindmillLaneDitch,
  WoodhallFarmTributary,
  WoodhallGateDitch,
  WoodridingsStream,
  WoodsideGreenBrook,
  WrenwoodDrain,
  WrothamParkStream,
  Wyncham,
  YeadingBrook,
  YeadingBrookEastArm,
  YeoveneyLodgeDitch,
  Other,
};

export default Thames21RiverEnum;
