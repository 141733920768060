import {
  attributeGroup,
  numberAttr,
  pointLayer,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const StSmartRiversSite = mapSchemaWithDefaults({
  mapId: unsafeMapId("StSmartRiversSite"),
  title: "SmartRivers Sites",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("StSmartRiversSite"),
      title: "SmartRivers Sites",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("StSmartRiversSite"),
        attribution: [],
      }),
      defaultAttribute: "catchment",
      attributes: [
        attributeGroup({
          label: "Location and Time",
          attributes: [
            stringAttr({
              label: "Catchment",
              attributeId: "catchment",
              buckets: "auto",
            }),
            stringAttr({
              label: "River",
              attributeId: "river",
              buckets: "auto",
            }),
            stringAttr({
              label: "Site",
              attributeId: "site",
              buckets: "auto",
            }),
            numberAttr({
              label: "TRPI River Type",
              attributeId: "trpiRiverType",
              buckets: "auto",
            }),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time Created",
              buckets: "auto",
            }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("StSmartRiversSite"),
            }),
            surveyAttr("surveyId", "Site"),
          ],
        }),
      ],
    }),
  ],
});
