// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesAquaticVegetationForm =
  | "LML"
  | "A"
  | "EBL"
  | "ELL"
  | "SBL"
  | "SLL"
  | "SFL"
  | "FA"
  | "FF";

const LiverwortsOrMossesOrLichens: CesAquaticVegetationForm = "LML";
const Amphibious: CesAquaticVegetationForm = "A";
const EmergentBroadLeaved: CesAquaticVegetationForm = "EBL";
const EmergentLinearLeaved: CesAquaticVegetationForm = "ELL";
const SubmergedBroadLeaved: CesAquaticVegetationForm = "SBL";
const SubmergedLinearLeaved: CesAquaticVegetationForm = "SLL";
const SubmergedFineLeaved: CesAquaticVegetationForm = "SFL";
const FilamentousAlgae: CesAquaticVegetationForm = "FA";
const FreeFloating: CesAquaticVegetationForm = "FF";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesAquaticVegetationFormEnum = {
  ...createEnum("CesAquaticVegetationForm", [
    [LiverwortsOrMossesOrLichens, "Liverworts, mosses, lichens"],
    [Amphibious, "Amphibious"],
    [EmergentBroadLeaved, "Emergent broad-leaved"],
    [EmergentLinearLeaved, "Emergent linear-leaved (incl. horsetails)"],
    [SubmergedBroadLeaved, "Submerged broad-leaved"],
    [SubmergedLinearLeaved, "Submerged linear-leaved"],
    [SubmergedFineLeaved, "Submerged fine-leaved"],
    [FilamentousAlgae, "Filamentous algae"],
    [FreeFloating, "Free floating"],
  ]),
  LiverwortsOrMossesOrLichens,
  Amphibious,
  EmergentBroadLeaved,
  EmergentLinearLeaved,
  SubmergedBroadLeaved,
  SubmergedLinearLeaved,
  SubmergedFineLeaved,
  FilamentousAlgae,
  FreeFloating,
};

export default CesAquaticVegetationFormEnum;
