import {
  ExpiredAuthorizationError,
  isExpiredAuthorizationError,
  isMissingAuthorizationError,
  MissingAuthorizationError,
} from "./types.generated";

export type AuthError = ExpiredAuthorizationError | MissingAuthorizationError;

export function isAuthError(error: unknown): error is AuthError {
  return (
    isExpiredAuthorizationError(error) || isMissingAuthorizationError(error)
  );
}
