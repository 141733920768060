// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverRestorationPrioritiesHydrologicalMeasure =
  | "RestoreSpringflows"
  | "RestoreNaturalFlow"
  | "RemoveLandDrainage";

const RestoreSpringflows: NeRiverRestorationPrioritiesHydrologicalMeasure =
  "RestoreSpringflows";
const RestoreNaturalFlow: NeRiverRestorationPrioritiesHydrologicalMeasure =
  "RestoreNaturalFlow";
const RemoveLandDrainage: NeRiverRestorationPrioritiesHydrologicalMeasure =
  "RemoveLandDrainage";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverRestorationPrioritiesHydrologicalMeasureEnum = {
  ...createEnum("NeRiverRestorationPrioritiesHydrologicalMeasure", [
    [
      RestoreSpringflows,
      "Restore natural springflows to headwater stream systems",
    ],
    [RestoreNaturalFlow, "Restore natural flow regime"],
    [RemoveLandDrainage, "Remove land drainage"],
  ]),
  RestoreSpringflows,
  RestoreNaturalFlow,
  RemoveLandDrainage,
};

export default NeRiverRestorationPrioritiesHydrologicalMeasureEnum;
