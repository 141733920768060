import { isArray, isNumber } from "@cartographerio/guard";

// The geojson library defines `type Position = number[]`.
// This file defines `Coord`, `Coord2`, and `Coord3`
// as refinements where we know the types

export type Coord2 = [number, number];
export type Coord3 = [number, number, number];
export type Coord = Coord2 | Coord3;

export function isCoord(value: unknown): value is Coord {
  return (
    isArray(value) &&
    (value.length === 2 || value.length === 3) &&
    value.every(isNumber)
  );
}

export function isCoord2(value: unknown): value is Coord2 {
  return isArray(value) && value.length === 2 && value.every(isNumber);
}

export function isCoord3(value: unknown): value is Coord3 {
  return isArray(value) && value.length === 3 && value.every(isNumber);
}

export function toCoord2(coord: Coord): Coord2 {
  return coord.length === 2 ? coord : [coord[0], coord[1]];
}

export function toCoord3(coord: Coord): Coord3 {
  return coord.length === 3 ? coord : [coord[0], coord[1], 0];
}
