import {
  BillingInterval,
  BillingUsage,
  Plan,
  Subscription,
  Timestamp,
  WorkspaceAlias,
  ddmmyyyy,
  formatTimestamp,
  nowTimestamp,
  timestampGt,
  timestampToDate,
} from "@cartographerio/types";
import { Box, HStack, useDisclosure } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT } from "../../config";
import ButtonLink from "../components/ButtonLink";
import CallUsLink from "../components/CallUsLink";
import EmailUsLink from "../components/EmailUsLink";
import Fieldset from "../components/Fieldset";
import HelpPopover from "../components/HelpPopover";
import LinkButton from "../components/LinkButton";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { routes } from "../routes";
import { formatIntervalAmount } from "./format";
import { invoiceTotalAmount, pluralize } from "./helpers";
import PlanChooserModal from "./PlanChooserModal";

export type SubscriptionChangeFunc = (params: {
  plan?: Plan;
  interval?: BillingInterval;
}) => void;

interface PlanSectionProps {
  workspaceAlias: WorkspaceAlias;
  usage: BillingUsage;
  plan: Plan;
  subscription: Subscription;
  lastInternalUpdate?: Timestamp | null;
  onSubscriptionChange?: SubscriptionChangeFunc;
}

export default function PlanSection(props: PlanSectionProps): ReactElement {
  const {
    workspaceAlias,
    usage,
    plan,
    subscription,
    lastInternalUpdate,
    onSubscriptionChange,
  } = props;

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const trialEnd = useMemo(
    () =>
      subscription.trialEnd != null &&
      timestampGt(nowTimestamp(), subscription.trialEnd)
        ? formatTimestamp(subscription.trialEnd, { format: ddmmyyyy })
        : null,
    [subscription.trialEnd]
  );

  const updating = useMemo(
    () =>
      lastInternalUpdate != null &&
      timestampGt(lastInternalUpdate, subscription.lastStripeUpdate),
    [lastInternalUpdate, subscription.lastStripeUpdate]
  );

  const updateTakingTooLong = useMemo(
    () =>
      updating &&
      lastInternalUpdate != null &&
      Date.now() - timestampToDate(lastInternalUpdate).getTime() >=
        STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT,
    [updating, lastInternalUpdate]
  );

  const title = useMemo(() => {
    return trialEnd == null ? "Your Plan" : "Your Trial";
  }, [trialEnd]);

  return (
    <Fieldset legend={title}>
      {updateTakingTooLong ? (
        <UpdateTakingTooLong />
      ) : updating ? (
        <LoadingPlaceholder label="Loading plan updates ..." h="13rem" />
      ) : (
        <PlanContent
          workspaceAlias={workspaceAlias}
          usage={usage}
          plan={plan}
          subscription={subscription}
          trialEnd={trialEnd}
          onPlansClick={onModalOpen}
          onIntervalChange={interval => onSubscriptionChange?.({ interval })}
        />
      )}
      <PlanChooserModal
        workspaceRef={workspaceAlias}
        usage={usage}
        defaultPlanId={plan.id}
        defaultBillingInterval={subscription.billingInterval}
        isOpen={isModalOpen}
        onClose={onModalClose}
        onPlanClick={(plan, interval) =>
          onSubscriptionChange?.({ plan, interval })
        }
      />
    </Fieldset>
  );
}

function UpdateTakingTooLong() {
  return (
    <Spaced spacing="6" h="13rem">
      <Para textAlign="center" __css={{ textWrap: "balance" }}>
        We&apos;re expecting an update from our payment provider but it&apos;s
        taking a long time. Please contact support if this message persists.
      </Para>

      <HStack gap="4" justify="center">
        <CallUsLink />
        <EmailUsLink />
      </HStack>
    </Spaced>
  );
}

interface PlanContentProps {
  workspaceAlias: WorkspaceAlias;
  usage: BillingUsage;
  plan: Plan;
  subscription: Subscription;
  trialEnd: string | null;
  onPlansClick: () => void;
  onIntervalChange: (interval: BillingInterval) => void;
}

function PlanContent(props: PlanContentProps): ReactElement {
  const {
    workspaceAlias,
    plan,
    usage,
    subscription,
    trialEnd,
    onPlansClick,
    onIntervalChange,
  } = props;

  return (
    <>
      <Spaced spacing="1">
        <Para>
          <Box>
            <strong>{plan.name}</strong> plan{" "}
            {plan.pricingScheme.type === "PerUser" ? (
              <>
                with <strong>{usage.numUsers}</strong>{" "}
                {pluralize(usage.numUsers, "user")}
                {plan.limits.maxUsers != null &&
                  ` out of ${plan.limits.maxUsers}`}
              </>
            ) : (
              <>
                with <strong>{usage.numProjects}</strong>{" "}
                {pluralize(usage.numProjects, "project")}
                {plan.limits.maxProjects != null &&
                  ` out of ${plan.limits.maxProjects}`}
              </>
            )}{" "}
            {trialEnd != null && (
              <>
                (trial ending <strong>{trialEnd}</strong>)
              </>
            )}
          </Box>
        </Para>
        <Para>
          <LinkButton justifySelf="end" onClick={onPlansClick}>
            Change plan
          </LinkButton>
        </Para>
      </Spaced>

      <Spaced spacing="1">
        <Para>
          <strong>
            {formatIntervalAmount(
              invoiceTotalAmount(subscription.nextInvoiceTotal),
              subscription.billingInterval
            )}
          </strong>
          <HelpPopover
            text="Price inclusive of tax and discounts. For a full breakdown see the
        Billing Portal."
          />
        </Para>
        <Para>
          {subscription.billingInterval === "Monthly" ? (
            <LinkButton
              justifySelf="end"
              onClick={() => onIntervalChange("Yearly")}
            >
              Switch to yearly billing and save
            </LinkButton>
          ) : (
            <LinkButton
              justifySelf="end"
              onClick={() => onIntervalChange("Monthly")}
            >
              Switch to monthly billing
            </LinkButton>
          )}
        </Para>
      </Spaced>

      <ButtonLink.Internal
        to={routes.workspace.billing.portal.url([workspaceAlias])}
        display="block"
        w="fit-content"
      >
        Billing Portal
      </ButtonLink.Internal>
    </>
  );
}
