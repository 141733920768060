// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterRainfallIntensity =
  | "None"
  | "Drizzle"
  | "Light"
  | "Heavy"
  | "Torrential";

const None: MrsMudSpotterRainfallIntensity = "None";
const Drizzle: MrsMudSpotterRainfallIntensity = "Drizzle";
const Light: MrsMudSpotterRainfallIntensity = "Light";
const Heavy: MrsMudSpotterRainfallIntensity = "Heavy";
const Torrential: MrsMudSpotterRainfallIntensity = "Torrential";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterRainfallIntensityEnum = {
  ...createEnum("MrsMudSpotterRainfallIntensity", [
    [None, "None"],
    [Drizzle, "Drizzle"],
    [Light, "Light"],
    [Heavy, "Heavy"],
    [Torrential, "Torrential"],
  ]),
  None,
  Drizzle,
  Light,
  Heavy,
  Torrential,
};

export default MrsMudSpotterRainfallIntensityEnum;
