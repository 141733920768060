import { Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  checkedToResult,
  isChecked,
  isWorkspaceId,
  isWorkspaceLimits,
  isWorkspaceLimitsCheckResult,
  Message,
  WorkspaceId,
  WorkspaceLimits,
  WorkspaceLimitsCheckResult,
  WorkspaceRef,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";
import { isArrayOf } from "@cartographerio/guard";

const basePath = "/workspace/limits/v1";

export function readOrFail(
  apiParams: ApiParams,
  ref: WorkspaceRef
): IO<WorkspaceLimits> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(contentAs("WorkspaceLimits", isWorkspaceLimits));
}

export function save(
  apiParams: ApiParams,
  ref: WorkspaceRef,
  limits: WorkspaceLimits
): IO<Result<Message[], WorkspaceLimits>> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${ref}` },
      body: limits,
    })
    .flatMap(
      contentAs("Checked<WorkspaceLimits>", isChecked(isWorkspaceLimits))
    )
    .map(checkedToResult);
}

export function checkUsers(
  apiParams: ApiParams,
  ref: WorkspaceRef
): IO<WorkspaceLimitsCheckResult> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/check-users/${ref}` },
    })
    .flatMap(
      contentAs("WorkspaceLimitsCheckResult", isWorkspaceLimitsCheckResult)
    );
}

export function checkAllUsers(apiParams: ApiParams): IO<WorkspaceId[]> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/check-users` },
    })
    .flatMap(contentAs("WorkspaceId[]", isArrayOf(isWorkspaceId)));
}

export function checkProjects(
  apiParams: ApiParams,
  ref: WorkspaceRef
): IO<WorkspaceLimitsCheckResult> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/check-projects/${ref}` },
    })
    .flatMap(
      contentAs("WorkspaceLimitsCheckResult", isWorkspaceLimitsCheckResult)
    );
}

export function checkAllProjects(apiParams: ApiParams): IO<WorkspaceId[]> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/check-projects` },
    })
    .flatMap(contentAs("WorkspaceId[]", isArrayOf(isWorkspaceId)));
}
