// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtRainfall = "none" | "light" | "heavy";

const None: WrtRainfall = "none";
const Light: WrtRainfall = "light";
const Heavy: WrtRainfall = "heavy";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtRainfallEnum = {
  ...createEnum("WrtRainfall", [
    [None, "None"],
    [Light, "Light rain/showers"],
    [Heavy, "Heavy/prolonged rain"],
  ]),
  None,
  Light,
  Heavy,
};

export default WrtRainfallEnum;
