import { createMessage } from "@cartographerio/topo-core";
import { RuleBuilder } from "./types";
import { checks, permissionCheckPasses } from "@cartographerio/permission";

/**
 * Check if the current team is associated with the current project
 */
export const teamAssociationRule: RuleBuilder =
  ({ project, identity, graph }) =>
  survey => {
    const { teamId } = survey;

    const isAdmin = permissionCheckPasses(
      checks.workspace.admin(graph.findWorkspaceByProjectId(project.id).id),
      {
        id: identity.userId,
        roles: identity.roles,
        qualificationRoles: identity.qualificationRoles,
      },
      graph
    );

    return teamId == null || project.teamIds.includes(teamId)
      ? []
      : [
          createMessage(
            isAdmin ? "info" : "error",
            `This team is not associated with the ${project.name} project`
          ),
        ];
  };
