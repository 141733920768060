import { Message } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { isFieldState } from "../guard";

import { BlockState, ContentState } from "./type";

type Highlight = "error" | "info" | undefined;

interface MessageCounts {
  errorCount: number;
  infoCount: number;
}

export function messageCounts(messages: Message[]): MessageCounts {
  return {
    errorCount: messages.filter(msg => msg.level === "error").length,
    infoCount: messages.filter(msg => msg.level === "info").length,
  };
}

export function pageMessageCounts(blockStates: BlockState[]): MessageCounts {
  let errorCount: number = 0;
  let infoCount: number = 0;

  function handleField(state: { errorCount: number; infoCount: number }): void {
    errorCount += state.errorCount;
    infoCount += state.infoCount;
  }

  function handleContent(state: ContentState): void {
    switch (state.type) {
      case "DynamicGridState":
      case "DynamicValueState":
      case "DynamicImageState":
      case "TextState":
      case "HeadingState":
        break;
      case "GroupState":
        state.blockStates.forEach(handleBlock);
        break;
      case "RepeatState":
        state.childStates.forEach(handleBlock);
        break;
      case "CardState":
      case "FullWidthState":
      case "RequireRoleState":
      case "ScopeState":
      case "VisibilityState":
        handleBlock(state.childState);
        break;
      case "PrimarySurveyorFieldState":
      case "PrimaryTeamFieldState":
        handleField(state);
        break;
      default:
        checkExhausted(state);
    }
  }

  function handleBlock(state: BlockState): void {
    if (isFieldState(state)) {
      handleField(state);
    } else {
      handleContent(state);
    }
  }

  blockStates.forEach(handleBlock);

  return {
    errorCount,
    infoCount,
  };
}

export function messageHighlight(counts: MessageCounts): Highlight {
  return counts.errorCount > 0
    ? "error"
    : counts.infoCount > 0
      ? "info"
      : undefined;
}
