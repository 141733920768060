import {
  attachmentField,
  columns2,
  enumSelectOptions,
  featureField,
  featureFieldCartographerMapStyle,
  form,
  integerField,
  matchesRegex,
  minValue,
  page,
  requireRole,
  required,
  section,
  select,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { enumAttr, stringAttr } from "@cartographerio/topo-map";
import {
  SasWaterQualitySiteTypeEnum,
  SasWaterQualityWeatherEnum,
} from "@cartographerio/inventory-enums";
import { unsafeMapLayerId, unsafeProjectAlias } from "@cartographerio/types";
import { outdent } from "outdent";

const bacteriaRule = matchesRegex({
  level: "error",
  regex: /^[<>]?[0-9]+$/,
  message:
    "Enter a non-negative whole number or an approximate value of the form '<123' or '>123'.",
});

export default form({
  title: "Coliforms",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            columns2(
              timestampField({
                label: "Date and Time of Survey",
                path: ["data", "recorded"],
                required: required("info"),
                defaultValue: "now",
                help: "Date and time the survey data was collected in the field.",
              })
            ),
            featureField({
              label: "Site",
              path: ["data", "site"],
              region: "uk",
              required: required(),
              mapOptions: {
                mapStyle: featureFieldCartographerMapStyle({
                  project: unsafeProjectAlias("wqsites"),
                  layer: unsafeMapLayerId("SasWaterQualitySite"),
                  geometryType: "Point",
                  primaryKey: "surveyId",
                }),
                selectMinZoom: 10,
                attributes: [
                  stringAttr({
                    attributeId: "river",
                    label: "River Name",
                    buckets: "auto",
                  }),
                  stringAttr({
                    attributeId: "site",
                    label: "Site Name",
                    buckets: "auto",
                  }),
                  enumAttr({
                    attributeId: "siteType",
                    label: "Site Type",
                    enum: SasWaterQualitySiteTypeEnum,
                  }),
                ],
              },
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              maxFiles: 4,
              path: ["data", "photographs"],
            }),
          ],
        }),
        section({
          title: "Field Observations",
          path: ["data"],
          blocks: [
            columns2(
              vstack(
                integerField({
                  label: "Number of Swimmers",
                  path: ["numberOfSwimmers"],
                  help: "Number of swimmers observed at the time and location of sampling",
                  required: required("info"),
                  bounds: minValue(0),
                }),
                integerField({
                  label: "Number of Other Water Users",
                  path: ["numberOfOtherWaterUsers"],
                  help: "Number of other water users observed at the time and location of sampling",
                  required: required("info"),
                  bounds: minValue(0),
                }),
                select({
                  label: "Weather",
                  path: ["weather"],
                  options: enumSelectOptions(SasWaterQualityWeatherEnum),
                  required: required("info"),
                }),
                integerField({
                  label: "Air Temperature",
                  path: ["airTemperature"],
                  units: "°C",
                  required: required("info"),
                })
              )
            ),
          ],
        }),
        section({
          title: "Lab Results",
          path: ["data"],
          help: outdent`
          This section can only be completed by *approvers* or *coordinators*.
          Please leave it blank if you aren't receiving results from lab tests.
          `,
          blocks: [
            requireRole({
              role: "Approver",
              block: columns2(
                vstack(
                  textField({
                    label: "Escherichia Coli",
                    path: ["escherichiaColiAsString"],
                    units: "CFU/100ml",
                    required: required("info"),
                    customRules: [bacteriaRule],
                  }),
                  textField({
                    label: "Total Thermotolerant Coliforms",
                    path: ["totalThermotolerantColiformsAsString"],
                    units: "CFU/100ml",
                    required: required("info"),
                    customRules: [bacteriaRule],
                  }),
                  textField({
                    label: "Intestinal Enterococci",
                    path: ["intestinalEnterococciAsString"],
                    units: "CFU/100ml",
                    required: required("info"),
                    customRules: [bacteriaRule],
                  })
                )
              ),
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 5,
            }),
          ],
        }),
      ],
    }),
  ],
});
