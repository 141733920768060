import { PricingScheme, WorkspaceUsage } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { ListItem, UnorderedList, chakra } from "@chakra-ui/react";
import outdent from "outdent";
import { ReactElement, useMemo } from "react";

import Fieldset from "../components/Fieldset";
import HelpText from "../components/HelpText";
import Para from "../components/Para";
import { pluralize } from "./helpers";

interface UsageSectionProps {
  usage: WorkspaceUsage;
  pricingSchemeType?: PricingScheme["type"];
}

export default function UsageSection(props: UsageSectionProps): ReactElement {
  const { usage, pricingSchemeType } = props;

  const numMembers = useMemo(
    () =>
      usage.numUsers + usage.numPendingInvitations + usage.numUnapprovedSignups,
    [usage]
  );

  const helpText = useMemo(() => {
    switch (pricingSchemeType) {
      case "Free":
        return FREE_HELP;
      case "PerUser":
        return PER_USER_HELP;
      case "PerProject":
        return PER_PROJECT_HELP;
      case undefined:
        return NO_PLAN_HELP;
      default:
        return checkExhausted(pricingSchemeType);
    }
  }, [pricingSchemeType]);

  return (
    <Fieldset legend="Usage">
      <HelpText text={helpText} />

      <Para>Your current usage:</Para>
      <UnorderedList ms="6">
        <ListItem my="4">
          <UsageNumber num={numMembers} unit="member" /> comprising:
          <UnorderedList my="4">
            <ListItem>
              <UsageNumber num={usage.numUsers} unit="current user" />
            </ListItem>
            <ListItem>
              <UsageNumber
                num={usage.numPendingInvitations}
                unit="pending invitation"
              />
            </ListItem>
            <ListItem>
              <UsageNumber
                num={usage.numUnapprovedSignups}
                unit="unapproved signup"
              />
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem my="4">
          <UsageNumber num={usage.numProjects} unit="project" />
        </ListItem>
      </UnorderedList>
    </Fieldset>
  );
}

interface UsageNumberProps {
  num: number;
  unit: string;
}

function UsageNumber(props: UsageNumberProps): ReactElement {
  const { num, unit } = props;

  return (
    <>
      <chakra.strong display="inline-block" minW="2ch">
        {num.toLocaleString(navigator.language)}
      </chakra.strong>{" "}
      {pluralize(num, unit)}
    </>
  );
}

const NO_PLAN_HELP = outdent`
  Depending on the plan you choose, your bill will be based on
  the number of **current users** or the number of **projects** below.
`;

const FREE_HELP = outdent`
  You are on a free subscription plan.
  Figures are provided for information only.
`;

const PER_USER_HELP = outdent`
  Your bill is based on the number of **current users** below.
  Other figures are for information only.
`;

const PER_PROJECT_HELP = outdent`
  Your bill is based on the number of **projects** below.
  Other figures are for information only.
`;
