// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtAmmonia = "A0" | "A05" | "A10" | "A30" | "A60";

const A0: WrtAmmonia = "A0";
const A05: WrtAmmonia = "A05";
const A10: WrtAmmonia = "A10";
const A30: WrtAmmonia = "A30";
const A60: WrtAmmonia = "A60";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtAmmoniaEnum = {
  ...createEnum("WrtAmmonia", [
    [A0, "0"],
    [A05, "0.5"],
    [A10, "1.0"],
    [A30, "3.0"],
    [A60, "6.0"],
  ]),
  A0,
  A05,
  A10,
  A30,
  A60,
};

export default WrtAmmoniaEnum;
