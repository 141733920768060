import {
  attachmentAttr,
  attributeGroup,
  booleanAttr,
  enumAttr,
  lineLayer,
  numberAttr,
  pointLayer,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  MrsMudSpotterDisturbedBankFaceSourceTypeEnum,
  MrsMudSpotterFlowEnum,
  MrsMudSpotterOverlandFlowSourceTypeEnum,
  MrsMudSpotterSedimentEnum,
  MrsMudSpotterSourceSizeEnum,
  MrsMudSpotterSourceTypeEnum,
  MrsMudSpotterSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const MrsMudSpotter = mapSchemaWithDefaults({
  mapId: unsafeMapId("MrsMudSpotter"),
  title: "Mud Spotter",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("MrsMudSpotter"),
      title: "Sources",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("MrsMudSpotter"),
        attribution: [],
      }),
      defaultAttribute: "sourceType",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            surveyAttr("surveyId", "Survey"),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("MrsMudSpotter"),
            }),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "surveyType",
              label: "Survey Type",
              enum: MrsMudSpotterSurveyTypeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Site",
          attributes: [
            stringAttr({
              attributeId: "riverName",
              label: "River",
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "reachName",
              label: "Reach",
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "siteName",
              label: "Site",
              buckets: "auto",
            }),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
        attributeGroup({
          label: "Source Type",
          attributes: [
            enumAttr({
              attributeId: "sourceType",
              label: "Source Type",
              enum: MrsMudSpotterSourceTypeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Disturbed Bank Face Sources",
          attributes: [
            enumAttr({
              attributeId: "disturbedBankFaceSourceType",
              label: "Source Sub-Type",
              enum: MrsMudSpotterDisturbedBankFaceSourceTypeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Overland Flow Sources",
          attributes: [
            booleanAttr({
              attributeId: "overlandFlowSourceVisible",
              label: "Source Visible?",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "overlandFlowSourceType",
              label: "Source Sub-Type",
              enum: MrsMudSpotterOverlandFlowSourceTypeEnum,
            }),
            numberAttr({
              attributeId: "overlandFlowDistance",
              label: "Distance to Source",
              unit: "m",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Source Size",
          attributes: [
            enumAttr({
              attributeId: "sourceSize",
              label: "Source Size",
              enum: MrsMudSpotterSourceSizeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Water Flow from Source",
          attributes: [
            enumAttr({
              attributeId: "flow",
              label: "Water Flow",
              enum: MrsMudSpotterFlowEnum,
            }),
            enumAttr({
              attributeId: "sediment",
              label: "Mud in Flowing Water",
              enum: MrsMudSpotterSedimentEnum,
            }),
          ],
        }),
      ],
    }),
    lineLayer({
      layerId: unsafeMapLayerId("MrsMudSpotterOverlandFlow"),
      title: "Overland Flow",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("MrsMudSpotterOverlandFlow"),
        attribution: [],
      }),
      defaultAttribute: "overlandFlowSourceType",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            surveyAttr("surveyId", "Survey"),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("MrsMudSpotter"),
            }),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            booleanAttr({
              attributeId: "training",
              label: "Training?",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Site",
          attributes: [
            stringAttr({
              attributeId: "riverName",
              label: "River",
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "reachName",
              label: "Reach",
              buckets: "auto",
            }),
            stringAttr({
              attributeId: "siteName",
              label: "Site",
              buckets: "auto",
            }),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
        attributeGroup({
          label: "Overland Flow",
          attributes: [
            booleanAttr({
              attributeId: "overlandFlowSourceVisible",
              label: "Source Visible?",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "overlandFlowSourceType",
              label: "Source Sub-Type",
              enum: MrsMudSpotterOverlandFlowSourceTypeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Source Size",
          attributes: [
            enumAttr({
              attributeId: "sourceSize",
              label: "Source Size",
              enum: MrsMudSpotterSourceSizeEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Water Flow from Source",
          attributes: [
            enumAttr({
              attributeId: "flow",
              label: "Water Flow",
              enum: MrsMudSpotterFlowEnum,
            }),
            enumAttr({
              attributeId: "sediment",
              label: "Mud in Flowing Water",
              enum: MrsMudSpotterSedimentEnum,
            }),
          ],
        }),
      ],
    }),
  ],
});
