import { checks } from "@cartographerio/permission";
import { TeamRoleNameEnum } from "@cartographerio/types";
import { useDisclosure } from "@chakra-ui/react";
import { ReactElement } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import Button from "../../components/Button";
import InvitationModal from "../../components/InvitationModal";
import PageTopBar from "../../components/PageTopBar";
import Popover from "../../components/Popover";
import Select from "../../components/Select";
import WithPermission from "../../components/WithPermission";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import useUserLimitReason from "../../hooks/useUserLimitReason";
import { routes } from "../../routes";
import BaseInvitationListPage from "../base/BaseInvitationListPage";
import TeamPageHeader from "./TeamPageHeader";

export default function TeamInvitationListPage(
  props: RouteProps<typeof routes.workspace.team.invitation.list>
): ReactElement {
  const {
    path: { workspaceRef, teamRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();

  const team = useSuspenseQueryData(
    queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, team.workspaceId)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const teamProjects = useSuspenseSearchResults(
    queries.project.v2.search(apiParams, {
      workspace: team.workspaceId,
      team: team.id,
    })
  );

  const userLimitReason = useUserLimitReason(workspace);

  useRequirePermissionRedirect(checks.team.viewInvitations(team), () =>
    routes.workspace.home.url([workspace.alias])
  );

  usePageTitle(`Invitations - ${team.name} - ${workspace.name}`);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="teams"
        team={team}
        teamPage="invitation-single"
      />
      <TeamPageHeader
        workspace={workspace}
        team={team}
        selected="invitation-single"
      />
      <WithPermission check={checks.team.grantAccess(team)}>
        {canCreate => (
          <BaseInvitationListPage
            route={routes.workspace.team.invitation.list}
            query={query}
            updateQuery={updateQuery}
            team={team}
            teamRole={query.role}
            roleSelect={
              <Select.Nullable
                value={query.role}
                options={TeamRoleNameEnum.entries}
                onChange={role => updateQuery({ ...query, role, page: 0 })}
                placeholder="All Roles"
                background="transparent"
                w="fit-content"
                maxW="52"
              />
            }
            addButton={
              canCreate && (
                <Popover
                  enabled={userLimitReason != null}
                  placement="bottom-start"
                  body={userLimitReason}
                >
                  <Button
                    label="Invite"
                    colorScheme="blue"
                    onClick={onModalOpen}
                    disabled={userLimitReason != null}
                  />
                </Popover>
              )
            }
            itemLink={invitation =>
              routes.workspace.team.invitation.view.url([
                workspace.alias,
                team.alias,
                invitation.id,
              ])
            }
          />
        )}
      </WithPermission>

      <InvitationModal.Team
        title="Invite to Team"
        isOpen={isModalOpen}
        onClose={onModalClose}
        team={team}
        teamProjects={teamProjects}
      />
    </>
  );
}
