// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopPondCount = "Eq0" | "Eq1" | "Eq2" | "Eq3" | "Gt3";

const Eq0: UclPitStopPondCount = "Eq0";
const Eq1: UclPitStopPondCount = "Eq1";
const Eq2: UclPitStopPondCount = "Eq2";
const Eq3: UclPitStopPondCount = "Eq3";
const Gt3: UclPitStopPondCount = "Gt3";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopPondCountEnum = {
  ...createEnum("UclPitStopPondCount", [
    [Eq0, "0"],
    [Eq1, "1"],
    [Eq2, "2"],
    [Eq3, "3"],
    [Gt3, "More than 3"],
  ]),
  Eq0,
  Eq1,
  Eq2,
  Eq3,
  Gt3,
};

export default UclPitStopPondCountEnum;
