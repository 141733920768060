// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopEdgeFeature =
  | "BasinPerimeter"
  | "HighWaterMark"
  | "CurrentWaterLevel";

const BasinPerimeter: UclPitStopEdgeFeature = "BasinPerimeter";
const HighWaterMark: UclPitStopEdgeFeature = "HighWaterMark";
const CurrentWaterLevel: UclPitStopEdgeFeature = "CurrentWaterLevel";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopEdgeFeatureEnum = {
  ...createEnum("UclPitStopEdgeFeature", [
    [BasinPerimeter, "Pond basin perimeter"],
    [HighWaterMark, "High water mark/vegetation line"],
    [CurrentWaterLevel, "Current water level"],
  ]),
  BasinPerimeter,
  HighWaterMark,
  CurrentWaterLevel,
};

export default UclPitStopEdgeFeatureEnum;
