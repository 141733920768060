// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrbanRiverflySpecies =
  | "casedCaddis"
  | "caselessCaddis"
  | "ephemeridae"
  | "ephemerellidae"
  | "heptageniidae"
  | "baetidae"
  | "stoneflies"
  | "gammarus"
  | "simuliidae"
  | "beetles"
  | "snails"
  | "leeches"
  | "asellidae"
  | "oligochaeta";

const CasedCaddis: UrbanRiverflySpecies = "casedCaddis";
const CaselessCaddis: UrbanRiverflySpecies = "caselessCaddis";
const Ephemeridae: UrbanRiverflySpecies = "ephemeridae";
const Ephemerellidae: UrbanRiverflySpecies = "ephemerellidae";
const Heptageniidae: UrbanRiverflySpecies = "heptageniidae";
const Baetidae: UrbanRiverflySpecies = "baetidae";
const Stoneflies: UrbanRiverflySpecies = "stoneflies";
const Gammarus: UrbanRiverflySpecies = "gammarus";
const Simuliidae: UrbanRiverflySpecies = "simuliidae";
const Beetles: UrbanRiverflySpecies = "beetles";
const Snails: UrbanRiverflySpecies = "snails";
const Leeches: UrbanRiverflySpecies = "leeches";
const Asellidae: UrbanRiverflySpecies = "asellidae";
const Oligochaeta: UrbanRiverflySpecies = "oligochaeta";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrbanRiverflySpeciesEnum = {
  ...createEnum("UrbanRiverflySpecies", [
    [CasedCaddis, "Cased Caddis"],
    [CaselessCaddis, "Caseless Caddis"],
    [Ephemeridae, "Mayfly - Ephemeridae"],
    [Ephemerellidae, "Mayfly - Ephemerellidae"],
    [Heptageniidae, "Mayfly - Heptageniidae"],
    [Baetidae, "Mayfly - Baetidae"],
    [Stoneflies, "Stoneflies"],
    [Gammarus, "Freshwater Shrimp - Gammarus"],
    [Simuliidae, "Blackfly Larvae - Simuliidae"],
    [Beetles, "Beetles"],
    [Snails, "Snails"],
    [Leeches, "Leeches"],
    [Asellidae, "Freshwater Hoglouse - Asellidae"],
    [Oligochaeta, "Worms - Oligochaeta"],
  ]),
  CasedCaddis,
  CaselessCaddis,
  Ephemeridae,
  Ephemerellidae,
  Heptageniidae,
  Baetidae,
  Stoneflies,
  Gammarus,
  Simuliidae,
  Beetles,
  Snails,
  Leeches,
  Asellidae,
  Oligochaeta,
};

export default UrbanRiverflySpeciesEnum;
