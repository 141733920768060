import { GeoJSON } from "geojson";

export function sortFeatures(name: string) {
  return function (geojson: GeoJSON): GeoJSON {
    switch (geojson.type) {
      case "Point":
      case "MultiPoint":
      case "LineString":
      case "MultiLineString":
      case "Polygon":
      case "MultiPolygon":
      case "GeometryCollection":
      case "Feature":
        return geojson;
      case "FeatureCollection":
        /* eslint-disable @typescript-eslint/no-explicit-any */
        return {
          ...geojson,
          features: geojson.features.sort((a: any, b: any) => {
            const valueA = a != null && a[name];
            const valueB = b != null && b[name];

            if (valueA < valueB) return -1;
            if (valueA > valueB) return +1;
            return 0;
          }),
        };
      /* eslint-enable @typescript-eslint/no-explicit-any */
    }
  };
}
