// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusHygiene =
  | "toiletsWithHandWashFacilities"
  | "drinkingFountainsOrTaps";

const ToiletsWithHandWashFacilities: EcostatusHygiene =
  "toiletsWithHandWashFacilities";
const DrinkingFountainsOrTaps: EcostatusHygiene = "drinkingFountainsOrTaps";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusHygieneEnum = {
  ...createEnum("EcostatusHygiene", [
    [ToiletsWithHandWashFacilities, "Toilets with hand-wash facilities"],
    [DrinkingFountainsOrTaps, "Drinking fountains / taps"],
  ]),
  ToiletsWithHandWashFacilities,
  DrinkingFountainsOrTaps,
};

export default EcostatusHygieneEnum;
