// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21LitterItem =
  | "foodPlasticCutlery"
  | "foodPlasticStraw"
  | "foodPlasticStirrer"
  | "foodPlasticLollipopStick"
  | "foodPlasticFoodWrapper"
  | "foodPlasticTakeAwayContainer"
  | "foodPlasticDrinkBottle"
  | "foodPlasticBottleTop"
  | "foodPlasticPlasticLid"
  | "foodPlasticCup"
  | "foodPlasticPlate"
  | "foodPlastic6Or4PackRings"
  | "foodPlasticWineCork"
  | "foodPlasticHardContainer"
  | "foodPlasticIcePopContainer"
  | "foodPlasticOther"
  | "foodMetalCutlery"
  | "foodMetalDrinksCan"
  | "foodMetaltinCan"
  | "foodMetalBottleTop"
  | "foodMetalTinFoilWrapping"
  | "foodMetalOther"
  | "foodTetraPakDrinkCarton"
  | "foodTetraPakCoffeeCup"
  | "foodPaperWoodCorkGlassCupOrPaperPlate"
  | "foodPaperWoodCorkGlassStirrer"
  | "foodPaperWoodCorkGlassBottle"
  | "foodPaperWoodCorkGlassCork"
  | "foodPolystyreneTakeAwayContainer"
  | "foodPolystyreneCup"
  | "foodPolystyreneOther"
  | "foodOther"
  | "packagingPlasticThickShoppingBag"
  | "packagingPlasticThinShoppingBag"
  | "packagingPlasticRefuseSack"
  | "packagingPlasticPlasticBottleOrContainer"
  | "packagingPlasticFoamRoll"
  | "packagingPlasticOtherPackagingPlastic"
  | "packagingPlasticOther"
  | "sewageMixedCondom"
  | "sewageMixedTamponOrApplicator"
  | "sewageMixedSanitaryTowel"
  | "sewageMixedCottonBudStick"
  | "sewageMixedNappy"
  | "sewageMixedSyringeOrNeedle"
  | "sewageMixedMedicinePackaging"
  | "sewageMixedWetWipesOrBabyWipes"
  | "sewageMixedPlasticGloves"
  | "sewageMixedEyeDropDispenser"
  | "sewageMixedPlaster"
  | "sewageMixedOther"
  | "smokingMixedLighter"
  | "smokingMixedCigaretteButt"
  | "smokingMixedCigaretteOrTobaccoPackaging"
  | "smokingMixedOther"
  | "clothingMixedFaceMask"
  | "clothingMixedShoe"
  | "clothingMixedItemOfClothing"
  | "clothingMixedOther"
  | "miscMixedRope"
  | "miscMixedString"
  | "miscMixedRubberBand"
  | "miscMixedSpecksOfPaint"
  | "miscNurdles"
  | "fishingRecreational"
  | "fishingCommercial"
  | "unidentifiedPlasticSmall"
  | "unidentifiedPlasticMedium"
  | "unidentifiedPlasticLarge"
  | "unidentifiedPolystyrene"
  | "unidentifiedClothSmall"
  | "unidentifiedClothMedium"
  | "unidentifiedClothLarge"
  | "unidentifiedFoamSmall"
  | "unidentifiedFoamMedium"
  | "unidentifiedFoamLarge"
  | "glassOther"
  | "metalOther"
  | "largeMixedShoppingTrolley"
  | "largeMixedTyre"
  | "largeMixedTrafficConeOrSignOrBarrier"
  | "largeMixedLargeAppliance"
  | "largeMixedSmallAppliance"
  | "largeMixedMattress"
  | "largeMixedCarpet"
  | "largeMixedOther"
  | "buildingPlasticBuildingInsulationPieces"
  | "buildingPlasticCableTie"
  | "buildingPlasticRawlPlug"
  | "buildingPlasticWindowSpacerTile"
  | "buildingPlasticScaffoldBungee"
  | "buildingPlasticPipeLagging"
  | "buildingPlasticTileCrossSpacers"
  | "domesticPlasticBall"
  | "domesticPlasticBalloonOrStick"
  | "domesticPlasticGardening"
  | "domesticPlasticNerfBullet"
  | "domesticPlasticPackagingPeanut"
  | "domesticPlasticPen";

const FoodPlasticCutlery: Thames21LitterItem = "foodPlasticCutlery";
const FoodPlasticStraw: Thames21LitterItem = "foodPlasticStraw";
const FoodPlasticStirrer: Thames21LitterItem = "foodPlasticStirrer";
const FoodPlasticLollipopStick: Thames21LitterItem = "foodPlasticLollipopStick";
const FoodPlasticFoodWrapper: Thames21LitterItem = "foodPlasticFoodWrapper";
const FoodPlasticTakeAwayContainer: Thames21LitterItem =
  "foodPlasticTakeAwayContainer";
const FoodPlasticDrinkBottle: Thames21LitterItem = "foodPlasticDrinkBottle";
const FoodPlasticBottleTop: Thames21LitterItem = "foodPlasticBottleTop";
const FoodPlasticPlasticLid: Thames21LitterItem = "foodPlasticPlasticLid";
const FoodPlasticCup: Thames21LitterItem = "foodPlasticCup";
const FoodPlasticPlate: Thames21LitterItem = "foodPlasticPlate";
const FoodPlastic6Or4PackRings: Thames21LitterItem = "foodPlastic6Or4PackRings";
const FoodPlasticWineCork: Thames21LitterItem = "foodPlasticWineCork";
const FoodPlasticHardContainer: Thames21LitterItem = "foodPlasticHardContainer";
const FoodPlasticIcePopContainer: Thames21LitterItem =
  "foodPlasticIcePopContainer";
const FoodPlasticOther: Thames21LitterItem = "foodPlasticOther";
const FoodMetalCutlery: Thames21LitterItem = "foodMetalCutlery";
const FoodMetalDrinksCan: Thames21LitterItem = "foodMetalDrinksCan";
const FoodMetaltinCan: Thames21LitterItem = "foodMetaltinCan";
const FoodMetalBottleTop: Thames21LitterItem = "foodMetalBottleTop";
const FoodMetalTinFoilWrapping: Thames21LitterItem = "foodMetalTinFoilWrapping";
const FoodMetalOther: Thames21LitterItem = "foodMetalOther";
const FoodTetraPakDrinkCarton: Thames21LitterItem = "foodTetraPakDrinkCarton";
const FoodTetraPakCoffeeCup: Thames21LitterItem = "foodTetraPakCoffeeCup";
const FoodPaperWoodCorkGlassCupOrPaperPlate: Thames21LitterItem =
  "foodPaperWoodCorkGlassCupOrPaperPlate";
const FoodPaperWoodCorkGlassStirrer: Thames21LitterItem =
  "foodPaperWoodCorkGlassStirrer";
const FoodPaperWoodCorkGlassBottle: Thames21LitterItem =
  "foodPaperWoodCorkGlassBottle";
const FoodPaperWoodCorkGlassCork: Thames21LitterItem =
  "foodPaperWoodCorkGlassCork";
const FoodPolystyreneTakeAwayContainer: Thames21LitterItem =
  "foodPolystyreneTakeAwayContainer";
const FoodPolystyreneCup: Thames21LitterItem = "foodPolystyreneCup";
const FoodPolystyreneOther: Thames21LitterItem = "foodPolystyreneOther";
const FoodOther: Thames21LitterItem = "foodOther";
const PackagingPlasticThickShoppingBag: Thames21LitterItem =
  "packagingPlasticThickShoppingBag";
const PackagingPlasticThinShoppingBag: Thames21LitterItem =
  "packagingPlasticThinShoppingBag";
const PackagingPlasticRefuseSack: Thames21LitterItem =
  "packagingPlasticRefuseSack";
const PackagingPlasticPlasticBottleOrContainer: Thames21LitterItem =
  "packagingPlasticPlasticBottleOrContainer";
const PackagingPlasticFoamRoll: Thames21LitterItem = "packagingPlasticFoamRoll";
const PackagingPlasticOtherPackagingPlastic: Thames21LitterItem =
  "packagingPlasticOtherPackagingPlastic";
const PackagingPlasticOther: Thames21LitterItem = "packagingPlasticOther";
const SewageMixedCondom: Thames21LitterItem = "sewageMixedCondom";
const SewageMixedTamponOrApplicator: Thames21LitterItem =
  "sewageMixedTamponOrApplicator";
const SewageMixedSanitaryTowel: Thames21LitterItem = "sewageMixedSanitaryTowel";
const SewageMixedCottonBudStick: Thames21LitterItem =
  "sewageMixedCottonBudStick";
const SewageMixedNappy: Thames21LitterItem = "sewageMixedNappy";
const SewageMixedSyringeOrNeedle: Thames21LitterItem =
  "sewageMixedSyringeOrNeedle";
const SewageMixedMedicinePackaging: Thames21LitterItem =
  "sewageMixedMedicinePackaging";
const SewageMixedWetWipesOrBabyWipes: Thames21LitterItem =
  "sewageMixedWetWipesOrBabyWipes";
const SewageMixedPlasticGloves: Thames21LitterItem = "sewageMixedPlasticGloves";
const SewageMixedEyeDropDispenser: Thames21LitterItem =
  "sewageMixedEyeDropDispenser";
const SewageMixedPlaster: Thames21LitterItem = "sewageMixedPlaster";
const SewageMixedOther: Thames21LitterItem = "sewageMixedOther";
const SmokingMixedLighter: Thames21LitterItem = "smokingMixedLighter";
const SmokingMixedCigaretteButt: Thames21LitterItem =
  "smokingMixedCigaretteButt";
const SmokingMixedCigaretteOrTobaccoPackaging: Thames21LitterItem =
  "smokingMixedCigaretteOrTobaccoPackaging";
const SmokingMixedOther: Thames21LitterItem = "smokingMixedOther";
const ClothingMixedFaceMask: Thames21LitterItem = "clothingMixedFaceMask";
const ClothingMixedShoe: Thames21LitterItem = "clothingMixedShoe";
const ClothingMixedItemOfClothing: Thames21LitterItem =
  "clothingMixedItemOfClothing";
const ClothingMixedOther: Thames21LitterItem = "clothingMixedOther";
const MiscMixedRope: Thames21LitterItem = "miscMixedRope";
const MiscMixedString: Thames21LitterItem = "miscMixedString";
const MiscMixedRubberBand: Thames21LitterItem = "miscMixedRubberBand";
const MiscMixedSpecksOfPaint: Thames21LitterItem = "miscMixedSpecksOfPaint";
const MiscNurdles: Thames21LitterItem = "miscNurdles";
const FishingRecreational: Thames21LitterItem = "fishingRecreational";
const FishingCommercial: Thames21LitterItem = "fishingCommercial";
const UnidentifiedPlasticSmall: Thames21LitterItem = "unidentifiedPlasticSmall";
const UnidentifiedPlasticMedium: Thames21LitterItem =
  "unidentifiedPlasticMedium";
const UnidentifiedPlasticLarge: Thames21LitterItem = "unidentifiedPlasticLarge";
const UnidentifiedPolystyrene: Thames21LitterItem = "unidentifiedPolystyrene";
const UnidentifiedClothSmall: Thames21LitterItem = "unidentifiedClothSmall";
const UnidentifiedClothMedium: Thames21LitterItem = "unidentifiedClothMedium";
const UnidentifiedClothLarge: Thames21LitterItem = "unidentifiedClothLarge";
const UnidentifiedFoamSmall: Thames21LitterItem = "unidentifiedFoamSmall";
const UnidentifiedFoamMedium: Thames21LitterItem = "unidentifiedFoamMedium";
const UnidentifiedFoamLarge: Thames21LitterItem = "unidentifiedFoamLarge";
const GlassOther: Thames21LitterItem = "glassOther";
const MetalOther: Thames21LitterItem = "metalOther";
const LargeMixedShoppingTrolley: Thames21LitterItem =
  "largeMixedShoppingTrolley";
const LargeMixedTyre: Thames21LitterItem = "largeMixedTyre";
const LargeMixedTrafficConeOrSignOrBarrier: Thames21LitterItem =
  "largeMixedTrafficConeOrSignOrBarrier";
const LargeMixedLargeAppliance: Thames21LitterItem = "largeMixedLargeAppliance";
const LargeMixedSmallAppliance: Thames21LitterItem = "largeMixedSmallAppliance";
const LargeMixedMattress: Thames21LitterItem = "largeMixedMattress";
const LargeMixedCarpet: Thames21LitterItem = "largeMixedCarpet";
const LargeMixedOther: Thames21LitterItem = "largeMixedOther";
const BuildingPlasticBuildingInsulationPieces: Thames21LitterItem =
  "buildingPlasticBuildingInsulationPieces";
const BuildingPlasticCableTie: Thames21LitterItem = "buildingPlasticCableTie";
const BuildingPlasticRawlPlug: Thames21LitterItem = "buildingPlasticRawlPlug";
const BuildingPlasticWindowSpacerTiles: Thames21LitterItem =
  "buildingPlasticWindowSpacerTile";
const BuildingPlasticScaffoldBungee: Thames21LitterItem =
  "buildingPlasticScaffoldBungee";
const BuildingPlasticPipeLagging: Thames21LitterItem =
  "buildingPlasticPipeLagging";
const BuildingPlasticTileCrossSpacers: Thames21LitterItem =
  "buildingPlasticTileCrossSpacers";
const DomesticPlasticBall: Thames21LitterItem = "domesticPlasticBall";
const DomesticPlasticBalloonOrStick: Thames21LitterItem =
  "domesticPlasticBalloonOrStick";
const DomesticPlasticGardening: Thames21LitterItem = "domesticPlasticGardening";
const DomesticPlasticNerfBullet: Thames21LitterItem =
  "domesticPlasticNerfBullet";
const DomesticPlasticPackagingPeanut: Thames21LitterItem =
  "domesticPlasticPackagingPeanut";
const DomesticPlasticPen: Thames21LitterItem = "domesticPlasticPen";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21LitterItemEnum = {
  ...createEnum("Thames21LitterItem", [
    [FoodPlasticCutlery, "Cutlery"],
    [FoodPlasticStraw, "Straw"],
    [FoodPlasticStirrer, "Stirrer"],
    [FoodPlasticLollipopStick, "Lollipop stick"],
    [FoodPlasticFoodWrapper, "Food wrapper"],
    [FoodPlasticTakeAwayContainer, "Take-away container"],
    [FoodPlasticDrinkBottle, "Drink bottle (with or without lid)"],
    [FoodPlasticBottleTop, "Bottle top"],
    [FoodPlasticPlasticLid, "Plastic lid"],
    [FoodPlasticCup, "Cup"],
    [FoodPlasticPlate, "Plate"],
    [FoodPlastic6Or4PackRings, "6/4-pack rings"],
    [FoodPlasticWineCork, "Wine cork (plastic only)"],
    [FoodPlasticHardContainer, "Hard container (e.g. yoghurt pot, ready meal)"],
    [FoodPlasticIcePopContainer, "Ice pop container"],
    [FoodPlasticOther, "Other"],
    [FoodMetalCutlery, "Cutlery"],
    [FoodMetalDrinksCan, "Drinks can"],
    [FoodMetaltinCan, "Tin can"],
    [FoodMetalBottleTop, "Bottle top"],
    [FoodMetalTinFoilWrapping, "Tin foil wrapping"],
    [FoodMetalOther, "Other"],
    [FoodTetraPakDrinkCarton, "Drink carton"],
    [FoodTetraPakCoffeeCup, "Coffee cup"],
    [FoodPaperWoodCorkGlassCupOrPaperPlate, "Cup/plate (paper)"],
    [FoodPaperWoodCorkGlassStirrer, "Stirrer (wood)"],
    [FoodPaperWoodCorkGlassBottle, "Glass bottle"],
    [FoodPaperWoodCorkGlassCork, "Wine cork (real cork only)"],
    [FoodPolystyreneTakeAwayContainer, "Take-away container or plate"],
    [FoodPolystyreneCup, "Cup"],
    [FoodPolystyreneOther, "Other"],
    [FoodOther, "Other"],
    [PackagingPlasticThickShoppingBag, "Thick (durable) shopping bag"],
    [PackagingPlasticThinShoppingBag, "Thin (single use) shopping bag"],
    [PackagingPlasticRefuseSack, "Refuse sack"],
    [PackagingPlasticPlasticBottleOrContainer, "Plastic bottle/container"],
    [PackagingPlasticFoamRoll, "Foam roll packaging"],
    [PackagingPlasticOtherPackagingPlastic, "Other packaging (plastic)"],
    [PackagingPlasticOther, "Other"],
    [SewageMixedCondom, "Condom"],
    [SewageMixedTamponOrApplicator, "Tampon/applicator"],
    [SewageMixedSanitaryTowel, "Sanitary towel"],
    [SewageMixedCottonBudStick, "Cotton bud stick"],
    [SewageMixedNappy, "Nappy"],
    [SewageMixedSyringeOrNeedle, "Syringe/needle"],
    [SewageMixedMedicinePackaging, "Medicine packaging"],
    [SewageMixedWetWipesOrBabyWipes, "Wet wipes/baby wipes"],
    [SewageMixedPlasticGloves, "Plastic gloves"],
    [SewageMixedEyeDropDispenser, "Eye drop dispenser"],
    [SewageMixedPlaster, "Plaster"],
    [SewageMixedOther, "Other"],
    [SmokingMixedLighter, "Lighter"],
    [SmokingMixedCigaretteButt, "Cigarette butt"],
    [SmokingMixedCigaretteOrTobaccoPackaging, "Cigarette/tobacco packaging"],
    [SmokingMixedOther, "Other"],
    [ClothingMixedFaceMask, "Face mask"],
    [ClothingMixedShoe, "Shoe or flip-flop"],
    [ClothingMixedItemOfClothing, "Item of clothing"],
    [ClothingMixedOther, "Other"],
    [MiscMixedRope, "Rope"],
    [MiscMixedString, "String"],
    [MiscMixedRubberBand, "Rubber band"],
    [MiscMixedSpecksOfPaint, "Specks of paint"],
    [MiscNurdles, "Nurdles"],
    [FishingRecreational, "Recreational, e.g. rod, reel, line"],
    [FishingCommercial, "Commercial, e.g. net, bouy, basket"],
    [UnidentifiedPlasticSmall, "<2.5 cm"],
    [UnidentifiedPlasticMedium, "2.5 - 20 cm"],
    [UnidentifiedPlasticLarge, ">20 cm"],
    [UnidentifiedPolystyrene, "Pieces"],
    [UnidentifiedClothSmall, "<2.5 cm"],
    [UnidentifiedClothMedium, "2.5 - 20 cm"],
    [UnidentifiedClothLarge, ">20cm"],
    [UnidentifiedFoamSmall, "<2.5 cm"],
    [UnidentifiedFoamMedium, "2.5 - 20 cm"],
    [UnidentifiedFoamLarge, ">20 cm"],
    [GlassOther, "Other"],
    [MetalOther, "Other"],
    [LargeMixedShoppingTrolley, "Shopping trolley"],
    [LargeMixedTyre, "Tyre"],
    [LargeMixedTrafficConeOrSignOrBarrier, "Traffic cone/sign/barrier"],
    [LargeMixedLargeAppliance, "Large appliance (fridge, washing machine...)"],
    [LargeMixedSmallAppliance, "Small appliance (toaster, microwave...)"],
    [LargeMixedMattress, "Mattress"],
    [LargeMixedCarpet, "Carpet (>20 cm)"],
    [LargeMixedOther, "Other"],
    [BuildingPlasticBuildingInsulationPieces, "Building insulation pieces"],
    [BuildingPlasticCableTie, "Cable tie"],
    [BuildingPlasticRawlPlug, "Rawl plug"],
    [BuildingPlasticWindowSpacerTiles, "Window spacer tiles"],
    [BuildingPlasticScaffoldBungee, "Scaffold bungee"],
    [BuildingPlasticPipeLagging, "Pipe lagging"],
    [BuildingPlasticTileCrossSpacers, "Tile cross spacers"],
    [DomesticPlasticBall, "Ball"],
    [DomesticPlasticBalloonOrStick, "Balloon / stick"],
    [DomesticPlasticGardening, "Gardening (flower pot, gloves, plant label)"],
    [DomesticPlasticNerfBullet, "Nerf bullet"],
    [DomesticPlasticPackagingPeanut, "Packaging peanut"],
    [DomesticPlasticPen, "Pen"],
  ]),
  FoodPlasticCutlery,
  FoodPlasticStraw,
  FoodPlasticStirrer,
  FoodPlasticLollipopStick,
  FoodPlasticFoodWrapper,
  FoodPlasticTakeAwayContainer,
  FoodPlasticDrinkBottle,
  FoodPlasticBottleTop,
  FoodPlasticPlasticLid,
  FoodPlasticCup,
  FoodPlasticPlate,
  FoodPlastic6Or4PackRings,
  FoodPlasticWineCork,
  FoodPlasticHardContainer,
  FoodPlasticIcePopContainer,
  FoodPlasticOther,
  FoodMetalCutlery,
  FoodMetalDrinksCan,
  FoodMetaltinCan,
  FoodMetalBottleTop,
  FoodMetalTinFoilWrapping,
  FoodMetalOther,
  FoodTetraPakDrinkCarton,
  FoodTetraPakCoffeeCup,
  FoodPaperWoodCorkGlassCupOrPaperPlate,
  FoodPaperWoodCorkGlassStirrer,
  FoodPaperWoodCorkGlassBottle,
  FoodPaperWoodCorkGlassCork,
  FoodPolystyreneTakeAwayContainer,
  FoodPolystyreneCup,
  FoodPolystyreneOther,
  FoodOther,
  PackagingPlasticThickShoppingBag,
  PackagingPlasticThinShoppingBag,
  PackagingPlasticRefuseSack,
  PackagingPlasticPlasticBottleOrContainer,
  PackagingPlasticFoamRoll,
  PackagingPlasticOtherPackagingPlastic,
  PackagingPlasticOther,
  SewageMixedCondom,
  SewageMixedTamponOrApplicator,
  SewageMixedSanitaryTowel,
  SewageMixedCottonBudStick,
  SewageMixedNappy,
  SewageMixedSyringeOrNeedle,
  SewageMixedMedicinePackaging,
  SewageMixedWetWipesOrBabyWipes,
  SewageMixedPlasticGloves,
  SewageMixedEyeDropDispenser,
  SewageMixedPlaster,
  SewageMixedOther,
  SmokingMixedLighter,
  SmokingMixedCigaretteButt,
  SmokingMixedCigaretteOrTobaccoPackaging,
  SmokingMixedOther,
  ClothingMixedFaceMask,
  ClothingMixedShoe,
  ClothingMixedItemOfClothing,
  ClothingMixedOther,
  MiscMixedRope,
  MiscMixedString,
  MiscMixedRubberBand,
  MiscMixedSpecksOfPaint,
  MiscNurdles,
  FishingRecreational,
  FishingCommercial,
  UnidentifiedPlasticSmall,
  UnidentifiedPlasticMedium,
  UnidentifiedPlasticLarge,
  UnidentifiedPolystyrene,
  UnidentifiedClothSmall,
  UnidentifiedClothMedium,
  UnidentifiedClothLarge,
  UnidentifiedFoamSmall,
  UnidentifiedFoamMedium,
  UnidentifiedFoamLarge,
  GlassOther,
  MetalOther,
  LargeMixedShoppingTrolley,
  LargeMixedTyre,
  LargeMixedTrafficConeOrSignOrBarrier,
  LargeMixedLargeAppliance,
  LargeMixedSmallAppliance,
  LargeMixedMattress,
  LargeMixedCarpet,
  LargeMixedOther,
  BuildingPlasticBuildingInsulationPieces,
  BuildingPlasticCableTie,
  BuildingPlasticRawlPlug,
  BuildingPlasticWindowSpacerTiles,
  BuildingPlasticScaffoldBungee,
  BuildingPlasticPipeLagging,
  BuildingPlasticTileCrossSpacers,
  DomesticPlasticBall,
  DomesticPlasticBalloonOrStick,
  DomesticPlasticGardening,
  DomesticPlasticNerfBullet,
  DomesticPlasticPackagingPeanut,
  DomesticPlasticPen,
};

export default Thames21LitterItemEnum;
