import {
  BillingInterval,
  BillingUsage,
  Plan,
  StripeCouponId,
  Timestamp,
  WorkspaceAlias,
  timestampToDate,
} from "@cartographerio/types";
import { useDisclosure } from "@chakra-ui/react";
import { compareAsc, formatDistanceStrict } from "date-fns";
import { ReactElement, useCallback, useMemo } from "react";

import Button from "../components/Button";
import Fieldset from "../components/Fieldset";
import Para from "../components/Para";
import { routes } from "../routes";
import PlanChooserModal from "./PlanChooserModal";

interface TrialSectionProps {
  workspaceAlias: WorkspaceAlias;
  usage: BillingUsage;
  trialEnd?: Timestamp | null;
  couponId?: StripeCouponId | null;
}

export default function TrialSection(props: TrialSectionProps): ReactElement {
  const { workspaceAlias, usage, trialEnd, couponId } = props;

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const planLink = useCallback(
    (plan: Plan, interval: BillingInterval) =>
      routes.workspace.billing.checkout.url([workspaceAlias], {
        plan: plan.id,
        interval,
      }),
    [workspaceAlias]
  );

  const daysLeft = useMemo(() => {
    if (trialEnd == null) {
      return null;
    } else {
      const now = new Date();
      const then = timestampToDate(trialEnd);
      const comp = compareAsc(now, then);
      return comp > 0
        ? null
        : formatDistanceStrict(now, then, {
            unit: "day",
            roundingMethod: "floor",
          });
    }
  }, [trialEnd]);

  const legend =
    daysLeft != null
      ? `You have ${daysLeft} left on your free trial`
      : trialEnd != null
      ? "Your free trial has ended"
      : "You are on a free trial";

  return (
    <Fieldset legend={legend}>
      {daysLeft == null ? (
        <Para>Choose a plan to continue using Cartographer</Para>
      ) : (
        <Para>
          Choose a plan now and we&apos;ll start charging you when your trial
          ends
        </Para>
      )}

      <Button label="Choose a Plan" onClick={onModalOpen} colorScheme="blue" />

      <PlanChooserModal
        workspaceRef={workspaceAlias}
        usage={usage}
        defaultBillingInterval="Yearly"
        showCouponAlert={couponId != null}
        isOpen={isModalOpen}
        onClose={onModalClose}
        planLink={planLink}
      />
    </Fieldset>
  );
}
