export function findAndMap<I, O>(
  arr: I[],
  mapper: (val: I) => O | null | undefined
): O | null {
  for (const val of arr) {
    const output = mapper(val);

    if (output != null) {
      return output;
    }
  }
  return null;
}
