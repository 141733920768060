/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { Tagged, bridgesIsTaggedHack as isTagged } from "@cartographerio/util";

export type Uuid = Tagged<"Uuid">;

export type Semver = Tagged<"Semver">;

export type Email = Tagged<"Email">;

export interface NamedEmail {
  name: string;
  email: Email;
}

export type ScreenName = Tagged<"ScreenName">;

export type StripeCouponId = Tagged<"StripeCouponId">;

export type StripeCustomerId = Tagged<"StripeCustomerId">;

export type StripeInvoiceId = Tagged<"StripeInvoiceId">;

export type StripeSubscriptionId = Tagged<"StripeSubscriptionId">;

export type MapId = Tagged<"MapId">;

export type MapLayerId = Tagged<"MapLayerId">;

export type ProjectId = Tagged<"ProjectId">;

export type ProjectAlias = Tagged<"ProjectAlias">;

export type ProjectBreadcrumbId = Tagged<"ProjectBreadcrumbId">;

export type ProjectTemplateId = Tagged<"ProjectTemplateId">;

export type QualificationId = Tagged<"QualificationId">;

export type QualificationHistoryEventId = Tagged<"QualificationHistoryEventId">;

export type QualificationAlias = Tagged<"QualificationAlias">;

export type QualificationRef = QualificationId | QualificationAlias;

export type SurveyModuleId = Tagged<"SurveyModuleId">;

export type SurveyId = Tagged<"SurveyId">;

export type TeamId = Tagged<"TeamId">;

export type TeamAlias = Tagged<"TeamAlias">;

export type WorkspaceId = Tagged<"WorkspaceId">;

export type PlanId = Tagged<"PlanId">;

export type StripeProductId = Tagged<"StripeProductId">;

export type StripePriceId = Tagged<"StripePriceId">;

export type WorkspaceAlias = Tagged<"WorkspaceAlias">;

export type WorkspaceRef = WorkspaceId | WorkspaceAlias;

export type Subdomain = WorkspaceAlias;

export type UserId = Tagged<"UserId">;

export type KnownUserFeature =
  | "ChangeIdentity"
  | "IntegrationTest"
  | "NonBillable"
  | "Mobile2Preview"
  | "Mobile2Default"
  | "EnableMobileRiverfly";

export type UserFeature =
  | "ChangeIdentity"
  | "IntegrationTest"
  | "NonBillable"
  | "Mobile2Preview"
  | "Mobile2Default"
  | "EnableMobileRiverfly"
  | string;

export type UnencryptedPassword = Tagged<"UnencryptedPassword">;

export type InvitationId = Tagged<"InvitationId">;

export type InvitationCodeId = Tagged<"InvitationCodeId">;

export type InvitationCodeAlias = Tagged<"InvitationCodeAlias">;

export type InvitationCodeRef = InvitationCodeId | InvitationCodeAlias;

export type DataLicense =
  | "Default"
  | "CcBy4"
  | "CcByNd4"
  | "CcBySa4"
  | "CcByNc4"
  | "CcByNcNd4"
  | "CcByNcSa4"
  | "Ogl3";

export type Path = (string | number)[];

export type MessageLevel = "info" | "error";

export interface Message {
  level: MessageLevel;
  path: Path;
  text: string;
  data?: unknown;
}

export interface Timestamp {
  type: "Timestamp";
  iso8601: string;
}

export interface SearchResults<A> {
  results: A[];
  total: number;
}

export interface ServerVersion {
  name: string;
  version: string;
  booted: Timestamp;
  uptime: number;
}

export interface CheckedSuccess<A> {
  type: "CheckedSuccess";
  value: A;
}

export interface CheckedFailure {
  type: "CheckedFailure";
  errors: Message[];
}

export type Checked<A> = CheckedSuccess<A> | CheckedFailure;

export interface UrlResponse {
  url: string;
}

export interface InvitationEmailSettings {
  fromCoordinator: boolean;
  fromAddress?: NamedEmail | null;
  toAddresses: NamedEmail[];
}

export interface SurveyCompleteEmailSettings {
  toApprovers: boolean;
  toAddresses: NamedEmail[];
}

export interface SurveyApprovedEmailSettings {
  fromApprover: boolean;
  fromAddress?: NamedEmail | null;
  toSurveyors: boolean;
  toAddresses: NamedEmail[];
}

export type WorkspaceAccessLevel = "Full" | "ReadOnly" | "None";

export const isUuid = (v: any): v is Uuid => {
  return isTagged((a0: any): a0 is "Uuid" => a0 === "Uuid")(v);
};

export const isSemver = (v: any): v is Semver => {
  return isTagged((a0: any): a0 is "Semver" => a0 === "Semver")(v);
};

export const isEmail = (v: any): v is Email => {
  return isTagged((a0: any): a0 is "Email" => a0 === "Email")(v);
};

export const isNamedEmail = (v: any): v is NamedEmail => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "email" in v &&
    isEmail(v.email)
  );
};

export const isScreenName = (v: any): v is ScreenName => {
  return isTagged((a0: any): a0 is "ScreenName" => a0 === "ScreenName")(v);
};

export const isStripeCouponId = (v: any): v is StripeCouponId => {
  return isTagged((a0: any): a0 is "StripeCouponId" => a0 === "StripeCouponId")(
    v
  );
};

export const isStripeCustomerId = (v: any): v is StripeCustomerId => {
  return isTagged(
    (a0: any): a0 is "StripeCustomerId" => a0 === "StripeCustomerId"
  )(v);
};

export const isStripeInvoiceId = (v: any): v is StripeInvoiceId => {
  return isTagged(
    (a0: any): a0 is "StripeInvoiceId" => a0 === "StripeInvoiceId"
  )(v);
};

export const isStripeSubscriptionId = (v: any): v is StripeSubscriptionId => {
  return isTagged(
    (a0: any): a0 is "StripeSubscriptionId" => a0 === "StripeSubscriptionId"
  )(v);
};

export const isMapId = (v: any): v is MapId => {
  return isTagged((a0: any): a0 is "MapId" => a0 === "MapId")(v);
};

export const isMapLayerId = (v: any): v is MapLayerId => {
  return isTagged((a0: any): a0 is "MapLayerId" => a0 === "MapLayerId")(v);
};

export const isProjectId = (v: any): v is ProjectId => {
  return isTagged((a0: any): a0 is "ProjectId" => a0 === "ProjectId")(v);
};

export const isProjectAlias = (v: any): v is ProjectAlias => {
  return isTagged((a0: any): a0 is "ProjectAlias" => a0 === "ProjectAlias")(v);
};

export const isProjectBreadcrumbId = (v: any): v is ProjectBreadcrumbId => {
  return isTagged(
    (a0: any): a0 is "ProjectBreadcrumbId" => a0 === "ProjectBreadcrumbId"
  )(v);
};

export const isProjectTemplateId = (v: any): v is ProjectTemplateId => {
  return isTagged(
    (a0: any): a0 is "ProjectTemplateId" => a0 === "ProjectTemplateId"
  )(v);
};

export const isQualificationId = (v: any): v is QualificationId => {
  return isTagged(
    (a0: any): a0 is "QualificationId" => a0 === "QualificationId"
  )(v);
};

export const isQualificationHistoryEventId = (
  v: any
): v is QualificationHistoryEventId => {
  return isTagged(
    (a0: any): a0 is "QualificationHistoryEventId" =>
      a0 === "QualificationHistoryEventId"
  )(v);
};

export const isQualificationAlias = (v: any): v is QualificationAlias => {
  return isTagged(
    (a0: any): a0 is "QualificationAlias" => a0 === "QualificationAlias"
  )(v);
};

export const isQualificationRef = (v: any): v is QualificationRef => {
  return isQualificationId(v) || isQualificationAlias(v);
};

export const isSurveyModuleId = (v: any): v is SurveyModuleId => {
  return isTagged((a0: any): a0 is "SurveyModuleId" => a0 === "SurveyModuleId")(
    v
  );
};

export const isSurveyId = (v: any): v is SurveyId => {
  return isTagged((a0: any): a0 is "SurveyId" => a0 === "SurveyId")(v);
};

export const isTeamId = (v: any): v is TeamId => {
  return isTagged((a0: any): a0 is "TeamId" => a0 === "TeamId")(v);
};

export const isTeamAlias = (v: any): v is TeamAlias => {
  return isTagged((a0: any): a0 is "TeamAlias" => a0 === "TeamAlias")(v);
};

export const isWorkspaceId = (v: any): v is WorkspaceId => {
  return isTagged((a0: any): a0 is "WorkspaceId" => a0 === "WorkspaceId")(v);
};

export const isPlanId = (v: any): v is PlanId => {
  return isTagged((a0: any): a0 is "PlanId" => a0 === "PlanId")(v);
};

export const isStripeProductId = (v: any): v is StripeProductId => {
  return isTagged(
    (a0: any): a0 is "StripeProductId" => a0 === "StripeProductId"
  )(v);
};

export const isStripePriceId = (v: any): v is StripePriceId => {
  return isTagged((a0: any): a0 is "StripePriceId" => a0 === "StripePriceId")(
    v
  );
};

export const isWorkspaceAlias = (v: any): v is WorkspaceAlias => {
  return isTagged((a0: any): a0 is "WorkspaceAlias" => a0 === "WorkspaceAlias")(
    v
  );
};

export const isWorkspaceRef = (v: any): v is WorkspaceRef => {
  return isWorkspaceId(v) || isWorkspaceAlias(v);
};

export const isSubdomain = (v: any): v is Subdomain => {
  return isWorkspaceAlias(v);
};

export const isUserId = (v: any): v is UserId => {
  return isTagged((a0: any): a0 is "UserId" => a0 === "UserId")(v);
};

export const isKnownUserFeature = (v: any): v is KnownUserFeature => {
  return (
    v === "ChangeIdentity" ||
    v === "IntegrationTest" ||
    v === "NonBillable" ||
    v === "Mobile2Preview" ||
    v === "Mobile2Default" ||
    v === "EnableMobileRiverfly"
  );
};

export const isUserFeature = (v: any): v is UserFeature => {
  return (
    v === "ChangeIdentity" ||
    v === "IntegrationTest" ||
    v === "NonBillable" ||
    v === "Mobile2Preview" ||
    v === "Mobile2Default" ||
    v === "EnableMobileRiverfly" ||
    typeof v === "string"
  );
};

export const isUnencryptedPassword = (v: any): v is UnencryptedPassword => {
  return isTagged(
    (a0: any): a0 is "UnencryptedPassword" => a0 === "UnencryptedPassword"
  )(v);
};

export const isInvitationId = (v: any): v is InvitationId => {
  return isTagged((a0: any): a0 is "InvitationId" => a0 === "InvitationId")(v);
};

export const isInvitationCodeId = (v: any): v is InvitationCodeId => {
  return isTagged(
    (a0: any): a0 is "InvitationCodeId" => a0 === "InvitationCodeId"
  )(v);
};

export const isInvitationCodeAlias = (v: any): v is InvitationCodeAlias => {
  return isTagged(
    (a0: any): a0 is "InvitationCodeAlias" => a0 === "InvitationCodeAlias"
  )(v);
};

export const isInvitationCodeRef = (v: any): v is InvitationCodeRef => {
  return isInvitationCodeId(v) || isInvitationCodeAlias(v);
};

export const isDataLicense = (v: any): v is DataLicense => {
  return (
    v === "Default" ||
    v === "CcBy4" ||
    v === "CcByNd4" ||
    v === "CcBySa4" ||
    v === "CcByNc4" ||
    v === "CcByNcNd4" ||
    v === "CcByNcSa4" ||
    v === "Ogl3"
  );
};

export const isPath = (v: any): v is Path => {
  return (
    Array.isArray(v) &&
    v.every((i: any) => typeof i === "string" || typeof i === "number")
  );
};

export const isMessageLevel = (v: any): v is MessageLevel => {
  return v === "info" || v === "error";
};

export const isMessage = (v: any): v is Message => {
  return (
    typeof v === "object" &&
    v != null &&
    "level" in v &&
    isMessageLevel(v.level) &&
    "path" in v &&
    isPath(v.path) &&
    "text" in v &&
    typeof v.text === "string" &&
    (!("data" in v) || true)
  );
};

export const isTimestamp = (v: any): v is Timestamp => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "Timestamp" &&
    "iso8601" in v &&
    typeof v.iso8601 === "string"
  );
};

export const isSearchResults =
  <A>(isA: (a: any) => a is A) =>
  (v: any): v is SearchResults<A> => {
    return (
      typeof v === "object" &&
      v != null &&
      "results" in v &&
      Array.isArray(v.results) &&
      v.results.every((i: any) => isA(i)) &&
      "total" in v &&
      typeof v.total === "number"
    );
  };

export const isServerVersion = (v: any): v is ServerVersion => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "version" in v &&
    typeof v.version === "string" &&
    "booted" in v &&
    isTimestamp(v.booted) &&
    "uptime" in v &&
    typeof v.uptime === "number"
  );
};

export const isCheckedSuccess =
  <A>(isA: (a: any) => a is A) =>
  (v: any): v is CheckedSuccess<A> => {
    return (
      typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "CheckedSuccess" &&
      "value" in v &&
      isA(v.value)
    );
  };

export const isCheckedFailure = (v: any): v is CheckedFailure => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    v.type === "CheckedFailure" &&
    "errors" in v &&
    Array.isArray(v.errors) &&
    v.errors.every((i: any) => isMessage(i))
  );
};

export const isChecked =
  <A>(isA: (a: any) => a is A) =>
  (v: any): v is Checked<A> => {
    return (
      isCheckedSuccess((a0: any): a0 is A => isA(a0))(v) || isCheckedFailure(v)
    );
  };

export const isUrlResponse = (v: any): v is UrlResponse => {
  return (
    typeof v === "object" &&
    v != null &&
    "url" in v &&
    typeof v.url === "string"
  );
};

export const isInvitationEmailSettings = (
  v: any
): v is InvitationEmailSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "fromCoordinator" in v &&
    typeof v.fromCoordinator === "boolean" &&
    (!("fromAddress" in v) ||
      isNamedEmail(v.fromAddress) ||
      v.fromAddress === null) &&
    "toAddresses" in v &&
    Array.isArray(v.toAddresses) &&
    v.toAddresses.every((i: any) => isNamedEmail(i))
  );
};

export const isSurveyCompleteEmailSettings = (
  v: any
): v is SurveyCompleteEmailSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "toApprovers" in v &&
    typeof v.toApprovers === "boolean" &&
    "toAddresses" in v &&
    Array.isArray(v.toAddresses) &&
    v.toAddresses.every((i: any) => isNamedEmail(i))
  );
};

export const isSurveyApprovedEmailSettings = (
  v: any
): v is SurveyApprovedEmailSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "fromApprover" in v &&
    typeof v.fromApprover === "boolean" &&
    (!("fromAddress" in v) ||
      isNamedEmail(v.fromAddress) ||
      v.fromAddress === null) &&
    "toSurveyors" in v &&
    typeof v.toSurveyors === "boolean" &&
    "toAddresses" in v &&
    Array.isArray(v.toAddresses) &&
    v.toAddresses.every((i: any) => isNamedEmail(i))
  );
};

export const isWorkspaceAccessLevel = (v: any): v is WorkspaceAccessLevel => {
  return v === "Full" || v === "ReadOnly" || v === "None";
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
