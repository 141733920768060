// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type StdActivityType = "Walk" | "Run" | "Cycle" | "Indoor";

const Walk: StdActivityType = "Walk";
const Run: StdActivityType = "Run";
const Cycle: StdActivityType = "Cycle";
const Indoor: StdActivityType = "Indoor";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StdActivityTypeEnum = {
  ...createEnum("StdActivityType", [
    [Walk, "Walk"],
    [Run, "Run"],
    [Cycle, "Cycle"],
    [Indoor, "Indoor"],
  ]),
  Walk,
  Run,
  Cycle,
  Indoor,
};

export default StdActivityTypeEnum;
