import { selectOption } from "@cartographerio/topo-form";
import {
  BillingTypeEnum,
  WorkspaceBillingSettings,
} from "@cartographerio/types";
import { FormControl } from "@chakra-ui/react";
import { ReactElement } from "react";

import queries from "../../queries";
import Checkbox from "../components/Checkbox";
import CheckboxGroup from "../components/CheckboxGroup";
import FormLabel from "../components/FormLabel";
import Select from "../components/Select";
import Spaced from "../components/Spaced";
import TimestampField from "../components/TimestampField";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";

interface WorkspaceBillingSettingsEditorProps {
  value: WorkspaceBillingSettings;
  onChange?: (value: WorkspaceBillingSettings) => void;
}

export default function WorkspaceBillingSettingsEditor(
  props: WorkspaceBillingSettingsEditorProps
): ReactElement {
  const { value, onChange } = props;

  const apiParams = useApiParams();

  const couponOptions = useSuspenseQueryData(
    queries.billing.coupon.v1.list(apiParams),
    coupons => coupons.map(coupon => selectOption(coupon.id, coupon.name))
  );

  const planOptions = useSuspenseQueryData(
    queries.billing.plan.v1.list(apiParams),
    plans => plans.map(plan => selectOption(plan.id, plan.name))
  );

  return (
    <Spaced spacing="6">
      <FormControl>
        <FormLabel text="Billing Enabled" />
        <Checkbox
          value={value.enableBilling}
          onChange={enableBilling => onChange?.({ ...value, enableBilling })}
          checkboxLabel="Enable for this workspace"
        />
      </FormControl>
      <FormControl>
        <FormLabel text="Billing Type" />
        <Select.Standard
          value={value.billingType}
          options={BillingTypeEnum.entries}
          onChange={billingType => onChange?.({ ...value, billingType })}
          w="100%"
        />
      </FormControl>
      <FormControl>
        <FormLabel
          text="Unsubscribed Coupon"
          help={UNSUBSCRIBED_COUPON_ID_HELP}
        />
        <Select.Nullable
          value={value.unsubscribedCouponId ?? null}
          options={couponOptions}
          onChange={unsubscribedCouponId =>
            onChange?.({ ...value, unsubscribedCouponId })
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel
          text="Unsubscribed Trial End"
          help={UNSUBSCRIBED_TRIAL_END_HELP}
        />
        <TimestampField
          value={value.unsubscribedTrialEnd ?? null}
          onChange={unsubscribedTrialEnd =>
            onChange?.({ ...value, unsubscribedTrialEnd })
          }
          nullable={true}
          w="100%"
        />
      </FormControl>
      <FormControl>
        <FormLabel text="Plan Options" help={PLAN_OPTIONS_HELP} />
        <CheckboxGroup
          value={value.planOptions ?? []}
          options={planOptions}
          onChange={planOptions => onChange?.({ ...value, planOptions })}
          columns={1}
        />
      </FormControl>
    </Spaced>
  );
}

const UNSUBSCRIBED_COUPON_ID_HELP =
  "Coupons to apply when a workspace owner sets up a new subscription (has no effect on existing subscriptions).";

const UNSUBSCRIBED_TRIAL_END_HELP =
  "Trial end to apply when a workspace owner sets up a new subscription (has no effect on existing subscriptions).";

const PLAN_OPTIONS_HELP =
  "The plans to show in the plan chooser. Plans will appear disabled if the workspace does not meet their user/project limits.";
