// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessPlantFunctionalGroup =
  | "RosetteFormingShortStiffLeaves"
  | "FloatingLeavesButRooted"
  | "FreeFloating"
  | "SubmergedLinearLeaves"
  | "SubmergedBroadLeaves"
  | "SubmergedFineOrDissectedLeaves"
  | "EmergentBroadLeaves"
  | "EmergentNarrowLeaves"
  | "FilamentousAlgae";

const RosetteFormingShortStiffLeaves: NeLakeNaturalnessPlantFunctionalGroup =
  "RosetteFormingShortStiffLeaves";
const FloatingLeavesButRooted: NeLakeNaturalnessPlantFunctionalGroup =
  "FloatingLeavesButRooted";
const FreeFloating: NeLakeNaturalnessPlantFunctionalGroup = "FreeFloating";
const SubmergedLinearLeaves: NeLakeNaturalnessPlantFunctionalGroup =
  "SubmergedLinearLeaves";
const SubmergedBroadLeaves: NeLakeNaturalnessPlantFunctionalGroup =
  "SubmergedBroadLeaves";
const SubmergedFineOrDissectedLeaves: NeLakeNaturalnessPlantFunctionalGroup =
  "SubmergedFineOrDissectedLeaves";
const EmergentBroadLeaves: NeLakeNaturalnessPlantFunctionalGroup =
  "EmergentBroadLeaves";
const EmergentNarrowLeaves: NeLakeNaturalnessPlantFunctionalGroup =
  "EmergentNarrowLeaves";
const FilamentousAlgae: NeLakeNaturalnessPlantFunctionalGroup =
  "FilamentousAlgae";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessPlantFunctionalGroupEnum = {
  ...createEnum("NeLakeNaturalnessPlantFunctionalGroup", [
    [RosetteFormingShortStiffLeaves, "Rosette forming short stiff leaves"],
    [FloatingLeavesButRooted, "Floating leaves but rooted"],
    [FreeFloating, "Free floating"],
    [SubmergedLinearLeaves, "Submerged linear leaves"],
    [SubmergedBroadLeaves, "Submerged broad leaves"],
    [SubmergedFineOrDissectedLeaves, "Submerged fine/dissected leaves"],
    [EmergentBroadLeaves, "Emergent broad leaves"],
    [EmergentNarrowLeaves, "Emergent narrow leaves"],
    [FilamentousAlgae, "Filamentous algae"],
  ]),
  RosetteFormingShortStiffLeaves,
  FloatingLeavesButRooted,
  FreeFloating,
  SubmergedLinearLeaves,
  SubmergedBroadLeaves,
  SubmergedFineOrDissectedLeaves,
  EmergentBroadLeaves,
  EmergentNarrowLeaves,
  FilamentousAlgae,
};

export default NeLakeNaturalnessPlantFunctionalGroupEnum;
