// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRestorationRelevance = "Yes" | "No" | "DontKnow";

const Yes: NeRestorationRelevance = "Yes";
const No: NeRestorationRelevance = "No";
const DontKnow: NeRestorationRelevance = "DontKnow";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRestorationRelevanceEnum = {
  ...createEnum("NeRestorationRelevance", [
    [Yes, "Yes"],
    [No, "No"],
    [DontKnow, "Don't know"],
  ]),
  Yes,
  No,
  DontKnow,
};

export default NeRestorationRelevanceEnum;
