import { Path } from "@cartographerio/topo-core";
import {
  topoExpr,
  attachmentField,
  bounds,
  columns2,
  dynamicValue,
  enumSelectOptions,
  Field,
  form,
  matchesRegex,
  minValue,
  numberField,
  page,
  pointField,
  required,
  requiredIf,
  section,
  select,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { Result } from "@cartographerio/fp";
import { isNumber } from "@cartographerio/guard";
import {
  WrtAmmoniaEnum,
  WrtUpstreamThinkingLandUseEnum,
  WrtUpstreamThinkingCatchmentEnum,
} from "@cartographerio/inventory-enums";

interface ColorimeterReadingFieldOpts {
  label: string;
  path: Path;
  units?: string;
  decimalPlaces?: number;
}

function colorimeterReadingField(opts: ColorimeterReadingFieldOpts): Field {
  const { label, path, units, decimalPlaces: dp } = opts;

  const regex = new RegExp(
    dp === 0
      ? `^([+][+][+]|[0-9]+([.]0*)?)$`
      : `^([+][+][+]|[0-9]+([.][0-9]{0,${dp}}0*)?|[.][0-9]{1,${dp}}0*)$`
  );

  const help = `Enter '+++' if the meter cannot provide a reading.`;

  return textField({
    label,
    path,
    units,
    customRules: [
      matchesRegex({
        level: "error",
        message:
          dp === 0
            ? `Please enter a whole number or '+++'`
            : `Please enter a number up to ${dp} decimal places or '+++'.`,
        regex,
      }),
    ],
    help,
  });
}

export default form({
  title: "Upstream Thinking",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: columns2(
            vstack(
              select({
                label: "Catchment",
                path: ["data", "catchment"],
                options: enumSelectOptions(WrtUpstreamThinkingCatchmentEnum),
                required: required("info"),
              }),
              textField({
                label: "River/Stream",
                path: ["data", "river"],
                required: required("info"),
                help: "Name of the river or stream in which the readings were taken.",
              }),
              textField({
                label: "Site",
                path: ["data", "site"],
                required: required("info"),
                help: "Name of the site at which the readings were taken.",
              }),
              timestampField({
                label: "Date and Time",
                path: ["data", "recorded"],
                required: required("info"),
                help: "Date and time the readings were taken.",
                defaultValue: "now",
              })
            ),
            pointField({
              label: "Location",
              path: ["data", "location"],
              required: required("info"),
              help: "Location of the survey site.",
            })
          ),
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Land Use",
          path: [],
          blocks: [
            select({
              label: "Land Use in the Immediate Surroundings",
              path: ["data", "landUse"],
              options: enumSelectOptions(WrtUpstreamThinkingLandUseEnum),
              appearance: "radiogroup",
              columns: 3,
            }),
            textField({
              label: "Description of Other Land Use",
              path: ["data", "landUseDescription"],
              customRules: [
                requiredIf({
                  level: "info",
                  message: "You should enter a description.",
                  otherPath: ["data", "landUse"],
                  otherTest: value =>
                    value === WrtUpstreamThinkingLandUseEnum.Other,
                }),
              ],
              help: "If you selected 'Other' above, please describe the land use.",
            }),
          ],
        }),
        section({
          title: "Flow",
          path: ["data", "estimatedFlow"],
          blocks: columns2(
            numberField({
              label: "Estimated Channel Width",
              path: ["channelWidth"],
              units: "m",
              decimalPlaces: 1,
            }),
            numberField({
              label: "Estimated Average Depth",
              path: ["averageDepth"],
              units: "m",
              decimalPlaces: 2,
            }),
            numberField({
              label: "Estimated Average Water Velocity",
              path: ["averageVelocity"],
              units: "m/s",
              decimalPlaces: 2,
            }),
            dynamicValue({
              label: "Calculated Flow",
              valueType: "number",
              units: "m/s",
              value: topoExpr(env =>
                Result.tupled([
                  env.getAs(["channelWidth"], isNumber),
                  env.getAs(["averageDepth"], isNumber),
                  env.getAs(["averageVelocity"], isNumber),
                ])
                  .map(([w, d, v]) => w * d * v)
                  .getOrNull()
              ),
            })
          ),
        }),
        section({
          title: "Readings",
          path: [],
          blocks: columns2(
            numberField({
              label: "Temperature",
              path: ["data", "temperature"],
              units: "°C",
              bounds: bounds(-273, 100),
              decimalPlaces: 1,
            }),
            numberField({
              label: "pH",
              path: ["data", "ph"],
              bounds: bounds(0, 14),
              decimalPlaces: 2,
            }),
            numberField({
              label: "Electrical Conductivity",
              path: ["data", "electricalConductivity"],
              units: "µS/cm",
              bounds: minValue(0),
              decimalPlaces: 1,
            }),
            colorimeterReadingField({
              label: "Turbidity",
              path: ["data", "turbidity"],
              units: "FAU",
              decimalPlaces: 1,
            }),
            colorimeterReadingField({
              label: "Suspended Sediment",
              path: ["data", "suspendedSediment"],
              units: "mg/l",
              decimalPlaces: 0,
            }),
            colorimeterReadingField({
              label: "Colour",
              path: ["data", "color"],
              units: "Hazen",
              decimalPlaces: 0,
            }),
            numberField({
              label: "Total Dissolved Solids",
              path: ["data", "totalDissolvedSolids"],
              units: "mg/l",
              bounds: minValue(0),
              decimalPlaces: 1,
            }),
            numberField({
              label: "Tryptophan",
              path: ["data", "tryptophan"],
              units: "counts",
              bounds: minValue(0),
              decimalPlaces: 0,
            }),
            numberField({
              label: "Nitrates",
              path: ["data", "nitrate"],
              units: "ppm",
              bounds: minValue(0),
              decimalPlaces: 1,
            }),
            numberField({
              label: "Nitrites",
              path: ["data", "nitrite"],
              units: "ppm",
              bounds: minValue(0),
              decimalPlaces: 1,
            }),
            numberField({
              label: "Ammonia (meter)",
              path: ["data", "quantitativeAmmonia"],
              units: "ppm",
              bounds: minValue(0),
              decimalPlaces: 1,
            }),
            select({
              label: "Ammonia (test strip)",
              path: ["data", "qualitativeAmmonia"],
              options: WrtAmmoniaEnum.entries.map(({ value, label }) => ({
                value,
                label: `${label} ppm`,
              })),
            }),
            colorimeterReadingField({
              label: "Phosphates",
              path: ["data", "phosphate"],
              units: "mg/l",
              decimalPlaces: 2,
            }),
            vstack(
              numberField({
                label: "Dissolved Oxygen Saturation",
                path: ["data", "dissolvedOxygenSaturation"],
                units: "%",
                bounds: minValue(0),
                decimalPlaces: 1,
              }),
              numberField({
                label: "Dissolved Oxygen Concentration",
                path: ["data", "dissolvedOxygenConcentration"],
                units: "mg/l",
                bounds: minValue(0),
                decimalPlaces: 2,
              })
            ),
            numberField({
              label: "Colored Dissolved Organic Matter (CDOM)",
              path: ["data", "coloredDissolvedOrganicMatter"],
              units: "counts",
              bounds: minValue(0),
              decimalPlaces: 2,
            }),
            numberField({
              label: "Optical Brightening Agents (OBA)",
              path: ["data", "opticalBrighteningAgents"],
              units: "counts",
              bounds: minValue(0),
              decimalPlaces: 3,
            })
          ),
        }),
        section({
          title: "Comments",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "comments"],
              rows: 5,
              help: "Please include any comments, including details of any additional surveyors.",
            }),
          ],
        }),
      ],
    }),
  ],
});
