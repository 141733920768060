import {
  BaseLayerSchemas,
  mapSchema,
  MapSchema,
} from "@cartographerio/topo-map";
import { MapBase, MapBaseEnum } from "@cartographerio/types";
import { baseStyles as defaultBaseStyles } from "./baseStyles";

interface MapSchemaWithDefaultsProps
  extends Omit<MapSchema, "type" | "baseStyles" | "defaultBase"> {
  baseStyles?: BaseLayerSchemas;
  defaultBase?: MapBase;
}

export function mapSchemaWithDefaults(
  props: MapSchemaWithDefaultsProps
): MapSchema {
  const {
    mapId,
    baseStyles = defaultBaseStyles,
    defaultBase = MapBaseEnum.Terrain,
    ...rest
  } = props;

  return mapSchema({
    mapId,
    baseStyles,
    defaultBase: defaultBase,
    ...rest,
  });
}
