import { marker, MarkerStyle } from "../marker";
import {
  LookupBucket,
  RangeBucket,
  Buckets,
  Bucket,
  AutoBuckets,
} from "./type";
import { PropertyValue } from ".";
import { Enum, EnumEntry } from "@cartographerio/util";

export function lookupBucket<A>(
  value: A,
  marker: MarkerStyle,
  label?: string
): LookupBucket<A> {
  return {
    type: "LookupBucket",
    label,
    value,
    marker,
  };
}

export function rangeBucket<A>(
  minValue: A,
  maxValue: A,
  marker: MarkerStyle,
  label?: string,
  maxInclusive: boolean = false
): RangeBucket<A> {
  return {
    type: "RangeBucket",
    label,
    minValue,
    maxValue,
    maxInclusive,
    marker,
  };
}

export function autoBuckets<A>(props: Omit<AutoBuckets, "type">): Buckets<A> {
  return {
    type: "AutoBuckets",
    ...props,
  };
}

export function standardBuckets<A = PropertyValue>(
  buckets: Bucket<A>[]
): Buckets<A> {
  return {
    type: "StandardBuckets",
    buckets,
  };
}

export function enumBuckets<A extends string>(
  source: Enum<A> | EnumEntry<A>[],
  markers?: Partial<Record<A, MarkerStyle>>,
  noneValue?: A
): Buckets<A> {
  const entries = Array.isArray(source) ? source : source.entries;

  return standardBuckets(
    entries.map((entry, index) =>
      noneValue != null && entry.value === noneValue
        ? lookupBucket(entry.value, marker.empty, entry.label)
        : lookupBucket(
            entry.value,
            markers?.[entry.value] ??
              marker.hue((360 * index) / entries.length),
            entry.label
          )
    )
  );
}

export function nullableEnumBuckets<A>(
  source: Enum<A> | EnumEntry<A>[]
): Buckets<A | null> {
  const entries = Array.isArray(source) ? source : source.entries;

  return standardBuckets([
    ...entries.map((entry, index) =>
      lookupBucket(
        entry.value,
        marker.hue((360 * index) / entries.length),
        entry.label
      )
    ),
    lookupBucket(null, marker.empty, "(blank)"),
  ]);
}
