import { ApiConfig, MapBase, MapLayerId } from "@cartographerio/types";
import { formatUrl } from "../../../url";
import { SingleLayerStyleOptionsV2 } from "./common";

const basePath = "/map/style/v2";

export function base(apiConfig: ApiConfig, style?: MapBase): string {
  const parts = {
    path: `${basePath}/base`,
    query: { style },
  };

  return formatUrl(apiConfig.baseUrl, parts);
}

export function singleLayer(
  apiConfig: ApiConfig,
  layerId: MapLayerId,
  options: SingleLayerStyleOptionsV2 = {}
): string {
  const { project, workspace, base, simplify, promoteId } = options;

  const parts = {
    path: `${basePath}/layer/${layerId}`,
    query: { project, workspace, base, simplify, promoteId },
  };

  return formatUrl(apiConfig.baseUrl, parts);
}
