// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsNaturalBankProfile =
  | "None"
  | "NV"
  | "NVU"
  | "NVT"
  | "NST"
  | "NGT"
  | "NCT"
  | "NBE";

const None: UrsNaturalBankProfile = "None";
const Vertical: UrsNaturalBankProfile = "NV";
const VerticalWithUndercut: UrsNaturalBankProfile = "NVU";
const VerticalWithToe: UrsNaturalBankProfile = "NVT";
const Steep: UrsNaturalBankProfile = "NST";
const Gentle: UrsNaturalBankProfile = "NGT";
const Composite: UrsNaturalBankProfile = "NCT";
const NaturalBerm: UrsNaturalBankProfile = "NBE";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsNaturalBankProfileEnum = {
  ...createEnum("UrsNaturalBankProfile", [
    [None, "None (entirely artificial)"],
    [Vertical, "Vertical"],
    [VerticalWithUndercut, "Vertical with undercut"],
    [VerticalWithToe, "Vertical with toe"],
    [Steep, "Steep (> 45 degrees)"],
    [Gentle, "Gentle (< 45 degrees)"],
    [Composite, "Composite"],
    [NaturalBerm, "Natural Berm"],
  ]),
  None,
  Vertical,
  VerticalWithUndercut,
  VerticalWithToe,
  Steep,
  Gentle,
  Composite,
  NaturalBerm,
};

export default UrsNaturalBankProfileEnum;
