import { Env } from "@cartographerio/topo-core";
import { Result } from "@cartographerio/fp";
import { TopoError, TopoExpr } from "./type";

export function topoEval<A>(expr: TopoExpr<A>, env: Env): Result<TopoError, A> {
  function subEval<B>(subExpr: TopoExpr<B>): B {
    return subExpr.run(env, subEval);
  }

  try {
    return Result.pure(expr.run(env, subEval));
  } catch (error) {
    return Result.fail(error);
  }
}

export function optTopoEval<A>(
  expr: TopoExpr<A> | undefined,
  env: Env
): Result<TopoError, A | undefined> {
  return expr == null ? Result.pure(undefined) : topoEval(expr, env);
}
