import {
  PermissionCheck,
  Project,
  QualificationId,
  Workspace,
  qualificationCoordinatorRole,
} from "@cartographerio/types";
import { check } from "../check";
import { checkExhausted } from "@cartographerio/util";

export function member(project: Project): PermissionCheck {
  return check.named(
    `Member of project ${project.name} (${project.id})`,
    check.projectMember(project.id, project.workspaceId, project.teamIds)
  );
}

export function view(project: Project): PermissionCheck {
  return check.named(
    `View project ${project.name} (${project.id})`,
    canReadProjectInternal(project)
  );
}

export function viewTeams(project: Project): PermissionCheck {
  return check.named(
    `View teams for project ${project.name} (${project.id})`,
    canReadProjectInternal(project)
  );
}

export function viewSettings(project: Project): PermissionCheck {
  return check.named(
    `View settings for project ${project.name} (${project.id})`,
    check.projectCoordinator(project.id, project.workspaceId, null)
  );
}

export function viewMembers(project: Project): PermissionCheck {
  return check.named(
    `View members for project ${project.name} (${project.id})`,
    check.projectCoordinator(project.id, project.workspaceId, null)
  );
}

export function viewInvitations(project: Project): PermissionCheck {
  return check.named(
    `View invitations to project ${project.name} (${project.id})`,
    check.projectCoordinator(project.id, project.workspaceId, null)
  );
}

export function create(workspace: Workspace): PermissionCheck {
  return check.named(
    `Can create projects in workspace ${workspace.name} (${workspace.id})`,
    check.globalAdmin
  );
}

export function editSettings(project: Project): PermissionCheck {
  return check.named(
    `Edit settings for project ${project.name} (${project.id})`,
    check.projectCoordinator(project.id, project.workspaceId, null)
  );
}

export function grantAccess(project: Project): PermissionCheck {
  return check.named(
    `Grant access to project ${project.name} (${project.id})`,
    check.projectCoordinator(project.id, project.workspaceId, null)
  );
}

export function editQualificationAssociations(
  project: Project,
  qualificationId: QualificationId
): PermissionCheck {
  return check.named(
    `Add/remove qualification ${qualificationId} to/from project ${project.name} (${project.id})`,
    check.and(
      check.projectCoordinator(project.id, project.workspaceId, null),
      check.hasQualification(qualificationCoordinatorRole(qualificationId))
    )
  );
}

export function remove(project: Project): PermissionCheck {
  return check.named(`Can delete project ${project.id}`, check.globalAdmin);
}

function canReadProjectInternal(project: Project): PermissionCheck {
  switch (project.projectVisibility) {
    case "Private":
      return check.projectMember(
        project.id,
        project.workspaceId,
        project.teamIds
      );

    case "Workspace":
      return check.workspaceActive(project.workspaceId);

    default:
      return checkExhausted(project.projectVisibility);
  }
}
