// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesSubtidalSedimentSize =
  | "AR"
  | "BE"
  | "BO"
  | "CO"
  | "GP"
  | "SM"
  | "SA"
  | "FS"
  | "FM"
  | "OR"
  | "NV";

const Artificial: MrsMorphEstuariesSubtidalSedimentSize = "AR";
const Bedrock: MrsMorphEstuariesSubtidalSedimentSize = "BE";
const Boulder: MrsMorphEstuariesSubtidalSedimentSize = "BO";
const Cobble: MrsMorphEstuariesSubtidalSedimentSize = "CO";
const GravelPebble: MrsMorphEstuariesSubtidalSedimentSize = "GP";
const ShellyMaterial: MrsMorphEstuariesSubtidalSedimentSize = "SM";
const Sand: MrsMorphEstuariesSubtidalSedimentSize = "SA";
const FineSediments: MrsMorphEstuariesSubtidalSedimentSize = "FS";
const FluidMud: MrsMorphEstuariesSubtidalSedimentSize = "FM";
const Organic: MrsMorphEstuariesSubtidalSedimentSize = "OR";
const NotVisible: MrsMorphEstuariesSubtidalSedimentSize = "NV";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesSubtidalSedimentSizeEnum = {
  ...createEnum("MrsMorphEstuariesSubtidalSedimentSize", [
    [Artificial, "Artificial"],
    [Bedrock, "Bedrock"],
    [Boulder, "Boulder"],
    [Cobble, "Cobble"],
    [GravelPebble, "Gravel-Pebble"],
    [ShellyMaterial, "Shelly Material"],
    [Sand, "Sand"],
    [FineSediments, "Fine Sediments"],
    [FluidMud, "Fluid Mud"],
    [Organic, "Organic"],
    [NotVisible, "Not visible"],
  ]),
  Artificial,
  Bedrock,
  Boulder,
  Cobble,
  GravelPebble,
  ShellyMaterial,
  Sand,
  FineSediments,
  FluidMud,
  Organic,
  NotVisible,
};

export default MrsMorphEstuariesSubtidalSedimentSizeEnum;
