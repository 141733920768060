import { isArray } from "@cartographerio/guard";
import {
  ddmmyyyy,
  defaultTimeZone,
  ProjectAlias,
  SurveyModuleId,
  TimestampFormat,
  TimeZone,
} from "@cartographerio/types";
import { Enum } from "@cartographerio/util";
import { enumBuckets } from "../bucket";
import { marker as markers, MarkerStyle } from "../marker";
import {
  booleanFormatter,
  BucketsProp,
  enumFormatter,
  numberFormatter,
  stringFormatter,
  timestampFormatter,
  wrapBuckets,
} from "./helpers";
import {
  AttachmentAttribute,
  Attribute,
  AttributeGroup,
  BooleanAttribute,
  FeatureAttribute,
  NumberAttribute,
  PropertyFormatter,
  StringAttribute,
  SurveyAttribute,
  TeamAttribute,
  TimestampAttribute,
} from "./type";

export interface FeatureAttributeProps {
  attributeId: string;
  label: string;
  marker?: MarkerStyle;
}

export function featureAttr(props: FeatureAttributeProps): FeatureAttribute {
  const { attributeId, label, marker = markers.standard } = props;

  return {
    type: "FeatureAttribute",
    attributeId,
    label,
    marker,
  };
}

export interface StringAttributeProps {
  attributeId: string;
  label: string;
  propertyName?: string;
  format?: PropertyFormatter;
  unit?: string;
  buckets: BucketsProp<string | null>;
  showDistribution?: boolean;
}

export function stringAttr(props: StringAttributeProps): StringAttribute {
  const {
    attributeId,
    label,
    propertyName = attributeId,
    format = stringFormatter,
    unit,
    buckets,
    showDistribution = true,
  } = props;

  return {
    type: "StringAttribute",
    attributeId,
    label,
    propertyName,
    format,
    unit,
    buckets: wrapBuckets(buckets),
    showDistribution,
  };
}

export interface EnumAttributeProps<A extends string> {
  attributeId: string;
  label: string;
  propertyName?: string;
  unit?: string;
  enum: Enum<A>;
  noneValue?: A;
  markers?: Partial<Record<A, MarkerStyle>>;
  showDistribution?: boolean;
}

export function enumAttr<A extends string>(
  props: EnumAttributeProps<A>
): StringAttribute {
  const {
    attributeId,
    label,
    propertyName = attributeId,
    unit,
    enum: _enum,
    markers,
    noneValue,
    showDistribution = true,
  } = props;

  return {
    type: "StringAttribute",
    attributeId,
    label,
    propertyName,
    format: enumFormatter(_enum),
    unit,
    buckets: enumBuckets(_enum, markers, noneValue),
    showDistribution,
  };
}

export interface NumberAttributeProps {
  attributeId: string;
  label: string;
  propertyName?: string;
  decimalPlaces?: number;
  format?: PropertyFormatter;
  unit?: string;
  buckets: BucketsProp<number | null>;
  showDistribution?: boolean;
  showTimeline?: boolean;
}

export function numberAttr(props: NumberAttributeProps): NumberAttribute {
  const {
    attributeId,
    label,
    propertyName = attributeId,
    decimalPlaces,
    format = numberFormatter(decimalPlaces),
    unit,
    buckets,
    showDistribution = true,
    showTimeline = true,
  } = props;

  return {
    type: "NumberAttribute",
    attributeId,
    label,
    propertyName,
    format,
    unit,
    buckets: wrapBuckets(buckets),
    showDistribution,
    showTimeline,
  };
}

export interface BooleanAttributeProps {
  attributeId: string;
  label: string;
  propertyName?: string;
  format?: PropertyFormatter;
  buckets: BucketsProp<boolean | null>;
  showDistribution?: boolean;
}

export function booleanAttr(props: BooleanAttributeProps): BooleanAttribute {
  const {
    attributeId,
    label,
    propertyName = attributeId,
    format = booleanFormatter,
    buckets,
    showDistribution = true,
  } = props;

  return {
    type: "BooleanAttribute",
    attributeId,
    label,
    propertyName,
    format,
    buckets: wrapBuckets(buckets),
    showDistribution,
  };
}

export interface TimestampAttributeProps {
  attributeId: string;
  label: string;
  propertyName?: string;
  format?: TimestampFormat;
  timeZone?: TimeZone;
  buckets: BucketsProp<number | null>;
  showDistribution?: boolean;
}

export function timestampAttr(
  props: TimestampAttributeProps
): TimestampAttribute {
  const {
    attributeId,
    label,
    propertyName = attributeId,
    format = ddmmyyyy,
    timeZone = defaultTimeZone,
    buckets,
    showDistribution = true,
  } = props;

  return {
    type: "TimestampAttribute",
    attributeId,
    label,
    propertyName,
    format: timestampFormatter(format, timeZone),
    buckets: wrapBuckets(buckets),
    showDistribution,
  };
}

export interface SurveyAttributeProps {
  attributeId: string;
  label: string;
  propertyName?: string;
}

export function surveyAttr(
  attributeId: string,
  label: string = "Survey",
  propertyName: string = attributeId
): SurveyAttribute {
  return {
    type: "SurveyAttribute",
    attributeId,
    label,
    propertyName,
  };
}

export interface TeamAttributeOptions {
  propertyName?: string;
  project?: ProjectAlias;
  marker?: MarkerStyle;
  module?: SurveyModuleId;
}

export function teamAttr(
  attributeId: string,
  label: string,
  options: TeamAttributeOptions = {}
): TeamAttribute {
  const { propertyName = attributeId, project, marker, module } = options;

  return {
    type: "TeamAttribute",
    attributeId,
    label,
    propertyName,
    project,
    marker,
    module,
  };
}

export interface AttachmentAttributeProps {
  attributeId: string;
  label: string;
  surveyPropertyName?: string;
  folderPropertyName?: string;
}

export function attachmentAttr(
  attributeId: string,
  label: string = "Attachments",
  surveyPropertyName: string = "surveyId",
  folderPropertyName: string = attributeId
): AttachmentAttribute {
  return {
    type: "AttachmentAttribute",
    attributeId,
    label,
    surveyPropertyName,
    folderPropertyName,
  };
}

export function surveyorAttr(
  attributeId: string = "surveyorScreenName"
): StringAttribute {
  return stringAttr({
    attributeId,
    label: "Surveyor",
    buckets: "auto",
  });
}

export function attributeGroup(
  props: Attribute[] | Omit<AttributeGroup, "type">
): AttributeGroup {
  if (isArray(props)) {
    return { type: "AttributeGroup", attributes: props };
  } else {
    return { type: "AttributeGroup", ...props };
  }
}
