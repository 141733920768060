// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRtaRiverCategory = "Navigable" | "Large" | "Other";

const Navigable: MrsRtaRiverCategory = "Navigable";
const Large: MrsRtaRiverCategory = "Large";
const Other: MrsRtaRiverCategory = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRtaRiverCategoryEnum = {
  ...createEnum("MrsRtaRiverCategory", [
    [Navigable, "Navigable river/canal"],
    [Large, "Large river"],
    [Other, "Other river"],
  ]),
  Navigable,
  Large,
  Other,
};

export default MrsRtaRiverCategoryEnum;
