import { SelectOption, SelectSection } from "@cartographerio/topo-form";
import {
  AnonymisedUser,
  ProjectId,
  TeamId,
  Team,
  UserRef,
  roleTeamId,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";

interface CalcPrimaryTeamOptionsProps {
  users: AnonymisedUser[];
  teams: Team[];
  projectId: ProjectId;
  initialTeamId: TeamId | null;
  primarySurveyor: UserRef | null;
  primaryTeamId: TeamId | null;
}

/**
 * Collects a list of SelectSections for the primary team field,
 * sorted in the order they should appear in the list of options.
 */
export function calcPrimaryTeamOptions(
  props: CalcPrimaryTeamOptionsProps
): SelectSection<TeamId | null>[] {
  const {
    users,
    teams,
    projectId,
    initialTeamId,
    primarySurveyor,
    primaryTeamId,
  } = props;

  const initialTeam = findTeam(initialTeamId, teams);
  const primaryTeam = findTeam(primaryTeamId, teams);

  const primarySurveyorUser = findUser(primarySurveyor, users);

  const selectedSection: SelectSection<TeamId> = {
    heading: "Selected Team",
    options:
      primaryTeamId != null
        ? [teamIdToOption(primaryTeamId, primaryTeam, "selected-team")]
        : [],
  };

  const seenTeamIds: Set<TeamId> = new Set(
    primaryTeamId != null ? [primaryTeamId] : []
  );

  const surveyorTeamIds: Set<TeamId | null> = new Set(
    primarySurveyorUser != null
      ? filterAndMap(primarySurveyorUser.roles, roleTeamId)
      : []
  );

  const restSections: SelectSection<TeamId>[] = teams.reduce(
    (teamSections: SelectSection<TeamId>[], team) => {
      if (team.id !== primaryTeamId) {
        const [index, sectionKey] = !team.projectIds.includes(projectId)
          ? [2, "other-workspace-teams"]
          : surveyorTeamIds.has(team.id)
            ? [0, "surveyor-teams"]
            : [1, "other-project-teams"];

        teamSections.splice(index, 1, {
          ...teamSections[index],
          options: teamSections[index].options.concat(
            teamToOption(team, sectionKey)
          ),
        });
      }
      seenTeamIds.add(team.id);
      return teamSections;
    },
    [
      { heading: "Surveyor's Teams", options: [] },
      { heading: "Other Project Teams", options: [] },
      { heading: "Other Workspace Teams", options: [] },
    ]
  );

  const originalSection: SelectSection<TeamId> = {
    heading: "Original Team",
    options:
      initialTeamId != null && !seenTeamIds.has(initialTeamId)
        ? [teamIdToOption(initialTeamId, initialTeam, "original-team")]
        : [],
  };

  return [
    {
      heading: "No Team",
      options: [{ label: "No Team", value: null, key: "no-team" }],
    },
    selectedSection,
    originalSection,
    ...restSections,
  ].filter(({ options }) => options.length > 0);
}

function teamIdToOption(
  teamId: TeamId,
  team: Team | null,
  keyPrefix?: string
): SelectOption<TeamId> {
  return {
    label: team?.name == null ? `Team ${teamId}` : team.name,
    value: teamId,
    key: keyPrefix != null ? `${keyPrefix}.${teamId}` : teamId,
  };
}

function teamToOption(team: Team, keyPrefix?: string): SelectOption<TeamId> {
  return {
    label: team.name,
    value: team.id,
    key: keyPrefix != null ? `${keyPrefix}.${team.id}` : team.id,
  };
}

function findTeam(teamId: TeamId | null, teams: Team[]): Team | null {
  return teamId == null ? null : teams.find(team => team.id === teamId) ?? null;
}

function findUser(
  ref: UserRef | null,
  users: AnonymisedUser[]
): AnonymisedUser | null {
  return ref?.userId == null
    ? null
    : users.find(user => user.id === ref.userId) ?? null;
}
