// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsFlowType =
  | "FF"
  | "CH"
  | "BW"
  | "UW"
  | "UP"
  | "RP"
  | "SM"
  | "NP"
  | "DR";

const FreeFall: MrsFlowType = "FF";
const Chute: MrsFlowType = "CH";
const BrokenStandingWaves: MrsFlowType = "BW";
const UnbrokenStandingWaves: MrsFlowType = "UW";
const Upwelling: MrsFlowType = "UP";
const Rippled: MrsFlowType = "RP";
const Smooth: MrsFlowType = "SM";
const NoPerceptibleFlow: MrsFlowType = "NP";
const Dry: MrsFlowType = "DR";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsFlowTypeEnum = {
  ...createEnum("MrsFlowType", [
    [FreeFall, "Free fall"],
    [Chute, "Chute"],
    [BrokenStandingWaves, "Broken standing waves"],
    [UnbrokenStandingWaves, "Unbroken standing waves"],
    [Upwelling, "Upwelling"],
    [Rippled, "Rippled"],
    [Smooth, "Smooth"],
    [NoPerceptibleFlow, "No perceptible flow"],
    [Dry, "Dry"],
  ]),
  FreeFall,
  Chute,
  BrokenStandingWaves,
  UnbrokenStandingWaves,
  Upwelling,
  Rippled,
  Smooth,
  NoPerceptibleFlow,
  Dry,
};

export default MrsFlowTypeEnum;
