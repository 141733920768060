// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopHabitat =
  | "Hedgerow"
  | "Meadow"
  | "Woodland"
  | "Scrub"
  | "Other";

const Hedgerow: UclPitStopHabitat = "Hedgerow";
const Meadow: UclPitStopHabitat = "Meadow";
const Woodland: UclPitStopHabitat = "Woodland";
const Scrub: UclPitStopHabitat = "Scrub";
const Other: UclPitStopHabitat = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopHabitatEnum = {
  ...createEnum("UclPitStopHabitat", [
    [Hedgerow, "Hedgerow"],
    [Meadow, "Meadow"],
    [Woodland, "Woodland"],
    [Scrub, "Scrub (e.g. bramble)"],
    [Other, "Other"],
  ]),
  Hedgerow,
  Meadow,
  Woodland,
  Scrub,
  Other,
};

export default UclPitStopHabitatEnum;
