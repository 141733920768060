// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsRtaLevelOfConfinement =
  | "Confined"
  | "PartlyConfined"
  | "Unconfined";

const Confined: MrsRtaLevelOfConfinement = "Confined";
const PartlyConfined: MrsRtaLevelOfConfinement = "PartlyConfined";
const Unconfined: MrsRtaLevelOfConfinement = "Unconfined";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsRtaLevelOfConfinementEnum = {
  ...createEnum("MrsRtaLevelOfConfinement", [
    [Confined, "Confined"],
    [PartlyConfined, "Partly confined"],
    [Unconfined, "Unconfined"],
  ]),
  Confined,
  PartlyConfined,
  Unconfined,
};

export default MrsRtaLevelOfConfinementEnum;
