// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { InvitationCodeStatus } from "../types.generated";

const Canceled: InvitationCodeStatus = "Canceled";
const Expired: InvitationCodeStatus = "Expired";
const Pending: InvitationCodeStatus = "Pending";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const InvitationCodeStatusEnum = {
  ...createEnum("InvitationCodeStatus", [
    [Canceled, "Canceled"],
    [Expired, "Expired"],
    [Pending, "Pending"],
  ]),
  Canceled,
  Expired,
  Pending,
};

export default InvitationCodeStatusEnum;
