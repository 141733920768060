// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPondCountWhyLostOrDestroyed =
  | "L"
  | "DI"
  | "NI"
  | "B"
  | "C"
  | "M"
  | "U"
  | "O";

const LandDrainage: FhtPondCountWhyLostOrDestroyed = "L";
const DeliberateInfilling: FhtPondCountWhyLostOrDestroyed = "DI";
const NaturalInfilling: FhtPondCountWhyLostOrDestroyed = "NI";
const BuiltOver: FhtPondCountWhyLostOrDestroyed = "B";
const Curtilage: FhtPondCountWhyLostOrDestroyed = "C";
const Merged: FhtPondCountWhyLostOrDestroyed = "M";
const Unknown: FhtPondCountWhyLostOrDestroyed = "U";
const Other: FhtPondCountWhyLostOrDestroyed = "O";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPondCountWhyLostOrDestroyedEnum = {
  ...createEnum("FhtPondCountWhyLostOrDestroyed", [
    [LandDrainage, "Land drainage"],
    [DeliberateInfilling, "Deliberate infilling"],
    [NaturalInfilling, "Natural infilling with sediment"],
    [BuiltOver, "Built over"],
    [Curtilage, "Lost to curtilage"],
    [Merged, "Two ponds merged into one"],
    [Unknown, "Unknown"],
    [Other, "Other"],
  ]),
  LandDrainage,
  DeliberateInfilling,
  NaturalInfilling,
  BuiltOver,
  Curtilage,
  Merged,
  Unknown,
  Other,
};

export default FhtPondCountWhyLostOrDestroyedEnum;
