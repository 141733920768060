import {
  FeatureCollection,
  isFeatureCollection,
} from "@cartographerio/geometry";
import { isNumber } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  MapLayerId,
  ProjectRef,
  WorkspaceRef,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs } from "../../../response";
import { UrlParts } from "../../../url";
import { PartialParams } from "../../params";
import { FeatureSearchParamsV2, formatFeatureSearchParamsV2 } from "./common";
import { NonEmptyArray } from "@cartographerio/util";

const basePath = "/map/feature/v2";

export function search(
  apiParams: ApiParams,
  layerId: MapLayerId,
  params: PartialParams<FeatureSearchParamsV2> = {}
): IO<FeatureCollection> {
  return fetch
    .get({ apiParams, url: searchUrl(layerId, params) })
    .chain(contentAs("FeatureCollection", isFeatureCollection));
}

export function searchUrl(
  layerId: MapLayerId,
  params: PartialParams<FeatureSearchParamsV2> = {}
): UrlParts {
  return {
    path: `${basePath}/${layerId}`,
    query: formatFeatureSearchParamsV2(params),
  };
}

export function count(
  apiParams: ApiParams,
  layerId: MapLayerId,
  params: PartialParams<Omit<FeatureSearchParamsV2, "promoteId">> = {}
): IO<number> {
  const url: UrlParts = {
    path: `${basePath}/${layerId}/count`,
    query: formatFeatureSearchParamsV2(params),
  };

  return fetch.get({ apiParams, url }).chain(contentAs("number", isNumber));
}

export function tileUrl(
  layer: MapLayerId,
  project?: NonEmptyArray<ProjectRef> | null,
  workspace?: WorkspaceRef | null,
  simplify?: boolean,
  promoteId?: string
): UrlParts {
  return {
    path: `${basePath}/${layer}/tile/{z}/{x}/{y}`,
    query: { project, workspace, simplify, promoteId },
    encodePath: false,
  };
}

export function reset(apiParams: ApiParams, layer?: MapLayerId): IO<void> {
  const url = {
    path: layer != null ? `${basePath}/${layer}/reset` : `${basePath}/reset`,
  };

  return fetch.get({ apiParams, url }).void();
}
