import { MapLayerId, Message, Timestamp, nowTimestamp } from "../core";
import { PermissionCheck, PermissionCheckParams } from "../permission";
import { ApiError } from "./types.generated";

export function expiredAuthorizationError(
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "ExpiredAuthorizationError",
    timestamp,
  };
}

export function invalidAuthorizationError(
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "InvalidAuthorizationError",
    timestamp,
  };
}

export function missingAuthorizationError(
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "MissingAuthorizationError",
    timestamp,
  };
}

export function forbiddenError(
  check: PermissionCheck,
  user?: Partial<PermissionCheckParams>,
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "ForbiddenError",
    check,
    userId: user?.id,
    roles: user?.roles,
    timestamp,
  };
}

export function badRequestError(
  message: string,
  extraData: unknown,
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "BadRequestError",
    message,
    extraData,
    timestamp,
  };
}

export function notFoundError(
  itemType: string,
  item: string,
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "NotFoundError",
    itemType,
    item,
    timestamp,
  };
}

export function duplicateError(
  itemType: string,
  item: string,
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "DuplicateError",
    itemType,
    item,
    timestamp,
  };
}

export function mapQueryUnsupportedError(
  layerId: MapLayerId,
  message: string,
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "MapQueryUnsupportedError",
    layerId,
    message,
    timestamp,
  };
}

export function internalError(
  message: string,
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "InternalError",
    message,
    timestamp,
  };
}

export function validationError(
  value: unknown,
  errors: Message[],
  message: string,
  status: number,
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "ValidationError",
    value,
    errors,
    message,
    status,
    timestamp,
  };
}

export function networkError(
  message: string,
  extraData: unknown = undefined,
  timestamp: Timestamp = nowTimestamp()
): ApiError {
  return {
    type: "NetworkError",
    message,
    extraData,
    timestamp,
  };
}
