// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsSurveyStart = "upstream" | "downstream";

const Upstream: UrsSurveyStart = "upstream";
const Downstream: UrsSurveyStart = "downstream";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsSurveyStartEnum = {
  ...createEnum("UrsSurveyStart", [
    [Upstream, "Upstream"],
    [Downstream, "Downstream"],
  ]),
  Upstream,
  Downstream,
};

export default UrsSurveyStartEnum;
