// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { WorkspaceSearchRoleParam } from "../types.generated";

const All: WorkspaceSearchRoleParam = "all";
const Registered: WorkspaceSearchRoleParam = "registered";
const Active: WorkspaceSearchRoleParam = "active";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WorkspaceSearchRoleParamEnum = {
  ...createEnum("WorkspaceSearchRoleParam", [
    [All, "All"],
    [Registered, "Registered"],
    [Active, "Active"],
  ]),
  All,
  Registered,
  Active,
};

export default WorkspaceSearchRoleParamEnum;
