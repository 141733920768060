import {
  attachmentAttr,
  attributeGroup,
  booleanAttr,
  enumAttr,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  UclPitStopCrassulaHelmsiiEnum,
  UclPitStopDescriptionEnum,
  UclPitStopMapPointEnum,
  UclPitStopNorfolkMostWantedEnum,
  UclPitStopPondStatusEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const UclPitStop = mapSchemaWithDefaults({
  mapId: unsafeMapId("UclPitStop"),
  title: "Project Pit Stop",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("UclPitStop"),
      title: "Project Pit Stop",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("UclPitStop"),
        attribution: [],
      }),
      defaultAttribute: "status",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          attributes: [
            timestampAttr({
              label: "Date/Time",
              attributeId: "timestamp",
              buckets: "auto",
            }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("UclPitStop"),
            }),
            surveyAttr("surveyId", "Survey"),
            attachmentAttr("arrivalPhotographs", "Arrival Photographs"),
            numberAttr({
              label: "Pit Stop Pond Number",
              attributeId: "pitStopPondNumber",
              buckets: "auto",
              decimalPlaces: 0,
            }),
            enumAttr({
              label: "Pond Status",
              attributeId: "status",
              enum: UclPitStopPondStatusEnum,
              markers: {
                Ghost: marker.fromColor("#FFF"),
                Zombie: marker.fromColor("#644536"),
                Living: marker.fromColor("#548C2F"),
                Other: marker.fromColor("#A020F0"),
              },
            }),
            enumAttr({
              label: "What is seen on the OS map?",
              attributeId: "point",
              enum: UclPitStopMapPointEnum,
              markers: {
                Water: marker.fromColor("#4259C3"),
                Vegetation: marker.fromColor("#548C2F"),
                Other: marker.fromColor("#777"),
              },
            }),
            numberAttr({
              label: "Ponds within 1km",
              attributeId: "pondCount",
              buckets: [
                rangeBucket(
                  null,
                  1,
                  marker.fromColor("#9EC2FF"),
                  undefined,
                  true
                ),
                rangeBucket(2, 3, marker.fromColor("#7B9FF2"), "2"),
                rangeBucket(3, 4, marker.fromColor("#4259C3"), "3"),
                rangeBucket(4, 5, marker.fromColor("#212AA5"), "4"),
                rangeBucket(5, null, marker.fromColor("#03018C")),
              ],
            }),
            enumAttr({
              label: "Description of Pond",
              attributeId: "description",
              enum: UclPitStopDescriptionEnum,
              markers: {
                Farmland: marker.fromColor("#644536"),
                NatureReserveOrWoodland: marker.fromColor("#548C2F"),
              },
            }),
            numberAttr({
              label: "Water Coverage",
              attributeId: "waterCoverage",
              unit: "%",
              buckets: [
                rangeBucket(null, 20, marker.fromColor("#9EC2FF")),
                rangeBucket(20, 40, marker.fromColor("#7B9FF2")),
                rangeBucket(40, 60, marker.fromColor("#4259C3")),
                rangeBucket(60, 80, marker.fromColor("#212AA5")),
                rangeBucket(80, null, marker.fromColor("#03018C")),
              ],
            }),
            numberAttr({
              label: "Canopy Cover of Water Surface",
              attributeId: "areaShade",
              unit: "%",
              buckets: [
                rangeBucket(null, 20, marker.fromColor("#E6E0D0")),
                rangeBucket(20, 40, marker.fromColor("#B6A691")),
                rangeBucket(40, 60, marker.fromColor("#9F8872")),
                rangeBucket(60, 80, marker.fromColor("#876B52")),
                rangeBucket(80, null, marker.fromColor("#6F4E33")),
              ],
            }),
            numberAttr({
              label: "Aquatic Plant Cover",
              attributeId: "aquaticPlantCover",
              unit: "%",
              buckets: [
                rangeBucket(null, 20, marker.fromColor("#B7FFBF")),
                rangeBucket(20, 40, marker.fromColor("#95F985")),
                rangeBucket(40, 60, marker.fromColor("#4DED30")),
                rangeBucket(60, 80, marker.fromColor("#26D701")),
                rangeBucket(80, null, marker.fromColor("#00AB08")),
              ],
            }),
            enumAttr({
              label: "Norfolk's Most Wanted",
              attributeId: "norfolkMostWanted",
              enum: UclPitStopNorfolkMostWantedEnum,
            }),
            enumAttr({
              label: "Crassula Helmsii",
              attributeId: "crassula",
              enum: UclPitStopCrassulaHelmsiiEnum,
              markers: {
                None: marker.fromColor("#FFF"),
                SmallFragments: marker.fromColor("#777"),
                EasilyVisible: marker.fromColor("#000"),
              },
            }),
          ],
        }),
        attributeGroup({
          label: "Demo Calculations",
          attributes: [
            booleanAttr({
              label: "Calculation 1",
              attributeId: "calculation1",
              buckets: [
                lookupBucket(true, marker.fromColor("pink"), "Yes"),
                lookupBucket(true, marker.empty, "No"),
              ],
            }),
            booleanAttr({
              label: "Calculation 2",
              attributeId: "calculation2",
              buckets: [
                lookupBucket(true, marker.fromColor("red"), "Yes"),
                lookupBucket(true, marker.empty, "No"),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
