import {
  ApiAuth,
  ApiClientInfo,
  ApiConfig,
  authHeader,
} from "@cartographerio/types";

export interface HeadersObject {
  [key: string]: string;
}

export function authHeaders(
  { client }: ApiConfig,
  auth: ApiAuth | undefined
): HeadersObject {
  const ans: HeadersObject = {};

  if (client != null) {
    const clientHeader = formatClientHeader(client);
    ans["Cartographer-Client"] = clientHeader;

    if (client.overrideUserAgent) {
      ans["User-Agent"] = clientHeader;
    }
  }

  if (auth != null) {
    ans["Authorization"] = authHeader(auth);
  }

  return ans;
}

export const jsonHeaders: HeadersObject = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export function combineHeaders(...headers: HeadersObject[]): HeadersObject {
  return headers.reduce((a, b) => ({ ...a, ...b }), {});
}

export function formatClientHeader(client: ApiClientInfo): string {
  const { name, version, build, hints } = client;
  let ans = `${name}/${version}`;

  if (build != null) {
    ans += `+${build}`;
  }

  if (hints != null) {
    for (const hint of hints) {
      ans += ` ${hint}`;
    }
  }

  return ans;
}
