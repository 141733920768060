// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type OutfallSafariAesthetics =
  | "None"
  | "Faint"
  | "Mild"
  | "Strong"
  | "Gross";

const None: OutfallSafariAesthetics = "None";
const Faint: OutfallSafariAesthetics = "Faint";
const Mild: OutfallSafariAesthetics = "Mild";
const Strong: OutfallSafariAesthetics = "Strong";
const Gross: OutfallSafariAesthetics = "Gross";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OutfallSafariAestheticsEnum = {
  ...createEnum("OutfallSafariAesthetics", [
    [None, "No odour or visible aesthetics (0)"],
    [Faint, "Faint smell, slight discolouration (2)"],
    [
      Mild,
      "Mild smell, mild discoloration and small covering of sewage fungus (4)",
    ],
    [
      Strong,
      "Strong smell, strong discoloration, large coverage of sewage fungus and/or litter (6)",
    ],
    [Gross, "Gross smell, gross sewage, very extensive problem (10)"],
  ]),
  None,
  Faint,
  Mild,
  Strong,
  Gross,
};

export default OutfallSafariAestheticsEnum;
