import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp, timestampEpoch } from "@cartographerio/types";
import { chain } from "lodash";

interface PartialCriterion {
  recorded?: Timestamp | null;
}

export interface PartialData {
  organisationName?: string | null;
  projectName?: string | null;
  location?: Point | null;
  criterion1: PartialCriterion;
  criterion2: PartialCriterion;
  criterion3: PartialCriterion;
  criterion4: PartialCriterion;
  criterion5: PartialCriterion;
  criterion6: PartialCriterion;
  criterion7: PartialCriterion;
}

export function isPartialData(data: unknown): data is PartialData {
  const isPartialCriterion = (data: unknown): data is PartialCriterion =>
    isObject(data) && hasOptionalKey(data, "recorded", isNullable(isTimestamp));

  return (
    isObject(data) &&
    hasOptionalKey(data, "organisationName", isNullable(isString)) &&
    hasOptionalKey(data, "projectName", isNullable(isString)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasKey(data, "criterion1", isPartialCriterion) &&
    hasKey(data, "criterion2", isPartialCriterion) &&
    hasKey(data, "criterion3", isPartialCriterion) &&
    hasKey(data, "criterion4", isPartialCriterion) &&
    hasKey(data, "criterion5", isPartialCriterion) &&
    hasKey(data, "criterion6", isPartialCriterion) &&
    hasKey(data, "criterion7", isPartialCriterion)
  );
}

const g = Result.guard(isPartialData, "PartialFhtPondCount");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    [
      data.location == null ? "Unknown NGR" : safePointToNgr(data.location),
      data.organisationName,
      data.projectName,
    ]
      .filter(x => !!x)
      .join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data =>
    chain([
      data.criterion1.recorded == null ? [] : [data.criterion1.recorded],
      data.criterion2.recorded == null ? [] : [data.criterion2.recorded],
      data.criterion3.recorded == null ? [] : [data.criterion3.recorded],
      data.criterion4.recorded == null ? [] : [data.criterion4.recorded],
      data.criterion5.recorded == null ? [] : [data.criterion5.recorded],
      data.criterion6.recorded == null ? [] : [data.criterion6.recorded],
      data.criterion7.recorded == null ? [] : [data.criterion7.recorded],
    ])
      .flatMap(x => x)
      .minBy(date => timestampEpoch(date))
      .value()
  );
}

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return g(data).map(data => ({
    ...data,
    criterion1: { ...data.criterion1, recorded: null },
    criterion2: { ...data.criterion1, recorded: null },
    criterion3: { ...data.criterion1, recorded: null },
    criterion4: { ...data.criterion1, recorded: null },
    criterion5: { ...data.criterion1, recorded: null },
    criterion6: { ...data.criterion1, recorded: null },
    criterion7: { ...data.criterion1, recorded: null },
  }));
}
