import { QualificationId } from "../../core";
import { unsafeQualificationRole } from "./internal";
import { QualificationRole, QualificationRoleName } from "../types.generated";

export function qualificationRole(
  name: QualificationRoleName,
  qualificationId: QualificationId
): QualificationRole {
  return unsafeQualificationRole(`Q:${name}:${qualificationId}`);
}

export function qualificationExpiredRole(
  qualificationId: QualificationId
): QualificationRole {
  return qualificationRole("Expired", qualificationId);
}

export function qualificationTraineeRole(
  qualificationId: QualificationId
): QualificationRole {
  return qualificationRole("Trainee", qualificationId);
}

export function qualificationQualifiedRole(
  qualificationId: QualificationId
): QualificationRole {
  return qualificationRole("Qualified", qualificationId);
}

export function qualificationTrainerRole(
  qualificationId: QualificationId
): QualificationRole {
  return qualificationRole("Trainer", qualificationId);
}

export function qualificationCoordinatorRole(
  qualificationId: QualificationId
): QualificationRole {
  return qualificationRole("Coordinator", qualificationId);
}
