import {
  PermissionCheck,
  ProjectId,
  Project,
  Team,
  Workspace,
} from "@cartographerio/types";
import { check } from "../check";

export function member(team: Team): PermissionCheck {
  return check.named(
    `Is member of team ${team.name}`,
    check.teamMember(team.id, team.workspaceId, team.projectIds)
  );
}

export function viewMembers(team: Team): PermissionCheck {
  return check.named(
    `View members for team ${team.name} (${team.id})`,
    canReadInternal(team)
  );
}

export function viewInvitations(team: Team): PermissionCheck {
  return check.named(
    `View invitations for team ${team.name} (${team.id})`,
    canReadInternal(team)
  );
}

// Note: this means "can we view settings in the UI",
// not "can we download settings from the API":
export function viewSettings(team: Team): PermissionCheck {
  return check.named(
    `View settings for team ${team.name} (${team.id})`,
    canReadInternal(team)
  );
}

export function editSettings(team: Team): PermissionCheck {
  return check.named(
    `Edit settings for team ${team.name} (${team.id})`,
    canUpdateInternal(team)
  );
}

export function grantAccess(team: Team): PermissionCheck {
  return check.named(
    `Grant access to team ${team.name} (${team.id})`,
    canUpdateInternal(team)
  );
}

// Is the user able to *view* the create screen:
export function create(
  workspace: Workspace,
  workspaceProjects: Project[]
): PermissionCheck {
  return check.named(
    `Can create teams in workspace ${workspace.name} (${workspace.id})`,
    check.or(
      ...workspaceProjects.map(project =>
        check.projectCoordinator(project.id, project.workspaceId, null)
      )
    )
  );
}

export function associate(team: Team, projectId: ProjectId): PermissionCheck {
  return check.named(
    `Can associate project ${projectId} with team ${team.name} (${team.id})`,
    check.and(
      canUpdateInternal(team),
      check.projectCoordinator(projectId, team.workspaceId, null)
    )
  );
}

export function canReadInternal(team: Team): PermissionCheck {
  return check.teamCoordinator(team.id, team.workspaceId, team.projectIds);
}

export function canUpdateInternal(team: Team): PermissionCheck {
  return check.teamCoordinator(team.id, team.workspaceId, team.projectIds);
}
