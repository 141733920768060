import { Path } from "@cartographerio/topo-core";
import { ChangeAction, DeleteAction, InsertAction, ResetAction } from "./type";
import { TeamId, UserRef } from "@cartographerio/types";

export function changeAction(path: Path, value: unknown): ChangeAction {
  return {
    type: "Change",
    path,
    value,
  };
}

export function changeSurveyorAction(surveyor: UserRef | null): ChangeAction {
  return changeAction(["surveyor"], surveyor);
}

export function changeTeamIdAction(teamId: TeamId | null): ChangeAction {
  return changeAction(["teamId"], teamId);
}

export function insertAction(path: Path, ...values: unknown[]): InsertAction {
  return {
    type: "Insert",
    path,
    values,
  };
}

export function deleteAction(path: Path, index: number): DeleteAction {
  return {
    type: "Delete",
    path,
    index,
  };
}

export function resetAction(props: Omit<ResetAction, "type">): ResetAction {
  return { type: "Reset", ...props };
}
