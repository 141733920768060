import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  river?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "river", isNullable(isString))
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialMrsWildInvaders"
  )(data).map(data =>
    [
      data.river ?? "Unknown River",
      data.location == null ? "Unknown NGR" : safePointToNgr(data.location),
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialMrsWildInvaders"
  )(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialMrsWildInvaders"
  )(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return Result.guard(
    isPartialData,
    "PartialMrsWildInvaders"
  )(data).map(data => ({
    ...data,
    recorded: null,
  }));
}
