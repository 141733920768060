import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  UrsSurveyType,
  UrsSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, nowTimestamp, Timestamp } from "@cartographerio/types";

interface SurveyDetails {
  riverName?: string | null;
  reachName?: string | null;
  subreachName?: string | null;
  moduleNumber?: number | null;
  surveyType?: UrsSurveyType | null;
  scenarioName?: string | null;
  midpointLocation?: Point | null;
}

function isSurveyDetails(details: unknown): details is SurveyDetails {
  return (
    isObject(details) &&
    hasOptionalKey(details, "riverName", isNullable(isString)) &&
    hasOptionalKey(details, "reachName", isNullable(isString)) &&
    hasOptionalKey(details, "subreachName", isNullable(isString)) &&
    hasOptionalKey(details, "moduleNumber", isNullable(isNumber)) &&
    hasOptionalKey(
      details,
      "surveyType",
      isNullable(UrsSurveyTypeEnum.isValue)
    ) &&
    hasOptionalKey(details, "scenarioName", isNullable(isString)) &&
    hasOptionalKey(details, "midpointLocation", isNullable(isPoint))
  );
}

interface ChannelDimensions {
  locationOfCrossSection?: Point | null;
  morphRiverWidth?: number | null;
  leftBankHeight?: number | null;
  rightBankHeight?: number | null;
  bankfullWidth?: number | null;
  waterWidth?: number | null;
  waterDepth?: number | null;
  notes?: string | null;
}

function isChannelDimensions(
  dimensions: unknown
): dimensions is ChannelDimensions {
  return (
    isObject(dimensions) &&
    hasOptionalKey(dimensions, "locationOfCrossSection", isNullable(isPoint)) &&
    hasOptionalKey(dimensions, "morphRiverWidth", isNullable(isNumber)) &&
    hasOptionalKey(dimensions, "leftBankHeight", isNullable(isNumber)) &&
    hasOptionalKey(dimensions, "rightBankHeight", isNullable(isNumber)) &&
    hasOptionalKey(dimensions, "bankfullWidth", isNullable(isNumber)) &&
    hasOptionalKey(dimensions, "waterWidth", isNullable(isNumber)) &&
    hasOptionalKey(dimensions, "waterDepth", isNullable(isNumber)) &&
    hasOptionalKey(dimensions, "notes", isNullable(isString))
  );
}

export interface PartialMorphData {
  recorded?: Timestamp | null;
  surveyDetails: SurveyDetails;
  channelDimensions: ChannelDimensions;
}

export function isPartialData(data: unknown): data is PartialMorphData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasKey(data, "surveyDetails", isSurveyDetails) &&
    hasKey(data, "channelDimensions", isChannelDimensions)
  );
}

function createDescription(...args: Array<string | null>): string {
  return args.filter(arg => arg != null).join(", ");
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialMrsMorphData"
  )(data).map(data =>
    createDescription(
      data.surveyDetails.riverName ?? "Unknown river",
      data.surveyDetails.reachName ?? "Unknown reach",
      data.surveyDetails.subreachName ?? "Unknown subreach",
      data.surveyDetails.moduleNumber == null
        ? "Unknown module"
        : `${data.surveyDetails.moduleNumber}`,
      data.surveyDetails.midpointLocation
        ? safePointToNgr(data.surveyDetails.midpointLocation)
        : null,
      data.surveyDetails.surveyType === UrsSurveyTypeEnum.Scenario
        ? `Scenario (${data.surveyDetails.scenarioName ?? "Unnamed"})`
        : data.surveyDetails.surveyType == null
          ? null
          : UrsSurveyTypeEnum.labelOf(data.surveyDetails.surveyType)
    )
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialMrsMorphData"
  )(data).map(data => data.surveyDetails.midpointLocation ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialMrsMorphData"
  )(data).map(data => data.recorded || null);
}

export function copyData(data: unknown): Result<GuardError, PartialMorphData> {
  return Result.guard(
    isPartialData,
    "PartialMrsMorphData"
  )(data).map(data => ({
    ...data,
    recorded: nowTimestamp(),
    surveyDetails: {
      ...data.surveyDetails,
      midpointLocation: null,
      moduleNumber: null,
    },
    channelDimensions: {
      ...data.channelDimensions,
      locationOfCrossSection: null,
    },
  }));
}
