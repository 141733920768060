// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessShorelineModification =
  | "None"
  | "Trace"
  | "Light"
  | "Medium"
  | "Extensive";

const None: NeLakeNaturalnessShorelineModification = "None";
const Trace: NeLakeNaturalnessShorelineModification = "Trace";
const Light: NeLakeNaturalnessShorelineModification = "Light";
const Medium: NeLakeNaturalnessShorelineModification = "Medium";
const Extensive: NeLakeNaturalnessShorelineModification = "Extensive";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessShorelineModificationEnum = {
  ...createEnum("NeLakeNaturalnessShorelineModification", [
    [None, "None (natural)"],
    [Trace, "A few small structures"],
    [Light, "<33% of the bank modified"],
    [Medium, "33-66% modified"],
    [Extensive, ">66% modified"],
  ]),
  None,
  Trace,
  Light,
  Medium,
  Extensive,
};

export default NeLakeNaturalnessShorelineModificationEnum;
