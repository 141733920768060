import {
  invitationStatus,
  Invitation,
  PermissionCheck,
  QualificationRole,
  Role,
  WorkspaceId,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { check } from "../check";
import { always } from "../check/create";

export function create(invite: Invitation): PermissionCheck {
  return check.named(
    invite.workspaceId == null
      ? `Create an invitation in the global admin area`
      : `Create an invitation in workspace ${invite.workspaceId}`,
    updateInternal(
      invite.workspaceId,
      invite.roles,
      invite.qualificationRoles,
      () => check.never("Invitations must contain at least one role")
    )
  );
}

export function update(invite: Invitation): PermissionCheck {
  const status = invitationStatus(invite);

  switch (status) {
    case "Accepted":
      return check.never("Invitation has been accepted");
    case "Canceled":
      return check.never("Invitation has been canceled");
    case "Expired":
      return check.never("Invitation has expired");
    case "Pending":
      return check.named(
        invite.workspaceId == null
          ? `Update invitation ${invite.id} in the global admin area`
          : `Update invitation ${invite.id} in workspace ${invite.workspaceId}`,
        updateInternal(
          invite.workspaceId,
          invite.roles,
          invite.qualificationRoles,
          () => check.never("Invitations must contain at least one role")
        )
      );
    default:
      return checkExhausted(status);
  }
}

export function cancel(invite: Invitation): PermissionCheck {
  const status = invitationStatus(invite);

  return check.named(
    `Cancel invitation ${invite.id} (currently ${status})`,
    updateInternal(
      invite.workspaceId,
      invite.roles,
      invite.qualificationRoles,
      () => always
    )
  );
}

export function remove(invite: Invitation): PermissionCheck {
  const status = invitationStatus(invite);

  const canDelete = () =>
    check.named(
      invite.workspaceId == null
        ? `Update invitation ${invite.id} in the global admin area`
        : `Update invitation ${invite.id} in workspace ${invite.workspaceId}`,
      updateInternal(
        invite.workspaceId,
        invite.roles,
        invite.qualificationRoles,
        () => always
      )
    );

  switch (status) {
    case "Accepted":
    case "Canceled":
    case "Expired":
      return canDelete();
    case "Pending":
      return check.never(
        "Only accepted/canceled/expired invitations can be deleted"
      );
    default:
      return checkExhausted(status);
  }
}

function updateInternal(
  workspaceId: WorkspaceId | null | undefined,
  roles: Role[],
  qualificationRoles: QualificationRole[],
  orElse: () => PermissionCheck
) {
  return check.and(
    workspaceId == null
      ? check.globalAdmin
      : check.workspaceActive(workspaceId),
    check.canGrantRoles(roles, qualificationRoles, orElse)
  );
}
