import { internalError } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { ReactNode, createContext, useContext } from "react";
import { UseFormPermissionsResult } from "../hooks/useFormPermissions";

export const FormPermissionsContext =
  createContext<UseFormPermissionsResult | null>(null);

export function useFormPermissionsContext(): UseFormPermissionsResult {
  return (
    useContext(FormPermissionsContext) ??
    raise(internalError("Form permissions context hasn't been initialised"))
  );
}

export interface FormPermissionsContextProviderProps {
  permissions: UseFormPermissionsResult;
  children: ReactNode;
}
