// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsBedMaterialParticleSize =
  | "BE"
  | "BO"
  | "CO"
  | "GP"
  | "SA"
  | "SI"
  | "CL";

const Bedrock: MrsBedMaterialParticleSize = "BE";
const Boulder: MrsBedMaterialParticleSize = "BO";
const Cobble: MrsBedMaterialParticleSize = "CO";
const GravelPebble: MrsBedMaterialParticleSize = "GP";
const Sand: MrsBedMaterialParticleSize = "SA";
const Silt: MrsBedMaterialParticleSize = "SI";
const Clay: MrsBedMaterialParticleSize = "CL";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsBedMaterialParticleSizeEnum = {
  ...createEnum("MrsBedMaterialParticleSize", [
    [Bedrock, "Bedrock"],
    [Boulder, "Boulder"],
    [Cobble, "Cobble"],
    [GravelPebble, "Gravel-Pebble"],
    [Sand, "Sand"],
    [Silt, "Silt"],
    [Clay, "Clay"],
  ]),
  Bedrock,
  Boulder,
  Cobble,
  GravelPebble,
  Sand,
  Silt,
  Clay,
};

export default MrsBedMaterialParticleSizeEnum;
