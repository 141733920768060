// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterSediment =
  | "Clear"
  | "Coloured"
  | "Translucent"
  | "Opaque";

const Clear: MrsMudSpotterSediment = "Clear";
const Coloured: MrsMudSpotterSediment = "Coloured";
const Translucent: MrsMudSpotterSediment = "Translucent";
const Opaque: MrsMudSpotterSediment = "Opaque";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterSedimentEnum = {
  ...createEnum("MrsMudSpotterSediment", [
    [Clear, "Clear"],
    [Coloured, "Coloured"],
    [Translucent, "Translucent"],
    [Opaque, "Opaque"],
  ]),
  Clear,
  Coloured,
  Translucent,
  Opaque,
};

export default MrsMudSpotterSedimentEnum;
