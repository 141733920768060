// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiLegacyPollutionSource =
  | "none"
  | "industrialDischarge"
  | "combinedSewerOverflow"
  | "residentialDischarge"
  | "roadDrains"
  | "other";

const None: WrtWestcountryCsiLegacyPollutionSource = "none";
const IndustrialDischarge: WrtWestcountryCsiLegacyPollutionSource =
  "industrialDischarge";
const CombinedSewerOverflow: WrtWestcountryCsiLegacyPollutionSource =
  "combinedSewerOverflow";
const ResidentialDischarge: WrtWestcountryCsiLegacyPollutionSource =
  "residentialDischarge";
const RoadDrains: WrtWestcountryCsiLegacyPollutionSource = "roadDrains";
const Other: WrtWestcountryCsiLegacyPollutionSource = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiLegacyPollutionSourceEnum = {
  ...createEnum("WrtWestcountryCsiLegacyPollutionSource", [
    [None, "None"],
    [IndustrialDischarge, "Industrial discharge"],
    [CombinedSewerOverflow, "Combined sewer overflow"],
    [ResidentialDischarge, "Residential discharge"],
    [RoadDrains, "Road drains"],
    [Other, "Other (please specify)"],
  ]),
  None,
  IndustrialDischarge,
  CombinedSewerOverflow,
  ResidentialDischarge,
  RoadDrains,
  Other,
};

export default WrtWestcountryCsiLegacyPollutionSourceEnum;
