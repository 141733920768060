import { PermissionCheck, WorkspaceId } from "@cartographerio/types";
import { check } from "../check";

export const global: PermissionCheck = check.named(
  `Billing admin`,
  check.superuser
);

export function workspace(workspaceId: WorkspaceId): PermissionCheck {
  return check.named(
    `Billing for workspace ${workspaceId}`,
    check.workspaceOwner(workspaceId)
  );
}
