import { checkExhausted } from "@cartographerio/util";
import { Field } from "./type";

export function fieldIsRequired(field: Field): boolean {
  switch (field.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
    case "IntegerField":
    case "NumberField":
    case "UserRefField":
    case "TimestampField":
    case "PointField":
    case "LineStringField":
    case "PolygonField":
    case "FeatureField":
    case "NearestFeatureField":
    case "TeamField":
    case "Select":
      return field.required?.level === "error";

    case "Checkbox":
    case "AttachmentField":
    case "MultiSelect":
      return false;

    default:
      return checkExhausted(field);
  }
}
