import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isSearchResults,
  isSurveyModuleSummary,
  SearchResults,
  SurveyModuleId,
  SurveyModuleSummary,
} from "@cartographerio/types";
import { fetch } from "../../..";
import { contentAs, optionalContentAs } from "../../../response";

const basePath = "/survey/module/v1";

export function search(
  apiParams: ApiParams
): IO<SearchResults<SurveyModuleSummary>> {
  return fetch
    .get({ apiParams, url: { path: basePath } })
    .flatMap(
      contentAs(
        "SearchResults<SurveyModuleSummary>",
        isSearchResults(isSurveyModuleSummary)
      )
    );
}

export function readOrFail(
  apiParams: ApiParams,
  id: SurveyModuleId
): IO<SurveyModuleSummary> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${id}` } })
    .flatMap(contentAs("SurveyModuleSummary", isSurveyModuleSummary));
}

export function readOption(
  apiParams: ApiParams,
  id: SurveyModuleId
): IO<Option<SurveyModuleSummary>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${id}` } })
    .flatMap(optionalContentAs("SurveyModuleSummary", isSurveyModuleSummary));
}
