import {
  AnonymisedUser,
  ProjectId,
  ProjectRoleName,
  TeamId,
  TeamRoleName,
  UserId,
  WorkspaceId,
  WorkspaceRoleName,
  notFoundError,
  projectRole,
  roleGte,
  roleProjectId,
  roleTeamId,
  roleWorkspaceId,
  teamRole,
  workspaceRole,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { sortBy } from "lodash";
import { WorkspaceGraph, WorkspaceGraphProps } from "./graph";
import { memoize1, memoize2 } from "./memoize";

export interface UserWorkspaceGraphProps<U> extends WorkspaceGraphProps {
  users: U[];
}

export class UserWorkspaceGraph<
  U extends AnonymisedUser = AnonymisedUser,
> extends WorkspaceGraph {
  private readonly users: U[];

  constructor(props: UserWorkspaceGraphProps<U>) {
    super(props);
    this.users = sortBy(props.users, u => u.screenName);
  }

  allUsers = (): U[] => {
    return [...this.users];
  };

  optFindUserById = memoize1((id: UserId): U | null => {
    return this.users.find(u => u.id === id) ?? null;
  });

  findUserById = (id: UserId): U => {
    return this.optFindUserById(id) ?? raise<U>(notFoundError("user", id));
  };

  findUsersByWorkspaceId = memoize2(
    (id: WorkspaceId, name: WorkspaceRoleName = "Active"): U[] => {
      const role = workspaceRole(name, id);
      return this.users.filter(user => {
        const userRole = user.roles.find(r => roleWorkspaceId(r) === id);
        return userRole != null && roleGte(userRole, role);
      });
    }
  );

  findUsersByProjectId = memoize2(
    (id: ProjectId, name: ProjectRoleName = "Member"): U[] => {
      const role = projectRole(name, id);
      return this.users.filter(user => {
        const userRole = user.roles.find(r => roleProjectId(r) === id);
        return userRole != null && roleGte(userRole, role);
      });
    }
  );

  findUsersByTeamId = memoize2(
    (id: TeamId, name: TeamRoleName = "Member"): U[] => {
      const role = teamRole(name, id);
      return this.users.filter(user => {
        const userRole = user.roles.find(r => roleTeamId(r) === id);
        return userRole != null && roleGte(userRole, role);
      });
    }
  );

  stringify = (): string => {
    const ans: string[] = [];

    for (const ws of this.allWorkspaces()) {
      ans.push(`- workspace ${ws.id} / ${ws.alias}`);

      for (const p of this.findProjectsByWorkspaceId(ws.id)) {
        ans.push(`  - project ${p.id} / ${p.alias}`);

        for (const t of this.findTeamsByProjectId(p.id)) {
          ans.push(`    - team ${t.id} / ${t.alias}`);
        }
      }

      for (const u of this.findUsersByWorkspaceId(ws.id, "Disabled")) {
        ans.push(`  - user ${u.id} / ${u.screenName}`);
      }
    }

    return ans.join("\n");
  };
}
