// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsSedimentSize =
  | "AR"
  | "BE"
  | "BO"
  | "CO"
  | "GP"
  | "SA"
  | "SI"
  | "CL"
  | "OR"
  | "PE"
  | "EA"
  | "NV";

const Artificial: MrsSedimentSize = "AR";
const Bedrock: MrsSedimentSize = "BE";
const Boulder: MrsSedimentSize = "BO";
const Cobble: MrsSedimentSize = "CO";
const GravelPebble: MrsSedimentSize = "GP";
const Sand: MrsSedimentSize = "SA";
const Silt: MrsSedimentSize = "SI";
const Clay: MrsSedimentSize = "CL";
const Organic: MrsSedimentSize = "OR";
const Peat: MrsSedimentSize = "PE";
const Earth: MrsSedimentSize = "EA";
const NotVisible: MrsSedimentSize = "NV";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsSedimentSizeEnum = {
  ...createEnum("MrsSedimentSize", [
    [Artificial, "Artificial"],
    [Bedrock, "Bedrock"],
    [Boulder, "Boulder"],
    [Cobble, "Cobble"],
    [GravelPebble, "Gravel-Pebble"],
    [Sand, "Sand"],
    [Silt, "Silt"],
    [Clay, "Clay"],
    [Organic, "Organic (leaves, twigs etc. not fully decomposed)"],
    [Peat, "Peat"],
    [Earth, "Earth (i.e. mixed, mainly sand and finer)"],
    [NotVisible, "Not visible"],
  ]),
  Artificial,
  Bedrock,
  Boulder,
  Cobble,
  GravelPebble,
  Sand,
  Silt,
  Clay,
  Organic,
  Peat,
  Earth,
  NotVisible,
};

export default MrsSedimentSizeEnum;
