// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesNaturalMaterial = "N" | "D" | "S";

const NotVisible: CesNaturalMaterial = "N";
const Dominant: CesNaturalMaterial = "D";
const Subdominant: CesNaturalMaterial = "S";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesNaturalMaterialEnum = {
  ...createEnum("CesNaturalMaterial", [
    [NotVisible, "Not visible"],
    [Dominant, "Dominant natural material"],
    [Subdominant, "Subdominant natural material"],
  ]),
  NotVisible,
  Dominant,
  Subdominant,
};

export default CesNaturalMaterialEnum;
