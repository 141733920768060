import {
  attachmentField,
  autocomplete,
  columns2,
  featureField,
  featureFieldCartographerMapStyle,
  form,
  integerField,
  localAutocompleteSource,
  minValue,
  oneOf,
  page,
  repeat,
  required,
  section,
  select,
  textArea,
  timestampField,
} from "@cartographerio/topo-form";
import { numberAttr, stringAttr } from "@cartographerio/topo-map";
import {
  StSmartRiversProjectEnum,
  StSmartRiversRoleEnum,
} from "@cartographerio/inventory-enums";
import { unsafeMapLayerId, unsafeProjectAlias } from "@cartographerio/types";
import taxa from "./taxa.generated.json";

const taxaOrNull = [null, ...taxa];

const taxonAndCount = columns2(
  autocomplete({
    label: null,
    placeholder: "Taxon",
    path: ["taxon"],
    required: required("info"),
    source: localAutocompleteSource(taxa),
    customRules: [
      oneOf({
        message: "You must select one of the expected taxa",
        options: taxaOrNull,
      }),
    ],
  }),
  integerField({
    label: null,
    placeholder: "Count",
    path: ["count"],
    required: required("info"),
    bounds: minValue(0, "error"),
  })
);

export default form({
  title: "SmartRivers",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: columns2(
            select({
              label: "Collected By",
              path: ["data", "observations", "collectedBy"],
              options: StSmartRiversRoleEnum.entries,
              required: required("info"),
            }),
            select({
              label: "Identified By",
              path: ["data", "observations", "identifiedBy"],
              options: StSmartRiversRoleEnum.entries,
              required: required("info"),
            }),
            timestampField({
              label: "Date and Time",
              path: ["data", "observations", "recorded"],
              required: required("info"),
            }),
            select({
              label: "Collected As Part Of",
              path: ["data", "observations", "collectedAsPartOf"],
              options: StSmartRiversProjectEnum.entries,
              required: required("info"),
            }),
            featureField({
              label: "Site",
              path: ["data", "site"],
              required: required("info"),
              fullWidth: true,
              mapOptions: {
                mapStyle: featureFieldCartographerMapStyle({
                  project: unsafeProjectAlias("smartriverssites"),
                  layer: unsafeMapLayerId("StSmartRiversSite"),
                  geometryType: "Point",
                  primaryKey: "surveyId",
                }),
                attributes: [
                  stringAttr({
                    attributeId: "catchment",
                    label: "Catchment",
                    buckets: "auto",
                  }),
                  stringAttr({
                    attributeId: "river",
                    label: "River",
                    buckets: "auto",
                  }),
                  stringAttr({
                    attributeId: "site",
                    label: "Site",
                    buckets: "auto",
                  }),
                  numberAttr({
                    attributeId: "trpiRiverType",
                    label: "TRPI River Type",
                    buckets: "auto",
                  }),
                ],
              },
            })
          ),
        }),
        section({
          title: "Species",
          path: [],
          blocks: [
            repeat({
              path: ["data", "observations", "counts"],
              appearance: "compact",
              defaultLength: 5,
              addMultiple: 5,
              confirmOnDelete: false,
              block: taxonAndCount,
            }),
          ],
        }),
        section({
          title: "Other Species",
          path: [],
          blocks: [
            repeat({
              path: ["data", "observations", "otherCounts"],
              appearance: "compact",
              defaultLength: 0,
              addMultiple: 2,
              confirmOnDelete: false,
              block: taxonAndCount,
            }),
          ],
        }),
        section({
          title: "Photographs",
          path: [],
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "observations", "photographs"],
              maxFiles: 2,
            }),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "observations", "notes"],
              rows: 8,
            }),
          ],
        }),
      ],
    }),
  ],
});
