import {
  attachmentAttr,
  AttributeGroup,
  attributeGroup,
  booleanAttr,
  enumAttr,
  lookupBucket,
  MapLayer,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  thresholdAttr,
  thresholdBucket,
  timestampAttr,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  ExtendedRiverflySpeciesEnum,
  RiverflyBreachTypeEnum,
  RiverflySampleTypeEnum,
  RiverflySecondBreachActionEnum,
  RiverflySpeciesEnum,
  UrbanRiverflySpeciesEnum,
} from "@cartographerio/inventory-enums";
import {
  ProjectRef,
  SurveyModuleId,
  unsafeSurveyModuleId,
} from "@cartographerio/types";
import { Enum, NonEmptyArray } from "@cartographerio/util";
import { cartographerSourceWithDefaults } from "../../core";

const white = marker.fromColor("#f7f7f7");

// [0,360] [0,1] -> color
function speciesColor(hue: number, lightness: number) {
  return marker.gradient(hue, 50, lightness);
}

const siteGroup = (module: SurveyModuleId) =>
  attributeGroup({
    label: "Location",
    attributes: [
      stringAttr({
        attributeId: "catchment",
        label: "Catchment",
        buckets: "auto",
      }),
      stringAttr({ attributeId: "river", label: "River", buckets: "auto" }),
      stringAttr({ attributeId: "site", label: "Site", buckets: "auto" }),
      timestampAttr({
        attributeId: "timestamp",
        label: "Date/Time",
        buckets: "auto",
      }),
      surveyAttr("surveyId"),
      teamAttr("teamId", "Team", { module }),
      stringAttr({
        attributeId: "moduleId",
        label: "Survey Type",
        buckets: [
          lookupBucket("riverfly", marker.hue(210), "Riverfly"),
          lookupBucket("urbanRiverfly", marker.hue(30), "Urban Riverfly"),
          lookupBucket(
            "extendedRiverfly",
            marker.hue(300),
            "Extended Riverfly"
          ),
        ],
      }),
      attachmentAttr("fixedPointPhotographs", "Fixed Point Photographs"),
    ],
  });

const triggerGroup = attributeGroup({
  label: "ARMI Pollution Warning",
  attributes: [
    enumAttr({
      attributeId: "sampleType",
      label: "Sample Type",
      enum: RiverflySampleTypeEnum,
      markers: {
        First: marker.fromColor("lime"),
        Second: marker.fromColor("orange"),
        Combined: marker.fromColor("pink"),
      },
    }),
    booleanAttr({
      attributeId: "sampleTaken",
      label: "Sample Taken?",
      buckets: [
        lookupBucket(true, marker.fromColor("limegreen")),
        lookupBucket(false, marker.fromColor("orange")),
        lookupBucket(null, marker.empty),
      ],
    }),
    thresholdAttr({
      attributeId: "trigger",
      label: "Trigger Level",
      decimalPlaces: 0,
      buckets: [
        thresholdBucket(0, speciesColor(210, 5 / 5)),
        thresholdBucket(4, speciesColor(210, 4 / 5)),
        thresholdBucket(8, speciesColor(210, 3 / 5)),
        thresholdBucket(12, speciesColor(210, 2 / 5)),
        thresholdBucket(16, speciesColor(210, 1 / 5)),
      ],
    }),
    booleanAttr({
      attributeId: "breach",
      label: "Trigger Level Breach",
      buckets: [
        lookupBucket(true, marker.fromColor("red"), "Yes"),
        lookupBucket(false, marker.fromColor("green"), "No"),
      ],
    }),
    stringAttr({
      attributeId: "breachType",
      label: "Trigger Level Breach Type",
      buckets: [
        lookupBucket(null, marker.fromColor("white"), "No Breach"),
        lookupBucket(
          RiverflyBreachTypeEnum.Unconfirmed,
          marker.fromColor("yellow"),
          "Unconfirmed"
        ),
        lookupBucket(
          RiverflyBreachTypeEnum.Confirmed,
          marker.fromColor("red"),
          "Confirmed"
        ),
      ],
      format: value =>
        RiverflyBreachTypeEnum.isValue(value)
          ? RiverflyBreachTypeEnum.labelOf(value)
          : "-",
    }),
    attachmentAttr("breachPhotographs", "Breach Photographs"),
    stringAttr({
      attributeId: "breachAction",
      label: "Reporting Action Taken",
      buckets: [
        lookupBucket(
          RiverflySecondBreachActionEnum.Coordinator,
          marker.fromColor("#a0a"),
          "Reported to Coordinator"
        ),
        lookupBucket(
          RiverflySecondBreachActionEnum.Hotline,
          marker.fromColor("#f0f"),
          "Reported to Hotline"
        ),
        lookupBucket(
          RiverflySecondBreachActionEnum.None,
          marker.fromColor("#ccc"),
          "Unreported"
        ),
        lookupBucket(null, marker.empty, "N/A"),
      ],
      format: value =>
        RiverflySecondBreachActionEnum.isValue(value)
          ? RiverflySecondBreachActionEnum.labelOf(value)
          : "-",
    }),
  ],
});

function sampleScoresGroup<A>(props: {
  label: string;
  speciesEnum: Enum<A>;
  totalScoreThresholds?: [number, number, number, number];
}): AttributeGroup {
  const { label, speciesEnum, totalScoreThresholds = [4, 8, 12, 6] } = props;

  const numSpecies = speciesEnum.values.length;

  const totalAttr = thresholdAttr({
    attributeId: "total",
    label: "Total Score",
    decimalPlaces: 0,
    buckets: [
      thresholdBucket(null, speciesColor(30, 5 / 5)),
      thresholdBucket(totalScoreThresholds[0], speciesColor(30, 4 / 5)),
      thresholdBucket(totalScoreThresholds[1], speciesColor(30, 3 / 5)),
      thresholdBucket(totalScoreThresholds[2], speciesColor(30, 2 / 5)),
      thresholdBucket(totalScoreThresholds[3], speciesColor(30, 1 / 5)),
    ],
  });

  const scoreAttr = (value: A, label: string, index: number) =>
    numberAttr({
      attributeId: `score_${value}`,
      label,
      decimalPlaces: 0,
      buckets: [
        rangeBucket(null, 1, white, "0"),
        rangeBucket(1, 2, speciesColor((360 * index) / numSpecies, 3 / 3), "1"),
        rangeBucket(2, 3, speciesColor((360 * index) / numSpecies, 2 / 3), "2"),
        rangeBucket(3, 4, speciesColor((360 * index) / numSpecies, 1 / 3), "3"),
        rangeBucket(
          4,
          null,
          speciesColor((360 * index) / numSpecies, 0 / 3),
          "4"
        ),
      ],
      showDistribution: true,
      showTimeline: true,
    });

  const scoreAttrs = speciesEnum.entries.map(({ value, label }, index) =>
    scoreAttr(value, label, index)
  );

  return attributeGroup({
    label,
    attributes: [totalAttr, ...scoreAttrs],
  });
}

function sampleCountsGroup<A>(props: {
  label: string;
  speciesEnum: Enum<A>;
}): AttributeGroup {
  const { label, speciesEnum } = props;

  const numSpecies = speciesEnum.values.length;

  const speciesAttr = (value: A, label: string, index: number) =>
    numberAttr({
      attributeId: `count_${value}`,
      label,
      decimalPlaces: 0,
      buckets: [
        rangeBucket(0, 1, white, "0"),
        rangeBucket(
          1,
          10,
          speciesColor((360 * index) / numSpecies, 3 / 3),
          "1-9"
        ),
        rangeBucket(
          10,
          100,
          speciesColor((360 * index) / numSpecies, 2 / 3),
          "10-99"
        ),
        rangeBucket(
          100,
          1000,
          speciesColor((360 * index) / numSpecies, 1 / 3),
          "100-999"
        ),
        rangeBucket(
          1000,
          null,
          speciesColor((360 * index) / numSpecies, 0 / 3),
          "1000+"
        ),
      ],
      showDistribution: true,
      showTimeline: true,
    });

  return attributeGroup({
    label,
    attributes: speciesEnum.entries.map(({ value, label }, index) =>
      speciesAttr(value, label, index)
    ),
  });
}

export function riverflyLayer(
  projects: NonEmptyArray<ProjectRef>,
  title: string
): MapLayer {
  return pointLayer({
    layerId: unsafeMapLayerId("riverfly"),
    title,
    source: cartographerSourceWithDefaults({
      layerId: unsafeMapLayerId("riverfly"),
      projects,
      attribution: [],
    }),
    defaultAttribute: "total",
    defaultZOrder: "timestamp",
    attributes: [
      siteGroup(unsafeSurveyModuleId("riverfly")),
      triggerGroup,
      sampleScoresGroup({
        label: "ARMI Scores",
        speciesEnum: RiverflySpeciesEnum,
      }),
      sampleCountsGroup({
        label: "Counts",
        speciesEnum: RiverflySpeciesEnum,
      }),
    ],
  });
}

export function urbanRiverflyLayer(
  projects: NonEmptyArray<ProjectRef>,
  title: string
): MapLayer {
  return pointLayer({
    layerId: unsafeMapLayerId("urbanRiverfly"),
    title,
    source: cartographerSourceWithDefaults({
      layerId: unsafeMapLayerId("urbanRiverfly"),
      projects,
      attribution: [],
    }),
    defaultAttribute: "total",
    defaultZOrder: "timestamp",
    attributes: [
      siteGroup(unsafeSurveyModuleId("urbanRiverfly")),
      sampleScoresGroup({
        label: "Urban Riverfly Scores",
        speciesEnum: UrbanRiverflySpeciesEnum,
      }),
      sampleCountsGroup({
        label: "Counts",
        speciesEnum: UrbanRiverflySpeciesEnum,
      }),
    ],
  });
}

export function extendedRiverflyWaterQualityLayer(
  project: ProjectRef,
  title: string
): MapLayer {
  return pointLayer({
    layerId: unsafeMapLayerId("extendedRiverflyWaterQuality"),
    title,
    source: cartographerSourceWithDefaults({
      layerId: unsafeMapLayerId("extendedRiverflyWaterQuality"),
      projects: [project],
      attribution: [],
    }),
    defaultAttribute: "total",
    defaultZOrder: "timestamp",
    attributes: [
      siteGroup(unsafeSurveyModuleId("extendedRiverfly")),
      sampleScoresGroup({
        label: "Water Quality Scores",
        speciesEnum: ExtendedRiverflySpeciesEnum,
        totalScoreThresholds: [10, 20, 30, 40],
      }),
      sampleCountsGroup({
        label: "Counts",
        speciesEnum: ExtendedRiverflySpeciesEnum,
      }),
    ],
  });
}

export function extendedRiverflySiltAndFlowLayer(
  project: ProjectRef,
  title: string
): MapLayer {
  return pointLayer({
    layerId: unsafeMapLayerId("extendedRiverflySiltAndFlow"),
    title,
    source: cartographerSourceWithDefaults({
      layerId: unsafeMapLayerId("extendedRiverflySiltAndFlow"),
      projects: [project],
      attribution: [],
    }),
    defaultAttribute: "total",
    defaultZOrder: "timestamp",
    attributes: [
      siteGroup(unsafeSurveyModuleId("extendedRiverfly")),
      sampleScoresGroup({
        label: "Silt and Flow Scores",
        speciesEnum: ExtendedRiverflySpeciesEnum,
        totalScoreThresholds: [10, 20, 30, 40],
      }),
      sampleCountsGroup({
        label: "Counts",
        speciesEnum: ExtendedRiverflySpeciesEnum,
      }),
    ],
  });
}
