import {
  Role,
  globalAdminRole,
  globalRole,
  parseRole,
  projectCoordinatorRole,
  superuserRole,
  teamCoordinatorRole,
  workspaceAdminRole,
  workspaceOwnerRole,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { hasRole } from "./runHasRole";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";

export function canGrantRole(
  roles: Role[],
  graph: WorkspaceGraph,
  role: Role
): boolean {
  const parts = parseRole(role);

  switch (parts[0]) {
    case "G":
      switch (parts[1]) {
        case "Superuser":
          return hasRole(roles, globalRole("Superuser"));
        case "Admin":
          return hasRole(roles, globalRole("Admin"));
        case "MapViewer":
          return hasRole(roles, globalRole("Admin"));
        default:
          return checkExhausted(parts[1]);
      }

    case "W": {
      const ws = parts[2];

      switch (parts[1]) {
        case "Active":
          return (
            hasRole(roles, globalAdminRole) ||
            hasRole(roles, workspaceAdminRole(ws)) ||
            graph
              .findProjectsByWorkspaceId(ws)
              .some(p => hasRole(roles, projectCoordinatorRole(p.id))) ||
            graph
              .findTeamsByWorkspaceId(ws)
              .some(t => hasRole(roles, teamCoordinatorRole(t.id)))
          );
        case "Disabled":
        case "Admin":
          return (
            hasRole(roles, globalAdminRole) ||
            hasRole(roles, workspaceAdminRole(ws))
          );
        case "Owner":
          return (
            hasRole(roles, superuserRole) ||
            hasRole(roles, workspaceOwnerRole(ws))
          );
        default:
          return checkExhausted(parts[1]);
      }
    }

    case "P": {
      const p = parts[2];
      const ws = graph.optFindWorkspaceByProjectId(p);

      // Project roles can only be granted by project coordinators and workspace admins:
      return (
        hasRole(roles, globalAdminRole) ||
        hasRole(roles, projectCoordinatorRole(p)) ||
        (ws != null && hasRole(roles, workspaceAdminRole(ws.id)))
      );
    }

    case "T": {
      const t = parts[2];

      // Team roles can be granted by related project coordinators and workspace admins:
      const relatedAdminsCanGrantTeamRole = () => {
        const ps = graph.findProjectsByTeamId(t);
        const ws = graph.optFindWorkspaceByTeamId(t);
        return (
          hasRole(roles, globalAdminRole) ||
          ps.every(p => hasRole(roles, projectCoordinatorRole(p.id))) ||
          (ws != null && hasRole(roles, workspaceAdminRole(ws.id)))
        );
      };

      switch (parts[1]) {
        case "Member":
        case "Surveyor":
        case "Approver":
          // Team coordinators can grant most team roles...
          return (
            hasRole(roles, teamCoordinatorRole(t)) ||
            relatedAdminsCanGrantTeamRole()
          );

        case "Coordinator":
          // ...except the team coordinator role itself:
          return relatedAdminsCanGrantTeamRole();

        default:
          return checkExhausted(parts[1]);
      }
    }

    default:
      return checkExhausted(parts[0]);
  }
}
