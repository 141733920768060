import {
  dataDescription,
  dataGeometry,
  dataTimestamp,
  copyData,
} from "./parts";
import { mapLink, surveyModuleFromJson } from "../SurveyModule";
import formSchema from "./form";
import moduleJson from "./module.generated.json";
import { unsafeMapId } from "@cartographerio/types";

export default surveyModuleFromJson({
  moduleJson,
  formSchema,
  dataDescription,
  dataGeometry,
  dataTimestamp,
  copyData,
  mapLinks: [
    mapLink({ label: "RiverWood", mapId: unsafeMapId("MrsRiverWood") }),
  ],
}).unsafeGet();
