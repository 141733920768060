// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopAquaticPlants =
  | "Submerged"
  | "Emergent"
  | "Floating"
  | "None";

const Submerged: UclPitStopAquaticPlants = "Submerged";
const Emergent: UclPitStopAquaticPlants = "Emergent";
const Floating: UclPitStopAquaticPlants = "Floating";
const None: UclPitStopAquaticPlants = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopAquaticPlantsEnum = {
  ...createEnum("UclPitStopAquaticPlants", [
    [Submerged, "Plants submerged below the surface of the water"],
    [Emergent, "Plants emerging from the water"],
    [Floating, "Plants with floating leaves"],
    [None, "None"],
  ]),
  Submerged,
  Emergent,
  Floating,
  None,
};

export default UclPitStopAquaticPlantsEnum;
