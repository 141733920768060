export type Labeled<A, B> = B & { type: A };

export function labeled<A, B>(type: A, doc: B): Labeled<A, B> {
  return { ...doc, type };
}

export function unlabeled<A, B>(
  labeled: Labeled<A, B>
): Omit<Labeled<A, B>, "type"> {
  const { type: _, ...rest } = labeled;
  return rest;
}
