import { GeometryAtomType, isGeometryAtomType } from "@cartographerio/geometry";
import {
  hasKey,
  hasKeyOfAnyType,
  isArrayOf,
  isNullable,
  isRecordOf,
  isString,
} from "@cartographerio/guard";
import { isObject } from "lodash";
import {
  ArraySchema,
  BooleanSchema,
  DictSchema,
  GeometrySchema,
  IntegerSchema,
  NullableSchema,
  NumberSchema,
  ProductSchema,
  Schema,
  StringSchema,
  SumSchema,
  TimestampSchema,
  TupleSchema,
  UnknownSchema,
  UuidSchema,
} from "./type";

export type SchemaPredicate = (schema: Schema) => boolean;

export function isAnySchema(_schema: Schema): boolean {
  return true;
}

export function isBooleanSchema(schema: Schema): schema is BooleanSchema {
  return schema.type === "Boolean";
}

export function isIntegerSchema(schema: Schema): schema is IntegerSchema {
  return schema.type === "Integer";
}

export function isNumberSchema(schema: Schema): schema is NumberSchema {
  return schema.type === "Number";
}

export function isNumericSchema(
  schema: Schema
): schema is IntegerSchema | NumberSchema {
  return isIntegerSchema(schema) || isNumberSchema(schema);
}

export function isStringSchema(schema: Schema): schema is StringSchema {
  return schema.type === "String";
}

export function isUuidSchema(schema: Schema): schema is UuidSchema {
  return schema.type === "Uuid";
}

export function isTimestampSchema(schema: Schema): schema is TimestampSchema {
  return schema.type === "Timestamp";
}

export function isGeometrySchema(geometryType?: GeometryAtomType) {
  return (schema: Schema): schema is GeometrySchema => {
    return (
      schema.type === "Geometry" &&
      (geometryType == null || schema.geometryType === geometryType)
    );
  };
}

export function isUnknownSchema(schema: Schema): schema is UnknownSchema {
  return schema.type === "Unknown";
}

export function isNullableSchema(pred: SchemaPredicate) {
  return (schema: Schema): schema is NullableSchema =>
    schema.type === "Nullable" && pred(schema.param);
}

export function isMaybeNullableSchema(pred: SchemaPredicate) {
  return (schema: Schema): boolean =>
    pred(schema) || isNullableSchema(pred)(schema);
}

export function isArraySchema(pred: SchemaPredicate) {
  return (schema: Schema): schema is ArraySchema =>
    schema.type === "Array" && pred(schema.param);
}

export function isTupleSchema(schema: Schema): schema is TupleSchema {
  return schema.type === "Tuple";
}

export function isTupleSchemaOf(...preds: SchemaPredicate[]) {
  return (schema: Schema): schema is TupleSchema =>
    schema.type === "Tuple" &&
    preds.length === schema.items.length &&
    preds.every((pred, index) => pred(schema.items[index]));
}

export function isDictSchemaOf(
  isKey: SchemaPredicate = isString,
  isValue: SchemaPredicate = isAnySchema
) {
  return (schema: Schema): schema is DictSchema =>
    schema.type === "Dict" && isKey(schema.key) && isValue(schema.value);
}

export function isProductSchema(schema: Schema): schema is ProductSchema {
  return schema.type === "Product";
}

export function productHasField(
  schema: ProductSchema,
  name: string,
  guard: SchemaPredicate
): boolean {
  return hasKeyOfAnyType(schema.fields, name) && guard(schema.fields[name]);
}

export function isSumSchema(schema: Schema): schema is SumSchema {
  return schema.type === "Sum";
}

export function sumHasProduct(
  schema: SumSchema,
  name: string,
  guard: (s: ProductSchema) => boolean
): boolean {
  return hasKeyOfAnyType(schema.products, name) && guard(schema.products[name]);
}

export function isLabeledSchema(
  label: string,
  guard: (s: ProductSchema) => boolean
) {
  return (schema: Schema): schema is SumSchema =>
    isSumSchema(schema) && sumHasProduct(schema, label, guard);
}

export function isSchema(schema: unknown): schema is Schema {
  if (isObject(schema) && hasKeyOfAnyType(schema, "type")) {
    switch (schema.type) {
      case "Boolean":
        return true;
      case "Integer":
        return true;
      case "Number":
        return true;
      case "String":
        return true;
      case "Uuid":
        return true;
      case "Timestamp":
        return true;
      case "Point":
        return true;
      case "Geometry": {
        return "geometryType" in schema
          ? hasKey(schema, "geometryType", isNullable(isGeometryAtomType))
          : true;
      }
      case "Enum":
        return hasKey(schema, "values", isArrayOf(isString));
      case "Nullable":
        return hasKey(schema, "param", isSchema);
      case "Array":
        return hasKey(schema, "param", isSchema);
      case "Tuple":
        return hasKey(schema, "items", isArrayOf(isSchema));
      case "Dict":
        return (
          hasKey(schema, "key", isSchema) && hasKey(schema, "value", isSchema)
        );
      case "Product":
        return hasKey(schema, "fields", isRecordOf(isString, isSchema));
      case "Sum":
        return hasKey(
          schema,
          "products",
          isRecordOf(isString, isProductSchema)
        );
      default:
        return false;
    }
  } else {
    return false;
  }
}
