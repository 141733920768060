import { Result } from "@cartographerio/fp";
import { GuardError } from "@cartographerio/guard";
import {
  PartialData,
  RiverSiteInfo,
  dataDescription,
  dataGeometry,
  dataTimestamp,
  isPartialData,
  isRiverSiteInfo,
} from "../NeRiverNaturalness/parts";

export { dataDescription, dataGeometry, dataTimestamp };

const g = Result.guard(isPartialData(isRiverSiteInfo), "PartialData");

export function copyData(
  data: unknown
): Result<GuardError, PartialData<RiverSiteInfo>> {
  return g(data).map(data => ({
    ...data,
    // recorded: null,
    // site: {},
    // context: null,
    // photographs: randomAttachmentFolder(),
  }));
}
