// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UclPitStopBufferComposition =
  | "LongGrassesOrWildflowers"
  | "Trees"
  | "BrambleOrScrub"
  | "ShortGrass";

const LongGrassesOrWildflowers: UclPitStopBufferComposition =
  "LongGrassesOrWildflowers";
const Trees: UclPitStopBufferComposition = "Trees";
const BrambleOrScrub: UclPitStopBufferComposition = "BrambleOrScrub";
const ShortGrass: UclPitStopBufferComposition = "ShortGrass";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UclPitStopBufferCompositionEnum = {
  ...createEnum("UclPitStopBufferComposition", [
    [LongGrassesOrWildflowers, "Long grasses / wildflowers"],
    [Trees, "Trees"],
    [BrambleOrScrub, "Bramble/scrub"],
    [ShortGrass, "Short grass"],
  ]),
  LongGrassesOrWildflowers,
  Trees,
  BrambleOrScrub,
  ShortGrass,
};

export default UclPitStopBufferCompositionEnum;
