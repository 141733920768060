// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type StdInvasiveSpeciesAmount =
  | "Low"
  | "Medium"
  | "High"
  | "VeryHigh"
  | "Absent";

const Low: StdInvasiveSpeciesAmount = "Low";
const Medium: StdInvasiveSpeciesAmount = "Medium";
const High: StdInvasiveSpeciesAmount = "High";
const VeryHigh: StdInvasiveSpeciesAmount = "VeryHigh";
const Absent: StdInvasiveSpeciesAmount = "Absent";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StdInvasiveSpeciesAmountEnum = {
  ...createEnum("StdInvasiveSpeciesAmount", [
    [Low, "Low (< 1m2)"],
    [Medium, "Medium (1m2 - 5m2)"],
    [High, "High (5m2 - 50m2)"],
    [VeryHigh, "Very High (> 50m2)"],
    [Absent, "Absent"],
  ]),
  Low,
  Medium,
  High,
  VeryHigh,
  Absent,
};

export default StdInvasiveSpeciesAmountEnum;
