// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsBankProtection =
  | "NO"
  | "WO"
  | "RE"
  | "BC"
  | "WS"
  | "WP"
  | "BW"
  | "GA"
  | "RR"
  | "SP"
  | "BR"
  | "CB"
  | "CC"
  | "OT";

const None: UrsBankProtection = "NO";
const WashedOut: UrsBankProtection = "WO";
const Reeds: UrsBankProtection = "RE";
const BiotexOrCoir: UrsBankProtection = "BC";
const WillowSpiling: UrsBankProtection = "WS";
const WoodPiling: UrsBankProtection = "WP";
const BuildersWaste: UrsBankProtection = "BW";
const Gabions: UrsBankProtection = "GA";
const RipRap: UrsBankProtection = "RR";
const SheetPiling: UrsBankProtection = "SP";
const BrickOrLaidStone: UrsBankProtection = "BR";
const ConcreteAndBrick: UrsBankProtection = "CB";
const Concrete: UrsBankProtection = "CC";
const Other: UrsBankProtection = "OT";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsBankProtectionEnum = {
  ...createEnum("UrsBankProtection", [
    [None, "None"],
    [WashedOut, "Washed out"],
    [Reeds, "Reeds (planted)"],
    [BiotexOrCoir, "Biotex / coir"],
    [WillowSpiling, "Willow spiling / faggots"],
    [WoodPiling, "Wood piling"],
    [BuildersWaste, "Builder's waste"],
    [Gabions, "Gabions"],
    [RipRap, "Rip rap"],
    [SheetPiling, "Sheet piling"],
    [BrickOrLaidStone, "Brick / laid stone"],
    [ConcreteAndBrick, "Concrete and brick"],
    [Concrete, "Concrete"],
    [Other, "Other"],
  ]),
  None,
  WashedOut,
  Reeds,
  BiotexOrCoir,
  WillowSpiling,
  WoodPiling,
  BuildersWaste,
  Gabions,
  RipRap,
  SheetPiling,
  BrickOrLaidStone,
  ConcreteAndBrick,
  Concrete,
  Other,
};

export default UrsBankProtectionEnum;
