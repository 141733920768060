import { BBox, Geometry } from "./type";
import { Position } from "../position";

type Converter = (coord: Position) => Position;

export function convertBBox(
  bbox: BBox | undefined,
  convert: Converter
): BBox | undefined {
  if (bbox == null) {
    return undefined;
  } else {
    const [ax0, ay0, ax1, ay1] = bbox;
    const [bx0, by0] = convert([ax0, ay0]);
    const [bx1, by1] = convert([ax1, ay1]);
    return [bx0, by0, bx1, by1];
  }
}

export function convertGeometry(geom: Geometry, convert: Converter): Geometry {
  switch (geom.type) {
    case "Point":
      return {
        type: "Point",
        bbox: convertBBox(geom.bbox, convert),
        coordinates: convert(geom.coordinates),
      };
    case "MultiPoint":
      return {
        type: "MultiPoint",
        bbox: convertBBox(geom.bbox, convert),
        coordinates: geom.coordinates.map(convert),
      };
    case "LineString":
      return {
        type: "LineString",
        bbox: convertBBox(geom.bbox, convert),
        coordinates: geom.coordinates.map(convert),
      };
    case "MultiLineString":
      return {
        type: "MultiLineString",
        bbox: convertBBox(geom.bbox, convert),
        coordinates: geom.coordinates.map(line => line.map(convert)),
      };
    case "Polygon":
      return {
        type: "Polygon",
        bbox: convertBBox(geom.bbox, convert),
        coordinates: geom.coordinates.map(ring => ring.map(convert)),
      };
    case "MultiPolygon":
      return {
        type: "MultiPolygon",
        bbox: convertBBox(geom.bbox, convert),
        coordinates: geom.coordinates.map(poly =>
          poly.map(ring => ring.map(convert))
        ),
      };
    case "GeometryCollection":
      return {
        type: "GeometryCollection",
        bbox: convertBBox(geom.bbox, convert),
        geometries: geom.geometries.map(geom => convertGeometry(geom, convert)),
      };
  }
}
