import { Option, Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiConfig,
  ApiParams,
  InvitationCode,
  InvitationCodeCreate,
  InvitationCodeRef,
  InvitationCodeSummary,
  InvitationCodeUpdate,
  Message,
  SearchResults,
  checkedToResult,
  isChecked,
  isInvitationCode,
  isInvitationCodeSummary,
  isSearchResults
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import {
  contentAs,
  notFoundToOption,
  optionalContentAs,
} from "../../../response";
import { UrlParts } from "../../../url";
import { PartialParams } from "../../params";
import { InvitationCodeSearchOptionsV3 } from "./common";

const basePath = "/invitation/code/v3";

export function search(
  apiParams: ApiParams,
  options: PartialParams<InvitationCodeSearchOptionsV3> = {}
): IO<SearchResults<InvitationCode>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(
      contentAs(
        "SearchResults<InvitationCode>",
        isSearchResults(isInvitationCode)
      )
    );
}

export function searchUrl(
  options: PartialParams<InvitationCodeSearchOptionsV3> = {}
): UrlParts {
  return { path: basePath, query: { ...options } };
}

export function readOrFail(
  apiParams: ApiParams,
  ref: InvitationCodeRef
): IO<InvitationCode> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(contentAs("InvitationCode", isInvitationCode));
}

export function readOption(
  apiParams: ApiParams,
  ref: InvitationCodeRef
): IO<Option<InvitationCode>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}` } })
    .flatMap(optionalContentAs("InvitationCode", isInvitationCode));
}

export function readSummary(
  apiConfig: ApiConfig,
  ref: InvitationCodeRef
): IO<InvitationCodeSummary> {
  return fetch
    .get({
      apiParams: { apiConfig },
      url: { path: `${basePath}/${ref}/summary` },
    })
    .flatMap(contentAs("InvitationCodeSummary", isInvitationCodeSummary));
}

export function create(
  apiParams: ApiParams,
  code: InvitationCodeCreate
): IO<Result<Message[], InvitationCode>> {
  return fetch
    .post({
      apiParams,
      url: { path: basePath },
      body: sanitiseInvitationCode(code),
    })
    .flatMap(contentAs("Checked<InvitationCode>", isChecked(isInvitationCode)))
    .map(checkedToResult);
}

export function save(
  apiParams: ApiParams,
  ref: InvitationCodeRef,
  code: InvitationCodeCreate | InvitationCodeUpdate
): IO<Result<Message[], InvitationCode>> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${ref}` },
      body: sanitiseInvitationCode(code),
    })
    .flatMap(contentAs("Checked<InvitationCode>", isChecked(isInvitationCode)))
    .map(checkedToResult);
}

export function cancel(apiParams: ApiParams, ref: InvitationCodeRef): IO<void> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${ref}/cancel` } })
    .map(notFoundToOption)
    .void();
}

export function remove(apiParams: ApiParams, ref: InvitationCodeRef): IO<void> {
  return fetch
    .remove({ apiParams, url: { path: `${basePath}/${ref}` } })
    .void();
}

function sanitiseInvitationCode<
  A extends InvitationCode | InvitationCodeCreate | InvitationCodeUpdate,
>(code: A): A {
  return code.requireApproval ? code : { ...code, approvalEmails: [] };
}
