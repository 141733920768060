import { Option } from "@cartographerio/fp";
import { isArray, Optional } from "@cartographerio/guard";
import {
  PermissionCheck as Check,
  ProjectId,
  ProjectPermissionModel,
  ProjectRoleName,
  Project,
  QualificationRole,
  Role,
  TeamId,
  TeamRoleName,
  UserId,
  WorkspaceId,
  globalAdminRole,
  projectRole,
  superuserRole,
  teamRole,
  workspaceActiveRole,
  workspaceAdminRole,
  workspaceOwnerRole,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";

type ArrayOrNullable<A> = A | A[] | null | undefined;

export const always: Check = { type: "Always" };

export function never(reason?: string): Check {
  return {
    type: "Never",
    reason,
  };
}

export function and(...args: Check[]): Check {
  return { type: "And", args };
}

export function or(...args: Check[]): Check {
  return { type: "Or", args };
}

export function cond<A>(
  value: A | undefined | null,
  func: (value: A) => Check,
  orElse: Check
): Check {
  return value != null ? func(value) : orElse;
}

export function hasRole(role: Role): Check {
  return {
    type: "HasRole",
    role,
  };
}

export function hasQualification(role: QualificationRole): Check {
  return {
    type: "HasQualification",
    role,
  };
}

export function hasRoles(
  roles: Role[],
  qualificationRoles: QualificationRole[],
  fallback: () => Check
): Check {
  return roles.length + qualificationRoles.length > 0
    ? and(...roles.map(hasRole), ...qualificationRoles.map(hasQualification))
    : fallback();
}

export function canGrantRole(role: Role): Check {
  return {
    type: "CanGrantRole",
    role,
  };
}

export function canGrantQualification(role: QualificationRole): Check {
  return {
    type: "CanGrantQualification",
    role,
  };
}

export function canGrantRoles(
  roles: Role[],
  qualificationRoles: QualificationRole[],
  fallback: () => Check
): Check {
  return roles.length + qualificationRoles.length > 0
    ? and(
        ...roles.map(canGrantRole),
        ...qualificationRoles.map(canGrantQualification)
      )
    : fallback();
}

export const hasAccount: Check = {
  type: "HasAccount",
};

export function hasUserId(userId: UserId): Check {
  return {
    type: "HasUserId",
    userId,
  };
}

export const activeAnywhere: Check = {
  type: "InAnyWorkspace",
  name: "Active",
};

export const adminAnywhere: Check = {
  type: "InAnyWorkspace",
  name: "Admin",
};

export function named(name: string, check: Check): Check {
  return {
    type: "Named",
    name: `[Client] ${name}`,
    check,
  };
}

export function workspaceReadOnlyAccess(workspaceId: WorkspaceId): Check {
  return or(globalAdmin, {
    type: "WorkspaceAccess",
    workspaceId,
    minAccess: "ReadOnly",
  });
}

export function workspaceFullAccess(workspaceId: WorkspaceId): Check {
  return or(globalAdmin, {
    type: "WorkspaceAccess",
    workspaceId,
    minAccess: "Full",
  });
}

export const superuser = hasRole(superuserRole);

export const globalAdmin = hasRole(globalAdminRole);

export function workspaceActive(workspaceId: WorkspaceId): Check {
  return or(globalAdmin, hasRole(workspaceActiveRole(workspaceId)));
}

export function workspaceAdmin(workspaceId: WorkspaceId): Check {
  return or(globalAdmin, hasRole(workspaceAdminRole(workspaceId)));
}

export function workspaceOwner(workspaceId: WorkspaceId): Check {
  // NOT `globalAdmin`
  return hasRole(workspaceOwnerRole(workspaceId));
}

export function projectMember(
  projectId: ProjectId,
  workspaceId: WorkspaceId,
  teamIds: ArrayOrNullable<TeamId>
): Check {
  return projectRoleCheck("Member", projectId, workspaceId, teamIds);
}

export function projectSurveyor(
  model: ProjectPermissionModel,
  projectId: ProjectId,
  workspaceId: WorkspaceId,
  teamIds: ArrayOrNullable<TeamId>
): Check {
  switch (model) {
    case "Default":
      return projectRoleCheck("Surveyor", projectId, workspaceId, teamIds);
    case "CoordinatorOnly":
      return projectRoleCheck("Coordinator", projectId, workspaceId, teamIds);
    default:
      return checkExhausted(model);
  }
}

export function projectApprover(
  model: ProjectPermissionModel,
  projectId: ProjectId,
  workspaceId: WorkspaceId,
  teamIds: ArrayOrNullable<TeamId>
): Check {
  switch (model) {
    case "Default":
      return projectRoleCheck("Approver", projectId, workspaceId, teamIds);
    case "CoordinatorOnly":
      return projectRoleCheck("Coordinator", projectId, workspaceId, teamIds);
    default:
      return checkExhausted(model);
  }
}

export function projectCoordinator(
  projectId: ProjectId,
  workspaceId: WorkspaceId,
  teamIds: ArrayOrNullable<TeamId>
): Check {
  return projectRoleCheck("Coordinator", projectId, workspaceId, teamIds);
}

function projectRoleCheck(
  role: ProjectRoleName,
  projectId: ProjectId,
  workspaceId: WorkspaceId,
  teamIds: ArrayOrNullable<TeamId>
): Check {
  teamIds = isArray(teamIds) ? teamIds : Option.wrap(teamIds).toArray();

  return or(
    workspaceAdmin(workspaceId),
    and(
      workspaceActive(workspaceId),
      or(
        hasRole(projectRole(role, projectId)),
        ...teamIds.map(teamId => hasRole(teamRole(role, teamId)))
      )
    )
  );
}

export function teamMember(
  teamId: TeamId,
  workspaceId: WorkspaceId,
  projectIds: ArrayOrNullable<ProjectId>
): Check {
  return teamRoleCheck("Member", teamId, workspaceId, projectIds);
}

export function teamSurveyor(
  model: ProjectPermissionModel,
  teamId: TeamId,
  workspaceId: WorkspaceId,
  projectIds: ArrayOrNullable<ProjectId>
): Check {
  switch (model) {
    case "Default":
      return teamRoleCheck("Surveyor", teamId, workspaceId, projectIds);
    case "CoordinatorOnly":
      return teamRoleCheck("Coordinator", teamId, workspaceId, projectIds);
    default:
      return checkExhausted(model);
  }
}

export function teamApprover(
  model: ProjectPermissionModel,
  teamId: TeamId,
  workspaceId: WorkspaceId,
  projectIds: ArrayOrNullable<ProjectId>
): Check {
  switch (model) {
    case "Default":
      return teamRoleCheck("Approver", teamId, workspaceId, projectIds);
    case "CoordinatorOnly":
      return teamRoleCheck("Coordinator", teamId, workspaceId, projectIds);
    default:
      return checkExhausted(model);
  }
}

export function teamCoordinator(
  teamId: TeamId,
  workspaceId: WorkspaceId,
  projectIds: ArrayOrNullable<ProjectId>
): Check {
  return teamRoleCheck("Coordinator", teamId, workspaceId, projectIds);
}

function teamRoleCheck(
  role: TeamRoleName,
  teamId: TeamId,
  workspaceId: WorkspaceId,
  projectIds: ArrayOrNullable<ProjectId>
): Check {
  projectIds = isArray(projectIds)
    ? projectIds
    : Option.wrap(projectIds).toArray();

  return or(
    workspaceAdmin(workspaceId),
    and(
      workspaceActive(workspaceId),
      or(
        hasRole(teamRole(role, teamId)),
        ...projectIds.map(projectId => hasRole(projectRole(role, projectId)))
      )
    )
  );
}

export function whenProjectHasTeam(
  project: Project,
  teamId: Optional<TeamId>,
  subCheck: (teamId: TeamId) => Check
): Check {
  return teamId == null
    ? never("No team specified")
    : project.teamIds.includes(teamId)
      ? subCheck(teamId)
      : never(`Project ${project.id} does not contain team ${teamId}`);
}
