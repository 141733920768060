import { AttachmentId, ContentType } from "./types.generated";
import { unsafeTag } from "@cartographerio/util";
import { v4 as createUuid } from "uuid";

export function randomAttachmentId(): AttachmentId {
  return unsafeTag<"AttachmentId">(createUuid());
}

export function unsafeAttachmentId(str: string): AttachmentId {
  return unsafeTag<"AttachmentId">(str);
}

export function unsafeContentType(str: string): ContentType {
  return unsafeTag<"ContentType">(str);
}
