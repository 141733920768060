// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsPlanform = "SN" | "ST" | "ME" | "RC";

const SemiNatural: UrsPlanform = "SN";
const Engineered: UrsPlanform = "ST";
const Meandering: UrsPlanform = "ME";
const Recovering: UrsPlanform = "RC";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsPlanformEnum = {
  ...createEnum("UrsPlanform", [
    [SemiNatural, "Semi-natural"],
    [Engineered, "Engineered: straight"],
    [Meandering, "Engineered: sinuous / meandering"],
    [Recovering, "Recovering"],
  ]),
  SemiNatural,
  Engineered,
  Meandering,
  Recovering,
};

export default UrsPlanformEnum;
