import { prettyPrintJson } from "@cartographerio/util";
import { Guard, GuardAs, GuardError } from "./type";
import { isObject } from "./guard";
import { hasKey, hasKeyOfAnyType, hasTypeTag, isString } from "./guard";

export function isGuardError(value: unknown): value is GuardError {
  return (
    isObject(value) &&
    hasTypeTag(value, "GuardError") &&
    hasKey(value, "hint", isString) &&
    hasKeyOfAnyType(value, "expected") &&
    hasKeyOfAnyType(value, "received")
  );
}

export function guardError(
  hint: string,
  expected: unknown,
  received: unknown
): GuardError {
  return {
    type: "GuardError",
    hint,
    expected,
    received,
  };
}

export function guard<A>(hint: string, guard: Guard<A>): GuardAs<A> {
  return function (value: unknown): A {
    if (guard(value)) {
      return value;
    } else {
      console.error("Failed guard", prettyPrintJson({ guard, hint, value }));
      throw guardError(hint, guard, value);
    }
  };
}
