// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityFlowObstacle =
  | "Weir"
  | "BridgeOrCulvert"
  | "Tree"
  | "DebrisDam"
  | "Ford"
  | "Other";

const Weir: EsrtWaterQualityFlowObstacle = "Weir";
const BridgeOrCulvert: EsrtWaterQualityFlowObstacle = "BridgeOrCulvert";
const Tree: EsrtWaterQualityFlowObstacle = "Tree";
const DebrisDam: EsrtWaterQualityFlowObstacle = "DebrisDam";
const Ford: EsrtWaterQualityFlowObstacle = "Ford";
const Other: EsrtWaterQualityFlowObstacle = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityFlowObstacleEnum = {
  ...createEnum("EsrtWaterQualityFlowObstacle", [
    [Weir, "Weir / sluice gate"],
    [BridgeOrCulvert, "Bridge / culvert"],
    [Tree, "Fallen or submerged tree"],
    [DebrisDam, "Debris dam"],
    [Ford, "Ford"],
    [Other, "Other artificial structure"],
  ]),
  Weir,
  BridgeOrCulvert,
  Tree,
  DebrisDam,
  Ford,
  Other,
};

export default EsrtWaterQualityFlowObstacleEnum;
