import { Option, Result } from "@cartographerio/fp";
import { isPoint, Point, safePointToNgr } from "@cartographerio/geometry";

import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isInteger,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  AttachmentFolder,
  isAttachmentFolder,
  isTimestamp,
  Timestamp,
} from "@cartographerio/types";

interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  collectedOnBehalfOf?: string | null;
  numberOfPlants?: number | null;
  percentagePlantCover?: number | null;
  plantSpecies?: string | null;
  invasivePlantSpecies?: string | null;
  wildlife?: string | null;
  photographs: AttachmentFolder;
  comments?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "collectedOnBehalfOf", isNullable(isString)) &&
    hasOptionalKey(data, "numberOfPlants", isNullable(isInteger)) &&
    hasOptionalKey(data, "percentagePlantCover", isNullable(isInteger)) &&
    hasOptionalKey(data, "plantSpecies", isNullable(isString)) &&
    hasOptionalKey(data, "invasivePlantSpecies", isNullable(isString)) &&
    hasOptionalKey(data, "wildlife", isNullable(isString)) &&
    hasKey(data, "photographs", isAttachmentFolder) &&
    hasOptionalKey(data, "comments", isNullable(isString))
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    Option.wrap(data.location)
      .map(safePointToNgr)
      .getOrElse(() => "Unknown NGR")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.recorded ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data?.location ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data);
}
