// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21LitterSmallPlasticCount =
  | "1000+"
  | "100-999"
  | "10-99"
  | "1-9";

const Over1000: Thames21LitterSmallPlasticCount = "1000+";
const From100To999: Thames21LitterSmallPlasticCount = "100-999";
const From10To99: Thames21LitterSmallPlasticCount = "10-99";
const From1To9: Thames21LitterSmallPlasticCount = "1-9";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21LitterSmallPlasticCountEnum = {
  ...createEnum("Thames21LitterSmallPlasticCount", [
    [Over1000, "1000+"],
    [From100To999, "100-999"],
    [From10To99, "10-99"],
    [From1To9, "1-9"],
  ]),
  Over1000,
  From100To999,
  From10To99,
  From1To9,
};

export default Thames21LitterSmallPlasticCountEnum;
