import { Path } from "@cartographerio/types";
import { Enum, EnumEntry } from "@cartographerio/util";
import { TopoExpr } from "./expr";
import {
  AttachmentField,
  Autocomplete,
  Block,
  Card,
  Checkbox,
  DynamicAttr,
  DynamicGrid,
  DynamicImage,
  DynamicValue,
  FeatureField,
  FeatureFieldCartographerMapStyle,
  FeatureFieldExternalMapStyle,
  Form,
  FullWidth,
  Group,
  Heading,
  IntegerField,
  LocalAutocompleteSource,
  MultiSelect,
  NearestFeatureField,
  NumberField,
  Page,
  PointField,
  PrimarySurveyorField,
  PrimaryTeamField,
  Repeat,
  RequireRole,
  Scope,
  Select,
  SelectOption,
  SelectValue,
  TeamField,
  Text,
  TextArea,
  TextField,
  TimestampField,
  UserRefField,
  Visibility,
} from "./type";

export function textField(props: Omit<TextField, "type">): TextField {
  return {
    type: "TextField",
    ...props,
  };
}

export function textArea(props: Omit<TextArea, "type">): TextArea {
  return {
    type: "TextArea",
    ...props,
  };
}

export function autocomplete(props: Omit<Autocomplete, "type">): Autocomplete {
  return {
    type: "Autocomplete",
    ...props,
  };
}

export function localAutocompleteSource(
  options: string[]
): LocalAutocompleteSource {
  return {
    type: "Local",
    options,
  };
}

export function integerField(props: Omit<IntegerField, "type">): IntegerField {
  return {
    type: "IntegerField",
    ...props,
  };
}

export function numberField(props: Omit<NumberField, "type">): NumberField {
  return {
    type: "NumberField",
    ...props,
  };
}

export function userRefField(props: Omit<UserRefField, "type">): UserRefField {
  return { type: "UserRefField", ...props };
}

export function teamField(props: Omit<TeamField, "type">): TeamField {
  return { type: "TeamField", ...props };
}

export function timestampField(
  props: Omit<TimestampField, "type">
): TimestampField {
  return { type: "TimestampField", ...props };
}

export function attachmentField(
  props: Omit<AttachmentField, "type">
): AttachmentField {
  return {
    type: "AttachmentField",
    ...props,
  };
}

export function pointField(props: Omit<PointField, "type">): PointField {
  return {
    type: "PointField",
    ...props,
  };
}

export function featureFieldCartographerMapStyle(
  props: Omit<FeatureFieldCartographerMapStyle, "type">
): FeatureFieldCartographerMapStyle {
  return {
    type: "Cartographer",
    ...props,
  };
}

export function featureFieldExternalMapStyle(
  props: Omit<FeatureFieldExternalMapStyle, "type">
): FeatureFieldExternalMapStyle {
  return {
    type: "External",
    ...props,
  };
}

export function featureField(props: Omit<FeatureField, "type">): FeatureField {
  return {
    type: "FeatureField",
    ...props,
  };
}

export function nearestFeatureField(
  props: Omit<NearestFeatureField, "type">
): NearestFeatureField {
  return {
    type: "NearestFeatureField",
    ...props,
  };
}

export function checkbox(props: Omit<Checkbox, "type">): Checkbox {
  return {
    type: "Checkbox",
    ...props,
  };
}

export function primarySurveyorField(
  props: Omit<PrimarySurveyorField, "type">
): PrimarySurveyorField {
  const { label, help = "Who was the surveyor?", rule } = props;
  return { type: "PrimarySurveyorField", label, help, rule };
}

export function primaryTeamField(
  props: Omit<PrimaryTeamField, "type">
): PrimaryTeamField {
  const {
    label,
    help = "Which team was the person surveying for?",
    rule,
  } = props;
  return { type: "PrimaryTeamField", label, help, rule };
}

export function selectOption<A extends SelectValue>(
  value: A,
  label: string,
  key?: string
): SelectOption<A> {
  return { value, label, key };
}

export function enumSelectOptions<A extends SelectValue>(
  source: Enum<A> | EnumEntry<A>[],
  showValues: boolean = false
): SelectOption<A>[] {
  const entries: EnumEntry<A>[] = Array.isArray(source)
    ? source
    : source.entries;

  const options: SelectOption<A>[] = entries.map(({ value, label }) => ({
    value,
    label: showValues ? `${value} - ${label}` : label,
  }));

  return options;
}

export function select<A extends SelectValue>(
  props: Omit<Select<A>, "type">
): Select<A> {
  return { type: "Select", ...props };
}

export function multiSelect<A extends SelectValue>(
  props: Omit<MultiSelect<A>, "type">
): MultiSelect<A> {
  return { type: "MultiSelect", ...props };
}

export function card(props: Omit<Card, "type">): Card {
  return { type: "Card", ...props };
}

export function dynamicAttr(props: DynamicAttr): DynamicAttr {
  return { ...props };
}

export function dynamicValue(props: Omit<DynamicValue, "type">): DynamicValue {
  return { type: "DynamicValue", ...props };
}

export function dynamicGrid(props: Omit<DynamicGrid, "type">): DynamicGrid {
  return { type: "DynamicGrid", ...props };
}

export function dynamicImage(props: Omit<DynamicImage, "type">): DynamicImage {
  return { type: "DynamicImage", ...props };
}

export function fullWidth(block: Block): FullWidth {
  return { type: "FullWidth", block };
}

export function heading(props: Omit<Heading, "type">): Heading {
  return { type: "Heading", ...props };
}

export function sectionHeading(text: string): Heading {
  return { type: "Heading", level: "section", text };
}

export function subsectionHeading(text: string): Heading {
  return { type: "Heading", level: "subsection", text };
}

export function subsubsectionHeading(text: string): Heading {
  return { type: "Heading", level: "subsubsection", text };
}

export function text(props: Omit<Text, "type">): Text {
  return { type: "Text", ...props };
}

export function bodyText(markdown: string): Text {
  return { type: "Text", appearance: "default", markdown };
}

export function helpText(markdown: string): Text {
  return { type: "Text", appearance: "help", markdown };
}

interface GroupParams extends Omit<Group, "type" | "blocks"> {
  blocks: Block | Block[];
}

export function grid(props: GroupParams): Group {
  const { blocks, ...rest } = props;
  return {
    type: "Group",
    ...rest,
    blocks: Array.isArray(blocks) ? blocks : [blocks],
  };
}

export function columns2(...blocks: Block[]): Group {
  return { type: "Group", columns: 2, blocks };
}

export function columns3(...blocks: Block[]): Group {
  return { type: "Group", columns: 3, blocks };
}

export function vstack(...blocks: Block[]): Group {
  return { type: "Group", columns: 1, blocks };
}

export function repeat(props: Omit<Repeat, "type">): Repeat {
  return { type: "Repeat", ...props };
}

export function requireRole(props: Omit<RequireRole, "type">): Block {
  return { type: "RequireRole", ...props };
}

export function scope(props: Omit<Scope, "type">): Scope {
  return { type: "Scope", ...props };
}

export function visibility(props: Omit<Visibility, "type">): Visibility {
  return { type: "Visibility", ...props };
}

interface SectionProps {
  title: string | null;
  path: Path;
  blocks: Block | Block[];
  visible?: TopoExpr<boolean>;
  appearance?: "success" | "warning" | "default";
  help?: string;
}

export function createSectionConstructor(myHeading: (text: string) => Block) {
  return function (props: SectionProps): Block {
    const { title, path, blocks: _blocks, visible, appearance, help } = props;

    const blocks = [
      ...(title == null ? [] : [myHeading(title)]),
      ...(help == null ? [] : [helpText(help)]),
      ...(Array.isArray(_blocks) ? [..._blocks] : [_blocks]),
    ];

    let content: Block = vstack(...blocks);

    if (appearance != null) {
      content = card({ appearance, block: content });
    }

    if (path != null && path.length > 0) {
      content = scope({ path, block: content });
    }

    if (visible != null) {
      content = visibility({ visible, block: content });
    }

    return content;
  };
}

export const section = createSectionConstructor(sectionHeading);

export const subsection = createSectionConstructor(subsectionHeading);

export const subsubsection = createSectionConstructor(subsubsectionHeading);

interface PageParams extends Omit<Page, "type" | "blocks"> {
  blocks: Block | Block[];
}

export function page(props: PageParams): Page {
  const { blocks, ...rest } = props;
  return {
    type: "Page",
    ...rest,
    blocks: Array.isArray(blocks) ? blocks : [blocks],
  };
}

interface FormParams extends Omit<Form, "type" | "pages"> {
  pages: Page | Page[];
}

export function form(props: FormParams): Form {
  const { pages, ...rest } = props;
  return {
    type: "Form",
    ...rest,
    pages: Array.isArray(pages) ? pages : [pages],
  };
}
