// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtFordsLivestock =
  | "DairyCowsAndFollowers"
  | "Calves"
  | "DairyBeef"
  | "BeefCattleOrSucklers"
  | "Sheep"
  | "Horses"
  | "None";

const DairyCowsAndFollowers: WrtFordsLivestock = "DairyCowsAndFollowers";
const Calves: WrtFordsLivestock = "Calves";
const DairyBeef: WrtFordsLivestock = "DairyBeef";
const BeefCattleOrSucklers: WrtFordsLivestock = "BeefCattleOrSucklers";
const Sheep: WrtFordsLivestock = "Sheep";
const Horses: WrtFordsLivestock = "Horses";
const None: WrtFordsLivestock = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtFordsLivestockEnum = {
  ...createEnum("WrtFordsLivestock", [
    [DairyCowsAndFollowers, "Dairy cows & followers"],
    [Calves, "Calves"],
    [DairyBeef, "Dairy beef"],
    [BeefCattleOrSucklers, "Beef cattle and/or sucklers"],
    [Sheep, "Sheep"],
    [Horses, "Horses"],
    [None, "None"],
  ]),
  DairyCowsAndFollowers,
  Calves,
  DairyBeef,
  BeefCattleOrSucklers,
  Sheep,
  Horses,
  None,
};

export default WrtFordsLivestockEnum;
