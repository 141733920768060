// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiFlowLevel =
  | "surging"
  | "steady"
  | "slow"
  | "still";

const Surging: WrtWestcountryCsiFlowLevel = "surging";
const Steady: WrtWestcountryCsiFlowLevel = "steady";
const Slow: WrtWestcountryCsiFlowLevel = "slow";
const Still: WrtWestcountryCsiFlowLevel = "still";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiFlowLevelEnum = {
  ...createEnum("WrtWestcountryCsiFlowLevel", [
    [Surging, "Surging"],
    [Steady, "Steady"],
    [Slow, "Slow"],
    [Still, "Still"],
  ]),
  Surging,
  Steady,
  Slow,
  Still,
};

export default WrtWestcountryCsiFlowLevelEnum;
