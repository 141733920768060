import { SearchResults } from "./types.generated";

export function createSearchResults<A>(
  results: A[],
  total?: number
): SearchResults<A> {
  return { results, total: total == null ? results.length : total };
}

export function emptySearchResults<A>(): SearchResults<A> {
  return createSearchResults<A>([]);
}

export function mapSearchResults<A, B>(
  results: SearchResults<A>,
  func: (a: A) => B
): SearchResults<B> {
  return createSearchResults(results.results.map(func), results.total);
}

export function filterSearchResults<A>(
  results: SearchResults<A>,
  func: (a: A) => boolean
): SearchResults<A> {
  return createSearchResults(results.results.filter(func), results.total);
}

export function replaceSearchResults<A>(
  results: SearchResults<A>,
  func: (a: A) => boolean,
  a: A
): SearchResults<A> {
  return mapSearchResults(results, s => (func(s) ? a : s));
}

export function sliceSearchResults<A>(
  results: SearchResults<A>,
  from: number,
  to: number
): SearchResults<A> {
  const a = Math.max(0, Math.min(results.results.length - 1, from));
  const b = Math.max(from, Math.min(results.results.length - 1, to));
  return { results: results.results.slice(a, b), total: results.total };
}
