import { Button, ButtonProps } from "@chakra-ui/react";
import { ForwardedRef, ReactElement, forwardRef } from "react";

import Link, { ExternalLinkProps, InternalLinkProps } from "./Link";

interface ButtonLinkProps {
  colorScheme?: ButtonProps["colorScheme"];
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  leftIcon?: ReactElement;
  buttonProps?: Omit<
    ButtonProps,
    "colorScheme" | "variant" | "size" | "leftIcon"
  >;
}

/** A link that looks like a button! */
function ExternalButtonLink(
  props: ButtonLinkProps & ExternalLinkProps,
  ref: ForwardedRef<HTMLButtonElement>
): ReactElement {
  const {
    colorScheme,
    variant,
    size,
    leftIcon,
    disabled,
    buttonProps = {},
    children,
    ...rest
  } = props;

  return disabled ? (
    <Button
      ref={ref}
      leftIcon={leftIcon}
      variant={variant}
      colorScheme={colorScheme}
      size={size}
      isDisabled={disabled}
      {...buttonProps}
    >
      {children}
    </Button>
  ) : (
    <Link.External color="unset" _hover={{ textDecoration: "unset" }} {...rest}>
      <Button
        ref={ref}
        leftIcon={leftIcon}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        {...buttonProps}
      >
        {children}
      </Button>
    </Link.External>
  );
}

function InternalButtonLink(
  props: ButtonLinkProps & InternalLinkProps,
  ref: ForwardedRef<HTMLButtonElement>
): ReactElement {
  const {
    colorScheme,
    variant,
    size,
    leftIcon,
    disabled,
    buttonProps = {},
    children,
    ...rest
  } = props;

  return disabled ? (
    <Button
      ref={ref}
      leftIcon={leftIcon}
      variant={variant}
      colorScheme={colorScheme}
      size={size}
      isDisabled={disabled}
      {...buttonProps}
    >
      {children}
    </Button>
  ) : (
    <Link.Internal color="unset" _hover={{ textDecoration: "unset" }} {...rest}>
      <Button
        ref={ref}
        leftIcon={leftIcon}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        {...buttonProps}
      >
        {children}
      </Button>
    </Link.Internal>
  );
}

export default {
  External: forwardRef(ExternalButtonLink),
  Internal: forwardRef(InternalButtonLink),
};
