import { Result } from "@cartographerio/fp";
import { Point, isPoint, safePointToNgr } from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  OutfallSafariActivity,
  OutfallSafariActivityEnum,
  OutfallSafariAesthetics,
  OutfallSafariAestheticsEnum,
  OutfallSafariBank,
  OutfallSafariBankEnum,
  OutfallSafariFlowOfDischarge,
  OutfallSafariFlowOfDischargeEnum,
  OutfallSafariInaccessibleAreaLength,
  OutfallSafariInaccessibleAreaLengthEnum,
  OutfallSafariVisualImpact,
  OutfallSafariVisualImpactEnum,
} from "@cartographerio/inventory-enums";
import { Timestamp, isTimestamp, nowTimestamp } from "@cartographerio/types";

interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  activity?: OutfallSafariActivity | null;
  outfallBank?: OutfallSafariBank | null;
  flowOfDischarge?: OutfallSafariFlowOfDischarge | null;
  visualImpact?: OutfallSafariVisualImpact | null;
  aesthetics?: OutfallSafariAesthetics | null;
  otherFormsOfPollution?: string | null;
  inaccessibleAreaDescription?: string | null;
  inaccessibleAreaLength?: OutfallSafariInaccessibleAreaLength | null;
  notes?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(
      data,
      "activity",
      isNullable(OutfallSafariActivityEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "outfallBank",
      isNullable(OutfallSafariBankEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "flowOfDischarge",
      isNullable(OutfallSafariFlowOfDischargeEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "visualImpact",
      isNullable(OutfallSafariVisualImpactEnum.isValue)
    ) &&
    hasOptionalKey(
      data,
      "aesthetics",
      isNullable(OutfallSafariAestheticsEnum.isValue)
    ) &&
    hasOptionalKey(data, "otherFormsOfPollution", isNullable(isString)) &&
    hasOptionalKey(data, "inaccessibleAreaDescription", isNullable(isString)) &&
    hasOptionalKey(
      data,
      "inaccessibleAreaLength",
      isNullable(OutfallSafariInaccessibleAreaLengthEnum.isValue)
    ) &&
    hasOptionalKey(data, "notes", isNullable(isString))
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data.activity == null
        ? "Unknown Activity"
        : OutfallSafariActivityEnum.labelOf(data.activity),
      data.location == null
        ? "Unknown Location"
        : safePointToNgr(data.location),
    ].join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.recorded ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data.location ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
    recorded: nowTimestamp(),
    activity: OutfallSafariActivityEnum.Outfall,
    outfallBank: null,
    flowOfDischarge: null,
    visualImpact: null,
    aesthetics: null,
    otherFormsOfPollution: null,
    inaccessibleAreaDescription: null,
    inaccessibleAreaLength: null,
    notes: null,
  }));
}
