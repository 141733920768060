import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  siteName?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "siteName", isNullable(isString))
  );
}

function createDescription(...args: Array<string | null>): string {
  return args.filter(arg => arg != null).join(", ");
}

const g = Result.guard(isPartialData, "PartialWrtFordsData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    createDescription(
      data.siteName ?? "Unknown site",
      data.location ? safePointToNgr(data.location) : "Unknown NGR"
    )
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}
