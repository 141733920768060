// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterSurveyType =
  | "monitoring"
  | "pre-project"
  | "post-project"
  | "training"
  | "not-applicable";

const Monitoring: MrsMudSpotterSurveyType = "monitoring";
const PreProject: MrsMudSpotterSurveyType = "pre-project";
const PostProject: MrsMudSpotterSurveyType = "post-project";
const Training: MrsMudSpotterSurveyType = "training";
const NotApplicable: MrsMudSpotterSurveyType = "not-applicable";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterSurveyTypeEnum = {
  ...createEnum("MrsMudSpotterSurveyType", [
    [Monitoring, "Monitoring"],
    [PreProject, "Pre-project"],
    [PostProject, "Post-project"],
    [Training, "Training"],
    [NotApplicable, "Not applicable"],
  ]),
  Monitoring,
  PreProject,
  PostProject,
  Training,
  NotApplicable,
};

export default MrsMudSpotterSurveyTypeEnum;
