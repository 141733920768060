// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsPollutionIndicator =
  | "waterOdours"
  | "sedimentOdours"
  | "oils"
  | "surfaceScum"
  | "grossPollution";

const WaterOdours: UrsPollutionIndicator = "waterOdours";
const SedimentOdours: UrsPollutionIndicator = "sedimentOdours";
const Oils: UrsPollutionIndicator = "oils";
const SurfaceScum: UrsPollutionIndicator = "surfaceScum";
const GrossPollution: UrsPollutionIndicator = "grossPollution";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsPollutionIndicatorEnum = {
  ...createEnum("UrsPollutionIndicator", [
    [WaterOdours, "Water odours"],
    [SedimentOdours, "Sediment odours"],
    [Oils, "Oils"],
    [SurfaceScum, "Surface scum"],
    [GrossPollution, "Gross pollution"],
  ]),
  WaterOdours,
  SedimentOdours,
  Oils,
  SurfaceScum,
  GrossPollution,
};

export default UrsPollutionIndicatorEnum;
