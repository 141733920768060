import {
  Attribute,
  booleanAttr,
  lookupBucket,
  marker,
  stringAttr,
  thresholdAttr,
  thresholdBucket,
} from "@cartographerio/topo-map";

export function countedLitterAttr(
  attributeId: string,
  label: string,
  unit: string = "items"
): Attribute {
  return thresholdAttr({
    attributeId,
    label,
    unit,
    buckets: [
      thresholdBucket(0, marker.quality(0.4)),
      thresholdBucket(10, marker.quality(0.3)),
      thresholdBucket(30, marker.quality(0.2)),
      thresholdBucket(50, marker.quality(0.1)),
      thresholdBucket(100, marker.quality(0.0)),
    ],
  });
}

export function polystyreneLitterAttr(
  attributeId: string,
  label: string
): Attribute {
  return stringAttr({
    attributeId,
    label,
    buckets: [
      lookupBucket("50-100", marker.quality(0.0), "50-100% cover"),
      lookupBucket("20-49", marker.quality(0.1), "20-49% cover"),
      lookupBucket("10-19", marker.quality(0.2), "10-19% cover"),
      lookupBucket("5-9", marker.quality(0.3), "5-9% cover"),
      lookupBucket("0-4", marker.quality(0.4), ">0-4% cover"),
    ],
  });
}

export function customBooleanAttr(
  attributeId: string,
  label: string,
  falseLabel: string,
  trueLabel: string
): Attribute {
  return booleanAttr({
    attributeId,
    label,
    buckets: [
      lookupBucket(false, marker.quality(0.2), falseLabel),
      lookupBucket(true, marker.quality(0.8), trueLabel),
    ],
  });
}
