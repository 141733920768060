import { isArrayOf } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  DateActivity,
  isDateActivity,
  isLabeledDateActivity,
  LabeledDateActivity,
  WorkspaceRef,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import { contentAs } from "../../response";
import { UrlParts } from "../../url";

const basePath = "/analytics/v1";

export function activeUsers(
  apiParams: ApiParams,
  workspace: WorkspaceRef
): IO<DateActivity[]> {
  const url: UrlParts = {
    path: `${basePath}/active-users/${workspace}`,
  };

  return fetch
    .get({ apiParams, url })
    .chain(contentAs("DateActivity[]", isArrayOf(isDateActivity)));
}

export function activeSurveyors(
  apiParams: ApiParams
): IO<LabeledDateActivity[]> {
  const url: UrlParts = {
    path: `${basePath}/active-surveyors`,
  };

  return fetch
    .get({ apiParams, url })
    .chain(
      contentAs("LabeledDateActivity[]", isArrayOf(isLabeledDateActivity))
    );
}
