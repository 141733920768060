// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankTopTerrestrialVegetation =
  | "unvegetated"
  | "mossesOrLichens"
  | "shortCreepingHerbsOrGrasses"
  | "tallHerbsOrGrasses"
  | "scrubOrShrubs"
  | "saplingsOrTrees";

const Unvegetated: CesBankTopTerrestrialVegetation = "unvegetated";
const MossesOrLichens: CesBankTopTerrestrialVegetation = "mossesOrLichens";
const ShortCreepingHerbsOrGrasses: CesBankTopTerrestrialVegetation =
  "shortCreepingHerbsOrGrasses";
const TallHerbsOrGrasses: CesBankTopTerrestrialVegetation =
  "tallHerbsOrGrasses";
const ScrubOrShrubs: CesBankTopTerrestrialVegetation = "scrubOrShrubs";
const SaplingsOrTrees: CesBankTopTerrestrialVegetation = "saplingsOrTrees";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankTopTerrestrialVegetationEnum = {
  ...createEnum("CesBankTopTerrestrialVegetation", [
    [Unvegetated, "Unvegetated (bare soil)"],
    [MossesOrLichens, "Mosses / lichens"],
    [ShortCreepingHerbsOrGrasses, "Short/creeping herbs/grasses"],
    [TallHerbsOrGrasses, "Tall herbs/grasses"],
    [ScrubOrShrubs, "Scrub or shrubs"],
    [SaplingsOrTrees, "Saplings or trees"],
  ]),
  Unvegetated,
  MossesOrLichens,
  ShortCreepingHerbsOrGrasses,
  TallHerbsOrGrasses,
  ScrubOrShrubs,
  SaplingsOrTrees,
};

export default CesBankTopTerrestrialVegetationEnum;
