// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessBirdSpecies =
  | "GreatCrestedGrebe"
  | "Pochard"
  | "Gadwall";

const GreatCrestedGrebe: NeLakeNaturalnessBirdSpecies = "GreatCrestedGrebe";
const Pochard: NeLakeNaturalnessBirdSpecies = "Pochard";
const Gadwall: NeLakeNaturalnessBirdSpecies = "Gadwall";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessBirdSpeciesEnum = {
  ...createEnum("NeLakeNaturalnessBirdSpecies", [
    [GreatCrestedGrebe, "Great crested grebe (Podiceps cristatus)"],
    [Pochard, "Pochard (Athya ferina)"],
    [Gadwall, "Gadwall (Anas strepera)"],
  ]),
  GreatCrestedGrebe,
  Pochard,
  Gadwall,
};

export default NeLakeNaturalnessBirdSpeciesEnum;
