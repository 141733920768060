import {
  ApiParams,
  MapboxAccessTokenResponse,
  isMapboxAccessTokenResponse,
} from "@cartographerio/types";

import * as fetch from "../../fetch";
import { contentAs } from "../../response";
import { IO } from "@cartographerio/io";

const basePath = "/mapbox/v1";

export function token(apiParams: ApiParams): IO<MapboxAccessTokenResponse> {
  const url = {
    path: `${basePath}/token`,
  };
  return fetch
    .get({ apiParams, url })
    .flatMap(
      contentAs("MapboxAccessTokenResponse", isMapboxAccessTokenResponse)
    );
}
