// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsTreeDistribution = "None" | "IS" | "RS" | "OC" | "SC" | "CO";

const None: UrsTreeDistribution = "None";
const IsolatedScattered: UrsTreeDistribution = "IS";
const RegularlySpacedIndividuals: UrsTreeDistribution = "RS";
const OccasionalClumps: UrsTreeDistribution = "OC";
const SemiContinuous: UrsTreeDistribution = "SC";
const Continuous: UrsTreeDistribution = "CO";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsTreeDistributionEnum = {
  ...createEnum("UrsTreeDistribution", [
    [None, "None"],
    [IsolatedScattered, "Isolated/scattered"],
    [RegularlySpacedIndividuals, "Regularly spaced individuals"],
    [OccasionalClumps, "Occasional clumps"],
    [SemiContinuous, "Semi-continuous"],
    [Continuous, "Continuous"],
  ]),
  None,
  IsolatedScattered,
  RegularlySpacedIndividuals,
  OccasionalClumps,
  SemiContinuous,
  Continuous,
};

export default UrsTreeDistributionEnum;
