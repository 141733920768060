import {
  topoExpr,
  checkbox,
  columns2,
  forbiddenIfV2,
  form,
  integerField,
  minValue,
  page,
  pointField,
  required,
  requiredIfV2,
  section,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";

export default form({
  title: "Riverfly Site",
  surveyorLabel: "Site Registrar",
  surveyorHelp: "Record the surveyor or site-registering coordinator.",
  teamHelp: "Which team is primarily responsible for surveying at this site?",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: ["data"],
          blocks: [
            columns2(
              vstack(
                textField({
                  label: "Catchment Name",
                  path: ["catchment"],
                  required: required(
                    "info",
                    "You should enter a catchment name"
                  ),
                }),
                textField({
                  label: "River Name",
                  path: ["river"],
                  required: required("info", "You should enter a river name"),
                }),
                textField({
                  label: "Site Name",
                  path: ["site"],
                  required: required("info", "You should enter a site name"),
                })
              ),
              pointField({
                label: "Location",
                region: "uk",
                path: ["location"],
                required: required(
                  "info",
                  "You must enter the location of the site"
                ),
              })
            ),
            columns2(
              vstack(
                timestampField({
                  label: "Date and Time Established",
                  path: ["established"],
                  required: required(
                    "info",
                    "You should enter a date and time established"
                  ),
                }),
                checkbox({
                  label: "Site Accepted?",
                  path: ["siteAccepted"],
                  checkboxLabel:
                    "Has the site been accepted by the relevant statutory body?",
                }),
                integerField({
                  label: "Trigger Level",
                  path: ["triggerLevel"],
                  help: "The score below which a pollution event will be signalled.",
                  bounds: minValue(
                    0,
                    "error",
                    "Trigger Levels must be greater than or equal to zero"
                  ),
                  customRules: [
                    requiredIfV2({
                      level: "info",
                      message:
                        "Enter the trigger level agreed with the statury body.",
                      otherTest: topoExpr(
                        env => env.get(["siteAccepted"]) === true
                      ),
                    }),
                    forbiddenIfV2({
                      level: "info",
                      message:
                        "Only enter a trigger level if the site has been accepted by a statutory body.",
                      otherTest: topoExpr(
                        env => env.get(["siteAccepted"]) !== true
                      ),
                    }),
                  ],
                })
              )
            ),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 8,
            }),
          ],
        }),
      ],
    }),
  ],
});
