// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { QualificationRoleName } from "../types.generated";

const Expired: QualificationRoleName = "Expired";
const Trainee: QualificationRoleName = "Trainee";
const Qualified: QualificationRoleName = "Qualified";
const Trainer: QualificationRoleName = "Trainer";
const Coordinator: QualificationRoleName = "Coordinator";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const QualificationRoleNameEnum = {
  ...createEnum("QualificationRoleName", [
    [Expired, "Expired"],
    [Trainee, "Trainee"],
    [Qualified, "Qualified"],
    [Trainer, "Trainer"],
    [Coordinator, "Coordinator"],
  ]),
  Expired,
  Trainee,
  Qualified,
  Trainer,
  Coordinator,
};

export default QualificationRoleNameEnum;
