// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPriorityPondsAnnex1Type =
  | "None"
  | "H3110"
  | "H3130"
  | "H3140"
  | "H3150"
  | "H3160"
  | "H3170"
  | "H3180"
  | "H2190";

const None: FhtPriorityPondsAnnex1Type = "None";
const H3110: FhtPriorityPondsAnnex1Type = "H3110";
const H3130: FhtPriorityPondsAnnex1Type = "H3130";
const H3140: FhtPriorityPondsAnnex1Type = "H3140";
const H3150: FhtPriorityPondsAnnex1Type = "H3150";
const H3160: FhtPriorityPondsAnnex1Type = "H3160";
const H3170: FhtPriorityPondsAnnex1Type = "H3170";
const H3180: FhtPriorityPondsAnnex1Type = "H3180";
const H2190: FhtPriorityPondsAnnex1Type = "H2190";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPriorityPondsAnnex1TypeEnum = {
  ...createEnum("FhtPriorityPondsAnnex1Type", [
    [None, "None"],
    [
      H3110,
      "3110 - Oligotrophic waters containing very few minerals of sandy plains (Littorelletalia uniflorae)",
    ],
    [
      H3130,
      "3130 - Oligotrophic to mesotrophic standing waters with vegetation of the Littorelletea uniflorae and/or Isoeto-Nanojuncetea",
    ],
    [
      H3140,
      "3140 - Hard oligo-mesotrophic waters with benthic vegetation of Chara spp",
    ],
    [
      H3150,
      "3150 - Natural eutrophic lakes with Magnopotamion or Hydrocharition \u2013 type vegetation",
    ],
    [H3160, "3160 - Natural dystrophic lakes and ponds"],
    [H3170, "3170 - Mediterranean temporary ponds"],
    [H3180, "3180 - Turloughs"],
    [H2190, "2190 - Humid dune slacks"],
  ]),
  None,
  H3110,
  H3130,
  H3140,
  H3150,
  H3160,
  H3170,
  H3180,
  H2190,
};

export default FhtPriorityPondsAnnex1TypeEnum;
