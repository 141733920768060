import { bodyText, form, page, section } from "@cartographerio/topo-form";

export default form({
  title: "Canal Environment Survey",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            bodyText(
              "CES is a legacy survey that can't be edited in the Cartographer web site or mobile app. However, you can temporarily download CES data via the Cartographer web site."
            ),
          ],
        }),
      ],
    }),
  ],
});
