// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsChannelDynamicsFeature =
  | "buriedSoil"
  | "fineSediment"
  | "structureBases"
  | "vegetationBases"
  | "narrowChannel"
  | "bedSediment"
  | "exposedRoots"
  | "exposedFoundations"
  | "compactedBed";

const BuriedSoil: UrsChannelDynamicsFeature = "buriedSoil";
const FineSediment: UrsChannelDynamicsFeature = "fineSediment";
const StructureBases: UrsChannelDynamicsFeature = "structureBases";
const VegetationBases: UrsChannelDynamicsFeature = "vegetationBases";
const NarrowChannel: UrsChannelDynamicsFeature = "narrowChannel";
const BedSediment: UrsChannelDynamicsFeature = "bedSediment";
const ExposedRoots: UrsChannelDynamicsFeature = "exposedRoots";
const ExposedFoundations: UrsChannelDynamicsFeature = "exposedFoundations";
const CompactedBed: UrsChannelDynamicsFeature = "compactedBed";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsChannelDynamicsFeatureEnum = {
  ...createEnum("UrsChannelDynamicsFeature", [
    [BuriedSoil, "Buried soil within bank profile"],
    [FineSediment, "Burial of river bed with fine sediment"],
    [StructureBases, "Burial of base of structures (e.g. bridge piers)"],
    [VegetationBases, "Burial of base of established vegetation (e.g. trees)"],
    [NarrowChannel, "River channel narrow relative to bridge openings"],
    [BedSediment, "Bed sediment exposed within bank profile"],
    [
      ExposedRoots,
      "Trees with exposed roots / collapsing / leaning into channel on both banks",
    ],
    [
      ExposedFoundations,
      "Exposure of foundations of structures (e.g. bridge piers)",
    ],
    [CompactedBed, "Heavily compacted and armoured bed"],
  ]),
  BuriedSoil,
  FineSediment,
  StructureBases,
  VegetationBases,
  NarrowChannel,
  BedSediment,
  ExposedRoots,
  ExposedFoundations,
  CompactedBed,
};

export default UrsChannelDynamicsFeatureEnum;
