// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type OutfallSafariBank = "Left" | "Right";

const Left: OutfallSafariBank = "Left";
const Right: OutfallSafariBank = "Right";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OutfallSafariBankEnum = {
  ...createEnum("OutfallSafariBank", [
    [Left, "Left"],
    [Right, "Right"],
  ]),
  Left,
  Right,
};

export default OutfallSafariBankEnum;
