// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeYesNoUncertain = "Y" | "N" | "U";

const Yes: NeYesNoUncertain = "Y";
const No: NeYesNoUncertain = "N";
const Uncertain: NeYesNoUncertain = "U";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeYesNoUncertainEnum = {
  ...createEnum("NeYesNoUncertain", [
    [Yes, "Yes"],
    [No, "No"],
    [Uncertain, "Uncertain"],
  ]),
  Yes,
  No,
  Uncertain,
};

export default NeYesNoUncertainEnum;
