// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPondCountWhyAbsent = "L" | "M";

const LostOrDestroyed: FhtPondCountWhyAbsent = "L";
const Misidentified: FhtPondCountWhyAbsent = "M";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPondCountWhyAbsentEnum = {
  ...createEnum("FhtPondCountWhyAbsent", [
    [LostOrDestroyed, "Pond lost or destroyed"],
    [Misidentified, "Other feature misidentified"],
  ]),
  LostOrDestroyed,
  Misidentified,
};

export default FhtPondCountWhyAbsentEnum;
