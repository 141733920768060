import { unsafeMapId } from "@cartographerio/topo-map";
import { unsafeProjectAlias } from "@cartographerio/types";
import { mapSchemaWithDefaults } from "../../core";
import { urbanRiverflyLayer } from "./helpers";

export const UrbanRiverfly = mapSchemaWithDefaults({
  mapId: unsafeMapId("urbanRiverfly"),
  title: "Urban Riverfly",
  layers: [
    urbanRiverflyLayer(
      [
        unsafeProjectAlias("urbanriverfly"),
        unsafeProjectAlias("extendedriverfly"),
      ],
      "Urban Riverfly"
    ),
  ],
});
