import {
  BlockQuote,
  Code,
  CodeBlock,
  CustomBlock,
  CustomInline,
  Document,
  Emph,
  Heading,
  HtmlBlock,
  HtmlInline,
  Image,
  Item,
  LineBreak,
  Link,
  List,
  Node,
  Paragraph,
  SoftBreak,
  Strong,
  Text,
  ThematicBreak,
} from "./type";

export function text(text: string): Text {
  return { type: "Text", text };
}

export const softBreak: SoftBreak = {
  type: "SoftBreak",
};

export const lineBreak: LineBreak = {
  type: "LineBreak",
};

export function emph(children: Node[]): Emph {
  return { type: "Emph", children };
}

export function strong(children: Node[]): Strong {
  return { type: "Strong", children };
}

export function htmlInline(html: string): HtmlInline {
  return { type: "HtmlInline", html };
}

export function link(href: string, children: Node[]): Link {
  return { type: "Link", href, children };
}

export function image(src: string, children: Node[]): Image {
  return { type: "Image", src, children };
}

export function code(code: string): Code {
  return { type: "Code", code };
}

export function document(children: Node[]): Document {
  return { type: "Document", children };
}

export function paragraph(children: Node[]): Paragraph {
  return { type: "Paragraph", children };
}

export function blockQuote(children: Node[]): BlockQuote {
  return { type: "BlockQuote", children };
}

export function item(children: Node[]): Item {
  return { type: "Item", children };
}

export function list(
  listType: "bullet" | "ordered",
  listDelimiter: ")" | "." | null,
  listStart: number | null,
  listTight: boolean,
  children: Node[]
): List {
  return {
    type: "List",
    listType,
    listDelimiter,
    listStart,
    listTight,
    children,
  };
}

export function heading(level: number, children: Node[]): Heading {
  return { type: "Heading", level, children };
}

export function codeBlock(info: string | null, code: string): CodeBlock {
  return { type: "CodeBlock", info, code };
}

export function htmlBlock(html: string): HtmlBlock {
  return { type: "HtmlBlock", html };
}

export const thematicBreak: ThematicBreak = {
  type: "ThematicBreak",
};

export function customInline(text: string): CustomInline {
  return { type: "CustomInline", text };
}

export function customBlock(text: string): CustomBlock {
  return { type: "CustomBlock", text };
}
