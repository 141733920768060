// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeLakeNaturalnessRiparianZone =
  | "AllSemiNatural"
  | "NearlyAllSemiNatural"
  | "OverTwoThirdsSemiNatural"
  | "OverOneThirdSemiNatural"
  | "UnderOneThirdSemiNatural";

const AllSemiNatural: NeLakeNaturalnessRiparianZone = "AllSemiNatural";
const NearlyAllSemiNatural: NeLakeNaturalnessRiparianZone =
  "NearlyAllSemiNatural";
const OverTwoThirdsSemiNatural: NeLakeNaturalnessRiparianZone =
  "OverTwoThirdsSemiNatural";
const OverOneThirdSemiNatural: NeLakeNaturalnessRiparianZone =
  "OverOneThirdSemiNatural";
const UnderOneThirdSemiNatural: NeLakeNaturalnessRiparianZone =
  "UnderOneThirdSemiNatural";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeLakeNaturalnessRiparianZoneEnum = {
  ...createEnum("NeLakeNaturalnessRiparianZone", [
    [AllSemiNatural, "All semi-natural land use"],
    [
      NearlyAllSemiNatural,
      "Nearly all semi-natural land use (patches of other use)",
    ],
    [OverTwoThirdsSemiNatural, ">66% semi-natural land use"],
    [OverOneThirdSemiNatural, "33-66% semi-natural land use"],
    [UnderOneThirdSemiNatural, "<33% semi-natural land use"],
  ]),
  AllSemiNatural,
  NearlyAllSemiNatural,
  OverTwoThirdsSemiNatural,
  OverOneThirdSemiNatural,
  UnderOneThirdSemiNatural,
};

export default NeLakeNaturalnessRiparianZoneEnum;
