// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesFringeWidth = "N" | "M" | "W";

const Narrow: CesFringeWidth = "N";
const Medium: CesFringeWidth = "M";
const Wide: CesFringeWidth = "W";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesFringeWidthEnum = {
  ...createEnum("CesFringeWidth", [
    [Narrow, "0m"],
    [Medium, ">0-1.5m"],
    [Wide, ">1.5m"],
  ]),
  Narrow,
  Medium,
  Wide,
};

export default CesFringeWidthEnum;
