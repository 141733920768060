// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesJettySize = "1" | "2" | "3";

const Largest: CesJettySize = "1";
const Second: CesJettySize = "2";
const Third: CesJettySize = "3";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesJettySizeEnum = {
  ...createEnum("CesJettySize", [
    [Largest, "Largest jetty/pontoon"],
    [Second, "Second jetty/pontoon"],
    [Third, "Third jetty/pontoon"],
  ]),
  Largest,
  Second,
  Third,
};

export default CesJettySizeEnum;
