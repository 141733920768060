/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { Guard } from "@cartographerio/guard";
import { Result } from "./Result";

export type OptionLike<A> = A | Option<A>;

type AnyOptionTuple = readonly Option<unknown>[];

type UnpackOptionTuple<A extends AnyOptionTuple> = {
  [K in keyof A]: A[K] extends Option<infer T> ? T : A[K];
};

/** Data type representing a nullable value.
 * Provides methods allowing you to safely chain operations
 * without worrying about nullability.
 *
 * For example, the following code would
 * return `-1` if `value`` is `null` or `(value+1)*2` otherwise:
 *
 * ```
 * const value: number | null | undefined = ...;
 *
 * const result: number = Option.wrap(value)
 *   .chain(n => n + 1)
 *   .chain(n => n * 2)
 *   .getOrElse(-1);
 * ```
 */
export interface Option<A> {
  /** Call `func` on the value in this `Option`
   * and return an `Option` of the result.
   * If this `Option` is empty, this is a noop.
   *
   * `func` can return a plain value or an `Option`.
   */
  chain<B>(func: (a: A) => OptionLike<B>): Option<B>;

  /** Call `func` on the value in this `Option`
   * and return an `Option` of the result.
   * If this `Option` is empty, this is a noop.
   *
   * `func` must return a plain value.
   */
  map<B>(func: (a: A) => B): Option<B>;

  /** Call `func` on the value in this `Option`
   * and return an `Option` of the result.
   * If this `Option` is empty, this is a noop.
   *
   * `func` can return a plain value, `null`, or `undefined`.
   */
  nullMap<B>(func: (a: A) => B | null | undefined): Option<B>;

  /** Call `func` on the value in this `Option`
   * and return an `Option` of the result.
   * If this `Option` is empty, this is a noop.
   *
   * `func` must return an `Option`.
   */
  flatMap<B>(func: (a: A) => Option<B>): Option<B>;

  /** Access the value in this `Option` for some side-effect
   * (e.g. to print it out), but don't affect the value.
   */
  tap(func: (a: A) => unknown): Option<A>;

  /** "Filter" this `Option` using the supplied `func` and return the result.
   * If `func` returns `true` the result is the same as this `Option`.
   * If `func` returns `false` the result is an empty `Option`.
   */
  filter(func: (a: A) => boolean): Option<A>;

  /** Like `filter` but the predicate is negated. */
  filterNot(func: (a: A) => boolean): Option<A>;

  /** "Filter" this `Option` using the supplied `guard` function and return the result.
   * If `func` returns `true` the result is the same as this `Option`.
   * If `func` returns `false` the result is an empty `Option`.
   */
  guard<B>(guard: Guard<B>): Option<B>;

  /** Call `ifNone` or `ifSome` as appropriate. Return the result. */
  fold<R>(ifNone: () => R, ifSome: (a: A) => R): R;

  /** Run `func` if this `Option` contains a value. Otherwise do nothing. */
  forEach(func: (a: A) => void): void;

  /** Returns `true` iff this `Option` contains a value. */
  isSome(): boolean;

  /** Returns `true` iff this `Option` is empty. */
  isNone(): boolean;

  /** Converts this `Option` to a successful `Result`.
   * Returns a `Failure` containing `error` if this `Option` is empty.
   */
  toSuccess<E>(error: () => E): Result<E, A>;

  /** Converts this `Option` to a failure `Result`.
   * Returns a `Success` containing `value` if this `Option` is empty.
   */
  toFailure<X>(value: () => X): Result<A, X>;

  /** Returns an array of length 0 or 1. */
  toArray(): A[];

  /** Returns the value in this `Option` or throws an exception. */
  unsafeGet(): A;

  /** Returns this `Option` if it is non-empty.
   * Otherwise calls `func()` and returns the result.
   */
  orElse<B>(func: () => Option<B>): Option<A | B>;

  /** Returns this `Option` if it is non-empty.
   * Otherwise calls `func()` and wraps the result with Option.wrap.
   */
  orElseNullable<B>(func: () => B | null | undefined): Option<A | B>;

  /** Returns the value in this `Option`, or the result of `func` if this is empty. */
  getOrElse<B>(func: () => B): A | B;

  /** Returns the value in this `Option`, or throws the error returned by `func` if this is empty. */
  getOrThrow(_error: () => unknown): A;

  /** Returns the value in this `Option`, or `null` if it is empty. */
  getOrNull(): A | null;

  /** Returns the value in this `Option`, or `undefined` if it is empty. */
  getOrUndefined(): A | undefined;
}

export class Some<A> implements Option<A> {
  _value: A;

  constructor(value: A) {
    this._value = value;
  }

  chain = <B>(func: (a: A) => OptionLike<B>): Option<B> => {
    const b = func(this._value);

    if (b instanceof Some || b instanceof None) {
      return b;
    } else {
      return new Some(b as B);
    }
  };

  map = <B>(func: (a: A) => B): Option<B> => {
    return new Some(func(this._value));
  };

  nullMap = <B>(func: (a: A) => B | null | undefined): Option<B> => {
    const b = func(this._value);
    return b == null ? new None<B>() : new Some<B>(b);
  };

  flatMap = <B>(func: (a: A) => Option<B>): Option<B> => {
    return func(this._value);
  };

  tap = (func: (a: A) => unknown): Option<A> => {
    return this.map(a => {
      func(a);
      return a;
    });
  };

  filter = (func: (a: A) => boolean): Option<A> => {
    return func(this._value) ? this : new None();
  };

  filterNot = (func: (a: A) => boolean): Option<A> => {
    return func(this._value) ? new None() : this;
  };

  guard = <B>(func: Guard<B>): Option<B> => {
    return func(this._value) ? Option.some(this._value) : Option.none();
  };

  fold = <R>(_ifNone: () => R, ifSome: (a: A) => R): R => {
    return ifSome(this._value);
  };

  forEach = (func: (a: A) => void): void => {
    func(this._value);
  };

  isSome = (): boolean => {
    return true;
  };

  isNone = (): boolean => {
    return false;
  };

  toSuccess = <E>(_error: () => E): Result<E, A> => {
    return Result.pure(this._value);
  };

  toFailure = <X>(_value: () => X): Result<A, X> => {
    return Result.fail(this._value);
  };

  toArray = (): A[] => {
    return [this._value];
  };

  unsafeGet = (): A => {
    return this._value;
  };

  orElse = <B>(_func: () => Option<B>): Option<A | B> => {
    return this;
  };

  orElseNullable = <B>(_func: () => B | null | undefined): Option<A | B> => {
    return this;
  };

  getOrElse = <B>(_func: () => B): A | B => {
    return this._value;
  };

  getOrThrow = (_error: () => unknown): A => {
    return this._value;
  };

  getOrNull = (): A | null => {
    return this._value;
  };

  getOrUndefined = (): A | undefined => {
    return this._value;
  };
}

export class None<A> implements Option<A> {
  chain = <B>(_func: (a: A) => OptionLike<B>): Option<B> => {
    return new None<B>();
  };

  map = <B>(_func: (a: A) => B): Option<B> => {
    return new None<B>();
  };

  nullMap = <B>(_func: (a: A) => B | null | undefined): Option<B> => {
    return new None<B>();
  };

  flatMap = <B>(_func: (a: A) => Option<B>): Option<B> => {
    return new None<B>();
  };

  tap = (_func: (a: A) => unknown): Option<A> => {
    return this;
  };

  filter = (_func: (a: A) => boolean): Option<A> => {
    return this;
  };

  filterNot = (_func: (a: A) => boolean): Option<A> => {
    return this;
  };

  guard = <B>(_func: Guard<B>): Option<B> => {
    return Option.none();
  };

  fold = <R>(ifNone: () => R, _ifSome: (a: A) => R): R => {
    return ifNone();
  };

  forEach = (_func: (a: A) => void): void => {
    return;
  };

  isSome = (): boolean => {
    return false;
  };

  isNone = (): boolean => {
    return true;
  };

  toSuccess = <E>(error: () => E): Result<E, A> => {
    return Result.fail(error());
  };

  toFailure = <X>(value: () => X): Result<A, X> => {
    return Result.pure(value());
  };

  toArray = (): A[] => {
    return [];
  };

  unsafeGet = (): A => {
    throw new Error("Called unsafeGet on a None");
  };

  orElse = <B>(func: () => Option<B>): Option<A | B> => {
    return func();
  };

  orElseNullable = <B>(func: () => B | null | undefined): Option<A | B> => {
    return Option.wrap(func());
  };

  getOrElse = <B>(func: () => B): A | B => {
    return func();
  };

  getOrThrow = (error: () => unknown): A => {
    throw error();
  };

  getOrNull = (): A | null => {
    return null;
  };

  getOrUndefined = (): A | undefined => {
    return undefined;
  };
}

/** Create an `Option` containing the supplied `value`. */
function some<A>(value: A): Option<A> {
  return new Some(value);
}

const _none = new None();

/** Create an empty `Option`. */
function none<A>(): Option<A> {
  return _none as Option<A>;
}

/** "Wrap" a nullable `value` to create an `Option`.
 * The result contains `value` unless it is `null` or `undefined`,
 * in which case the result is empty.
 */
function wrap<A>(value: A | null | undefined): Option<A> {
  return value == null ? new None() : new Some(value);
}

function cond<A>(cond: boolean) {
  return function (value: () => A): Option<A> {
    return cond ? Option.some(value()) : Option.none();
  };
}

function tupled<const A extends AnyOptionTuple>(
  opts: A
): Option<UnpackOptionTuple<A>> {
  const values: unknown[] = [];

  for (const opt of opts) {
    if (opt instanceof Some) {
      values.push(opt._value);
    } else {
      return none();
    }
  }

  return some(values as UnpackOptionTuple<A>);
}

function when<const A extends AnyOptionTuple>(
  ...opts: A
): <R>(func: (...values: UnpackOptionTuple<A>) => R) => Option<R> {
  const option = tupled(opts);
  return func => option.map(values => func(...values));
}

function sequence<A>(options: Option<A>[]): Option<A[]> {
  const ans: A[] = [];

  for (const opt of options) {
    if (opt instanceof Some) {
      ans.push(opt._value);
    } else if (opt instanceof None) {
      return none();
    } else {
      throw new Error("Unknown Option type: " + opt);
    }
  }

  return some(ans);
}

function traverse<A>(as: A[]) {
  return function <B>(func: (a: A) => Option<B>): Option<B[]> {
    const ans: B[] = [];

    for (const a of as) {
      const opt = func(a);

      if (opt instanceof Some) {
        ans.push(opt._value);
      } else if (opt instanceof None) {
        return none();
      } else {
        throw new Error("Unknown Option type: " + opt);
      }
    }

    return some(ans);
  };
}

export const Option = {
  some,
  none,
  wrap,
  cond,
  when,
  tupled,
  sequence,
  traverse,
};

export function isOption<A>(isA: (value: unknown) => value is A) {
  return function (value: unknown): value is Option<A> {
    if (value instanceof Some) {
      return isA(value._value);
    } else if (value instanceof None) {
      return true;
    } else {
      return false;
    }
  };
}

/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-explicit-any */
