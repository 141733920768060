import { Result } from "@cartographerio/fp";
import { GeometryAtom } from "@cartographerio/geometry";
import { guardError, GuardError } from "@cartographerio/guard";
import {
  blankSurvey,
  defaultBlankSurveyOpts,
  Form,
} from "@cartographerio/topo-form";
import { Schema, surveySchema, typeCheck } from "@cartographerio/topo-survey";
import {
  MapId,
  MapLayerId,
  Message,
  nowTimestamp,
  Project,
  ProjectId,
  randomSurveyId,
  Survey,
  SurveyId,
  SurveyModuleId,
  SurveyNames,
  SurveyStatus,
  SurveyStatusEnum,
  SurveySummary,
  Team,
  TeamId,
  Timestamp,
  unsafeMapId,
  unsafeMapLayerId,
  UserRef,
  Workspace,
} from "@cartographerio/types";
import { SurveyModuleParts } from "./parts";

export interface BlankOpts {
  id?: SurveyId;
  projectId: ProjectId;
  teamId?: TeamId | null;
  surveyor: UserRef;
  timestamp?: Timestamp;
}

export interface CopyOpts {
  id?: SurveyId;
  projectId?: ProjectId;
  teamId?: TeamId | null;
  surveyor?: UserRef;
  status?: SurveyStatus;
  timestamp?: Timestamp;
}

export interface MapLink {
  mapId: MapId;
  layerId: MapLayerId;
  attributeId: string;
  label: string;
}

function defaultMapLinks(moduleId: SurveyModuleId, label: string): MapLink[] {
  return [
    {
      label,
      mapId: unsafeMapId(moduleId),
      layerId: unsafeMapLayerId(moduleId),
      attributeId: "surveyId",
    },
  ];
}

export class SurveyModule {
  moduleId: SurveyModuleId;

  names: SurveyNames;

  /** Form layout for this module. */
  formSchema: Form;

  /** Schema describing the `data` field of surveys belonging to this module. */
  dataSchema: Schema;

  private dataDescription: (data: unknown) => Result<GuardError, string>;

  private dataGeometry: (
    data: unknown
  ) => Result<GuardError, GeometryAtom | null>;

  private dataTimestamp: (
    data: unknown
  ) => Result<GuardError, Timestamp | null>;

  private copyData?: (data: unknown) => Result<GuardError, unknown>;

  mapLinks: MapLink[];

  constructor(parts: SurveyModuleParts) {
    this.moduleId = parts.moduleId;
    this.names = parts.names;
    this.formSchema = parts.formSchema;
    this.dataSchema = parts.dataSchema;
    this.dataDescription = parts.dataDescription;
    this.dataGeometry = parts.dataGeometry;
    this.dataTimestamp = parts.dataTimestamp;
    this.copyData = parts.copyData;
    this.mapLinks =
      parts.mapLinks ?? defaultMapLinks(parts.moduleId, parts.names.shortName);
  }

  /** Schema describing a survey belonging to this module (incorporates `dataSchema` above). */
  surveySchema = (): Schema => surveySchema(this.dataSchema);

  /** Return a human-readable string describing `survey`. Used as a labelin, for example, a list of surveys. */
  description = (survey: Survey): Result<GuardError, string> =>
    this.dataDescription(survey.data);

  /**
   * Return a GeoJSON geometry value representing the location of this survey.
   * This can be any single geometry value (point, line, etc) but not a geometry collection.
   */
  geometry = (survey: Survey): Result<GuardError, GeometryAtom | null> =>
    this.dataGeometry(survey.data);

  /** Return a `Timestamp` representing the date/time the survey data was gathered by a surveyor. */
  timestamp = (survey: Survey): Result<GuardError, Timestamp | null> =>
    this.dataTimestamp(survey.data);

  /** Create a blank survey using survey and form schemas. */
  blank = (opts: BlankOpts): Result<GuardError, Survey> => {
    const { id, projectId, teamId = null, surveyor, timestamp } = opts;

    return blankSurvey(
      this.formSchema,
      surveySchema(this.dataSchema),
      this.moduleId,
      projectId,
      teamId ?? null,
      {
        ...defaultBlankSurveyOpts,
        id,
        surveyor,
        created: timestamp,
        updated: timestamp,
      }
    ).mapError(message =>
      guardError(
        `Could not initialise survey from form: ${JSON.stringify(message)}`,
        this.moduleId,
        null
      )
    );
  };

  /** Return a new survey with the current data copied over. */
  copy = (survey: Survey, opts: CopyOpts): Result<GuardError, Survey> => {
    const {
      id = randomSurveyId(),
      projectId = survey.projectId,
      teamId = survey.teamId ?? null,
      surveyor = survey.surveyor,
      status = SurveyStatusEnum.Draft,
      timestamp = nowTimestamp(),
    } = opts;

    const base = {
      ...survey,
      id,
      projectId,
      teamId,
      surveyor,
      status,
      created: timestamp,
      updated: timestamp,
    };

    return this.copyData == null
      ? Result.pass(base)
      : this.copyData(base.data).map(data => ({ ...base, data }));
  };

  summary = (
    survey: Survey,
    workspace: Workspace,
    project: Project,
    team: Team | null
  ): Result<GuardError, SurveySummary> => {
    const params = Result.tupled([
      this.dataGeometry(survey.data),
      this.dataTimestamp(survey.data),
      this.dataDescription(survey.data),
    ]);

    return params.map(([geometry, timestamp, description]) => ({
      ...survey,
      data: {
        geometry,
        timestamp,
        workspaceName: workspace.name,
        projectName: project.name,
        teamName: team?.name || null,
        description,
      },
    }));
  };

  typeCheck = (survey: Survey): Message[] =>
    typeCheck(surveySchema(this.dataSchema), survey);
}
