// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessBryophyteSpecies =
  | "FontinalisSp"
  | "FissidensSp"
  | "PelliaSp"
  | "SphagnumSp";

const FontinalisSp: NeRiverNaturalnessBryophyteSpecies = "FontinalisSp";
const FissidensSp: NeRiverNaturalnessBryophyteSpecies = "FissidensSp";
const PelliaSp: NeRiverNaturalnessBryophyteSpecies = "PelliaSp";
const SphagnumSp: NeRiverNaturalnessBryophyteSpecies = "SphagnumSp";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessBryophyteSpeciesEnum = {
  ...createEnum("NeRiverNaturalnessBryophyteSpecies", [
    [FontinalisSp, "Fontinalis sp (Mosses)"],
    [FissidensSp, "Fissidens sp (Mosses)"],
    [PelliaSp, "Pellia sp (Thallose liverworts)"],
    [SphagnumSp, "Sphagnum sp"],
  ]),
  FontinalisSp,
  FissidensSp,
  PelliaSp,
  SphagnumSp,
};

export default NeRiverNaturalnessBryophyteSpeciesEnum;
