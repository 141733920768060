// From https://developer.mozilla.org/en-US/docs/Web/HTTP/Status

export const OK = 200;
// export const Created = 201;
// export const Accepted = 202;
export const BadRequest = 400;
export const Unauthorized = 401;
export const Forbidden = 403;
export const NotFound = 404;
export const Conflict = 409;
export const InternalServerError = 500;

export function is1xx(status: number): boolean {
  return status <= 199;
}

export function is2xx(status: number): boolean {
  return status >= 200 && status <= 299;
}

export function is3xx(status: number): boolean {
  return status >= 300 && status <= 399;
}

export function is4xx(status: number): boolean {
  return status >= 400 && status <= 499;
}

export function is5xx(status: number): boolean {
  return status >= 500;
}
