import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  BannerMessage,
  isBannerMessage,
  WorkspaceRef,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";

const basePath = "/workspace/banner/v1";

export function readOrFail(
  apiParams: ApiParams,
  workspace: WorkspaceRef
): IO<BannerMessage> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${workspace}` } })
    .flatMap(contentAs("BannerMessage", isBannerMessage));
}

export function readOption(
  apiParams: ApiParams,
  workspace: WorkspaceRef
): IO<Option<BannerMessage>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${workspace}` } })
    .flatMap(optionalContentAs("BannerMessage", isBannerMessage));
}

export function save(
  apiParams: ApiParams,
  ref: WorkspaceRef,
  banner: BannerMessage
): IO<void> {
  return fetch
    .put({
      apiParams,
      url: { path: `${basePath}/${ref}` },
      body: banner,
    })
    .void();
}

export function remove(apiParams: ApiParams, ref: WorkspaceRef): IO<void> {
  return fetch
    .remove({ apiParams, url: { path: `${basePath}/${ref}` } })
    .void();
}
