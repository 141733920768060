import {
  Identity,
  Message,
  Project,
  Survey,
  UserRef,
} from "@cartographerio/types";
import { UserWorkspaceGraph } from "@cartographerio/workspace-graph";

export type Rule = (survey: Survey) => Message[];

export type RuleBuilder = (options: {
  identity: Identity;
  graph: UserWorkspaceGraph;
  project: Project;
}) => Rule;

export function differentSurveyor(
  identity: Identity,
  surveyor: UserRef | null
): boolean {
  return identity.userId !== surveyor?.userId;
}
