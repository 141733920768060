import { checkExhausted } from "@cartographerio/util";
import {
  AttachmentAttribute,
  Attribute,
  BucketedAttribute,
  FeatureAttribute,
  PropertyAttribute,
  SurveyAttribute,
  TeamAttribute,
} from "./type";

export function isFeatureAttribute(attr: Attribute): attr is FeatureAttribute {
  switch (attr.type) {
    case "FeatureAttribute":
      return true;
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "SurveyAttribute":
    case "TeamAttribute":
    case "AttachmentAttribute":
      return false;
    default:
      return checkExhausted(attr);
  }
}

export function isBucketedAttribute(
  attr: Attribute
): attr is BucketedAttribute {
  switch (attr.type) {
    case "FeatureAttribute":
      return false;
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
      return true;
    case "SurveyAttribute":
    case "TeamAttribute":
    case "AttachmentAttribute":
      return false;
    default:
      return checkExhausted(attr);
  }
}
export function isPropertyAttribute(
  attr: Attribute
): attr is PropertyAttribute {
  switch (attr.type) {
    case "FeatureAttribute":
      return false;
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "SurveyAttribute":
    case "TeamAttribute":
      return true;
    case "AttachmentAttribute":
      return false;
    default:
      return checkExhausted(attr);
  }
}

export function isSurveyAttribute(attr: Attribute): attr is SurveyAttribute {
  switch (attr.type) {
    case "FeatureAttribute":
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
      return false;
    case "SurveyAttribute":
      return true;
    case "TeamAttribute":
    case "AttachmentAttribute":
      return false;
    default:
      return checkExhausted(attr);
  }
}

export function isTeamAttribute(attr: Attribute): attr is TeamAttribute {
  switch (attr.type) {
    case "FeatureAttribute":
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "SurveyAttribute":
      return false;
    case "TeamAttribute":
      return true;
    case "AttachmentAttribute":
      return false;
    default:
      return checkExhausted(attr);
  }
}

export function isAttachmentAttribute(
  attr: Attribute
): attr is AttachmentAttribute {
  switch (attr.type) {
    case "FeatureAttribute":
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "SurveyAttribute":
    case "TeamAttribute":
      return false;
    case "AttachmentAttribute":
      return true;
    default:
      return checkExhausted(attr);
  }
}
