import { Option, Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  AccessToken,
  ApiConfig,
  ApiParams,
  AuthError,
  CheckResetPasswordTokenResponse,
  CheckSigninEmailResponse,
  CheckWorkspaceAccessResult,
  Credentials,
  Email,
  ForgotPasswordRequest,
  Message,
  ResetPasswordRequest,
  ResetPasswordResult,
  ResetPasswordToken,
  SearchResults,
  SigninRequest,
  SigninResponse,
  UserId,
  WorkspaceRef,
  checkedToResult,
  expiredAuthorizationError,
  isAuthError,
  isCheckResetPasswordTokenResponse,
  isCheckSigninEmailResponse,
  isCheckWorkspaceAccessResult,
  isChecked,
  isCredentials,
  isNotFoundError,
  isResetPasswordResult,
  isSearchResults,
  isSigninResponse,
  nowTimestamp,
} from "@cartographerio/types";
import * as fetch from "../../fetch";
import {
  contentAs,
  optionalContentAs,
  recoverUnknownErrors,
  validationErrorsToChecked,
} from "../../response";
import { UrlParts } from "../../url";

const basePath = "/auth/v2";

export function signin(
  apiConfig: ApiConfig,
  body: SigninRequest
): IO<Result<Message[], SigninResponse>> {
  const url: UrlParts = { path: `${basePath}/login` };
  return fetch
    .post({ apiParams: { apiConfig }, url, body })
    .map(
      recoverUnknownErrors({
        400: isSigninResponse,
        401: isSigninResponse,
        403: isSigninResponse,
        404: isSigninResponse,
      })
    )
    .map(validationErrorsToChecked)
    .flatMap(contentAs("Checked<SigninResponse>", isChecked(isSigninResponse)))
    .map(checkedToResult);
}

export function readOrFail(
  apiConfig: ApiConfig,
  token: AccessToken
): IO<Credentials> {
  return (
    fetch
      .get({ apiParams: { apiConfig }, url: { path: `${basePath}/${token}` } })
      .flatMap(contentAs("Credentials", isCredentials))
      // The auth read endpoint reports missing session tokens as 404s.
      // Here we turn them into missing auth errors like other endpoints:
      .partialRecover(isNotFoundError, error =>
        IO.fail(expiredAuthorizationError(error.timestamp ?? undefined))
      )
  );
}

export function readOption(
  apiConfig: ApiConfig,
  token: AccessToken
): IO<Option<Credentials>> {
  return fetch
    .get({ apiParams: { apiConfig }, url: { path: `${basePath}/${token}` } })
    .flatMap(optionalContentAs("Credentials", isCredentials));
}

export function readAttempt(
  apiConfig: ApiConfig,
  token: AccessToken
): IO<Result<AuthError, Credentials>> {
  return (
    readOption(apiConfig, token)
      // The auth read endpoint reports missing session tokens as 404s.
      // We turn them into missing auth errors like other endpoints:
      .map(option =>
        option.toSuccess<AuthError>(() =>
          expiredAuthorizationError(nowTimestamp())
        )
      )
      .partialRecover(isAuthError, error =>
        Result.fail<AuthError, Credentials>(error)
      )
  );
}

export function forgotPassword(
  apiConfig: ApiConfig,
  request: ForgotPasswordRequest
): IO<void> {
  return fetch
    .post({
      apiParams: { apiConfig },
      url: { path: `${basePath}/forgot-password` },
      body: request,
    })
    .void();
}

export function checkSigninEmail(
  apiConfig: ApiConfig,
  email: Email
): IO<CheckSigninEmailResponse> {
  return fetch
    .get({
      apiParams: { apiConfig },
      url: { path: `${basePath}/check-signin-email/${email}` },
    })
    .flatMap(contentAs("CheckSigninEmailResponse", isCheckSigninEmailResponse));
}

export function listWorkspaceAccess(
  apiParams: ApiParams
): IO<SearchResults<CheckWorkspaceAccessResult>> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/check-workspace-access` },
    })
    .flatMap(
      contentAs(
        "SearchResults<CheckWorkspaceAccessResult>",
        isSearchResults(isCheckWorkspaceAccessResult)
      )
    );
}

export function readWorkspaceAccess(
  apiParams: ApiParams,
  workspace: WorkspaceRef
): IO<CheckWorkspaceAccessResult> {
  return fetch
    .get({
      apiParams,
      url: { path: `${basePath}/check-workspace-access/${workspace}` },
    })
    .flatMap(
      contentAs("CheckWorkspaceAccessResult", isCheckWorkspaceAccessResult)
    );
}

export function checkPasswordToken(
  apiConfig: ApiConfig,
  token: ResetPasswordToken
): IO<CheckResetPasswordTokenResponse> {
  return fetch
    .get({
      apiParams: { apiConfig },
      url: { path: `${basePath}/check-password-token/${token}` },
    })
    .flatMap(
      contentAs(
        "CheckResetPasswordTokenResponse",
        isCheckResetPasswordTokenResponse
      )
    );
}

export function resetPassword(
  apiConfig: ApiConfig,
  request: ResetPasswordRequest
): IO<ResetPasswordResult> {
  return fetch
    .post({
      apiParams: { apiConfig },
      url: { path: `${basePath}/reset-password` },
      body: request,
    })
    .flatMap(contentAs("ResetPasswordResult", isResetPasswordResult));
}

export function changeIdentity(
  apiParams: ApiParams,
  userId: UserId
): IO<SigninResponse> {
  return fetch
    .post({
      apiParams,
      url: { path: `${basePath}/change-identity` },
      body: { userId },
    })
    .flatMap(contentAs("SigninResponse", isSigninResponse));
}

export function sendWelcomeEmail(
  apiParams: ApiParams,
  email: Email,
  workspace?: WorkspaceRef
): IO<void> {
  return fetch
    .post({
      apiParams,
      url: { path: `${basePath}/welcome-email` },
      body: { workspace, email },
    })
    .void();
}
