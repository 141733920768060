import {
  LineLayerSchema as LineLayer,
  PointLayerSchema as PointLayer,
  PolygonLayerSchema as PolygonLayer,
} from "./type";

export function pointLayer(props: Omit<PointLayer, "type">): PointLayer {
  return {
    type: "PointLayer",
    ...props,
  };
}

export function lineLayer(props: Omit<LineLayer, "type">): LineLayer {
  return {
    type: "LineLayer",
    ...props,
  };
}

export function polygonLayer(props: Omit<PolygonLayer, "type">): PolygonLayer {
  return {
    type: "PolygonLayer",
    ...props,
  };
}
