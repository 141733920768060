import { GeometryAtomType } from "@cartographerio/geometry";
import { Enum } from "@cartographerio/util";
import { GeometrySchema, ProductSchema, Schema } from "./type";

export const booleanSchema: Schema = { type: "Boolean" };

export const integerSchema: Schema = { type: "Integer" };

export const numberSchema: Schema = { type: "Number" };

export const stringSchema: Schema = { type: "String" };

export const uuidSchema: Schema = { type: "Uuid" };

export const timestampSchema: Schema = { type: "Timestamp" };

export function geometrySchema(
  geometryType?: GeometryAtomType
): GeometrySchema {
  return geometryType == null
    ? { type: "Geometry" }
    : { type: "Geometry", geometryType };
}

export const pointSchema: GeometrySchema = geometrySchema("Point");

export const unknownSchema: Schema = { type: "Unknown" };

export function enumSchema<A extends string>(source: Enum<A> | A[]): Schema {
  const values = Array.isArray(source) ? source : source.values;

  return {
    type: "Enum",
    values,
  };
}

export function nullableSchema(param: Schema): Schema {
  return { type: "Nullable", param };
}

export function arraySchema(param: Schema): Schema {
  return { type: "Array", param };
}

export function tupleSchema(items: Schema[]): Schema {
  return { type: "Tuple", items };
}

export function dictSchema(key: Schema, value: Schema): Schema {
  return { type: "Dict", key, value };
}

interface DictOfSchemas {
  [name: string]: Schema;
}

export function productSchema(fields: DictOfSchemas): ProductSchema {
  return { type: "Product", fields };
}

interface DictOfProducts {
  [name: string]: ProductSchema;
}

export function sumSchema(products: DictOfProducts): Schema {
  return { type: "Sum", products };
}
