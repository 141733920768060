// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsReinforcementType =
  | "CC"
  | "CB"
  | "BR"
  | "SP"
  | "WP"
  | "BW"
  | "RR"
  | "GA"
  | "WS"
  | "RE"
  | "BC"
  | "WO";

const Concrete: MrsReinforcementType = "CC";
const ConcreteAndBrick: MrsReinforcementType = "CB";
const BrickOrLaidStone: MrsReinforcementType = "BR";
const SheetPiling: MrsReinforcementType = "SP";
const WoodPiling: MrsReinforcementType = "WP";
const BuildersWaste: MrsReinforcementType = "BW";
const RipRap: MrsReinforcementType = "RR";
const Gabions: MrsReinforcementType = "GA";
const WillowSpiling: MrsReinforcementType = "WS";
const PlantedReeds: MrsReinforcementType = "RE";
const BiotexOrCoirOrBiotex: MrsReinforcementType = "BC";
const WashedOutReinforcement: MrsReinforcementType = "WO";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsReinforcementTypeEnum = {
  ...createEnum("MrsReinforcementType", [
    [Concrete, "Concrete"],
    [ConcreteAndBrick, "Concrete & brick / laid stone (cemented)"],
    [BrickOrLaidStone, "Brick / laid stone (cemented)"],
    [SheetPiling, "Sheet piling"],
    [WoodPiling, "Wood piling / panels"],
    [BuildersWaste, "Builders waste / hard core (tipped)"],
    [RipRap, "Rip-rap (large laid stone, uncemented)"],
    [Gabions, "Gabions / rock roll"],
    [WillowSpiling, "Willow spiling"],
    [PlantedReeds, "Planted reeds"],
    [BiotexOrCoirOrBiotex, "Biotex / coir / biotextile"],
    [WashedOutReinforcement, "Washed out reinforcement"],
  ]),
  Concrete,
  ConcreteAndBrick,
  BrickOrLaidStone,
  SheetPiling,
  WoodPiling,
  BuildersWaste,
  RipRap,
  Gabions,
  WillowSpiling,
  PlantedReeds,
  BiotexOrCoirOrBiotex,
  WashedOutReinforcement,
};

export default MrsReinforcementTypeEnum;
