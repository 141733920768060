// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsReinforcementLevel =
  | "none"
  | "bed"
  | "oneBank"
  | "bedOneBank"
  | "twoBanks"
  | "full";

const None: UrsReinforcementLevel = "none";
const BedOnly: UrsReinforcementLevel = "bed";
const OneBankOnly: UrsReinforcementLevel = "oneBank";
const BedAndOneBankOnly: UrsReinforcementLevel = "bedOneBank";
const BothBanksOnly: UrsReinforcementLevel = "twoBanks";
const Full: UrsReinforcementLevel = "full";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsReinforcementLevelEnum = {
  ...createEnum("UrsReinforcementLevel", [
    [None, "None"],
    [BedOnly, "Bed only"],
    [OneBankOnly, "One bank only"],
    [BedAndOneBankOnly, "Bed and one bank only"],
    [BothBanksOnly, "Both banks only"],
    [Full, "Full"],
  ]),
  None,
  BedOnly,
  OneBankOnly,
  BedAndOneBankOnly,
  BothBanksOnly,
  Full,
};

export default UrsReinforcementLevelEnum;
