import { Result } from "@cartographerio/fp";
import { Guard, GuardError } from "@cartographerio/guard";
import { errorMessage } from "./message";
import { pathGet, pathSet, pathUpdate, prefixPath } from "./path";
import { Message, Path } from "./type";

/** Cursor-like class providing methods for
 * safely getting/setting values in a value of type `unknown`.
 *
 * For example, you can use the `get()` and `set() methods to access
 * and copy-and-update particular `Paths` in the document:
 *
 * ```
 * const env = Env.create({foo: {bar: "baz"}}));
 *
 * env.get(["foo"])        // Result.pass({bar: "baz"})
 * env.get(["foo", "bar"]) // Result.pass("baz")
 *
 * env.set(["foo"], 42)    // Result.pass(Env.create({foo: 42}))
 *
 * env.get(["nope"])       // Result.fail(errorMessage("Field not found", ["nope"]))
 * ```
 *
 * The `focus()` method creates a "sub-environment" that focuses on a particular path:
 *
 * ```
 * const env1 = Env.create({foo: {bar: {foo: 42}}})
 * const env2 = env1.focus(["foo", "bar"])
 *
 * env1.get(["foo"]) // Result.pass({foo: {bar: {foo: 42}}})
 * env2.get(["foo"]) // Result.pass(42)
 * ```
 *
 * The `get()` method searches up the environment chain until it finds the requested path.
 * This is useful when running validation expressions on forms.
 *
 * Other methods have `fooRelative()` and `fooAbsolute()` variants that apply to
 * the focused value or the root document as appropriate:
 *
 * ```
 * const env1 = Env.create({foo: {bar: {foo: 42}}})
 * const env2 = env1.focus(["foo", "bar"])
 *
 * env2.setAbsolute(["foo"], 123) // Result.pass({foo: 123})
 * env2.setRelative(["foo"], 123) // Result.pass({foo: {bar: {foo: 123}}})
 * ```
 */
export interface Env {
  /** Fetch the root document. */
  doc(): unknown;
  /** Fetch the focused value. */
  focused(): unknown;
  /** Expand a relative path to an absolute path. */
  expand(path: Path): Path;
  /** Fetch a value relative to the focused value. */
  get(path: Path): unknown;
  /** Fetch a value relative to the focused value and verify its type. */
  getAs<A>(path: Path, guard: Guard<A>, hint?: string): Result<GuardError, A>;
  /** Fetch the focused value. */
  getFocused(): unknown;
  /** Fetch the focused value and verify its type. */
  getFocusedAs<A>(guard: Guard<A>, hint?: string): Result<GuardError, A>;
  /** Fetch a value relative to the root document. */
  getAbsolute(path: Path): unknown;
  /** Fetch a value relative to the root document and verify its type. */
  getAbsoluteAs<A>(
    path: Path,
    guard: Guard<A>,
    hint?: string
  ): Result<GuardError, A>;
  /** Update a path relative to the focused value. */
  setRelative(path: Path, value: unknown): Result<Message, Env>;
  /** Update the focsed value. Equivalent to `setRelative([])`. */
  setFocused(value: unknown): Result<Message, Env>;
  /** Update a path relative to the root document. */
  setAbsolute(path: Path, value: unknown): Result<Message, Env>;
  /** Transform a path relative to the focused value using the supplied `func`. */
  updateRelative(
    path: Path,
    func: (a: unknown) => unknown
  ): Result<Message, Env>;
  /** Transform a path relative to the root document using the supplied `func`. */
  updateAbsolute(
    path: Path,
    func: (a: unknown) => unknown
  ): Result<Message, Env>;
  /**
   * Transform a path relative to the focused value using the supplied `func`.
   * Verify that the value at the path is of the required type before starting.
   */
  updateRelativeAs<A>(
    guard: Guard<A>,
    path: Path,
    func: (a: A) => A
  ): Result<Message, Env>;
  /**
   * Transform a path relative to the root document using the supplied `func`.
   * Verify that the value at the path is of the required type before starting.
   */
  updateAbsoluteAs<A>(
    guard: Guard<A>,
    path: Path,
    func: (a: A) => A
  ): Result<Message, Env>;

  /** Return a new environment focusing on a particular path. */
  focus(path: Path): Env;
}

class RootEnv implements Env {
  _doc: unknown;

  constructor(doc: unknown) {
    this._doc = doc;
  }

  doc = (): unknown => {
    return this._doc;
  };

  focused = (): unknown => {
    return this._doc;
  };

  expand = (path: Path): Path => {
    return path;
  };

  get = (path: Path): unknown => {
    return pathGet(this._doc, path);
  };

  getAs = <A>(
    path: Path,
    guard: Guard<A>,
    hint?: string
  ): Result<GuardError, A> => {
    return Result.guard(guard, hint)(this.get(path));
  };

  getFocused = (): unknown => {
    return this._doc;
  };

  getFocusedAs = <A>(guard: Guard<A>, hint?: string): Result<GuardError, A> => {
    return Result.guard(guard, hint)(this._doc);
  };

  getAbsolute = (path: Path): unknown => {
    return this.get(path);
  };

  getAbsoluteAs = <A>(
    path: Path,
    guard: Guard<A>,
    hint?: string
  ): Result<GuardError, A> => {
    return this.getAs(path, guard, hint);
  };

  setRelative = (path: Path, value: unknown): Result<Message, Env> => {
    return pathSet(this._doc, path, value).map(doc => new RootEnv(doc));
  };

  setFocused = (value: unknown): Result<Message, Env> => {
    return this.setRelative([], value);
  };

  setAbsolute = (path: Path, value: unknown): Result<Message, Env> => {
    return this.setRelative(path, value);
  };

  updateRelative = (
    path: Path,
    func: (a: unknown) => unknown
  ): Result<Message, Env> => {
    return pathUpdate(this._doc, path, func).map(doc => new RootEnv(doc));
  };

  updateAbsolute = (
    path: Path,
    func: (a: unknown) => unknown
  ): Result<Message, Env> => {
    return this.updateRelative(path, func);
  };

  updateRelativeAs = <A>(
    guard: Guard<A>,
    path: Path,
    func: (a: A) => A
  ): Result<Message, Env> => {
    return Result.guardWith(guard, () =>
      errorMessage("Type mismatch", this.expand(path))
    )(pathGet(this._doc, path))
      .flatMap(a => pathSet(this._doc, path, func(a)))
      .map(doc => new RootEnv(doc));
  };

  updateAbsoluteAs = <A>(
    guard: Guard<A>,
    path: Path,
    func: (a: A) => A
  ): Result<Message, Env> => {
    return this.updateRelativeAs(guard, path, func);
  };

  focus = (path: Path): Env => {
    return path.length === 0
      ? this
      : new RelativeEnv(this, path, this.get(path));
  };
}

class RelativeEnv implements Env {
  _parent: Env;
  _path: Path;
  _focused: unknown;

  constructor(parent: Env, path: Path, focused: unknown) {
    this._parent = parent;
    this._path = path;
    this._focused = focused;
  }

  doc = (): unknown => {
    return this._parent.doc();
  };

  focused = (): unknown => {
    return this._focused;
  };

  expand = (path: Path): Path => {
    return this._parent.expand(prefixPath(this._path, path));
  };

  get = (path: Path): unknown => {
    const value = pathGet(this._focused, path);
    return value === undefined ? this._parent.get(path) : value;
  };

  getAs = <A>(
    path: Path,
    guard: Guard<A>,
    hint?: string
  ): Result<GuardError, A> => {
    return Result.guard(guard, hint)(this.get(path));
  };

  getFocused = (): unknown => {
    return this._focused;
  };

  getFocusedAs = <A>(guard: Guard<A>, hint?: string): Result<GuardError, A> => {
    return Result.guard(guard, hint)(this._focused);
  };

  getAbsolute = (path: Path): unknown => {
    return this._parent.getAbsolute(path);
  };

  getAbsoluteAs = <A>(
    path: Path,
    guard: Guard<A>,
    hint?: string
  ): Result<GuardError, A> => {
    return this._parent.getAbsoluteAs(path, guard, hint);
  };

  setRelative = (path: Path, value: unknown): Result<Message, Env> =>
    this._parent.setRelative(prefixPath(this._path, path), value);

  setFocused = (value: unknown): Result<Message, Env> => {
    return this.setRelative([], value);
  };

  setAbsolute = (path: Path, value: unknown): Result<Message, Env> =>
    this._parent.setAbsolute(path, value);

  updateRelative = (
    path: Path,
    func: (a: unknown) => unknown
  ): Result<Message, Env> =>
    this._parent.updateRelative(prefixPath(this._path, path), func);

  updateAbsolute = (
    path: Path,
    func: (a: unknown) => unknown
  ): Result<Message, Env> => this._parent.updateAbsolute(path, func);

  updateRelativeAs = <A>(
    guard: Guard<A>,
    path: Path,
    func: (a: A) => A
  ): Result<Message, Env> =>
    this._parent.updateRelativeAs(guard, prefixPath(this._path, path), func);

  updateAbsoluteAs = <A>(
    guard: Guard<A>,
    path: Path,
    func: (a: A) => A
  ): Result<Message, Env> => this._parent.updateAbsoluteAs(guard, path, func);

  focus = (path: Path): Env => {
    return path.length === 0
      ? this
      : new RelativeEnv(this, path, this.get(path));
  };
}

function create(doc: unknown): Env {
  return new RootEnv(doc);
}

export const Env = {
  create,
};
