// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type StSmartRiversRole = "Scientist" | "Volunteer";

const Scientist: StSmartRiversRole = "Scientist";
const Volunteer: StSmartRiversRole = "Volunteer";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StSmartRiversRoleEnum = {
  ...createEnum("StSmartRiversRole", [
    [Scientist, "Professional scientist"],
    [Volunteer, "Volunteer"],
  ]),
  Scientist,
  Volunteer,
};

export default StSmartRiversRoleEnum;
