// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsTreeType = "A" | "D" | "C" | "M";

const Absent: MrsTreeType = "A";
const Deciduous: MrsTreeType = "D";
const Coniferous: MrsTreeType = "C";
const Mixed: MrsTreeType = "M";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsTreeTypeEnum = {
  ...createEnum("MrsTreeType", [
    [Absent, "Absent"],
    [Deciduous, "Deciduous"],
    [Coniferous, "Coniferous"],
    [Mixed, "Mixed"],
  ]),
  Absent,
  Deciduous,
  Coniferous,
  Mixed,
};

export default MrsTreeTypeEnum;
