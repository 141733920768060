// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21VegetationOrigin =
  | "fromWater"
  | "fromBank"
  | "floatingPlanter";

const FromWater: Thames21VegetationOrigin = "fromWater";
const FromBank: Thames21VegetationOrigin = "fromBank";
const FloatingPlanter: Thames21VegetationOrigin = "floatingPlanter";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21VegetationOriginEnum = {
  ...createEnum("Thames21VegetationOrigin", [
    [FromWater, "From the water"],
    [FromBank, "From the bank"],
    [FloatingPlanter, "In a floating planter"],
  ]),
  FromWater,
  FromBank,
  FloatingPlanter,
};

export default Thames21VegetationOriginEnum;
