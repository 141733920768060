import { OtherSpecify, isOtherSpecify } from "@cartographerio/topo-form";
import { Option, Result } from "@cartographerio/fp";
import {
  Feature,
  Point,
  isFeatureF,
  isPoint,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isArrayOf,
  isBoolean,
  isNullable,
  isNumber,
  isObject,
  isRecordOf,
  isString,
} from "@cartographerio/guard";
import {
  RiverflyFirstBreachAction,
  RiverflyFirstBreachActionEnum,
  RiverflyReasonNotTaken,
  RiverflyReasonNotTakenEnum,
  RiverflySampleType,
  RiverflySampleTypeEnum,
  RiverflySecondBreachAction,
  RiverflySecondBreachActionEnum,
  UrbanRiverflySpecies,
  UrbanRiverflySpeciesEnum,
} from "@cartographerio/inventory-enums";
import {
  AttachmentFolder,
  Timestamp,
  isAttachmentFolder,
  isTimestamp,
} from "@cartographerio/types";

interface SiteAttributes {
  catchment?: string | null;
  river?: string | null;
  site?: string | null;
  triggerLevel?: number | null;
}

function isSiteAttributes(attrs: unknown): attrs is SiteAttributes {
  return (
    isObject(attrs) &&
    hasOptionalKey(attrs, "catchment", isNullable(isString)) &&
    hasOptionalKey(attrs, "river", isNullable(isString)) &&
    hasOptionalKey(attrs, "site", isNullable(isString)) &&
    hasOptionalKey(attrs, "triggerLevel", isNullable(isNumber))
  );
}

interface Observations {
  recorded?: Timestamp | null;
  sampleType?: RiverflySampleType | null;
  sampleTaken?: boolean | null;
  sampleReasonNotTaken?: OtherSpecify<RiverflyReasonNotTaken | null>;
  counts: Record<UrbanRiverflySpecies, number | null>;
  fixedPointPhotographs: AttachmentFolder;
  firstBreachAction?: RiverflyFirstBreachAction | null;
  firstBreachReasonNotRepeated?: string | null;
  secondBreachAction?: RiverflySecondBreachAction | null;
  secondBreachIncidentNumber?: string | null;
  secondBreachReasonNotReported?: string | null;
  breachPhotographs: AttachmentFolder;
  additionalSurveyors?: string | null;
  notes?: string | null;
}

function isObservations(observations: unknown): observations is Observations {
  return (
    isObject(observations) &&
    hasOptionalKey(observations, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(
      observations,
      "sampleType",
      isNullable(RiverflySampleTypeEnum.isValue)
    ) &&
    hasOptionalKey(observations, "sampleTaken", isNullable(isBoolean)) &&
    hasOptionalKey(
      observations,
      "sampleReasonNotTaken",
      isOtherSpecify(isNullable(RiverflyReasonNotTakenEnum.isValue))
    ) &&
    hasKey(
      observations,
      "counts",
      isRecordOf<UrbanRiverflySpecies, number | null>(
        UrbanRiverflySpeciesEnum.isValue,
        isNullable(isNumber)
      )
    ) &&
    hasKey(observations, "fixedPointPhotographs", isAttachmentFolder) &&
    hasOptionalKey(
      observations,
      "firstBreachAction",
      isNullable(RiverflyFirstBreachActionEnum.isValue)
    ) &&
    hasOptionalKey(
      observations,
      "firstBreachReasonNotRepeated",
      isNullable(isString)
    ) &&
    hasKey(
      observations,
      "secondBreachAction",
      isArrayOf(RiverflySecondBreachActionEnum.isValue)
    ) &&
    hasOptionalKey(
      observations,
      "secondBreachIncidentNumber",
      isNullable(isString)
    ) &&
    hasOptionalKey(
      observations,
      "secondBreachReasonNotReported",
      isNullable(isString)
    ) &&
    hasKey(observations, "breachPhotographs", isAttachmentFolder) &&
    hasOptionalKey(observations, "additionalSurveyors", isNullable(isString)) &&
    hasOptionalKey(observations, "notes", isNullable(isString))
  );
}

interface PartialData {
  site?: Feature<Point, SiteAttributes> | null;
  observations: Observations;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasKey(data, "observations", isObservations) &&
    hasOptionalKey(
      data,
      "site",
      isNullable(isFeatureF(isPoint, isSiteAttributes))
    )
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [
      data.site?.properties?.catchment ?? "Unknown catchment",
      data.site?.properties?.river ?? "Unknown river",
      data.site?.properties?.site ?? "Unknown site",
      Option.wrap(data.site?.geometry)
        .map(safePointToNgr)
        .getOrElse(() => "Unknown location"),
    ].join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.observations.recorded ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data.site?.geometry ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
    observations: {
      ...data.observations,
      sampleReasonNotTaken: { selected: null, other: null },
      counts: {},
      secondSampleCounts: {},
      secondBreachAction: [],
    },
  }));
}
