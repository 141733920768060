import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  UrsSurveyType,
  UrsSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

interface SurveyDetails {
  projectName?: string | null;
  riverName?: string | null;
  stretchName?: string | null;
  surveyType?: UrsSurveyType | null;
  scenarioName?: string | null;
}

function isSurveyDetails(details: unknown): details is SurveyDetails {
  return (
    isObject(details) &&
    hasOptionalKey(details, "projectName", isNullable(isString)) &&
    hasOptionalKey(details, "riverName", isNullable(isString)) &&
    hasOptionalKey(details, "stretchName", isNullable(isString)) &&
    hasOptionalKey(
      details,
      "surveyType",
      isNullable(UrsSurveyTypeEnum.isValue)
    ) &&
    hasOptionalKey(details, "scenarioName", isNullable(isString))
  );
}

interface SiteInformation {
  upstreamLocation?: Point | null;
  downstreamLocation?: Point | null;
}

function isSiteInformation(details: unknown): details is SiteInformation {
  return (
    isObject(details) &&
    hasOptionalKey(details, "upstreamLocation", isNullable(isPoint)) &&
    hasOptionalKey(details, "downstreamLocation", isNullable(isPoint))
  );
}

export interface PartialUrsData {
  recorded?: Timestamp | null;
  surveyDetails: SurveyDetails;
  siteInformation: SiteInformation;
}

export function isPartialData(data: unknown): data is PartialUrsData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasKey(data, "surveyDetails", isSurveyDetails) &&
    hasKey(data, "siteInformation", isSiteInformation)
  );
}

function createDescription(...args: Array<string | null>): string {
  return args.filter(arg => arg != null).join(", ");
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialUrsData"
  )(data).map(data => {
    const location =
      data.siteInformation.upstreamLocation ??
      data.siteInformation.downstreamLocation;

    return createDescription(
      data.surveyDetails.riverName ?? "Unknown river",
      data.surveyDetails.stretchName ?? "Unknown stretch",
      data.surveyDetails.projectName ?? "Unknown project",
      data.surveyDetails.surveyType === UrsSurveyTypeEnum.Scenario
        ? `Scenario (${data.surveyDetails.scenarioName ?? "Unnamed"})`
        : data.surveyDetails.surveyType == null
          ? null
          : UrsSurveyTypeEnum.labelOf(data.surveyDetails.surveyType),
      location ? safePointToNgr(location) : null
    );
  });
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialUrsData"
  )(data).map(
    data =>
      data.siteInformation.upstreamLocation ??
      data.siteInformation.downstreamLocation ??
      null
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialUrsData"
  )(data).map(data => data.recorded ?? null);
}
