// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesCreekType = "DC" | "UB" | "LC";

const DeepCreek: MrsMorphEstuariesCreekType = "DC";
const UnstableBanks: MrsMorphEstuariesCreekType = "UB";
const LowOrderCreek: MrsMorphEstuariesCreekType = "LC";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesCreekTypeEnum = {
  ...createEnum("MrsMorphEstuariesCreekType", [
    [DeepCreek, "Deep Creek"],
    [UnstableBanks, "Unstable Banks"],
    [LowOrderCreek, "Low-Order Creek"],
  ]),
  DeepCreek,
  UnstableBanks,
  LowOrderCreek,
};

export default MrsMorphEstuariesCreekTypeEnum;
