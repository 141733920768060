import { PricingScheme, unsafeCurrencyAmount } from "@cartographerio/types";

export type PremiumPrice = {
  type: "Premium";
};

export type Price = PricingScheme | PremiumPrice;

export const freePrice: Price = {
  type: "Free",
};

export function perUserPrice(unitAmount: number): Price {
  return {
    type: "PerUser",
    unitAmount: unsafeCurrencyAmount(100 * unitAmount),
    flatAmount: unsafeCurrencyAmount(0),
    inclusiveUnits: 0,
  };
}

export function perProjectPrice(
  flatAmount: number,
  inclusiveUnits: number,
  unitAmount: number
): Price {
  return {
    type: "PerProject",
    flatAmount: unsafeCurrencyAmount(100 * flatAmount),
    inclusiveUnits,
    unitAmount: unsafeCurrencyAmount(100 * unitAmount),
  };
}

export const premiumPrice: Price = {
  type: "Premium",
};
