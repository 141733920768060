import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  WorkspaceBillingSettings,
  WorkspaceRef,
  isWorkspaceBillingSettings,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";

const basePath = "/billing/settings/v1";

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<WorkspaceBillingSettings> {
  const url = { path: `${basePath}/${workspaceRef}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(contentAs("WorkspaceBillingSettings", isWorkspaceBillingSettings));
}

export function readOption(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): IO<Option<WorkspaceBillingSettings>> {
  const url = { path: `${basePath}/${workspaceRef}` };
  return fetch
    .get({ apiParams, url })
    .flatMap(
      optionalContentAs("WorkspaceBillingSettings", isWorkspaceBillingSettings)
    );
}

export function update(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  update: WorkspaceBillingSettings
): IO<WorkspaceBillingSettings> {
  const url = { path: `${basePath}/${workspaceRef}` };
  return fetch
    .put({ apiParams, url, body: update })
    .flatMap(contentAs("WorkspaceBillingSettings", isWorkspaceBillingSettings));
}
