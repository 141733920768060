// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { WorkspaceAccessLevel } from "../types.generated";

const Full: WorkspaceAccessLevel = "Full";
const ReadOnly: WorkspaceAccessLevel = "ReadOnly";
const None: WorkspaceAccessLevel = "None";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WorkspaceAccessLevelEnum = {
  ...createEnum("WorkspaceAccessLevel", [
    [Full, "Full Access"],
    [ReadOnly, "Read-Only Access"],
    [None, "No Access"],
  ]),
  Full,
  ReadOnly,
  None,
};

export default WorkspaceAccessLevelEnum;
