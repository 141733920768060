export interface SortJsonOptions {
  initialKeys?: string[];
}

const defaultInitialKeys = ["type", "id"];

function compareFunc(initialKeys?: string[]) {
  if (initialKeys == null) {
    return undefined;
  } else {
    return (a: string, b: string) => {
      const i = initialKeys.indexOf(a);
      const j = initialKeys.indexOf(b);
      if (i >= 0) {
        if (j >= 0) {
          return i - j;
        } else {
          return -1;
        }
      } else {
        if (j >= 0) {
          return +1;
        } else {
          return a < b ? -1 : +1;
        }
      }
    };
  }
}

export function sortJson(
  value: unknown,
  options: SortJsonOptions = {}
): unknown {
  const { initialKeys = defaultInitialKeys } = options;

  if (Array.isArray(value)) {
    return value.map(value => sortJson(value, options));
  } else if (typeof value === "object" && value != null) {
    const ans: Record<string, unknown> = {};

    Object.keys(value)
      .sort(compareFunc(initialKeys))
      .forEach(key => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ans[key] = sortJson((value as any)[key], options);
      });

    return ans;
  } else {
    return value;
  }
}
