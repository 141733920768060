import {
  attachmentAttr,
  attributeGroup,
  enumAttr,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  StormPhosphatesLevelEnum,
  StormPhosphatesLevelTrendEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const StormPhosphates = mapSchemaWithDefaults({
  mapId: unsafeMapId("StormPhosphates"),
  title: "Phosphates",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("StormPhosphates"),
      title: "Phosphates",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("StormPhosphates"),
        attribution: [],
      }),
      defaultAttribute: "phosphate",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Location and Time",
          attributes: [
            stringAttr({
              attributeId: "riverName",
              label: "River",
              buckets: "auto",
            }),
            enumAttr({
              attributeId: "level",
              label: "Level at Nearest Gauge",
              enum: StormPhosphatesLevelEnum,
              markers: {
                [StormPhosphatesLevelEnum.High]: marker.hue(60),
                [StormPhosphatesLevelEnum.Normal]: marker.hue(180),
                [StormPhosphatesLevelEnum.Low]: marker.hue(300),
              },
            }),
            enumAttr({
              attributeId: "levelTrend",
              label: "Level Trend at Nearest Gauge",
              enum: StormPhosphatesLevelTrendEnum,
              markers: {
                [StormPhosphatesLevelTrendEnum.Rising]: marker.hue(60),
                [StormPhosphatesLevelTrendEnum.Steady]: marker.hue(180),
                [StormPhosphatesLevelTrendEnum.Falling]: marker.hue(300),
              },
            }),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("StormPhosphates"),
            }),
            surveyAttr("surveyId", "Site"),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
        attributeGroup({
          label: "Measurements",
          attributes: [
            numberAttr({
              attributeId: "phosphate",
              label: "Phosphates",
              unit: "ppm",
              buckets: [
                rangeBucket(0, 0.05, marker.quality(15 / 20)),
                rangeBucket(0.05, 0.25, marker.quality(10 / 20)),
                rangeBucket(0.25, 0.5, marker.quality(9 / 20)),
                rangeBucket(0.5, 0.75, marker.quality(8 / 20)),
                rangeBucket(0.75, 1.0, marker.quality(7 / 20)),
                rangeBucket(1.0, 1.25, marker.quality(6 / 20)),
                rangeBucket(1.25, 1.5, marker.quality(5 / 20)),
                rangeBucket(1.5, 1.75, marker.quality(4 / 20)),
                rangeBucket(1.75, 2.0, marker.quality(3 / 20)),
                rangeBucket(2.0, 2.25, marker.quality(2 / 20)),
                rangeBucket(2.25, 2.5, marker.quality(1 / 20)),
                rangeBucket(2.5, null, marker.quality(0 / 20)),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
