import { CartographerSource } from "@cartographerio/topo-map";

export function cartographerSourceWithDefaults(
  params: Omit<CartographerSource, "type">
): CartographerSource {
  const { layerId, ...rest } = params;
  return {
    type: "CartographerSource",
    layerId,
    ...rest,
  };
}
