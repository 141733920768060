// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsProtectedSpeciesCategory = "european" | "national";

const European: UrsProtectedSpeciesCategory = "european";
const National: UrsProtectedSpeciesCategory = "national";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsProtectedSpeciesCategoryEnum = {
  ...createEnum("UrsProtectedSpeciesCategory", [
    [European, "European protected species"],
    [National, "Nationally protected species"],
  ]),
  European,
  National,
};

export default UrsProtectedSpeciesCategoryEnum;
