import { PlanId, PlanLimits } from "@cartographerio/types";
import { FeatureSupport } from "./features";
import { Price } from "./price";

export type Quotas = {
  maxProjects: number | null;
  maxUsers: number | null;
};

export type PricingPlan = {
  id: PlanId;
  title: string;
  price: Price;
  limits: PlanLimits;
  features: FeatureSupport;
};

export function plan(plan: PricingPlan): PricingPlan {
  return plan;
}
