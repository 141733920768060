import {
  attachmentAttr,
  attributeGroup,
  booleanAttr,
  enumAttr,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  EsrtWaterQualityBankVegetationEnum,
  EsrtWaterQualityFlowObstacleEnum,
  EsrtWaterQualityInvasivePlantEnum,
  EsrtWaterQualityPollutionEvidenceEnum,
  EsrtWaterQualityPollutionSourceEnum,
  EsrtWaterQualityRiverbedCompositionEnum,
  EsrtWaterQualityWildlifeEnum,
} from "@cartographerio/inventory-enums";
import { unsafeSurveyModuleId } from "@cartographerio/types";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";

export const EsrtWaterQuality = mapSchemaWithDefaults({
  mapId: unsafeMapId("EsrtWaterQuality"),
  title: "ESRT Water Quality",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("EsrtWaterQuality"),
      title: "ESRT Water Quality",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("EsrtWaterQuality"),
        attribution: [],
      }),
      defaultAttribute: "wildlife",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("EsrtWaterQuality"),
            }),
            surveyAttr("surveyId", "Survey"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
          ],
        }),
        attributeGroup({
          label: "Photographs",
          attributes: [attachmentAttr("photographs", "Photographs")],
        }),
        attributeGroup({
          label: "Plants and Wildlife",
          attributes: [
            enumAttr({
              attributeId: "bankVegetation",
              label: "Bank Vegetation",
              enum: EsrtWaterQualityBankVegetationEnum,
            }),
            enumAttr({
              attributeId: "invasivePlants",
              label: "Invasive Plants",
              enum: EsrtWaterQualityInvasivePlantEnum,
            }),
            enumAttr({
              attributeId: "wildlife",
              label: "Wildlife",
              enum: EsrtWaterQualityWildlifeEnum,
            }),
            stringAttr({
              attributeId: "outfalls",
              label: "Outfall(s)",
              buckets: [
                lookupBucket(null, marker.empty, "None recorded"),
                lookupBucket(
                  "Active",
                  marker.fromColor("rgb(255, 128, 0)"),
                  "Active (discharging)"
                ),
                lookupBucket(
                  "Inactive",
                  marker.fromColor("rgb(255, 255, 0)"),
                  "Dormant (not discharging)"
                ),
              ],
            }),
          ],
        }),
        attributeGroup({
          label: "Pollution",
          attributes: [
            enumAttr({
              attributeId: "pollutionSource",
              label: "Visible Sources of Pollution",
              enum: EsrtWaterQualityPollutionSourceEnum,
            }),
            enumAttr({
              attributeId: "pollutionEvidence",
              label: "Evidence of Recent Pollution",
              enum: EsrtWaterQualityPollutionEvidenceEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "River Channel",
          attributes: [
            enumAttr({
              attributeId: "obstacles",
              label: "Obstacles to Fish and Flow",
              enum: EsrtWaterQualityFlowObstacleEnum,
            }),
            enumAttr({
              attributeId: "riverbedComposition",
              label: "Riverbed Composition",
              enum: EsrtWaterQualityRiverbedCompositionEnum,
            }),
          ],
        }),
        attributeGroup({
          label: "Water Quality Measurements",
          attributes: [
            booleanAttr({
              attributeId: "sampleTaken",
              label: "Water Quality Tested",
              buckets: [
                lookupBucket(true, marker.fromColor("limegreen")),
                lookupBucket(false, marker.fromColor("orange")),
                lookupBucket(null, marker.empty),
              ],
            }),
            numberAttr({
              attributeId: "temperature",
              label: "Temperature",
              unit: "C",
              decimalPlaces: 1,
              buckets: [
                rangeBucket(0, 4, marker.quality(6 / 6)),
                rangeBucket(4, 8, marker.quality(5 / 6)),
                rangeBucket(8, 12, marker.quality(4 / 6)),
                rangeBucket(12, 16, marker.quality(3 / 6)),
                rangeBucket(16, 20, marker.quality(2 / 6)),
                rangeBucket(20, 24, marker.quality(1 / 6)),
                rangeBucket(24, null, marker.quality(0 / 6)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "dissolvedSolids",
              label: "Dissolved Solids",
              unit: "ppm",
              decimalPlaces: 0,
              buckets: [
                rangeBucket(0, 50, marker.quality(6 / 6)),
                rangeBucket(50, 200, marker.quality(5 / 6)),
                rangeBucket(200, 350, marker.quality(4 / 6)),
                rangeBucket(350, 500, marker.quality(3 / 6)),
                rangeBucket(500, 650, marker.quality(2 / 6)),
                rangeBucket(650, 800, marker.quality(1 / 6)),
                rangeBucket(800, null, marker.quality(0 / 6)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "turbidity",
              label: "Turbidity",
              unit: "NTU",
              decimalPlaces: 0,
              buckets: [
                rangeBucket(null, 12, marker.quality(6 / 6)),
                rangeBucket(12, 40, marker.quality(5 / 6)),
                rangeBucket(40, 80, marker.quality(4 / 6)),
                rangeBucket(80, 120, marker.quality(3 / 6)),
                rangeBucket(120, 160, marker.quality(2 / 6)),
                rangeBucket(160, 240, marker.quality(1 / 6)),
                rangeBucket(240, null, marker.quality(0 / 6)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "ammonia",
              label: "Ammonia",
              unit: "ppm",
              decimalPlaces: 1,
              buckets: [
                rangeBucket(0, 0.5, marker.quality(4 / 4)),
                rangeBucket(0.5, 1, marker.quality(3 / 4)),
                rangeBucket(1, 3, marker.quality(2 / 4)),
                rangeBucket(3, 6, marker.quality(1 / 4)),
                rangeBucket(6, null, marker.quality(0 / 4)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "phosphate",
              label: "Phosphates",
              unit: "ppb",
              decimalPlaces: 0,
              buckets: [
                rangeBucket(0, 100, marker.quality(6 / 6)),
                rangeBucket(100, 200, marker.quality(5 / 6)),
                rangeBucket(200, 300, marker.quality(4 / 6)),
                rangeBucket(300, 500, marker.quality(3 / 6)),
                rangeBucket(500, 1000, marker.quality(2 / 6)),
                rangeBucket(1000, 2500, marker.quality(1 / 6)),
                rangeBucket(2500, null, marker.quality(0 / 6)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "nitrate",
              label: "Nitrates",
              unit: "ppm",
              decimalPlaces: 0,
              buckets: [
                rangeBucket(0, 10, marker.quality(5 / 5)),
                rangeBucket(10, 30, marker.quality(4 / 5)),
                rangeBucket(30, 60, marker.quality(3 / 5)),
                rangeBucket(60, 120, marker.quality(2 / 5)),
                rangeBucket(120, 200, marker.quality(1 / 5)),
                rangeBucket(200, null, marker.quality(0 / 5)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
