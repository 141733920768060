// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityBankVegetation =
  | "TreesOrShrubs"
  | "MixedPlantsOrFlowers"
  | "Grass"
  | "BareEarth"
  | "ImpermeableSurface"
  | "Other";

const TreesOrShrubs: ArrtWaterQualityBankVegetation = "TreesOrShrubs";
const MixedPlantsOrFlowers: ArrtWaterQualityBankVegetation =
  "MixedPlantsOrFlowers";
const Grass: ArrtWaterQualityBankVegetation = "Grass";
const BareEarth: ArrtWaterQualityBankVegetation = "BareEarth";
const ImpermeableSurface: ArrtWaterQualityBankVegetation = "ImpermeableSurface";
const Other: ArrtWaterQualityBankVegetation = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityBankVegetationEnum = {
  ...createEnum("ArrtWaterQualityBankVegetation", [
    [TreesOrShrubs, "Trees/Shrubs"],
    [MixedPlantsOrFlowers, "Mixed Plants/Flowers"],
    [Grass, "Grass"],
    [BareEarth, "None (Bare Earth)"],
    [ImpermeableSurface, "None (Impermeable Surface e.g. Tarmac)"],
    [Other, "Other"],
  ]),
  TreesOrShrubs,
  MixedPlantsOrFlowers,
  Grass,
  BareEarth,
  ImpermeableSurface,
  Other,
};

export default ArrtWaterQualityBankVegetationEnum;
