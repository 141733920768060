import { Option, Result } from "@cartographerio/fp";
import { GeometryAtom, isPoint, Point } from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isBoolean,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  MrsMudSpotterSourceType,
  MrsMudSpotterSourceTypeEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface PartialMudSpotterData {
  recorded?: Timestamp | null;
  location?: Point | null;
  training?: boolean | null;
  riverName?: string | null;
  reachName?: string | null;
  siteName?: string | null;
  sourceType?: MrsMudSpotterSourceType | null;
}

export function isPartialData(data: unknown): data is PartialMudSpotterData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "training", isNullable(isBoolean)) &&
    hasOptionalKey(data, "riverName", isNullable(isString)) &&
    hasOptionalKey(data, "reachName", isNullable(isString)) &&
    hasOptionalKey(data, "siteName", isNullable(isString)) &&
    hasOptionalKey(
      data,
      "sourceType",
      isNullable(MrsMudSpotterSourceTypeEnum.isValue)
    )
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialMudSpotterData"
  )(data).map(data =>
    [
      Option.wrap(data.sourceType)
        .map(MrsMudSpotterSourceTypeEnum.labelOf)
        .getOrElse(() => "Unknown Source"),
      Option.wrap(data.riverName).getOrElse(() => "Unknown River"),
      Option.wrap(data.reachName).getOrElse(() => "Unknown Reach"),
      Option.wrap(data.siteName).getOrElse(() => "Unknown Site"),
      data.training ? "Training" : null,
    ]
      .filter(x => x != null)
      .join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialMudSpotterData"
  )(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialMudSpotterData"
  )(data).map(data => data.recorded || null);
}

export function copyData(
  data: unknown
): Result<GuardError, PartialMudSpotterData> {
  return Result.guard(
    isPartialData,
    "PartialMudSpotterData"
  )(data).map(data => ({ ...data, recorded: null }));
}
