import { PermissionCheck, Project } from "@cartographerio/types";
import { check } from "../check";
import { checkExhausted } from "@cartographerio/util";

export function view(project: Project): PermissionCheck {
  const description = `View maps in project ${project.name}`;

  switch (project.mapVisibility) {
    case "Project":
      switch (project.projectVisibility) {
        case "Private":
          return check.named(
            description,
            check.and(
              check.workspaceReadOnlyAccess(project.workspaceId),
              check.projectMember(
                project.id,
                project.workspaceId,
                project.teamIds
              )
            )
          );

        case "Workspace":
          return check.named(
            description,
            check.and(
              check.workspaceReadOnlyAccess(project.workspaceId),
              check.workspaceActive(project.workspaceId)
            )
          );

        default:
          return checkExhausted(project.projectVisibility);
      }

    case "Public":
      return check.named(
        description,
        check.workspaceReadOnlyAccess(project.workspaceId)
      );

    default:
      return checkExhausted(project.mapVisibility);
  }
}

export function reset(): PermissionCheck {
  return check.named(`Reset map data`, check.superuser);
}
