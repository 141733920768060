// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NeRiverNaturalnessBiologicalAssessmentForm =
  | "Visual"
  | "Nbd"
  | "OtherInitiative"
  | "Other";

const Visual: NeRiverNaturalnessBiologicalAssessmentForm = "Visual";
const Nbd: NeRiverNaturalnessBiologicalAssessmentForm = "Nbd";
const OtherInitiative: NeRiverNaturalnessBiologicalAssessmentForm =
  "OtherInitiative";
const Other: NeRiverNaturalnessBiologicalAssessmentForm = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NeRiverNaturalnessBiologicalAssessmentFormEnum = {
  ...createEnum("NeRiverNaturalnessBiologicalAssessmentForm", [
    [Visual, "Simple visual inspection of non-native species presence"],
    [Nbd, "National Biodiversity Network data"],
    [OtherInitiative, "Data from other recording initiative (please specify)"],
    [Other, "Other (please specify)"],
  ]),
  Visual,
  Nbd,
  OtherInitiative,
  Other,
};

export default NeRiverNaturalnessBiologicalAssessmentFormEnum;
