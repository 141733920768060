import {
  InvitationCodeRef,
  InvitationCode,
  PermissionCheck,
  QualificationRole,
  Role,
  WorkspaceId,
  invitationCodeStatus,
} from "@cartographerio/types";
import { check } from "../check";
import { checkExhausted } from "@cartographerio/util";

export function create(code: InvitationCode): PermissionCheck {
  return check.named(
    code.workspaceId == null
      ? `Create an invitation code in the global admin area`
      : `Create an invitation code in workspace ${code.workspaceId}`,
    updateInternal(code.workspaceId, code.roles, code.qualificationRoles)
  );
}

export function update(code: InvitationCode): PermissionCheck {
  const status = invitationCodeStatus(code);

  switch (status) {
    case "Canceled":
      return check.never("Invitation code has been canceled");
    case "Expired":
      return check.never("Invitation code has expired");
    case "Pending":
      return check.named(
        code.workspaceId == null
          ? `Update invitation code ${code.id} in the global admin area`
          : `Update invitation code ${code.id} in workspace ${code.workspaceId}`,
        updateInternal(code.workspaceId, code.roles, code.qualificationRoles)
      );
    default:
      return checkExhausted(status);
  }
}

export function approveOrRejectSignup(
  workspaceId: WorkspaceId | null,
  invitationCodeRef: InvitationCodeRef,
  invitationCodeRoles: Role[],
  invitationCodeQualificationRoles: QualificationRole[]
): PermissionCheck {
  return check.named(
    workspaceId == null
      ? `Approve or reject signup for invitation code ${invitationCodeRef} (global admin area)`
      : `Approve or reject signup for invitation code ${invitationCodeRef} (workspace ${workspaceId})`,
    updateInternal(
      workspaceId,
      invitationCodeRoles,
      invitationCodeQualificationRoles
    )
  );
}

export function remove(code: InvitationCode): PermissionCheck {
  return code.workspaceId == null
    ? check.globalAdmin
    : check.workspaceAdmin(code.workspaceId);
}

function updateInternal(
  workspaceId: WorkspaceId | null | undefined,
  roles: Role[],
  qualificationRoles: QualificationRole[]
) {
  return check.and(
    workspaceId == null
      ? check.globalAdmin
      : check.workspaceActive(workspaceId),
    check.canGrantRoles(roles, qualificationRoles, () =>
      check.never("Invitation codes must contain at least one role")
    )
  );
}
