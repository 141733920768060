// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusPoorModerateGood = "poor" | "moderate" | "good";

const Poor: EcostatusPoorModerateGood = "poor";
const Moderate: EcostatusPoorModerateGood = "moderate";
const Good: EcostatusPoorModerateGood = "good";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusPoorModerateGoodEnum = {
  ...createEnum("EcostatusPoorModerateGood", [
    [Poor, "Poor"],
    [Moderate, "Moderate"],
    [Good, "Good"],
  ]),
  Poor,
  Moderate,
  Good,
};

export default EcostatusPoorModerateGoodEnum;
