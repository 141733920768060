import { checkExhausted } from "@cartographerio/util";
import lodash from "lodash";
import { PropertyValue } from ".";
import { Attribute, attributeUnit, formatAttributeValue } from "../attribute";
import { Bucket, LookupBucket, RangeBucket } from "./type";

export function isLookupBucket<A = PropertyValue>(
  bucket: Bucket<A>
): bucket is LookupBucket<A> {
  switch (bucket.type) {
    case "LookupBucket":
      return true;
    case "RangeBucket":
      return false;
    default:
      return checkExhausted(bucket);
  }
}

export function isRangeBucket<A = PropertyValue>(
  bucket: Bucket<A>
): bucket is RangeBucket<A> {
  switch (bucket.type) {
    case "LookupBucket":
      return false;
    case "RangeBucket":
      return true;
    default:
      return checkExhausted(bucket);
  }
}

export function bucketMinValue<A>(bucket: Bucket<A>): A | null {
  return isLookupBucket(bucket) ? bucket.value : bucket.minValue;
}

export function bucketMaxValue<A>(bucket: Bucket<A>): A | null {
  return isLookupBucket(bucket) ? bucket.value : bucket.maxValue;
}

export function rangeLabel(
  attribute: Attribute,
  minValue: PropertyValue,
  maxValue: PropertyValue,
  maxInclusive: boolean
): string | null {
  const minLabel =
    minValue == null ? null : formatAttributeValue(attribute, minValue);
  const maxLabel =
    maxValue == null ? null : formatAttributeValue(attribute, maxValue);

  if (minLabel != null && maxLabel != null) {
    if (minLabel === maxLabel) {
      return `${minLabel}`;
    } else if (maxInclusive) {
      return `${minLabel} to ${maxLabel}`;
    } else {
      return `${minLabel} to <${maxLabel}`;
    }
  } else if (minLabel != null) {
    return `≥${minLabel}`;
  } else if (maxLabel != null) {
    if (maxInclusive) {
      return `≤${maxLabel}`;
    } else {
      return `<${maxLabel}`;
    }
  } else {
    return null;
  }
}

export function bucketLabel(
  attribute: Attribute,
  bucket: Bucket
): string | null {
  if (isLookupBucket(bucket)) {
    return (
      bucket.label ??
      (bucket.value != null
        ? joinNullableStrings(
            [
              formatAttributeValue(attribute, bucket.value),
              attributeUnit(attribute),
            ],
            " "
          )
        : null)
    );
  } else {
    return (
      bucket.label ??
      joinNullableStrings(
        [
          rangeLabel(
            attribute,
            bucket.minValue,
            bucket.maxValue,
            bucket.maxInclusive
          ),
          attributeUnit(attribute),
        ],
        " "
      )
    );
  }
}

export function multiBucketLabel(
  attribute: Attribute,
  buckets: Bucket[]
): string | null {
  if (buckets.some(isLookupBucket)) {
    return buckets.map(bucket => bucketLabel(attribute, bucket)).join(", ");
  } else {
    const minValues = buckets.map(bucketMinValue);
    const maxValues = buckets.map(bucketMaxValue);

    const minValue = minValues.includes(null)
      ? null
      : lodash.min(minValues) ?? null;

    const maxValue = maxValues.includes(null)
      ? null
      : lodash.max(minValues) ?? null;

    const maxInclusive = false;

    return rangeLabel(attribute, minValue, maxValue, maxInclusive);
  }
}

export function bucketContains<A>(bucket: Bucket<A>, value: A | null): boolean {
  switch (bucket.type) {
    case "LookupBucket": {
      return bucket.value == null ? value == null : bucket.value === value;
    }

    case "RangeBucket": {
      const minBound: boolean =
        bucket.minValue == null
          ? true
          : value != null && bucket.minValue <= value;

      const maxBound: boolean =
        bucket.maxValue == null
          ? true
          : bucket.maxInclusive
            ? value != null && bucket.maxValue >= value
            : value != null && bucket.maxValue > value;

      return minBound && maxBound;
    }

    default:
      return checkExhausted(bucket);
  }
}

export function findBucket<A>(
  buckets: Bucket<A>[],
  value: A | null
): Bucket<A> | null {
  return buckets.find(bucket => bucketContains(bucket, value)) ?? null;
}

export function findBucketIndex<A>(
  buckets: Bucket<A>[],
  value: A | null
): number {
  return buckets.findIndex(bucket => bucketContains(bucket, value));
}

export function findBuckets<A>(buckets: Bucket<A>[], values: A[]): Bucket<A>[] {
  return buckets.filter(bucket =>
    values.some(value => bucketContains(bucket, value))
  );
}

function joinNullableStrings(
  items: (string | null)[],
  delim: string
): string | null {
  const strings = items.filter(item => item != null);
  return strings.length === 0 ? null : strings.join(delim);
}
