import {
  Attribute,
  attributeGroup,
  enumAttr,
  lookupBucket,
  MapId,
  MapSchema,
  marker,
  numberAttr,
  pointLayer,
  stringAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";

export function neutralIndex(attributeId: string, label: string): Attribute {
  return numberAttr({
    attributeId,
    label,
    decimalPlaces: 3,
    buckets: [
      lookupBucket(-4, marker.numeric(0 / 8)),
      lookupBucket(-3, marker.numeric(1 / 8)),
      lookupBucket(-2, marker.numeric(2 / 8)),
      lookupBucket(-1, marker.numeric(3 / 8)),
      lookupBucket(0, marker.numeric(4 / 8)),
      lookupBucket(1, marker.numeric(5 / 8)),
      lookupBucket(2, marker.numeric(6 / 8)),
      lookupBucket(3, marker.numeric(7 / 8)),
      lookupBucket(4, marker.numeric(8 / 8)),
    ],
  });
}

import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { moduleNumbersAttr, surveyTypeAttr } from "./helpers";
import { UrsSurveyBankEnum } from "@cartographerio/inventory-enums";

const indices = [
  {
    id: "B1.0",
    title: "Supratidal (bank top) terrestrial vegetation structure",
  },
  {
    id: "B1.1",
    title: "Supratidal (bank top) upper marsh vegetation structure",
  },
  { id: "B2", title: "Supratidal (bank top) tree feature richness" },
  { id: "B3", title: "Supratidal (bank top) freshwater-related features" },
  { id: "B4", title: "Supratidal (bank top) NNIPS cover" },
  { id: "B5", title: "Supratidal (bank top) managed ground cover" },
  { id: "B6", title: "Supratidal (bank top) litter cover" },
  { id: "C1", title: "Vegetated intertidal vegetation structure" },
  {
    id: "C2",
    title: "Vegetated intertidal (lower marsh) vegetation structure",
  },
  { id: "C3", title: "Vegetated intertidal natural bank profile extent" },
  { id: "C4", title: "Vegetated intertidal natural bank profile richness" },
  { id: "C5", title: "Vegetated intertidal natural materials richness" },
  { id: "C6", title: "Vegetated intertidal bare sediment extent" },
  {
    id: "C7",
    title: "Vegetated intertidal artificial bank profile extent",
  },
  { id: "C8", title: "Vegetated intertidal reinforcement extent" },
  {
    id: "C9",
    title: "Vegetated intertidal reinforcement material severity",
  },
  {
    id: "C10.0",
    title: "Vegetated intertidal (Whole Marsh) NNIPS cover",
  },
  {
    id: "C10.1",
    title: "Vegetated intertidal (Lower Marsh) NNIPS cover",
  },
  { id: "C11", title: "Vegetated intertidal litter cover" },
  {
    id: "C12",
    title: "Vegetated intertidal extent of erosion and pressures",
  },
  {
    id: "C13",
    title: "Vegetated intertidal artificial features and pressures severity",
  },
  {
    id: "D1",
    title: "Unvegetated intertidal natural physical features richness",
  },
  {
    id: "D2",
    title: "Unvegetated intertidal natural physical features extent",
  },
  { id: "D3", title: "Unvegetated intertidal materials richness" },
  {
    id: "D4",
    title: "Unvegetated intertidal tree features and vegetation richness",
  },
  { id: "D5", title: "Unvegetated intertidal reinforcement extent" },
  {
    id: "D6",
    title: "Unvegetated intertidal reinforcement materials severity",
  },
  { id: "D7", title: "Unvegetated intertidal litter cover" },
  {
    id: "D8",
    title: "Unvegetated intertidal artificial features severity",
  },
  { id: "D9", title: "Unvegetated intertidal erosional features extent" },
  { id: "E1", title: "Subtidal aquatic morphotype richness" },
  { id: "E2", title: "Subtidal tree features richness" },
  { id: "E3", title: "Channel bed hydraulic feature richness" },
  { id: "E4", title: "Subtidal natural physical features extent" },
  { id: "E5", title: "Subtidal natural features richness" },
  { id: "E6", title: "Subtidal material richness" },
  { id: "E7", title: "Channel bed siltation" },
  { id: "E8", title: "Channel bed reinforcement extent" },
  { id: "E9", title: "Subtidal reinforcement materials severity" },
  { id: "E10", title: "Subtidal artificial features severity" },
  { id: "E11", title: "Channel bed NNIPS cover" },
  { id: "E12", title: "Subtidal filamentous algae extent" },
  { id: "E13", title: "Subtidal litter cover" },
];

function indexAttributeGroup(letter: string) {
  function normalizedAttr(id: string, title: string): Attribute {
    return numberAttr({
      attributeId: id,
      label: `${id}: ${title}`,
      decimalPlaces: 0,
      buckets: [
        lookupBucket(-4, marker.numeric(0 / 8)),
        lookupBucket(-3, marker.numeric(1 / 8)),
        lookupBucket(-2, marker.numeric(2 / 8)),
        lookupBucket(-1, marker.numeric(3 / 8)),
        lookupBucket(0, marker.numeric(4 / 8)),
        lookupBucket(1, marker.numeric(5 / 8)),
        lookupBucket(2, marker.numeric(6 / 8)),
        lookupBucket(3, marker.numeric(7 / 8)),
        lookupBucket(4, marker.numeric(8 / 8)),
      ],
    });
  }

  function unnormalizedAttr(id: string, title: string): Attribute {
    return numberAttr({
      attributeId: `${id}U`,
      label: `${id}U: ${title} (unnormalized)`,
      decimalPlaces: 0,
      buckets: "auto",
    });
  }

  const selected = indices.filter(index => index.id[0] === letter);

  return attributeGroup({
    label: `Group ${letter}`,
    attributes: [
      ...selected.map(({ id, title }) => normalizedAttr(id, title)),
      ...selected.map(({ id, title }) => unnormalizedAttr(id, title)),
    ],
  });
}

function morphEstuaries5Map(mapId: MapId, title: string): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,
    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId),
          attribution: [],
        }),
        defaultAttribute: "riverName",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "Survey Details",
            attributes: [
              stringAttr({
                attributeId: "riverName",
                label: "River",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "reachName",
                label: "Reach",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "subreachName",
                label: "Subreach",
                buckets: "auto",
              }),
              moduleNumbersAttr,
              stringAttr({
                attributeId: "projectName",
                label: "Project Name",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectCode",
                label: "MoRPh Correlation Code",
                buckets: "auto",
              }),
              surveyTypeAttr,
              stringAttr({
                attributeId: "scenarioName",
                label: "Scenario",
                buckets: "auto",
              }),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
              enumAttr({
                attributeId: "surveyBank",
                label: "Surveyed Bank",
                enum: UrsSurveyBankEnum,
              }),
            ],
          }),
          indexAttributeGroup("B"),
          indexAttributeGroup("C"),
          indexAttributeGroup("D"),
          indexAttributeGroup("E"),
        ],
      }),
    ],
  });
}

export const MrsMorphEstuaries5 = morphEstuaries5Map(
  unsafeMapId("mrsMorphEstuaries5"),
  "MoRPh Estuaries 5"
);

export const MrsMorphEstuaries5Pro = morphEstuaries5Map(
  unsafeMapId("mrsMorphEstuaries5Pro"),
  "MoRPh Estuaries 5"
);
