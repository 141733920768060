// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { WorkspaceFeatureSet } from "../types.generated";

const Starter: WorkspaceFeatureSet = "Starter";
const Standard: WorkspaceFeatureSet = "Standard";
const Plus: WorkspaceFeatureSet = "Plus";
const Premium: WorkspaceFeatureSet = "Premium";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WorkspaceFeatureSetEnum = {
  ...createEnum("WorkspaceFeatureSet", [
    [Starter, "Starter"],
    [Standard, "Standard"],
    [Plus, "Plus"],
    [Premium, "Premium"],
  ]),
  Starter,
  Standard,
  Plus,
  Premium,
};

export default WorkspaceFeatureSetEnum;
