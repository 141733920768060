import { Path } from "@cartographerio/topo-core";
import {
  attachmentField,
  Block,
  enumSelectOptions,
  featureFieldExternalMapStyle,
  Field,
  form,
  grid,
  integerField,
  minValue,
  multiSelect,
  nearestFeatureField,
  otherSpecify,
  page,
  section,
  select,
  selectOption,
  SelectOption,
  textArea,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import { numberAttr, stringAttr } from "@cartographerio/topo-map";
import {
  NeLakeNaturalnessBirdSpeciesEnum,
  NeLakeNaturalnessChemicalAssessmentFormEnum,
  NeLakeNaturalnessEmergentFringeEnum,
  NeLakeNaturalnessFishSpeciesEnum,
  NeLakeNaturalnessHerpetofaunaSpeciesEnum,
  NeLakeNaturalnessHigherPlantSpeciesEnum,
  NeLakeNaturalnessInvertebrateSpeciesEnum,
  NeLakeNaturalnessMammalSpeciesEnum,
  NeLakeNaturalnessOutflowStructureEnum,
  NeLakeNaturalnessPerimeterTreesEnum,
  NeLakeNaturalnessPlantFunctionalGroupEnum,
  NeLakeNaturalnessRiparianZoneEnum,
  NeLakeNaturalnessShorelineModificationEnum,
  NeNameSourceEnum,
  NeNaturalnessClassEnum,
  NeNaturalnessInvasivePlantSpeciesEnum,
  NeYesNoUncertainEnum,
} from "@cartographerio/inventory-enums";
import { outdent } from "outdent";
import { naturalnessSection } from "../NeRiverNaturalness/form";
import { isArrayOf } from "@cartographerio/guard";

function withNotAssessed(options: SelectOption[]): SelectOption[] {
  return [selectOption(null, "Not Assessed"), ...options];
}

interface SubCategorySelectOptions {
  label: string;
  path: Path;
  help?: string;
}

function subCategorySelect({ label, path }: SubCategorySelectOptions) {
  return select({
    label,
    secondaryLabel: "Assessment Sub-Category",
    path,
    options: withNotAssessed(enumSelectOptions(NeNaturalnessClassEnum, false)),
  });
}

interface NeLakeSiteInfoSectionProps {
  styleUrl?: string;
  sourceId?: string;
  mapAttribution?: string;
  extraFields?: Field[];
}

export function neLakeSiteInfoSection(
  props: NeLakeSiteInfoSectionProps = {}
): Block {
  const {
    styleUrl = "mapbox://styles/cartographerio/cl0js2k63000e15qpb1lj92ry",
    mapAttribution = '<a href="https://eip.ceh.ac.uk/apps/lakes/">&copy; Centre for Ecology and Hydrology</a>',
    extraFields = [],
  } = props;

  return section({
    title: "Survey/Lake Location",
    path: [],
    help: outdent`
    Select the location where you made your observations
    by clicking on the map or entering a 10-digit NGR or GPS coordinate.
    The nearest lake will automatically be highlighted.

    If the lake you visited is not a known water body on the map
    (it doesn't have a blue outline) it won't be outlined on the map.
    We still want to know about the lake, though,
    so fill in the "Custom Lake Name" field instead.
    `,
    blocks: [
      grid({
        columns: 2,
        blocks: [
          nearestFeatureField({
            label: "Survey Location",
            path: ["data", "site", "picked"],
            fullWidth: true,
            mapOptions: {
              mapStyle: featureFieldExternalMapStyle({
                styleUrl,
                sourceId: "ne_lake_sites",
                primaryKey: "WBID",
                selectableLayer: { id: "ne_lake_sites", type: "fill" },
                selectedLayers: [
                  { id: "ne_lake_sites_selected", type: "fill" },
                  { id: "ne_lake_sites_selected_outline", type: "line" },
                ],
              }),
              selectMinZoom: 12,
              attribution: [mapAttribution],
              attributes: [
                stringAttr({
                  attributeId: "NAME",
                  label: "Name",
                  buckets: "auto",
                }),
                numberAttr({
                  attributeId: "WBID",
                  label: "Waterbody ID",
                  buckets: "auto",
                }),
              ],
            },
          }),
          textField({
            label: "Custom Lake Name",
            path: ["data", "site", "customLakeName"],
            help: outdent`
            If the lake you selected from the map is incorrectly named,
            you can manually specify it here.
            `,
          }),
          select({
            label: "Lake Name Source",
            path: ["data", "site", "lakeNameSource"],
            options: enumSelectOptions(NeNameSourceEnum),
            help: outdent`
            Indicate the source if you manually assigned a lake name above.
            `,
          }),
          // textField({
          //   label: "Custom GB Lakes Inventory ID",
          //   path: ["data", "site", "customWaterbodyId"],
          //   help: outdent`
          // }),
          ...extraFields,
        ],
      }),
    ],
  });
}

export default form({
  title: "Lake Naturalness",
  pages: [
    page({
      title: null,
      path: [],
      help: outdent`
      For further information on completing this assessment,
      please see the [guidance document](https://priorityhabitats.org/lake-naturalness-form-guidance)
      `,
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                timestampField({
                  label: "Date and Time of Survey",
                  path: ["data", "recorded"],
                }),
              ],
            }),
          ],
        }),
        neLakeSiteInfoSection({
          extraFields: [
            select({
              label: "Is the surveyed site representative of the whole lake?",
              path: ["data", "naturalness", "representative"],
              options: enumSelectOptions(NeYesNoUncertainEnum),
            }),
          ],
        }),
        naturalnessSection({
          title: "Physical Naturalness",
          path: ["data", "naturalness", "physical"],
          blocks: [
            subCategorySelect({
              label: "Shoreline Condition",
              path: ["subCategoriesAssessed", "ShorelineCondition"],
            }),
            subCategorySelect({
              label: "Riparian Land",
              path: ["subCategoriesAssessed", "RiparianLand"],
            }),
            subCategorySelect({
              label: "Lake Morphology",
              help: "If of artificial origin",
              path: ["subCategoriesAssessed", "LakeMorphology"],
            }),
          ],
        }),
        naturalnessSection({
          title: "Hydrological Naturalness",
          path: ["data", "naturalness", "hydrological"],
          blocks: [
            subCategorySelect({
              label: "Structures",
              path: ["subCategoriesAssessed", "Structures"],
            }),
            subCategorySelect({
              label: "Water Level Fluctuations",
              path: ["subCategoriesAssessed", "WaterLevelFluctuations"],
            }),
            subCategorySelect({
              label: "Inflows and Outflows",
              path: ["subCategoriesAssessed", "InflowsAndOutflows"],
            }),
          ],
        }),
        naturalnessSection({
          title: "Chemical Naturalness",
          path: ["data", "naturalness", "chemical"],
          blocks: [
            multiSelect({
              label: "Forms of Assessment",
              path: ["formsOfAssessment"],
              options: enumSelectOptions(
                NeLakeNaturalnessChemicalAssessmentFormEnum
              ),
            }),
          ],
        }),
        naturalnessSection({
          title: "Biological Naturalness",
          path: ["data", "naturalness", "biological"],
          blocks: [
            subCategorySelect({
              label: "Non-Native Plants",
              path: ["subCategoriesAssessed", "NonNativePlants"],
            }),
            subCategorySelect({
              label: "Non-Native Animals",
              path: ["subCategoriesAssessed", "NonNativeAnimals"],
            }),
            vstack(
              multiSelect({
                label: "Invasive Plant Species",
                path: ["invasivePlantSpecies"],
                options: NeNaturalnessInvasivePlantSpeciesEnum.entries.map(
                  ({ value, label }) => ({
                    value,
                    label:
                      value === "Other" ? `${label} (please specify)` : label,
                  })
                ),
              }),
              otherSpecify({
                level: "info",
                label: "Other Invasive Plant Species",
                thisPath: ["otherInvasivePlantSpecies"],
                thatPath: ["invasivePlantSpecies"],
                test: (value: unknown) =>
                  isArrayOf(NeNaturalnessInvasivePlantSpeciesEnum.isValue)(
                    value
                  ) &&
                  value.includes(NeNaturalnessInvasivePlantSpeciesEnum.Other),
                visible: "auto",
              })
            ),
          ],
        }),
        section({
          title: "Notes",
          path: [],
          help: outdent`
          Provide a general description of the site in relation to each naturalness component,
          drawing attention to any particular features of interest or impacts of concern.
          `,
          blocks: [
            textArea({
              label: null,
              path: ["data", "notes"],
              rows: 10,
            }),
          ],
        }),
        section({
          title: "Photographs and Other Files",
          path: [],
          help: outdent`
          Take at least one picture of the lake.
          Other photos can focus on particular features of the lake.
          You may want to add a file providing more detail about the site
          such as a detailed site description or species list (this is optional).
          `,
          blocks: [
            attachmentField({
              label: null,
              path: ["data", "photographs"],
              maxFiles: 4,
            }),
          ],
        }),
        section({
          title: "Plant Functional Groups",
          path: [],
          help: outdent`
          Please refer to the [guidance document](https://priorityhabitats.org/lake-naturalness-form-guidance)
          for more information on completing this section.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                multiSelect({
                  label: null,
                  path: ["data", "plantFunctionalGroups"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessPlantFunctionalGroupEnum
                  ),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Species of Interest",
          path: ["data", "species"],
          help: outdent`
          If you happen to see any of the species below please tick the appropriate box.
          Please refer to the [guidance document](https://priorityhabitats.org/lake-naturalness-form-guidance)
          for more information about recording rare and threatened species,
          and use [iRecord](https://www.brc.ac.uk/irecord/)
          to make sure important sightings are captured in national species inventories.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                multiSelect({
                  label: "Mammals",
                  path: ["mammals"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessMammalSpeciesEnum
                  ),
                }),
                multiSelect({
                  label: "Birds",
                  path: ["birds"],
                  options: enumSelectOptions(NeLakeNaturalnessBirdSpeciesEnum),
                }),
                multiSelect({
                  label: "Fish",
                  path: ["fish"],
                  options: enumSelectOptions(NeLakeNaturalnessFishSpeciesEnum),
                }),
                multiSelect({
                  label: "Herpetofauna",
                  path: ["herpetofauna"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessHerpetofaunaSpeciesEnum
                  ),
                }),
                multiSelect({
                  label: "Invertebrates",
                  path: ["invertebrates"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessInvertebrateSpeciesEnum
                  ),
                }),
                multiSelect({
                  label: "Plants",
                  path: ["higherPlants"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessHigherPlantSpeciesEnum
                  ),
                }),
              ],
            }),
          ],
        }),
        section({
          title: "Habitat Features",
          path: ["data", "habitatFeatures"],
          help: outdent`
          Please refer to the [guidance document](https://priorityhabitats.org/lake-naturalness-form-guidance)
          for more information on completing this section.
          `,
          blocks: [
            grid({
              columns: 2,
              blocks: [
                select({
                  label: "Shoreline modification",
                  path: ["shorelineModification"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessShorelineModificationEnum
                  ),
                }),
                select({
                  label: "Shoreline with fringing marginal emergent vegetation",
                  path: ["emergentFringe"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessEmergentFringeEnum
                  ),
                }),
                select({
                  label: "Riparian zone (up to 10m from bank)",
                  path: ["riparianZone"],
                  options: enumSelectOptions(NeLakeNaturalnessRiparianZoneEnum),
                }),
                integerField({
                  label: "Number of ditches flowing into the lake",
                  path: ["ditches"],
                  bounds: minValue(0),
                }),
                select({
                  label: "Perimeter trees",
                  path: ["perimeterTrees"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessPerimeterTreesEnum
                  ),
                }),
                select({
                  label: "Outflow structure",
                  path: ["outflowStructure"],
                  options: enumSelectOptions(
                    NeLakeNaturalnessOutflowStructureEnum
                  ),
                }),
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});
