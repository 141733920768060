// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type NwcSeedbankCollection = "None" | "Intending" | "Collected";

const None: NwcSeedbankCollection = "None";
const Intending: NwcSeedbankCollection = "Intending";
const Collected: NwcSeedbankCollection = "Collected";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NwcSeedbankCollectionEnum = {
  ...createEnum("NwcSeedbankCollection", [
    [None, "No - I am not collecting seeds"],
    [Intending, "Yes - I intend to collect seeds"],
    [Collected, "Yes - I have collected seeds"],
  ]),
  None,
  Intending,
  Collected,
};

export default NwcSeedbankCollectionEnum;
