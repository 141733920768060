import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";

export interface PartialData {
  recorded?: Timestamp | null;
  location?: Point | null;
  catchment?: string | null;
  river?: string | null;
  site?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "catchment", isNullable(isString)) &&
    hasOptionalKey(data, "river", isNullable(isString)) &&
    hasOptionalKey(data, "site", isNullable(isString))
  );
}

function createDescription(...args: Array<string | null>): string {
  return args.filter(arg => arg != null).join(", ");
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialWrtUpstreamThinkingData"
  )(data).map(data =>
    createDescription(
      data.site ?? "Unknown site",
      data.river ?? "Unknown river",
      data.catchment ?? "Unknown catchment",
      data.location ? safePointToNgr(data.location) : null
    )
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialWrtUpstreamThinkingData"
  )(data).map(data => data.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialWrtUpstreamThinkingData"
  )(data).map(data => data.recorded ?? null);
}
