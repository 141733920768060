// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityRainfall = "None" | "Light" | "Heavy";

const None: ArrtWaterQualityRainfall = "None";
const Light: ArrtWaterQualityRainfall = "Light";
const Heavy: ArrtWaterQualityRainfall = "Heavy";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityRainfallEnum = {
  ...createEnum("ArrtWaterQualityRainfall", [
    [None, "None"],
    [Light, "Light Rain/Showers"],
    [Heavy, "Heavy or Prolonged Rain"],
  ]),
  None,
  Light,
  Heavy,
};

export default ArrtWaterQualityRainfallEnum;
