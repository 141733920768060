// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type StSmartRiversProject = "SmartRivers" | "Riverfly" | "Other";

const SmartRivers: StSmartRiversProject = "SmartRivers";
const Riverfly: StSmartRiversProject = "Riverfly";
const Other: StSmartRiversProject = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StSmartRiversProjectEnum = {
  ...createEnum("StSmartRiversProject", [
    [SmartRivers, "SmartRivers"],
    [Riverfly, "Riverfly Census"],
    [Other, "Other partner submitted data"],
  ]),
  SmartRivers,
  Riverfly,
  Other,
};

export default StSmartRiversProjectEnum;
