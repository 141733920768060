import { WorkspaceLimits, WorkspaceUsage } from "@cartographerio/types";
import { chakra } from "@chakra-ui/react";
import outdent from "outdent";
import { ReactElement, useMemo, useState } from "react";

import { splitMessages } from "../../schema/rule/errors";
import { workspaceLimitsRule } from "../../schema/WorkspaceLimits";
import Fieldset from "../components/Fieldset";
import HelpText from "../components/HelpText";
import MessageFormControl from "../components/MessageFormControl";
import SaveButton from "../components/SaveButton";
import TextField from "../components/TextField";

interface UsageLimitsSectionProps {
  limits: WorkspaceLimits;
  usage: WorkspaceUsage;
  onLimitsChange?: (newLimits: WorkspaceLimits) => void;
}

export default function UsageLimitsSection(
  props: UsageLimitsSectionProps
): ReactElement {
  const { usage, limits, onLimitsChange } = props;

  const [newLimits, setNewLimits] = useState(() => limits);

  const { rule, keys } = useMemo(() => workspaceLimitsRule(usage), [usage]);

  const messages = useMemo(() => rule(newLimits), [newLimits, rule]);
  const errors = useMemo(() => splitMessages(messages, keys), [keys, messages]);

  return (
    <Fieldset
      legend={
        <>
          Limits <chakra.em>(Optional)</chakra.em>
        </>
      }
    >
      <HelpText text={LIMITS_HELP} />
      <MessageFormControl
        label="Member Limit"
        messages={errors.maxUsers}
        help={MEMBER_LIMIT_HELP}
      >
        <TextField.NullableNumber
          value={newLimits.maxUsers}
          placeholder="No limit"
          clearButton={true}
          decimalPlaces={0}
          onChange={maxUsers => setNewLimits({ ...newLimits, maxUsers })}
        />
      </MessageFormControl>
      <MessageFormControl
        label="Project Limit"
        messages={errors.maxProjects}
        help={PROJECT_LIMIT_HELP}
      >
        <TextField.NullableNumber
          value={newLimits.maxProjects}
          placeholder="No limit"
          clearButton={true}
          decimalPlaces={0}
          onChange={maxProjects => setNewLimits({ ...newLimits, maxProjects })}
        />
      </MessageFormControl>
      <SaveButton
        label="Save"
        messages={messages}
        onClick={() => onLimitsChange?.(newLimits)}
        colorScheme="gray"
      />
    </Fieldset>
  );
}

const LIMITS_HELP = outdent`
  Optionally limit the number of users/projects in your
  workspace to stop people adding too many:
`;

const MEMBER_LIMIT_HELP = outdent`
  We'll prevent you inviting or adding new people
  when the number of **members** on the **Usage** panel
  reaches the limit below.
`;

const PROJECT_LIMIT_HELP = outdent`
  We'll prevent you adding new projects
  when the number of **projects** on the **Usage** panel
  reaches the limit below.
`;
