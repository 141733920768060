import { WorkspaceAccessLevel } from "./types.generated";

const ACCESS_LEVEL_ORDER: WorkspaceAccessLevel[] = ["None", "ReadOnly", "Full"];

export function workspaceAccessLevelGte(
  a: WorkspaceAccessLevel,
  b: WorkspaceAccessLevel
): boolean {
  return ACCESS_LEVEL_ORDER.indexOf(a) >= ACCESS_LEVEL_ORDER.indexOf(b);
}
