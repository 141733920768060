// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesBankFeature = "activeBankErosion" | "pontoonFormsBank";

const ActiveBankErosion: CesBankFeature = "activeBankErosion";
const PontoonFormsBank: CesBankFeature = "pontoonFormsBank";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesBankFeatureEnum = {
  ...createEnum("CesBankFeature", [
    [ActiveBankErosion, "Active bank erosion"],
    [PontoonFormsBank, "Pontoon forms bank"],
  ]),
  ActiveBankErosion,
  PontoonFormsBank,
};

export default CesBankFeatureEnum;
