import {
  hasKey,
  hasOptionalKey,
  isArrayOf,
  isBoolean,
  isNumber,
  isObject,
  isOptional,
  isString,
} from "@cartographerio/guard";
import { Semver, isSemver } from "../core";
import { ApiAuth, isApiAuth } from "./auth";

export interface ApiClientInfo {
  name: string; // e.g. "CartographerMobile"
  version: Semver;
  build?: number; // build number
  hints?: string[]; // e.g. "Darwin/1.2.3", "iPhone/12", ...
  overrideUserAgent?: boolean;
}

export interface ApiConfig {
  baseUrl: string;
  client?: ApiClientInfo;
}

export function isApiClientInfo(obj: unknown): obj is ApiClientInfo {
  return (
    isObject(obj) &&
    hasKey(obj, "version", isSemver) &&
    hasOptionalKey(obj, "build", isNumber) &&
    hasOptionalKey(obj, "hints", isArrayOf(isString)) &&
    hasOptionalKey(obj, "overrideUserAgent", isBoolean)
  );
}

export function isApiConfig(obj: unknown): obj is ApiConfig {
  return (
    isObject(obj) &&
    hasKey(obj, "baseUrl", isString) &&
    hasOptionalKey(obj, "client", isApiClientInfo)
  );
}

export interface ApiParams {
  apiConfig: ApiConfig;
  auth?: ApiAuth;
}

export function isApiParams(obj: unknown): obj is ApiParams {
  return (
    isObject(obj) &&
    hasKey(obj, "apiConfig", isApiConfig) &&
    hasOptionalKey(obj, "auth", isOptional(isApiAuth))
  );
}
