// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusNoneOneMore = "none" | "one" | "moreThanOne";

const None: EcostatusNoneOneMore = "none";
const One: EcostatusNoneOneMore = "one";
const MoreThanOne: EcostatusNoneOneMore = "moreThanOne";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusNoneOneMoreEnum = {
  ...createEnum("EcostatusNoneOneMore", [
    [None, "None"],
    [One, "One"],
    [MoreThanOne, ">One"],
  ]),
  None,
  One,
  MoreThanOne,
};

export default EcostatusNoneOneMoreEnum;
