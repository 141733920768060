import { Env } from "@cartographerio/topo-core";
import { Result } from "@cartographerio/fp";
import { GuardError, isArray } from "@cartographerio/guard";
import { checkExhausted } from "@cartographerio/util";
import { topoEval, optTopoEval } from "../expr/run";
import { isFieldState } from "../guard";
import { runRules } from "../rule";
import { fieldImage } from "./image";
import { initialFormState, initialRepeatStateFromArray } from "./initial";
import { messageCounts, messageHighlight, pageMessageCounts } from "./message";
import {
  FormAction,
  BlockState,
  CardState,
  ContentState,
  DynamicGridState,
  DynamicImageState,
  DynamicValueState,
  FieldState,
  FormState,
  FullWidthState,
  GroupState,
  PageState,
  PrimarySurveyorFieldState,
  RepeatState,
  RequireRoleState,
  ScopeState,
  TextState,
  VisibilityState,
  PrimaryTeamFieldState,
} from "./type";
import { isEqual } from "lodash";
import { isTeamId, isUserRef } from "@cartographerio/types";

export function fieldReducer(
  state: FieldState,
  parentEnv: Env,
  action: FormAction
): FieldState {
  const { field } = state;

  switch (action.type) {
    case "Reset":
    case "Change": {
      const env = parentEnv.focus(field.path);
      const value = env.getFocused();
      const image = fieldImage(field, value);
      const messages = runRules(field, env);
      const counts = messageCounts(messages);
      const highlight = messageHighlight(counts);
      const visible =
        optTopoEval(field.visible, parentEnv).getOrUndefined() ?? true;

      return {
        ...state,
        value,
        image,
        messages,
        highlight,
        visible,
        ...counts,
      };
    }

    case "Insert":
    case "Delete":
      return state;
    default:
      return checkExhausted(action);
  }
}

function passThroughChildReducer<S extends { childState: BlockState }>() {
  return (state: S, parentEnv: Env, action: FormAction): S => ({
    ...state,
    childState: blockReducer(state.childState, parentEnv, action),
  });
}

export const cardReducer = passThroughChildReducer<CardState>();

export function dynamicValueReducer(
  state: DynamicValueState,
  parentEnv: Env,
  _action: FormAction
): DynamicValueState {
  const { block } = state;

  return {
    ...state,
    attribute: topoEval(block.value, parentEnv)
      .map(value => ({
        label: block.label,
        valueType: block.valueType,
        units: block.units,
        decimalPlaces: block.decimalPlaces,
        value,
        error: null,
      }))
      .getOrElse(message => ({
        label: block.label,
        valueType: block.valueType,
        units: block.units,
        decimalPlaces: block.decimalPlaces,
        value: null,
        error: String(message),
      })),
  };
}

export function dynamicGridReducer(
  state: DynamicGridState,
  parentEnv: Env,
  _action: FormAction
): DynamicGridState {
  const { block } = state;

  return {
    ...state,
    attributes: block.attributes.map(attribute =>
      topoEval(attribute.value, parentEnv)
        .map(value => ({
          label: attribute.label,
          valueType: attribute.valueType,
          units: attribute.units,
          decimalPlaces: attribute.decimalPlaces,
          value,
          error: null,
        }))
        .getOrElse(message => ({
          label: attribute.label,
          valueType: attribute.valueType,
          units: attribute.units,
          decimalPlaces: attribute.decimalPlaces,
          value: null,
          error: String(message),
        }))
    ),
  };
}

export function dynamicImageReducer(
  state: DynamicImageState,
  parentEnv: Env,
  _action: FormAction
): DynamicImageState {
  const urlResult: Result<string, string | null> = topoEval(
    state.block.url,
    parentEnv
  ).mapError(String);

  const captionResult: Result<string, string | null | undefined> = optTopoEval(
    state.block.caption,
    parentEnv
  ).mapError(String);

  return {
    ...state,
    url: urlResult.getOrNull(),
    urlError: urlResult.swap().getOrNull(),
    caption: captionResult.getOrNull(),
    captionError: captionResult?.swap().getOrNull(),
  };
}

export const fullWidthReducer = passThroughChildReducer<FullWidthState>();

export function gridReducer(
  state: GroupState,
  parentEnv: Env,
  action: FormAction
): GroupState {
  return {
    ...state,
    blockStates: state.blockStates.map(bs =>
      blockReducer(bs, parentEnv, action)
    ),
  };
}

export function repeatReducer(
  state: RepeatState,
  parentEnv: Env,
  action: FormAction
): RepeatState {
  const { block, childStates, arraySchema } = state;

  const arrayEnv = parentEnv.focus(block.path);

  switch (action.type) {
    case "Reset":
    case "Change": {
      return {
        ...state,
        childStates: childStates.map((child, index) =>
          blockReducer(child, arrayEnv.focus([index]), action)
        ),
      };
    }

    case "Insert":
    case "Delete":
      return initialRepeatStateFromArray(block, arraySchema, arrayEnv);

    default:
      return checkExhausted(action);
  }
}

export const requireRoleReducer = passThroughChildReducer<RequireRoleState>();

export function textReducer(
  state: TextState,
  parentEnv: Env,
  _action: FormAction
): TextState {
  return {
    ...state,
    visible:
      optTopoEval(state.block.visible, parentEnv).getOrUndefined() ?? true,
  };
}

export function scopeReducer(
  state: ScopeState,
  parentEnv: Env,
  action: FormAction
): ScopeState {
  return {
    ...state,
    childState: blockReducer(
      state.childState,
      parentEnv.focus(state.block.path),
      action
    ),
  };
}

export function visibilityReducer(
  state: VisibilityState,
  parentEnv: Env,
  action: FormAction
): VisibilityState {
  return {
    ...state,
    visible: topoEval(state.block.visible, parentEnv).getOrUndefined() ?? true,
    childState: blockReducer(state.childState, parentEnv, action),
  };
}

export function primarySurveyorFieldReducer(
  state: PrimarySurveyorFieldState,
  parentEnv: Env,
  action: FormAction
): PrimarySurveyorFieldState {
  if (
    action.type === "Change" &&
    (isEqual(action.path, ["surveyor"]) || isEqual(action.path, ["teamId"]))
  ) {
    const surveyor = parentEnv
      .getAbsoluteAs(["surveyor"], isUserRef)
      .getOrNull();
    const teamId = parentEnv.getAbsoluteAs(["teamId"], isTeamId).getOrNull();
    const messages = state.field.rule(parentEnv);
    const counts = messageCounts(messages);
    return {
      ...state,
      surveyor,
      teamId,
      messages,
      ...counts,
      highlight: messageHighlight(counts),
    };
  } else {
    return state;
  }
}

export function primaryTeamFieldReducer(
  state: PrimaryTeamFieldState,
  parentEnv: Env,
  action: FormAction
): PrimaryTeamFieldState {
  if (
    action.type === "Change" &&
    (isEqual(action.path, ["surveyor"]) || isEqual(action.path, ["teamId"]))
  ) {
    const surveyor = parentEnv
      .getAbsoluteAs(["surveyor"], isUserRef)
      .getOrNull();
    const teamId = parentEnv.getAbsoluteAs(["teamId"], isTeamId).getOrNull();
    const messages = state.field.rule(parentEnv);
    const counts = messageCounts(messages);
    return {
      ...state,
      surveyor,
      teamId,
      messages,
      ...counts,
      highlight: messageHighlight(counts),
    };
  } else {
    return state;
  }
}

export function contentReducer(
  state: ContentState,
  parentEnv: Env,
  action: FormAction
): ContentState {
  switch (state.type) {
    case "CardState":
      return cardReducer(state, parentEnv, action);
    case "DynamicValueState":
      return dynamicValueReducer(state, parentEnv, action);
    case "DynamicGridState":
      return dynamicGridReducer(state, parentEnv, action);
    case "DynamicImageState":
      return dynamicImageReducer(state, parentEnv, action);
    case "FullWidthState":
      return fullWidthReducer(state, parentEnv, action);
    case "HeadingState":
      return state;
    case "TextState":
      return textReducer(state, parentEnv, action);
    case "GroupState":
      return gridReducer(state, parentEnv, action);
    case "RepeatState":
      return repeatReducer(state, parentEnv, action);
    case "RequireRoleState":
      return requireRoleReducer(state, parentEnv, action);
    case "ScopeState":
      return scopeReducer(state, parentEnv, action);
    case "VisibilityState":
      return visibilityReducer(state, parentEnv, action);
    case "PrimarySurveyorFieldState":
      return primarySurveyorFieldReducer(state, parentEnv, action);
    case "PrimaryTeamFieldState":
      return primaryTeamFieldReducer(state, parentEnv, action);
    default:
      return checkExhausted(state);
  }
}

export function blockReducer(
  state: BlockState,
  parentEnv: Env,
  action: FormAction
): BlockState {
  if (isFieldState(state)) {
    return fieldReducer(state, parentEnv, action);
  } else {
    return contentReducer(state, parentEnv, action);
  }
}

export function pageReducer(
  state: PageState,
  formEnv: Env,
  action: FormAction
): PageState {
  const { page, blockStates: blockStates0 } = state;

  const env = formEnv.focus(page.path);

  const blockStates = blockStates0.map(state =>
    blockReducer(state, env, action)
  );

  const counts = pageMessageCounts(blockStates);
  const highlight = messageHighlight(counts);

  const enabled: boolean =
    optTopoEval(page.enabled, env).getOrUndefined() ?? true;

  return {
    ...state,
    blockStates,
    highlight,
    enabled,
    ...counts,
  };
}

export function formReducer(state: FormState, action: FormAction): FormState {
  if (action.type === "Reset") {
    return initialFormState(action.form, action.schema, action.document);
  } else {
    const { document: survey0, pageStates: pageStates0 } = state;

    let env: Env;
    switch (action.type) {
      case "Change": {
        env = Env.create(survey0)
          .setAbsolute(action.path, action.value)
          .getOrThrow();
        break;
      }

      case "Insert": {
        env = Env.create(survey0)
          .updateAbsoluteAs(isArray, action.path, array => [
            ...array,
            ...action.values,
          ])
          .getOrThrow();
        break;
      }

      case "Delete": {
        env = Env.create(survey0)
          .updateAbsoluteAs(isArray, action.path, array => [
            ...array.slice(0, action.index),
            ...array.slice(action.index + 1),
          ])
          .getOrThrow();
        break;
      }

      default:
        checkExhausted(action);
    }

    const pageStates = pageStates0.map(ps => pageReducer(ps, env, action));

    const errorCount = pageStates.reduce(
      (total, pageState) => total + pageState.errorCount,
      0
    );

    const infoCount = pageStates.reduce(
      (total, pageState) => total + pageState.infoCount,
      0
    );

    const document = env.doc();

    return { ...state, document, pageStates, errorCount, infoCount };
  }
}

export function formStateDocument<A>(
  state: FormState,
  guard: (value: unknown) => value is A,
  hint?: string
): Result<GuardError, A> {
  return Result.pure<GuardError, unknown>(state.document).guard(guard, hint);
}
