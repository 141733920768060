import { unsafeTag } from "@cartographerio/util";
import { Semver } from "./types.generated";
import * as semverlib from "semver";
import { Option } from "@cartographerio/fp";

export function semver(
  major: number,
  minor: number = 0,
  patch: number = 0
): Semver {
  return unsafeTag(`${major}.${minor}.${patch}`);
}

export function unsafeSemver(version: string): Semver {
  return unsafeTag(version);
}

export function parseSemver(version: string): Option<Semver> {
  return Option.wrap(semverlib.parse(version, { loose: true }))
    .map(version => `${version.major}.${version.minor}.${version.patch}`)
    .map(unsafeSemver);
}

export function semverMajor(version: Semver): number {
  return semverlib.major(version);
}

export function semverMinor(version: Semver): number {
  return semverlib.minor(version);
}

export function semverPatch(version: Semver): number {
  return semverlib.patch(version);
}

export function semverLt(x: Semver, y: Semver): boolean {
  return semverlib.lt(x, y);
}

export function semverGt(x: Semver, y: Semver): boolean {
  return semverlib.gt(x, y);
}

export function semverLteq(x: Semver, y: Semver): boolean {
  return semverlib.lte(x, y);
}

export function semverGteq(x: Semver, y: Semver): boolean {
  return semverlib.gte(x, y);
}
