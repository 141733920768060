import { Result } from "@cartographerio/fp";
import { GeometryAtom } from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  attachmentField,
  Form,
  form,
  numberField,
  page,
  required,
  section,
  textField,
  timestampField,
} from "@cartographerio/topo-form";
import {
  attachmentFolderSchema,
  nullableSchema,
  numberSchema,
  pointSchema,
  productSchema,
  Schema,
  stringSchema,
  timestampSchema,
} from "@cartographerio/topo-survey";
import {
  SurveyNames,
  Timestamp,
  unsafeSurveyModuleId,
} from "@cartographerio/types";

export const moduleId = unsafeSurveyModuleId("TestSurvey1");

export const names: SurveyNames = {
  shortName: "Survey 1",
  longName: "Test Survey 1",
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    (data: unknown): data is { text: string } =>
      isObject(data) && hasOptionalKey(data, "text", isNullable(isString))
  )(data).map(
    data => "Test Survey 1" + (data.text == null ? "" : `: ${data.text}`)
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.pure((data as any).location);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.pure((data as any).timestamp);
}

export function copyData(data: any): Result<GuardError, unknown> {
  return Result.pure({ ...data, timestamp: undefined });
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-explicit-any */

export const dataSchema: Schema = productSchema({
  location: nullableSchema(pointSchema),
  timestamp: nullableSchema(timestampSchema),
  number: nullableSchema(numberSchema),
  text: nullableSchema(stringSchema),
  photographs: attachmentFolderSchema,
});

export const formSchema: Form = form({
  title: names.shortName,
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date/Time",
              path: ["data", "timestamp"],
              required: required("info"),
              defaultValue: "now",
            }),
            numberField({
              label: "Number",
              path: ["data", "number"],
              required: required("info"),
            }),
            textField({
              label: "Text",
              path: ["data", "text"],
              required: required("info"),
            }),
            attachmentField({
              label: "Photographs",
              path: ["data", "photographs"],
              fullWidth: true,
              maxFiles: 4,
            }),
          ],
        }),
      ],
    }),
  ],
});
