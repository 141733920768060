// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMorphEstuariesSaltMarshLocation = "M" | "L" | "B";

const Middle: MrsMorphEstuariesSaltMarshLocation = "M";
const Lower: MrsMorphEstuariesSaltMarshLocation = "L";
const Both: MrsMorphEstuariesSaltMarshLocation = "B";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMorphEstuariesSaltMarshLocationEnum = {
  ...createEnum("MrsMorphEstuariesSaltMarshLocation", [
    [Middle, "Middle"],
    [Lower, "Lower"],
    [Both, "Both"],
  ]),
  Middle,
  Lower,
  Both,
};

export default MrsMorphEstuariesSaltMarshLocationEnum;
