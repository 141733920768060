// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type CesReinforcementMaterial =
  | "CC"
  | "BS"
  | "BW"
  | "WS"
  | "CB"
  | "SP"
  | "RR"
  | "BC"
  | "WP"
  | "GA"
  | "WO";

const Concrete: CesReinforcementMaterial = "CC";
const BrickOrStoneBlocks: CesReinforcementMaterial = "BS";
const BuildersWaste: CesReinforcementMaterial = "BW";
const WillowSpiling: CesReinforcementMaterial = "WS";
const ConcreteAndBrick: CesReinforcementMaterial = "CB";
const MetalSheets: CesReinforcementMaterial = "SP";
const RipRap: CesReinforcementMaterial = "RR";
const Biotextiles: CesReinforcementMaterial = "BC";
const WoodPanels: CesReinforcementMaterial = "WP";
const Gabions: CesReinforcementMaterial = "GA";
const WashedOut: CesReinforcementMaterial = "WO";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CesReinforcementMaterialEnum = {
  ...createEnum("CesReinforcementMaterial", [
    [Concrete, "Concrete (including piling/slabs)"],
    [BrickOrStoneBlocks, "Brick / stone / blocks (cemented)"],
    [BuildersWaste, "Hard core / builder's waste"],
    [WillowSpiling, "Willow spiling"],
    [ConcreteAndBrick, "Concrete and brick / stone (cemented)"],
    [MetalSheets, "Metal sheets / piling"],
    [RipRap, "Rip-rap (laid stone, no cement)"],
    [Biotextiles, "Biotextiles/coir"],
    [WoodPanels, "Wood panels / planks / piling"],
    [Gabions, "Gabions / rock rolls"],
    [WashedOut, "Washed out"],
  ]),
  Concrete,
  BrickOrStoneBlocks,
  BuildersWaste,
  WillowSpiling,
  ConcreteAndBrick,
  MetalSheets,
  RipRap,
  Biotextiles,
  WoodPanels,
  Gabions,
  WashedOut,
};

export default CesReinforcementMaterialEnum;
