// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityRiverbedComposition =
  | "CobbleOrStone"
  | "GravelOrPebble"
  | "Sand"
  | "SiltOrMud"
  | "LeafLitter"
  | "Clay"
  | "Artificial"
  | "NotVisible"
  | "Other";

const CobbleOrStone: EsrtWaterQualityRiverbedComposition = "CobbleOrStone";
const GravelOrPebble: EsrtWaterQualityRiverbedComposition = "GravelOrPebble";
const Sand: EsrtWaterQualityRiverbedComposition = "Sand";
const SiltOrMud: EsrtWaterQualityRiverbedComposition = "SiltOrMud";
const LeafLitter: EsrtWaterQualityRiverbedComposition = "LeafLitter";
const Clay: EsrtWaterQualityRiverbedComposition = "Clay";
const Artificial: EsrtWaterQualityRiverbedComposition = "Artificial";
const NotVisible: EsrtWaterQualityRiverbedComposition = "NotVisible";
const Other: EsrtWaterQualityRiverbedComposition = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityRiverbedCompositionEnum = {
  ...createEnum("EsrtWaterQualityRiverbedComposition", [
    [CobbleOrStone, "Cobble/stone"],
    [GravelOrPebble, "Gravel/pebble"],
    [Sand, "Sand"],
    [SiltOrMud, "Silt/mud"],
    [LeafLitter, "Leaf litter"],
    [Clay, "Clay"],
    [Artificial, "Artificial"],
    [NotVisible, "Not visible"],
    [Other, "Other"],
  ]),
  CobbleOrStone,
  GravelOrPebble,
  Sand,
  SiltOrMud,
  LeafLitter,
  Clay,
  Artificial,
  NotVisible,
  Other,
};

export default EsrtWaterQualityRiverbedCompositionEnum;
