import {
  createMessage,
  Env,
  errorMessage,
  Message,
} from "@cartographerio/topo-core";
import { checkExhausted, prettyPrintJson } from "@cartographerio/util";
import { chain } from "lodash";
import {
  CustomRuleField,
  Field,
  NumericField,
  RequiredRuleField,
  TimestampField,
} from "../type";
import {
  boundsRulePasses,
  customRulePasses,
  requiredRulePasses,
  timestampBoundsRulePasses,
} from "./pass";
import {
  boundsRuleDefaultMessage,
  requiredRuleDefaultMessage,
  timestampBoundsRuleDefaultMessage,
} from "./text";
import {
  BoundsRule,
  CustomRule,
  RequiredRule,
  TimestampBoundsRule,
} from "./type";
import { isTimestamp, nowTimestamp, Timestamp } from "@cartographerio/types";

export function runRequiredRule(
  fieldType: RequiredRuleField["type"],
  rule: RequiredRule,
  value: unknown
): Message[] {
  return requiredRulePasses(fieldType, value)
    ? []
    : [
        createMessage(
          rule.level,
          rule.message ?? requiredRuleDefaultMessage(rule.level)
        ),
      ];
}

export function runCustomRule(rule: CustomRule, env: Env): Message[] {
  return customRulePasses(rule.triggerWhen, env).fold(
    e => [errorMessage(String(e))],
    ans => (ans ? [createMessage(rule.level, rule.message)] : [])
  );
}

export function runBoundsRule(
  rule: BoundsRule,
  value: number | null | undefined,
  valueDescription: string = "The value"
): Message[] {
  if (boundsRulePasses(rule.minValue, rule.maxValue, value)) {
    return [];
  } else {
    return [
      createMessage(
        rule.level,
        rule.message ??
          boundsRuleDefaultMessage(
            rule.level,
            rule.minValue,
            rule.maxValue,
            valueDescription
          )
      ),
    ];
  }
}

export function runTimestampBoundsRule(
  rule: TimestampBoundsRule,
  value: Timestamp | null | undefined,
  valueDescription: string = "The value",
  now?: Timestamp
): Message[] {
  if (
    timestampBoundsRulePasses(
      rule.minValue,
      rule.maxValue,
      value,
      rule.validateTime,
      now
    )
  ) {
    return [];
  } else {
    return [
      createMessage(
        rule.level,
        rule.message ??
          timestampBoundsRuleDefaultMessage(
            rule.level,
            rule.minValue ?? null,
            rule.maxValue ?? null,
            rule.validateTime,
            valueDescription,
            now
          )
      ),
    ];
  }
}

export function runCustomRules(field: CustomRuleField, env: Env): Message[] {
  return chain(field.customRules ?? [])
    .flatMap(rule => runCustomRule(rule, env))
    .value();
}

export function runRequiredRules(
  field: RequiredRuleField,
  env: Env
): Message[] {
  return field.required != null
    ? runRequiredRule(field.type, field.required, env.getFocused())
    : [];
}

export function runNumericRules(field: NumericField, env: Env): Message[] {
  const value = env.getFocused();

  if (value == null) {
    return [];
  } else if (typeof value === "number") {
    return field.bounds != null ? runBoundsRule(field.bounds, value) : [];
  } else {
    return [
      errorMessage(
        `Expected a number or null, found ${prettyPrintJson(value)}`,
        env.expand([])
      ),
    ];
  }
}

export function runTimestampRules(field: TimestampField, env: Env): Message[] {
  const value = env.getFocused();

  if (value == null) {
    return [];
  } else if (isTimestamp(value)) {
    return field.bounds != null
      ? runTimestampBoundsRule(
          field.bounds,
          value,
          undefined,
          env.getAbsoluteAs(["created"], isTimestamp).getOrElse(nowTimestamp)
        )
      : [];
  } else {
    return [
      errorMessage(
        `Expected a timestamp or null, found ${prettyPrintJson(value)}`,
        env.expand([])
      ),
    ];
  }
}

export function runRules(field: Field, env: Env): Message[] {
  switch (field.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
    case "UserRefField":
    case "PointField":
    case "LineStringField":
    case "PolygonField":
    case "FeatureField":
    case "NearestFeatureField":
    case "Select":
    case "MultiSelect":
    case "TeamField":
      return [...runRequiredRules(field, env), ...runCustomRules(field, env)];

    case "TimestampField":
      return [
        ...runRequiredRules(field, env),
        ...runTimestampRules(field, env),
        ...runCustomRules(field, env),
      ];

    case "IntegerField":
    case "NumberField":
      return [
        ...runRequiredRules(field, env),
        ...runNumericRules(field, env),
        ...runCustomRules(field, env),
      ];

    case "Checkbox":
      return runCustomRules(field, env);

    case "AttachmentField":
      return [];

    default:
      return checkExhausted(field);
  }
}
