// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsCrossProfile = "SN" | "CL" | "RE" | "RS" | "EN" | "TS";

const SemiNatural: UrsCrossProfile = "SN";
const Cleaned: UrsCrossProfile = "CL";
const Restored: UrsCrossProfile = "RE";
const Resectioned: UrsCrossProfile = "RS";
const Enlarged: UrsCrossProfile = "EN";
const TwoStage: UrsCrossProfile = "TS";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsCrossProfileEnum = {
  ...createEnum("UrsCrossProfile", [
    [SemiNatural, "Semi-natural"],
    [Cleaned, "Cleaned"],
    [Restored, "Restored"],
    [Resectioned, "Resectioned"],
    [Enlarged, "Enlarged"],
    [TwoStage, "Two-stage"],
  ]),
  SemiNatural,
  Cleaned,
  Restored,
  Resectioned,
  Enlarged,
  TwoStage,
};

export default UrsCrossProfileEnum;
