import { markdownSummary } from "@cartographerio/markdown";
import { checkExhausted, filterAndMap } from "@cartographerio/util";
import { Block, Field, Form, Page } from "./type";

export function currentFieldImage(
  field: Field,
  value?: unknown
): string | undefined {
  switch (field.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
    case "IntegerField":
    case "NumberField":
      return field.image;
    case "Select":
      return (
        field.options.find(option => (option.value ?? null) === (value ?? null))
          ?.image ?? field.image
      );
    case "MultiSelect":
      return value == null || !Array.isArray(value)
        ? field.image
        : field.options.find(option =>
            value.find(value => (option.value ?? null) === (value ?? null))
          )?.image ?? field.image;
    case "Checkbox":
    case "UserRefField":
    case "TimestampField":
    case "AttachmentField":
    case "TeamField":
    case "PointField":
    case "LineStringField":
    case "PolygonField":
    case "FeatureField":
    case "NearestFeatureField":
      return undefined;
    default:
      return checkExhausted(field);
  }
}

export function markdownImages(markdown?: string): string[] {
  try {
    return markdown == null ? [] : markdownSummary(markdown).imageUrls;
  } catch (error) {
    return [];
  }
}

export function blockImages(block: Block): string[] {
  switch (block.type) {
    case "TextField":
    case "TextArea":
    case "Autocomplete":
    case "IntegerField":
    case "NumberField":
      return [
        ...(block.image == null ? [] : [block.image]),
        ...markdownImages(block.help),
      ];
    case "Select":
    case "MultiSelect":
      return [
        ...(block.image == null ? [] : [block.image]),
        ...filterAndMap(block.options, option => option.image),
        ...markdownImages(block.help),
      ];
    case "Checkbox":
    case "UserRefField":
    case "TimestampField":
    case "AttachmentField":
    case "LineStringField":
    case "PolygonField":
    case "PointField":
    case "FeatureField":
    case "NearestFeatureField":
    case "DynamicValue":
    case "TeamField":
      return markdownImages(block.help);
    case "Text":
      return markdownImages(block.markdown);
    case "Heading":
    case "DynamicGrid":
    case "PrimarySurveyorField":
    case "PrimaryTeamField":
      return [];
    case "DynamicImage":
      return block.precacheUrls ?? [];
    case "Group":
      return block.blocks.flatMap(blockImages);
    case "Card":
    case "FullWidth":
    case "Repeat":
    case "RequireRole":
    case "Scope":
    case "Visibility":
      return blockImages(block.block);
    default:
      return checkExhausted(block);
  }
}

export function pageImages(page: Page): string[] {
  return [...markdownImages(page.help), ...page.blocks.flatMap(blockImages)];
}

export function formImages(form: Form): string[] {
  return form.pages.flatMap(pageImages);
}
