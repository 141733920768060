import {
  PermissionCheck,
  QualificationRole,
  Role,
} from "@cartographerio/types";
import { check } from "../check";

export function grant(
  roles?: Role[] | null,
  qualificationRoles?: QualificationRole[] | null
): PermissionCheck {
  const rolesLabel =
    roles != null && roles.length > 0 ? ` roles: ${roles.join(", ")}` : "";

  const qualificationRolesLabel =
    qualificationRoles != null && qualificationRoles.length > 0
      ? `${rolesLabel.length > 0 ? " and " : " "}qualification roles: ${qualificationRoles.join(", ")}`
      : "";

  return check.named(
    `Can grant ${rolesLabel}${qualificationRolesLabel}`,
    check.canGrantRoles(roles ?? [], qualificationRoles ?? [], check.never)
  );
}
