// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type ArrtWaterQualityPollutionEvidence =
  | "SewageRelatedLitter"
  | "LitterOrFlyTipping"
  | "UnpleasantOdour"
  | "OilySheen"
  | "Foam"
  | "SmotheringAlgae"
  | "SewageFungus"
  | "Other";

const SewageRelatedLitter: ArrtWaterQualityPollutionEvidence =
  "SewageRelatedLitter";
const LitterOrFlyTipping: ArrtWaterQualityPollutionEvidence =
  "LitterOrFlyTipping";
const UnpleasantOdour: ArrtWaterQualityPollutionEvidence = "UnpleasantOdour";
const OilySheen: ArrtWaterQualityPollutionEvidence = "OilySheen";
const Foam: ArrtWaterQualityPollutionEvidence = "Foam";
const SmotheringAlgae: ArrtWaterQualityPollutionEvidence = "SmotheringAlgae";
const SewageFungus: ArrtWaterQualityPollutionEvidence = "SewageFungus";
const Other: ArrtWaterQualityPollutionEvidence = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrtWaterQualityPollutionEvidenceEnum = {
  ...createEnum("ArrtWaterQualityPollutionEvidence", [
    [SewageRelatedLitter, "Sewage-Related Litter"],
    [LitterOrFlyTipping, "Litter/Fly-Tipping"],
    [UnpleasantOdour, "Unpleasant Odour"],
    [OilySheen, "Oily Sheen"],
    [Foam, "Foam (White/Fragrant)"],
    [SmotheringAlgae, "Smothering Algae"],
    [SewageFungus, "Sewage Fungus"],
    [Other, "Other"],
  ]),
  SewageRelatedLitter,
  LitterOrFlyTipping,
  UnpleasantOdour,
  OilySheen,
  Foam,
  SmotheringAlgae,
  SewageFungus,
  Other,
};

export default ArrtWaterQualityPollutionEvidenceEnum;
