// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusStudyAreaBank = "left" | "right" | "both";

const Left: EcostatusStudyAreaBank = "left";
const Right: EcostatusStudyAreaBank = "right";
const Both: EcostatusStudyAreaBank = "both";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusStudyAreaBankEnum = {
  ...createEnum("EcostatusStudyAreaBank", [
    [Left, "Left"],
    [Right, "Right"],
    [Both, "Both"],
  ]),
  Left,
  Right,
  Both,
};

export default EcostatusStudyAreaBankEnum;
