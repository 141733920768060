// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type UrsTreeFeature =
  | "channelShading"
  | "overhangingBoughs"
  | "exposedBanksideRoots"
  | "underwaterRoots"
  | "fallenTrees"
  | "largeWoodDebris";

const ChannelShading: UrsTreeFeature = "channelShading";
const OverhangingBoughs: UrsTreeFeature = "overhangingBoughs";
const ExposedBanksideRoots: UrsTreeFeature = "exposedBanksideRoots";
const UnderwaterRoots: UrsTreeFeature = "underwaterRoots";
const FallenTrees: UrsTreeFeature = "fallenTrees";
const LargeWoodDebris: UrsTreeFeature = "largeWoodDebris";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UrsTreeFeatureEnum = {
  ...createEnum("UrsTreeFeature", [
    [ChannelShading, "Channel shading"],
    [OverhangingBoughs, "Overhanging boughs"],
    [ExposedBanksideRoots, "Exposed bankside roots"],
    [UnderwaterRoots, "Underwater roots"],
    [FallenTrees, "Fallen trees"],
    [LargeWoodDebris, "Large wood debris"],
  ]),
  ChannelShading,
  OverhangingBoughs,
  ExposedBanksideRoots,
  UnderwaterRoots,
  FallenTrees,
  LargeWoodDebris,
};

export default UrsTreeFeatureEnum;
