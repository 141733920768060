// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { ProjectRoleName } from "../types.generated";

const Member: ProjectRoleName = "Member";
const Surveyor: ProjectRoleName = "Surveyor";
const Approver: ProjectRoleName = "Approver";
const Coordinator: ProjectRoleName = "Coordinator";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProjectRoleNameEnum = {
  ...createEnum("ProjectRoleName", [
    [Member, "Project Member"],
    [Surveyor, "Project Surveyor"],
    [Approver, "Project Approver"],
    [Coordinator, "Project Coordinator"],
  ]),
  Member,
  Surveyor,
  Approver,
  Coordinator,
};

export default ProjectRoleNameEnum;
