import { Result } from "@cartographerio/fp";
import { GeometryAtom } from "@cartographerio/geometry";
import { GuardError } from "@cartographerio/guard";
import {
  Form,
  form,
  numberField,
  page,
  repeat,
  required,
  section,
  textField,
  timestampField,
  vstack,
} from "@cartographerio/topo-form";
import {
  arraySchema,
  nullableSchema,
  numberSchema,
  pointSchema,
  productSchema,
  Schema,
  stringSchema,
  timestampSchema,
} from "@cartographerio/topo-survey";
import {
  SurveyNames,
  Timestamp,
  unsafeSurveyModuleId,
} from "@cartographerio/types";

export const moduleId = unsafeSurveyModuleId("TestSurvey3");

export const names: SurveyNames = {
  shortName: "Survey 3",
  longName: "Test Survey 3",
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function dataDescription(_data: unknown): Result<GuardError, string> {
  return Result.pure("Test Survey 3");
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.pure((data as any).location);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.pure((data as any).timestamp);
}

export function copyData(data: any): Result<GuardError, unknown> {
  return Result.pure({ ...data, timestamp: undefined });
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-explicit-any */

export const dataSchema: Schema = productSchema({
  location: nullableSchema(pointSchema),
  timestamp: nullableSchema(timestampSchema),
  keyPoints: arraySchema(
    productSchema({
      text: nullableSchema(stringSchema),
      number: nullableSchema(numberSchema),
    })
  ),
});

export const formSchema: Form = form({
  title: names.shortName,
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            timestampField({
              label: "Date/time",
              path: ["data", "timestamp"],
              required: required("info"),
              defaultValue: "now",
            }),
          ],
        }),
        section({
          title: "Key Points",
          path: [],
          blocks: [
            repeat({
              path: ["data", "keyPoints"],
              block: vstack(
                textField({
                  label: "Text",
                  path: ["text"],
                }),
                numberField({
                  label: "Number",
                  path: ["number"],
                })
              ),
            }),
          ],
        }),
      ],
    }),
  ],
});
