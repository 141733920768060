import { v4 as createUuid } from "uuid";
import { AttachmentFolder } from "./types.generated";

export function unsafeAttachmentFolder(folder: string): AttachmentFolder {
  return { type: "AttachmentFolder", folder } as AttachmentFolder;
}

export function randomAttachmentFolder(): AttachmentFolder {
  return { type: "AttachmentFolder", folder: createUuid() } as AttachmentFolder;
}
