// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type FhtPriorityPondsPsymAssessmentBasis =
  | "PlantsAndInvertebrates"
  | "PlantsOnly"
  | "InvertebratesOnly";

const PlantsAndInvertebrates: FhtPriorityPondsPsymAssessmentBasis =
  "PlantsAndInvertebrates";
const PlantsOnly: FhtPriorityPondsPsymAssessmentBasis = "PlantsOnly";
const InvertebratesOnly: FhtPriorityPondsPsymAssessmentBasis =
  "InvertebratesOnly";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FhtPriorityPondsPsymAssessmentBasisEnum = {
  ...createEnum("FhtPriorityPondsPsymAssessmentBasis", [
    [PlantsAndInvertebrates, "Plants and invertebrates"],
    [PlantsOnly, "Plants only"],
    [InvertebratesOnly, "Invertebrates only"],
  ]),
  PlantsAndInvertebrates,
  PlantsOnly,
  InvertebratesOnly,
};

export default FhtPriorityPondsPsymAssessmentBasisEnum;
