import {
  topoExpr,
  dynamicAttr,
  dynamicGrid,
  form,
  page,
  section,
} from "@cartographerio/topo-form";
import {
  UrbanRiverflySpecies,
  UrbanRiverflySpeciesEnum,
} from "@cartographerio/inventory-enums";
import {
  urbanRiverflyCalculations,
  urbanRiverflyConversions,
} from "../Riverfly/calculations";
import {
  armiScoreAttribute,
  armiTriggerLevelAttribute,
  armiTriggerLevelBreachedAttribute,
  armiTriggerLevelBreached,
  armiTriggerLevelBreachOkSection,
  armiTriggerLevelBreachedSections,
  sampleCountsSection,
  riverflyFixedPointPhotographsSection,
  riverflyNotesSection,
  riverflyPollutionPhotographsSection,
  riverflyTopSections,
  isRiverflyARMICounts,
} from "../Riverfly/form";

const urbanRiverflyScoreAttribute = dynamicAttr({
  label: "Urban Score",
  valueType: "number",
  value: topoExpr(env =>
    env
      .getAs(
        ["data", "observations", "counts"],
        isRiverflyARMICounts(UrbanRiverflySpeciesEnum, urbanRiverflyConversions)
      )
      .map(counts =>
        urbanRiverflyCalculations.total(
          urbanRiverflyCalculations.scores(counts, 0)
        )
      )
      .getOrNull()
  ),
});

const urbanRiverflyTriggerLevelBreached =
  armiTriggerLevelBreached<UrbanRiverflySpecies>(
    UrbanRiverflySpeciesEnum,
    urbanRiverflyConversions
  );

export default form({
  title: "Urban Riverfly",
  pages: [
    page({
      title: null,
      path: [],
      blocks: [
        ...riverflyTopSections("urbanRiverfly"),
        sampleCountsSection<UrbanRiverflySpecies>(
          UrbanRiverflySpeciesEnum,
          value =>
            `https://media.cartographer.io/static/images/urban-riverfly/${value}.jpg`,
          urbanRiverflyConversions,
          [urbanRiverflyCalculations]
        ),
        section({
          title: "Sample Scores",
          path: [],
          blocks: [
            dynamicGrid({
              columns: 2,
              attributes: [
                armiScoreAttribute<UrbanRiverflySpecies>(
                  UrbanRiverflySpeciesEnum,
                  urbanRiverflyConversions
                ),
                armiTriggerLevelAttribute,
                armiTriggerLevelBreachedAttribute<UrbanRiverflySpecies>(
                  UrbanRiverflySpeciesEnum,
                  urbanRiverflyConversions
                ),
                urbanRiverflyScoreAttribute,
              ],
            }),
          ],
        }),
        armiTriggerLevelBreachOkSection(urbanRiverflyTriggerLevelBreached),
        ...armiTriggerLevelBreachedSections(urbanRiverflyTriggerLevelBreached),
        riverflyPollutionPhotographsSection(urbanRiverflyTriggerLevelBreached),
        riverflyFixedPointPhotographsSection,
        riverflyNotesSection,
      ],
    }),
  ],
});
