// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { StripeSubscriptionStatus } from "../types.generated";

const Incomplete: StripeSubscriptionStatus = "incomplete";
const IncompleteExpired: StripeSubscriptionStatus = "incomplete_expired";
const Trialing: StripeSubscriptionStatus = "trialing";
const Active: StripeSubscriptionStatus = "active";
const PastDue: StripeSubscriptionStatus = "past_due";
const Canceled: StripeSubscriptionStatus = "canceled";
const Unpaid: StripeSubscriptionStatus = "unpaid";
const Paused: StripeSubscriptionStatus = "paused";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StripeSubscriptionStatusEnum = {
  ...createEnum("StripeSubscriptionStatus", [
    [Incomplete, "Incomplete"],
    [IncompleteExpired, "Incomplete (Expired)"],
    [Trialing, "Trialing"],
    [Active, "Active"],
    [PastDue, "Past Due"],
    [Canceled, "Canceled"],
    [Unpaid, "Unpaid"],
    [Paused, "Paused"],
  ]),
  Incomplete,
  IncompleteExpired,
  Trialing,
  Active,
  PastDue,
  Canceled,
  Unpaid,
  Paused,
};

export default StripeSubscriptionStatusEnum;
