// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21SlowFlowServiceType = "SlowFlow" | "Storage";

const SlowFlow: Thames21SlowFlowServiceType = "SlowFlow";
const Storage: Thames21SlowFlowServiceType = "Storage";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21SlowFlowServiceTypeEnum = {
  ...createEnum("Thames21SlowFlowServiceType", [
    [SlowFlow, "Slowing the flow"],
    [Storage, "Providing extra storage"],
  ]),
  SlowFlow,
  Storage,
};

export default Thames21SlowFlowServiceTypeEnum;
