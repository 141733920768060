/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  Email,
  isEmail,
  QualificationAlias,
  isQualificationAlias,
  QualificationHistoryEventId,
  isQualificationHistoryEventId,
  QualificationId,
  isQualificationId,
  ScreenName,
  isScreenName,
  Timestamp,
  isTimestamp,
  UserId,
  isUserId,
} from "../core/types.generated";
import {
  QualificationRoleName,
  isQualificationRoleName,
} from "../permission/types.generated";
import { UserRef, isUserRef } from "../user/types.generated";

export interface Qualification {
  name: string;
  alias: QualificationAlias;
  id: QualificationId;
}

export interface CompleteQualificationHistoryEvent {
  qualificationId: QualificationId;
  userId: UserId;
  timestamp: Timestamp;
  roleName?: QualificationRoleName | null;
  updatedBy?: UserRef | null;
  notes?: string | null;
  id: QualificationHistoryEventId;
}

export interface QualificationHistoryEvent {
  timestamp: Timestamp;
  roleName?: QualificationRoleName | null;
  updatedBy?: UserRef | null;
  notes?: string | null;
}

export type QualificationRegisterPrivacy = "Email" | "Name" | "ScreenName";

export type QualificationRegisterSharing = "Public" | "Unlisted" | "Private";

export interface QualificationRegisterSettings {
  privacy: QualificationRegisterPrivacy;
  sharing: QualificationRegisterSharing;
}

export interface QualificationRegisterEntry {
  id: string;
  qualificationId: QualificationId;
  userId?: UserId | null;
  firstName?: string | null;
  lastName?: string | null;
  screenName?: ScreenName | null;
  email?: Email | null;
  privacy: QualificationRegisterPrivacy;
  sharing: QualificationRegisterSharing;
  lastUpdate: QualificationHistoryEvent;
}

export const isQualification = (v: any): v is Qualification => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "alias" in v &&
    isQualificationAlias(v.alias) &&
    "id" in v &&
    isQualificationId(v.id)
  );
};

export const isCompleteQualificationHistoryEvent = (
  v: any
): v is CompleteQualificationHistoryEvent => {
  return (
    typeof v === "object" &&
    v != null &&
    "qualificationId" in v &&
    isQualificationId(v.qualificationId) &&
    "userId" in v &&
    isUserId(v.userId) &&
    "timestamp" in v &&
    isTimestamp(v.timestamp) &&
    (!("roleName" in v) ||
      isQualificationRoleName(v.roleName) ||
      v.roleName === null) &&
    (!("updatedBy" in v) || isUserRef(v.updatedBy) || v.updatedBy === null) &&
    (!("notes" in v) || typeof v.notes === "string" || v.notes === null) &&
    "id" in v &&
    isQualificationHistoryEventId(v.id)
  );
};

export const isQualificationHistoryEvent = (
  v: any
): v is QualificationHistoryEvent => {
  return (
    typeof v === "object" &&
    v != null &&
    "timestamp" in v &&
    isTimestamp(v.timestamp) &&
    (!("roleName" in v) ||
      isQualificationRoleName(v.roleName) ||
      v.roleName === null) &&
    (!("updatedBy" in v) || isUserRef(v.updatedBy) || v.updatedBy === null) &&
    (!("notes" in v) || typeof v.notes === "string" || v.notes === null)
  );
};

export const isQualificationRegisterPrivacy = (
  v: any
): v is QualificationRegisterPrivacy => {
  return v === "Email" || v === "Name" || v === "ScreenName";
};

export const isQualificationRegisterSharing = (
  v: any
): v is QualificationRegisterSharing => {
  return v === "Public" || v === "Unlisted" || v === "Private";
};

export const isQualificationRegisterSettings = (
  v: any
): v is QualificationRegisterSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "privacy" in v &&
    isQualificationRegisterPrivacy(v.privacy) &&
    "sharing" in v &&
    isQualificationRegisterSharing(v.sharing)
  );
};

export const isQualificationRegisterEntry = (
  v: any
): v is QualificationRegisterEntry => {
  return (
    typeof v === "object" &&
    v != null &&
    "id" in v &&
    typeof v.id === "string" &&
    "qualificationId" in v &&
    isQualificationId(v.qualificationId) &&
    (!("userId" in v) || isUserId(v.userId) || v.userId === null) &&
    (!("firstName" in v) ||
      typeof v.firstName === "string" ||
      v.firstName === null) &&
    (!("lastName" in v) ||
      typeof v.lastName === "string" ||
      v.lastName === null) &&
    (!("screenName" in v) ||
      isScreenName(v.screenName) ||
      v.screenName === null) &&
    (!("email" in v) || isEmail(v.email) || v.email === null) &&
    "privacy" in v &&
    isQualificationRegisterPrivacy(v.privacy) &&
    "sharing" in v &&
    isQualificationRegisterSharing(v.sharing) &&
    "lastUpdate" in v &&
    isQualificationHistoryEvent(v.lastUpdate)
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
