import { Point } from "@cartographerio/geometry";
import {
  AttachmentFolder,
  SurveyF,
  TestSurveyProps,
  Timestamp,
  randomAttachmentFolder,
  testSurvey,
  unsafeSurveyModuleId,
} from "@cartographerio/types";

export interface TestSurvey1Data {
  location?: Point | null;
  timestamp?: Timestamp | null;
  number?: number | null;
  text?: string | null;
  photographs: AttachmentFolder;
}

export type TestSurvey1 = SurveyF<TestSurvey1Data>;

export function testSurvey1(
  props: TestSurveyProps<TestSurvey1Data>
): TestSurvey1 {
  return testSurvey({
    ...props,
    moduleId: unsafeSurveyModuleId("TestSurvey1"),
    data: props.data ?? {
      location: null,
      timestamp: null,
      number: null,
      text: null,
      photographs: randomAttachmentFolder(),
    },
  });
}
