import { Result } from "@cartographerio/fp";
import { GeometryAtom, Point, isPoint } from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { Timestamp, isTimestamp } from "@cartographerio/types";
import { midpoint } from "@turf/turf";

export interface PartialData {
  recorded?: Timestamp | null;
  general: CesGeneralInformation;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasKey(data, "general", isCesGeneralInformation) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp))
  );
}

interface CesGeneralInformation {
  location: CesSurveyLocation;
}

function isCesGeneralInformation(data: unknown): data is CesGeneralInformation {
  return isObject(data) && hasKey(data, "location", isCesSurveyLocation);
}

interface CesSurveyLocation {
  canalNameCode?: string | null;
  canalKmCode?: string | null;
  startMarker?: string | null;
  startLocation?: Point | null;
  endMarker?: string | null;
  endLocation?: Point | null;
}

function isCesSurveyLocation(data: unknown): data is CesSurveyLocation {
  return (
    isObject(data) &&
    hasOptionalKey(data, "canalNameCode", isNullable(isString)) &&
    hasOptionalKey(data, "canalKmCode", isNullable(isString)) &&
    hasOptionalKey(data, "startMarker", isNullable(isString)) &&
    hasOptionalKey(data, "startLocation", isNullable(isPoint)) &&
    hasOptionalKey(data, "endMarker", isNullable(isString)) &&
    hasOptionalKey(data, "endLocation", isNullable(isPoint))
  );
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialCanalEnvironmentSurveyData"
  )(data).map(data => {
    const location = data.general.location;

    if (location.startMarker != null && location.endMarker != null) {
      return `${location.startMarker} to ${location.endMarker}`;
    } else if (location.canalNameCode != null && location.canalKmCode != null) {
      return `${location.canalNameCode} (${location.canalKmCode})`;
    } else {
      return (
        location.canalNameCode ?? location.canalKmCode ?? "Unknown location"
      );
    }
  });
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialCanalEnvironmentSurveyData"
  )(data).map(data =>
    data.general.location.startLocation != null &&
    data.general.location.endLocation != null
      ? midpoint(
          data.general.location.startLocation,
          data.general.location.endLocation
        ).geometry
      : data.general.location.startLocation ??
        data.general.location.endLocation ??
        null
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialCanalEnvironmentSurveyData"
  )(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return Result.guard(
    isPartialData,
    "PartialCanalEnvironmentSurveyData"
  )(data).map(data => ({
    ...data,
    general: { ...data.general, location: {} },
  }));
}
