import {
  Block,
  featureFieldExternalMapStyle,
  Field,
  form,
  grid,
  nearestFeatureField,
  page,
  section,
  timestampField,
} from "@cartographerio/topo-form";
import { outdent } from "outdent";
import {
  restorationPrioritiesNotesSection,
  restorationPrioritiesPhotographsSection,
  riverRestorationPrioritiesGuidanceUrl,
  riverRestorationPrioritiesSelectionsSections,
} from "../NeRiverRestorationPriorities/form";
import { booleanAttr, numberAttr, stringAttr } from "@cartographerio/topo-map";

export function neRiverAreaInfoSection(extraFields: Field[] = []): Block {
  return section({
    title: "Location",
    path: [],
    blocks: [
      grid({
        columns: 2,
        blocks: [
          nearestFeatureField({
            label: null,
            path: ["data", "site", "picked"],
            fullWidth: true,
            help: outdent`Click on the map or enter a 10-digit NGR or GPS coordinate to zoom to a location:`,
            mapOptions: {
              mapStyle: featureFieldExternalMapStyle({
                styleUrl:
                  "mapbox://styles/cartographerio/cl3m269jm005p15odh2nm2exg",
                sourceId: "ne_river_areas",
                primaryKey: "gid",
                selectableLayer: { id: "ne_river_areas_fill", type: "fill" },
                selectedLayers: [
                  { id: "ne_river_areas_fill_selected", type: "fill" },
                  { id: "ne_river_areas_stroke_selected", type: "line" },
                ],
              }),
              selectMinZoom: 9,
              attribution: [
                "&copy; Environment Agency",
                "&copy; Natural England",
              ],
              attributes: [
                numberAttr({
                  attributeId: "gid",
                  label: "Area ID",
                  buckets: "auto",
                }),
                stringAttr({
                  attributeId: "ea_wb_id",
                  label: "EA Waterbody ID",
                  buckets: "auto",
                }),
                booleanAttr({
                  attributeId: "headwater",
                  label: "Headwater?",
                  buckets: "auto",
                }),
              ],
            },
          }),
          ...extraFields,
        ],
      }),
    ],
  });
}

export default form({
  title: "River/Stream Restoration Priorities (Area Based)",
  surveyorLabel: "Proposer",
  pages: [
    page({
      title: null,
      path: [],
      help: outdent`
      This form allows you to select a group of river/stream sections as a cluster,
      based on WFD waterbody catchments but divided into areas for
      headwaters (green areas) and larger rivers (blue areas).

      For further information on completing this assessment,
      please see the [guidance document](${riverRestorationPrioritiesGuidanceUrl})
      `,
      blocks: [
        section({
          title: null,
          path: [],
          blocks: [
            grid({
              columns: 2,
              blocks: [
                timestampField({
                  label: "Date and Time",
                  path: ["data", "recorded"],
                }),
              ],
            }),
          ],
        }),
        neRiverAreaInfoSection(),
        ...riverRestorationPrioritiesSelectionsSections,
        restorationPrioritiesPhotographsSection,
        restorationPrioritiesNotesSection,
      ],
    }),
  ],
});
