import { isArray, isNumber } from "@cartographerio/guard";
import { BBox } from "geojson";
import { Coord, Coord2, Coord3 } from "./coord";

export type BBox4 = [number, number, number, number];
export type BBox6 = [number, number, number, number, number, number];

export function isBBox(value: unknown): value is BBox {
  return (
    isArray(value) &&
    (value.length === 4 || value.length === 6) &&
    value.every(isNumber)
  );
}

export function isBBox4(value: unknown): value is BBox4 {
  return isArray(value) && value.length === 4 && value.every(isNumber);
}

export function isBBox6(value: unknown): value is BBox6 {
  return isArray(value) && value.length === 6 && value.every(isNumber);
}

export function toBBox4(bbox: BBox): BBox4 {
  return bbox.length === 4 ? bbox : [bbox[0], bbox[1], bbox[3], bbox[4]];
}

export function toBBox6(bbox: BBox): BBox6 {
  return bbox.length === 6 ? bbox : [bbox[0], bbox[1], 0, bbox[2], bbox[3], 0];
}

export function bboxCenter(bbox: BBox4): Coord2;
export function bboxCenter(bbox: BBox6): Coord3;
export function bboxCenter(bbox: BBox): Coord;
export function bboxCenter(bbox: BBox): Coord {
  return isBBox4(bbox)
    ? [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2]
    : [
        (bbox[0] + bbox[3]) / 2,
        (bbox[1] + bbox[4]) / 2,
        (bbox[2] + bbox[5]) / 2,
      ];
}

export function bboxSw(bbox: BBox4): Coord2;
export function bboxSw(bbox: BBox6): Coord3;
export function bboxSw(bbox: BBox): Coord;
export function bboxSw(bbox: BBox): Coord {
  return isBBox4(bbox) ? [bbox[0], bbox[1]] : [bbox[0], bbox[1], bbox[2]];
}

export function bboxNe(bbox: BBox4): Coord2;
export function bboxNe(bbox: BBox6): Coord3;
export function bboxNe(bbox: BBox): Coord;
export function bboxNe(bbox: BBox): Coord {
  return isBBox4(bbox) ? [bbox[2], bbox[3]] : [bbox[3], bbox[4], bbox[5]];
}

export function normalizeBBox(bbox: BBox4): BBox4;
export function normalizeBBox(bbox: BBox6): BBox6;
export function normalizeBBox(bbox: BBox): BBox;
export function normalizeBBox(bbox: BBox): BBox {
  if (isBBox4(bbox)) {
    return [
      Math.min(bbox[0], bbox[2]),
      Math.min(bbox[1], bbox[3]),
      Math.max(bbox[0], bbox[2]),
      Math.max(bbox[1], bbox[3]),
    ];
  } else {
    return [
      Math.min(bbox[0], bbox[3]),
      Math.min(bbox[1], bbox[4]),
      Math.min(bbox[2], bbox[5]),
      Math.max(bbox[0], bbox[3]),
      Math.max(bbox[1], bbox[4]),
      Math.max(bbox[2], bbox[5]),
    ];
  }
}
