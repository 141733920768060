// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityInvasivePlant =
  | "HimalayanBalsam"
  | "JapaneseKnotweed"
  | "GiantHogweed"
  | "NewZealandPygmyweed"
  | "WaterFern"
  | "FloatingPennywort"
  | "Other";

const HimalayanBalsam: EsrtWaterQualityInvasivePlant = "HimalayanBalsam";
const JapaneseKnotweed: EsrtWaterQualityInvasivePlant = "JapaneseKnotweed";
const GiantHogweed: EsrtWaterQualityInvasivePlant = "GiantHogweed";
const NewZealandPygmyweed: EsrtWaterQualityInvasivePlant =
  "NewZealandPygmyweed";
const WaterFern: EsrtWaterQualityInvasivePlant = "WaterFern";
const FloatingPennywort: EsrtWaterQualityInvasivePlant = "FloatingPennywort";
const Other: EsrtWaterQualityInvasivePlant = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityInvasivePlantEnum = {
  ...createEnum("EsrtWaterQualityInvasivePlant", [
    [HimalayanBalsam, "Himalayan Balsam"],
    [JapaneseKnotweed, "Japanese Knotweed"],
    [GiantHogweed, "Giant Hogweed"],
    [NewZealandPygmyweed, "New Zealand Pygmyweed"],
    [WaterFern, "Water Fern"],
    [FloatingPennywort, "Floating Pennywort"],
    [Other, "Other"],
  ]),
  HimalayanBalsam,
  JapaneseKnotweed,
  GiantHogweed,
  NewZealandPygmyweed,
  WaterFern,
  FloatingPennywort,
  Other,
};

export default EsrtWaterQualityInvasivePlantEnum;
