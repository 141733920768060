// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type Thames21ChannelSide = "footpath" | "opposite";

const Footpath: Thames21ChannelSide = "footpath";
const Opposite: Thames21ChannelSide = "opposite";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Thames21ChannelSideEnum = {
  ...createEnum("Thames21ChannelSide", [
    [Footpath, "Footpath side"],
    [Opposite, "Opposite side"],
  ]),
  Footpath,
  Opposite,
};

export default Thames21ChannelSideEnum;
