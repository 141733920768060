// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMudSpotterLegacySourceSubType =
  | "EarthWorksOrDredging"
  | "VehicleDisturbance"
  | "Poaching"
  | "NaturalBankErosion"
  | "FlowFromImpermeableSurface"
  | "FlowFromOtherSurface";

const EarthWorksOrDredging: MrsMudSpotterLegacySourceSubType =
  "EarthWorksOrDredging";
const VehicleDisturbance: MrsMudSpotterLegacySourceSubType =
  "VehicleDisturbance";
const Poaching: MrsMudSpotterLegacySourceSubType = "Poaching";
const NaturalBankErosion: MrsMudSpotterLegacySourceSubType =
  "NaturalBankErosion";
const FlowFromImpermeableSurface: MrsMudSpotterLegacySourceSubType =
  "FlowFromImpermeableSurface";
const FlowFromOtherSurface: MrsMudSpotterLegacySourceSubType =
  "FlowFromOtherSurface";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMudSpotterLegacySourceSubTypeEnum = {
  ...createEnum("MrsMudSpotterLegacySourceSubType", [
    [EarthWorksOrDredging, "Earth Works / Dredging"],
    [VehicleDisturbance, "Vehicle Disturbance"],
    [Poaching, "Poaching"],
    [NaturalBankErosion, "Natural Bank Erosion"],
    [FlowFromImpermeableSurface, "Flow from Impermeable Surface"],
    [FlowFromOtherSurface, "Flow from Other Surface"],
  ]),
  EarthWorksOrDredging,
  VehicleDisturbance,
  Poaching,
  NaturalBankErosion,
  FlowFromImpermeableSurface,
  FlowFromOtherSurface,
};

export default MrsMudSpotterLegacySourceSubTypeEnum;
