import {
  attachmentAttr,
  attributeGroup,
  booleanAttr,
  enumAttr,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  WrtWestcountryCsiChannelSubstrateEnum,
  WrtWestcountryCsiInvasivePlantEnum,
  WrtWestcountryCsiPollutionEvidenceEnum,
  WrtWestcountryCsiPollutionSourceEnum,
  WrtWestcountryCsiWildlifeEnum,
} from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

export const WrtWestcountryCsi = mapSchemaWithDefaults({
  mapId: unsafeMapId("wrtWestcountryCsi"),
  title: "Westcountry CSI",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("wrtWestcountryCsi"),
      title: "Westcountry CSI",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("wrtWestcountryCsi"),
        attribution: [],
      }),
      defaultAttribute: "wildlife",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("wrtWestcountryCsi"),
            }),
            surveyAttr("surveyId", "Survey"),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
        attributeGroup({
          label: "Plants and Wildlife",
          attributes: [
            enumAttr({
              attributeId: "invasivePlant",
              label: "Invasive Plants",
              enum: WrtWestcountryCsiInvasivePlantEnum,
              noneValue: "none",
            }),
            enumAttr({
              attributeId: "wildlife",
              label: "Wildlife",
              enum: WrtWestcountryCsiWildlifeEnum,
              noneValue: "none",
            }),
            stringAttr({
              attributeId: "outfalls",
              label: "Outfall(s)",
              buckets: [
                lookupBucket(null, marker.empty, "None recorded"),
                lookupBucket(
                  "Active",
                  marker.fromColor("rgb(255, 128, 0)"),
                  "Active (discharging)"
                ),
                lookupBucket(
                  "Inactive",
                  marker.fromColor("rgb(255, 255, 0)"),
                  "Dormant (not discharging)"
                ),
              ],
            }),
          ],
        }),
        attributeGroup({
          label: "Pollution",
          attributes: [
            enumAttr({
              attributeId: "pollutionSource",
              label: "Visible Sources of Pollution",
              enum: WrtWestcountryCsiPollutionSourceEnum,
              noneValue: "none",
            }),
            enumAttr({
              attributeId: "pollutionEvidence",
              label: "Evidence of Recent Pollution",
              enum: WrtWestcountryCsiPollutionEvidenceEnum,
              noneValue: "none",
            }),
          ],
        }),
        attributeGroup({
          label: "River Channel Observations",
          attributes: [
            booleanAttr({
              attributeId: "dryRun",
              label: "Dry Run",
              buckets: [
                lookupBucket(true, marker.fromColor("green")),
                lookupBucket(false, marker.fromColor("red")),
                lookupBucket(null, marker.empty),
              ],
            }),
            numberAttr({
              attributeId: "temperature",
              label: "Temperature",
              unit: "C",
              decimalPlaces: 1,
              buckets: [
                rangeBucket(null, 4, marker.fromColor("#1094d8")),
                rangeBucket(4, 8, marker.quality(0.9)),
                rangeBucket(8, 12, marker.quality(0.5)),
                rangeBucket(12, 16, marker.quality(0.4)),
                rangeBucket(16, 20, marker.quality(0.3)),
                rangeBucket(20, 24, marker.quality(0.2)),
                rangeBucket(24, null, marker.quality(0.1)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "totalDissolvedSolids",
              label: "Total Dissolved Solids",
              unit: "ppm",
              buckets: [
                rangeBucket(null, 50, marker.fromColor("#1094d8")),
                rangeBucket(50, 200, marker.quality(0.9)),
                rangeBucket(200, 350, marker.quality(0.5)),
                rangeBucket(350, 500, marker.quality(0.4)),
                rangeBucket(500, 650, marker.quality(0.3)),
                rangeBucket(650, 800, marker.quality(0.2)),
                rangeBucket(800, null, marker.quality(0.1)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "turbidity",
              label: "Turbidity",
              unit: "NTU",
              buckets: [
                rangeBucket(null, 12, marker.fromColor("#1094d8")),
                rangeBucket(12, 40, marker.quality(0.9)),
                rangeBucket(40, 80, marker.quality(0.5)),
                rangeBucket(80, 120, marker.quality(0.4)),
                rangeBucket(120, 160, marker.quality(0.3)),
                rangeBucket(160, 240, marker.quality(0.2)),
                rangeBucket(240, null, marker.quality(0.1)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            numberAttr({
              attributeId: "phosphate",
              label: "Phosphates",
              unit: "ppb",
              buckets: [
                rangeBucket(null, 100, marker.fromColor("#1094d8")),
                rangeBucket(100, 200, marker.quality(0.9)),
                rangeBucket(200, 300, marker.quality(0.5)),
                rangeBucket(300, 500, marker.quality(0.4)),
                rangeBucket(500, 1000, marker.quality(0.3)),
                rangeBucket(1000, 2500, marker.quality(0.2)),
                rangeBucket(2500, null, marker.quality(0.1)),
                lookupBucket(null, marker.empty, "-"),
              ],
            }),
            enumAttr({
              attributeId: "predominateSubstrate",
              label: "Predominant Substrate",
              enum: WrtWestcountryCsiChannelSubstrateEnum,
              noneValue: "notVisible",
              showDistribution: false,
            }),
          ],
        }),
        attributeGroup({
          label: "Optional River Channel Observations",
          attributes: [
            numberAttr({
              attributeId: "ph",
              label: "pH",
              buckets: [
                rangeBucket(0, 1, marker.ph(0)),
                rangeBucket(1, 2, marker.ph(1)),
                rangeBucket(2, 3, marker.ph(2)),
                rangeBucket(3, 4, marker.ph(3)),
                rangeBucket(4, 5, marker.ph(4)),
                rangeBucket(5, 6, marker.ph(5)),
                rangeBucket(6, 7, marker.ph(6)),
                rangeBucket(7, 8, marker.ph(7)),
                rangeBucket(8, 9, marker.ph(8)),
                rangeBucket(9, 10, marker.ph(9)),
                rangeBucket(10, 11, marker.ph(10)),
                rangeBucket(11, 12, marker.ph(11)),
                rangeBucket(12, 13, marker.ph(12)),
                rangeBucket(13, 14, marker.ph(13), undefined, true),
                lookupBucket(null, marker.empty),
              ],
              decimalPlaces: 0,
            }),
            numberAttr({
              attributeId: "bacteria",
              label: "Bacteria",
              buckets: "auto",
              decimalPlaces: 3,
            }),
            numberAttr({
              attributeId: "nitrate",
              label: "Nitrate",
              buckets: "auto",
              decimalPlaces: 3,
            }),
            numberAttr({
              attributeId: "ammonia",
              label: "Ammonia",
              buckets: "auto",
              decimalPlaces: 3,
            }),
          ],
        }),
      ],
    }),
  ],
});
