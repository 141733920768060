// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { SurveyVisibility } from "../types.generated";

const Project: SurveyVisibility = "Project";
const Workspace: SurveyVisibility = "Workspace";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SurveyVisibilityEnum = {
  ...createEnum("SurveyVisibility", [
    [Project, "Project"],
    [Workspace, "Workspace"],
  ]),
  Project,
  Workspace,
};

export default SurveyVisibilityEnum;
