import {
  attributeGroup,
  lookupBucket,
  MapId,
  MapSchema,
  marker,
  numberAttr,
  pointLayer,
  stringAttr,
  thresholdAttr,
  thresholdBucket,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import { MrsFlowTypeEnum } from "@cartographerio/inventory-enums";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { moduleNumbersAttr, particleSizeAttr } from "./helpers";

function morph10Map(mapId: MapId, title: string): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,
    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId as string),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId as string),
          attribution: [],
        }),
        defaultAttribute: "index8",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "Survey Details",
            attributes: [
              stringAttr({
                attributeId: "riverName",
                label: "River",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "reachName",
                label: "Reach",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "subreachName",
                label: "Subreach",
                buckets: "auto",
              }),
              moduleNumbersAttr,
              stringAttr({
                attributeId: "projectName",
                label: "Project Name",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectCode",
                label: "MoRPh Correlation Code",
                buckets: "auto",
              }),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
            ],
          }),
          attributeGroup({
            label: "Channel Characteristics",
            attributes: [
              thresholdAttr({
                attributeId: "index1",
                label: "INDEX 1: Number of Present/Extensive Flow Types",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(0, marker.quality(0.0)),
                  thresholdBucket(2, marker.quality(0.3)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.8)),
                  thresholdBucket(5, marker.quality(1.0)),
                ],
              }),
              stringAttr({
                attributeId: "index2",
                label: "INDEX 2: Highest Energy Present/Extensive Flow Type",
                buckets: [
                  lookupBucket(MrsFlowTypeEnum.Dry, marker.quality(0.0), "Dry"),
                  lookupBucket(
                    MrsFlowTypeEnum.NoPerceptibleFlow,
                    marker.quality(0.1),
                    "No perceptible flow"
                  ),
                  lookupBucket(
                    MrsFlowTypeEnum.Smooth,
                    marker.quality(0.2),
                    "Smooth"
                  ),
                  lookupBucket(
                    MrsFlowTypeEnum.Rippled,
                    marker.quality(0.3),
                    "Rippled"
                  ),
                  lookupBucket(
                    MrsFlowTypeEnum.Upwelling,
                    marker.quality(0.5),
                    "Upwelling"
                  ),
                  lookupBucket(
                    MrsFlowTypeEnum.UnbrokenStandingWaves,
                    marker.quality(0.6),
                    "Unbroken standing waves"
                  ),
                  lookupBucket(
                    MrsFlowTypeEnum.BrokenStandingWaves,
                    marker.quality(0.8),
                    "Broken standing waves"
                  ),
                  lookupBucket(
                    MrsFlowTypeEnum.Chute,
                    marker.quality(0.9),
                    "Chute"
                  ),
                  lookupBucket(
                    MrsFlowTypeEnum.FreeFall,
                    marker.quality(1.0),
                    "Free fall"
                  ),
                ],
              }),
              thresholdAttr({
                attributeId: "index3",
                label:
                  "INDEX 3: Number of Present/Extensive Bed Material Types",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(2, marker.quality(0.1)),
                  thresholdBucket(3, marker.quality(0.3)),
                  thresholdBucket(4, marker.quality(0.5)),
                  thresholdBucket(5, marker.quality(0.8)),
                  thresholdBucket(6, marker.quality(0.9)),
                  thresholdBucket(7, marker.quality(1.0)),
                ],
              }),
              particleSizeAttr(
                "index4",
                "INDEX 4: Coarsest Present/Extensive Mineral Bed Material Type",
                true
              ),
              thresholdAttr({
                attributeId: "index5",
                label:
                  "INDEX 5: Average Alluvial Bed Material Size (Phi Units)",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(-8, marker.quality(0.1)),
                  thresholdBucket(-6, marker.quality(0.2)),
                  thresholdBucket(-4, marker.quality(0.3)),
                  thresholdBucket(-2, marker.quality(0.4)),
                  thresholdBucket(0, marker.quality(0.5)),
                  thresholdBucket(2, marker.quality(0.6)),
                  thresholdBucket(4, marker.quality(0.8)),
                  thresholdBucket(6, marker.quality(0.9)),
                  thresholdBucket(8, marker.quality(1.0)),
                ],
              }),
              particleSizeAttr(
                "index6",
                "INDEX 6: Average Alluvial Bed Material Size Class",
                false
              ),
              thresholdAttr({
                attributeId: "index7",
                label: "INDEX 7: Extent of Superficial Bed Siltation",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(1.0)),
                  thresholdBucket(1, marker.quality(0.6)),
                  thresholdBucket(2, marker.quality(0.5)),
                  thresholdBucket(3, marker.quality(0.4)),
                  thresholdBucket(4, marker.quality(0.3)),
                  thresholdBucket(5, marker.quality(0.1)),
                  thresholdBucket(6, marker.quality(0.0)),
                ],
              }),
              thresholdAttr({
                attributeId: "index8",
                label: "INDEX 8: Channel Physical Habitat Complexity",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.3)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.6)),
                  thresholdBucket(5, marker.quality(0.8)),
                  thresholdBucket(6, marker.quality(0.9)),
                  thresholdBucket(7, marker.quality(1.0)),
                ],
              }),
              thresholdAttr({
                attributeId: "index9",
                label: "INDEX 9: Number of Aquatic Vegetation Morphotypes",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.3)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.6)),
                  thresholdBucket(5, marker.quality(0.8)),
                  thresholdBucket(6, marker.quality(0.9)),
                  thresholdBucket(7, marker.quality(1.0)),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Riparian Characteristics (Bank Face and Bank Top)",
            attributes: [
              thresholdAttr({
                attributeId: "index10",
                label: "INDEX 10: Average Riparian Physical Habitat Complexity",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.5)),
                  thresholdBucket(3, marker.quality(0.8)),
                  thresholdBucket(4, marker.quality(0.9)),
                  thresholdBucket(5, marker.quality(1.0)),
                ],
              }),
              thresholdAttr({
                attributeId: "index11",
                label: "INDEX 11: Maximum Riparian Physical Habitat Complexity",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.5)),
                  thresholdBucket(3, marker.quality(0.8)),
                  thresholdBucket(4, marker.quality(0.9)),
                  thresholdBucket(5, marker.quality(1.0)),
                ],
              }),
              thresholdAttr({
                attributeId: "index12",
                label: "INDEX 12: Riparian Vegetation Structural Complexity",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.0)),
                  thresholdBucket(1, marker.quality(0.1)),
                  thresholdBucket(2, marker.quality(0.2)),
                  thresholdBucket(3, marker.quality(0.3)),
                  thresholdBucket(4, marker.quality(0.4)),
                  thresholdBucket(5, marker.quality(0.5)),
                  thresholdBucket(6, marker.quality(0.6)),
                  thresholdBucket(7, marker.quality(0.8)),
                  thresholdBucket(8, marker.quality(1.0)),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Human Pressures and Impacts",
            attributes: [
              thresholdAttr({
                attributeId: "index13",
                label:
                  "INDEX 13: Degree of Human Pressure Imposed by Bank Top Land Cover",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.9)),
                  thresholdBucket(1, marker.quality(0.8)),
                  thresholdBucket(2, marker.quality(0.6)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.3)),
                  thresholdBucket(5, marker.quality(0.2)),
                  thresholdBucket(6, marker.quality(0.1)),
                  thresholdBucket(7, marker.quality(0.0)),
                ],
              }),
              thresholdAttr({
                attributeId: "index14",
                label: "INDEX 14: Channel Reinforcement",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.9)),
                  thresholdBucket(1, marker.quality(0.8)),
                  thresholdBucket(2, marker.quality(0.6)),
                  thresholdBucket(3, marker.quality(0.5)),
                  thresholdBucket(4, marker.quality(0.3)),
                  thresholdBucket(5, marker.quality(0.2)),
                  thresholdBucket(6, marker.quality(0.1)),
                  thresholdBucket(7, marker.quality(0.0)),
                ],
              }),
              thresholdAttr({
                attributeId: "index15",
                label: "INDEX 15: Non-Native Invasive Plant Species Extent",
                decimalPlaces: 3,
                buckets: [
                  thresholdBucket(null, marker.quality(0.8)),
                  thresholdBucket(0.5, marker.quality(0.6)),
                  thresholdBucket(1.0, marker.quality(0.5)),
                  thresholdBucket(1.5, marker.quality(0.4)),
                  thresholdBucket(2.0, marker.quality(0.3)),
                  thresholdBucket(2.5, marker.quality(0.2)),
                  thresholdBucket(3.0, marker.quality(0.1)),
                  thresholdBucket(3.5, marker.quality(0.0)),
                ],
              }),
              numberAttr({
                attributeId: "index16",
                label: "INDEX 16: Number of Non-Native Invasive Plant Species",
                decimalPlaces: 3,
                buckets: [
                  lookupBucket(0, marker.quality(0.8), "0"),
                  lookupBucket(1, marker.quality(0.5), "1"),
                  lookupBucket(2, marker.quality(0.4), "2"),
                  lookupBucket(3, marker.quality(0.3), "3"),
                  lookupBucket(4, marker.quality(0.2), "4"),
                  lookupBucket(5, marker.quality(0.1), "5"),
                  lookupBucket(6, marker.quality(0.0), "6"),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
}

export const MrsMorph10 = morph10Map(unsafeMapId("mrsMorph10"), "MoRPh10");

export const MrsMorph10Pro = morph10Map(
  unsafeMapId("mrsMorph10Pro"),
  "MoRPh10"
);
