// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type WrtWestcountryCsiFlowImpedance =
  | "none"
  | "weir"
  | "tree"
  | "debrisDam"
  | "bridgeOrCulvert"
  | "other";

const None: WrtWestcountryCsiFlowImpedance = "none";
const Weir: WrtWestcountryCsiFlowImpedance = "weir";
const Tree: WrtWestcountryCsiFlowImpedance = "tree";
const DebrisDam: WrtWestcountryCsiFlowImpedance = "debrisDam";
const BridgeOrCulvert: WrtWestcountryCsiFlowImpedance = "bridgeOrCulvert";
const Other: WrtWestcountryCsiFlowImpedance = "other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WrtWestcountryCsiFlowImpedanceEnum = {
  ...createEnum("WrtWestcountryCsiFlowImpedance", [
    [None, "None"],
    [Weir, "Weir"],
    [Tree, "Fallen or submerged tree"],
    [DebrisDam, "Debris dam"],
    [BridgeOrCulvert, "Bridge/culvert (with step)"],
    [Other, "Other artificial structure"],
  ]),
  None,
  Weir,
  Tree,
  DebrisDam,
  BridgeOrCulvert,
  Other,
};

export default WrtWestcountryCsiFlowImpedanceEnum;
