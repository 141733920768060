import { Option } from "@cartographerio/fp";
import { SurveyModuleId } from "@cartographerio/types";
import lodash, { uniqBy } from "lodash";
import { SurveyModule } from "../SurveyModule";

type SurveyModuleDict = Record<string, SurveyModule>;

/** A colletion of `SurveyModules`. One day we'll deprecate this in favour of loading surveys from the server. */
export class SurveyModuleInventory {
  _modules: SurveyModule[];
  _modulesById: SurveyModuleDict;

  constructor(modules: SurveyModule[]) {
    this._modules = uniqBy(modules, module => module.moduleId);
    this._modulesById = lodash
      .chain(modules)
      .map(module => [module.moduleId, module])
      .fromPairs()
      .value();
  }

  /** Return a new SurveyModuleInventory including the specified module. */
  addSurveyModule = (module: SurveyModule): SurveyModuleInventory =>
    new SurveyModuleInventory([...this._modules, module]);

  /** Return a new SurveyModuleInventory without the specified module. */
  removeSurveyModule = (moduleId: SurveyModuleId): SurveyModuleInventory =>
    new SurveyModuleInventory(
      this._modules.filter(module => module.moduleId !== moduleId)
    );

  /** Returns a list of all survey modules. */
  surveyModules = (): SurveyModule[] => {
    return this._modules;
  };

  /** Returns a list of all survey module IDs. */
  surveyModuleIds = (): SurveyModuleId[] => {
    return this._modules.map(module => module.moduleId);
  };

  /** Returns a SurveyModule given an ID. */
  surveyModuleById = (id: SurveyModuleId): Option<SurveyModule> => {
    return Option.wrap(this._modulesById[id]);
  };
}

export function surveyModuleInventory(
  modules: SurveyModule[]
): SurveyModuleInventory {
  return new SurveyModuleInventory(modules);
}
