import { Result } from "@cartographerio/fp";
import { isPoint, Point } from "@cartographerio/geometry";
import {
  GuardError,
  hasOptionalKey,
  isNullable,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";

interface PartialData {
  location?: Point | null;
  established?: Timestamp | null;
  name?: string | null;
  description?: string | null;
  notes?: string | null;
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    hasOptionalKey(data, "location", isNullable(isPoint)) &&
    hasOptionalKey(data, "established", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "name", isNullable(isString)) &&
    hasOptionalKey(data, "description", isNullable(isString)) &&
    hasOptionalKey(data, "notes", isNullable(isString))
  );
}

const g = Result.guard(isPartialData, "PartialData");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map((data: PartialData) =>
    [data?.name ?? "Unknown site", data?.description ?? null]
      .filter(x => x != null)
      .join(", ")
  );
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map((data: PartialData) => data.established ?? null);
}

export function dataGeometry(data: unknown): Result<GuardError, Point | null> {
  return g(data).map((data: PartialData) => data.location ?? null);
}

export function copyData(data: unknown): Result<GuardError, unknown> {
  return g(data).map(data => ({
    ...data,
  }));
}
