import { Option, Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isObject,
  isString,
} from "@cartographerio/guard";
import { isTimestamp, Timestamp } from "@cartographerio/types";
import { midpoint } from "@turf/turf";

interface PartialLocation {
  upstream?: Point | null;
  downstream?: Point | null;
  name?: string | null;
}

export interface PartialData {
  recorded?: Timestamp | null;
  badgedGroupName?: string | null;
  location: PartialLocation;
}

function isPartialLocation(data: unknown): data is PartialLocation {
  return (
    isObject(data) &&
    hasOptionalKey(data, "upstream", isNullable(isPoint)) &&
    hasOptionalKey(data, "downstream", isNullable(isPoint)) &&
    hasOptionalKey(data, "name", isNullable(isString))
  );
}

export function isPartialData(data: unknown): data is PartialData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasOptionalKey(data, "badgedGroupName", isNullable(isString)) &&
    hasKey(data, "location", isPartialLocation)
  );
}

function locationMidpoint(data: PartialLocation): Option<Point> {
  return Option.wrap(data.upstream).fold(
    () =>
      Option.wrap(data.downstream).fold(
        () => Option.none(),
        dn => Option.some(dn)
      ),
    up =>
      Option.wrap(data.downstream).fold(
        () => Option.some(up),
        dn => Option.some(midpoint(up.coordinates, dn.coordinates).geometry)
      )
  );
}

const g = Result.guard(isPartialData, "PartialThames21BadgedGroupEvent");

export function dataDescription(data: unknown): Result<GuardError, string> {
  return g(data).map(data =>
    [
      Option.wrap(data.badgedGroupName).getOrElse(() => "Unknown group"),
      Option.wrap(data.location.name).getOrElse(() => "Unknown site"),
      locationMidpoint(data.location).fold(() => "Unknown NGR", safePointToNgr),
    ].join(", ")
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return g(data).map(data => locationMidpoint(data.location).getOrNull());
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return g(data).map(data => data.recorded ?? null);
}

export function copyData(data: unknown): Result<GuardError, PartialData> {
  return g(data).map(data => ({ ...data, recorded: null }));
}
