// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { AttachmentNaming } from "../types.generated";

const Original: AttachmentNaming = "Original";
const Title: AttachmentNaming = "Title";
const Id: AttachmentNaming = "Id";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AttachmentNamingEnum = {
  ...createEnum("AttachmentNaming", [
    [Original, "Use the original filename"],
    [Title, "Derive the filename from the title"],
    [Id, "Derive the filename from the attachment ID"],
  ]),
  Original,
  Title,
  Id,
};

export default AttachmentNamingEnum;
