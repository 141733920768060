// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusPersonalSafety =
  | "cctv"
  | "emergencyFloatAids"
  | "socialIssues"
  | "mainFootpathLighting"
  | "mainFootpathVisibility"
  | "emergencyContactNumbers";

const Cctv: EcostatusPersonalSafety = "cctv";
const EmergencyFloatAids: EcostatusPersonalSafety = "emergencyFloatAids";
const SocialIssues: EcostatusPersonalSafety = "socialIssues";
const MainFootpathLighting: EcostatusPersonalSafety = "mainFootpathLighting";
const MainFootpathVisibility: EcostatusPersonalSafety =
  "mainFootpathVisibility";
const EmergencyContactNumbers: EcostatusPersonalSafety =
  "emergencyContactNumbers";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusPersonalSafetyEnum = {
  ...createEnum("EcostatusPersonalSafety", [
    [Cctv, "CCTV"],
    [EmergencyFloatAids, "Emergency float aids"],
    [SocialIssues, "Social issues"],
    [MainFootpathLighting, "Main footpath lighting"],
    [MainFootpathVisibility, "Main footpath visibility"],
    [EmergencyContactNumbers, "Emergency contact numbers"],
  ]),
  Cctv,
  EmergencyFloatAids,
  SocialIssues,
  MainFootpathLighting,
  MainFootpathVisibility,
  EmergencyContactNumbers,
};

export default EcostatusPersonalSafetyEnum;
