// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EsrtWaterQualityPollutionEvidence =
  | "SewageRelatedLitter"
  | "LitterOrFlyTipping"
  | "UnpleasantOdour"
  | "OilySheen"
  | "SewageFungus"
  | "Foam"
  | "SmotheringAlgae"
  | "Other";

const SewageRelatedLitter: EsrtWaterQualityPollutionEvidence =
  "SewageRelatedLitter";
const LitterOrFlyTipping: EsrtWaterQualityPollutionEvidence =
  "LitterOrFlyTipping";
const UnpleasantOdour: EsrtWaterQualityPollutionEvidence = "UnpleasantOdour";
const OilySheen: EsrtWaterQualityPollutionEvidence = "OilySheen";
const SewageFungus: EsrtWaterQualityPollutionEvidence = "SewageFungus";
const Foam: EsrtWaterQualityPollutionEvidence = "Foam";
const SmotheringAlgae: EsrtWaterQualityPollutionEvidence = "SmotheringAlgae";
const Other: EsrtWaterQualityPollutionEvidence = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EsrtWaterQualityPollutionEvidenceEnum = {
  ...createEnum("EsrtWaterQualityPollutionEvidence", [
    [SewageRelatedLitter, "Sewage-related litter"],
    [LitterOrFlyTipping, "Litter/fly-tipping"],
    [UnpleasantOdour, "Unpleasant odour"],
    [OilySheen, "Oily sheen"],
    [SewageFungus, "Sewage fungus"],
    [Foam, "Foam"],
    [SmotheringAlgae, "Smothering algae"],
    [Other, "Other"],
  ]),
  SewageRelatedLitter,
  LitterOrFlyTipping,
  UnpleasantOdour,
  OilySheen,
  SewageFungus,
  Foam,
  SmotheringAlgae,
  Other,
};

export default EsrtWaterQualityPollutionEvidenceEnum;
