// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type MrsMajorIntermediateMinor = "Maj" | "Int" | "Min";

const Major: MrsMajorIntermediateMinor = "Maj";
const Intermediate: MrsMajorIntermediateMinor = "Int";
const Minor: MrsMajorIntermediateMinor = "Min";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MrsMajorIntermediateMinorEnum = {
  ...createEnum("MrsMajorIntermediateMinor", [
    [Major, "Major"],
    [Intermediate, "Intermediate"],
    [Minor, "Minor"],
  ]),
  Major,
  Intermediate,
  Minor,
};

export default MrsMajorIntermediateMinorEnum;
