import { appendPath, errorMessage, Message } from "@cartographerio/topo-core";
import {
  isGeometryAtom,
  isLineString,
  isMultiLineString,
  isMultiPoint,
  isMultiPolygon,
  isPoint,
  isPolygon,
} from "@cartographerio/geometry";
import {
  hasKeyOfAnyType,
  hasTypeTag,
  isArray,
  isBoolean,
  isInteger,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from "@cartographerio/guard";
import { isValidTimestamp, isValidUuid, Path } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { describeSchema } from "./describe";
import { Schema } from "./type";

function fail(expected: Schema, actual: unknown, path: Path): Message {
  return errorMessage(
    `Expected ${describeSchema(expected)}, found ${JSON.stringify(actual)}`,
    path,
    { actual }
  );
}

export function typeCheck(
  schema: Schema,
  value: unknown,
  path: Path = []
): Message[] {
  switch (schema.type) {
    case "Boolean":
      return isBoolean(value) ? [] : [fail(schema, value, path)];

    case "Integer":
      return isInteger(value) ? [] : [fail(schema, value, path)];

    case "Number":
      return isNumber(value) ? [] : [fail(schema, value, path)];

    case "String":
      return isString(value) ? [] : [fail(schema, value, path)];

    case "Uuid":
      return isValidUuid(value) ? [] : [fail(schema, value, path)];

    case "Timestamp":
      return isValidTimestamp(value) ? [] : [fail(schema, value, path)];

    case "Geometry":
      switch (schema.geometryType) {
        case "Point":
          return isPoint(value) ? [] : [fail(schema, value, path)];
        case "MultiPoint":
          return isMultiPoint(value) ? [] : [fail(schema, value, path)];
        case "LineString":
          return isLineString(value) ? [] : [fail(schema, value, path)];
        case "MultiLineString":
          return isMultiLineString(value) ? [] : [fail(schema, value, path)];
        case "Polygon":
          return isPolygon(value) ? [] : [fail(schema, value, path)];
        case "MultiPolygon":
          return isMultiPolygon(value) ? [] : [fail(schema, value, path)];
        case undefined:
          return isGeometryAtom(value) ? [] : [fail(schema, value, path)];
        default:
          return checkExhausted(schema.geometryType);
      }

    case "Unknown":
      return [];

    case "Enum":
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return schema.values.includes(value as any)
        ? []
        : [fail(schema, value, path)];

    case "Nullable":
      return isNull(value) || isUndefined(value)
        ? []
        : typeCheck(schema.param, value, path);

    case "Array":
      if (isArray(value)) {
        return value.reduce<Message[]>(
          (memo, item, index) => [
            ...memo,
            ...typeCheck(schema.param, item, appendPath(path, index)),
          ],
          []
        );
      } else {
        return [fail(schema, value, path)];
      }

    case "Tuple":
      if (isArray(value)) {
        return schema.items.reduce<Message[]>((memo, schema, index) => {
          return [
            ...memo,
            ...typeCheck(
              schema,
              index < value.length ? value[index] : undefined,
              appendPath(path, index)
            ),
          ];
        }, []);
      } else {
        return [fail(schema, value, path)];
      }

    case "Dict":
      if (isObject(value)) {
        return Object.keys(value).reduce<Message[]>((memo, key) => {
          return [
            ...memo,
            ...typeCheck(schema.key, key, appendPath(path, key)),
            ...typeCheck(
              schema.value,
              hasKeyOfAnyType(value, key) ? value[key] : undefined,
              appendPath(path, key)
            ),
          ];
        }, []);
      } else {
        return [fail(schema, value, path)];
      }

    case "Product":
      if (isObject(value)) {
        return Object.keys(schema.fields).reduce<Message[]>((memo, key) => {
          return [
            ...memo,
            ...typeCheck(
              schema.fields[key],
              hasKeyOfAnyType(value, key) ? value[key] : undefined,
              appendPath(path, key)
            ),
          ];
        }, []);
      } else {
        return [fail(schema, value, path)];
      }

    case "Sum":
      if (isObject(value)) {
        const ctor: string | undefined = Object.keys(schema.products).find(
          ctor => hasTypeTag(value, ctor)
        );

        const prodSchema: Schema | undefined = ctor
          ? schema.products[ctor]
          : undefined;

        if (prodSchema) {
          return typeCheck(prodSchema, value, path);
        } else {
          return [fail(schema, value, path)];
        }
      } else {
        return [fail(schema, value, path)];
      }

    default:
      return checkExhausted(schema);
  }
}
