import {
  attachmentAttr,
  Attribute,
  attributeGroup,
  lookupBucket,
  marker,
  numberAttr,
  pointLayer,
  surveyAttr,
  teamAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import { unsafeSurveyModuleId } from "@cartographerio/types";

function hlaAttr(attributeId: string, label: string): Attribute {
  return numberAttr({
    attributeId,
    label,
    buckets: [
      lookupBucket(1, marker.quality(0.9)),
      lookupBucket(2, marker.quality(0.7)),
      lookupBucket(3, marker.quality(0.5)),
      lookupBucket(4, marker.quality(0.3)),
      lookupBucket(5, marker.quality(0.1)),
    ],
  });
}

export const PlacemarkerStudyArea = mapSchemaWithDefaults({
  mapId: unsafeMapId("placemarkerStudyArea"),
  title: "Study Area",
  layers: [
    pointLayer({
      layerId: unsafeMapLayerId("placemarkerStudyArea"),
      title: "Study Area",
      source: cartographerSourceWithDefaults({
        layerId: unsafeMapLayerId("placemarkerStudyArea"),
        attribution: [],
      }),
      defaultAttribute: "habitatOverall",
      defaultZOrder: "timestamp",
      attributes: [
        attributeGroup({
          label: "Survey",
          attributes: [
            surveyAttr("surveyId", "Survey"),
            teamAttr("teamId", "Team", {
              module: unsafeSurveyModuleId("placemarkerStudyArea"),
            }),
            timestampAttr({
              attributeId: "timestamp",
              label: "Date/Time",
              buckets: "auto",
            }),
            attachmentAttr("siteMap", "Site Map"),
            attachmentAttr("photographs", "Photographs"),
          ],
        }),
        attributeGroup({
          label: "Classifications",
          attributes: [
            hlaAttr("habitatOverall", "Habitat Quality Assessment (HaQA)"),
            hlaAttr("habitatComplexity", "Habitat Complexity"),
            hlaAttr("habitatConnectivity", "Habitat Connectivity"),
            hlaAttr("habitatCondition", "Habitat Condition"),
            hlaAttr("landscapeOverall", "Landscape Quality Assessment (LQA)"),
            hlaAttr("landscapeComplexity", "Landscape Complexity"),
            hlaAttr("landscapeCoherence", "Landscape Coherence"),
            hlaAttr("landscapeInterest", "Landscape Interest"),
            hlaAttr("landscapeCondition", "Landscape Condition"),
            hlaAttr("heritageOverall", "Heritage Quality Assessment (HeQA)"),
            hlaAttr("heritageComplexity", "Heritage Complexity"),
            hlaAttr("heritageCoherence", "Heritage Coherence"),
            hlaAttr("heritageInterest", "Heritage Interest"),
            hlaAttr("heritageCondition", "Heritage Condition"),
            hlaAttr("amenityOverall", "Amenity Quality Assessment (AQA)"),
            hlaAttr("amenityComplexity", "Amenity Complexity"),
            hlaAttr("amenityCoherence", "Amenity Coherence"),
            hlaAttr("amenityInterest", "Amenity Interest"),
            hlaAttr("amenityCondition", "Amenity Condition"),
          ],
        }),
        attributeGroup({
          label: "Indices",
          attributes: [
            numberAttr({
              attributeId: "countStudyAreaHabs",
              label:
                "CountStudyAreaHabs: Number of 'green' land cover types in the study area",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "accessibility",
              label:
                "Accessibility: Accessibility into the study area for people",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "footpaths",
              label: "Footpaths: Accessibility provided by footpaths",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "cyclePaths",
              label: "CyclePaths: Accessibility provided by cycle paths",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "wheelChairAccess",
              label: "WheelChairAccess: Accessibility available to wheelchairs",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "riverVisibility",
              label:
                "RiverVisibility: Visibility of the river from the study area ",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "urbanVisibility",
              label:
                "UrbanVisibility: Visibility of urban-industrial transport from the study area",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "visualConnectivity",
              label:
                "VisualConnectivity: Extent of connections from the study area laterally to fringing areas",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "humanConnectivity",
              label: "HumanConnectivity: Aggregate study area connectivity",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "recreationOpportunities",
              label:
                "RecreationOpportunities: Range of recreational facilities available",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "recreationCondition",
              label:
                "RecreationCondition: Average condition of recreational facilities",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "wilderness",
              label: "Wilderness: Presence and condition of 'wilderness' areas",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "interpretationBoards",
              label:
                "InterpretationBoards: Presence and condition of interpretation boards",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "formalEducation",
              label:
                "FormalEducation: Presence and condition of formal eduction facilities",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "education",
              label: "Education: Aggregate educational value of study area",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "areaNumPollution",
              label:
                "AreaNumPollution: The number of types of pollution recorded across the area",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "hygiene",
              label: "Hygiene: Presence of toilet and drinking water faciities",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "litter",
              label: "Litter: Presence of litter across the study area",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "litterDisposal",
              label:
                "LitterDisposal: Presence of waste disposal facilities across the study area",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "personalSafety",
              label:
                "PersonalSafety: Facilities and indicators of personal safety",
              buckets: "auto",
            }),
            numberAttr({
              attributeId: "economicAndSocialValue",
              label:
                "EconomicAndSocialValue: Potential economic value of the study area",
              buckets: "auto",
            }),
          ],
        }),
      ],
    }),
  ],
});
