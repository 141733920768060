// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { TeamRoleName } from "../types.generated";

const Member: TeamRoleName = "Member";
const Surveyor: TeamRoleName = "Surveyor";
const Approver: TeamRoleName = "Approver";
const Coordinator: TeamRoleName = "Coordinator";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TeamRoleNameEnum = {
  ...createEnum("TeamRoleName", [
    [Member, "Team Member"],
    [Surveyor, "Team Surveyor"],
    [Approver, "Team Approver"],
    [Coordinator, "Team Coordinator"],
  ]),
  Member,
  Surveyor,
  Approver,
  Coordinator,
};

export default TeamRoleNameEnum;
