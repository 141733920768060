// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type EcostatusConfidenceLevel = "high" | "medium" | "low";

const High: EcostatusConfidenceLevel = "high";
const Medium: EcostatusConfidenceLevel = "medium";
const Low: EcostatusConfidenceLevel = "low";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EcostatusConfidenceLevelEnum = {
  ...createEnum("EcostatusConfidenceLevel", [
    [High, "High"],
    [Medium, "Medium"],
    [Low, "Low"],
  ]),
  High,
  Medium,
  Low,
};

export default EcostatusConfidenceLevelEnum;
