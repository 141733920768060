// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

export type RiverflyReasonNotTaken =
  | "Drought"
  | "HighFlow"
  | "Frozen"
  | "Other";

const Drought: RiverflyReasonNotTaken = "Drought";
const HighFlow: RiverflyReasonNotTaken = "HighFlow";
const Frozen: RiverflyReasonNotTaken = "Frozen";
const Other: RiverflyReasonNotTaken = "Other";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RiverflyReasonNotTakenEnum = {
  ...createEnum("RiverflyReasonNotTaken", [
    [Drought, "Drought - Low/No Flow"],
    [HighFlow, "Flow Too High/Fast"],
    [Frozen, "Frozen"],
    [Other, "Other"],
  ]),
  Drought,
  HighFlow,
  Frozen,
  Other,
};

export default RiverflyReasonNotTakenEnum;
