import { Result } from "@cartographerio/fp";
import {
  GeometryAtom,
  isPoint,
  Point,
  safePointToNgr,
} from "@cartographerio/geometry";
import {
  GuardError,
  hasKey,
  hasOptionalKey,
  isNullable,
  isNumber,
  isObject,
  isString,
} from "@cartographerio/guard";
import {
  UrsSurveyType,
  UrsSurveyTypeEnum,
} from "@cartographerio/inventory-enums";
import { isTimestamp, Timestamp } from "@cartographerio/types";

interface SurveyDetails {
  riverName?: string | null;
  reachName?: string | null;
  subreachName?: string | null;
  moduleNumber?: number | null;
  surveyType?: UrsSurveyType | null;
  scenarioName?: string | null;
  location?: Point | null;
}

function isSurveyDetails(details: unknown): details is SurveyDetails {
  return (
    isObject(details) &&
    hasOptionalKey(details, "riverName", isNullable(isString)) &&
    hasOptionalKey(details, "reachName", isNullable(isString)) &&
    hasOptionalKey(details, "subreachName", isNullable(isString)) &&
    hasOptionalKey(details, "moduleNumber", isNullable(isNumber)) &&
    hasOptionalKey(
      details,
      "surveyType",
      isNullable(UrsSurveyTypeEnum.isValue)
    ) &&
    hasOptionalKey(details, "scenarioName", isNullable(isString)) &&
    hasOptionalKey(details, "location", isNullable(isPoint))
  );
}

export interface PartialMorphEstuariesData {
  recorded?: Timestamp | null;
  surveyDetails: SurveyDetails;
}

export function isPartialData(
  data: unknown
): data is PartialMorphEstuariesData {
  return (
    isObject(data) &&
    hasOptionalKey(data, "recorded", isNullable(isTimestamp)) &&
    hasKey(data, "surveyDetails", isSurveyDetails)
  );
}

function createDescription(...args: Array<string | null>): string {
  return args.filter(arg => arg != null).join(", ");
}

export function dataDescription(data: unknown): Result<GuardError, string> {
  return Result.guard(
    isPartialData,
    "PartialMrsMorphEstuariesData"
  )(data).map(data =>
    createDescription(
      data.surveyDetails.riverName ?? "Unknown river",
      data.surveyDetails.reachName ?? "Unknown reach",
      data.surveyDetails.subreachName ?? "Unknown subreach",
      data.surveyDetails.moduleNumber == null
        ? "Unknown module"
        : `${data.surveyDetails.moduleNumber}`,
      data.surveyDetails.location
        ? safePointToNgr(data.surveyDetails.location)
        : null,
      data.surveyDetails.surveyType === UrsSurveyTypeEnum.Scenario
        ? `Scenario (${data.surveyDetails.scenarioName ?? "Unnamed"})`
        : data.surveyDetails.surveyType == null
          ? null
          : UrsSurveyTypeEnum.labelOf(data.surveyDetails.surveyType)
    )
  );
}

export function dataGeometry(
  data: unknown
): Result<GuardError, GeometryAtom | null> {
  return Result.guard(
    isPartialData,
    "PartialMrsMorphEstuariesData"
  )(data).map(data => data.surveyDetails.location ?? null);
}

export function dataTimestamp(
  data: unknown
): Result<GuardError, Timestamp | null> {
  return Result.guard(
    isPartialData,
    "PartialMrsMorphEstuariesData"
  )(data).map(data => data.recorded || null);
}

export function copyData(
  data: unknown
): Result<GuardError, PartialMorphEstuariesData> {
  return Result.guard(
    isPartialData,
    "PartialMrsMorphEstuariesData"
  )(data).map(data => ({
    ...data,
    recorded: null,
    surveyDetails: {
      ...data.surveyDetails,
      location: null,
      moduleNumber: null,
    },
  }));
}
