import { unsafeTag } from "@cartographerio/util";
import { v4 as createUuid } from "uuid";
import {
  ArcgisAccessToken,
  ArcgisAuthCode,
  ArcgisIntegrationId,
  ArcgisLayerUrl,
  ArcgisRefreshToken,
} from "./types.generated";

export function randomArcgisIntegrationId(): ArcgisIntegrationId {
  return unsafeTag<"ArcgisIntegrationId">(createUuid());
}

export function unsafeArcgisIntegrationId(id: string): ArcgisIntegrationId {
  return unsafeTag<"ArcgisIntegrationId">(id);
}

export function unsafeArcgisLayerUrl(url: string): ArcgisLayerUrl {
  return unsafeTag<"ArcgisLayerUrl">(url);
}

export function randomArcgisAccessToken(): ArcgisAccessToken {
  return unsafeTag<"ArcgisAccessToken">(createUuid());
}

export function unsafeArcgisAccessToken(token: string): ArcgisAccessToken {
  return unsafeTag<"ArcgisAccessToken">(token);
}

export function randomArcgisRefreshToken(): ArcgisRefreshToken {
  return unsafeTag<"ArcgisRefreshToken">(createUuid());
}

export function unsafeArcgisRefreshToken(token: string): ArcgisRefreshToken {
  return unsafeTag<"ArcgisRefreshToken">(token);
}

export function unsafeArcgisAuthCode(code: string): ArcgisAuthCode {
  return unsafeTag<"ArcgisAuthCode">(code);
}
