import { Env } from "@cartographerio/topo-core";
import {
  ArraySchema,
  isAnySchema,
  isArraySchema,
  isSchema,
  Schema,
  schemaRef,
} from "@cartographerio/topo-survey";
import { Option, Result } from "@cartographerio/fp";
import { isArray } from "@cartographerio/guard";
import { internalError, isTeamId, isUserRef } from "@cartographerio/types";
import { runRules } from "../rule";
import {
  Block,
  Card,
  DynamicGrid,
  DynamicImage,
  DynamicValue,
  Field,
  Form,
  FullWidth,
  Group,
  Heading,
  Page,
  PrimarySurveyorField,
  PrimaryTeamField,
  Repeat,
  RequireRole,
  Scope,
  Text,
  Visibility,
} from "../type";
import { fieldImage } from "./image";
import { messageCounts, messageHighlight, pageMessageCounts } from "./message";
import { fieldIsRequired } from "../required";
import {
  BlockState,
  CardState,
  DynamicGridState,
  DynamicImageState,
  DynamicValueState,
  FieldState,
  FormState,
  FullWidthState,
  GroupState,
  HeadingState,
  PageState,
  PrimarySurveyorFieldState,
  PrimaryTeamFieldState,
  RepeatState,
  RequireRoleState,
  ScopeState,
  TextState,
  VisibilityState,
} from "./type";
import { topoEval, optTopoEval } from "../expr/run";

export function initialFieldState(block: Field, parentEnv: Env): FieldState {
  const env = parentEnv.focus(block.path);
  const absolutePath = env.expand([]);
  const value = env.getFocused();
  const image = fieldImage(block, value);
  const required = fieldIsRequired(block);
  const messages = runRules(block, env);
  const counts = messageCounts(messages);
  const highlight = messageHighlight(counts);
  const visible =
    optTopoEval(block.visible, parentEnv).getOrUndefined() ?? true;

  return {
    type: "FieldState",
    absolutePath,
    field: block,
    value,
    image,
    required,
    visible,
    messages,
    highlight,
    ...counts,
  };
}

export function initialCardState(
  block: Card,
  parentSchema: Schema,
  parentEnv: Env
): CardState {
  return {
    type: "CardState",
    block,
    childState: initialBlockState(block.block, parentSchema, parentEnv),
  };
}

export function initialDynamicValueState(
  block: DynamicValue,
  parentEnv: Env
): DynamicValueState {
  return {
    type: "DynamicValueState",
    block,
    attribute: topoEval(block.value, parentEnv)
      .map(value => ({
        label: block.label,
        valueType: block.valueType,
        units: block.units,
        decimalPlaces: block.decimalPlaces,
        value,
        error: null,
      }))
      .getOrElse(message => ({
        label: block.label,
        valueType: block.valueType,
        units: block.units,
        decimalPlaces: block.decimalPlaces,
        value: null,
        error: `${message}`,
      })),
  };
}

export function initialDynamicGridState(
  block: DynamicGrid,
  parentEnv: Env
): DynamicGridState {
  return {
    type: "DynamicGridState",
    block,
    attributes: block.attributes.map(attribute =>
      topoEval(attribute.value, parentEnv)
        .map(value => ({
          label: attribute.label,
          valueType: attribute.valueType,
          units: attribute.units,
          decimalPlaces: attribute.decimalPlaces,
          value,
          error: null,
        }))
        .getOrElse(message => ({
          label: attribute.label,
          valueType: attribute.valueType,
          units: attribute.units,
          decimalPlaces: attribute.decimalPlaces,
          value: null,
          error: `${message}`,
        }))
    ),
  };
}

export function initialDynamicImageState(
  block: DynamicImage,
  parentEnv: Env
): DynamicImageState {
  const urlResult: Result<string, string | null> = topoEval(
    block.url,
    parentEnv
  ).mapError(String);

  const captionResult: Result<string, string | null | undefined> = optTopoEval(
    block.caption,
    parentEnv
  ).mapError(String);

  return {
    type: "DynamicImageState",
    block,
    url: urlResult.getOrNull(),
    urlError: urlResult.swap().getOrNull(),
    caption: captionResult?.getOrNull(),
    captionError: captionResult?.swap().getOrNull(),
  };
}

export function initialHeadingState(block: Heading): HeadingState {
  return {
    type: "HeadingState",
    block,
  };
}

export function initialTextState(block: Text, parentEnv: Env): TextState {
  return {
    type: "TextState",
    visible: optTopoEval(block.visible, parentEnv).getOrUndefined() ?? true,
    block,
  };
}

export function initialGroupState(
  block: Group,
  parentSchema: Schema,
  parentEnv: Env
): GroupState {
  return {
    type: "GroupState",
    block,
    blockStates: block.blocks.map(b =>
      initialBlockState(b, parentSchema, parentEnv)
    ),
  };
}

export function initialRepeatStateFromArray(
  block: Repeat,
  arraySchema: ArraySchema,
  arrayEnv: Env
): RepeatState {
  const arrayPath = arrayEnv.expand([]);

  const arrayValue = arrayEnv
    .getFocusedAs(isArray)
    .mapError(() => internalError("Data for repeat block isn't an array"))
    .getOrThrow();

  const childSchema = arraySchema?.param;

  const childStates: BlockState[] = arrayValue.map((_value, index) =>
    initialBlockState(block.block, childSchema, arrayEnv.focus([index]))
  );

  return {
    type: "RepeatState",
    block,
    arrayPath,
    arraySchema,
    childStates,
  };
}

export function initialRepeatState(
  block: Repeat,
  parentSchema: Schema,
  parentEnv: Env
): RepeatState {
  const arraySchema: ArraySchema = Option.wrap(parentSchema)
    .nullMap(s => schemaRef(s, block.path))
    .guard(
      (value: unknown): value is ArraySchema =>
        isSchema(value) && isArraySchema(isAnySchema)(value)
    )
    .getOrThrow(() => new Error("Schema for repeat block isn't an array"));

  const arrayEnv: Env = parentEnv.focus(block.path);

  return initialRepeatStateFromArray(block, arraySchema, arrayEnv);
}

export function initialRequireRoleState(
  block: RequireRole,
  parentSchema: Schema,
  parentEnv: Env
): RequireRoleState {
  return {
    type: "RequireRoleState",
    block,
    childState: initialBlockState(block.block, parentSchema, parentEnv),
  };
}

export function initialScopeState(
  block: Scope,
  parentSchema: Schema,
  parentEnv: Env
): ScopeState {
  const schema = Option.wrap(parentSchema)
    .nullMap(schema => schemaRef(schema, block.path))
    .getOrThrow(() => new Error("Repeat schema not found"));

  const env = parentEnv.focus(block.path);

  return {
    type: "ScopeState",
    block,
    childState: initialBlockState(block.block, schema, env),
  };
}

export function initialFullWidthState(
  block: FullWidth,
  parentSchema: Schema,
  parentEnv: Env
): FullWidthState {
  return {
    type: "FullWidthState",
    block,
    childState: initialBlockState(block.block, parentSchema, parentEnv),
  };
}

export function initialVisibilityState(
  block: Visibility,
  parentSchema: Schema,
  parentEnv: Env
): VisibilityState {
  return {
    type: "VisibilityState",
    visible: topoEval(block.visible, parentEnv).getOrUndefined() ?? true,
    block,
    childState: initialBlockState(block.block, parentSchema, parentEnv),
  };
}

export function initialPrimarySurveyorFieldState(
  field: PrimarySurveyorField,
  _parentSchema: Schema,
  parentEnv: Env
): PrimarySurveyorFieldState {
  const messages = field.rule(parentEnv);
  const counts = messageCounts(messages);
  return {
    type: "PrimarySurveyorFieldState",
    field,
    messages,
    surveyor: parentEnv.getAbsoluteAs(["surveyor"], isUserRef).getOrNull(),
    teamId: parentEnv.getAbsoluteAs(["teamId"], isTeamId).getOrNull(),
    ...counts,
    highlight: messageHighlight(counts),
  };
}

export function initialPrimaryTeamFieldState(
  field: PrimaryTeamField,
  _parentSchema: Schema,
  parentEnv: Env
): PrimaryTeamFieldState {
  const messages = field.rule(parentEnv);
  const counts = messageCounts(messages);
  return {
    type: "PrimaryTeamFieldState",
    field,
    messages,
    surveyor: parentEnv.getAbsoluteAs(["surveyor"], isUserRef).getOrNull(),
    teamId: parentEnv.getAbsoluteAs(["teamId"], isTeamId).getOrNull(),
    ...counts,
    highlight: messageHighlight(counts),
  };
}

export function initialBlockState(
  block: Block,
  parentSchema: Schema,
  parentEnv: Env
): BlockState {
  switch (block.type) {
    case "Card":
      return initialCardState(block, parentSchema, parentEnv);
    case "DynamicValue":
      return initialDynamicValueState(block, parentEnv);
    case "DynamicGrid":
      return initialDynamicGridState(block, parentEnv);
    case "DynamicImage":
      return initialDynamicImageState(block, parentEnv);
    case "FullWidth":
      return initialFullWidthState(block, parentSchema, parentEnv);
    case "Heading":
      return initialHeadingState(block);
    case "Text":
      return initialTextState(block, parentEnv);
    case "Group":
      return initialGroupState(block, parentSchema, parentEnv);
    case "Repeat":
      return initialRepeatState(block, parentSchema, parentEnv);
    case "RequireRole":
      return initialRequireRoleState(block, parentSchema, parentEnv);
    case "Scope":
      return initialScopeState(block, parentSchema, parentEnv);
    case "Visibility":
      return initialVisibilityState(block, parentSchema, parentEnv);
    case "PrimarySurveyorField":
      return initialPrimarySurveyorFieldState(block, parentSchema, parentEnv);
    case "PrimaryTeamField":
      return initialPrimaryTeamFieldState(block, parentSchema, parentEnv);
    default:
      return initialFieldState(block, parentEnv);
  }
}

export function initialPageState(
  page: Page,
  formSchema: Schema,
  formEnv: Env
): PageState {
  const pageSchema: Schema = Option.wrap(formSchema)
    .nullMap(s => schemaRef(s, page.path))
    .getOrThrow(() => new Error("Page schema not found"));

  const pageEnv = formEnv.focus(page.path);

  const blockStates: BlockState[] = page.blocks.flatMap(block => {
    return initialBlockState(block, pageSchema, pageEnv);
  });

  const counts = pageMessageCounts(blockStates);
  const highlight = messageHighlight(counts);

  const enabled: boolean =
    optTopoEval(page.enabled, pageEnv).getOrUndefined() ?? true;

  return {
    page,
    blockStates,
    enabled,
    highlight,
    ...counts,
  };
}

export function initialFormState(
  form: Form,
  schema: Schema,
  document: unknown
): FormState {
  const pageStates = form.pages.map(page =>
    initialPageState(page, schema, Env.create(document))
  );

  const errorCount = pageStates.reduce(
    (total, pageState) => total + pageState.errorCount,
    0
  );

  const infoCount = pageStates.reduce(
    (total, pageState) => total + pageState.infoCount,
    0
  );

  return {
    form,
    document,
    pageStates,
    errorCount,
    infoCount,
  };
}
